import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { Card, CardBody, Container, Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Home } from "react-feather";

export default function FailedUrls() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [searchText, setSearchText] = useState('')
    const columns = [
        { name: 'File Name', selector: 'file_name', sortable: true, center: true },
        { name: 'File Download', selector: 'url', center: true, sortable: 'true', cell: row => <a className="link-primary" href={`/api/v1/internal/failed_urls/download?filename=${row.url}`} download>Download a file</a> }
    ]
    useEffect(() => {
        setLoading(true)
        axios.get('/api/v1/internal/failed_urls')
            .then(res => {
                setData(res.data.file_names);
                setSearchResults(res.data.file_names)
                setLoading(false)
            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong')
                setLoading(false)
            })
    }, []);

    const handleChange = event => {
        if (event.target.value == '') {
            setSearchResults(data)
        }
        else {
            setSearchResults(data.filter(item => item.file_name.includes(event.target.value)))
        }
        setSearchText(event.target.value);
    }


    return <Container fluid>
        <Container fluid>
            <div className="page-title">
                <Row className=''>
                    <Col xs="6">
                        <h3>Internal Dashboard</h3>
                    </Col>
                    <Col xs="6">
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <Link to={`/internal`}><Home /></Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem className="fw-bold">
                                Failed URLs
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
        </Container>
        {loading ? <Loader type="Puff" color="#00BFFF" height={100} width={100} className='pc-loader' /> : <Card>
            <CardBody>
                <Row className="align-items-center float-end my-3">
                    <div className="col-sm-3">Search</div>
                    <div className="col">
                        <input className="form-control" type={'text'} value={searchText} onChange={handleChange} />
                    </div>
                </Row>
                <DataTable
                    columns={columns}
                    data={searchResults}
                    pagination
                    noHeader
                    highlightOnHover />
            </CardBody>
        </Card>}
    </Container>
}