/**
 * This is a wrapper component for all the loading animations shown in the components
 * It takes a prop called "loading" which is boolean true or false
 * If the prop passed is true then it displays the loading screen otherwise it displays 
 * the component wrapped within it.
 * @example: https://www.youtube.com/watch?v=LME0Uv9SlDA&ab_channel=NiklasZiermann
 */

import React from 'react';
import Loader from 'react-loader-spinner';

export default function SpinnerLoader(props) {
  if (props.loading) {
    return (
      <>
        <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
      </>
    )
  }
  else {
    return (
      <>
        {props.children}
      </>
    )
  }

}
