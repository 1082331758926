import React, { useEffect, useMemo, useState } from 'react';

import { Card, CardBody, Container, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import axios from 'axios';

import Breadcrumbs from '../../Common/BreadCrumb/BreadCrumbs.component';
import SpinnerButton from '../../Common/SpinnerButton/SpinnerButton.component';
import SpinnerLoader from '../../Common/LoadingAnimation/SpinnerLoader.component';
import EndToEndCrawlTrackerDataTable from './DataTable/EndToEndCrawlTrackerDataTable.component';

export default function EndToEndCrawlTracker() {

  const [loading, setLoading] = useState(false) // loading animation
  const [siteName, setSiteName] = useState({ label: 'All', value: 'all' }) // sitename selected by user
  const [crawlCounts, setCrawlCounts] = useState({ label: 7, value: 7 }) // crawlcounts selected by user
  const [searchString, setSearchString] = useState('') // value searched by user in the search input
  const [filteredData, setFilteredData] = useState('') // the result data of searching the value in the data
  const [siteNamesDropdownOptions, setSiteNameDropdownOptions] = useState([]) // sitenames displayed in dropdown
  const [endToEndCrawlStatusData, setEndToEndCrawlStatusData] = useState([]) // data from backend

  // API Endpoint
  const API_ENDPOINT = useMemo(() => (
    `/api/v2/internal/end_to_end_crawl_tracker?filter[site_name]=${siteName.value}&filter[crawl_counts]=${crawlCounts.value}`
  ), [siteName, crawlCounts])

  // Available crawl count options
  const crawlCountsOptions = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
    { label: 11, value: 11 },
    { label: 12, value: 12 },
    { label: 13, value: 13 },
    { label: 14, value: 14 },
    { label: 15, value: 15 },

  ];


  useEffect(async () => {
    const constroller = new AbortController()
    setLoading(true)
    await fetchEndToEndCrawlTrackerData(constroller.signal)
    fetchDefaultOptionsForSiteName()
    // cleanup
    return () => {
      constroller.abort()
    }
  }, []);

  // Helper function to filter data based on search
  useEffect(() => {
    let filteredData = endToEndCrawlStatusData.filter((data) => {
      return data.site_name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
        data.rss_job_type.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
        data.rss_crawlstatus.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
        data.rss_crawl_start_status.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
        data.rss_crawl_end_status.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
    })
    setFilteredData(filteredData)
  }, [endToEndCrawlStatusData, searchString])

  // Helper function to get data from backed DB for End To ENd Crawl Status
  async function fetchEndToEndCrawlTrackerData(signal) {
    try {
      const response = await axios.get(API_ENDPOINT, { signal: signal })
      const data = await response?.data?.crawl_tracker_data
      setEndToEndCrawlStatusData(data)
      setLoading(false)
    }
    catch (error) {
      toast.error(error?.message)
      setLoading(false)
    }
  }

  // Helper method to fetch default site name dropdown options
  function fetchDefaultOptionsForSiteName() {
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let siteNames = res?.data?.site_names, siteNamesOptions = []
        for (var key in siteNames) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
          siteNames[key].map(item => {
            children.push({ label: item, value: item })
          })
          group['options'] = children;
          siteNamesOptions.push(group)
        }
        siteNamesOptions.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
        setSiteNameDropdownOptions(siteNamesOptions)
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      })
  }

  // Helper mothod to fetch site name in site name dropdown async select
  async function onDropdownOptionSearchHandler(inputValue) {
    if (inputValue.length < 3) {
      return null
    } else {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200') {
            let siteNames = res.data.site_names, siteNamesOptions = []
            for (var key in siteNames) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
              siteNames[key].map(item => {
                children.push({ label: item, value: item })
              })
              group['options'] = children;
              siteNamesOptions.push(group)
            }
            siteNamesOptions.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setSiteNameDropdownOptions(siteNamesOptions)
            resolve(siteNamesOptions)
          }
        }).catch(err => {
          console.log(`err`, err)
        })
      })
    }
  }

  // Helper button to fetch data when Get Data button is clicked
  async function onGetDataButtonClickHandler(event) {
    event.preventDefault()
    setLoading(true)
    await fetchEndToEndCrawlTrackerData()
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Container fluid>
          <Breadcrumbs parent='Internal Dashboard' title='End To End Crawl Tracker' />
        </Container>
      </Container>
      <Card>
        <CardBody>
          <Row className='align-items-end'>
            <div className="col-sm-7">
              <label className="fw-bold">Site Name</label>
              <AsyncSelect
                value={siteName}
                cacheOptions
                defaultOptions={siteNamesDropdownOptions}
                loadOptions={onDropdownOptionSearchHandler}
                onChange={(VALUE) => { setSiteName(VALUE) }}
                placeholder={'Select Site Name'}
              />
            </div>
            <div className="col-sm-3">
              <label className="fw-bold">Crawl Counts</label>
              <Select
                value={crawlCounts}
                options={crawlCountsOptions}
                onChange={(VALUE) => setCrawlCounts(VALUE)}
              />
            </div>
            <div className="col-sm-2">
              <SpinnerButton
                className="w-100 p-2"
                color={'success'}
                loading={loading}
                onClick={onGetDataButtonClickHandler}>
                Get Data
              </SpinnerButton>
            </div>
            <div className="form-text">Please type atleast 3 characters while searching the sitename</div>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <div>
            <h4>Note</h4>
            <p>1) <b> Finished</b> signifies either that stage is done or failed in between.</p>
            <p>2) DateTime is GMT and 24 hours format.</p>
            <p>3) <i className="fa fa-info-circle"></i> indicates the hover is there. It has some informations or links.</p>
          </div>
          <Row className="my-3">
            <Col>
              <div className="float-end">
                <Row className="align-items-center">
                  <Col sm="3">Search</Col>
                  <Col>
                    <input type="text" className="form-control" value={searchString} onChange={(event) => setSearchString(event.target.value)} />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <SpinnerLoader loading={loading}>
            <EndToEndCrawlTrackerDataTable data={endToEndCrawlStatusData} tableData={filteredData} />
          </SpinnerLoader>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
