import React from 'react';
import { useState, useEffect } from 'react';
import { CardBody, Nav, NavItem, Card, TabContent, TabPane, NavLink } from 'reactstrap';
import Breadcrumbs from '../../Common/BreadCrumb/BreadCrumbs.component';
import NewOrganizationsTable from './DataTables/NewOrganizationsTable.component';
import NewSitesOrSiteGroupTable from './DataTables/NewSitesOrSiteGroupTable.component';
import { getOrganizationTableData, getSitesTableData } from '../../Utils/AdminFeasibilityCheck/getTableData';
import SpinnerLoader from '../../Common/LoadingAnimation/SpinnerLoader.component';

export default function AdminFeasibilityChecks() {
  
  const API_ENDPOINT = '/api/v1/admins/feasibility_checks';

  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState('new_org');
  const [orgData, setOrgData] = useState([]);
  const [siteData, setSiteData] = useState([]);

  useEffect(async () => {
    setLoading(true);
    const newOrgs = await getOrganizationTableData(API_ENDPOINT);
    const newSites = await getSitesTableData(API_ENDPOINT);
    setOrgData(newOrgs);
    setSiteData(newSites);
    setLoading(false);

  }, [])

  return (
    <div className='pt-2'>
      <SpinnerLoader loading={loading}>
        <Breadcrumbs parent={"Feasibility Check"} title={"New Requirements"} />
        <Card>
          <CardBody>
            <Nav tabs>
              <NavItem>
                <NavLink href="#"
                  className={selectedTab === 'new_org' ? 'active' : ''}
                  onClick={() => setSelectedTab('new_org')}>
                  New organizations
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#"
                  className={selectedTab === 'site' ? 'active' : ''}
                  onClick={() => setSelectedTab('site')}>
                  New sitegroups/sites</NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={selectedTab}>
              <TabPane className="fade show" tabId="new_org">
                <NewOrganizationsTable data={orgData} />
              </TabPane>
              <TabPane tabId="site">
                <NewSitesOrSiteGroupTable data={siteData} />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </SpinnerLoader>
    </div>
  )
}
