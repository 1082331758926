import React, {useState, useEffect} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import AsyncSelect from 'react-select/async';
import DatePicker from 'react-datepicker'
import { toast } from 'react-toastify';

export default function PushFailedUrls() {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [dropdownOptions, setDropdownOptions] = useState({})
    const [selectedSite, setSelectedSite] = useState({})
    const [selectedFromDate, setSelectedFromDate] = useState('')
    const [selectedToDate, setSelectedToDate] = useState('')
    const [selectedOption, setSelectedOption] = useState('')
    const [particularDate, setParticularDate] = useState('')
    const [queueName, setQueueName] = useState('')
    const [fieldName, setFieldName] = useState('')

    const today = new Date(Date.now())

    useEffect(() => {
        fetchSiteNames();
    }, [])
    
    function fetchSiteNames() {
        axios.get('/api/v1/internal/dropdown_list_helper')
        .then(res => {
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
            let group = {}, children = []
            group['label'] = <div className="fw-bold col text-black">{key}</div>
            site_names[key].map(item => {
                children.push({ label: item, value: item })
            })
            group['options'] = children;
            site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setDropdownOptions(site_names_options)
        })
        .catch(err => {
            console.error(err?.message || 'Oops something went wrong')
        })
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('/');
    }
      
    const promiseOptions = (inputValue) => {
        if(inputValue.length < 3){
            return null
        } else {
            return new Promise((resolve) => {
            axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
                if (res.status == '200'){
                let site_names = res.data.site_names, site_names_options = []
                for (var key in site_names) {
                    let group = {}, children = []
                    group['label'] = <div className="fw-bold col text-black">{key}</div>
                    site_names[key].map(item => {
                    children.push({ label: item, value: item })
                    })
                    group['options'] = children;
                    site_names_options.push(group)
                }
                site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                setDropdownOptions(site_names_options)
                resolve(site_names_options)
                }
            }).catch(err => {
                    console.log(`err`, err)
                })
            })
        }
    }

    const handleSubmit = () => {
        setLoading(true)
        setErrors({})
        let tempObj = {}
        if (!(selectedSite?.value)){
            tempObj = {...tempObj, site:true}
        }
        if (selectedOption?.length < 1){
            tempObj = {...tempObj, option:true}
        }
        if (particularDate?.toString()?.length < 1){
            tempObj = {...tempObj, crawl_date: true}
        }
        if (selectedFromDate?.toString()?.length < 1){
            tempObj = {...tempObj, from_date: true}
        }
        if (selectedToDate?.toString()?.length < 1){
            tempObj = {...tempObj, to_date: true}
        }
        if (selectedOption == 'queue_custom' && queueName.length < 1){
            tempObj = {...tempObj, queue_name: true}
        }
        setErrors(tempObj)

        if (Object.keys(tempObj)?.length == 0){
            let resultObj = {
                "outer_command": "push_failed_urls",
                "inner_command": "push_failed_urls",
                "nested_inner_command": "push_failed_urls",
                "sitename": selectedSite?.value, 
                "input_type": selectedOption,
                "rss_start_date": formatDate(particularDate),
                "custom_queue_name": selectedOption == 'queue_custom' ? queueName : '',
                "date": {
                    "from": formatDate(selectedFromDate), "to": formatDate(selectedToDate)
                },
                "field_name": fieldName
            }
            axios.post('/api/v1/internal/command_dashboard/push_failed_urls', {
                filter: resultObj
            }).then(res => {
                toast.success(res?.data?.message)
                setLoading(false)
                window.location = `/internal/command_dashboard`
            }).catch(err => {
                toast.error(err?.response?.data?.message)
                setLoading(false)
            })
        }
        else {
            setLoading(false)
        }
    }

    return (
        <Container fluid>
            <Row>
                <Col md={4} className='my-2'>
                    <h6 className='fw-bold'>Outer Command (Non-Editable)</h6>
                    <input className='form-control' value='push_failed_urls'
                        disabled />
                </Col>
                <Col md={4} className='my-2'>
                    <h6 className='fw-bold'>Inner Command (Non-Editable)</h6>
                    <input className='form-control' value='push_failed_urls'
                        disabled />
                </Col>
                <Col md={4} className='my-2'>
                    <h6 className='fw-bold'>Nested Inner Command (Non-Editable)</h6>
                    <input className='form-control' value='push_failed_urls'
                        disabled />
                </Col>
                <Col md={6}>
                    <h6 className='fw-bold'>RSS Crawl start date*</h6>
                    <DatePicker className="form-control my-2" selected={particularDate} dateFormat="yyyy/MM/dd"
                        maxDate={today} onChange={(date) => setParticularDate(date)} />
                    {errors?.crawl_date && 
                        <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                </Col>
                <Col md={12} className='my-2'>
                    <h6 className='fw-bold'>Site Name*</h6>
                    <AsyncSelect cacheOptions defaultOptions={dropdownOptions} name='site_name' 
                        value={selectedSite} loadOptions={promiseOptions} onChange={res => setSelectedSite(res)}
                        placeholder="Select a site name" />
                    {errors?.site && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                    <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
                </Col>
                <Col md={12} className='my-2' >
                    <h6 className='fw-bold'>
                        Select site for queue name or add custom queue
                    </h6>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="queue_name"
                            checked={selectedOption == 'default_rss_queue'} 
                            onChange={() => setSelectedOption('default_rss_queue')} id="queue_name1" />
                        <label className="form-check-label" htmlFor="queue_name1">
                            Default rss queue
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="queue_name" 
                            checked={selectedOption == 'queue_custom'}
                            onChange={() => setSelectedOption('queue_custom')} id="queue_name2" />
                        <label className="form-check-label" htmlFor="queue_name2">
                            Add custom queue
                        </label>
                    </div>
                    {errors?.option && <h6 className='fw-bold text-danger my-2'> You must select an option </h6>}
                </Col>
                {selectedOption?.length > 0 && <Col md={12} className='my-2' >
                    {selectedOption == 'queue_custom' && <Row>
                        <Col md={12}>
                            <h6 className='fw-bold'>Enter Queue Name</h6>
                            <input type='text' placeholder='Enter Custom Queue Name' className='form-control' 
                                value={queueName} onChange={(e) => setQueueName(e.target.value)} />
                            {errors?.queue_name && <h6 className='fw-bold text-danger my-2'> Queue Name is mandatory </h6>}
                        </Col>
                    </Row>}
                </Col>}
                <Col md={6} className='my-2'>
                    <h6 className='fw-bold'>From CrawlDate</h6>
                    <DatePicker className="form-control my-2" selected={selectedFromDate} dateFormat="yyyy/MM/dd"
                        maxDate={today} onChange={(date) => setSelectedFromDate(date)} />
                    {errors?.from_date && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                </Col>    
                <Col md={6} className='my-2'>
                    <h6 className='fw-bold'>To CrawlDate</h6>
                    <DatePicker className="form-control my-2" selected={selectedToDate} dateFormat="yyyy/MM/dd"
                        maxDate={today} onChange={(date) => setSelectedToDate(date)} />
                    {errors?.to_date && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                </Col> 
                <Col md={12} className='my-2'>
                    <h6 className='fw-bold'>Field name (In case of CSV and LDJSON)</h6>
                    <input type='text' placeholder='Enter Field Name' className='form-control' 
                        value={fieldName} onChange={(e) => setFieldName(e.target.value)} />
                </Col>
                <Col md={6} className='my-2'>
                    <button className='btn btn-success' onClick={handleSubmit}
                        disabled={loading} style={{margin: '25px auto', width: '100%'}}> Submit </button>
                </Col>
            </Row>
        </Container>
    )
}
