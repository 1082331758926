// Common helper method created for populating clusterHealthApiUrl, clusterNodeApiUrl, shardAllocationApiUrl, clusterIndicesApiUrl
 /**
 * 
 * @param {string} endpoint // Name of endpoint eg. clusterHealthApiUrl
 * @param {string} BASE_URI // Host name e.g https://localhost:2606
 * @returns end point URL based on host name for kibana v8 which is dynamically fetched from backend 
 * 
 * example usage:
 * generateUrl("clusterHealthApiUrl", "https://localhost:2606") returns a string 'https://localhost:2606/_cluster/health?pretty'
 * generateUrl("clusterHealthApiUrl", "") returns '#'
 * generateUrl("", "https://localhost:2606") returns '#'
 * generateUrl("", "") returns '#'
 */

// Helper method to return URL based on endpoint name and host name
const generateUrl = (endpoint, BASE_URI) => {
  // If no base URL is passed then return #
  if (!BASE_URI) {
    return '#';
  }
  //return the appropriate URL based on the endpoint name
  switch (endpoint) {
    case 'clusterHealthApiUrl':
      return `${BASE_URI}/_cluster/health?pretty`;
    case 'clusterNodeApiUrl':
      return `${BASE_URI}/_cat/nodes?v`;
    case 'shardAllocationApiUrl':
      return `${BASE_URI}/_cat/allocation?v`;
    case 'clusterIndicesApiUrl':
      return `${BASE_URI}/_cat/indices?v`;
    default:
      return '#';
  }
};

export default generateUrl;