import React from 'react';
import { Container } from 'reactstrap';
import Breadcrumbs from '../../Common/BreadCrumb/BreadCrumbs.component';
import BannerNotifications from '../../Common/BannerNotification/BannerNotifications.component';
import SitegroupDetailsCard from './Cards/SitegroupDetailsCard.component';
import SitesContextProvider from './Context/SitesContext';
import SitesInfoCard from './Cards/SitesInfoCard.component';
import SitesCrawlGraphCard from './Cards/SitesCrawlGraphCard.component';
export default function Sites({ banner_notifications }) {
  return (
    <div>
      <Container fluid>
        <Breadcrumbs parent="Sites" title="Site Status" />
        <BannerNotifications notificationsArray={banner_notifications} />
        <SitesContextProvider>
          <SitegroupDetailsCard />
          <SitesInfoCard />
          <SitesCrawlGraphCard />
        </SitesContextProvider>
      </Container>
    </div>
  )
}
