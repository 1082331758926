import React, {useState, useEffect, Fragment} from 'react'
import { useParams } from "react-router-dom";
import Breadcrumb from '../../../../global/Breadcrumb';
import { Card, CardBody, CardHeader, Container, Row, Col, Button, Modal, ModalBody, ModalFooter,
  ModalHeader, CardFooter } from 'reactstrap'
import axios from 'axios'
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';

import ChangePasswordModal from './ChangePasswordModal';
import EditProfileModal from './EditProfileModal';
import ChangeOrganizationModal from './ChangeOrganizationModal';



export default function Index(){
  let { profile_id } = useParams();

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [orgList, setOrgList] = useState([])
  const [toggleData, setToggleData] = useState(true)

  const [changePasswordOpen, setChangePasswordOpen] = useState(false)
  const [editProfileOpen, setEditProfileOpen] = useState(false)
  const [changeOrganizationOpen, setChangeOrganizationOpen] = useState(false)


  useEffect(() => {
    axios.get(`/api/v1/profiles/${profile_id}?page_type=client&controller_name=profiles`).then(res => {
      setData(res.data.profile)
      setLoading(false)
    }).catch(err => {
      toast.error(err.response.data.message)
      setLoading(false)
    })
  }, [toggleData])

  useEffect(() => {
    axios.get(`/api/v1/default_org?id=${profile_id}?page_type=client&controller_name=default_org`).then(res => {
      setOrgList(res.data.org_list)
    })
  }, [toggleData])

  return(
    <Container fluid>
      <Breadcrumb parent="Profile Details" title="User Profile" />
      {
        loading 
        ?
        <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
        :
        <Fragment>
           <ChangePasswordModal isOpen={changePasswordOpen} setModal={setChangePasswordOpen} profile_id={profile_id}  /> 
           <EditProfileModal isOpen={editProfileOpen} setModal={setEditProfileOpen} profile_id={profile_id} data={data} toggleData={toggleData} setToggleData={setToggleData}/> 
           <ChangeOrganizationModal isOpen={changeOrganizationOpen} setModal={setChangeOrganizationOpen} orgList={orgList} profile_id={profile_id} data={data} toggleData={toggleData} setToggleData={setToggleData}/>
          <Card className='mb-4'>
            <CardBody>
              <div className='d-flex justify-content-between mb-4'>
                <h5 className='fw-lighter'>
                  {localStorage.getItem("authEmail")}
                </h5>
                <div>
                  <button className='btn btn-primary mx-2' onClick={()=> setChangePasswordOpen(true)} >Change Password</button>
                  <button className='btn btn-primary mx-2' onClick={()=> setEditProfileOpen(true)}>Edit Profile</button>
                </div>
              </div>
              <div className='px-5 py-2'>
                <h6>
                  <b>Name: </b> {data?.name}
                </h6>
                <h6>
                  <b>Phone number: </b> {data?.phone_number || ""}
                </h6>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className='d-flex justify-content-between mb-4'>
                <h5 className='fw-lighter'>
                  Current organization
                </h5>
                <div>
                  <button className='btn btn-primary' onClick={()=> setChangeOrganizationOpen(true)}>Change Current Organization</button>
                </div>
              </div>
              <div className='p-5'>
                <h6>
                  <b>Organization Name: </b> {data?.organization_name}
                </h6>
                <h6>
                  <b>Country: </b> {data?.country}
                </h6>
              </div>
            </CardBody>
          </Card>
        </Fragment>
      }
    </Container>
  )
}
