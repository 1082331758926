import React from 'react'
import { useState, useEffect, useMemo } from 'react';
import { Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, Label, Input } from 'reactstrap';
import { Form, FormGroup } from 'react-bootstrap';
import ErrorMessageModal from '../Modal/ErrorMessageModal.component';
import axios from 'axios';
import { toast } from 'react-toastify';
export default function EditMembersDetailsButton({ color, size, children, user, organizationName, data }) {

   // Default modal data
   const defaultModalData = useMemo(() => ({
    first_name: '',
    last_name: '',
    email: '',
    designation: '',
    accessible_modules: [],
    notification_preferences: [],
  }), []);

  // Error check
  const [errors, setErrors] = useState({});
  const [memberModalData, setMemberModalData] = useState(defaultModalData);
  // Modal State
  const [modalOpen, setModalOpen] = useState(false);
  // Pop-up message for modal
  const [popupMessage, setPopupMessage] = useState('');
  // Error Modal
  const [errorModal, setErrorModal] = useState(false);

  useEffect(() => {
    if (modalOpen && data) {
      axios.get(`/api/v1/organizations/${organizationName}/members/${data.id}/edit`).then(res => {
        setMemberModalData({
          first_name: res.data?.member?.first_name, last_name: res.data?.member?.last_name,
          email: res.data?.member?.email,
          designation: res.data?.member?.designation, invited_by_id: res.data?.member.invited_by_id,
          accessible_modules: res.data?.member?.accessible_modules,
          notification_preferences: res.data?.member?.notification_preferences
        });
      }).catch(err => {
        toast.error(err.response.data.message);
      })
    }
  }, [modalOpen, data]);

  // Helper function to open and close modal
  function onModalOpenHandler() {
    resetFields();
    setModalOpen(!modalOpen);
  };

  // Helper function to open and close the error modal
  function onErrorModalOpenHandler(){
    setErrorModal(!errorModal);
  }

  // Helper function to reset form fields
  function resetFields() {
    setMemberModalData(defaultModalData);
  }

  // Input change handler
  function onInputChangeHandler(event) {
    const { name, value } = event.target;
    setMemberModalData({ ...memberModalData, [name]: value });
  };

  // Helper function to get pre-set notification preferences
  function handleNotificationPreferences(memberHash, accessible_module) {
    let notificationPreferences = memberHash.notification_preferences
    if (accessible_module === "data_access") {
      notificationPreferences = notificationPreferences.filter(item => item !== "redmine")
    }
    else if (accessible_module === "billing") {
      notificationPreferences = notificationPreferences.filter(item => item !== "invoice")
    }
    return notificationPreferences;
  };

  // Helper function to handle accessible modules
  function onAccessibleModulesCheckHandler(status, element) {
    let temp = { ...memberModalData }
    let notificationPreferences = handleNotificationPreferences(temp, element)
    if (status) {
      temp = { ...temp, accessible_modules: [...temp.accessible_modules, element], notification_preferences: notificationPreferences }
      setMemberModalData(temp)
    }
    else {
      axios.post(`/api/v1/organizations/${organizationName}/members/${data.id}/can_edit_accessible_modules`, {
        accessible_modules: [element]
      }).then(res => {
        if (res?.data?.can_edit_accessible_modules) {
          temp = { ...temp, accessible_modules: temp.accessible_modules.filter(x => x != element), notification_preferences: notificationPreferences }
          setMemberModalData(temp)
        } else {
          // toast.error(res?.data?.popup_message);
          setPopupMessage(res?.data?.popup_message);
          // setModalOpen(false);
          setErrorModal(true);
        }
      }).catch(err => {
        toast.error(err?.response.data.message);
      })
    }
  };

  // Helper function to set notification preferences
  function onNotificationPreferencesCheckHandler(status, element) {
    let temp = { ...memberModalData }
    if (status) {
      temp = { ...temp, notification_preferences: [...temp.notification_preferences, element] }
      setMemberModalData(temp)
    }
    else {
      axios.post(`/api/v1/organizations/${organizationName}/members/${data.id}/can_edit_notification_preferences`, {
        notification_preferences: [element]
      }).then(res => {
        if (res.data.can_edit_notification_preferences) {
          temp = { ...temp, notification_preferences: temp.notification_preferences.filter(x => x != element) }
          setMemberModalData(temp);
        } else {
          // toast.error(res.data?.popup_message);
          setPopupMessage(res?.data?.popup_message);
          // setModalOpen(false);
          setErrorModal(true);
        }
      }).catch(err => {
        toast.error(err.response.data.message);
      })
    }
  };

  // Helper function to edit user's detials
  function onEditUserDetailsButtonClickHandler() {
    setErrors({})
    if (memberModalData?.first_name?.length === 0 || memberModalData?.last_name?.length === 0 ||
      memberModalData?.email?.length === 0 || memberModalData?.designation?.length === 0 || memberModalData?.accessible_modules?.length === 0) {
      let tempObj = {}
      if (memberModalData?.first_name?.length === 0) {
        tempObj.first_name = 'First Name is required'
      }
      if (memberModalData?.last_name?.length === 0) {
        tempObj.last_name = 'Last Name is required'
      }
      if (memberModalData?.email?.length === 0) {
        tempObj.email = 'Email is required'
      }
      if (memberModalData?.designation?.length === 0) {
        tempObj.designation = 'Role is required'
      }
      if (memberModalData?.accessible_modules?.length === 0) {
        tempObj.accessible_modules = 'Atleast one Accessible module is required'
      }
      setErrors(tempObj);
    }
    else {
      // console.log("memberModalData", memberModalData);
      console.log(data.id);
      axios.put(`/api/v1/organizations/${organizationName}/members/${data.id}`, {
        member: memberModalData
      }).then(res => {
        toast.success(res.data.message);
        setModalOpen(false);
        setTimeout(()=>{location.reload();},650);
      }).catch(err => {
        toast.error(err.response.data.message);
      })
    }
  };

  // Available options for roles
  const availableRoles = useMemo(() => [
    {
      id: 1,
      optionLabel: "CXO",
      value: "CXO",
    },
    {
      id: 2,
      optionLabel: "CTO",
      value: "CTO",
    },
    {
      id: 3,
      optionLabel: "Product Head",
      value: "Product Head",
    },
    {
      id: 4,
      optionLabel: "Technical Head",
      value: "Technical Head",
    },
    {
      id: 5,
      optionLabel: "Business owner",
      value: "Business owner",
    },
    {
      id: 6,
      optionLabel: "Analyst",
      value: "Analyst",
    },
    {
      id: 7,
      optionLabel: "Other",
      value: "Other",
    },
  ], []);

  return (
    <>
      <div>
        <Modal funk={"true"} isOpen={modalOpen} toggle={onModalOpenHandler} size='lg'>
          <ModalHeader toggle={onModalOpenHandler}>
            Edit Member: {organizationName?.replaceAll("_", " ")}
          </ModalHeader>
          <ModalBody className='px-5'>
            <div className='row mb-3 align-items-center'>
              <Label className='col-sm-2 h6'>First Name </Label>
              <div className='col-sm-10'>
                <Input
                  className='form-control'
                  type='text'
                  name="first_name"
                  value={memberModalData?.first_name}
                  onChange={onInputChangeHandler} />
                {errors.first_name && <div className='form-text text-danger'> {errors.first_name} </div>}
              </div>
            </div>

            <div className='row mb-3 align-items-center'>
              <Label className='col-sm-2 h6' >Last Name </Label>
              <div className='col-sm-10'>
                <Input
                  className='form-control'
                  type='text'
                  name="last_name"
                  value={memberModalData?.last_name}
                  onChange={onInputChangeHandler} />
                {errors.last_name && <div className='form-text text-danger'> {errors.last_name} </div>}
              </div>
            </div>

            <div className='row mb-3 align-items-center'>
              <Label className='col-sm-2 h6' >Email </Label>
              <div className='col-sm-10'>
                <Input
                  className='form-control'
                  type='email'
                  name="email"
                  value={memberModalData?.email}
                  onChange={onInputChangeHandler} />
                {errors.email && <div className='form-text text-danger'> {errors.email} </div>}
              </div>
            </div>

            <div className='row mb-3 align-items-center'>
              <Label className='col-sm-2 h6' >Designation </Label>
              <div className='col-sm-10'>
                <Input
                  className='form-control show-arrow'
                  type='select'
                  name="designation"
                  onChange={onInputChangeHandler} >
                  <option defaultValue={memberModalData?.designation} hidden>{memberModalData?.designation}</option>
                  {availableRoles.map(role => (
                    <option name={role.optionLabel} key={role.id} value={role.value}>{role.optionLabel}</option>
                  ))}
                </Input>
                {errors.designation && <div className='form-text text-danger'> {errors.designation} </div>}
              </div>
            </div>
            <div className='row mb-3 align-items-center'>
              <Label className='col-sm-2 h6' >Accessible Modules </Label>
              <FormGroup className='col-sm-10'>
                <Input
                  type="checkbox"
                  checked={memberModalData?.accessible_modules?.includes("data_access")}
                  onChange={(event) => onAccessibleModulesCheckHandler(event.target.checked, 'data_access')} />
                {' '}
                <Label className='h6'>
                  Data Access
                </Label>
                {' '}
                <br />
                <Input
                  type="checkbox"
                  checked={memberModalData?.accessible_modules?.includes("billing")}
                  onChange={(event) => onAccessibleModulesCheckHandler(event.target.checked, 'billing')} />
                {' '}
                <Label className='h6'>
                  Billing
                </Label>
                {' '}
                <br />
                <Input
                  type="checkbox"
                  checked={memberModalData?.accessible_modules?.includes("user_management")}
                  onChange={(event) => onAccessibleModulesCheckHandler(event.target.checked, 'user_management')} />
                {' '}
                <Label className='h6'>
                  User Management
                </Label>
                {' '}
              </FormGroup>
              {errors.accessible_modules && <div className='form-text text-danger'> {errors.accessible_modules} </div>}
            </div>
            <div className='row mb-3 align-items-center'>
              <Label className='col-sm-2 h6' >Notification Preferences </Label>
              <FormGroup className='col-sm-10'>
                <Input
                  type="checkbox"
                  disabled={!memberModalData?.accessible_modules?.includes('data_access')}
                  checked={memberModalData?.accessible_modules?.includes('data_access') && memberModalData?.notification_preferences?.includes("redmine")}
                  onChange={(event) => onNotificationPreferencesCheckHandler(event.target.checked, 'redmine')} />
                {' '}
                <Label className='h6'>
                  Redmine
                </Label>
                {' '}
                <br />
                <Input
                  type="checkbox"
                  disabled={!memberModalData?.accessible_modules?.includes('billing')}
                  checked={memberModalData?.accessible_modules?.includes('billing') && memberModalData?.notification_preferences?.includes("invoice")}
                  onChange={(event) => onNotificationPreferencesCheckHandler(event.target.checked, 'invoice')} />
                {' '}
                <Label className='h6'>
                  Invoice
                </Label>
                {' '}
              </FormGroup>
              {errors.notification_preferences && <p className='text-danger'> {errors.notification_preferences} </p>}
            </div>
          </ModalBody>
          <ModalFooter className='px-5'>
            <Button color='light' onClick={onModalOpenHandler}>Cancel</Button>
            <Button color='success' onClick={onEditUserDetailsButtonClickHandler}>Update</Button>
          </ModalFooter>
        </Modal>
        {
          errorModal && (<ErrorMessageModal messageContent={popupMessage} errorModalOpenHandler={onErrorModalOpenHandler} />)
        }
      </div>
      <Button color={color} size={size} onClick={onModalOpenHandler} >{children}</Button>
    </>
  )
}
