import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";
import moment from "moment";
import axios from "axios";
import DatePicker from "react-datepicker";
import AsyncSelect from 'react-select/async';

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function ScraperApiCrawlTracker() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const tabNameFromSearchUrl = new URLSearchParams(search).get("filter[tab_name]");
  const tabNameFromUrl = window.location.hash.substr(1);
  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[site_name]"),
    fetchStatus: new URLSearchParams(search).get("filter[fetch_status]"),
    requestFrom: new URLSearchParams(search).get("filter[request_from]"),
    fromDate: new URLSearchParams(search).get("filter[from]"),
    toDate: new URLSearchParams(search).get("filter[to]"),
    tabName: tabNameFromSearchUrl ? tabNameFromSearchUrl : tabNameFromUrl
  }
  const [siteName, setSiteName] = useState({label: 'All', value: '*'});
  const [siteNameOptions, setSiteNameOptions] = useState([]);
  const [fetchStatus, setFetchStatus] = useState({label: 'All', value: '*'});
  const [requestFrom, setRequestFrom] = useState({label: 'All', value: '*'});
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(7, 'days')));
  const now = new Date();
  const [toDate, setToDate] = useState(now);
  const [activeTab, setActiveTab] = useState(tabNameFromUrl ? tabNameFromUrl : 'total_success_and_failed_counts_by_site_wise');

  const fetchStatusOptions = [
    { label: 'All', value: '*' },
    { label: 'Success', value: 'success' },
    { label: 'Failure', value: 'failure' }
  ]

  const requestFromOptions = [
    { label: 'All', value: '*' },
    { label: 'Feedcrawl', value: 'feedcrawl' },
    { label: 'RSS', value: 'RSS' }
  ]

  const [UrlParams, setUrlParams] = useState({
    siteName: "*",
    fetchStatus: '*',
    requestFrom: '*',
    fromDate: new Date(moment().subtract(7, 'days')),
    toDate: now,
    tabName: "total_success_and_failed_counts_by_site_wise"
  })

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let site_names = res.data.site_names, site_names_options = []
        for (var key in site_names) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
            site_names[key].map(item => {
              children.push({ label: item, value: item })
            })
          group['options'] = children;
          site_names_options.push(group)
        }
        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: '*' }] })
        setSiteNameOptions(site_names_options);
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      });
  }, []);

  function takeKibanaTime(from_date, to_date){
    var startDate = moment(from_date);
    var endDate = moment(to_date);
    var daysCount = endDate.diff(startDate, 'days');
    return (daysCount == 0) ? "now-24h" : `now-${daysCount}d`
  }

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.siteName && searchParams.siteName != siteName.value) {
      setSiteName({ label: searchParams.siteName  == '*' ? 'All' :  searchParams.siteName, value: searchParams.siteName });
    }
    if (searchParams.fetchStatus && searchParams.fetchStatus != fetchStatus.value) {
      setFetchStatus({ label: searchParams.fetchStatus  == '*' ? 'All' :  searchParams.fetchStatus, value: searchParams.fetchStatus });
    }
    if (searchParams.requestFrom && searchParams.requestFrom != requestFrom.value) {
      setRequestFrom({ label: searchParams.requestFrom  == '*' ? 'All' :  searchParams.requestFrom, value: searchParams.requestFrom });
    }
    if (searchParams.tabName && searchParams.tabName != activeTab) {
      setActiveTab(searchParams.tabName);
    }
    if (searchParams.fromDate && searchParams.fromDate != fromDate) {
      setFromDate(new Date(searchParams.fromDate));
    }
    if (searchParams.toDate && searchParams.toDate != toDate) {
      setToDate(new Date(searchParams.toDate));
    }
    if (commit == 'Get Data') {
      setUrlParams(searchParams)
    }
  }

  function totalSuccessAndFailedCountsBySiteWiseKibanaUrl(){
    var from_time_val = takeKibanaTime(UrlParams.fromDate, UrlParams.toDate)

    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=line&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${from_time_val}',mode:quick,to:now))&_a=(filters:!(),linked:!f,query:(language:lucene,query:(query_string:(analyze_wildcard:!t,query:'custom:++%22scraper_api_crawl_tracker%22+AND+site_name:+%22${UrlParams.siteName}%22+AND+fetch_status:+%22${UrlParams.fetchStatus}%22+AND+request_from:+%22${UrlParams.requestFrom}%22'))),uiState:(),vis:(aggs:!((id:'1',params:(),schema:metric,type:count),(id:'2',params:(field:site_name.raw,order:desc,orderBy:'1',size:20),schema:segment,type:terms),(id:'3',params:(field:fetch_status.raw,order:desc,orderBy:'1',size:5),schema:group,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Scraper+API+-+Total+Success+and+Failed+Counts+By+Site+Wise',type:histogram))`
  }

  function totalRetryCountsBySiteWiseKibanaUrl(){
    var from_time_val = takeKibanaTime(UrlParams.fromDate, UrlParams.toDate)

    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=line&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&embed=true&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${from_time_val}',mode:quick,to:now))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'custom:%20%20%22scraper_api_crawl_tracker%22%20AND%20site_name:%20%22${UrlParams.siteName}%22%20AND%20fetch_status:%20%22${UrlParams.fetchStatus}%22%20AND%20request_from:%20%22${UrlParams.requestFrom}%22')),uiState:(),vis:(aggs:!((id:'1',params:(field:retry_count),schema:metric,type:sum),(id:'2',params:(field:site_name.raw,order:desc,orderBy:'1',size:20),schema:segment,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Scraper%20API%20-%20Total%20Retry%20Counts%20By%20Site%20Wise',type:histogram))`
  }

  function totalSuccessAndFailedCountsByDateWiseKibanaUrl(){
    var from_time_val = takeKibanaTime(UrlParams.fromDate, UrlParams.toDate)

    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=line&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${from_time_val}',mode:quick,to:now))&_a=(filters:!(),linked:!f,query:(language:lucene,query:(query_string:(analyze_wildcard:!t,query:'custom:++%22scraper_api_crawl_tracker%22+AND+site_name:+%22${UrlParams.siteName}%22+AND+fetch_status:+%22${UrlParams.fetchStatus}%2220AND+request_from:+%22${UrlParams.requestFrom}%22'))),uiState:(),vis:(aggs:!((id:'1',params:(),schema:metric,type:count),(id:'2',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:d,min_doc_count:1),schema:segment,type:date_histogram),(id:'3',params:(field:fetch_status.raw,order:asc,orderBy:'1',row:!t,size:5),schema:split,type:terms),(id:'4',params:(field:site_name.raw,order:desc,orderBy:'1',size:50),schema:group,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Scraper+API+-+Total+Success+and+Failed+Counts+By+Date+Wise',type:histogram))`
  }

  function totalRetryCountsByDateWiseKibanaUrl(){
    var from_time_val = takeKibanaTime(UrlParams.fromDate, UrlParams.toDate)

    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=line&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${from_time_val}',mode:quick,to:now))&_a=(filters:!(),linked:!f,query:(language:lucene,query:(query_string:(analyze_wildcard:!t,query:'custom:++%22scraper_api_crawl_tracker%22+AND+site_name:+%22${UrlParams.siteName}%22+AND+fetch_status:+%22${UrlParams.fetchStatus}%2220AND+request_from:+%22${UrlParams.requestFrom}%22'))),uiState:(),vis:(aggs:!((id:'1',params:(field:retry_count),schema:metric,type:sum),(id:'2',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:d,min_doc_count:1),schema:segment,type:date_histogram),(id:'3',params:(field:site_name.raw,order:desc,orderBy:'1',size:50),schema:group,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Scraper+API+-+Total+Retry+Counts+By+Date+Wise',type:histogram))`
  }

  function apiRequestCountsByRssAndFeedcrawlKibanaUrl(){
    var from_time_val = takeKibanaTime(UrlParams.fromDate, UrlParams.toDate)

    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=line&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${from_time_val}',mode:quick,to:now))&_a=(filters:!(),linked:!f,query:(language:lucene,query:(query_string:(analyze_wildcard:!t,query:'custom:++%22scraper_api_crawl_tracker%22+AND+site_name:+%22${UrlParams.siteName}%22+AND+fetch_status:+%22${UrlParams.fetchStatus}%2220AND+request_from:+%22${UrlParams.requestFrom}%22'))),uiState:(spy:(mode:(fill:!f,name:!n))),vis:(aggs:!((id:'1',params:(),schema:metric,type:count),(id:'2',params:(field:site_name.raw,order:desc,orderBy:'1',size:30),schema:segment,type:terms),(id:'3',params:(field:request_from.raw,order:desc,orderBy:'1',size:1000),schema:group,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Scraper+api+request+counts+by+rss+and+feedcrawl',type:histogram))`
  }

  const handleGetData = () => {
    window.location.href = `/internal/scraper_api_crawl_tracker?filter[site_name]=${siteName.value}&filter[fetch_status]=${fetchStatus.value}&filter[request_from]=${requestFrom.value}&filter[from]=${moment(fromDate).format('YYYY/MM/DD')}&filter[to]=${moment(toDate).format('YYYY/MM/DD')}&filter[tab_name]=${activeTab}&commit=${'Get Data'}`
  }

  const promiseOptions = (inputValue) => {
    if(inputValue.length < 3){
      return null
    } else {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200'){
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
              site_names[key].map(item => {
                children.push({ label: item, value: item })
              })
              group['options'] = children;
              site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setSiteNameOptions(site_names_options)
            resolve(site_names_options)
          }
        }).catch(err => {
          console.log(`err`, err)
        })
      })
    }
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/scraper_api_crawl_tracker">Scraper API Crawl Tracker</a> : 'Scraper API Crawl Tracker'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                Search
                </BreadcrumbItem>}
          </Breadcrumb>
        </Col>
      </Row>
    </div>

    <Card>
      <CardBody>
        <Row>
          <Col sm="8">
            <label className="fw-bold">Site Name</label>
            <AsyncSelect cacheOptions defaultOptions={siteNameOptions} name='site_name' 
                value={siteName} loadOptions={promiseOptions} onChange={res => setSiteName(res)}
                placeholder="Select a site name" />
          </Col>
          <Col sm="4">
            <label className="fw-bold">Fetch Status</label>
            <Select
              value={fetchStatus}
              options={fetchStatusOptions}
              onChange={option => setFetchStatus(option)} />
          </Col>
        </Row>
        <Row className="align-items-end mt-2">
          <Col sm="4">
            <label className="fw-bold">From Date</label>
            <DatePicker
              className="form-control"
              selected={new Date(fromDate)}
              dateFormat={"YYY/MM/dd"}
              onChange={date => setFromDate(date)} />
          </Col>
          <Col sm="4">
            <label className="fw-bold">To Date</label>
            <DatePicker
              className="form-control"
              selected={new Date(toDate)}
              dateFormat={"YYY/MM/dd"}
              onChange={date => setToDate(date)} />
          </Col>
          <Col sm="4">
            <label className="fw-bold">Request From</label>
            <Select
              value={requestFrom}
              options={requestFromOptions}
              onChange={option => setRequestFrom(option)} />
          </Col>
        </Row>
        <Row className="align-items-end mt-4">
          <Col sm="4">
            <label className="fw-bold">&nbsp;</label>
            <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
          </Col>
        </Row>
      </CardBody>
    </Card>
    <Card>
      <CardBody>
        <div className="mb-1">
          <b>NOTE:</b>
          If the graph is not shown after your form submission then you should do either maximize its size(the option is available on the right side of graph) or minimize the sidebar.
        </div>
        <div className="mb-1">
          <b>NOTE:</b>
          This data is coming from ES and we log only 5% of these records.
        </div>
        <Nav tabs>
          <NavItem>
            <NavLink className={activeTab == 'total_success_and_failed_counts_by_site_wise' ? 'active' : ''} onClick={() => setActiveTab('total_success_and_failed_counts_by_site_wise')}>
              Total Success <br /> & Failed Counts <br /> By Site Wise
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab == 'total_retry_counts_by_site_wise' ? 'active' : ''} onClick={() => setActiveTab('total_retry_counts_by_site_wise')}>
              Total Retry <br /> Counts By <br /> Site Wise
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab == 'total_success_and_failed_counts_by_date_wise' ? 'active' : ''} onClick={() => setActiveTab('total_success_and_failed_counts_by_date_wise')}>
              Total Success <br /> & Failed Counts <br /> By Date Wise
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab == 'total_retry_counts_by_date_wise' ? 'active' : ''} onClick={() => setActiveTab('total_retry_counts_by_date_wise')}>
              Total Retry <br /> Counts By <br /> Date Wise
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab == 'api_request_counts_by_rss_and_feedcrawl' ? 'active' : ''} onClick={() => setActiveTab('api_request_counts_by_rss_and_feedcrawl')}>
              Scraper Api <br /> Request Counts <br /> By Rss & Feedcrawl
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={'total_success_and_failed_counts_by_site_wise'}>
            <div className="panel-body">
              <center>
                <span className="fw-bold">Total Success & Failed Counts By Site Wise</span><br />
                <a target="_blank" className="link-primary" href={totalSuccessAndFailedCountsBySiteWiseKibanaUrl()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={totalSuccessAndFailedCountsBySiteWiseKibanaUrl() + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
            </div>
          </TabPane>
          <TabPane tabId={'total_retry_counts_by_site_wise'}>
            <div className="panel-body">
              <center>
                <span className="fw-bold">Total Retry Counts By Site Wise</span><br />
                <a target="_blank" className="link-primary" href={totalRetryCountsBySiteWiseKibanaUrl()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={totalRetryCountsBySiteWiseKibanaUrl() + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
            </div>
          </TabPane>
          <TabPane tabId={'total_success_and_failed_counts_by_date_wise'}>
            <div className="panel-body">
              <center>
                <span className="fw-bold">Total Success & Failed Counts By Date Wise</span><br />
                <a target="_blank" className="link-primary" href={totalSuccessAndFailedCountsByDateWiseKibanaUrl()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={totalSuccessAndFailedCountsByDateWiseKibanaUrl() + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
            </div>
          </TabPane>
          <TabPane tabId={'total_retry_counts_by_date_wise'}>
            <div className="panel-body">
              <center>
                <span className="fw-bold">Total Retry Counts By Date Wise</span><br />
                <a target="_blank" className="link-primary" href={totalRetryCountsByDateWiseKibanaUrl()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={totalRetryCountsByDateWiseKibanaUrl() + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
            </div>
          </TabPane>
          <TabPane tabId={'api_request_counts_by_rss_and_feedcrawl'}>
            <div className="panel-body">
              <center>
                <span className="fw-bold">Scraper api request counts by rss and feedcrawl</span><br />
                <a target="_blank" className="link-primary" href={apiRequestCountsByRssAndFeedcrawlKibanaUrl()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={apiRequestCountsByRssAndFeedcrawlKibanaUrl() + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
            </div>
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  </Container>
}

