import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import axios from "axios";
import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";

export default function Index() {
  const [blockingSolutionConfigFiles, setBlockingSolutionConfigFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults]= useState([]);

  const tableColumns = [
    { 
      name: 'File Name', 
      selector: 'file_name', 
      sortable: true,
      cell: (row, index) => (
        <>
          <p>{row.base_name}</p>
        </>
        )
    },
    { 
      name: 'File Download', 
      selector: '', 
      sortable: true,
      cell: (row, index) => (
        <button onClick={handleDownloadFile(row)} 
          className='btn text-primary'> Download a file </button>
        )
    },
  ]

  const handleDownloadFile = (row) => (event) => {
    event.preventDefault();
    axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem("authToken")}`;
    axios.defaults.headers["Content-Type"] = `application/txt`;
    axios.defaults.headers["Accept"] = `application/txt`;
    axios({
      url: `/api/v1/internal/blocking_solution_config_files/download_file?filename=${row.file_name}`,
      method: 'GET',
      responseType: 'blob'
    })
      .then((res) => {
        const url_to_download = window.URL.createObjectURL(new Blob([res.data]));
        const a_tag = document.createElement('a');
        a_tag.href = url_to_download;
        a_tag.setAttribute('download', row.base_name);
        document.body.appendChild(a_tag);
        a_tag.click();
      });
    axios.defaults.headers["Content-Type"] = `application/json`;
    axios.defaults.headers["Accept"] = `application/json`;
  }

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true)
    axios.get(`/api/v1/internal/blocking_solution_config_files`)
      .then(res => {
        setBlockingSolutionConfigFiles(res.data.blocking_solution_config_files);
        setSearchResults(res.data.blocking_solution_config_files);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  const handleSearch = event =>{
    if(event.target.value == ''){
      setSearchResults(blockingSolutionConfigFiles);
    }
    else{
      setSearchResults(searchFromData(event.target.value));
    }
    setSearchText(event.target.value);
  }

  function searchFromData(value){
    var search_result = blockingSolutionConfigFiles.filter(item => item.file_name.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              Blocking Solution Config Files
            </BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
    </div>

    <Card>
      <CardBody>
        <Row className="align-items-end mt-2">
          <Col>
            <div className="float-end mb-3">
              <Row className="align-items-center">
                <Col sm="3">Search</Col>
                <Col>
                  <input type="text" className="form-control" value={searchText} onChange={handleSearch} />
                </Col>
              </Row>
            </div>
          </Col>
          <DataTable
            columns={tableColumns}
            data={searchResults}
            noHeader
            pagination
            progressPending={loading}
          />
        </Row>
      </CardBody>
    </Card>
  </Container>
}

