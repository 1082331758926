import React from 'react';
import { Fragment, forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Row, Col, Form, Label, Input, Table } from 'reactstrap';
import DatePicker from "react-datepicker";
import Dropzone from 'react-dropzone-uploader';
import axios from 'axios';
import XLSX from 'xlsx';
import { toast } from 'react-toastify';

const SchemaDetails = forwardRef(({ setSchemaDetails, schemaDetails,
  nextStep, environment }, ref) => {
  const [freqSelection, setFreqSelection] = useState(schemaDetails.freqSelection || 'none');
  const [tableData, setTableData] = useState({
    'row01': { comments: '' },
    'row02': {},
    'row03': { sampleValue: '', comments: '' },
    'row1': {
      fieldName: '', datatype: '', isMandatory: 'no', defaultValue: '',
      sampleValue: '', comments: ''
    }
  });
  const [newRows, setNewRows] = useState(['row1']);
  const [sgName, setSgName] = useState(schemaDetails.sgName || '');
  const [dataformat, setDataformat] = useState(schemaDetails.dataformat || 'none');
  const [crawlType, setCrawlType] = useState(schemaDetails.crawlType || 'none');
  const [dataDelivery, setDataDelivery] = useState(schemaDetails.dataDelivery || 'api');
  const [freqWeekly, setfreqWeekly] = useState(schemaDetails.freqWeekly || 'none');
  const [freqMonthly, setFreqMonthly] = useState(schemaDetails.freqMonthly || new Date());
  const [freqOther, setFreqOther] = useState(schemaDetails.freqOther || '');
  const [rssUrlsCrawlLimit, setRssUrlsCrawlLimit] = useState(schemaDetails.rssUrlsCrawlLimit || '');
  const [errors, setErrors] = useState({});
  const [inpErrors, setInpErrors] = useState({});
  let accessEnv = ['development', 'staging'];

  useImperativeHandle(ref, () => ({
    async isValidated() {
      setErrors({})
      let rowsToValidate = Object.keys(tableData)
        .filter(x => x != 'row01')
        .filter(x => x != 'row02')
        .filter(x => x != 'row03')

      let names = []
      let fixedHeaders = ['uniq_id', 'crawl_timestamp', 'pageurl']
      let rowErrors = {}
      rowsToValidate.forEach(each => {
        if (tableData[each] != null) {
          if (tableData[each].fieldName == '') {
            rowErrors = {
              ...rowErrors, [each]: {
                ...rowErrors[each],
                fieldName: 'This field is required'
              }
            }
          }
          else if (names.includes(tableData[each].fieldName) || fixedHeaders.includes(tableData[each].fieldName)) {
            rowErrors = {
              ...rowErrors, [each]: {
                ...rowErrors[each],
                fieldName: 'This field name is already given'
              }
            }
          }
          else {
            names.push(tableData[each].fieldName)
            rowErrors = {
              ...rowErrors, [each]: {
                ...rowErrors[each],
                fieldName: ''
              }
            }
          }

          if (tableData[each].datatype == '') {
            rowErrors = {
              ...rowErrors, [each]: {
                ...rowErrors[each],
                datatype: 'This field is required'
              }
            }
          }
          else {
            rowErrors = {
              ...rowErrors, [each]: {
                ...rowErrors[each],
                datatype: ''
              }
            }
          }
        }
      })

      setErrors(rowErrors);

      setInpErrors({})
      let tempErrors = {}
      if (sgName.length <= 1) {
        tempErrors = {
          ...tempErrors,
          sgName: 'This field is required'
        }
      }
      if (dataformat == 'none') {
        tempErrors = {
          ...tempErrors,
          dataformat: 'This field is required'
        }
      }
      if (crawlType == 'none') {
        tempErrors = {
          ...tempErrors,
          crawlType: 'This field is required'
        }
      }
      if (freqSelection == 'none') {
        tempErrors = {
          ...tempErrors,
          frequency: 'This field is required'
        }
      }
      if (freqSelection == 'weekly' && freqWeekly == 'none') {
        tempErrors = {
          ...tempErrors,
          freqWeekly: 'This field is required'
        }
      }
      if (freqSelection == 'other_frequency' && freqOther.length <= 1) {
        tempErrors = {
          ...tempErrors,
          freqOther: 'This field is required'
        }
      }
      setInpErrors(tempErrors)

      let errorVals = Object.values(rowErrors)
      let inpVals = Object.values(tempErrors)

      let rowHasErrors = errorVals.some(record =>
        Object.values(record).some(item => item?.length > 0)
      )

      let schemaHasErrors = inpVals.some(item => item?.length > 0)

      if(rowHasErrors || schemaHasErrors) toast.error("The form is invalid so please check it!")

      let count = 0
      errorVals.forEach(x => {
        if (x.fieldName.length > 1 || x.datatype.length > 1) {
          count += 1
        }
      })
      if (inpVals.length == 0 && count == 0 && nextStep) {
        submitValues()
        return true
      }
      else {
        return false
      }
    }
  }));

  useEffect(() => {
    let tempRows = {}
    let tempArr = []
    schemaDetails.schemaFields.forEach((each) => {
      let keyy = Object.keys(each)[0]
      tempArr.push(keyy)
      tempRows = {
        ...tempRows, [keyy]: each[keyy]
      }
    })
    tempArr = tempArr.filter(x => x != 'row01').filter(x => x != 'row02').filter(x => x != 'row03')
    tempArr = tempArr.length > 0 ? tempArr : ["row1"]
    setTableData({
      ...tableData, ...tempRows
    })
    setNewRows(tempArr)

  }, [])

  function normalized_field_name(text) {
    return text.replace(/[^a-z0-9A-Z]+/g, ' ').trim().replace(/\s+/g, '_').toLowerCase();
  };

  const submitValues = () => {
    let tempArr = []

    Object.keys(tableData).forEach(each => {
      tempArr.push({
        [each]: tableData[each]
      })
    })

    setSchemaDetails({
      sgName, dataformat, crawlType, freqSelection, freqOther,
      freqMonthly, freqWeekly, dataDelivery, rssUrlsCrawlLimit,
      schemaFields: tempArr
    })
  }

  const handleDelete = (element) => {
    let filtered = newRows.filter(each => each !== element)
    setNewRows(filtered)
    let tempTableData = {}
    let tempErrorsData = {}
    for (let key in tableData) {
      if (key !== element) {
        tempTableData[key] = tableData[key]
      }
    }
    for (let key in errors) {
      if (key !== element) {
        tempErrorsData[key] = errors[key]
      }
    }
    setTableData(tempTableData)
    setErrors(tempErrorsData)
  }

  const handleFreqSelect = (e) => {
    setFreqSelection(e.target.value)
    setSchemaDetails({
      ...schemaDetails,
      frequency: e.target.value
    })
    if (e.target.value == '') {
      setInpErrors({
        ...inpErrors, frequency: 'This field is required'
      })
    }
    else {
      setInpErrors({
        ...inpErrors, frequency: ''
      })
    }
  }

  const handleDateChange = (e) => {
    setFreqMonthly(e);
    setSchemaDetails({
      ...schemaDetails,
      freqMonthly: e
    })
  }

  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

  const handleChangeStatus = (uploadData, status) => {
    const { meta, file } = uploadData
    const dataTypes = ["string", "date","datetime","boolean","float","integer"]

    if (status === "done") {
      var fileReader = new FileReader();
      fileReader.onload = function (event) {
        var workbook = XLSX.read(event.target.result, {
          type: "binary"
        });
        workbook.SheetNames.forEach(sheet => {
          let rowObject = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheet]
          );
          let validRecords = rowObject.every(record => dataTypes.includes(record?.data_type) )
          if (rowObject.length === 0) {
            toast.error("Uploaded file is empty")
          } else if(!validRecords){
            toast.error("Uploaded file has invalid records")
          } else if ("field_name" in rowObject[0] && "data_type" in rowObject[0]) {
            let tempObj = {
              'row01': { comments: '' },
              'row02': {},
              'row03': { sampleValue: '', comments: '' },
            }
            let newRowsArr = []

            rowObject.forEach((each2, index) => {
              tempObj[`row${index + 1}`] = {
                comments: each2["comments"],
                fieldName: each2?.field_name ? normalized_field_name(each2?.field_name) : "",
                datatype: each2.data_type?.toLowerCase() || "",
                isMandatory: each2.is_mandatory?.toLowerCase() || 'no',
                defaultValue: each2.default_value || "",
                sampleValue: each2.sample_value || "",
              }

              newRowsArr.push(`row${index + 1}`)
            })
            setNewRows(newRowsArr)
            setTableData(tempObj)
          }
          else {
            toast.error("Please keep column headers as mentioned in sample excel file")
          }
        });
      };
      fileReader.readAsBinaryString(file);
    }
    else if (status === "removed") {
      setNewRows(['row1'])
      setTableData({
        'row01': { comments: '' }, 'row02': {}, 'row03': { sampleValue: '', comments: '' },
        'row1': {
          fieldName: '', datatype: '', isMandatory: 'no', defaultValue: '',
          sampleValue: '', comments: ''
        }
      })
    }
    else if (status === 'rejected_file_type') {
      toast.error("ERROR: Unsupported File Format!" + "\n" + "Supported file formats are .xls, .xlsx, .xlsb, .xlsm, .ods, .ots, .uos");
    }
  }

  const handleSubmit = (files, allFiles) => {
    allFiles.forEach(f => f.remove())
    toast.success("Dropzone successfully submitted !");
  }

  const handleUpdateRows = () => {
    let tempRows = Object.keys(tableData)
    let last = tempRows[tempRows.length - 1]
    last = last.substring(3, last.length)
    let num = parseInt(last) + 1
    setNewRows([...newRows, `row${num}`])
    let new_name = 'row' + num
    setTableData({
      ...tableData, [new_name]: {
        id: Object.keys(tableData).length,
        fieldName: '', datatype: '', isMandatory: 'no',
        defaultValue: '', sampleValue: '', comments: ''
      }
    })
  }

  const handleInputs = (e, element) => {
    setTableData({
      ...tableData,
      [element]: {
        ...tableData[element],
        [e.target.name]: e.target.name == 'fieldName'
          ? e.target.value.replaceAll(" ", "_").toLowerCase()
          : e.target.value.toLowerCase()
      }
    })
    if (e.target.value == '' && (e.target.name == 'fieldName' || e.target.name == 'datatype')) {
      setErrors({
        ...errors, [element]: {
          ...errors[element],
          [e.target.name]: 'This field is required'
        }

      })
    }
    else if (e.target.value != '' && (e.target.name == 'fieldName' || e.target.name == 'datatype')) {
      setErrors({
        ...errors, [element]: {
          ...errors[element],
          [e.target.name]: ''
        }
      })
    }
  }

  const handleExcelDownload = () => {
    axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem("authToken")}`;
    axios.defaults.headers["Content-Type"] = `application/vnd.ms-excel`;
    axios.defaults.headers["Accept"] = `application/vnd.ms-excel`;
    axios({
      url: `/api/v1/download_sample_schema_details?page_type=client&controller_name=organizations`,
      method: 'GET',
      responseType: 'blob'
    })
      .then((res) => {
        const url_to_download = window.URL.createObjectURL(new Blob([res.data]));
        const a_tag = document.createElement('a');
        a_tag.href = url_to_download;
        a_tag.setAttribute('download', `sample_schema_fields.xls`);
        document.body.appendChild(a_tag);
        a_tag.click();
      });
    axios.defaults.headers["Content-Type"] = `application/json`;
    axios.defaults.headers["Accept"] = `application/json`;
  }

  const handleSGName = (e) => {
    let name = e.target.value
    name = name.replaceAll(" ", "_")
    setSgName(name)
    setSchemaDetails({
      ...schemaDetails,
      sgName: name
    })
    if (e.target.value.length > 1) {
      setInpErrors({
        ...inpErrors, sgName: ''
      })
    }
    else {
      setInpErrors({
        ...inpErrors, sgName: 'This field is required'
      })
    }
  }

  const handleRssUrlsCrawlLimit = (e) => {
    let rss_urls_crawl_limit = e.target.value
    setRssUrlsCrawlLimit(rss_urls_crawl_limit)
    setSchemaDetails({
      ...schemaDetails,
      rssUrlsCrawlLimit: rss_urls_crawl_limit
    })
    if (rss_urls_crawl_limit.length > 0 && !/^\d+$/.test(rss_urls_crawl_limit)) {
      setInpErrors({
        ...inpErrors, rssUrlsCrawlLimit: 'This field should be integer!'
      })
    }
    else {
      setInpErrors({
        ...inpErrors, rssUrlsCrawlLimit: ''
      })
    }
  }


  return (
    <div>
      <Row>
        <Col sm="12">
          <Form className="needs-validation">
            <Row className="my-4">
              <Col lg={5}>
                <div className='my-3'>
                  <Label>Sitegroup Name*</Label>
                  <Input className="form-control" name="sg_name" type="text"
                    value={sgName} onChange={(e) => { handleSGName(e) }} title='please give a name to the group of sites having similar schema'
                    placeholder="Eg: hotel_reviews (No spaces)" maxLength={25} />
                  {inpErrors && inpErrors.sgName && <p className='m-0 error-msg mt-1 mb-2'>
                    {inpErrors.sgName}
                  </p>}
                  <div className="text-muted text-sm">
                    All sites within a sitegroup share the same fields, frequency and output format
                  </div>
                </div>
                <div className='my-3'>
                  <Label>Crawl Type*</Label>
                  <Input type="select" name="crawl_type" id="crawl_type"
                    title={`1) Full crawl - a full crawl will be performed each time (eg: product info crawls)
2) Incremental crawl - the crawler will only look for newly available URLs (eg: reviews, jobs data etc where previously crawled / delivered data will not be crawled again)`}
                    defaultValue={crawlType} onChange={(e) => {
                      setCrawlType(e.target.value)
                      setSchemaDetails({
                        ...schemaDetails,
                        crawlType: e.target.value
                      })
                      if (e.target.value == '') {
                        setInpErrors({
                          ...inpErrors, crawlType: 'This field is required'
                        })
                      }
                      else {
                        setInpErrors({
                          ...inpErrors, crawlType: ''
                        })
                      }
                    }}>
                    <option value="none">Select crawltype</option>
                    <option value="fullcrawl">Full crawl</option>
                    <option value="incremental_recrawl">Incremental Recrawl</option>
                  </Input>
                  {inpErrors && inpErrors.crawlType && <p className='m-0 error-msg mt-1 mb-2'>
                    {inpErrors.crawlType}
                  </p>}
                </div>
                <div className='my-3'>
                  <Label>Data Delivery*</Label>
                  <Input type="select" name="datadelv" id="datadelv"
                    title='select the delivery method where you want to data to be delivered'
                    defaultValue={dataDelivery} onChange={(e) => {
                      setDataDelivery(e.target.value)
                      setSchemaDetails({
                        ...schemaDetails,
                        dataDelivery: e.target.value
                      })
                    }}>
                    <option value="api">Promptcloud Data API (Free)</option>
                    <option value="s3">Amazon S3</option>
                    <option value="dropbox">Dropbox</option>
                    <option value="box">Box</option>
                    <option value="ftp">FTP</option>
                    <option value="sftp">sFTP</option>
                    <option value="microsoft_azure">Microsoft Azure</option>
                    <option value="google_drive">Google Drive</option>
                  </Input>
                  {inpErrors && inpErrors.dataDelivery && <p className='m-0 error-msg mt-1 mb-2'>
                    {inpErrors.dataDelivery}
                  </p>}
                </div>
                {accessEnv.includes(environment) ?
                  <div className='my-3'>
                    <Label>Rss Urls Crawl Limit</Label>
                    <Input
                      type="text" name={`rss_urls_crawl_limit`}
                      id={`rss_urls_crawl_limit`}
                      onChange={(e) => { handleRssUrlsCrawlLimit(e) }}
                      value={rssUrlsCrawlLimit}
                      placeholder='It will be 30k by default' />
                    {inpErrors && inpErrors.rssUrlsCrawlLimit && <p className='m-0 error-msg mt-1 mb-2'>
                      {inpErrors.rssUrlsCrawlLimit}
                    </p>}
                  </div>
                  : ""
                }
              </Col>
              <Col lg={1}></Col>
              <Col lg={5}>
                <div className='my-3'>
                  <Label>Data Format*</Label>
                  <Input type="select" name="dataformat" id="dataformat"
                    title='select the format in which you wish to recieve the data'
                    onChange={(e) => {
                      setDataformat(e.target.value)
                      setSchemaDetails({
                        ...schemaDetails,
                        dataFormat: e.target.value
                      })
                      if (e.target.value == '') {
                        setInpErrors({
                          ...inpErrors, dataformat: 'This field is required'
                        })
                      }
                      else {
                        setInpErrors({
                          ...inpErrors, dataformat: ''
                        })
                      }
                    }} defaultValue={dataformat}>
                    <option value="">Select format</option>
                    <option value="json">JSON</option>
                    <option value="ld_json">LD-JSON</option>
                    <option value="xml">XML</option>
                    <option value="csv">CSV</option>
                    <option value="tsv">TSV</option>
                  </Input>
                  {inpErrors && inpErrors.dataformat && <p className='m-0 error-msg mt-1 mb-2'>
                    {inpErrors.dataformat}
                  </p>}
                </div>
                <div className='my-3'>
                  <Label>Frequency*</Label>
                  <Input type="select" name="frequency" id="frequency"
                    title='How often do you wish to run the crawls?'
                    onChange={handleFreqSelect} defaultValue={freqSelection}>
                    <option value="">Select frequency</option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="other_frequency">Other</option>
                  </Input>
                  {inpErrors && inpErrors.frequency && <p className='m-0 error-msg mt-1 mb-2'>
                    {inpErrors.frequency}
                  </p>}
                </div>
                {freqSelection == 'weekly' &&
                  <div className='my-3'>
                    <Label>Days*</Label>
                    <Input type="select" name="weekly" id="weekly"
                      defaultValue={freqWeekly} onChange={(e) => {
                        setfreqWeekly(e.target.value)
                        setSchemaDetails({
                          ...schemaDetails,
                          freqWeekly: e.target.value
                        })
                        if (e.target.value == '') {
                          setInpErrors({
                            ...inpErrors, freqWeekly: 'This field is required'
                          })
                        }
                        else {
                          setInpErrors({
                            ...inpErrors, freqWeekly: ''
                          })
                        }
                      }}>
                      <option value="">Select day</option>
                      <option value="monday">Monday</option>
                      <option value="tuesday">Tuesday</option>
                      <option value="wednesday">Wednesday</option>
                      <option value="thursday">Thursday</option>
                      <option value="friday">Friday</option>
                      <option value="saturday">Saturday</option>
                      <option value="sunday">Sunday</option>
                    </Input>
                    {inpErrors && inpErrors.freqWeekly && <p className='m-0 error-msg mt-1 mb-2'>
                      {inpErrors.freqWeekly}
                    </p>}
                  </div>}
                {freqSelection == 'other_frequency' &&
                  <div className='my-3'>
                    <Label>Details*</Label>
                    <Input className="form-control" name="details" type="text"
                      placeholder="" value={freqOther}
                      onChange={(e) => {
                        setFreqOther(e.target.value)
                        setSchemaDetails({
                          ...schemaDetails,
                          freqOther: e.target.value
                        })
                        if (e.target.value == '') {
                          setInpErrors({
                            ...inpErrors, freqOther: 'This field is required'
                          })
                        }
                        else {
                          setInpErrors({
                            ...inpErrors, freqOther: ''
                          })
                        }
                      }} />
                    {inpErrors && inpErrors.freqOther && <p className='m-0 error-msg mt-1 mb-2'>
                      {inpErrors.freqOther}
                    </p>}
                    <div className="text-muted text-sm">
                      *Minimum requirements - at least one crawl per month, for a minimum period of 3 months. Please note that we do not take up one-time data acquisition projects.
                    </div>
                  </div>}
                {freqSelection == 'monthly' &&
                  <div className='my-3'>
                    <Label>Day of Month*</Label>
                    <DatePicker className="form-control digits"
                      selected={freqMonthly} onChange={handleDateChange} />
                    {errors && errors.freqmonth && <p className='m-0 error-msg mt-1 mb-2'>
                      {errors.freqmonth}
                    </p>}
                  </div>}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <Row className='my-5'>
        <Table>
          <thead>
            <tr>
              <th>Field Name</th>
              <th>Data Type</th>
              <th>isMandatory</th>
              <th>Default Value</th>
              <th>Sample Value</th>
              <th>Comments</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Input type="text" name="fieldname"
                  value='uniq_id' disabled />
              </td>
              <td>
                <Input type="select" name="datatype" placeholder='choose a value'
                  defaultValue="string" disabled>
                  <option value="string">string</option>
                  <option value="date">date</option>
                  <option value="datetime">datetime</option>
                  <option value="boolean">boolean</option>
                  <option value="float">float</option>
                  <option value="integer">integer</option>
                </Input>
              </td>
              <td>
                <Input type="select" name="isMandatory"
                  defaultValue="yes" disabled>
                  <option value="yes">yes</option>
                  <option value="no">no</option>
                </Input>
              </td>
              <td>
                <Input type="text" name="defvalue"
                  value='system_generated' disabled />
              </td>
              <td>
                <Input type="text" name="sampleval"
                  value='default' disabled />
              </td>
              <td>
                <Input type="text" name="comments" value={tableData.row01.comments}
                  onChange={(e) => setTableData({
                    ...tableData, "row01": { comments: e.target.value }
                  })} />
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <Input type="text" name="fieldname"
                  value='crawl_timestamp' disabled />
              </td>
              <td>
                <Input type="select" name="datatype" placeholder='choose a value'
                  defaultValue="date" disabled>
                  <option value="string">string</option>
                  <option value="date">date</option>
                  <option value="datetime">datetime</option>
                  <option value="boolean">boolean</option>
                  <option value="float">float</option>
                  <option value="integer">integer</option>
                </Input>
              </td>
              <td>
                <Input type="select" name="isMandatory"
                  defaultValue="yes" disabled>
                  <option value="yes">yes</option>
                  <option value="no">no</option>
                </Input>
              </td>
              <td>
                <Input type="text" name="defvalue"
                  value='system_generated' disabled />
              </td>
              <td>
                <Input type="text" name="sampleval"
                  value='default' disabled />
              </td>
              <td>
                <Input type="text" name="comments"
                  disabled />
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <Input type="text" name="fieldname"
                  value='pageurl' disabled />
              </td>
              <td>
                <Input type="select" name="datatype" placeholder='choose a value'
                  defaultValue="string" disabled>
                  <option value="string">string</option>
                  <option value="date">date</option>
                  <option value="datetime">datetime</option>
                  <option value="boolean">boolean</option>
                  <option value="float">float</option>
                  <option value="integer">integer</option>
                </Input>
              </td>
              <td>
                <Input type="select" name="isMandatory"
                  defaultValue="yes" disabled>
                  <option value="yes">yes</option>
                  <option value="no">no</option>
                </Input>
              </td>
              <td>
                <Input type="text" name="defvalue"
                  value='system_generated' disabled />
              </td>
              <td>
                <Input type="text" name="sampleval" value={tableData.row03.sampleValue}
                  onChange={(e) => {
                    setTableData({
                      ...tableData,
                      row03: { ...tableData.row03, sampleValue: e.target.value }
                    })
                  }} />
              </td>
              <td>
                <Input type="text" name="comments" value={tableData.row03.comments}
                  onChange={(e) => {
                    setTableData({
                      ...tableData,
                      row03: { ...tableData.row03, comments: e.target.value }
                    })
                  }} />
              </td>
              <td>

              </td>
            </tr>
            {newRows.map((element, index) => {
              return (
                tableData[element] != null &&
                (<tr key={`1${index}`} name={element}>
                  <td>
                    <Input type="text" name={`fieldName`}
                      value={tableData[element]['fieldName']}
                      onChange={(e) => { handleInputs(e, element) }} />
                    {errors && errors[element] && errors[element]['fieldName'] && <p className='m-0 error-msg mt-1 mb-2'>
                      {errors[element]['fieldName']}
                    </p>}
                  </td>
                  <td>
                    <Input type="select" name={`datatype`}
                      value={tableData[element]['datatype']}
                      onChange={(e) => { handleInputs(e, element) }}>
                      <option value="">Select datatype</option>
                      <option value="string">string</option>
                      <option value="date">date</option>
                      <option value="datetime">datetime</option>
                      <option value="boolean">boolean</option>
                      <option value="float">float</option>
                      <option value="integer">integer</option>
                    </Input>
                    {errors && errors[element]
                      && errors[element]['datatype'] && <p className='m-0 error-msg mt-1 mb-2'>
                        {errors[element]['datatype']}
                      </p>}
                  </td>
                  <td>
                    <Input type="select" name={`isMandatory`}
                      value={tableData[element]['isMandatory']}
                      onChange={(e) => { handleInputs(e, element) }}>
                      <option value="">Select one</option>
                      <option value="yes">yes</option>
                      <option value="no">no</option>
                    </Input>
                    {errors && errors[element]
                      && errors[element]['isMandatory'] && <p className='m-0 error-msg mt-1 mb-2'>
                        {errors[element]['isMandatory']}
                      </p>}
                  </td>
                  <td>
                    <Input type="text" name={`defaultValue`}
                      value={tableData[element]['defaultValue']}
                      onChange={(e) => { handleInputs(e, element) }} />
                  </td>
                  <td>
                    <Input type="text" name={`sampleValue`}
                      value={tableData[element]['sampleValue']}
                      onChange={(e) => { handleInputs(e, element) }} />
                  </td>
                  <td>
                    <Input type="text" name={`comments`}
                      value={tableData[element]['comments']}
                      onChange={(e) => { handleInputs(e, element) }} />
                  </td>
                  <td>

                    <button className='btn btn-outline-danger'
                      onClick={(e) => { handleDelete(element) }}>
                      Delete
                    </button>
                  </td>
                </tr>))
            })}
          </tbody>
        </Table>

        <button className='btn btn-outline-primary my-3'
          onClick={(e) => { handleUpdateRows() }}>
          + Add more fields
        </button>
      </Row>
      <div className='my-4 d-flex justify-content-center fw-bold'>
        OR
      </div>
      <Row>
        <Col lg={3}>
          <h6 className='fw-bold d-flex justify-content-end'>
            Add Schema details via a file: </h6>
        </Col>
        <Col lg={5}>
          <Form onSubmit={handleSubmit}>
            <div className="dz-message needsclick">
              <Dropzone
                getUploadParams={getUploadParams} maxFiles={1}
                onChangeStatus={handleChangeStatus} multiple={false}
                canCancel={true}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.ms-excel.sheet.binary.macroEnabled.12,application/vnd.oasis.opendocument.spreadsheet,application/vnd.ms-excel.sheet.macroEnabled.12,application/vnd.oasis.opendocument.spreadsheet-template"
                inputContent={(files, extra) => (extra.reject ? 'Please enter only supported files' : 'Upload / Drop an Excel File')}
                styles={{
                  dropzone: { height: 40 },
                  dropzoneActive: { borderColor: 'green' },
                }}
              />
            </div>
          </Form>
          <p className='text-muted m-0 mt-2 text-sm'>
            Support file formats are .xls, .xlsx, .xlsb, .xlsm, .ods, .ots, .uos
          </p>
        </Col>
      </Row>
      <p className='my-5 d-flex align-center'>
        <button onClick={handleExcelDownload}
          className='btn text-primary'> Click here for a sample Excel file. </button>
        Please do not change column headers
      </p>
    </div>
  )
})

export default SchemaDetails;
