import React from 'react';
import DataTable from 'react-data-table-component';
import { KIBANA_PLM_URL } from '../../../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints';

const columns = [
    {
        name: 'Host Name',
        selector: 'hostname',
        sortable: true
    },
    {
        name: 'Segment Name',
        selector: 'segment_name',
        sortable: true,
        cell: row => (
            <a href={kibanaUrl(row.kibana_query_value, row.segment_name)} target="_blank" rel="noopener noreferrer">
                {row.segment_name}
            </a>
        )
    },
    {
        name: 'Crawldate',
        selector: 'crawldate',
        sortable: true
    },
    {
        name: 'PID',
        selector: 'pid',
        sortable: true
    },
    {
        name: 'Crawled URLs Count',
        selector: 'crawled_urls_count',
        sortable: true
    },
    {
        name: 'Crawl Status',
        selector: 'crawlstatus',
        sortable: true
    },
    {
        name: 'Feedcrawl Start Status',
        selector: 'feedcrawl_start_status',
        sortable: true
    },
    {
        name: 'Feedcrawl End Status',
        selector: 'feedcrawl_end_status',
        sortable: true
    },
    {
        name: 'Extraction status',
        selector: 'extractstatus',
        sortable: true
    },
];

function kibanaUrl(kibana_query_value, segment_name) {
    return `${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(pause:!t,value:0),time:(from:now-7d,to:now))&_a=(columns:!(segment_uniq_id,segid),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'%22${kibana_query_value}%22+and+segment_uniq_id.raw:+${segment_name}'),sort:!(!('@timestamp',desc)))`
}

export default function DiskfetcherProcessTable(props) {
    return (
        <div>
            <DataTable
                columns={columns}
                data={props.data}
                progressPending={props.progressPending}
                noHeader
                responsive
                pagination />
        </div>
    )
}
