import React, {
  forwardRef, useImperativeHandle,
  useState, useEffect
} from 'react';
import { Row, Col, Form, Input, Card, CardBody, Table } from 'reactstrap'
import Dropzone from 'react-dropzone-uploader';
import { toast } from 'react-toastify'
import XLSX from 'xlsx'
import axios from 'axios'
import Loader from 'react-loader-spinner';

const SchemaDetails = forwardRef(({ data, setGlobalTableData,
  globalTableData, v2Data, setV2Data, dataDisable }, ref) => {
  const [tableData, setTableData] = useState({})
  const [newRows, setNewRows] = useState(['row1'])
  const [errors, setErrors] = useState({})
  const [localData, setLocalData] = useState({})
  const [version, setVersion] = useState(data.project_version)
  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    let tempRows = {}
    let tempArr = []
    let filteredData = data.schema_fields_details || []
    filteredData.forEach((each, index) => {
      let keyy = index;
      keyy = "row" + keyy;
      tempArr.push(keyy)
      tempRows = {
        ...tempRows, [keyy]: each
      }
    })
    globalTableData.row1 ? setTableData(globalTableData) : setTableData({ ...tempRows })
    setNewRows(tempArr)
    setVersion(data.project_version)
    setLocalData({
      ...data,
      organization_name: v2Data.organization_name
        ? v2Data.organization_name : data.organization_name,
      additional_information: v2Data.additional_information
        ? v2Data.additional_information : data.additional_information,
      fields_to_extract: v2Data.fields_to_extract
        ? v2Data.fields_to_extract : data.fields_to_extract

    })
  }, [data, toggle])

  function normalized_field_name(text) {
    return text.replace(/[^a-z0-9A-Z]+/g, ' ').trim().replace(/\s+/g, '_').toLowerCase();
  };

  const handleDelete = (element) => {
    let filtered = newRows.filter(each => each !== element)
    setNewRows(filtered)
    let tempTableData = {}
    let tempErrorsData = {}
    for (let key in tableData) {
      if (key !== element) {
        tempTableData[key] = tableData[key]
      }
    }
    for (let key in errors) {
      if (key !== element) {
        tempErrorsData[key] = errors[key]
      }
    }
    setTableData(tempTableData)
    setErrors(tempErrorsData)
  }

  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

  const handleChangeStatus = (uploadData, status) => {
    const { meta, file } = uploadData
    const dataTypes = ["string", "date","datetime","boolean","float","integer"]

    if (status === "done") {
      var fileReader = new FileReader();
      fileReader.onload = function (event) {
        var workbook = XLSX.read(event.target.result, {
          type: "binary"
        });
        workbook.SheetNames.forEach(sheet => {
          let rowObject = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheet]
          );
          let validRecords = rowObject.every(record => dataTypes.includes(record?.data_type) )

          if (rowObject.length === 0) {
            toast.error("Uploaded file is empty")
          } else if(!validRecords){
            toast.error("Uploaded file has invalid records")
          } else if ("field_name" in rowObject[0] && "data_type" in rowObject[0]) {
            let tempObj = { ...tableData }
            let newRowsArr = [...newRows]

            rowObject.forEach((each2, index) => {
              tempObj[`row1${index + 1}`] = {
                comments: each2["comments"],
                field_name: each2?.field_name ? normalized_field_name(each2?.field_name) : "",
                data_type: each2.data_type?.toLowerCase() || "",
                is_mandatory: each2.is_mandatory?.toLowerCase() || 'no',
                default_value: each2.default_value || "",
                sample_value: each2.sample_value || "",
              }

              newRowsArr.push(`row${index + 1}`)
            })
            setNewRows(newRowsArr)
            setTableData(tempObj)
          }
          else {
            toast.error("Please keep column headers as mentioned in sample excel file")
          }
        });
      };
      fileReader.readAsBinaryString(file);
    }
    else if (status === "removed") {
      setToggle(curr => !curr)
    }
    else if (status === 'rejected_file_type') {
      toast.error("ERROR: Unsupported File Format!" + "\n" + "Supported file formats are .xls, .xlsx, .xlsb, .xlsm, .ods, .ots, .uos");
  }
}

  const handleSubmit = (files, allFiles) => {
    allFiles.forEach(f => f.remove())
    toast.success("Dropzone successfully submitted !");
  }

  const handleUpdateRows = () => {
    let tempRows = Object.keys(tableData)
    let last = tempRows[tempRows.length - 1]
    last = last.substring(3, last.length)
    let num = parseInt(last) + 1
    setNewRows([...newRows, `row${num}`])
    let new_name = 'row' + num
    setTableData({
      ...tableData, [new_name]: {
        id: '',
        field_name: '', data_type: '', is_mandatory: 'no',
        default_value: '', sample_value: '', comments: ''
      }
    })
  }

  useImperativeHandle(ref, () => ({
    async isValidated() {
      let errorRows = Object.values(errors)
      let status = true
      errorRows.forEach(each => {
        if (each.field_name && each.field_name.length > 1) {
          status = false
        }
        else if (each.data_type && each.data_type.length > 1) {
          status = false
        }
      })
      let tableRows = Object.keys(tableData)

      let tempError = {}
      tableRows.forEach(element => {
        let each = tableData[element]
        if (each !== null && (each.field_name.length < 2 && each.data_type.length < 2)) {
          tempError = {
            ...tempError, [element]: {
              field_name: 'This field is required',
              data_type: 'This field is required'
            }
          }
          status = false
        }
        else if (each !== null && (each.field_name.length < 2)) {
          tempError = {
            ...tempError, [element]: {
              ...tempError[element],
              field_name: 'This field is required'
            }
          }
          status = false
        }
        else if (each !== null && (each.data_type.length < 2)) {
          tempError = {
            ...tempError, [element]: {
              ...tempError[element],
              data_type: 'This field is required'
            }
          }
          status = false
        }
      })
      let filteredFieldNames = []
      Object.keys(tableData).forEach(element => {
        let each = tableData[element]
        if (filteredFieldNames.includes(each.field_name)) {
          tempError = {
            ...tempError, [element]: {
              ...tempError[element],
              field_name: 'Field name must be unique'
            }
          }
          status = false
        }
        else {
          filteredFieldNames.push(each.field_name)
        }
      })
      setErrors(tempError)
      let rowHasErrors = Object.values(tempError).some(record =>
        Object.values(record).some(item => item?.length > 0)
      )
      if(rowHasErrors) toast.error("The form is invalid so please check it!")
      setV2Data({
        additional_information: localData.additional_information,
        fields_to_extract: localData.fields_to_extract
      })
      setGlobalTableData(tableData)
      return status
    }
  }));

  const handleInputs = (e, element) => {
    let tempData = {
      ...tableData,
      [element]: {
        ...tableData[element],
        [e.target.name]: e.target.name == 'field_name'
          ? e.target.value.replaceAll(" ", "_").toLowerCase()
          : e.target.value.toLowerCase()
      }
    }
    setTableData({ ...tempData })
    if (e.target.value == '' && (e.target.name == 'field_name' || e.target.name == 'data_type')) {
      setErrors({
        ...errors, [element]: {
          ...errors[element],
          [e.target.name]: 'This field is required'
        }
      })
    }
    else if (e.target.value != '' && (e.target.name == 'field_name' || e.target.name == 'data_type')) {
      setErrors({
        ...errors, [element]: {
          ...errors[element],
          [e.target.name]: ''
        }
      })
    }
    setLocalData({
      ...localData, schema_fields_details: Object.values(tempData)
    })
  }

  const handleExcelDownload = () => {
    axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem("authToken")}`;
    axios.defaults.headers["Content-Type"] = `application/vnd.ms-excel`;
    axios.defaults.headers["Accept"] = `application/vnd.ms-excel`;
    axios({
      url: `/api/v1/download_sample_schema_details`,
      method: 'GET',
      responseType: 'blob'
    })
      .then((res) => {
        const url_to_download = window.URL.createObjectURL(new Blob([res.data]));
        const a_tag = document.createElement('a');
        a_tag.href = url_to_download;
        a_tag.setAttribute('download', `sample_schema_fields.xls`);
        document.body.appendChild(a_tag);
        a_tag.click();
      });
    axios.defaults.headers["Content-Type"] = `application/json`;
    axios.defaults.headers["Accept"] = `application/json`;
  }

  return (
    <div className='p-3'>
      {localData.sitegroup_id ? (<>
        <Card>
          <CardBody className='p-4'>
            <Row>
              <Col lg={4}>
                <h6 className='fw-bold'>
                  Sitegroup Name
                </h6>
                <p>
                  {localData.sitegroup_name &&
                    localData.sitegroup_name}
                </p>
              </Col>
              <Col lg={5}>
                <h6 className='fw-bold'>
                  Organization
                </h6>
                {localData.organization_name && <input
                  onChange={(e) => setLocalData({
                    ...localData,
                    organization_name: e.target.value
                  })}
                  value={"" || localData.organization_name}
                  placeholder='Enter organization name'
                  name="organization_name"
                  className='form-control' />}
              </Col>
              <Col lg={3}>
                <h6 className='fw-bold'>
                  Country
                </h6>
                <p>
                  {localData.country_name &&
                    localData.country_name}
                </p>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col lg={4}>
                <h6 className='fw-bold'>
                  Creator
                </h6>
                <p>
                  {localData.creator && localData.creator}
                </p>
              </Col>
              <Col lg={5}>
                <h6 className='fw-bold'>
                  Additional Information
                </h6>
                <textarea rows='2'
                  value={"" || localData.additional_information}
                  onChange={(e) => setLocalData({
                    ...localData,
                    additional_information: e.target.value
                  })}
                  name="additional_information"
                  className='form-control' />
              </Col>
              <Col lg={3}>
                <h6 className='fw-bold'>
                  Attachments
                </h6>
                {(localData.attachments &&
                  localData.attachments.length > 0) ?
                  localData.attachments.map((each, index) =>
                    <a href={each.url} key={index} download> {each.file_name} </a>
                  )
                  : <p> N/A </p>}
              </Col>
            </Row>
          </CardBody>
        </Card>

        {version == 'v2' ?
          (<div className='mt-4'>
            <h6>Fields to Extract:</h6>
            <textarea rows='3' className='form-control' disabled={dataDisable}
              value={localData.fields_to_extract ? localData.fields_to_extract : ''}
              onChange={(e) => setLocalData({
                ...localData, fields_to_extract: e.target.value
              })} />
          </div>)
          : (
            <div className='my-1'>
              <Row className='my-3'>
                <h6 className='fw-bold'>Schema Details: </h6>
                <Table responsive className='schema-table--custom'>
                  <thead>
                    <tr>
                      <th>Field Name</th>
                      <th>Data Type</th>
                      <th>is_mandatory</th>
                      <th>Default Value</th>
                      <th>Sample Value</th>
                      <th>Comments</th>
                      <th>Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData &&
                      Object.keys(tableData).map((each, index) => {
                        let element = tableData[each]
                        return (
                          (element !== null &&
                            <tr key={`${index}`} name={each}>
                              <td>
                                <Input type="text" name={`field_name`}
                                  value={element.field_name || ''}
                                  disabled={(index === 0 || index === 1 || index === 2) || dataDisable}
                                  onChange={(e) => { handleInputs(e, each) }} />
                                {errors && errors[each] && errors[each]['field_name'] && <p className='m-0 error-msg mt-1 mb-2'>
                                  {errors[each]['field_name']}
                                </p>}
                              </td>
                              <td>
                                <Input type="select" name={`data_type`}
                                  value={element.data_type || ''}
                                  disabled={(index === 0 || index === 1 || index === 2) || dataDisable}
                                  onChange={(e) => { handleInputs(e, each) }}>
                                  <option value=""></option>
                                  <option value="string">string</option>
                                  <option value="date">date</option>
                                  <option value="datetime">datetime</option>
                                  <option value="boolean">boolean</option>
                                  <option value="float">float</option>
                                  <option value="integer">integer</option>
                                </Input>
                                {errors && errors[each]
                                  && errors[each]['data_type'] && <p className='m-0 error-msg mt-1 mb-2'>
                                    {errors[each]['data_type']}
                                  </p>}
                              </td>
                              <td>
                                <Input type="select" name={`is_mandatory`}
                                  value={element.is_mandatory || ''}
                                  disabled={(index === 0 || index === 1 || index === 2) || dataDisable}
                                  onChange={(e) => { handleInputs(e, each) }}>
                                  <option value=""></option>
                                  <option value="yes">yes</option>
                                  <option value="no">no</option>
                                </Input>
                                {errors && errors[element]
                                  && errors[element]['is_mandatory'] && <p className='m-0 error-msg mt-1 mb-2'>
                                    {errors[element]['is_mandatory']}
                                  </p>}
                              </td>
                              <td>
                                <Input type="text" name={'default_value'}
                                  value={element.default_value || ''}
                                  disabled={(index === 0 || index === 1 || index === 2) || dataDisable}
                                  onChange={(e) => { handleInputs(e, each) }} />
                              </td>
                              <td>
                                <Input type="text" name={`sample_value`}
                                  value={element.sample_value || ''}
                                  disabled={(index === 0 || index === 1) || dataDisable}
                                  onChange={(e) => { handleInputs(e, each) }} />
                              </td>
                              <td>
                                <Input type="text" name={`comments`}
                                  disabled={(index === 1) || dataDisable}
                                  value={element.comments || ''}
                                  onChange={(e) => { handleInputs(e, each) }} />
                              </td>
                              <td>
                                {(!(index === 0 || index === 1 || index === 2) && !dataDisable) &&
                                  <button className='btn btn-outline-danger'
                                    onClick={(e) => { handleDelete(each) }}>
                                    Delete
                                  </button>}
                              </td>
                            </tr>))
                      })}
                  </tbody>
                </Table>

                {!dataDisable && <button className='btn btn-outline-primary my-3'
                  onClick={(e) => { handleUpdateRows() }}>
                  + Add more fields
                </button>}
              </Row>

              {!dataDisable && <div className='my-4 d-flex justify-content-center fw-bold'>
                OR
              </div>}

              {!dataDisable && <Row>
                <Col lg={3}>
                  <h6 className='fw-bold d-flex justify-content-end'>
                    Add Schema details via a file: </h6>
                </Col>
                <Col lg={5}>
                  <Form onSubmit={handleSubmit}>
                    <div className="dz-message needsclick">
                      <Dropzone getUploadParams={getUploadParams} maxFiles={1}
                        onChangeStatus={handleChangeStatus} multiple={false}
                        canCancel={true}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.ms-excel.sheet.binary.macroEnabled.12,application/vnd.oasis.opendocument.spreadsheet,application/vnd.ms-excel.sheet.macroEnabled.12,application/vnd.oasis.opendocument.spreadsheet-template"
                        inputContent={(files, extra) => (extra.reject ? 'Please enter only supported files' : 'Upload / Drop an Excel File')}
                        styles={{
                          dropzone: { height: 40 },
                          dropzoneActive: { borderColor: 'green' },
                        }}
                      />
                    </div>
                  </Form>
                  <p className='text-muted m-0 mt-2 text-sm'>
                    Support file formats are .xls, .xlsx, .xlsb, .xlsm, .ods, .ots, .uos
                  </p>
                </Col>
              </Row>}
              <p className='my-5 d-flex align-center'>
                <button onClick={handleExcelDownload}
                  className='btn text-primary'> Click here for a sample Excel file. </button>
                Please do not change column headers
              </p>
            </div>
          )}
      </>)
        : <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />}
    </div>
  );
});

export { SchemaDetails as default }
