import React, { useEffect, useState, useContext } from 'react'
import { useParams } from "react-router-dom";
import Breadcrumb from '../../../Common/BreadCrumb/BreadCrumbs.component';
import { Row, Col, Card, CardBody, CardHeader, Container } from 'reactstrap'
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import {
  useStripe, useElements, CardNumberElement,
  CardExpiryElement, CardCvcElement
} from '@stripe/react-stripe-js';
import { OrgnaziationContext } from '../../../../Home';

export default function AddNewCard({ current_user, current_organization }) {
  let { organization_name } = useParams();
  const [loadingBtns, setLoadingBtns] = useState(false)
  const stripe = useStripe();
  const elements = useElements();
  let history = useHistory();

  const { setOrgName } = useContext(OrgnaziationContext)
  const [organizationName, setOrganizationName] = useState("");

  useEffect(() => {
    if (organization_name) {
      setOrganizationName(organization_name)
    } else {
      setOrganizationName(current_organization?.org_identifier)
    }
  }, [])

  useEffect(() => {
    if (!(current_organization?.org_identifier)) {
      setOrgName(organizationName)
    }
  }, [organizationName])


  useEffect(() => {
    if ((current_user?.id && !(current_user?.is_admin)) && !(current_user?.accessible_modules.includes("billing"))) {
      history.push(`/`)
      toast.error('You are not authorized to access this page')
    }
  }, [current_user])

  const addCard = (token) => {
    setLoadingBtns(true)
    axios.post(`/api/v1/organizations/${organizationName}/cards`, {
      payment_details: { stripeToken: token.id },
      page_type: "client",
      controller_name: "cards"
    }).then(res => {
      toast.success(res.data.message)
      history.push(`/organizations/${organizationName}/cards/`)
      setLoadingBtns(false)
    }).catch(err => {
      toast.error(err.response.data.message)
      setLoadingBtns(false)
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    const { error, token } = await stripe.createToken(elements.getElement(CardNumberElement))
    if (error) {
      toast.error(error)
    }
    else if (token.id) {
      addCard(token)
    }
  };
  return (
    <div>
      <Container fluid>
        <Breadcrumb parent={"Cards"}
          childrenObj={[{ name: "Cards", url: `/organizations/${organizationName}/cards/` }]}
          title="Add Card" />
        <Row className='px-4'>
          <Col lg={12}>
            <Card>
              <CardHeader className='m-0 p-4'>
                Add a new card
              </CardHeader>
              <CardBody className='m-0 p-4'>
                <form onSubmit={handleSubmit}>
                  <div className='form-group my-3'>
                    <label className="form-label">
                      Card number
                    </label>
                    <CardNumberElement className='form-control' />
                  </div>
                  <div className='form-group my-3'>
                    <label className="form-label">
                      Expiration date
                    </label>
                    <CardExpiryElement className='form-control' />
                  </div>
                  <div className='form-group my-3'>
                    <label className="form-label">
                      CVC
                    </label>
                    <CardCvcElement className='form-control' />
                  </div>
                  <button type="submit" className='btn btn-success mt-4'
                    disabled={(!stripe || !elements) || loadingBtns}>
                    Add card
                  </button>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
