import React from 'react';
import Loader from 'react-loader-spinner';

export default function LoadingAnimation() {
  return (
    <div>
      <Loader
        className='pc-loader'
        type="Puff"
        color="#00BFFF"
        height={60}
        width={60} />
    </div>
  )
}
