import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Form, Input, Table } from 'reactstrap'
import { useParams, useHistory } from 'react-router-dom';

import Dropzone from 'react-dropzone-uploader';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify'
import { Container } from 'reactstrap'
import axios from 'axios'
import XLSX from 'xlsx'

import { getItem } from '../../../localStorageMethods';
import Breadcrumbs from '../../Common/BreadCrumb/BreadCrumbs.component';

export default function AddNewSitesToSiteGroup() {
    let accessEnv = ['development', 'staging']
    const history = useHistory()
    const environment = getItem('environment');
    const { organization_name } = useParams();
    const [newRows, setNewRows] = useState(['row1', 'row2', 'row3', 'row4'])
    const [sgList, setSgList] = useState([])
    // const [selectedSiteGroup, setSelectedSiteGroup] = useState('none')
    const [siteDetails, setSiteDetails] = useState([])
    const [sitegroupNamesDropdownOptions, setSitegroupNamesDropdownOptions] = useState([]);
    const [selectedSitegroup, setSelectedSitegroup] = useState({value: '', label:'Select A Sitegroup'});
    const [urlData, setUrlData] = useState({
        'row1': { url: '', rss_urls_crawl_limit: '' },
        'row2': { url: '', rss_urls_crawl_limit: '' },
        'row3': { url: '', rss_urls_crawl_limit: '' },
        'row4': { url: '', rss_urls_crawl_limit: '' },
    })
    const [errors, setErrors] = useState({})
    const [valid, setValid] = useState(false);


    useEffect(() => {
        axios.get(`/api/v1/organizations/${organization_name}/sitegroups?from=new_sites_form&page_type=client&controller_name=sitegroups`).then(res => {
            setSgList(res.data.sitegroups)
            setSitegroupNamesDropdownOptions(convertToArrayOfObjects(res?.data?.sitegroups));
        }).catch(err => {
            toast.error(err.response.data.message)
        })
    }, [])


    useEffect(() => {
        let totalErrors = Object.values(errors).filter(x => x.url != '')
        let correctUrls = Object.values(urlData).filter(x => x.url != '').filter(y => isURL(y.url))
        if (correctUrls.length == 0 && siteDetails.length > 0) {
            Array.prototype.push.apply(correctUrls, siteDetails)
        }
        else if (totalErrors.length == 0 && correctUrls.length >= 1) {
            setValid(true)
            setSiteDetails(correctUrls)
        }
        else {
            setValid(false)
        }
    }, [errors, urlData])

    const feasibleCheck = async (key, val) => {
        let promise = new Promise((resolve, reject) => {
            axios.post('/api/v1/sites/check_feasibility', {
              "sites": { "sample_page_url": val },
              page_type: "client",
              controller_name: "sites"
            }).then(res => {
                const data = res.data
                if (data.status != "success") {
                    setErrors({
                        ...errors, [key]: { url: 'This URL is not feasible' }
                    })
                    reject(false)
                }
                else {
                    resolve(true)
                }
            }).catch(res => {
                setErrors({
                    ...errors, [key]: { url: 'This URL is not feasible' }
                })
                reject(false)
            })
        });

        let result = await promise;
        return result
    }

    const validateUrl = async (key, value) => {
        let promise = new Promise((resolve, reject) => {
            let urls = Object.values(urlData).filter(x => x.url != '').map(x => x.url)
            let sitenames = urls.map(x => getSiteName(x))
            let sitename = getSiteName(value)
            let result = find_duplicate_in_array(sitenames);
            if (result.length > 0 && result.includes(sitename) && value != "") {
                setErrors({
                    ...errors, [key]: { url: "Same sites can't be added again, Please enter a different site." }
                })
                reject(false)
            }
            else {
                setErrors({
                    ...errors, [key]: { url: '' }
                })
                resolve(true)
            }
        });

        let result = await promise;
        return result
    }

    const stepValidate = async () => {
        let promise = new Promise((resolve, reject) => {
            let feasRows = Object.keys(urlData).filter(x => urlData[x].url != "").filter(x => isURL(urlData[x].url))
            if (feasRows.length === 0) {
                toast.error('please enter atleast one feasible url')
            }
            let wrongUrls = 0
            feasRows.forEach((x, index) => {
                feasibleCheck(x, urlData[x].url)
                    .then(res => {
                        console.log("res", res);
                    })
                    .catch(res => {
                        wrongUrls += 1
                    })
                    .finally(() => {
                        if (index == feasRows.length - 1 && wrongUrls == 0) {
                            let tempErrs = Object.values(errors).filter(x => x.url != '')
                            let stepPass = (feasRows.length >= 1 && tempErrs.length == 0) ? true : false
                            if (valid || stepPass) {
                                resolve(true)
                            } else {
                                reject(false)
                            }
                        }
                    })
                validateUrl(x, urlData[x].url)
                    .then(res => {
                        console.log("res", res);
                    })
                    .catch(res => {
                        wrongUrls += 1
                    })
                    .finally(() => {
                        if (index == feasRows.length - 1 && wrongUrls == 0) {
                            let tempErrs = Object.values(errors).filter(x => x.url != '')
                            let stepPass = (feasRows.length >= 1 && tempErrs.length == 0) ? true : false
                            if (valid || stepPass) {
                                resolve(true)
                            } else {
                                reject(false)
                            }
                        }
                    })
            })
        });

        let result = await promise;
        return result
    }

    const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

    const handleChangeStatus = (uploadData, status) => {
        const { meta, file } = uploadData
        if (status === "done") {

            var fileReader = new FileReader();
            fileReader.onload = function (event) {
                var workbook = XLSX.read(event.target.result, {
                    type: "binary"
                });
                workbook.SheetNames.forEach(sheet => {
                    let rowObject = XLSX.utils.sheet_to_row_object_array(
                        workbook.Sheets[sheet]
                    );
                    let headerCondition;
                    if (accessEnv.includes(environment)) {
                        headerCondition = ("page_url" in rowObject[0] && "rss_urls_crawl_limit" in rowObject[0])
                    }
                    else {
                        headerCondition = ("page_url" in rowObject[0])
                    }
                    if (rowObject.length === 0) {
                        toast.error("Uploaded file is empty")
                    }
                    else if (headerCondition) {
                        let tempObj = {
                            'row1': { url: '', rss_urls_crawl_limit: '' },
                            'row2': { url: '', rss_urls_crawl_limit: '' },
                            'row3': { url: '', rss_urls_crawl_limit: '' },
                            'row4': { url: '', rss_urls_crawl_limit: '' },
                        }

                        let newRowsArr = []

                        rowObject.forEach((each2, index) => {
                            let rss_urls_crawl_limit = each2?.rss_urls_crawl_limit ? each2.rss_urls_crawl_limit : ""
                            tempObj[`row${index + 1}`] = { url: each2.page_url, rss_urls_crawl_limit: rss_urls_crawl_limit }
                            newRowsArr.push(`row${index + 1}`)
                        })
                        setNewRows(newRowsArr)
                        setUrlData(tempObj)
                    }
                    else {
                        toast.error("Please keep column headers as mentioned in sample excel file")
                    }
                });
            };
            fileReader.readAsBinaryString(file);
        }
        else if (status === "removed") {
            setNewRows(['row1', 'row2', 'row3', 'row4'])
            setUrlData(
                {
                    'row1': { url: '', rss_urls_crawl_limit: '' },
                    'row2': { url: '', rss_urls_crawl_limit: '' },
                    'row3': { url: '', rss_urls_crawl_limit: '' },
                    'row4': { url: '', rss_urls_crawl_limit: '' },
                }
            )
        }
        else if (status === 'rejected_file_type') {
            toast.error("ERROR: Unsupported File Format!" + "\n" + "Supported file formats are .xls, .xlsx, .xlsb, .xlsm, .ods, .ots, .uos");
        }
    }


    const handleSubmit = (files, allFiles) => {
        allFiles.forEach(f => f.remove())
        toast.success("Dropzone successfully submitted !");
    }

    function isURL(str) {
        if (str == undefined) {
            return true
        }
        var url = new RegExp(/^((www\.)|(http(s*)))/i);
        return str.length < 2083 && url.test(str)
    }

    function getSiteName(str) {
        var url_arr = str.split("://")
        if (url_arr.length > 1) {
            url_arr.shift()
        }
        var sitename;
        sitename = url_arr.join("").split("/")[0].split(".").join("_")
        sitename = sitename.split("www_").join("")
        sitename = sitename.split("-").join("_")
        return sitename
    }

    function find_duplicate_in_array(arra1) {
        var object = {};
        var result = [];

        arra1.forEach(function (item) {
            if (!object[item])
                object[item] = 0;
            object[item] += 1;
        })

        for (var prop in object) {
            if (object[prop] >= 2) {
                result.push(prop);
            }
        }
        return result;
    }

    const handleURL = (value, element) => {
        setUrlData({
            ...urlData, [element]: { ...urlData[element], url: value }
        })
        let urls = Object.values(urlData).filter(x => x.url != '').map(x => x.url)
        let rss_urls_crawl_limit = urlData[element].rss_urls_crawl_limit
        if (urls.includes(value) && value != "") {
            setErrors({
                ...errors, [element]: { url: 'Entered URL is already present' }
            })
        }
        else if ((rss_urls_crawl_limit.toString().length > 0) && value.length == 0) {
            setErrors({
                ...errors, [element]: { url: 'This field is required if rss urls crawl limit has value!' }
            })
        }
        else if (rss_urls_crawl_limit.length > 0 && !/^\d+$/.test(rss_urls_crawl_limit)) {
            setErrors({
                ...errors, [element]: { rss_urls_crawl_limit: "This field should be integer!" }
            })
        }
        else if (isURL(value) || value == '') {
            setErrors({
                ...errors, [element]: { url: '' }
            })
        }
        else {
            setErrors({
                ...errors, [element]: { url: 'Please enter a valid URL' }
            })
        }
    }

    const handleRssUrlsCrawlLimit = (value, element) => {
        setUrlData({
            ...urlData, [element]: { ...urlData[element], rss_urls_crawl_limit: value }
        })
        if ((urlData[element].url.length == 0) && value.length > 0) {
            setErrors({
                ...errors, [element]: { url: 'This field is required if rss urls crawl limit has value!' }
            })
        }
        else if (value.length > 0 && !/^\d+$/.test(value)) {
            setErrors({
                ...errors, [element]: { rss_urls_crawl_limit: "This field should be integer!" }
            })
        }
        else {
            setErrors({
                ...errors, [element]: { url: '', rss_urls_crawl_limit: '' }
            })
        }
    }

    const handleRowsUpdate = () => {
        setNewRows([...newRows, `row${newRows.length + 1}`])
        let new_name = 'row' + (newRows.length + 1)
        setUrlData({ ...urlData, [new_name]: { url: '', rss_urls_crawl_limit: '' } })
    }

    const addSitetoSitegroup = () => {
        stepValidate()
            .then(result => {
                if (result) {
                    axios.post(`/api/v1/organizations/${organization_name}/sites`, {
                        submit_requirements: {
                            sitegroup_id: selectedSitegroup['value'],
                            site_add_from: "client_side",
                            site_details: siteDetails
                        },
                      page_type: "client",
                      controller_name: "sites"
                    }).then(res => {
                        toast.success(res.data.message)
                        history.push(`/organizations/${organization_name}/sites`)
                    }).catch(err => {
                        toast.error(err?.response?.data?.message)
                    })
                }
                else {
                    toast.error("Ooops! Something went wrong! Please refresh the page and continue")
                }
            })
            .catch(error => {
                console.log('error', error)
            })
    }
    const handleExcelDownload = () => {
        axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem("authToken")}`;
        axios.defaults.headers["Content-Type"] = `application/vnd.ms-excel`;
        axios.defaults.headers["Accept"] = `application/vnd.ms-excel`;
        axios({
            url: `/api/v1/download_sample_site_details?page_type=client&controller_name=organizations`,
            method: 'GET',
            responseType: 'blob'
        })
            .then((res) => {
                const url_to_download = window.URL.createObjectURL(new Blob([res.data]));
                const a_tag = document.createElement('a');
                a_tag.href = url_to_download;
                a_tag.setAttribute('download', `sample_site_urls.xls`);
                document.body.appendChild(a_tag);
                a_tag.click();
            });
        axios.defaults.headers["Content-Type"] = `application/json`;
        axios.defaults.headers["Accept"] = `application/json`;
    }

    // Helper function to convert array of arrays into
    // Array of objects
    function convertToArrayOfObjects(inputArray) {
        return inputArray.map(([value, label]) => ({ value, label }));
    }

    // Helper function to filter the values and return the object
    // based on user input
    const filterValues = (inputValue) => {
        return sitegroupNamesDropdownOptions.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    // AsyncMethod to traverse the array and return resut that matches 
    // the input query entered by the user 
    const loadOptions = (inputValue, callback) => {
        setTimeout(() => {
        callback(filterValues(inputValue));
        }, 650);
    };

    return (
        <Container fluid>
            <Breadcrumbs parent={"Add Sites To Site Group"} title={"Add Sites To Site Group"} />
            <Row className='card my-2 p-5'>
                <Row>
                    <div className='form-group d-flex justify-content-center align-items-center'>
                        <label className='form-label'
                            style={{ whiteSpace: "nowrap" }}> Select Sitegroup: </label>
                        {/* <select className='form-control mx-2 show-arrow'
                            onChange={(e) => setSelectedSiteGroup(e.target.value)}
                            defaultValue={selectedSiteGroup}>
                            <option value='none'>Select a Sitegroup</option>
                            {sgList.map(each => (
                                <option value={each[0]} key={each[0]}>
                                    {each[1]}
                                </option>
                            ))}
                        </select> */}
                        <AsyncSelect
                            className='w-100 mx-2 select_sitegroup'
                            name='site_name'
                            value={selectedSitegroup}
                            placeholder="Select a Site Group"
                            cacheOptions
                            defaultOptions={sitegroupNamesDropdownOptions}
                            onChange={res => setSelectedSitegroup(res)}
                            loadOptions={loadOptions}
                        />
                    </div>
                    <Table hover bordered className='site-details-table my-3'>
                        <thead>
                            <tr>
                                <th>SI.No</th>
                                <th>URL</th>
                                {accessEnv.includes(environment) ? <th>Rss urls crawl limit</th> : ""}
                            </tr>
                        </thead>
                        <tbody>
                            {newRows.map((element, index) => {
                                return (
                                    <tr key={element}>
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td style={{ width: accessEnv.includes(environment) ? "70%" : "100%" }}>
                                            <Input
                                                type="text" name={`url${index}`} id={`url${index}`}
                                                onInput={(e) => { handleURL(e.target.value, element) }}
                                                value={urlData ? urlData[element]?.url : ''}
                                                placeholder='http://www.ebay.com/sch/DSLR-Cameras/31388/bn_732/i.html' />
                                            {errors && errors[element] && <p className='m-0 error-msg mt-1 mb-2'>
                                                {errors[element].url}
                                            </p>}
                                        </td>
                                        {accessEnv.includes(environment) ?
                                            <td>
                                                <Input
                                                    type="text" name={`rss_urls_crawl_limit${index}`}
                                                    id={`rss_urls_crawl_limit${index}`}
                                                    onChange={(e) => { handleRssUrlsCrawlLimit(e.target.value, element) }}
                                                    value={urlData ? urlData[element]?.rss_urls_crawl_limit : ''}
                                                    placeholder='It will be 30k by default' />
                                                {errors && errors[element] && <p className='m-0 error-msg mt-1 mb-2'>
                                                    {errors[element].rss_urls_crawl_limit}
                                                </p>}
                                            </td>
                                            : ""
                                        }
                                    </tr>)
                            })}
                        </tbody>
                    </Table>
                    <button className='btn btn-outline-primary my-3'
                        onClick={(e) => { handleRowsUpdate() }}>
                        + Add more sites
                    </button>
                </Row>
                <div className='my-4 d-flex justify-content-center fw-bold'>
                    OR
                </div>
                <Row>
                    <Col lg={3}>
                        <h6 className='fw-bold d-flex justify-content-end'>
                            Add Sites via a file: </h6>
                    </Col>
                    <Col lg={5}>
                        <Form onSubmit={handleSubmit}>
                            <div className="dz-message needsclick">
                                <Dropzone 
                                getUploadParams={getUploadParams} 
                                maxFiles={1}
                                onChangeStatus={handleChangeStatus} 
                                multiple={false}
                                canCancel={true} 
                                inputContent="Upload / Drop an Excel File"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.ms-excel.sheet.binary.macroEnabled.12,application/vnd.oasis.opendocument.spreadsheet,application/vnd.ms-excel.sheet.macroEnabled.12,application/vnd.oasis.opendocument.spreadsheet-template"
                                    styles={{
                                        dropzone: { height: 40 },
                                        dropzoneActive: { borderColor: 'green' },
                                    }}
                                />
                            </div>
                        </Form>
                        <p className='text-muted m-0 mt-2 text-sm'>
                            Support file formats are .xls, .xlsx, .xlsb, .xlsm, .ods, .ots, .uos
                        </p>
                    </Col>
                </Row>
                <p className='my-5 d-flex align-center'>
                    <button onClick={handleExcelDownload}
                        className='btn text-primary'> Click here for a sample Excel file. </button>
                    Please do not change column headers
                </p>
                <div>
                    <button className='btn float-right btn-success'
                        onClick={addSitetoSitegroup}>
                        Add sites
                    </button>
                </div>
            </Row>
        </Container>
    )
}
