import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, } from "reactstrap";
import { Home } from "react-feather";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import DataTable from "react-data-table-component";
import axios from "axios";
import Loader from "react-loader-spinner";

export default function VolumeOfListingsFromTopCountries() {
    const search = useLocation().search;
    const commit = new URLSearchParams(search).get("commit");
    const [platformType, setPlatformType] = useState({ label: 'All', value: null });
    const [comparisionValue, setComparisionValue] = useState({ label: "Day on Day", value: "day" });
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [loading,setLoading]=useState(false);
    const platformTypesOptions = [
        { label: 'All', value: null },
        { label: "f500", value: 'f500' },
        { label: "Job Board", value: "job_board" },
        { label: "Platforms", value: "platforms" },
        { label: "Common", value: 'common' }
    ]
    const comparisionOptions = [
        { label: "Week of Week", value: 'week' },
        { label: "Month on Month", value: "month" },
        { label: "Day on Day", value: "day" }
    ]
    const searchParams = {
        comparisionValue: new URLSearchParams(search).get("filter[comparision_type]"),
        platformType: new URLSearchParams(search).get("filter[platform_type]")
    }


    useEffect(() => {
        setSearchParamsData()
        
    }, []);

    function setSearchParamsData(){
        console.log(searchParams)
        if (searchParams.comparisionValue && searchParams.comparisionValue != comparisionValue.value) {
            setComparisionValue(comparisionOptions.filter(item => item.value == searchParams.comparisionValue)[0])
        }
        if (searchParams.platformType && searchParams.platformType != platformType.value) {
            let filterPlatform=platformTypesOptions.filter(item => item.value == searchParams.platformType)
            setPlatformType(filterPlatform.length == 0 ?  { label: 'All', value: null } : filterPlatform[0] )
        }
        if (commit == 'Get Data' && searchParams.comparisionValue) {

            fetchData(searchParams.comparisionValue, searchParams?.platformType || '')
        }
        else{
            fetchData()
        }
    }

    function fetchData(ComparisionValue=comparisionValue.value,PlatformType=platformType.value){
        setLoading(true)
        axios.get(`/api/v1/jobspikr_v3_internal/jobspikr_graphs/volume_of_listings_from_top_countries?filter[comparision_type]=${ComparisionValue}&filter[platform_type]=${PlatformType ? PlatformType : ''}`)
            .then(res => {
                let newColumns=res.data.dates.map(item =>({name: item, sortable: true, center: true, cell: row => <div><span className="fw-bold">{row[item]}</span><br />{row[`${item}_percentage`]}</div> }))
                setColumns([{name: "Country", selector: "country", center: true, sortable: true, cell: row=> <div className="fw-bold">{row["country"]}</div>},...newColumns]);
                setData(res.data.data)
                setLoading(false)
            })
            .catch(err => {
                console.log(err?.message || 'Oops something went wrong')
                setLoading(false)
            })
    }

    const handleGetData= () =>{
        window.location.href=`/jobspikr_v3_internal/jobspikr_graphs/volume_of_listings_from_top_countries?filter[comparision_type]=${comparisionValue.value}&filter[platform_type]=${platformType?.value || ''}&commit=${'Get Data'}`
    }

    
    return <Container fluid>
        <div className="page-title">
            <Row>
                <Col xs="6" className="flex-column">
                    <h4>Internal Dashboard</h4>

                </Col>
                <Col xs="6">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={'/internal'}>
                                <Home />
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            Internal QA
                        </BreadcrumbItem>
                        <BreadcrumbItem className="fw-bold">
                            {commit == 'Get Data' ? <a className="link-primary fw-normal" href={'/jobspikr_v3_internal/jobspikr_graphs/volume_of_listings_from_top_countries'}>Volume of job listings from top countries</a> : 'Volume of job listings from top countries'}
                        </BreadcrumbItem>
                        {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                            Search
                        </BreadcrumbItem>}
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
        <Card>
            <CardBody>
                <Row className="align-items-end">
                    <Col sm="4">
                        <label className="fw-bold">Comparision type</label>
                        <Select
                            value={comparisionValue}
                            options={comparisionOptions}
                            onChange={option => setComparisionValue(option)}
                        />
                    </Col>
                    <Col sm="3">
                        <label className="fw-bold">Platform type</label>
                        <Select
                            value={platformType}
                            options={platformTypesOptions}
                            onChange={option => setPlatformType(option)} />
                    </Col>
                    <Col sm="3">
                        <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
        {loading ?<Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> :<Card>
            <CardBody>
                <p className="fw-bold">{searchParams.comparisionValue ? searchParams.comparisionValue : 'Day on Day'} comparision of volume of job listings from top 10 countries</p>
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    highlightOnHover
                    noHeader />
            </CardBody>
        </Card>}
    </Container>
}