import React from 'react'
import { Accordion, AccordionItem, AccordionItemHeading, 
    AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

export default function MyAccordion({title, children, preExpanded=[]}) {
    return (
        <Accordion allowZeroExpanded className='shadow shadow-showcase' preExpanded={preExpanded}>
            <AccordionItem uuid='one'>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        {title}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    {children}
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
    )
}
