import React, { useEffect, useState } from "react";
import Breadcrumb from '../../global/Breadcrumb';
import {
    CardBody, Card,
    Container,
    CardHeader,
    Row,
    Col,
    Modal,
    ModalBody, ModalHeader, ModalFooter
} from 'reactstrap';
import Select from 'react-select';
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";

export default function DataStockFiles() {
    const [siteName, setSiteName] = useState('');
    const [folderName, setFolderName] = useState('');
    const [domainName, setDomainName] = useState('');
    const [bizMappingStatus, setBizMappingStatus] = useState('');
    const [categories, setCategories] = useState([]);
    const [modal, setModal] = useState(false);
    const [updateFields, setUpdateField] = useState({})
    const [selectedFile, setSelectedFile] = useState({});
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [bizMappingOptions, setBizMappingOptions] = useState([]);
    const [dataRepoFileCount, setDataRepoFileCount] = useState(0);

    useEffect(() => {
        fetchDataRepoFiles(1);
    }, []);

    const handleSearch = event => {
        event.preventDefault();
        axios.get(`/api/v1/admins/data_repo_files?filterrific[search_by_site_name]=${siteName}&filterrific[search_by_folder_name]=${folderName}&filterrific[search_by_domain_name]=${domainName}&filterrific[search_by_biz_mapping_status]=${bizMappingStatus?.label || ''}`)
            .then(res => {
                console.log(res, 'response')
                setData(res.data.data_repo_files);
                setBizMappingOptions(res.data.biz_mapping_statuses);
                setDataRepoFileCount(res.data.total_count);
            })
            .catch(err => {
                console.error(err.message)
            });
    }

    const handleEdit = (row) => {
        setSelectedFile(row);
        console.log(row, 'refresh')
        setUpdateField({
            site_name: row.site_name,
            next_refresh_date: row.next_refresh_date,
            biz_mapping_status: { label: row.biz_mapping_status, value: row.biz_mapping_status == 'done' ? 0 : 1 },
            price: row.price,
            categories: row.data_repo_categories

        })
        setModal(true);
    }

    const handlePageChange = page => {
        setPage(page);
        fetchDataRepoFiles(page);
    }

    const handleFormReset = event => {
        event.preventDefault();
        setDomainName('');
        setFolderName('');
        setSiteName('');
        setBizMappingStatus('');
    }


    const handleUpdate = () => {
        axios.patch(`/api/v1/admins/data_repo_files/${selectedFile.id}`, {
            data_repo_file: {
                site_name: updateFields.site_name,
                next_refresh_date: updateFields.next_refresh_date,
                biz_mapping_status: updateFields.biz_mapping_status.label,
                price: updateFields.price,
                categories: updateFields.categories.map(item => item.value)
            }
        }).then(res => {
            if (res.data.status == 200) {
                setModal(false);
                toast.success(res.data.message);
            }
            else {
                setModal(false);
                toast.success(res.data.message);
            }
        }).catch(err => {
            console.error(err.message)
        });
    }

    function fetchDataRepoFiles(page) {
        axios.get(`/api/v1/admins/data_repo_files?page=${page}`)
            .then(res => {
                setData(res.data.data_repo_files);
                setBizMappingOptions(res.data.biz_mapping_statuses);
                setCategories(res.data.categories);
                setDataRepoFileCount(res.data.total_count);
            })
            .catch(err => {
                console.error(err.message)
            })
    }

    const fetchNewRepo = () => {
        axios.get('/api/v1/admins/data_repo_files/fetch_new_folder')
            .then(res => {
                if (res.data.status == "success") {
                    toast.success(res.data.message)
                }
                else {
                    toast.error("Fetching New Repo Failed")
                }
            }).catch(err => {
                console.error(err.message)
            });
    }


    const ExpandableComponent = ({ data }) => <div className="ps-3">{console.log(data.available_fields, 'Data At Component')}<span className="fw-bold" >Available Fields:</span>{data.available_fields || 'NA'}</div>;

    const columns = [
        {
            name: <div style={{color: "#337ab7"}}>Site name</div>, selector: 'site_name', sortable: true, center: true, grow: 2, cell: row => <div>
                <div className="m-1">{row.site_name}</div>
                <div className="m-1"><i className="fa fa-calendar"></i> { row.crawl_from_date !=null ? moment(row.crawl_from_date).format("Do MMM YYYY"): 'na'}-{ row.crawl_end_date != null ? moment(row.crawl_end_date).format("Do MMM YYYY") : 'na'}</div><br />
                <div className="badge bg-primary">Sample <i className={`fa ${row.is_sample_file_present ? 'fa-check' : 'fa-times'}`}></i> </div>
                <div className="badge bg-info">Meta <i className={`fa ${row.is_meta_file_present ? 'fa-check' : 'fa-times'}`}></i> </div>
                {<div className="badge bg-success m-1">{row.data_repo_file_status[0].toUpperCase()+row.data_repo_file_status.slice(1)}</div>}
                <div className="badge bg-primary m-1">Marketing Sample <i className={`fa ${row.marketing_sample ? 'fa-check' : 'fa-times'}`}></i> </div>
            </div>
        },
        { name: <div style={{color: "#337ab7"}}>Folder Name</div>, selector: 'folder_name', sortable: true, center: true, },
        { name: <div style={{color: "#337ab7"}}>Domain Name</div>, selector: 'domain_name', sortable: true, center: true },
        { name: 'Records Count', selector: 'records_count', center: true,  },
        { name: 'Next Refresh Date', selector: 'next_refresh_date', center: true,cell: row => <div>{ row.next_refresh_date != null ? moment(row.next_refresh_date).format("Do MMM YYYY"): 'na'}</div> },
        { name: <div style={{color: "#337ab7"}}>Api last updated</div>, selector: 'api_last_updated', sortable: true, center: true,cell: row => <div>{row.api_last_updated != null ? moment(row.api_last_updated).format("Do MMM YYYY") : 'na'}</div> },
        { name: <div style={{color: "#337ab7"}}>Biz Mapping Status</div>, selector: 'biz_mapping_status', sortable: true, center: true },
        { name: <div style={{color: "#337ab7"}}>Price</div>, selector: 'price', sortable: true, center: true },
        { name: <div style={{color: "#337ab7"}}>Actions</div>, selector: 'actions', center: true, cell: row => <button className="btn btn-primary"  onClick={() => handleEdit(row)} >Edit</button> },

    ];

    function displayModal() {
        return <Modal
            className="modal-xl"
            isOpen={modal}
            toggle={() => setModal(false)}>
            <ModalHeader close={<button className="btn-close" onClick={() => setModal(false)}></button>} toggle={() => setModal(false)}>
                Edit Data Repo File
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <Row>
                            <Col className="m-2">
                                <h6 className='fw-bold'>Select Categories</h6>

                            </Col>
                            <Col className="m-2" >
                                <Select
                                    value={updateFields.categories}
                                    options={categories}
                                    isMulti
                                    onChange={(options) => setUpdateField({ ...updateFields, categories: options })}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="m-2">
                                <h6 className='fw-bold'>Site Name</h6>

                            </Col>
                            <Col className="m-2" >
                                <input
                                    value={updateFields.site_name}
                                    type={'text'} className='form-control'
                                    onChange={(event) => setUpdateField({ ...updateFields, site_name: event.target.value })} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="m-2">
                                <h6 className='fw-bold'>Next Refresh Date</h6>
                            </Col>
                            <Col className="m-2">
                                <DatePicker
                                    className="form-control"
                                    selected={updateFields.next_refresh_date != null ? new Date(updateFields.next_refresh_date) : null}
                                    onChange={(value)=>setUpdateField({ ...updateFields, next_refresh_date: value })}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Select Next Refresh Date"
                                    maxDate={new Date()}
                                    />
                                
                            </Col>
                        </Row>
                        <Row>
                            <Col className="m-2">
                                <h6 className='fw-bold'>Biz Mapping Status</h6>
                            </Col>
                            <Col className="m-2">
                                <Select
                                    value={updateFields.biz_mapping_status}
                                    options={bizMappingOptions}
                                    onChange={(value) => setUpdateField({ ...updateFields, biz_mapping_status: value })} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="m-2">
                                <h6 className='fw-bold'>Price</h6>
                            </Col>
                            <Col className="m-2">
                                <input
                                    type={'text'}
                                    value={updateFields.price}
                                    className='form-control'
                                    onChange={(event) => setUpdateField({ ...updateFields, price: event.target.value })} />
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col className="m-2">
                                <h6 className='fw-bold'>Folder Name</h6>

                            </Col>
                            <Col className="m-2">
                                <div>
                                    {selectedFile.folder_name || 'na'}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="m-2">
                                <h6 className='fw-bold'>Domain Name</h6>

                            </Col>
                            <Col className="m-2">
                                <div>
                                    {selectedFile.domain_name || 'na'}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="m-2">
                                <h6 className='fw-bold'>Available Fields</h6>
                            </Col>
                            <Col className="m-2">
                                <div>
                                    {selectedFile.available_fields || 'na'}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="m-2">
                                <h6 className='fw-bold'>Crawl From Date</h6>
                            </Col>
                            <Col className="m-2">
                                { selectedFile.crawl_from_date != null ? moment(selectedFile.crawl_from_date).format("Do MMM YYYY") : 'na'}

                            </Col>
                        </Row>
                        <Row>
                            <Col className="m-2">
                                <h6 className='fw-bold'>Crawl End Date</h6>
                            </Col>
                            <Col className="m-2">
                                { selectedFile.crawl_end_date != null ? moment(selectedFile.crawl_end_date).format("Do MMM YYYY") : 'na'}

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-success" onClick={handleUpdate}  >Update</button>
            </ModalFooter>
        </Modal>
    }

    function displayForm() {
        return (
            <Card>
                <CardBody>
                    <Row>
                        <Col md={3} className='my-2'>
                            <h6 className='fw-bold'>Site Name</h6>
                            <input type="text" className='form-control' value={siteName}
                                onChange={(e) => setSiteName(e.target.value)} />
                        </Col>
                        <Col md={3} className='my-2'>
                            <h6 className='fw-bold'>Folder Name</h6>
                            <input type="text" className='form-control' value={folderName}
                                onChange={(e) => setFolderName(e.target.value)} />
                        </Col>
                        <Col md={3} className='my-2'>
                            <h6 className='fw-bold'>Domain Name</h6>
                            <input type="text" className='form-control' value={domainName}
                                onChange={(e) => setDomainName(e.target.value)} />
                        </Col>
                        <Col md={3} className='my-2'>
                            <h6 className='fw-bold'>Biz mapping status</h6>
                            <Select
                                value={bizMappingStatus}
                                options={bizMappingOptions}
                                onChange={option => setBizMappingStatus(option)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} className='my-2'>
                            <button className="btn btn-primary w-100" onClick={handleSearch} >Search</button>
                        </Col>
                        <Col md={3} className='my-2'>
                            <button className="btn btn-danger w-100" onClick={handleFormReset} >Form Reset</button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>);
    }

    function displayTable() {
        return (
            <Card className='pt-3'>
                <CardHeader className="d-flex justify-content-between">
                    <div className="fw-bold" >Data Repo</div>
                    <div>
                        <button className='btn btn-primary' onClick={fetchNewRepo} >Look for new repo</button>
                    </div>
                </CardHeader>
                <DataTable
                    title={
                        <div>
                            {dataRepoFileCount > 10 ?
                                <div>
                                    Displaying Data repo file
                                    <span className="fw-bold">{page == 1 ? page : (page - 1) * 10 + 1} - {dataRepoFileCount < page * 10 ? dataRepoFileCount : page * 10}</span> of  <span className="fw-bold">{dataRepoFileCount}</span> in total
                                </div>
                                :
                                <div>
                                    Displaying Data repo file
                                    <span className="fw-bold">{1} - {dataRepoFileCount}</span> of  <span className="fw-bold">{dataRepoFileCount}</span> in total
                                </div>
                            }
                        </div>}
                    columns={columns}
                    data={data}
                    pagination
                    paginationServer
                    paginationTotalRows={dataRepoFileCount}
                    paginationPerPage={10}
                    paginationComponentOptions={{
                        noRowsPerPage: true
                    }}
                    expandableRows={true}
                    expandableRowExpanded={() => true}
                    onChangePage={handlePageChange}
                    highlightOnHover={true}
                    expandableRowsComponent={<ExpandableComponent />}
                />
            </Card>
        )
    }

    return <Container fluid>
        {displayModal()}
        <Breadcrumb title="New Requirements" parent="Data Stock Files" />
        {displayForm()}
        {displayTable()}

    </Container>
}