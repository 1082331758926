import React, {useState, useEffect} from 'react'
import { CardBody, Col, Card, Container, Row, CardHeader } from 'reactstrap';
import Breadcrumb from '../../global/Breadcrumb'
import axios from 'axios'
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';

export default function New() {

  const [accessTokenExpiry, setAccessTokenExpiry] = useState();
  const [zbOauth2AuthUrl, setZbOauth2AuthUrl] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true)
    axios.get(`/api/v1/admins/zohobooks_oauth2_callbacks/new`)
      .then(res => {
        setAccessTokenExpiry(res.data?.access_token_expiry)
        setZbOauth2AuthUrl(res.data?.zb_oauth2_auth_url)
        setLoading(false)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
        setLoading(false)
      })
  }, []);

  return (
    <Container fluid>
      <Breadcrumb parent={"Zohobooks Authentication"} title={"Zohobooks Authentication"} />
      <Card>
        <CardBody>
          <Row className="justify-content-md-center">
            <Col md={9} className='my-2'>
              <p><b>Access Token expires at:</b> {accessTokenExpiry}</p>
            </Col>
            <Col md={3} className='my-2'>
              <a href={zbOauth2AuthUrl} target="_blank" className='btn btn-primary w-100'>Connect to Zohobooks</a>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  )
}
