import React from 'react';
import { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { SitesContext } from '../Context/SitesState';
import { Modal, Button, ModalBody, ModalHeader, ModalFooter, FormGroup, Input, Label } from 'reactstrap';
import SpinnerButton from '../../../Common/SpinnerButton/SpinnerButton.component';
import getSymbolFromCurrency from 'currency-symbol-map';
import axios from 'axios';
import {toast} from 'react-toastify';

export default function SetBillingAlarms() {
  const {organization_name} = useParams()
  const {country} = useContext(SitesContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [billingAlarms, setBillingAlarms] = useState({
    'records_count': { 'alert_by': 'records_count', 'records_count': 0, selected: true },
    'bill_amount': { 'alert_by': 'bill_amount', 'bill_amount': 0, selected: false },
  })

  // Function to open and close modal
  function openCloseModal() {
    setModalOpen(!modalOpen);
  }

  // Function to set radio input selections
  const handleRadioChange = (e, name) => {
    if (name === 'bill_amount') {
      setBillingAlarms({
        bill_amount: { ...billingAlarms.bill_amount, selected: true },
        records_count: { ...billingAlarms.records_count, selected: false },
      })
    }
    else {
      setBillingAlarms({
        bill_amount: { ...billingAlarms.bill_amount, selected: false },
        records_count: { ...billingAlarms.records_count, selected: true },
      })
    }
  }

  // Helper method to send POST request
  const postBillingAlarms = () => {
    setLoading(true)
    if (billingAlarms.records_count.selected) {
      axios.post(`/api/v1/organizations/${organization_name}/organization_billing_alerts`, {
        billing_alerts: billingAlarms.records_count
      }).then(res => {
        toast.success(res?.data?.message)
        setModalOpen(false)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })
      setLoading(false)
    }
    else {
      axios.post(`/api/v1/organizations/${organization_name}/organization_billing_alerts`, {
        billing_alerts: billingAlarms.bill_amount
      }).then(res => {
        toast.success(res?.data?.message)
        setModalOpen(false)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })
      setLoading(false)
    }
  }

  return (
    <div>
      <Button className='mx-2' color='primary' onClick={() => openCloseModal()}>Set Billing Alarms</Button>
      <Modal funk={"true"} isOpen={modalOpen} toggle={() => openCloseModal()} backdrop='static' size='lg'>
        <ModalHeader toggle={() => openCloseModal()}>
          Set Billing Alarms
        </ModalHeader>
        <ModalBody className='p-5'>
          <div>
            <h6 className='fw-bold mb-5'>
              Notify me when:
            </h6>
            <FormGroup check className='d-flex my-3'>
              <Input name="radio1" type="radio" className='me-3'
                checked={billingAlarms.records_count.selected}
                onChange={(e) => handleRadioChange(e, 'records_count')} />
              {' '}
              <Label check className='px-1'>
                total records count for the month exceeds
              </Label>
              <Input className='form-control ms-2' type='text' placeholder='100'
                value={billingAlarms.records_count.records_count} onChange={(e) => setBillingAlarms({
                  ...billingAlarms, records_count: { ...billingAlarms.records_count, records_count: e.target.value }
                })}
                disabled={!billingAlarms.records_count.selected} style={{ width: 240 }} />
            </FormGroup>
            <FormGroup check className='d-flex my-3'>
              <Input name="radio1" type="radio" className='me-3'
                checked={billingAlarms.bill_amount.selected}
                onChange={(e) => handleRadioChange(e, 'bill_amount')} />
              {' '}
              <Label check className='px-1'>
                total charges for the month exceeds
              </Label>
              <div className='d-flex align-items-center ms-2'>
                <button className='btn text-primary px-1'>{getSymbolFromCurrency(country)}</button>
                <Input className='form-control ms-2' type='number'
                  value={billingAlarms.bill_amount.bill_amount} onChange={(e) => setBillingAlarms({
                    ...billingAlarms, bill_amount: { ...billingAlarms.bill_amount, bill_amount: e.target.value }
                  })}
                  disabled={!billingAlarms.bill_amount.selected} style={{ width: 240 }} />
              </div>
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <SpinnerButton color='primary' loading={loading} disabled={loading} onClick={() => postBillingAlarms()}>
            Set Alarm
          </SpinnerButton>
        </ModalFooter>
      </Modal>
    </div>
  )
}
