import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";
import moment from "moment";
import axios from "axios";

import { KIBANA_PLM_URL } from "../../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function AvgTimeStatsGraphsPerPipelineStage() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const tabNameFromSearchUrl = new URLSearchParams(search).get("filter[tab_name]");
  const tabNameFromUrl = window.location.hash.substr(1);
  const searchParams = {
    geoCluster: new URLSearchParams(search).get("filter[geo_cluster]"),
    seleniumCluster: new URLSearchParams(search).get("filter[selenium_cluster]"),
    avgTimeType: new URLSearchParams(search).get("filter[avg_time_type]"),
    fromTime: new URLSearchParams(search).get("filter[time]"),
    tabName: tabNameFromSearchUrl ? tabNameFromSearchUrl : tabNameFromUrl
  }
  const [geoCluster, setGeoCluster] = useState({label: 'All', value: '*'});
  const [geoClusterOptions, setGeoClusterOptions] = useState([]);
  const [seleniumCluster, setSeleniumCluster] = useState({label: 'All', value: '*'});
  const [avgTimeType, setAvgTimeType] = useState({ label: 'All', value: '*' });
  const [fromTime, setFromTime] = useState({ label: 'Last 4 hours', value: 'now-4h' });
  const [activeTab, setActiveTab] = useState(tabNameFromUrl ? tabNameFromUrl : 'rss');

  const seleniumClusterOptions = [
    { label: 'All', value: '*' },
    { label: 'gce_us', value: 'gce_us' },
    { label: 'gce_india', value: 'gce_india' },
    { label: 'not_specified_any', value: 'not_specified_any' },
    { label: 'aws_india', value: 'aws_india' },
  ];

  const avgTimeTypeOptions = [
    { label: 'All', value: '*' },
    { label: 'Average Time Duration', value: 'avg_time_duration' },
    { label: 'Average Normalized Time', value: 'avg_normalized_time' },
  ];

  const timeOptions = [
    { label: 'Today', value: 'now%2Fd' },
    { label: 'Last 30 minutes', value: 'now-30m' },
    { label: 'Last 1 hour', value: 'now-1h' },
    { label: 'Last 2 hours', value: 'now-2h' },
    { label: 'Last 4 hours', value: 'now-4h' },
    { label: "Last 10 hours", value: "now-10h" },
    { label: 'Last 12 hours', value: 'now-12h' },
    { label: 'Last 24 hours', value: 'now-24h' },
    { label: 'Last 2 days', value: 'now-2d' },
    { label: 'Last 3 days', value: 'now-3d' },
    { label: 'Last 4 days', value: 'now-4d' },
    { label: 'Last 7 days', value: 'now-7d' },
    { label: 'Last 15 days', value: 'now-15d' },
    { label: 'Last 30 days', value: 'now-30d' },
    { label: 'Last 60 days', value: 'now-60d' },
    { label: 'Last 1 year', value: 'now-1y' }
  ];

  const [UrlParams, setUrlParams] = useState({
    geoCluster: "*",
    seleniumCluster: '*',
    avgTimeType: '*',
    fromTime: 'now-4h',
    tabName: "rss"
  })

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let geo_clusters = []
        res.data.geo_clusters.forEach(item => {
          geo_clusters.push({ label: item, value: item })
        })
        geo_clusters.push({ label: 'All', value: '*' })
        setGeoClusterOptions(geo_clusters);
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      });
  }, []);

  function humanize(str) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.geoCluster && searchParams.geoCluster != geoCluster.value) {
      setGeoCluster({ label: searchParams.geoCluster  == '*' ? 'All' :  searchParams.geoCluster, value: searchParams.geoCluster });
    }
    if (searchParams.seleniumCluster && searchParams.seleniumCluster != seleniumCluster.value) {
      setSeleniumCluster({ label: searchParams.seleniumCluster  == '*' ? 'All' :  searchParams.seleniumCluster, value: searchParams.seleniumCluster });
    }
    if (searchParams.avgTimeType && searchParams.avgTimeType != avgTimeType.value) {
      setAvgTimeType({ label: searchParams.avgTimeType  == '*' ? 'All' :  humanize(searchParams.avgTimeType), value: searchParams.avgTimeType });
    }
    if (searchParams.fromTime && searchParams.fromTime != fromTime.value) {
      setFromTime(timeOptions.filter(item => item.value == searchParams.fromTime)[0]);
    }
    if (searchParams.tabName && searchParams.tabName != activeTab) {
      setActiveTab(searchParams.tabName);
    }
    if (commit == 'Get Data') {
      setUrlParams(searchParams)
    }
  }

  function titleize(value){
    return value.substr(0,1).toUpperCase() + value.substr(1).toLowerCase();
  }

  function avgNormalizedTimeText() {
    return "The time duration has been normalized for 2000 urls in every stage apart rss. The time duration is considered in seconds."
  }

  function avgTimeDurationText() {
    return "This is the exact time duration taken by the urls in the segment. The time duration is considered in seconds."
  }

  function kibanaToTimeVal() {
    return UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
  }

  function rssKibanaUrl(){
    console.log("kibanaToTimeVal", kibanaToTimeVal());
    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${UrlParams.fromTime}',mode:quick,to:'${kibanaToTimeVal()}'))&_a=(filters:!(),linked:!f,query:(language:lucene,query:(query_string:(analyze_wildcard:!t,query:'rss_status_tracker:+true+AND+geo_cluster:+%22${UrlParams.geoCluster}%22+AND+selenium_cluster:+%22${UrlParams.seleniumCluster}%22'))),uiState:(vis:(colors:('Average+Time+Duration':%23508642))),vis:(aggs:!((id:'1',params:(customLabel:'Average+Time+Duration',field:time_duration),schema:metric,type:avg),(id:'2',params:(field:site_name,order:desc,orderBy:'1',size:30),schema:segment,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Average+Time+Duration+Overall+-+Rss',type:histogram))`
  }

  function avgNormalizedTimeFeedcrawlKibanaUrl(){
    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${UrlParams.fromTime}',mode:quick,to:'${kibanaToTimeVal()}'))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'diskfetcher_crawl_time_tracker:true%20AND%20geo_cluster:%20%22${UrlParams.geoCluster}%22%20AND%20selenium_cluster:%20%22${UrlParams.seleniumCluster}%22')),uiState:(vis:(colors:('Average%20Normalized%20Time':%230A437C))),vis:(aggs:!((id:'1',params:(customLabel:'Average%20Normalized%20Time',field:normalized_time),schema:metric,type:avg),(id:'2',params:(field:site_name,order:desc,orderBy:'1',size:30),schema:segment,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Average%20Normalized%20Time%20Overall%20-%20Feedcrawl',type:histogram))`
  }

  function avgTimeDurationFeedcrawlKibanaUrl(){
    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${UrlParams.fromTime}',mode:quick,to:'${kibanaToTimeVal()}'))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'diskfetcher_crawl_time_tracker:true%20AND%20geo_cluster:%20%22${UrlParams.geoCluster}%22%20AND%20selenium_cluster:%20%22${UrlParams.seleniumCluster}%22')),uiState:(vis:(colors:('Average%20Time%20Duration':%230A437C))),vis:(aggs:!((id:'1',params:(customLabel:'Average%20Time%20Duration',field:time_duration),schema:metric,type:avg),(id:'2',params:(field:site_name,order:desc,orderBy:'1',size:30),schema:segment,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Average%20Time%20Duration%20Overall%20-%20Feedcrawl',type:histogram))`
  }

  function avgNormalizedTimeEdnuKibanaUrl(ednu){
    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${UrlParams.fromTime}',mode:quick,to:'${kibanaToTimeVal()}'))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'ednu_status_tracker:true%20AND%20ednu_stage:${ednu}%20AND%20geo_cluster:%20%22${UrlParams.geoCluster}%22%20AND%20selenium_cluster:%20%22${UrlParams.seleniumCluster}%22')),uiState:(vis:(colors:('Average%20Normalized%20Time':%23C15C17))),vis:(aggs:!((id:'1',params:(customLabel:'Average%20Normalized%20Time',field:normalized_time),schema:metric,type:avg),(id:'2',params:(field:site_name,order:desc,orderBy:'1',size:30),schema:segment,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Average%20Normalized%20Time%20Overall%20-%20${titleize(ednu)}',type:histogram))`
  }

  function avgTimeDurationEdnuKibanaUrl(ednu){
    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${UrlParams.fromTime}',mode:quick,to:'${kibanaToTimeVal()}'))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'ednu_status_tracker:true%20AND%20ednu_stage:${ednu}%20AND%20geo_cluster:%20%22${UrlParams.geoCluster}%22%20AND%20selenium_cluster:%20%22${UrlParams.seleniumCluster}%22')),uiState:(vis:(colors:('Average%20Time%20Duration':%23C15C17))),vis:(aggs:!((id:'1',params:(customLabel:'Average%20Time%20Duration',field:time_duration),schema:metric,type:avg),(id:'2',params:(field:site_name,order:desc,orderBy:'1',size:30),schema:segment,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Average%20Time%20Duration%20Overall%20-%20${titleize(ednu)}',type:histogram))`
  }

  const handleGetData = () => {
    window.location.href = `/internal/overall_system_reports/overall_avg_time_stats_per_pipeline_stage?filter[geo_cluster]=${geoCluster.value}&filter[selenium_cluster]=${seleniumCluster.value}&filter[avg_time_type]=${avgTimeType.value}&filter[time]=${fromTime.value}&filter[tab_name]=${activeTab}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/overall_system_reports/overall_avg_time_stats_per_pipeline_stage">Avg Time Stats graphs Per Pipeline Stage</a> : 'Avg Time Stats graphs Per Pipeline Stage'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
          </BreadcrumbItem>}
        </Breadcrumb>
      </Col>
    </Row>
  </div>

  <Card>
    <CardBody>
      <Row>
        <Col sm="4">
          <label className="fw-bold">Geo Cluster</label>
          <Select
            value={geoCluster}
            options={geoClusterOptions}
            onChange={option => setGeoCluster(option)} />
        </Col>
        <Col sm="4">
          <label className="fw-bold">Selenium Cluster</label>
          <Select
            value={seleniumCluster}
            options={seleniumClusterOptions}
            onChange={option => setSeleniumCluster(option)} />
        </Col>
      </Row>
      <Row className="align-items-end mt-2">
        <Col sm="4">
          <label className="fw-bold">Time</label>
          <Select
            value={fromTime}
            options={timeOptions}
            onChange={option => setFromTime(option)} />
        </Col>
        <Col sm="4">
          <label className="fw-bold">Average Time Type</label>
          <Select
            value={avgTimeType}
            options={avgTimeTypeOptions}
            onChange={option => setAvgTimeType(option)} />
        </Col>
        <Col sm="4">
          <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
        </Col>
      </Row>
    </CardBody>
  </Card>
  <Card>
    <CardBody>
      <div className="mb-1">
        <b>NOTE:</b>
        If the graph is not shown after your form submission then you should do either maximize its size(the option is available on the right side of graph) or minimize the sidebar.
      </div>
      <Nav tabs>
        <NavItem>
          <NavLink className={activeTab == 'rss' ? 'active' : ''} onClick={() => setActiveTab('rss')}>
            Rss
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={activeTab == 'feedcrawl' ? 'active' : ''} onClick={() => setActiveTab('feedcrawl')}>
            Feedcrawl
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={activeTab == 'extraction' ? 'active' : ''} onClick={() => setActiveTab('extraction')}>
            Extraction
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={activeTab == 'dedup' ? 'active' : ''} onClick={() => setActiveTab('dedup')}>
            Dedup
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={activeTab == 'normalization' ? 'active' : ''} onClick={() => setActiveTab('normalization')}>
            Normalization
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={activeTab == 'upload' ? 'active' : ''} onClick={() => setActiveTab('upload')}>
            Upload
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={'rss'}>
          <div className="panel-body">
            <center>
              <h5 className="mb-2">Rss</h5>
              <span>NOTE: <b>i) For DRSS, We are taking the average time considering all hosts. ii) For normal Rss, We are displaying the exact time.</b></span><br />
              <a target="_blank" className="link-primary" href={rssKibanaUrl()}>Click here to see below graph in kibana</a>
            </center>
            <iframe src={rssKibanaUrl() + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
          </div>
        </TabPane>
        <TabPane tabId={'feedcrawl'}>
          <div className="panel-body">
            {UrlParams.avgTimeType == "avg_normalized_time" && (
              <>
                <center>
                  <h5 className="mb-2">Average Normalized Time - Feedcrawl</h5>
                  <span>NOTE: <b>{avgNormalizedTimeText()}</b></span><br />
                  <a target="_blank" className="link-primary" href={avgNormalizedTimeFeedcrawlKibanaUrl()}>Click here to see below graph in kibana</a>
                </center>
                <iframe src={avgNormalizedTimeFeedcrawlKibanaUrl() + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
              </>
            )}
            {UrlParams.avgTimeType == "avg_time_duration" && (
              <>
                <center>
                  <h5 className="mb-2">Average Time Duration - Feedcrawl</h5>
                  <span>NOTE: <b>{avgTimeDurationText()}</b></span><br />
                  <a target="_blank" className="link-primary" href={avgTimeDurationFeedcrawlKibanaUrl()}>Click here to see below graph in kibana</a>
                </center>
                <iframe src={avgTimeDurationFeedcrawlKibanaUrl() + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
              </>
            )}
            {UrlParams.avgTimeType == "*" && (
              <>
                <center>
                  <h5 className="mb-2">Average Normalized Time - Feedcrawl</h5>
                  <span>NOTE: <b>{avgNormalizedTimeText()}</b></span><br />
                  <a target="_blank" className="link-primary" href={avgNormalizedTimeFeedcrawlKibanaUrl()}>Click here to see below graph in kibana</a>
                </center>
                <iframe src={avgNormalizedTimeFeedcrawlKibanaUrl() + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
                <div className="mb-2">
                  <center>
                    <h5 className="mb-2">Average Time Duration - Feedcrawl</h5>
                    <span>NOTE: <b>{avgTimeDurationText()}</b></span><br />
                    <a target="_blank" className="link-primary" href={avgTimeDurationFeedcrawlKibanaUrl()}>Click here to see below graph in kibana</a>
                  </center>
                  <iframe src={avgTimeDurationFeedcrawlKibanaUrl() + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
                </div >
              </>
            )}
          </div>
        </TabPane>
        <TabPane tabId={'extraction'}>
          <div className="panel-body">
            {UrlParams.avgTimeType == "avg_normalized_time" && (
              <>
                <center>
                  <h5 className="mb-2">Average Normalized Time - Extraction</h5>
                  <span>NOTE: <b>{avgNormalizedTimeText()}</b></span><br />
                  <a target="_blank" className="link-primary" href={avgNormalizedTimeEdnuKibanaUrl("extraction")}>Click here to see below graph in kibana</a>
                </center>
                <iframe src={avgNormalizedTimeEdnuKibanaUrl("extraction") + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
              </>
            )}
            {UrlParams.avgTimeType == "avg_time_duration" && (
              <>
                <center>
                  <h5 className="mb-2">Average Time Duration - Extraction</h5>
                  <span>NOTE: <b>{avgTimeDurationText()}</b></span><br />
                  <a target="_blank" className="link-primary" href={avgTimeDurationEdnuKibanaUrl("extraction")}>Click here to see below graph in kibana</a>
                </center>
                <iframe src={avgTimeDurationEdnuKibanaUrl("extraction") + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
              </>
            )}
            {UrlParams.avgTimeType == "*" && (
              <>
                <center>
                  <h5 className="mb-2">Average Normalized Time - Extraction</h5>
                  <span>NOTE: <b>{avgNormalizedTimeText()}</b></span><br />
                  <a target="_blank" className="link-primary" href={avgNormalizedTimeEdnuKibanaUrl("extraction")}>Click here to see below graph in kibana</a>
                </center>
                <iframe src={avgNormalizedTimeEdnuKibanaUrl("extraction") + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
                <div className="mb-2">
                  <center>
                    <h5 className="mb-2">Average Time Duration - Extraction</h5>
                    <span>NOTE: <b>{avgTimeDurationText()}</b></span><br />
                    <a target="_blank" className="link-primary" href={avgTimeDurationEdnuKibanaUrl("extraction")}>Click here to see below graph in kibana</a>
                  </center>
                  <iframe src={avgTimeDurationEdnuKibanaUrl("extraction") + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
                </div >
              </>
            )}
          </div>
        </TabPane>
        <TabPane tabId={'dedup'}>
          <div className="panel-body">
            {UrlParams.avgTimeType == "avg_normalized_time" && (
              <>
                <center>
                  <h5 className="mb-2">Average Normalized Time - Dedup</h5>
                  <span>NOTE: <b>{avgNormalizedTimeText()}</b></span><br />
                  <a target="_blank" className="link-primary" href={avgNormalizedTimeEdnuKibanaUrl("dedup")}>Click here to see below graph in kibana</a>
                </center>
                <iframe src={avgNormalizedTimeEdnuKibanaUrl("dedup") + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
              </>
            )}
            {UrlParams.avgTimeType == "avg_time_duration" && (
              <>
                <center>
                  <h5 className="mb-2">Average Time Duration - Dedup</h5>
                  <span>NOTE: <b>{avgTimeDurationText()}</b></span><br />
                  <a target="_blank" className="link-primary" href={avgTimeDurationEdnuKibanaUrl("dedup")}>Click here to see below graph in kibana</a>
                </center>
                <iframe src={avgTimeDurationEdnuKibanaUrl("dedup") + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
              </>
            )}
            {UrlParams.avgTimeType == "*" && (
              <>
                <center>
                  <h5 className="mb-2">Average Normalized Time - Dedup</h5>
                  <span>NOTE: <b>{avgNormalizedTimeText()}</b></span><br />
                  <a target="_blank" className="link-primary" href={avgNormalizedTimeEdnuKibanaUrl("dedup")}>Click here to see below graph in kibana</a>
                </center>
                <iframe src={avgNormalizedTimeEdnuKibanaUrl("dedup") + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
                <div className="mb-2">
                  <center>
                    <h5 className="mb-2">Average Time Duration - Dedup</h5>
                    <span>NOTE: <b>{avgTimeDurationText()}</b></span><br />
                    <a target="_blank" className="link-primary" href={avgTimeDurationEdnuKibanaUrl("dedup")}>Click here to see below graph in kibana</a>
                  </center>
                  <iframe src={avgTimeDurationEdnuKibanaUrl("dedup") + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
                </div >
              </>
            )}
          </div>
        </TabPane>
        <TabPane tabId={'normalization'}>
          <div className="panel-body">
            {UrlParams.avgTimeType == "avg_normalized_time" && (
              <>
                <center>
                  <h5 className="mb-2">Average Normalized Time - Normalization</h5>
                  <span>NOTE: <b>{avgNormalizedTimeText()}</b></span><br />
                  <a target="_blank" className="link-primary" href={avgNormalizedTimeEdnuKibanaUrl("normalization")}>Click here to see below graph in kibana</a>
                </center>
                <iframe src={avgNormalizedTimeEdnuKibanaUrl("normalization") + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
              </>
            )}
            {UrlParams.avgTimeType == "avg_time_duration" && (
              <>
                <center>
                  <h5 className="mb-2">Average Time Duration - Normalization</h5>
                  <span>NOTE: <b>{avgTimeDurationText()}</b></span><br />
                  <a target="_blank" className="link-primary" href={avgTimeDurationEdnuKibanaUrl("normalization")}>Click here to see below graph in kibana</a>
                </center>
                <iframe src={avgTimeDurationEdnuKibanaUrl("normalization") + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
              </>
            )}
            {UrlParams.avgTimeType == "*" && (
              <>
                <center>
                  <h5 className="mb-2">Average Normalized Time - Normalization</h5>
                  <span>NOTE: <b>{avgNormalizedTimeText()}</b></span><br />
                  <a target="_blank" className="link-primary" href={avgNormalizedTimeEdnuKibanaUrl("normalization")}>Click here to see below graph in kibana</a>
                </center>
                <iframe src={avgNormalizedTimeEdnuKibanaUrl("normalization") + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
                <div className="mb-2">
                  <center>
                    <h5 className="mb-2">Average Time Duration - Normalization</h5>
                    <span>NOTE: <b>{avgTimeDurationText()}</b></span><br />
                    <a target="_blank" className="link-primary" href={avgTimeDurationEdnuKibanaUrl("normalization")}>Click here to see below graph in kibana</a>
                  </center>
                  <iframe src={avgTimeDurationEdnuKibanaUrl("normalization") + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
                </div >
              </>
            )}
          </div>
        </TabPane>
        <TabPane tabId={'upload'}>
          <div className="panel-body">
            {UrlParams.avgTimeType == "avg_normalized_time" && (
              <>
                <center>
                  <h5 className="mb-2">Average Normalized Time - Upload</h5>
                  <span>NOTE: <b>{avgNormalizedTimeText()}</b></span><br />
                  <a target="_blank" className="link-primary" href={avgNormalizedTimeEdnuKibanaUrl("upload")}>Click here to see below graph in kibana</a>
                </center>
                <iframe src={avgNormalizedTimeEdnuKibanaUrl("upload") + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
              </>
            )}
            {UrlParams.avgTimeType == "avg_time_duration" && (
              <>
                <center>
                  <h5 className="mb-2">Average Time Duration - Upload</h5>
                  <span>NOTE: <b>{avgTimeDurationText()}</b></span><br />
                  <a target="_blank" className="link-primary" href={avgTimeDurationEdnuKibanaUrl("upload")}>Click here to see below graph in kibana</a>
                </center>
                <iframe src={avgTimeDurationEdnuKibanaUrl("upload") + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
              </>
            )}
{UrlParams.avgTimeType == "*" && (
  <>
    <center>
      <h5 className="mb-2">Average Normalized Time - Upload</h5>
      <span>NOTE: <b>{avgNormalizedTimeText()}</b></span><br />
      <a target="_blank" className="link-primary" href={avgNormalizedTimeEdnuKibanaUrl("upload")}>Click here to see below graph in kibana</a>
    </center>
    <iframe src={avgNormalizedTimeEdnuKibanaUrl("upload") + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
    <div className="mb-2">
      <center>
        <h5 className="mb-2">Average Time Duration - Upload</h5>
        <span>NOTE: <b>{avgTimeDurationText()}</b></span><br />
        <a target="_blank" className="link-primary" href={avgTimeDurationEdnuKibanaUrl("upload")}>Click here to see below graph in kibana</a>
      </center>
      <iframe src={avgTimeDurationEdnuKibanaUrl("upload") + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
    </div >
  </>
)}
          </div>
        </TabPane>
      </TabContent>
    </CardBody>
  </Card>
</Container>
}

