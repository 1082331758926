import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import axios from "axios";

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function index() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[site_name]"),
    jobType: new URLSearchParams(search).get("filter[job_type]"),
    fromTime: new URLSearchParams(search).get("filter[time]")
  }
  const [siteName, setSiteName] = useState({label: 'All', value: '*'});
  const [siteNameOptions, setSiteNameOptions] = useState([]);
  const [jobType, setJobType] = useState({label: 'All', value: '*'});
  const [fromTime, setFromTime] = useState({ label: 'Last 24 hours', value: 'now-24h' });

  const jobTypeOptions = [
    { label: 'All', value: '*' },
    { label: 'Recrawl', value: 'recrawl' },
    { label: 'Distributed Recrawl', value: 'distributed_recrawl' },
    { label: 'Feedcrawl', value: 'feedcrawl3' },
    { label: 'Dependent Data Upload', value: 'dependent_data_upload' },
  ]

  const timeOptions = [
    { label: 'Today', value: 'now%2Fd' },
    { label: 'Last 30 minutes', value: 'now-30m' },
    { label: 'Last 1 hour', value: 'now-1h' },
    { label: 'Last 2 hours', value: 'now-2h' },
    { label: 'Last 4 hours', value: 'now-4h' },
    { label: "Last 10 hours", value: "now-10h" },
    { label: 'Last 12 hours', value: 'now-12h' },
    { label: 'Last 24 hours', value: 'now-24h' },
    { label: 'Last 2 days', value: 'now-2d' },
    { label: 'Last 3 days', value: 'now-3d' },
    { label: 'Last 4 days', value: 'now-4d' },
    { label: 'Last 7 days', value: 'now-7d' },
    { label: 'Last 15 days', value: 'now-15d' },
    { label: 'Last 30 days', value: 'now-30d' },
    { label: 'Last 60 days', value: 'now-60d' },
    { label: 'Last 1 year', value: 'now-1y' }
  ];

  const [UrlParams, setUrlParams] = useState({
    siteName: "*",
    jobType: '*',
    fromTime: 'now-24h',
  })

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let site_names = res.data.site_names, site_names_options = []
        for (var key in site_names) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
          site_names[key].map(item => {
            children.push({ label: item, value: item })
          })
          group['options'] = children;
          site_names_options.push(group)
        }
        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: '*' }] })
        setSiteNameOptions(site_names_options);
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      });
  }, []);

  const capitalize = (str, lower = false) => (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.siteName && searchParams.siteName != siteName.value) {
      setSiteName({ label: searchParams.siteName  == '*' ? 'All' :  searchParams.siteName, value: searchParams.siteName });
    }
    if (searchParams.jobType && searchParams.jobType != jobType.value) {
      setJobType({ label: searchParams.jobType  == '*' ? 'All' : capitalize((searchParams.jobType)?.replaceAll('_', ' ').replaceAll('3', '')), value: searchParams.jobType });
    }
    if (searchParams.fromTime && searchParams.fromTime != fromTime.value) {
      setFromTime(timeOptions.filter(item => item.value == searchParams.fromTime)[0]);
    }
    if (commit == 'Get Data' && searchParams.jobType && searchParams.siteName) {
      setUrlParams(searchParams)
    }
  }

  function siteWiseWorkerCountKibanaUrl(){
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
    var from_time_val = UrlParams.fromTime ? UrlParams.fromTime : "now%2Fd";

    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=line&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(pause:!t,value:0),time:(from:${from_time_val},to:${to_time_val}))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'%22metrics+from+resque+utils%22+AND+current_status:+running+AND+site_name:+${UrlParams.siteName}+and+job_type:+${UrlParams.jobType}'),uiState:(),vis:(aggs:!((enabled:!t,id:'1',params:(field:job_count),schema:metric,type:sum),(enabled:!t,id:'2',params:(drop_partials:!f,extended_bounds:(),field:'@timestamp',interval:auto,min_doc_count:1,scaleMetricValues:!f,timeRange:(from:now-24h,to:now),useNormalizedEsInterval:!t),schema:segment,type:date_histogram)),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,categoryAxes:!((id:CategoryAxis-1,labels:(filter:!t,show:!t,truncate:100),position:bottom,scale:(type:linear),show:!t,style:(),title:(),type:category)),dimensions:(x:(accessor:0,aggType:date_histogram,format:(id:date,params:(pattern:'HH:mm')),params:(bounds:(max:'2022-06-20T11:27:11.287Z',min:'2022-06-19T11:27:11.287Z'),date:!t,format:'HH:mm',interval:PT30M,intervalESUnit:m,intervalESValue:30)),y:!((accessor:1,aggType:avg,format:(id:number),params:()))),grid:(categoryLines:!f),labels:(),legendPosition:right,seriesParams:!((data:(id:'1',label:'Sum+of+job_count'),drawLinesBetweenPoints:!t,interpolate:linear,lineWidth:2,mode:normal,show:!t,showCircles:!t,type:line,valueAxis:ValueAxis-1)),thresholdLine:(color:%2334130C,show:!f,style:full,value:10,width:1),times:!(),type:line,valueAxes:!((id:ValueAxis-1,labels:(filter:!f,rotate:0,show:!t,truncate:100),name:LeftAxis-1,position:left,scale:(mode:normal,type:linear),show:!t,style:(),title:(text:'Sum+of+job_count'),type:value))),title:'',type:line))`
  }

  const handleGetData = () => {
    window.location.href = `/internal/site_wise_worker_count?filter[job_type]=${jobType.value}&filter[site_name]=${siteName.value}&filter[time]=${fromTime.value}&commit=${'Get Data'}`
  }

  const promiseOptions = (inputValue) => {
    if(inputValue.length < 3){
      return null
    } else {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200'){
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
              site_names[key].map(item => {
                children.push({ label: item, value: item })
              })
              group['options'] = children;
              site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setSiteNameOptions(site_names_options)
            resolve(site_names_options)
          }
        }).catch(err => {
          console.log(`err`, err)
        })
      })
    }
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/site_wise_worker_count">Site Wise Worker Count</a> : 'Site Wise Worker Count'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
          </BreadcrumbItem>}
        </Breadcrumb>
      </Col>
    </Row>
  </div>

  <Card>
    <CardBody>
      <Row>
        <Col sm="8">
          <label className="fw-bold">Site Name</label>
          <AsyncSelect cacheOptions defaultOptions={siteNameOptions} name='site_name' 
            value={siteName} loadOptions={promiseOptions} onChange={res => setSiteName(res)}
            placeholder="Select a site name" />
            <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
        </Col>
        <Col sm="4">
          <label className="fw-bold">Time</label>
          <Select
            value={fromTime}
            options={timeOptions}
            onChange={option => setFromTime(option)} />
        </Col>
      </Row>
      <Row className="align-items-end mt-4">
        <Col sm="4">
          <label className="fw-bold">Job Type</label>
          <Select
            value={jobType}
            options={jobTypeOptions}
            onChange={option => setJobType(option)} />
        </Col>
        <Col sm="4">
          <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
        </Col>
      </Row>
    </CardBody>
  </Card>
  <Card>
    <CardBody>
      <div className="mb-1">
        <b>NOTE:</b>
        If the graph is not shown after your form submission then you should do either maximize its size(the option is available on the right side of graph) or minimize the sidebar.
      </div>
      <div className="panel-body">
        <center>
          <span className="fw-bold">Site Wise Worker Count</span><br />
          <a target="_blank" className="link-primary" href={siteWiseWorkerCountKibanaUrl()}>Click here to see below graph in kibana</a>
        </center>
        <iframe src={siteWiseWorkerCountKibanaUrl() + "&embed=true"} height="500" width="100%" className="border-0"></iframe>
      </div>
    </CardBody>
  </Card>
</Container>
}
