export async function setItem (key, value) {
    await localStorage.setItem(key, value)
}

export function getItem(key){
    return localStorage.getItem(key)
}

export function removeItem(key){
    localStorage.removeItem(key)
}

export function userLoggedin(){
    if (getItem('authToken') && getItem('authEmail')){
        return true
    }
    else{
        return false
    }
}

export function userRegistered(){
    if (getItem('user_name') && getItem('authEmail')){
        return true
    }
    else{
        return false
    }
}