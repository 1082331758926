import React from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import LoadingAnimation from '../../../Common/LoadingAnimation/LoadingAnimation';
import Badge from './Badge/Badge.component';
import ToggleButton from './ToggleButton/ToggleButton.component';
import '../../../../stylesheets/CrawlNotificationPreference.scss';

export default function CrawlNotificationPreferenceTable({ data, paginationTotalRows, progressPending, pageSetter, apiEndpoint }) {

  // Table column definitions
  const columns = [
    { name: "Site Name", selector: "site_name", center: true },
    {
      name: "Site Status",
      cell: row => (<Badge siteStatus={row.site_status} />),
      center: true,
    },
    {
      name: 'Crawl Start Notification',
      cell: row => (<ToggleButton
        fieldName={'crawl_start_notification_preference'}
        requestBody={row}
        notificationId={row.website_notification_preference_id}
        baseURI={apiEndpoint}
        active={row.crawl_start_notification_preference==='notify_crawl_start'?true:false} />),
      center: true
    },
    {
      name: 'Crawl End Notification',
      cell: row => (<ToggleButton
        fieldName={'crawl_end_notification_preference'}
        requestBody={row}
        notificationId={row.website_notification_preference_id}
        baseURI={apiEndpoint}
        active={row.crawl_end_notification_preference==='notify_crawl_end'?true:false} />),
      center: true
    },
  ];

  // Helper function to set page number 
  function onChangePageHandler(pageNumber) {
    pageSetter(pageNumber);
  };


  return (
    <>
      {progressPending && <LoadingAnimation />}
      <DataTable
        data={data}
        columns={columns}
        noHeader
        highlightOnHover
        pagination
        paginationServer
        progressPending={progressPending}
        paginationTotalRows={paginationTotalRows}
        paginationPerPage={10}
        paginationComponentOptions={{ noRowsPerPage: true }}
        onChangePage={onChangePageHandler}
      />
    </>
  )
};
