import axios from 'axios';
import { Row, Badge, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { DataNotFoundAlerts } from './Alerts.component';
import React, { Fragment, useState, useEffect, useMemo } from 'react';
import HighPriorityDataCheckReportsTable from '../Temp/HighPriorotyDataCheckReportsTable';
import SpinnerLoader from '../../../V2/Common/LoadingAnimation/SpinnerLoader.component';

export default function HighPriorityDataCheckReports(props) {

  const { rssCrawlPushID } = props;

  const { site_name } = useParams();

  const [loading, setLoading] = useState(false);

  const [dataAvailable, setDataAvailable] = useState(false);
  const [reportTableData, setReportTableData] = useState([]);
  const [availableColumnsInDataCheckReports, setAvailableColumnsInDataCheckReports] = useState("");
  const [numberOfAvailableColumnsInDataCheckReports, setNumberOfAvailableColumnsInDataCheckReports] = useState(0);

  const HIGH_PRIORITY_DATA_CHECK_REPORTS_API_URL = useMemo(() => {
    const baseApi = '/api/v1/internal/new_qa_reports/get_high_priority_data_check_reports_data?';
    const params = (rssCrawlPushID !== "") ? `site_name=${site_name}&rss_crawl_push_id=${rssCrawlPushID}` : `site_name=${site_name}`;
    return `${baseApi}${params}`;
  }, [site_name, rssCrawlPushID]);

  useEffect(() => {

    const controller = new AbortController();

    fetchHighPriorotyDataReportsTabData(controller.signal);

    // cleanup
    return () => {
      controller.abort();
      setReportTableData([]);
      setDataAvailable(false);
      setAvailableColumnsInDataCheckReports("");
      setNumberOfAvailableColumnsInDataCheckReports(0);
    }
  }, [HIGH_PRIORITY_DATA_CHECK_REPORTS_API_URL])

  // Helper function to fetch data
  async function fetchHighPriorotyDataReportsTabData(signal) {
    try {
      setLoading(true);
      const response = await axios.get(HIGH_PRIORITY_DATA_CHECK_REPORTS_API_URL, { signal: signal });
      const data = await response?.data;
      setDataAvailable(data?.is_data_available);
      const sorted = await sortByReportType(data?.report_table_data)
      setReportTableData(sorted);
      setAvailableColumnsInDataCheckReports(data?.high_priority_data_check_reports?.report_available_fields);
      setNumberOfAvailableColumnsInDataCheckReports(data?.high_priority_data_check_reports?.no_of_fields_covered);
      setLoading(false);
    }
    catch (error) {
      console.error(error?.message);
      setLoading(false);
    }
  }

  function sortByReportType(dataArray) {
    return dataArray.slice().sort((a, b) => {
      // Helper function to check if the report_for_field_name contains "data_type_report" or "frequency_report"
      const hasDataTypeOrFrequency = (item) =>
        item.report_for_field_name.some(
          (report) =>
            report.report_name === "data_type_report" ||
            report.report_name === "frequency_report"
        );
  
      const hasDataTypeOrFrequencyA = hasDataTypeOrFrequency(a);
      const hasDataTypeOrFrequencyB = hasDataTypeOrFrequency(b);
  
      // If either A or B has "data_type_report" or "frequency_report", prioritize the one that doesn't
      if (hasDataTypeOrFrequencyA !== hasDataTypeOrFrequencyB) {
        return hasDataTypeOrFrequencyB - hasDataTypeOrFrequencyA;
      }
  
      // If both have or don't have "data_type_report" or "frequency_report", sort based on the array length
      const sizeA = a.report_for_field_name.length;
      const sizeB = b.report_for_field_name.length;
  
      return sizeB - sizeA;
    });
  }

  return (
    <Fragment>
      <SpinnerLoader loading={loading}>
        <DataNotFoundAlerts display={dataAvailable} alertMessage={"No Data Available"}>
          <div className='parent-container m-2'>
            <section className='body mb-4'>
              <div className='id-and-download-button d-flex flex-row justify-content-between align-items-end'>
                <div className='rss-crawl-id'>
                  <p className='h6'>Report ID: <mark>{rssCrawlPushID}</mark></p>
                </div>
                <div className='download-report-button'>
                </div>
              </div>
              <div className='table'></div>
            </section>
          </div>
          <div>
            <div className='d-flex flex-row'>
              <div className='d-flex flex-column justify-content-center align-items-center align-content-center m-2 w-100'>
                <div className='d-flex flex-column justify-content-center align-items-center align-content-center p-1 w-100' style={{ backgroundColor: "#40A2E3" }}>
                  <p className='fw-bold text-white mb-0'>AVAILABLE COLUMNS IN HIGH PRIORITY DATA CHECK REPORTS</p>
                  <p className='fw-bold text-white'>TOTAL NUMBER OF FIELDS: {numberOfAvailableColumnsInDataCheckReports}</p>
                </div>
                <div className='d-flex flex-row justify-content-center align-items-center align-content-center flex-wrap my-3 p-2' style={{ backgroundColor: "#FFF6E9" }} >
                  {
                    availableColumnsInDataCheckReports?.length > 0 && availableColumnsInDataCheckReports?.split(", ").map((data, indx) => (
                      <Badge key={indx} color='light'><p className='fw-bold text-dark'>{data}</p></Badge>
                    ))
                  }
                </div>
              </div>
            </div>
            <div className='report-table'>
              <div className='d-flex'>
                <Row>
                  {
                    reportTableData.map((data, indx) => (
                      <Col md="6" key={indx}>
                        <HighPriorityDataCheckReportsTable data={data} key={indx} />
                      </Col>
                    ))
                  }
                </Row>
              </div>
            </div>
          </div>
        </DataNotFoundAlerts>
      </SpinnerLoader>
    </Fragment>
  )
}
