import React from 'react';
import DataTable from 'react-data-table-component';
const columns = [
  {
    name: 'Uniq Id',
    selector: 'uniq_id',
    sortable: true
  },
  {
    name: 'Duplicate Status',
    selector: 'duplicate_status',
    sortable: true
  },
  {
    name: 'Duplicate of',
    selector: 'duplicate_of',
    sortable: true
  },
];

export default function JobStatusTable({ data, loading }) {
  return (
    <div>
      <DataTable
      data={data}
      columns={columns}
      progressPending={loading}
      noHeader
      responsive
      pagination />
    </div>
  )
}
