import axios from 'axios'
import React, {useState, useEffect} from 'react'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalHeader, Row, Col, ModalFooter, Button } from 'reactstrap'
import Loader from 'react-loader-spinner'

export default function EditSiteDetailsForm({data, setToggle, siteStatuses, feasibilityStatuses}) {
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [siteModalData, setSiteModalData] = useState({
    feasibility_status: data.feasibility_status.toString(), 
    site_status: data.site_status, 
    redmine_ticket_id: data.redmine_ticket_id, 
    site_setup_fee: data.site_setup_fee, 
    monthly_maintenance_fee: data.monthly_maintenance_fee,
    volume_charges: data.volume_charges
  })
  const [loadingBtns, setLoadingBtns] = useState(false)

  const toggleFunc = () => {
    setModal(curr => !curr)
  }

  const closeBtn = <button className="close" 
    onClick={() => setModal(false)}> &times; </button>;

  const updateSiteDetails = () => {
    setLoadingBtns(true)
    axios.put(`/api/v1/admins/feasibility_reports/${data.site_id}`, {
      site: { 
        feasibility_status: siteModalData.feasibility_status, 
        site_status: siteModalData.site_status,
        redmine_ticket_id: siteModalData.redmine_ticket_id,
        pricing_detail_attributes: {
          id: data?.pricing_detail_id,
          site_setup_fee: siteModalData.site_setup_fee, 
          monthly_maintenance_fee: siteModalData.monthly_maintenance_fee, 
          volume_charges: siteModalData.volume_charges
        }
      }
    }).then(res => {
      toast.success(res.data.message)
      setModal(false)
      setToggle(curr => !curr)
      setLoadingBtns(false)
    }).catch(err => {
      toast.error(err.response.data.message)
      setLoadingBtns(false)
    })
  }

  return (
    <>
      <Modal isOpen={modal} toggle={toggleFunc} backdrop='static' size='lg'>
        <ModalHeader toggle={toggleFunc} close={closeBtn}>
          Edit Member: {data.site_name}
        </ModalHeader>
        <ModalBody className='px-5'>
          {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
        : <>
          <Row className='my-2'>
            <Col lg={4}>
              <h6 className='my-0'>Feasibility Status</h6>
            </Col>
            <Col md={8}>
              <select className='form-control' value={siteModalData.feasibility_status}
                onChange={(e) => setSiteModalData({...siteModalData, feasibility_status: e.target.value})} >
                <option value=""></option>
                {feasibilityStatuses.map(each => (
                    <option value={each[1]} key={each[0]}>
                {each[0]}
              </option>
                ))}
            </select>
          </Col>
        </Row>
          <Row className='my-2'>
            <Col lg={4}>
              <h6 className='my-0'>Site Status </h6>
            </Col>
            <Col lg={8}>
              <select className='form-control' value={siteModalData.site_status}
                onChange={(e) => setSiteModalData({...siteModalData, site_status: e.target.value})} >
                <option value=""></option>
                {siteStatuses.map(each => (
                    <option value={each[1]} key={each[0]}>
                {each[0]}
              </option>
                ))}
            </select>
          </Col>
        </Row>
          <Row className='my-2'>
            <Col lg={4}>
              <h6 className='my-0'>Redmine Ticket ID# </h6>
            </Col>
            <Col lg={8}>
              <input type="text" className='form-control' value={siteModalData.redmine_ticket_id}
                onChange={(e) => setSiteModalData({ ...siteModalData, redmine_ticket_id: e.target.value })} />
            </Col>
          </Row>
          <Row className='my-2'>
            <Col lg={4}>
              <h6 className='my-0'>Site Setup Fee</h6>
            </Col>
            <Col lg={8}>
              <input type="text" className='form-control' value={siteModalData.site_setup_fee}
                onChange={(e) => setSiteModalData({ ...siteModalData, site_setup_fee: e.target.value })} />
            </Col>
          </Row>
          <Row className='my-2'>
            <Col lg={4}>
              <h6 className='my-0'>Monthly Maintenance Fee</h6>
            </Col>
            <Col lg={8}>
              <input type="text" className='form-control' value={siteModalData.monthly_maintenance_fee}
                onChange={(e) => setSiteModalData({ ...siteModalData, monthly_maintenance_fee: e.target.value })} />
            </Col>
          </Row>
          <Row className='my-2'>
            <Col lg={4}>
              <h6 className='my-0'>Volume Charges</h6>
            </Col>
            <Col lg={8}>
              <input type="text" className='form-control' value={siteModalData.volume_charges}
                onChange={(e) => setSiteModalData({ ...siteModalData, volume_charges: e.target.value })} />
            </Col>
          </Row>
      </>}
    </ModalBody>
        <ModalFooter>
          <Button color='light' onClick={() => setModal(false)}> Close </Button>
          <Button color="success" disabled={loadingBtns} onClick={updateSiteDetails}>update</Button>
        </ModalFooter>
        </Modal>
      <button className='btn btn-xs btn-primary mx-1' onClick={toggleFunc}>Edit</button>
        </>
  )
}
