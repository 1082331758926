import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Home } from "react-feather";
import Loader from "react-loader-spinner";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
 
export default function SiteFeasibilityCheck() {
    const search = useLocation().search;
    const commit = new URLSearchParams(search).get("commit");
    const searchParams = {
        url: new URLSearchParams(search).get("filter[url]"),
        region: new URLSearchParams(search).get("filter[region]"),
        renderJs: new URLSearchParams(search).get("filter[render_js]")
    }

    const [url, setUrl] = useState('https://');
    const [proxyCrawl, setProxyCrawl] = useState('');
    const [scraperApi, setScraperApi] = useState('');
    const [scrapeStack, setScrapeStack] = useState('');
    const [flipNodeProxies, setFlipNodeProxies] = useState('');
    const [stormProxies, setStormProxies] = useState('');
    const [rotatingProxies, setRotatingProxies] = useState('');
    const [rackProxies, setRackProxies] = useState('');
    const [region, setRegion] = useState({ label: "Select region", value: "" });
    const [clipBoardText, setClipBoardText] = useState('');
    const [renderJs, setRenderJS] = useState("false");
    const [loading, setLoading] = useState(false)
    const regions = [
        { label: "United States", value: "us" },
        { label: "Great Britain", value: "gb" },
        { label: "Albania", value: "al" },
        { label: "Argentina", value: "ar" },
        { label: "Armenia", value: "am" },
        { label: "Australia", value: "au" },
        { label: "Austria", value: "at" },
        { label: "Azerbaijan", value: "az" },
        { label: "Bangladesh", value: "bd" },
        { label: "Belarus", value: "by" },
        { label: "Belgium", value: "be" },
        { label: "Bolivia", value: "bo" },
        { label: "Brazil", value: "br" },
        { label: "Bulgaria", value: "bg" },
        { label: "Cambodia", value: "kh" },
        { label: "Canada", value: "ca" },
        { label: "Chile", value: "cl" },
        { label: "China", value: "cn" },
        { label: "Colombia", value: "co" },
        { label: "Costa Rica", value: "cr" },
        { label: "Croatia (Hrvatska)", value: "hr" },
        { label: "Cyprus", value: "cy" },
        { label: "Czech Republic", value: "cz" },
        { label: "Denmark", value: "dk" },
        { label: "Dominican Republic", value: "do" },
        { label: "Ecuador", value: "ec" },
        { label: "Egypt", value: "eg" },
        { label: "Estonia", value: "ee" },
        { label: "Finland", value: "fi" },
        { label: "France", value: "fr" },
        { label: "Georgia", value: "ge" },
        { label: "Germany", value: "de" },
        { label: "Greece", value: "gr" },
        { label: "Guatemala", value: "gt" },
        { label: "Hong Kong", value: "hk" },
        { label: "Hungary", value: "hu" },
        { label: "Iceland", value: "is" },
        { label: "India", value: "in" },
        { label: "Indonesia", value: "id" },
        { label: "Ireland", value: "ie" },
        { label: "Isle of Man", value: "im" },
        { label: "Israel", value: "il" },
        { label: "Italy", value: "it" },
        { label: "Jamaica", value: "jm" },
        { label: "Japan", value: "jp" },
        { label: "Jordan", value: "jo" },
        { label: "Kazakhstan", value: "kz" },
        { label: "Kyrgyzstan", value: "kg" },
        { label: "Laos", value: "la" },
        { label: "Latvia", value: "lv" },
        { label: "Lithuania", value: "lt" },
        { label: "Luxembourg", value: "lu" },
        { label: "Malaysia", value: "my" },
        { label: "Mexico", value: "mx" },
        { label: "Moldova", value: "md" },
        { label: "Netherlands", value: "nl" },
        { label: "New Zealand", value: "nz" },
        { label: "Norway", value: "no" },
        { label: "Peru", value: "pe" },
        { label: "Philippines", value: "ph" },
        { label: "Russia", value: "ru" },
        { label: "Saudi Arabia", value: "sa" },
        { label: "Singapore", value: "sg" },
        { label: "South Korea", value: "kr" },
        { label: "Spain", value: "es" },
        { label: "Sri Lanka", value: "lk" },
        { label: "Sweden", value: "se" },
        { label: "Switzerland", value: "ch" },
        { label: "Taiwan", value: "tw" },
        { label: "Tajikistan", value: "tj" },
        { label: "Thailand", value: "th" },
        { label: "Turkey", value: "tr" },
        { label: "Turkmenistan", value: "tm" },
        { label: "Ukraine", value: "ua" },
        { label: "United Arab Emirates", value: "ae" },
        { label: "Uzbekistan", value: "zu" },
        { label: "Vietnam", value: "vn" }
    ];

    useEffect(() => {
        searchParamsData();
    }, []);

    function searchParamsData() {
        if (searchParams.url && searchParams.url != url) {
            setUrl(searchParams.url);
        }
        if (searchParams.region && searchParams.region != region) {
            setRegion(regions.filter(item => item.value == searchParams.region)[0]);
        }
        if (searchParams.renderJs && searchParams.renderJs != renderJs) {
            setRenderJS(searchParams.renderJs)
        }
        if (commit == 'Check' && searchParams.url && searchParams.region) {
            fetchData(searchParams.url, searchParams.region, searchParams?.renderJs || "false");
        }

    }

    function fetchData(URL = url, Region = region.value, RenderJs = renderJs) {
        setLoading(true)
        axios.get(`/api/v1/internal/site_feasibility_check?filter[url]=${URL}&filter[region]=${Region}${RenderJs == "true" ? `&filter[render_js]=${'true'}` : ''}`)
            .then(res => {
                if (res.data.status == 200) {
                    setProxyCrawl(res.data.result.proxy_crawl?.url || '');
                    setScrapeStack(res.data.result.scrape_stack?.url || '');
                    setScraperApi(res.data.result.scraper_api?.url || '');
                    setFlipNodeProxies(getWidgetUrl(URL, Region, 'flipnode_proxies'));
                    setStormProxies(getWidgetUrl(URL, Region, 'storm_proxies'));
                    setRotatingProxies(getWidgetUrl(URL, Region, 'rotating_proxies'));
                    setRackProxies(getWidgetUrl(URL, Region, 'rack_proxies'))
                }
                setLoading(false)
            })
            .catch(err => {
                toast.error(err?.message || 'Oops Something went wrong')
                setLoading(false)
            })
    }

    function getWidgetUrl(url, country, proxySource) {
        return `https://search.promptcloud.com/api/v1/search/feasibilitydashboard?url=${url}/&proxy_country=${country}&proxy_source=${proxySource}`
    }

    const copyToClipBoard = text => {
        navigator.clipboard.writeText(text)
        setClipBoardText(text);
    }

    const handleCheck = () => {
        window.location.href = `/internal/scraper_api_feasibility_check?filter[url]=${url}&filter[region]=${region.value}&commit=${'Check'}${renderJs == "true" ? `&filter[render_js]=${'true'}` : ''}`
    }

    return <Container fluid>
        <div className="page-title">
            <Row>
                <Col xs="6">
                    <h3>Internal Dashboard</h3>
                </Col>
                <Col xs="6">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={'/internal'}>
                                <Home />
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            {commit == 'Check' ? <a className="link-primary" href={'/internal/scraper_api_feasibility_check'}>ScraperApi Feasibility Check</a> : 'ScraperApi Feasibility Check'}
                        </BreadcrumbItem>
                        {commit == 'Check' && <BreadcrumbItem className="fw-bold">
                            Search Results
                        </BreadcrumbItem>}
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
        {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> : <Fragment>
            <Card>
                <CardBody>
                    <Row className="align-items-center">
                        <div className="col-sm-6 form-group">
                            <label className="fw-bold"> URL </label>
                            <input type={'text'} value={url} onChange={event => setUrl(event.target.value)} className='form-control' />
                        </div>
                        <div className="col-sm-2 form-group">
                            <label className="fw-bold"> Region </label>
                            <Select
                                value={region}
                                options={regions}
                                onChange={(value) => setRegion(value)}
                            />
                        </div>
                        <div className="col-sm-2 form-group fw-bold align-middle mt-3">
                            <input checked={renderJs == "true" ? true : false} onChange={() => setRenderJS(renderJs == "true" ? "false" : "true")} type={'checkbox'} name='enable javascript' /> Enable javascript
                        </div>
                        <div className="col-sm-2 form-group">
                            <button className="btn btn-success w-100" onClick={handleCheck} >Check</button>
                        </div>
                    </Row>
                </CardBody>
            </Card>
            {scraperApi != '' && <Card>
                <CardHeader className="fw-bold p-3">
                    Scraper api
                </CardHeader>
                <CardBody>
                    <div className="input-group">
                        <input
                            type={'text'}
                            value={scraperApi}
                            onChange={event => setScraperApi(event.target.value)}
                            className='form-control' />
                        <button
                            className="btn btn-success rounded-0 text-white"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={clipBoardText == scraperApi ? "Copied" : "Copy to clipboard"}
                            onClick={() => copyToClipBoard(scraperApi)}>
                            <i className="fa fa-clipboard"></i>
                        </button>
                        <a href={scraperApi} target={"_blank"} className="btn btn-primary rounded-0 text-white">
                            <i className="fa fa-eye"></i>
                        </a>
                    </div>
                </CardBody>
            </Card>}
            {proxyCrawl != '' && <Card>
                <CardHeader className="fw-bold p-3">
                    Proxy crawl
                </CardHeader>
                <CardBody>
                    <div className="input-group">
                        <input
                            type={'text'}
                            value={proxyCrawl}
                            onChange={event => setProxyCrawl(event.target.value)}
                            className='form-control' />
                        <button
                            className="btn btn-success rounded-0 text-white"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={clipBoardText == proxyCrawl ? "Copied" : "Copy to clipboard"}
                            onClick={() => copyToClipBoard(proxyCrawl)}>
                            <i className="fa fa-clipboard"></i>
                        </button>
                        <a href={proxyCrawl} target={"_blank"} className="btn btn-primary rounded-0 text-white">
                            <i className="fa fa-eye"></i>
                        </a>
                    </div>
                </CardBody>
            </Card>}
            {scrapeStack != '' && <Card>
                <CardHeader className="fw-bold p-3">
                    Scrape stack
                </CardHeader>
                <CardBody>
                    <div className="input-group">
                        <input
                            type={'text'}
                            value={scrapeStack}
                            onChange={event => setScrapeStack(event.target.value)}
                            className='form-control' />
                        <button
                            className="btn btn-success rounded-0 text-white"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={clipBoardText == scrapeStack ? "Copied" : "Copy to clipboard"}
                            onClick={() => copyToClipBoard(scrapeStack)}>
                            <i className="fa fa-clipboard"></i>
                        </button>
                        <a href={scrapeStack} target={"_blank"} className="btn btn-primary rounded-0 text-white">
                            <i className="fa fa-eye"></i>
                        </a>
                    </div>
                </CardBody>
            </Card>}
            {flipNodeProxies != '' && <Card>
                <CardHeader className="fw-bold p-3">
                    Flip Node Proxies
                </CardHeader>
                <CardBody>
                    <div className="input-group">
                        <input
                            type={'text'}
                            value={flipNodeProxies}
                            onChange={event => setFlipNodeProxies(event.target.value)}
                            className='form-control' />
                        <button
                            className="btn btn-success rounded-0 text-white"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={clipBoardText == flipNodeProxies ? "Copied" : "Copy to clipboard"}
                            onClick={() => copyToClipBoard(flipNodeProxies)}>
                            <i className="fa fa-clipboard"></i>
                        </button>
                        <a href={flipNodeProxies} target={"_blank"} className="btn btn-primary rounded-0 text-white">
                            <i className="fa fa-eye"></i>
                        </a>
                    </div>
                </CardBody>
            </Card>}
            {stormProxies != '' && <Card>
                <CardHeader className="fw-bold p-3">
                    Storm Proxies
                </CardHeader>
                <CardBody>
                    <div className="input-group">
                        <input
                            type={'text'}
                            value={stormProxies}
                            onChange={event => setStormProxies(event.target.value)}
                            className='form-control' />
                        <button
                            className="btn btn-success rounded-0 text-white"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={clipBoardText == stormProxies ? "Copied" : "Copy to clipboard"}
                            onClick={() => copyToClipBoard(stormProxies)}>
                            <i className="fa fa-clipboard"></i>
                        </button>
                        <a href={stormProxies} target={"_blank"} className="btn btn-primary rounded-0 text-white">
                            <i className="fa fa-eye"></i>
                        </a>
                    </div>
                </CardBody>
            </Card>}
            {rotatingProxies != '' && <Card>
                <CardHeader className="fw-bold p-3">
                    Rotating Proxies
                </CardHeader>
                <CardBody>
                    <div className="input-group">
                        <input
                            type={'text'}
                            value={rotatingProxies}
                            onChange={event => setRotatingProxies(event.target.value)}
                            className='form-control' />
                        <button
                            className="btn btn-success rounded-0 text-white"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={clipBoardText == rotatingProxies ? "Copied" : "Copy to clipboard"}
                            onClick={() => copyToClipBoard(rotatingProxies)}>
                            <i className="fa fa-clipboard"></i>
                        </button>
                        <a href={rotatingProxies} target={"_blank"} className="btn btn-primary rounded-0 text-white">
                            <i className="fa fa-eye"></i>
                        </a>
                    </div>
                </CardBody>
            </Card>}
            {rackProxies != '' && <Card>
                <CardHeader className="fw-bold p-3">
                    Rack Proxies
                </CardHeader>
                <CardBody>
                    <div className="input-group">
                        <input
                            type={'text'}
                            value={rackProxies}
                            onChange={event => setRackProxies(event.target.value)}
                            className='form-control' />
                        <button
                            className="btn btn-success rounded-0 text-white"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={clipBoardText == rackProxies ? "Copied" : "Copy to clipboard"}
                            onClick={() => copyToClipBoard(rackProxies)}>
                            <i className="fa fa-clipboard"></i>
                        </button>
                        <a href={rackProxies} target={"_blank"} className="btn btn-primary rounded-0 text-white">
                            <i className="fa fa-eye"></i>
                        </a>
                    </div>
                </CardBody>
            </Card>}
        </Fragment>}
    </Container>
}