import React, {useState} from 'react'
import { Container, Row, Col } from 'reactstrap'
import axios from 'axios'
import { toast } from 'react-toastify';

export default function UpdateCodeOnHetzner() {
    const [loading, setLoading] = useState(false)

    const handleSubmit = () => {
        setLoading(true)
        axios.post('/api/v1/internal/command_dashboard/update_on_all_servers', {
            filter: {
                outer_command: "run_on_all_servers",
                inner_command: "update_common"
            }
        }).then(res => {
            toast.success(res?.data?.message)
            setLoading(false)
            window.location = `/internal/command_dashboard`
        }).catch(err => {
            toast.error(err?.response?.data?.message)
            setLoading(false)
        })
    }
    return (
        <Container>
            <Row>
                <Col md={4}>
                    <h6 className='fw-bold'>Outer Command (Non-Editable)</h6>
                    <input className='form-control' value='run_on_all_servers'
                        disabled />
                </Col>
                <Col md={4}>
                    <h6 className='fw-bold'>Inner Command (Non-Editable)</h6>
                    <input className='form-control' value='update_common'
                        disabled />
                </Col>
                <Col md={4}>
                    <button className='btn btn-success' onClick={handleSubmit}
                        disabled={loading} style={{margin: '25px auto', width: '100%'}}> 
                        Update Code on All Servers </button>
                </Col>
            </Row>
        </Container>
    )
}
