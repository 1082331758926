import React from 'react';
import { Row, Col, Card, CardBody } from "reactstrap";
import SitesDataTable from '../DataTables/SitesDataTable.component';

export default function DataTableCard(props) {
  return (
    <div>
      <Card>
        <CardBody>
          <Row className="align-items-end mt-2 mb-3">
            <Col>
              <div className="float-end">
                <Row className="align-items-center">
                  <Col sm="3">Search</Col>
                  <Col>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(event) => { props.onSeachHandler(event) }} />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <SitesDataTable
            data={props?.data}
            progressPending={props.progressPending}
          />
        </CardBody>
      </Card>
    </div>
  )
}
