import React, { useState } from 'react';
import Breadcrumb from '../../global/Breadcrumb';
import {
    CardBody, Card,
    Label,
    Input,
    Container,
    CardHeader,
    Form
} from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function NewAnnouncement() {
    const [message, SetMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (message.length != 0) {
            setLoading(true);
            axios.post('/api/v1/admins/announcements', { announcement: { message } })
                .then(res => {
                    toast.success(res.data.message);
                    SetMessage('');
                    setLoading(false);
                })
                .catch(err => {
                    toast.error(err.response.data.message)
                    setLoading(false);
                })
        }
    }

    return (
        <Container fluid>
            <Breadcrumb title="New Requirements" parent="New Announcements" />
            <Card className='pt-3'>
                <CardHeader className='fw-bold' tag="h5">
                    Create Announcements
                </CardHeader>
                <CardBody>
                    <Form onSubmit={handleSubmit} >
                        <Label for="message"><p className='fw-bold'>Message*</p></Label>
                        <Input onChange={(event) => SetMessage(event.target.value)} type='textarea' id='message' value={message} />

                        <div className='text-center' >
                            <button disabled={loading} type='submit' className='btn btn-primary mt-3'>Send Announcement</button>

                        </div>
                    </Form>
                </CardBody>
            </Card>
        </Container>
    )
}
