import React, {useState, useEffect} from 'react'
import { Container, Row, Col } from 'reactstrap'
import axios from 'axios'
import AsyncSelect from 'react-select/async';
import DatePicker from 'react-datepicker'
import { toast } from 'react-toastify';

export default function MergeFilesAndUpload() {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [dropdownOptions, setDropdownOptions] = useState({})
    const [selectedSite, setSelectedSite] = useState({})
    const [selectedOption, setSelectedOption] = useState('')
    const [numberOfRecords, setNumberOfRecords] = useState('50000')
    const [unzip, setUnzip] = useState(false)
    const [ignore, setIgnore] = useState(false)
    const [dedupRecords, setDedupRecords] = useState(false)
    const [selectedDataType, setSelectedDataType] = useState('')
    const [selectedCrawlFromDate, setSelectedCrawlFromDate] = useState('')
    const [selectedCrawlToDate, setSelectedCrawlToDate] = useState('')
    const [selectedFromDate, setSelectedFromDate] = useState('')
    const [selectedToDate, setSelectedToDate] = useState('')
    const [rssCrawlPushID, setRssCrawlPushID] = useState('')

    const today = new Date(Date.now())

    useEffect(() => {
        fetchSiteNames();
    }, [])

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('/');
    }
    
    function fetchSiteNames() {
        axios.get('/api/v1/internal/dropdown_list_helper')
        .then(res => {
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
            let group = {}, children = []
            group['label'] = <div className="fw-bold col text-black">{key}</div>
            site_names[key].map(item => {
                children.push({ label: item, value: item })
            })
            group['options'] = children;
            site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setDropdownOptions(site_names_options)
        })
        .catch(err => {
            console.error(err?.message || 'Oops something went wrong')
        })
    }
      
    const promiseOptions = (inputValue) => {
        if(inputValue.length < 3){
            return null
        } else {
            return new Promise((resolve) => {
            axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
                if (res.status == '200'){
                let site_names = res.data.site_names, site_names_options = []
                for (var key in site_names) {
                    let group = {}, children = []
                    group['label'] = <div className="fw-bold col text-black">{key}</div>
                    site_names[key].map(item => {
                    children.push({ label: item, value: item })
                    })
                    group['options'] = children;
                    site_names_options.push(group)
                }
                site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                setDropdownOptions(site_names_options)
                resolve(site_names_options)
                }
            }).catch(err => {
                    console.log(`err`, err)
                })
            })
        }
    }

    const handleRadioBtn = (val) => {
        setSelectedOption(val)
    }

    const handleSubmit = () => {
        setLoading(true)
        setErrors({})
        let tempObj = {}
        if (!(selectedSite?.value)){
            tempObj = {...tempObj, site:true}
        }
        if (selectedDataType?.length < 1){
            tempObj = {...tempObj, data_type:true}
        }
        if (selectedDataType == 'crawldate' && selectedCrawlFromDate?.toString()?.length < 1){
            tempObj = {...tempObj, crawl_from_date: true}
        }
        if (selectedDataType == 'crawldate' && selectedCrawlToDate?.toString()?.length < 1){
            tempObj = {...tempObj, crawl_to_date: true}
        }
        if (selectedDataType == 'date' && selectedFromDate?.toString()?.length < 1){
            tempObj = {...tempObj, from_date: true}
        }
        if (selectedDataType == 'date' && selectedToDate?.toString()?.length < 1){
            tempObj = {...tempObj, to_date: true}
        }
        if (selectedDataType == 'rss_crawl_push_id'){
            if (rssCrawlPushID?.length < 1){
                tempObj = {...tempObj, rss_crawl: 'This field is required'}
            }
            else if (isNaN(rssCrawlPushID)){
                tempObj = {...tempObj, rss_crawl: 'Please enter a valid number.'}
            }
            else if (rssCrawlPushID?.length < 14){
                tempObj = {...tempObj, rss_crawl: 'Rss crawl push id should be 14 characters'}
            }
        }
        setErrors(tempObj)

        if (Object.keys(tempObj)?.length == 0){
            let resultObj = {
                "outer_command": "merge_files_and_upload",
                "inner_command": "merge_files_and_upload",
                "nested_inner_command": "merge_files_and_upload",            
                "sitename": selectedSite?.value, "records_per_file": selectedOption, 
                "dedup_records": dedupRecords ? "true" : "false", 
                "records_custom": selectedOption == "custom_record" ? numberOfRecords : "",
                "type_of_date": selectedDataType,
                "crawldate": {
                    from: selectedDataType == 'crawldate' ? formatDate(selectedCrawlFromDate) : '',
                    to: selectedDataType == 'crawldate' ? formatDate(selectedCrawlToDate) : ''
                },
                "date": {
                    from: selectedDataType == 'date'? formatDate(selectedFromDate) : '',
                    to: selectedDataType == 'date'? formatDate(selectedToDate) : '',
                },
                "rss_crawl_push_id": selectedDataType == 'rss_crawl_push_id' ? rssCrawlPushID : 0,
            }
            if (unzip){
              resultObj["unzip"] = "true"
            }
            if (ignore){
              resultObj["ignore_multi_target_upload"] = "true"
            }
            axios.post('/api/v1/internal/command_dashboard/merge_and_upload', {
                filter: resultObj
            }).then(res => {
                toast.success(res?.data?.message)
                setLoading(false)
                window.location = `/internal/command_dashboard`
            }).catch(err => {
                toast.error(err?.response?.data?.message)
                setLoading(false)
            })
        }
        else {
            setLoading(false)
        }
    }

    return (
        <Container fluid>
            <Row>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Outer Command (Non-Editable)</h6>
                    <input className='form-control' value='merge_files_and_upload'
                        disabled />
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Inner Command (Non-Editable)</h6>
                    <input className='form-control' value='merge_files_and_upload'
                        disabled />
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Nested Inner Command (Non-Editable)</h6>
                    <input className='form-control' value='merge_files_and_upload'
                        disabled />
                </Col>
                <Col md={12} className='my-3'>
                    <h6 className='fw-bold'>Site Name*</h6>
                    <AsyncSelect cacheOptions defaultOptions={dropdownOptions} name='site_name' 
                        value={selectedSite} loadOptions={promiseOptions} onChange={res => setSelectedSite(res)}
                        placeholder="Select a site name" />
                    {errors?.site && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                    <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
                </Col>

                <Col md={4} className='my-3'>
                    <h6>Records Per File (Optional)</h6>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="records"
                            checked={selectedOption == 'all'} 
                            onChange={() => handleRadioBtn('all')} id="records1" />
                        <label className="form-check-label" htmlFor="records1">
                            All
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="records" 
                            checked={selectedOption == 'custom_record'}
                            onChange={() => handleRadioBtn('custom_record')} id="records2" />
                        <label className="form-check-label" htmlFor="records2">
                            Records per file - custom
                        </label>
                    </div>
                </Col>

                <Col md={4} className='my-3'>
                    <h6>Unzip (Optional)</h6>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="unzip"
                            checked={unzip} onChange={(e) => setUnzip(e.target.checked)} id="unzip1" />
                        <label className="form-check-label" htmlFor="unzip1">
                            Unzip
                        </label>
                    </div>
                </Col>

                <Col md={4} className='my-3'>
                    <h6>Dedup Records (Optional)</h6>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="dedup"
                            checked={dedupRecords} onChange={(e) => setDedupRecords(e.target.checked)} id="dedup1" />
                        <label className="form-check-label" htmlFor="dedup1">
                            Dedup Records
                        </label>
                    </div>
                </Col>

                {selectedOption == "custom_record" && <Col md={12} className='my-3'>
                    <h6 className='fw-bold'>Enter Number of records</h6>  
                    <input type="text" className='form-control' value={numberOfRecords}
                        onChange={(e) => setNumberOfRecords(e?.target?.value)} />
                </Col>}

                <Col md={6} className='my-3'>
                    <h6>Select date or Rss crawl push id*</h6>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="dates"
                            checked={selectedDataType == 'date'} 
                            onChange={() => setSelectedDataType('date')} id="dates1" />
                        <label className="form-check-label" htmlFor="dates1">
                            Upload Date
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="dates" 
                            checked={selectedDataType == 'crawldate'}
                            onChange={() => setSelectedDataType('crawldate')} id="dates2" />
                        <label className="form-check-label" htmlFor="dates2">
                            Crawldate
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="dates" 
                            checked={selectedDataType == 'rss_crawl_push_id'}
                            onChange={() => setSelectedDataType('rss_crawl_push_id')} id="dates3" />
                        <label className="form-check-label" htmlFor="dates3">
                            Operation based on Rss Crawl Push Id
                        </label>
                    </div>
                    {errors?.data_type && <h6 className='fw-bold text-danger my-2'> You must select Data Type </h6>}
                </Col>

                <Col md={6} className='my-3'>
                    <h6>Ignore multi target upload  (Optional)</h6>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="Ignore"
                            checked={ignore} onChange={(e) => setIgnore(e.target.checked)} id="Ignore1" />
                        <label className="form-check-label" htmlFor="Ignore1">
                            Ignore multi target upload 
                        </label>
                    </div>
                    <p className='text-muted'>Use this option when data was not uploading to client location</p>
                </Col>

                {selectedDataType == "date" ? 
                <>
                    <Col md={6}>
                        <h6 className='fw-bold'>From date</h6>
                        <DatePicker className="form-control my-2" selected={selectedFromDate} dateFormat="yyyy/MM/dd"
                            maxDate={today} onChange={(date) => setSelectedFromDate(date)} />
                        {errors?.from_date && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                    </Col>    
                    <Col md={6}>
                        <h6 className='fw-bold'>To date</h6>
                        <DatePicker className="form-control my-2" selected={selectedToDate} dateFormat="yyyy/MM/dd"
                            maxDate={today} onChange={(date) => setSelectedToDate(date)} />
                        {errors?.to_date && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                    </Col> 
                </>
                : selectedDataType == "crawldate" ? <>
                    <Col md={6}>
                        <h6 className='fw-bold'>From Crawldate</h6>
                        <DatePicker className="form-control my-2" selected={selectedCrawlFromDate} dateFormat="yyyy/MM/dd"
                            maxDate={today} onChange={(date) => setSelectedCrawlFromDate(date)} />
                        {errors?.crawl_from_date && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                    </Col>    
                    <Col md={6}>
                        <h6 className='fw-bold'>To Crawldate</h6>
                        <DatePicker className="form-control my-2" selected={selectedCrawlToDate} dateFormat="yyyy/MM/dd"
                            maxDate={today} onChange={(date) => setSelectedCrawlToDate(date)} />
                        {errors?.crawl_to_date && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                    </Col>
                </> : selectedDataType == 'rss_crawl_push_id' && <>
                    <Col md={12}>
                        <h6 className='fw-bold'>Rss Crawl Push Id (only number) </h6>
                        <input type='text' className='form-control' value={rssCrawlPushID}
                            minLength={14} onChange={(e) => setRssCrawlPushID(e.target.value)} />
                        {errors?.rss_crawl && <h6 className='fw-bold text-danger my-2'> {errors?.rss_crawl} </h6>}
                    </Col>
                </>}

                <Col md={6} className='my-3'>
                    <button className='btn btn-success' onClick={handleSubmit}
                        disabled={loading} style={{margin: '25px auto', width: '100%'}}> Submit </button>
                </Col>
            </Row>
        </Container>
    )
}
