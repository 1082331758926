import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function OverallRecordsCountBySystemWide() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const tabNameFromSearchUrl = new URLSearchParams(search).get("filter[tab_name]");
  const tabNameFromUrl = window.location.hash.substr(1);
  const searchParams = {
    pipelineStage: new URLSearchParams(search).get("filter[pipeline_stage]"),
    fromTime: new URLSearchParams(search).get("filter[time]"),
    fromDate: new URLSearchParams(search).get("filter[from]"),
    toDate: new URLSearchParams(search).get("filter[to]"),
    tabName: tabNameFromSearchUrl ? tabNameFromSearchUrl : tabNameFromUrl
  }
  const [pipelineStageByTimeStamp, setPipelineStageByTimeStamp] = useState({ label: 'All', value: '*' });
  const [pipelineStageByDate, setPipelineStageByDate] = useState({ label: 'All', value: '*' });
  const [fromTime, setFromTime] = useState({ label: 'Last 4 hours', value: 'now-4h' });
  const now = new Date();
  const [activeTab, setActiveTab] = useState(tabNameFromUrl ? tabNameFromUrl : 'timestamp_graph');
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(2, 'days')));
  const [toDate, setToDate] = useState(now);

  const pipelineStageOptions = [
    { label: 'All', value: '*' },
    { label: 'Rss', value: 'rss' },
    { label: 'Feedcrawl', value: 'diskfetcher' },
    { label: 'Extraction', value: 'extraction' },
    { label: 'Dedup', value: 'dedup' },
    { label: 'Normalization', value: 'normalization' },
    { label: 'Upload', value: 'upload' },
  ]

  const timeOptions = [
    { label: 'Today', value: 'now%2Fd' },
    { label: 'Last 30 minutes', value: 'now-30m' },
    { label: 'Last 1 hour', value: 'now-1h' },
    { label: 'Last 2 hours', value: 'now-2h' },
    { label: 'Last 4 hours', value: 'now-4h' },
    { label: "Last 10 hours", value: "now-10h" },
    { label: 'Last 12 hours', value: 'now-12h' },
    { label: 'Last 24 hours', value: 'now-24h' },
    { label: 'Last 2 days', value: 'now-2d' },
    { label: 'Last 3 days', value: 'now-3d' },
    { label: 'Last 4 days', value: 'now-4d' },
    { label: 'Last 7 days', value: 'now-7d' },
    { label: 'Last 15 days', value: 'now-15d' },
    { label: 'Last 30 days', value: 'now-30d' },
    { label: 'Last 60 days', value: 'now-60d' },
    { label: 'Last 1 year', value: 'now-1y' }
  ];

  const [timeStampUrlParams, setTimeStampUrlParams] = useState({
    pipelineStage: '*',
    fromTime: 'now-4h'
  })

  const [dateUrlParams, setDateUrlParams] = useState({
    pipelineStage: '*',
    fromDate: new Date(moment().subtract(2, 'days')),
    toDate: now
  })

  useEffect(() => {
    setSearchParamsData()
  }, []);

  function titleize(value){
    return value.substr(0,1).toUpperCase() + value.substr(1).toLowerCase();
  }

  function pipelineStageLabel(){
    var labelValue;
    if (searchParams.pipelineStage == '*'){
      labelValue = "All";
    }
    else if (searchParams.pipelineStage == 'diskfetcher'){
      labelValue = "Feedcrawl";
    }
    else {
      labelValue = titleize(searchParams.pipelineStage);
    }
    return labelValue;
  }

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.tabName == "timestamp_graph"){
      if (searchParams.pipelineStage && searchParams.pipelineStage != pipelineStageByTimeStamp.value) {
        setPipelineStageByTimeStamp({
          label: pipelineStageLabel(),
          value: searchParams.pipelineStage
        });
      }
    }
    else{
      if (searchParams.pipelineStage && searchParams.pipelineStage != pipelineStageByDate.value) {
        setPipelineStageByDate({
          label: pipelineStageLabel(),
          value: searchParams.pipelineStage
        });
      }
    }
    if (searchParams.fromTime && searchParams.fromTime != fromTime.value) {
      setFromTime(timeOptions.filter(item => item.value == searchParams.fromTime)[0]);
    }
    if (searchParams.fromDate && searchParams.fromDate != fromDate) {
      setFromDate(new Date(searchParams.fromDate));
    }
    if (searchParams.toDate && searchParams.toDate != toDate) {
      setToDate(new Date(searchParams.toDate));
    }
    if (searchParams.tabName && searchParams.tabName != activeTab) {
      setActiveTab(searchParams.tabName);
    }
    if (commit == 'Get Data' && searchParams.fromDate && searchParams.toDate && searchParams.pipelineStage) {
      setActiveTab('date_graph');
      setDateUrlParams(searchParams)
    }
    if (commit == 'Get Data' && searchParams.fromTime && searchParams.pipelineStage) {
      setActiveTab('timestamp_graph');
      setTimeStampUrlParams(searchParams)
    }
  }

  function systemWideGraphByTimestamp(){
    var to_time_val = timeStampUrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${timeStampUrlParams.fromTime}',mode:quick,to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'(ednu_status_tracker:true%20%20OR%20diskfetcher_crawl_time_tracker:true%20OR%20rss_status_tracker:true)%20AND%20pipeline_stage:%20%22${timeStampUrlParams.pipelineStage}%22')),uiState:(vis:(colors:('Sum%20of%20crawled_urls_count':%237EB26D,'Sum%20of%20extract_output_record_count':%23F2C96D))),vis:(aggs:!((id:'1',params:(customLabel:rss,field:pushed_urls_count),schema:metric,type:sum),(id:'2',params:(customLabel:feedcrawl,field:crawled_urls_count),schema:metric,type:sum),(id:'3',params:(customLabel:extraction,field:extract_output_record_count),schema:metric,type:sum),(id:'4',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:auto,min_doc_count:1),schema:segment,type:date_histogram),(id:'5',params:(customLabel:dedup,field:upload_count),schema:metric,type:sum),(id:'6',params:(customLabel:normalization,field:normalized_count),schema:metric,type:sum),(id:'7',params:(customLabel:upload,field:upload_count_per_segment),schema:metric,type:sum)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:'Overall%20Records%20Count%20By%20System%20Wide(Using%20Timestamp)',type:line))`
  }

  function systemWideGraphByDate(){
    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(pause:!t,value:0),time:(from:'${moment(dateUrlParams.fromDate).format('YYYY-MM-DDTHH:mm:ss')}',to:'${moment(dateUrlParams.toDate).format('YYYY-MM-DDTHH:mm:ss')}'))&_a=(filters:!(),linked:!f,query:(language:lucene,query:'(ednu_status_tracker:true++OR+diskfetcher_crawl_time_tracker:true+OR+rss_status_tracker:true)+AND+pipeline_stage:+%22${dateUrlParams.pipelineStage}%22+AND+date:%5B${moment(dateUrlParams.fromDate).format('YYYYMMDD')}+TO+${moment(dateUrlParams.toDate).format('YYYYMMDD')}%5D'),uiState:(vis:(colors:('Sum+of+crawled_urls_count':%237EB26D,'Sum+of+extract_output_record_count':%23F2C96D))),vis:(aggs:!((id:'1',params:(customLabel:rss,field:pushed_urls_count),schema:metric,type:sum),(id:'2',params:(customLabel:feedcrawl,field:crawled_urls_count),schema:metric,type:sum),(id:'3',params:(customLabel:extraction,field:extract_output_record_count),schema:metric,type:sum),(id:'4',params:(field:date.raw,order:desc,orderAgg:(id:'4-orderAgg',params:(field:date.raw),schema:orderAgg,type:cardinality),orderBy:custom,size:10),schema:segment,type:terms),(id:'5',params:(customLabel:dedup,field:upload_count),schema:metric,type:sum),(id:'6',params:(customLabel:normalization,field:normalized_count),schema:metric,type:sum),(id:'7',params:(customLabel:upload,field:upload_count_per_segment),schema:metric,type:sum)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:'Overall+Records+Count+By+System+Wide(Using+Date)',type:line))`
  }

  const handleSystemWideGraphByTimestampData = () => {
    window.location.href = `/internal/overall_system_reports/overall_records_count_by_system_wide?filter[pipeline_stage]=${pipelineStageByTimeStamp.value}&filter[time]=${fromTime.value}&filter[tab_name]=timestamp_graph&commit=${'Get Data'}`
  }

  const handleSystemWideGraphByDateData = () => {
    window.location.href = `/internal/overall_system_reports/overall_records_count_by_system_wide?filter[pipeline_stage]=${pipelineStageByDate.value}&filter[from]=${moment(fromDate).format('YYYY/MM/DD')}&filter[to]=${moment(toDate).format('YYYY/MM/DD')}&filter[tab_name]=date_graph&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/overall_system_reports/overall_records_count_by_system_wide">Overall Records Count By System Wide</a> : 'Overall Records Count By System Wide'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                Search
                </BreadcrumbItem>}
          </Breadcrumb>
        </Col>
      </Row>
    </div>
    <Card>
      <CardBody>
        <Nav tabs>
          <NavItem>
            <NavLink className={activeTab == 'timestamp_graph' ? 'active' : ''} onClick={() => setActiveTab('timestamp_graph')}>
              System Wide Graph by Timestamp
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab == 'date_graph' ? 'active' : ''} onClick={() => setActiveTab('date_graph')}>
              System Wide Graph by Date
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={'timestamp_graph'}>
            <div className="ibox-content m-b-sm border-bottom">
              <Row className="align-items-end ">
                <Col sm="4">
                  <label className="fw-bold">Pipeline Stage</label>
                  <Select
                    value={pipelineStageByTimeStamp}
                    options={pipelineStageOptions}
                    onChange={option => setPipelineStageByTimeStamp(option)}
                  />
                </Col>
                <Col sm="4">
                  <label className="fw-bold">Time</label>
                  <Select
                    value={fromTime}
                    options={timeOptions}
                    onChange={option => setFromTime(option)}
                  />
                </Col>
                <Col sm="4">
                  <button className="btn btn-success w-100" onClick={handleSystemWideGraphByTimestampData}>Get Data</button>
                </Col>
              </Row>
            </div>
            <div className="panel-body">
              <center>
                <span className="fw-bold">System Wide Graph by Timestamp</span><br />
                <a target="_blank" className="link-primary" href={systemWideGraphByTimestamp()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={systemWideGraphByTimestamp() + "&embed=true"} height="500" width="100%" className="border-0"></iframe>
            </div>
          </TabPane>
          <TabPane tabId={'date_graph'}>
            <div className="ibox-content m-b-sm border-bottom">
              <Row className="align-items-end ">
                <Col sm="3">
                  <label className="fw-bold">Pipeline Stage</label>
                  <Select
                    value={pipelineStageByDate}
                    options={pipelineStageOptions}
                    onChange={option => setPipelineStageByDate(option)}
                  />
                </Col>
                <Col sm="3">
                  <label className="fw-bold">From Date</label>
                  <DatePicker
                    className="form-control"
                    selected={new Date(fromDate)}
                    dateFormat={"YYY/MM/dd"}
                    onChange={date => setFromDate(date)} />
                </Col>
                <Col sm="3">
                  <label className="fw-bold">To Date</label>
                  <DatePicker
                    className="form-control"
                    selected={new Date(toDate)}
                    dateFormat={"YYY/MM/dd"}
                    onChange={date => setToDate(date)} />
                </Col>
                <Col sm="3">
                  <button className="btn btn-success w-100" onClick={handleSystemWideGraphByDateData}>Get Data</button>
                </Col>
              </Row>
            </div>
            <div className="panel-body">
              <center>
                <span className="fw-bold">System Wide Graph by Date</span><br />
                <a target="_blank" className="link-primary" href={systemWideGraphByDate()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={systemWideGraphByDate() + "&embed=true"} height="500" width="100%" className="border-0"></iframe>
            </div>
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  </Container>
}
