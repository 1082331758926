import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";
import moment from "moment";
import axios from "axios";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";

export default function RssStats() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const searchParams = {
    fromDate: new URLSearchParams(search).get("filter[from]"),
    toDate: new URLSearchParams(search).get("filter[to]"),
  }
  const [rssStatsData, setRssStatsData] = useState([]);
  const now = new Date();
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(2, 'days')));
  const [toDate, setToDate] = useState(now);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults]= useState([]);

  const tableColumns = [
    { name: 'Site Name', selector: 'site_name', sortable: true },
    { name: 'Blocked Url Count', selector: 'blocked_url_count', sortable: true },
    { name: 'Response Status Codes', selector: 'response_status_codes', sortable: true },
  ]

  const [UrlParams, setUrlParams] = useState({
    fromDate: new Date(moment().subtract(2, 'days')),
    toDate: now
  })

  useEffect(() => {
    setSearchParamsData();
  }, []);


  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.fromDate && searchParams.fromDate != fromDate) {
      setFromDate(new Date(searchParams.fromDate));
    }
    if (searchParams.toDate && searchParams.toDate != toDate) {
      setToDate(new Date(searchParams.toDate));
    }
    if (commit == 'Get Data') {
      setUrlParams(searchParams)
      fetchDataWithParams(searchParams);
    }
    else {
      fetchData();
    }
  }

  function fetchDataWithParams(filteredParams) {
    setLoading(true)
    axios.get(`/api/v1/internal/blocking_url_stats/rss_stats?&filter[from_date]=${moment(filteredParams.fromDate).format('YYYY/MM/DD')}&filter[to_date]=${moment(filteredParams.toDate).format('YYYY/MM/DD')}`)
      .then(res => {
        setRssStatsData(res.data.rss_stats_data);
        setSearchResults(res.data.rss_stats_data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  function fetchData() {
    setLoading(true)
    axios.get(`/api/v1/internal/blocking_url_stats/rss_stats`)
      .then(res => {
        setRssStatsData(res.data.rss_stats_data);
        setSearchResults(res.data.rss_stats_data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  const handleSearch = event =>{
    if(event.target.value == ''){
      setSearchResults(rssStatsData);
    }
    else{
      setSearchResults(searchFromData(event.target.value));
    }
    setSearchText(event.target.value);
  }

  function searchFromData(value){
    var search_result = rssStatsData.filter(item => item.site_name.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = rssStatsData.filter(item => item.blocked_url_count.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = rssStatsData.filter(item => item.response_status_codes.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
  }

  const handleGetData = () => {
    window.location.href = `/internal/blocking_url_stats/rss_stats?filter[from]=${moment(fromDate).format('YYYY/MM/DD')}&filter[to]=${moment(toDate).format('YYYY/MM/DD')}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/blocking_url_stats/rss_stats">Rss Blocking URL Stats</a> : 'Rss Blocking URL Stats'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
          </BreadcrumbItem>}
        </Breadcrumb>
      </Col>
    </Row>
  </div>

  <Card>
    <CardBody>
      <Row>
        <Col sm="4">
          <label className="fw-bold">From Date</label>
          <DatePicker
            className="form-control"
            selected={new Date(fromDate)}
            dateFormat={"YYY/MM/dd"}
            maxDate={new Date()}
            minDate={new Date(now.getFullYear(),now.getMonth(),now.getDate()-7)}
            onChange={date => setFromDate(date)} />
        </Col>
        <Col sm="4">
          <label className="fw-bold">To Date</label>
          <DatePicker
            className="form-control"
            selected={new Date(toDate)}
            dateFormat={"YYY/MM/dd"}
            maxDate={new Date()}
            minDate={new Date(now.getFullYear(),now.getMonth(),now.getDate()-7)}
            onChange={date => setToDate(date)} />
        </Col>
        <Col sm="4">
          <label className="fw-bold">&nbsp;</label>
          <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
        </Col>
      </Row>
    </CardBody>
  </Card>
  <Card>
    <CardBody>
      <Row className="align-items-end mt-2">
        <Col>
          <div className="float-end mb-3">
            <Row className="align-items-center">
              <Col sm="3">Search</Col>
              <Col>
                <input type="text" className="form-control" value={searchText} onChange={handleSearch} />
              </Col>
            </Row>
          </div>
        </Col>
        <DataTable
          columns={tableColumns}
          data={searchResults}
          noHeader
          pagination
          defaultSortField="site_name"
          defaultSortAsc
          progressPending={loading}
        />
      </Row>
    </CardBody>
  </Card>
</Container>
}

