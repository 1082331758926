import React from 'react';
import { forwardRef, useImperativeHandle, useState, useEffect } from 'react';


import { Row, Col, Form, Label, Input, Table } from 'reactstrap';
import { Field, useFormikContext, ErrorMessage, setNestedObjectValues, FastField } from 'formik';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone-uploader';
import axios from 'axios';
import XLSX from 'xlsx';

import CrawlForm from './CrawlForm.component';

const SchemaDetails = forwardRef(({ formikRef, accessEnv }, ref) => {

  const { values, validateForm, touched, setTouched, setErrors, setFieldValue } = useFormikContext()

  const environment = localStorage.getItem("environment");

  useImperativeHandle(ref, () => ({
    isValidated() {
      let validateValues = {...values}
      delete validateValues["site_details"]
      return new Promise((resolve, reject) => {
        formikRef.current.validateForm(validateValues)
          .then(errors => {
            if (Object.keys(errors).length === 0) {
              resolve();
            } else {
              reject(errors);
              setTouched(setNestedObjectValues(errors, true))
              toast.error("The form is invalid so please check it!")
            }
          })
          .catch(error => reject(error));
      });
    }
  }))

  return(
    <div>
      <Row>
        <Col sm={12}>
          <Row className='my-4'>
            <Col lg={5}>
              <div className='my-3'>
                <Label htmlFor="sitegroup_name">Sitegroup Name*</Label>
                <Field 
                  className="form-control"
                  name="sitegroup_name"
                  type="text"
                  title='please give a name to the group of sites having similar schema'
                  placeholder="Eg: hotel_reviews (No spaces)"
                  maxLength={25}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/\s/g, '_');
                    setFieldValue('sitegroup_name', newValue);
                  }}
                />
                <ErrorMessage name="sitegroup_name" component="p" className='m-0 error-msg mt-1 mb-2' />
                <div className="text-muted text-sm">
                  All sites within a sitegroup share the same fields, frequency and output format
                </div>
              </div>
              <div className='my-3'>
                <Label htmlFor="crawl_type">Crawl Type*</Label>
                <Field
                  as="select"
                  name="crawl_type"
                  id="crawl_type"
                  className="form-control"
                  title={`1) Full crawl - a full crawl will be performed each time (eg: product info crawls)
                  2) Incremental crawl - the crawler will only look for newly available URLs (eg: reviews, jobs data etc where previously crawled / delivered data will not be crawled again)`}
                >
                  <option value="none">Select crawl type</option>
                  <option value="fullcrawl">Full crawl</option>
                  <option value="incremental_recrawl">Incremental Recrawl</option>
                </Field>
                <ErrorMessage name="crawl_type" component="p" className='m-0 error-msg mt-1 mb-2' />
              </div>
              <div className='my-3'>
                <Label htmlFor="data_delivery">Data Delivery*</Label>
                <Field
                  as="select"
                  name="data_delivery"
                  id="data_delivery"
                  className="form-control"
                  title='select the delivery method where you want to data to be delivered'
                >
                  <option value="api">Promptcloud Data API (Free)</option>
                  <option value="s3">Amazon S3</option>
                  <option value="dropbox">Dropbox</option>
                  <option value="box">Box</option>
                  <option value="ftp">FTP</option>
                  <option value="sftp">sFTP</option>
                  <option value="microsoft_azure">Microsoft Azure</option>
                  <option value="google_drive">Google Drive</option>
                </Field>
                <ErrorMessage name="data_delivery" component="p" className='m-0 error-msg mt-1 mb-2' />
              </div>
              {
                accessEnv.includes(environment) ? (
                  <div className='my-3'>
                    <Label htmlFor="rss_urls_crawl_limit">Rss Urls Crawl Limit</Label>
                    <Field
                      type="text"
                      name="rss_urls_crawl_limit"
                      id="rss_urls_crawl_limit"
                      className="form-control"
                      placeholder='It will be 30k by default'
                    />
                    <ErrorMessage name="rss_urls_crawl_limit" component="p" className='m-0 error-msg mt-1 mb-2' />
                  </div>
                ) : null
              }
            </Col>
            <Col lg={1}></Col>
            <Col lg={5}>
              <div className='my-3'>
                <Label htmlFor="data_format">Data Format*</Label>
                <Field
                  as="select"
                  name="data_format"
                  id="data_format"
                  className="form-control"
                  title='select the format in which you wish to receive the data'
                >
                  <option value="">Select format</option>
                  <option value="json">JSON</option>
                  <option value="ld_json">LD-JSON</option>
                  <option value="xml">XML</option>
                  <option value="csv">CSV</option>
                  <option value="tsv">TSV</option>
                </Field>
                <ErrorMessage name="data_format" component="p" className='m-0 error-msg mt-1 mb-2' />
              </div>
              <div className='my-3'>
                <Label htmlFor="frequency">Frequency*</Label>
                <Field
                  as="select"
                  name="frequency"
                  id="frequency"
                  className="form-control"
                  title='How often do you wish to run the crawls?'
                >
                  <option value="">Select frequency</option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="other_frequency">Other</option>
                </Field>
                <ErrorMessage name="frequency" component="p" className='m-0 error-msg mt-1 mb-2' />
              </div>
              {
                values?.frequency == 'weekly' && 
                <div className='my-3'>
                  <label htmlFor="weekly">Days*</label>
                  <Field
                    as="select"
                    name="weekly"
                    id="weekly"
                    className="form-control"
                  >
                    <option value="">Select day</option>
                    <option value="monday">Monday</option>
                    <option value="tuesday">Tuesday</option>
                    <option value="wednesday">Wednesday</option>
                    <option value="thursday">Thursday</option>
                    <option value="friday">Friday</option>
                    <option value="saturday">Saturday</option>
                    <option value="sunday">Sunday</option>
                  </Field>
                  <ErrorMessage name="weekly" component="p" className='m-0 error-msg mt-1 mb-2' />
                </div>
              }
              {
                values?.frequency == 'other_frequency' &&
                <div className='my-3'>
                  <label htmlFor="details">Details*</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="details"
                    id="details"
                    placeholder=""
                  />
                  <ErrorMessage name="details" component="p" className='m-0 error-msg mt-1 mb-2' />
                  <div className="text-muted text-sm">
                    *Minimum requirements - at least one crawl per month, for a minimum period of 3 months. Please note that we do not take up one-time data acquisition projects.
                  </div>
                </div>
              }
              {
                values?.frequency == 'monthly' &&
                <div className='my-3'>
                  <Label htmlFor="monthly">Day of Month*</Label>
                  <Field name="monthly">
                    {({ field }) => (
                      <DatePicker
                        id="freqMonthly"
                        {...field}
                        className="form-control digits"
                        selected={field.value || new Date}
                        onChange={(date) => {
                          field.onChange({ target: { name: field.name, value: date } });
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="monthly" component="p" className='m-0 error-msg mt-1 mb-2' />
                </div>
              }
            </Col>
          </Row>
          <CrawlForm />
        </Col>
      </Row>
    </div>
  )

})

export default SchemaDetails;
