import React, { Fragment } from 'react'
import DataTable from 'react-data-table-component'

export default function CustomTable({ columns, data }) {
  return (
    <Fragment>
      <DataTable data={data} columns={columns} responsive noHeader striped highlightOnHover pagination />
    </Fragment>
  )
}
