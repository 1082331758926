import React from 'react';
import { useState, useEffect, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { SitesContext } from '../Context/SitesState';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { toast } from 'react-toastify';

const columns = [
  { name: 'Site Name', selector: 'site_name', sortable: true },
  { name: 'Status', selector: 'site_status', sortable: true, },
  { name: 'Uploads in last 30 days', selector: 'uploads_in_last_30_days', sortable: true },
  { name: 'Uploads in last 24 hours', selector: 'uploads_in_last_24_hours', sortable: true },
  { name: 'Last Run Date', selector: 'last_run_date', sortable: true },
  { name: 'Next Scheduled Run', selector: 'next_scheduled_run_date', sortable: true },
  { name: 'Queue count (Urls in queue)', selector: 'queue_count', sortable: true },
];


export default function SitesInformationTable() {
  const { siteStatus, sitegroupId, selectionFromTable } = useContext(SitesContext);
  const { organization_name } = useParams();
  const [tableData, setTableData] = useState([]);
  const [siteName, setSiteName] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageTotal, setPageTotal] = useState(0);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(async () => {

    const controller = new AbortController();

    await getTabelData(controller.signal);

    // cleanup
    return () => {
      controller.abort();
    }
  }, [sitegroupId, pageNumber, status, siteName]);



  // Helper function to fetch data from DB and display in table
  async function getTabelData(SIGNAL) {
    const API_URL = `/api/v1/organizations/${organization_name}/sites?filterrific[filter_by_site_status]=${status}&filterrific[search_by_site_name]=${siteName}&sitegroup_id=${sitegroupId}&page=${pageNumber}`;
    setLoading(true);
    try {
      const response = await axios.get(API_URL, { signal: SIGNAL });
      const sitesData = await response?.data?.sites;
      const totalPages = await response?.data?.total_sites_count;
      setTableData(sitesData);
      setPageTotal(totalPages);
      setLoading(false);
    }
    catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  }

  // Optimize the search using debounce
  function debounce(func) {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 650);
    }
  }
  // When search input changes this method is invoked
  function onInputChangeHandler(event) {
    let { value } = event.target;
    setLoading(true);
    setSiteName(value);
    setLoading(false);
  }

  // Use debounce to stop multiple API calls 
  const optimizedSearchHandler= useCallback(debounce(onInputChangeHandler), []);


  // Server side pagination for data table
  function onPaginationHandler(pagenum) {
    setPageNumber(pagenum);
  }

  // Helper function for selecting rows
  // This function updates the array of selected sites ID in context whenever
  // user clicks on the table select checkbox. This is essential for handelling the 
  // crawl schedules in the "Schedule Crawls" button. If user selects sites, the the option
  // "Selected Sites" is shown, otherwise, all sites is shown as default.
  function onRowSelectHandler({ selectedRows }) {
    const selections = selectedRows.map(obj => obj["id"]).toString();
    selectionFromTable(selections);
  }

  // Helper function to set site status.
  // When status changes, so does the contents of the table.
  function handleSiteStatus(event) {
    const { value } = event?.target;
    setStatus(value);
  }
  return (
    <div>
      <Row className='my-4'>
        <Col lg={4} className='d-flex align-center'>
          <h6 className='m-0 p-0 me-2'> Status: </h6>
          <select defaultValue={""} className='form-control show-arrow'
            onChange={(e) => handleSiteStatus(e)} >
            <option value={""} key="none" disabled hidden>Select Status</option>
            {
              siteStatus?.map((status) => (
                <option value={status.value} key={status.label}>{status.label}</option>
              ))
            }
          </select>
        </Col>
        <Col lg={3}></Col>
        <Col lg={5} className='d-flex align-center'>
          <h6 className='m-0 p-0 me-2'> Search: </h6>
          <input
            className='form-control'
            type='text'
            onChange={(event) => {setLoading(true),optimizedSearchHandler(event)}} />
        </Col>
      </Row>
      <DataTable
        columns={columns}
        data={tableData}
        responsive
        noHeader
        selectableRows
        onSelectedRowsChange={onRowSelectHandler}
        selectableRowDisabled={(row) => row?.site_status != "active"}
        pagination
        paginationServer
        progressPending={loading}
        paginationTotalRows={pageTotal}
        paginationPerPage={10}
        paginationComponentOptions={{ noRowsPerPage: true }}
        onChangePage={(page) => onPaginationHandler(page)}
      />
    </div>
  )
}
