import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, ModalFooter, Button } from 'reactstrap'
import { Link, useLocation } from "react-router-dom";
import { Home, Info } from "react-feather";
import axios from "axios";
import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";
import { toast } from 'react-toastify'
import Select, { components } from "react-select";
import UpdateCustomList from './update_custom_list';

export default function CustomList() {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingBtns, setLoadingBtns] = useState(false);
  const [showCustomForm, setShowCustomForm] = useState(false);
  const [sitesOptions, setSitesOptions] = useState([]);
  const [customNamesOptions, setCustomNamesOptions] = useState([]);
  const [siteNames, setSiteNames] = useState([]);
  const [listName, setListName] = useState();

  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults]= useState([]);

  const toggleModalFunc = (e) => {
    e.preventDefault();
    customNameList();
    activeSiteList();
    setModal(curr => !curr)
  }

  const closeBtn = <button className="close" 
    onClick={() => setModal(false)}> &times; </button>;

  const customNameList = () => {
    setLoading(true)
    axios.get(`/api/v1/internal/high_volume_clients_and_sites/custom_name_list`)
      .then(res => {
        setCustomNamesOptions(res.data?.custom_lists)
        setSearchResults(res.data?.custom_lists);
        setLoading(false)
      }).catch(err => {
        console.error(err?.message || 'Oops something went wrong');
        // toast.error(err?.response.data.message || 'Oops something went wrong');
        setLoading(false)
      })
  }

  const activeSiteList = () => {
    setLoading(true)
    axios.get(`/api/v1/internal/high_volume_clients_and_sites/active_site_list`)
      .then(res => {
        setSitesOptions(res.data?.sites);
        setLoading(false)
      }).catch(err => {
        console.error(err?.message || 'Oops something went wrong');
        // toast.error(err?.response.data.message || 'Oops something went wrong');
        setLoading(false)
      })
  }

  const createCustomList = (e) => {
    e.preventDefault()
    setLoading(true)
    setModal(false)
    axios.post(`/api/v1/internal/high_volume_clients_and_sites/create_custom_list`, {
      filter: {list_name: listName, site_names: siteNames.map(x=> x["value"])} 
    }).then(res => {
      toast.success(res.data?.message);
      setLoading(false);
      location.reload();
    }).catch(err => {
      setModal(true)
      console.error(err?.message || 'Oops something went wrong');
      // toast.error(err?.response.data.message || 'Oops something went wrong');
      setLoading(false)
    })
  }
  const deleteCustomList = (list_name) => (e) => {
    e.preventDefault()
    setLoading(true)
    setModal(false)
    axios.get(`/api/v1/internal/high_volume_clients_and_sites/delete_custom_list?filter[list_name]=${list_name}`).then(res => {
      toast.success(res.data?.message);
      setLoading(false);
      location.reload();
    }).catch(err => {
      setModal(true)
      console.error(err?.message || 'Oops something went wrong');
      // toast.error(err?.response.data.message || 'Oops something went wrong');
      setLoading(false)
    })
  }

  const handleSearch = event =>{
    if(event.target.value == ''){
      setSearchResults(customNamesOptions);
    }
    else{
      setSearchResults(searchFromData(event.target.value));
    }
    setSearchText(event.target.value);
  }

  function searchFromData(value){
    var search_result = customNamesOptions.filter(item => item.list_name.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
  }

  const customStyles = {
    rows: {
      style: { 'display': 'block' },
    }
  } 

  const tableColumns = [
    {
      name: 'List Name', 
      selector: 'list_name', 
      sortable: true,
      style: {
        "display": "inline-block",
      },
    },
    {
      name: 'Options', 
      selector: '',
      sortable: true,
      button: true,
      style: {
        "display": "block",
        "padding": "5px",
        "float": "right",
      },
      cell: (row, index) => (
        <>
          <UpdateCustomList key={index} listName={row.list_name}/>
          | <button className="btn btn-danger btn-sm" 
            onClick={deleteCustomList(row.list_name)}>Delete</button>
        </>
        )
    },
  ]

  return (
    <>
      <Modal isOpen={modal} toggle={toggleModalFunc} backdrop='static' size='lg'>
        <ModalHeader toggle={toggleModalFunc} close={closeBtn}>
          <center>
            Custom List 
          </center>
        </ModalHeader>
        <ModalBody className='px-5'>
          {
            showCustomForm ?
            <>
              <Row className='my-2'>
                <Col md={12} className="mb-2">
                  <h6 className='my-1 f-w-600'>List Name</h6>
                  <input type="text" value={listName} className='form-control'
                    onChange={(e) => setListName(e.target.value)} />
                </Col>
                <Col md={12}>
                  <h6 className='my-1 f-w-600'>Site names</h6>
                  <Select className='show-arrow basic-multi-select' value={siteNames} isMulti isSearchable 
                    onChange={(res) => setSiteNames(res)} 
                    options={sitesOptions} />
                </Col>
              </Row>
            </>
            :
            <>
              <Row className='my-2'>
                <center>
                  <button className="btn btn-primary btn-sm me-1 px-xl-3" onClick={() => setShowCustomForm(true)}>Create custom list</button>
                  <p className="mt-2">(OR)</p>
                </center>
              </Row>
              <Row className='my-2'>
                <Col lg={12}>
                  {
                    loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> 
                    :
                    <Row className="align-items-end mt-2">
                      <Col>
                        <div className="float-end mb-3">
                          <Row className="align-items-center">
                            <Col sm="3">Search</Col>
                            <Col>
                              <input type="text" className="form-control" value={searchText} onChange={handleSearch} />
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <DataTable
                        columns={tableColumns}
                        customStyles={customStyles}
                        data={searchResults}
                        noHeader
                        pagination
                        progressPending={loading}
                      />
                    </Row>
                  }
                </Col>
              </Row>
            </>
          }
        </ModalBody>
        <ModalFooter>
          {
            showCustomForm ?  
            <>
              <Button color='light' onClick={() => setShowCustomForm(false)}> Back </Button>
              <Button color='btn btn-success btn-sm' onClick={createCustomList}> Create </Button>
            </>
            :
            <>
              <Button color='light' onClick={() => setModal(false)}> Close </Button>
            </>
          }
        </ModalFooter>
      </Modal>
      <button className="btn btn-primary btn-sm p-xl-1" onClick={toggleModalFunc}>Create/Update custom list</button>
    </>
    )
}

