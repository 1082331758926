import React from 'react'
import { Card, CardBody, Container } from 'reactstrap'
import Breadcrumb from '../global/Breadcrumb'

export default function Help() {
    return (
        <Container fluid>
            <Breadcrumb parent={"Help"} title="Help" />

            <Card>
                <CardBody>
                    <h6 className='fw-bold my-3'>1. Sharing Project Requirements {'&'} Getting Started</h6>
                    <ul style={{listStyleType: 'circle'}} className='mx-3'>
                        <li className='my-1'>Once logged in, please choose - Add &gt; Site - from the menu on the top to bring up the requirements gathering tool.</li>
                        <li className='my-1'>Select a Site Group or create one if required.</li>
                        <li className='my-1'>Provide a URL of a sample page and if relevant, the seed/starting URL (say, if you want to crawl only a specific category within a site).</li>
                        <li className='my-1'>A first-level of automated feasibility check would be performed to give you an indication on feasibility of crawling the given sites.</li>
                        <li className='my-1'>Click “Next” to enter data delivery details – file format in which you wish to receive data; delivery mechanism etc.</li>
                        <li className='my-1'>Click “Next” to add any further details. It would be great if you could share a sample output file, screenshots or any similar documents to make sure the requirements are made clear.</li>
                        <li className='my-1'>Click “Send for Final Feasibility Check”.</li>
                        <li className='my-1'>You also have an option to view an estimated monthly cost for the project based on the requirements provided.</li>
                        <li className='my-1'>You shall receive an email within the next 4 business hours confirming the feasibility of crawling, pricing structure, PromptCloud’s standard Service Agreement.</li>
                        <li className='my-1'>On agreeing to the Service Agreement, an invoice shall be generated.</li>
                        <li className='my-1'>Upon paying the invoice, PromptCloud shall start working on the project and first set of data will be delivered for review in 5 business days or earlier (for up to 10 target sites).</li>
                    </ul>

                    <hr className='my-2' />

                    <h6 className='fw-bold my-3'>2. Ticketing System</h6>
                    <ul style={{listStyleType: 'circle'}} className='mx-3'>
                        <li className='my-1'>All technical support is handled via our ticketing system (which also integrates with emails).</li>
                        <li className='my-1'>In order to create, view or update existing tickets, click “Issues” option from the left-side menu.</li>
                        <li className='my-1'>Once created, a ticket shall be automatically assigned to someone from PromptCloud’s technical team who will be taking up and resolving the issue. Due date and priority too shall be set by the personnel.</li>
                        <li className='my-1'>Ticket updates shall be accompanied by an email notification as well.</li>
                        <li className='my-1'>An issue can be updated by clicking on it and adding your comments; or by replying to the email corresponding to the email (keeping support@promptcloud.com in the list of recipients and not changing the subject-line)</li>
                        <li className='my-1'>Tickets are classified based on their status as –</li>
                        <ol style={{listStyleType: 'alpha'}} className='mx-3 d-flex flex-column'>
                            <li className='my-1'>Not Started</li>
                            <li className='my-1'>In-progress</li>
                            <li className='my-1'>Waiting on Client</li>
                            <li className='my-1'>Closed</li>
                        </ol>
                        <li className='my-1'>Tickets are also classified based on their type as –</li>
                        <ol style={{listStyleType: 'alpha'}} className='mx-3 d-flex flex-column'>
                            <li className='my-1'>New Site</li>
                            <li className='my-1'>New Requirement</li>
                            <li className='my-1'>Data Issues</li>
                            <li className='my-1'>Other Issues</li>
                        </ol>
                        <li className='my-1'>The above classifications could be used to filter out tickets with ease.</li>
                        <li className='my-1'>The “Activities” tab can be used to view a timeline-style representation of all the tickets/activities pertaining to your projects.</li>
                    </ul>

                    <hr className='my-2' />
                    <h6 className='fw-bold my-3'>3. Billing</h6>
                    <ul style={{listStyleType: 'circle'}} className='mx-3'>
                        <li className='my-1'>Both invoicing and payments are integrated with the Client Dashboard.</li>
                        <li className='my-1'>Outstanding invoices can be found under the “Pending” tab and all the cleared ones under “Paid” tab.</li>
                        <li className='my-1'>Invoices shall get auto-debited every month in case no issues are raised within the stipulated time period.</li>
                        <li className='my-1'>Choose “Manage Cards” from the right side menu to add new or update your credit card information.</li>
                    </ul>
                    <hr className='my-2' />

                    <h5 className='my-3 fw-bold'>
                        For Technical Documentation visit the 
                        <a href='https://www.promptcloud.com/download/28168/' className='mx-2'>Data Sheet</a>
                    </h5>

                    <h5 className='my-3 fw-bold'>
                        For questions related to crawlboard, visit the 
                        <a href='/faq' className='mx-2'>FAQ</a>
                        section 
                    </h5>
                </CardBody>
            </Card>
        </Container>
    )
}
