import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Home } from "react-feather";
import Loader from "react-loader-spinner";
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, NavLink, Nav, NavItem, TabContent, TabPane } from "reactstrap";

export default function FieldUploadSpikeAndDrop() {
    const search = useLocation().search;
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('1');
    const [decreasedUpload, setDecreasedUpload] = useState([]);
    const [increasedUpload, setIncreasedUpload] = useState([]);
    const [noData, setNoData] = useState([]);
    const [insufficient, setInsufficient] = useState([]);
    const [decreasedUploadSearch, setDecreasedUploadSearch] = useState([]);
    const [increasedUploadSearch, setIncreasedUploadSearch] = useState([]);
    const [noDataSearch, setNoDataSearch] = useState([]);
    const [insufficienSearch, setInsufficientSearch] = useState([]);

    const [decreasedSearchText, setDecreasedSearchText] = useState('');
    const [increasedSearchText, setIncreasedSearchText] = useState('');
    const [noDataSearchText, setNoDataSearchText] = useState('');
    const [insufficientSearchText, setInsufficientSearchText] = useState('');


    const uploadColumns = [
        { name: 'Site Name', selector: 'site_name', sortable: true, center: true },
        { name: 'Present Upload', selector: 'present_upload', sortable: true, center: true },
        { name: 'Average Upload', selector: 'average_upload', sortable: true, center: true },

    ]
    const noDataColumns = [
        { name: 'Site Name', selector: 'site_name', sortable: true, center: true },
        { name: 'Field Count', selector: row => row?.fields?.count, sortable: true, center: true },
        { name: 'Field Names', selector: row => row?.fields?.join(','), sortable: true, center: true },
    ]

    useEffect(() => {
        axios.get(`/api/v1/internal/overall_system_reports/field_upload_spike_and_drop`)
            .then(res => {
                setDecreasedUpload(res.data.decreased_upload || []);
                setIncreasedUpload(res.data.increased_upload || []);
                setInsufficient(res.data.insufficient_upload || []);
                setNoData(res.data.no_data || [])
                setDecreasedUploadSearch(res.data.decreased_upload || []);
                setIncreasedUploadSearch(res.data.increased_upload || []);
                setInsufficientSearch(res.data.insufficient_upload || []);
                setNoDataSearch(res.data.no_data || [])
                setLoading(false);
            }).catch(err => {
                console.error(err?.message || 'Oops something went wrong');
                setLoading(false);
            })

    }, [])


    const handleNoDataChange = event => {
        if (event.target.value == '') {
            setNoDataSearch(noData)
        }
        else {
            setNoDataSearch(noData?.filter(item => item.site_name.includes(event.target.value)))
        }
        setNoDataSearchText(event.target.value)
    }

    const handleIncreasedChange = event => {
        if (event.target.value == '') {
            setIncreasedUploadSearch(increasedUpload)
        }
        else {
            setIncreasedUploadSearch(increasedUpload?.filter(item => item.site_name.includes(event.target.value)))
        }
        setIncreasedSearchText(event.target.value)
    }
    const handleInsufficientChange = event => {
        if (event.target.value == '') {
            setInsufficientSearch(insufficient)
        }
        else {
            setInsufficientSearch(insufficient?.filter(item => item.site_name.includes(event.target.value)))
        }
        setInsufficientSearchText(event.target.value)
    }
    const handleDecreasedChange = event => {
        if (event.target.value == '') {
            setDecreasedUploadSearch(decreasedUpload)
        }
        else {
            setDecreasedUploadSearch(decreasedUpload?.filter(item => item.site_name.includes(event.target.value)))
        }
        setDecreasedSearchText(event.target.value)
    }

    return <Container fluid>
        <div className="page-title">
            <Row>
                <Col xs="6">
                    <h3>Aggregated Alerts</h3>
                </Col>
                <Col xs="6">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={'/internal'}>
                                <Home />
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem className="fw-bold">
                            Field Level Count
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
        {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> : <Fragment>
            <Card>
                <CardBody className="fw-bold">
                    Note: For all the tabs, we collect the data for 7 days, use the last days data to compare and use other 6 days data to calculate average. For example, if we have data from 20160801 to 20160807, we will calculate average using data from 20160801 to 20160806. And we use data from 20160807 to compare.
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={activeTab == '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                Decreased Upload
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={activeTab == '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                Increased Upload
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={activeTab == '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                                No Data
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={activeTab == '4' ? 'active' : ''} onClick={() => setActiveTab('4')}>
                                Insufficient Data
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane className="panel-body" tabId={'1'}>
                            <p className="fw-bold">The sites under these tabs are those for which upload decreased by 50% of the average.</p>
                            <Row className="my-3">
                                <Col>
                                    <div className="float-end">
                                        <Row className="align-items-center">
                                            <Col sm="3">Search</Col>
                                            <Col>
                                                <input type="text" className="form-control" value={decreasedSearchText} onChange={handleDecreasedChange} />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <DataTable
                                columns={uploadColumns}
                                data={decreasedUploadSearch || []}
                                noHeader
                                highlightOnHover
                                pagination />
                        </TabPane>
                        <TabPane className="panel-body" tabId={'2'}>
                            <p className="fw-bold">The sites under these tabs are those for which upload decreased by 50% of the average.</p>
                            <Row className="my-3">
                                <Col>
                                    <div className="float-end">
                                        <Row className="align-items-center">
                                            <Col sm="3">Search</Col>
                                            <Col>
                                                <input type="text" className="form-control" value={increasedSearchText} onChange={handleIncreasedChange} />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <DataTable
                                columns={uploadColumns}
                                data={increasedUploadSearch || []}
                                noHeader
                                highlightOnHover
                                pagination />
                        </TabPane>
                        <TabPane className="panel-body" tabId={'3'}>
                            <p className="fw-bold">All the sites for which data is not present for current day. From the above example, sites which don't have data for 20160807 will come in this tab. All the sites which don't run daily may come here.</p>
                            <Row className="my-3">
                                <Col>
                                    <div className="float-end">
                                        <Row className="align-items-center">
                                            <Col sm="3">Search</Col>
                                            <Col>
                                                <input type="text" className="form-control" value={noDataSearchText} onChange={handleNoDataChange} />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <DataTable
                                columns={noDataColumns}
                                data={noDataSearch || []}
                                noHeader
                                highlightOnHover
                                pagination />
                        </TabPane>
                        <TabPane className="panel-body" tabId={'4'}>

                            <p className="fw-bold">The sites for which we don't have data for all the 6 days to calculate average. From the above example, if the data is missing from any date between 20160801 to 20160806, then that site will present in this tab.</p>
                            <Row className="my-3">
                                <Col>
                                    <div className="float-end">
                                        <Row className="align-items-center">
                                            <Col sm="3">Search</Col>
                                            <Col>
                                                <input type="text" className="form-control" value={insufficientSearchText} onChange={handleInsufficientChange} />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <DataTable
                                columns={noDataColumns}
                                data={insufficienSearch || []}
                                noHeader
                                highlightOnHover
                                pagination
                            />
                        </TabPane>
                    </TabContent>
                </CardBody>
            </Card>
        </Fragment>}
    </Container>
}
