/**
 * This javascript file contains all the KIbana URL for Kibava V8 which will be used wherever required
 * NOTE: These links are yet to be updated by the Platform Team, once done, it will be used everywhere 
 *       it is required. For now, adding it just for the reference
 */


// This is the URL for PLM cluster
export const KIBANA_PLM_URL = "https://kibanav8-plm.promptcloud.com/"

// This is the URL for Backend cluster
export const KIBANA_BE_URL = ""

// This is  the URL for JobsPikr cluster
export const KIBANA_JP_URL = "https://kibanav8-jp.promptcloud.com/"

// This is the URL for 42Signals cluster
export const KIBANA_42S_URL = "https://kibanav8-42signals.promptcloud.com/"