import React from 'react';
import SegmentsDataTable from '../DataTable/SegmentsDataTable.component';
import LoadingAnimation from '../../../../Common/LoadingAnimation/LoadingAnimation';
import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import {Row} from "reactstrap";
import axios from 'axios';
// Table data API Endpoint
const TABLE_DATA_API_ENDPOINT = '/api/v2/internal/overall_system_reports/segments_take_more_time_by_ednu_stage_wise?filter[crawl_date]=';

export default function DataTab(props) {

  // Data read from url parameters
  const seach = useLocation().search;
  const commit = new URLSearchParams(seach).get("commit");
  const urlDate = new URLSearchParams(seach).get("filter[crawl_date]");

  // States 
  const [key, setKey] = useState('');
  const [date, setDate] = useState(new Date());
  const [tableData, setTableData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(false);

  // When the app loads, set the key name which is used for table
  useEffect(async () => {
    await setKeyName();
    if (urlDate && new Date(urlDate) != date) {
      setDate(new Date(urlDate));
    }
    if (commit == 'Get Data' && urlDate) {
      await fetchDataFromDB(urlDate)
    }
    else {
      await fetchDataFromDB();
    }

    return ()=>{
      controller.abort();
    }
  }, [key,urlDate, commit]);


  // Set the keyname based on title
  async function setKeyName(title = props?.title) {
    switch (title) {
      case 'Extraction':
        setKey('seg_by_extraction');
        break;
      case 'Deadup':
        setKey('seg_by_dedup');
        break;
      case 'Normalization':
        setKey('seg_by_normalization');
        break;
      case 'Upload':
        setKey('seg_by_upload');
        break;
      default:
        setKey('');
    }
  };
  // Key name setter function ends here

  // Get data from back-end for each table based on title
  async function fetchDataFromDB(crawlDate = date) {
    setLoading(true);
    try {
      const res = await axios.get(TABLE_DATA_API_ENDPOINT + crawlDate);
      const data = await res?.data;
      setTableData(data[key]);
      setLoading(false);
    }
    catch (error) {
      console.error(error?.message || 'Oops something went wrong');
      setLoading(false);
    }
  };
  // Fetch data from DB ends here 

  // Helper function to handle search
  function onSearchHandler(event) {
    let {value} = event?.target;
    if(value!== ''){
      setSearchData(tableData.filter(item => item.site.includes(value)));
    }
    else{
      setSearchData(tableData);
    }
  };
  // Search handler ends here

  return (
    <div>
      {tableData?.length === 0 ? <LoadingAnimation /> :
        <div>
          <center className="fw-bold mt-4">{props?.title}</center>
          <Row className="align-items-center float-end my-3">
            <div className="col-sm-3">Search</div>
            <div className="col">
              <input className="form-control" type={'text'} onChange={(event) => onSearchHandler(event)} />
            </div>
          </Row>
          <SegmentsDataTable
            data={searchData.length!==0?searchData:tableData}
            loading ={loading} />
        </div>
      }
    </div>
  )
}
