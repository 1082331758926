import React from 'react';
import DataTable from 'react-data-table-component';

// Table column definitions
const columns = [
    {
        name: 'Site Name',
        selector: 'site_name',
        sortable: true,
        center: true
    },
    {
        name: 'Record Count',
        selector: 'record_count',
        sortable: true,
        center: true
    },
    {
        name: 'Upload time',
        selector: 'upload_time',
        sortable: true,
        center: true
    },
    {
        name: 'Download File',
        selector: 'download_file_link',
        center: true
    },
    {
        name: 'id',
        selector: 'id',
        sortable: true,
        center: true,
        omit: true
    },
];
export default function SitesTable({ data, rowSelect }) {
    return (
        <div className='mb-3 mt-4'>
            <DataTable
                data={data}
                columns={columns}
                selectableRows
                onSelectedRowsChange={rowSelect}
                noHeader
                responsive
                pagination />
        </div>
    )
}
