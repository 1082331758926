import React, {useState, useEffect} from 'react'
import { Alert } from 'reactstrap'
import Loader from 'react-loader-spinner';
import axios from 'axios'
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { useParams } from "react-router-dom";
import { JSONTree } from 'react-json-tree';
import ScrollArea from 'react-scrollbar';

export default function SegmentLevelDataFile({theme, downloadDoc, activeTabName}) {
  let { sitename } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [download, setDownload] = useState(false);
  const [dataFileColumns, setDataFileColumns] = useState([])

  useEffect(() => {
    if (activeTabName == "data_file"){
      setLoading(true)
      axios.get(`/api/v1/internal/qa/segment_level_qa_data_file?site_name=${sitename}&data_fetch_type=data_file`).then(res => {
        setData(res?.data?.data_file)

        if (res?.data?.data_file?.file_present){
          let tableColumns = res.data?.data_file?.file_content[0]
          let resultColumns = []
          tableColumns?.forEach((each, index) => {
            if (index == 6){
              resultColumns.push({
                name: each, selector: `a${index}`, width: '400px'
              })
            } else{
              resultColumns.push({
                name: each, selector: `a${index}`
              })
            }
          })
          setDataFileColumns(resultColumns)
        }
        setLoading(false)
      }).catch(err => {
        console.log("err", err);
        toast.error(err.response?.data?.message)
        setLoading(false)
      })
    }
  }, [activeTabName])

  const handleDataDownload = () => {
    setDownload(true)
    axios.get(`/api/v1/internal/qa/${sitename}/download_data_file`).then(res => {
      if (data.file_extension == "json"){
        downloadDoc(JSON.stringify(res.data), data.file_name, data.content_type)
      }
      else{
        downloadDoc(res.data, data.file_name, data.content_type)
      }
      setDownload(false)
    }).catch(err => {
      console.log("err", err);
      toast.error(err.response?.data?.message)
      setDownload(false)
    })
  } 

  const transformData = arr => {
    let result = []
    if (arr){
      arr?.forEach((each) => {
        let tempObj = {}
        each?.forEach((each2, index) => {
          tempObj = {...tempObj, [`a${index}`]: each2}
        })
        result.push(tempObj)
      })
      return result
    }
    else{
      return result
    }
  }

  return (
    <>
      {
        loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
          :
          <>
            {
              data?.file_present == false ?
              <Alert color='light'>
                <div dangerouslySetInnerHTML={{__html: data?.error_message}} />
              </Alert>
                : 
                <>
                  {
                    data?.has_error == true ?
                    <>
                      <Alert color='light'>
                        <div dangerouslySetInnerHTML={{__html: data?.error_message}} />
                      </Alert>
                    </>
                      :
                      <>
                        <div className='my-4'>
                          <p className='fw-bold m-0' style={{float: 'right'}}>
                            Last updated: {data?.last_updated_at}
                          </p> <br /> <br />
                          {
                            download ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
                              : 
                              <>
                                <Alert color='primary'>
                                  <h6 className="hidden"> <button className='btn text-white fw-bold' onClick={handleDataDownload}> Click here </button> to download the actual file</h6>
                                  <h6> <a href={data?.download_link} target="_blank" rel="noopener noreferrer" className='btn text-white fw-bold'> Click here </a>to download the actual file</h6>
                                  <h6>Showing atmost 200 randomly selected records.</h6>
                                </Alert>
                              </>
                          }
                        </div>
                        {Array.isArray(data?.file_content) ? <DataTable noHeader columns={dataFileColumns} responsive
                          data={transformData(data?.file_content[1])} pagination />
                            : 
                            <ScrollArea speed={0.8} className="area" contentClassName="content" style={{maxWidth: "100%"}}>
                              <JSONTree data={data?.file_content} theme={theme} invertTheme={false} />
                            </ScrollArea>
                        }
                      </>
                  }
                </>
            }
          </>
      }
    </>
  )
}
