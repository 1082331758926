import React from 'react'
import { Row, Col, Card, CardBody, Alert } from 'reactstrap';
import { Info, Link, ExternalLink } from 'react-feather';
import ReactTooltip from 'react-tooltip';
export default function ConfigRunningWaitingJobsTab({ data, siteValue}) {
  return (
    <div>
      <Row className='my-3'>
        <Col md={4}>
          <Card>
            <CardBody>
              <p className='fw-bold text-center'>Recrawl Interval</p>
              <div className='d-flex'>
                {data?.recrawl_interval?.map((each, index) => (
                  <p key={index} className='me-1 my-0'> <b>{Object.keys(each)[0]}: </b> {Object.values(each)[0]}, </p>
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <CardBody>
              <p className='fw-bold text-center'>Diskfetcher Type</p>
              <p>{data?.diskfetcher_type}</p>
            </CardBody>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <CardBody>
              <p className='fw-bold text-center'>Selenium Cluster</p>
              <p>{data?.selenium_cluster_info}</p>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Card>
          <CardBody>
            {data?.feedcrawl_worker_configs?.length > 0 ? <>
              <div className="d-flex justify-content-between my-2">
                <p className='fw-bold my-0'>Feedcrawl Worker Configuration</p>
                <button className='btn btn-success'>
                  Proxy Status:
                  {data?.proxy_infos?.feedcrawl_proxy_info?.is_proxy_enabled
                    ? <> Active <Info size={18} className='mx-2' data-tip data-for='proxy_active' /> </> : 'Inactive'}</button>
                <ReactTooltip multiline id='proxy_active'>
                  <div>
                    <p>Proxy Source: {data?.proxy_infos?.feedcrawl_proxy_info?.proxy_source || ''}</p>
                    <p>Proxy Country: {data?.proxy_infos?.feedcrawl_proxy_info?.proxy_country || ''}</p>
                    <p>Proxy Change Frequency: {data?.proxy_infos?.feedcrawl_proxy_info?.proxy_change_frequency || ''}</p>
                  </div>
                </ReactTooltip>
              </div>
              <table className='table my-2'>
                <thead>
                  <tr className='bg-light'>
                    <th className='fw-bold'>Geo</th>
                    <th className='fw-bold'>Rss Q size</th>
                    <th className='fw-bold'>Test urls Q</th>
                    <th className='fw-bold'>Max on one host</th>
                    <th className='fw-bold'>Max hosts</th>
                    <th className='fw-bold'>Curr Running jobs</th>
                    <th className='fw-bold'>Curr Waiting jobs</th>
                    <th className='fw-bold'>Killed</th>
                    <th className='fw-bold'>Failed</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.feedcrawl_worker_configs?.map((each, index) => (
                    <tr key={index}>
                      <td>
                        <a href={`/internal/clusterwise_worker_and_jobs_info?filter[geo_cluster]=${each?.geo}`} target='_blank'
                          data-tip data-for='geocluster' className='d-flex justify-content-center'>
                          {each?.geo} <Info size={18} className='mx-2' />
                        </a>
                        <ReactTooltip id='geocluster' multiline>
                          <p> Go to Site Resque Job Config </p>
                        </ReactTooltip>
                      </td>
                      <td>
                        <a href={`/internal/queue_sizes/search?filter%5Bqueue_type%5D=rss_queue&filter%5Bsite%5D=${siteValue || ''}`} target='_blank'
                          data-tip data-for='rsscluster' className='d-flex justify-content-center'>
                          {data?.rss_queue_size} <Info size={18} className='mx-2' />
                        </a>
                        <ReactTooltip id='rsscluster' multiline>
                          <p> Go to Queue Size view </p>
                        </ReactTooltip>
                      </td>
                      <td>
                        <a href={`/internal/queue_sizes/search?filter%5Bqueue_type%5D=test_run_queue&filter%5Bsite%5D=${siteValue || ''}`} target='_blank'
                          data-tip data-for='test_run_queue_size' className='d-flex justify-content-center'>
                          {data?.test_run_queue_size} <Info size={18} className='mx-2' />
                        </a>
                        <ReactTooltip id='test_run_queue_size' multiline>
                          <p> Go to Queue Size view </p>
                        </ReactTooltip>
                      </td>
                      <td>
                        {each?.max_on_one_host}
                      </td>
                      <td>
                        {each?.max_hosts}
                      </td>
                      <td>
                        {each?.current_running_jobs}
                      </td>
                      <td>
                        {each?.current_waiting_jobs}
                      </td>
                      <td>
                        {
                          each?.killed == 0 ? 0
                            :
                            <>
                              <p data-tip data-for='killed_count'>{each?.killed} <Info size={18} className='mx-2' /></p>
                              <ReactTooltip id='killed_count' multiline>
                                {`Killed data are showing by ${data?.days} days and getting it from resque`}
                              </ReactTooltip>
                            </>
                        }
                      </td>
                      <td>
                        {
                          each?.failed == 0 ? 0
                            :
                            <>
                              <p data-tip data-for='failed_count'>{each?.failed} <Info size={18} className='mx-2' /></p>
                              <ReactTooltip id='failed_count' multiline>
                                {`Failed data are showing by ${data?.days} days and getting it from resque`}
                              </ReactTooltip>
                            </>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
              :
              <>
                <p className='fw-bold my-0'>Feedcrawl Worker Configuration</p>
                <Alert color='primary'> No data Available </Alert> </>}
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            {data?.recrawl_worker_configs?.length > 0 ? <>
              <div className="d-flex justify-content-between my-2">
                <p className='fw-bold my-0'>Recrawl Worker Configuration</p>
                <button className='btn btn-success'>
                  Proxy Status:
                  {data?.proxy_infos?.recrawl_proxy_info?.is_proxy_enabled
                    ? <> Active <Info size={18} className='mx-2' data-tip data-for='proxy_active_2' /> </> : 'Inactive'}
                </button>
                <ReactTooltip multiline id='proxy_active_2'>
                  <div>
                    <p>Proxy Source: {data?.proxy_infos?.recrawl_proxy_info?.proxy_source || ''}</p>
                    <p>Proxy Country: {data?.proxy_infos?.recrawl_proxy_info?.proxy_country || ''}</p>
                    <p>Proxy Change Frequency: {data?.proxy_infos?.recrawl_proxy_info?.proxy_change_frequency || ''}</p>
                  </div>
                </ReactTooltip>
              </div>
              <table className='table my-2'>
                <thead>
                  <tr className='bg-light'>
                    <th className='fw-bold'>Geo</th>
                    <th className='fw-bold'>Max on one host</th>
                    <th className='fw-bold'>Max hosts</th>
                    <th className='fw-bold'>Curr Running jobs</th>
                    <th className='fw-bold'>Curr Waiting jobs</th>
                    <th className='fw-bold'>Killed</th>
                    <th className='fw-bold'>Failed</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.recrawl_worker_configs?.map((each, index) => (
                    <tr key={index}>
                      <td>
                        <a href={`/internal/clusterwise_worker_and_jobs_info?filter[geo_cluster]=${each?.geo}`} target='_blank'
                          data-tip data-for='geocluster' className='d-flex justify-content-center'>
                          {each?.geo} <Info size={18} className='mx-2' />
                        </a>
                        <ReactTooltip id='geocluster' multiline>
                          <p> Go to Site Resque Job Config </p>
                        </ReactTooltip>
                      </td>
                      <td>
                        {each?.max_on_one_host}
                      </td>
                      <td>
                        {each?.max_hosts}
                      </td>
                      <td>
                        {each?.current_running_jobs}
                      </td>
                      <td>
                        {each?.current_waiting_jobs}
                      </td>
                      <td>
                        {
                          each?.killed == 0 ? 0
                            :
                            <>
                              <p data-tip data-for='killed_count'>{each?.killed} <Info size={18} className='mx-2' /></p>
                              <ReactTooltip id='killed_count' multiline>
                                {`Killed data are showing by ${data?.days} days and getting it from resque`}
                              </ReactTooltip>
                            </>
                        }
                      </td>
                      <td>
                        {
                          each?.failed == 0 ? 0
                            :
                            <>
                              <p data-tip data-for='failed_count'>{each?.failed} <Info size={18} className='mx-2' /></p>
                              <ReactTooltip id='failed_count' multiline>
                                {`Failed data are showing by ${data?.days} days and getting it from resque`}
                              </ReactTooltip>
                            </>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </> : <>
              <p className='fw-bold my-0'>Recrawl Worker Configuration</p>
              <Alert color='primary'> No data Available </Alert> </>}
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            {data?.distributed_recrawl_worker_configs?.length > 0 ? <>
              <div className="d-flex justify-content-between my-2">
                <p className='fw-bold my-0'>Distributed Recrawl Worker Configuration</p>
              </div>
              <table className='table my-2'>
                <thead>
                  <tr className='bg-light'>
                    <th className='fw-bold'>Geo</th>
                    <th className='fw-bold'>Drss seed Q size</th>
                    <th className='fw-bold'>Max on one host</th>
                    <th className='fw-bold'>Max hosts</th>
                    <th className='fw-bold'>Curr Running jobs</th>
                    <th className='fw-bold'>Curr Waiting jobs</th>
                    <th className='fw-bold'>Killed</th>
                    <th className='fw-bold'>Failed</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.distributed_recrawl_worker_configs?.map((each, index) => (
                    <tr key={index}>
                      <td>
                        <a href={`/internal/clusterwise_worker_and_jobs_info?filter[geo_cluster]=${each?.geo}`} target='_blank'
                          data-tip data-for='geocluster' className='d-flex justify-content-center'>
                          {each?.geo} <Info size={18} className='mx-2' />
                        </a>
                        <ReactTooltip id='geocluster' multiline>
                          <p> Go to Site Resque Job Config </p>
                        </ReactTooltip>
                      </td>
                      <td>
                        <a href={`/internal/queue_sizes/search?filter%5Bqueue_type%5D=drss_seed_urls_queue&filter%5Bsite%5D=${siteValue || ''}`} target='_blank'
                          data-tip data-for='rsscluster' className='d-flex justify-content-center'>
                          {data?.drss_seed_urls_queue_size} <Info size={18} className='mx-2' />
                        </a>
                        <ReactTooltip id='rsscluster' multiline>
                          <p> Go to Queue Size view </p>
                        </ReactTooltip>
                      </td>
                      <td>
                        {each?.max_on_one_host}
                      </td>
                      <td>
                        {each?.max_hosts}
                      </td>
                      <td>
                        {each?.current_running_jobs}
                      </td>
                      <td>
                        {each?.current_waiting_jobs}
                      </td>
                      <td>
                        {
                          each?.killed == 0 ? 0
                            :
                            <>
                              <p data-tip data-for='killed_count'>{each?.killed} <Info size={18} className='mx-2' /></p>
                              <ReactTooltip id='killed_count' multiline>
                                {`Killed data are showing by ${data?.days} days and getting it from resque`}
                              </ReactTooltip>
                            </>
                        }
                      </td>
                      <td>
                        {
                          each?.failed == 0 ? 0
                            :
                            <>
                              <p data-tip data-for='failed_count'>{each?.failed} <Info size={18} className='mx-2' /></p>
                              <ReactTooltip id='failed_count' multiline>
                                {`Failed data are showing by ${data?.days} days and getting it from resque`}
                              </ReactTooltip>
                            </>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </> : <>
              <p className='fw-bold my-0'>Distributed Recrawl Worker Configuration</p>
              <Alert color='primary'> No data Available </Alert>
            </>}
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            {data?.dependent_data_worker_configs?.length > 0 ? <>
              <div className="d-flex justify-content-between my-2">
                <p className='fw-bold my-0'>Dependent Upload Worker Configuration</p>
              </div>
              <table className='table my-2'>
                <thead>
                  <tr className='bg-light'>
                    <th className='fw-bold'>Geo</th>
                    <th className='fw-bold'>Max on one host</th>
                    <th className='fw-bold'>Max hosts</th>
                    <th className='fw-bold'>Curr Running jobs</th>
                    <th className='fw-bold'>Curr Waiting jobs</th>
                    <th className='fw-bold'>Killed</th>
                    <th className='fw-bold'>Failed</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.dependent_data_worker_configs?.map((each, index) => (
                    <tr key={index}>
                      <td>
                        <a href={`/internal/clusterwise_worker_and_jobs_info?filter[geo_cluster]=${each?.geo}`} target='_blank'
                          data-tip data-for='geocluster' className='d-flex justify-content-center'>
                          {each?.geo} <Info size={18} className='mx-2' />
                        </a>
                        <ReactTooltip id='geocluster' multiline>
                          <p> Go to Site Resque Job Config </p>
                        </ReactTooltip>
                      </td>
                      <td>
                        {each?.max_on_one_host}
                      </td>
                      <td>
                        {each?.max_hosts}
                      </td>
                      <td>
                        {each?.current_running_jobs}
                      </td>
                      <td>
                        {each?.current_waiting_jobs}
                      </td>
                      <td>
                        {
                          each?.killed == 0 ? 0
                            :
                            <>
                              <p data-tip data-for='killed_count'>{each?.killed} <Info size={18} className='mx-2' /></p>
                              <ReactTooltip id='killed_count' multiline>
                                {`Killed data are showing by ${data?.days} days and getting it from resque`}
                              </ReactTooltip>
                            </>
                        }
                      </td>
                      <td>
                        {
                          each?.failed == 0 ? 0
                            :
                            <>
                              <p data-tip data-for='failed_count'>{each?.failed} <Info size={18} className='mx-2' /></p>
                              <ReactTooltip id='failed_count' multiline>
                                {`Failed data are showing by ${data?.days} days and getting it from resque`}
                              </ReactTooltip>
                            </>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </> : <>
              <p className='fw-bold my-0'>Distributed Recrawl Worker Configuration</p>
              <Alert color='primary'> No data Available </Alert> </>}
          </CardBody>
        </Card>
      </Row>
    </div>
  )
}
