import React, {forwardRef, useImperativeHandle, useState, 
  useEffect } from 'react';
import {Row, Col, Label,Input, Form, FormGroup, Table, Button } from 'reactstrap'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dropzone from 'react-dropzone-uploader';
import DatePicker from "react-datepicker";
import axios from 'axios'
import { toast } from 'react-toastify'
import { useHistory } from "react-router-dom";
import XLSX from 'xlsx'


const Feasibility_SiteDetails = forwardRef(({data, setDataToggle, dataToggle,
  globalTableData, v2Data, dataDisable, setDataToggle2}, ref) => {
    const [overallCosts, setOverallCosts] = useState({
      siteSetup: 0, maintenance: 0, volume: 0, freeRecords: 0
    })
    const [checks, setChecks] = useState({
      feasibilityReport: true, skipAgreements: data.skip_agreements, skipInvoice: data.skip_invoice, 
      customContracts: false
    })
    const [sitesData, setSitesData] = useState([])
    const [sitesUrlDetails, setSitesUrlDetails] = useState([])
    const [additionalCosts, setAdditionalCosts] = useState(0.0)
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [feasibleStatus, setFeasibleStatus] = useState('feasible')
    const [newRows, setNewRows] = useState(['row1', 'row2', 'row3', 'row4'])
    const [urlData, setUrlData] = useState({
      'row1': {url: '', rss_urls_crawl_limit: ''},
      'row2': {url: '', rss_urls_crawl_limit: ''},
      'row3': {url: '', rss_urls_crawl_limit: ''},
      'row4': {url: '', rss_urls_crawl_limit: ''},
    })
    const [localData, setLocalData] = useState({})
    const [inpData, setInpData] = useState({})
    const [costData, setCostData] = useState({})
    const [errors, setErrors] = useState({})
    const [valid, setValid] = useState(true)
    const [version, setVersion] = useState('')
    const [comments, setComments] = useState('')
    const [additionalDetails, setAdditionalDetails] = useState({})
    let history = useHistory();

    const environment = localStorage.getItem("environment");
    let accessEnv = ['development', 'staging']

    useEffect(() => {
      let totalErrors = Object.values(errors).filter(x => x.url != '')
      let correctUrls = Object.values(urlData).filter(x => x.url != '').filter(y => isURL(y.url))
      if (correctUrls.length == 0 && sitesUrlDetails.length > 0){
        Array.prototype.push.apply(correctUrls, sitesUrlDetails)
      }
      else if(totalErrors.length == 0 && correctUrls.length >= 1){
        setValid(true)
        setSitesUrlDetails(correctUrls)
      }
      else{
        setValid(false)
      }
      setVersion(data.project_version)
      if (data.project_version === 'v3'){
        setAdditionalDetails({
          merge_files_fee: 0, image_downloads_fee: 0
        })
      }
      else if (data.project_version === 'v2'){
        setAdditionalDetails({
          merge_files_fee: 0, image_downloads_fee: 0, 
          two_days_of_delivery_fee: 0, hosted_indexing_fee: 1,
        })
      }
    }, [errors, urlData])  

    useEffect(() => {
      if (data.websites && data.websites.length > 0){
        let websitesURLs = []
        data.websites.forEach(each => {
          websitesURLs.push(each.sample_url)
        })
        setSitesData(websitesURLs)
      }
      setLocalData(data)

    }, [dataToggle, data])

    useEffect(() => {

      if (dataDisable){
        let keysObj = {}
        let keysObj2 = {}
        sitesData.forEach((each) => {
          let idObj = data.websites.filter(x => x.sample_url === each)[0]
          keysObj = {
            ...keysObj, [each]: !idObj?.feasibility_status, 
          }
          keysObj2 = {
            ...keysObj2,
            [each]: {
              id: idObj.id,
              siteSetup: idObj.site_setup_fee, siteMaintenance: idObj.monthly_maintenance_fee,
              volumeCharges: idObj.volume_charges, freeRecords: idObj.number_of_free_records
            }
          }
        })
        setInpData(keysObj)
        setCostData(keysObj2)
      }
      else{
        let keysObj = {}
        let keysObj2 = {}
        sitesData.forEach((each) => {
          let idObj = data.websites.filter(x => x.sample_url === each)[0]
          keysObj = {
            ...keysObj, [each]: true
          }
          keysObj2 = {
            ...keysObj2,
            [each]: {
              id: idObj.id,
              siteSetup: 0, siteMaintenance: 0,
              volumeCharges: 0, freeRecords: 0
            }
          }
        })
        setInpData(keysObj)
        setCostData(keysObj2)
      }
    }, [sitesData])

    const handleOverall = (e, name) => {
      let tempObj = costData
      Object.keys(tempObj).forEach(each => {
        tempObj = {
          ...tempObj, [each]: {
            ...tempObj[each], [name]: e.target.value
          }
        }
      })
      setCostData(tempObj)
    }

    const toggle = () => {
      setModal(!modal)
    };
    const closeBtn = <button className="close" 
      onClick={() => setModal2(false)}> &times; </button>;

    function getFormattedDate(date) {
      date = new Date(date)
      var year = date.getFullYear();

      var month = (1 + date.getMonth()).toString();
      month = month.length > 1 ? month : '0' + month;

      var day = date.getDate().toString();
      day = day.length > 1 ? day : '0' + day;

      return month + '/' + day + '/' + year;
    }

    const updateDeliveryDetails = () => {
      if (localData.project_version === 'v3'){
        let tempRows = Object.values(localData.delivery_frequency_details)
          .filter(x => x !== "")
          .filter(x => x !== "none")
        if (localData.delivery_details.crawl_type === 'none'){
          toast.error('Please select crawl type')
        }
        else if ((localData.delivery_details.frequency !== 'daily') && tempRows.length === 0){
          toast.error('Please select frequency method')
        }
        else{
          axios.put(`/api/v1/admins/feasibility_checks/${localData.id}/update_delivery_details`, {
            submit_requirements : {
              crawl_type: localData.delivery_details.crawl_type,
              delivery_format: localData.delivery_details.delivery_format,
              delivery_frequency: localData.delivery_details.frequency,
              delivery_method: localData.delivery_details.delivery_method, 
              delivery_frequency_details: {
                ...localData.delivery_frequency_details
              }
            }
          }).then(res => {
            if (dataDisable){
              history.push(`/admins/feasibility_checks/${localData.id}/edit`)
            }
            else{
              history.push(`/admins/feasibility_checks/${localData.id}`)
            }
            toast.success(res.data.message)
            setModal(false)
          })
            .catch(err => {
              toast.error(err.response.data.message)
            })
        }
      }
      else{
        if (localData.delivery_details.delivery_method === 'api'){
          axios.put(`/api/v1/admins/feasibility_checks/${localData.id}/update_delivery_details`, {
            submit_requirements : {
              crawl_type: localData.delivery_details.crawl_type,
              delivery_format: localData.delivery_details.delivery_format,
              delivery_frequency: localData.delivery_details.frequency,
              delivery_method: localData.delivery_details.delivery_method, 
              delivery_frequency_details: {
                ...localData.delivery_frequency_details
              }, delivery_method_details: {}
            }
          }).then(res => {
            setDataToggle(currentState => !currentState)
            toast.success(res.data.message)
            setModal(false)
          })
            .catch(err => {
              toast.error(err.response.data.message)
            })
        }
        else if (localData.delivery_details.delivery_method === 's3'){   
          try {
            if(localData.delivery_method_details && 
              localData.delivery_method_details.s3_bucket_name.length > 0 &&
              localData.delivery_method_details.s3_id.length > 0 &&
              localData.delivery_method_details.s3_key.length > 0 &&
              localData.delivery_method_details.s3_location.length > 0){
              axios.put(`/api/v1/admins/feasibility_checks/${localData.id}/update_delivery_details`, {
                submit_requirements : {
                  crawl_type: localData.delivery_details.crawl_type,
                  delivery_format: localData.delivery_details.delivery_format,
                  delivery_frequency: localData.delivery_details.frequency,
                  delivery_method: localData.delivery_details.delivery_method, 
                  delivery_frequency_details: {
                    ...localData.delivery_frequency_details
                  }, delivery_method_details: {
                    s3_bucket_name: localData.delivery_method_details.s3_bucket_name,
                    s3_id: localData.delivery_method_details.s3_id,
                    s3_key: localData.delivery_method_details.s3_key,
                    s3_location: localData.delivery_method_details.s3_location,
                  }
                }
              }).then(res => {
                setDataToggle(currentState => !currentState)
                toast.success(res.data.message)
                setModal(false)
              })
                .catch(err => {
                  toast.error(err.response.data.message)
                })
            }
            else{
              toast.error('Please enter all bucket details')
            } 
          } catch (error) {
            toast.error('Please enter all bucket details')
          }   
        }
        else if (localData.delivery_details.delivery_method === 'dropbox'){
          try {
            if(localData.delivery_method_details && 
              localData.delivery_method_details.dropbox_access_token.length > 0){
              axios.put(`/api/v1/admins/feasibility_checks/${localData.id}/update_delivery_details`, {
                submit_requirements : {
                  crawl_type: localData.delivery_details.crawl_type,
                  delivery_format: localData.delivery_details.delivery_format,
                  delivery_frequency: localData.delivery_details.frequency,
                  delivery_method: localData.delivery_details.delivery_method, 
                  delivery_frequency_details: {
                    ...localData.delivery_frequency_details
                  }, delivery_method_details: {
                    dropbox_access_token: localData.delivery_method_details.dropbox_access_token,
                  }
                }
              }).then(res => {
                setDataToggle(currentState => !currentState)
                toast.success(res.data.message)
                setModal(false)
              })
                .catch(err => {
                  toast.error(err.response.data.message)
                })
            }
            else{
              toast.error('Please enter all dropbox details')
            }
          } catch (error) {
            toast.error('Please enter all bucket details')
          }
        }
        else if (localData.delivery_details.delivery_method === 'box'){
          try {
            if(localData.delivery_method_details && 
              localData.delivery_method_details.box_id.length > 0 &&
              localData.delivery_method_details.box_secret.length > 0 &&
              localData.delivery_method_details.box_username.length > 0 &&
              localData.delivery_method_details.box_password.length > 0){
              axios.put(`/api/v1/admins/feasibility_checks/${localData.id}/update_delivery_details`, {
                submit_requirements : {
                  crawl_type: localData.delivery_details.crawl_type,
                  delivery_format: localData.delivery_details.delivery_format,
                  delivery_frequency: localData.delivery_details.frequency,
                  delivery_method: localData.delivery_details.delivery_method, 
                  delivery_frequency_details: {
                    ...localData.delivery_frequency_details
                  }, delivery_method_details: {
                    box_id: localData.delivery_method_details.box_id,
                    box_username: localData.delivery_method_details.box_username,
                    box_secret: localData.delivery_method_details.box_secret,
                    box_password: localData.delivery_method_details.box_password,
                  }
                }
              }).then(res => {
                setDataToggle(currentState => !currentState)
                toast.success(res.data.message)
                setModal(false)
              })
                .catch(err => {
                  toast.error(err.response.data.message)
                  setModal(true)
                })
            }
            else{
              toast.error('Please enter all box details')
            }
          } catch (error) {
            toast.error('Please enter all box details')
          }
        }
        else if (localData.delivery_details.delivery_method === 'ftp'){
          try {
            if(localData.delivery_method_details && 
              localData.delivery_method_details.ftp_server.length > 0 &&
              localData.delivery_method_details.ftp_username.length > 0 &&
              localData.delivery_method_details.ftp_password.length > 0){
              axios.put(`/api/v1/admins/feasibility_checks/${localData.id}/update_delivery_details`, {
                submit_requirements : {
                  crawl_type: localData.delivery_details.crawl_type,
                  delivery_format: localData.delivery_details.delivery_format,
                  delivery_frequency: localData.delivery_details.frequency,
                  delivery_method: localData.delivery_details.delivery_method, 
                  delivery_frequency_details: {
                    ...localData.delivery_frequency_details
                  }, delivery_method_details: {
                    ftp_server: localData.delivery_method_details.ftp_server,
                    ftp_username: localData.delivery_method_details.ftp_username,
                    ftp_password: localData.delivery_method_details.ftp_password,
                  }
                }
              }).then(res => {
                setDataToggle(currentState => !currentState)
                toast.success(res.data.message)
                setModal(false)
              })
                .catch(err => {
                  toast.error(err.response.data.message)
                  setModal(true)
                })
            }
            else{
              toast.error('Please enter all ftp details')
            }
          } catch (error) {
            toast.error('Please enter all ftp details')
          }
        }
      }
    }

    const toggle2 = () => {
      if (modal2){
        stepValidate().then(res => {
          axios.post(`/api/v1/organizations/${localData.organization_identifier}/sites`, { 
            "submit_requirements" : {
              sitegroup_id: localData.sitegroup_id,
              site_add_from: "feasibility_check",
              site_details : sitesUrlDetails
            }
          }).then(res => {
            if (res.status === 200){
              toast.success(res.data.message)
              setModal2(false)
            }
          }).catch(err => {
            toast.error(err.response.data.message)
          }).finally(() => setDataToggle(curr => !curr))
        }).catch(error => {
          toast.error(error?.response?.data?.message)
        })
      }else {
        setModal2(true)
      }
    };

    const feasibleCheck = async (key, val) => {
      let promise = new Promise((resolve, reject) => {
        axios.post('/api/v1/sites/check_feasibility', {
          "sites": {"sample_page_url": val}
        }).then(res => {
          const data = res.data
          if (data.status != "success"){
            setErrors({
              ...errors, [key]: {url: 'This URL is not feasible'}
            })
            reject(false)
          }
          else{
            resolve(true)
          }
        }).catch(res => {
          setErrors({
            ...errors, [key]: {url: 'This URL is not feasible'}
          })
          reject(false)
        })
      });

      let result = await promise;
      return result
    }

    const validateUrl = async (key, value) => {
      let promise = new Promise((resolve, reject) => {
        let urls = Object.values(urlData).filter(x => x.url != '').map(x => x.url)
        let sitenames = urls.map(x => getSiteName(x))
        let sitename = getSiteName(value)
        let result = find_duplicate_in_array(sitenames);
        if(result.length > 0 && result.includes(sitename) && value != ""){
          setErrors({
            ...errors, [key]: {url: "Same sites can't be added again, Please enter a different site."}
          })
          reject(false)
        }
        else{
          setErrors({
            ...errors, [key]: {url: ''}
          })
          resolve(true)
        }
      });

      let result = await promise;
      return result
    }

    const stepValidate = async () => { 
      let promise = new Promise((resolve, reject) => {
        let feasRows = Object.keys(urlData).filter(x => urlData[x].url != "").filter(x => isURL(urlData[x].url))
        if (feasRows.length === 0){
          toast.error('please enter atleast one feasible url')
        }
        let wrongUrls = 0
        feasRows.forEach((x, index) => {
          feasibleCheck(x, urlData[x].url)
            .then(res => {
              setValid(res)
            })
            .catch(res => {
              wrongUrls += 1
            })
            .finally(() => {
              if (index == feasRows.length - 1 && wrongUrls == 0){
                let tempErrs = Object.values(errors).filter(x => x.url != '')
                let stepPass = (feasRows.length >= 1 && tempErrs.length == 0) ? true : false
                if (stepPass){
                  resolve(true)
                }else{
                  reject(false)
                }
              }
            })
          validateUrl(x, urlData[x].url)
            .then(res => {
              console.log("res", res);
            })
            .catch(res => {
              wrongUrls += 1
            })
            .finally(() => {
              if (index == feasRows.length - 1 && wrongUrls == 0){
                let tempErrs = Object.values(errors).filter(x => x.url != '')
                let stepPass = (feasRows.length >= 1 && tempErrs.length == 0) ? true : false
                if (valid || stepPass){
                  resolve(true)
                }else{
                  reject(false)
                }
              }
            })
        })
      });

      let result = await promise;
      return result 
    }

    function isURL(str) {
      if (str == undefined){
        return true
      }
      var url = new RegExp(/^((www\.)|(http(s*)))/i);
      return str.length < 2083 && url.test(str)
    }

    function getSiteName(str){
      var url_arr = str.split("://")
      if (url_arr.length > 1) {
        url_arr.shift()
      }
      var sitename;
      sitename = url_arr.join("").split("/")[0].split(".").join("_")
      sitename = sitename.split("www_").join("")
      sitename = sitename.split("-").join("_")
      return sitename
    }

    function find_duplicate_in_array(arra1) {
      var object = {};
      var result = [];

      arra1.forEach(function (item) {
        if(!object[item])
          object[item] = 0;
        object[item] += 1;
      })

      for (var prop in object) {
        if(object[prop] >= 2) {
          result.push(prop);
        }
      }
      return result;
    }

    const handleURL = (value, element) => {
      setUrlData({
        ...urlData, [element]: {...urlData[element], url: value}
      })
      let urls = Object.values(urlData).filter(x => x.url != '').map(x => x.url)
      let rss_urls_crawl_limit = urlData[element].rss_urls_crawl_limit
      if(urls.includes(value) && value != ""){
        setErrors({
          ...errors, [element]: {url: 'Entered URL is already present'}
        })
      }
      else if((rss_urls_crawl_limit.toString().length > 0) && value.length == 0 ){
        setErrors({
          ...errors, [element]: {url: 'This field is required if rss urls crawl limit has value!'}
        })
      }
      else if(rss_urls_crawl_limit.length > 0 && !/^\d+$/.test(rss_urls_crawl_limit)){
        setErrors({
          ...errors, [element]: {rss_urls_crawl_limit: "This field should be integer!"}
        })
      }
      else if(isURL(value) || value == ''){
        setErrors({
          ...errors, [element]: {url: ''}
        })
      }
      else{
        setErrors({
          ...errors, [element]: {url: 'Please enter a valid URL'}
        })
      }
    }

    const handleRssUrlsCrawlLimit = (value, element) => {
      setUrlData({
        ...urlData, [element]: { ...urlData[element], rss_urls_crawl_limit: value}
      })
      if((urlData[element].url.length == 0) && value.length > 0 ){
        setErrors({
          ...errors, [element]: {url: 'This field is required if rss urls crawl limit has value!'}
        })
      }
      else if(value.length > 0 && !/^\d+$/.test(value)){
        setErrors({
          ...errors, [element]: {rss_urls_crawl_limit: "This field should be integer!"}
        })
      }
      else{
        setErrors({
          ...errors, [element]: {url: '', rss_urls_crawl_limit: ''}
        })
      }
    }

    const handleRowsUpdate = () => {
      setNewRows([...newRows, `row${newRows.length + 1}`])
      let new_name = 'row' + (newRows.length+1)
      setUrlData({...urlData, [new_name]: {url: '', rss_urls_crawl_limit: ''}})
    }

    const handleRadioBtn = (val) => {
      setLocalData({
        ...localData, delivery_details: {
          ...localData.delivery_details && localData.delivery_details,
          crawl_type: val
        }
      })
    }

    const handleRadioBtn2 = (val) => {
      setLocalData({
        ...localData, delivery_details: {
          ...localData.delivery_details && localData.delivery_details,
          delivery_format: val
        }
      })
    }

    const handleSelect = (val) => {
      setLocalData({
        ...localData, delivery_details: {
          ...localData.delivery_details && localData.delivery_details,
          delivery_method: val
        },
        delivery_method_details: {}
      })
    }

    const handleFrequency = (val) => {
      setLocalData({
        ...localData, delivery_details: {
          ...localData.delivery_details && localData.delivery_details,
          frequency: val
        },
        delivery_frequency_details: {}
      })
    }

    const handleFreqWeekly = (val) => {
      setLocalData({
        ...localData, 
        delivery_frequency_details: {
          days: val
        }
      })
    }

    const handleDateChange = (val) => {
      setLocalData({
        ...localData, delivery_frequency_details: {
          date: getFormattedDate(val)
        }
      })
    }

    useImperativeHandle(ref, () => ({
      isValidated() {
        return true
      }
    }));

    const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

    const handleChangeStatus = (uploadData, status) => {
      const { meta, file } = uploadData
      if (status === "done"){

        var fileReader = new FileReader();
        fileReader.onload = function(event) {
          var workbook = XLSX.read(event.target.result, {
            type: "binary"
          });
          workbook.SheetNames.forEach(sheet => {
            let rowObject = XLSX.utils.sheet_to_row_object_array(
              workbook.Sheets[sheet]
            );
            let headerCondition;
            if (accessEnv.includes(environment)) {
              headerCondition = ("page_url" in rowObject[0] && "rss_urls_crawl_limit" in rowObject[0])
            }
            else {
              headerCondition = ("page_url" in rowObject[0])
            }
            if (rowObject.length === 0){
              toast.error("Uploaded file is empty")
            }
            else if (headerCondition){
              let tempObj = {
                'row1': {url: '', rss_urls_crawl_limit: ''}, 
                'row2': {url: '', rss_urls_crawl_limit: ''}, 
                'row3': {url: '', rss_urls_crawl_limit: ''}, 
                'row4': {url: '', rss_urls_crawl_limit: ''}, 
              }

              let newRowsArr = []

              rowObject.forEach((each2, index) => {
                let rss_urls_crawl_limit = each2?.rss_urls_crawl_limit? each2.rss_urls_crawl_limit : ""
                tempObj[`row${index+1}`] = {url: each2.page_url, rss_urls_crawl_limit: rss_urls_crawl_limit}
                newRowsArr.push(`row${index+1}`)
              })
              setNewRows(newRowsArr)
              setUrlData(tempObj)
            }
            else{
              toast.error("Please keep column headers as mentioned in sample excel file")
            }
          });
        };
        fileReader.readAsBinaryString(file);
      }
      else if (status === "removed")
      {

        setNewRows(['row1', 'row2', 'row3', 'row4'])
        setUrlData(
          { 
            'row1': {url: '', rss_urls_crawl_limit: ''}, 
            'row2': {url: '', rss_urls_crawl_limit: ''}, 
            'row3': {url: '', rss_urls_crawl_limit: ''}, 
            'row4': {url: '', rss_urls_crawl_limit: ''}, 
          }
        )
      }
    }

    const handleSubmit = (files, allFiles) => {
      allFiles.forEach(f => f.remove())
      toast.success("Dropzone successfully submitted !");
    }

    const HandleCB = (e, isOverall) => {
      if (isOverall){
        let tempData = inpData
        Object.keys(tempData).forEach(each => {
          tempData = {
            ...tempData, [each]: !e.target.checked
          }
        })
        setInpData(tempData)
      }
      else{
        let tempData = inpData
        Object.keys(tempData).forEach(each => {
          if (e.target.name == each){
            tempData = {
              ...tempData, [each]: !e.target.checked
            }
          }
        })
        setInpData(tempData)
      }
    }

    const discardOrganization = () => {
      axios.post(`/api/v1/admins/feasibility_checks/${localData.id}/discard_requirement`, {
        feasibility_report_id: localData.id
      }).then(res => {
        toast.success(res.data.message)
        history.push(`/admins/feasibility_checks/`)
      }).catch(err => {
        toast.error(err.response.data.message)
      })
    }

    const generateFeasibilityReport = async () => {
      let sitesDetails = []
      let schemaDetails = []
      let originalUrls = []
      let tempObj = {}

      Object.keys(additionalDetails).forEach(each => {
        if (additionalDetails[each] !== 0){
          tempObj[each] = parseFloat(additionalDetails[each]) ? parseFloat(additionalDetails[each]) : 0
        }
      })
      await Object.keys(costData).forEach(eachUrl => {
        sitesDetails.push({
          site_id: costData[eachUrl].id,
          feasibility_status: inpData[eachUrl] ? 'not_feasible' : 'feasible',
          site_setup_fee: costData[eachUrl].siteSetup,
          monthly_maintenance_fee: costData[eachUrl].siteMaintenance,
          volume_charges: costData[eachUrl].volumeCharges,
          number_of_free_records: costData[eachUrl].freeRecords
        })
      })

      if (data.project_version === 'v2'){
        const resData = {
          org_feasibility_check: {
            sitegroup_id: data.sitegroup_id, feasibility_report_id: data.id,
            comments: comments, skip_agreements: checks.skipAgreements,
            skip_invoice: checks.skipInvoice, use_custom_contracts: false,
            overall_feasibility: feasibleStatus, project_version: data.project_version,
            organization_name: data.organization_name,
            send_feasibility_report: true, any_additional_cost: additionalCosts,
            additional_information: v2Data.additional_information ? v2Data.additional_information : data.additional_information,
            fields_to_extract: v2Data.fields_to_extract ? v2Data.fields_to_extract : data.fields_to_extract,
            sites_details: sitesDetails,
            additional_details_fee: tempObj
          }
        }
        if (dataDisable){
          axios.put(`/api/v1/admins/feasibility_checks/${localData.id}`, resData)
            .then((res) => {
              toast.success(res.data.message)
              setTimeout(() => {
                setDataToggle2(curr => !curr)
                history.push(`/admins/feasibility_checks/${localData.id}`)
              }, 2000);
            })
            .catch(err => {
              toast.error(err.response.data.message)
            })
        }
        else{
          axios.post('/api/v1/admins/feasibility_checks', resData)
            .then((res) => {
              toast.success(res.data.message)
              setTimeout(() => {
                setDataToggle2(curr => !curr)
              }, 2000);
            })
            .catch(err => {
              toast.error(err.response.data.message)
            })
        }
      }
      else if (data.project_version === 'v3'){
        await data.schema_fields_details.forEach(each => {
          originalUrls.push(each.id)
        })
        await Object.keys(globalTableData).forEach(eachRow => {
          if (globalTableData[eachRow] !== null){
            schemaDetails.push({    
              id: globalTableData[eachRow].id,
              field_name: globalTableData[eachRow].field_name,
              data_type: globalTableData[eachRow].data_type,
              is_mandatory: globalTableData[eachRow].is_mandatory,
              default_value: globalTableData[eachRow].default_value,
              sample_value: globalTableData[eachRow].sample_value,
              comments: globalTableData[eachRow].comments,
            })
            originalUrls = originalUrls.filter(each => each !== globalTableData[eachRow].id)
          }
        })
        const resData = {
          org_feasibility_check: {
            sitegroup_id: data.sitegroup_id, feasibility_report_id: data.id,
            comments: comments, skip_agreements: checks.skipAgreements,
            skip_invoice: checks.skipInvoice, use_custom_contracts: false,
            overall_feasibility: feasibleStatus, project_version: data.project_version,
            organization_name: data.organization_name,
            send_feasibility_report: true, any_additional_cost: additionalCosts,
            additional_information: v2Data.additional_information ? v2Data.additional_information : data.additional_information,
            schema_fields_details: schemaDetails,
            sites_details: sitesDetails, remove_schema_fields: {ids: originalUrls},
            additional_details_fee: tempObj
          }
        }
        if (dataDisable){
          axios.put(`/api/v1/admins/feasibility_checks/${localData.id}`, resData)
            .then((res) => {
              toast.success(res.data.message)
              setTimeout(() => {
                setDataToggle2(curr => !curr)
                history.push(`/admins/feasibility_checks/${localData.id}`)
              }, 2000);
            })
            .catch(err => {
              toast.error(err.response.data.message)
            })
        } else {
          axios.post('/api/v1/admins/feasibility_checks', resData)
            .then((res) => {
              toast.success(res.data.message)
              setTimeout(() => {
                setDataToggle2(curr => !curr)
              }, 2000);
            })
            .catch(err => {
              toast.error(err.response.data.message)
            })
        }
      }
    }

    const handleExcelDownload = () => {
      axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem("authToken")}`;
      axios.defaults.headers["Content-Type"] = `application/vnd.ms-excel`;
      axios.defaults.headers["Accept"] = `application/vnd.ms-excel`;
      axios({
        url: `/api/v1/download_sample_site_details`,
        method: 'GET',
        responseType: 'blob'
      })
        .then((res) => {
          const url_to_download = window.URL.createObjectURL(new Blob([res.data]));
          const a_tag = document.createElement('a');
          a_tag.href = url_to_download;
          a_tag.setAttribute('download', `sample_site_urls.xls`);
          document.body.appendChild(a_tag);
          a_tag.click();
        });
      axios.defaults.headers["Content-Type"] = `application/json`;
      axios.defaults.headers["Accept"] = `application/json`;
    }

    return (
      <div className='p-3'>
        <div className='mt-3 feasibility__site-details'>
          <Table responsive>
            <thead>
              <tr>
                <th>
                  #
                </th>
                <th>
                  Sample URL
                </th>
                <th className=''>
                  <input type="checkbox" className='form-check-input ms-2'
                    checked={Object.values(inpData).filter(x => x == true).length === 0}
                    name='feasibility' onChange={(e) => HandleCB(e, true)}  />
                </th>
                <th>
                  Site setup
                </th>
                <th>
                  Site Maintenance
                </th>
                <th>
                  Volume charges
                </th>
                <th>
                  No.of free records
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(inpData).map((each, index) => {
                return(
                  <tr key={index}>
                    <td scope='row'>
                      {index + 1}
                    </td>
                    <td>
                      <a href={each}>{each}</a>
                    </td>
                    <td>
                      {dataDisable ? 
                      <input type="checkbox" name={each} className='form-check-input' 
                        onChange={(e) => HandleCB(e, false)} checked={!inpData[each]} />
                          : <input type="checkbox" name={each} className='form-check-input' 
                            onChange={(e) => HandleCB(e, false)} checked={!inpData[each]} />}
                          </td>
                          <td>
                            <input type="text" name={each} className='form-control' 
                              disabled={inpData[each]} value={inpData[each] ? 0 : costData[each]?.siteSetup} 
                              onChange={(e) => setCostData({ ...costData, 
                                [each]: { ...costData[each], siteSetup: e.target.value }})}/>
                            </td>
                            <td>
                              <input type="text" name={each} className='form-control' 
                                disabled={inpData[each]} value={inpData[each] ? 0 : costData[each]?.siteMaintenance} 
                                onChange={(e) => setCostData({ ...costData, 
                                  [each]: { ...costData[each], siteMaintenance: e.target.value }})}/>
                              </td>
                              <td>
                                <input type="text" name={each} className='form-control' 
                                  disabled={inpData[each]} value={inpData[each] ? 0 : costData[each]?.volumeCharges}
                                  onChange={(e) => setCostData({ ...costData, 
                                    [each]: { ...costData[each], volumeCharges: e.target.value }})}/>
                                </td>
                                <td>
                                  <input type="text" name={each} className='form-control' 
                                    disabled={inpData[each]} value={inpData[each] ? 0 : costData[each]?.freeRecords}
                                    onChange={(e) => setCostData({ ...costData, 
                                      [each]: { ...costData[each], freeRecords: e.target.value }})} />
                                  </td>
                                </tr>
                )
              })}
            </tbody>
          </Table>

          {version == 'v3' && 
          <div>
            {!dataDisable && <button className='btn btn-success btn-sm d-flex mx-auto mt-3'
              onClick={toggle2}>Add new site</button>}
              <Modal isOpen={modal2} toggle={toggle2} backdrop='static' size='xl'>
                <ModalHeader toggle={toggle2} close={closeBtn}>
                  Submit requirements
                </ModalHeader>
                <ModalBody>
                  <Row className='my-2 ps-4'>
                    <Row>
                      <h6>
                        <b>Sitegroup name: </b>
                        {localData.sitegroup_name ? 
                            localData.sitegroup_name : ''}
                          </h6>
                          <Table hover className='site-details-table'>
                            <thead>
                              <tr>
                                <th>SI.No</th>
                                <th>URL</th>
                                {accessEnv.includes(environment) ? <th>Rss urls crawl limit</th> : ""}
                              </tr>
                            </thead>
                            <tbody>
                              {newRows.map((element, index) => {
                                return (
                                  <tr key={element}>
                                    <td>
                                      {index+1}
                                    </td>
                                    <td style={{width: accessEnv.includes(environment) ? "70%" : "100%"}}>
                                      <Input 
                                        type="text" name={`url${index}`} id={`url${index}`} 
                                        onChange={(e) => {handleURL(e.target.value, element)}}
                                        value={urlData? urlData[element]?.url : ''} 
                                        placeholder='http://www.ebay.com/sch/DSLR-Cameras/31388/bn_732/i.html'/>
                                      {errors && errors[element] && <p className='m-0 error-msg mt-1 mb-2'>
                                        {errors[element].url}
                                      </p>}
                                    </td>
                                    {accessEnv.includes(environment) ?
                                    <td>
                                      <Input 
                                        type="text" name={`rss_urls_crawl_limit${index}`} 
                                        id={`rss_urls_crawl_limit${index}`} 
                                        onChange={(e) => {handleRssUrlsCrawlLimit(e.target.value, element)}}
                                        value={urlData? urlData[element]?.rss_urls_crawl_limit : ''} 
                                        placeholder='It will be 30k by default'/> 
                                      {errors && errors[element] && <p className='m-0 error-msg mt-1 mb-2'>
                                        {errors[element].rss_urls_crawl_limit}
                                      </p>}
                                    </td>
                                        : ""
                                    }
                                  </tr>)
                              })}
                            </tbody>
                          </Table>
                          <button className='btn btn-outline-primary my-3'
                            onClick={(e) => {handleRowsUpdate()}}>
                            + Add more fields
                          </button>
                        </Row>
                        <div className='my-4 d-flex justify-content-center fw-bold'>
                          OR
                        </div>
                        <Row>
                          <Col lg={3}>
                            <h6 className='fw-bold d-flex justify-content-end'>
                              Add Sites via a file: </h6>
                          </Col>
                          <Col lg={5}>
                            <Form onSubmit={handleSubmit}>
                              <div className="dz-message needsclick">
                                <Dropzone getUploadParams={getUploadParams} maxFiles={1}
                                  onChangeStatus={handleChangeStatus} multiple={false}
                                  canCancel={true} inputContent="Upload / Drop an Excel File"
                                  styles={{
                                    dropzone: { height: 40 },
                                    dropzoneActive: { borderColor: 'green' },
                                  }}
                                />
                              </div>
                            </Form>
                            <p className='text-muted m-0 mt-2 text-sm'>
                              Support file formats are .xls, .xlsx, .xlsb, .xlsm, .ods, .ots, .uos
                            </p>
                          </Col>
                        </Row>
                        <p className='my-5 d-flex align-center'>
                          <button onClick={handleExcelDownload} 
                            className='btn text-primary'> Click here for a sample Excel file. </button>
                          Please do not change column headers
                        </p> 
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="danger" onClick={() => setModal2(!modal2)}>Discard</Button>
                      <Button color="success" onClick={toggle2}>Add sites</Button>
                    </ModalFooter>
                  </Modal>
              </div>}
            </div>
            <div className='my-5'>
              <Row className='my-2'>
                <Col lg={6}>
                  <div className="d-flex justify-content-between align-items-center">
                    <h6>Delivery Details</h6>
                    <button className='btn btn-success btn-sm' onClick={toggle}>
                      Edit
                    </button>
                    <Modal isOpen={modal} toggle={toggle} size='lg'>
                      <ModalHeader toggle={toggle}>
                        Edit Delivery Details
                      </ModalHeader>
                      <ModalBody>
                        <div className='my-2 mx-3'>
                          <Row className='my-1'> 
                            <Col lg={3}>
                              <h6 className=''>Crawl Type: </h6>
                            </Col>
                            <Col lg={9}>
                              {version == 'v2' && 
                              <div className='d-flex'>
                                <FormGroup check className='mx-2'>
                                  <Label check>
                                    <Input type="radio" name="radio1" checked={localData.delivery_details && localData.delivery_details.crawl_type == 'none'}
                                      onChange={() => handleRadioBtn("none")} />{' '} Not set 
                                  </Label>
                                </FormGroup>
                                <FormGroup check className='mx-2'>
                                  <Label check>
                                    <Input type="radio" name="radio1" checked={localData.delivery_details && localData.delivery_details.crawl_type == 'fullcrawl'}
                                      onChange={() => handleRadioBtn("fullcrawl")} />{' '} Full crawl
                                  </Label>
                                </FormGroup>
                                <FormGroup check className='mx-2'>
                                  <Label check>
                                    <Input type="radio" name="radio1" checked={localData.delivery_details && localData.delivery_details.crawl_type == 'incremental_recrawl'}
                                      onChange={() => handleRadioBtn("incremental_recrawl")} />{' '} Incr Crawl
                                  </Label>
                                </FormGroup>
                            </div>}
                              {version == 'v3' && 
                              <select className='form-control show-arrow' 
                                onChange={(e) => {
                                  setLocalData({
                                    ...localData, delivery_details: {
                                      ...localData.delivery_details && localData.delivery_details,
                                      crawl_type: e.target.value
                                    }
                                  })
                                }}
                                defaultValue={localData.delivery_details && localData.delivery_details.crawl_type || 'none'}>
                                <option value='none'></option>
                                <option value='fullcrawl'>Full crawl</option>
                                <option value='incremental_recrawl'>Incremental Crawl</option>
                            </select>}
                          </Col>
                        </Row>
                        <hr />
                        <Row className='my-1'>
                          <Col lg={3}>
                            <h6 className=''>Data format: </h6>
                          </Col>
                          <Col lg={9}>
                            {version == 'v2' && 
                            <div className='d-flex'>
                              <FormGroup check className='mx-2'>
                                <Label check>
                                  <Input type="radio" name="radio2" checked={localData.delivery_details && localData.delivery_details.delivery_format == 'json'}
                                    onChange={() => handleRadioBtn2("json")} />{' '} JSON
                                </Label>
                              </FormGroup>
                              <FormGroup check className='mx-2'>
                                <Label check>
                                  <Input type="radio" name="radio2" checked={localData.delivery_details && localData.delivery_details.delivery_format == 'csv'}
                                    onChange={() => handleRadioBtn2("csv")} />{' '} CSV
                                </Label>
                              </FormGroup>
                              <FormGroup check className='mx-2'>
                                <Label check>
                                  <Input type="radio" name="radio2" checked={localData.delivery_details && localData.delivery_details.delivery_format == 'xml'}
                                    onChange={() => handleRadioBtn2("xml")} />{' '} XML
                                </Label>
                              </FormGroup>
                              <FormGroup check className='mx-2'>
                                <Label check>
                                  <Input type="radio" name="radio2" checked={localData.delivery_details && localData.delivery_details.delivery_format == 'tsv'}
                                    onChange={() => handleRadioBtn2("tsv")} />{' '} TSV
                                </Label>
                              </FormGroup>
                          </div>}
                            {version == 'v3' && 
                            <select className='form-control show-arrow' 
                              defaultValue={localData.delivery_details 
                              ? localData.delivery_details.delivery_format : 'json' } 
                              onChange={(e) => setLocalData({
                                ...localData, delivery_details: {
                                  ...localData.delivery_details && localData.delivery_details,
                                  delivery_format: e.target.value
                                }
                              })}>
                              <option value='json'>JSON</option>
                              <option value='ld_json'>LD-JSON</option>
                              <option value='xml'>XML</option>
                              <option value='csv'>CSV</option>
                              <option value='tsv'>TSV</option>
                          </select>}
                        </Col>
                      </Row>
                      <hr />
                      <Row className='my-1'>
                        <Col lg={3}>
                          <h6 className=''>Frequency: </h6>
                        </Col>

                        <Col lg={9}>
                          <select className='form-control show-arrow' 
                            onChange={(e) => handleFrequency(e.target.value)}
                            defaultValue={localData.delivery_details ? 
                              localData.delivery_details.frequency : 'daily'}>
                              <option value="daily">Daily</option>
                              <option value="weekly">Weekly</option>
                              <option value="monthly">Monthly</option>
                              <option value="other_frequency">Other</option>
                            </select>
                          </Col>
                        </Row>
                        { localData.delivery_details && localData.delivery_details.frequency == 'weekly' && (
                          <Row className='my-4'>
                            <Col lg={3}>
                              <h6 className=''>Days: </h6>
                            </Col>
                            <Col lg={9}>
                              <div className='d-flex flex-wrap'>
                                <FormGroup check className='mx-3'>
                                  <Label check>
                                    <Input type="radio" name="radio5" checked={localData.delivery_frequency_details &&
                                      localData.delivery_frequency_details.days == 'monday'}
                                      onClick={() => handleFreqWeekly("monday")}
                                      onChange={() => handleFreqWeekly("monday")} />{' '} Monday
                                  </Label>
                                </FormGroup>
                                <FormGroup check className='mx-3'>
                                  <Label check>
                                    <Input type="radio" name="radio5" checked={localData.delivery_frequency_details &&
                                      localData.delivery_frequency_details.days == 'tuesday'}
                                      onClick={() => handleFreqWeekly("tuesday")}
                                      onChange={() => handleFreqWeekly("tuesday")} />{' '} Tuesday
                                  </Label>
                                </FormGroup>
                                <FormGroup check className='mx-3'>
                                  <Label check>
                                    <Input type="radio" name="radio5" checked={localData.delivery_frequency_details &&
                                      localData.delivery_frequency_details.days == 'wednesday'}
                                      onClick={() => handleFreqWeekly("wednesday")}
                                      onChange={() => handleFreqWeekly("wednesday")} />{' '} Wednesday
                                  </Label>
                                </FormGroup>
                                <FormGroup check className='mx-3'>
                                  <Label check>
                                    <Input type="radio" name="radio5" checked={localData.delivery_frequency_details &&
                                      localData.delivery_frequency_details.days == 'thursday'}
                                      onClick={() => handleFreqWeekly("thursday")}
                                      onChange={() => handleFreqWeekly("thursday")} />{' '} Thursday
                                  </Label>
                                </FormGroup>
                                <FormGroup check className='mx-3'>
                                  <Label check>
                                    <Input type="radio" name="radio5" checked={localData.delivery_frequency_details &&
                                      localData.delivery_frequency_details.days == 'friday'}
                                      onClick={() => handleFreqWeekly("friday")}
                                      onChange={() => handleFreqWeekly("friday")} />{' '} Friday
                                  </Label>
                                </FormGroup>
                                <FormGroup check className='mx-3'>
                                  <Label check>
                                    <Input type="radio" name="radio5" checked={localData.delivery_frequency_details &&
                                      localData.delivery_frequency_details.days == 'saturday'}
                                      onClick={() => handleFreqWeekly("saturday")}
                                      onChange={() => handleFreqWeekly("saturday")} />{' '} Saturday
                                  </Label>
                                </FormGroup>
                                <FormGroup check className='mx-3'>
                                  <Label check>
                                    <Input type="radio" name="radio5" checked={localData.delivery_frequency_details &&
                                      localData.delivery_frequency_details.days == 'sunday'}
                                      onClick={() => handleFreqWeekly("sunday")}
                                      onChange={() => handleFreqWeekly("sunday")} />{' '} Sunday
                                  </Label>
                                </FormGroup>
                              </div>
                            </Col>
                          </Row>
                        )}
                        { localData.delivery_details && localData.delivery_details.frequency == 'monthly' && (
                          <Row className='my-4'>
                            <Col lg={3}>
                              <h6 className=''>Day of Month: </h6>
                            </Col>
                            <Col lg={9}>
                              <DatePicker className="form-control digits" 
                                selected={localData.delivery_frequency_details.date 
                              ? new Date(localData.delivery_frequency_details.date) : new Date()}
                              onChange={handleDateChange} />
                          </Col>
                        </Row>
                        )}
                        { localData.delivery_details && localData.delivery_details.frequency == 'other_frequency' && (
                          <Row className='my-4'>
                            <Col lg={3}>
                              <h6 className=''>Details: </h6>
                            </Col>
                            <Col lg={9}>
                              <Input className="form-control" name="details" type="text" 
                                placeholder="" value={localData.delivery_frequency_details.other_details}
                                onChange={(e) => {
                                  setLocalData({
                                    ...localData,
                                    delivery_frequency_details: {other_details: e.target.value}
                                  })
                                }} />
                                <div className="text-muted text-sm">
                                  *Minimum requirements - at least one crawl per month, for a minimum period of 3 months. Please note that we do not take up one-time data acquisition projects.
                                </div>
                              </Col>
                            </Row>
                        )}
                        <hr />
                        <Row className='my-2'>
                          <Col lg={3}>
                            <h6 className=''>Delivery Method: </h6>
                          </Col>
                          <Col lg={9}>
                            {version == 'v2' ? 
                            <select className='form-control show-arrow' 
                              onChange={(e) => handleSelect(e.target.value)}
                              defaultValue={localData.delivery_details && localData.delivery_details.delivery_method || 'api'}>
                              <option value="api">Promptcloud Data API (Free)</option>
                              <option value="s3">S3 (+ $30 per month)</option>
                              <option value="dropbox">Dropbox (+ $30 per month)</option>
                              <option value="box">Box (+ $30 per month)</option>
                              <option value="ftp">FTP (+ $30 per month)</option>
                            </select>
                                : <select className='form-control show-arrow' 
                                  onChange={(e) => handleSelect(e.target.value)}
                                  defaultValue={localData.delivery_details && localData.delivery_details.delivery_method || 'api'}>
                                  <option value="api">Promptcloud Data API (Free)</option>
                                  <option value="s3">Amazon s3</option>
                                  <option value="dropbox">Dropbox</option>
                                  <option value="box">Box</option>
                                  <option value="ftp">FTP</option>
                                  <option value="sftp">sFTP</option>
                                  <option value="microsoft_azure">Microsoft Azure</option>
                                  <option value='google_drive'>Google drive</option>
                              </select>}
                            </Col>
                          </Row>
                          <div className='my-2 float-end'>
                            <a href='https://api.promptcloud.com/v2/data/info?id=demo&type=help' target="_blank" className='fw-bold'>Promptcloud API Help</a>
                          </div>
                          <br />
                          {localData.delivery_details && localData.project_version === 'v2'
                              && localData.delivery_details.delivery_method === 's3' && (
                                <div className='mt-4'>
                                  <hr />
                                  <Row className='my-2'>
                                    <Col lg={3}>
                                      <h6 className=''>Bucket Name: </h6>
                                    </Col>
                                    <Col lg={9}>
                                      <Input type="text" name="bucketname" className='form-control'
                                        value={localData.delivery_method_details.s3_bucket_name || ''}
                                        onChange={(e) => setLocalData({
                                          ...localData, delivery_method_details: {
                                            ...localData.delivery_method_details,
                                            s3_bucket_name: e.target.value
                                          }
                                        })} />
                                    </Col> 
                                  </Row>
                                  <Row className='my-2'>
                                    <Col lg={3}>
                                      <h6 className=''>Bucket ID: </h6>
                                    </Col>
                                    <Col lg={9}>
                                      <Input type="text" name="bucketname" className='form-control'
                                        value={localData.delivery_method_details.s3_id || ''}
                                        onChange={(e) => setLocalData({
                                          ...localData, delivery_method_details: {
                                            ...localData.delivery_method_details,
                                            s3_id: e.target.value
                                          }
                                        })} />
                                    </Col> 
                                  </Row>
                                  <Row className='my-2'>
                                    <Col lg={3}>
                                      <h6 className=''>Bucket Key: </h6>
                                    </Col>
                                    <Col lg={9}>
                                      <Input type="text" name="bucketname" className='form-control'
                                        value={localData.delivery_method_details.s3_key || ''}
                                        onChange={(e) => setLocalData({
                                          ...localData, delivery_method_details: {
                                            ...localData.delivery_method_details,
                                            s3_key: e.target.value
                                          }
                                        })} />
                                    </Col> 
                                  </Row>
                                  <Row className='my-2'>
                                    <Col lg={3}>
                                      <h6 className=''>Region: </h6>
                                    </Col>
                                    <Col lg={9}>
                                      <select className='form-control show-arrow' 
                                        defaultValue={localData.delivery_method_details.s3_location || ''}
                                        onChange={(e) => setLocalData({
                                          ...localData, delivery_method_details: {
                                            ...localData.delivery_method_details,
                                            s3_location: e.target.value
                                          }
                                        })}>
                                        <option value=''>Select one</option>
                                        <option value='us-east-1'>us-east-1</option>
                                        <option value='us-west-1'>us-west-1</option>
                                        <option value='us-west-2'>us-west-2</option>
                                        <option value='ap-south-1'>ap-south-1</option>
                                        <option value='ap-northeast-1'>ap-northeast-1</option>
                                        <option value='ap-northeast-2'>ap-northeast-2</option>
                                        <option value='ap-southeast-1'>ap-southeast-1</option>
                                        <option value='ap-southeast-2'>ap-southeast-2</option>
                                        <option value='eu-central-1'>eu-central-1</option>
                                        <option value='eu-west-1'>eu-west-1</option>
                                        <option value='sa-east-1'>sa-east-1</option>
                                      </select>
                                    </Col> 
                                  </Row>
                                </div> 
                              )}
                              {localData.delivery_details && localData.project_version === 'v2'
                                  && localData.delivery_details.delivery_method === 'dropbox' && (
                                    <div className='mt-4'>
                                      <hr />
                                      <Row className='my-2'>
                                        <Col lg={3}>
                                          <h6 className=''>Access Token: </h6>
                                        </Col>
                                        <Col lg={9}>
                                          <Input type="text" name="dropbox_access_token" className='form-control'
                                            value={localData.delivery_method_details.dropbox_access_token || ''}
                                            onChange={(e) => setLocalData({
                                              ...localData, delivery_method_details: {
                                                dropbox_access_token: e.target.value
                                              }
                                            })} />
                                        </Col> 
                                      </Row>
                                    </div> 
                                  )}
                                  {localData.delivery_details && localData.project_version === 'v2'
                                      && localData.delivery_details.delivery_method === 'box' && (
                                        <div className='mt-4'>
                                          <hr />
                                          <Row className='my-2'>
                                            <Col lg={3}>
                                              <h6 className=''>Box ID: </h6>
                                            </Col>
                                            <Col lg={9}>
                                              <Input type="text" name="box_id" className='form-control'
                                                value={localData.delivery_method_details.box_id || ''}
                                                onChange={(e) => setLocalData({
                                                  ...localData, delivery_method_details: {
                                                    ...localData.delivery_method_details,
                                                    box_id: e.target.value
                                                  }
                                                })} />
                                            </Col> 
                                          </Row>
                                          <Row className='my-2'>
                                            <Col lg={3}>
                                              <h6 className=''>Box Secret: </h6>
                                            </Col>
                                            <Col lg={9}>
                                              <Input type="text" name="box_secret" className='form-control'
                                                value={localData.delivery_method_details.box_secret || ''}
                                                onChange={(e) => setLocalData({
                                                  ...localData, delivery_method_details: {
                                                    ...localData.delivery_method_details,
                                                    box_secret: e.target.value
                                                  }
                                                })} />
                                            </Col> 
                                          </Row>
                                          <Row className='my-2'>
                                            <Col lg={3}>
                                              <h6 className=''>Username: </h6>
                                            </Col>
                                            <Col lg={9}>
                                              <Input type="text" name="box_username" className='form-control'
                                                value={localData.delivery_method_details.box_username || ''}
                                                onChange={(e) => setLocalData({
                                                  ...localData, delivery_method_details: {
                                                    ...localData.delivery_method_details,
                                                    box_username: e.target.value
                                                  }
                                                })} />
                                            </Col> 
                                          </Row>
                                          <Row className='my-2'>
                                            <Col lg={3}>
                                              <h6 className=''>Password: </h6>
                                            </Col>
                                            <Col lg={9}>
                                              <Input type="text" name="box_password" className='form-control'
                                                value={localData.delivery_method_details.box_password || ''}
                                                onChange={(e) => setLocalData({
                                                  ...localData, delivery_method_details: {
                                                    ...localData.delivery_method_details,
                                                    box_password: e.target.value
                                                  }
                                                })} />
                                            </Col> 
                                          </Row>
                                        </div> 
                                      )}
                                      {localData.delivery_details && localData.project_version === 'v2'
                                          && localData.delivery_details.delivery_method === 'ftp' && (
                                            <div className='mt-4'>
                                              <hr />
                                              <Row className='my-2'>
                                                <Col lg={3}>
                                                  <h6 className=''>Username: </h6>
                                                </Col>
                                                <Col lg={9}>
                                                  <Input type="text" name="ftp_username" className='form-control'
                                                    value={localData.delivery_method_details.ftp_username || ''}
                                                    onChange={(e) => setLocalData({
                                                      ...localData, delivery_method_details: {
                                                        ...localData.delivery_method_details,
                                                        ftp_username: e.target.value
                                                      }
                                                    })} />
                                                </Col> 
                                              </Row>
                                              <Row className='my-2'>
                                                <Col lg={3}>
                                                  <h6 className=''>Password: </h6>
                                                </Col>
                                                <Col lg={9}>
                                                  <Input type="text" name="ftp_password" className='form-control'
                                                    value={localData.delivery_method_details.ftp_password || ''}
                                                    onChange={(e) => setLocalData({
                                                      ...localData, delivery_method_details: {
                                                        ...localData.delivery_method_details,
                                                        ftp_password: e.target.value
                                                      }
                                                    })} />
                                                </Col> 
                                              </Row>
                                              <Row className='my-2'>
                                                <Col lg={3}>
                                                  <h6 className=''>Server: </h6>
                                                  <p className='fw-bold'>Add port number</p>
                                                </Col>
                                                <Col lg={9}>
                                                  <Input type="text" name="ftp_server" className='form-control'
                                                    value={localData.delivery_method_details.ftp_server || ''}
                                                    onChange={(e) => setLocalData({
                                                      ...localData, delivery_method_details: {
                                                        ...localData.delivery_method_details,
                                                        ftp_server: e.target.value
                                                      }
                                                    })} />
                                                </Col> 
                                              </Row>
                                            </div> 
                                          )}
                                        </div>
                                      </ModalBody>
                                      <ModalFooter>
                                        <Button color="primary" 
                                          onClick={() => updateDeliveryDetails()}>Done</Button>
                                      </ModalFooter>
                                    </Modal>
                                  </div>
                                  <Table>
                                    <tbody>
                                      <tr>
                                        <td> Crawl Type </td>
                                        <td className='fw-bold'>
                                          {(localData.delivery_details && localData.delivery_details.crawl_type == 'incremental_recrawl') && 'Incremental Crawl'}
                                          {(localData.delivery_details && localData.delivery_details.crawl_type == 'fullcrawl') && 'Full crawl'}
                                          {(localData.delivery_details && localData.delivery_details.crawl_type == 'none') && 'N/A'}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td> Data format </td>
                                        <td className='fw-bold'>
                                          {localData.delivery_details && localData?.delivery_details?.delivery_format?.toUpperCase()}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td> Frequency </td>
                                        <td className='fw-bold'>
                                          {localData.delivery_details && 
                                          (localData.delivery_details.frequency === 'monthly' 
                                            ? `Every month at ${localData.delivery_frequency_details.date && localData.delivery_frequency_details.date.substring(3, 5)}`
                                            : localData.delivery_details.frequency === 'weekly' 
                                            ? `Every week on ${localData.delivery_frequency_details.days && localData.delivery_frequency_details.days}` :
                                            localData.delivery_details.frequency === 'daily' ? 'Daily'
                                            : `Other: ${localData.delivery_frequency_details.other_details && localData.delivery_frequency_details.other_details}` )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td> Data Delivery </td>
                                          <td className='fw-bold'>
                                            {localData.delivery_details && localData?.delivery_details?.delivery_method?.toUpperCase()}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                    <Row className='mt-3'>
                                      <Col lg={4}>
                                        <h6>Feasibility</h6>
                                      </Col>
                                      <Col lg={8}>
                                        <select className='form-control show-arrow' defaultValue={feasibleStatus}
                                          onChange={(e) => setFeasibleStatus(e.target.value)}>
                                          <option value='feasible'>Feasible</option>
                                          <option value='partially_feasible'>Partially Feasible</option>
                                          <option value='not_feasible'>Not Feasible</option>
                                        </select>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg={6}>
                                    <h6>Additional Details</h6>
                                    <Table>
                                      {version == 'v2' &&
                                      <tbody>
                                        {dataDisable ? 
                                        <>
                                          {data?.additional_details.map(each => (
                                            <tr key={each.key}>
                                              <td>{each?.title}</td>
                                              <td>{each?.status}</td>
                                              <td><input className='form-control' value={each?.fee} disabled /></td>
                                            </tr>
                                          ))}
                                        </>
                                            :<>
                                            <tr>
                                              <td>Image Downloads</td>
                                              <td>
                                                {data?.additional_details?.image_downloads != '0' ? 'Enabled' : 'Disabled'}
                                              </td>
                                              <td>
                                                <input className='form-control'
                                                  value={data?.additional_details?.image_downloads != '0' ? additionalDetails?.image_downloads_fee : ''} 
                                                  onChange={(e) => setAdditionalDetails({...additionalDetails, image_downloads_fee: e.target.value})}
                                                  disabled={data?.additional_details?.image_downloads == '0'|| dataDisable} />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Two days of delivery</td>
                                              <td>
                                                {data?.additional_details?.two_days_of_delivery != '0' ? 'Enabled' : 'Disabled'}
                                              </td>
                                              <td>
                                                <input className='form-control'
                                                  value={data?.additional_details?.two_days_of_delivery != '0' ? additionalDetails?.two_days_of_delivery_fee : ''} 
                                                  onChange={(e) => setAdditionalDetails({...additionalDetails, two_days_of_delivery_fee: e.target.value})}
                                                  disabled={data?.additional_details?.two_days_of_delivery == '0'|| dataDisable} />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Hosted Indexing</td>
                                              <td>
                                                {data?.additional_details?.hosted_indexing != '0' ? 'Enabled' : 'Disabled'}
                                              </td>
                                              <td>
                                                <input className='form-control'
                                                  value={data?.additional_details?.hosted_indexing != '0' ? additionalDetails.hosted_indexing_fee : ''} 
                                                  onChange={(e) => setAdditionalDetails({...additionalDetails, hosted_indexing_fee: e.target.value})}
                                                  disabled={data?.additional_details?.hosted_indexing == '0' || dataDisable} />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Merge files</td>
                                              <td>
                                                {data?.additional_details?.merge_files != '0' ? 'Enabled' : 'Disabled'}
                                              </td>
                                              <td>
                                                <input className='form-control'
                                                  value={data?.additional_details?.merge_files != '0' ? additionalDetails.merge_files_fee : ''} 
                                                  onChange={(e) => setAdditionalDetails({...additionalDetails, merge_files_fee: e.target.value})}
                                                  disabled={data?.additional_details?.merge_files == '0' } />
                                              </td>
                                            </tr>
                                        </>}
                                      </tbody>}
                                        {version == 'v3' &&
                                        <tbody> 
                                          {dataDisable ? 
                                          <>
                                            {data?.additional_details.map(each => (
                                              <tr key={each.key}>
                                                <td>{each?.title}</td>
                                                <td>{each?.status}</td>
                                                <td><input className='form-control' value={each?.fee} disabled /></td>
                                              </tr>
                                            ))}
                                          </>
                                              :<>
                                              <tr>
                                                <td>Enable merge</td>
                                                <td>
                                                  {data?.additional_details?.merge_files != '0' ? 'Enabled' : 'Disabled'}
                                                </td>
                                                <td>
                                                  <input className='form-control'
                                                    value={data?.additional_details?.merge_files != '0' ? additionalDetails.merge_files : ''} 
                                                    onChange={(e) => setAdditionalDetails({...additionalDetails, merge_files_fee: e.target.value})}
                                                    disabled={data?.additional_details?.merge_files == '0'} />
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Enable Image / File Downloads</td>
                                                <td> 
                                                  {data?.additional_details?.image_downloads != '0' ? 'Enabled' : 'Disabled'}
                                                </td>
                                                <td>
                                                  <input className='form-control'
                                                    value={data?.additional_details?.image_downloads != '0' ? additionalDetails.image_downloads_fee : ''} 
                                                    onChange={(e) => setAdditionalDetails({...additionalDetails, image_downloads_fee: e.target.value})}
                                                    disabled={data?.additional_details?.image_downloads == '0' } />
                                                </td>
                                              </tr>
                                          </>}
                                        </tbody>}
                                      </Table>
                                    </Col>
                                  </Row>
                                  <div className='mb-3 mt-5'>
                                    <div className='d-flex mb-2'> <h6 className='me-1'> Signup Url: </h6> {data?.sign_up_url? data.sign_up_url : "NA" } </div>
                                    <textarea rows='3' className='form-control' placeholder='Enter the comments to be mailed'
                                      value={comments} onChange={(e) => setComments(e.target.value)} />
                                  </div>
                                  <Row className='my-2'>
                                    <Col lg={4} className='p-2'>
                                      <p className='m-0'> Site Setup Cost </p>
                                      <input className='form-control' value={dataDisable ? data.site_setup_fee : overallCosts.siteSetup} 
                                        onChange={e => {
                                          setOverallCosts({...overallCosts, 
                                            siteSetup: e.target.value})
                                          handleOverall(e, 'siteSetup')
                                        }} disabled={dataDisable} />
                                    </Col>
                                    <Col lg={4} className='p-2'>
                                      <p className='m-0'> Maintenance Cost </p>
                                      <input className='form-control' value={dataDisable ? data.monthly_maintenance_fee : overallCosts.maintenance} 
                                        onChange={e => {
                                          setOverallCosts({...overallCosts, maintenance: e.target.value})
                                          handleOverall(e, 'siteMaintenance')
                                        }} disabled={dataDisable} />
                                    </Col>
                                    <Col lg={4} className='p-2'>
                                      <p className='m-0'> Volume costs </p>
                                      <input className='form-control' value={dataDisable ? data.volume_charges : overallCosts.volume} 
                                        onChange={e => {
                                          setOverallCosts({...overallCosts, volume: e.target.value})
                                          handleOverall(e, 'volumeCharges')    
                                        }} disabled={dataDisable} />
                                    </Col>
                                    <Col lg={4} className='p-2'>
                                      <p className='m-0'> Number of free records </p>
                                      <input className='form-control' value={dataDisable ? data.number_of_free_records :overallCosts.freeRecords} 
                                        onChange={e => {
                                          setOverallCosts({...overallCosts, freeRecords: e.target.value})
                                          handleOverall(e, 'freeRecords') }    
                                          } disabled={dataDisable} />
                                      </Col>
                                      <Col lg={4} className='p-2'>
                                        <p className='m-0'> Any Additional cost </p>
                                        <input className='form-control' value={dataDisable ? data.any_additional_cost :additionalCosts}
                                          onChange={e => setAdditionalCosts(e.target.value)} disabled={dataDisable}  />
                                      </Col>
                                    </Row>
                                    <Row className='mt-4 mb-3'>
                                      <Col lg={3}>
                                        <Input type="checkbox" checked={checks.feasibilityReport}
                                          onChange={(e) => setChecks({...checks, feasibilityReport: !checks.feasibilityReport})} />
                                        {' '} Send Feasibility Report 
                                      </Col>
                                      <Col lg={3}>
                                        <Input type="checkbox" checked={checks.skipAgreements}
                                          onChange={(e) => setChecks({...checks, skipAgreements: !checks.skipAgreements})} />
                                        {' '} Skip Agreements
                                      </Col>
                                      <Col lg={3}>
                                        <Input type="checkbox" checked={checks.skipInvoice}
                                          onChange={(e) => setChecks({...checks, skipInvoice: !checks.skipInvoice})} />
                                        {' '} Skip Invoice
                                      </Col>
                                      <Col lg={3}>
                                        <Input type="checkbox" checked={dataDisable ? data.use_custom_contracts : checks.customContracts}
                                          onChange={(e) => setChecks({...checks, customContracts: !checks.customContracts})} />
                                        {' '} Use custom contracts
                                      </Col>
                                    </Row>
                                    {checks.customContracts && <Row className='my-2'>
                                      <Col lg={2}>
                                        <h6 className='fw-bold my-0'>Upload MSA*</h6>
                                      </Col>    
                                      <Col lg={3}>
                                        File upload
                                      </Col>
                                      <Col lg={2}></Col>
                                      <Col lg={2}>
                                        <h6 className='fw-bold my-0'>Upload SoW*</h6>
                                      </Col> 
                                      <Col lg={3}>
                                        File upload
                                      </Col>
                                  </Row>}
                                </div>
                                <div className='d-flex float-end mt-3'>
                                  <button className='btn btn-success mx-4' onClick={() => generateFeasibilityReport()}>
                                    Generate feasibility report
                                  </button>
                                  <button className='btn btn-danger' onClick={() => discardOrganization()}>
                                    Discard
                                  </button>
                                </div>
                              </div>
    );
  });

export {Feasibility_SiteDetails as default}
