import React, {useState, useEffect, Fragment} from 'react'
import { Card, CardBody, Col, Container, Row, Breadcrumb, BreadcrumbItem, } from 'reactstrap'
import AsyncSelect from 'react-select/async';
import Loader from 'react-loader-spinner';
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { Home } from 'react-feather';
import { Link } from 'react-router-dom'

export default function QAReportPage() {
    const search = useLocation().search
    const pendingQaValue = new URLSearchParams(search).get('filter[pending_qa]')
    const siteValue = new URLSearchParams(search).get('filter[site]')
    const devSiteQaValue = new URLSearchParams(search).get('filter[dev_site_qa_status]')
    const bizSiteQaValue = new URLSearchParams(search).get('filter[biz_site_qa_status]')
    const commitValue = new URLSearchParams(search).get('commit')

    const [data, setData] = useState({})
    const [dropdownOptions, setDropdownOptions] = useState({})
    const [selectedSite, setSelectedSite] = useState({value: '', label: 'All'})
    const [loading, setLoading] = useState(false)
    const [selectedDevQaStatus, setSelectedDevQaStatus] = useState('')
    const [selectedBizQaStatus, setSelectedBizQaStatus] = useState('')
    const [pendingQa, setPendingQa] = useState(null)
    const [tableData, setTableData] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [duplicateData, setDuplicateData] = useState([])

    useEffect(() => {
        fetchSiteNames();
    }, [])
    

    function fetchSiteNames() {
        axios.get('/api/v1/internal/dropdown_list_helper')
          .then(res => {
                let site_names = res.data.site_names, site_names_options = []
                for (var key in site_names) {
                let group = {}, children = []
                group['label'] = <div className="fw-bold col text-black">{key}</div>
                site_names[key].map(item => {
                    children.push({ label: item, value: item })
                })
                group['options'] = children;
                site_names_options.push(group)
                }
                site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                setDropdownOptions(site_names_options)
          })
          .catch(err => {
            console.error(err?.message || 'Oops something went wrong')
          })
    }

    useEffect(() => {
        if (siteValue){
            setSelectedSite({label: siteValue, value: siteValue})
        }
        if (pendingQaValue){
            setPendingQa(pendingQaValue)
        }
        if (devSiteQaValue){
            setSelectedDevQaStatus(devSiteQaValue)
        }
        if (bizSiteQaValue){
            setSelectedBizQaStatus(bizSiteQaValue)
        }
        getUploadData1()
    }, [pendingQaValue, devSiteQaValue, commitValue, siteValue])

    const getUploadData = () => {
        let tempPendingQA = 0
        if (pendingQaValue == 0 || pendingQaValue == 1){
            tempPendingQA = pendingQaValue
        }
        else if (window.location.pathname.includes('/qa_report/pending')){
            tempPendingQA = 1
            setPendingQa(1)
        }
        else{
            tempPendingQA = 0
            setPendingQa(0)
        }
        if (data?.current_user_can_access_biz_qa){
            window.location.href = `/internal/qa_report/search?utf8=✓&filter%5Bpending_qa%5D=${tempPendingQA}&filter%5Bsite%5D=${selectedSite.value || ''}&filter%5Bdev_site_qa_status%5D=${(selectedDevQaStatus != '' ? selectedDevQaStatus : 0)}&filter%5Bbiz_site_qa_status%5D=${(selectedBizQaStatus != '' ? selectedBizQaStatus : 0)}&commit=Get+Data`
        }
        else {
            window.location.href = `/internal/qa_report/search?utf8=✓&filter%5Bpending_qa%5D=${tempPendingQA}&filter%5Bsite%5D=${selectedSite.value || ''}&filter%5Bdev_site_qa_status%5D=${(selectedDevQaStatus != '' ? selectedDevQaStatus : 0)}&commit=Get+Data`
        }
    }

    const getUploadData1 = () => {
        setLoading(true)
        if (window.location.pathname.includes('/qa_report/search')){
            axios.get(`/api/v1/internal/qa_reports/search?filter[qa_pending_records]=${pendingQa ? 'yes' : 'no'}&filter[site_name]=${selectedSite.value ? selectedSite.value : (siteValue || '')}&filter[dev_site_qa_status]=${devSiteQaValue || (selectedDevQaStatus != '' ? selectedDevQaStatus : 0)}&filter[biz_site_qa_status]=${bizSiteQaValue || (selectedBizQaStatus != '' ? selectedBizQaStatus : 0)}`)
            .then(res => {
                setData(res.data)
                setLoading(false)
            }).catch(err => {
                toast.error(err.response?.data?.message)
                setLoading(false)
            })
        }
        else if (window.location.pathname.includes('/qa_report/pending')){
            axios.get(`/api/v1/internal/qa_reports/pending`)
            .then(res => {
                setData(res.data)
                setLoading(false)
            }).catch(err => {
                toast.error(err.response?.data?.message)
                setLoading(false)
            })
        }
        else if (window.location.pathname.includes('/qa')){
            axios.get(`/api/v1/internal/qa_reports`).then(res => {
                setData(res.data)
                setLoading(false)
            }).catch(err => {
                toast.error(err?.response?.data?.message)
            })
        }
        
    }

    const columns = [
        {name: 'Site',selector: 'site_name',center:true,sortable: true,grow: 2, 
            cell: (each) => <a href={`/internal/qa_report/${each.site_name}`} target="_blank">{each.site_name}</a> },
        {name: 'Dev QA Status',selector: 'dev_site_qa_status_in_text',center:true,sortable: true,},
        {name: 'Dev QA Done By',selector: 'assignee1',center:true,sortable: true,},
        {name: 'Dev"s Comment',selector: 'dev_comment',center:true,sortable: true,},
        {name: 'BizQA status',selector: 'biz_site_qa_status_in_text',center:true,sortable: true,},
        {name: 'BizQA Done By',selector: 'assignee2',center:true,sortable: true,},
        {name: 'Biz"s Comment',selector: 'comment',center:true,sortable: true,},
        {name: 'Sample Record',selector: 'sample_data_record',center:true,sortable: true,},
        {name: 'Last Updated at',selector: 'updated_at',center:true,sortable: true,},
    ]

    useEffect(() => {
        let tempTableData = []
        if (window.location.pathname.includes('/qa_report/search')){
            tempTableData = data?.qa_report_list 
        }
        else if (window.location.pathname.includes('/qa_report/pending')){
            tempTableData = data?.pending_qa_report_list 
        }
        else if (window.location.pathname.includes('/qa')){
            tempTableData = data?.qa_report_list
        }
        setDuplicateData(tempTableData)
        setTableData(tempTableData)
    }, [data])
    
    const promiseOptions = (inputValue) => {
        if(inputValue.length < 3){
          return null
        } else {
          return new Promise((resolve) => {
            axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
              if (res.status == '200'){
                    let site_names = res.data.site_names, site_names_options = []
                    for (var key in site_names) {
                    let group = {}, children = []
                    group['label'] = <div className="fw-bold col text-black">{key}</div>
                    site_names[key].map(item => {
                        children.push({ label: item, value: item })
                    })
                    group['options'] = children;
                    site_names_options.push(group)
                    }
                    site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                    setDropdownOptions(site_names_options)
                    resolve(site_names_options)
              }
            }).catch(err => {
              console.log(`err`, err)
            })
          })
        }
    }

    useEffect(() => {
        if (searchValue){
            let res = []
            tableData.forEach(each => {
              if (each?.site_name?.includes(searchValue) || each?.dev_site_qa_status_in_text?.includes(searchValue)
                || each?.assignee1?.includes(searchValue) || each?.assignee2?.includes(searchValue) 
                || each?.dev_comment?.includes(searchValue) || each?.comment?.includes(searchValue) || each?.updated_at?.toString()?.includes(searchValue)
                || each?.sample_data_record?.includes(searchValue) || each?.biz_site_qa_status_in_text?.includes(searchValue)){
                res.push(each)
              }
            })
            setDuplicateData(res)
        } else {
            setDuplicateData(tableData)
        }
    }, [searchValue])
    
    return (
        <Container fluid>
            <Fragment>
                <Container fluid>
                    <div className="page-title">
                        <Row className=''>
                            <Col xs="6">
                                <h3>Internal Dashboard</h3>
                            </Col>
                            <Col xs="6">
                            <Breadcrumb>
                                <BreadcrumbItem>
                                    <Link to={`/internal`}><Home /></Link>
                                </BreadcrumbItem>
                                <BreadcrumbItem>
                                    {(window.location.pathname == '/internal/qa_report' || window.location.pathname == '/internal/qa_report/')
                                    ? <b>All Sites </b>
                                    : <a href={'/internal/qa_report'}>
                                        All Sites
                                    </a>}
                                </BreadcrumbItem>
                                <BreadcrumbItem>
                                {(window.location.pathname == '/internal/qa_report/pending' || window.location.pathname == '/internal/qa_report/pending/')
                                    ? <b> List of Sites Pending for QA </b>
                                    : <a href={'/internal/qa_report/pending'}>
                                        List of Sites Pending for QA
                                    </a>}
                                </BreadcrumbItem>
                                {window.location.pathname.includes('/qa_report/search') && <BreadcrumbItem>
                                    <b>Search Results</b>
                                </BreadcrumbItem>}
                            </Breadcrumb>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </Fragment>

            {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
            : <>
            <Card className='my-3'>
                <CardBody>
                    <Row className='my-3'>
                        <Col md={12}>
                            <h6 className='fw-bold'>Site Name: </h6>
                            <AsyncSelect cacheOptions defaultOptions={dropdownOptions} name='site_name' 
                                value={selectedSite} loadOptions={promiseOptions} onChange={res => setSelectedSite(res)}
                                placeholder="Select a site name" />
                                <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <Col md={4}>
                            <h6 className='fw-bold'>DevQA Status</h6>
                            <select className='form-control' value={selectedDevQaStatus}
                                onChange={(e) => setSelectedDevQaStatus(e.target.value)}>
                                <option value=''></option>
                                <option value='0'>Does not look good!</option>
                                <option value='1'>All Fine</option>
                            </select>
                        </Col>
                        <Col md={4}>
                            <h6 className='fw-bold'>BizQA Status</h6>
                            <select className='form-control' value={selectedBizQaStatus} 
                                disabled={!(data?.current_user_can_access_biz_qa)}
                                onChange={(e) => setSelectedBizQaStatus(e.target.value)}>
                                <option value=''></option>
                                <option value='0'>Does not look good!</option>
                                <option value='1'>All Fine</option>
                            </select>
                        </Col>
                        <Col md={4}>
                            <button className='btn btn-success' style={{width: '100%', marginTop: 27}}
                                onClick={() => getUploadData()}> Get Data </button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Card>
                <CardBody>
                    <Row className='mb-2'>
                        <Col md={7} className='my-3'></Col>
                        <Col md={5} className='my-3'>
                            <div className='d-flex justify-content-center align-items-center'>
                                <h6 className='my-0 me-2'>Search:</h6>
                                <input type='text' className='form-control' value={searchValue} 
                                onChange={(e) => setSearchValue(e.target?.value)} />
                            </div>
                        </Col>
                    </Row>
                    <DataTable noHeader columns={columns} data={duplicateData} pagination responsive />
                </CardBody>
            </Card>
            </>}
        </Container>
    )
}
