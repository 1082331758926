import React from 'react';
import { Row, Col, Card, CardBody, Table } from 'reactstrap';
import AverageTimeTable from './../DataTables/AverageTimeTable.component';

export default function AverageTimeTab({ data, siteValue }) {
  return (
    <div>
      <Row>
        <Col md={6}>
          <Card>
            <CardBody>
              <p className='fw-bold'>Days to finish ETA: {data?.days_to_finish_eta}</p>
              <p>Queue Size / Feed Crawl Avg: {data?.queue_size_and_feed_crawl_avg} </p>
            </CardBody>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <CardBody>
              <p className='fw-bold'>Max pop urls count</p>
              <p>{data?.max_pop_urls_count}</p>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className='my-4'>
        <Card>
          <CardBody>
            <h6 className='fw-bold text-center mt-2 mb-3'>Average time take for every 2000 urls in each stage</h6>
            <AverageTimeTable
              data={data}
              siteValue={siteValue}
            />
          </CardBody>
        </Card>
      </Row>
    </div>
  )
}
