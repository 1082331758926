import React from 'react';
import Loader from 'react-loader-spinner';

export default function ClientPageLoader() {
  return (
    <div>
        <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
    </div>
  )
}
