import React from 'react'
import { useState, useEffect, useContext } from 'react';
import { Card, CardHeader, Container, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../global/Breadcrumb';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import CrawlNotificationPreferenceTable from './CrawlNotificationsPreferenceTable/CrawlNotificationPreferenceTable.component';
import Search from './CrawlNotificationsPreferenceTable/Search/Search.component';

export default function CrawlNotificationPreferences() {
    const { organization_name } = useParams();
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [siteName, setSiteName] = useState('');
    const [pageNumber, setPageNumber] = useState(1);


    const API_ENDPOINT = `/api/v1/organizations/${organization_name}/website_notification_preferences?page=${pageNumber}&filterrific[search_by_site_name]=${siteName}&page_type=client&controller_name=website_notification_preferences`;


    // Use Effect for when the component mounts
    useEffect(async () => {
        setLoading(true);

        const controller = new AbortController();

        await fetchDataFromServer(controller);

        setLoading(false);

        // cleanup 
        return () => {
            setLoading(false);
            controller.abort();
        }
    }, [pageNumber, siteName]);


    // Helper function to fetch data from the server
    async function fetchDataFromServer(signalController) {
        try {
            let response = await axios.get(API_ENDPOINT, { signal: signalController.signal });
            let dataFromServer = await response?.data?.website_notification_preferences;
            setTableData(dataFromServer);
            setTotalRows(dataFromServer[0].total_page_count);
        }
        catch (error) {
            toast.error("Sorry, No Data Found!");
        }
    };


    return (
        <>
            <Container fluid>
                <Breadcrumb parent={"Crawl Notification Preferences"} title={'Site Crawl Notifications'} />
                <Card>
                    <CardHeader>
                        <Search siteNameSetter={setSiteName} loadingAnimation={setLoading} />
                    </CardHeader>
                    <CardBody>
                        <CrawlNotificationPreferenceTable
                            progressPending={loading}
                            data={tableData}
                            paginationTotalRows={totalRows}
                            apiEndpoint={API_ENDPOINT}
                            pageSetter={setPageNumber}
                        />
                    </CardBody>
                </Card>
            </Container>
        </>
    )
};
