import React, {useEffect, useState, useContext} from 'react'
import { useParams } from "react-router-dom";
import Breadcrumb from '../../../global/Breadcrumb';
import axios from 'axios'
import { toast } from 'react-toastify';
import getSymbolFromCurrency from 'currency-symbol-map'
import { Card, CardBody, Container, Table, Row, Col, CardHeader, Button, Alert,
  Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Input, Label } from 'reactstrap';
import Loader from 'react-loader-spinner';
import { OrgnaziationContext } from '../../../Home';
import {useHistory} from 'react-router-dom';

import SitegroupDetailsInSites from './SitegroupDetailsInSites';

export default function AllSites({banner_notifications, current_organization, current_user}) {

  let history = useHistory();
  const [bannerNotifications, setBannerNotifications] = useState([])
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setBannerNotifications(banner_notifications)
  }, [banner_notifications])

  let { organization_name } = useParams();

  const [organizationName, setOrganizationName] = useState(
    organization_name? organization_name : current_organization?.org_identifier
  )

  const {setOrgName} = useContext(OrgnaziationContext)

  useEffect(() => {
    if (!(current_organization?.org_identifier)){
      setOrgName(organizationName)
    }
    if (organization_name == "promptcloud"){
      history.push('/');
      toast.error("You are not authorized this page")
    } 
  }, [organizationName])

  useEffect(() => {
    setLoading(true);
    axios.get(`/api/v1/organizations/${organizationName}/user_can_access_page?page_type=client&controller_name=dashboard`)
      .then(res => {
        setLoading(false)
      })
      .catch(err => {
        history.push('/');
        toast.error(err.response.data.message)
        setLoading(false)
      }) 
  }, [organizationName])

  return (
    <>
      {
        loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
          : 
          <Container fluid>
            <Breadcrumb parent={"Sites"} title={`Site Status`} />
            {bannerNotifications.map(item => {
              if (item?.notify_maintenance){
                return (
                  <Alert color='primary' className='d-flex align-items-center justify-content-between my-2'>
                    <p className='my-0'>{item?.notify_maintenance}</p>
                  </Alert>
                )
              }
              if (item?.gst_update?.message){
                return (
                  <Alert color='primary' className='d-flex align-items-center justify-content-between my-2'>
                    <p className='my-0'>{item?.gst_update?.message}</p>
                    <a href={item?.gst_update?.url} target='_blank' style={{background: 'white', whiteSpace: 'nowrap'}} className='btn btn-xs text-primary'>Update GSTIN</a>
                  </Alert>
                )
              }
              if (item?.pending_feasibility_reports?.length > 0){
                return (item?.pending_feasibility_reports?.map((each, index) => (
                  <Alert color='primary' key={index} className='d-flex align-items-center justify-content-between my-2'>
                    <p className='my-0'>{each?.message}</p>
                    <a href={each?.url} target='_blank' style={{background: 'white', whiteSpace: 'nowrap'}} className='btn btn-xs text-primary'>View and Proceed</a>
                  </Alert>
                )))
              }
            })}
            <div className='d-flex justify-content-between mb-3'>
              <a href={`/organizations/${organizationName}/site/add`} className='btn btn-primary me-4'>
                + Add Site
              </a>
              <a href={`/organizations/${organizationName}/sites/new`} className='btn btn-primary'>
                + Add Site group
              </a>
            </div>
            <SitegroupDetailsInSites orgName={organizationName} />
          </Container>
      }
    </>
  )
}
