/**
 * This card is only displayed to users who have super user privileges
 * This operations are only allowed for the internal teams, developers as well as users who are give authority
 * to manage internal changes.
 * 
 * CAUTION: PLEASE CONTACT DEV TEAMS BEFORE MAKING ANY CHANGES HERE
 */
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useContext, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Card, CardHeader, CardBody, CardFooter, Row, Col } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import { AdminContext } from '../../../Context/Exports/ContextExports';
import SpinnerLoader from '../../../Common/LoadingAnimation/SpinnerLoader.component';
import EditAdminConfigModal from '../Modals/EditAdminConfigModal.component';
import Select from 'react-select';
import axios from 'axios';
import { toast } from 'react-toastify';


export default function AdminOperationsCard() {

  const history = useHistory();
  const { superUser } = useContext(AdminContext); // Ensures that users have super user privileges 
  const { organization_name } = useParams();
  const [loading, setLoading] = useState(false); // used for handling loading spinner animations
  const [adminCardData, setAdminCardData] = useState({}); // Data from backend
  const [displayAdminCard, setDisplayAdminCard] = useState(false); // Used for displaying the admin operations

  // These are states holding information for changond details shown in select options
  const [primaryContact, setPrimaryContact] = useState('');
  const [secondaryContact, setSecondaryContact] = useState('');
  const [businessContact, setBusinessContact] = useState('');
  const [pmContact, setPmContact] = useState('');
  const [qacontact, setQacontact] = useState('');
  const [orgStatus, setOrgStatus] = useState('');
  const [invoicePlatform, setInvoicePlatform] = useState('');
  const [dataAPI, setDataAPI] = useState('');



  // These are the endpoint for CRUD operations
  // This is memoized which means the updates are only made when the organization name changes
  // This avoids un-necessary creation of the objects everytime the page reloads
  const API_ENDPOINTS = useMemo(() => ({
    ORG: `/api/v1/organizations/${organization_name}?page_type=client&controller_name=organizations`,
    CREATE_FRESH: `/api/v1/organizations/${organization_name}/create_freshbooks?page_type=client&controller_name=organizations`,
    CREATE_ZOHO: `/api/v1/organizations/${organization_name}/create_zohobooks?page_type=client&controller_name=organizations`,
    UPDATE_FRESH: `/api/v1/organizations/${organization_name}/update_freshbooks?page_type=client&controller_name=organizations`,
    UPDATE_ZOHO: `/api/v1/organizations/${organization_name}/update_zohobooks?page_type=client&controller_name=organizations`,
    UPDATE_CONFIGURATION: `/api/v1/organizations/${organization_name}/update_admin_configs?page_type=client&controller_name=organizations`
  }), [organization_name]);

  useEffect(async () => {
    const controller = new AbortController();

    // Get data from DB
    await getAdminDataFromDB(controller.signal);

    // Cleanup
    return () => {
      controller.abort();
    }
  }, [organization_name])

  // Helper function to fetch admin data from backend DB
  // This function makes an API call request to the backend DB and checks if the "admin_operations" key is present or not
  // If the field is not present, that means user is not allowed any admin operations and thus the card remains hidden 
  // The state "displayAdminCard" is used to display the admin operations. Furthermore, extra validation is added to ensure
  // that the user must have "admin" privileges to do so.
  async function getAdminDataFromDB(signal) {
    try {
      const response = await axios.get(API_ENDPOINTS.ORG, { signal: signal });
      if (response?.status === 200 && response?.data?.organization?.admin_operations) {
        setDisplayAdminCard(true);
        setLoading(true);
        let responseObject = {
          billing_name: await response?.data?.organization?.billing_name,
          freshbooks_client_id: await response?.data?.organization?.admin_operations?.freshbooks_client_id,
          quickbooks_client_id: await response?.data?.organization?.admin_operations?.quickbooks_client_id,
          redmine_id: await response?.data?.organization?.admin_operations?.redmine_id,
          stripe_customer_id: await response?.data?.organization?.admin_operations?.stripe_customer_id,
          created_date: await response?.data?.organization?.admin_operations?.created_date,
          contacts_for_primary_and_secondary: await response?.data?.organization?.admin_operations?.contacts_for_primary_and_secondary,
          biz_contact_list: await response?.data?.organization?.admin_operations?.biz_contact_list,
          pm_contact_list: await response?.data?.organization?.admin_operations?.pm_contact_list,
          qa_contact_list: await response?.data?.organization?.admin_operations?.qa_contact_list,
          org_statuses: await response?.data?.organization?.admin_operations?.org_statuses,
          access_zohobooks: await response?.data?.organization?.admin_operations?.access_zohobooks,
          invoice_generations: await response?.data?.organization?.admin_operations?.invoice_generations
        }
        if (response?.data?.organization?.admin_operations?.access_zohobooks){
          let tempObject = {
            zohobooks_client_id: await response?.data?.organization?.admin_operations?.zohobooks_client_id,
          }
          responseObject = Object.assign(responseObject, tempObject);
        }
        setAdminCardData(responseObject);


        setBusinessContact({ value: await response?.data?.organization?.admin_operations?.biz_contact, label: await response?.data?.organization?.admin_operations?.biz_contact });
        setPrimaryContact({ value: await response?.data?.organization?.admin_operations?.primary_contact, label: await response?.data?.organization?.admin_operations?.primary_contact });
        setPmContact({ value: await response?.data?.organization?.admin_operations?.pm_contact, label: await response?.data?.organization?.admin_operations?.pm_contact });
        setSecondaryContact({ value: await response?.data?.organization?.admin_operations?.secondary_contact, label: await response?.data?.organization?.admin_operations?.secondary_contact });
        setDataAPI({ value: await response?.data?.organization?.admin_operations?.data_api_status, label: await response?.data?.organization?.admin_operations?.data_api_status == 1 ? 'Enabled' : 'Disabled' });
        setQacontact({ value: await response?.data?.organization?.admin_operations?.qa_contact, label: await response?.data?.organization?.admin_operations?.qa_contact });
        setOrgStatus({ value: await response?.data?.organization?.admin_operations?.org_status, label: await response?.data?.organization?.admin_operations?.org_status });
        setInvoicePlatform({ value: await response?.data?.organization?.admin_operations?.invoice_generation, label: await response?.data?.organization?.admin_operations?.invoice_generation });

        setLoading(false);
      }
      else {
        setDisplayAdminCard(false);
      }
    }
    catch (error) {
      toast.error(error?.message);
      setLoading(false);
    }
  }

  // Helper function to perform CRUD operations.
  // Based on the input, it performs updations and removal
  function onTableHeaderButtonsClickHandler(action) {
    switch (action) {
      case "update_freshbook":
        performPOST(API_ENDPOINTS.UPDATE_FRESH);
        break
      case "create_freshbook":
        performPOST(API_ENDPOINTS.CREATE_FRESH);
        break
      case "update_quickbook":
        performPOST(API_ENDPOINTS.UPDATE_QUICK);
        break
      case "create_quickbook":
        performPOST(API_ENDPOINTS.CREATE_QUICK);
        break
      case "create_zohobook":
        performPOST(API_ENDPOINTS.CREATE_ZOHO);
        break
      case "update_zohobook":
        performPOST(API_ENDPOINTS.UPDATE_ZOHO);
        break
      case "remove_organization":
        removeOrganization();
        break
      default:
        console.log("No action specified!");
    }
  };

  // Helper function to remove organization permanently
  // This will pop-up a window that will remind user that the actions they are about to perform is irreversible
  const removeOrganization = () => {
    confirmAlert({
      title: 'Are you 100% sure?',
      message: 'If an organization is removed all the associated users will be removed and deleted. Clients won"t be able to access the data through api also. So proceed only if you are 100% sure',
      buttons: [
        {
          label: 'Yes, Proceed!',
          onClick: () => deleteOrganization(API_ENDPOINTS.ORG)
        },
        {
          label: 'Cancel',
          onClick: () => console.log('Ok')
        }
      ]
    });
  }

  // Helper function to perform POST request
  async function performPOST(API_URL) {
    setLoading(true);
    try {
      const response = await axios.post(API_URL, {});
      toast.success(response?.data?.message);
      setLoading(false);
    }
    catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  }

  // Helper function to send DELETE request
  async function deleteOrganization(API_URL) {
    setLoading(true);
    try {
      const response = await axios.delete(API_URL);
      toast.success(response?.data?.message);
      history.push('/');
      setLoading(false);
    }
    catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  }

  // Helper function to change and set the options in select tabs shown in admin operations card
  async function onChangeHandler(res, fieldName) {
    const { value } = res;
    try {
      setLoading(true);
      const response = await axios.put(API_ENDPOINTS.ORG, { organization: { [fieldName]: value } });
      toast.success(response?.data?.message);
      setLoading(false);
    }
    catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  }


  // Transform data from DB for to be used in select options
  // This function turns the code is {label:"some_label", value:"some_value"} format
  const transformData = (listData) => {
    let temp = [];
    listData?.forEach(each => {
      temp.push({ value: each, label: each })
    })
    return temp;
  }

  // Helper function to handle id changes in redmine, freshbooks and zohobooks
  // in the admin config change modal
  async function onModalSubmitHandler(configData = {}) {
    setLoading(true);
    try {
      const response = await axios.post(API_ENDPOINTS.UPDATE_CONFIGURATION, { organization: configData });
      toast.success(response?.data?.message);
      setLoading(false);
    }
    catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }

  }

  if (superUser && displayAdminCard) {
    return (
      <>
        <SpinnerLoader loading={loading}>
          <Card>
            <CardHeader className='p-4'>
              <div className='d-flex justify-content-between align-items-center'>
                <h5 className='fw-lighter'>
                  Admin operations for {adminCardData?.billing_name}
                </h5>
                <div className='d-flex'>
                  <EditAdminConfigModal
                    redmineId={adminCardData?.redmine_id}
                    freshbooksId={adminCardData?.freshbooks_client_id}
                    zohobooksId={adminCardData?.zohobooks_client_id}
                    accessZohobooks={adminCardData?.access_zohobooks}
                    submitRequestHandler={onModalSubmitHandler} />

                  {adminCardData?.freshbooks_client_id ?
                    <button className='btn btn-xs btn-primary mx-2' onClick={() => onTableHeaderButtonsClickHandler("update_freshbook")}
                      title='Creates Freshbooks entry with all the details like Address, All Admins and Biz Contact as contacts in Freshbooks'>Update Freshbooks Client</button>
                    : <button className='btn btn-xs btn-primary mx-2' onClick={() => onTableHeaderButtonsClickHandler("create_freshbook")}
                      title='Creates Freshbooks entry with all the details like Address, All Admins and Biz Contact as contacts in Freshbooks'>Create Freshbooks Client</button>}

                    {adminCardData?.access_zohobooks ? 
                      <> 
                        {adminCardData?.zohobooks_client_id ?
                        <button className='btn btn-xs btn-primary mx-2' onClick={() => onTableHeaderButtonsClickHandler("update_zohobook")}
                          title='Updates Zohobooks entry with all details like address, v2_admins & v2_accountants email addresses as customers email address in Zohobooks'>Update Zohobooks Client</button>
                        : <button className='btn btn-xs btn-primary mx-2' onClick={() => onTableHeaderButtonsClickHandler("create_zohobook")}
                          title='Creates Zohobooks entry with all details like address, v2_admins & v2_accountants email addresses as customers email address in Zohobooks'>Create Zohobooks Client</button>}
                    </> : ""}

                  <button className='btn btn-xs btn-danger mx-2' onClick={() => onTableHeaderButtonsClickHandler("remove_organization")}
                    title='Be 100% sure before deleting. It will remove all the users form organization and deletes them from app. Also clients won"t be able to access data while api.'>Remove Organization</button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg={3} className='d-flex mb-1 px-0'>
                  <h6 className='my-0 fw-bold me-2'>Redmine Project ID: </h6>
                  {adminCardData?.redmine_id ? <a href={`https://redmine.promptcloud.com/projects/${adminCardData?.redmine_id}`}>{adminCardData?.redmine_id}</a> : <p> NA </p>}
                </Col>
                <Col lg={3} className='d-flex mb-1 px-0'>
                  <h6 className='my-0 fw-bold me-2'>Freshbooks Client ID: </h6>
                  {adminCardData?.freshbooks_client_id ? <a href={`https://promptcloud.freshbooks.com/showUser?userid=${adminCardData?.freshbooks_client_id}`}>
                    {adminCardData?.freshbooks_client_id}
                  </a> : <p> NA </p>}
                </Col>
                <Col lg={3} className='d-flex mb-1 px-0'>
                  <h6 className='my-0 fw-bold me-2'>Quickbooks Client ID: </h6>
                  {adminCardData?.quickbooks_client_id ? <a href={`https://sg.qbo.intuit.com/app/customerdetail?nameId=${adminCardData?.quickbooks_client_id}`}>
                    {adminCardData?.quickbooks_client_id}
                  </a> : <p> NA </p>}
                </Col>
                {adminCardData?.access_zohobooks ? 
                  <Col lg={3} className='d-flex mb-1 px-0'>
                    <h6 className='my-0 fw-bold me-2'>Zohobooks Client ID: </h6>
                    {adminCardData?.zohobooks_client_id ? <a href={`#`}>{adminCardData?.zohobooks_client_id}</a> : <p> NA </p>}
                  </Col>
                : "" }
                <Col lg={3} className='d-flex mb-1 px-0'>
                  <h6 className='my-0 fw-bold me-2'>Stripe Customer ID: </h6>
                  {adminCardData?.stripe_customer_id ? <a href={`https://dashboard.stripe.com/customers/${adminCardData?.stripe_customer_id}`}>
                    {adminCardData?.stripe_customer_id}</a> : <p> NA </p>}
                </Col>
                <Col lg={3} className='d-flex mb-1 px-0'>
                  <h6 className='my-0 fw-bold me-2'>Creation Date: </h6>
                  <h6 className='my-0'>{adminCardData?.created_date}</h6>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col lg={3} className='mb-4'>
                  <h6 className='fw-bold'>
                    Primary Contact:
                  </h6>
                  <Select
                    className='show-arrow form-control primary_contact'
                    isSearchable
                    value={primaryContact}
                    onChange={(res) => { setPrimaryContact(res), onChangeHandler(res, "primary_contact") }}
                    options={transformData(adminCardData?.contacts_for_primary_and_secondary)} />
                </Col>
                <Col lg={3} className='mb-4'>
                  <h6 className='fw-bold'>
                    Secondary Contact:
                  </h6>
                  <Select
                    className='show-arrow form-control secondary_contact'
                    isSearchable
                    value={secondaryContact}
                    onChange={(res) => { setSecondaryContact(res), onChangeHandler(res, "secondary_contact") }}
                    options={transformData(adminCardData?.contacts_for_primary_and_secondary)} />
                </Col>
                <Col lg={3} className='mb-4'>
                  <h6 className='fw-bold'>
                    Business Contact:
                  </h6>
                  <Select
                    className='show-arrow form-control business_contact'
                    isSearchable
                    value={businessContact}
                    onChange={(res) => { setBusinessContact(res), onChangeHandler(res, "biz_contact") }}
                    options={transformData(adminCardData?.biz_contact_list)} />
                </Col>
                <Col lg={3} className='mb-4'>
                  <h6 className='fw-bold'>
                    PM Contact:
                  </h6>
                  <Select
                    className='show-arrow form-control pm_contact'
                    isSearchable
                    value={pmContact}
                    onChange={(res) => { setPmContact(res), onChangeHandler(res, "pm_contact") }}
                    options={transformData(adminCardData?.pm_contact_list)} />
                </Col>
                <Col lg={3} className='mb-4'>
                  <h6 className='fw-bold'>
                    QA Contact:
                  </h6>
                  <Select
                    className='show-arrow form-control qa_contact'
                    isSearchable
                    value={qacontact}
                    onChange={(res) => { setQacontact(res), onChangeHandler(res, "qa_contact") }}
                    options={transformData(adminCardData?.qa_contact_list)} />
                </Col>
                <Col lg={3} className='mb-4'>
                  <h6 className='fw-bold'>
                    Organization status:
                  </h6>
                  <Select
                    className='show-arrow form-control org_status'
                    isSearchable
                    value={orgStatus}
                    onChange={(res) => { setOrgStatus(res), onChangeHandler(res, "organization_status") }}
                    options={transformData(adminCardData?.org_statuses)} />
                </Col>
                <Col lg={3} className='mb-4'>
                  <h6 className='fw-bold'>
                    Invoice Generation Platform:
                  </h6>
                  <Select
                    className='show-arrow form-control invoice_platform'
                    isSearchable
                    value={invoicePlatform}
                    onChange={(res) => { setInvoicePlatform(res), onChangeHandler(res, "invoice_generation") }}
                    options={transformData(adminCardData?.invoice_generations)} />
                </Col>
                <Col lg={3} className='mb-4'>
                  <h6 className='fw-bold'>
                    Data API status:
                  </h6>
                  <Select
                    className='show-arrow form-control data_api_status'
                    isSearchable
                    value={dataAPI}
                    onChange={(res) => { setDataAPI(res), onChangeHandler(res, "client_status") }}
                    options={[{ value: 0, label: 'Disabled' }, { value: 1, label: 'Enabled' }]} />
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </SpinnerLoader>
      </>
    )
  }
  else {
    return (
      <></>
    )
  }
}
