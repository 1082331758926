import React from 'react';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Row, Col, Form, Input } from 'reactstrap';
import { useHistory } from "react-router-dom";
import Dropzone from 'react-dropzone-uploader';
import { toast } from 'react-toastify';
import axios from 'axios';
const AdditionalDetails = forwardRef(({ schemaDetails, siteDetails,
  nextStep, organization_name }, ref) => {
  const [attachmentIds, setAttachmentIds] = useState([]);
  const [description, setDescription] = useState("");
  const [enMerge, setEnMerge] = useState(false);
  const [enDownloads, setEnDownloads] = useState(false);
  let history = useHistory();

  const uploadFile = (file) => {
    let fd = new FormData();
    fd.append('file', file)
    fd.append('page_type', "client")
    fd.append('controller_name', "new_attachments")
    axios.post(`/api/v1/new_attachments`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      let tempIds = [res.data.attachment.id]
      setAttachmentIds(tempIds)
      toast.success(res.data.message)
    }).catch(err => {
      toast.error(err.response.data.message)
    })
  }

  useImperativeHandle(ref, () => ({
    isValidated() {
      return nextStep
    }
  }));

  function getFormattedDate(date) {
    date = new Date(date)
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  }

  const handleDataSubmit = () => {
    let freq = schemaDetails.freqSelection
    let freqObj = {}
    if (freq == 'weekly') {
      freqObj = { "days": schemaDetails.freqWeekly }
    }
    else if (freq == 'other') {
      freqObj = { "other_details": schemaDetails.freqOther }
    }
    else if (freq == 'monthly') {
      freqObj = { "date": getFormattedDate(schemaDetails.freqMonthly) }
    }

    let fields = []
    schemaDetails.schemaFields.forEach((each, index) => {
      if (Object.keys(each)[0] == 'row01') {
        fields.push({
          ...schemaDetails.schemaFields[index].row01,
          "field_name": "uniq_id", "data_type": "string",
          "is_mandatory": "yes", "default_value": "system_generated",
          "sample_value": "default"
        })
      }
      else if (Object.keys(each)[0] == 'row02') {
        fields.push({
          "field_name": "crawl_timestamp", "data_type": "date",
          "is_mandatory": "yes", "default_value": "system_generated",
          "sample_value": "default"
        })
      }
      else if (Object.keys(each)[0] == 'row03') {
        fields.push({
          "field_name": "pageurl", "data_type": "string",
          "is_mandatory": "yes", "default_value": "system_generated",
          "sample_value": schemaDetails.schemaFields[index].row03.sampleValue,
          "comments": schemaDetails.schemaFields[index].row03.comments
        })
      }
      else {
        let keyy = Object.keys(each)[0]
        if (schemaDetails.schemaFields[index][keyy] != null) {
          fields.push({
            "field_name": schemaDetails.schemaFields[index][keyy].fieldName,
            "data_type": schemaDetails.schemaFields[index][keyy].datatype,
            "is_mandatory": schemaDetails.schemaFields[index][keyy].isMandatory,
            "default_value": schemaDetails.schemaFields[index][keyy].defaultValue,
            "sample_value": schemaDetails.schemaFields[index][keyy].sampleValue,
            "comments": schemaDetails.schemaFields[index][keyy].comments
          })
        }
      }
    })

    let inputData = {
      "submit_requirements": {
        "sitegroup_name": schemaDetails.sgName,
        "frequency": schemaDetails.freqSelection, "data_delivery": schemaDetails.dataDelivery,
        "data_format": schemaDetails.dataformat, "crawl_type": schemaDetails.crawlType,
        "additional_information": description, "attachment_ids": attachmentIds,
        "schema_fields_details": fields, "rss_urls_crawl_limit": schemaDetails.rssUrlsCrawlLimit,
        "additional_details": { "merge_files": enMerge ? 1 : 0, "image_downloads": enDownloads ? 1 : 0 },
        "delivery_frequency_details": freqObj,
        "site_details": siteDetails
      },
      page_type: "client",
      controller_name: "sitegroups"
    }
    axios.post(`/api/v1/organizations/${organization_name}/sitegroups`, inputData)
      .then(res => {
        const data = res.data
        if (data.status == 'success') {
          toast.success(data.message)
          history.push(`/organizations/${organization_name}/sites`)
        }
        else {
          toast.error(data.message)
        }
      }).catch(err => {
        toast.error(err.response.data.message)
      })
  }

  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

  const handleChangeStatus = ({ meta, file }, status) => {
    if (status == 'done') {
      uploadFile(file)
    }
    if (status == 'removed') {
      setAttachmentIds([])
    }
  }

  const handleSubmit = (files, allFiles) => {
    allFiles.forEach(f => f.remove())
    toast.success("Dropzone successfully submitted !");
  }

  return (
    <>
      <Row className='my-5 py-3'>
        <Row className='my-3'>
          <Col lg={4}>
            <h6 className='fw-bold d-flex justify-content-end'>
              Any further description
            </h6>
          </Col>
          <Col lg={6}>
            <Input type="textarea" rows='6' name="any_further_description"
              onChange={(e) => setDescription(e.target.value)}
              id="description" />
          </Col>
        </Row>
        <Row className='my-3'>
          <Col lg={4}>
            <h6 className='fw-bold d-flex justify-content-end'>
              Additional Requirements
            </h6>
          </Col>
          <Col lg={6}>
            <div className='mb-3'>
              <Input type="checkbox" checked={enMerge} name="enable_merge"
                onChange={(e) => setEnMerge(e.target.checked)} />{' '} Enable Merge
            </div>
            <div className='mb-3'>
              <Input type="checkbox" checked={enDownloads} name="enable_image_and_file_downloads"
                onChange={(e) => setEnDownloads(e.target.checked)} />{' '} Enable Image / File Downloads
            </div>
          </Col>
        </Row>
        <Row className='my-3'>
          <Col lg={4}>
            <h6 className='fw-bold d-flex justify-content-end'>
              Attach File
            </h6>
          </Col>
          <Col lg={6}>
            <Form onSubmit={handleSubmit}>
              <div className="dz-message needsclick">
                <Dropzone getUploadParams={getUploadParams} maxFiles={1}
                  onChangeStatus={handleChangeStatus} multiple={false}
                  canCancel={true} inputContent="Browse / Drop a File"
                  styles={{
                    dropzone: { height: 70 },
                    dropzoneActive: { borderColor: 'green' },
                  }} maxSizeBytes={14680064}
                />
              </div>
            </Form>
          </Col>
        </Row>
      </Row>
      <button className='btn btn-success mb-2' style={{ float: 'right', height: 39 }}
        onClick={() => handleDataSubmit()} disabled={!nextStep}>
        Send for final feasibility check
      </button>
    </>
  )

})

export default AdditionalDetails;
