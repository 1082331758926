import React, { useEffect, useMemo, useState } from 'react'
/**
 * This table shows data for extraction fetched from extraction table and is rendered in SegmentsTakeMoreTimeByEDNUStagewise.index component
 */

import { Row } from 'reactstrap';
import moment from 'moment';
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify';
import axios from 'axios';

import SpinnerLoader from '../../../Common/LoadingAnimation/SpinnerLoader.component';

export default function ExtractionTable(props) {

  const { crawlDate } = props

  const EXTRACTION_SEGMENTS_DATA_API_ENDPOINT = useMemo(() => (
    `/api/v2/internal/overall_system_reports/segments_by_extraction?filter[crawl_date]=${crawlDate ? crawlDate : new Date()}`
  ), [crawlDate])

  const [extractionData, setExtractionData] = useState([]) // Data for segments by extraction

  const [searchString, setSearchString] = useState('') // Data entered by user in the search field

  const [filteredData, setFilteredData] = useState('') // The result of data search filter

  const [loading, setLoading] = useState(false); // control variable for loading animation while data loads

  // This useEffect hooks fetched data initially from the backendDB
  // Initially, it fetched data based of of current date
  useEffect(async () => {
    const controller = new AbortController()
    await getExtractionSegmentsDataFromES(controller.signal, EXTRACTION_SEGMENTS_DATA_API_ENDPOINT)

    return () => {
      controller.abort()
    }
  }, [EXTRACTION_SEGMENTS_DATA_API_ENDPOINT])

  // This useEffect hook updates the state by populating the filtered
  // data state by searching for records in the result and the displays
  // in the data table 
  useEffect(() => {
    let filteredData = extractionData.filter((segment) => {
      return segment.site.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
        segment.hostname.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
        segment.extraction_start_status.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
        segment.extraction_end_status.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
    })
    setFilteredData(filteredData)
  }, [extractionData, searchString])

  // Helper method to fetch the segments from extraction data
  async function getExtractionSegmentsDataFromES(signal, url) {
    try {
      setLoading(true)
      const response = await axios.get(url, { signal: signal })
      if(response?.status === 204){
        toast.error("Sorry no data available for this date. Please select other date")
        setFilteredData([])
        setLoading(false);
        return
      }
      else{
        const data = await response?.data?.data
        setExtractionData(data)
        setLoading(false)
      }
    }
    catch (error) {
      toast.error(error?.message)
      setLoading(false)
    }
  }

  // Helper method to set search string
  function onSearchHandler(event) {
    const { value } = event.target
    setSearchString(value)
  }

  // Helper function for getting difference in time
  function getDifferenceInHours(start_date, end_date) {
    let a = moment(end_date);
    let b = moment(start_date);
    let hours = a.diff(b, 'hours');
    let minutes = moment.utc(a.diff(b)).format("mm");
    let seconds = moment.utc(a.diff(b)).format("ss");
    return `${Math.floor(hours)}h ${Math.floor(minutes)}m ${seconds}s`
  };

  const extractionTableColumns = useMemo(() => (
    [
      {
        name: 'Site',
        selector: 'site',
        center: true,
        sortable: true,
        cell: row => <div data-bs-toggle="tooltip" data-bs-placement="bottom" title={row.segment_path}>{row.site}</div>
      },
      {
        name: 'Host Name',
        selector: 'hostname',
        center: true,
        sortable: true
      },
      {
        name: 'Crawl Date',
        selector: 'crawldate',
        center: true,
        sortable: true
      },
      
      {
        name: 'Extraction Start Status',
        selector: row=>(row.extraction_start_status === 'N/A'?'N/A':row.extraction_start_status),
        sortable: true
      },
      {
        name: 'Extraction End Status',
        selector: row=>(row.extraction_end_status === 'N/A'?'N/A':row.extraction_end_status),
        sortable: true
      },
      {
        name: 'Extraction Start Time',
        selector: 'extraction_start_time',
        center: true, sortable: true,
        cell: row => <div>{row.extraction_start_time === 'N/A'?'N/A':row.extraction_start_time + " UTC"}</div>
      },
      {
        name: 'Extraction End Time',
        selector: 'extraction_end_time',
        center: true,
        sortable: true,
        cell: row => <div>{row.extraction_end_time === 'N/A'?'N/A':row.extraction_end_time + " UTC"} </div>
      },
      {
        name: 'Extraction Duration',
        selector: row=>(row.extraction_duration === 'N/A'?'N/A':row.extraction_duration),
        center: true,
        sortable: true
      },
      {
        name: 'Takes More Time',
        center: true, sortable: true,
        cell: row => <div>{row.extraction_start_time === 'N/A'?'N/A':getDifferenceInHours(row.extraction_start_time, row.extraction_end_time)}</div>
      },
    ]
  ), []);

  return (
    <React.Fragment>
      <SpinnerLoader loading={loading}>
        <center className="fw-bold mt-4">Extraction</center>
        <Row className="align-items-center float-end my-3">
          <div className="col-sm-3">Search</div>
          <div className="col">
            <input className="form-control" type={'text'} onChange={(event) => onSearchHandler(event)} />
          </div>
        </Row>
        <DataTable
          data={filteredData}
          columns={extractionTableColumns}
          pagination
          responsive
          noHeader />
      </SpinnerLoader>
    </React.Fragment>
  )
}
