import React, { useState } from "react";
import { Container } from "reactstrap";
import moment from "moment";
import axios from "axios";

import BreadCrumbComponent from "./BreadCrumb/BreadCrumbComponent";
import SiteSearchCard from "./Cards/SiteSearchCard.component";
import DataTableCard from "./Cards/DataTableCard.component";

export default function InternalRSSProcess() {

  const [loading, setLoading] = useState(false);
  const [rssProcessesData, setRssProcessesData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  // Helper method to fetch table data based on input parameters
  function fetchDataWithParams(site_name, rss_crawlstatus, from_date, to_date) {
    setLoading(true);
    axios.get(`/api/v2/internal/rss_processes?filter[site_name]=${site_name}&filter[rss_crawlstatus]=${rss_crawlstatus}&filter[from_date]=${moment(from_date).format('YYYY/MM/DD')}&filter[to_date]=${moment(to_date).format('YYYY/MM/DD')}`)
      .then(res => {
        setRssProcessesData(res.data.rss_processes_data);
        setSearchResults(res.data.rss_processes_data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  // Helper method to fetch table data
  function fetchData() {
    setLoading(true)
    axios.get(`/api/v2/internal/rss_processes/new`)
      .then(res => {
        setRssProcessesData(res.data.rss_processes_data);
        setSearchResults(res.data.rss_processes_data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  // Helper function to search record in the data-table component
  const handleSearch = event => {
    if (event.target.value === '') {
      setSearchResults(rssProcessesData);
    }
    else {
      setSearchResults(searchFromData(event.target.value));
    }
    setSearchText(event.target.value);
  }

  function searchFromData(value) {
    var search_result = rssProcessesData.filter(item => item.site_name.includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
    var search_result = rssProcessesData.filter(item => item.hostname.includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
    var search_result = rssProcessesData.filter(item => item.rss_crawlstatus.includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
    var search_result = rssProcessesData.filter(item => item.crawldate.toString().includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
  }



  return (
    <Container fluid>
      <BreadCrumbComponent />

      <SiteSearchCard
        fetchRSSTableData={fetchData}
        fetchRSSTableDataWithParams={fetchDataWithParams}
      />
      <DataTableCard
        data={searchResults}
        progressPending={loading}
        onSeachHandler = {handleSearch}
      />
    </Container>)
}
