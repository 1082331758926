import React from 'react';
import { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import FeedCRawlCountTable from '../DataTables/FeedCRawlCountTable.component';
export default function Top20FeedCrawlCount() {
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [top20SitesFeedcrawlCountData, setTop20SitesFeedcrawlCountData] = useState([]);
  const [loadingBtns, setLoadingBtns] = useState(false)

  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  // Helper functions

  // Function to handle modal opening and closing
  const toggleFunc = (e) => {
    e.preventDefault();
    GetTop20SitesFeedcrawlCount();
    setModal(curr => !curr)
  }

  // Helper function for close button click
  const closeBtn = <button className="close"
    onClick={() => setModal(false)}> &times; </button>;

  // Helper function to fetch top 20 sites from DB
  const GetTop20SitesFeedcrawlCount = () => {
    setLoading(true)
    axios.get(`/api/v2/internal/high_volume_clients_and_sites/top_20_sites_feedcrawl_count_data`)
      .then(res => {
        setTop20SitesFeedcrawlCountData(res.data?.sites)
        setSearchResults(res.data?.sites);
        setLoading(false)
      }).catch(err => {
        console.error(err?.message || 'Oops something went wrong');
        // toast.error(err?.response.data.message || 'Oops something went wrong');
        setLoading(false)
      })
  };

  // Helper function to handle search
  
  const handleSearch = event => {
    if (event.target.value == '') {
      setSearchResults(top20SitesFeedcrawlCountData);
    }
    else {
      setSearchResults(searchFromData(event.target.value));
    }
    setSearchText(event.target.value);
  };

  function searchFromData(value) {
    var search_result = top20SitesFeedcrawlCountData.filter(item => item.site_name.includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
    var search_result = top20SitesFeedcrawlCountData.filter(item => item.records_count.toString().includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
  };


  return (
    <>
      <Modal isOpen={modal} toggle={toggleFunc} backdrop='static' size='xl'>
        <ModalHeader toggle={toggleFunc} close={closeBtn}>
          <center>
            FeedCrawl (Yesterday data) 
          </center>
        </ModalHeader>
        <ModalBody className='px-5'>
          {
            loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> 
            :
            top20SitesFeedcrawlCountData.length > 0 ? 
            <>
              <Row className='my-2'>
                <Col lg={12}>
                  <div className="float-end mb-3">
                    <Row className="align-items-center">
                      <Col sm="3">Search</Col>
                      <Col>
                        <input type="text" className="form-control" value={searchText} onChange={handleSearch} />
                      </Col>
                    </Row>
                  </div>
                  <FeedCRawlCountTable
                    data={searchResults}
                    progressPending={loading}
                  />
                </Col>
              </Row>
            </>
            : ""
          }
        </ModalBody>
        <ModalFooter>
          <Button color='light' onClick={() => setModal(false)}> Close </Button>
        </ModalFooter>
      </Modal>
      <button className="btn btn-primary btn-sm me-1 px-xl-3" onClick={toggleFunc}>Feedcrawl</button>
    </>
  )
}
