import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Alert } from 'reactstrap'
import axios from 'axios'
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { Info } from 'react-feather';
import ReactTooltip from 'react-tooltip';

export default function ManuallyPushToQueue() {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [dropdownOptions, setDropdownOptions] = useState({})
    const [selectedSite, setSelectedSite] = useState({})
    const [selectedOption, setSelectedOption] = useState('')
    const [rssCrawlId, setRssCrawlId] = useState('')
    const [queueName, setQueueName] = useState('')
    const [selectedFile, setSelectedFile] = useState(null)
    const [findBlockingSol, setFindBlockingSol] = useState(false)

    useEffect(() => {
        fetchSiteNames();
    }, [])

    function fetchSiteNames() {
        axios.get('/api/v1/internal/dropdown_list_helper')
            .then(res => {
                let site_names = res.data.site_names, site_names_options = []
                for (var key in site_names) {
                    let group = {}, children = []
                    group['label'] = <div className="fw-bold col text-black">{key}</div>
                    site_names[key].map(item => {
                        children.push({ label: item, value: item })
                    })
                    group['options'] = children;
                    site_names_options.push(group)
                }
                site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                setDropdownOptions(site_names_options)
            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong')
            })
    }

    const promiseOptions = (inputValue) => {
        if (inputValue.length < 3) {
            return null
        } else {
            return new Promise((resolve) => {
                axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
                    if (res.status == '200') {
                        let site_names = res.data.site_names, site_names_options = []
                        for (var key in site_names) {
                            let group = {}, children = []
                            group['label'] = <div className="fw-bold col text-black">{key}</div>
                            site_names[key].map(item => {
                                children.push({ label: item, value: item })
                            })
                            group['options'] = children;
                            site_names_options.push(group)
                        }
                        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                        setDropdownOptions(site_names_options)
                        resolve(site_names_options)
                    }
                }).catch(err => {
                    console.log(`err`, err)
                })
            })
        }
    }

    const handleSubmit = () => {
        setLoading(true)
        setErrors({})
        let tempObj = {}
        if (!(selectedSite?.value)) {
            tempObj = { ...tempObj, site: true }
        }
        if (!selectedFile) {
            tempObj = { ...tempObj, file: true }
        }
        if (selectedOption?.length < 1) {
            tempObj = { ...tempObj, option: true }
        }
        if (selectedOption == 'queue_custom' && queueName.length < 1) {
            tempObj = { ...tempObj, queue_name: true }
        }
        if (selectedOption != 'queue_custom') {
            if (rssCrawlId?.length > 0 && isNaN(rssCrawlId)){
                tempObj = {...tempObj, rss_crawl: 'Please enter a valid number.'}
            }
            else if ((rssCrawlId?.length > 0 && rssCrawlId?.length < 14) ) {
                tempObj = { ...tempObj, rss_crawl: 'Rss crawl push id should be 14 characters' }
            }
        }
        setErrors(tempObj)

        if (Object.keys(tempObj)?.length == 0) {
            const formData = new FormData();
            formData.append(
                "file", selectedFile
            );

            axios.post(`/api/v1/internal/command_dashboard/manually_push_to_queue_file_upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                let resultObj = {
                    "outer_command": "normally_push_to_queue",
                    "inner_command": "normally_push_to_queue",
                    "nested_inner_command": "normally_push_to_queue",
                    "file_location": res?.data?.file_location,
                    "sitename": selectedSite?.value, "input_type": selectedOption,
                    "run_status": findBlockingSol ? "true" : "false",
                    "rss_crawl_push_id": selectedOption == 'queue_custom' ? '' : rssCrawlId,
                    "custom_queue_name": selectedOption == 'queue_custom' ? queueName : ''
                }
                axios.post('/api/v1/internal/command_dashboard/manually_push_to_queue', {
                    filter: resultObj
                }).then(res => {
                    toast.success(res?.data?.message)
                    setLoading(false)
                    window.location = `/internal/command_dashboard`
                }).catch(err => {
                    toast.error(err?.response?.data?.message)
                    setLoading(false)
                })
            }).catch(err => {
                console.log('err', err)
            })

        }
        else {
            setLoading(false)
        }
    }

    return (
        <Container fluid>
            <Row>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Outer Command (Non-Editable)</h6>
                    <input className='form-control' value='normally_push_to_queue'
                        disabled />
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Inner Command (Non-Editable)</h6>
                    <input className='form-control' value='normally_push_to_queue'
                        disabled />
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Nested Inner Command (Non-Editable)</h6>
                    <input className='form-control' value='normally_push_to_queue'
                        disabled />
                </Col>

                <Col md={12} className='my-3'>
                    <h6 className='fw-bold'>Attach a File</h6>
                    <input type="file" onChange={(e) => setSelectedFile(e.target.files[0])} className='form-control' />
                    {errors?.file && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                </Col>

                <Col md={12} className='my-3'>
                    <h6 className='fw-bold'>Sitename</h6>
                    <AsyncSelect cacheOptions defaultOptions={dropdownOptions} name='site_name'
                        value={selectedSite} loadOptions={promiseOptions} onChange={res => setSelectedSite(res)}
                        placeholder="Select a site name" />
                    {errors?.site && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                    <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
                </Col>

                <Col md={6} className='my-3'>
                    <h6 className='fw-bold'>
                        Select site for queue name or add custom queue
                    </h6>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="rssqueue"
                            checked={selectedOption == 'default_rss_queue'}
                            onChange={() => setSelectedOption('default_rss_queue')} id="rssqueue1" />
                        <label className="form-check-label" htmlFor="rssqueue1">
                            Default rss queue
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="rssqueue"
                            checked={selectedOption == 'default_drss_queue'}
                            onChange={() => setSelectedOption('default_drss_queue')} id="rssqueue2" />
                        <label className="form-check-label" htmlFor="rssqueue2">
                            Default drss queue
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="rssqueue"
                            checked={selectedOption == 'queue_custom'}
                            onChange={() => setSelectedOption('queue_custom')} id="rssqueue3" />
                        <label className="form-check-label" htmlFor="rssqueue3">
                            Add custom queue
                        </label>
                    </div>
                    {errors.option && <h6 className='text-danger my-2 fw-bold'>This field is required</h6>}
                </Col>

                <Col md={6} className='my-3'>
                    <div className='d-flex mx-2 align-items-center'>
                        <input type="checkbox" className='form-check me-2' checked={findBlockingSol}
                            onChange={(e) => setFindBlockingSol(e.target.checked)} />
                        <h6 className='fw-bold m-0'>Test Run / Find Blocking Solution <Info size={18} className='mx-2' data-tip
                            data-for='redmine_link' /> </h6>
                        <ReactTooltip id='redmine_link' clickable delayHide={1000} effect='solid'>
                            <a href={`https://redmine.promptcloud.com/projects/sdftech/wiki/Automated_Solution_Finder_For_Blocking_Sites`} target='_blank'
                                className='text-white'>
                                https://redmine.promptcloud.com/projects/sdftech/wiki/Automated_Solution_Finder_For_Blocking_Sites </a>
                        </ReactTooltip>
                    </div>
                </Col>

                {selectedOption == 'default_rss_queue' ?
                    <Col md={12} className='my-3'>
                        <Alert color='light'>
                            <h6 className='fw-bold'>
                                NOTE: In case you are unaware of the active rss queue, please go
                                <a href={`/internal/end_to_end_crawl_tracker`} target='_blank'> here </a>
                                to get the rss_crawl_push_id. As queue names are created using rss_crawl_push_id, so its an important field. Please be careful about queue name while manually pushing to the queue.
                            </h6>
                        </Alert>
                        <h6 className='fw-bold mt-2'>Rss Crawl Push ID (Optional)</h6>
                        <input type='text' className='form-control' value={rssCrawlId} placeholder='Rss Crawl Push ID'
                            onChange={(e) => setRssCrawlId(e.target?.value)} />
                        {errors?.rss_crawl && <h6 className='fw-bold text-danger my-2'> {errors?.rss_crawl} </h6>}
                    </Col>
                    : selectedOption == 'default_drss_queue' ?
                        <Col md={12} className='my-3'>
                            <h6 className='fw-bold'>Rss Crawl Push ID (Optional)</h6>
                            <input type='text' className='form-control' value={rssCrawlId} placeholder='Rss Crawl Push ID'
                                onChange={(e) => setRssCrawlId(e.target?.value)} />
                            {errors?.rss_crawl && <h6 className='fw-bold text-danger my-2'> {errors?.rss_crawl} </h6>}
                        </Col>
                        : selectedOption == 'queue_custom' && <Col md={12} className='my-3'>
                            <h6 className='fw-bold'>Enter Queue Name*</h6>
                            <input type='text' className='form-control' value={queueName} placeholder='Enter Custom Queue Name'
                                onChange={(e) => setQueueName(e.target?.value)} />
                            {errors.queue_name && <h6 className='text-danger my-2 fw-bold'>This field is required</h6>}
                        </Col>}

                <Col md={6} className='my-3'>
                    <button className='btn btn-success' onClick={handleSubmit}
                        disabled={loading} style={{ margin: '25px auto', width: '100%' }}> Submit </button>
                </Col>
            </Row>
        </Container>
    )
}
