import React from 'react';
import { useMemo } from 'react';
import { useRef, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function EditAdminConfigModal({ redmineId, freshbooksId, zohobooksId, accessZohobooks, submitRequestHandler }) {

  const redId = useRef(redmineId);
  const freshId = useRef(freshbooksId);
  const zohoId = useRef(zohobooksId);

  const [openModal, setOpenModal] = useState(false);

    // Helper function to open and close modal
    function modalOpener() {
      setOpenModal(!openModal);
    }

  return (
    <>
      <button className='btn btn-xs btn-primary mx-2' onClick={modalOpener}>Edit Admin Configuration</button>
      <Modal funk={"true"} isOpen={openModal} toggle={() => modalOpener()} backdrop='static' size='lg'>
        <ModalHeader toggle={() => modalOpener()} >
          Admin Configurations
        </ModalHeader>
        <ModalBody className='px-5'>
          <Row className='my-2'>
            <Col lg={6}>
              <h6 className='my-0'>Redmine Project ID: </h6>
            </Col>
            <Col lg={6}>
              <input
                type="text"
                defaultValue={redmineId}
                ref={redId}
                className='form-control'
              />
            </Col>
          </Row>
          <Row className='my-2'>
            <Col lg={6}>
              <h6 className='my-0'>Freshbooks Client ID: </h6>
            </Col>
            <Col lg={6}>
              <input
                type="text"
                defaultValue={freshbooksId}
                ref={freshId}
                className='form-control'
              />
            </Col>
          </Row>
          {accessZohobooks ? 
          <Row className='my-2'>
            <Col lg={6}>
              <h6 className='my-0'>Zohobooks Client ID: </h6>
            </Col>
            <Col lg={6}>
              <input
                type="text"
                defaultValue={zohobooksId}
                ref={zohoId}
                className='form-control'
              />
            </Col>
          </Row>
          : ""}
        </ModalBody>
        <ModalFooter>
          <Button color='light' onClick={() => modalOpener()}> Close </Button>
          {accessZohobooks ? 
          <Button color="success" onClick={() => submitRequestHandler({
            redmine_id: redId.current.value,
            freshbooks_client_id: freshId.current.value,
            zohobooks_client_id: zohoId.current.value
          })}
            disabled={false}>Save</Button>
          :
          <Button color="success" onClick={() => submitRequestHandler({
            redmine_id: redId.current.value,
            freshbooks_client_id: freshId.current.value,
          })}
            disabled={false}>Save</Button>
        }
        </ModalFooter>
      </Modal>
    </>
  )
}
