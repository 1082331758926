import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function SdfMetricsStats() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const searchParams = {
    fromTime: new URLSearchParams(search).get("filter[time]"),
  }
  const [fromTime, setFromTime] = useState({ label: 'Last 4 hours', value: 'now-4h' });

  const timeOptions = [
    { label: 'Today', value: 'now%2Fd' },
    { label: 'Last 30 minutes', value: 'now-30m' },
    { label: 'Last 1 hour', value: 'now-1h' },
    { label: 'Last 2 hours', value: 'now-2h' },
    { label: 'Last 4 hours', value: 'now-4h' },
    { label: "Last 10 hours", value: "now-10h" },
    { label: 'Last 12 hours', value: 'now-12h' },
    { label: 'Last 24 hours', value: 'now-24h' },
    { label: 'Last 2 days', value: 'now-2d' },
    { label: 'Last 3 days', value: 'now-3d' },
    { label: 'Last 4 days', value: 'now-4d' },
    { label: 'Last 7 days', value: 'now-7d' },
    { label: 'Last 15 days', value: 'now-15d' },
    { label: 'Last 30 days', value: 'now-30d' },
    { label: 'Last 60 days', value: 'now-60d' },
    { label: 'Last 1 year', value: 'now-1y' }
  ];

  const [UrlParams, setUrlParams] = useState({
    fromTime: 'now-4h',
  })

  useEffect(() => {
    setSearchParamsData();
  }, []);

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.fromTime && searchParams.fromTime != fromTime.value) {
      setFromTime(timeOptions.filter(item => item.value == searchParams.fromTime)[0]);
    }
    if (commit == 'Get Data' && searchParams.fromTime) {
      setUrlParams(searchParams)
    }
  }

  function sdfMetricsStatsKibanaUrl(){
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";

    return `${KIBANA_PLM_URL}app/kibana#/dashboard/sdf_metrics_dashboard?_g=(refreshInterval:(pause:!t,value:0),time:(from:'${UrlParams.fromTime}',to:'${to_time_val}'))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(darkTheme:!f),panels:!((embeddableConfig:(),gridData:(h:25,i:'1',w:48,x:0,y:100),id:sdf_upload_count_per_crawl_date,panelIndex:'1',type:visualization,version:'7.5.1'),(embeddableConfig:(),gridData:(h:30,i:'2',w:48,x:0,y:125),id:sdf_upload_count_per_site_name,panelIndex:'2',type:visualization,version:'7.5.1'),(embeddableConfig:(),gridData:(h:25,i:'3',w:48,x:0,y:155),id:Killed_or_failed_site_barchart,panelIndex:'3',type:visualization,version:'7.5.1'),(embeddableConfig:(),gridData:(h:20,i:'4',w:48,x:0,y:0),id:sdfmetrics_index_count_over_hour,panelIndex:'4',type:visualization,version:'7.5.1'),(embeddableConfig:(),gridData:(h:25,i:'5',w:48,x:0,y:20),id:sdfmetrics_By_File_AND_Function_Name,panelIndex:'5',type:visualization,version:'7.5.1'),(embeddableConfig:(),gridData:(h:25,i:'6',w:48,x:0,y:45),id:sdfmetrics_By_Host_AND_Function_Name,panelIndex:'6',type:visualization,version:'7.5.1'),(embeddableConfig:(),gridData:(h:30,i:'7',w:48,x:0,y:70),id:sdfmetrics_Histogram,panelIndex:'7',type:visualization,version:'7.5.1')),query:(language:kuery,query:''),timeRestore:!t,title:sdf_metrics_dashboard,viewMode:view)`
  }

  const handleGetData = () => {
    window.location.href = `/internal/log_stats/sdf_metrics_stats?filter[time]=${fromTime.value}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/log_stats/sdf_metrics_stats">Log Stats - Sdf Metrics Stats</a> : 'Log Stats - Sdf Metrics Stats'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                Search
                </BreadcrumbItem>}
          </Breadcrumb>
        </Col>
      </Row>
    </div>

    <Card>
      <CardBody>
        <Row>
          <Col sm="4">
            <label className="fw-bold">Time</label>
            <Select
              value={fromTime}
              options={timeOptions}
              onChange={option => setFromTime(option)} />
          </Col>
          <Col sm="4">
            <label className="fw-bold">&nbsp;</label>
            <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
          </Col>
        </Row>
      </CardBody>
    </Card>
    <Card>
      <CardBody>
        <center>
          <span className="fw-bold">Sdf Metrics Statistics</span><br />
          <a target="_blank" className="link-primary" href={sdfMetricsStatsKibanaUrl()}>Click here to see below graph in kibana</a>
        </center>
        <iframe src={sdfMetricsStatsKibanaUrl() + "&embed=true"} height="1000" width="100%" className="border-0"></iframe>
      </CardBody>
    </Card>
  </Container>
}

