import React from 'react'
import DataTable from 'react-data-table-component';
import { Card, CardHeader, CardBody } from 'reactstrap';
import AddWebsite from './AddWebsite/AddWebsite.component';
export default function Table({ Columns, TableData }) {
    const numberOfRecordsToDisplay = 20; // Number of record to display 
    return (
        <>
            <Card>
                <CardHeader className='d-flex justify-content-end'>
                    <div>
                        <AddWebsite />
                    </div>
                </CardHeader>
                <CardBody>
                    <DataTable
                        columns={Columns}
                        data={TableData}
                        paginationPerPage={numberOfRecordsToDisplay}
                        highlightOnHover
                        pagination
                        responsive
                        noHeader
                    />
                </CardBody>
            </Card>
        </>
    )
}
