import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";

import { KIBANA_JP_URL } from '../../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints'

export default function JpInferringErrors() {

  function KibanaUrl(){
    return `${KIBANA_JP_URL}app/kibana#/dashboard/JP-Logstash-QA-Inferring-Error-Dashboard?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(filters:!(),options:(darkTheme:!f),panels:!((col:1,id:JP-Logstash-QA-Count-of-Inferring-Error-Domains,panelIndex:1,row:4,size_x:6,size_y:4,type:visualization),(col:7,id:JP-Logstash-QA-Count-of-Inferring-Error-Types,panelIndex:2,row:8,size_x:6,size_y:4,type:visualization),(col:1,id:JP-Logstash-QA-Inferring-Error-Types-Graph,panelIndex:3,row:1,size_x:12,size_y:3,type:visualization),(col:7,id:JP-Logstash-QA-Count-of-Inferring-Error-Site-Setup-Name,panelIndex:4,row:4,size_x:6,size_y:4,type:visualization),(col:1,id:JP-Logstash-QA-Count-of-Inferring-Error-Job-Boards,panelIndex:5,row:8,size_x:6,size_y:4,type:visualization)),query:(query_string:(analyze_wildcard:!t,query:'custom:jobspikr_inferred_location_error')),title:'JP%20Logstash%20QA%20Inferring%20Error%20Dashboard',uiState:(P-3:(vis:(legendOpen:!t))))`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>JP Inferring Errors Dashboard</BreadcrumbItem>
        </Breadcrumb>
      </Col>
    </Row>
  </div>

  <Card>
    <CardBody>
      <center>
        <h5 className="mb-2">JP Inferring Errors Dashboard</h5>
        <a target="_blank" className="link-primary" href={KibanaUrl()}>Click here to see below graph in kibana</a>
      </center>
      <iframe src={KibanaUrl() + "&embed=true"} height="600" width="100%" className="border-0"></iframe>
    </CardBody>
  </Card>
</Container>
}

