import React, {useState, useEffect, Fragment} from 'react'
import { Card, CardBody, Col, Container, Row, Breadcrumb, BreadcrumbItem, Nav, Table, Alert,
  NavItem, NavLink, TabContent, TabPane, CardHeader } from 'reactstrap'
import Loader from 'react-loader-spinner';
import axios from 'axios'
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import MyAccordion from '../../global/MyAccordion'

export default function ExamplesForMissingFields({activeTabName}) {
  let { sitename } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (activeTabName == "missing_fields"){
      setLoading(true)
      axios.get(`/api/v1/internal/qa/segment_level_qa?site_name=${sitename}&data_fetch_type=example_for_missing_fields`).then(res => {
        setData(res?.data?.qa_json_data)
        setLoading(false)
      }).catch(err => {
        console.log("err", err);
        toast.error(err.response?.data?.message)
        setLoading(false)
      })
    }
  }, [activeTabName])

  return (
    <>
      {
        loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
          :
          <>
            {
              data?.file_present == false ? 
              <Alert color='light'>
                <div dangerouslySetInnerHTML={{__html: data?.error_message}} />
              </Alert>
                :
                <>
                  {
                    Object.keys(data?.examples_for_missing_fields).length > 0 ?
                      Object.keys(data?.examples_for_missing_fields).map(each => 
                        (<MyAccordion title={`${each}`} className='my-3'>
                          <p>
                            {JSON.stringify(data?.examples_for_missing_fields[each])}
                          </p>
                        </MyAccordion>
                        )) 
                        : 
                        <Alert color='light'>
                          <div dangerouslySetInnerHTML={{__html: data?.error_message}} />
                        </Alert>
                  }
                </>
            }
          </>
      }
    </>
  )
}
