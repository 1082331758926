import React, { Fragment, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import DataTable from 'react-data-table-component';

export default function ChatGPTDataComparison(props) {

  const { rowData, prefetchedData, prefetchedDataColumns, comparisonDataTableArray } = props; // props


  // states
  const [openModal, setOpenModal] = useState(false);
  const [comaprisonTableData, setCompariosnTableData] = useState([])

  // Helper function to open and close modal
  const handleClose = () => setOpenModal(false);
  const handleShow = () => setOpenModal(true);

  // create a common array by combining both arrays and add a new key for each pre-fecthed
  // and raw data to differentiate them
  const combinedArray = createFinalArrayOfData(Array(rowData), prefetchedData);

  useEffect(() => {
    const comparisonTableData = generateComparisonOutput(Array(rowData), prefetchedData)
    setCompariosnTableData(comaprisonTableData);
  }, [rowData])

  const comparisonTableColumns = [
    { name: "Field Name", selector: row => row?.field_name, left: true, sortable: true, maxWidth: "200px" },
    { name: "Raw Data", selector: row => (<p className='fw-bold'>{row?.raw_data}</p>), center: true, sortable: true, maxWidth: "550px", wrap: true },
    { name: "Pre-Fetched Data From ChatGPT", selector: row => (<p className='fw-bold'>{row?.pre_fetched_data}</p>), center: true, sortable: true, maxWidth: "550px", wrap: true },
  ]

  const customStyles = {
    header: {
      style: {
        minHeight: '56px',

      },
    },
    headRow: {
      style: {
        backgroundColor: "#9dbaed",
        color: "black",
        fontWeight: "bold",
      },
    },
    headCells: {
      style: {
        '&:first-of-type': {
          width: "250px",
          minWidth: "250px",
          maxWidth: "250px",
        },
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: "#0000",
        },
        width: "350px",

      },
    },
    cells: {
      style: {
        '&:first-of-type': {
          width: "250px",
          minWidth: "250px",
          maxWidth: "250px",
        },
        border: "1px solid black",
        cursor: "pointer",
      },
    },
  };

  const customRowStyles = [
    {
      when: (row) => row.raw_data === row.pre_fetched_data && row.pre_fetched_data.includes(row.raw_data),
      style: {
        backgroundColor: '#FFC700',
        color: "white",
      },
    },
    {
      when: (row) => row.raw_data !== row.pre_fetched_data,
      style: {
        backgroundColor: '#A0153E',
        color: "white",
      },
    },
    {
      when: (row) => row.raw_data === row.pre_fetched_data,
      style: {
        backgroundColor: '#007F73',
        color: "white",
      },
    },
  ];

  if (rowData?.prefetched_chatgpt_response !== "NA") {
    return (
      <Fragment>
        <Button variant="primary" onClick={handleShow}>Compare Data</Button>
        <Modal
          size="xl"
          show={openModal}
          onHide={handleClose}
          scrollable={true}
          fullscreen='xl-down'
        >
          <Modal.Header closeButton>
            <div><h2>Data Comparison</h2></div>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className='d-flex flex-column justify-content-center align-items-center align-content-center mb-2'>
                <div>
                  <h4>Pre-fetched Chat GPT response VS Raw Data</h4>
                </div>
                <div className='d-flex flex-row justify-content-center align-items-center'>
                  <div className='d-flex flex-row justify-content-center align-items-center'>
                    <div style={{ width: "20px", height: "20px", backgroundColor: "#007F73" }} className='mx-2'></div>
                    Matched
                    <div style={{ width: "20px", height: "20px", backgroundColor: "#A0153E" }} className='mx-2'></div>
                    Not Matched
                  </div>
                </div>
              </div>
              <div className='new-qa-reports-raw-data-file p-3'>
                <DataTable
                  columns={comparisonTableColumns}
                  data={comparisonDataTableArray}
                  conditionalRowStyles={customRowStyles}
                  customStyles={customStyles}
                  striped
                  noHeader
                  responsive
                  pagination
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    )
  }
  else {
    return (
      <Fragment>
        "N/A"
      </Fragment>
    )
  }
}

function createFinalArrayOfData(inputArrayA, inputArrayB) {
  const arrayA = findCommonElements(inputArrayA, inputArrayB)
  const newArrayA = arrayA.map(obj => ({ ...obj, data_source: "Data from Raw File" }));
  const newArrayB = inputArrayB.map(obj => ({ ...obj, data_source: "Pre-fetched ChatGPT Data" }));

  const arrayC = [...newArrayA, ...newArrayB];
  return arrayC;
};

const findCommonElements = (arrayA, arrayB) => {
  const commonElements = {};

  // Check if both arrays have objects
  if (arrayA.length > 0 && arrayB.length > 0) {
    const objA = arrayA[0];

    // Iterate through the properties of the first object in arrayA
    for (const key in objA) {
      // Check if the key is present in the second object in arrayB
      if (arrayB[0].hasOwnProperty(key)) {
        // If present, add it to the commonElements object with value from objA
        commonElements[key] = objA[key];
      }
    }
  }

  // Convert the commonElements object to an array with a single object
  const arrayC = [commonElements];
  return arrayC;
};

function generateComparisonOutput(rawDataArray, prefetchedDataArray) {
  const output = [];

  for (let i = 0; i < rawDataArray.length; i++) {
    const rawData = rawDataArray[i];
    const prefetchedData = prefetchedDataArray[i] || {};

    for (const key in rawData) {
      if (Object.prototype.hasOwnProperty.call(rawData, key)) {
        const field_name = key;
        const raw_data = rawData[key];
        const pre_fetched_data = prefetchedData[key] || "N/A";

        output.push({ field_name, raw_data, pre_fetched_data });
      }
    }
  }

  return output;
};
