import React, {forwardRef, useImperativeHandle, useState, useEffect} from 'react';
import currencyData from './Currency.json'
import axios from 'axios'
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
const AddressDetails = forwardRef(({data, setData, myOrganization}, ref) => {
    const [billingName, setBillingName] = useState('')
    const [currency, setCurrency] = useState('')
    const [user, setUser] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [loading, setLoading] = useState(false)
    const [clientCountry, setClientCountry] = useState('')
    const [gstin, setGstin] = useState('')
    const [line1, setLine1] = useState('')
    const [line2, setLine2] = useState('')
    const [city, setCity] = useState('')
    const [zip, setZip] = useState('')
    const [sstate, setSstate] = useState('')

    useEffect(() => {
        setUser(myOrganization?.org_identifier || '')
    }, [myOrganization])

    useEffect(() => {
        if ("address" in data){
            setLoading(true)
            setBillingName(data?.billing_name || '')
            setCurrency(data?.admin_operations?.preffered_currency || '')
            setClientCountry(data?.address?.country_name || '')
            setPhoneNumber(data?.phone_number || '')
            setGstin(data?.gstin_id || '')
            setLine1(data?.address?.line1 || '')
            setLine2(data?.address?.line2 || '')
            setCity(data?.address?.city || '')
            setZip(data?.address?.zip || '')
            setSstate(data?.address?.state || '')
            setLoading(false)
        }
    }, [data])

    useImperativeHandle(ref, () => ({
        isValidated() {
            if (line1.trim().length === 0 && city.trim().length === 0){
                toast.error('Please enter Street line 1 and city details')
                return false
            }
            if (line1.trim().length === 0){
                toast.error('Please enter Street line details')
                return false
            }
            if (city.trim().length === 0){
                toast.error('Please enter City details')
                return false
            }
            else{
                setData({
                    ...data,
                    address: {
                        ...data.address, city, line1, line2, state: sstate, zip
                    },
                    admin_operations: {
                        ...data.admin_operations, preffered_currency: currency
                    },
                    billing_name: billingName, gstin_id: gstin, phone_number: phoneNumber
                })
                return true
            }
        }
    }));


    return (
        <>
        {
            loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> :
        <div className='px-2 py-4'>
            { user.toLowerCase() === "promptcloud" &&
            <div className='my-4'>
                <h6 className='fw-bold'>Billing Name </h6>
                <input type="text" className="form-control" name="billing_name" 
                    value={billingName} onChange={(e) => setBillingName(e.target.value)} />
            </div>}

            { user.toLowerCase() === "promptcloud" &&
            <div className='my-4'>
                <h6 className='fw-bold'>Preffered Currency</h6>
                <select className='form-control show-arrow' value={currency}
                    onChange={(e) => setCurrency(e.target.value)}>
                    {Object.keys(currencyData).map(each => (
                        <option key={each} value={each}> {each} </option>
                    ))}
                </select>
            </div>}

            <div className='my-4'>
                <h6 className='fw-bold'>Phone Number </h6>
                <input type="text" className='form-control' name="phone_number" placeholder='phone number'
                    value={phoneNumber} onChange={(e) => {
                        if (e.target.value / 1){
                            setPhoneNumber(e.target.value)
                        }
                    }} />
            </div>

            { clientCountry.toLowerCase() === "india" &&
            <div className='my-4'>
                <h6 className='fw-bold'>GSTIN </h6>
                <input type="text" className="form-control" name="gstin_number" placeholder='please enter your GSTIN Number'
                    value={gstin} onChange={(e) => setGstin(e.target.value)} />
            </div>}

            <div className='my-4'>
                <h6 className='fw-bold'>Street Line 1* </h6>
                <input type="text" className='form-control' name="line1" placeholder='Required'
                    value={line1} onChange={(e) => setLine1(e.target.value)} />
            </div>

            <div className='my-4'>
                <h6 className='fw-bold'>Street Line 2 </h6>
                <input type="text" className='form-control' name="line2" placeholder='optional'
                    value={line2} onChange={(e) => setLine2(e.target.value)} />
            </div>

            <div className='my-4'>
                <h6 className='fw-bold'>City* </h6>
                <input type="text" className='form-control' name="city" placeholder='Required'
                    value={city} onChange={(e) => setCity(e.target.value)} />
            </div>

            { clientCountry.toLowerCase() === "india" &&
            <div className='my-4'>
                <h6 className='fw-bold'>State* </h6>
                <select className='form-control show-arrow' name="state" value={sstate}
                    onChange={(e) => setSstate(e.target.value)}>
                    <option value="AP">Andhra Pradesh</option>
                    <option value="AD">Andhra Pradesh (New)</option>
                    <option value="AR">Arunachal Pradesh</option>
                    <option value="AS">Assam</option>
                    <option value="BH">Bihar</option>
                    <option value="CH">Chandigarh</option>
                    <option value="CT">Chattisgarh</option>
                    <option value="DN">Dadra and Nagar Haveli</option>
                    <option value="DD">Daman and Diu</option>
                    <option value="DL">Delhi</option>
                    <option value="GA">Goa</option>
                    <option value="GJ">Gujarat</option>
                    <option value="HR">Haryana</option>
                    <option value="HP">Himachal Pradesh</option>
                    <option value="JK">Jammu and Kashmir</option>
                    <option value="JH">Jharkhand</option>
                    <option value="KA">Karnataka</option>
                    <option value="KL">Kerala</option>
                    <option value="LD">Lakshadweep Islands</option>
                    <option value="MP">Madhya Pradesh</option>
                    <option value="MH">Maharashtra</option>
                    <option value="MN">Manipur</option>
                    <option value="ME">Meghalaya</option>
                    <option value="MI">Mizoram</option>
                    <option value="NL">Nagaland</option>
                    <option value="OR">Odisha</option>
                    <option value="PY">Pondicherry</option>
                    <option value="PB">Punjab</option>
                    <option value="RJ">Rajasthan</option>
                    <option value="SK">Sikkim</option>
                    <option value="TN">Tamil Nadu</option>
                    <option value="TS">Telangana</option>
                    <option value="TR">Tripura</option>
                    <option value="UP">Uttar Pradesh</option>
                    <option value="UT">Uttarakhand</option>
                    <option value="WB">West Bengal</option>
                </select>
            </div>}

            <div className='my-4'>
                <h6 className='fw-bold'>ZIP </h6>
                <input type="text" className='form-control' name="zip" placeholder='optional'
                    value={zip} onChange={(e) => setZip(e.target.value)} />
            </div>
        </div>}
        </>
    );
});

export {AddressDetails as default}
