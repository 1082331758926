/**
 * This is the re-factored component for SegmentsTakeMoreTimeByEDNUStageWise which queries from the Elastic Search Cluster
 */
import React from 'react'
import { useState } from 'react'

import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import { Nav, NavItem, NavLink } from 'reactstrap'
import { TabContent, TabPane } from 'reactstrap'
import ReactDatePicker from 'react-datepicker'

import Breadcrumbs from '../../Common/BreadCrumb/BreadCrumbs.component'
import ExtractionTable from './DataTables/ExtractionTable.component'
import DedupTable from './DataTables/DedupTable.component'
import NormalizationTable from './DataTables/NormalizationTable.component'
import UploadTable from './DataTables/UploadTable.component'

export default function SegmentsTakeMoreTimeByEDNUStagewise() {

  const [date, setDate] = useState(new Date()) // date passed through props for API Call
  const [selectedDate, setSelectedDate] = useState(new Date) // The state to hold the date selected by user
  const [activeTab, setActiveTab] = useState('Extraction') // Tab selected by user


  // Helper method to navigate to different tabs in the tab pane
  function onTabSelectHandler(SelectedTabName) {
    setActiveTab(SelectedTabName)
  }

  // Helper function to handle date change
  function onDateChangeHandler(value) {
    setSelectedDate(value)
  }

  // Helper function to handle get data button click
  function onGetDataButtonClickHandler() {
    setDate(selectedDate)
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Container fluid>
          <Breadcrumbs parent={'Internal Dashboard'} title={'Segments Take More Time BY EDNU Stagewise'} />
        </Container>
        <Card>
          <CardBody>
            <Row className='align-items-end'>
              <Col sm='5'>
                <label className='fw-bold'>Crawl Date</label>
                <ReactDatePicker
                className='form-select'
                selected={selectedDate}
                maxDate={new Date()}
                onChange={(value)=>onDateChangeHandler(value)}
                dateFormat={'yyyy/MM/dd'} />
              </Col>
              <Col sm='2'>
                <button className='btn btn-success w-100' onClick={()=>onGetDataButtonClickHandler()}>Get Data</button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Nav tabs>
              <NavItem>
                <NavLink className={activeTab === 'Extraction' ? 'active' : ''} onClick={() => onTabSelectHandler('Extraction')}>Extraction</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={activeTab === 'Dedup' ? 'active' : ''} onClick={() => onTabSelectHandler('Dedup')}>Dedup</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={activeTab === 'Normalization' ? 'active' : ''} onClick={() => onTabSelectHandler('Normalization')}>Normalization</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={activeTab === 'Upload' ? 'active' : ''} onClick={() => onTabSelectHandler('Upload')}>Upload</NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={'Extraction'}>
                <ExtractionTable crawlDate={date} />
              </TabPane>
              <TabPane tabId={'Dedup'}>
                <DedupTable crawlDate={date}/>
              </TabPane>
              <TabPane tabId={'Normalization'}>
                <NormalizationTable crawlDate={date} />
              </TabPane>
              <TabPane tabId={'Upload'}>
                <UploadTable crawlDate={date} />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  )
}
