import React from 'react';
import { useState, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { SitesContext } from '../Context/SitesState';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import SpinnerButton from '../../../Common/SpinnerButton/SpinnerButton.component';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import { toast } from 'react-toastify';


export default function ScheduleCrawls() {
  const { organization_name } = useParams(); // Name of organization
  const { siteGroupNames, tableSelection } = useContext(SitesContext); // Names of sitegroups 
  const [modalOpen, setModalOpen] = useState(false); // use for opening and closing modal
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));
  const [selectedOptions, setSelectedOptions] = useState("all");
  const [testCrawlButtonLoading, setTestCrwalButtonLoading] = useState(false); // loading animation when test crawl button is clicked
  const [fullCrawlButtonLoading, setFullCrwalButtonLoading] = useState(false); // loading animation when full crawl button is clicked

  // THE API endpoint used for POST request 
  const API_ENDPOINT = useMemo(() => {
    return `/api/v1/organizations/${organization_name}/sites/crawl_schedules`
  }, [organization_name]);


  // use effect hook for everytime the modal is loaded
  useEffect(() => {
    if (tableSelection.length > 0) {
      setSelectedOptions("selected_sites");
    }
    else {
      setSelectedOptions("all");
    }
  }, [tableSelection])

  // Helper function make API call based on crawl type
  async function handleCrawlScheduleRequest(crawlType) {
    switch (crawlType) {
      case "test_crawl":
        setTestCrwalButtonLoading(true);
        await sendRequest("test_crawl");
        setTestCrwalButtonLoading(false);
        break;
      case "full_crawl":
        setFullCrwalButtonLoading(true);
        await sendRequest("full_crawl");
        setFullCrwalButtonLoading(false);
        break;
      default:
        console.log("NO OPTION SELECTED!");
    }
  }

  // Function to open and close modal
  function openCloseModal() {
    setModalOpen(!modalOpen);
  }

  // Function to handle options select from dropdown
  function onSiteGroupNameSelectHandler(event) {
    const { value } = event.target;
    setSelectedOptions(value);

  }

  // Function to handle date select
  function onDateSelectHandler(date) {
    setSelectedDate(date);
  }


  // Function to send POST request to backend DB
  async function sendRequest(crwlTyp) {
    try {
      if (selectedOptions === "all") {
        const request = await axios.post(API_ENDPOINT, {
          schedule: {
            date: getFormattedDate(selectedDate),
            site_options: 'all_sites',
            crawl_type: crwlTyp
          }
        });
        setModalOpen(false);
        toast.success(request?.data?.message);
      }
      else if (selectedOptions === "selected_sites") {
        const request = await axios.post(API_ENDPOINT, {
          schedule: {
            selected_sites: tableSelection,
            date: getFormattedDate(selectedDate),
            site_options: 'selected_sites',
            crawl_type: crwlTyp
          }
        });
        setModalOpen(false);
        toast.success(request?.data?.message);
      }
      else {
        const request = await axios.post(API_ENDPOINT, {
          schedule: {
            date: getFormattedDate(selectedDate),
            site_options: selectedOptions,
            crawl_type: crwlTyp
          }
        });
        setModalOpen(false);
        toast.success(request?.data?.message);
      }
    }
    catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  // Misc
  function getFormattedDate(date) {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  }

  return (
    <div>
      <Button className='mx-2' color='primary' onClick={openCloseModal}>Schedule Crawls</Button>
      <Modal funk={"true"} isOpen={modalOpen} toggle={openCloseModal} backdrop='static' size='lg'>
        <ModalHeader toggle={openCloseModal}>
          Schedule Crawls
        </ModalHeader>
        <ModalBody className='p-5'>
          <div>
            <div className='my-3 d-flex align-center'>
              <h6 className='me-2 fw-bold'>
                Sites:
              </h6>
              <select className='form-control show-arrow' defaultValue={selectedOptions}
                onChange={(e) => onSiteGroupNameSelectHandler(e)}>
                <option value="selected_sites" disabled={tableSelection.length <= 0}>
                  Selected sites
                </option>
                <option value='all'>All options</option>
                {siteGroupNames.map(each => (
                  <option key={each[0]} value={each[1]}>
                    Active sites of {each[1]}
                  </option>
                ))}
              </select>
            </div>
            <div className='mb-5 mt-2 d-flex align-center'>
              <h6 className='me-2 fw-bold'>
                Date:
              </h6>
              <DatePicker
                className="form-control"
                selected={selectedDate}
                minDate={new Date(Date.now())}
                onChange={onDateSelectHandler} />
            </div>
            <p>
              <b>NOTE: </b> These crawls will be performed only
              if it does not clash with already running crawl
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <SpinnerButton
            color='primary'
            loading={testCrawlButtonLoading}
            onClick={() => handleCrawlScheduleRequest("test_crawl")}
            disabled={testCrawlButtonLoading}>
            Schedule a Test Run
          </SpinnerButton>
          <SpinnerButton
            color='success'
            loading={fullCrawlButtonLoading}
            onClick={() => handleCrawlScheduleRequest("full_crawl")}
            disabled={fullCrawlButtonLoading}>
            Schedule a Complete Recrawl
          </SpinnerButton>
        </ModalFooter>
      </Modal>
    </div>
  )
}
