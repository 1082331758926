// Styling for static tables

export const tableStyle = {
  tableLayout: "fixed",
  width: "100%",
  maxWidth: "692px",
  border: "2px solid #FFF6E9",
}

export const tableHeaderStyle = {
  textAlign: "center",
  border: "2px solid #FFF6E9",
}

export const tableRowStyle = {
  textAlign: "center",
  border: "2px solid #FFF6E9",
  overflow: "hidden",
  height: "40px",
  maxHeight: "40px",
  padding: "3px",
  maxWidth: "350px"
}

export const tableDataStyle = {
  border: "2px solid #FFF6E9",
  textAlign: "center",
  overflow: "hidden",
  height: "40px",
  padding: "3px",
  maxWidth: "350px",
  maxHeight: "40px"
}

export const pStyle = {
  margin: "0px",
  padding: "0px",
  height: "40px",
  maxHeight: "40px",
}

export const cardHeaderStyle = {
  backgroundColour: "#0D9276",
}