// Table column definitons for table shown in Queuse Size Card
import React from "react";
import { Info } from "react-feather";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import DataTable from "react-data-table-component";

export default function AverageTimeTable({ siteValue, data }) {

    // Helper function to format time
    const transformTime = (string) => {
        if (string != undefined) {
            let temp = new Date(string * 1000)?.toISOString()?.substr(11, 8);
            return temp.substr(0, 2) + ' h ' + temp.substr(3, 2) + ' m ' + temp.substr(6, 2) + ' s'
        } else {
            return ''
        }
    }

    // Data Table Column Definitions
    const columns = [
        { name: 'Date', selector: 'date', center: true, sortable: true },
        {
            name: <>
                <div className='d-flex align-items-center'><b data-tip data-for='rssss_link'> RSS </b>
                    <Info size={14} className='ms-2' /> </div>
                <ReactTooltip id='rssss_link' clickable multiline delayHide={1000} effect='solid'>
                    <a href={`https://app.promptcloud.com/internal/overall_system_reports/avg_time_per_pipeline_stage_geo_wise?filter%5Bsite_name%5D=${siteValue}&filter%5Btab_name%5D=rss`} target='_blank'
                        className='text-white'>
                        Click here to see the avg time taken per Rss geo wise </a>
                </ReactTooltip>
            </>, center: true, sortable: true, cell: (each) => <>
                {transformTime(each?.rss_avg_time_taken)}
            </>
        },
        {
            name: <>
                <div className='d-flex align-items-center'><b data-tip data-for='feedcrawlll_link'> Feedcrawl </b>
                    <Info size={14} className='ms-2' /> </div>
                <ReactTooltip id='feedcrawlll_link' clickable multiline delayHide={1000} effect='solid'>
                    <a href={`https://app.promptcloud.com/internal/overall_system_reports/avg_time_per_pipeline_stage_geo_wise?filter%5Bsite_name%5D=${siteValue}&filter%5Btab_name%5D=feedcrawl`} target='_blank'
                        className='text-white'>
                        Click here to see the avg time taken per Feedcrawl geo wise </a>
                </ReactTooltip>
            </>, center: true, sortable: true, cell: (each) => <>
                {(((each?.feedcrawl_count < (each?.rss_count * 0.75)) && (each?.date == moment().format('YYYYMMDD')))
                    || ((each?.feedcrawl_count * 0.1) < each?.failed_url_count))
                    ? <b className='text-danger'> {transformTime(each?.feedcrawl_avg_time_taken)} </b>
                    : transformTime(each?.feedcrawl_avg_time_taken)}
            </>
        },
        {
            name: 'Extraction', center: true, sortable: true, cell: (each) => <>
                {transformTime(each?.extr_avg_time_taken)}
            </>
        },
        {
            name: 'Dedup', center: true, sortable: true, cell: (each) => <>
                {(each?.dropped_ded > 1000 || (each?.dropped_ded * 0.3) > each?.total_segment_dedup_count)
                    ? <p className='fw-bold text-danger'> {transformTime(each?.dedup_avg_time_taken)} </p>
                    : transformTime(each?.dedup_avg_time_taken)}
            </>
        },
        {
            name: 'Normalize', center: true, sortable: true, cell: (each) => <>
                {transformTime(each?.norm_avg_time_taken)}
            </>
        },
        {
            name: 'Upload', center: true, sortable: true, cell: (each) => <>
                {transformTime(each?.upload_avg_time_taken)}
            </>
        },
    ];

    return (
        <>
            <DataTable
                columns={columns}
                data={data?.site_data}
                noHeader
            />
        </>
    )
};
