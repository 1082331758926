import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from "moment";
import { Row, Col, Card, CardBody } from "reactstrap";
import DatePicker from "react-datepicker";
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import axios from 'axios';
import { toast } from 'react-toastify';

import { NewInternalBasePoint } from '../../../../Common/BaseEsPoints/InternalEsBasePoint';

const rssCrawlStatusOptions = [
  { label: 'All other than done', value: 'all_other_than_done' },
  { label: 'started', value: 'started' },
  { label: 'failed', value: 'failed' }
];

const DROPDOWN_LIST_OPTIONS_API_ENDPOINT = '/api/v1/internal/dropdown_list_helper';
const SEARCH_SITE_NAME_API_ENDPOINT = '/api/v1/internal/search_site_names?site_name=';

export default function SiteSearchCard(props) {

  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const site_name = new URLSearchParams(search).get("filter[site]");
  const rss_crawlstatus = new URLSearchParams(search).get("filter[rss_crawlstatus]");

  const searchParams = {
    siteName: site_name ? site_name : "all",
    rssCrawlStatus: rss_crawlstatus ? rss_crawlstatus : "all_other_than_done",
    fromDate: new URLSearchParams(search).get("filter[crawldate][from]"),
    toDate: new URLSearchParams(search).get("filter[crawldate][to]"),
  };

  const [UrlParams, setUrlParams] = useState({
    siteName: "all",
    rssCrawlStatus: 'all_other_than_done',
    fromDate: new Date(moment().subtract(2, 'days')),
    toDate: now
  });

  const now = new Date();
  const [siteName, setSiteName] = useState({ label: 'all', value: 'all' });
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(2, 'days')));
  const [toDate, setToDate] = useState(now);
  const [siteNameOptions, setSiteNameOptions] = useState([{ label: null, value: null }]);
  const [rssCrawlStatus, setRssCrawlStatus] = useState({ label: 'All other than done', value: 'all_other_than_done' });

  // useEffect for when card is loaded
  useEffect(async () => {
    const controller = new AbortController();

    await setSearchParamsData();
    await fetchDropdownOptionsFromDB(controller.signal);

    return () => {
      controller.abort();
      console.log('Internal RSS Process Component un-mounted');
    }
  }, []);

  // Helper function to fetch dropdown options from backend
  async function fetchDropdownOptionsFromDB(signal) {
    try {
      const res = await axios.get(DROPDOWN_LIST_OPTIONS_API_ENDPOINT, { signal: signal });
      const siteNames = res?.data?.site_names;
      await optionsDataFormatter(siteNames, setSiteNameOptions);
    }
    catch (error) {
      toast.error(error?.message || 'Oops something went wrong');
    }
  };


  // Helper function to fetch name of site based on site name entered by user in async-select
  async function onAsyncSelectInputChangeHandler(inputValue) {
    if(inputValue.length < 3){
      return null
    } else {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200'){
            console.log(res.data)
            let siteNames = res.data.site_names, siteNamesOptions = []
            for (var key in siteNames) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
              siteNames[key].map(item => {
                children.push({ label: item, value: item })
              })
              group['options'] = children;
              siteNamesOptions.push(group)
            }
            siteNamesOptions.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setSiteNameOptions(siteNamesOptions)
            resolve(siteNamesOptions)
          }
        }).catch(err => {
          console.log(`err`, err)
        })
      })
    }
  };

  // Helper function for get data button click
  function onGetDataButtonClickHandler(params) {
    window.location.href = `${NewInternalBasePoint}/rss_process?filter[site]=${siteName.value}&filter[rss_crawlstatus]=${rssCrawlStatus.value}&filter[crawldate][from]=${moment(fromDate).format('YYYY/MM/DD')}&filter[crawldate][to]=${moment(toDate).format('YYYY/MM/DD')}&commit=${'Get Data'}`;
  };

  // Helper function to format options data
  // Set the resolve flag to true to use formatter inside the promise options of async select
  async function optionsDataFormatter(data, setter) {

    let siteNameOptions = [];

    for (let key in data) {
      let group = {};
      let children = [];

      group['label'] = <div className='fw-bold col text-black'>{key}</div>

      data[key].map((item) => {
        children.push({ label: item, value: item });
      });
      group['options'] = children;
      siteNameOptions.push(group);
    }
    siteNameOptions.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] });
    setter(siteNameOptions);

  };

  // Helper function to set search params
  async function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.siteName && searchParams.siteName !== siteName.value) {
      setSiteName({ label: searchParams.siteName === 'all' ? 'All' : searchParams.siteName, value: searchParams.siteName });
    }
    if (searchParams.rssCrawlStatus && searchParams.rssCrawlStatus !== rssCrawlStatus.value) {
      setRssCrawlStatus({ label: searchParams.rssCrawlStatus === 'all_other_than_done' ? 'All' : searchParams.rssCrawlStatus, value: searchParams.rssCrawlStatus });
    }
    if (searchParams.fromDate && searchParams.fromDate !== fromDate) {
      setFromDate(new Date(searchParams.fromDate));
    }
    if (searchParams.toDate && searchParams.toDate !== toDate) {
      setToDate(new Date(searchParams.toDate));
    }
    if (commit === 'Get Data') {
      setUrlParams(searchParams)
      props.fetchRSSTableDataWithParams(searchParams.siteName, searchParams.rssCrawlStatus, searchParams.fromDate, searchParams.toDate);
    }
    else {
      props.fetchRSSTableData();
    }
  };

  return (
    <div>
      <Card>
        <CardBody>
          <Row>
            <div className="col-sm-8">
              <label className="fw-bold">Site Name</label>
              <AsyncSelect
                cacheOptions
                loadOptions={onAsyncSelectInputChangeHandler}
                defaultOptions={siteNameOptions}
                name='site_name'
                value={siteName}
                onChange={(name) => setSiteName(name)}
                placeholder="Select a site name"
              />
              <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
            </div>
            <Col sm="4">
              <label className="fw-bold">Rss Crawl Status</label>
              <Select
                value={rssCrawlStatus}
                options={rssCrawlStatusOptions}
                onChange={option => setRssCrawlStatus(option)} />
            </Col>
          </Row>
          <Row className="align-items-end mt-2">
            <Col sm="4">
              <label className="fw-bold">From Date</label>
              <DatePicker
                className="form-control"
                selected={new Date(fromDate)}
                dateFormat={"YYY/MM/dd"}
                maxDate={new Date()}
                onChange={date => setFromDate(date)} />
            </Col>
            <Col sm="4">
              <label className="fw-bold">To Date</label>
              <DatePicker
                className="form-control"
                selected={new Date(toDate)}
                dateFormat={"YYY/MM/dd"}
                maxDate={new Date()}
                onChange={date => setToDate(date)} />
            </Col>
            <Col sm="4">
              <button
                type='button'
                className="btn btn-success w-100"
                onClick={onGetDataButtonClickHandler}>Get Data
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  )
}
