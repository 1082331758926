import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { Formik, Form, Field, useFormikContext, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Custom DatePicker component for Formik
const FormikDatePicker = ({ field, form, ...props }) => {
  const { setFieldValue } = form;
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={(val) => setFieldValue(field.name, val)}
      className="form-control"
    />
  );
};

const ScheduleCrawls = forwardRef(({ orgName, sitegroupNames, siteNames }, ref) => {
  const [modal, setModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const today = new Date(Date.now());

  const is_org_body_cupid = orgName === "bodycupid";

  const toggle = () => {
    setModal(curr => !curr);
  };

  useImperativeHandle(ref, () => ({
    handleRowChange(selectedRows) {
      setSelectedRows(selectedRows);
    }
  }));

  const initialValues = {
    date: today,
    siteOption: 'all'
  };

  function getFormattedDate(date) {
      var year = date.getFullYear();
  
      var month = (1 + date.getMonth()).toString();
      month = month.length > 1 ? month : '0' + month;
  
      var day = date.getDate().toString();
      day = day.length > 1 ? day : '0' + day;
  
      return month + '/' + day + '/' + year;
    }

  const validationSchema = Yup.object({
    date: Yup.date().required('Date is required'),
    siteOption: Yup.string().required('Site option is required')
  });

  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    const { date, siteOption } = values;

    let selectedSiteIDs = [];
    selectedRows.forEach(each => {
      selectedSiteIDs.push(each.id);
    });

    const payload = {
      schedule: {
        date: getFormattedDate(date),
        site_options: siteOption,
        selected_sites: selectedSiteIDs.toString(),
        crawl_type: 'full_crawl'  // or 'test_crawl' based on the button clicked
      },
      page_type: "client",
      controller_name: "crawl_schedules"
    };

    axios.post(`/api/v1/organizations/${orgName}/sites/crawl_schedules`, payload)
      .then(res => {
        toast.success(res.data.message);
        if (!is_org_body_cupid) {
          setModalInfo(res?.data?.crawl_schedule_today);
        }
        setModal(false);
        setLoading(false);
        setSubmitting(false);
      })
      .catch(err => {
        toast.error(err?.response?.data?.message, { autoClose: false });
        setLoading(false);
        setSubmitting(false);
      });
  };

  const toggleModalInfo = () => {
    setModalInfo(curr => !curr);
  };

  const closeBtnModalInfo = <button className="close" onClick={() => setModalInfo(false)}> &times; </button>;

  const closeBtn = <button className="close" onClick={() => setModal(false)}> &times; </button>;

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop='static' size='lg'>
        <ModalHeader toggle={toggle} close={closeBtn}>
          {is_org_body_cupid ? "Initiate Recrawl" : "Schedule Crawls"}
        </ModalHeader>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue, errors, values }) => {
            return(
            <Form>
              <ModalBody className='p-5'>
                <div>
                  <div className='my-3 d-flex align-center'>
                    <h6 className='me-2 fw-bold'>Sites:</h6>
                    <Field as="select" name="siteOption" className='form-control show-arrow'>
                      <option value="selected_sites" disabled={selectedRows.length === 0}>
                        Selected sites
                      </option>
                      <option value='all'>All options</option>
                      {is_org_body_cupid ? siteNames.map(each => (
                        <option key={each[0]} value={each[0]}>
                          {each[1]}
                        </option>
                      )) : sitegroupNames.map(each => (
                        <option key={each[0]} value={each[1]}>
                          Active sites of {each[1]}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="siteOption" component="p" className="m-0 error-msg mt-1 mb-2" />
                  </div>
                  {!is_org_body_cupid && (
                    <div className='mb-5 mt-2 d-flex align-center'>
                      <h6 className='me-2 fw-bold'>Date:</h6>
                      <div className="d-flex flex-column w-100">
                        <Field name="date" component={FormikDatePicker} minDate={today} />
                        <ErrorMessage name="date" component="p" className="m-0 error-msg mt-1 mb-2" />
                      </div>
                    </div>
                  )}
                  <p>
                    <b>NOTE:</b> These crawls will be performed only if it does not clash with already running crawl
                  </p>
                </div>
              </ModalBody>
              <ModalFooter>
                {loading ? (
                  <>
                    <Button className='primary mx-2' disabled>
                      Loading ...
                    </Button>
                    <Button className='success mx-2' disabled>
                      Loading ...
                    </Button>
                  </>
                ) : (
                  <>
                    <Button color="primary" className='mx-2' type="submit" onClick={() => setFieldValue('crawlType', 'test_crawl')}>
                      {is_org_body_cupid ? "Initiate a Test Run" : "Schedule a Test Run"}
                    </Button>
                    <Button color="success" className='mx-2' type="submit" onClick={() => setFieldValue('crawlType', 'full_crawl')}>
                      {is_org_body_cupid ? "Initiate a Complete Recrawl" : "Schedule a Complete Recrawl"}
                    </Button>
                  </>
                )}
              </ModalFooter>
            </Form>
          )}}
        </Formik>
      </Modal>
      <Button className='mx-2' color="primary" onClick={toggle}>
        {is_org_body_cupid ? "Initiate Recrawl" : "Schedule Crawls"}
      </Button>

      <Modal isOpen={modalInfo} toggle={toggleModalInfo} backdrop='static' size='lg'>
        <ModalHeader toggle={toggleModalInfo} close={closeBtnModalInfo}>
          Thank you for scheduling the crawl
        </ModalHeader>
        <ModalBody className='p-5'>
          <div>
            <h6 className='me-2 fw-bold'>Please note:</h6>
            <div className='my-3 d-flex align-center'>
              <ul>
                <li>1. Our process will initiate the requested crawl in the next 1-2 hours.</li>
                <li>2. The actual crawls may take a few hours to run and complete (based on the expected data volume).</li>
                <li>3. The crawls run in smaller batches and data shall For uploaded to the API as soon as the first batch is processed.</li>
                <li>4. You may enable Crawl Notifications <a href={`/organizations/${orgName}/sites`} >here</a> separately to get notified of crawl start and end.</li>
              </ul>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='light' onClick={() => setModalInfo(false)}> Close </Button>
        </ModalFooter>
      </Modal>
    </>
  );
});

export default ScheduleCrawls;
