import React, { useState, useEffect, useCallback } from 'react'
import { CardBody, Col, Card, Container, Row, Badge, Button, CardHeader } from 'reactstrap';
import Breadcrumb from '../../global/Breadcrumb'
import axios from 'axios'
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component'
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom'

export default function AllCoupons() {
    let history = useHistory()
    const [coupons, setCoupons] = useState([]);
    const [couponsData, setCouponsData] = useState([]);
    const [page, setPage] = useState(1);
    const [searchVal, setSearchVal] = useState('');
    const countPerPage = 20;
    const [pageTotal, setPageTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [sortString, setSortString] = useState('');
    const [toggle, setToggle] = useState(true);
    const [globalData, setGlobalData] = useState([]);
    const [couponAuthorized, setCouponAuthorized] = useState(false);

    useEffect(() => {
        setLoading(true)
        axios.get(`/api/v2/admins/coupons?page=${page}&filterrific[sorted_by]=${sortString}&filterrific[search_value]=${searchVal}`)
            .then(res => {
                setCoupons(res.data?.coupons)
                setGlobalData(res.data?.coupons)
                setCouponAuthorized(res.data?.coupon_authorized)
                setPageTotal(res.data?.total_coupons)
                setLoading(false)
            }).catch(err => {
                if (err.response.status == 401) {
                    history.push(`/`)
                }
                toast.error(err?.response?.data?.message)
                setLoading(false)
            })
    }, [toggle, sortString, searchVal]);

    // useEffect(() => {
    //     let temp = [...globalData]
    //     let result = []
    //     temp.forEach(each => {
    //         if (each.coupon_code.includes(searchVal) ||
    //             each.organizations.includes(searchVal) ||
    //             each.products.includes(searchVal) ||
    //             each.status.includes(searchVal)) {
    //             result.push(each)
    //         }
    //     })
    //     setCoupons(result)
    // }, [searchVal]);
     

    const customSort = (rows, selector, direction) => {
        if (selector !== null) {
            setSortString(`${selector}_${direction}`)
        }
        return rows
    }

    const columns = [
        { name: 'ID', selector: 'id', sortable: true, center: true },
        { name: 'Code', selector: 'coupon_code', sortable: true, center: true },
        { name: 'Organizations', selector: 'organizations', center: true},
        { name: 'Products', selector: 'products', center: true, sortable: true },
        { name: 'Start At', selector: 'start_at', center: true, sortable: true },
        { name: 'Expire At', selector: 'expire_at', center: true, sortable: true },
        { name: 'Status', selector: 'status', center: true },
        { name: 'Actions', selector: 'actions', center: true },
    ]

    const colorsData = {
        active: 'success',
        paused: 'danger',
        draft: 'warning'
    }

    const handlePageChange = page => {
        setPage(page)
        axios.get(`/api/v2/admins/coupons?page=${page}`)
            .then(res => {
                setCoupons(res.data?.coupons)
                setLoading(false)
            }).catch(err => {
                toast.error(err?.response?.data?.message)
                setLoading(false)
            })
    }

    function debounce(func) {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 650);
        }
    }''

    function onSearchHandler(event) {
        const {value} = event.target;
        setSearchVal(value);
    }

    const optimizedSerachHandler = useCallback(debounce(onSearchHandler), []);
    console.log(searchVal);

    useEffect(() => {
        let tempArr = []
        coupons.forEach(each => {
            tempArr.push({
                start_at: each?.start_at,
                id: each?.id,
                coupon_code: each?.coupon_code,
                organizations: each?.organizations,
                products: each?.products,
                expire_at: each?.expire_at,
                status: <Badge color={colorsData[each.status?.toLowerCase()]}>{each.status}</Badge>,
                actions: <div>
                    {each.status == 'paused' && (
                        <>
                            {(new Date(each.expire_at) > new Date(Date.now())) && <Button color='success' className='m-1' size='xs' onClick={() => activateCoupon(each?.id)}>
                                Activate
                            </Button>}
                            <a href={`/admins/coupons/${each.id}/edit`} className='btn btn-xs btn-primary m-1'>Edit</a>
                        </>
                    )}
                    {each.status == 'draft' && (
                        <>
                            <Button color='success' className='m-1' size='xs' onClick={() => activateCoupon(each?.id)}>
                                Activate
                            </Button>
                            <a href={`/admins/coupons/${each.id}/edit`} className='btn btn-xs btn-primary m-1'>Edit</a>
                        </>
                    )}
                    {each.status == 'active' && (
                        <>
                            <Button color='warning' size='xs' className='m-1' onClick={() => pauseCoupon(each?.id)}>
                                Pause
                            </Button>
                            <a href={`/admins/coupons/${each.id}/edit`} className='btn btn-xs btn-primary m-1'>Edit</a>
                        </>
                    )}
                </div>
            })
        })
        setCouponsData(tempArr)
    }, [coupons]);

    const activateCoupon = id => {
        axios.put(`/api/v2/admins/coupons/${id}`, {coupon: {coupon_status: "active"}}).then(res => {
            toast.success(res.data?.message)
            setToggle(curr => !curr)
        }).catch(err => {
            toast.error(err.response?.data?.message)
        })
    }

    const pauseCoupon = id => {
        axios.put(`/api/v2/admins/coupons/${id}`, {coupon: {coupon_status: "paused"}}).then(res => {
            toast.success(res.data?.message)
            setToggle(curr => !curr)
        }).catch(err => {
            toast.error(err.response?.data?.message)
        })
    }

    // console.log(sortString);

    return (
        <Container fluid>
            <Breadcrumb title={"Coupons"} parent={"Discount Coupons"} />

            <Card>
                <CardHeader>
                    <Row>

                        <Col md={5}>
                            <div className='d-flex align-items-center'>
                                <h6 className='my-0 mx-2'>Search:</h6>
                                <input type="text" name="search_coupon" onChange={optimizedSerachHandler}
                                    className='form-control' />
                            </div>
                        </Col>
                        <Col md={5}>

                        </Col>
                        {couponAuthorized ? <Col md={2}>
                            <a href='/admins/coupons/new' className='btn btn-primary btn-sm float-end white-space-no-wrap'>
                                + Create Coupon
                            </a>
                        </Col> : ''
                        }
                    </Row>
                </CardHeader>
                <CardBody>
                    {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
                        :
                        <DataTable
                            columns={couponAuthorized ? columns : columns.map(sh => { if (sh.name != 'Actions') return sh }).filter(sh => sh !== undefined)}
                            data={couponsData}
                            highlightOnHover
                            onChangePage={handlePageChange}
                            pagination
                            paginationServer
                            paginationTotalRows={pageTotal}
                            paginationPerPage={10}
                            paginationComponentOptions={{ noRowsPerPage: true }}
                            progressPending={loading}
                            sortFunction={customSort}
                            striped
                        />}
                </CardBody>
            </Card>
        </Container>
    );
}
