import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import { Home } from "react-feather";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import { Container, Row, Col, BreadcrumbItem, Breadcrumb, Card, CardBody, Nav, NavItem, NavLink, TabPane, TabContent } from "reactstrap";

export default function KilledAndFailedStatsGraphs() {
    const search = useLocation().search;
    const commit = new URLSearchParams(search).get("commit");
    let searchParams = {
        siteName: new URLSearchParams(search).get("filter[site_name]"),
        fromDate: new URLSearchParams(search).get("filter[from]"),
        toDate: new URLSearchParams(search).get("filter[to]"),
        geoCluster: new URLSearchParams(search).get("filter[geo_cluster]"),
        jobType: new URLSearchParams(search).get("filter[job_type]"),
        exitType: new URLSearchParams(search).get("filter[exit_type]") || '*',
        tabName: new URLSearchParams(search).get("filter[tab_name]")


    }
    const [siteName, setSiteName] = useState({ label: 'All', value: '*' })
    const [siteNameOptions, setSiteNameOptions] = useState([]);
    const now = new Date();
    const [fromDate, setFromDate] = useState(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 2));
    const [toDate, setToDate] = useState(now)
    const [geoCluster, setGeoCluster] = useState({ label: 'All', value: '*' })
    const [jobType, setJobType] = useState({ label: 'All', value: '*' })
    const [exitType, setExitType] = useState({ label: 'All', value: '*' })
    const [geoClusterOptions, setGeoClusterOptions] = useState([])
    const [activeTab, setActiveTab] = useState('failed_stats_graph_by_site_wise');
    const [failedSiteNames, setFailedSiteNames] = useState([]);
    const [failedData, setFailedData] = useState([]);
    const [killedSiteNames, setKilledSiteNames] = useState([]);
    const [killedData, setKilledData] = useState([])
    const [killedDateWise, setKilledDateWise] = useState([])
    const [dateRange, setDateRange] = useState([])
    const [failedDateWise, setFailedDateWise] = useState([])
    const jobTypeOptions = [
        { label: 'All', value: '*' },
        { label: 'Recrawl', value: 'recrawl' },
        { label: 'Distributed Recrawl', value: 'distributed_recrawl' },
        { label: 'Feedcrawl', value: 'feedcrawl3' },
        { label: 'Dependent Data Upload', value: 'dependent_data_upload' },
    ]
    const exitTypeOptions = [
        { label: 'All', value: '*' },
        { label: 'Killed', value: 'killed' },
        { label: 'Failed', value: 'failed' }
    ]
    const getOptions = (status) => ({

        scales: {
            yAxes: {
                title: {
                    display: true,
                    text: `Job Count: (Exit Type: ${status})`,
                    font: {
                        size: 15
                    }
                },

            },

            xAxes: {
                title: {
                    display: true,
                    text: `Site Name`,

                },

            },
        }

    })

    useEffect(() => {
        setSearchParamsData()
        fetchSiteNames();
    }, [])

    useEffect(() => {
        console.log(searchParams.exitType, 'exit Type', searchParams)
    })

    function setSearchParamsData() {
        console.log(searchParams, 'params')
        if (searchParams.siteName && searchParams.siteName != siteName.value) {
            setSiteName({ label: searchParams.siteName == '*' ? 'All' : searchParams.siteName, value: searchParams.siteName })
        }
        if (searchParams.fromDate && searchParams.fromDate != fromDate) {
            setFromDate(new Date(searchParams.fromDate));
        }
        if (searchParams.toDate && searchParams.toDate != toDate) {
            setToDate(new Date(searchParams.toDate));
        }
        if (searchParams.tabName && searchParams.tabName != activeTab) {
            setActiveTab(searchParams.tabName);
        }
        if (searchParams.geoCluster && searchParams.geoCluster != geoCluster.value) {
            console.log('geoTypeupdated', geoClusterOptions.filter(item => item.value == searchParams.geoCluster))

            setGeoCluster({ label: searchParams.geoCluster, value: searchParams.geoCluster })
        }
        if (searchParams.jobType && searchParams.jobType != jobType.value) {
            console.log('jobTypeupdated')

            setJobType(jobTypeOptions.filter(item => item.value == searchParams.jobType)[0])
        }
        if (searchParams.exitType && searchParams.exitType != exitType?.value) {
            console.log('exitTypeupdated', exitTypeOptions.filter(item => item.value == searchParams.jobType))
            setExitType(exitTypeOptions.filter(item => item.value == searchParams.exitType)[0])
        }


        fetchData(searchParams.siteName, searchParams.fromDate, searchParams.toDate, searchParams.geoCluster, searchParams.jobType, searchParams.exitType)


    }

    function getTitle() {
        if (activeTab == '1') {
            return 'Failed Stats Graph By Site Wise'
        }
        else if (activeTab == '2') {
            return 'Killed Stats Graph By Site Wise'
        }
        else {
            return 'Killed And Failed Stats Graph By Date Wise'
        }
    }

    const fetchData = (SiteName = siteName.value, FromDate = fromDate, ToDate = toDate, GeoCluster = geoCluster.value, JobType = jobType.value, ExitType = exitType?.value) => {
        axios.get(`/api/v1/internal/resque_info/killed_and_failed_stats?filter[site_name]=${SiteName}&filter[from_date]=${moment(FromDate).format('YYYY/MM/DD')}&filter[to_date]=${moment(ToDate).format('YYYY/MM/DD')}&filter[geo_cluster]=${GeoCluster}&filter[job_type]=${JobType}&filter[exit_type]=${ExitType}`)
            .then(res => {
                setFailedSiteNames(res.data.failedSiteNames);
                setFailedData(res.data.failed_data);
                setKilledSiteNames(res.data.killed_site_names)
                setKilledData(res.data.killed_data)
                setKilledDateWise(res.data.killed_data_by_date_wise)
                setFailedDateWise(res.data.failed_data_by_date_wise)

                setDateRange(res.data.date_range)
            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong');
            })
    }

    function fetchSiteNames() {
        axios.get('/api/v1/internal/dropdown_list_helper')
            .then(res => {
                let site_names = res.data.site_names, site_names_options = []
                for (var key in site_names) {
                    let group = {}, children = []
                    group['label'] = <div className="fw-bold col text-black">{key}</div>
                    site_names[key].map(item => {
                        children.push({ label: item, value: item })
                    })
                    group['options'] = children;
                    site_names_options.push(group)
                }
                site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: '*' }] })
                setSiteNameOptions(site_names_options)
                setGeoClusterOptions([...res.data.geo_clusters.map(item => ({ label: item, value: item })), { label: 'All', value: '*' }])
            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong')
            })
    }

    useEffect(() => {
        console.log(geoClusterOptions)
    })

    const promiseOptions = (inputValue) => {
        if (inputValue.length < 3) {
            return null
        } else {
            return new Promise((resolve) => {
                axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
                    if (res.status == '200') {
                        let site_names = res.data.site_names, site_names_options = []
                        for (var key in site_names) {
                            let group = {}, children = []
                            group['label'] = <div className="fw-bold col text-black">{key}</div>
                            site_names[key].map(item => {
                                children.push({ label: item, value: item })
                            })
                            group['options'] = children;
                            site_names_options.push(group)
                        }
                        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                        setSiteNameOptions(site_names_options)
                        resolve(site_names_options)
                    }
                }).catch(err => {
                    console.log(`err`, err)
                })
            })
        }
    }

    const handleGetData = () => {
        window.location.href = `/internal/resque_info/killed_and_failed_stats?filter[site_name]=${siteName.value}&filter[from]=${moment(fromDate).format('YYYY/MM/DD')}&filter[to]=${moment(toDate).format('YYYY/MM/DD')}&filter[geo_cluster]=${geoCluster.value}&filter[job_type]=${jobType.value}&filter[exit_type]=${exitType?.value}&commit=${'Get Data'}`
    }

    return <Container fluid>
        <div className="page-title">
            <Row className=''>
                <Col xs="6">
                    <h3>Internal Dashboard</h3>
                </Col>
                <Col xs="6">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={'/interanal'}>
                                <Home />
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            {commit == 'Get Data' ? <a href={'/internal/resque_info/killed_and_failed_stats'}>Killed And Failed Stats Graphs</a> : 'Killed And Failed Stats Graphs'}
                        </BreadcrumbItem>
                        {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                            Search Results
                        </BreadcrumbItem>}
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
        <Card>
            <CardBody>
                <Row>
                    <div className="col-sm-8">
                        <label className="fw-bold">Site Name</label>
                        <AsyncSelect cacheOptions defaultOptions={siteNameOptions} name='site_name' value={siteName}
                            loadOptions={promiseOptions} onChange={(data) => {
                                setSiteName(data)
                            }}
                            placeholder="Select a site name"
                        />
                        <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
                    </div>
                </Row>
                <Row>
                    <Col sm="4" className="mt-2">
                        <label className="fw-bold">From Date</label>
                        <DatePicker
                            className="form-control"
                            selected={new Date(fromDate)}
                            dateFormat={"YYY/MM/dd"}
                            maxDate={now}

                            onChange={date => setFromDate(date)} />
                    </Col>
                    <Col sm="4">
                        <label className="fw-bold">To Date</label>
                        <DatePicker
                            className="form-control"
                            selected={new Date(toDate)}
                            maxDate={now}
                            dateFormat={"YYY/MM/dd"}
                            onChange={date => setToDate(date)} />
                    </Col>
                    <Col sm="4">
                        <label className="fw-bold">Geo Cluster</label>
                        <Select
                            value={geoCluster}
                            options={geoClusterOptions}
                            onChange={option => setGeoCluster(option)} />
                    </Col>
                </Row>
                <Row className="align-items-end mt-3">
                    <Col sm="4">
                        <label className="fw-bold">Job Type</label>
                        <Select
                            value={jobType}
                            options={jobTypeOptions}
                            onChange={option => setJobType(option)} />
                    </Col>
                    <Col sm="4">
                        <label className="fw-bold">Exit Type</label>
                        <Select
                            value={exitType}
                            options={exitTypeOptions}
                            onChange={option => setExitType(option)} />
                    </Col>
                    <Col sm="4">
                        <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
        <Card>
            <CardBody>
                <p><b>NOTE:</b> This data is coming from Back-end <span data-bs-toggle="tooltip" data-bs-placement="right" title="GenerateJobStatsForApp.new.get_jobs_stats()"> (Method <i className='fa fa-info-circle' aria-hidden='true'></i>)</span></p>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={`fw-bold ${activeTab == 'failed_stats_graph_by_site_wise' ? 'active' : ''}`} onClick={() => setActiveTab('failed_stats_graph_by_site_wise')}>
                            Failed Stats Graph By Site Wise
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={`fw-bold ${activeTab == 'killed_stats_graph_by_site_wise' ? 'active' : ''}`} onClick={() => setActiveTab('killed_stats_graph_by_site_wise')}>
                            Killed Stats Graph By Site Wise
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink className={`fw-bold ${activeTab == 'killed_and_failed_stats_graph_by_date_wise' ? 'active' : ''}`} onClick={() => setActiveTab('killed_and_failed_stats_graph_by_date_wise')}>
                            Killed and  Failed Stats Graph By Date Wise
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={'failed_stats_graph_by_site_wise'}>
                        {(searchParams.exitType == 'failed' || searchParams.exitType == '*') ? <Bar
                            options={{
                                ...getOptions(), plugins: {
                                    title: {
                                        display: true,
                                        text: 'Failed Stats Graph By Site Wise'
                                    },
                                    legend: {
                                        display: failedData.length != 0
                                    }
                                },
                            }}
                            data={{
                                labels: failedSiteNames || [''],
                                datasets: [
                                    {
                                        data: failedData
                                    }
                                ]
                            }} /> : <center>You have chosen the exit type is Killed</center>}
                    </TabPane>
                    <TabPane tabId={'killed_stats_graph_by_site_wise'}>
                        {(searchParams.exitType == 'killed' || searchParams.exitType == '*') ? <Bar
                            options={{
                                ...getOptions(), plugins: {
                                    title: {
                                        display: true,
                                        text: 'Killed Stats Graph By Site Wise'
                                    },
                                    legend: {
                                        display: killedData.length != 0
                                    }
                                },
                            }}
                            data={{
                                labels: killedSiteNames,
                                datasets: [
                                    {
                                        data: killedData
                                    }
                                ]
                            }} /> : <center>You have chosen the exit type is Failed</center>}
                    </TabPane>
                    <TabPane tabId={'killed_and_failed_stats_graph_by_date_wise'}>
                        <Bar
                            options={{
                                ...getOptions(), plugins: {
                                    title: {
                                        display: true,
                                        text: 'Killed And Failed Stats Graph By Date Wise'
                                    },
                                    legend: {
                                        display: failedDateWise.length != 0
                                    }
                                },
                                scales: {
                                    yAxes: {
                                        title: {
                                            display: true,
                                            text: `Job Count: (Exit Type: ${status})`,
                                            font: {
                                                size: 15
                                            }
                                        },

                                    },
                                    xAxes: {
                                        title: {
                                            display: true,
                                            text: ``,

                                        },

                                    },

                                }
                            }}
                            data={{
                                labels: dateRange,
                                datasets: [
                                    {
                                        data: failedDateWise
                                    }
                                ]

                            }} />
                        <Bar
                            options={{
                                ...getOptions('Killed'), plugins: {
                                    title: {
                                        display: true,
                                        text: 'Killed or Failed on Date',
                                    },
                                    legend: {
                                        display: killedDateWise.length != 0
                                    },
                                    scales: {
                                        yAxes: {
                                            title: {
                                                display: true,
                                                text: `Job Count: (Exit Type: ${status})`,
                                                font: {
                                                    size: 15
                                                }
                                            },

                                        },



                                        xAxes: {
                                            title: {
                                                display: true,
                                                text: ``,

                                            },

                                        },

                                    }
                                },

                            }}
                            data={{
                                labels: dateRange,
                                datasets: [
                                    {
                                        data: killedDateWise
                                    }
                                ]

                            }} />
                    </TabPane>
                </TabContent>
            </CardBody>
        </Card>
    </Container>
}