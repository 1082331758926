/*
    This Data Table component is rendered by Top20RSSCount component
*/
import React from 'react';
import { useMemo } from 'react';
import DataTable from 'react-data-table-component';
export default function RSSCountTable({ data, progressPending }) {
    // Table column definitions
    const columns = useMemo(()=>[
        { name: 'Site', selector: 'site_name', sortable: true },
        { name: 'Records', selector: 'records_count', sortable: true },
    ], []);

    return (
        <>
            <DataTable
                data={data}
                columns={columns}
                noHeader
                pagination
                progressPending={progressPending}
            />
        </>
    )
}
