import React from 'react';
import { useState, useEffect, useContext} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import SpinnerLoader from '../../Common/LoadingAnimation/SpinnerLoader.component';
import Breadcrumbs from '../../Common/BreadCrumb/BreadCrumbs.component';
import BannerNotifications from '../../Common/BannerNotification/BannerNotifications.component';
import ClientFeasibilityReportsTable from './ClientFeasibilityReportsTable/ClientFeasibilityReportsTable.component';
import axios from 'axios';
import { toast } from 'react-toastify';
import { OrgnaziationContext } from '../../../Home';

export default function FeasibilityReports({ banner_notifications, environment }) {

  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const { organization_name } = useParams();

  const [data, setData] = useState([]);

  const [searchString, setSearchString] = useState("");

  const [filteredData, setFilteredData] = useState([]);

  const {setOrgName} = useContext(OrgnaziationContext)
  useEffect(() => {
    setOrgName(organization_name)
  }, [organization_name])

  // use Effect hook for mounting
  useEffect(async () => {
    if (organization_name) {
      await fetchFeasibilityReportsForClient(organization_name);
    }
    return;
  }, [organization_name]);


  // Use effect to populate the table with data filtered using the sitegroug name
  useEffect(() => {
    let searchedData = data.filter((report) => {
      return report.sitegroup_name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase());
    });
    setFilteredData(searchedData);
  }, [searchString]);


  // Helper function for searching data in data table
  function onFeasibilityReportsSearchHandler(event) {
    const { value } = event.target;
    setSearchString(value)
  }


  // Helper function to fetch feasibility reports for clients from the back-end DB
  async function fetchFeasibilityReportsForClient(organization) {
    setLoading(true);
    try {
      const response = await axios.get(`/api/v1/organizations/${organization}/feasibility_reports?page_type=client&controller_name=feasibility_reports`);
      const feasibility_reports = await response?.data?.feasibility_reports;
      console.log(feasibility_reports);
      setData(feasibility_reports);
      setLoading(false)
    }
    catch (error) {
      // If client tries to access the client page, the back-end sends a 401
      // If that happens show client a toast regarding the same 
      if(error?.response?.status === 401){
        toast.error("You are not authorized to access this page!");
        setLoading(false);
        history.push("/");
      }
      // For everything else show the responses
      else{
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };





  return (
    <>
      <SpinnerLoader loading={loading}>
        <Breadcrumbs title={"Feasibility Reports"} parent={"Feasibility Reports"} />
        <div className='container container-fluid'>
          <BannerNotifications notificationsArray={banner_notifications} />
          <ClientFeasibilityReportsTable
            tableData={filteredData.length !== 0 ? filteredData : data}
            onSearch={onFeasibilityReportsSearchHandler}
            environment={environment} />
        </div>
      </SpinnerLoader>
    </>
  )
}

