import React, {useState, useEffect, Fragment} from 'react';
import { Card, CardBody, Col, Container, Row, Breadcrumb, BreadcrumbItem, Nav, Table, Alert,
  NavItem, NavLink, TabContent, TabPane, CardHeader } from 'reactstrap';
import Select from 'react-select';
import JSONPretty from 'react-json-pretty';
import Loader from 'react-loader-spinner';
import DatePicker from 'react-datepicker'
import axios from 'axios'
import { toast } from 'react-toastify';
import MyAccordion from '../../../../global/MyAccordion'
import { useParams, useLocation, Link } from 'react-router-dom';
import moment from "moment";

export default function AggregateQa({downloadDoc, activeTabName}) {
  let { sitename } = useParams();
  const search = useLocation().search
  const selectedPushID = new URLSearchParams(search).get('filter[rss_crawl_push_id]')
  const selectedCrawlDate = new URLSearchParams(search).get('filter[crawldate]')
  const tabName = new URLSearchParams(search).get('tab_name')
  const commitValue = new URLSearchParams(search).get('commit')

  const [activeTab, setActiveTab] = useState(tabName? tabName : "agg_qa_file")
  const [crawlIDs, setCrawlIDs] = useState([])
  const [aggregateChecked, setAggregateChecked] = useState('rsscrawl')    
  const [toggle, setToggle] = useState(false)
  const [loading, setLoading] = useState(true)
  const [exampleData, setExampleData] = useState({})
  const [selectedDate, setSelectedDate] = useState()
  const [selectedCrawlID, setSelectedCrawlID] = useState({})
  const [aggrgateQAData, setAggrgateQAData] = useState({})
  const today = new Date(Date.now())
  const [download, setDownload] = useState(false)

  useEffect(() => {
    if (activeTabName == "aggregate_level_qa") {
      setLoading(true)
      axios.get(`/api/v2/internal/qa/aggregate_level_qa?site_name=${sitename}`).then(res => {
        setCrawlIDs(res?.data?.rss_crawl_push_ids)
        setLoading(false)
      }).catch(err => {
        setLoading(false)
      })
    }
  }, [activeTabName])

  const handleRadioButton = (e, option) => {
    if (e.target.checked){
      setAggregateChecked(option)
    }
  }

  const transformOptions = arr => {
    arr = arr.map(each => ({
      value: each, label: each
    }))
    return arr
  }

  const handleAggregateDownload = () => {
    setDownload(true)
    let url = ''
    if (selectedCrawlDate){
      url = `/api/v2/internal/qa/${sitename}/download_aggregate_qa_file?filter[crawldate]=${selectedCrawlDate}`
    }
    else if (selectedPushID){
      url = `/api/v2/internal/qa/${sitename}/download_aggregate_qa_file?filter[rss_crawl_push_id]=${selectedPushID}`
    }
    else if (aggregateChecked == 'rsscrawl'){
      url = `/api/v2/internal/qa/${sitename}/download_aggregate_qa_file?filter[rss_crawl_push_id]=${selectedCrawlID?.value}`
    }
    else{
      url = `/api/v2/internal/qa/${sitename}/download_aggregate_qa_file?filter[crawldate]=${selectedDate.toISOString().split('T')[0].replaceAll('-', '/')}`
    }

    axios.get(url).then(res => {
      downloadDoc(JSON.stringify(res.data), `download_${sitename}.json`, "text/json")
      setDownload(false)
    }).catch(err => {
      toast.error(err.response.data?.message)
      setDownload(false)
    })
  }

  const fetchDataByCrawlID = (option = true) => {
    setLoading(true)
    if (option){
      axios.get(`/api/v2/internal/qa/aggregate_level_qa?site_name=${sitename}&filter[rss_crawl_push_id]=${selectedCrawlID?.value}`).then(res => {
        console.log(res.data)
        setAggrgateQAData(res.data)
        setExampleData(JSON.parse(res?.data?.aggregate_qa_file?.examples_for_missing_fields))
        setLoading(false)
      }).catch(err => {
        setLoading(false)
      })
    } else {
      axios.get(`/api/v2/internal/qa/aggregate_level_qa?site_name=${sitename}&filter[rss_crawl_push_id]=${selectedPushID}`).then(res => {
        console.log(res.data)
        setAggrgateQAData(res.data)
        setExampleData(JSON.parse(res?.data?.aggregate_qa_file?.examples_for_missing_fields))
        setLoading(false)
      }).catch(err => {
        setLoading(false)
      })
    }
  }

  const fetchDataByCrawlDate = (option = true) => {
    setLoading(true)
    if (option){
      axios.get(`/api/v2/internal/qa/aggregate_level_qa?site_name=${sitename}&filter[crawldate]=${moment(selectedDate).format('YYYY/MM/DD')}`).then(res => {
        console.log(res.data)
        setAggrgateQAData(res.data)
        setExampleData(JSON.parse(res?.data?.aggregate_qa_file?.examples_for_missing_fields))
        setLoading(false)
      }).catch(err => {
        setLoading(false)
      })
    } else {
      axios.get(`/api/v2/internal/qa/aggregate_level_qa?site_name=${sitename}&filter[crawldate]=${moment(selectedCrawlDate).format('YYYY/MM/DD')}`).then(res => {
        console.log(res.data)
        setAggrgateQAData(res.data)
        setExampleData(JSON.parse(res?.data?.aggregate_qa_file?.examples_for_missing_fields))
        setLoading(false)
      }).catch(err => {
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    console.log('selectedPushID, selectedCrawlDate, tabName, commitValue', 
      selectedPushID, selectedCrawlDate, tabName, commitValue)
    if (selectedPushID){
      setSelectedCrawlID({label: selectedPushID, value: selectedPushID})
    }
    if (selectedCrawlDate){
      setSelectedDate(new Date(selectedCrawlDate))
    }
    if (commitValue == 'Get Data' && selectedPushID){
      fetchDataByCrawlID(false)
    }
    if (commitValue == 'Get Data' && selectedCrawlDate){
      fetchDataByCrawlDate(false)
    }
  }, [selectedPushID, selectedCrawlDate, tabName, commitValue])


  return (
    <>
      <Card>
        <CardHeader className='p-2'>
          <b>Aggregate Level Quality Analysis</b>
        </CardHeader>
        <CardBody>
          {
            loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
              :
              <Row>
                <div className="form-check">
                  <input className="form-check-input" type="radio" checked={aggregateChecked == 'rsscrawl'}
                    name="flexRadioDefault" id="flexRadioDefault1"
                    onChange={(e) => handleRadioButton(e, 'rsscrawl')} />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                    <h6 className='fw-bold'>RSS Crawl Push Id</h6>
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" checked={aggregateChecked == 'crawldate' }
                    name="flexRadioDefault" id="flexRadioDefault2"
                    onChange={(e) => handleRadioButton(e, 'crawldate')} />
                  <label className="form-check-label" htmlFor="flexRadioDefault2">
                    <h6 className='fw-bold'>Crawldate</h6>
                  </label>
                </div>

                <div className='my-3'>
                  {
                    aggregateChecked == 'rsscrawl' &&
                    <>
                      <h6 className='fw-bold'>Select Crawl Push ID:</h6>
                      <Select className='form-control my-2' isClearable isSearchable classNamePrefix="select"
                        value={selectedCrawlID} onChange={(option) => setSelectedCrawlID(option)}
                        options={transformOptions(crawlIDs)} />
                      <button className='btn btn-success' onClick={fetchDataByCrawlID}
                        disabled={selectedCrawlID?.label?.toString()?.length > 0 ? false : true}>Get Data</button>
                    </>
                  }
                  {
                    aggregateChecked == 'crawldate' &&
                    <>
                      <h6 className='fw-bold'>Select CrawlDate:</h6>
                      <DatePicker className="form-control my-2" selected={selectedDate}
                        maxDate={today} dateFormat={"YYY/MM/dd"} onChange={(date) => setSelectedDate(date)} />
                      <button className='btn btn-success' onClick={fetchDataByCrawlDate}
                        disabled={selectedDate?.toString()?.length > 0 ? false : true}>Get Data</button>
                    </>
                  }
                </div>
              </Row>
          }
        </CardBody>
      </Card>
      {
        aggrgateQAData?.aggregate_qa_file && 
        <div className='my-4'>
          {
            loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
              : 
              <div> 
                {
                  aggrgateQAData?.aggregate_qa_file?.file_present && (download ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
                    : 
                    <>
                      <h6 className="hidden"><button className='btn text-primary fw-bold' onClick={handleAggregateDownload}> Click here </button> to download the actual file</h6>
                      <h6> <a href={aggrgateQAData?.aggregate_qa_file?.download_link} target="_blank" rel="noopener noreferrer" className='btn fw-bold'> Click here </a>to download the actual file</h6>
                    </>
                  )
                }
                <Card className='my-3'>
                  <CardHeader className='bg-success py-3'>
                    Sample record with max number of fields 
                  </CardHeader>
                  <CardBody>
                    <JSONPretty id="json-pretty" 
                      data={aggrgateQAData?.aggregate_qa_file?.sample_record_with_max_number_of_fields}></JSONPretty>
                  </CardBody>
                </Card>
                <Card className='my-3'>
                  <CardHeader className='bg-warning py-3'>
                    Sample record with min number of fields
                  </CardHeader>
                  <CardBody>
                    <JSONPretty id="json-pretty" data={aggrgateQAData?.aggregate_qa_file?.sample_record_with_min_number_of_fields}></JSONPretty>
                  </CardBody>
                </Card>
                { 
                  aggrgateQAData?.aggregate_qa_file?.file_present && 
                  <Card className='my-3'>
                    <CardHeader className='bg-danger py-3'>
                      Missing  fields
                    </CardHeader>
                    <CardBody>
                      {aggrgateQAData?.aggregate_qa_file?.sample_record_for_missing_field}
                    </CardBody>
                  </Card>
                }
                <Nav tabs className="border-tab">
                  <NavItem>
                    <NavLink className={activeTab === "agg_qa_file" ? "active" : ''} onClick={() => setActiveTab("agg_qa_file")}> 
                      Qa File 
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={activeTab === "agg_missing_fields" ? "active" : ''} onClick={() => setActiveTab("agg_missing_fields")}> 
                      Examples for missing fields 
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId='agg_qa_file'>
                    {
                      aggrgateQAData?.aggregate_qa_file?.file_present == false ? 
                      <Alert color='light'>
                        <div dangerouslySetInnerHTML={{__html: aggrgateQAData?.aggregate_qa_file?.error_message}} />
                      </Alert> 
                        : 
                        <>
                          <Alert color='primary'>
                            <h6 className="hidden"> <button className='btn text-white fw-bold' onClick={handleAggregateDownload}> Click here </button> to download the actual file</h6>
                            <h6> <a href={aggrgateQAData?.aggregate_qa_file?.download_link} target="_blank" rel="noopener noreferrer" className='btn text-white fw-bold'> Click here </a>to download the actual file</h6>
                            <h6>Number of records considered for QA Report: {aggrgateQAData?.aggregate_qa_file?.agg_qa_total_records}</h6>
                          </Alert>

                          <Table className='my-3' bordered>
                            <thead>
                              <tr>
                                {aggrgateQAData?.aggregate_qa_file?.agg_qa_headers_headers?.map((each, index) => (
                                  <th key={index} className='fw-bold'>{each}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {aggrgateQAData?.aggregate_qa_file?.agg_qa_data?.map((each, index) => (
                                <tr key={index}>
                                  <td>{each.key}</td>
                                  <td className={each.values >= 80 ? 'fw-bold bg-success' 
                                    : each.values >= 50 ? 'fw-bold bg-warning text-dark' : 'fw-bold bg-danger' }>
                                    {each.values}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </>
                    }
                  </TabPane>
                  <TabPane tabId='agg_missing_fields'>
                    {
                      aggrgateQAData?.aggregate_qa_file?.file_present == false && 
                      <Alert color='light'>
                        <div dangerouslySetInnerHTML={{__html: aggrgateQAData?.aggregate_qa_file?.error_message}} />
                      </Alert>
                    } 
                    {
                      Object.keys(exampleData).length > 0 &&
                        Object.keys(exampleData).map(each => 
                          (
                            <MyAccordion title={`${each}`} className='my-3'>
                              <p>
                                {JSON.stringify(exampleData[each])}
                              </p>
                            </MyAccordion>
                          )
                        ) 
                      }
                    </TabPane>
                  </TabContent>
                </div> 
                } 
              </div>
      }
    </>
  )
}
