export const fieldNameOptions = [
  { label: "Product Category", value: "product_category" },
  { label: "Product Brand", value: "product_brand" },
  { label: "Product Name", value: "product_name" },
  { label: "Product Price", value: "product_price" },
  { label: "SKU", value: "sku" },
  { label: "Product Description", value: "product_description" },
  { label: "Product Currency", value: "product_currency" },
  { label: "Product Image Url", value: "product_image_url" },
  { label: "Product Model Number", value: "product_model_number" },
  { label: "Product Contents", value: "product_contents" },
  { label: "BSR", value: "bsr" },
]