import React from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { businessAssignees } from '../../../Common/Contacts/BusinessAssignees';
import { onBusinessAssigneeChangeHandler } from '../../../Utils/AdminFeasibilityCheck/getTableData';
export default function NewOrganizationsTable({ data }) {

  const columns = [
    {
      name: 'Organization',
      selector: 'organization_name',
      sortable: true,
      center: true,
    },
    {
      name: 'Country',
      selector: 'country_name',
      sortable: true,
      center: true,
    },
    {
      name: 'User',
      selector: 'user_email',
      sortable: true,
      center: true
    },
    {
      name: 'Created At',
      selector: 'created_at',
      sortable: true,
      center: true
    },
    {
      name: 'Biz Assignee',
      selector: row =>
      (
        <select
          defaultValue={row?.biz_assignee}
          onChange={event => onBusinessAssigneeChangeHandler(event?.target?.value, row?.organization_id)}
          className='form-control show-arrow' 
          name="biz_assignee">
          <option value={null}>Select a Biz Assignee</option>
          {businessAssignees?.map(each => (
            <option value={each?.value} key={each?.id}>{each?.label}</option>
          ))}
        </select>
      ),
      center: true
    },
    {
      name: 'Actions',
      selector: row => (
        <Link to={`/admins/feasibility_checks/${row?.feas_check_id}`} className='btn btn-primary'>Check</Link>
      ),
      center: true
    },

  ];

  return (
    <div>
      <DataTable
        columns={columns}
        data={data}
        responsive
        noHeader />
    </div>
  )
}
