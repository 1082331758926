
import React, {useEffect, useState} from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './Home'
import Users from './users-components/Users';
import {setAuthHeaders} from './axios'
import { ToastContainer } from 'react-toastify'
import { CookiesProvider } from 'react-cookie';
import PrivacyPolicy from './static-components/PrivacyPolicy';
import AcceptInvite from './home-components/profile-pages/AcceptInvite';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Error500 from './Error500';
import Error400 from './Error400';
import InternalDashboardRoutes from './internal-dashboard/InternalDashboardRoutes';
import JPInternalDashboardRoutes from './internal-dashboard/JpInternalDashboardRoutes';

/* Refactored V2 Pages */
import OrganizationNew from './V2/ClientPages/Organization/CreateNewOrganization.component';
import OrganizationNewFormik from './V2/ClientPages/Organization/Formik/CreateNewOrganization.component'


export default function App() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setAuthHeaders(setLoading);
    }, []);

    if (loading){
        return (
            <Loader type="Puff" color="#00BFFF" height={100} width={100} className='pc-loader' />
        )
    }
    
    else{
        return (
            <>
                <CookiesProvider>
                    <ToastContainer />
                    <Router>
                        <Switch>
                            <Route path={`/organizations/:organization_name/user_invites/:invite_id/accept_invite`}
                                component={AcceptInvite} exact />
                            <Route path='/users' component={Users} />
                            <Route path='/internal' component={InternalDashboardRoutes} />
                            <Route path='/jobspikr_v3_internal' component={JPInternalDashboardRoutes} />
                            <Route path='/organizations/new' component={OrganizationNewFormik} exact />
                            <Route path='/privacy_policy' component={PrivacyPolicy} exact />
                            <Route path='/server-error' component={Error500} exact />
                            <Route path='/not-found' component={Error400} exact />
                            <Route path='/' component={Home} />
                        </Switch>
                    </Router>
                    
                </CookiesProvider>
            </>
        )
    }
}
