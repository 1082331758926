/*
    This Data Table is rendered by CustomList cumponent
*/
import React from 'react';
import { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import UpdateCustomList from '../CustomList/UpdateCustomList.component';
export default function CustomListTable({ data, progressPending }) {

    // helper function to delete custom list 
    const deleteCustomList = (list_name) => (e) => {
        e.preventDefault()
        setLoading(true)
        setModal(false)
        axios.get(`/api/v2/internal/high_volume_clients_and_sites/delete_custom_list?filter[list_name]=${list_name}`).then(res => {
            toast.success(res?.data?.message);
            setLoading(false);
            location.reload();
        }).catch(err => {
            setModal(true)
            console.error(err?.message || 'Oops something went wrong');
            setLoading(false);
        })
    };

    // Table column definitions
    const columns = useMemo(() => [
        {
            name: 'List Name',
            selector: 'list_name',
            sortable: true,
            style: {
                "display": "inline-block",
            },
        },
        {
            name: 'Options',
            selector: '',
            sortable: true,
            button: true,
            style: {
                "display": "block",
                "padding": "5px",
                "float": "right",
            },
            cell: (row, index) => (
                <>
                    <UpdateCustomList key={index} listName={row.list_name} />
                    | <button className="btn btn-danger btn-sm"
                        onClick={deleteCustomList(row.list_name)}>Delete</button>
                </>
            )
        },
    ], []);

    // Custom styling options for data table
    const customStyles = {
        rows: {
            style: { 'display': 'block' },
        }
    };

    return (
        <>
            <DataTable
                data={data}
                columns={columns}
                customStyles={customStyles}
                noHeader
                pagination
                progressPending={progressPending}
            />
        </>
    )
}
