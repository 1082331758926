import React from 'react'

export default function Badge({ siteStatus }) {
  return (
    <>
      {
        siteStatus.toLocaleLowerCase() === 'active' ?
          (<h6><span className="badge bg-success">{siteStatus.toLocaleLowerCase()}</span></h6>) :

          siteStatus.toLocaleLowerCase() === 'paused' ?
            (<h6><span className="badge bg-warning">{siteStatus.toLocaleLowerCase()}</span></h6>) :

            siteStatus.toLocaleLowerCase() === 'waiting_on_client' ?
              (<h6><span className="badge bg-info">{siteStatus.toLocaleLowerCase()}</span></h6>) :

              siteStatus.toLocaleLowerCase() === 'queued' ?
                (<h6><span className="badge bg-secondary">{siteStatus.toLocaleLowerCase()}</span></h6>) :

                siteStatus.toLocaleLowerCase() === 'archived' ?
                  (<h6><span className="badge bg-dark">{siteStatus.toLocaleLowerCase()}</span></h6>) :

                    (<h6><span className="badge bg-primary">{siteStatus.toLocaleLowerCase()}</span></h6>) 
      }
    </>
  )
};
