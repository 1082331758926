import React, {useState, useEffect, Fragment} from 'react'
import { Card, CardBody, Col, Container, Nav, NavItem, Row, TabContent, TabPane,
  NavLink, Alert,  Breadcrumb, BreadcrumbItem, Table} from 'reactstrap'
import AsyncSelect from 'react-select/async';
import Loader from 'react-loader-spinner';
import DatePicker from "react-datepicker";
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify';
import { Copy, ExternalLink, Info, Link, Home } from 'react-feather';
import DataTable from 'react-data-table-component';
import ReactTooltip from 'react-tooltip';

import { KIBANA_PLM_URL } from '../Common/KibanaAPIEndpoints/KibanaAPIEndpoints';
import { MysqlInternalBasePoint } from '../Common/BaseEsPoints/InternalEsBasePoint';

export default function InternalDashboardHome() {
  const search = useLocation().search
  const siteValue = new URLSearchParams(search).get('filter[site]')
  const fromValue = new URLSearchParams(search).get('filter[crawldate][from]')
  const toValue = new URLSearchParams(search).get('filter[crawldate][to]')
  const commitValue = new URLSearchParams(search).get('commit')

  const [data, setData] = useState({})
  const [dropdownOptions, setDropdownOptions] = useState({})
  const [selectedSite, setSelectedSite] = useState(siteValue ? {label: siteValue, value: siteValue} :{})
  const [loading, setLoading] = useState(false)
  const [avgtime_taken, setAvgtime_taken] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [duplicateData, setDuplicateData] = useState([])
  const [searchValue2, setSearchValue2] = useState('')
  const [duplicateData2, setDuplicateData2] = useState([])
  const today = new Date()
  const [fromDate, setFromDate] = useState(fromValue ? new Date(fromValue) : new Date(today.getFullYear(),today.getMonth(),today.getDate()-2))
  const [endDate, setEndDate] = useState(toValue ? new Date(toValue) : today)
  const [activeTab,setActiveTab] = useState("1")
  const [sitesData, setSitesData] = useState([])

  useEffect(() => {
    function fetchSiteNames() {
        axios.get('/api/v1/internal/all_site_names')
          .then(res => {
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
              site_names[key].map(item => {
                children.push({ label: item, value: item })
              })
              group['options'] = children;
              site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setDropdownOptions(site_names_options)
          })
          .catch(err => {
            console.error(err?.message || 'Oops something went wrong')
          })
    }

    const getUploadData1 = () => {
      if (loading || data?.data){
        console.log('returning 61');
        return 
      }
      else if (commitValue != 'Get Data' && siteValue?.label){
        console.log('returning 65');
        return 
      }
      else if (selectedSite?.value !== undefined && selectedSite?.value.length > 0){
        setLoading(true)
        axios.get(`/api/v1/internal/uploads?filter[site]=${selectedSite?.value}&filter[from_date]=${getFormattedDate(fromDate)}&filter[to_date]=${getFormattedDate(endDate)}`).then(res => {
          setData(res.data?.data)
          console.log('res.data?.data', res.data?.data)
          setAvgtime_taken(res.data?.data?.site_data)
          setDuplicateData2(res.data?.data?.site_data)
  
          let tempSitesData = [...res.data?.data?.site_data]
          tempSitesData.push({
            site: '', date: <b>Total: </b>, 
            drss_count: <b>{res.data?.data?.total_drss_count}</b>, 
            rss_count: <b>{res.data?.data?.total_rss_count}</b>, 
            feedcrawl_count: <b>{res.data?.data?.total_feedcrawl_count}</b>,
            segment_extr_count: <b>{res.data?.data?.total_segment_extr_count}</b>, 
            segment_dedup_count: <b>{res.data?.data?.total_segment_dedup_count}</b>,
            segment_normalize_count: <b>{res.data?.data?.total_segment_normalize_count}</b>, 
            segment_upload_count: <b>{res.data?.data?.total_segment_upload_count}</b>,
            qa: '', kibana: ''
          })
          setSitesData(tempSitesData)
          setDuplicateData(tempSitesData)
          setLoading(false)
        }).catch(err => {
          toast.error(err.response?.data?.message)
          setLoading(false)
        })
      }
    }

    fetchSiteNames();
    getUploadData1()  
  }, [])

  useEffect(() => {
    if (searchValue){
      let res = []
      sitesData.forEach(each => {
        if (each?.site?.includes(searchValue) || each?.date?.toString()?.includes(searchValue) || each?.drss_count?.toString()?.includes(searchValue)
        || each?.rss_count?.toString()?.includes(searchValue) || each?.feedcrawl_count?.toString()?.includes(searchValue)
        || each?.segment_extr_count?.toString()?.includes(searchValue) || each?.segment_dedup_count?.toString()?.includes(searchValue)
        || each?.segment_normalize_count?.toString()?.includes(searchValue) || each?.segment_upload_count?.toString()?.includes(searchValue)){
          res.push(each)
        }
      })
      setDuplicateData(res)
    } else {
      setDuplicateData(sitesData)
    }
  }, [searchValue])
  
  useEffect(() => {
    if (searchValue2){
      let res2 = []
      avgtime_taken.forEach(each => {
        if (each?.date?.toString()?.includes(searchValue2) || each?.rss_avg_time_taken?.toString()?.includes(searchValue2)
        || each?.feedcrawl_avg_time_taken?.toString()?.includes(searchValue2) || each?.extr_avg_time_taken?.toString()?.includes(searchValue2)
        || each?.dedup_avg_time_taken?.toString()?.includes(searchValue2) || each?.norm_avg_time_taken?.toString()?.includes(searchValue2)
        || each?.upload_avg_time_taken?.toString()?.includes(searchValue2)){
          res2.push(each)
        }
      setDuplicateData2(res2)
      })
    } else {
      setDuplicateData2(avgtime_taken)
    }
  }, [searchValue2])
  
  const promiseOptions = (inputValue) => {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200'){
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
              site_names[key].map(item => {
                children.push({ label: item, value: item })
              })
              group['options'] = children;
              site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setDropdownOptions(site_names_options)
            resolve(site_names_options)
          }
        }).catch(err => {
          console.log(`err`, err)
        })
      })
  }
  
  function getFormattedDate(date) {
    var year = date.getFullYear();
  
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
  
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    return year + '/' + month + '/' + day;
  }

  function datediff(first, second) {
    return Math.round((second-first)/(1000*60*60*24));
  }

  function copyToClipboard(value){
    navigator.clipboard.writeText(value)
  }

  const transformTime = (string) => {
    if (string != undefined){
      let temp = new Date(string * 1000)?.toISOString()?.substr(11, 8);
      return temp.substr(0, 2) + ' h ' + temp.substr(3, 2) + ' m ' + temp.substr(6, 2) + ' s'
    } else {
      return ''
    }
  }

  Date.prototype.yyyymmdd = function() {
    var mm = this.getMonth() + 1;
    var dd = this.getDate();
    return [this.getFullYear(), (mm>9 ? '' : '0') + mm, (dd>9 ? '' : '0') + dd ].join('');
  };

  const getUploadData = () => {
    if (selectedSite?.value == undefined){
      toast.error("Sitename is required!")
      return true
    }

    if (fromDate == undefined){
      toast.error("From date is required!")
      return true
    }

    if (endDate == undefined){
      toast.error("To date is required!")
      return true
    }

    window.location.href = `${MysqlInternalBasePoint}/upload?utf8=✓&filter%5Bsite%5D=${selectedSite?.value}&filter%5Bcrawldate%5D%5Bfrom%5D=${formatDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${formatDate(endDate)}&commit=Get Data`
  }

  const changeFormat = (dateString) => {
    const year = +dateString.toString().substring(0, 4);
    const month = +dateString.toString().substring(4, 6);
    const day = +dateString.toString().substring(6, 8);
    const date = new Date(year, month - 1, day);
    return date.toDateString()
  }

  const columns = [
    {name: 'Date',selector: 'date',center:true, sortable: true},
    {name: <>
      <div className='d-flex align-items-center'><b data-tip data-for='rssss_link'> RSS </b>
      <Info size={14} className='ms-2' /> </div>
      <ReactTooltip id='rssss_link' clickable multiline  delayHide={1000} effect='solid'>
          <a href={`https://app.promptcloud.com/internal/overall_system_reports/avg_time_per_pipeline_stage_geo_wise?filter%5Bsite_name%5D=${siteValue}&filter%5Btab_name%5D=rss`} target='_blank'
            className='text-white'>
          Click here to see the avg time taken per Rss geo wise </a>
      </ReactTooltip>
      </>, center:true, sortable: true, cell: (each) => <>
        {transformTime(each?.rss_avg_time_taken)}
    </>},
    {name: <>
      <div className='d-flex align-items-center'><b data-tip data-for='feedcrawlll_link'> Feedcrawl </b>
      <Info size={14} className='ms-2' /> </div>
      <ReactTooltip id='feedcrawlll_link' clickable multiline  delayHide={1000} effect='solid'>
          <a href={`https://app.promptcloud.com/internal/overall_system_reports/avg_time_per_pipeline_stage_geo_wise?filter%5Bsite_name%5D=${siteValue}&filter%5Btab_name%5D=feedcrawl`} target='_blank'
            className='text-white'>
          Click here to see the avg time taken per Feedcrawl geo wise </a>
      </ReactTooltip>
      </>,center:true, sortable: true, cell: (each) => <>
        {(((each?.feedcrawl_count < (each?.rss_count * 0.75)) && (each?.date == new Date().yyyymmdd())) 
            || ((each?.feedcrawl_count * 0.1) < each?.failed_url_count)) 
            ? <b className='text-danger'> {transformTime(each?.feedcrawl_avg_time_taken)} </b>
            : transformTime(each?.feedcrawl_avg_time_taken)}
    </>},
    {name: 'Extraction',center:true, sortable: true, cell: (each) => <>
        {transformTime(each?.extr_avg_time_taken)}
    </>},
    {name: 'Dedup',center:true, sortable: true, cell: (each) => <>
        {(each?.dropped_ded > 1000 || (each?.dropped_ded * 0.3) > each?.total_segment_dedup_count) 
            ? <p className='fw-bold text-danger'> {transformTime(each?.dedup_avg_time_taken)} </p> 
            : transformTime(each?.dedup_avg_time_taken)}
    </>},
    {name: 'Normalize',center:true, sortable: true, cell: (each) => <>
        {transformTime(each?.norm_avg_time_taken)}
    </>},
    {name: 'Upload',center:true, sortable: true, cell: (each) => <>
        {transformTime(each?.upload_avg_time_taken)}
    </>},
  ]

  const columns2 = [
    {name: 'Site',selector: 'site',center:true, sortable: true, width: '380px', cell: (each) => <div className='d-flex justify-content-center align-items-center'>
      <a href={`/internal/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${getFormattedDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)}&filter%5Bsite%5D=${each['site']}`} target='_blank'
        data-tip data-for='goto_segment_view' className='d-flex justify-content-center align-items-center'>
        {each['site']} 
        </a> {each?.site != '' && <Info size={18} className='mx-2' />} </div>},
    {name: 'Date',selector: 'date',center:true, sortable: true, width: '160px', cell: (each) => <>{each?.site == '' 
    ? <b>Total: </b>
    : <div className='pt-1'> <p data-tip data-for='kibana_link' className='d-flex justify-content-center align-items-center'>
        {each['date']} <Info size={18} className='mx-2' /> </p>
    <ReactTooltip id='kibana_link' clickable multiline  delayHide={1000} effect='solid'>
        <a href={`${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+%22${each?.site}%22+or+sitename:+%22${each?.site}%22)+and+yyyymmdd:${each?.date}'),sort:!(!('@timestamp',desc)))`} target='_blank'
          className='text-white'>
        Kibana Link </a>
    </ReactTooltip> </div>}</> },
    {name: 'Drss',center:true, sortable: true, grow: 0, cell: (each) => <>{each.site == '' 
        ? <b>{data?.total_drss_count}</b>
        : <><a href={`/internal/rss?filter%5Bcrawldate%5D%5Bfrom%5D=${getFormattedDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)}&filter%5Brss_job_type%5D=drss&filter%5Bsite%5D=${each?.site}`} target='_blank'
        data-tip data-for={`kibana_link_custom_${each["date"]}`} className='d-flex justify-content-center align-items-center'>{each['drss_count']} <Info size={18} className='mx-2' /> </a>
        <ReactTooltip id={`kibana_link_custom_${each["date"]}`} clickable multiline  delayHide={1000} effect='solid'>
        <a href={`${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+%22${each?.site}%22++or+sitename+:%22${each?.site}%22)+and+yyyymmdd:${each?.date}+and+(pipeline_stage:+rss+or+pipeline_stage:+RSS)'),sort:!(!('@timestamp',desc)))`} target="_blank" className='text-white'>Kibana Link</a>
        <br />
        <a href={`http://alerta.promptcloud.com/#/alerts?status=open`} target='_blank' className='text-white'>Alert Link (To Do)</a>
        <br />
        <p className='m-0'>Avg Time Taken: {transformTime(each?.rss_avg_time_taken)}</p>
        </ReactTooltip></>}</> },
    {name: 'Rss',center:true, sortable: true, grow: 0, cell: (each) => <>{each.site == '' 
        ? <b>{data?.total_rss_count}</b>
        : <><a href={`/internal/rss?filter%5Bcrawldate%5D%5Bfrom%5D=${getFormattedDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)}&filter%5Brss_job_type%5D=rss&filter%5Bsite%5D=${each?.site}`} target='_blank'
        data-tip data-for={`kibana_link_custom2_${each["date"]}`} className='d-flex justify-content-center align-items-center'>{each['rss_count']} <Info size={18} className='mx-2' /> </a>
        <ReactTooltip id={`kibana_link_custom2_${each["date"]}`} clickable multiline  delayHide={1000} effect='solid' >
        <a href={`${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+%22${each?.site}%22++or+sitename+:%22${each?.site}%22)+and+yyyymmdd:${each?.date}+and+(pipeline_stage:+rss+or+pipeline_stage:+RSS)'),sort:!(!('@timestamp',desc)))`} target="_blank" className='text-white'>Kibana Link</a>
        <br />
        <a href={`http://alerta.promptcloud.com/#/alerts?status=open`} target='_blank' className='text-white'>Alert Link (To Do)</a>
        <br />
        <p className='m-0'>Avg Time Taken: {transformTime(each?.rss_avg_time_taken)}</p>
        </ReactTooltip></>}</> },
    {name: 'Feedcrawl',center:true, sortable: true, grow: 0, cell: (each) => <>{each.site == '' 
        ? <b>{data?.total_feedcrawl_count}</b>
        : <div>
            <a href={`/internal/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${getFormattedDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)}&filter%5Bsite%5D=${each?.site}`} target='_blank'
            data-tip data-for={`kibana_link_custom31_${each["date"]}`} 
            className={`d-flex justify-content-center align-items-center ${
              ((changeFormat(each['date']) < new Date().toDateString()) && (each['feedcrawl_count'] < (each['rss_count'] * 0.25)) 
                || ((each['feedcrawl_count'] * 0.1) < each['failed_url_count'])) ? 'text-danger fw-bold' : ''}`}>{each['feedcrawl_count']} <Info size={18} className='mx-2' /> </a>
            <ReactTooltip id={`kibana_link_custom31_${each["date"]}`} clickable multiline  delayHide={1000} effect='solid' >
            {(changeFormat(each['date']) < new Date().toDateString()) && (each['feedcrawl_count'] < (each['rss_count'] * 0.25)) ?
              <p className='m-0 mb-2 fw-bold'>Probably the site is blocking or crawler is broken</p> : 
              ((each['feedcrawl_count'] * 0.1) < each['failed_url_count']) ?
              <p className='m-0 mb-2 fw-bold'>Total failed url is more than 10% of the total crawled</p>
              : null}
            <a href={`${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+%22${each?.site}%22++or+sitename+:%22${each?.site}%22)+and+yyyymmdd:${each?.date}+and+(pipeline_stage:+rss+or+pipeline_stage:+RSS)'),sort:!(!('@timestamp',desc)))`} target="_blank" className='text-white'>Kibana Link</a>
            <br />
            <a href={`http://alerta.promptcloud.com/#/alerts?status=open`} target='_blank' className='text-white'>Alert Link (To Do)</a>
            <br />
            <p className='m-0'>Avg Time Taken: {transformTime(each['feedcrawl_avg_time_taken'])}</p>
            <p className='m-0'>Failed Count: {each['failed_url_count']} </p>
          </ReactTooltip>
          </div>} </>},
    {name: 'Extraction',center:true, sortable: true, grow: 0, cell: (each) => <>{each.site == '' 
        ? <b>{data?.total_segment_extr_count}</b>
        : <><a href={`/internal/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${getFormattedDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)}&filter%5Bsite%5D=${each?.site}`} target='_blank'
        data-tip data-for={`kibana_link_custom3_${each["date"]}`} className='d-flex justify-content-center align-items-center'>{each['segment_extr_count']} <Info size={18} className='mx-2' /> </a>
        <ReactTooltip id={`kibana_link_custom3_${each["date"]}`} clickable multiline  delayHide={1000} effect='solid' >
        <p className='m-0'>Dropped: {each?.dropped_ext}</p>
        <a href={`${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+%22${each?.site}%22++or+sitename+:%22${each?.site}%22)+and+yyyymmdd:${each?.date}+and+(pipeline_stage:+rss+or+pipeline_stage:+RSS)'),sort:!(!('@timestamp',desc)))`} target="_blank" className='text-white'>Kibana Link</a>
        <br />
        <a href={`http://alerta.promptcloud.com/#/alerts?status=open`} target='_blank' className='text-white'>Alert Link (To Do)</a>
        <p className='m-0'>Avg Time Taken: {transformTime(each?.extr_avg_time_taken)}</p>
        <p className='m-0'>Failed Count: {each?.failed_url_count} </p>
        </ReactTooltip></>} </>},
    {name: 'Dedup',center:true, sortable: true, grow: 0, cell: (each) => <>{each.site == '' 
        ? <b>{data?.total_segment_dedup_count}</b>
        : <><a href={`/internal/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${getFormattedDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)}&filter%5Bsite%5D=${each?.site}`} target='_blank'
        data-tip data-for={`kibana_link_custom4_${each["date"]}`} className={`d-flex justify-content-center align-items-center ${(each['segment_dedup_count'] > 1000 || (each['segment_dedup_count'] > 0.3 * each['dropped_ded'])) ? 'text-danger fw-bold' : ''}`}>{each['segment_dedup_count']} <Info size={18} className='mx-2' /> </a>
        <ReactTooltip id={`kibana_link_custom4_${each["date"]}`} clickable multiline  delayHide={1000} effect='solid' >
        <p className='m-0'>Dropped: {each?.dropped_ded}</p>
        <a href={`${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+%22${each?.site}%22++or+sitename+:%22${each?.site}%22)+and+yyyymmdd:${each?.date}+and+(pipeline_stage:+rss+or+pipeline_stage:+RSS)'),sort:!(!('@timestamp',desc)))`} target="_blank" className='text-white'>Kibana Link</a>
        <br />
        <a href={`http://alerta.promptcloud.com/#/alerts?status=open`} target='_blank' className='text-white'>Alert Link (To Do)</a>
        <p className='m-0'>Avg Time Taken: {transformTime(each?.dedup_avg_time_taken)}</p>
        </ReactTooltip></>} </>},
    {name: 'Normalize',
    center:true, 
    sortable: true, 
    grow: 0, 
    cell: (each) => <>{each.site == ''
        ? <b>{data?.total_segment_normalize_count}</b>
        : <><a href={`/internal/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${getFormattedDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)}&filter%5Bsite%5D=${each?.site}`} target='_blank'
        data-tip data-for={`kibana_link_custom5_${each["date"]}`} className='d-flex justify-content-center align-items-center'>{each['segment_normalize_count']} <Info size={18} className='mx-2' /> </a>
        <ReactTooltip id={`kibana_link_custom5_${each["date"]}`} clickable multiline  delayHide={1000} effect='solid' >
        <p className='m-0'>Dropped: {each?.dropped_nor}</p>
        <a href={`${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+%22${each?.site}%22++or+sitename+:%22${each?.site}%22)+and+yyyymmdd:${each?.date}+and+(pipeline_stage:+rss+or+pipeline_stage:+RSS)'),sort:!(!('@timestamp',desc)))`} target="_blank" className='text-white'>Kibana Link</a>
        <br />
        <a href={`http://alerta.promptcloud.com/#/alerts?status=open`} target='_blank' className='text-white'>Alert Link (To Do)</a>
        <p className='m-0'>Avg Time Taken: {transformTime(each?.norm_avg_time_taken)}</p>
        </ReactTooltip></>} </>
    },
    {name: 'Upload',
    center:true, 
    sortable: true, 
    grow: 0, 
    cell: (each) => <>{each.site == '' 
        ? <b>{data?.total_segment_upload_count}</b>
        : <><a href={`/internal/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${getFormattedDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)}&filter%5Bsite%5D=${each?.site}`} target='_blank'
        data-tip data-for={`kibana_link_custom6_${each["date"]}`} className='d-flex justify-content-center align-items-center'>{each['segment_upload_count']} <Info size={18} className='mx-2' /> </a>
        <ReactTooltip id={`kibana_link_custom6_${each["date"]}`} clickable multiline  delayHide={1000} effect='solid' >
        <p className='m-0'>Dropped: {each?.dropped_upload}</p>
        <a href={`${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+%22${each?.site}%22++or+sitename+:%22${each?.site}%22)+and+yyyymmdd:${each?.date}+and+(pipeline_stage:+rss+or+pipeline_stage:+RSS)'),sort:!(!('@timestamp',desc)))`} target="_blank" className='text-white'>Kibana Link</a>
        <a href={`http://alerta.promptcloud.com/#/alerts?status=open`} target='_blank' className='text-white'>Alert Link (To Do)</a>
        <p className='m-0'>Avg Time Taken: {transformTime(each?.upload_avg_time_taken)}</p>
        </ReactTooltip></>} </> },
    {name: 'QA',center:true, grow: 0, cell: (each) => <>{each.site == '' ? '' : <a href={`https://app.promptcloud.com/internal/qa/${each?.site}`} target='_blank'>See Report</a>} </>},
    {name: 'Kibana',center:true, grow: 0, cell: (each) => <>{ each.site == '' ? '' :
        <a href={`${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'site_name:+%22${each?.site}%22+or+sitename:+%22${each?.site}%22'),sort:!(!('@timestamp',desc)))`} target='_blank'>Kibana Link</a>} </>},
  ]

  function formatDate(date) {
    var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    return [year, month, day].join('/');
}

  return (
    <Container fluid>
      { (window.location.pathname == '/internal/mysql/upload/new'|| window.location.pathname == 'internal/mysql/upload/new/')
         ? <Fragment>
        <Container fluid>
            <div className="page-title">
                <Row className=''>
                    <Col xs="6">
                        <h3>Internal Dashboard</h3>
                    </Col>
                    <Col xs="6">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <a href={`/internal`}><Home /></a>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <b> Upload Page </b>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    </Col>
                </Row>
            </div>
        </Container>
      </Fragment> : <div className='py-3'></div>
      }

      { loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
      : (data?.site_data ? 
      <div>
        <Card className='my-3'>
          <CardBody>
            <Row>
              <Col md={9}>
                <h5 className='fw-lighter'>Site Info: {siteValue || (selectedSite?.value || '')} </h5>
              </Col>
              <Col md={3} className='d-flex justify-content-center'>
                <h6 className='fw-bold'> Site setup ticket: </h6>
                <a href={`https://redmine.promptcloud.com/issues/${data?.redmine_ticket_id}`} className='fw-bold'>
                  {data?.redmine_ticket_id} </a>
              </Col>
              <Col md={12} className='mt-4'>
                <Nav tabs className="border-tab">
                    <NavItem><NavLink className={activeTab === "1" ? "active" : ''} 
                      onClick={() => setActiveTab("1")} style={{fontSize: 12}} > Overall Stats </NavLink></NavItem>
                    <NavItem><NavLink className={activeTab === "2" ? "active" : ''} 
                      onClick={() => setActiveTab("2")} style={{fontSize: 12}} > Configured, Running and Waiting jobs </NavLink></NavItem>
                    <NavItem><NavLink className={activeTab === "3" ? "active" : ''} 
                      onClick={() => setActiveTab("3")} style={{fontSize: 12}} > Average Times </NavLink></NavItem>
                </Nav>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <div>
          <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                  <div>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col md={8}>
                            <Row className='my-3'>
                              <Col md={12}>
                                <h6 className='fw-bold'>Site Name</h6>
                                <AsyncSelect cacheOptions defaultOptions={dropdownOptions} name='site_name' 
                                  value={selectedSite} loadOptions={promiseOptions} onChange={res => setSelectedSite(res)}
                                  placeholder="Select a site name" isClearable={true} />
                                  <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
                              </Col>
                            </Row>
                            <Row className='my-3'>
                              <Col md={4}>
                                <h6 className='fw-bold'>From date</h6>
                                <DatePicker selected={fromDate} onChange={(date) => setFromDate(date)}
                                  maxDate={new Date()} className='form-control' dateFormat={"YYY/MM/dd"} />
                              </Col>
                              <Col md={4}>
                                <h6 className='fw-bold'>To date</h6>
                                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)}
                                  maxDate={new Date()} className='form-control' dateFormat={"YYY/MM/dd"} />
                              </Col>
                              <Col md={4} className='d-flex justify-content-center mt-3'>
                                <button className='btn btn-success my-auto w-100' onClick={() => getUploadData()}>Get Data</button>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={4}>
                            <Table bordered className='my-2' responsive>
                              <tbody>
                                <tr>
                                  <td className='fw-bold'>Asignees</td>
                                  <td className='fw-bold'>Biz Contact</td>
                                  <td className='fw-bold'>DB Query Buffer Q Size</td>
                                </tr>
                                <tr>
                                  <td>{data?.assignees}</td>
                                  <td>{data?.biz_contact}</td>
                                  <td>
                                    <p data-tip data-for='db_query_buffer' 
                                      className={`d-flex justify-content-center ${data?.db_query_buffer_queue_size == 0 ? '' : data?.db_query_buffer_queue_size > 3000 ? 'text-danger' : 'text-success' }`}>
                                      {data?.db_query_buffer_queue_size} <Info size={18} className='mx-2' />
                                    </p>
                                    <ReactTooltip id='db_query_buffer' clickable multiline  delayHide={1000} effect='solid' >
                                      <p> Get queue name from PipelineV2::Utils.get_db_query_buffer_queue_name(sitename) and 
                                      <br /> queue size from SdfBaseQueue.new(queue_name).size </p>
                                    </ReactTooltip>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                            <div className='btn-group my-2'>
                              <button className='btn btn-sm btn-success d-flex align-items-center justify-content-center'> <Link size={14} className='mx-2' /> <p className='my-0'>Regular API-Link</p> </button>
                              <a className='btn btn-sm btn-outline-success' target="_blank" href={data?.regular_api_link} data-tip='Open in new Tab'> <ExternalLink size={14} /></a>
                              <button className='btn btn-sm btn-outline-success' 
                                onClick={() => copyToClipboard(data?.regular_api_link)} data-tip='copy to clipboard'> <Copy size={14} /></button>
                            </div>
                            <div className='btn-group my-2'>
                              <button className='btn btn-sm btn-success d-flex align-items-center justify-content-center'> <Link size={14} className='mx-2' /> <p className='my-0'>API-Link for merged file </p> </button>
                              <a className='btn btn-sm btn-outline-success' target="_blank" href={data?.merged_file_api_link} data-tip='Open in new Tab'> <ExternalLink size={14} /> </a>
                              <button className='btn btn-sm btn-outline-success' 
                                onClick={() => copyToClipboard(data?.merged_file_api_link)} data-tip='copy to clipboard'> <Copy size={14} /></button>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>

                    <Card>
                      <CardBody>
                        <h6 className='fw-bold text-center my-2'>Queue Size</h6>
                        <Table className='my-2' bordered responsive>
                            <thead>
                              <tr>
                                <th className='fw-bold text-center'>Site</th>
                                <th className='fw-bold text-center'>Drss seed Q size</th>
                                <th className='fw-bold text-center'>Rss Q size</th>
                                <th className='fw-bold text-center'>Dep data upload Q</th>
                                <th className='fw-bold text-center'>Dep data failed Q</th>
                                <th className='fw-bold text-center'>Test urls Q</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <a href={`/internal/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${getFormattedDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)}&filter%5Bsite%5D=${data?.site_data[0]['site']}`} target='_blank'
                                     data-tip data-for='goto_segment_view' className='d-flex justify-content-center'>
                                    {data?.site_data[0]['site']} <Info size={18} className='mx-2' />
                                  </a>
                                  <ReactTooltip id='goto_segment_view' multiline>
                                    <p> Go to Segment View </p>
                                  </ReactTooltip>
                                </td>
                                <td>     
                                  <a href={`/internal/queue_sizes/search?filter%5Bqueue_type%5D=drss_seed_urls_queue&filter%5Bsite%5D=${data?.site_data[0]['site']}`} target='_blank'
                                     data-tip data-for='goto_queue_view' className='d-flex justify-content-center'>
                                    {data?.drss_seed_urls_queue_size} <Info size={18} className='mx-2' />
                                  </a>
                                </td>
                                <td>     
                                  <a href={`/internal/queue_sizes/search?filter%5Bqueue_type%5D=rss_queue&filter%5Bsite%5D=${data?.site_data[0]['site']}`} target='_blank'
                                     data-tip data-for='goto_queue_view' className='d-flex justify-content-center'>
                                    {data?.rss_queue_size} <Info size={18} className='mx-2' />
                                  </a>
                                </td>
                                <td>
                                  <a href={`/internal/queue_sizes/${data?.site_data[0]['site']}_dependent_data_upload_queue`} target='_blank'
                                     data-tip data-for='goto_queue_view' className='d-flex justify-content-center'>
                                    {data?.dependent_data_upload_queue_size} <Info size={18} className='mx-2' />
                                  </a>
                                </td>
                                <td>
                                  <a href={`/internal/queue_sizes/${data?.site_data[0]['site']}_dependent_data_upload_failed_queue`} target='_blank'
                                     data-tip data-for='goto_queue_view' className='d-flex justify-content-center'>
                                    {data?.dependent_data_upload_failed_queue_size} <Info size={18} className='mx-2' />
                                  </a>
                                </td>
                                <td>
                                  <a href={`/internal/queue_sizes/search?filter%5Bqueue_type%5D=test_run_queue&filter%5Bsite%5D=${data?.site_data[0]['site']}_dependent_data_upload_failed_queue`} target='_blank'
                                     data-tip data-for='goto_queue_view' className='d-flex justify-content-center'>
                                    {data?.test_run_queue_size} <Info size={18} className='mx-2' />
                                  </a>
                                </td>
                              </tr>
                              <ReactTooltip id='goto_queue_view' multiline>
                                <p> Go to Queue Size View </p>
                              </ReactTooltip>
                            </tbody>
                        </Table>
                        <div className='my-5'>
                          <p className='text-center'>
                            <b>Note: </b> <Info size={14} /> indicates the hover is there. It has some informations or links
                          </p>
                        </div>
                        <Row className='mb-2'>
                          <Col md={7} className='my-3'></Col>
                          <Col md={5} className='my-3'>
                              <div className='d-flex justify-content-center align-items-center'>
                                  <h6 className='my-0 me-2'>Search:</h6>
                                  <input type='text' className='form-control' value={searchValue} 
                                  onChange={(e) => setSearchValue(e.target?.value)} />
                              </div>
                          </Col>
                        </Row>
                        <DataTable columns={columns2} data={duplicateData} noHeader responsive className='sites_table' />
                      </CardBody>
                    </Card>
                  </div>
              </TabPane>


              <TabPane tabId="2">
                  <div>
                    <Row className='my-3'>
                      <Col md={4}>
                        <Card>
                          <CardBody>
                            <p className='fw-bold text-center'>Recrawl Interval</p>
                            <div className='d-flex'>
                              {data?.recrawl_interval?.map((each, index) => (
                                <p key={index} className='me-1 my-0'> <b>{Object.keys(each)[0]}: </b> {Object.values(each)[0]}, </p>
                              ))}
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={4}>
                        <Card>
                          <CardBody>
                            <p className='fw-bold text-center'>Diskfetcher Type</p>
                            <p>{data?.diskfetcher_type}</p>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={4}>
                        <Card>
                          <CardBody>
                            <p className='fw-bold text-center'>Selenium Cluster</p>
                            <p>{data?.selenium_cluster_info}</p>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Card>
                        <CardBody>
                          { data?.feedcrawl_worker_configs?.length > 0 ? <>
                          <div className="d-flex justify-content-between my-2">
                            <p className='fw-bold my-0'>Feedcrawl Worker Configuration</p>
                            <button className='btn btn-success'>
                              Proxy Status: 
                              {data?.proxy_infos?.feedcrawl_proxy_info?.is_proxy_enabled 
                              ? <> Active <Info size={18} className='mx-2' data-tip data-for='proxy_active' /> </> : 'Inactive' }</button>
                              <ReactTooltip multiline id='proxy_active'>
                                  <div>
                                    <p>Proxy Source: {data?.proxy_infos?.feedcrawl_proxy_info?.proxy_source || ''}</p>
                                    <p>Proxy Country: {data?.proxy_infos?.feedcrawl_proxy_info?.proxy_country || ''}</p>
                                    <p>Proxy Change Frequency: {data?.proxy_infos?.feedcrawl_proxy_info?.proxy_change_frequency || ''}</p>
                                  </div>
                              </ReactTooltip>
                          </div>
                          <table className='table my-2'>
                            <thead>
                              <tr className='bg-light'>
                                <th className='fw-bold'>Geo</th>
                                <th className='fw-bold'>Rss Q size</th>
                                <th className='fw-bold'>Test urls Q</th>
                                <th className='fw-bold'>Max on one host</th>
                                <th className='fw-bold'>Max hosts</th>
                                <th className='fw-bold'>Curr Running jobs</th>
                                <th className='fw-bold'>Curr Waiting jobs</th>
                                <th className='fw-bold'>Killed</th>
                                <th className='fw-bold'>Failed</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.feedcrawl_worker_configs?.map((each, index) => (
                                <tr key={index}>
                                  <td>
                                    <a href={`/internal/clusterwise_worker_and_jobs_info?filter[geo_cluster]=${each?.geo}`} target='_blank'
                                      data-tip data-for='geocluster' className='d-flex justify-content-center'>
                                      {each?.geo} <Info size={18} className='mx-2' />
                                    </a>
                                    <ReactTooltip id='geocluster' multiline>
                                      <p> Go to Site Resque Job Config </p>
                                    </ReactTooltip>
                                  </td>
                                  <td>
                                    <a href={`/internal/queue_sizes/search?filter%5Bqueue_type%5D=rss_queue&filter%5Bsite%5D=${siteValue || (selectedSite?.value || '')}`} target='_blank'
                                      data-tip data-for='rsscluster' className='d-flex justify-content-center'>
                                      {data?.rss_queue_size} <Info size={18} className='mx-2' />
                                    </a>
                                    <ReactTooltip id='rsscluster' multiline>
                                      <p> Go to Queue Size view </p>
                                    </ReactTooltip>
                                  </td>
                                  <td>
                                    <a href={`/internal/queue_sizes/search?filter%5Bqueue_type%5D=test_run_queue&filter%5Bsite%5D=${siteValue || (selectedSite?.value || '')}`} target='_blank'
                                      data-tip data-for='test_run_queue_size' className='d-flex justify-content-center'>
                                      {data?.test_run_queue_size} <Info size={18} className='mx-2' />
                                    </a>
                                    <ReactTooltip id='test_run_queue_size' multiline>
                                      <p> Go to Queue Size view </p>
                                    </ReactTooltip>
                                  </td>
                                  <td>
                                    {each?.max_on_one_host}
                                  </td>
                                  <td>
                                    {each?.max_hosts}
                                  </td>
                                  <td>
                                    {each?.current_running_jobs}
                                  </td>
                                  <td>
                                    {each?.current_waiting_jobs}
                                  </td>
                                  <td>
                                    {
                                      each?.killed == 0 ? 0
                                        :
                                        <>
                                          <p data-tip data-for='killed_count'>{each?.killed} <Info size={18} className='mx-2' /></p>
                                          <ReactTooltip id='killed_count' multiline>
                                            {`Killed data are showing by ${data?.days} days and getting it from resque`}
                                          </ReactTooltip>
                                        </>
                                    }
                                  </td>
                                  <td>
                                    {
                                      each?.failed == 0 ? 0
                                        :
                                        <>
                                          <p data-tip data-for='failed_count'>{each?.failed} <Info size={18} className='mx-2' /></p>
                                          <ReactTooltip id='failed_count' multiline>
                                            {`Failed data are showing by ${data?.days} days and getting it from resque`}
                                          </ReactTooltip>
                                        </>
                                    }
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          </>
                          : 
                          <>
                          <p className='fw-bold my-0'>Feedcrawl Worker Configuration</p>
                          <Alert color='primary'> No data Available </Alert> </>}
                        </CardBody>
                      </Card>

                      <Card>
                        <CardBody>
                          {data?.recrawl_worker_configs?.length > 0 ? <>
                          <div className="d-flex justify-content-between my-2">
                            <p className='fw-bold my-0'>Recrawl Worker Configuration</p>
                            <button className='btn btn-success'>
                              Proxy Status: 
                              {data?.proxy_infos?.recrawl_proxy_info?.is_proxy_enabled 
                              ? <> Active <Info size={18} className='mx-2' data-tip data-for='proxy_active_2' /> </> : 'Inactive'}
                            </button>
                            <ReactTooltip multiline id='proxy_active_2'>
                              <div>
                                <p>Proxy Source: {data?.proxy_infos?.recrawl_proxy_info?.proxy_source || ''}</p>
                                <p>Proxy Country: {data?.proxy_infos?.recrawl_proxy_info?.proxy_country || ''}</p>
                                <p>Proxy Change Frequency: {data?.proxy_infos?.recrawl_proxy_info?.proxy_change_frequency || ''}</p>
                              </div>
                            </ReactTooltip>
                          </div>
                          <table className='table my-2'>
                            <thead>
                              <tr className='bg-light'>
                                <th className='fw-bold'>Geo</th>
                                <th className='fw-bold'>Max on one host</th>
                                <th className='fw-bold'>Max hosts</th>
                                <th className='fw-bold'>Curr Running jobs</th>
                                <th className='fw-bold'>Curr Waiting jobs</th>
                                <th className='fw-bold'>Killed</th>
                                <th className='fw-bold'>Failed</th>
                              </tr>
                            </thead>
                            <tbody>
                              { data?.recrawl_worker_configs?.map((each, index) => (
                                <tr key={index}>
                                  <td>
                                    <a href={`/internal/clusterwise_worker_and_jobs_info?filter[geo_cluster]=${each?.geo}`} target='_blank'
                                      data-tip data-for='geocluster' className='d-flex justify-content-center'>
                                      {each?.geo} <Info size={18} className='mx-2' />
                                    </a>
                                    <ReactTooltip id='geocluster' multiline>
                                      <p> Go to Site Resque Job Config </p>
                                    </ReactTooltip>
                                  </td>
                                  <td>
                                    {each?.max_on_one_host}
                                  </td>
                                  <td>
                                    {each?.max_hosts}
                                  </td>
                                  <td>
                                    {each?.current_running_jobs}
                                  </td>
                                  <td>
                                    {each?.current_waiting_jobs}
                                  </td>
                                  <td>
                                    {
                                      each?.killed == 0 ? 0
                                        :
                                        <>
                                          <p data-tip data-for='killed_count'>{each?.killed} <Info size={18} className='mx-2' /></p>
                                          <ReactTooltip id='killed_count' multiline>
                                            {`Killed data are showing by ${data?.days} days and getting it from resque`}
                                          </ReactTooltip>
                                        </>
                                    }
                                  </td>
                                  <td>
                                    {
                                      each?.failed == 0 ? 0
                                        :
                                        <>
                                          <p data-tip data-for='failed_count'>{each?.failed} <Info size={18} className='mx-2' /></p>
                                          <ReactTooltip id='failed_count' multiline>
                                            {`Failed data are showing by ${data?.days} days and getting it from resque`}
                                          </ReactTooltip>
                                        </>
                                    }
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          </> : <>
                          <p className='fw-bold my-0'>Recrawl Worker Configuration</p>
                          <Alert color='primary'> No data Available </Alert> </>}
                        </CardBody>
                      </Card>

                      <Card>
                        <CardBody>
                          { data?.distributed_recrawl_worker_configs?.length > 0 ? <>
                          <div className="d-flex justify-content-between my-2">
                            <p className='fw-bold my-0'>Distributed Recrawl Worker Configuration</p>
                          </div>
                          <table className='table my-2'>
                            <thead>
                              <tr className='bg-light'>
                                <th className='fw-bold'>Geo</th>
                                <th className='fw-bold'>Drss seed Q size</th>
                                <th className='fw-bold'>Max on one host</th>
                                <th className='fw-bold'>Max hosts</th>
                                <th className='fw-bold'>Curr Running jobs</th>
                                <th className='fw-bold'>Curr Waiting jobs</th>
                                <th className='fw-bold'>Killed</th>
                                <th className='fw-bold'>Failed</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.distributed_recrawl_worker_configs?.map((each, index) => (
                                <tr key={index}>
                                  <td>
                                    <a href={`/internal/clusterwise_worker_and_jobs_info?filter[geo_cluster]=${each?.geo}`} target='_blank'
                                      data-tip data-for='geocluster' className='d-flex justify-content-center'>
                                      {each?.geo} <Info size={18} className='mx-2' />
                                    </a>
                                    <ReactTooltip id='geocluster' multiline>
                                      <p> Go to Site Resque Job Config </p>
                                    </ReactTooltip>
                                  </td>
                                  <td>
                                    <a href={`/internal/queue_sizes/search?filter%5Bqueue_type%5D=drss_seed_urls_queue&filter%5Bsite%5D=${siteValue || (selectedSite?.value || '')}`} target='_blank'
                                      data-tip data-for='rsscluster' className='d-flex justify-content-center'>
                                      {data?.drss_seed_urls_queue_size} <Info size={18} className='mx-2' />
                                    </a>
                                    <ReactTooltip id='rsscluster' multiline>
                                      <p> Go to Queue Size view </p>
                                    </ReactTooltip>
                                  </td>
                                  <td>
                                    {each?.max_on_one_host}
                                  </td>
                                  <td>
                                    {each?.max_hosts}
                                  </td>
                                  <td>
                                    {each?.current_running_jobs}
                                  </td>
                                  <td>
                                    {each?.current_waiting_jobs}
                                  </td>
                                  <td>
                                    {
                                      each?.killed == 0 ? 0
                                        :
                                        <>
                                          <p data-tip data-for='killed_count'>{each?.killed} <Info size={18} className='mx-2' /></p>
                                          <ReactTooltip id='killed_count' multiline>
                                            {`Killed data are showing by ${data?.days} days and getting it from resque`}
                                          </ReactTooltip>
                                        </>
                                    }
                                  </td>
                                  <td>
                                    {
                                      each?.failed == 0 ? 0
                                        :
                                        <>
                                          <p data-tip data-for='failed_count'>{each?.failed} <Info size={18} className='mx-2' /></p>
                                          <ReactTooltip id='failed_count' multiline>
                                            {`Failed data are showing by ${data?.days} days and getting it from resque`}
                                          </ReactTooltip>
                                        </>
                                    }
                                  </td>
                                </tr>
                              )) }
                            </tbody>
                          </table>
                          </> : <>
                          <p className='fw-bold my-0'>Distributed Recrawl Worker Configuration</p>
                          <Alert color='primary'> No data Available </Alert>
                          </>}
                        </CardBody>
                      </Card>

                      <Card>
                        <CardBody>
                          {data?.dependent_data_worker_configs?.length > 0 ? <>
                          <div className="d-flex justify-content-between my-2">
                            <p className='fw-bold my-0'>Dependent Upload Worker Configuration</p>
                          </div>
                          <table className='table my-2'>
                            <thead>
                              <tr className='bg-light'>
                                <th className='fw-bold'>Geo</th>
                                <th className='fw-bold'>Max on one host</th>
                                <th className='fw-bold'>Max hosts</th>
                                <th className='fw-bold'>Curr Running jobs</th>
                                <th className='fw-bold'>Curr Waiting jobs</th>
                                <th className='fw-bold'>Killed</th>
                                <th className='fw-bold'>Failed</th>
                              </tr>
                            </thead>
                            <tbody>
                              { data?.dependent_data_worker_configs?.map((each, index) => (
                                <tr key={index}>
                                  <td>
                                    <a href={`/internal/clusterwise_worker_and_jobs_info?filter[geo_cluster]=${each?.geo}`} target='_blank'
                                      data-tip data-for='geocluster' className='d-flex justify-content-center'>
                                      {each?.geo} <Info size={18} className='mx-2' />
                                    </a>
                                    <ReactTooltip id='geocluster' multiline>
                                      <p> Go to Site Resque Job Config </p>
                                    </ReactTooltip>
                                  </td>
                                  <td>
                                    {each?.max_on_one_host}
                                  </td>
                                  <td>
                                    {each?.max_hosts}
                                  </td>
                                  <td>
                                    {each?.current_running_jobs}
                                  </td>
                                  <td>
                                    {each?.current_waiting_jobs}
                                  </td>
                                  <td>
                                    {
                                      each?.killed == 0 ? 0
                                        :
                                        <>
                                          <p data-tip data-for='killed_count'>{each?.killed} <Info size={18} className='mx-2' /></p>
                                          <ReactTooltip id='killed_count' multiline>
                                            {`Killed data are showing by ${data?.days} days and getting it from resque`}
                                          </ReactTooltip>
                                        </>
                                    }
                                  </td>
                                  <td>
                                    {
                                      each?.failed == 0 ? 0
                                        :
                                        <>
                                          <p data-tip data-for='failed_count'>{each?.failed} <Info size={18} className='mx-2' /></p>
                                          <ReactTooltip id='failed_count' multiline>
                                            {`Failed data are showing by ${data?.days} days and getting it from resque`}
                                          </ReactTooltip>
                                        </>
                                    }
                                  </td>
                                </tr>
                              )) }
                            </tbody>
                          </table>
                          </> : <>
                          <p className='fw-bold my-0'>Distributed Recrawl Worker Configuration</p>
                          <Alert color='primary'> No data Available </Alert> </> }
                        </CardBody>
                      </Card>
                    </Row>
                  </div>
              </TabPane>

              <TabPane tabId="3">
                  <div>
                    <Row>
                      <Col md={6}>
                        <Card>
                          <CardBody>
                              <p className='fw-bold'>Days to finish ETA: {data?.days_to_finish_eta}</p>
                              <p>Queue Size / Feed Crawl Avg: {data?.queue_size_and_feed_crawl_avg} </p>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={6}>
                        <Card>
                          <CardBody>
                              <p className='fw-bold'>Max pop urls count</p>
                              <p>{data?.max_pop_urls_count}</p>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row className='my-4'>
                        {datediff(fromDate, endDate) <= 10 ? <Card>
                          <CardBody>
                            <h6 className='fw-bold text-center mt-2 mb-3'>Average time take for every 2000 urls in each stage</h6>
                            <Row className='mb-2'>
                              <Col md={7} className='my-3'></Col>
                              <Col md={5} className='my-3'>
                                  <div className='d-flex justify-content-center align-items-center'>
                                      <h6 className='my-0 me-2'>Search:</h6>
                                      <input type='text' className='form-control' value={searchValue2} 
                                      onChange={(e) => setSearchValue2(e.target?.value)} />
                                  </div>
                              </Col>
                            </Row>
                            <DataTable columns={columns} data={duplicateData2} noHeader />
                          </CardBody>
                        </Card> : 
                        <Alert color='primary'>
                          <p>Date range should not be more than 10 days.</p>
                        </Alert>
                        }
                    </Row>
                  </div>
              </TabPane>
          </TabContent>
        </div>
      </div>
      : <Card>
          <CardBody>
            <Row className='my-3'>
              <Col md={12}>
                <h6 className='fw-bold'>Site Name</h6>
                <AsyncSelect cacheOptions defaultOptions={dropdownOptions} name='site_name' 
                  value={selectedSite} loadOptions={promiseOptions} onChange={res => setSelectedSite(res)}
                  placeholder="Select a site name" />
                  <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
              </Col>
            </Row>
            <Row className='align-items-end my-3'>
              <Col md={5}>
                <h6 className='fw-bold'>From date</h6>
                <DatePicker selected={fromDate} onChange={(date) => setFromDate(date)}
                  maxDate={endDate} className='form-control' dateFormat={"YYY/MM/dd"} />
              </Col>
              <Col md={5}>
                <h6 className='fw-bold'>To date</h6>
                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)}
                  maxDate={new Date()} className='form-control' minDate={fromDate} dateFormat={"YYY/MM/dd"} />
              </Col>
              <Col md={2} className='d-flex justify-content-center mt-3'>
                <button className='btn btn-success my-auto w-100' onClick={() => getUploadData()}>Get Data</button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        )
      }
    </Container>
  )
}
