import React, {useState, useEffect} from 'react'
import { Container, Row, Col } from 'reactstrap'
import axios from 'axios'
import AsyncSelect from 'react-select/async';
import DatePicker from 'react-datepicker'
import { toast } from 'react-toastify';

export default function CollectFailedUrl() {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [dropdownOptions, setDropdownOptions] = useState({})
    const [selectedSite, setSelectedSite] = useState({})
    const [selectedFromDate, setSelectedFromDate] = useState('')
    const [selectedOption, setSelectedOption] = useState('')
    const [selectedToDate, setSelectedToDate] = useState('')
    const [particularDate, setParticularDate] = useState('')

    const today = new Date(Date.now())
    useEffect(() => {
        fetchSiteNames();
    }, [])
    
    function fetchSiteNames() {
        axios.get('/api/v1/internal/dropdown_list_helper')
        .then(res => {
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
            let group = {}, children = []
            group['label'] = <div className="fw-bold col text-black">{key}</div>
            site_names[key].map(item => {
                children.push({ label: item, value: item })
            })
            group['options'] = children;
            site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setDropdownOptions(site_names_options)
        })
        .catch(err => {
            console.error(err?.message || 'Oops something went wrong')
        })
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('/');
    }
      
    const promiseOptions = (inputValue) => {
        if(inputValue.length < 3){
            return null
        } else {
            return new Promise((resolve) => {
            axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
                if (res.status == '200'){
                let site_names = res.data.site_names, site_names_options = []
                for (var key in site_names) {
                    let group = {}, children = []
                    group['label'] = <div className="fw-bold col text-black">{key}</div>
                    site_names[key].map(item => {
                    children.push({ label: item, value: item })
                    })
                    group['options'] = children;
                    site_names_options.push(group)
                }
                site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                setDropdownOptions(site_names_options)
                resolve(site_names_options)
                }
            }).catch(err => {
                    console.log(`err`, err)
                })
            })
        }
    }

    const handleSubmit = () => {
        setLoading(true)
        setErrors({})
        let tempObj = {}
        if (!(selectedSite?.value)){
            tempObj = {...tempObj, site:true}
        }
        if (selectedOption?.length < 1){
            tempObj = {...tempObj, option:true}
        }
        if (selectedOption == 'one' && particularDate?.toString()?.length < 1){
            tempObj = {...tempObj, crawl_date: true}
        }
        if (selectedOption == 'range' && selectedFromDate?.toString()?.length < 1){
            tempObj = {...tempObj, from_date: true}
        }
        if (selectedOption == 'range' && selectedToDate?.toString()?.length < 1){
            tempObj = {...tempObj, to_date: true}
        }
        setErrors(tempObj)

        if (Object.keys(tempObj)?.length == 0){
            let resultObj = {
                "site_name": selectedSite?.value, 
                "type_of_crawldate": selectedOption,
                "normal_crawldate": selectedOption == 'one' ? formatDate(particularDate) : '',
                "from_crawl_date": selectedOption == 'range'? formatDate(selectedFromDate) : '',
                "to_crawl_date": selectedOption == 'range'? formatDate(selectedToDate) : '',
            }
            axios.post('/api/v1/internal/failed_urls/get_failed_urls', {
                filter: resultObj
            }).then(res => {
                toast.success(res?.data?.message)
                setLoading(false)
                window.location = `/internal/command_dashboard`
            }).catch(err => {
                toast.error(err?.response?.data?.message)
                setLoading(false)
            })
        }
        else {
            setLoading(false)
        }
    }

    return (
        <Container fluid>
            <Row>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Outer Command (Non-Editable)</h6>
                    <input className='form-control' value='collect_failed_urls'
                        disabled />
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Inner Command (Non-Editable)</h6>
                    <input className='form-control' value='collect_failed_urls'
                        disabled />
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Nested Inner Command (Non-Editable)</h6>
                    <input className='form-control' value='collect_failed_urls'
                        disabled />
                </Col>
                <Col md={12} className='my-3'>
                    <h6 className='fw-bold'>Site Name*</h6>
                    <AsyncSelect cacheOptions defaultOptions={dropdownOptions} name='site_name' 
                        value={selectedSite} loadOptions={promiseOptions} onChange={res => setSelectedSite(res)}
                        placeholder="Select a site name" />
                    {errors?.site && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                    <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
                </Col>
                <Col md={12} className='my-3' >
                    <h6 className='fw-bold'>
                        Select One Crawldate / Range of Crawldates
                    </h6>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="optionnnn"
                            checked={selectedOption == 'one'} 
                            onChange={() => setSelectedOption('one')} id="optionnnn1" />
                        <label className="form-check-label" htmlFor="optionnnn1">
                            Get failed urls for one crawldate
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="optionnnn" 
                            checked={selectedOption == 'range'}
                            onChange={() => setSelectedOption('range')} id="optionnnn2" />
                        <label className="form-check-label" htmlFor="optionnnn2">
                            Get failed urls for range of crawldates
                        </label>
                    </div>
                    {errors?.option && <h6 className='fw-bold text-danger my-2'> You must select an option </h6>}
                </Col>
                {selectedOption?.length > 0 && <Col md={12} className='my-3' >
                    {selectedOption == 'one' && <Row>
                        <Col md={6}>
                            <h6 className='fw-bold'>Crawldate*</h6>
                            <DatePicker className="form-control my-2" selected={particularDate}
                                maxDate={today} onChange={(date) => setParticularDate(date)} dateFormat="yyyy/MM/dd" />
                            {errors?.crawl_date && 
                                <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                        </Col>
                    </Row>}

                    {selectedOption == 'range' && <Row>
                        <Col md={6}>
                            <h6 className='fw-bold'>From date</h6>
                            <DatePicker className="form-control my-2" selected={selectedFromDate}
                                maxDate={today} onChange={(date) => setSelectedFromDate(date)} dateFormat="yyyy/MM/dd" />
                            {errors?.from_date && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                        </Col>    
                        <Col md={6}>
                            <h6 className='fw-bold'>To date</h6>
                            <DatePicker className="form-control my-2" selected={selectedToDate}
                                maxDate={today} onChange={(date) => setSelectedToDate(date)} dateFormat="yyyy/MM/dd" />
                            {errors?.to_date && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                        </Col> 
                    </Row>}
                </Col>}
                <Col md={6} className='my-3'>
                    <button className='btn btn-success' onClick={handleSubmit}
                        disabled={loading} style={{margin: '25px auto', width: '100%'}}> Submit </button>
                </Col>
            </Row>
        </Container>
    )
}
