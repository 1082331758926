import React from 'react'

export default function ExportCSVButton({ data }) {
  const exportToCSV = () => {
    const csvRows = [];

    // Extract headers
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));

    // Extract data rows
    data.forEach(row => {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    });

    // Join rows with newline character
    const csvContent = csvRows.join('\n');

    // Create a link element
    const encodedUri = encodeURI('data:text/csv;charset=utf-8,' + csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'high_level_summary.csv');
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up
    document.body.removeChild(link);
  }

  return (
    <button className='btn btn-primary mb-2' onClick={exportToCSV}>Export to CSV</button>
  );
};
