import React from 'react'
import { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'reactstrap';
import ErrorMessageModal from '../Modal/ErrorMessageModal.component';
import { toast } from 'react-toastify';
import axios from 'axios';
export default function RemoveMemberButton({ color, size, children, data, organizationName, ...otherProps }) {

  // Modal State
  const [modalOpen, setModalOpen] = useState(false);
  // Pop-up message for modal
  const [popupMessage, setPopupMessage] = useState('');
  // Error Modal
  const [errorModal, setErrorModal] = useState(false);

  // Helper function to open and close modal
  function onModalOpenHandler() {
    if (data) {
      setModalOpen(!modalOpen);
    }
  };

  // Helper function to open and close the error modal
  function onErrorModalOpenHandler() {
    setErrorModal(!errorModal);
  }

  // Helper function to remove user from application
  function onRemoveMemberButtonClickHandler() {
    if (data?.status == 'Invitation Sent') {
      axios.delete(`/api/v1/organizations/${organizationName}/user_invites/${data.id}?page_type=client&controller_name=user_invites`).then(res => {
        toast.success(res?.data?.message);
        setModalOpen(false);
        setTimeout(() => { location.reload(); }, 650);
      }).catch(err => {
        toast.error(err?.response?.data?.message);
      })
    }
    else {
      axios.get(`/api/v1/organizations/${organizationName}/members/${data.id}/can_remove_member?page_type=client&controller_name=members`)
        .then(res => {
          if (res?.data?.can_remove_member) {
            axios.get(`/api/v1/organizations/${organizationName}/members/${data.id}/can_remove_notification?page_type=client&controller_name=members`)
              .then(res2 => {
                if (res2.data?.can_remove_notification_preference) {
                  axios.delete(`/api/v1/organizations/${organizationName}/members/${data.id}?page_type=client&controller_name=members`).then(res => {
                    toast.success(res?.data?.message);
                    setModalOpen(false);
                    setTimeout(() => { location.reload(); }, 650);
                  }).catch(err => {
                    toast.error(err?.response?.data?.message);
                  });
                }
                else {
                  // toast.error(res?.data?.popup_message);
                  setPopupMessage(res?.data?.popup_message);
                  setErrorModal(true);
                }
              })
          }
          else {
            // toast.error(res?.data?.popup_message);
            setPopupMessage(res?.data?.popup_message);
            setErrorModal(true);
          }
        });
    }
  }
  return (
    <>
      <div>
        <Modal funk={"true"} isOpen={modalOpen} toggle={onModalOpenHandler} centered size='md'>
          <ModalBody className='px-5'>
            <p className='h6'>Are you sure you want to delete user <b>{data?.email}</b> ?</p>
          </ModalBody>
          <ModalFooter>
            <Button color="light" onClick={onModalOpenHandler}>Cancel</Button>
            <Button color='danger' onClick={onRemoveMemberButtonClickHandler}>Remove user</Button>
          </ModalFooter>
        </Modal>
        {
          errorModal && (<ErrorMessageModal messageContent={popupMessage} errorModalOpenHandler={onErrorModalOpenHandler} />)
        }
      </div>
      <Button color={color} size={size} {...otherProps} onClick={onModalOpenHandler} >{children}</Button>
    </>
  )
};
