import React, { Fragment } from 'react'
import { Row, Col, Container, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Home } from 'react-feather'
export default function NewQaToolBreadcrumb({ siteName, ParentPath, ParentTitle, pagePath, PagePathTitle }) {
  return (
    <Fragment>
      <Container fluid>
        <div className="page-title">
          <Row className=''>
            <Col xs="6">
              <h3>Internal Dashboard</h3>
            </Col>
            <Col xs="6">
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to={`/internal`}><Home /></Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to={ParentPath}>
                    {ParentTitle}
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to={pagePath}>
                    {PagePathTitle}
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <b>{siteName}</b>
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  )
}