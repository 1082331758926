import React from "react";
import { useState, useEffect } from "react";
import '../../../stylesheets/custom.scss';
import generateUrl from "./CommonCode/UrlGenerator";

import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import { Nav, NavItem, NavLink } from "reactstrap";
import { Tab, TabContent, TabPane } from "reactstrap";
import { Card, CardBody } from "reactstrap";
import { Home } from "react-feather";
import { Link } from "react-router-dom";

import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";
import axios from "axios";

// 42 SIGNALS KIBANA VERSION 8.0 HOST NAME
import { KIBANA_42S_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

const ES42S = () => {

    const [activeTab, setActiveTab] = useState('esClusterHealth')
    const [health, setHealth] = useState({});
    const [nodesInfo, setNodesInfo] = useState([]);
    const [nodeSearch, setNodeSearch] = useState([]);
    const [shardInfo, setShardInfo] = useState([]);
    const [shardSearch, setShardSearch] = useState([]);
    const [nodeSearchText, setNodeSearchText] = useState('');
    const [shardSearchText, setShardSearchText] = useState('');
    const [indices, setIndices] = useState([]);
    const [indicesSearch, setIndicesSearch] = useState([]);
    const [indicesSearchText, setIndicesSearchText] = useState('');
    const [missingNodes, setMissingNodes] = useState([])
    const [urlMissingNodes, setUrlMissingNodes] = useState([])
    const [env, setEnv] = useState('')
    const [es42signalsHostName, setEs42signalsHostName] = useState('')
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        axios.get(`/api/v1/internal/es_cluster_health/es_42signals`)
            .then(res => {
                setEs42signalsHostName(res.data.es_42signals_host_name)
                setHealth(res.data.es_42signals_health_response);
                setNodesInfo(res.data.es_42signals_nodes_info);
                setShardInfo(res.data.es_42signals_shards_info);
                setNodeSearch(res.data.es_42signals_nodes_info);
                setShardSearch(res.data.es_42signals_shards_info);
                setIndices(res.data.es_42signals_indices_info);
                setIndicesSearch(res.data.es_42signals_indices_info)
                setEnv(res.data.env);
                setMissingNodes(res.data.missing_es_nodes_from_config);
                setUrlMissingNodes(res.data.missing_nodes_in_url_response);
                setLoading(false);

            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong');
                setLoading(false);
            })
    }, []);

    const columns1 = [
        { label: "Cluster name", value: "cluster_name" },
        { label: "timed_out", value: health.timed_out ? "true" : "false" },
        { label: "Active primary shards", value: "active_primary_shards" },
        { label: "Active shards", value: "active_shards" },
        { label: "Relocating shards", value: "relocating_shards" },
        { label: "Initializing shards", value: "initializing_shards" }
    ]

    const columns2 = [
        { label: "Unassigned shards", value: "unassigned_shards" },
        { label: "Delayed unassigned shards", value: "delayed_unassigned_shards" },
        { label: "Number of pending tasks", value: "number_of_pending_tasks" },
        { label: "Number of in flight fetch", value: "number_of_in_flight_fetch" },
        { label: "Task max waiting in queue millis", value: "task_max_waiting_in_queue_millis" },
        { label: "Active shards percent as number", value: "active_shards_percent_as_number" }
    ]

    const nodeColumns = [
        { name: "ip", selector: "ip", sortable: true, center: true },
        { name: "heap.percent", selector: "heap_percent", sortable: true, center: true },
        { name: "ram.percent", selector: "ram_percent", sortable: true, center: true },
        { name: "cpu", selector: "cpu", sortable: true, center: true },
        { name: "load_1m", selector: "load_1m", sortable: true, center: true },
        { name: "load_5m", selector: "load_5m", sortable: true, center: true },
        { name: "load_15m", selector: "load_15m", sortable: true, center: true },
        { name: "node.role", selector: "node_role", sortable: true, center: true },
        { name: "master", selector: "master", sortable: true, center: true },
        { name: "name", selector: "name", sortable: true, center: true }
    ]

    const shardColumns = [
        { name: "shards", selector: "shards", sortable: true, center: true },
        { name: "disk.indices", selector: "disk_indices", sortable: true, center: true },
        { name: "disk.used", selector: "disk_used", sortable: true, center: true },
        { name: "disk.avail", selector: "disk_avail", sortable: true, center: true },
        { name: "disk.total", selector: "disk_total", sortable: true, center: true },
        { name: "disk.percent", selector: "disk_percent", sortable: true, center: true },
        { name: "host", selector: "host", sortable: true, center: true },
        { name: "ip", selector: "ip", sortable: true, center: true },
        { name: "node", selector: "node", sortable: true, center: true },
    ]

    const indicesColumns = [
        { name: "health", selector: "health", sortable: true, center: true },
        { name: "status", selector: "status", sortable: true, center: true },
        { name: "index", selector: "index", sortable: true, center: true },
        { name: "uuid", selector: "uuid", sortable: true, center: true },
        { name: "pri", selector: "pri", sortable: true, center: true },
        { name: "rep", selector: "rep", sortable: true, center: true },
        { name: "docs.count", selector: "docs_count", sortable: true, center: true },
        { name: "docs.deleted", selector: "docs_deleted", sortable: true, center: true },
        { name: "store.size", selector: "store_size", sortable: true, center: true },
        { name: "pri.store.size", selector: "pri_store_size", sortable: true, center: true },
    ]

    function displayStatus() {
        if (health.status == 'green') {
            return <h2 className="text-navy">
                <i className="fa fa-play fa-rotate-270"></i> {health.status}
            </h2>
        }
        else if (health.status == 'yellow') {
            return <h2 className="text-warning">
                <i className="fa fa-play fa-rotate-30"></i> {health.status}
            </h2>
        }
        else {
            return <h2 className="text-danger">
                <i className="fa fa-play fa-rotate-90"></i> {health.status}
            </h2>
        }
    }

    const handleNodeChange = event => {
        if (event.target.value == '') {
            setNodeSearch(nodesInfo)
        }
        else {
            setNodeSearch(nodesInfo.filter(item => item.ip.includes(event.target.value) || item.name.includes(event.target.value) || item.load_1m.includes(event.target.value) || item.load_5m.includes(event.target.value) || item.heap_percent.includes(event.target.value) || item.cpu.includes(event.target.value) || item.master.includes(event.target.value) || item.node_role.includes(event.target.value) || item.ram_percent.includes(event.target.value)))
        }
        setNodeSearchText(event.target.value);
    }

    const handleShardChange = event => {
        if (event.target.value == '') {
            setShardSearch(shardInfo)
        }
        else {
            setShardSearch(shardInfo.filter(item => item.ip.includes(event.target.value) || item.disk_avail.includes(event.target.value) || item.disk_indices.includes(event.target.value) || item.disk_percent.includes(event.target.value) || item.disk_total.includes(event.target.value) || item.disk_used.includes(event.target.value) || item.host.includes(event.target.value) || item.node.includes(event.target.value) || item.shards.includes(event.target.value)))
        }
        setShardSearchText(event.target.value);
    }

    const handleIndicesChange = event => {
        if (event.target.value == '') {
            setIndicesSearch(indices)
        }
        else {
            setIndicesSearch(indices.filter(item => item.status.includes(event.target.value) || item.health.includes(event.target.value) || item.index.includes(event.target.value) || item.uuid.includes(event.target.value) || item.pri.includes(event.target.value) || item.rep.includes(event.target.value) || item.docs_count.includes(event.target.value) || item.docs_deleted.includes(event.target.value) || item.store_size.includes(event.target.value) || item.pri_store_size.includes(event.target.value)))
        }
        setIndicesSearchText(event.target.value);
    }

    function displayMissingNodes() {

        let node1 = '', node2 = '';
        if (missingNodes.length != 0) {
            node1 = <p><strong>Missing ES nodes in sdf config file: </strong>{missingNodes.join(", ")}</p>
        }
        if (urlMissingNodes.length != 0) {
            node2 = <p><strong>URL response and SDF config hosts doesn't match: </strong>{urlMissingNodes.join(", ")}</p>
        }
        return <div>
            {node1}
            {node2}
        </div>
    }

    return (
        <Container fluid>
            <div className="page-title">
                <Row>
                    <Col xs="6">
                        <h3> Internal Dashboard</h3>
                    </Col>
                    <Col xs="6">
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <Link to={'/internal'}>
                                    <Home />
                                </Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem className="fw-bold">
                                ES 42Signals
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
            {loading ? <Loader type="Puff" color="#00BFFF" height={100} width={100} className='pc-loader' />
                :
                <Card>
                    <CardBody>
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={activeTab == 'esClusterHealth' ? 'active' : ''} onClick={() => setActiveTab('esClusterHealth')}>
                                    ES 42Signals Cluster Health
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={activeTab == 'kibanaMonitoringUrl' ? 'active' : ''} onClick={() => setActiveTab('kibanaMonitoringUrl')}>
                                    Kibana Monitoring URL

                                </NavLink>
                            </NavItem><NavItem>
                                <NavLink className={activeTab == 'indices' ? 'active' : ''} onClick={() => setActiveTab('indices')}>
                                    ES 42SIgnals Indices
                                </NavLink>
                            </NavItem><NavItem>
                                <NavLink className={activeTab == 'missingNodes' ? 'active' : ''} onClick={() => setActiveTab('missingNodes')}>
                                    Missing Nodes
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId={'esClusterHealth'} className="mt-4">
                                <div>
                                    <Row className="borders">
                                        <Col className="fw-bold">
                                            ES Cluster Health
                                        </Col>
                                        <Col className="text-end">
                                            <a href={generateUrl("clusterHealthApiUrl", es42signalsHostName)} target={'_blank'} className="text-secondary">ES 42Signals Cluster Health Link</a>
                                        </Col>
                                    </Row>
                                </div>
                                <Row className="mt-2 mb-4">
                                    <Col md="2" className="text-center">
                                        {displayStatus()}
                                        <br />
                                        <p className="fw-bold">No of nodes: {health.number_of_nodes}</p>
                                        <p className="fw-bold">Data nodes: {health.number_of_data_nodes}</p>
                                    </Col>
                                    <Col md="5">
                                        <table className="table">
                                            <tbody>
                                                {columns1.map(item => {
                                                    return <tr key={item.label}>
                                                        <td className={`fw-bold ${(item.label == 'Active primary shards' || item.label == 'Active shards' || item.label == 'Initializing shards') ? 'text-navy' : ''}`}>{item.label}: {item.label == 'timed_out' ? item.value : health[item.value]}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col md="5">
                                        <table className="table">
                                            <tbody>
                                                {columns2.map(item => {
                                                    return <tr key={item.label}>
                                                        <td className={`fw-bold ${(item.label == 'Unassigned shards' || item.label == 'Active shards percent as number') ? 'text-navy' : ''}`}>{item.label}: {health[item.value]}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                                <Row className="borders">
                                    <Col className="fw-bold">
                                        ES 42Signals Nodes
                                    </Col>
                                    <Col className="text-end">
                                        <a href={generateUrl("clusterNodeApiUrl", es42signalsHostName)} target="_blank" className="text-secondary">ES 42Signals Nodes Link</a>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col>
                                        <div className="float-end">
                                            <Row className="align-items-center">
                                                <Col sm="3">Search</Col>
                                                <Col>
                                                    <input type="text" className="form-control" value={nodeSearchText} onChange={handleNodeChange} />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <DataTable
                                    columns={nodeColumns}
                                    data={nodeSearch}
                                    highlightOnHover
                                    noHeader
                                    pagination />
                                <Row className="borders">
                                    <Col className="fw-bold">
                                        ES 42Signals Shard allocation
                                    </Col>
                                    <Col className="text-end">
                                        <a href={generateUrl("shardAllocationApiUrl", es42signalsHostName)} target="_blank" className="text-secondary">ES 42Signals Shard Allocation Link</a>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col>
                                        <div className="float-end">
                                            <Row className="align-items-center">
                                                <Col sm="3">Search</Col>
                                                <Col>
                                                    <input type="text" className="form-control" value={shardSearchText} onChange={handleShardChange} />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <DataTable
                                    columns={shardColumns}
                                    data={shardSearch}
                                    highlightOnHover
                                    noHeader
                                    pagination />
                            </TabPane>
                            <TabPane tabId={'kibanaMonitoringUrl'} className="ms-2">
                                <div className="row text-center fw-bold">
                                    <a href={`${KIBANA_42S_URL}app/monitoring#/elasticsearch/nodes?_g=(cluster_uuid:'6_tyqXuBQz-kGy9k88KZMw',refreshInterval:(pause:!f,value:10000),time:(from:now-15m,to:now))`} className="escluster-header-link" target="_blank">Kibana Dashboard Link</a>
                                </div><br />
                                <iframe src={`${KIBANA_42S_URL}app/monitoring#/elasticsearch/nodes?_g=(cluster_uuid:'6_tyqXuBQz-kGy9k88KZMw',refreshInterval:(pause:!f,value:10000),time:(from:now-15m,to:now))`} frameBorder="0" width="100%" height="600px"></iframe>

                            </TabPane>
                            <TabPane tabId={'indices'}>
                                <div className="row text-center fw-bold escluster-header-link">
                                    <a href={generateUrl("clusterIndicesApiUrl", es42signalsHostName)} target="_blank">ES 42Signals Indices Link </a>
                                </div>
                                <Row className="my-3">
                                    <Col>
                                        <div className="float-end">
                                            <Row className="align-items-center">
                                                <Col sm="3">Search</Col>
                                                <Col>
                                                    <input type="text" className="form-control" value={indicesSearchText} onChange={handleIndicesChange} />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <DataTable
                                    columns={indicesColumns}
                                    data={indicesSearch}
                                    highlightOnHover
                                    noHeader
                                    pagination />
                            </TabPane>
                            <TabPane tabId={'missingNodes'}>
                                <div className="panel-body">
                                    {displayMissingNodes()}
                                </div>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>}
        </Container>
    )
}

export default ES42S;