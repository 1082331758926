import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { Container, Row, Col, } from 'reactstrap'
import { Home } from 'react-feather'

import { NewInternalBasePoint } from '../../../../Common/BaseEsPoints/InternalEsBasePoint';

export default function QualityAnalysisBreadcrumbs() {
  return (
    <Fragment>
      <Container fluid>
        <div className="page-title">
          <Row className=''>
            <Col xs="6">
              <h3>Internal Dashboard</h3>
            </Col>
            <Col xs="6">
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to={`/internal`}><Home /></Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  {(window.location.pathname == `${NewInternalBasePoint}/qa` || window.location.pathname == `${NewInternalBasePoint}/qa/`)
                    ? <b>All Sites </b>
                    : <a href={`${NewInternalBasePoint}/qa`}>
                      All Sites
                    </a>}
                </BreadcrumbItem>
                <BreadcrumbItem>
                  {(window.location.pathname == `${NewInternalBasePoint}/qa/pending` || window.location.pathname == `${NewInternalBasePoint}/qa/pending/`)
                    ? <b> List of Sites Pending for QA </b>
                    : <a href={`${NewInternalBasePoint}/qa/pending`}>
                      List of Sites Pending for QA
                    </a>}
                </BreadcrumbItem>
                {window.location.pathname.includes('/qa/search') && <BreadcrumbItem>
                  <b>Search Results</b>
                </BreadcrumbItem>}
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  )
}
