import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Home } from "react-feather";
import Loader from "react-loader-spinner";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import { Container, Col, Row, Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader } from "reactstrap";

// Kibana Version 8.0 link for jobspikr
import { KIBANA_JP_URL } from '../../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints'

export default function Countries30dVs7d() {
    const search = useLocation().search;
    const commit = new URLSearchParams(search).get("commit");
    const searchParams = {
        indexName: new URLSearchParams(search).get("filter[jp_index_name]")
    }
    const [indexName, setIndexName] = useState({ label: "jobspikr_v3_data-*", value: "jobspikr_v3_data-*" });
    const [last30Days, setLast30Days] = useState([]);
    const [last7Days, setLast7Days] = useState([]);
    const [missingCountries, setMissingCountries] = useState([]);
    const [newCountries, setNewCountries] = useState([]);
    const [last30dSearchText, setLast30dSearchText] = useState('');
    const [last7dSearchText, setLast7dSearchText] = useState('');
    const [missingSearchText, setMissingSearchText] = useState('');
    const [newSearchText, setNewSearchText] = useState('');
    const [last30dSearch, setLast30dSearch] = useState([]);
    const [last7dSearch, setLast7dSearch] = useState([]);
    const [missingSearch, setMissingSearch] = useState([]);
    const [newSearch, setNewSearch] = useState([]);
    const [loading, setLoading] = useState(false);


    const indexOptions = [
        { label: "jobspikr_v3_data-*", value: "jobspikr_v3_data-*" },
        { label: "jobspikr_v3_staging_data-*", value: "jobspikr_v3_staging_data-*" }
    ]
    const lastDaysColumns = [
        { name: "Country", selector: "country", sortable: true, center: true },
        { name: "Data count", selector: "data_count", sortable: true, center: true },

    ]
    const missingColumns = [
        { name: "Country", selector: "country", sortable: true, center: true },

    ]

    useEffect(() => {
        setSearchParamsData()
    }, []);

    function setSearchParamsData() {
        if (searchParams.indexName && searchParams.indexName != indexName.value) {
            setIndexName(indexOptions.filter(option => option.value == searchParams.indexName)[0])
        }
        if (commit == 'Get Data' && searchParams.indexName) {
            fetchData(searchParams.indexName)
        }
        else {
            fetchData()
        }
    }

    function fetchData(IndexName = indexName.value) {
        setLoading(true)

        axios.get(`/api/v1/jobspikr_v3_internal/jobspikr_graphs/countries_30d_vs_7d?filter[jp_index_name]=${IndexName}`)
            .then(res => {
                setLast30Days(res.data.last_30_days);
                setLast7Days(res.data.last_7_days);
                setMissingCountries(res.data.missing_countries);
                setNewCountries(res.data.new_countries)
                setLast30dSearch(res.data.last_30_days);
                setLast7dSearch(res.data.last_7_days);
                setMissingSearch(res.data.missing_countries);
                setNewSearch(res.data.new_countries);
                setLoading(false);
            }).catch(err => {
                console.error(err?.message || 'Oops something went wrong');

                setLoading(false);
            })
    }

    const handleLast30dChange = event => {
        if (event.target.value == '') {
            setLast30dSearch(last30Days)
        }
        else {
            console.log(event.target.value, 'value', last30Days.filter(item => item.country.includes(event.target.value)))
            setLast30dSearch(last30Days.filter(item => item.country.toLowerCase().includes(event.target.value)))
        }
        setLast30dSearchText(event.target.value)
    }

    const handleLast7dChange = event => {
        if (event.target.value == '') {
            setLast7dSearch(last30Days)
        }
        else {
            setLast7dSearch(last7Days.filter(item => item.country.toLowerCase().includes(event.target.value)))
        }
        setLast7dSearchText(event.target.value)
    }

    const handleMissingCountriesChange = event => {
        if (event.target.value == '') {
            setMissingSearch(missingCountries)
        }
        else {
            setMissingSearch(missingCountries.filter(item => item.country.toLowerCase().includes(event.target.value)))
        }
        setMissingSearchText(event.target.value)
    }

    const handleNewCountriesChange = event => {
        if (event.target.value == '') {
            setNewSearch(newCountries)
        }
        else {
            setNewSearch(newCountries.filter(item => item.country.toLowerCase().includes(event.target.value)))
        }
        setNewSearchText(event.target.value)
    }

    const handleGetData = () => {
        window.location.href = `/jobspikr_v3_internal/jobspikr_graphs/countries_30d_vs_7d?filter[jp_index_name]=${indexName.value}&commit=${'Get Data'}`
    }

    return <Container fluid>
        <div className="page-title">
            <Row>
                <Col xs="6">
                    <h3> Internal Dashboard</h3>
                </Col>
                <Col xs="6">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={'/internal'}>
                                <Home />
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem className="fw-bold">
                            {commit == 'Get Data' ? <a className="link-primary fw-normal" href={'/jobspikr_v3_internal/jobspikr_graphs/countries_30d_vs_7d'}>Countries: Last 30 days vs 7 days</a> : 'Countries: Last 30 days vs 7 days'}
                        </BreadcrumbItem>
                        {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                            Search
                        </BreadcrumbItem>}
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
        <Card>
            <CardBody>
                <Row className="align-items-end">
                    <Col sm="4">
                        <label className="fw-bold">JP index name</label>
                        <Select
                            options={indexOptions}
                            value={indexName}
                            onChange={option => setIndexName(option)} />
                    </Col>
                    <Col sm="3">
                        <button className="btn btn-success" onClick={handleGetData}>Get Data</button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
        {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> : <Card>
            <CardHeader className="p-4">
                <h6>
                    Countries: Last 30 days vs 7 days &nbsp; &nbsp;  JP index name:<strong>{indexName.value}</strong>
                </h6>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col>
                        <p>Countries crawled in last 30 days:<span className="fw-bold">{last30Days.length}</span></p> <br />
                        <a className="link-primary" href={indexName.value == 'jobspikr_v3_data-*' ? `${KIBANA_JP_URL}app/kibana#/visualize/edit/JPP-QA-Inferred-Country-Counts?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-30d,mode:quick,to:now))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'*')),uiState:(),vis:(aggs:!((id:'1',params:(),schema:metric,type:count),(id:'2',params:(field:inferred_country.raw,order:desc,orderBy:'1',size:1000),schema:bucket,type:terms)),listeners:(),params:(perPage:10,showMeticsAtAllLevels:!f,showPartialRows:!f),title:'JPP%20QA%20-%20Inferred%20Country%20Counts',type:table))` : `${KIBANA_JP_URL}app/kibana#/visualize/edit/JPS-QA-Inferred-Country-Counts?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'*')),uiState:(),vis:(aggs:!((id:'1',params:(),schema:metric,type:count),(id:'2',params:(field:inferred_country.raw,order:desc,orderBy:'1',size:1000),schema:bucket,type:terms)),listeners:(),params:(perPage:10,showMeticsAtAllLevels:!f,showPartialRows:!f),title:'JPS%20QA%20-%20Inferred%20Country%20Counts',type:table))`} target='_blank'>Click here to see below table in kibana</a>
                        <Row className="my-3">
                            <Col>
                                <div className="float-end">
                                    <Row className="align-items-center">
                                        <Col sm="3">Search</Col>
                                        <Col>
                                            <input type="text" className="form-control" value={last30dSearchText} onChange={handleLast30dChange} />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <DataTable
                            columns={lastDaysColumns}
                            data={last30dSearch}
                            pagination
                            highlightOnHover
                            noHeader

                        />
                    </Col>
                    <Col>
                        <p>Countries crawled in last 7 days:<span className="fw-bold">{last7Days.length}</span></p> <br />
                        <a className="link-primary" href={indexName.value == 'jobspikr_v3_data-*' ? `${KIBANA_JP_URL}app/kibana#/visualize/edit/JPP-QA-Inferred-Country-Counts?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'*')),uiState:(),vis:(aggs:!((id:'1',params:(),schema:metric,type:count),(id:'2',params:(field:inferred_country.raw,order:desc,orderBy:'1',size:1000),schema:bucket,type:terms)),listeners:(),params:(perPage:10,showMeticsAtAllLevels:!f,showPartialRows:!f),title:'JPP%20QA%20-%20Inferred%20Country%20Counts',type:table))` : `${KIBANA_JP_URL}app/kibana#/visualize/edit/JPS-QA-Inferred-Country-Counts?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'*')),uiState:(),vis:(aggs:!((id:'1',params:(),schema:metric,type:count),(id:'2',params:(field:inferred_country.raw,order:desc,orderBy:'1',size:1000),schema:bucket,type:terms)),listeners:(),params:(perPage:10,showMeticsAtAllLevels:!f,showPartialRows:!f),title:'JPS%20QA%20-%20Inferred%20Country%20Counts',type:table))`} target='_blank'>Click here to see below table in kibana</a>
                        <Row className="my-3">
                            <Col>
                                <div className="float-end">
                                    <Row className="align-items-center">
                                        <Col sm="3">Search</Col>
                                        <Col>
                                            <input type="text" className="form-control" value={last7dSearchText} onChange={handleLast7dChange} />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <DataTable
                            columns={lastDaysColumns}
                            data={last7dSearch}
                            pagination
                            highlightOnHover
                            noHeader

                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h6>Missing countries:<strong>{missingCountries.length}</strong></h6>
                        <Row className="my-3">
                            <Col>
                                <div className="float-end">
                                    <Row className="align-items-center">
                                        <Col sm="3">Search</Col>
                                        <Col>
                                            <input type="text" className="form-control" value={missingSearchText} onChange={handleMissingCountriesChange} />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <DataTable
                            columns={missingColumns}
                            data={missingSearch}
                            pagination
                            highlightOnHover
                            noHeader

                        />
                    </Col>
                    <Col>
                        <h6>New countries:<span className="fw-bold">{newCountries.length}</span></h6> <br />
                        <Row className="my-3">
                            <Col>
                                <div className="float-end">
                                    <Row className="align-items-center">
                                        <Col sm="3">Search</Col>
                                        <Col>
                                            <input type="text" className="form-control" value={newSearchText} onChange={handleNewCountriesChange} />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <DataTable
                            columns={missingColumns}
                            data={newSearch}
                            pagination
                            highlightOnHover
                            noHeader

                        />
                    </Col>
                </Row>
            </CardBody>
        </Card>}
    </Container>
}