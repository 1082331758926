import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Row, Col, Button } from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

export default function Feedback() {
    const [modal, setModal] = useState(false);
    const [loadingBtns, setLoadingBtns] = useState(false);

    const toggle = () => {
        setModal(curr => !curr);
    };

    const closeBtn = <button className="close" onClick={() => setModal(false)}> &times; </button>;

    const validationSchema = Yup.object({
        name: Yup.string().required('Please enter a valid name'),
        subject: Yup.string().min(2, 'Please enter a valid subject').required('Please enter a valid subject'),
        message: Yup.string().min(4, 'Please enter a valid message').required('Please enter a valid message'),
    });

    const initialValues = {
        name: '',
        subject: '',
        message: '',
    };

    const sendFeedback = (values, { setSubmitting, resetForm }) => {
        setLoadingBtns(true);
        axios.post(`/api/v1/feedback`, {
            feedback: {
                name: values.name,
                email: localStorage.getItem('authEmail'),
                subject: values.subject,
                message: values.message,
            },
        }).then(res => {
            toast.success(res.data.message);
            setModal(false);
            setLoadingBtns(false);
            setSubmitting(false);
            resetForm();
        }).catch(err => {
            toast.error(err.response.data.message);
            setLoadingBtns(false);
            setSubmitting(false);
        });
    };

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} backdrop='static' size='md'>
                <ModalHeader toggle={toggle} close={closeBtn}>
                    CrawlBoard Feedback
                </ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={sendFeedback}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Row className='my-3'>
                                    <Col lg={3}>
                                        <h6 className='fw-bold my-0 text-end'>Name* </h6>
                                    </Col>
                                    <Col lg={9}>
                                        <Field type="text" className='form-control' name="name" />
                                        <ErrorMessage name="name" component="div" className="m-0 error-msg mt-1 mb-2" />
                                    </Col>
                                </Row>
                                <Row className='my-3'>
                                    <Col lg={3}>
                                        <h6 className='fw-bold my-0 text-end'>Subject* </h6>
                                    </Col>
                                    <Col lg={9}>
                                        <Field type="text" className='form-control' name="subject" />
                                        <ErrorMessage name="subject" component="div" className="m-0 error-msg mt-1 mb-2" />
                                    </Col>
                                </Row>
                                <Row className='my-3'>
                                    <Col lg={3}>
                                        <h6 className='fw-bold my-0 text-end'>Message* </h6>
                                    </Col>
                                    <Col lg={9}>
                                        <Field as="textarea" rows="3" className='form-control' name="message" />
                                        <ErrorMessage name="message" component="div" className="m-0 error-msg mt-1 mb-2" />
                                    </Col>
                                </Row>
                                <ModalFooter>
                                    <Button type="submit" color="success" disabled={isSubmitting || loadingBtns}>Submit</Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
            <button className="btn btn-primary btn-xs feedback-btn" onClick={toggle}>
                Feedback
            </button>
        </>
    );
}
