import { StyleSheet } from '@react-pdf/renderer';
export const styles = StyleSheet.create({
    page: {
      backgroundColor: '#fff'
    },
    section: {
      margin: 10,
      padding: 10,
    },
    topContainer: {
      padding: 14,
      margin: 12,
      fontSize: 13,
      color: '#333',
      display: 'flex',
      flexDirection: 'row', 
      justifyContent: 'space-between',
      borderBottom: '1px solid black'
    },
    images: {
      width: 90,
      height: 55,
      margin: 0,
      padding: 0,
    },
    heading1: {
      fontSize: 15,
      fontWeight: 700,
      textAlign: 'center',
      margin: 10
    },
    flexContainer: { display: 'flex', flexDirection: 'row', 
      alignItems: 'center', width: '100%', padding: '9px 60px' },
    flex1: {width: '40%', fontSize: 14, fontWeight: '600'},
    flex2: {width: '20%', fontSize: 14, textAlign: 'center'},
    flex3: {width: '40%', fontSize: 13 },
    table: {
      width: '88%', margin: '10px 38px', border: '1px solid #ddd'
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      borderBottom: '2px solid #ddd',
      paddingTop: 8,
      paddingBottom: 8,
    },
    header: {
      borderTop: 'none',
    },
    bold: {
      backgroundColor: '#eee',
      fontWeight: 'bold',
    },
    row1: {
      fontSize: 13,
      textAlign: 'center',
      width: '10%',
    },
    row2: {
      fontSize: 13,
      textAlign: 'center',
      width: '50%',
    },
    row3: {
      fontSize: 13,
      textAlign: 'center',
      width: '20%',
    },
    row4: {
      fontSize: 13,
      textAlign: 'center',
      textTransform: 'capitalize',
      width: '20%',
    },
    row11: {
      fontSize: 13,
      textAlign: 'center',
      width: '14%',
    },
    row12: {
      fontSize: 13,
      textAlign: 'center',
      width: '13%',
    },
    row13: {
      fontSize: 13,
      textAlign: 'center',
      width: '13%',
    },
    row14: {
      fontSize: 13,
      textAlign: 'center',
      width: '20%',
    },
    row15: {
      fontSize: 13,
      textAlign: 'center',
      width: '20%',
    },
    row16: {
      fontSize: 13,
      textAlign: 'center',
      textTransform: 'capitalize',
      width: '20%',
    },
    row21: {
      fontSize: 13,
      textAlign: 'center',
      width: '6%',
    },
    row22: {
      fontSize: 13,
      textAlign: 'center',
      width: '40%',
    },
    row23: {
      fontSize: 13,
      textAlign: 'center',
      width: '18%',
    },
    row24: {
      fontSize: 13,
      textAlign: 'center',
      width: '18%',
    },
    row25: {
      fontSize: 13,
      textAlign: 'center',
      width: '18%',
    },
    sideHeading:{
      fontSize: 13,
      fontFamily: 'SourceSansPro',
      fontWeight: 700,
      margin: '10px 60px'
    },
    fwBold: {
      fontFamily: 'SourceSansPro',
      fontWeight: 700,
    },
  });
  