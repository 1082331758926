import React from 'react';
import { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Row, Col, Label, FormGroup, Input, Button } from 'reactstrap';
import DatePicker from "react-datepicker";


export default function EditDeliveryDetails({ version, localData, setLocalData, updateDeliveryDetails, toggle, modalOpen }) {


  return (
    <div>
      {/* The button that shows edit option in form */}
      <Button color='success' className='btn-sm' onClick={() => toggle()}>Edit</Button>

      {/* Modal */}
      <Modal isOpen={modalOpen} toggle={() => toggle()} size='lg'>
        <ModalHeader toggle={() => toggle()}>
          Edit Delivery Details
        </ModalHeader>
        <ModalBody>
          <div className='my-2 mx-3'>
            <Row className='my-1'>
              <Col lg={3}>
                <h6 className=''>Crawl Type: </h6>
              </Col>
              <Col lg={9}>
                {version === 'v2' &&
                  <div className='d-flex'>
                    <FormGroup check className='mx-2'>
                      <Label check>
                        <Input type="radio" name="radio1" checked={localData?.delivery_details && localData?.delivery_details?.crawl_type == 'none'}
                          onChange={() => onCrawlTypeSelectHandler("none")} />{' '} Not set
                      </Label>
                    </FormGroup>
                    <FormGroup check className='mx-2'>
                      <Label check>
                        <Input type="radio" name="radio1" checked={localData?.delivery_details && localData?.delivery_details?.crawl_type == 'fullcrawl'}
                          onChange={() => onCrawlTypeSelectHandler("fullcrawl")} />{' '} Full crawl
                      </Label>
                    </FormGroup>
                    <FormGroup check className='mx-2'>
                      <Label check>
                        <Input type="radio" name="radio1" checked={localData?.delivery_details && localData?.delivery_details?.crawl_type == 'incremental_recrawl'}
                          onChange={() => onCrawlTypeSelectHandler("incremental_recrawl")} />{' '} Incr Crawl
                      </Label>
                    </FormGroup>
                  </div>}
                {version === 'v3' &&
                  <select className='form-control show-arrow' name="crawl_type"
                    onChange={(e) => {
                      setLocalData({
                        ...localData, delivery_details: {
                          ...localData.delivery_details && localData?.delivery_details,
                          crawl_type: e.target.value
                        }
                      })
                    }}
                    defaultValue={localData?.delivery_details && localData?.delivery_details?.crawl_type || 'none'}>
                    <option value='none' disabled hidden>Select Crawl Option</option>
                    <option value='fullcrawl'>Full crawl</option>
                    <option value='incremental_recrawl'>Incremental Crawl</option>
                  </select>}
              </Col>
            </Row>
            <hr />
            <Row className='my-1'>
              <Col lg={3}>
                <h6 className=''>Data format: </h6>
              </Col>
              <Col lg={9}>
                {version == 'v2' &&
                  <div className='d-flex'>
                    <FormGroup check className='mx-2'>
                      <Label check>
                        <Input type="radio" name="radio2" checked={localData?.delivery_details && localData?.delivery_details?.delivery_format == 'json'}
                          onChange={() => onDeliverFormatSelectHandler("json")} />{' '} JSON
                      </Label>
                    </FormGroup>
                    <FormGroup check className='mx-2'>
                      <Label check>
                        <Input type="radio" name="radio2" checked={localData?.delivery_details && localData?.delivery_details?.delivery_format == 'csv'}
                          onChange={() => onDeliverFormatSelectHandler("csv")} />{' '} CSV
                      </Label>
                    </FormGroup>
                    <FormGroup check className='mx-2'>
                      <Label check>
                        <Input type="radio" name="radio2" checked={localData?.delivery_details && localData?.delivery_details?.delivery_format == 'xml'}
                          onChange={() => onDeliverFormatSelectHandler("xml")} />{' '} XML
                      </Label>
                    </FormGroup>
                    <FormGroup check className='mx-2'>
                      <Label check>
                        <Input type="radio" name="radio2" checked={localData?.delivery_details && localData?.delivery_details?.delivery_format == 'tsv'}
                          onChange={() => onDeliverFormatSelectHandler("tsv")} />{' '} TSV
                      </Label>
                    </FormGroup>
                  </div>}
                {version == 'v3' &&
                  <select className='form-control show-arrow' name="data_format"
                    defaultValue={localData?.delivery_details
                      ? localData?.delivery_details.delivery_format : 'json'}
                    onChange={(e) => setLocalData({
                      ...localData, delivery_details: {
                        ...localData.delivery_details && localData?.delivery_details,
                        delivery_format: e.target.value
                      }
                    })}>
                    <option value='json'>JSON</option>
                    <option value='ld_json'>LD-JSON</option>
                    <option value='xml'>XML</option>
                    <option value='csv'>CSV</option>
                    <option value='tsv'>TSV</option>
                  </select>}
              </Col>
            </Row>
            <hr />
            <Row className='my-1'>
              <Col lg={3}>
                <h6 className=''>Frequency: </h6>
              </Col>
              <Col lg={9}>
                <select className='form-control show-arrow' name="frequency"
                  onChange={(e) => handleFrequency(e.target.value)}
                  defaultValue={localData?.delivery_details ?
                    localData?.delivery_details.frequency : 'daily'}>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="other_frequency">Other</option>
                </select>
              </Col>
            </Row>
            {localData.delivery_details && localData?.delivery_details?.frequency == 'weekly' && (
              <Row className='my-4'>
                <Col lg={3}>
                  <h6 className=''>Days: </h6>
                </Col>
                <Col lg={9}>
                  <div className='d-flex flex-wrap'>
                    <FormGroup check className='mx-3'>
                      <Label check>
                        <Input type="radio" name="radio5" checked={localData?.delivery_frequency_details &&
                          localData?.delivery_frequency_details?.days == 'monday'}
                          onClick={() => handleFreqWeekly("monday")}
                          onChange={() => handleFreqWeekly("monday")} />{' '} Monday
                      </Label>
                    </FormGroup>
                    <FormGroup check className='mx-3'>
                      <Label check>
                        <Input type="radio" name="radio5" checked={localData?.delivery_frequency_details &&
                          localData?.delivery_frequency_details?.days == 'tuesday'}
                          onClick={() => handleFreqWeekly("tuesday")}
                          onChange={() => handleFreqWeekly("tuesday")} />{' '} Tuesday
                      </Label>
                    </FormGroup>
                    <FormGroup check className='mx-3'>
                      <Label check>
                        <Input type="radio" name="radio5" checked={localData?.delivery_frequency_details &&
                          localData?.delivery_frequency_details?.days == 'wednesday'}
                          onClick={() => handleFreqWeekly("wednesday")}
                          onChange={() => handleFreqWeekly("wednesday")} />{' '} Wednesday
                      </Label>
                    </FormGroup>
                    <FormGroup check className='mx-3'>
                      <Label check>
                        <Input type="radio" name="radio5" checked={localData?.delivery_frequency_details &&
                          localData?.delivery_frequency_details?.days == 'thursday'}
                          onClick={() => handleFreqWeekly("thursday")}
                          onChange={() => handleFreqWeekly("thursday")} />{' '} Thursday
                      </Label>
                    </FormGroup>
                    <FormGroup check className='mx-3'>
                      <Label check>
                        <Input type="radio" name="radio5" checked={localData?.delivery_frequency_details &&
                          localData?.delivery_frequency_details?.days == 'friday'}
                          onClick={() => handleFreqWeekly("friday")}
                          onChange={() => handleFreqWeekly("friday")} />{' '} Friday
                      </Label>
                    </FormGroup>
                    <FormGroup check className='mx-3'>
                      <Label check>
                        <Input type="radio" name="radio5" checked={localData?.delivery_frequency_details &&
                          localData?.delivery_frequency_details?.days == 'saturday'}
                          onClick={() => handleFreqWeekly("saturday")}
                          onChange={() => handleFreqWeekly("saturday")} />{' '} Saturday
                      </Label>
                    </FormGroup>
                    <FormGroup check className='mx-3'>
                      <Label check>
                        <Input type="radio" name="radio5" checked={localData?.delivery_frequency_details &&
                          localData?.delivery_frequency_details?.days == 'sunday'}
                          onClick={() => handleFreqWeekly("sunday")}
                          onChange={() => handleFreqWeekly("sunday")} />{' '} Sunday
                      </Label>
                    </FormGroup>
                  </div>
                </Col>
              </Row>
            )}
            {localData.delivery_details && localData?.delivery_details?.frequency == 'monthly' && (
              <Row className='my-4'>
                <Col lg={3}>
                  <h6 className=''>Day of Month: </h6>
                </Col>
                <Col lg={9}>
                  <DatePicker className="form-control digits"
                    selected={localData?.delivery_frequency_details?.date
                      ? new Date(localData?.delivery_frequency_details?.date) : new Date()}
                    onChange={handleDateChange} />
                </Col>
              </Row>
            )}
            {localData?.delivery_details && localData?.delivery_details?.frequency == 'other_frequency' && (
              <Row className='my-4'>
                <Col lg={3}>
                  <h6 className=''>Details: </h6>
                </Col>
                <Col lg={9}>
                  <Input className="form-control" name="details" type="text"
                    placeholder="" value={localData?.delivery_frequency_details?.other_details}
                    onChange={(e) => {
                      setLocalData({
                        ...localData,
                        delivery_frequency_details: { other_details: e.target.value }
                      })
                    }} />
                  <div className="text-muted text-sm">
                    *Minimum requirements - at least one crawl per month, for a minimum period of 3 months. Please note that we do not take up one-time data acquisition projects.
                  </div>
                </Col>
              </Row>
            )}
            <hr />
            <Row className='my-2'>
              <Col lg={3}>
                <h6 className=''>Delivery Method: </h6>
              </Col>
              <Col lg={9}>
                {version == 'v2' ?
                  <select className='form-control show-arrow'
                    onChange={(e) => handleSelect(e.target.value)}
                    defaultValue={localData?.delivery_details && localData?.delivery_details?.delivery_method || 'api'}>
                    <option value="api">Promptcloud Data API (Free)</option>
                    <option value="s3">S3 (+ $30 per month)</option>
                    <option value="dropbox">Dropbox (+ $30 per month)</option>
                    <option value="box">Box (+ $30 per month)</option>
                    <option value="ftp">FTP (+ $30 per month)</option>
                  </select>
                  : <select className='form-control show-arrow' name="delivery_method"
                    onChange={(e) => handleSelect(e.target.value)}
                    defaultValue={localData?.delivery_details && localData?.delivery_details?.delivery_method || 'api'}>
                    <option value="api">Promptcloud Data API (Free)</option>
                    <option value="s3">Amazon s3</option>
                    <option value="dropbox">Dropbox</option>
                    <option value="box">Box</option>
                    <option value="ftp">FTP</option>
                    <option value="sftp">sFTP</option>
                    <option value="microsoft_azure">Microsoft Azure</option>
                    <option value='google_drive'>Google drive</option>
                  </select>}
              </Col>
            </Row>
            <div className='my-2 float-end'>
              <a href='https://api.promptcloud.com/v2/data/info?id=demo&type=help' target="_blank" className='fw-bold'>Promptcloud API Help</a>
            </div>
            <br />
            {localData?.delivery_details && localData?.project_version === 'v2'
              && localData?.delivery_details?.delivery_method === 's3' && (
                <div className='mt-4'>
                  <hr />
                  <Row className='my-2'>
                    <Col lg={3}>
                      <h6 className=''>Bucket Name: </h6>
                    </Col>
                    <Col lg={9}>
                      <Input type="text" name="bucketname" className='form-control'
                        value={localData?.delivery_method_details?.s3_bucket_name || ''}
                        onChange={(e) => setLocalData({
                          ...localData, delivery_method_details: {
                            ...localData.delivery_method_details,
                            s3_bucket_name: e.target.value
                          }
                        })} />
                    </Col>
                  </Row>
                  <Row className='my-2'>
                    <Col lg={3}>
                      <h6 className=''>Bucket ID: </h6>
                    </Col>
                    <Col lg={9}>
                      <Input type="text" name="bucketname" className='form-control'
                        value={localData?.delivery_method_details?.s3_id || ''}
                        onChange={(e) => setLocalData({
                          ...localData, delivery_method_details: {
                            ...localData.delivery_method_details,
                            s3_id: e.target.value
                          }
                        })} />
                    </Col>
                  </Row>
                  <Row className='my-2'>
                    <Col lg={3}>
                      <h6 className=''>Bucket Key: </h6>
                    </Col>
                    <Col lg={9}>
                      <Input type="text" name="bucketname" className='form-control'
                        value={localData?.delivery_method_details?.s3_key || ''}
                        onChange={(e) => setLocalData({
                          ...localData, delivery_method_details: {
                            ...localData.delivery_method_details,
                            s3_key: e.target.value
                          }
                        })} />
                    </Col>
                  </Row>
                  <Row className='my-2'>
                    <Col lg={3}>
                      <h6 className=''>Region: </h6>
                    </Col>
                    <Col lg={9}>
                      <select className='form-control show-arrow'
                        defaultValue={localData?.delivery_method_details?.s3_location || ''}
                        onChange={(e) => setLocalData({
                          ...localData, delivery_method_details: {
                            ...localData.delivery_method_details,
                            s3_location: e.target.value
                          }
                        })}>
                        <option value=''>Select one</option>
                        <option value='us-east-1'>us-east-1</option>
                        <option value='us-west-1'>us-west-1</option>
                        <option value='us-west-2'>us-west-2</option>
                        <option value='ap-south-1'>ap-south-1</option>
                        <option value='ap-northeast-1'>ap-northeast-1</option>
                        <option value='ap-northeast-2'>ap-northeast-2</option>
                        <option value='ap-southeast-1'>ap-southeast-1</option>
                        <option value='ap-southeast-2'>ap-southeast-2</option>
                        <option value='eu-central-1'>eu-central-1</option>
                        <option value='eu-west-1'>eu-west-1</option>
                        <option value='sa-east-1'>sa-east-1</option>
                      </select>
                    </Col>
                  </Row>
                </div>
              )}
            {localData?.delivery_details && localData?.project_version === 'v2'
              && localData?.delivery_details?.delivery_method === 'dropbox' && (
                <div className='mt-4'>
                  <hr />
                  <Row className='my-2'>
                    <Col lg={3}>
                      <h6 className=''>Access Token: </h6>
                    </Col>
                    <Col lg={9}>
                      <Input type="text" name="dropbox_access_token" className='form-control'
                        value={localData?.delivery_method_details?.dropbox_access_token || ''}
                        onChange={(e) => setLocalData({
                          ...localData, delivery_method_details: {
                            dropbox_access_token: e.target.value
                          }
                        })} />
                    </Col>
                  </Row>
                </div>
              )}
            {localData?.delivery_details && localData?.project_version === 'v2'
              && localData?.delivery_details?.delivery_method === 'box' && (
                <div className='mt-4'>
                  <hr />
                  <Row className='my-2'>
                    <Col lg={3}>
                      <h6 className=''>Box ID: </h6>
                    </Col>
                    <Col lg={9}>
                      <Input type="text" name="box_id" className='form-control'
                        value={localData?.delivery_method_details?.box_id || ''}
                        onChange={(e) => setLocalData({
                          ...localData, delivery_method_details: {
                            ...localData.delivery_method_details,
                            box_id: e.target.value
                          }
                        })} />
                    </Col>
                  </Row>
                  <Row className='my-2'>
                    <Col lg={3}>
                      <h6 className=''>Box Secret: </h6>
                    </Col>
                    <Col lg={9}>
                      <Input type="text" name="box_secret" className='form-control'
                        value={localData?.delivery_method_details?.box_secret || ''}
                        onChange={(e) => setLocalData({
                          ...localData, delivery_method_details: {
                            ...localData.delivery_method_details,
                            box_secret: e.target.value
                          }
                        })} />
                    </Col>
                  </Row>
                  <Row className='my-2'>
                    <Col lg={3}>
                      <h6 className=''>Username: </h6>
                    </Col>
                    <Col lg={9}>
                      <Input type="text" name="box_username" className='form-control'
                        value={localData?.delivery_method_details?.box_username || ''}
                        onChange={(e) => setLocalData({
                          ...localData, delivery_method_details: {
                            ...localData.delivery_method_details,
                            box_username: e.target.value
                          }
                        })} />
                    </Col>
                  </Row>
                  <Row className='my-2'>
                    <Col lg={3}>
                      <h6 className=''>Password: </h6>
                    </Col>
                    <Col lg={9}>
                      <Input type="text" name="box_password" className='form-control'
                        value={localData?.delivery_method_details?.box_password || ''}
                        onChange={(e) => setLocalData({
                          ...localData, delivery_method_details: {
                            ...localData.delivery_method_details,
                            box_password: e.target.value
                          }
                        })} />
                    </Col>
                  </Row>
                </div>
              )}
            {localData?.delivery_details && localData?.project_version === 'v2'
              && localData?.delivery_details?.delivery_method === 'ftp' && (
                <div className='mt-4'>
                  <hr />
                  <Row className='my-2'>
                    <Col lg={3}>
                      <h6 className=''>Username: </h6>
                    </Col>
                    <Col lg={9}>
                      <Input type="text" name="ftp_username" className='form-control'
                        value={localData?.delivery_method_details?.ftp_username || ''}
                        onChange={(e) => setLocalData({
                          ...localData, delivery_method_details: {
                            ...localData.delivery_method_details,
                            ftp_username: e.target.value
                          }
                        })} />
                    </Col>
                  </Row>
                  <Row className='my-2'>
                    <Col lg={3}>
                      <h6 className=''>Password: </h6>
                    </Col>
                    <Col lg={9}>
                      <Input type="text" name="ftp_password" className='form-control'
                        value={localData?.delivery_method_details?.ftp_password || ''}
                        onChange={(e) => setLocalData({
                          ...localData, delivery_method_details: {
                            ...localData.delivery_method_details,
                            ftp_password: e.target.value
                          }
                        })} />
                    </Col>
                  </Row>
                  <Row className='my-2'>
                    <Col lg={3}>
                      <h6 className=''>Server: </h6>
                      <p className='fw-bold'>Add port number</p>
                    </Col>
                    <Col lg={9}>
                      <Input type="text" name="ftp_server" className='form-control'
                        value={localData?.delivery_method_details?.ftp_server || ''}
                        onChange={(e) => setLocalData({
                          ...localData, delivery_method_details: {
                            ...localData.delivery_method_details,
                            ftp_server: e.target.value
                          }
                        })} />
                    </Col>
                  </Row>
                </div>
              )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => { updateDeliveryDetails()}}>Done</Button>
        </ModalFooter>
      </Modal>
    </div>
  )

  // helper function to handle Crawl Type selection
  function onCrawlTypeSelectHandler(val) {
    setLocalData({
      ...localData, delivery_details: {
        ...localData.delivery_details && localData.delivery_details,
        crawl_type: val
      }
    })
  }

  // helper function to change delivery format
  function onDeliverFormatSelectHandler(val) {
    setLocalData({
      ...localData, delivery_details: {
        ...localData.delivery_details && localData.delivery_details,
        delivery_format: val
      }
    })
  }

  // helper function to change crawl frequency
  function handleFrequency(val) {
    setLocalData({
      ...localData, delivery_details: {
        ...localData.delivery_details && localData.delivery_details,
        frequency: val
      },
      delivery_frequency_details: {}
    })
  }

  // helper function  to change weekly frequency 
  function handleFreqWeekly(val) {
    setLocalData({
      ...localData,
      delivery_frequency_details: {
        days: val
      }
    })
  }

  // helper function to format date
  function getFormattedDate(date) {
    date = new Date(date)
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  }

  // helper function to change delivery date
  function handleDateChange(val) {
    setLocalData({
      ...localData, delivery_frequency_details: {
        date: getFormattedDate(val)
      }
    })
  }

  // helper function to select endpoint
  function handleSelect(val) {
    setLocalData({
      ...localData, delivery_details: {
        ...localData.delivery_details && localData.delivery_details,
        delivery_method: val
      },
      delivery_method_details: {}
    })
  }
}
