import React, {useState, useEffect, useContext} from 'react'
import { useParams } from "react-router-dom";
import Breadcrumb from '../../global/Breadcrumb';
import { Card, CardBody, CardHeader, Container, Form, FormGroup, Row, Col, Input, Button } from 'reactstrap'
import axios from 'axios'
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { OrgnaziationContext } from '../../Home';
import '../../../stylesheets/custom.scss';
export default function MergeAndUpload({current_user}) {
    const [loading, setLoading] = useState(true)
    const [sitesData, setSitesData] = useState([])
    const [recordCount, setRecordCount] = useState(50000)
    const [sitename, setSitename] = useState('')
    const [unzip, setUnzip] = useState(false)
    const [dedup, setDedup] = useState(false)
    const [records, setRecords] = useState('all')
    const [startDate, setStartDate] = useState(new Date())
    const [loadingButtons, setLoadingButtons] = useState(false)
    const [endDate, setEndDate] = useState(new Date())
    let today = new Date()

    let history = useHistory()

    useEffect(() => {
        if ((current_user?.id && !(current_user?.is_admin)) && !(current_user?.accessible_modules.includes("data_access"))){
            history.push(`/`)
            toast.error('You are not authorized to access this page')
        }
    }, [current_user])

    let { organization_name } = useParams();
    const {setOrgName} = useContext(OrgnaziationContext)
    useEffect(() => {
        setOrgName(organization_name)
    }, [organization_name])

    const handleFormSubmit = (e) => {
        e.preventDefault()
        if (datediff(parseDate(startDate), parseDate(endDate)) < 0){
            toast.error('Please provide valid From and To Dates')
        }
        else{
            let finalData = {sitename, records_per_file: records, unzip, dedup_records: dedup, crawldate: {from: getFormattedDate(startDate), to: getFormattedDate(endDate)}}
            if (records === 'custom_record'){
                finalData.records_custom = recordCount
            }
            setLoadingButtons(true)
            axios.post(`/api/v1/organizations/${organization_name}/merge_and_upload_create`, {
              filter: finalData,
              page_type: "client",
              controller_name: "data_download"
            }).then(res => {
                toast.success(res.data.message);
                setLoadingButtons(false)
            })
            .catch(err => {
                toast.error(err.response.data.message)
                setLoadingButtons(false)
            })
        }
    }

    function getFormattedDate(date) {
        var year = date.getFullYear();
      
        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
      
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        
        return year + '/' + month + '/' + day;
    }

    function parseDate(str) {
        str = getFormattedDate(str)
        var mdy = str.split('/');
        return new Date(mdy[2], mdy[0]-1, mdy[1]);
    }
    
    function datediff(first, second) {
        return Math.round((second-first)/(1000*60*60*24));
    }
    
    useEffect(() => {
        axios.get(`/api/v1/organizations/${organization_name}/merge_and_upload_new?page_type=client&controller_name=data_download`).then(res => {
            setSitesData(res.data.sites)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }, [])

    const handleRadioButtons = (name, status) => {
        if (status) {
            setRecords(name)
        }
    }

    return (
        <Container fluid>
            <Breadcrumb parent="Merge and upload" title="Merge and upload" />

            { loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> : 
            <Card>
                <CardHeader className= "internal-dash-card-header">
                    <h6 className='fw-bold'>Merge and upload</h6>
                </CardHeader>
                <CardBody>
                    <Form onSubmit={handleFormSubmit} className='pb-3'>
                        <FormGroup>
                            <h6 className='fw-bold'> Site Name: </h6>
                            <select className='form-control show-arrow' name="site_name" defaultValue={sitename} required
                                onChange={(e) => setSitename(e.target.value)}>
                                <option value=''>Choose a sitename</option>
                                {sitesData.map((each, index) => (
                                    <option key={index} value={each}> {each} </option>
                                ))}
                            </select>
                        </FormGroup>
                        <Row className='mt-4 mb-3'>
                            <Col lg={4}>
                                <h6 className='fw-bold'>Records Per File (Optional)</h6>
                                <div className='form-group my-3'>
                                    <div className='my-2'>
                                        <Input type="radio" name="radio1" className='me-2' checked={records === 'all'}
                                            onChange={(e) => handleRadioButtons('all', e.target.checked)} />{' '} All 
                                    </div>
                                    <div className='my-2'>
                                        <Input type="radio" name="radio1" className='me-2' checked={records === 'custom_record'}
                                            onChange={(e) => handleRadioButtons('custom_record', e.target.checked)} />{' '} Records per file - Custom
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <h6 className='fw-bold'>Unzip (Optional)</h6>
                                <div className='d-flex'>
                                    <input type="checkbox" className='form-check-input me-2' checked={unzip}
                                        name='unzip' onChange={(e) => setUnzip(e.target.checked)}  />
                                    <h6>Unzip</h6>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <h6 className='fw-bold'>Dedup Records (Optional)</h6>
                                <div className='d-flex'>
                                    <input type="checkbox" className='form-check-input me-2' checked={dedup}
                                        name='dedup' onChange={(e) => setDedup(e.target.checked)}  />
                                    <h6>Dedup Records</h6>
                                </div>
                            </Col>
                        </Row>
                        {records === 'custom_record' && <FormGroup className='mt-2 mb-4'>
                            <h6 className='fw-bold'>Enter Number of records: </h6>
                            <Input type='number' placeholder='Number of records' min={0} value={recordCount}
                                onChange={(e) => setRecordCount(e.target.value)} required />
                        </FormGroup>}
                        <Row className=''>
                            <h6 className='fw-bold mb-3'>Select Date:</h6>
                            <Col lg={6}>
                                <h6 className='fw-bold'>From</h6>
                                <DatePicker className="form-control" selected={startDate} id="from_date" dateFormat={"YYY/MM/dd"} maxDate={today}
                                    onChange={(date) => setStartDate(date)} required />
                            </Col>
                            <Col lg={6}>
                                <h6 className='fw-bold'>To</h6>
                                <DatePicker 
                                    className="form-control" 
                                    id="to_date"
                                    selected={endDate} 
                                    dateFormat={"YYY/MM/dd"}
                                    minDate={startDate} 
                                    maxDate={today}
                                    onChange={(date) => setEndDate(date)} required />
                            </Col>
                        </Row>
                        <Button type='submit' color='success' className='mt-4' disabled={loadingButtons}>Submit</Button>
                    </Form>
                </CardBody>
            </Card>}
        </Container>
    )
}
