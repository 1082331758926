import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";
import axios from "axios";

import { KIBANA_PLM_URL } from "../../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function HostWiseAverageTimeToPickSegment() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const searchParams = {
    hostName: new URLSearchParams(search).get("filter[host_name]"),
    fromTime: new URLSearchParams(search).get("filter[time]"),
  }
  const [hostName, setHostName] = useState({label: 'All', value: '*'});
  const [hostNameOptions, setHostNameOptions] = useState([]);
  const [fromTime, setFromTime] = useState({ label: 'Last 30 minutes', value: 'now-30m' });

  const timeOptions = [
    { label: 'Today', value: 'now%2Fd' },
    { label: 'Last 30 minutes', value: 'now-30m' },
    { label: 'Last 1 hour', value: 'now-1h' },
    { label: 'Last 2 hours', value: 'now-2h' },
    { label: 'Last 4 hours', value: 'now-4h' },
    { label: "Last 10 hours", value: "now-10h" },
    { label: 'Last 12 hours', value: 'now-12h' },
    { label: 'Last 24 hours', value: 'now-24h' },
    { label: 'Last 2 days', value: 'now-2d' },
    { label: 'Last 3 days', value: 'now-3d' },
    { label: 'Last 4 days', value: 'now-4d' },
    { label: 'Last 7 days', value: 'now-7d' },
    { label: 'Last 15 days', value: 'now-15d' },
    { label: 'Last 30 days', value: 'now-30d' },
    { label: 'Last 60 days', value: 'now-60d' },
    { label: 'Last 1 year', value: 'now-1y' }
  ];

  const [UrlParams, setUrlParams] = useState({
    hostName: '*',
    fromTime: 'now-30m'
  })

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let machine_names = []
        res.data.machine_names.forEach(item => {
          machine_names.push({ label: item, value: item })
        })
        machine_names.push({ label: 'All', value: '*' })
        setHostNameOptions(machine_names);
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      });
  }, []);


  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.hostName && searchParams.hostName != hostName.value) {
      setHostName({ label: searchParams.hostName  == '*' ? 'All' :  searchParams.hostName, value: searchParams.hostName });
    }
    if (searchParams.fromTime && searchParams.fromTime != fromTime.value) {
      setFromTime(timeOptions.filter(item => item.value == searchParams.fromTime)[0]);
    }
    if (commit == 'Get Data' && searchParams.fromTime && searchParams.hostName) {
      setUrlParams(searchParams)
    }
  }

  function hostWiseAverageTimeToPickSegmentKibanaUrl(){
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";

    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=line&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${UrlParams.fromTime}',mode:quick,to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'file_name:pipeline_v2_ednu_runner.rb%20AND%20host.name:%20%22${UrlParams.hostName}%22')),uiState:(),vis:(aggs:!((id:'1',params:(field:dirglob_time_taken),schema:metric,type:avg),(id:'2',params:(field:host.name.raw,order:desc,orderBy:'1',size:17),schema:segment,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Host%20Wise%20Average%20Time%20To%20Pick%20Segment',type:histogram))`
  }

  const handleGetData = () => {
    window.location.href = `/internal/overall_system_reports/host_wise_avg_time_to_pick_segment?filter[host_name]=${hostName.value}&filter[time]=${fromTime.value}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/overall_system_reports/host_wise_avg_time_to_pick_segment">Host Wise Average Time To Pick A Segment</a> : 'Host Wise Average Time To Pick A Segment'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                Search
                </BreadcrumbItem>}
          </Breadcrumb>
        </Col>
      </Row>
    </div>

    <Card>
      <CardBody>
        <Row>
          <Col sm="4">
            <label className="fw-bold">Host Name</label>
            <Select
              value={hostName}
              options={hostNameOptions}
              onChange={option => setHostName(option)} />
          </Col>
          <Col sm="4">
            <label className="fw-bold">Time</label>
            <Select
              value={fromTime}
              options={timeOptions}
              onChange={option => setFromTime(option)} />
          </Col>
          <Col sm="4">
            <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
          </Col>
        </Row>
      </CardBody>
    </Card>
    <Card>
      <CardBody>
        <center>
          <span className="fw-bold">Host Wise Average Time To Pick A Segment</span><br />
          <div className="mb-1">
            <b>NOTE:</b>
            This graph shows the host wise average time taken to pick a segment. The time duration is considered in milliseconds, generally it should be less than 50ms. 
          </div>
          <a target="_blank" className="link-primary" href={hostWiseAverageTimeToPickSegmentKibanaUrl()}>Click here to see below graph in kibana</a>
        </center>
        <iframe src={hostWiseAverageTimeToPickSegmentKibanaUrl() + "&embed=true"} height="500" width="100%" className="border-0"></iframe>
      </CardBody>
    </Card>
  </Container>
}

