import React from 'react';
import { Info } from "react-feather";
import ReactTooltip from 'react-tooltip';
import DataTable from 'react-data-table-component';

import { KIBANA_PLM_URL } from '../../../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints';

export default function SegmentLevelRssStatsDataTable(props) {

  function siteKibanaUrl(data) {
    return `${KIBANA_PLM_URL}app/kibana#/discover?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:'${data.kibana_from_date}',to:'${data.kibana_to_date}'))&_a=(columns:!(_source),filters:!(('$state':(store:appState),exists:(field:rss_status_tracker),meta:(alias:!n,disabled:!f,index:'76972760-1ad0-11ec-9735-177ab3888d9e',key:rss_status_tracker,negate:!f,type:exists,value:exists))),index:'76972760-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+${data.site_name}+OR+sitename:+${data.site_name}+OR+site:+${data.site_name})'),sort:!(!('@timestamp',desc)))`
  }

  function dateKibanaUrl(data) {
    return `${KIBANA_PLM_URL}app/kibana#/discover?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:'${data.kibana_from_date}',to:'${data.kibana_to_date}'))&_a=(columns:!(date),filters:!(('$state':(store:appState),exists:(field:rss_status_tracker),meta:(alias:!n,disabled:!f,index:'76972760-1ad0-11ec-9735-177ab3888d9e',key:rss_status_tracker,negate:!f,type:exists,value:exists))),index:'76972760-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+${data.site_name}+OR+sitename:+${data.site_name}+OR+site:+${data.site_name})+and+date:+${data.crawldate}'),sort:!(!('@timestamp',desc)))`
  }

  function statusKibanaUrl(data) {
    return `${KIBANA_PLM_URL}app/kibana#/discover?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:'${data.kibana_from_date}',to:'${data.kibana_to_date}'))&_a=(columns:!(date,current_status),filters:!(('$state':(store:appState),exists:(field:rss_status_tracker),meta:(alias:!n,disabled:!f,index:'76972760-1ad0-11ec-9735-177ab3888d9e',key:rss_status_tracker,negate:!f,type:exists,value:exists))),index:'76972760-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+${data.site_name}+OR+sitename:+${data.site_name}+OR+site:+${data.site_name})+and+date:+${data.crawldate}+and+current_status:+%22${data.rss_crawlstatus}%22'),sort:!(!('@timestamp',desc)))`
  }


  // Table column definitions
  const tableColumns = [
    {
      name: 'Site',
      selector: 'site_name',
      sortable: true,
      cell: (row, index) => (
        <>
          <p data-tip data-for={'site-tooltip-' + index}>{row.site_name} <Info size={18} className='mx-2' /></p>
          <ReactTooltip id={'site-tooltip-' + index} clickable multiline delayHide={1000} effect='solid'>
            <a href={siteKibanaUrl(row)} target="_blank" className='text-white '>Kibana Link</a><br />
            <a href="http://alerta.promptcloud.com/#/alerts?status=open" target='_blank' className='text-white'>Alert Link (To Do)</a>
          </ReactTooltip>
        </>
      )
    },
    { name: 'Rss Crawl_Geo', selector: 'rss_crawl_geo', sortable: true },
    {
      name: 'Date',
      selector: 'crawldate',
      sortable: true,
      cell: (row, index) => (
        <>
          <p data-tip data-for={'crawldate-tooltip-' + index}>{row.crawldate} <Info size={18} className='mx-2' /></p>
          <ReactTooltip id={'crawldate-tooltip-' + index} clickable multiline delayHide={1000} effect='solid'>
            <a href={dateKibanaUrl(row)} target="_blank" className='text-white'>Kibana Link</a><br />
            <a href="http://alerta.promptcloud.com/#/alerts?status=open" target='_blank' className='text-white'>Alert Link (To Do)</a>
          </ReactTooltip>
        </>
      )
    },
    {
      name: 'Rss Crawl Start Status',
      selector: 'rss_crawl_start_status',
      sortable: true,
      cell: (row, index) => (
        <>
          <p data-tip data-for={'status-tooltip-' + index}>{row.rss_crawl_start_status} <Info size={18} className='mx-2' /></p>
          <ReactTooltip id={'status-tooltip-' + index} clickable multiline delayHide={1000} effect='solid'>
            <span className='text-white'>Crawl begin time: {row.crawlbegintime}</span><br />
            <span className='text-white'>Crawl end time: {row.rss_crawlendtime}</span><br />
            <a href={statusKibanaUrl(row)} target="_blank" className='text-white'>Kibana Link</a><br />
            <a href="http://alerta.promptcloud.com/#/alerts?status=open" target='_blank' className='text-white'>Alert Link (To Do)</a>
          </ReactTooltip>
        </>
      )
    },
    {
      name: 'Rss Crawl End Status',
      selector: 'rss_crawl_end_status',
      sortable: true,
      cell: (row, index) => (
        <>
          <p data-tip data-for={'status-tooltip-' + index}>{row.rss_crawl_end_status} <Info size={18} className='mx-2' /></p>
          <ReactTooltip id={'status-tooltip-' + index} clickable multiline delayHide={1000} effect='solid'>
            <span className='text-white'>Crawl begin time: {row.crawlbegintime}</span><br />
            <span className='text-white'>Crawl end time: {row.rss_crawlendtime}</span><br />
            <a href={statusKibanaUrl(row)} target="_blank" className='text-white'>Kibana Link</a><br />
            <a href="http://alerta.promptcloud.com/#/alerts?status=open" target='_blank' className='text-white'>Alert Link (To Do)</a>
          </ReactTooltip>
        </>
      )
    },

    { name: 'Pushed urls', selector: 'pushed_urls_count', sortable: true },
    { name: 'Dropped urls', selector: 'rss_dropped_urls_count', sortable: true },
    { name: 'Drop%', selector: 'drop_percentage', sortable: true },
    { name: 'Crawl Duration(sec.)', selector: 'rss_crawl_duration', sortable: true },
    { name: 'Hostname', selector: 'host_name', sortable: true },
    { name: 'Segmentname', selector: 'segment_name', sortable: true },
    { name: 'Rss job type', selector: 'rss_job_type', sortable: true },
  ];


  return (
    <div>
      <DataTable
        columns={tableColumns}
        data={props?.data}
        progressPending={props?.progressPending}
        noHeader
        responsive
        pagination
      />
    </div>
  )
}
