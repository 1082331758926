import React, { useState } from 'react';
import { Home } from 'react-feather';
import { Link } from 'react-router-dom';
import { Container, Row, Breadcrumb, BreadcrumbItem, Col, Card, CardBody, Collapse } from 'reactstrap';

export default function OurStack() {
    const [isOpen, setIsOpen] = useState('')
    const stacks = [
        {
            id: 1,
            stack: 'Resque',
            links: [
                {
                    label: 'https://resque.promptcloud.com/overview',
                    value: 'https://resque.promptcloud.com/overview'
                },
                {
                    label: 'https://resque.bcp.promptcloud.com/overview',
                    value: 'https://resque.bcp.promptcloud.com/overview'
                }
            ]
        },
        {
            id: 2,
            stack: 'Elastic Search',
            links: [
                {
                    label: 'https://es.promptcloud.com/',
                    value: 'https://es.promptcloud.com/'
                },
                {
                    label: 'https://es.bcp.promptcloud.com/',
                    value: 'https://es.bcp.promptcloud.com/'
                }
            ]
        },
        {
            id: 3,
            stack: 'Queue1',
            links: [
                {
                    label: 'https://queue1.promptcloud.com/',
                    value: 'https://queue1.promptcloud.com/'
                },
                {
                    label: 'https://queue1.bcp.promptcloud.com/',
                    value: 'https://queue1.bcp.promptcloud.com/'
                }
            ]
        },
        {
            id: 4,
            stack: 'Queue2',
            links: [
                {
                    label: 'https://queue2.promptcloud.com/',
                    value: 'https://queue2.promptcloud.com/'
                },
                {
                    label: 'https://queue2.bcp.promptcloud.com/',
                    value: 'https://queue2.bcp.promptcloud.com/'
                }
            ]
        },
        {
            id: 5,
            stack: 'Riak',
            links: [
                {
                    label: 'https://riak.promptcloud.com/',
                    value: 'https://riak.promptcloud.com/'
                },
                {
                    label: 'https://riak.bcp.promptcloud.com/',
                    value: 'https://riak.bcp.promptcloud.com/'
                }
            ]
        },
        {
            id: 6,
            stack: 'Kibana',
            links: [
                {
                    label: 'https://kibana.promptcloud.com/',
                    value: 'https://kibana.promptcloud.com/'
                },
                {
                    label: 'https://kibana.bcp.promptcloud.com/',
                    value: 'https://kibana.bcp.promptcloud.com/'
                }
            ]
        },
        {
            id: 7,
            stack: 'Jenkins',
            links: [
                {
                    label: 'https://ci.promptcloud.com/',
                    value: 'https://ci.promptcloud.com/',

                },
                {
                    label: 'https://ci.bcp.promptcloud.com/  (Non-functional)',
                    value: 'https://ci.bcp.promptcloud.com/',

                },
            ]
        },
        {
            id: 8,
            stack: 'HA Proxy',
            links: [
                {
                    label: 'http://haproxy.promptcloud.com/ (Non-functional)',
                    value: 'http://haproxy.promptcloud.com/',

                },
                {
                    label: 'http://haproxy.promptcloud.com/ (Non-functional)',
                    value: 'http://haproxy.promptcloud.com/',

                },
            ]
        },
        {
            id: 9,
            stack: 'PromptCloud API',
            links: [
                {
                    label: 'https://api.promptcloud.com/v2/data/info?type=help',
                    value: 'https://api.promptcloud.com/v2/data/info?type=help'
                }
            ]
        },
        {
            id: 10,
            stack: 'PromptCloud Search',
            links: [
                {
                    label: 'http://premium.search.promptcloud.com/api/v1/search?type=help',
                    value: 'http://premium.search.promptcloud.com/api/v1/search?type=help'
                },
                {
                    label: 'http://search.promptcloud.com/api/v1/search?type=help',
                    value: 'http://search.promptcloud.com/api/v1/search?type=help'
                }
            ]
        },
        {
            id: 11,
            stack: 'Redmine',
            links: [
                {
                    label: 'https://tickets.promptcloud.com/',
                    value: 'https://tickets.promptcloud.com/'
                },
                {
                    label: 'https://tickets.bcp.promptcloud.com/',
                    value: 'https://tickets.bcp.promptcloud.com/'
                }
            ]
        },
        {
            id: 12,
            stack: 'Alerta',
            links: [
                {
                    label: 'https://alerta.promptcloud.com/',
                    value: 'https://alerta.promptcloud.com/'
                },
                {
                    label: 'https://alerta.bcp.promptcloud.com/',
                    value: 'https://alerta.bcp.promptcloud.com/'
                }
            ]
        },
        {
            id: 13,
            stack: 'Gerrit Review',
            links: [
                {
                    label: 'https://reviews.promptcloud.com/',
                    value: 'https://reviews.promptcloud.com/',

                },
                {
                    label: 'https://reviews.bcp.promptcloud.com/ (Non-functional)',
                    value: 'https://reviews.bcp.promptcloud.com/'
                }
            ]
        },
        {
            id: 14,
            stack: 'CrawlBoard (App v3)',
            links: [
                {
                    label: 'https://app.promptcloud.com/',
                    value: 'https://app.promptcloud.com/'
                },
                {
                    label: 'https://app.bcp.promptcloud.com/ (Non-functional)',
                    value: 'https://app.bcp.promptcloud.com/'
                }
            ]
        },
        {
            id: 15,
            stack: 'Internal Dashboard',
            links: [
                {
                    label: 'https://app.promptcloud.com/internal',
                    value: 'https://app.promptcloud.com/internal'
                },
                {
                    label: 'https://app.bcp.promptcloud.com/internal (Non-functional)',
                    value: 'https://app.bcp.promptcloud.com/internal'
                }
            ]
        },
        {
            id: 16,
            stack: 'Yardoc',
            links: [
                {
                    label: 'https://yardoc.promptcloud.com/',
                    value: 'https://yardoc.promptcloud.com/'
                },
                {
                    label: 'https://yardoc.bcp.promptcloud.com/',
                    value: 'https://yardoc.bcp.promptcloud.com/'
                },
                {
                    label: 'https://yardoc.promptcloud.com/coverage/',
                    value: 'https://yardoc.promptcloud.com/coverage/'
                },
                {
                    label: 'https://yardoc.promptcloud.com/rubycritic/ (Non-functional)',
                    value: 'https://yardoc.promptcloud.com/rubycritic/'
                }
            ]
        },
        {
            id: 17,
            stack: 'Graphite',
            links: [
                {
                    label: 'https://graphite.promptcloud.com/',
                    value: 'https://graphite.promptcloud.com/'
                },
                {
                    label: 'https://graphite.bcp.promptcloud.com/ (Non-functional)',
                    value: 'https://graphite.bcp.promptcloud.com/'
                }
            ]
        },
        {
            id: 18,
            stack: 'Graphite Alerts',
            links: [
                {
                    label: 'https://graphite-alerts.promptcloud.com/',
                    value: 'https://graphite-alerts.promptcloud.com/'
                },
                {
                    label: 'https://graphite-alerts.bcp.promptcloud.com/ (Non-functional)',
                    value: 'https://graphite-alerts.bcp.promptcloud.com/'
                }
            ]
        },
    ];
    let count=0;
    return <Container fluid>
        <div className="page-title">
            <Row>
                <Col xs="6">
                    <h3> Internal Dashboard</h3>
                </Col>
                <Col xs="6">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={'/internal'}>
                                <Home />
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem className="fw-bold">
                            PromptCloud Stack
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
        <Card>
            <CardBody>
                {
                    stacks.map(item => {
                        return <div style={{ cursor: "pointer" }} key={item.id}>
                            <div className="w-100 p-3 fw-bold text-start mt-2 panel-heading" role={'presentation'} onClick={() => {
                                setIsOpen(isOpen == item.id ? '' : item.id)
                            }}>
                            {item.stack}

                            </div>
                            <Collapse className='panel-body' isOpen={isOpen == item.id}>
                                {item.links.map(link => {
                                    
                                    return <div className={item.links[0].label == link.label ? 'fw-bold' : ''}>
                                        <a href={link.value} target='_blank' className='link-primary'>{link.label}</a>
                                    </div>
                                })}
                            </Collapse>
                        </div>
                    })
                }
            </CardBody>
        </Card>
    </Container>
}