import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import moment from "moment";
import axios from "axios";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";
import AsyncSelect from 'react-select/async';
import Select, { components } from "react-select";

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function New() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const extractstatus = new URLSearchParams(search).get("filter[extractstatus]");
  const crawlstatus = new URLSearchParams(search).get("filter[crawlstatus]");
  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[site]"),
    extractStatus: extractstatus ? extractstatus : "all_other_than_done",
    crawlStatus: crawlstatus ? crawlstatus : "any",
    fromDate: new URLSearchParams(search).get("filter[crawldate][from]"),
    toDate: new URLSearchParams(search).get("filter[crawldate][to]"),
  }
  const [siteName, setSiteName] = useState({label: '', value: ''});
  const [extractStatus, setExtractStatus] = useState({label: 'All other than done', value: 'all_other_than_done'});
  const [crawlStatus, setCrawlStatus] = useState({label: 'Any', value: 'any'});
  const [siteNameOptions, setSiteNameOptions] = useState([]);
  const [diskfetcherProcessesData, setDiskfetcherProcessesData] = useState([]);
  const now = new Date();
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(2, 'days')));
  const [toDate, setToDate] = useState(now);
  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults]= useState([]);

  const crawlStatusOptions = [
    { label: 'Any', value: 'any' },
    { label: 'started', value: 'started' },
    { label: 'done', value: 'done' },
    { label: 'failed', value: 'failed' }
  ]

  const extractStatusOptions = [
    { label: 'All other than done', value: 'all_other_than_done' },
    { label: 'failed', value: 'failed' },
    { label: 'inprogress', value: 'inprogress' },
    { label: 'NULL', value: 'NULL' },
    { label: 'started', value: 'started' },
    { label: 'done', value: 'done' }
  ]

  const tableColumns = [
    { name: 'Host Name', selector: 'hostname', sortable: true },
    { 
      name: 'Segmentname', 
      selector: 'segment_name', 
      sortable: true,
      cell: row => (
        <a href={kibanaUrl(row.kibana_query_value, row.segment_name)} target="_blank" rel="noopener noreferrer">
          {row.segment_name}
        </a>
        )
    },
    { name: 'Crawldate', selector: 'crawldate', sortable: true },
    { name: 'PID', selector: 'pid', sortable: true },
    { name: 'Crawled URLs Count', selector: 'crawled_urls_count', sortable: true },
    { name: 'Crawl Status', selector: 'crawlstatus', sortable: true },
    { name: 'Extraction status', selector: 'extractstatus', sortable: true },
  ]

  function kibanaUrl(kibana_query_value, segment_name) {
    return `${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(pause:!t,value:0),time:(from:now-7d,to:now))&_a=(columns:!(segment_uniq_id,segid),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'%22${kibana_query_value}%22+and+segment_uniq_id.raw:+${segment_name}'),sort:!(!('@timestamp',desc)))`
  }

  const [UrlParams, setUrlParams] = useState({
    siteName: '',
    extractStatus: 'all_other_than_done',
    crawlStatus: 'any',
    fromDate: new Date(moment().subtract(2, 'days')),
    toDate: now
  })

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let site_names = res.data.site_names, site_names_options = []
        for (var key in site_names) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
          site_names[key].map(item => {
            children.push({ label: item, value: item })
          })
          group['options'] = children;
          site_names_options.push(group)
        }
        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: '', value: '' }] })
        setSiteNameOptions(site_names_options);
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      });
  }, []);

  const promiseOptions = (inputValue) => {
    if(inputValue.length < 3){
      return null
    } else {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200'){
            console.log(res.data)
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
              site_names[key].map(item => {
                children.push({ label: item, value: item })
              })
              group['options'] = children;
              site_names_options.push(group)
            }
            setSiteNameOptions(site_names_options)
            resolve(site_names_options)
          }
        }).catch(err => {
          console.log(`err`, err)
        })
      })
    }
  }

  const handleSiteNameChange = (data) => {
    setSiteName(data)
  }

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.siteName && searchParams.siteName != siteName.value) {
      setSiteName({ label: searchParams.siteName, value: searchParams.siteName });
    }
    if (searchParams.extractStatus && searchParams.extractStatus != extractStatus.value) {
      setExtractStatus({ label: searchParams.extractStatus  == 'all_other_than_done' ? 'All' :  searchParams.extractStatus, value: searchParams.extractStatus });
    }
    if (searchParams.crawlStatus && searchParams.crawlStatus != crawlStatus.value) {
      setCrawlStatus({ label: searchParams.crawlStatus  == 'any' ? 'Any' :  searchParams.crawlStatus, value: searchParams.crawlStatus });
    }
    if (searchParams.fromDate && searchParams.fromDate != fromDate) {
      setFromDate(new Date(searchParams.fromDate));
    }
    if (searchParams.toDate && searchParams.toDate != toDate) {
      setToDate(new Date(searchParams.toDate));
    }
    if (commit == 'Get Data') {
      setUrlParams(searchParams)
      fetchDataWithParams(searchParams.siteName, searchParams.extractStatus, searchParams.crawlStatus, searchParams.fromDate, searchParams.toDate);
    }
  }

  function fetchDataWithParams(site_name = siteName, extract_status = extractStatus, crawl_status = crawlStatus, from_date = fromDate, to_date = toDate) {
    setLoading(true)
    axios.get(`/api/v1/internal/diskfetcher_processes?filter[site_name]=${site_name}&filter[extractstatus]=${extract_status}&filter[crawlstatus]=${crawl_status}&filter[from_date]=${moment(from_date).format('YYYY/MM/DD')}&filter[to_date]=${moment(to_date).format('YYYY/MM/DD')}`)
      .then(res => {
        setDiskfetcherProcessesData(res.data.diskfetcher_processes_data);
        setSearchResults(res.data.diskfetcher_processes_data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  const handleSearch = event =>{
    if(event.target.value == ''){
      setSearchResults(diskfetcherProcessesData);
    }
    else{
      setSearchResults(searchFromData(event.target.value));
    }
    setSearchText(event.target.value);
  }

  function searchFromData(value){
    var search_result = diskfetcherProcessesData.filter(item => item.hostname.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = diskfetcherProcessesData.filter(item => item.segment_name.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = diskfetcherProcessesData.filter(item => item.crawldate.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = diskfetcherProcessesData.filter(item => item.pid.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = diskfetcherProcessesData.filter(item => item.crawled_urls_count.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = diskfetcherProcessesData.filter(item => item.crawlstatus.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = diskfetcherProcessesData.filter(item => item.extractstatus.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
  }

  const handleGetData = () => {
    window.location.href = `/internal/df?filter[site]=${siteName.value}&filter[extractstatus]=${extractStatus.value}&filter[crawlstatus]=${crawlStatus.value}&filter[crawldate][from]=${moment(fromDate).format('YYYY/MM/DD')}&filter[crawldate][to]=${moment(toDate).format('YYYY/MM/DD')}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/df/new">Diskfetcher Processes</a> : 'Diskfetcher Processes'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
          </BreadcrumbItem>}
        </Breadcrumb>
      </Col>
    </Row>
  </div>

  <Card>
    <CardBody>
      <Row>
        <div className="col-sm-6">
          <label className="fw-bold">Site Name</label>
          <AsyncSelect cacheOptions defaultOptions={siteNameOptions} name='site_name' value={siteName}
            loadOptions={promiseOptions} onChange={handleSiteNameChange}
            placeholder="Select a site name"
          />
          <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
        </div>
        <Col sm="3">
          <label className="fw-bold">From Date</label>
          <DatePicker
            className="form-control"
            selected={new Date(fromDate)}
            dateFormat={"YYY/MM/dd"}
            maxDate={new Date()}
            onChange={date => setFromDate(date)} />
        </Col>
        <Col sm="3">
          <label className="fw-bold">To Date</label>
          <DatePicker
            className="form-control"
            selected={new Date(toDate)}
            dateFormat={"YYY/MM/dd"}
            maxDate={new Date()}
            onChange={date => setToDate(date)} />
        </Col>
      </Row>
      <Row className="align-items-end mt-2">
        <Col sm="4">
          <label className="fw-bold">Crawl Status</label>
          <Select
            value={crawlStatus}
            options={crawlStatusOptions}
            onChange={option => setCrawlStatus(option)} />
        </Col>
        <Col sm="4">
          <label className="fw-bold">Extract Status</label>
          <Select
            value={extractStatus}
            options={extractStatusOptions}
            onChange={option => setExtractStatus(option)} />
        </Col>
        <Col sm="4">
          <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
        </Col>
      </Row>
    </CardBody>
  </Card>
  {
    UrlParams.siteName ? 
    <>
      <Card>
        <CardBody>
          {
            diskfetcherProcessesData.length > 0 ? 
            <>
              <Row className="align-items-end mt-2 mb-3">
                <Col>
                  <div className="float-end">
                    <Row className="align-items-center">
                      <Col sm="3">Search</Col>
                      <Col>
                        <input type="text" className="form-control" value={searchText} onChange={handleSearch} />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <DataTable
                columns={tableColumns}
                data={searchResults}
                noHeader
                pagination
                progressPending={loading}
              />
          </>
            : "No data available"
          }
        </CardBody>
      </Card>
    </>
    : ""
  }
</Container>
}

