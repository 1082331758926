import React, { Fragment, useState, useEffect, useContext } from 'react';
import { ArrowRight, ArrowLeft, Grid } from 'react-feather';
import {
  Code, Globe, Box, DollarSign, Users, GitPullRequest,
  CheckSquare, HelpCircle, Heart, FileText
} from 'react-feather'
import { Link, useHistory } from 'react-router-dom'
import { translate } from 'react-switch-lang';
import configDB from './config';
import axios from 'axios';
import { OrgnaziationContext } from '../Home';
import './../../stylesheets/custom.scss';

const Sidebar = (props) => {

  // eslint-disable-next-line
  const [mainmenu, setMainMenu] = useState([]);
  const { orgName } = useContext(OrgnaziationContext)
  const [margin, setMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const [sidebartoogle, setSidebartoogle] = useState(true)
  const wrapper = configDB.data.settings.sidebar.type;

  const history = useHistory()

  const token = localStorage.getItem("authToken");
  const email = localStorage.getItem("authEmail");
  const env = localStorage.getItem("environment");

  useEffect(() => {
    axios.defaults.headers["X-Auth-Email"] = email;
    axios.defaults.headers["X-Auth-Token"] = token;
    axios.defaults.headers["Authorization"] = `Bearer ${token}`;
    getSidebarReady()
  }, [orgName])

  async function getData(url) {
    const res = await axios.get(url)
    return await res?.data
  }

  async function getSidebarReady() {
    getData(`/api/v1/user/details?email=${email}&page_type=client&controller_name=dashboard`).then(res => {
      if (res?.user && res?.user?.is_admin) {
        getData('/api/v1/navigate_sidebar_details?page_type=client&controller_name=dashboard').then(res3 => {
          setMainMenu([
            {
              menutitle: "Item1",
              menucontent: "Item1.1",
              Items: [
                {
                  title: 'Data Access',
                  icon: Code, type: 'sub',
                  active: false,
                  children: [
                    { path: `/organizations/${orgName}/data_download`, title: 'Data Download', type: 'link' },
                    { path: `/organizations/${orgName}/api_details`, title: 'API Details', type: 'link' },
                    { path: `/organizations/${orgName}/merge_and_upload_new`, title: 'Merge and Upload', type: 'link' },
                    { path: `/organizations/${orgName}/webhooks`, title: 'Webhooks', type: 'link' },
                  ]
                }
              ]
            },
            {
              menutitle: "Item2",
              menucontent: "Item2.1",
              Items: [
                { path: `/organizations/${orgName}/sites`, icon: Globe, title: 'Sites', type: 'link' },
                { path: `/organizations/${orgName}/feasibility_reports`, icon: FileText, title: 'Feasibility Reports', type: 'link' },
                { path: `/organizations/${orgName}/issues`, icon: GitPullRequest, title: 'Issues', type: 'link' },
                { path: `/organizations/${orgName}/invoices`, icon: DollarSign, type: 'link', title: 'Billing' },

              ]
            },
            {
              menutitle: "Components",
              menucontent: "UI Components & Elements",
              Items: [
                {
                  title: 'Admin Section', icon: Box, type: 'sub', active: false, children:
                    [
                      { path: `/admins/feasibility_checks`, title: 'Feasibility Checks', type: 'link' },
                      { path: `/admins/feasibility_reports`, title: 'Feasibility Reports', type: 'link' },
                      { path: `/admins/coupons`, title: 'Discount Coupons', type: 'link' },
                      { path: `/admins/search/new`, title: 'Search Page', type: 'link' },
                      { path: `/admins/org_mapping`, title: 'Organization Mapping', type: 'link' },
                      {
                        path: `/admins/data_stock_files`,
                        title: res3?.navigate_sidebar_details?.admin_data_stock_files?.title, type: 'link'
                      },
                      { path: `/admins/announcements/new`, title: 'New Announcement', type: 'link' },
                      { path: `/admins/organizations`, title: 'All Organizations', type: 'link' },
                      { path: `/admins/payments`, title: 'All Payments', type: 'link' },
                      { path: `/admins/invoices`, title: 'All Invoices', type: 'link' },
                    ]
                }
              ]
            },
            {
              menutitle: "Item3",
              menucontent: "Item3.1",
              Items: [
                { path: `/internal`, icon: HelpCircle, title: 'Internal Dashboard', type: 'link' },
              ]
            },
            {
              menutitle: "Miscellaneous",
              menucontent: "Bouns Pages & Apps",
              Items: [
                // { path: `${res3?.navigate_sidebar_details?.internal_dashboard?.url}`,icon: HelpCircle,  type: 'external_link',active:false, title: res3?.navigate_sidebar_details?.internal_dashboard?.title },
                { path: `${res3?.navigate_sidebar_details?.data_stock?.url}`, icon: Users, type: 'external_link', active: false, title: res3?.navigate_sidebar_details?.data_stock?.title },
              ]
            },
          ])
        })
      }
      else {
        getData('/api/v1/navigate_sidebar_details?page_type=client&controller_name=dashboard').then(res3 => {
          setMainMenu([
            {
              menutitle: "Item1",
              menucontent: "Item1.1",
              Items: [
                {
                  title: 'Data Access',
                  icon: Code, type: 'sub',
                  active: false,
                  children: [
                    { path: `/organizations/${orgName}/data_download`, title: 'Data Download', type: 'link' },
                    { path: `/organizations/${orgName}/api_details`, title: 'API Details', type: 'link' },
                    { path: `/organizations/${orgName}/merge_and_upload_new`, title: 'Merge and Upload', type: 'link' },
                    { path: `/organizations/${orgName}/webhooks`, title: 'Webhooks', type: 'link' },
                  ]
                }
              ]
            },

            {
              menutitle: "Item2",
              menucontent: "Item2.1",
              Items: [
                { path: `/organizations/${orgName}/sites`, icon: Globe, title: 'Sites', type: 'link' },
                { path: `/organizations/${orgName}/feasibility_reports`, icon: FileText, title: 'Feasibility Reports', type: 'link' },
                { path: `/organizations/${orgName}/issues`, icon: GitPullRequest, title: 'Issues', type: 'link' },
                { path: `/organizations/${orgName}/invoices`, icon: DollarSign, type: 'link', title: 'Billing' },

              ]
            },
            {
              menutitle: "Miscellaneous",
              menucontent: "Bouns Pages & Apps",
              Items: [
                {
                  path: `${res3?.navigate_sidebar_details?.data_stock?.url}`, icon: Users, type: 'external_link', active: false,
                  title: res3?.navigate_sidebar_details?.data_stock?.title
                },
              ]
            },
          ])
        })
      }
    })

  }

  const handleScroll = () => {
    if (window.scrollY > 400) {
      if (configDB.data.settings.sidebar.type.split(' ').pop() === 'material-type' || configDB.data.settings.sidebar.type.split(' ').pop() === 'advance-layout')
        document.querySelector(".sidebar-main").className = "sidebar-main hovered"
    } else {
      if (document.getElementById("sidebar-main"))
        document.querySelector(".sidebar-main").className = "sidebar-main"
    }
  }

  useEffect(() => {
    document.querySelector(".left-arrow").classList.add("d-none")
    window.addEventListener('resize', handleResize)
    handleResize();
    const currentUrl = window.location.pathname;
    mainmenu?.map(items => {
      items.Items.filter((Items) => {
        if (Items.path === currentUrl)
          setNavActive(Items)
        if (!Items.children) return false
        Items.children.filter(subItems => {
          if (subItems.path === currentUrl)
            setNavActive(subItems)
          if (!subItems.children) return false
          subItems.children.filter(subSubItems => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems)
              return true
            }
            else {
              return false
            }
          })
          return subItems
        })
        return Items
      })
      return items
    })
    window.addEventListener('scroll', handleScroll)
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
    }
    // eslint-disable-next-line
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth - 500);
  }

  const setNavActive = (item) => {
    let temp = mainmenu?.map(menuItems => {
      menuItems.Items.filter(Items => {
        if (Items !== item) {
          Items.active = false
          document.querySelector(".bg-overlay1").classList.remove("active")
        }
        if (Items.children && Items.children.includes(item)) {
          Items.active = true
          document.querySelector(".sidebar-link").classList.add("active")
        }
        if (Items.children) {
          Items.children.filter(submenuItems => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              Items.active = true
              submenuItems.active = true
              return true
            }
            else {
              return false
            }
          })
        }
        return Items
      })
      return menuItems
    })
    item.active = !item.active
    setMainMenu(temp)
  }

  const toggletNavActive = (item) => {


    if (window.innerWidth <= 991) {
      document.querySelector(".page-header").className = "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon "
      document.querySelector(".mega-menu-container").classList.remove("d-block")
      if (item.type === "sub") {
        document.querySelector(".page-header").className = "page-header ";
        document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper "
      }
    }
    let temp = [...mainmenu]
    if (!item.active) {
      temp = mainmenu?.map(a => {
        a.Items.filter((Items) => {
          if (a.Items.includes(item))
            Items.active = false
          if (!Items.children) return false
          Items.children.forEach(b => {
            if (Items.children.includes(item)) {
              b.active = false
            }
            if (!b.children) return false
            b.children.forEach(c => {
              if (b.children.includes(item)) {
                c.active = false
              }
            })
          })
          return Items
        });
        return a
      });
    }
    item.active = !item.active
    setMainMenu(temp)
  }

  const scrollToRight = () => {
    if (margin <= -2598 || margin <= -2034) {
      if (width === 492) {
        setMargin(-3570)
      } else {
        setMargin(-3464)
      }
      document.querySelector(".right-arrow").classList.add("d-none")
      document.querySelector(".left-arrow").classList.remove("d-none")
    } else {
      setMargin(margin => margin += (-width));
      document.querySelector(".left-arrow").classList.remove("d-none")
    }
  }

  const scrollToLeft = () => {
    if (margin >= -width) {
      setMargin(0)
      document.querySelector(".left-arrow").classList.add("d-none")
      document.querySelector(".right-arrow").classList.remove("d-none")
    } else {
      setMargin(margin => margin += width);
      document.querySelector(".right-arrow").classList.remove("d-none")
    }
  }

  const closeOverlay = () => {
    document.querySelector(".bg-overlay1").classList.remove("active")
    document.querySelector(".sidebar-link").classList.remove("active")
  }

  const activeClass = () => {
    document.querySelector(".sidebar-link").classList.add("active")
    document.querySelector(".bg-overlay1").classList.add("active")
  }

  const openCloseSidebar = (toggle) => {
    if (toggle) {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className = "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon "
    } else {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className = "page-header";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper "
    }
  };

  const responsiveSidebar = () => {
    document.querySelector(".page-header").className = "page-header close_icon";
    document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon"
  }

  function redirectToHomePageHandler() {
    history.push('/')
    window.location.reload()
  }

  return (
    <Fragment>
      <div className={`bg-overlay1`} onClick={() => { closeOverlay() }} ></div>
      <div className="sidebar-wrapper" id="sidebar-wrapper">
        <div className="logo-wrapper">
          <Link to={'#'}>
            <img className="img-fluid for-light" src={require("./logo/logo.png")} alt=""
              style={{ height: 55, width: 80, display: 'flex', margin: '0 auto' }}
              onClick={redirectToHomePageHandler} />
          </Link>
          <div className="back-btn" onClick={() => responsiveSidebar()}><i className="fa fa-angle-left"></i></div>
          <div className="toggle-sidebar" onClick={() => openCloseSidebar(sidebartoogle)}><Grid className="status_toggle middle sidebar-toggle" /></div>
        </div>
        <div className="logo-icon-wrapper">
          <Link to={`/`}><img className="img-fluid" src={require("./logo/logo-icon.png")} alt="" /></Link>
        </div>
        <nav className="sidebar-main" id="sidebar-main">
          <div className="left-arrow" onClick={scrollToLeft}><ArrowLeft /></div>
          <div id="sidebar-menu" style={wrapper.split(' ').includes('horizontal-wrapper') ? { 'marginLeft': margin + 'px' } : { margin: '0px' }}>
            <ul className="sidebar-links custom-scrollbar" >
              <li className="back-btn">
                <div className="mobile-back text-right"><span>{"Back"}</span><i className="fa fa-angle-right pl-2" aria-hidden="true"></i></div>
              </li>
              {
                mainmenu?.map((Item, i) =>
                  <Fragment key={i}>
                    {Item.Items.map((menuItem, i) =>
                      <li className="sidebar-list" key={i}>
                        {/* {(menuItem.type === 'sub') ?
                          <a href="javascript" className={`sidebar-title sidebar-link ${menuItem.active ? ' active ' : ''}`} onClick={(event) => { event.preventDefault(); setNavActive(menuItem) }}>
                            <menuItem.icon />
                            <span>{props.t(menuItem.title)}</span>
                            {menuItem.badge ? <label className={menuItem.badge}>{menuItem.badgetxt}</label> : ""}
                            <div className="according-menu">
                              {menuItem.active ?
                                <i className="fa fa-angle-down"></i>
                                : <i className="fa fa-angle-right"></i>
                              }
                            </div>
                          </a>
                          : ''} */}
                          {(menuItem.type === 'sub') ?
                            <a 
                              href="javascript" 
                              className={`sidebar-link sidebar-title ${menuItem.active ? activeClass() : ''}`} 
                              onClick={(event) => {event.preventDefault(); setNavActive(menuItem)}}>
                              <menuItem.icon />
                              <span>{props.t(menuItem.title)}</span>
                              {menuItem.badge ? <label className={menuItem.badge}>{menuItem.badgetxt}</label> : ""}
                              <div className="according-menu">
                                {menuItem.active ?
                                  <i className="fa fa-angle-down"></i>
                                  : <i className="fa fa-angle-right"></i>
                                }
                              </div>
                            </a>
                            : ''}

                        {/* <Link to={menuItem.path + '/'} className={`sidebar-link sidebar-title link-nav ${menuItem.active ? ' active ' : ''}`} onClick={() => toggletNavActive(menuItem)}>
                            <menuItem.icon />
                            <span>{props.t(menuItem.title)}</span>
                            {menuItem.badge ? <label className={menuItem.badge}>{menuItem.badgetxt}</label> : ""}
                          </Link> */}

                        {(menuItem.type === 'link') ?
                          (orgName == "promptcloud" && menuItem.title === "Sites") || (orgName == "promptcloud" && menuItem.title === "Issues") ?
                            <Link to="#" className={`sidebar-link sidebar-title link-nav disabled`}>
                              <menuItem.icon />
                              <span>{props.t(menuItem.title)}</span>
                              {menuItem.badge ? <label className={menuItem.badge}>{menuItem.badgetxt}</label> : ""}
                            </Link>
                            :
                            <Link to={menuItem.path + '/'} className={`sidebar-link sidebar-title link-nav ${menuItem.active ? ' active ' : ''}`} onClick={() => toggletNavActive(menuItem)}>
                              <menuItem.icon />
                              <span>{props.t(menuItem.title)}</span>
                              {menuItem.badge ? <label className={menuItem.badge}>{menuItem.badgetxt}</label> : ""}
                            </Link>
                          : (menuItem.type === 'external_link' ? <a href={menuItem.path + '/'} className={`sidebar-link sidebar-title link-nav  ${menuItem.active ? ' active ' : ''}`}
                            onClick={() => toggletNavActive(menuItem)} target='_blank'>
                            <menuItem.icon />
                            <span>{props.t(menuItem.title)}</span>
                            {menuItem.badge ? <label className={menuItem.badge}>{menuItem.badgetxt}</label> : ""}
                          </a> : '')
                        }

                        {menuItem.children ?

                          <ul className="sidebar-submenu"
                            style={menuItem.active ? sidebartoogle ? { opacity: 1, transition: 'opacity 500ms ease-in' } : { display: "block" } : { display: "none" }}>

                            {menuItem.children.map((childrenItem, index) => {

                              return (
                                <li key={index}>

                                  {(childrenItem.type === 'sub') ?
                                    <a href="javascript" className={`${childrenItem.active ? 'active' : ''}`} onClick={(event) => { event.preventDefault(); toggletNavActive(childrenItem) }}>{props.t(childrenItem.title)}
                                      <span className="sub-arrow">
                                        <i className="fa fa-chevron-right"></i>
                                      </span>
                                      <div className="according-menu">
                                        {childrenItem.active ?
                                          <i className="fa fa-angle-down"></i>
                                          : <i className="fa fa-angle-right"></i>
                                        }
                                      </div>
                                    </a>
                                    : ''}

                                  {(childrenItem.type === 'link') ?
                                    <Link
                                      to={childrenItem.path + '/'}
                                      className={`${childrenItem.active ? 'active submenu-item-link' : 'submenu-item-link'}`}
                                      onClick={() => toggletNavActive(childrenItem)}>
                                      <p className={`${childrenItem.active ? 'submenu-item-link-is-active' : 'submenu-item-link'}`}>{props.t(childrenItem.title)}</p>
                                      {/* {childrenItem.active ? <span className='childern-itm-link-content'><p className='sidebar-submenu-link-active'>{props.t(childrenItem.title)}</p></span> : <span className='childern-itm-link-content'>{props.t(childrenItem.title)}</span>} */}
                                    </Link>
                                    :
                                    <a
                                      href={childrenItem.path + '/'}
                                      className={`${childrenItem.active ? ' active  submenu-item-link': 'submenu-item-link'}`}
                                      onClick={() => toggletNavActive(childrenItem)}
                                      target='_blank'>
                                      {props.t(childrenItem.title)}
                                    </a>
                                  }
                                  {/* {(childrenItem.type === 'link') ?
                                    <Link to={childrenItem.path + '/'}
                                      className={` ChildrenItemLink ${childrenItem.active ? ' active ' : ''}`}
                                      onClick={() => toggletNavActive(childrenItem)}
                                    >
                                      {childrenItem.active ? <span className='childern-itm-link-content'><p className='sidebar-submenu-link-active'>{props.t(childrenItem.title)}</p></span> : <span className='childern-itm-link-content'>{props.t(childrenItem.title)}</span>}

                                    </Link>
                                    :
                                    <a href={childrenItem.path + '/'} className={`${childrenItem.active ? ' active ' : ''}`} onClick={() => toggletNavActive(childrenItem)} target='_blank'>{props.t(childrenItem.title)}</a>
                                  } */}

                                  {childrenItem.children ?
                                    <ul className="nav-sub-childmenu submenu-content"
                                      style={childrenItem.active ? { display: "block" } : { display: "none" }}
                                    >
                                      {childrenItem.children.map((childrenSubItem, key) =>
                                        <li key={key}>
                                          {(childrenSubItem.type === 'link') ?
                                            <Link to={childrenSubItem.path + '/'} className={` ${childrenSubItem.active ? ' childrenSubItem active' : ''}`} onClick={() => toggletNavActive(childrenSubItem)}>{props.t(childrenSubItem.title)}</Link>
                                            : ''}
                                        </li>
                                      )}
                                    </ul>
                                    : ""}

                                </li>
                              )
                            })}
                          </ul>
                          : ''}
                      </li>)}
                  </Fragment>
                )}
            </ul>
          </div>
          <div className="right-arrow" onClick={scrollToRight}><ArrowRight /></div>
        </nav>
      </div>
    </Fragment>
  );
}

export default translate(Sidebar);
