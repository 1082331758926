import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import Select from "react-select";
import { Card, CardBody, Container, Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import Loader from "react-loader-spinner";
import { Home } from "react-feather";

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function SitewiseFailedAndSucceededUrlStats() {
    const search = useLocation().search;
    const commit = new URLSearchParams(search).get('commit')
    const searchParams = {
        siteName: new URLSearchParams(search).get("filter[site_name]"),
        seleniumCluster: new URLSearchParams(search).get("filter[selenium_cluster]"),
        fetcherType: new URLSearchParams(search).get("filter[diskfetcher_type]"),
        time: new URLSearchParams(search).get("filter[time]"),
        geoCluster: new URLSearchParams(search).get("filter[geo_cluster]")
    }

    const [siteName, setSiteName] = useState({ label: 'All', value: '*' });
    const [siteNameOptions, setSiteNameOptions] = useState([])
    const [seleniumCluster, setSeleniumCluster] = useState({ label: 'All', value: '*' });
    const [fetcherType, setFetcherType] = useState({ label: 'All', value: '*' });
    const [geoCluster, setGeoCluster] = useState({ label: 'All', value: '*' })
    const [geoClusterOptions, setGeoClusterOptions] = useState([])
    const [chooseTime, setChooseTime] = useState({ label: 'Last 2 Days', value: 'now-2d' })
    const [loading, setLoading] = useState(false);
    const [urlParams, setUrlParams] = useState({
        time: 'now-2d',
        siteName: '*',
        fetcherType: '*',
        seleniumCluster: '*',
        geoCluster: '*'
    })
    const fetcherTypeOptions = [
        { label: 'All', value: '*' },
        { label: 'Get', value: 'get' },
        { label: 'Post', value: 'post' },
        { label: 'Selenium', value: 'selenium' },
        { label: 'Open_uri', value: 'open_uri' },
    ];
    const timeOptions = [
        { label: 'Today', value: 'now%2Fd' },
        { label: 'Last 30 minutes', value: 'now-30m' },
        { label: 'Last 1 hour', value: 'now-1h' },
        { label: 'Last 2 hours', value: 'now-2h' },
        { label: 'Last 4 hours', value: 'now-4h' },
        { label: "Last 10 hours", value: "now-10h" },
        { label: 'Last 12 hours', value: 'now-12h' },
        { label: 'Last 24 hours', value: 'now-24h' },
        { label: 'Last 2 days', value: 'now-2d' },
        { label: 'Last 3 days', value: 'now-3d' },
        { label: 'Last 4 days', value: 'now-4d' },
        { label: 'Last 7 days', value: 'now-7d' },
        { label: 'Last 15 days', value: 'now-15d' },
        { label: 'Last 30 days', value: 'now-30d' },
        { label: 'Last 60 days', value: 'now-60d' },
        { label: 'Last 1 year', value: 'now-1y' }
    ]
    const seleniumClusterOptions = [
        { label: 'gce_us', value: 'gce_us' },
        { label: 'gce_india', value: 'gce_india' },
        { label: 'not_specified_any', value: 'not_specified any' },
        { label: 'aws_india', value: 'aws_india' },
        { label: 'All', value: '*' }
    ]

    useEffect(() => {
        setLoading(true);
        setSearchParamsData();
        axios.get(`/api/v1/internal/dropdown_list_helper`)
            .then(res => {
                let site_names = res.data.site_names, site_names_options = [];
                for (var key in site_names) {
                    let group = {}, children = []
                    group['label'] = <div className="fw-bold col text-black">{key}</div>
                    site_names[key].map(item => {
                        children.push({ label: item, value: item })
                    })
                    group['options'] = children;
                    site_names_options.push(group)
                }
                site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: '*' }] })
                setSiteNameOptions(site_names_options);
                let geo_cluster = []
                res.data.geo_clusters.forEach(item => {
                    geo_cluster.push({ label: item, value: item })
                })
                setGeoClusterOptions(geo_cluster);
                setLoading(false)
            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong');
                setLoading(false)
            })
    }, [])

    function setSearchParamsData() {
        if (searchParams.siteName && searchParams.siteName != siteName.value) {
            setSiteName({ label: searchParams.siteName, value: searchParams.siteName })
        }
        if (searchParams.seleniumCluster && searchParams.seleniumCluster != seleniumCluster.value) {
            setSeleniumCluster({ label: searchParams.seleniumCluster, value: searchParams.seleniumCluster })
        }
        if (searchParams.fetcherType && searchParams.fetcherType != fetcherType.value) {
            setFetcherType({
                label: searchParams.fetcherType[0].toUpperCase() + searchParams.fetcherType.slice(1),
                value: searchParams.fetcherType
            });
        }
        if (searchParams.geoCluster && searchParams.geoCluster != geoCluster.value) {
            setGeoCluster({ label: searchParams.geoCluster, value: searchParams.geoCluster })
        }
        if (searchParams.time && searchParams.time != chooseTime.value) {
            setChooseTime(timeOptions.filter(item => searchParams.time == item.value)[0])
        }
        if (commit == 'Get Data' && searchParams.siteName && searchParams.fetcherType && searchParams.seleniumCluster && searchParams.geoCluster && searchParams.time) {
            setUrlParams(searchParams)
        }
    }

    const handleGetData = () => {
        window.location.href = `/internal/diskfetcher_segment_stats/sitewise_failed_and_succeeded_url_stats?filter[site_name]=${siteName.value}&filter[selenium_cluster]=${seleniumCluster.value}&filter[diskfetcher_type]=${fetcherType.value}&filter[geo_cluster]=${geoCluster.value}&filter[time]=${chooseTime.value}&commit=${'Get Data'}`
    }

    return <Container fluid>
        <Container fluid>
            <div className="page-title">
                <Row className=''>
                    <Col xs="6">
                        <h3>Internal Dashboard</h3>
                    </Col>
                    <Col xs="6">
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <Link to={`/internal`}><Home /></Link>
                            </BreadcrumbItem>

                            <BreadcrumbItem className={commit == 'Get Data' ? '' : 'fw-bold'} >
                                {commit == 'Get Data' ? <a href={'/internal/end_to_end_crawl_tracker'}>Sitewise Failed and Succeeded Url Stats</a> : 'Sitewise Failed and Succeeded Url Stats'}
                            </BreadcrumbItem>
                            {commit == 'Get Data' && <BreadcrumbItem>
                                <b>Failed and Succeeded Url Count Per Site</b>
                            </BreadcrumbItem>}
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
        </Container>
        {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> : <Fragment>
            <Card>
                <CardBody>
                    <Row>
                        <div className="col-sm-9">
                            <label className="fw-bold">Site Name</label>
                            <Select
                                value={siteName}
                                onChange={option => setSiteName(option)}
                                options={siteNameOptions} />
                        </div>
                        <div className="col-sm-3">
                            <label className="fw-bold">Selenium Cluster</label>
                            <Select
                                value={seleniumCluster}
                                onChange={option => setSeleniumCluster(option)}
                                options={seleniumClusterOptions} />
                        </div>
                    </Row>
                    <Row className="align-items-end mt-2">
                        <div className="col-sm-3">
                            <label className="fw-bold">Diskfetcher Type</label>
                            <Select
                                value={fetcherType}
                                onChange={option => setFetcherType(option)}
                                options={fetcherTypeOptions} />
                        </div>
                        <div className="col-sm-3">
                            <label className="fw-bold">Geo Cluster</label>
                            <Select
                                value={geoCluster}
                                onChange={option => setGeoCluster(option)}
                                options={geoClusterOptions} />
                        </div>
                        <div className="col-sm-3">
                            <label className="fw-bold">Choose Time</label>
                            <Select
                                value={chooseTime}
                                onChange={option => setChooseTime(option)}
                                options={timeOptions} />
                        </div>
                        <div className="col-sm-3">
                            <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
                        </div>
                    </Row>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <center>
                        <a className="link-primary text-center" target={'_blank'} href={`${KIBANA_PLM_URL}app/kibana#/visualize/edit/Sitewise-Failed-and-Succeeded-Url-Stats?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.time},mode:quick,to:${urlParams.time == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(language:lucene,query:(query_string:(analyze_wildcard:!t,query:'diskfetcher_crawl_time_tracker:true+AND+diskfetcher_type:${urlParams.fetcherType}+AND+site_name:${urlParams.siteName}+AND+selenium_cluster:${urlParams.seleniumCluster}+AND+geo_cluster:${urlParams.geoCluster}'))))`}>Click here to see below graph in kibana</a>
                    </center>
                    <iframe src={`${KIBANA_PLM_URL}app/kibana#/visualize/edit/Sitewise-Failed-and-Succeeded-Url-Stats?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.time},mode:quick,to:${urlParams.time == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(language:lucene,query:(query_string:(analyze_wildcard:!t,query:'diskfetcher_crawl_time_tracker:true+AND+diskfetcher_type:${urlParams.fetcherType}+AND+site_name:${urlParams.siteName}+AND+selenium_cluster:${urlParams.seleniumCluster}+AND+geo_cluster:${urlParams.geoCluster}'))))&embed=true`} height="600" width="100%" className="border-0" ></iframe>
                </CardBody>
            </Card>
        </Fragment>}
    </Container>
}