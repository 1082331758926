import React, {useState, useEffect} from 'react'
import { Card, CardHeader, CardBody, Alert } from 'reactstrap'
import Loader from 'react-loader-spinner';
import axios from 'axios'
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { useParams } from "react-router-dom";
import { JSONTree } from 'react-json-tree';

export default function SampleMaxRecord({theme, activeTabName}) {
  let { sitename } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (activeTabName == "segment_level_qa"){
      setLoading(true)
      axios.get(`/api/v1/internal/qa/segment_level_qa?site_name=${sitename}&data_fetch_type=sample_max_record`).then(res => {
        setData(res?.data?.qa_json_data)
        setLoading(false)
      }).catch(err => {
        console.log("err", err);
        toast.error(err.response?.data?.message)
        setLoading(false)
      })
    }
  }, [activeTabName])

  return (
    <Card className='my-3'>
      <CardHeader className='bg-success py-3'>
        Sample record with max number of fields
      </CardHeader>
      <CardBody className="custom-scrollbar" style={{height: "300px", overflow: "scroll"}}>
        {
          loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
            :
            <>
              {
                data?.file_present == false ? 
                <Alert color='light'>
                  <div dangerouslySetInnerHTML={{__html: data?.error_message}} />
                </Alert>
                  :
                  <JSONTree data={data?.sample_record_with_max_number_of_fields} theme={theme} invertTheme={false} />
              }
            </>
        }
      </CardBody>
    </Card>
  )
}

