import React from 'react';
import { Row, Col, Card, CardBody, Table } from 'reactstrap';
import DatePicker from "react-datepicker";
import AsyncSelect from 'react-select/async';
import { Info, Link, ExternalLink, Copy } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import QueueSizeTable from '../DataTables/QueueSizeTable.component';

import { NewInternalBasePoint } from '../../../../Common/BaseEsPoints/InternalEsBasePoint';

export default function OverallStatsTab({ data, siteName, fromDate, endDate, tableData, setFromDate, setEndDate, selectedSite, setSelectedSite, defaultOptions, promiseOptions, ButtonClickHandler }) {

  // Helper function to copy content from the clipboard
  function copyToClipboard(value) {
    navigator.clipboard.writeText(value)
  };

  // Helper function to format date
  function formatDateYYYYMMDD(date) {
    let d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();
    if (month.length < 2) {

      month = '0' + month;
    }
    if (day.length < 2) {

      day = '0' + day;
    }
    return [year, month, day].join('/');
  }

  return (
    <div>
      <Card>
        <CardBody>
          <Row>
            <Col md={8}>
              <Row className='my-3'>
                <Col md={12}>
                  <h6 className='fw-bold'>
                    Site Name
                  </h6>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions={defaultOptions}
                    loadOptions={promiseOptions}
                    name='site_name'
                    value={selectedSite}
                    onChange={res => setSelectedSite(res)}
                    placeholder="Select a site name"
                  />
                  <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
                </Col>
              </Row>
              <Row className='my-3'>
                <Col md={4}>
                  <h6 className='fw-bold'>
                    From Date
                  </h6>
                  <DatePicker
                    selected={fromDate}
                    onChange={(date) => setFromDate(date)}
                    maxDate={endDate}
                    className='form-control'
                    dateFormat={"YYY/MM/dd"} />
                </Col>
                <Col md={4}>
                  <h6 className='fw-bold'>
                    To Date
                  </h6>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    maxDate={new Date()}
                    className='form-control'
                    minDate={fromDate}
                    dateFormat={"YYY/MM/dd"} />
                </Col>
                <Col md={4} className='d-flex justify-content-center mt-3'>
                  <button className='btn btn-success my-auto w-100' onClick={ButtonClickHandler}>Get Data</button>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <Table bordered className='my-2' responsive>
                <tbody>
                  <tr>
                    <td className='fw-bold'>Asignees</td>
                    <td className='fw-bold'>Biz Contact</td>
                    <td className='fw-bold'>DB Query Buffer Q Size</td>
                  </tr>
                  <tr>
                    <td>{data?.assignees}</td>
                    <td>{data?.biz_contact}</td>
                    <td>
                      <p data-tip data-for='db_query_buffer'
                        className={`d-flex justify-content-center ${data?.db_query_buffer_queue_size == 0 ? '' : data?.db_query_buffer_queue_size > 3000 ? 'text-danger' : 'text-success'}`}>
                        {data?.db_query_buffer_queue_size} <Info size={18} className='mx-2' />
                      </p>
                      <ReactTooltip id='db_query_buffer' clickable multiline delayHide={1000} effect='solid' >
                        <p> Get queue name from PipelineV2::Utils.get_db_query_buffer_queue_name(sitename) and
                          <br /> queue size from SdfBaseQueue.new(queue_name).size </p>
                      </ReactTooltip>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className='btn-group my-2'>
                <button className='btn btn-sm btn-success d-flex align-items-center justify-content-center'> <Link size={14} className='mx-2' /> <p className='my-0'>Regular API-Link</p> </button>
                <a className='btn btn-sm btn-outline-success' target="_blank" href={data?.regular_api_link} data-tip='Open in new Tab'> <ExternalLink size={14} /></a>
                <button className='btn btn-sm btn-outline-success'
                  onClick={() => copyToClipboard(data?.regular_api_link)} data-tip='copy to clipboard'> <Copy size={14} /></button>
              </div>
              <div className='btn-group my-2'>
                <button className='btn btn-sm btn-success d-flex align-items-center justify-content-center'> <Link size={14} className='mx-2' /> <p className='my-0'>API-Link for merged file </p> </button>
                <a className='btn btn-sm btn-outline-success' target="_blank" href={data?.merged_file_api_link} data-tip='Open in new Tab'> <ExternalLink size={14} /> </a>
                <button className='btn btn-sm btn-outline-success'
                  onClick={() => copyToClipboard(data?.merged_file_api_link)} data-tip='copy to clipboard'> <Copy size={14} /></button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <h6 className='fw-bold text-center my-2'>Queue Size</h6>
          <Table className='my-2' bordered responsive>
            <thead>
              <tr>
                <th className='fw-bold text-center'>Site</th>
                <th className='fw-bold text-center'>Drss seed Q size</th>
                <th className='fw-bold text-center'>Rss Q size</th>
                <th className='fw-bold text-center'>Dep data upload Q</th>
                <th className='fw-bold text-center'>Dep data failed Q</th>
                <th className='fw-bold text-center'>Test urls Q</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <a href={`${NewInternalBasePoint}/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${formatDateYYYYMMDD(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${formatDateYYYYMMDD(endDate)}&filter%5Bsite%5D=${data?.site_data[0]['site']}`} target='_blank'
                    data-tip data-for='goto_segment_view' className='d-flex justify-content-center'>
                    {data?.site_data[0]['site']} <Info size={18} className='mx-2' />
                  </a>
                  <ReactTooltip id='goto_segment_view' multiline>
                    <p> Go to Segment View </p>
                  </ReactTooltip>
                </td>
                <td>
                  <a href={`/internal/queue_sizes/search?filter%5Bqueue_type%5D=drss_seed_urls_queue&filter%5Bsite%5D=${data?.site_data[0]['site']}`} target='_blank'
                    data-tip data-for='goto_queue_view' className='d-flex justify-content-center'>
                    {data?.drss_seed_urls_queue_size} <Info size={18} className='mx-2' />
                  </a>
                </td>
                <td>
                  <a href={`/internal/queue_sizes/search?filter%5Bqueue_type%5D=rss_queue&filter%5Bsite%5D=${data?.site_data[0]['site']}`} target='_blank'
                    data-tip data-for='goto_queue_view' className='d-flex justify-content-center'>
                    {data?.rss_queue_size} <Info size={18} className='mx-2' />
                  </a>
                </td>
                <td>
                  <a href={`/internal/queue_sizes/${data?.site_data[0]['site']}_dependent_data_upload_queue`} target='_blank'
                    data-tip data-for='goto_queue_view' className='d-flex justify-content-center'>
                    {data?.dependent_data_upload_queue_size} <Info size={18} className='mx-2' />
                  </a>
                </td>
                <td>
                  <a href={`/internal/queue_sizes/${data?.site_data[0]['site']}_dependent_data_upload_failed_queue`} target='_blank'
                    data-tip data-for='goto_queue_view' className='d-flex justify-content-center'>
                    {data?.dependent_data_upload_failed_queue_size} <Info size={18} className='mx-2' />
                  </a>
                </td>
                <td>
                  <a href={`/internal/queue_sizes/search?filter%5Bqueue_type%5D=test_run_queue&filter%5Bsite%5D=${data?.site_data[0]['site']}_dependent_data_upload_failed_queue`} target='_blank'
                    data-tip data-for='goto_queue_view' className='d-flex justify-content-center'>
                    {data?.test_run_queue_size} <Info size={18} className='mx-2' />
                  </a>
                </td>
              </tr>
              <ReactTooltip id='goto_queue_view' multiline>
                <p> Go to Queue Size View </p>
              </ReactTooltip>
            </tbody>
          </Table>
          <div className='my-5'>
            <p className='text-center'>
              <b>Note: </b> <Info size={14} /> indicates the hover is there. It has some informations or links
            </p>
          </div>
          <QueueSizeTable
            data={data}
            tableData={tableData}
            fromDate={fromDate}
            endDate={endDate}
          />
        </CardBody>
      </Card>
    </div>
  )
}
