import React from 'react';
import { useEffect, useState } from 'react';

import { Row, Card } from 'reactstrap';
import { useHistory } from "react-router-dom";
import StepZilla from "react-stepzilla";
import { toast } from 'react-toastify';

import axios from 'axios';

import { getItem, userLoggedin } from '../../../localStorageMethods';
import SiteDetails from './Stepzilla/SiteDetails/SiteDetails.component';
import SchemaDetails from './Stepzilla/SchemaDetails/SchemaDetails.component';
import AdditionalDetails from './Stepzilla/AdditionalDetails/AdditionalDetails.component';
import RequirementsSubmissionCard from './Cards/RequirementsSubmissionCard.component';



export default function CreateNewOrganization() {
  let history = useHistory();
  const [organizationName, setOrganizationName] = useState('');
  const [siteDetails, setSiteDetails] = useState([]);
  const [userEmail, setUserEmail] = useState(getItem('authEmail'));
  const [industrySelected, setIndustrySelected] = useState('none');
  const [countrySelected, setCountrySelected] = useState('none');
  const [enteredStepForm, setEnteredStepForm] = useState(false);
  const [nextStep, setNextStep] = useState(false);
  const [newRows, setNewRows] = useState(['row1', 'row2', 'row3', 'row4'])
  const [errors, setErrors] = useState({
    organization: null, country: null, industry: null
  })
  const [schemaDetails, setSchemaDetails] = useState({
    sgName: '', crawlType: '', dataDelivery: 'api',
    dataformat: '', freqSelection: '', freqMonthly: '',
    freqWeekly: '', freqOther: '', rssUrlsCrawlLimit: '', schemaFields: [],
  })

  const token = localStorage.getItem("authToken");
  const email = localStorage.getItem("authEmail");

  useEffect(() => {
    axios.get(`/api/v1/app_status?page_type=client&controller_name=dashboard`).then(res => {
      if (res?.data?.app_status === "maintenance") {
        //history.push(res?.data?.maintain_url)
        window.location.replace(res?.data?.maintain_url)
      }
    }).catch(err => {
    })
  }, [])
  
  useEffect(() => {
    if (token && email) {
      axios.defaults.headers["X-Auth-Email"] = email;
      axios.defaults.headers["X-Auth-Token"] = token;
      axios.defaults.headers["Authorization"] = `Bearer ${token}`;
    }
  }, [token, email]);


  useEffect(() => {
    if (!userLoggedin()) {
      history.push('/users/sign_in')
    }
    axios.get(`/api/v1/user/details?email=${email}&page_type=client&controller_name=dashboard`).then(res => {
      if (res.data.user.organization_present_status && !(res.data.user.is_admin)) {
        history.push('/')
      }
    }).catch(err => {
      console.error(err?.response?.data?.message || 'Oops Something went wrong!');
    })
  }, []);


  useEffect(() => {
    if (enteredStepForm) {
      setErrors({
        ...errors,
        organization: organizationName.length < 3
          ? 'Organization name is required' : errors.organization == 'Organization name is taken already'
            ? 'Organization name is taken already' : null,
        industry: industrySelected == 'none'
          ? 'Industry is required' : null,
        country: countrySelected == 'none'
          ? 'Country is required' : null
      })
    }
  }, [enteredStepForm]);


  useEffect(() => {
    let errs = Object.values(errors).filter(x => x != null).filter(x => x != '')
    if (errs.length == 0 && industrySelected != 'none' && countrySelected != 'none' && organizationName.length >= 3) {
      setNextStep(true)
    }
    else {
      setNextStep(false)
    }
  }, [countrySelected, industrySelected, organizationName, errors]);


  // Helper function to handle logout
  async function handleLogout() {
    try {
      const response = await axios.delete(`/api/v1/users/logout?page_type=client&controller_name=sessions`, {
        user: {
          email: localStorage.getItem("authEmail"),
          authenticity_token: localStorage.getItem("authToken")
        }
      }
      )
    }
    catch (error) {
      toast.error(error?.response?.data?.message);
    }
    finally {
      localStorage.removeItem('authEmail');
      localStorage.removeItem('authToken');
      localStorage.removeItem('user_name');
      toast.success("User logged out successfully")
      history.push("/users/sign_in")
    }
  }


  // Helper function to handle setting organization name
  async function handleOrganization(e) {
    setErrors({ ...errors, organization: null })
    setOrganizationName(e.target.value)
    let regexpr = new RegExp(/^[a-zA-Z0-9\s]+$/);
    if (e.target.value.trim().length < 3) {
      setErrors({
        ...errors,
        organization: 'please enter atleast 3 characters'
      })
    }
    else if (/\b(something|none|dummy|na|null|noname|blank|test)\b/.test(e.target.value.toLowerCase())) {
      setErrors({
        ...errors,
        organization: 'Organization name should be valid.'
      })
    }
    else if (/^\d[0-9a-zA-Z\s]*$/.test(e.target.value)) {
      setErrors({
        ...errors,
        organization: 'Organization name should not be just numbers (or) not start with numbers'
      })
    }
    else if (!(regexpr.test(e.target.value))) {
      setErrors({
        ...errors,
        organization: 'Special characters not allowed in organizaiton name.'
      })
    }
    else {
      try {
        axios.post(`/api/v1/organizations/validate_uniqness`, {
          "organization": { "name": e.target.value },
          page_type: "client",
          controller_name: "organizations"
        }).then((res) => {
          const data = res.data
          if (data.status == 'success') {
            setErrors({
              ...errors,
              organization: null
            })
          }
        })
          .catch(err => {
            setErrors({
              ...errors,
              organization: 'Organization name is taken already'
            })
          })
      } catch {
      }
    }
  }


  const steps = [
    {
      name: 'Schema Details',
      component: <SchemaDetails
        setEnteredStepForm={setEnteredStepForm}
        enteredStepForm={enteredStepForm}
        schemaDetails={schemaDetails}
        setSchemaDetails={setSchemaDetails}
        nextStep={nextStep} />
    },
    {
      name: 'Site Details',
      component: <SiteDetails
        siteDetails={siteDetails}
        setSiteDetails={setSiteDetails}
        nextStep={nextStep}
        setNewRows={setNewRows}
        newRows={newRows}
        rssUrlsCrawlLimit={schemaDetails.rssUrlsCrawlLimit} />
    },
    {
      name: 'Additional Details',
      component: <AdditionalDetails
        schemaDetails={schemaDetails}
        siteDetails={siteDetails}
        organizationName={organizationName}
        industrySelected={industrySelected}
        countrySelected={countrySelected}
        nextStep={nextStep} />
    },
  ];



  return (
    <>
      <div className='pre-header d-flex justify-content-between align-items-center p-4'>
        <p className='m-0'>
          Hi {userEmail}!
        </p>
        <button className='btn btn-outline-secondary'
          onClick={handleLogout}>
          Logout
        </button>
      </div>

      <div className='mx-4 my-2'>
        <Card className='px-4 py-3 mycard'>
          <Row className='pt-2'>
            <h4 className='fw-light'>
              Submit Requirements
            </h4>
          </Row>
        </Card>

        <RequirementsSubmissionCard
          errors={errors}
          setErrors={setErrors}
          countrySelected={countrySelected}
          setCountrySelected={setCountrySelected}
          industrySelected={industrySelected}
          setIndustrySelected={setIndustrySelected}
          organizationName={organizationName}
          handleOrganization={handleOrganization}
        />

        <Card className='px-5 py-4'>
          <StepZilla
            steps={steps}
            showSteps={true}
            showNavigation={true}
            stepsNavigation={true}
            prevBtnOnLastStep={true}
            dontValidate={false} />
        </Card>
      </div>

      <div className='organization-footer p-3 d-flex align-items-center justify-content-center'>
        Copyright © 2021 PromptCloud All Rights Reserved.
        <a href='#' target='_blank'> Privacy Policy </a>
      </div>
    </>
  )
}
