import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Home } from 'react-feather';
import { Link, useLocation } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody } from 'reactstrap';

export default function EndToEndUrlTracker() {
    const search = useLocation().search;
    const commit = new URLSearchParams(search).get("commit");
    const searchParams = {
        siteName: new URLSearchParams(search).get("filter[site_name]"),
        url: new URLSearchParams(search).get("filter[url]")
    }
    const [url, setUrl] = useState('')
    const [siteName, setSiteName] = useState({ label: "Select a option", value: '' })
    const [siteNameOptions, setSiteNameOptions] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const columns = [
        { name: 'Diskfetcher Status', selector: 'diskfetcher_stats', sortable: true, center: true },
        { name: 'Rss Crawl Status', selector: 'rss_stats', sortable: true, center: true },

    ]
    useEffect(() => {
        setSearchParamsData()
        axios.get('/api/v1/internal/dropdown_list_helper')
            .then(res => {
                let site_names = res.data.site_names, site_names_options = []
                for (var key in site_names) {
                    let group = {}, children = []
                    group['label'] = <div className="fw-bold col text-black">{key}</div>
                    site_names[key].map(item => {
                        children.push({ label: item, value: item })
                    })
                    group['options'] = children;
                    site_names_options.push(group)
                }
                site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: '*' }] })
                setSiteNameOptions(site_names_options);

            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong')
            });
    }, [])

    function setSearchParamsData() {
        if (searchParams.siteName && searchParams.siteName != siteName.value) {
            setSiteName({ label: searchParams.siteName == '*' ? 'All' : searchParams.siteName, value: searchParams.siteName });
        }
        if (searchParams.url && searchParams.url != url) {
            setUrl(searchParams.url)
        }
        if (commit == 'Get Data') {
            fetchData(searchParams.siteName, searchParams.url);

        }

    }

    function fetchData(SiteName = siteName.value || '', URL = url) {
        setLoading(true)
        axios.get(`/api/v1/internal/pipeline_stage/end_to_end_url_tracker?filter[url]=${URL || ''}&filter[site_name]=${SiteName || ''}`)
            .then(res => {
                setData(res.data.data);
                setLoading(false)
            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong')
                setLoading(false);
            })
    }

    const handleGetData = () => {
        window.location.href = `/internal/pipeline_stage/end_to_end_url_tracker?filter[url]=${url}&filter[site_name]=${siteName.value}&commit=${'Get Data'}`
    }

    const promiseOptions = (inputValue) => {
        if (inputValue.length < 3) {
            return null
        } else {
            return new Promise((resolve) => {
                axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
                    if (res.status == '200') {
                        let site_names = res.data.site_names, site_names_options = []
                        for (var key in site_names) {
                            let group = {}, children = []
                            group['label'] = <div className="fw-bold col text-black">{key}</div>
                            site_names[key].map(item => {
                                children.push({ label: item, value: item })
                            })
                            group['options'] = children;
                            site_names_options.push(group)
                        }
                        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                        setSiteNameOptions(site_names_options)
                        resolve(site_names_options)
                    }
                }).catch(err => {
                    console.log(`err`, err)
                })
            })
        }
    }

    return <Container fluid>
        <div className="page-title">
            <Row className=''>
                <Col xs="6">
                    <h3>Internal Dashboard</h3>
                </Col>
                <Col xs="6">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={'/interanal'}>
                                <Home />
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            {commit == 'Get Data' ? <a href={'/internal/pipeline_stage/end_to_end_url_tracker'}>End to End Url Tracker</a> : 'End to End Url Tracker '}
                        </BreadcrumbItem>
                        {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                            Search Results
                        </BreadcrumbItem>}
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
        <Card>
            <CardBody>
                <Row>
                    <Col sm="5">
                        <label className="fw-bold">Enter a Url</label>

                        <input placeholder='Enter a Url' className='form-control' value={url} onChange={event => setUrl(event.target.value)} />
                    </Col>

                    <div className="col-sm-5">
                        <label className="fw-bold">Site Name</label>
                        <AsyncSelect cacheOptions defaultOptions={siteNameOptions} name='site_name' value={siteName}
                            loadOptions={promiseOptions} onChange={(data) => {
                                setSiteName(data)
                              }}
                            placeholder="Select a site name"
                        />
                        <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
                    </div>

                    <Col sm="2">
                        <button className='btn btn-success w-100' onClick={handleGetData} >Get Data</button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
        {data.length > 0 ? <Card>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={data} />
            </CardBody>
        </Card> : null}
    </Container>
}