import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Home } from 'react-feather';
import { Link } from 'react-router-dom';

export default function BreadcrumbComponent() {
  return (
    <div>
      <Container fluid>
        <div className="page-title">
          <Row className=''>
            <Col xs="6">
              <h3>Internal Dashboard: Test Site Stats</h3>
            </Col>
            <Col xs="6">
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to={`/internal`}><Home /></Link>
                </BreadcrumbItem>
                {window.location.pathname.includes('/test_site_stats/search')
                  ?
                  <>
                    <BreadcrumbItem>
                      <Link to={'/internal/test_site_stats'}>
                        Test Site Stats
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <b>Search </b>
                    </BreadcrumbItem>
                  </>
                  : <BreadcrumbItem active>
                    Test Site Stats
                  </BreadcrumbItem>}
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}
