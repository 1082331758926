import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { Form, FormGroup } from 'react-bootstrap';
import { Label, Input } from 'reactstrap';
import { useState } from 'react';
import CreatableSelect from "react-select/creatable";
import { toast } from 'react-toastify';
import axios from 'axios';

// URL for POST request
const notFeasibleWebsitesURL = '/api/v1/admins/not_feasible_websites/'
// Helper function to create default options
const createOption = (label) => ({
  label,
  value: label,
});
const defaultOptions = [
  createOption('Site dynamically blocks crawling'),
  createOption('Crawling is disallowed via site\'s Robots.txt file'),
  createOption('Site uses Captcha/reCaptcha'),
];

export default function AddWebsite() {
  const [isOpen, setIsOpen] = useState(false);
  const [requestBody, setRequestBody] = useState({ sitename: "", url: "", reason: "" });
  const [selectedOptions, setSelectedOptions] = useState({ label: null, value: null });
  const [options, setOptions] = useState(defaultOptions);

  // Helper function to clear the request body
  function resetRequestBodyParamaters() {
    setRequestBody({ sitename: null, url: null, reason: null });
  }

  // Helper function to handle modal opening and closing
  function toggle() {
    resetRequestBodyParamaters();
    setIsOpen(!isOpen);
  };


  // Helper function for setting input to body
  function onInputChangeHandler(event) {
    if (event.target) {
      const { name, value } = event.target;
      setRequestBody({ ...requestBody, [name]: value });
    }
  };

  // Helper function to handle reason from dropdown
  function reasonSelectHandler(event) {
    setSelectedOptions(event)
    const { value } = event;
    setRequestBody({ ...requestBody, reason: value });
  }

  // Helper function to create a user defined reason
  function createOptionHandler(inputValue) {
    const newOption = createOption(inputValue);
    setSelectedOptions(newOption);
    setOptions([...options, newOption]);
    setRequestBody({ ...requestBody, reason: newOption.value });
  }

  // Helper function to validate required inputs
  function validateInputValues() {
    const { sitename, url, reason } = requestBody;
    if (!(sitename && url && reason)) {
      toast.error("Sitename, URL and Reason are required and cannot be empty!");
      return false;
    }
    return true;
  }

  // Helper function to make POST request
  async function submitPOSTRequest(requestUrl, postRequestBody) {
    try {
      await axios.post(requestUrl, { not_feasible_website: postRequestBody });
      toast.success("Website Added Successfully to the Not Feasible List!!");
      toggle();
      setTimeout(()=>location.reload(),2500);
      
    }
    catch (error) {
      toast.error(error.message);
    }
  }

  // Helper function to submit the form
  function onSubmitSiteAsNotFeasibleHandler() {
    validateInputValues() && submitPOSTRequest(notFeasibleWebsitesURL, requestBody);
  }


  return (
    <>
      <button className="btn btn-primary" onClick={toggle} >+ Add New Site</button>
      <div>
        <Modal funk={"true"} isOpen={isOpen} toggle={toggle} size='lg'>
          <ModalHeader toggle={toggle}>
            Add Website As Not Feasible
          </ModalHeader>
          <ModalBody>
            <Form>
              <div className='row mb-3 align-items-center'>
                <Label className='fw-bold col-sm-2'>Site Name </Label>
                <div className='col-sm-10'>
                  <Input className='form-control' name="sitename" onChange={onInputChangeHandler} required />
                </div>
              </div>

              <div className='row mb-3 align-items-center'>
                <Label className='fw-bold  col-sm-2' >URL </Label>
                <div className='col-sm-10'>
                  <Input className='form-control' name="url" onChange={onInputChangeHandler} required />
                </div>
              </div>

              <div className='row mb-3 align-items-center'>
                <Label className='fw-bold  col-sm-2' >Reason </Label>
                <div className='col-sm-10'>
                  <CreatableSelect
                    options={options}
                    name='reason'
                    onChange={reasonSelectHandler}
                    onCreateOption={createOptionHandler}
                    value={selectedOptions.value === null ? '' : selectedOptions}
                    placeholder="Select from list or enter a new reason"
                  />
                </div>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color='light' onClick={toggle}>Cancel</Button>
            <Button color='success' onClick={onSubmitSiteAsNotFeasibleHandler} >Add To List</Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  )
}
