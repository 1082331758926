import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Container, Row, Col, Breadcrumb,BreadcrumbItem } from "reactstrap";
import { Home } from "react-feather";
import axios from "axios";
import { useLocation, Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import AsyncSelect from 'react-select/async';
import Select, { components } from "react-select";

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function OverallFailedUrlsCount() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get('commit');
  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[site_name]"),
    time: new URLSearchParams(search).get("filter[time]")
  }
  const [siteName, setSiteName] = useState({ label: 'All', value: '*' });
  const [time, setTime] = useState({ label: 'Last 2 days', value: 'now-2d' });
  const [siteNameOptions, setSiteNameOptions] = useState([]);
  const [urlParams, setUrlParams] = useState({
    siteName: '*',
    time: 'now-2d'
  })
  const [loading, setLoading] = useState(false);
  const timeOptions = [
    { label: 'Today', value: 'now%2Fd' },
    { label: 'Last 30 minutes', value: 'now-30m' },
    { label: 'Last 1 hour', value: 'now-1h' },
    { label: 'Last 2 hours', value: 'now-2h' },
    { label: 'Last 4 hours', value: 'now-4h' },
    { label: "Last 10 hours", value: "now-10h" },
    { label: 'Last 12 hours', value: 'now-12h' },
    { label: 'Last 24 hours', value: 'now-24h' },
    { label: 'Last 2 days', value: 'now-2d' },
    { label: 'Last 3 days', value: 'now-3d' },
    { label: 'Last 4 days', value: 'now-4d' },
    { label: 'Last 7 days', value: 'now-7d' },
    { label: 'Last 15 days', value: 'now-15d' },
    { label: 'Last 30 days', value: 'now-30d' },
    { label: 'Last 60 days', value: 'now-60d' },
    { label: 'Last 1 year', value: 'now-1y' }
  ];

  useEffect(() => {
    setLoading(true)
    setSearchParamsData()
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let site_names = res.data.site_names, site_names_options = []
        for (var key in site_names) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
          site_names[key].map(item => {
            children.push({ label: item, value: item })
          })
          group['options'] = children;
          site_names_options.push(group)
        }
        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: '*' }] })
        setSiteNameOptions(site_names_options);
        setLoading(false);
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }, []);

  function setSearchParamsData() {
    if (searchParams.siteName && searchParams.siteName != siteName.value) {
      setSiteName({ label: searchParams.siteName, value: searchParams.siteName })
    }
    if (searchParams.time && searchParams.time != time.value) {
      setTime(timeOptions.filter(item => searchParams.time == item.value)[0])
    }
    if (commit == 'Get Data' && searchParams.siteName && searchParams.time) {
      setUrlParams(searchParams)
    }
  }

  const promiseOptions = (inputValue) => {
    if(inputValue.length < 3){
      return null
    } else {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200'){
            console.log(res.data)
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
              site_names[key].map(item => {
                children.push({ label: item, value: item })
              })
              group['options'] = children;
              site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: '*' }] })
            setSiteNameOptions(site_names_options)
            resolve(site_names_options)
          }
        }).catch(err => {
          console.log(`err`, err)
        })
      })
    }
  }

  const handleSiteNameChange = (data) => {
    setSiteName(data)
  }

  const handleGetData = () => {
    window.location.href = `/internal/diskfetcher_segment_stats/overall_failed_urls_count?filter[site_name]=${siteName.value}&filter[time]=${time.value}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <Container fluid>
      <div className="page-title">
        <Row className=''>
          <Col xs="6">
            <h3>Internal Dashboard</h3>
          </Col>
          <Col xs="6">
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={`/internal`}><Home /></Link>
              </BreadcrumbItem>

              <BreadcrumbItem className={commit == 'Get Data' ? '' : 'fw-bold'} >
                {commit == 'Get Data' ? <a href={'/internal/diskfetcher_segment_stats/overall_failed_urls_count'}>Overall Failed URLs Count</a> : 'Overall Failed URLs Count'}
              </BreadcrumbItem>
              {commit == 'Get Data' && <BreadcrumbItem>
                <b>Search</b>
            </BreadcrumbItem>}
          </Breadcrumb>
        </Col>
      </Row>
    </div>
  </Container>
  {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> : <Fragment>
    <Card>
      <CardBody>
        <Row className="align-items-end">
          <div className="col-sm-7">
            <label className="fw-bold">Site Name</label>
            <AsyncSelect cacheOptions defaultOptions={siteNameOptions} name='site_name' value={siteName}
              loadOptions={promiseOptions} onChange={handleSiteNameChange}
              placeholder="Select a site name"
            />
            <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
          </div>
          <div className="col-sm-3">
            <label className="fw-bold">Choose Time</label>
            <Select
              value={time}
              onChange={option => setTime(option)}
              options={timeOptions} />
            <p>&nbsp;</p>
          </div>
          <div className="col-sm-2">
            <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
            <p>&nbsp;</p>
          </div>
        </Row>
      </CardBody>
    </Card>
    <Card>
      <CardBody>
        <center>
          <a className="link-primary" target={'_blank'} href={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.time},mode:quick,to:${urlParams.time == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(language:lucene,query:(query_string:(analyze_wildcard:!t,query:'custom:%22percentage_disfetcher_failure%22+AND+site_name:+${urlParams.siteName}'))),uiState:(),vis:(aggs:!((id:'1',params:(customLabel:failed_urls_count,field:failed_count),schema:metric,type:sum),(id:'2',params:(field:site_name.raw,order:desc,orderBy:'1',size:30),schema:segment,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Sum+of+Failed+Urls+Count',type:histogram))`}>Click here to see below graph in kibana</a>
        </center>
        <iframe src={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.time},mode:quick,to:${urlParams.time == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(language:lucene,query:(query_string:(analyze_wildcard:!t,query:'custom:%22percentage_disfetcher_failure%22+AND+site_name:+${urlParams.siteName}'))),uiState:(),vis:(aggs:!((id:'1',params:(customLabel:failed_urls_count,field:failed_count),schema:metric,type:sum),(id:'2',params:(field:site_name.raw,order:desc,orderBy:'1',size:30),schema:segment,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Sum+of+Failed+Urls+Count',type:histogram))&embed=true`} height="800" width="100%" className="border-0"></iframe>
      </CardBody>
    </Card>
</Fragment>}

    </Container>
  }
