import React from 'react';
import logo from '../../../../Common/Logo/logo.png';

export default function OrganizationAddress({ serviceToUse }) {
  if ((serviceToUse === 'quickbooks') || (serviceToUse === 'zohobooks')) {
    return (
      <div>
        <div className='media d-flex'>
          <div className='media-left'>
            <img className="media-object img-100" src={logo} alt="" />
          </div>
          <div className='media-body m-l-20 text-right'>
            <h6 className='media-heading'>PromptCloud Technologies Pvt. Ltd., </h6>
            <p className='my-0'> #118/1, 80 ft. Road, </p>
            <p className='my-0'>Indira Nagar,</p>
            <p className='my-0'>Bangalore - 560075,</p>
            <p className='my-0'> India </p>
            <p className='my-0'>
              <b>GSTIN: </b>29AAHCP9825C1Z5
            </p>
          </div>
        </div>
      </div>
    )
  }
  else {
    return (
      <div>
        <div className='media d-flex'>
          <div className='media-left'>
            <img className="media-object img-100" src={logo} alt="" />
          </div>
          <div className='media-body m-l-20 text-right'>
            <h6 className='media-heading'>Promptcloud Inc. </h6>
            <p className='my-0'> 16192 Coastal Highway </p>
            <p className='my-0'> Lewes DE 19958 </p>
            <p className='my-0'> United States </p>
          </div>
        </div>
      </div>
    )
  }
}
