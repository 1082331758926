import React from 'react';
import { useEffect, useState, useContext } from 'react'
import { useParams } from "react-router-dom";
import Breadcrumb from '../../../Common/BreadCrumb/BreadCrumbs.component';
import { Card, CardBody, CardFooter, CardHeader, Container } from 'reactstrap';
import { cardImages } from '../../../Common/CardImages/CardImages';
import { toast } from 'react-toastify';
import { OrgnaziationContext } from '../../../../Home';
import Loader from 'react-loader-spinner';
import axios from 'axios';

export default function ListAvailableCards({ current_user, current_organization }) {
  const [cardsData, setCardsData] = useState([]);
  const [loading, setLoading] = useState(true)
  const [toggle, setToggle] = useState(true)
  let { organization_name } = useParams();
  const { setOrgName } = useContext(OrgnaziationContext)

  const [organizationName, setOrganizationName] = useState('')

  useEffect(() => {
    if (organization_name) {
      setOrganizationName(organization_name)
    } else {
      setOrganizationName(current_organization?.org_identifier)
    }
  }, [])

  useEffect(() => {
    if (!(current_organization?.org_identifier)) {
      setOrgName(organizationName)
    }
  }, [organizationName])

  useEffect(() => {
    if (organizationName) {
      axios.get(`/api/v1/organizations/${organizationName}/cards?page_type=client&controller_name=cards`).then(res => {
        setCardsData(res.data.cards);
        setLoading(false)
      })
        .catch(err => {
          setCardsData([])
          setLoading(false)
        })
    }
  }, [toggle, organizationName])

  const deleteCard = (each) => {
    setLoading(true)
    axios.delete(`/api/v1/organizations/${organizationName}/cards/${each.id}?page_type=client&controller_name=cards`).then(res => {
      toast.success(res.data.message)
      // console.log(res?.data?.org);
      setToggle(curr => !curr)
      setLoading(false)
    }).catch(err => {
      toast.error(err.response.data.message)
      setLoading(false)
    })
  }

  // Helper function to send card removal request
  // The function sets the status of card as boolean value true if use requests card removal
  async function setCardRemovalRequestHandler(each) {
    try {
      if (cardsData.length === 1) {
        toast.error("Primary card cannot be removed.\nPlease enter another card before removing it!");
        return;
      }
      const response = await axios.put(`/api/v1/organizations/${organizationName}/cards/${each.id}/set_card_removal_request?page_type=client&controller_name=cards`);
      const data = await response?.data;
      toast.success(data?.message);
      setTimeout(() => location.reload(), 650)
    }
    catch (error) {
      toast.error(error?.message);
    }
  }
  return (
    <div>
      <Container fluid>
        <Breadcrumb parent={"Cards"} title="Cards" />
        <div className='mb-5'>
          {((current_user?.id && current_user?.is_admin) ||
            (current_user?.accessible_modules?.includes("billing"))) &&
            <a href={`/organizations/${organizationName}/cards/new`}
              className='btn btn-primary float-end'>
              Add New Card
            </a>}
        </div>
        <br />
        {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> :
          <div className='d-flex flex-wrap'>
            {cardsData.length > 0 ?
              cardsData.map(each => {
                return (
                  <Card key={each.id} className='p-4 mx-3' style={{ width: 480 }}>
                    <CardHeader className='p-2 pb-4'>
                      <div className='d-flex justify-content-between'>
                        <div className='pe-3'>
                          <img src={cardImages[each.brand.toLowerCase()]}
                            style={{ height: 90 }} />
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <h4 className='mb-0 mt-3'>
                            **** **** **** {each.last4}
                          </h4>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody className='p-2 pb-4'>
                      <p className="mb-1">
                        <b>Added by: </b> {each.added_by}
                      </p>
                      <div className='d-flex justify-content-between'>
                        <p>
                          <b>Expiry date: </b> {each.expiry_date}
                        </p>
                        <p>
                          <b>Last Activity: </b> {each.last_activity}
                        </p>
                      </div>
                    </CardBody>
                    <CardFooter className="p-2 pt-4 pb-0">
                      {
                        current_user?.is_admin ? (
                          <button className='btn btn-danger float-end' onClick={() => deleteCard(each)}>
                            Delete
                          </button>
                        ) :
                          (
                            each.card_removal_request ?
                              <button className='btn btn-light disable float-end'>
                                Requested Removal
                              </button>
                              :
                              <button className='btn btn-primary float-end' onClick={() => setCardRemovalRequestHandler(each)}>
                                Remove Card
                              </button>
                          )
                      }
                    </CardFooter>
                  </Card>)
              }) : <Card>
                <CardBody>
                  <h6>No cards found</h6>
                </CardBody>
              </Card>}
          </div>}
      </Container>
    </div>
  )
}
