import React, {useState, useEffect} from 'react'
import { Card, CardHeader, CardBody, Col, Container, Row, Breadcrumb, BreadcrumbItem, Alert } from 'reactstrap'
import Loader from 'react-loader-spinner';
import axios from 'axios'
import { toast } from 'react-toastify';

export default function SchemaReports({site_name , apiEndpoint}) {
  const [missingFields, setMissingFields] = useState();
  const [extraFields, setExtraFields] = useState();
  const [missingFieldsCount, setMissingFieldsCount] = useState()
  const [extraFieldsCount, setExtraFieldsCount] = useState()
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState({min_date:"N/A", max_date:"N/A"})
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [rssCrawlPushId, setRssCrawlPushId] = useState();

  useEffect(() => {
    setLoading(true)
    axios.get(apiEndpoint).then(res => {
      setMissingFields(res?.data?.schema_reports?.missing_fields)
      setExtraFields(res?.data?.schema_reports?.extra_fields)
      setMissingFieldsCount(res?.data?.schema_reports?.no_of_missing_fields)
      setExtraFieldsCount(res?.data?.schema_reports?.no_of_extra_fields)
      setDateRange(res?.data?.input_data_date_range)
      setIsDataAvailable(res?.data?.is_data_available)
      setRssCrawlPushId(res?.data?.schema_reports?.rss_crawl_push_id)
      setLoading(false)
    }).catch(err => {
      toast.error(err?.response?.data?.message)
      setLoading(false)
    })
  }, [site_name, apiEndpoint])

  return (
    <>
      {
        loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
          :
          <React.Fragment>
            <Card className='my-3'>
              <CardHeader className='bg-primary py-3'>
                Schema Reports: (Rss Crawl Push Id: <strong>{rssCrawlPushId}</strong>)
              </CardHeader>
              { isDataAvailable ? 
              <CardBody className="custom-scrollbar" style={{height: "100px", overflow: "scroll", padding: "20px"}}>
                <Row>
                  <Col md='6' className='my-3'>
                    <h6 className='fw-bold px-3'>Missing Fields({missingFieldsCount}):</h6>
                    <p>{missingFields ? missingFields : "no data available"}</p>
                  </Col>
                  <Col md='6' className='my-3'>
                    <h6 className='fw-bold px-3'>Extra Fields({extraFieldsCount}):</h6>
                    <p>{extraFields ? extraFields : "no data available"}</p>
                  </Col>
                </Row>
              </CardBody>
                  : 
                  <CardBody>
                    <p>No data available!</p>
                  </CardBody>
              }
            </Card>
          </React.Fragment>
      }
    </>
  )
}

