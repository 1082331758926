import React, {useState, useEffect} from 'react'
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import axios from 'axios'

export default function AcceptInvite() {
    let history = useHistory()
    let { organization_name, invite_id } = useParams();
    const [loading, setLoading] = useState(true)

    const handleLogout = () => {
        axios.delete(`/api/v1/users/logout?page_type=client&controller_name=sessions`, {
            user: {
                email: localStorage.getItem("authEmail"),
                authenticity_token: localStorage.getItem("authToken")
            }
        })
        localStorage.removeItem('authEmail'); 
        localStorage.removeItem('authToken');
        localStorage.removeItem('user_name');
    }

    useEffect(() => {
        axios.get(`/api/v1/organizations/${organization_name}/user_invites/${invite_id}/accept_invite?page_type=client&controller_name=user_invites`).then(res => {
            if (localStorage.getItem('authToken') || localStorage.getItem('authEmail')){
                handleLogout()
            }
            toast.success(res.data.message)
            if (res.data?.redirect_to_sign_in_page){
                history.push(`/users/sign_in`)
            } else {
                history.push(`/users/password/edit?reset_password_token=${res.data.reset_password_token}`)
            }
        }).catch(err => {
            toast.error(err.response.data.message)
        })
    }, [])
    return (
        <div>
            
        </div>
    )
}
