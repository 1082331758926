import React, { useState, useEffect, Fragment } from 'react'
import { Card, CardBody, Container } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import Loader from 'react-loader-spinner';
import DatePicker from "react-datepicker";
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify';
import { Home } from 'react-feather';
import UploadsInfoCard from './Cards/UploadsInfoCard.component';

import { NewInternalBasePoint } from '../../../Common/BaseEsPoints/InternalEsBasePoint';


// If the user visits either of these pages then redirect then to uploads home page
const redirectPaths = [`${NewInternalBasePoint}/upload`, `${NewInternalBasePoint}/upload/new`];

if (redirectPaths.includes(window.location.pathname)) {
  window.location.pathname.replace('/internal/upload');
};

export default function UploadsHome() {
  // These are the parameters that are read from search bar
  const search = useLocation().search;
  const siteValue = new URLSearchParams(search)?.get('filter[site]')?.replace(/[^a-z0-9A-Z]+/g, ' ').trim().replace(/\s+/g, '_').toLowerCase();
  const fromValue = new URLSearchParams(search).get('filter[crawldate][from]');
  const toValue = new URLSearchParams(search).get('filter[crawldate][to]');
  const commitValue = new URLSearchParams(search).get('commit');

  // Various state for internal data management
  const [data, setData] = useState({}); // Data shown in the data table and internal cards
  const [dropdownOptions, setDropdownOptions] = useState({}); // Options shown in dropdown menu
  const [selectedSite, setSelectedSite] = useState(siteValue ? { label: siteValue, value: siteValue } : {}); // site selected by user
  const [loading, setLoading] = useState(false); // Loading animation
  const today = new Date() // Current date
  const [fromDate, setFromDate] = useState(fromValue ? new Date(fromValue) : new Date(today.getFullYear(), today.getMonth(), today.getDate() - 2))
  const [endDate, setEndDate] = useState(toValue ? new Date(toValue) : today)
  const [activeTab, setActiveTab] = useState("overallStatsTab"); // The tab which user clicks on

  // Use Effect for when the page loads, it gets the options from the Backend DB

  useEffect(async () => {
    const controller = new AbortController();
    // Fetch all site options available from the back-end DB
    await fetchSiteOptionFromDB(controller.signal);
    // Fetch site data if the URL params are present
    await FetchQUeueSIzeTableData(controller.signal);

    // cleanup for when component unmounts
    // This cancels all the state updates and API calls 
    // if component unmounts before payload is delivered
    return () => {
      controller.abort();
      console.log('Fetch request aborted!');
    }
  }, []);

  /*------------------------------------------------------------------------------------------------------------------------- */
  // Helper function to fetch optins data from back-end DB and store it in the dropdownOptions state
  // This functions loops through the data and converts it into desired format

  const SITE_OPTIONS_ENDPOINT = '/api/v1/internal/all_site_names';

  async function fetchSiteOptionFromDB(signal) {
    try {
      const response = await axios.get(SITE_OPTIONS_ENDPOINT, { signal: signal });
      const responseData = await response.data;
      const siteNames = responseData?.site_names;
      let siteNameOptions = [];
      for (var key in siteNames) {
        let group = {}, children = [];
        group['label'] = <div className="fw-bold col text-black">{key}</div>;
        siteNames[key].map(item => {
          children.push({ label: item, value: item })
        });
        group['options'] = children;
        siteNameOptions.push(group);
      }
      siteNameOptions.push({
        label: <div className="fw-bold text-black">All</div>,
        options: [{ label: 'All', value: 'all' }]
      });
      setDropdownOptions(siteNameOptions);
    }
    catch (error) {
      console.error(error?.message || 'Oops something went wrong')
    }
  };

  /*-------------------------------------------------------------------------------------------------------------------------------------------- */
  // Helper function to fetch data for table shown in Queue Size tab
  // This data is displayed in the Queue Size tab's data table

  const SITE_DATA_API_ENDPOINT = `/api/v2/internal/uploads?filter[site]=${selectedSite?.value}&filter[from_date]=${formatDateYYYYMMDD(fromDate)}&filter[to_date]=${formatDateYYYYMMDD(endDate)}`

  async function FetchQUeueSIzeTableData(signal) {
    // Do not make API request if Data is not yet fetched
    if (loading || data?.data) {
      console.log('returning 61');
      return
    }
    // Do not make API request if Commit value or dite value is absent
    if (commitValue !== 'Get Data' && siteValue?.label) {
      console.log('returning 65');
      return
    }
    // Make API request if neither of the above two is present
    if (selectedSite?.value !== undefined && selectedSite?.value.length > 0) {
      setLoading(true);
      try {
        const response = await axios.get(SITE_DATA_API_ENDPOINT, { signal: signal });
        const responseData = await response?.data?.data;
        setData(responseData);
        // console.log('API request for site is made!');
        setAvgtime_taken(responseData?.site_data);

        let tempSitesData = [...responseData?.site_data]
        tempSitesData.push({
          site: '', date: <b>Total: </b>,
          drss_count: <b>{responseData?.total_drss_count}</b>,
          rss_count: <b>{responseData?.total_rss_count}</b>,
          feedcrawl_count: <b>{responseData?.total_feedcrawl_count}</b>,
          segment_extr_count: <b>{responseData?.total_segment_extr_count}</b>,
          segment_dedup_count: <b>{responseData?.total_segment_dedup_count}</b>,
          segment_normalize_count: <b>{responseData?.total_segment_normalize_count}</b>,
          segment_upload_count: <b>{responseData?.total_segment_upload_count}</b>,
          qa: '', kibana: ''
        });
        setLoading(false);
      }
      catch (error) {
        toast.error(error.response?.data?.message)
        setLoading(false);
      };
    }
  };
  /*----------------------------------------------------------------------------------------------------------------------------------------------- */
  // Helper function to redirect user to the uploads information page
  // This functions sets a different URL from where the states are updated dynamically and fetched the data for populating the data table
  function onGetDataButtonClickHandler() {
    // Validation to ensure site name is not empty
    if (selectedSite?.value === undefined) {
      toast.error("Sitename is required!");
      return;
    }
    // Validation to ensure date field is not empty
    if (fromDate === undefined) {
      toast.error("From date is required and cannot be empty!");
      return;
    }
    //Validation to ensure the date field is not empty 
    if (endDate === undefined) {
      toast.error("To date is required and cannot be empty!")
      return;
    }
    // The date difference should not be more than 10 days
    if(datediff(fromDate, endDate) >=10){
      toast.error("The date difference should not be more than 10 days!");
      return;
    }
    // Re-write the URL 
    window.location.href = `${NewInternalBasePoint}/upload?utf8=✓&filter%5Bsite%5D=${selectedSite?.value}&filter%5Bcrawldate%5D%5Bfrom%5D=${formatDateYYYYMMDD(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${formatDateYYYYMMDD(endDate)}&commit=Get Data`
  }
  /*----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

  /*--------------------------------------------------------------------------------------------[MISCELLANEOUS FUNCTIONS]-------------------------------------------------------------------------------------------------*/
  // Helper function to format date to YYYY/MM/DD
  function formatDateYYYYMMDD(date) {
    let d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();
    if (month.length < 2) {

      month = '0' + month;
    }
    if (day.length < 2) {

      day = '0' + day;
    }
    return [year, month, day].join('/');
  }
  // Helper function to calculate date difference
  function datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }


  // Helper function to fetch sitename options from backend
  const promiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
        if (res.status == '200'){
          let siteNames = res?.data?.site_names, siteNamesOptions = []
          for (var key in siteNames) {
            let group = {}, children = []
            group['label'] = <div className="fw-bold col text-black">{key}</div>
              siteNames[key].map(item => {
                children.push({ label: item, value: item })
              })
            group['options'] = children;
            siteNamesOptions.push(group)
          }
          siteNamesOptions.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
          setDropdownOptions(siteNamesOptions)
          resolve(siteNamesOptions)
        }
      }).catch(err => {
        console.log(`err`, err)
      })
    })
  }
  /*-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/
  return (
    <>
      <Container fluid>
        {
          redirectPaths.includes(window.location.pathname) ? (
            <Container fluid>
              <div className="page-title">
                <Row className=''>
                  <Col xs="6">
                    <h3>Internal Dashboard</h3>
                  </Col>
                  <Col xs="6">
                    <Breadcrumb>
                      <BreadcrumbItem>
                        <a href={`/internal`}><Home /></a>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <b> Upload Page </b>
                      </BreadcrumbItem>
                    </Breadcrumb>
                  </Col>
                </Row>
              </div>
            </Container>
          )
            : <></>
        }
        {
          loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
            : (
              data?.site_data ? (
                <UploadsInfoCard
                  data={data}
                  siteName={siteValue || (selectedSite?.value || '')}
                  tab={activeTab}
                  selectTab={setActiveTab}
                  defaultOptions={dropdownOptions}
                  promiseOptions={promiseOptions}
                  fromDate={fromDate}
                  endDate={endDate}
                  setFromDate={setFromDate}
                  setEndDate={setEndDate}
                  selectedSite={selectedSite}
                  setSelectedSite={setSelectedSite}
                  ButtonClickHandler={onGetDataButtonClickHandler}
                />
              ) : (
                <Card>
                  <CardBody>
                    <Row className='my-3'>
                      <Col md={12}>
                        <h6 className='fw-bold'>Site Name</h6>
                        <AsyncSelect
                          cacheOptions
                          defaultOptions={dropdownOptions}
                          loadOptions={promiseOptions}
                          name='site_name'
                          value={selectedSite}
                          onChange={res => setSelectedSite(res)}
                          placeholder="Select a site name" />
                        <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
                      </Col>
                    </Row>
                    <Row className='align-items-end my-3'>
                      <Col md={5}>
                        <h6 className='fw-bold'>From date</h6>
                        <DatePicker
                          selected={fromDate}
                          onChange={(date) => setFromDate(date)}
                          maxDate={endDate}
                          className='form-control'
                          dateFormat={"YYY/MM/dd"} />
                      </Col>
                      <Col md={5}>
                        <h6 className='fw-bold'>To date</h6>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          maxDate={new Date()}
                          className='form-control'
                          minDate={fromDate}
                          dateFormat={"YYY/MM/dd"} />
                      </Col>
                      <Col md={2} className='d-flex justify-content-center mt-3'>
                        <button className='btn btn-success my-auto w-100' onClick={onGetDataButtonClickHandler}>Get Data</button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )

            )
        }
      </Container>
    </>
  )
}
