import React from 'react';
import DataTable from 'react-data-table-component';

const columns = [
  {
    name: 'Site Name',
    selector: 'site',
    sortable: true
  },
  {
    name: 'Crawl Date',
    selector: 'date',
    sortable: true
  },
  {
    name: 'Rss Count',
    selector: 'rss_count',
    sortable: true
  }
];
export default function RssCountsTable({ data, loading }) {
  return (
    <div>
      <DataTable
        data={data}
        columns={columns}
        progressPending={loading}
        noHeader
        responsive
        pagination />
    </div>
  )
}
