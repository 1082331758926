import React, { useEffect, useState } from "react";
import { Row, Col} from "reactstrap";
import DataTable from "react-data-table-component";
import { DownloadCloud } from "react-feather";

export default function SuggestionsWarnings({data, weekNumber, downloadJSONFile}) {

  const [tableOneData, setTableOneData] = useState(data["cpm_greater_than_50_proxies"]);
  const [tableTwoData, setTableTwoData] = useState(data["not_used_credits_greater_than_100"]);
  const [tableThreeData, setTableThreeData] = useState(data["remove_proxies"]);
  const [tableFourData, setTableFourData] = useState(data["increase_threads"]);

  const [tableOneSearchText, setTableOneSearchText] = useState('');
  const [tableThreeSearchText, setTableThreeSearchText] = useState('');
  const [tableFourSearchText, setTableFourSearchText] = useState('');

  const [tableOneSearchResults, setTableOneSearchResults]= useState(data["cpm_greater_than_50_proxies"]);
  const [tableThreeSearchResults, setTableThreeSearchResults]= useState(data["remove_proxies"]);
  const [tableFourSearchResults, setTableFourSearchResults]= useState(data["increase_threads"]);

  const [loading, setLoading] = useState(false);

  const tableOneColumns = [
    { name: 'Proxy Source', selector: 'proxy_source', sortable: true },
    { name: 'CPM($)', selector: 'cpm', sortable: true,
      cell: (row, index) => (<p>{row?.cpm? row.cpm : "NA or Infinity"}</p>)
    },
  ]

  const tableTwoColumns = [
    { name: 'Proxy Source', selector: 'proxy_source', sortable: true },
    { name: 'Unused Credits', selector: 'unused_credits', sortable: true },
  ]

  const tableFourColumns = [
    { name: 'Proxy Source', selector: 'proxy_source', sortable: true },
    { name: 'Failed Percentage', selector: 'failed_percentage', sortable: true },
    { name: 'Retries Percentage', selector: 'retries_percentage', sortable: true },
    { name: 'Success Percentage', selector: 'success_percentage', sortable: true },
  ]

  const handleTableOneSearch = event =>{
    if(event.target.value == ''){
      setTableOneSearchResults(tableOneData);
    }
    else{
      setTableOneSearchResults(tableOneSearchFromData(event.target.value));
    }
    setTableOneSearchText(event.target.value);
  }

  function tableOneSearchFromData(value){
    var search_result = tableOneData.filter(item => item.proxy_source.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableOneData.filter(item => item.cpm.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
  }

  const handleTableTwoSearch = event =>{
    if(event.target.value == ''){
      setTableTwoSearchResults(tableTwoData);
    }
    else{
      setTableTwoSearchResults(tableTwoSearchFromData(event.target.value));
    }
    setTableTwoSearchText(event.target.value);
  }

  function tableTwoSearchFromData(value){
    var search_result = tableTwoData.filter(item => item.oxylabs_proxies.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableTwoData.filter(item => item.scraper_api.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableTwoData.filter(item => item.geonode_proxies.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableTwoData.filter(item => item.scrapingdog_proxies.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
  }

  const handleTableFourSearch = event =>{
    if(event.target.value == ''){
      setTableFourSearchResults(tableFourData);
    }
    else{
      setTableFourSearchResults(tableFourSearchFromData(event.target.value));
    }
    setTableFourSearchText(event.target.value);
  }

  function tableFourSearchFromData(value){
    var search_result = tableFourData.filter(item => item.proxy_source.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableFourData.filter(item => item.failed_percentage.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableFourData.filter(item => item.retries_percentage.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableFourData.filter(item => item.success_percentage.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
  }

  return (
    <>
      <center>
        <h5 className="mb-2">Suggestions warnings</h5>
      </center>
      {
         (data?.cpm_greater_than_50_proxies && data?.cpm_greater_than_50_proxies.length > 0)&& (
          <div className="position-absolute top-0 end-0">
            <button 
            className="btn btn-primary btn-sm mw-25 mh-25 float-right mx-3 my-3 d-inline"
            onClick={()=>downloadJSONFile(weekNumber, 'suggestions_warnings', 'json')}>
            <span>Download JSON  <DownloadCloud style={{verticalAlign:"middle", width:"20px", height:"20px"}}/></span>
            </button>
          </div>
        )
      }
      <Row className="align-items-end mt-2">
        <Col sm="6">
          <center>
            <h5 className="mb-2">CPM greater than 50 proxies</h5>
          </center>
          <div className="float-end mb-3">
            <Row className="align-items-center">
              <Col sm="3">Search</Col>
              <Col>
                <input type="text" className="form-control" value={tableOneSearchText} onChange={handleTableOneSearch} />
              </Col>
            </Row>
          </div>
          <DataTable
            columns={tableOneColumns}
            data={tableOneSearchResults}
            noHeader
            pagination
          />
        </Col>
        <Col sm="6">
          <center>
            <h5 className="mb-2">Increase threads</h5>
          </center>
          <div className="float-end mb-3">
            <Row className="align-items-center">
              <Col sm="3">Search</Col>
              <Col>
                <input type="text" className="form-control" value={tableFourSearchText} onChange={handleTableFourSearch} />
              </Col>
            </Row>
          </div>
          <DataTable
            columns={tableFourColumns}
            data={tableFourSearchResults}
            noHeader
            pagination
          />
        </Col>
      </Row>
      <Row className="align-items-end mt-2">
        <Col sm="6">
          <center>
            <h5 className="mb-2">Not used credits greater than 100</h5>
          </center>
          <DataTable
            columns={tableTwoColumns}
            data={tableTwoData}
            noHeader
            pagination
          />
        </Col>
        <Col sm="6">
          <center>
            <h5 className="mb-2">Remove proxies(To do)</h5>
          </center>
        </Col>
      </Row>
    </>
  )
}
