import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, FormGroup, Input } from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const SetCrawlNotifications = forwardRef(({ orgName, sitegroupNames }, ref) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const toggle = () => {
    setModal(curr => !curr);
  };

  useImperativeHandle(ref, () => ({
    handleRowChange(selectedRows) {
      setSelectedRows(selectedRows);
    }
  }));

  const initialValues = {
    siteOption: 'all',
    start: false,
    completion: false
  };

  const validationSchema = Yup.object({
    siteOption: Yup.string().required('Site option is required'),
    start: Yup.boolean(),
    completion: Yup.boolean()
  });

  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    let selectedSiteIDs = [];
    selectedRows.forEach(each => {
      selectedSiteIDs.push(each.id);
    });

    const payload = {
      website_notification_preference: {
        selected_sites: selectedSiteIDs.toString(),
        request_from: 'site_list',
        site_options: values.siteOption,
        crawl_start_notification_preference: values.start ? 'notify_crawl_start' : 'dont_notify_crawl_start',
        crawl_end_notification_preference: values.completion ? 'notify_crawl_end' : 'dont_notify_crawl_end'
      },
      page_type: "client",
      controller_name: "website_notification_preferences"
    };

    axios.post(`/api/v1/organizations/${orgName}/website_notification_preferences`, payload)
      .then(res => {
        toast.success(res.data.message);
        setModal(false);
        setLoading(false);
        window.location.reload();
        setSubmitting(false);
      })
      .catch(err => {
        toast.error(err.response.data.message);
        setLoading(false);
        setSubmitting(false);
      });
  };

  const closeBtn = <button className="close" onClick={() => setModal(false)}> &times; </button>;

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop='static' size='lg'>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Set Crawl Notifications
        </ModalHeader>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <ModalBody className='p-5'>
                <div>
                  <div className='my-3 d-flex align-center'>
                    <h6 className='me-2 fw-bold'>
                      Sites:
                    </h6>
                    <Field as="select" name="siteOption" className='form-control show-arrow'>
                      <option value="selected_sites" disabled={selectedRows.length === 0}>
                        Selected sites
                      </option>
                      <option value='all'>All options</option>
                      {sitegroupNames.map(each => (
                        <option key={each[0]} value={each[1]}>
                          Active sites of {each[1]}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className='my-5'>
                    <FormGroup check className='my-3'>
                      <Field type="checkbox" name="start" className="form-check-input" />
                      {' '}
                      <h6>Crawl Start Notification</h6>
                    </FormGroup>
                    <FormGroup check className='my-3'>
                      <Field type="checkbox" name="completion" className="form-check-input" />
                      {' '}
                      <h6>Crawl Completion Notification</h6>
                    </FormGroup>
                  </div>
                  <a href={`/organizations/${orgName}/sites/crawl_notification_preferences`} className='text-primary mt-4 mb-2'>
                    Edit Crawl Notification Preferences
                  </a>
                </div>
              </ModalBody>
              <ModalFooter>
                {loading ? (
                  <Button className='primary mx-2' disabled>
                    Loading ...
                  </Button>
                ) : (
                  <Button color="primary" className='mx-2' type="submit" disabled={isSubmitting}>
                    Save Preference
                  </Button>
                )}
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
      <Button className='mx-2' color="primary" onClick={toggle}> Set Crawl Notifications </Button>
    </>
  );
});

export default SetCrawlNotifications;
