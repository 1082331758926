import React, {useState, useEffect} from 'react'
import { Row, Col, Alert } from 'reactstrap'
import Loader from 'react-loader-spinner';
import axios from 'axios'
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { useParams } from "react-router-dom";
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';

export default function DataFileComparison({downloadDoc, activeTabName}) {
  let { sitename } = useParams();
  const [oldData, setOldData] = useState({});
  const [newData, setNewData] = useState({});
  const [loadingOldFile, setLoadingOldFile] = useState(true);
  const [loadingNewFile, setLoadingNewFile] = useState(true);

  useEffect(() => {
    if (activeTabName == "file_comparison"){
      setLoadingNewFile(true)
      axios.get(`/api/v1/internal/qa/segment_level_qa_data_file?site_name=${sitename}&data_fetch_type=file_comparison`).then(res => {
        setNewData(res?.data?.data_file)
        setLoadingNewFile(false)
      }).catch(err => {
        console.log("err", err);
        toast.error(err.response?.data?.message)
        setLoadingNewFile(false)
      })

      setLoadingOldFile(true)
      axios.get(`/api/v1/internal/qa/file_before_runforce?site_name=${sitename}`).then(res => {
        setOldData(res?.data?.data_file)
        setLoadingOldFile(false)
      }).catch(err => {
        console.log("err", err);
        toast.error(err.response?.data?.message)
        setLoadingOldFile(false)
      })
    }
  }, [activeTabName])

  const handleDataDownload = () => {
    setLoadingNewFile(true)
    axios.get(`/api/v1/internal/qa/${sitename}/download_data_file`).then(res => {
      if (newData.file_extension == "json"){
        downloadDoc(JSON.stringify(res.data), newData.file_name, newData.content_type)
      }
      else{
        downloadDoc(res.data, newData.file_name, newData.content_type)
      }
      setLoadingNewFile(false)
    }).catch(err => {
      console.log("err", err);
      toast.error(err.response?.data?.message)
      setLoadingNewFile(false)
    })
  } 

  const handleOldDataDownload = () => {
    setLoadingOldFile(true)
    axios.get(`/api/v1/internal/qa/${sitename}/download_file_before_runforce`).then(res => {
      downloadDoc(JSON.stringify(res.data), `download_${sitename}.json`, "text/json")
      setLoadingOldFile(false)
    }).catch(err => {
      console.log("err", err);
      toast.error(err.response?.data?.message)
      setLoadingOldFile(false)
    })
  } 

  const newStyles = {
    diffContainer: {
      display: "block",
      overflow: "auto",
      wordWrap: "anywhere"
    },
    titleBlock: {
      textAlign: "center",
      fontSize: "18px",
      fontWeight: "500"
    },
    content: {
      width: "50%",
      position: "relative"
    }
  }

  return ( 
  <Row className="align-items-end mt-2 file-comparison-view">
    {
      loadingOldFile || loadingNewFile ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
        :
        <>
          {
            oldData?.file_present == true && newData?.file_present == true ?
            <>
              {
                oldData?.has_error == false  && newData?.has_error == false ?
                <>
                  <Col xs="6">
                    <p className='fw-bold m-0' style={{float: 'right'}}>
                      Last updated: {oldData?.last_updated_at}
                    </p> <br />
                    <Alert color='primary mt-2 mb-2'>
                      <h6 className="hidden"> <button className='btn text-white fw-bold' onClick={handleOldDataDownload}> Click here </button> to download the actual file</h6>
                      <h6> <a href={oldData?.download_link} target="_blank" rel="noopener noreferrer" className='btn text-white fw-bold'> Click here </a>to download the actual file</h6>
                      <h6>Showing first 200 records.</h6>
                    </Alert>
                  </Col>
                  <Col xs="6">
                    <p className='fw-bold m-0' style={{float: 'right'}}>
                      Last updated: {newData?.last_updated_at}
                    </p> <br />
                    <Alert color='primary mt-2 mb-2'>
                      <h6 className="hidden"> <button className='btn text-white fw-bold' onClick={handleDataDownload}> Click here </button> to download the actual file</h6>
                      <h6> <a href={newData?.download_link} target="_blank" rel="noopener noreferrer" className='btn text-white fw-bold'> Click here </a>to download the actual file</h6>
                      <h6>Showing first 200 records.</h6>
                    </Alert>
                  </Col>
                  <Col xs="12">
                  <ReactDiffViewer
                    oldValue={JSON.stringify(oldData?.file_content)}
                    newValue={JSON.stringify(newData?.file_content)}
                    compareMethod={DiffMethod.SENTENCES}
                    leftTitle={"Old File"}
                    rightTitle={"New File"}
                    showDiffOnly={true}
                    splitView={true}
                    styles={newStyles}
                  />
                  </Col>
              </>
                  :
                  <>
                    <Col xs="6">
                      <Alert color='light'><h5>no file content present</h5>
                        <div dangerouslySetInnerHTML={{__html: oldData?.error_message}} />
                      </Alert>
                    </Col>
                    <Col xs="6">
                      <Alert color='light'>
                        <div dangerouslySetInnerHTML={{__html: newData?.error_message}} />
                      </Alert>
                    </Col>
                  </>
              }
            </>
              :
              <>
                <Col xs="6">
                  <Alert color='light'>
                    {oldData?.file_present == true ?
                    <>
                      <p> The file is present in the respective path. The file comparison can be shown only if both files are present!</p>
                    </>
                        :
                        <>
                          <div dangerouslySetInnerHTML={{__html: oldData?.error_message}} />
                        </>
                    }
                  </Alert>
                </Col>
                <Col xs="6">
                  <Alert color='light'>
                    {newData?.file_present == true ?
                    <>
                      <p>The file is present in the respective path. The file comparison can be shown only if both files are present!</p>
                    </>
                        :
                        <>
                          <div dangerouslySetInnerHTML={{__html: newData?.error_message}} />
                        </>
                    }
                  </Alert>
                </Col>
              </>
          }
        </>
    }
  </Row>
  )
}
