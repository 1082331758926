import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Card, CardBody } from "reactstrap";
import DatePicker from "react-datepicker";
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import axios from 'axios';
import moment from "moment";
import { toast } from 'react-toastify';

import { NewInternalBasePoint } from '../../../../Common/BaseEsPoints/InternalEsBasePoint';

const DROPDOWN_LIST_OPTIONS_API_ENDPOINT = '/api/v1/internal/dropdown_list_helper';
const SEARCH_SITE_NAME_API_ENDPOINT = '/api/v1/internal/search_site_names?site_name=';


const crawlStatusOptions = [
  { label: 'started', value: 'started' },
  { label: 'done', value: 'done' },
  { label: 'failed', value: 'failed' }
];

const dedupStatusOptions = [
  { label: 'started', value: 'started' },
  { label: 'pending', value: 'pending' },
  { label: 'done', value: 'done' },
  { label: 'failed', value: 'failed' },
];

const uploadStatusOptions = [
  { label: 'started', value: 'started' },
  { label: 'pending', value: 'pending' },
  { label: 'done', value: 'done' },
  { label: 'failed', value: 'failed' },
  { label: 'dropped', value: 'dropped'}
];

const normalizationStatusOptions = [
  { label: 'started', value: 'started' },
  { label: 'pending', value: 'pending' },
  { label: 'done', value: 'done' },
  { label: 'failed', value: 'failed' },
];


export default function SearchCard(props) {

  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const host_name = new URLSearchParams(search).get("filter[hostname]");
  const dedup_status = new URLSearchParams(search).get("filter[dedupstatus]");
  const upload_status = new URLSearchParams(search).get("filter[uploadstatus]");
  const normalization_status = new URLSearchParams(search).get("filter[normalizationstatus]");
  const crawl_status = new URLSearchParams(search).get("filter[crawlstatus]");
  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[site]"),
    hostName: host_name ? host_name : "all",
    dedupStatus: dedup_status ? dedup_status : "any",
    uploadStatus: upload_status ? upload_status : "any",
    normalizationStatus: normalization_status ? normalization_status : "any",
    feedcrawlGeoCluster: new URLSearchParams(search).get("filter[geo_cluster]") || 'all',
    crawlStatus: crawl_status ? crawl_status : "any",
    testRunStatus: new URLSearchParams(search).get("filter[test_run_status]") || 'false',
    fromDate: new URLSearchParams(search).get("filter[crawldate][from]"),
    toDate: new URLSearchParams(search).get("filter[crawldate][to]"),
  };

  const [UrlParams, setUrlParams] = useState({
    siteName: '',
    hostName: 'all',
    dedupStatus: 'any',
    uploadStatus: 'any',
    normalizationStatus: 'any',
    feedcrawlGeoCluster: 'all',
    crawlStatus: 'any',
    testRunStatus: 'false',
    fromDate: new Date(moment().subtract(2, 'days')),
    toDate: now
  });

  const now = new Date();
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(2, 'days')));
  const [toDate, setToDate] = useState(now);

  const [siteName, setSiteName] = useState({ label: '', value: '' });
  const [hostName, setHostName] = useState({ label: 'All', value: 'all' });
  const [dedupStatus, setDedupStatus] = useState({ label: 'Any', value: 'any' });
  const [uploadStatus, setUploadStatus] = useState({ label: 'Any', value: 'any' });
  const [normalizationStatus, setNormalizationStatus] = useState({ label: 'Any', value: 'any' });
  const [feedcrawlGeoCluster, setFeedcrawlGeoCluster] = useState({ label: 'All', value: 'all' });
  const [crawlStatus, setCrawlStatus] = useState({ label: 'Any', value: 'any' });
  const [testRunStatus, setTestRunStatus] = useState('false');
  const [siteNameOptions, setSiteNameOptions] = useState([]);
  const [hostNameOptions, setHostNameOptions] = useState([]);
  const [feedcrawlGeoClusterOptions, setFeedcrawlGeoClusterOptions] = useState([]);
  

  // useEffect for when card is loaded
  useEffect(async () => {
    const controller = new AbortController();

    await setSearchParamsData();
    await fetchDropdownOptionsFromDB(controller.signal);

    return () => {
      controller.abort();
    }
  }, []);

  // Helper function to fetch dropdown options from backend
  async function fetchDropdownOptionsFromDB(signal) {
    try {
      const res = await axios.get(DROPDOWN_LIST_OPTIONS_API_ENDPOINT, { signal: signal });
      const siteNames = res?.data?.site_names;
      await optionsDataFormatter(siteNames, res);
    }
    catch (error) {
      toast.error(error?.message || 'Oops something went wrong');
    }
  };

  // Helper function to format options data
  async function optionsDataFormatter(sitesData, res) {

    let siteNameOptions = [];

    for (let key in sitesData) {
      let group = {};
      let children = [];

      group['label'] = <div className='fw-bold col text-black'>{key}</div>

      sitesData[key].map((item) => {
        children.push({ label: item, value: item });
      });
      group['options'] = children;
      siteNameOptions.push(group);
    }
    siteNameOptions.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] });
    setSiteNameOptions(siteNameOptions);
    let geo_clusters = []
    res.data.geo_clusters.forEach(item => {
      geo_clusters.push({ label: item, value: item })
    })
    geo_clusters.push({ label: 'All', value: 'all' })
    setFeedcrawlGeoClusterOptions(geo_clusters);
    let machine_names = []
    res.data.machine_names.forEach(item => {
      machine_names.push({ label: item, value: item })
    })
    machine_names.push({ label: 'All', value: 'all' })
    setHostNameOptions(machine_names);

  };

  // Helper function to fetch name of site based on site name entered by user in async-select
  async function onAsyncSelectInputChangeHandler(inputValue) {
    if (inputValue.length < 3) {
      return null
    } else {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200') {
            // console.log(res.data)
            let siteNames = res.data.site_names, siteNamesOptions = []
            for (var key in siteNames) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
              siteNames[key].map(item => {
                children.push({ label: item, value: item })
              })
              group['options'] = children;
              siteNamesOptions.push(group)
            }
            siteNamesOptions.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setSiteNameOptions(siteNamesOptions)
            resolve(siteNamesOptions)
          }
        }).catch(err => {
          console.log(`err`, err)
        })
      })
    }
  };

  // Helper function for get data button click
  function onGetDataButtonClickHandler() {
    let from_date = moment(fromDate).format('YYYY/MM/DD') == "Invalid date" ? new Date(moment().subtract(2, 'days')).format('YYYY/MM/DD') : moment(fromDate).format('YYYY/MM/DD')
    let to_date = moment(toDate).format('YYYY/MM/DD') == "Invalid date" ? moment().format('YYYY/MM/DD') : moment(toDate).format('YYYY/MM/DD')
    window.location.href = `${NewInternalBasePoint}/segment?filter[site]=${siteName.value}&filter[geo_cluster]=${feedcrawlGeoCluster.value}&filter[hostname]=${hostName.value}&filter[crawlstatus]=${crawlStatus.value}&filter[dedupstatus]=${dedupStatus.value}&filter[uploadstatus]=${uploadStatus.value}&filter[normalizationstatus]=${normalizationStatus.value}&filter[test_run_status]=${testRunStatus}&filter[crawldate][from]=${from_date}&filter[crawldate][to]=${to_date}&commit=${'Get Data'}`;
  };

  // Helper function to set the url 
  async function setSearchParamsData() {
    
    if (searchParams.siteName && searchParams.siteName != siteName.value) {
      setSiteName({ label: searchParams.siteName, value: searchParams.siteName });
    }
    if (searchParams.feedcrawlGeoCluster && searchParams.feedcrawlGeoCluster != feedcrawlGeoCluster.value) {
      setFeedcrawlGeoCluster({ label: searchParams.feedcrawlGeoCluster  == 'all' ? 'All' :  searchParams.feedcrawlGeoCluster, value: searchParams.feedcrawlGeoCluster });
    }
    if (searchParams.hostName && searchParams.hostName != hostName.value) {
      setHostName({ label: searchParams.hostName, value: searchParams.hostName });
    }
    if (searchParams.dedupStatus && searchParams.dedupStatus != dedupStatus.value) {
      setDedupStatus({ label: searchParams.dedupStatus, value: searchParams.dedupStatus });
    }
    if (searchParams.uploadStatus && searchParams.uploadStatus != uploadStatus.value) {
      setUploadStatus({ label: searchParams.uploadStatus, value: searchParams.uploadStatus });
    }
    if (searchParams.normalizationStatus && searchParams.normalizationStatus != normalizationStatus.value) {
      setNormalizationStatus({ label: searchParams.normalizationStatus, value: searchParams.normalizationStatus });
    }
    if (searchParams.crawlStatus && searchParams.crawlStatus != crawlStatus.value) {
      setCrawlStatus({ label: searchParams.crawlStatus, value: searchParams.crawlStatus });
    }
    if (searchParams.testRunStatus && searchParams.testRunStatus != testRunStatus) {
      setTestRunStatus(searchParams.testRunStatus);
    }
    if (searchParams.fromDate && searchParams.fromDate != fromDate) {
      let from_date = moment(searchParams.fromDate).format('YYYY/MM/DD') == "Invalid date" ? moment(fromDate).format('YYYY/MM/DD') : moment(searchParams.fromDate).format('YYYY/MM/DD')
      setFromDate(new Date(from_date));
    }
    if (searchParams.toDate && searchParams.toDate != toDate) {
      let to_date = moment(searchParams.toDate).format('YYYY/MM/DD') == "Invalid date" ? moment(toDate).format('YYYY/MM/DD') : moment(searchParams.toDate).format('YYYY/MM/DD')
      setToDate(new Date(to_date));
    }
    if (commit == 'Get Data' || searchParams.siteName && searchParams.fromDate && searchParams.toDate) {
      searchParams.fromDate = moment(searchParams.fromDate).format('YYYY/MM/DD') == "Invalid date" ? moment(fromDate).format('YYYY/MM/DD') : moment(searchParams.fromDate).format('YYYY/MM/DD')
      searchParams.toDate = moment(searchParams.toDate).format('YYYY/MM/DD') == "Invalid date" ? moment(toDate).format('YYYY/MM/DD') : moment(searchParams.toDate).format('YYYY/MM/DD')
      setUrlParams(searchParams)
      props?.fetchData(searchParams);
    }
  };

  return (
    <div>
      <Card>
        <CardBody>
          <Row className="mb-2">
            <div className="col-sm-6">
              <label className="fw-bold">Site Name</label>
              <AsyncSelect
                cacheOptions
                defaultOptions={siteNameOptions}
                name='site_name'
                value={siteName}
                loadOptions={onAsyncSelectInputChangeHandler}
                onChange={site => setSiteName(site)}
                placeholder="Select a site name"
              />
              <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
            </div>
            <Col sm="3">
              <label className="fw-bold">Feedcrawl Geo Cluster</label>
              <Select
                value={feedcrawlGeoCluster}
                options={feedcrawlGeoClusterOptions}
                onChange={option => setFeedcrawlGeoCluster(option)} />
            </Col>
            <Col sm="3">
              <label className="fw-bold">Crawl Status </label>
              <Select
                value={crawlStatus}
                options={crawlStatusOptions}
                onChange={option => setCrawlStatus(option)} />
            </Col>
          </Row>
          <Row className="align-items-end mb-2">
            <Col sm="3">
              <label className="fw-bold">Host Name</label>
              <Select
                value={hostName}
                options={hostNameOptions}
                onChange={option => setHostName(option)} />
            </Col>
            <Col sm="3">
              <label className="fw-bold">Dedup Status </label>
              <Select
                value={dedupStatus}
                options={dedupStatusOptions}
                onChange={option => setDedupStatus(option)} />
            </Col>
            <Col sm="3">
              <label className="fw-bold">Upload Status </label>
              <Select
                value={uploadStatus}
                options={uploadStatusOptions}
                onChange={option => setUploadStatus(option)} />
            </Col>
            <Col sm="3">
              <label className="fw-bold">Normalization Status </label>
              <Select
                value={normalizationStatus}
                options={normalizationStatusOptions}
                onChange={option => setNormalizationStatus(option)} />
            </Col>
          </Row>
          <Row className="align-items-end mb-2">
            <Col sm="3">
              <label className="fw-bold">From Date</label>
              <DatePicker
                className="form-control"
                selected={new Date(fromDate)}
                dateFormat={"YYY/MM/dd"}
                maxDate={new Date()}
                onChange={date => setFromDate(date)} />
            </Col>
            <Col sm="3">
              <label className="fw-bold">To Date</label>
              <DatePicker
                className="form-control"
                selected={new Date(toDate)}
                dateFormat={"YYY/MM/dd"}
                minDate={new Date(fromDate)}
                maxDate={new Date()}
                onChange={date => setToDate(date)} />
            </Col>
            <Col sm="3">
              <input checked={testRunStatus == "true" ? true : false} onChange={() => setTestRunStatus(testRunStatus == "true" ? "false" : "true")} type={'checkbox'} name='test_run_status' /> Test Run Status
            </Col>
            <Col sm="3">
              <button type='button' className="btn btn-success w-100" onClick={onGetDataButtonClickHandler}>Get Data</button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  )
}
