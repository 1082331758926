import axios from 'axios';
import React, {useState, useEffect} from 'react';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalHeader, Row, Col, ModalFooter, Button } from 'reactstrap';
import Loader from 'react-loader-spinner';
import { useParams } from "react-router-dom";
import JSONPretty from 'react-json-pretty';

export default function ResendWebhookJob({data, setToggle}) {
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  console.log("data", data);
  let { organization_name } = useParams();
  const [loadingBtns, setLoadingBtns] = useState(false)

  const toggleFunc = () => {
    setModal(curr => !curr)
  }

  const closeBtn = <button className="close" 
    onClick={() => setModal(false)}> &times; </button>;

  const resendWebhookJob = () => {
    setLoading(true)
    axios.post(`/api/v1/organizations/${organization_name}/resend_webhook_job`, {
      subscribe_hook: {
        site_name: data.site_name, notification_hash: JSON.stringify(data.notification_hash),
        parent_webhook_id: data.doc_id
      }
    }).then(res => {
      toast.success(res.data.message)
      setLoading(false)
      setModal(false)
      setToggle(curr => !curr)
    }).catch(err => {
      toast.error(err.response.data.message)
      setLoading(false)
    })
  }

  return (
    <>
      <Modal isOpen={modal} toggle={toggleFunc} backdrop='static' size='lg'>
        <ModalHeader toggle={toggleFunc} close={closeBtn}>
          Are you sure to resend the Webhook Job?
        </ModalHeader>
        <ModalBody className='px-5'> 
          <p>You have selected,</p>
          <p>URL: <strong>{data.url}</strong></p>
          <p>Notification ID: <strong>{data.notification_id}</strong></p>
          <p>Site Name: <strong>{data.site_name}</strong></p>
          <p>Event Name: <strong>{data.event_name}</strong></p>
          <p>Status & Code: <strong>{data.status_and_code}</strong></p>
        </ModalBody>
        <ModalFooter>
          <Button color='light' onClick={() => setModal(false)}>Cancel</Button>
          <Button color="success" disabled={loadingBtns} onClick={resendWebhookJob}>Yes, proceed</Button>
        </ModalFooter>
      </Modal>
      <button className='btn btn-xs btn-success mx-1' onClick={toggleFunc}>Resend</button>
    </>
  )
}
