import React, { useEffect, useState } from "react";
import { Card, CardBody, Container, Row, Breadcrumb, BreadcrumbItem, Col } from "reactstrap";
import DataTable from "react-data-table-component";
import axios from "axios";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import { Home } from "react-feather";

export default function SdfConfigServicesAndServerMappings() {
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false)
    const columns = [
        { name: 'Service Name', selector: 'config_key', sortable: true, center: true },
        { name: 'Host', selector: 'config_value', sortable: true, center: true },
        { name: 'Ip address', selector: 'ip_address', sortable: true, center: true },
        { name: 'Comments', selector: 'comments', sortable: true, center: true },
    ]

    useEffect(() => {
        setLoading(true);
        axios.get('/api/v1/internal/devops/sdf_config_services_and_server_mappings')
            .then(res => {
                setData(res.data.server_mappings_info);
                setSearchResults(res.data.server_mappings_info);
                setLoading(false)
            })
            .catch(err => {
                console.error(err?.message || 'Oops somethinf went wrong');
                setLoading(false)
            })
    }, []);

    const handleChange = event => {
        if (event.target.value == '') {
            setSearchResults(data);
        }
        else {
            setSearchResults(data.filter(item => item.config_key.includes(event.target.value) || item.config_value.includes(event.target.value)))
        }
        setSearchText(event.target.value)

    }

    return <Container fluid>
        <Container fluid>
            <div className="page-title">
                <Row className=''>
                    <Col xs="6">
                        <h3>Internal Dashboard</h3>
                    </Col>
                    <Col xs="6">
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <Link to={`/internal`}><Home /></Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem className="fw-bold">
                                SDF Config Services and Server Mappings
                            </BreadcrumbItem>

                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
        </Container>
        {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> : <Card>
            <CardBody>
                <center className="fw-bold" >SDF Config Services and Server Mappings</center>
                <Row className="align-items-center float-end my-3">
                    <div className="col-sm-3">Search</div>
                    <div className="col">
                        <input className="form-control" type={'text'} value={searchText} onChange={handleChange} />
                    </div>
                </Row>
                <DataTable
                    columns={columns}
                    data={searchResults}
                    pagination
                    noHeader
                    highlightOnHover />
            </CardBody>
        </Card>}
    </Container>
}