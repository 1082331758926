import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home, Info } from "react-feather";
import moment from "moment";
import axios from "axios";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";
import Select, { components } from "react-select";
import { toast } from 'react-toastify'

import { NewJpInternalBasePoint } from '../../../../Common/BaseEsPoints/InternalEsBasePoint';

export default function Index() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[jobspikr_site_name]"),
    fromDate: new URLSearchParams(search).get("filter[from_date]"),
    toDate: new URLSearchParams(search).get("filter[to_date]")
  }
  const [siteName, setSiteName] = useState({label: 'All', value: '*'});
  const [siteNameOptions, setSiteNameOptions] = useState([]);
  const [uploadStatsData, setUploadStatsData] = useState([]);
  const [dateArr, setDateArr] = useState([]);
  const now = new Date();
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(7, 'days')));
  const [toDate, setToDate] = useState(now);
  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults]= useState([]);

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v1/internal/jobspikr_drl_sites')
      .then(res => {
        let site_names = []
        res.data.site_names.forEach(item => {
          site_names.push({ label: item, value: item })
        })
        site_names.push({ label: 'All', value: '*' })
        setSiteNameOptions(site_names);
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong');
        toast.error(err?.message || 'Oops something went wrong');
      });
  }, []);

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.siteName && searchParams.siteName != siteName.value) {
      setSiteName({ label: searchParams.siteName == "*" ? "All" : searchParams.siteName, value: searchParams.siteName });
    }
    if (searchParams.fromDate && searchParams.fromDate != fromDate) {
      setFromDate(new Date(searchParams.fromDate));
    }
    if (searchParams.toDate && searchParams.toDate != toDate) {
      setToDate(new Date(searchParams.toDate));
    }
    if (commit == 'Get Data') {
      fetchDataWithParams(searchParams);
      buildTableColums();
    }
    else {
      fetchData();
    }
  }

  function fetchDataWithParams(filterParams) {
    setLoading(true)
    axios.get(`/api/v2/jobspikr_v3_internal/jobspikr_graphs/upload_stats_of_jp_sites?filter[jobspikr_site_name]=${filterParams.siteName}&filter[from_date]=${moment(filterParams.fromDate).format('YYYY/MM/DD')}&filter[to_date]=${moment(filterParams.toDate).format('YYYY/MM/DD')}`)
      .then(res => {
        setUploadStatsData(res.data?.upload_stats_data);
        setSearchResults(res.data?.upload_stats_data);
        setDateArr(res.data?.date_header_arr);
        setLoading(false)
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong');
        toast.error(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  function fetchData() {
    setLoading(true)
    axios.get(`/api/v2/jobspikr_v3_internal/jobspikr_graphs/upload_stats_of_jp_sites`)
      .then(res => {
        setUploadStatsData(res.data?.upload_stats_data);
        setSearchResults(res.data?.upload_stats_data);
        setDateArr(res.data?.date_header_arr);
        setLoading(false)
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong');
        toast.error(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  function buildTableColums() {
    const tableColumns = [
      {name: 'Site Name', selector: 'site_name', sortable: true, cell: (each) => <a href={`/internal/upload?filter%5Bsite%5D=${each?.site_name}`} target='_blank'>
        {each.site_name}
      </a>, grow: 3},
    ]
    dateArr.map(item => tableColumns.push({name: `${item}`, selector: `${item.replaceAll("/", "")}`, sortable: true}));
    return tableColumns;
  }

  const handleSearch = event =>{
    if(event.target.value == ''){
      setSearchResults(uploadStatsData);
    }
    else{
      setSearchResults(searchFromData(event.target.value));
    }
    setSearchText(event.target.value);
  }

  function searchFromData(value){
    var search_result = uploadStatsData.filter(item => item.site_name.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
  }

  const handleGetData = () => {
    window.location.href = `${NewJpInternalBasePoint}/jobspikr_graphs/upload_stats_of_jp_sites?filter[jobspikr_site_name]=${siteName.value}&filter[from_date]=${moment(fromDate).format('YYYY/MM/DD')}&filter[to_date]=${moment(toDate).format('YYYY/MM/DD')}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href={`${NewJpInternalBasePoint}/jobspikr_graphs/upload_stats_of_jp_sites`}>Upload stats of JP sites</a> : 'Upload stats of JP sites'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
          </BreadcrumbItem>}
        </Breadcrumb>
      </Col>
    </Row>
  </div>

  <Card>
    <CardBody>
      <Row className="align-items-end mt-2">
        <Col sm="4">
          <label className="fw-bold">Jobspikr Site</label>
          <Select
            value={siteName}
            options={siteNameOptions}
            onChange={option => setSiteName(option)} />
        </Col>
        <Col sm="3">
          <label className="fw-bold">From Date</label>
          <DatePicker
            className="form-control"
            selected={new Date(fromDate)}
            dateFormat={"YYY/MM/dd"}
            maxDate={new Date()}
            onChange={date => setFromDate(date)} />
        </Col>
        <Col sm="3">
          <label className="fw-bold">To Date</label>
          <DatePicker
            className="form-control"
            selected={new Date(toDate)}
            dateFormat={"YYY/MM/dd"}
            maxDate={new Date()}
            onChange={date => setToDate(date)} />
        </Col>
        <Col sm="2">
          <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
        </Col>
      </Row>
    </CardBody>
  </Card>

  <Card>
    <CardBody>
      <center><h6 className='fw-bold'>Upload Count</h6></center>
      <Row className="align-items-end mt-2 mb-3">
        <Col>
          <div className="float-end">
            <Row className="align-items-center">
              <Col sm="3">Search</Col>
              <Col>
                <input type="text" className="form-control" value={searchText} onChange={handleSearch} />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <DataTable
        columns={buildTableColums()}
        data={searchResults}
        noHeader
        pagination
        progressPending={loading}
      />
    </CardBody>
  </Card>
</Container>
}

