import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, ModalFooter, Button } from 'reactstrap'
import { Link, useLocation } from "react-router-dom";
import { Home, Info } from "react-feather";
import axios from "axios";
import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";
import { toast } from 'react-toastify'

export default function Top20SitesRssCount() {
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [top20SitesRssCountData, setTop20SitesRssCountData] = useState([]);
  const [loadingBtns, setLoadingBtns] = useState(false)

  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults]= useState([]);

  const toggleFunc = (e) => {
    e.preventDefault();
    GetTop20SitesRssCount();
    setModal(curr => !curr)
  }

  const closeBtn = <button className="close" 
    onClick={() => setModal(false)}> &times; </button>;

  const GetTop20SitesRssCount = () => {
    setLoading(true)
    axios.get(`/api/v1/internal/high_volume_clients_and_sites/top_20_sites_rss_count_data`)
      .then(res => {
        setTop20SitesRssCountData(res.data?.sites)
        setSearchResults(res.data?.sites);
        setLoading(false)
      }).catch(err => {
        console.error(err?.message || 'Oops something went wrong');
        // toast.error(err?.response.data.message || 'Oops something went wrong');
        setLoading(false)
      })
  }

  const handleSearch = event =>{
    if(event.target.value == ''){
      setSearchResults(top20SitesRssCountData);
    }
    else{
      setSearchResults(searchFromData(event.target.value));
    }
    setSearchText(event.target.value);
  }

  function searchFromData(value){
    var search_result = top20SitesRssCountData.filter(item => item.site_name.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = top20SitesRssCountData.filter(item => item.records_count.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
  }

  const tableColumns = [
    {name: 'Site', selector: 'site_name', sortable: true},
    {name: 'Records', selector: 'records_count', sortable: true},
  ]

  return (
    <>
      <Modal isOpen={modal} toggle={toggleFunc} backdrop='static' size='xl'>
        <ModalHeader toggle={toggleFunc} close={closeBtn}>
          <center>
            RSS (Yesterday data) 
          </center>
        </ModalHeader>
        <ModalBody className='px-5'>
          {
            loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> 
            : 
            top20SitesRssCountData.length > 0 ? 
            <>
              <Row className='my-2'>
                <Col lg={12}>
                  <div className="float-end mb-3">
                    <Row className="align-items-center">
                      <Col sm="3">Search</Col>
                      <Col>
                        <input type="text" className="form-control" value={searchText} onChange={handleSearch} />
                      </Col>
                    </Row>
                  </div>
                  <DataTable
                    columns={tableColumns}
                    data={searchResults}
                    noHeader
                    pagination
                    progressPending={loading}
                  />
                </Col>
              </Row>
            </>
            : ""
          }
        </ModalBody>
        <ModalFooter>
          <Button color='light' onClick={() => setModal(false)}> Close </Button>
        </ModalFooter>
      </Modal>
      <button className="btn btn-primary btn-sm me-1 px-xl-3" onClick={toggleFunc}>RSS</button>
    </>
    )
}

