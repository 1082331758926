import React, { useState } from "react";

import { Row, Col } from "reactstrap";
import DataTable from "react-data-table-component";
import { DownloadCloud } from "react-feather";



export default function ProxyWiseStats({ proxy_wise_stats_data, weekNumber, downloadCSVFile }) {


  const [tableData, setTableData] = useState(proxy_wise_stats_data);
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState(proxy_wise_stats_data);


  const tableColumns = [
    { name: 'Proxy Source', selector: 'proxy_source', sortable: true },
    { name: 'Success(#)', selector: 'success', sortable: true },
    { name: 'Failed(#)', selector: 'failed', sortable: true },
    { name: 'Per Page Size(KB)', selector: 'per_page_size', sortable: true,center:true, grow: 2 },
    { name: 'Retries(#)', selector: 'retries', sortable: true },
    { name: 'Total(#)', selector: 'total', sortable: true },
    { name: 'Success%', selector: 'success%', sortable: true },
    { name: 'Failed%', selector: 'failed%', sortable: true },
    { name: 'Retries%', selector: 'retries%', sortable: true },
    { name: 'Success Traffic(GB)', selector: 'success_traffic', sortable: true },
    { name: 'Failed Traffic(GB)', sortable: true, selector: 'failed_traffic'},
    { name: 'CPM($)', selector: 'cpm', sortable: true },
    { name: 'Total Cost($)', selector: 'total_cost', sortable: true },
    { name: 'Subscription Cost($)', selector: 'subscription_cost', sortable: true },
  ]

  const handleSearch = event => {
    if (event.target.value == '') {
      setSearchResults(tableData);
    }
    else {
      setSearchResults(searchFromData(event.target.value));
    }
    setSearchText(event.target.value);
  }

  function searchFromData(value) {
    var search_result = tableData.filter(item => item.proxy_source.includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
    var search_result = tableData.filter(item => item.success.includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
    var search_result = tableData.filter(item => item.failed.toString().includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
    var search_result = tableData.filter(item => item.retries.toString().includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
    var search_result = tableData.filter(item => item.total.toString().includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
    var search_result = tableData.filter(item => item.success_traffic.toString().includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
    var search_result = tableData.filter(item => item.failed_traffic.toString().includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
    var search_result = tableData.filter(item => item.cpm.toString().includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
    var search_result = tableData.filter(item => item.total_cost.toString().includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
    var search_result = tableData.filter(item => item.subscription_cost.toString().includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
  }


  // function downloadCSVFile(weekNumber, fileName){
  //   axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem("authToken")}`;
  //   axios.defaults.headers["Content-Type"] = `text/csv`;
  //   axios.defaults.headers["Accept"] = `text/csv`;
  //   axios({
  //     url: `http://localhost:7171/api/v1/internal/proxy_log_reports/download_data_file?weekNumber=${weekNumber}&fileName=${fileName}`,
  //     method: 'GET',
  //     responseType: 'blob'
  //   })
  //     .then((res) => {
  //       const url_to_download = window.URL.createObjectURL(new Blob([res.data]));
  //       const a_tag = document.createElement('a');
  //       a_tag.href = url_to_download;
  //       a_tag.setAttribute('download', `proxy_wise_stats.csv`);
  //       document.body.appendChild(a_tag);
  //       a_tag.click();
  //     });
  // }



  return (
    <>
      <center>
        <h5 className="mb-2">Proxy wise stats</h5>
      </center>
      {
        proxy_wise_stats_data.length > 0 && (
          <div className="position-absolute top-0 end-0">
            <button
              className="btn btn-primary btn-sm mw-25 mh-25 float-right mx-3 my-3 d-inline"
              onClick={() => downloadCSVFile(weekNumber, 'proxy_wise_stats')}>
              <span>Download CSV  <DownloadCloud style={{verticalAlign:"middle", width:"20px", height:"20px"}}/></span>
            </button>
          </div>
        )
      }
      <Row className="align-items-end mt-2">
        <Col>
          <div className="float-end mb-3">
            <Row className="align-items-center">
              <Col sm="3">Search</Col>
              <Col>
                <input type="text" className="form-control mx-1 my-3" value={searchText} onChange={handleSearch} />
              </Col>
            </Row>
          </div>
        </Col>
        <DataTable
          columns={tableColumns}
          data={searchResults}
          noHeader
          pagination
        />
      </Row>
    </>
  )
}
