import React from 'react';
import { useState, useEffect, useMemo } from 'react'
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import SpinnerLoader from '../../../Common/LoadingAnimation/SpinnerLoader.component';
import BillingDetailsForm from './BillingDetailsForm/BillingDetailsForm.component';
import axios from 'axios';
import countryList from 'react-select-country-list'
import { toast } from 'react-toastify';

export default function ClientBillingDetailsPage() {
  const [address, setAddress] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState({})
  let { organization_name, feas_id } = useParams();
  let history = useHistory();

  const options = useMemo(() => countryList().getData(), [])

  useEffect(() => {
    axios.get(`/api/v1/organizations/${organization_name}/feasibility_reports/${feas_id}/billing_address?page_type=client&controller_name=feasibility_reports`).then(res => {
      setAddress(res.data.address)
      setLoading(false)
    }).catch(err => {
      if(err?.response?.status === 401){
        toast.error("You are not authorized to access this page!");
        setLoading(false);
        history.push("/");
      }
      // For everything else show the responses
      else{
        toast.error(err?.message);
        setLoading(false);
      }
    })
  }, [])

  const handleBilling = (e) => {
    e.preventDefault()
    setError({})
    let tempErrs = {}
    if (address.organization_name.length < 1) {
      tempErrs = {
        ...tempErrs, organization_name: true
      }
    }
    if (address.line1.length < 1) {
      tempErrs = {
        ...tempErrs, line1: true
      }
    }
    if (address.city.length < 1) {
      tempErrs = {
        ...tempErrs, city: true
      }
    }
    if (address.state && address.state.length < 1) {
      tempErrs = {
        ...tempErrs, state: true
      }
    }
    if (address.country.length < 1) {
      tempErrs = {
        ...tempErrs, country: true
      }
    }
    setError(tempErrs)
    if (Object.values(tempErrs).length === 0) {
      axios.put(`/api/v1/organizations/${organization_name}/feasibility_reports/${feas_id}`, {
        organization: address.country == "IN"
        ? { name: address.organization_name, gstin_id: address.gstin_id }
        : { name: address.organization_name },
        address: {
          line1: address.line1, line2: address.line2, city: address.city,
          state: address.state, zip: address.zip
        },
        page_type: "client", controller_name: "feasibility_reports"
      }).then(res => {
        toast.success(res.data.message)
        if (address?.skip_invoice) {
          history.push('/')
        }
        else {
          history.push(`/organizations/${organization_name}/invoices/${res.data.invoice_id}`)
        }
      }).catch(err => {
        toast.error(err.response.data.message)
      })
    }
  }
  return (
    <>
      <div className='mt-2'>
        <SpinnerLoader loading={loading}>
          <BillingDetailsForm
            error={error}
            setError={setError}
            address={address}
            setAddress={setAddress}
            handleBilling={handleBilling} />
        </SpinnerLoader>
      </div>
    </>
  )
}
