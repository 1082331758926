import React, { useEffect, useState } from "react";
import { Container, Breadcrumb, BreadcrumbItem, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import { Home } from "react-feather";
import Select from "react-select";

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function SeleniumClusterDiagnostics() {
    const search = useLocation().search;
    const commit = new URLSearchParams(search).get("commit");
    const searchParams = {
        time: new URLSearchParams(search).get("filter[time]"),
        seleniumCluster: new URLSearchParams(search).get("filter[selenium_cluster]"),
        tabName: new URLSearchParams(search).get("filter[tab_name]")
    }

    const [seleniumCluster, setSeleniumCluster] = useState({ label: 'All', value: 'all' });
    const [seleniumOptions, setSeleniumOptions] = useState([]);
    const [time, setTime] = useState({ label: 'Last 4 hours', value: 'now-4h' });
    const [activeTab, setActiveTab] = useState('1');
    const [urlParams, setUrlParams] = useState({
        seleniumCluster: '*',
        time: 'now-4h'
    })
    const timeOptions = [
        { label: 'Today', value: 'now%2Fd' },
        { label: 'Last 30 minutes', value: 'now-30m' },
        { label: 'Last 1 hour', value: 'now-1h' },
        { label: 'Last 2 hours', value: 'now-2h' },
        { label: 'Last 4 hours', value: 'now-4h' },
        { label: "Last 10 hours", value: "now-10h" },
        { label: 'Last 12 hours', value: 'now-12h' },
        { label: 'Last 24 hours', value: 'now-24h' },
        { label: 'Last 2 days', value: 'now-2d' },
        { label: 'Last 3 days', value: 'now-3d' },
        { label: 'Last 4 days', value: 'now-4d' },
        { label: 'Last 7 days', value: 'now-7d' },
        { label: 'Last 15 days', value: 'now-15d' },
        { label: 'Last 30 days', value: 'now-30d' },
        { label: 'Last 60 days', value: 'now-60d' },
        { label: 'Last 1 year', value: 'now-1y' }
    ];

    useEffect(() => {
        setSearchParamsData();
        axios.get('/api/v1/internal/dropdown_list_helper')
            .then(res => {
                let result = res.data.geo_clusters.map(item => ({ label: item, value: item }));
                result.push({ label: 'All', value: 'all' })
                setSeleniumOptions(result);
            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong')
            });
    }, []);

    function setSearchParamsData() {
        if(searchParams.tabName && searchParams.tabName == 'segment_logs'){
            setActiveTab('3')
        }
        if(searchParams.tabName && searchParams.tabName == 'chrome_and_firefox_logs'){
            setActiveTab('2')
        }
        if(searchParams.tabName && searchParams.tabName == 'all_the_logs_from_selenium'){
            setActiveTab('1')
        }
        if(searchParams.tabName && searchParams.tabName == 'waiting_graphs'){
            setActiveTab('4')
        }
        if (searchParams.time && searchParams.time != time.value) {
            setTime(timeOptions.filter(item => searchParams.time == item.value)[0])
        }
        if (searchParams.seleniumCluster && searchParams.seleniumCluster != seleniumCluster.value) {
            setSeleniumCluster({ label: searchParams.seleniumCluster == 'all' ? 'All' : searchParams.seleniumCluster, value: searchParams.seleniumCluster })
        }
        if (commit == 'Get Data' && searchParams.time && searchParams.seleniumCluster) {
            setUrlParams(searchParams)
        }
    }

    const tabNames=[
        '',
        'all_the_logs_from_selenium',
        'chrome_and_firefox_logs',
        'segment_logs',
        'waiting_graphs'
    ]

    const handleGetData = () => {
        window.location.href = `/internal/selenium/selenium_cluster_diagnostics?filter[selenium_cluster]=${seleniumCluster.value}&filter[time]=${time.value}&filter[tab_name]=${tabNames[activeTab]}&commit=${'Get Data'}`
    }

    return <Container fluid>
        <div className="page-title">
            <Row className=''>
                <Col xs="6">
                    <h3>Internal Dashboard</h3>
                </Col>
                <Col xs="6">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={'/interanal'}>
                                <Home />
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            {commit == 'Get Data' ? <a href={'/internal/selenium/selenium_cluster_diagnostics'}>Selenium Cluster Diagnostics</a> : 'Selenium Cluster Diagnostics'}
                        </BreadcrumbItem>
                        {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                            Search Results
                        </BreadcrumbItem>}
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
        <Card>
            <CardBody>
                <Row className="align-items-end mt-3">
                    <Col sm="4">
                        <label className="fw-bold">Selenium Cluster</label>
                        <Select
                            value={seleniumCluster}
                            options={seleniumOptions}
                            onChange={option => setSeleniumCluster(option)} />
                    </Col>
                    <Col sm="4">
                        <label className="fw-bold">Choose Time</label>
                        <Select
                            value={time}
                            options={timeOptions}
                            onChange={option => setTime(option)} />
                    </Col>
                    <Col sm="4">
                        <button className="btn btn-success w-100" onClick={handleGetData} >Get Data</button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
        <Card>
            <CardBody>
                <div className="mb-2">
                    <b>NOTE:</b>If the graph is not shown after your form submission then you should do either maximize its size(the option is available on the right side of graph) or minimize the sidebar.
                </div>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={activeTab == '1' ? 'active text-wrap' : 'text-wrap'} onClick={() => setActiveTab('1')}>
                            All the logs from selenium
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={activeTab == '2' ? 'active text-wrap' : 'text-wrap'} onClick={() => setActiveTab('2')}>
                            All the logs w.r.t chrome and firefox
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={activeTab == '3' ? 'active text-wrap' : 'text-wrap'} onClick={() => setActiveTab('3')}>
                            Segmentation of logs from selenium_diskfetcher_v2 w.r.t sites
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={activeTab == '4' ? 'active text-wrap' : 'text-wrap'} onClick={() => setActiveTab('4')}>
                            Waiting graphs for selenium browsers across clusters
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} className="panel-body">
                    <TabPane tabId={'1'}>
                        <div className="fw-bold">
                            All the logs from selenium a. inner circle is split by file names b. outer circle is further segmentation within those files w.r.t method names
                        </div>
                        <center>
                            <a href={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=pie&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.time},mode:quick,to:${urlParams.time == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'*selenium*+and+selenium_cluster.raw+:${urlParams.seleniumCluster == 'all' ? '*' : urlParams.seleniumCluster}'),uiState:(),vis:(aggs:!((id:'1',params:(),schema:metric,type:count),(id:'2',params:(field:file_name.raw,order:desc,orderBy:'1',size:8),schema:segment,type:terms),(id:'3',params:(field:function_name.raw,order:desc,orderBy:'1',size:5),schema:segment,type:terms)),listeners:(),params:(addLegend:!t,addTooltip:!t,isDonut:!f,shareYAxis:!t),title:'New%20Visualization',type:pie))`} target="_blank" className="primary-link">Click here to see below graph in kibana</a>
                        </center>
                        <iframe src={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=pie&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.time},mode:quick,to:${urlParams.time == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'*selenium*+and+selenium_cluster.raw+:${urlParams.seleniumCluster == 'all' ? '*' : urlParams.seleniumCluster}'),uiState:(),vis:(aggs:!((id:'1',params:(),schema:metric,type:count),(id:'2',params:(field:file_name.raw,order:desc,orderBy:'1',size:8),schema:segment,type:terms),(id:'3',params:(field:function_name.raw,order:desc,orderBy:'1',size:5),schema:segment,type:terms)),listeners:(),params:(addLegend:!t,addTooltip:!t,isDonut:!f,shareYAxis:!t),title:'New%20Visualization',type:pie))&embed=true`} height="1000" width="100%"></iframe>
                    </TabPane>
                    <TabPane tabId={'2'}>
                        <center>
                            <span className="fw-bold">All the logs w.r.t chrome and firefox</span><br />
                            <span className="fw-bold">Chrome logs</span><br />
                            <a href={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=pie&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.time},mode:quick,to:${urlParams.time == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'(*chrome*)+and+selenium_cluster.raw+:${urlParams.seleniumCluster == 'all' ? '*' : urlParams.seleniumCluster}'),uiState:(),vis:(aggs:!((enabled:!t,id:'1',params:(),schema:metric,type:count),(enabled:!t,id:'2',params:(filters:!((input:(query:chrome),label:''),(input:(query:firefox)))),schema:segment,type:filters),(enabled:!t,id:'3',params:(customLabel:'',field:file_name.raw,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:8),schema:segment,type:terms),(enabled:!t,id:'4',params:(field:function_name.raw,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:8),schema:segment,type:terms)),params:(addLegend:!t,addTooltip:!t,isDonut:!f,labels:(last_level:!t,show:!f,truncate:100,values:!t),legendPosition:right,shareYAxis:!t,type:pie),title:'New+Visualization',type:pie))`} target="_blank" className="primary-link">Click here to see below graph in kibana</a>
                        </center>
                        <iframe src={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=pie&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.time},mode:quick,to:${urlParams.time == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'(*chrome*)+and+selenium_cluster.raw+:${urlParams.seleniumCluster == 'all' ? '*' : urlParams.seleniumCluster}'),uiState:(),vis:(aggs:!((enabled:!t,id:'1',params:(),schema:metric,type:count),(enabled:!t,id:'2',params:(filters:!((input:(query:chrome),label:''),(input:(query:firefox)))),schema:segment,type:filters),(enabled:!t,id:'3',params:(customLabel:'',field:file_name.raw,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:8),schema:segment,type:terms),(enabled:!t,id:'4',params:(field:function_name.raw,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:8),schema:segment,type:terms)),params:(addLegend:!t,addTooltip:!t,isDonut:!f,labels:(last_level:!t,show:!f,truncate:100,values:!t),legendPosition:right,shareYAxis:!t,type:pie),title:'New+Visualization',type:pie))&embed=true`} height="500" width="100%"></iframe>
                        <center>
                            <span className="fw-bold">Firefox logs</span><br />
                            <a href={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=pie&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(pause:!t,value:0),time:(from:${urlParams.time},to:${urlParams.time == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'(*firefox*)+and+selenium_cluster.raw+:${urlParams.seleniumCluster == 'all' ? '*' : urlParams.seleniumCluster}'),uiState:(),vis:(aggs:!((enabled:!t,id:'1',params:(),schema:metric,type:count),(enabled:!t,id:'2',params:(filters:!((input:(query:chrome),label:''),(input:(query:firefox)))),schema:segment,type:filters),(enabled:!t,id:'3',params:(customLabel:'',field:file_name.raw,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:8),schema:segment,type:terms),(enabled:!t,id:'4',params:(field:function_name.raw,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:8),schema:segment,type:terms)),params:(addLegend:!t,addTooltip:!t,isDonut:!f,labels:(last_level:!t,show:!f,truncate:100,values:!t),legendPosition:right,shareYAxis:!t,type:pie),title:'New+Visualization',type:pie))`} target="_blank" className="primary-link">Click here to see below graph in kibana</a>
                        </center>
                        <iframe src={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=pie&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(pause:!t,value:0),time:(from:${urlParams.time},to:${urlParams.time == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'(*firefox*)+and+selenium_cluster.raw+:${urlParams.seleniumCluster == 'all' ? '*' : urlParams.seleniumCluster}'),uiState:(),vis:(aggs:!((enabled:!t,id:'1',params:(),schema:metric,type:count),(enabled:!t,id:'2',params:(filters:!((input:(query:chrome),label:''),(input:(query:firefox)))),schema:segment,type:filters),(enabled:!t,id:'3',params:(customLabel:'',field:file_name.raw,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:8),schema:segment,type:terms),(enabled:!t,id:'4',params:(field:function_name.raw,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:8),schema:segment,type:terms)),params:(addLegend:!t,addTooltip:!t,isDonut:!f,labels:(last_level:!t,show:!f,truncate:100,values:!t),legendPosition:right,shareYAxis:!t,type:pie),title:'New+Visualization',type:pie))&embed=true`} height="500" width="100%"></iframe>
                        <center>
                            <span className="fw-bold">a. Inner circle is segmented per file name. c.Outer circle is segmented per method name within those files.</span><br />
                            <a href={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=pie&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.time},mode:quick,to:${urlParams.time == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'selenium_cluster.raw+:${urlParams.seleniumCluster == 'all' ? '*' : urlParams.seleniumCluster}'),uiState:(),vis:(aggs:!((enabled:!t,id:'1',params:(),schema:metric,type:count),(enabled:!t,id:'2',params:(filters:!((input:(query:chrome),label:''),(input:(query:firefox)))),schema:segment,type:filters),(enabled:!t,id:'3',params:(customLabel:'',field:file_name.raw,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:8),schema:segment,type:terms),(enabled:!t,id:'4',params:(field:function_name.raw,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:8),schema:segment,type:terms)),params:(addLegend:!t,addTooltip:!t,isDonut:!f,labels:(last_level:!t,show:!f,truncate:100,values:!t),legendPosition:right,shareYAxis:!t,type:pie),title:'New+Visualization',type:pie))`} target="_blank" className="primary-link">Click here to see below graph in kibana</a>
                        </center>
                        <iframe src={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=pie&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.time},mode:quick,to:${urlParams.time == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'selenium_cluster.raw+:${urlParams.seleniumCluster == 'all' ? '*' : urlParams.seleniumCluster}'),uiState:(),vis:(aggs:!((enabled:!t,id:'1',params:(),schema:metric,type:count),(enabled:!t,id:'2',params:(filters:!((input:(query:chrome),label:''),(input:(query:firefox)))),schema:segment,type:filters),(enabled:!t,id:'3',params:(customLabel:'',field:file_name.raw,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:8),schema:segment,type:terms),(enabled:!t,id:'4',params:(field:function_name.raw,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:8),schema:segment,type:terms)),params:(addLegend:!t,addTooltip:!t,isDonut:!f,labels:(last_level:!t,show:!f,truncate:100,values:!t),legendPosition:right,shareYAxis:!t,type:pie),title:'New+Visualization',type:pie))&embed=true`} height="500" width="100%"></iframe>

                    </TabPane>
                    <TabPane tabId={'3'}>
                        <div className="fw-bold">
                            Segmentaion of logs from selenium_diskfetcher_v2 (main selenium file) w.r.t sites, can help in identifying troublesome sites
                        </div>
                        <center>
                            <a href={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.time},mode:quick,to:${urlParams.time == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(('$state':(store:appState),meta:(alias:!n,apply:!t,disabled:!f,index:'cc4e73b0-69fc-11ea-8b4a-69464b0a274d',key:file_name.raw,negate:!f,value:selenium_diskfetcher_module_v2.rb),query:(match:(file_name.raw:(query:selenium_diskfetcher_module_v2.rb,type:phrase))))),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'*selenium*%20AND%20selenium_cluster:%22${urlParams.seleniumCluster == 'all' ? '*' : urlParams.seleniumCluster}%22')),uiState:(),vis:(aggs:!((id:'1',params:(),schema:metric,type:count),(id:'2',params:(field:site_name.raw,order:desc,orderBy:'1',size:10),schema:segment,type:terms),(id:'3',params:(field:function_name.raw,order:desc,orderBy:'1',size:10),schema:group,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'New%20Visualization',type:histogram))`} target="_blank" className="primary-link">Click here to see below graph in kibana</a>
                        </center>
                        <iframe src={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.time},mode:quick,to:${urlParams.time == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(('$state':(store:appState),meta:(alias:!n,apply:!t,disabled:!f,index:'cc4e73b0-69fc-11ea-8b4a-69464b0a274d',key:file_name.raw,negate:!f,value:selenium_diskfetcher_module_v2.rb),query:(match:(file_name.raw:(query:selenium_diskfetcher_module_v2.rb,type:phrase))))),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'*selenium*%20AND%20selenium_cluster:%22${urlParams.seleniumCluster == 'all' ? '*' : urlParams.seleniumCluster}%22')),uiState:(),vis:(aggs:!((id:'1',params:(),schema:metric,type:count),(id:'2',params:(field:site_name.raw,order:desc,orderBy:'1',size:10),schema:segment,type:terms),(id:'3',params:(field:function_name.raw,order:desc,orderBy:'1',size:10),schema:group,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'New%20Visualization',type:histogram))&embed=true`} height="1000" width="100%"></iframe>
                    </TabPane>
                    <TabPane tabId={'4'}>
                        <center>
                            <span className="fw-bold">Waiting graphs for selenium browsers across clusters</span><br />
                            <a href={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.time},mode:quick,to:${urlParams.time == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'%22Geo%20wise%20selenium%20browser%20status%22%20AND%20selenium_cluster:%20%22${urlParams.seleniumCluster == 'all' ? '*' : urlParams.seleniumCluster}%22')),uiState:(),vis:(aggs:!((id:'1',params:(field:waiting_requests),schema:metric,type:max),(id:'2',params:(customLabel:selenium_cluster,field:selenium_cluster.raw,order:desc,orderBy:'1',size:5),schema:group,type:terms),(id:'3',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:auto,min_doc_count:1),schema:segment,type:date_histogram)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:'Waiting%20Graphs%20For%20Selenium%20Browsers%20Across%20Clusters',type:line))`} target="_blank" className="primary-link">Click here to see below graph in kibana</a>
                        </center>
                        <iframe src={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.time},mode:quick,to:${urlParams.time == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'%22Geo%20wise%20selenium%20browser%20status%22%20AND%20selenium_cluster:%20%22${urlParams.seleniumCluster == 'all' ? '*' : urlParams.seleniumCluster}%22')),uiState:(),vis:(aggs:!((id:'1',params:(field:waiting_requests),schema:metric,type:max),(id:'2',params:(customLabel:selenium_cluster,field:selenium_cluster.raw,order:desc,orderBy:'1',size:5),schema:group,type:terms),(id:'3',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:auto,min_doc_count:1),schema:segment,type:date_histogram)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:'Waiting%20Graphs%20For%20Selenium%20Browsers%20Across%20Clusters',type:line))&embed=true`} height="1000" width="100%"></iframe>
                    </TabPane>
                </TabContent>
            </CardBody>
        </Card>
    </Container>
}