import React, { Fragment,useState,useEffect,useCallback } from 'react';
import {Form,Row} from 'reactstrap'
import {X} from 'react-feather'
import {MENUITEMS} from '../../sidebar/menu'
import LeftHeader from '../../header/leftbar'
import RightHeader from '../../header/rightbar'
import {Link} from 'react-router-dom'
import configDB from '../../sidebar/config';

const Header = (props) => {
  return (
      <Fragment>
        <div className="page-header">
            <Row className="header-wrapper m-0">
                <RightHeader internalDashboard={true} />
            </Row>
        </div>
    </Fragment>
  );
}

export default Header;