import React from 'react'

export default function CustomCard({title, content, bg, small}) {
    return (
        <div className='custom-card' style={{background: `${bg}`}}>
            <p>{title}</p>
            {small ? <h4>{content}</h4> : <h2>{content}</h2>}
        </div>
    )
}
