import React, { Fragment } from 'react';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { Home } from 'react-feather';
import { Link } from 'react-router-dom'

const Breadcrumbs = (props) => {
  return (
    <Fragment>
      <Container fluid>
        <div className="page-title">
          <Row className=''>
            <Col xs="6">
              <h3>{props.parent}</h3>
            </Col>
            <Col xs="6">
              <Breadcrumb>
                {props?.internal ? <BreadcrumbItem><Link to={`/internal`}><Home /></Link></BreadcrumbItem>
                : <BreadcrumbItem><Link to={`/`}><Home /></Link></BreadcrumbItem>}
                {props.childrenObj && props.childrenObj.map((each, index) => (
                  <BreadcrumbItem key={index}>
                    <Link to={each.url}>
                      {each.name}
                    </Link>
                  </BreadcrumbItem>
                ))}
                <BreadcrumbItem active>{props.title}</BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default Breadcrumbs;