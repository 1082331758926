import React, { useCallback } from 'react'

export default function Search({ siteNameSetter, loadingAnimation }) {

    function debounce(func) {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 650);
        }
    };


    function onInputChangeHandler(event) {
        let { value } = event.target;
        loadingAnimation(true);
        siteNameSetter(value);
        // console.log(value);
        loadingAnimation(false);
    }


    const optimizedSerachHandler = useCallback(debounce(onInputChangeHandler), []);

    return (
        <>
            <div className='float-end d-flex align-center col-sm-5'>
                <h6 className='m-0 pe-2'>Search: </h6>
                <input
                    type="text"
                    name="search"
                    placeholder='Enter Site Name'
                    className='form-control'
                    onChange={optimizedSerachHandler}
                />
            </div>
        </>
    )
};