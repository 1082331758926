import React, { Fragment, useState, useEffect, useMemo } from 'react'

import axios from 'axios';
import { Button, Badge, Row, Col } from 'reactstrap'
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { DataNotFoundAlerts } from './Alerts.component';
import ChatGPTDataComparison from './ChatGPTDataComparison';
import XpathsWithTwoStepsModal from './XpathsWithTwoStepsModal';
import HighLevelDataSummary from './HighLevelDataSummary.modal';
import SpinnerLoader from '../../../V2/Common/LoadingAnimation/SpinnerLoader.component';

import XLSX from 'xlsx';


export default function RawDataFileForDataCheckReports(props) {

  // de-construct props
  const { rssCrawlPushID } = props;

  // site name from params
  const { site_name } = useParams();

  // misc.
  const [loading, setLoading] = useState(false);
  const [showHighLevelDataSummary, setShowHighLevelDataSummary] = useState(false);

  // states for api
  const [fileName, setFileName] = useState("");
  const [tableData, setTableData] = useState([]);
  const [fieldName, setFieldName] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [rawDataFilePath, setRawDataFilePath] = useState("");
  const [rawDataAvailable, setRawDataAvailable] = useState(false);
  const [rawFileColumnNames, setRawFileColumnNames] = useState([]);
  const [userSelectedColumns, setUserSelectedColumns] = useState([]);
  const [rawDataFilePresent, setRawDataFilePresent] = useState(false);
  const [dataComparisonFields, setDataComparisonFields] = useState([]);
  const [filterValue, setFilterValue] = useState("");

  const RAW_FILE_DOWNLOAD_API_ENDPOINT = useMemo(() => `/api/v1/internal/new_qa_reports/${site_name}/rss_crawl_push_id/${rssCrawlPushID}/download_raw_file`, [site_name, rssCrawlPushID]);

  const ALL_COMPARISON_DATA_DOWNLOAD_API_ENDPOINT = useMemo(() => `/api/v1/internal/new_qa_reports/${site_name}/rss_crawl_push_id/${rssCrawlPushID}/download_all_comparison_data`, [site_name, rssCrawlPushID]);

  const RAW_DATA_API_URL = useMemo(() => {
    const baseApi = '/api/v1/internal/new_qa_reports/raw_data_of_data_checks_reports?';
    const params = (rssCrawlPushID !== "") ? `site_name=${site_name}&rss_crawl_push_id=${rssCrawlPushID}` : `site_name=${site_name}`;
    return `${baseApi}${params}`;
  }, [site_name, rssCrawlPushID]);

  useEffect(() => {

    const controller = new AbortController();

    fetchRawDataTabData(controller.signal);

    // cleanup
    return () => {
      controller.abort();
      setFileName("");
      setTableData([]);
      setTableHeaders([]);
      setRawFileColumnNames([]);
      setRawDataAvailable(false);
      setUserSelectedColumns([]);
      setRawDataFilePresent(false);

    }

  }, [RAW_DATA_API_URL]);

  // Helper function to fetch table data
  async function fetchRawDataTabData(signal) {
    try {
      setLoading(true);

      const response = await axios.get(RAW_DATA_API_URL, { signal: signal });
      const data = await response?.data?.data;
      const filePath = await response?.data?.raw_data_file_path;
      const data_comparison_fields = await response?.data?.data_comparison_fields;

      setTableData(data?.raw_data);
      setFileName(data?.raw_data_file_name);
      setRawDataFilePath(filePath)
      setTableHeaders(data?.raw_data_headers);
      setRawFileColumnNames(data?.column_names);
      setUserSelectedColumns(data?.column_names);
      setRawDataAvailable(data?.is_raw_data_available);
      setRawDataFilePresent(data?.raw_data_file_present);
      setDataComparisonFields(data_comparison_fields);

      setLoading(false);
    }
    catch (error) {
      console.error(error?.response?.message);
      setLoading(false);
    }
  }

  function tableColumns(headers) {
    let tableColumns = []
    headers?.forEach((header) => {
      if (header?.selector === "file_paths") {
        tableColumns.push(
          {
            id: header?.id,
            name: header?.name,
            selector: header?.selector,
            cell: row => (
              <XpathsWithTwoStepsModal
                siteName={site_name}
                inputFilePath={row?.file_paths}
                fieldName={fieldName}
                setFieldName={setFieldName}
              />
            ),
            center: true,
            responsive: true,
            sortable: true,
            wrap: true,
            compact: true,
            maxWidth: "87rem",
            omit: !userSelectedColumns.includes(header?.name)
          }
        )
      }
      else if (header?.selector === "prefetched_chatgpt_response") {
        tableColumns.push(
          {
            id: header?.id,
            name: header?.name,
            selector: header?.selector,
            cell: row => (
              <ChatGPTDataComparison
                rowData={row}
                comparisonDataTableArray={generateComparisonOutput(Array(filteredFields(row)), formatPrefetchData(row?.prefetched_chatgpt_response))}
              />
            ),
            center: true,
            responsive: true,
            sortable: true,
            wrap: true,
            compact: true,
            maxWidth: "87rem",
            omit: !userSelectedColumns.includes(header?.name)
          }
        )
      }
      else {
        tableColumns.push(
          {
            id: header.id,
            name: header.name,
            selector: header.selector,
            center: true,
            sortable: true,
            responsive: true,
            wrap: true,
            compact: true,
            maxWidth: "87rem",
            omit: !userSelectedColumns.includes(header?.name)
          }
        )
      }
    })
    return tableColumns
  };

  const filteredFields = (row) => {
    let filtered = Object.keys(row)
      .filter(key => dataComparisonFields.includes(key))
      .reduce((obj, key) => {
        obj[key] = row[key];
        return obj;
      }, {});
    return filtered;
  }

  const downloadCsvGzFile = (apiEndpoint, fileName) => {
    if (rawDataFilePresent) {
      axios.get(apiEndpoint, {
        responseType: 'blob',
      }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/gzip' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }).catch((err) => {
        toast.error(err?.message)
      });
    }
  };

  async function downloadAllComparisonFile(apiEndpoint) {
    try {
      const response = await axios.get(apiEndpoint);
      const data = await response?.data?.data;

      // Check if data exists and is an array
      if (Array.isArray(data) && data.length > 0) {
        // Convert data array to CSV format
        const csvContent = "data:text/csv;charset=utf-8," +
          Object.keys(data[0]).join(",") + "\n" +
          data.map(item => Object.values(item).join(",")).join("\n");

        // Create a temporary anchor element to trigger download
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        let fileName = site_name + "_" + rssCrawlPushID + "_" + "comparison_data.csv"
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up
        document.body.removeChild(link);
      } else {
        toast.error("Data is empty or not in the correct format.");
      }
    } catch (error) {
      toast.error(error?.message);
    }
  }

  function handleColumnFilterCheckHandler(event) {
    const { value } = event?.target;
    if (value === "all") {
      if (rawFileColumnNames?.length === userSelectedColumns?.length) {
        setUserSelectedColumns([]);
      }
      else {
        setUserSelectedColumns(rawFileColumnNames)
      }
    }
    else {
      if (userSelectedColumns.includes(value)) {
        setUserSelectedColumns(userSelectedColumns.filter((field_name) => field_name !== value))
      }
      else {
        setUserSelectedColumns([...userSelectedColumns, value])
      }
    }
  }

  return (
    <Fragment>
      <SpinnerLoader loading={loading} className="p-3">
        <DataNotFoundAlerts display={rawDataFilePresent || rawDataAvailable} alertMessage={"Raw File Not Found"}>
          <div className='parent-container'>
            <section className='table-filter mb-3'>
              <div className='d-flex flex-row justify-content-center align-items-center align-content-center flex-wrap my-3 p-2'>
                <Row>
                  <Col key={"all"} md="3">
                    <input
                      type="checkbox"
                      value={"all"}
                      id="column-name"
                      className='mx-2'
                      onChange={handleColumnFilterCheckHandler}
                      defaultChecked />
                    <label htmlFor="column-name" key={"all"} className='mx-2 d-inline-flex'> All</label>
                  </Col>
                  {
                    rawFileColumnNames?.length > 0 && rawFileColumnNames?.map((columnName, indx) => (
                      <Col key={columnName} md="3">
                        <input
                          type="checkbox"
                          value={columnName}
                          id="column-name"
                          className='mx-2'
                          onChange={handleColumnFilterCheckHandler}
                          checked={userSelectedColumns.includes(columnName)}
                        />
                        <label htmlFor="column-name" key={indx} className='mx-2 d-inline-flex'> {columnName}</label>
                      </Col>
                    ))
                  }
                </Row>
              </div>
            </section>
            <section className='body mb-3'>
              <div className='id-and-download-button d-flex flex-row justify-content-between align-items-center'>
                <div className='rss-crawl-id'>
                  <p className='h6'>Report ID: <mark>{rssCrawlPushID}</mark></p>
                </div>
                <div className="d-flex flex-row">
                  <div className='high-level-data-summary-report-modal-button mx-3'>
                    <Button
                      color="primary"
                      disabled={!rawDataFilePresent}
                      onClick={() => setShowHighLevelDataSummary(true)} >
                      High Level Data Summary
                    </Button>
                    <HighLevelDataSummary
                      show={showHighLevelDataSummary}
                      onHide={() => setShowHighLevelDataSummary(false)}
                      inputFilePath={rawDataFilePath} />
                  </div>
                  <div className='download-report-button'>
                    <Button
                      className='mx-3'
                      color="success"
                      disabled={!rawDataFilePresent}
                      onClick={() => downloadAllComparisonFile(ALL_COMPARISON_DATA_DOWNLOAD_API_ENDPOINT)} >
                      Download Comparison File
                    </Button>
                    <Button
                      color="success"
                      disabled={!rawDataFilePresent}
                      onClick={() => downloadCsvGzFile(RAW_FILE_DOWNLOAD_API_ENDPOINT, fileName)} >
                      Download Raw File
                    </Button>
                  </div>
                </div>
              </div>
            </section>
            <section className='new-qa-reports-raw-data-file'>
              <DataNotFoundAlerts display={rawDataAvailable} alertMessage={"No data available to display"}>
                <DataTable
                  columns={tableColumns(tableHeaders)}
                  data={tableData}
                  striped
                  noHeader
                  responsive
                  pagination
                  highlightOnHover
                />
              </DataNotFoundAlerts>
            </section>
          </div>
        </DataNotFoundAlerts>
      </SpinnerLoader>
    </Fragment>
  )
}

// https://react-data-table-component.netlify.app/?path=/story/columns-omit-dynamically--omit-dynamically
function extractSelectors(inputArray) {
  // Extract values for the "selector" key from each object in the input array
  return inputArray.map(item => item.selector);
}

function formatPrefetchData(inputString) {
  try {
    const data = JSON.parse(inputString);

    if (typeof data === 'object' && data !== null) {
      // Extract keys and values
      const result = [Object.fromEntries(Object.entries(data).map(([key, value]) => [key, value.llm_value]))];

      return result;
    } else {
      throw new Error('Invalid JSON format.');
    }
  } catch (error) {
    console.error('Error parsing data:', error.message);
    return [];
  }
}


function parseData(inputString) {
  try {
    const data = JSON.parse(inputString);

    if (typeof data === 'object' && data !== null) {
      // Extract keys and values
      const result = Object.entries(data).map(([key, value]) => ({ [key]: value.llm_value }));

      return result;
    } else {
      throw new Error('Invalid JSON format.');
    }
  } catch (error) {
    console.error('Error parsing data:', error.message);
    return [];
  }
}

function capitalizeWords(str) {
  return str.replace(/_/g, ' ').replace(/\b\w/g, match => match.toUpperCase());
}

function transformToColumnsArray(inputString) {
  const inputArray = parseData(inputString);

  const dataSourceColumn = {
    name: "Data Source",
    selector: row => row?.data_source,
    left: true,
    sortable: true,
  };

  const transformedColumns = inputArray.map(obj => {
    const key = Object.keys(obj)[0];
    const name = capitalizeWords(key);
    const selectorFunction = row => {
      const value = row?.[key];
      return (value !== null && value !== "") ? value : "NA";
    };

    return {
      name,
      selector: selectorFunction,
      left: true,
      sortable: true,
      maxWidth: "650px",
    };
  });

  return [dataSourceColumn, ...transformedColumns];
}

function generateComparisonOutput(rawDataArray, prefetchedDataArray) {
  const output = [];

  for (let i = 0; i < rawDataArray.length; i++) {
    const rawData = rawDataArray[i];
    const prefetchedData = prefetchedDataArray[i] || {};

    for (const key in rawData) {
      if (Object.prototype.hasOwnProperty.call(rawData, key)) {
        const field_name = key;
        const raw_data = rawData[key];
        const pre_fetched_data = prefetchedData[key] || "NA";
        const data_match = checkIfDataMatches(raw_data, pre_fetched_data)

        output.push({ field_name, raw_data, pre_fetched_data, data_match });
      }
    }
  }

  return output;
};

function checkIfDataMatches(rawValue, prefetchValue) {
  if (rawValue.toString().toLowerCase() === prefetchValue.toString().toLowerCase() || prefetchValue.toString().toLowerCase().includes(rawValue.toString().toLowerCase())) {
    return "matched"
  }
  else {
    return "not matched"
  }
}
