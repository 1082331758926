import React, {Fragment, useState, useEffect} from 'react'
import { Container, Row, Col, BreadcrumbItem, Breadcrumb, Card, CardBody } from 'reactstrap'
import { Home } from 'react-feather';
import { Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import moment from "moment";
import axios from "axios";
import Loader from "react-loader-spinner";
import ApexCharts from 'react-apexcharts'
import { useLocation } from 'react-router-dom'

export default function TotalRSSTotalFeedcrawlCounts() {
  const search = useLocation().search
  const commit = new URLSearchParams(search).get("commit");
  const searchParams = {
    fromDate: new URLSearchParams(search).get("filter[from]"),
    toDate: new URLSearchParams(search).get("filter[to]"),
  }

  const [fromDate, setFromDate] = useState(new Date(moment().subtract(3, 'days')));
  const [toDate, setToDate] = useState(new Date());
  const [loadingBtns, setLoadingBtns] = useState(false)
  const [loading, setLoading] = useState(true)
  const today = new Date(Date.now())
  const [data, setData] = useState({options: [], series: []})

  useEffect(() => {
    setSearchParamsData()
  }, [])

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.fromDate && searchParams.fromDate != fromDate) {
      setFromDate(new Date(searchParams.fromDate));
    }
    if (searchParams.toDate && searchParams.toDate != toDate) {
      setToDate(new Date(searchParams.toDate));
    }
    if (commit == 'Get Data' && searchParams.fromDate && searchParams.toDate) {
      fetchData(searchParams.fromDate, searchParams.toDate);
    }
    else {
      fetchData(fromDate, toDate);
    }
  }

  const getData = () => {
    window.location.href = `/internal/overall_system_reports/total_rss_and_feed_crawl_count?filter[from]=${moment(fromDate).format('YYYY/MM/DD')}&filter[to]=${moment(toDate).format('YYYY/MM/DD')}&commit=${'Get Data'}`
  }

  const fetchData = (selectedFromDate, SelectedToDate) => {
    setLoading(true)
    axios.get(`/api/v1/internal/overall_system_reports/total_rss_and_feed_crawl_count?filter[from]=${moment(selectedFromDate).format('YYYY/MM/DD')}&filter[to]=${moment(SelectedToDate).format('YYYY/MM/DD')}`).then(res => {
      console.log('res.data', res.data)
      setData({
        series: [
          {
            name: 'RSS Crawl Counts',
            data: res.data?.rss_crawl_counts
          }, {
            name: 'Feed Crawl Counts',
            data: res?.data?.feed_crawl_counts
          }],
        options: {
          chart: { type: 'bar', height: 350 },
          plotOptions: {
            bar: { horizontal: false, columnWidth: '55%', endingShape: 'rounded' },
          },
          dataLabels: { enabled: false },
          stroke: { show: true, width: 2, colors: ['transparent']},
          xaxis: {
            categories: res.data?.date_range,
            title: { text: ''}
          },
          fill: { opacity: 1 },
          tooltip: {
            y: {
              formatter: function (val) {
                return "RSS Crawl Counts: " + val
              }
            }
          }
        }
      })
      setLoading(false)
    }).catch(err => {
      setLoading(false)
    })
  }

  return (
    <Container>
      <Fragment>
        <Container fluid>
          <div className="page-title">
            <Row className=''>
              <Col xs="6">
                <h3>Internal Dashboard</h3>
              </Col>
              <Col xs="6">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={'/internal'}>
                      <Home />
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    {commit == 'Get Data' ? <a href="/internal/overall_system_reports/total_rss_and_feed_crawl_count">Total RSS Crawl Counts vs Total Feedcrawl Counts</a> : 'Total RSS Crawl Counts vs Total Feedcrawl Counts'}
                  </BreadcrumbItem>
                  {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                    Search
                </BreadcrumbItem>}
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>

    <Card>
      <CardBody>
        <Row>
          <Col md={4} className='my-3'>
            <h6 className='fw-bold my-0'>From Date*</h6>
            <DatePicker className="form-control my-2" selected={fromDate}
              dateFormat="yyyy/MM/dd" maxDate={today} onChange={(date) => setFromDate(date)} />
          </Col>
          <Col md={4} className='my-3'>
            <h6 className='fw-bold my-0'>To Date*</h6>
            <DatePicker className="form-control my-2" selected={toDate}
              dateFormat="yyyy/MM/dd" maxDate={today} onChange={(date) => setToDate(date)} />
          </Col>
          <Col md={4}>
            <button className='btn btn-success' onClick={getData}
              disabled={loadingBtns} style={{margin: '46px auto', width: '100%'}}> Submit </button>
          </Col>
        </Row>
      </CardBody>
    </Card>

    {(data?.series?.length > 0) 
    ? <Card className='mt-2 mb-3'>
      <CardBody>
        <h5 className='fw-bold text-center mb-2'>Total RSS Crawl Counts vs Total Feedcrawl Counts</h5>
        <ApexCharts options={data?.options} 
          series={data?.series} type="bar" height={350} />
      </CardBody>
    </Card>
      : <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />}
    </Container>
  )
}
