import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";

import {KIBANA_JP_URL} from '../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints'

export default function DatastockRequestLogs() {

  function KibanaUrl(){
    return `${KIBANA_JP_URL}app/kibana#/dashboard/57aaa6b0-ec28-11ea-ae69-8fc0e4ff3a0d?_g=(refreshInterval:(pause:!t,value:0),time:(from:now-7d,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),panels:!((embeddableConfig:(vis:(legendOpen:!f)),gridData:(h:8,i:b4e7737c-14f8-40ef-87ea-350d1aea43a0,w:24,x:0,y:0),id:d5fc44d0-ec2b-11ea-8111-3981991577bf,panelIndex:b4e7737c-14f8-40ef-87ea-350d1aea43a0,type:visualization,version:'7.5.1'),(embeddableConfig:(),gridData:(h:8,i:ef584fdb-49db-4d28-b8ad-5c5d6c0d9ddc,w:24,x:24,y:0),id:ecde9030-ec27-11ea-8111-3981991577bf,panelIndex:ef584fdb-49db-4d28-b8ad-5c5d6c0d9ddc,type:search,version:'7.5.1'),(embeddableConfig:(),gridData:(h:9,i:'817fc667-ce26-4490-a9cd-4c802e16f237',w:48,x:0,y:8),id:'51f00480-ec29-11ea-ae69-8fc0e4ff3a0d',panelIndex:'817fc667-ce26-4490-a9cd-4c802e16f237',type:search,version:'7.5.1')),query:(language:kuery,query:''),timeRestore:!f,title:'DataStock+Logstash',viewMode:view)`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>DataStock Request Logs</BreadcrumbItem>
        </Breadcrumb>
      </Col>
    </Row>
  </div>

  <Card>
    <CardBody>
      <center>
        <a target="_blank" className="link-primary" href={KibanaUrl()}>Click here to see below graph in kibana</a>
      </center>
      <iframe src={KibanaUrl() + "&embed=true"} height="500" width="100%" className="border-0"></iframe>
    </CardBody>
  </Card>
</Container>
}

