import React, {useState, useEffect, useContext} from 'react'
import {Container, Row, Col, Card, CardBody, CardHeader, Alert } from 'reactstrap';
import ApexCharts from 'react-apexcharts'
import { useHistory } from "react-router-dom";
import Breadcrumb from '../global/Breadcrumb';
import DataTable from 'react-data-table-component'
import axios from 'axios';
import { toast } from 'react-toastify';
import { Download } from 'react-feather';
import { useParams } from "react-router-dom";
import Loader from "react-loader-spinner";
import { OrgnaziationContext } from '../Home';

export default function Homepage({banner_notifications, current_organization}) {
    let history = useHistory();
    const [data, setData] = useState({})
    const [last10Data, setLast10Data] = useState([])
    const [loading, setLoading] = useState(true)
    const [series, setSeries] = useState([])
    let { organization_name:tempOrgname } = useParams();
    const {orgName, setOrgName} = useContext(OrgnaziationContext)
    const [organization_name, setOrganization_name] = useState("")
    const [bannerNotifications, setBannerNotifications] = useState([])
    const [gettingStarted, setGettingStarted] = useState({
      add_sites: false, invite_users: false, check_api: false, setup_api: false,
      faq: false, watch_video: false
  })
  
    const [templast10data, setTemplast10data] = useState([])

    useEffect(() => {
      if (tempOrgname){
        setOrgName(tempOrgname);
        setOrganization_name(tempOrgname);
      }
      else{
        setOrganization_name(current_organization?.org_identifier);
      }
    }, [orgName, tempOrgname, current_organization?.org_identifier])

    useEffect(() => {
      setBannerNotifications(banner_notifications)
    }, [banner_notifications])

    const [options, setOptions] = useState({
        chart: {
          height: 240,
          type: 'area',
          toolbar: {
            show: false
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'category',
          low: 0,
          offsetX: 0,
          offsetY: 0,
          show: false,
          categories: [],
          labels: {
            low: 0,
            offsetX: 0,
            show: false,
          },
          axisBorder: {
            low: 0,
            offsetX: 0,
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        markers: {
          strokeWidth: 3,
          colors: "#ffffff",
          strokeColors: ['#f73164'],
          hover: {
            size: 6,
          }
        },
        yaxis: {
          low: 0,
          offsetX: 0,
          offsetY: 0,
          show: false,
          labels: {
            low: 0,
            offsetX: 0,
            show: false,
          },
          axisBorder: {
            low: 0,
            offsetX: 0,
            show: false,
          },
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
            bottom: -15,
            top: -40
          }
        },
        colors: ['#f73164'],
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.5,
            stops: [0, 80, 100]
          }
        },
        legend: {
          show: false,
        },
        tooltip: {
          x: {
            format: 'MM'
          },
        },
    })

    


    useEffect(() => {
        if (organization_name){
            setLoading(true)
            axios.get(`/api/v1/organizations/${organization_name}/dashboard/home`).then(res => {
                setData(res.data.dashboard_details)
                setSeries([{
                    name: 'upload count',
                    data: res.data.dashboard_details.uploads_count
                }])
                setOptions({
                    chart: {
                      height: 240,
                      type: 'area',
                      toolbar: {
                        show: false
                      },
                    },
                    dataLabels: {
                      enabled: false
                    },
                    stroke: {
                      curve: 'smooth'
                    },
                    xaxis: {
                      type: 'category',
                      low: 0,
                      offsetX: 0,
                      offsetY: 0,
                      show: false,
                      categories: res.data.dashboard_details.days,
                      labels: {
                        low: 0,
                        offsetX: 0,
                        show: false,
                      },
                      axisBorder: {
                        low: 0,
                        offsetX: 0,
                        show: false,
                      },
                      axisTicks: {
                        show: false,
                      },
                    },
                    markers: {
                      strokeWidth: 3,
                      colors: "#ffffff",
                      strokeColors: ['#f73164'],
                      hover: {
                        size: 6,
                      }
                    },
                    yaxis: {
                      low: 0,
                      offsetX: 0,
                      offsetY: 0,
                      show: false,
                      labels: {
                        low: 0,
                        offsetX: 0,
                        show: false,
                      },
                      axisBorder: {
                        low: 0,
                        offsetX: 0,
                        show: false,
                      },
                    },
                    grid: {
                      show: false,
                      padding: {
                        left: 0,
                        right: 0,
                        bottom: -15,
                        top: -40
                      }
                    },
                    colors: ['#f73164'],
                    fill: {
                      type: 'gradient',
                      gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.5,
                        stops: [0, 80, 100]
                      }
                    },
                    legend: {
                      show: false,
                    },
                    tooltip: {
                      x: {
                        format: 'MM'
                      },
                    },
                  })
                setLast10Data(res.data.dashboard_details.last_ten_uploaded_files)
                setLoading(false)
            }).catch(err => {
                setLoading(false)
            })
            
        }
    }, [organization_name])

    useEffect(() => {
        if (last10Data.length > 0){
            let temp = []
            last10Data.forEach(each => {
                temp.push({
                    site_name: each.site_name, no_of_records: each.no_of_records, upload_time: each.upload_time,
                    download: <a href={each.download_url} target='_blank' className='btn btn-primary btn-sm'> <Download size={18} /> </a>
                })
            })
            setTemplast10data(temp)
        }
    }, [last10Data])

    const columns = [
        {name: 'Site',selector: 'site_name',sortable: true,center:true, width: '400px'},
        {name: 'no.of records',selector: 'no_of_records',sortable: true,center:true,},
        {name: 'Upload time',selector: 'upload_time',sortable: true,center:true},
        {name: 'Download',selector: 'download',center:true},
    ];

    const handleCB = (e) => {
        setGettingStarted({
            ...gettingStarted, [e.target.name]: e.target.checked
        })
    }
    return (
        <Container fluid>
            <Breadcrumb parent={"Overview"} title="Welcome to CrawlBoard" />
            {bannerNotifications.map(item => {
              if (item?.gst_update?.message){
                return (
                  <Alert color='primary' className='d-flex align-items-center justify-content-between my-2'>
                    <p className='my-0'>{item?.gst_update?.message}</p>
                    <a href={item?.gst_update?.url} target='_blank' style={{background: 'white', whiteSpace: 'nowrap'}} className='btn btn-xs text-primary'>Update GSTIN</a>
                  </Alert>
                )
              }
              else if (item?.pending_feasibility_reports?.length > 0){
                return (item?.pending_feasibility_reports?.map((each, index) => (
                  <Alert color='primary' key={index} className='d-flex align-items-center justify-content-between my-2'>
                      <p className='my-0'>{each?.message}</p>
                      <a href={each?.url} target='_blank' style={{background: 'white', whiteSpace: 'nowrap'}} className='btn btn-xs text-primary'>View and Proceed</a>
                  </Alert>
                 )))
              }
            })}
            {(loading && !(data.current_bill)) ? 
            <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />: 
            <Container fluid>
            <Row>
                <Col xl="12 xl-100" className="dashboard-sec box-col-12">
                    <Card className="earning-card">
                      <CardHeader className='p-4 fw-bold'>
                          Records uploaded in last 30 days
                      </CardHeader>
                    <CardBody className="p-0">
                        <Row className="m-0">
                        <Col xl="3" className="earning-content p-0">
                            <Row className="m-0 chart-left">
                            <Col xl="12" className="p-0 left_side_earning">
                                <h5>Dashboard</h5>
                                <p className="font-rubik">Overview of last month</p>
                            </Col>
                            <Col xl="12" className="p-0 left_side_earning">
                                <h5>
                                    {data.active_sites}
                                </h5>
                                <p className="font-rubik">Active Sites</p>
                            </Col>
                            <Col xl="12" className="p-0 left_side_earning">
                                <h5>
                                    {data.new_records_today}
                                </h5>
                                <p className="font-rubik">New Records Today</p>
                            </Col>
                            <Col xl="12" className="p-0 left_side_earning">
                                <h5>
                                    {data.current_bill}
                                </h5>
                                <p className="font-rubik">Current Bill</p>
                            </Col>
                            <Col xl="12" className="p-0 left-btn"></Col>
                            </Row>
                        </Col>
                        <Col xl="9" className="p-0">
                            <div className="chart-right">
                            <Row className="m-0 p-tb">
                                <Col xl="8" md="8" sm="8" className="col-12 p-0">

                                </Col>
                                <Col xl="4" md="4" sm="4" className="col-12 p-0 justify-content-end">
                                <div className="inner-top-right">
                                    <ul className="d-flex list-unstyled justify-content-end">
                                        
                                        <li>Upload count</li>
                                    </ul>
                                </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl="12">
                                <CardBody className="p-0">
                                    <div className="current-sale-container">
                                    <ApexCharts id="chart-currently" options={options} series={series} type='area' height={240} />
                                    </div>
                                </CardBody>
                                </Col>
                            </Row>
                            </div>
                            <Row className="border-top m-0">
                            <Col xl="4" md="6" sm="6" className="pl-0">
                                <div className="media p-0">
                                <div className="media-left"><i className="icofont icofont-ui-previous"></i></div>
                                <div className="media-body">
                                    <h6>Last Crawl Date</h6>
                                    <p>{data.last_crawl_date}</p>
                                </div>
                                </div>
                            </Col>
                            <Col xl="4" md="6" sm="6">
                                <div className="media p-0">
                                <div className="media-left bg-secondary"><i className="icofont icofont-upload"></i></div>
                                <div className="media-body">
                                    <h6>Last upload Date</h6>
                                    <p>
                                        {data.last_upload_date}
                                    </p>
                                </div>
                                </div>
                            </Col>
                            <Col xl="4" md="12" className="pr-0">
                                <div className="media p-0">
                                <div className="media-left"><i className="icofont icofont-ui-next"></i></div>
                                <div className="media-body">
                                    <h6>Next Crawl Date</h6>
                                    <p>
                                        {data.next_crawl_date}
                                    </p>
                                </div>
                                </div>
                            </Col>
                            </Row>
                        </Col>
                        </Row>
                    </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md='6'>
                    <Card>
                        <CardBody>
                            <h5 className='mb-4'> Last 10 uploaded files </h5>
                            <DataTable columns={columns} data={templast10data} noHeader responsive />
                            <br />
                            <a href={`/organizations/${organization_name}/api_details`} className='text-primary mt-4'>
                                Click here for instructions to access rest of the data files
                            </a>
                        </CardBody>
                    </Card>
                </Col>
                <Col md='6'>
                    <Card>
                        <CardBody>
                            <h5 className='mb-4'> Getting started </h5>
                            <div className='d-flex align-center my-3'>
                                <input type="checkbox" className='form-check-input mt-0 me-4'
                                    checked={gettingStarted.add_sites}
                                    name='add_sites' onChange={(e) => handleCB(e)}  />
                                <h6 className={gettingStarted.add_sites ? 'line-thrgh m-0' : 'm-0'}>Add sites to crawl</h6>
                            </div>
                            <div className='d-flex align-center my-3'>
                                <input type="checkbox" className='form-check-input mt-0 me-4'
                                    checked={gettingStarted.invite_users}
                                    name='invite_users' onChange={(e) => handleCB(e)}  />
                                <h6 className={gettingStarted.invite_users ? 'line-thrgh m-0' : 'm-0'}>
                                    Invite users to your account
                                </h6>
                            </div>
                            <div className='d-flex align-center my-3'>
                                <input type="checkbox" className='form-check-input mt-0 me-4'
                                    checked={gettingStarted.check_api}
                                    name='check_api' onChange={(e) => handleCB(e)}  />
                                <h6 className={gettingStarted.check_api ? 'line-thrgh m-0' : 'm-0'}>
                                    Check the API doc
                                </h6>
                            </div>
                            <div className='d-flex align-center my-3'>
                                <input type="checkbox" className='form-check-input mt-0 me-4'
                                    checked={gettingStarted.setup_api}
                                    name='setup_api' onChange={(e) => handleCB(e)}  />
                                <h6 className={gettingStarted.setup_api ? 'line-thrgh m-0' : 'm-0'}>
                                    Setup API download
                                </h6>
                            </div>
                            <div className='d-flex align-center my-3'>
                                <input type="checkbox" className='form-check-input mt-0 me-4'
                                    checked={gettingStarted.faq}
                                    name='faq' onChange={(e) => handleCB(e)}  />
                                <h6 className={gettingStarted.faq ? 'line-thrgh m-0' : 'm-0'}>
                                    Go through <a href='#' target='_blank' className='text-primary'>FAQ</a>
                                </h6>
                            </div>
                            <div className='d-flex align-center my-3'>
                                <input type="checkbox" className='form-check-input mt-0 me-4'
                                    checked={gettingStarted.watch_video}
                                    name='watch_video' onChange={(e) => handleCB(e)}  />
                                <h6 className={gettingStarted.watch_video ? 'line-thrgh m-0' : 'm-0'}>
                                    Watch illustration <a href='#' target='_blank' className='text-primary'> video </a>
                                </h6>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>}
        </Container>
    )
}