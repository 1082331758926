import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";
import axios from "axios";

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function SiteOrProjectOrClientVsSeleniumCluster() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[site_name]"),
    seleniumCluster: new URLSearchParams(search).get("filter[selenium_cluster]"),
    fromTime: new URLSearchParams(search).get("filter[time]"),
  }

  const [siteName, setSiteName] = useState("");
  const [seleniumCluster, setSeleniumCluster] = useState({ label: 'All', value: '*' });
  const [seleniumClusterOptions, setSeleniumClusterOptions] = useState([])
  const [fromTime, setFromTime] = useState({ label: 'Last 7 days', value: 'now-7d' });
  const [loading, setLoading] = useState(false);

  const timeOptions = [
    { label: 'Today', value: 'now%2Fd' },
    { label: 'Last 30 minutes', value: 'now-30m' },
    { label: 'Last 1 hour', value: 'now-1h' },
    { label: 'Last 2 hours', value: 'now-2h' },
    { label: 'Last 4 hours', value: 'now-4h' },
    { label: "Last 10 hours", value: "now-10h" },
    { label: 'Last 12 hours', value: 'now-12h' },
    { label: 'Last 24 hours', value: 'now-24h' },
    { label: 'Last 2 days', value: 'now-2d' },
    { label: 'Last 3 days', value: 'now-3d' },
    { label: 'Last 4 days', value: 'now-4d' },
    { label: 'Last 7 days', value: 'now-7d' },
    { label: 'Last 15 days', value: 'now-15d' },
    { label: 'Last 30 days', value: 'now-30d' },
    { label: 'Last 60 days', value: 'now-60d' },
    { label: 'Last 1 year', value: 'now-1y' }
  ];

  const [UrlParams, setUrlParams] = useState({
    siteName: '',
    seleniumCluster: '*',
    fromTime: 'now-7d'
  })

  useEffect(() => {
    setLoading(true);
    setSearchParamsData();
    axios.get(`/api/v1/internal/dropdown_list_helper`)
      .then(res => {
        let selenium_clusters = []
        res.data.geo_clusters.forEach(item => {
          selenium_clusters.push({ label: item, value: item })
        })
        setSeleniumClusterOptions(selenium_clusters);
        setLoading(false)
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong');
        setLoading(false)
      })
  }, [])


  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.siteName && searchParams.siteName != siteName) {
      setSiteName(searchParams.siteName);
    }
    if (searchParams.seleniumCluster && searchParams.seleniumCluster != seleniumCluster.value) {
      setSeleniumCluster({ label: searchParams.seleniumCluster  == '*' ? 'All' :  searchParams.seleniumCluster, value: searchParams.seleniumCluster });
    }
    if (searchParams.fromTime && searchParams.fromTime != fromTime.value) {
      setFromTime(timeOptions.filter(item => item.value == searchParams.fromTime)[0]);
    }
    if (commit == 'Get Data' && searchParams.fromTime && searchParams.siteName && searchParams.seleniumCluster) {
      setUrlParams(searchParams)
    }
  }

  const handleInputChange = event => {
    setSiteName(event.target.value);
  }

  function SiteOrProjectOrClientVsSeleniumClusterKibanaUrl(){
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
    var site_name_val = UrlParams.siteName ? `*${UrlParams.siteName}*` : "*";
    var selenium_cluster_val = UrlParams.seleniumCluster ? UrlParams.seleniumCluster : "*";
    console.log("UrlParams", UrlParams);

    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(pause:!t,value:0),time:(from:'${UrlParams.fromTime}',to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'site_name:${site_name_val}+AND+msg:+%22Fetch+status+when+crawl+are+using+proxies%22+AND+request_type+:+%22selenium%22+AND+selenium_cluster:${selenium_cluster_val}'),uiState:(),vis:(aggs:!((enabled:!t,id:'1',params:(field:no_of_requests_to_fetch_page),schema:metric,type:sum),(enabled:!t,id:'2',params:(customLabel:'cluster+name',field:selenium_cluster.raw,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:10),schema:segment,type:terms)),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,categoryAxes:!((id:CategoryAxis-1,labels:(filter:!t,show:!t,truncate:100),position:bottom,scale:(type:linear),show:!t,style:(),title:(),type:category)),dimensions:(x:(accessor:0,aggType:terms,format:(id:terms,params:(id:string,missingBucketLabel:Missing,otherBucketLabel:Other)),params:()),y:!((accessor:1,aggType:count,format:(id:number),params:()))),grid:(categoryLines:!f),labels:(show:!f),legendPosition:right,seriesParams:!((data:(id:'1',label:'Sum+of+no_of_requests_to_fetch_page'),drawLinesBetweenPoints:!t,lineWidth:2,mode:stacked,show:!t,showCircles:!t,type:histogram,valueAxis:ValueAxis-1)),thresholdLine:(color:%2334130C,show:!f,style:full,value:10,width:1),times:!(),type:histogram,valueAxes:!((id:ValueAxis-1,labels:(filter:!f,rotate:0,show:!t,truncate:100),name:LeftAxis-1,position:left,scale:(mode:normal,type:linear),show:!t,style:(),title:(text:'Sum+of+no_of_requests_to_fetch_page'),type:value))),title:'',type:histogram))`
  }

  const handleGetData = () => {
    window.location.href = `/internal/site_or_project_or_client_vs_selenium_cluster?filter[site_name]=${siteName}&filter[selenium_cluster]=${seleniumCluster.value}&filter[time]=${fromTime.value}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/site_or_project_or_client_vs_selenium_cluster">Site/Project/Client vs Selenium Cluster</a> : 'Site/Project/Client vs Selenium Cluster'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                Search
                </BreadcrumbItem>}
          </Breadcrumb>
        </Col>
      </Row>
    </div>

    <Card>
      <CardBody>
        <Row>
          <Col sm="4">
            <label className="fw-bold">Enter Site/Project/Client Name</label>
            <input type="text" className="form-control" value={siteName} onChange={handleInputChange} />
          </Col>
          <Col sm="4">
            <label className="fw-bold">Selenium Cluster</label>
            <Select
              value={seleniumCluster}
              options={seleniumClusterOptions}
              onChange={option => setSeleniumCluster(option)} />
          </Col>
          <Col sm="4">
            <label className="fw-bold">Time</label>
            <Select
              value={fromTime}
              options={timeOptions}
              onChange={option => setFromTime(option)} />
          </Col>
        </Row>
        <Row className="align-items-end mt-4">
          <Col sm="4">
            <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
          </Col>
        </Row>
      </CardBody>
    </Card>
    <Card>
      <CardBody>
        <center>
          <span className="fw-bold">Site/Project/Client vs Selenium Cluster</span><br />
          <div className="mb-1">
            <b>NOTE:</b>
            1% of total request is represented here
          </div>
          <a target="_blank" className="link-primary" href={SiteOrProjectOrClientVsSeleniumClusterKibanaUrl()}>Click here to see below graph in kibana</a>
        </center>
        <iframe src={SiteOrProjectOrClientVsSeleniumClusterKibanaUrl() + "&embed=true"} height="500" width="100%" className="border-0"></iframe>
      </CardBody>
    </Card>
  </Container>
}

