import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home, Info } from "react-feather";
import moment from "moment";
import axios from "axios";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";
import AsyncSelect from 'react-select/async';
import Select, { components } from "react-select";
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';

import { KIBANA_PLM_URL } from "../../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function New() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[site]"),
    recrawlGeoCluster: new URLSearchParams(search).get("filter[geo_cluster]") || 'all',
    rssJobType: new URLSearchParams(search).get("filter[rss_job_type]"),
    testRunStatus: new URLSearchParams(search).get("filter[test_run_status]") || 'false',
    fromDate: new URLSearchParams(search).get("filter[crawldate][from]"),
    toDate: new URLSearchParams(search).get("filter[crawldate][to]"),
  }
  const [siteName, setSiteName] = useState({label: '', value: ''});
  const [recrawlGeoCluster, setRecrawlGeoCluster] = useState({label: 'All', value: 'all'});
  const [rssJobType, setRssJobType] = useState({label: 'rss', value: 'rss'});
  const [testRunStatus, setTestRunStatus] = useState('false');
  const [siteNameOptions, setSiteNameOptions] = useState([]);
  const [recrawlGeoClusterOptions, setRecrawlGeoClusterOptions] = useState([]);
  const [rssStatsData, setRssStatsData] = useState([]);
  const now = new Date();
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(2, 'days')));
  const [toDate, setToDate] = useState(now);
  const [loading, setLoading] = useState(false);

  const rssJobTypeOptions = [
    { label: 'rss', value: 'rss' },
    { label: 'drss', value: 'drss' }
  ]

  const tableColumns = [
    { 
      name: 'Site', 
      selector: 'site_name', 
      sortable: true, 
      cell: (row, index) => (
        <>
          <p data-tip data-for={'site-tooltip-' + index}>{row.site_name} <Info size={18} className='mx-2' /></p>
          <ReactTooltip id={'site-tooltip-' + index} clickable multiline delayHide={1000} effect='solid'>
            <a href={siteKibanaUrl(row)} target="_blank" className='text-white '>Kibana Link</a><br />
            <a href="http://alerta.promptcloud.com/#/alerts?status=open" target='_blank' className='text-white'>Alert Link (To Do)</a>
          </ReactTooltip>
        </>
      )
    },
    { name: 'Rss Crawl_Geo', selector: 'rss_crawl_geo', sortable: true },
    { 
      name: 'Date', 
      selector: 'crawldate', 
      sortable: true, 
      cell: (row, index) => (
        <>
          <p data-tip data-for={'crawldate-tooltip-' + index}>{row.crawldate} <Info size={18} className='mx-2' /></p>
          <ReactTooltip id={'crawldate-tooltip-' + index} clickable multiline  delayHide={1000} effect='solid'>
            <a href={dateKibanaUrl(row)} target="_blank" className='text-white'>Kibana Link</a><br />
            <a href="http://alerta.promptcloud.com/#/alerts?status=open" target='_blank' className='text-white'>Alert Link (To Do)</a>
          </ReactTooltip>
        </>
      )
    },
    { 
      name: 'Status', 
      selector: 'rss_crawlstatus', 
      sortable: true, 
      cell: (row, index) => (
        <>
          <p data-tip data-for={'status-tooltip-' + index}>{row.rss_crawlstatus} <Info size={18} className='mx-2' /></p>
          <ReactTooltip id={'status-tooltip-' + index} clickable multiline  delayHide={1000} effect='solid'>
            <span className='text-white'>Crawl begin time: {row.crawlbegintime}</span><br />
            <span className='text-white'>Crawl end time: {row.rss_crawlendtime}</span><br />
            <a href={statusKibanaUrl(row)} target="_blank" className='text-white'>Kibana Link</a><br />
            <a href="http://alerta.promptcloud.com/#/alerts?status=open" target='_blank' className='text-white'>Alert Link (To Do)</a>
          </ReactTooltip>
        </>
      )
    },
    { name: 'Pushed urls', selector: 'pushed_urls_count', sortable: true },
    { name: 'Dropped urls', selector: 'rss_dropped_urls_count', sortable: true },
    { name: 'Drop%', selector: 'drop_percentage', sortable: true },
    { name: 'Crawl Duration(sec.)', selector: 'rss_crawl_duration', sortable: true },
    { name: 'Hostname', selector: 'host_name', sortable: true },
    { name: 'Segmentname', selector: 'segment_name', sortable: true },
    { name: 'Rss job type', selector: 'rss_job_type', sortable: true },
  ]

  function siteKibanaUrl(data) {
    return `${KIBANA_PLM_URL}app/kibana#/discover?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:'${data.kibana_from_date}',to:'${data.kibana_to_date}'))&_a=(columns:!(_source),filters:!(('$state':(store:appState),exists:(field:rss_status_tracker),meta:(alias:!n,disabled:!f,index:'76972760-1ad0-11ec-9735-177ab3888d9e',key:rss_status_tracker,negate:!f,type:exists,value:exists))),index:'76972760-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+${data.site_name}+OR+sitename:+${data.site_name}+OR+site:+${data.site_name})'),sort:!(!('@timestamp',desc)))`
  }

  function dateKibanaUrl(data) {
    return `${KIBANA_PLM_URL}app/kibana#/discover?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:'${data.kibana_from_date}',to:'${data.kibana_to_date}'))&_a=(columns:!(date),filters:!(('$state':(store:appState),exists:(field:rss_status_tracker),meta:(alias:!n,disabled:!f,index:'76972760-1ad0-11ec-9735-177ab3888d9e',key:rss_status_tracker,negate:!f,type:exists,value:exists))),index:'76972760-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+${data.site_name}+OR+sitename:+${data.site_name}+OR+site:+${data.site_name})+and+date:+${data.crawldate}'),sort:!(!('@timestamp',desc)))`
  }

  function statusKibanaUrl(data) {
    return `${KIBANA_PLM_URL}app/kibana#/discover?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:'${data.kibana_from_date}',to:'${data.kibana_to_date}'))&_a=(columns:!(date,current_status),filters:!(('$state':(store:appState),exists:(field:rss_status_tracker),meta:(alias:!n,disabled:!f,index:'76972760-1ad0-11ec-9735-177ab3888d9e',key:rss_status_tracker,negate:!f,type:exists,value:exists))),index:'76972760-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+${data.site_name}+OR+sitename:+${data.site_name}+OR+site:+${data.site_name})+and+date:+${data.crawldate}+and+current_status:+%22${data.rss_crawlstatus}%22'),sort:!(!('@timestamp',desc)))`
  }

  const [UrlParams, setUrlParams] = useState({
    siteName: '',
    recrawlGeoCluster: 'all',
    rssJobType: 'rss',
    testRunStatus: 'false',
    fromDate: new Date(moment().subtract(2, 'days')),
    toDate: now
  })

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let site_names = res.data.site_names, site_names_options = []
        for (var key in site_names) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
            site_names[key].map(item => {
              children.push({ label: item, value: item })
            })
          group['options'] = children;
          site_names_options.push(group)
        }
        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: '', value: '' }] })
        setSiteNameOptions(site_names_options);
        let geo_clusters = []
        res.data.geo_clusters.forEach(item => {
          geo_clusters.push({ label: item, value: item })
        })
        geo_clusters.push({ label: 'All', value: 'all' })
        setRecrawlGeoClusterOptions(geo_clusters);
      })
      .catch(err => {
        toast.error(err?.message || 'Oops something went wrong');
      });
  }, []);

  const promiseOptions = (inputValue) => {
    if(inputValue.length < 3){
      return null
    } else {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200'){
            console.log(res.data)
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
                site_names[key].map(item => {
                  children.push({ label: item, value: item })
                })
              group['options'] = children;
              site_names_options.push(group)
            }
            setSiteNameOptions(site_names_options)
            resolve(site_names_options)
          }
        }).catch(err => {
          toast.error(err?.message || 'Oops something went wrong');
        })
      })
    }
  }

  const handleSiteNameChange = (data) => {
    setSiteName(data)
  }

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.siteName && searchParams.siteName != siteName.value) {
      setSiteName({ label: searchParams.siteName, value: searchParams.siteName });
    }
    if (searchParams.recrawlGeoCluster && searchParams.recrawlGeoCluster != recrawlGeoCluster.value) {
      setRecrawlGeoCluster({ label: searchParams.recrawlGeoCluster  == 'all' ? 'All' :  searchParams.recrawlGeoCluster, value: searchParams.recrawlGeoCluster });
    }
    if (searchParams.rssJobType && searchParams.rssJobType != rssJobType.value) {
      setRssJobType({ label: searchParams.rssJobType, value: searchParams.rssJobType });
    }
    if (searchParams.testRunStatus && searchParams.testRunStatus != testRunStatus) {
      setTestRunStatus(searchParams.testRunStatus);
    }
    if (searchParams.fromDate && searchParams.fromDate != fromDate) {
      setFromDate((searchParams.fromDate == "Invalid date") ? fromDate : new Date(searchParams.fromDate));
    }
    if (searchParams.toDate && searchParams.toDate != toDate) {
      setToDate((searchParams.toDate == "Invalid date") ? toDate : new Date(searchParams.toDate));
    }
    if (commit == 'Get Data' || searchParams.siteName && searchParams.rssJobType && searchParams.fromDate && searchParams.toDate) {
      setUrlParams(searchParams)
      fetchData(searchParams);
    }
  }

  function fetchData(filterParams) {
    setLoading(true)
    let from_date = moment(filterParams.fromDate).format('YYYY/MM/DD') == "Invalid date" ? moment(fromDate).format('YYYY/MM/DD') : moment(filterParams.fromDate).format('YYYY/MM/DD')
    let to_date = moment(filterParams.toDate).format('YYYY/MM/DD') == "Invalid date" ? moment(toDate).format('YYYY/MM/DD') : moment(filterParams.toDate).format('YYYY/MM/DD')
    axios.get(`/api/v1/internal/segment_level_rss_stats?filter[site_name]=${filterParams.siteName}&filter[geo_cluster]=${filterParams.recrawlGeoCluster}&filter[rss_job_type]=${filterParams.rssJobType}&filter[from_date]=${from_date}&filter[to_date]=${to_date}&filter[test_run_status]=${filterParams.testRunStatus}`)
      .then(res => {
        setRssStatsData(res.data.rss_stats_data);
        setLoading(false);
      })
      .catch(err => {
        toast.error(err?.response?.data?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  const handleGetData = () => {
    let from_date = moment(fromDate).format('YYYY/MM/DD') == "Invalid date" ? new Date(moment().subtract(2, 'days')).format('YYYY/MM/DD') : moment(fromDate).format('YYYY/MM/DD')
    let to_date = moment(toDate).format('YYYY/MM/DD') == "Invalid date" ? moment().format('YYYY/MM/DD') : moment(toDate).format('YYYY/MM/DD')
    window.location.href = `/internal/rss?filter[site]=${siteName.value}&filter[geo_cluster]=${recrawlGeoCluster.value}&filter[test_run_status]=${testRunStatus}&filter[rss_job_type]=${rssJobType.value}&filter[crawldate][from]=${from_date}&filter[crawldate][to]=${to_date}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/rss/new">Segment Level RSS Stats</a> : 'Segment Level RSS Stats'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
          </BreadcrumbItem>}
        </Breadcrumb>
      </Col>
    </Row>
  </div>

  <Card>
    <CardBody>
      <Row>
        <div className="col-sm-8">
          <label className="fw-bold">Site Name</label>
          <AsyncSelect cacheOptions defaultOptions={siteNameOptions} name='site_name' value={siteName}
            loadOptions={promiseOptions} onChange={handleSiteNameChange}
            placeholder="Select a site name" required="true"
          />
          <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
        </div>
        <Col sm="4">
          <label className="fw-bold">Recrawl Geo Cluster</label>
          <Select
            value={recrawlGeoCluster}
            options={recrawlGeoClusterOptions}
            onChange={option => setRecrawlGeoCluster(option)} />
        </Col>
      </Row>
      <Row className="align-items-end mt-2">
        <Col sm="3">
          <label className="fw-bold">From Date</label>
          <DatePicker
            className="form-control"
            selected={new Date(fromDate)}
            dateFormat={"YYY/MM/dd"}
            maxDate={new Date()}
            onChange={date => setFromDate(date)} />
        </Col>
        <Col sm="3">
          <label className="fw-bold">To Date</label>
          <DatePicker
            className="form-control"
            selected={new Date(toDate)}
            dateFormat={"YYY/MM/dd"}
            maxDate={new Date()}
            onChange={date => setToDate(date)} />
        </Col>
        <Col sm="2">
          <input checked={testRunStatus == "true" ? true : false} onChange={() => setTestRunStatus(testRunStatus == "true" ? "false" : "true")} type={'checkbox'} name='test_run_status' /> Test Run Status
        </Col>
        <Col sm="2">
          <label className="fw-bold">Rss job type</label>
          <Select
            value={rssJobType}
            options={rssJobTypeOptions}
            onChange={option => setRssJobType(option)} />
        </Col>
        <Col sm="2">
          <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
        </Col>
      </Row>
    </CardBody>
  </Card>
  {
    UrlParams.siteName ? 
    <>
      <Card>
        <CardBody>
          {
            rssStatsData.length > 0 ? 
            <>
              <div className="mb-1">
                <b>NOTE:</b>
                <Info size={18} className='mx-2'/> indicates the hover is there. It has some informations or links.
              </div>
              <Row className="align-items-end mt-2">
                <DataTable
                  columns={tableColumns}
                  data={rssStatsData}
                  noHeader
                  pagination
                  progressPending={loading}
                />
              </Row>
            </>
              : "No data available"
          }
        </CardBody>
      </Card>
    </>
      : ""
  }
</Container>
}

