/**
 * THESE ARE THE STATES  WHICH ARE SHOWN IN THE OPTIONS FOR BILLING DETIALS FOR THE USER
 */

export const IndianStates = [
    {
        label: "AD",
        state: "Andhra Pradesh",
    },
    {
        label: "AD",
        state: "Andhra Pradesh (New)",
    },
    {
        label: "AR",
        state: "Arunachal Pradesh",
    },
    {
        label: "AS",
        state: "Assam",
    },
    {
        label: "BH",
        state: "Bihar",
    },
    {
        label: "CH",
        state: "Chandigarh",
    },
    {
        label: "CT",
        state: "Chattisgarh",
    },
    {
        label: "DN",
        state: "Dadra and Nagar Haveli",
    },
    {
        label: "DD",
        state: "Daman and Diu",
    },
    {
        label: "DL",
        state: "Delhi",
    },
    {
        label: "GA",
        state: "Goa",
    },
    {
        label: "GJ",
        state: "Gujrat",
    },
    {
        label: "HR",
        state: "Haryana",
    },
    {
        label: "HP",
        state: "Himachal Pradesh",
    },
    {
        label: "JK",
        state: "Jammu and Kashmir",
    },
    {
        label: "JH",
        state: "Jharkhand",
    },
    {
        label: "KA",
        state: "Karnataka",
    },
    {
        label: "KL",
        state: "Kerala",
    },
    {
        label: "LD",
        state: "Lakshadweep Islands",
    },
    {
        label: "MP",
        state: "Madhya Pradesh",
    },
    {
        label: "MH",
        state: "Maharashtra",
    },
    {
        label: "MN",
        state: "Manipur",
    },
    {
        label: "ME",
        state: "Meghalaya",
    },
    {
        label: "MI",
        state: "Mizoram",
    },
    {
        label: "NL",
        state: "Nagaland",
    },
    {
        label: "OR",
        state: "Odisha",
    },
    {
        label: "PY",
        state: "Pondicherry",
    },
    {
        label: "PB",
        state: "Punjab",
    },
    {
        label: "RJ",
        state: "Rajasthan",
    },
    {
        label: "SK",
        state: "Sikkim",
    },
    {
        label: "TN",
        state: "Tamil Nadu",
    },
    {
        label: "TS",
        state: "Telangana",
    },
    {
        label: "TR",
        state: "Tripura",
    },
    {
        label: "UP",
        state: "Uttar Pradesh",
    },
    {
        label: "UT",
        state: "Uttrakhand",
    },
    {
        label: "WB",
        state: "West Bengal",
    },
];

/** DO NOT DELETE THE LINES BELOW!! */
/**
 * <option state="AP">Andhra Pradesh</option>
<option state="AD">Andhra Pradesh (New)</option>
<option state="AR">Arunachal Pradesh</option>
<option state="AS">Assam</option>
<option state="BH">Bihar</option>
<option state="CH">Chandigarh</option>
<option state="CT">Chattisgarh</option>
<option state="DN">Dadra and Nagar Haveli</option>
<option state="DD">Daman and Diu</option>
<option state="DL">Delhi</option>
<option state="GA">Goa</option>
<option state="GJ">Gujarat</option>
<option state="HR">Haryana</option>
<option state="HP">Himachal Pradesh</option>
<option state="JK">Jammu and Kashmir</option>
<option state="JH">Jharkhand</option>
<option state="KA">Karnataka</option>
<option state="KL">Kerala</option>
<option state="LD">Lakshadweep Islands</option>
<option state="MP">Madhya Pradesh</option>
<option state="MH">Maharashtra</option>
<option state="MN">Manipur</option>
<option state="ME">Meghalaya</option>
<option state="MI">Mizoram</option>
<option state="NL">Nagaland</option>
<option state="OR">Odisha</option>
<option state="PY">Pondicherry</option>
<option state="PB">Punjab</option>
<option state="RJ">Rajasthan</option>
<option state="SK">Sikkim</option>
<option state="TN">Tamil Nadu</option>
<option state="TS">Telangana</option>
<option state="TR">Tripura</option>
<option state="UP">Uttar Pradesh</option>
<option state="UT">Uttarakhand</option>
<option state="WB">West Bengal</option>
 */