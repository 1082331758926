import React, {useState} from 'react'
import { CardBody, Col, Card, Row, CardHeader } from 'reactstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { CheckSquare, Clipboard } from 'react-feather'

export default function OrganizationCard({each}) {
    const [val, setVal] = useState(each?.api_link)
    const [copied, setCopied] = useState(false)
    return (
        <Card>
            <CardHeader>
                <div className='d-flex align-center'>
                    <h3 className='mx-2 my-0'>{each?.org_name}</h3> | 
                    <h6 className='mx-2 my-0'>{each?.org_identifier}</h6> | 
                    <h6 className='mx-2 my-0'>{each?.status}</h6> | 
                    <h6 className='mx-2 my-0'>{each?.country_name}</h6>
                </div>

                <div className='d-flex my-3'>
                    <a href={each?.organization_edit_link} className='btn text-white mx-1 btn-xs btn-success'>
                        Organization edit page</a>
                    <a href={each?.organization_home_link} className='btn text-white mx-1 btn-xs btn-primary'>
                        Organization Home page</a>
                    <a href={`/organizations/${each?.org_identifier}/organization_show`} 
                        className='btn text-white mx-1 btn-xs btn-info'>
                        Admin page</a>
                    <a href={each?.invoices_link} className='btn text-white mx-1 btn-xs btn-warning'>
                        Invoices</a>
                    <a href={each?.sites_link} className='btn text-white mx-1 btn-xs btn-danger'>
                        Sites page</a>
                    <a href={each?.manage_users_link} className='btn text-white mx-1 btn-xs btn-primary'>
                        Manage Users</a>
                    <a href={each?.api_link} className='btn text-white mx-1 btn-xs btn-success'>
                        API Link</a>
                    <CopyToClipboard text={val} onCopy={() => setCopied(true)}>
                        {copied ? <button className='btn btn-xs btn-success'><CheckSquare size={11} /></button> 
                            : <button className='btn btn-xs btn-outline-success' ><Clipboard size={11} /></button>}
                    </CopyToClipboard>
                </div>

                <Row className='my-3'>
                    <Col md={6}>
                        <h6 className='fw-bold'>Users</h6>
                        {each?.user_emails?.map((each2, index) => (
                            <p className='fw-bold my-0' key={index}> {each2} </p>
                        ))}
                    </Col>
                    <Col md={6}>
                        <h6 className='fw-bold'>Promptcloud Contacts</h6>
                        <h6><b>Primary Contact: </b> {each?.primary_contact} </h6>
                        <h6><b>Secondary Contact: </b> {each?.secondary_contact} </h6>
                        <h6><b>Business Contact: </b> {each?.biz_contact} </h6>
                    </Col>
                </Row>
            </CardHeader>

            <CardBody>
                <h6 className='fw-bold'>Sitegroups And Sites</h6>

                {each?.projects_and_sites?.map((each2, index) => (
                    <div key={index} className='my-2'>
                        <p className='fw-bold'>{each2.title}</p>
                        <div className='bg-light p-4'>
                            {each2?.sites.map((each3, index2) => (
                                <p className='my-0 text-dark' key={index2}>
                                    {each3}
                                </p>
                            ))}
                        </div>
                    </div>
                ))}
            </CardBody>
        </Card>
    )
}
