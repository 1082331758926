/***************************************************************************************************
 *              This componet displays the paid or pending invoices for the clients                *
 *                                      This is a client page                                      *
 *  CREATED BY: Aniket Atram                                                                       *
 *  Updated ON: 6 April 2023                                                                       *
 *                                                                                                 *
 *  Description of update: Added a conditon to check if the invoice is of cbv3 app or not          *
 *  If the invoice belongs to JobsPikr or Datastock then user is redirected to invoice list page   *
 *  The status code retun for it is 422                                                            *
 **************************************************************************************************/
// React imports
import React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Third party imports
import { Container, Row, Col, Alert } from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty'

// Components and script imports
import SpinnerLoader from '../../../Common/LoadingAnimation/SpinnerLoader.component';
import Breadcrumbs from '../../../Common/BreadCrumb/BreadCrumbs.component';
import SuperUserContext from '../../../Context/Providers/SuperUserPrivilege/SuperUserContext.component';
import PaidInvoice from './PaidInvoice.component';
import PendingInvoice from './PendingInvoice.component';

export default function InvoiceDetails({ razorpayKey, currentUser }) {

  const [loading, setLoading] = useState(false); // used for displaying the loading animation
  const [invoiceData, setInvoiceData] = useState([]); // used for storing data from DB
  const [toggle, setToggle] = useState(false);

  // Organization name and invoice ID from URL
  const { organization_name, invoiceId } = useParams();

  const history = useHistory();

  // INVOICE API ENDPOINT
  const INVOICE_API = useMemo(() => `/api/v1/organizations/${organization_name}/invoices/${invoiceId}?page_type=client&controller_name=invoices`, [organization_name, invoiceId]);

  // PAYMENT API ENDPOINT
  const PAYMENT_API = useMemo(() => `/api/v1/organizations/${organization_name}/invoices/${invoiceId}/payments?page_type=client&controller_name=new_payments`, [organization_name, invoiceId]);

  // Use-effect hook for whenever the user initiates the payment
  useEffect(async () => {

    const controller = new AbortController();
    setLoading(true);
    await fetchInvoiceDataFromDB(INVOICE_API, controller.signal);
    setLoading(false);
    // cleanup
    return () => {
      controller.abort();
    }
  }, [toggle]);


  // Helper function to fetch data from the Database
  async function fetchInvoiceDataFromDB(baseURI, signal) {
    try {
      const response = await axios.get(INVOICE_API, { signal: signal });
      const data = await response?.data?.invoice;
      setInvoiceData(data);
    }
    catch (error) {
      if(error?.response?.status === 422){
        toast?.error(error?.response?.data?.message);
        history.push(`/organizations/${organization_name}/invoices`);
      }
      else if(error?.response?.status === 401){
        toast.error("You are not authorized to access this page!");
        history.push("/");
      }
      else{
        toast?.error(error?.response?.data?.message);
      }
    }
  };


  return (
    <>
      <div className='container container-fluid'>
        <SuperUserContext>
          <SpinnerLoader loading={isEmpty(invoiceData) && loading}>
            <Breadcrumbs
              parent={"Invoices"}
              title={`Invoice #${invoiceData?.id}`}
              childrenObj={[{ name: 'Invoices', url: `/organizations/${organization_name}/invoices` }]} />
            {
              invoiceData?.invoice_status === 'pending' ? (
                <PendingInvoice
                  data={invoiceData}
                  invoiceId={invoiceId}
                  organization_name={organization_name}
                  setToggle={setToggle}
                  setLoading={setLoading}
                  razorpayKey={razorpayKey} />) :
                (<PaidInvoice
                  invoiceData={invoiceData}
                  organization_name={organization_name}
                  invoiceId={invoiceId}
                  loading={loading}
                />)

            }
          </SpinnerLoader>
        </SuperUserContext>
      </div>
    </>
  )
}
