import React, { useState, Fragment } from 'react'
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';


export default function UploadFileModal(props) {

  const { show, onHide, trigger, siteName, inputFilePath } = props

  const [selectedFile, setSelectedFile] = useState(null);

  const UPLOAD_HTML_FILE_API_ENDPOINT = `/api/v1/internal/chat_gpt_xpath_result_page/upload_file`

  // Helper function to upload html file and store it in state
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Check if a file is selected
    if (file) {
      // Check file size (maxSize in bytes)
      const maxSize = 10 * 1024 * 1024; // 10 MB
      if (file.size > maxSize) {
        alert('File size exceeds the maximum limit (10 MB). Please choose a smaller file.');
        event.target.value = null; // Clear the input field
        setSelectedFile(null);
        return;
      }

      // File is within the size limit
      setSelectedFile(file);
    }
  }

  const handleFileUpload = async () => {
    if (!selectedFile) {
      console.error('No file selected');
      return;
    }

    try {
      const fileContent = await readFileContent(selectedFile);
      const response = await uploadFile(fileContent);
      toast.success(response?.data?.message)
    } catch (error) {
      toast.error(error?.response?.message);
    }
  };

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsText(file);
    });
  };

  const uploadFile = async (htmlContent) => {
    const responseBody = {
      filters: {
        site_name: siteName.value,
        input_file_name: selectedFile.name,
        input_file: htmlContent
      }
    }
    try {
      const response = await axios.post(UPLOAD_HTML_FILE_API_ENDPOINT, responseBody)
      toast.success(response?.data?.message)
      trigger(false)
    }
    catch (error) {
      console.log(selectedFile)
      toast.error(error?.response?.message)
    }
  };

  return (
    <Fragment>
      <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Upload A HTML File
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              type="file"
              id="fileInput"
              accept=".html"
              onChange={handleFileChange}
            />
            {selectedFile && (
              <p>Selected File: {selectedFile.name}</p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='success'
            onClick={() => handleFileUpload()}
            disabled={selectedFile ? false : true}>
            Upload
          </Button>
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
}
