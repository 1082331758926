import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { TabContent, TabPane } from 'reactstrap';
import OverallStatsTab from '../Tabs/OverallStatsTab.component';
import ConfigRunningWaitingJobsTab from '../Tabs/ConfigRunningWaitingJobsTab.component';
import AverageTimeTab from '../Tabs/AverageTimeTab.component';

export default function UploadsInfoCard({ data, siteName, tab, selectTab, fromDate, endDate,setFromDate, setEndDate, setSelectedSite, defaultOptions, promiseOptions, selectedSite, ButtonClickHandler}) {
  return (
    <>
      <Card className='my-3'>
        <CardBody>
          <Row>
            <Col md={9}>
              <h5 className='fw-lighter'>Site Info: {siteName} </h5>
            </Col>
            <Col md={3} className='d-flex justify-content-center'>
              <h6 className='fw-bold'> Site setup ticket: &nbsp;</h6>
              <a href={`https://redmine.promptcloud.com/issues/${data?.redmine_ticket_id}`} className='fw-bold'>
                {data?.redmine_ticket_id} </a>
            </Col>
            <Col md={12} className='mt-4'>
              <Nav tabs className="border-tab">
                <NavItem><NavLink className={tab === "overallStatsTab" ? "active" : ''}
                  onClick={() => selectTab("overallStatsTab")} style={{ fontSize: 12 }} > Overall Stats </NavLink></NavItem>
                <NavItem><NavLink className={tab === "configRunningAndWaitingJobsTab" ? "active" : ''}
                  onClick={() => selectTab("configRunningAndWaitingJobsTab")} style={{ fontSize: 12 }} > Configured, Running and Waiting jobs </NavLink></NavItem>
                <NavItem><NavLink className={tab === "avgTimeTab" ? "active" : ''}
                  onClick={() => selectTab("avgTimeTab")} style={{ fontSize: 12 }} > Average Times </NavLink></NavItem>
              </Nav>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div>
        <TabContent activeTab={tab}>
          <TabPane tabId={"overallStatsTab"}>
            <OverallStatsTab 
            data={data}
            siteName={siteName}
            fromDate={fromDate}
            endDate={endDate}
            setFromDate={setFromDate}
            setEndDate={setEndDate}
            selectedSite={selectedSite}
            setSelectedSite={setSelectedSite}
            defaultOptions={defaultOptions}
            promiseOptions={promiseOptions}
            ButtonClickHandler={ButtonClickHandler}
            />
          </TabPane>
          <TabPane tabId={"configRunningAndWaitingJobsTab"}>
            <ConfigRunningWaitingJobsTab
              data={data}
              siteValue={siteName}
              fromDate={fromDate}
              endDate={endDate}
            />
          </TabPane>
          <TabPane tabId={"avgTimeTab"}>
            <AverageTimeTab
            data={data}
            siteValue={siteName}
            />
          </TabPane>
        </TabContent>
      </div>
    </>
  )
}
