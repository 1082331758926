import React, {useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import Breadcrumb from '../../global/Breadcrumb';
import StepZilla from "react-stepzilla";
import { Card, CardHeader, CardBody, Row, Col, Table } from 'reactstrap';
import axios from 'axios'
import DataTable from 'react-data-table-component'
import Feasibility_SchemaDetails from './Feasibility_SchemaDetails';
import Feasibility_SiteDetails from './Feasibility_SiteDetails';
import { toast } from 'react-toastify';
import Loader from "react-loader-spinner";

export default function EachFeasibilityCheck() {
    const [data, setData] = useState({})
    const [displayData, setDisplayData] = useState({})
    const [dataToggle, setDataToggle] = useState(true)
    const [globalTableData, setGlobalTableData] = useState({})
    const [dataToggle2, setDataToggle2] = useState(false)
    const [v2Data, setV2Data] = useState({})
    let { feasid } = useParams();

    useEffect(() => {
        axios.get(`/api/v1/admins/feasibility_checks/${feasid}`).then(res => {
            setDisplayData(res.data.feasibility_report)
        }).catch(err => {
            toast.error(err.response.data.message)
        })
    }, [dataToggle, dataToggle2])

    useEffect(() => {
        axios.get(`/api/v1/admins/feasibility_checks/${feasid}/show_form`).then(res => {
            setData(res.data.feasibility_report)
        }).catch(err => {
            toast.error(err.response.data.message)
        })
    }, [dataToggle, dataToggle2])

    const steps =
        [
            { name: 'Schema Details', 
                component: <Feasibility_SchemaDetails data={data} 
                v2Data={v2Data} setV2Data={setV2Data} dataDisable={false}
                   setDataToggle={setDataToggle} globalTableData={globalTableData}
                   dataToggle={dataToggle} setGlobalTableData={setGlobalTableData} /> },
            { name: 'Site Details', component: <Feasibility_SiteDetails data={data} 
               setDataToggle={setDataToggle} dataToggle={dataToggle} setDataToggle2={setDataToggle2}
               v2Data={v2Data} setV2Data={setV2Data} dataDisable={false}
               globalTableData={globalTableData} setGlobalTableData={setGlobalTableData} /> },
    ]

    const siteColumns = [
        {name: 'Sample URL',selector: 'sample_url',sortable: true,center:true,},
        {name: 'Site Status',selector: 'site_status',sortable: true,center:true,},
        {name: 'Site Setup Fee',selector: 'site_setup_fee',sortable: true,center:true,},
        {name: 'Monthly Maintenance Fee',selector: 'monthly_maintenance_fee',sortable: true,center:true,},
        {name: 'Volume Charges',selector: 'volume_charges',sortable: true,center:true,},
        {name: 'No.of Free Records',selector: 'number_of_free_records',sortable: true,center:true,},
    ]

    return (
        <div className='container container-fluid pt-2'>
            <Breadcrumb parent={"Feasibility Check"} title={feasid}
            childrenObj={[{name: 'Feasibility Checks', url: '/admins/feasibility_checks'}]} />
            
            {"id" in data ?
                ( data.feasibility_check_status === "under_review" ? 
                (<div>
                    <Card className='p-5'>
                        <StepZilla steps={steps} showSteps={true} 
                            showNavigation={true} stepsNavigation={true}
                            prevBtnOnLastStep={true} dontValidate={false} />
                    </Card>
                </div>)
                : (
                    <Card>
                        <CardHeader className='p-4'>
                            <h6 className='fw-bold m-0'>Site Requirements</h6>
                        </CardHeader>
                        <CardBody>
                            <h4 className='fw-lighter'>
                                Sites belonging to this Feasibility Check
                            </h4>
                            <div></div>
                            <div className='my-5'>
                                <h6 className='fw-bold'>Site Details</h6>
                                <DataTable columns={siteColumns} noHeader responsive
                                    data={displayData?.sites_of_feasibility_report} />
                            </div>

                            {displayData?.sitegroup_details?.unpaid_feasibility_reports.length > 0 && 
                            <div className='my-5'>
                                <h6 className='fw-bold'>Feasibility Report for this Feasibility Check</h6>
                                <Table responsive bordered>
                                    <thead>
                                        <tr>
                                            <th className='fw-bold'>
                                                # Sites
                                            </th>
                                            <th className='fw-bold'>
                                                Overall Feasibility
                                            </th>
                                            <th className='fw-bold'>
                                                Created By
                                            </th>
                                            <th className='fw-bold'>
                                                # Viewed
                                            </th>
                                            <th className='fw-bold'>
                                                Accepted By
                                            </th>
                                            <th className='fw-bold'>
                                                Current Status
                                            </th>
                                            <th className='fw-bold'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayData?.sitegroup_details?.unpaid_feasibility_reports.map((each, index) => (
                                            <tr key={index}>
                                                <td>{each?.sites_count}</td>
                                                <td>{each?.overall_feasibility}</td>
                                                <td>{each?.created_by}</td>
                                                <td>{each?.viewed_count}</td>
                                                <td>{each?.accepted_by}</td>
                                                <td>{each?.client_acceptance_status}</td>
                                                <td>
                                                    <a href={`/organizations/${data.organization_identifier}/feasibility_reports/${feasid}`} 
                                                        className='btn btn-xs btn-success mx-2 text-white'> View </a>
                                                    {each?.editable && <a href={`/admins/feasibility_checks/${feasid}/edit`} 
                                                        className='btn btn-xs btn-primary mx-2'> Edit </a>}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>}

                            <div className='my-4'>
                                <h4 className='fw-lighter mb-4'>
                                    Sitegroup Full Details
                                </h4>
                                <Row className='my-2'>
                                    <Col lg={4}>
                                        <h6 className='fw-bold'>Sitegroup Name</h6>
                                        <h6>
                                            {displayData?.sitegroup_details?.sitegroup_name}
                                        </h6>
                                    </Col>
                                    <Col lg={4}>
                                        <h6 className='fw-bold'>Additional Information</h6>
                                        <h6>
                                            {displayData?.sitegroup_details?.additional_information}
                                        </h6>
                                    </Col>
                                    <Col lg={4}>
                                        <h6 className='fw-bold'>Sitegroup Status</h6>
                                        <h6 className='text-capitalize'>
                                            {displayData?.sitegroup_details?.sitegroup_status}
                                        </h6>
                                    </Col>
                                </Row>
                                
                                {displayData?.sitegroup_details?.schema_fields_details ? <>
                                    <h6 className='fw-bold mt-5 mb-3'>
                                        Schema Details
                                    </h6>
                                    <Table responsive bordered>
                                        <thead>
                                            <tr>
                                                <th className='fw-bold'>Field Name</th>
                                                <th className='fw-bold'>Data Type</th>
                                                <th className='fw-bold'>Is Mandatory</th>
                                                <th className='fw-bold'>Default Value</th>
                                                <th className='fw-bold'>Sample Value</th>
                                                <th className='fw-bold'>Comments</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {displayData?.sitegroup_details?.schema_fields_details?.map(each => {
                                                return (<tr key={each.id}>
                                                    <td>
                                                        {each?.field_name}
                                                    </td>
                                                    <td>
                                                        {each?.data_type}
                                                    </td>
                                                    <td>
                                                        {each?.is_mandatory}
                                                    </td>
                                                    <td>
                                                        {each?.default_value}
                                                    </td>
                                                    <td>
                                                        {each?.sample_value}
                                                    </td>
                                                    <td>
                                                        {each?.comments}
                                                    </td>
                                                </tr>)
                                            })}
                                        </tbody>
                                    </Table>
                                </> : 
                                <>
                                    <h6 className='fw-bold mt-5 mb-3'>
                                        Fields to extract
                                    </h6>
                                    <textarea rows='3' disabled className='form-control my-3'
                                        value={displayData?.sitegroup_details?.fields_to_extract} />
                                </>}

                                <div className='my-4'>
                                    <h6 className='fw-bold'>Site Details</h6>
                                    <DataTable columns={siteColumns} noHeader responsive
                                        data={displayData?.sitegroup_details?.sites_of_sitegroup} />
                                </div>
                            </div>

                            <div className='my-4'>
                                <Row className='my-2'>
                                    <Col lg={6} className='p-2'>
                                        <h6 className='fw-bold'>Delivery Details</h6>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Crawl Type
                                                    </td>
                                                    <td className='fw-bold text-capitalize'>
                                                        {displayData?.sitegroup_details?.crawl_type}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Output Format
                                                    </td>
                                                    <td className='fw-bold text-capitalize'>
                                                        {displayData?.sitegroup_details?.delivery_format}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Frequency
                                                    </td>
                                                    <td className='fw-bold text-capitalize'>
                                                        {displayData.sitegroup_details?.frequency === "other_frequency" ?
                                                            `${displayData.sitegroup_details?.frequency} (${displayData.sitegroup_details?.delivery_method_details})` 
                                                            : displayData.sitegroup_details?.frequency}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Delivery Method
                                                    </td>
                                                    <td className='fw-bold text-capitalize'>
                                                        {displayData?.sitegroup_details?.delivery_method}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Creator
                                                    </td>
                                                    <td className='fw-bold text-capitalize'>
                                                        {displayData?.sitegroup_details?.creator}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                    <Col lg={6} className='p-2'>
                                        <h6 className='fw-bold'>Additional Details</h6>
                                        <Table>
                                            <tbody>
                                                { displayData?.sitegroup_details?.additional_details?.map((each, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {each?.title}
                                                        </td>
                                                        <td>
                                                            {each?.status}
                                                        </td>
                                                        <td>
                                                            {each?.fee}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </div>

                            <div className='my-4'>
                                <Row>
                                    <Col lg={3}>
                                        <p className='fw-bold'>
                                            Site Setup Cost
                                        </p>
                                        <input className='form-control' disabled onChange={(e) => console.log()}
                                            value={displayData?.sitegroup_details?.site_setup_fee} />
                                    </Col>
                                    <Col lg={3}>
                                        <p className='fw-bold'>
                                            Maintenance Cost
                                        </p>
                                        <input className='form-control' disabled onChange={(e) => console.log()}
                                            value={displayData?.sitegroup_details?.monthly_maintenance_fee} />
                                    </Col>
                                    <Col lg={3}>
                                        <p className='fw-bold'>
                                            Volume Costs
                                        </p>
                                        <input className='form-control' disabled onChange={(e) => console.log()}
                                            value={displayData?.sitegroup_details?.volume_charges} />
                                    </Col>
                                    <Col lg={3}>
                                        <p className='fw-bold'>
                                            Any Additional Cost
                                        </p>
                                        <input className='form-control' disabled onChange={(e) => console.log()}
                                            value={displayData?.sitegroup_details?.any_additional_cost} />
                                    </Col>
                                </Row>
                            </div>

                            {displayData?.sitegroup_details?.unpaid_feasibility_reports.length > 0 && 
                            <div className='my-5'>
                                <h6 className='fw-bold'>Unpaid Feasibility Reports</h6>
                                <Table responsive bordered>
                                    <thead>
                                        <tr>
                                            <th className='fw-bold'>
                                                # Sites
                                            </th>
                                            <th className='fw-bold'>
                                                Overall Feasibility
                                            </th>
                                            <th className='fw-bold'>
                                                Created By
                                            </th>
                                            <th className='fw-bold'>
                                                # Viewed
                                            </th>
                                            <th className='fw-bold'>
                                                Accepted By
                                            </th>
                                            <th className='fw-bold'>
                                                Current Status
                                            </th>
                                            <th className='fw-bold'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayData?.sitegroup_details?.unpaid_feasibility_reports.map((each, index) => (
                                            <tr key={index}>
                                                <td>{each?.sites_count}</td>
                                                <td>{each?.overall_feasibility}</td>
                                                <td>{each?.created_by}</td>
                                                <td>{each?.viewed_count}</td>
                                                <td>{each?.accepted_by}</td>
                                                <td>{each?.client_acceptance_status}</td>
                                                <td>
                                                    <a href={`#`} className='btn btn-xs btn-success mx-2 text-white'>View</a>
                                                    {each?.editable && <a href={`/admins/feasibility_checks/${feasid}/edit`} className='btn btn-xs btn-primary mx-2'>Edit </a>}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>}
                        </CardBody>
                    </Card>
                ))
                : <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />}
        </div>
    )
}
