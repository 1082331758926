import React, {useState, useEffect} from 'react'
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import InternalDashboardSidebar from './sidebar/InternalDashboardSidebar';
import InternalDashboardHeader from './sidebar/InternalDashboardHeader';
import { useHistory } from "react-router-dom";
import axios from 'axios'
import { toast } from 'react-toastify';
import JobspikrMainQa from './jobspikr-v3-internal/jobspikr-graphs/JobspikrMainQa';
import JpImportantFilters from './jobspikr-v3-internal/jobspikr-graphs/JpImportantFilters';
import JpIndexingErrors from './jobspikr-v3-internal/jobspikr-graphs/JpIndexingErrors';
import JpInferringErrors from './jobspikr-v3-internal/jobspikr-graphs/JpInferringErrors';
import JpIndexProgress from './jobspikr-v3-internal/jobspikr-graphs/JpIndexProgress';
import JpInferredLocationErrorBySite from './jobspikr-v3-internal/jobspikr-graphs/JpInferredLocationErrorBySite';
import DedupTracker from './jobspikr-v3-internal/jobspikr-graphs/DedupTracker';
import Countries30dVs7d from './jobspikr-v3-internal/jobspikr-graphs/Countries30dVs7d';
import Domains7dVs2d from './jobspikr-v3-internal/jobspikr-graphs/Domains7dVs2d';
import UploadStatsOfJpSites from './jobspikr-v3-internal/jobspikr-graphs/UploadStatsOfJpSites';
import UploadCountVsIndexCountOfJpSites from './jobspikr-v3-internal/jobspikr-graphs/UploadCountVsIndexCountOfJpSites';
import CountDataOfMostUsedSites from './jobspikr-v3-internal/jobspikr-graphs/CountDataOfMostUsedSites';
import VolumeOfListingsFromTopCountries from './jobspikr-v3-internal/jobspikr-graphs/VolumeOfListingFromTopCountries';
// import RandomRecordsFromQA from './jobspikr-v3-internal/jobspikr-graphs/RandomRecordsForQA';
import FreshnessCheckStats from './jobspikr-v3-internal/jobspikr-graphs/FreshnessCheckStats';

/* es page routes*/
import V2UploadStatsOfJpSites from './v2/jobspikr-v3-internal/jobspikr-graphs/UploadStatsOfJpSites';
import V2CountDataOfMostUsedSites from './v2/jobspikr-v3-internal/jobspikr-graphs/CountDataOfMostUsedSites';
import V2UploadCountVsIndexCountOfJpSites from './v2/jobspikr-v3-internal/jobspikr-graphs/UploadCountVsIndexCountOfJpSites';
import V2DedupTracker from './v2/jobspikr-v3-internal/jobspikr-graphs/DedupTracker/DedupTracker.index';
import HundredRandomRecordsForQA from '../V2/InternalDashboardPages/JobsPikrV3InternalDashboard/InternalQALinks/HundredRandomRecordsForQA/HundredRandomRecordsForQA.component';

export default function JpInternalDashboardRoutes() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState({})

  const token = localStorage.getItem("authToken");
  const email = localStorage.getItem("authEmail");

  useEffect(() => {
    setLoading(true)
    axios.get(`/api/v1/user/details?page_type=internal&controller_name=dashboard&email=${email}`).then(res => {
      setUser(res.data.user)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      if (err.response.data.message === "User is not present!"){
        handleLogout()
      }
      else if (err.response.data.message === "Authorization token has expired"){
        handleLogout()
      }
      else if (err.response.status == 500){
        history.push("/server-error")
      }
    })
  }, [email])    

  const handleLogout = () => {
    sessionStorage.setItem('redirectUrl', window.location.href)
    axios.delete(`/api/v1/users/logout?page_type=internal&controller_name=sessions`, {
      user: {
        email: localStorage.getItem("authEmail"),
        authenticity_token: localStorage.getItem("authToken")
      }
    }).then(res => {
      toast.success(res.data?.message)
    }).catch(err => {
      console.log('err', err);
    })
    localStorage.removeItem('authEmail'); 
    localStorage.removeItem('authToken');
    localStorage.removeItem('user_name');
    history.push("/users/sign_in")
  }

  useEffect(() => {
    if (user?.is_admin === false){
      history.push('/')
    }
  }, [user])

  return (
    <div className="page-wrapper compact-wrapper" id="pageWrapper">
      {!loading && <InternalDashboardHeader />}
      {loading ? <Loader type="Puff" color="#00BFFF" height={100} width={100} className='pc-loader' />
      : <div className="page-body-wrapper">
        <InternalDashboardSidebar />
        <div className="page-body">
          <Switch>
            <Route path={`${path}/jobspikr_graphs/jp_main_qa`} component={JobspikrMainQa} exact />             
            <Route path={`${path}/jobspikr_graphs/jp_imp_filters`} component={JpImportantFilters} exact />             
            <Route path={`${path}/jobspikr_graphs/jp_indexing_errors`} component={JpIndexingErrors} exact />             
            <Route path={`${path}/jobspikr_graphs/jp_inferring_errors`} component={JpInferringErrors} exact />             
            <Route path={`${path}/jobspikr_graphs/jp_index_progress`} component={JpIndexProgress} exact />             
            <Route path={`${path}/jobspikr_graphs/jp_inferred_location_error_by_site`} component={JpInferredLocationErrorBySite} exact />             
            <Route path={`${path}/mysql/jobspikr_graphs/dedup_tracker`} component={DedupTracker} exact /> 
            <Route path={`${path}/jobspikr_graphs/countries_30d_vs_7d`} component={Countries30dVs7d} exact />             
            <Route path={`${path}/jobspikr_graphs/domains_7d_vs_2d`} component={Domains7dVs2d} exact />             
            <Route path={`${path}/mysql/jobspikr_graphs/upload_stats_of_jp_sites`} component={UploadStatsOfJpSites} exact />             
            <Route path={`${path}/jobspikr_graphs/domains_7d_vs_2d`} component={Domains7dVs2d} exact />  
            <Route path={`${path}/mysql/jobspikr_graphs/upload_count_vs_index_count_of_jp_sites`} component={UploadCountVsIndexCountOfJpSites} exact />  
            <Route path={`${path}/mysql/jobspikr_graphs/count_data_of_most_used_sites`} component={CountDataOfMostUsedSites} exact />  
            <Route path={`${path}/jobspikr_graphs/volume_of_listings_from_top_countries`} component={VolumeOfListingsFromTopCountries} exact />  
            {/* <Route path={`${path}/jobspikr_graphs/random_records_for_qa`} component={RandomRecordsFromQA} exact />   */}
            <Route path={`${path}/jobspikr_graphs/random_records_for_qa`} component={HundredRandomRecordsForQA} exact />  
            <Route path={`${path}/jobspikr_graphs/freshness_check_stats`} component={FreshnessCheckStats} exact />         

            {/* es page routes */} 
            <Route path={`${path}/jobspikr_graphs/upload_stats_of_jp_sites`} component={V2UploadStatsOfJpSites} exact />             
            <Route path={`${path}/jobspikr_graphs/count_data_of_most_used_sites`} component={V2CountDataOfMostUsedSites} exact />  
            <Route path={`${path}/jobspikr_graphs/upload_count_vs_index_count_of_jp_sites`} component={V2UploadCountVsIndexCountOfJpSites} exact />  
            <Route path={`${path}/jobspikr_graphs/dedup_tracker`} component={V2DedupTracker} exact />  
          </Switch>
        </div>
    </div>}
  </div>
  )
}
