
// This is the URL for es queries call pages
export const NewInternalBasePoint = "/internal"

// This is the URL for es queries call pages
export const NewJpInternalBasePoint = "/jobspikr_v3_internal"

// This is the URL for old mysql call pages
export const OldInternalBasePoint = "/internal/old"

// This is the URL for old mysql call pages
export const MysqlInternalBasePoint = "/internal/mysql"
