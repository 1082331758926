import React, {useState, useEffect, useContext} from 'react'
import { useParams, useHistory } from "react-router-dom";


import { Card, CardBody, CardHeader, Container, Row, Col } from 'reactstrap'
import {Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { CheckCircle, Info, PlusCircle, Target, Briefcase } from 'react-feather';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import axios from 'axios'


import Breadcrumb from '../../Common/BreadCrumb/BreadCrumbs.component';
import BannerNotifications from '../../Common/BannerNotification/BannerNotifications.component';
import CreateNewIssue from './Modal/CreateNewIssue.component';
import RedmineTickets from './Tickets/RedmineTickets.component';
import { OrgnaziationContext } from '../../../Home';

export default function Issues({banner_notifications, current_user, current_organization}) {
    
    const [organizationName, setOrganizationName] = useState('');
    const [data, setData] = useState({});
    const [activities, setActivities] = useState([]);
    const [notStarted, setNotStarted] = useState([]);
    const [inProgress, setInProgress] = useState([]);
    const [closedIssues, setClosedIssues] = useState([]);
    const [waitingOnClient, setWaitingOnClient] = useState([]);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadToggle, setLoadToggle] = useState(true);
    const [activeTab,setActiveTab] = useState("issues");
    const [filterChecks, setFilterChecks] = useState({data_issues: true, new_site: true,
        new_requirement: true, other_issues: true});
        
    const { organization_name } = useParams();
    const history = useHistory();
    const {setOrgName} = useContext(OrgnaziationContext);

    useEffect(() => {
        setLoading(true);
        if (organization_name === 'promptcloud'){
            history.push('/');
            toast.error("You are not authorized to access this page!");
            setLoading(false);
        }
        else if (current_organization?.org_identifier){
            setOrganizationName(current_organization?.org_identifier);
            setLoading(false);
        } else {
            setOrganizationName(organization_name);
            setLoading(false);
        }
    }, [])
    
    useEffect(() => {
        if (!(current_organization?.org_identifier)){
            setOrgName(organizationName)
        }
    }, [organizationName])


    const  createNewIssueModal= () => {
        setModal(curr => !curr)
    }

    useEffect(() => {
        if (organizationName){
            setLoading(true)
        let trueData = []
        for (const [key, value] of Object.entries(filterChecks)) {
            if (value){
                trueData.push(key)
            }
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
            },
            data: {},
            params: {selected_trackers: trueData},
        }

        axios.get(`/api/v1/organizations/${organizationName}/issues?page_type=client&controller_name=issues`, config).then(res => {
            setData(res?.data)
            setActivities(res?.data?.activities?.entries || [])
            setClosedIssues(res?.data?.closed_issues || [])
            setInProgress(res?.data?.in_progress_issues || [])
            setNotStarted(res?.data?.not_started_issues || [])
            setWaitingOnClient(res?.data?.waiting_on_client_issues || [])
            setLoading(false)
        }).catch(err => {
            toast.error(err.response.data.message)
            setLoading(false)
        })
        }
    }, [filterChecks, loadToggle, organizationName])



    return (
        <Container fluid>
            <Breadcrumb parent="Issues" title="Issues " />
            <BannerNotifications notificationsArray={banner_notifications} />
            <CreateNewIssue 
            data={data} 
            modal={modal} 
            createNewIssueModal={createNewIssueModal}
            setLoadToggle={setLoadToggle}
            setModal={setModal} />
            <Card>
                <Row>
                    <Col md="12" className="project-list">
                        <Card className='py-2 m-0'>
                            <Row>
                                <Col sm="8">
                                    <Nav tabs className="border-tab">
                                    <NavItem><NavLink className={activeTab === "issues" ? "active btn-primary" : 'btn-light'} onClick={() => setActiveTab("issues")}><Info />Issues</NavLink></NavItem>
                                    <NavItem><NavLink className={activeTab === "recentActivities" ? "active btn-primary" : 'btn-light'} onClick={() => setActiveTab("recentActivities")}><Target />Recent Activites</NavLink></NavItem>
                                    <NavItem><NavLink className={activeTab === "closed" ? "active btn-primary" : 'btn-light'} onClick={() => setActiveTab("closed")}><CheckCircle />Closed Issues</NavLink></NavItem>
                                    </Nav>
                                </Col>
                                <Col sm="4">
                                    {((current_user?.id && current_user?.is_admin) ||
                                    (current_user?.accessible_modules?.includes("data_access"))) &&
                                    <div className="text-end">
                                        <button className="btn btn-primary" onClick={createNewIssueModal}> <PlusCircle />Create New Issue</button>
                                    </div>}
                                </Col>
                            </Row>
                            {activeTab === "issues" &&<Row>
                                <Col lg={3} className='d-flex align-center'>
                                    <input type='checkbox' className='form-check me-2' checked={filterChecks.data_issues}
                                        onChange={(e) => setFilterChecks({...filterChecks, data_issues: e.target.checked})} /> <h6 className='m-0'>Data Issue</h6>
                                </Col>
                                <Col lg={3} className='d-flex align-center'>
                                    <input type='checkbox' className='form-check me-2' checked={filterChecks.new_site}
                                        onChange={(e) => setFilterChecks({...filterChecks, new_site: e.target.checked})} /> <h6 className='m-0'>New Site</h6>
                                </Col>
                                <Col lg={3} className='d-flex align-center'>
                                    <input type='checkbox' className='form-check me-2' checked={filterChecks.new_requirement}
                                        onChange={(e) => setFilterChecks({...filterChecks, new_requirement: e.target.checked})} /> <h6 className='m-0'>New Requirement</h6>
                                </Col>
                                <Col lg={3} className='d-flex align-center'>
                                    <input type='checkbox' className='form-check me-2' checked={filterChecks.other_issues} 
                                        onChange={(e) => setFilterChecks({...filterChecks, other_issues: e.target.checked})} /> <h6 className='m-0'>Other Issue</h6>
                                </Col>
                            </Row>}
                        </Card>
                    </Col>
                </Row>
            </Card>
            { loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> :
            <TabContent activeTab={activeTab}>
            <TabPane tabId="issues">
                <Row>
                    <Col lg={4}>
                    <Card>
                        <CardHeader className='p-4'>
                            <h6 className='fw-bold'>Not Started</h6>
                        </CardHeader>
                        <CardBody className='px-3 py-3'style={{height: 700, overflowY: 'scroll', overflowX: 'hidden'}}>
                            {notStarted.map(each => (
                                <RedmineTickets key={each.id} data={each} organization_name={organizationName} />
                            ))}
                        </CardBody>
                    </Card>
                    </Col>
                    
                    <Col lg={4}>
                    <Card>
                        <CardHeader className='p-4'>
                            <h6 className='fw-bold'>In Progress</h6>
                        </CardHeader>
                        <CardBody className='px-3 py-3'style={{height: 700, overflowY: 'scroll', overflowX: 'hidden'}}>
                            {inProgress.map(each => (
                                <RedmineTickets key={each.id} data={each} organization_name={organizationName} />
                            ))}
                        </CardBody>
                    </Card>
                    </Col>

                    <Col lg={4}>
                    <Card>
                        <CardHeader className='p-4'>
                            <h6 className='fw-bold'>Waiting on Client</h6>
                        </CardHeader>
                        <CardBody className='px-3 py-3'style={{height: 700, overflowY: 'scroll', overflowX: 'hidden'}}>
                            {waitingOnClient.map(each => (
                                <RedmineTickets key={each.id} data={each} organization_name={organizationName} />
                            ))}
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
            </TabPane>

            <TabPane tabId="recentActivities">
                <VerticalTimeline layout='1-column'>
                    { activities.map(each => (
                        <VerticalTimelineElement className="vertical-timeline-element--work" key={each.issue_id}
                            date={each.updated_at || ""} iconStyle={{ background: '#F6F6F6', color: 'black' }} 
                            icon={<Briefcase />} >
                            <a href={`/organizations/${organizationName}/issues/${each.issue_id || ''}`} className="vertical-timeline-element-title text-primary"
                                style={{fontSize: 16}}>{each.activity_title}</a>
                            <p className='text-muted custom-ptag' dangerouslySetInnerHTML={{__html: each.content}} />
                        </VerticalTimelineElement>
                    ))}
                    </VerticalTimeline>
            </TabPane>

            <TabPane tabId="closed">
                <Row>
                    <Col lg={5}>
                        <Card>
                            <CardHeader className='p-4'>
                                <h6 className='fw-bold'>Closed Issues</h6>
                            </CardHeader>
                            <CardBody className='px-3 py-3'style={{height: 700, overflowY: 'scroll', overflowX: 'hidden'}}>
                                {closedIssues.map(each => (
                                    <RedmineTickets key={each.id} data={each} organization_name={organizationName} />
                                ))}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </TabPane>
            </TabContent>}
        </Container>
    )
}
