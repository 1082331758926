import React, {useState, useEffect} from 'react'
import { Container, Row, Col, Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap'
import axios from 'axios'
import Select from 'react-select';
import DatePicker from 'react-datepicker'
import { Info } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
  
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};
  
const formatGroupLabel = (data) => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

export default function PushRecrawl() {
    const [loading, setLoading] = useState(false)
    const [loadingBtns, setLoadingBtns] = useState(false)
    const [errors, setErrors] = useState({})
    const [dropdownOptions, setDropdownOptions] = useState([])
    const [dropdownOptions2, setDropdownOptions2] = useState([])
    const [selectedJob, setSelectedJob] = useState('')
    const [selectedSite, setSelectedSite] = useState([])
    const [selectedSite2, setSelectedSite2] = useState([])
    const [particularDate, setParticularDate] = useState('')
    const [ignoreRecrawl, setIgnoreRecrawl] = useState(false)
    const [customOperation, setCustomOperation] = useState(false)
    const [testRun, setTestRun] = useState(false)
    const [findBlockingSol, setFindBlockingSol] = useState(false)
    const [freshCrawl, setFreshCrawl] = useState(false)
    const [modal, setModal] = useState(false)

    const toggle = () => {
        setModal(curr => !curr)
    }

    const today = new Date(Date.now())

    const transformData = (inputObj) => {
        let temp = []
        Object.keys(inputObj).forEach(each => {
          temp.push({
            label: each,
            options: inputObj[each].map(each => ({value: each, label: each}))
          })
        })
        return temp
    }

    useEffect(() => {
        fetchSiteNames();
    }, [])
    
    function fetchSiteNames() {
        axios.get('/api/v1/internal/project_names')
        .then(res => {
            console.log('res.data', res.data)
            let site_names = res.data.project_names
            site_names.unshift({ label: 'All', value: 'all' })
            setDropdownOptions(site_names)
        })
        .catch(err => {
            console.error(err?.message || 'Oops something went wrong')
        })
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('/');
    }

    const getValues = arr => {
        let res = []
        arr.forEach(each => {
            res.push(each.value)
        })
        return res
    }

    useEffect(() => {
        if (selectedSite?.length > 0){
            setLoading(true)
            axios.post('/api/v1/internal/site_names_by_project', {
                projects: getValues(selectedSite)
            })
            .then(res => {
                let site_names = transformData(res.data.site_names)
                site_names.unshift({ label: 'All', value: 'all' })
                console.log('site_names', site_names)
                setDropdownOptions2(site_names)
                setLoading(false)
            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong')
                setLoading(false)
            })
        }
        else{
            setSelectedSite2([])
        }
    }, [selectedSite])
    
    const handleSubmit = () => {
        setLoadingBtns(true)
        setErrors({})
        let tempObj = {}
        if (selectedSite?.length == 0){
            tempObj = {...tempObj, site:true}
        }
        if (selectedSite2?.length == 0){
            tempObj = {...tempObj, site2:true}
        }
        if (!(selectedJob?.value)){
            tempObj = {...tempObj, selected_job: true}
        }
        setErrors(tempObj)

        if (Object.keys(tempObj)?.length == 0){
            console.log('done')
            setModal(true)
            setLoadingBtns(false)
        }
        else {
            setLoadingBtns(false)
        }
    }

    const handleDataSubmission = () => {
        let resultObj = {
            "outer_command": "enqueue_resque_jobs",
            "inner_command": "enqueue_resque_jobs",
            "job_type": selectedJob?.value,
            "project": getValues(selectedSite),
            "site_name": getValues(selectedSite2),
            "date": particularDate ? formatDate(particularDate) : '',
            "custom": customOperation ? "true" : "false",
            "run_as_fresh_crawl": freshCrawl ? "true" : "false",
            "ignore_recrawl_interval": ignoreRecrawl ? "true" : "false"
        }
        testRun ? resultObj["test_run"] = true : ""
        findBlockingSol ? resultObj["find_blocking_solution_mode"] = true : ""
        axios.post('/api/v1/internal/command_dashboard/enque_resque', {
            filter: resultObj
        }).then(res => {
            toast.success(res?.data?.message)
            setLoading(false)
            window.location = `/internal/command_dashboard`
        }).catch(err => {
            toast.error(err?.response?.data?.message)
            setLoading(false)
        })
        setModal(false)
    }

    const closeBtn = <button className="close" 
        onClick={() => setModal(false)}> &times; </button>;
      
    return (
        <Container fluid>
            <Modal isOpen={modal} toggle={toggle} className="modal-body" backdrop='static' size='lg' >
                <ModalHeader toggle={toggle} close={closeBtn}>
                    {selectedJob?.value == "feedcrawl3" 
                        ? <h6 className='fw-bold'>Info</h6> 
                        : <h6 className='fw-bold'>Are you sure that you want to submit the form?</h6>}
                </ModalHeader>
                <ModalBody>
                    <Container>
                        {selectedJob?.value == "feedcrawl3" 
                        ? <h6>
                            N out of XXX jobs will be pushed if you want to push more then do run again or in cron, it will take up in every 8 mins
                        </h6>
                        : <div>
                            <h6> You have selected, </h6>
                            <h6> The job type is <b>{selectedJob?.value}</b> </h6>
                            <h6> The projects are: <b>{getValues(selectedSite)?.join(', ')} </b> </h6>
                            <h6> The sites are: <b>{getValues(selectedSite2)?.join(', ')} </b> </h6>
                        </div>}
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                    <Button color="success" onClick={handleDataSubmission}>Proceed</Button>
                </ModalFooter>
            </Modal>
            <Row>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Outer Command (Non-Editable)</h6>
                    <input className='form-control' value='enqueue_resque_jobs'
                        disabled />
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Inner Command (Non-Editable)</h6>
                    <input className='form-control' value='enqueue_resque_jobs'
                        disabled />
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Select Job Type</h6>
                    <Select classNamePrefix='select' isClearable isSearchable value={selectedJob} onChange={option => setSelectedJob(option)}
                        options={[{value: 'feedcrawl3', label: 'FeedCrawl3'}, {value: 'recrawl', label: 'Recrawl'}]} />
                    {errors?.selected_job && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                
                </Col>
                <Col md={12} className='my-3'>
                    <h6 className='fw-bold'>Project Name*</h6>
                    <Select options={dropdownOptions} value={selectedSite} onChange={res => setSelectedSite(res)}
                        placeholder="Select Project" isMulti isSearchable />
                    {errors?.site && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                </Col>

                {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
                : <Col md={12} className='my-3'>
                    <h6 className='fw-bold'>Project Sites (*Default All Sites Selected)</h6>
                    <Select options={dropdownOptions2} value={selectedSite2} isMulti isSearchable
                        onChange={res => setSelectedSite2(res)} formatGroupLabel={formatGroupLabel} />
                    {errors?.site2 && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                </Col>}

                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Run Date (Optional)</h6>
                    <DatePicker className="form-control my-2" selected={particularDate}
                        dateFormat="yyyy/MM/dd"
                        maxDate={today} onChange={(date) => setParticularDate(date)} />
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Select Operation (Optional)</h6>
                    <div className='d-flex'>
                        <div className='d-flex mx-2 align-items-center'>
                            <input type="checkbox" className='form-check me-2' checked={ignoreRecrawl} 
                                onChange={(e) => setIgnoreRecrawl(e.target.checked)} />
                            <h6 className='fw-bold m-0'>Ignore Recrawl Interval</h6>
                        </div>
                        <div className='d-flex mx-2 align-items-center'>
                            <input type="checkbox" className='form-check me-2' checked={customOperation} 
                                onChange={(e) => {
                                    setCustomOperation(e.target.checked)
                                    if (e.target.checked){
                                        setFindBlockingSol(false)
                                    }
                                }} />
                            <h6 className='fw-bold m-0'>Custom</h6>
                        </div>
                    </div>
                </Col>

                <Col md={4} className='my-3'>
                    <div className='d-flex mx-2 align-items-center'>
                        <input type="checkbox" className='form-check me-2' checked={testRun} 
                            onChange={(e) => setTestRun(e.target.checked)} />
                        <h6 className='fw-bold m-0'>Test Run</h6>
                    </div>
                    <div className='d-flex mx-2 align-items-center'>
                        <input type="checkbox" className='form-check me-2' checked={findBlockingSol} 
                            onChange={(e) => {
                                setIgnoreRecrawl(e.target.checked)
                                setFindBlockingSol(e.target.checked)
                                if (e.target.checked){
                                    setCustomOperation(false)
                                }
                            }} />
                        <h6 className='fw-bold m-0'>Find Blocking Solution <Info size={18} className='mx-2' data-tip
                            data-for='redmine_link' /> </h6>
                        <ReactTooltip id='redmine_link' clickable delayHide={1000} effect='solid'>
                            <a href={`https://redmine.promptcloud.com/projects/sdftech/wiki/Automated_Solution_Finder_For_Blocking_Sites`} target='_blank'
                                className='text-white'>
                            https://redmine.promptcloud.com/projects/sdftech/wiki/Automated_Solution_Finder_For_Blocking_Sites </a>
                        </ReactTooltip>
                    </div>
                    <div className='d-flex mx-2 align-items-center'>
                        <input type="checkbox" className='form-check me-2' checked={freshCrawl} 
                            onChange={(e) => setFreshCrawl(e.target.checked)} />
                        <h6 className='fw-bold m-0'>Run as fresh crawl</h6>
                    </div>
                </Col>

                <Col md={12} className='my-3'>
                    <h6 className='fw-bold'>
                        NOTE: Result of find blocking solution:
                    </h6>
                    <p>
                        Once you have done the form submission with find blocking solution then you can go to check 
                        the file of find blocking solution in blocking solution config file page and its link 
                        <a href={`https://app.promptcloud.com/internal/blocking_solution_config_files`} target='_blank'
                            className='mx-2'>https://app.promptcloud.com/internal/blocking_solution_config_files</a> and also you can download a file there.
                    </p>
                </Col>

                <Col md={7}></Col>
                <Col md={5}>
                    <button className='btn btn-success' style={{width: '100%', marginTop: '21px'}}
                        onClick={handleSubmit} disabled={loadingBtns} >
                        Submit
                    </button>
                </Col>
            </Row>
        </Container>
    )
}
