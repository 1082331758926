import React, {useState, useEffect, useContext} from 'react'
import { Container, Row, Col, ModalBody, Modal, ModalHeader,Card, CardBody, ModalFooter,
    Button, CardHeader, Table } from 'reactstrap'
import MembersRow from './MembersRow';
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import Breadcrumb from '../../global/Breadcrumb';
import axios from 'axios'
import { OrgnaziationContext } from '../../Home';

export default function ManageMembers({current_user}) {
    let { organization_name } = useParams();
    const [membersData, setMembersData] = useState({})
    const [memberModalData, setMemberModalData] = useState({
        first_name: '', last_name: '', email: '', designation: '',
        invited_by_id: '', product_type: 'custom_projects', accessible_modules: [],
        notification_preferences: []
    });
    const [errors, setErrors] = useState({})
    const [modal, setModal] = useState(false)
    const [loadingBtns, setLoadingBtns] = useState(false)
    const [toggle, setToggle] = useState(false)
    const [removedMembers, setRemovedMembers] = useState([])

    const {setOrgName} = useContext(OrgnaziationContext)
    useEffect(() => {
        setOrgName(organization_name)
    }, [organization_name])

    const closeBtn = <button className="close" 
        onClick={() => setModal(false)}> &times; </button>;

    const toggleFunc = () => {
        setModal(curr => !curr)
    }

    useEffect(() => {
        setMemberModalData({...memberModalData, invited_by_id: current_user.id})
    }, [current_user])

    useEffect(() => {
        axios.get(`/api/v1/organizations/${organization_name}/members/removed_members`).then(res => {
            setRemovedMembers(res.data.removed_members)
        }).catch(err => {
            console.log('err', err);
            toast.error(err.response.data.message)
        })
    }, [])

    const inviteNewMember = () => {
        setErrors({})
        if (memberModalData?.first_name?.length === 0 || memberModalData?.last_name?.length === 0 || 
            memberModalData?.email?.length === 0 || memberModalData?.designation?.length === 0 || memberModalData?.accessible_modules?.length === 0){
            let tempObj = {}
            if (memberModalData?.first_name?.length === 0){
                tempObj.first_name = 'First Name is required'
            }
            if (memberModalData?.last_name?.length === 0){
                tempObj.last_name = 'Last Name is required'
            }
            if (memberModalData?.email?.length === 0){
                tempObj.email = 'Email is required'
            }
            if (memberModalData?.designation?.length === 0){
                tempObj.designation = 'Role is required'
            }
            if (memberModalData?.accessible_modules?.length === 0){
                tempObj.accessible_modules = 'Atleast one Accessible module is required'
            }
            setErrors(tempObj)
        }
        else{
            setLoadingBtns(true)
            axios.post(`/api/v1/organizations/${organization_name}/user_invites`, {
                user_invite: memberModalData
            }).then(res => {
                toast.success(res.data.message)
                setModal(false)
                setToggle(curr => !curr)
                setLoadingBtns(false)
            }).catch(err => {
                toast.error(err.response.data.message)
                setLoadingBtns(false)
            })
        }
    }

    useEffect(() => {
        axios.get(`/api/v1/organizations/${organization_name}/user_invites`).then(res => {
            setMembersData(res.data)
        }).catch(err => {
            console.log('err', err);
            toast.error(err.response.data.message)
        })
    }, [toggle])

    const handleCheck = (status, element) => {
        let temp = {...memberModalData}
        if (status){
            if (element == "data_access"){
                let filteredArray = temp.notification_preferences.filter(item => item !== "redmine")
                temp = {...temp, accessible_modules: [...temp.accessible_modules, element], notification_preferences: filteredArray}
            } 
            else if(element == "billing"){
                let filteredArray = temp.notification_preferences.filter(item => item !== "invoice")
                temp = {...temp, accessible_modules: [...temp.accessible_modules, element], notification_preferences: filteredArray}
            }
            else{
                temp = {...temp, accessible_modules: [...temp.accessible_modules, element]}
            }
        }
        else{
            temp = {...temp, accessible_modules: temp.accessible_modules.filter(x => x != element)}
        }
        setMemberModalData(temp)
    }

    const handleCheck2 = (status, element) => {
        let temp = {...memberModalData}
        if (status){
            temp = {...temp, notification_preferences: [...temp.notification_preferences, element]}
        }
        else{
            temp = {...temp, notification_preferences: temp.notification_preferences.filter(x => x != element)}
        }
        setMemberModalData(temp)
    }

    return (
        <Container fluid>
            <Breadcrumb parent="Manage members" title="Manage members" />
            <Modal isOpen={modal} toggle={toggleFunc} backdrop='static' size='lg'>
                <ModalHeader toggle={toggleFunc} close={closeBtn}>
                    Add Member: {organization_name.replaceAll("_", " ")}
                </ModalHeader>
                <ModalBody className='px-5'>
                    <Row className='my-2'>
                        <Col lg={4}>
                            <h6 className='my-0'>First Name </h6>
                        </Col>
                        <Col lg={8}>
                            <input placeholder="First Name" type="text" className='form-control' value={memberModalData.first_name} 
                                onChange={(e) => setMemberModalData({ ...memberModalData, first_name: e.target.value })} />
                            {errors.first_name && <p className='text-danger'> {errors.first_name} </p>}
                        </Col>
                    </Row>
                    <Row className='my-2'>
                        <Col lg={4}>
                            <h6 className='my-0'>Last Name </h6>
                        </Col>
                        <Col lg={8}>
                            <input placeholder="Last Name" type="text" className='form-control' value={memberModalData.last_name}
                                onChange={(e) => setMemberModalData({ ...memberModalData, last_name: e.target.value })} />
                            {errors.last_name && <p className='text-danger'> {errors.last_name} </p>}
                        </Col>
                    </Row>
                    <Row className='my-2'>
                        <Col lg={4}>
                            <h6 className='my-0'>Enter Email </h6>
                        </Col>
                        <Col lg={8}>
                            <input placeholder="Email" type="email" className='form-control' value={memberModalData.email}
                                onChange={(e) => setMemberModalData({ ...memberModalData, email: e.target.value })} />
                            {errors.email && <p className='text-danger'> {errors.email} </p>}
                        </Col>
                    </Row>
                    <Row className='my-2'>
                        <Col lg={4}>
                            <h6 className='my-0'>Role </h6>
                        </Col>
                        <Col lg={8}>
                            <select className='form-control show-arrow' defaultValue={memberModalData.designation}
                                onChange={(e) => setMemberModalData({...memberModalData, designation: e.target.value})}>
                                <option value="">Select a role</option>
                                <option value="CXO">CXO</option>
                                <option value="CTO">CTO</option>
                                <option value="Product Head">Product Head</option>
                                <option value="Technical Head">Technical Head</option>
                                <option value="Business owner">Business owner</option>
                                <option value="Analyst">Analyst</option>
                                <option value="Other">Other</option>
                            </select>
                            {errors.designation && <p className='text-danger'> {errors.designation} </p>}
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <h6 className='my-0'>Accessible Modules </h6>
                        </Col>
                        <Col lg={8}>
                            <div className='my-1 d-flex align-items-center'>
                                <input type="checkbox" className='form-check' checked={memberModalData.accessible_modules.includes("data_access")} 
                                    onChange={(e) => handleCheck(e.target.checked, "data_access")} />
                                <h6 className='my-0 mx-2'>Data Access</h6>
                            </div>
                            <div className='my-1 d-flex align-items-center'>
                                <input type="checkbox" className='form-check' checked={memberModalData.accessible_modules.includes("billing")}
                                    onChange={(e) => handleCheck(e.target.checked, "billing")} />
                                <h6 className='my-0 mx-2'>Billing</h6>
                            </div>
                            <div className='my-1 d-flex align-items-center'>
                                <input type="checkbox" className='form-check' checked={memberModalData.accessible_modules.includes("user_management")}
                                    onChange={(e) => handleCheck(e.target.checked, "user_management")} />
                                <h6 className='my-0 mx-2'>User management</h6>
                            </div>
                            {errors.accessible_modules && <p className='text-danger'> {errors.accessible_modules} </p>}

                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <h6 className='my-0'>Notification Preferences </h6>
                        </Col>
                        <Col lg={8}>
                            <div className='my-1 d-flex align-items-center'>
                                <input type="checkbox" className='form-check' 
                                    disabled={!memberModalData.accessible_modules.includes('data_access')}
                                    checked={memberModalData.accessible_modules.includes('data_access') && memberModalData.notification_preferences.includes("redmine")} 
                                    onChange={(e) => handleCheck2(e.target.checked, "redmine")} />
                                <h6 className='my-0 mx-2'>Redmine</h6>
                            </div>
                            <div className='my-1 d-flex align-items-center'>
                                <input type="checkbox" className='form-check'
                                    disabled={!memberModalData.accessible_modules.includes('billing')} 
                                    checked={memberModalData.accessible_modules.includes('billing') && memberModalData.notification_preferences.includes("invoice")}
                                    onChange={(e) => handleCheck2(e.target.checked, "invoice")} />
                                <h6 className='my-0 mx-2'>Invoice</h6>
                            </div>
                            {errors.notification_preferences && <p className='text-danger'> {errors.notification_preferences} </p>}
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color='light' onClick={() => setModal(false)}> Close </Button>
                    <Button color="success" onClick={inviteNewMember}
                        disabled={loadingBtns}>Send Invite</Button>
                </ModalFooter>
            </Modal>

            {membersData?.members &&
            <Card>
                <CardHeader className='p-4 d-flex justify-content-between'>
                    <h5 className='my-0 fw-light'>Members</h5>
                    {(current_user?.is_admin || current_user?.accessible_modules.includes("user_management"))
                    && <button className='btn btn-primary btn-xs'
                        onClick={toggleFunc}>Invite members</button>}
                </CardHeader>
                <CardBody>
                    <Table responsive>
                        <thead>
                            <tr>
                                <td>Name</td>
                                <td>Email</td>
                                <td>Accessible Modules</td>
                                <td>Notification Preferences </td>
                                <td>Invited by </td>
                                <td>Status </td>
                                <td>Options </td>
                            </tr>
                        </thead>
                        <tbody>
                            {membersData?.members?.map(each => (
                                <MembersRow data={each} key={each.id} setToggle={setToggle}
                                    organization_name={organization_name} current_user={current_user} />
                            ))}
                            {membersData?.invited_members != null && membersData?.invited_members?.map(each => (
                                <MembersRow data={each} key={each.id} setToggle={setToggle}
                                    organization_name={organization_name} current_user={current_user} />
                            ))}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>}

            {removedMembers?.length > 0 &&
            <Card className='mt-5'>
                <CardHeader className='p-4 d-flex justify-content-between'>
                    <h5 className='my-0 fw-light'>RemovedMembers</h5>
                </CardHeader>
                <CardBody>
                    <Table responsive>
                        <thead>
                            <tr>
                                <td>Name</td>
                                <td>Email</td>
                                <td>Removed At</td>
                                <td>Removed By User</td>
                            </tr>
                        </thead>
                        <tbody>
                            {removedMembers?.map(each => (
                                <tr>
                                    <td>
                                        {each.name}
                                    </td>
                                    <td>
                                        {each.email}
                                    </td>
                                    <td>
                                        {each?.removed_at}
                                    </td>
                                    <td>
                                        {each?.removed_by_user}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>}
        </Container>
    )
}
