import React from 'react'
import { CardBody, CardHeader, Container , Card} from 'reactstrap'
import PromptCloudLogo from '../sidebar/logo/logo.png';
export default function PrivacyPolicy() {
    return (
        <Container fluid className='p-0'>
            <div>
                <img src={PromptCloudLogo} 
                    className='d-flex mx-auto' style={{height: 100}} />
            </div>
            <div style={{background: '#081B22', width: '100%'}}
                className='py-4 px-5'>
                <Card className='mx-5'>
                    <CardHeader className='p-3'>
                        <h3 className='fw-lighter text-center'>
                            Privacy Policy
                        </h3>
                    </CardHeader>
                    <CardBody>
                        <h6 className='my-2 lh-medium'>
                            The effective date of this policy is May 24, 2018. PromptCloud, Inc. and its affiliates including the CrawlBoard platform (herein referred to as “PromptCloud”) operate https://www.promptcloud.com. This page informs you of our policies regarding the collection, use and disclosure of personal Information we receive from users of the site.
                        </h6>
                        <hr />
                        <div className='my-4'>
                            <h5 className='fw-bold'>1. Terms of Use</h5>
                            <h6 className='lh-medium'>
                            We use your personal Information only for providing and improving the site. By using the site, you agree to the collection and use of information in accordance with this policy.
                            </h6>
                        </div>
                        <hr />
                        <div className='my-4'>
                            <h5 className='fw-bold'>2. Personal Identification Information Collection</h5>
                            <h6 className='lh-medium'>
                            While using our site, we may ask you to provide us with certain personally identifiable information that can be used to serve you with our solutions. Personally identifiable information may include, but is not limited to your company email ("Personal Information"). We may collect personal identification information from users in a variety of ways, including, but not limited to, when users visit our website, register on the website, subscribe to our services, subscribe to the newsletter, fill out a form, and in connection with other activities, services, features or resources we make available on our website. Users may be asked for, as appropriate, name, company email address, mailing address, phone numbers. We will collect personal identification information from users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain website related activities.
                            </h6>
                        </div>
                        <hr />
                        <div className='my-4'>
                            <h5 className='fw-bold'>3. Log Data</h5>
                            <h6 className='lh-medium'>Like many site operators, we may collect information that your browser sends whenever you visit our site ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our site that you visit, the time and date of your visit, the time spent on those pages and other statistics.</h6>
                        </div>
                        <hr />
                        <div className='my-4'>
                            <h5 className='fw-bold'>4. Cookies</h5>
                            <h6 className='lh-medium'>Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive. Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our site.</h6>
                        </div>
                        <hr />
                        <div className='my-4'>
                            <h5 className='fw-bold'>5. How we use collected information</h5>
                            <h6 className='lh-medium'>PromptCloud collects and uses users' personal information for the following purposes</h6>
                            <ul className='my-2 mx-5' style={{listStyleType: 'disc'}}>
                                <li className='lh-medium my-2'>
                                    To provide your individual account access on our site - We will use your personal information only to serve you for your specific needs. We may use this information in the aggregate to understand how our users as a group use the services and resources provided on our website.
                                </li>
                                <li className='lh-medium my-2'>To improve our website - We continually strive to improve our website offerings based on the information and feedback we receive from you.</li>
                                <li className='lh-medium my-2'>To improve customer service - Your information helps us to more effectively respond to your customer service requests and support needs.</li>
                                <li className='lh-medium my-2'>To send periodic emails - The email address users provide for order processing, will only be used to send them information and updates pertaining to their order. It may also be used to respond to their inquiries, and/or other requests or questions.</li>
                                <li className='lh-medium my-2'>To provide your individual account access on our site - We will use your personal information only to serve you for your specific needs. We may use this information in the aggregate to understand how our users as a group use the services and resources provided on our website.</li>
                                <li className='lh-medium my-2'>If at any time the user would like to unsubscribe from receiving future emails, they may do so by contacting us via our website.</li>
                            </ul>
                        </div>
                        <hr />
                        <div className='my-4'>
                            <h5 className='fw-bold'>6. How we protect your information</h5>
                            <h6 className='lh-medium'>We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our website. Sensitive and private data exchange between the website and its users happens over a SSL secured communication channel and is encrypted and protected with digital signatures. Sharing your personal information We do not sell, trade, or rent users personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above. We may use third party service providers to help us operate our business and the website or administer activities on our behalf, such as sending out newsletters. We may share your information with these third parties for those limited purposes provided that you have given us your permission.</h6>
                        </div>
                        <hr />
                        <div className='my-4'>
                            <h5 className='fw-bold'>7. Your Rights</h5>
                            <h6 className='lh-medium'>You may have certain rights relating to your Personally Identifiable Information provided for under applicable law. These are the right to:</h6>
                            <ul className='my-2 mx-5' style={{listStyleType: 'disc'}}>
                                <li className='lh-medium my-2'>Request access to your Personally Identifiable Information and the processing activities on the same.</li>
                                <li className='lh-medium my-2'>Request that your Personally Identifiable Information is rectified if it is inaccurate or incomplete.</li>
                                <li className='lh-medium my-2'>Request erasure of your Personally Identifiable Information in certain circumstances.</li>
                                <li className='lh-medium my-2'>Request restriction of the processing of your Personally Identifiable Information in certain circumstances.</li>
                                <li className='lh-medium my-2'>Object to the processing of your Personally Identifiable Information in certain circumstances.</li>
                                <li className='lh-medium my-2'>Receive your Personally Identifiable Information provided to us as a controller in a structured, commonly used and machine-readable format in certain circumstances.</li>
                                <li className='lh-medium my-2'>Lodge a complaint with a supervisory authority.</li>
                                <li className='lh-medium my-2'>Withdraw your consent provided at any time by contacting us.</li>
                            </ul>
                            <h6 className='lh-medium'>
                            To exercise the rights outlined above in respect of your Personally Identifiable Information please contact the details available in the contact section below. We will endeavor to respond to you within appropriate timelines as required by the law.
                            </h6>
                        </div>
                        <hr />
                        <div className='my-4'>
                            <h5 className='fw-bold'>8. Right to opt out and withdrawal</h5>
                            <h6 className='lh-medium'>
                                PromptCloud respects your privacy considerations and hence provides you an option to refuse to provide your Personally Identifiable Information or information sought to be collected.
                                <br />
                                You also have the option to subscribe/opt-in to receive newsletters, new alerts and marketing content. You can always opt-out of such options by writing to info+dataprotection@promptcloud.com or click the “unsubscribe” link at the bottom of an email newsletter received by you from us. PromptCloud shall adhere to your preferences.
                            </h6>
                        </div>
                        <hr />
                        <div className='my-4'>
                            <h5 className='fw-bold'>9. Authenticity</h5>
                            <h6 className='lh-medium'>PromptCloud shall not be responsible for the authenticity of the information and it is your duty to ensure that the information provided is authentic.</h6>
                        </div>
                        <hr />
                        <div className='my-4'>
                            <h5 className='fw-bold'>10. Protection of minor’s information</h5>
                            <h6 className='lh-medium'>The website is not directed to children under the age of sixteen (16). PromptCloud does not knowingly solicit PII from or market to anyone under sixteen (16) years of age. If you believe that we have received information from a minor, please notify us immediately and we will ensure that reasonable efforts will be made by us to delete such information from our database.</h6>
                        </div>
                        <hr />
                        <div className='my-4'>
                            <h5 className='fw-bold'>11. Changes to Privacy Policy</h5>
                            <h6 className='lh-medium'>PromptCloud may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the site. You are advised to review this Privacy Policy periodically for any changes.</h6>
                        </div>
                        <hr />
                        <div className='my-4'>
                            <h5 className='fw-bold'>12. Contact Us</h5>
                            <h6 className='lh-medium'>
                                If you have any questions about this Privacy Policy, please contact us via support (at) promptcloud.com.
                            </h6>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </Container>
    )
}
