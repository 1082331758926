import React, {useState, useEffect} from 'react'
import { Card, CardHeader, CardBody, Col, Container, Row, Breadcrumb, BreadcrumbItem, Alert } from 'reactstrap'
import Loader from 'react-loader-spinner';
import axios from 'axios'
import { toast } from 'react-toastify';

export default function SchemaAndDataFields({site_name, rssIdSetter, apiEndpoint}) {
  const [schemaFields, setSchemaFields] = useState([])
  const [dataFields, setDataFields] = useState([])
  const [schemaFieldsCount, setSchemaFieldsCount] = useState()
  const [dataFieldsCount, setDataFieldsCount] = useState()
  const [totalRecordsCount, setTotalRecordsCount] = useState()
  const [crawlDateRange, setCrawlDateRange] = useState()
  const [loading, setLoading] = useState(true);
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [rssCrawlPushId, setRssCrawlPushId] = useState();

  useEffect(() => {
    setLoading(true)
    axios.get(apiEndpoint).then(res => {
      setSchemaFields(res?.data?.schema_and_data_fields?.schema_fields)
      setDataFields(res?.data?.schema_and_data_fields?.data_fields)
      setSchemaFieldsCount(res?.data?.schema_and_data_fields?.no_of_schema_fields)
      setDataFieldsCount(res?.data?.schema_and_data_fields?.no_of_data_fields)
      setTotalRecordsCount(res?.data?.schema_and_data_fields?.no_of_records)
      setIsDataAvailable(res?.data?.is_data_available)
      setCrawlDateRange(res?.data?.schema_and_data_fields?.crawl_date_range)
      setRssCrawlPushId(res?.data?.schema_and_data_fields?.rss_crawl_push_id)
      setLoading(false)
    }).catch(err => {
      console.log("err", err);
      toast.error(err.response?.data?.message)
      setLoading(false)
    })
  }, [site_name, apiEndpoint])


  return (
    <>
      {
        loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
          :
          <>
            { isDataAvailable ? 
            <>
              <Card className='my-3'>
                <CardHeader className='bg-primary py-3 mt-0'>
                  Schema Fields in DRL (No.of fields: <strong>{schemaFieldsCount}</strong>) & (Rss Crawl Push Id: <strong>{rssCrawlPushId}</strong>):
                </CardHeader>
                <CardBody style={{padding: "10px 20px"}}><p style={{paddingLeft: "20px"}}>{schemaFields}</p></CardBody>
              </Card>

              <Card className='my-3'>
                <CardHeader className='bg-primary py-3'>
                  Data Fields in Data File (No.of fields: <strong>{dataFieldsCount}</strong>) & (Rss Crawl Push Id: <strong>{rssCrawlPushId}</strong>):
                </CardHeader>
                <CardBody style={{padding: "10px 20px"}}><p style={{paddingLeft: "20px"}}>{dataFields}</p></CardBody>
              </Card>
              <hr style={{margin: "0rem 0"}}/>
              <Row>
                <Col md='6' className='my-3'><h6>Crawl Dates: <strong>{crawlDateRange}</strong></h6></Col>
                <Col md='6' className='my-3 d-flex justify-content-end'><h6>Number of records considered for QA Report: <strong>{totalRecordsCount}</strong> randomly</h6></Col>
              </Row>
              <hr style={{margin: "0rem 0"}}/>
            </>
                : ""
            }
          </>
      }
    </>
  )
}

