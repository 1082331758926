import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import AsyncSelect from 'react-select/async';
import { Home } from "react-feather";
import Select from "react-select";
import axios from "axios";

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function SiteOrProjectOrClientVsAvgTimeForCrawl() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const tabNameFromSearchUrl = new URLSearchParams(search).get("filter[tab_name]");
  const tabNameFromUrl = window.location.hash.substr(1);
  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[site_name]"),
    fromTime: new URLSearchParams(search).get("filter[time]"),
    tabName: tabNameFromSearchUrl ? tabNameFromSearchUrl : tabNameFromUrl
  }

  const [siteName, setSiteName] = useState("");
  const [siteNameOptions, setSiteNameOptions] = useState([])
  const [fromTime, setFromTime] = useState({ label: 'Last 7 days', value: 'now-7d' });
  const [activeTab, setActiveTab] = useState(tabNameFromUrl ? tabNameFromUrl : 'rss');

  const timeOptions = [
    { label: 'Today', value: 'now%2Fd' },
    { label: 'Last 30 minutes', value: 'now-30m' },
    { label: 'Last 1 hour', value: 'now-1h' },
    { label: 'Last 2 hours', value: 'now-2h' },
    { label: 'Last 4 hours', value: 'now-4h' },
    { label: "Last 10 hours", value: "now-10h" },
    { label: 'Last 12 hours', value: 'now-12h' },
    { label: 'Last 24 hours', value: 'now-24h' },
    { label: 'Last 2 days', value: 'now-2d' },
    { label: 'Last 3 days', value: 'now-3d' },
    { label: 'Last 4 days', value: 'now-4d' },
    { label: 'Last 7 days', value: 'now-7d' },
    { label: 'Last 15 days', value: 'now-15d' },
    { label: 'Last 30 days', value: 'now-30d' },
    { label: 'Last 60 days', value: 'now-60d' },
    { label: 'Last 1 year', value: 'now-1y' }
  ];

  const [UrlParams, setUrlParams] = useState({
    siteName: '',
    fromTime: 'now-7d',
    tabName: "rss"
  })

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let site_names = res.data.site_names, site_names_options = []
        for (var key in site_names) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
          site_names[key].map(item => {
            children.push({ label: item, value: item })
          })
          group['options'] = children;
          site_names_options.push(group)
        }
        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
        setSiteNameOptions(site_names_options);
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong');
      })
  }, []);


  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.siteName && searchParams.siteName != siteName.value) {
      if ((searchParams.siteName == "*") || (searchParams.siteName == "all")){
        setSiteName({ label: 'All', value: "*" });
      }
      else{
        setSiteName({ label: searchParams.siteName, value: searchParams.siteName });
      }
    }
    if (searchParams.fromTime && searchParams.fromTime != fromTime.value) {
      setFromTime(timeOptions.filter(item => item.value == searchParams.fromTime)[0]);
    }
    if (searchParams.tabName && searchParams.tabName != activeTab) {
      setActiveTab(searchParams.tabName);
    }
    if (commit == 'Get Data') {
      setUrlParams(searchParams)
    }
  }


  function rssKibanaUrl() {
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
    var site_name_val = UrlParams.siteName ? `*${UrlParams.siteName}*` : "*";

    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=line&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(pause:!t,value:0),time:(from:'${UrlParams.fromTime}',to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'site_name:${site_name_val}+and+current_status:+%22done%22+AND+%22rss_status_tracker%22'),uiState:(),vis:(aggs:!((enabled:!t,id:'2',params:(drop_partials:!f,extended_bounds:(),field:'@timestamp',interval:d,min_doc_count:0,scaleMetricValues:!f,timeRange:(from:now-7d,to:now),useNormalizedEsInterval:!t),schema:segment,type:date_histogram),(enabled:!t,id:'3',params:(customLabel:'Avg+time+for+one+segment',field:time_duration),schema:metric,type:avg)),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,categoryAxes:!((id:CategoryAxis-1,labels:(filter:!t,show:!t,truncate:100),position:bottom,scale:(type:linear),show:!t,style:(),title:(),type:category)),dimensions:(x:(accessor:0,aggType:date_histogram,format:(id:date,params:(pattern:'YYYY-MM-DD+HH:mm')),params:(bounds:(max:'2021-10-22T09:46:40.637Z',min:'2021-10-15T09:46:40.637Z'),date:!t,format:'YYYY-MM-DD+HH:mm',interval:PT3H,intervalESUnit:h,intervalESValue:3)),y:!((accessor:1,aggType:sum,format:(id:number),params:()))),grid:(categoryLines:!f),labels:(),legendPosition:right,seriesParams:!((data:(id:'3',label:'Avg+time+for+one+segment'),drawLinesBetweenPoints:!t,interpolate:linear,lineWidth:2,mode:normal,show:!t,showCircles:!t,type:line,valueAxis:ValueAxis-1)),thresholdLine:(color:%2334130C,show:!f,style:full,value:10,width:1),times:!(),type:line,valueAxes:!((id:ValueAxis-1,labels:(filter:!f,rotate:0,show:!t,truncate:100),name:LeftAxis-1,position:left,scale:(mode:normal,type:linear),show:!t,style:(),title:(text:'Avg+time+for+one+segment'),type:value))),title:'',type:line))`
  }

  function feedcrawlKibanaUrl() {
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
    var site_name_val = UrlParams.siteName ? `*${UrlParams.siteName}*` : "*";

    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=line&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(pause:!t,value:0),time:(from:'${UrlParams.fromTime}',to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'site_name:${site_name_val}+and+current_status:+%22done%22+AND+%22diskfetcher+crawl+end%22'),uiState:(),vis:(aggs:!((enabled:!t,id:'2',params:(drop_partials:!f,extended_bounds:(),field:'@timestamp',interval:d,min_doc_count:0,scaleMetricValues:!f,timeRange:(from:now-7d,to:now),useNormalizedEsInterval:!t),schema:segment,type:date_histogram),(enabled:!t,id:'3',params:(customLabel:'Avg+time+for+one+segment',field:time_duration),schema:metric,type:avg)),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,categoryAxes:!((id:CategoryAxis-1,labels:(filter:!t,show:!t,truncate:100),position:bottom,scale:(type:linear),show:!t,style:(),title:(),type:category)),dimensions:(x:(accessor:0,aggType:date_histogram,format:(id:date,params:(pattern:'YYYY-MM-DD+HH:mm')),params:(bounds:(max:'2021-10-22T09:46:40.637Z',min:'2021-10-15T09:46:40.637Z'),date:!t,format:'YYYY-MM-DD+HH:mm',interval:PT3H,intervalESUnit:h,intervalESValue:3)),y:!((accessor:1,aggType:sum,format:(id:number),params:()))),grid:(categoryLines:!f),labels:(),legendPosition:right,seriesParams:!((data:(id:'3',label:'Avg+time+for+one+segment'),drawLinesBetweenPoints:!t,interpolate:linear,lineWidth:2,mode:normal,show:!t,showCircles:!t,type:line,valueAxis:ValueAxis-1)),thresholdLine:(color:%2334130C,show:!f,style:full,value:10,width:1),times:!(),type:line,valueAxes:!((id:ValueAxis-1,labels:(filter:!f,rotate:0,show:!t,truncate:100),name:LeftAxis-1,position:left,scale:(mode:normal,type:linear),show:!t,style:(),title:(text:'Avg+time+for+one+segment'),type:value))),title:'',type:line))`
  }

  const handleGetData = () => {
    window.location.href = `/internal/site_or_project_or_client_vs_avg_time_for_crawl?filter[site_name]=${siteName}&filter[time]=${fromTime.value}&filter[tab_name]=${activeTab}&commit=${'Get Data'}`
  }

  const promiseOptions = (inputValue) => {
    if (inputValue.length < 3) {
      return null
    } else {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200') {
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
              site_names[key].map(item => {
                children.push({ label: item, value: item })
              })
              group['options'] = children;
              site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setSiteNameOptions(site_names_options)
            resolve(site_names_options)
          }
        }).catch(err => {
          console.log(`err`, err)
        })
      })
    }
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/site_or_project_or_client_vs_avg_time_for_crawl">Site/Project/Client vs Avgerage time for crawl</a> : 'Site/Project/Client vs Avgerage time for crawl'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
            </BreadcrumbItem>}
          </Breadcrumb>
        </Col>
      </Row>
    </div>

    <Card>
      <CardBody>
        <Row className="align-items-end">
          <Col sm="4">
            <label className="fw-bold">Enter Site/Project/Client Name</label>
            <AsyncSelect cacheOptions defaultOptions={siteNameOptions} name='site_name'
              value={siteName} loadOptions={promiseOptions} onChange={res => setSiteName(res)}
              placeholder="Select a site name" />
          </Col>
          <Col sm="4">
            <label className="fw-bold">Time</label>
            <Select
              value={fromTime}
              options={timeOptions}
              onChange={option => setFromTime(option)} />
          </Col>
          <Col sm="4">
            <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
          </Col>
        </Row>
      </CardBody>
    </Card>
    <Card>
      <CardBody>
        <Nav tabs>
          <NavItem>
            <NavLink className={activeTab == 'rss' ? 'active' : ''} onClick={() => setActiveTab('rss')}>
              Rss
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab == 'feedcrawl' ? 'active' : ''} onClick={() => setActiveTab('feedcrawl')}>
              Feedcrawl
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={'rss'}>
            <div className="panel-body">
              <center>
                <span className="fw-bold">Site/Project/Client vs Avgerage time for crawl - RSS</span><br />
                <a target="_blank" className="link-primary" href={rssKibanaUrl()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={rssKibanaUrl() + "&embed=true"} height="500" width="100%" className="border-0"></iframe>
            </div>
          </TabPane>
          <TabPane tabId={'feedcrawl'}>
            <div className="panel-body">
              <center>
                <span className="fw-bold">Site/Project/Client vs Avgerage time for crawl - Feedcrawl</span><br />
                <a target="_blank" className="link-primary" href={feedcrawlKibanaUrl()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={feedcrawlKibanaUrl() + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
            </div>
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  </Container>
}

