import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import React, { Fragment, useState, useMemo, useEffect } from 'react'
import SpinnerLoader from '../../../V2/Common/LoadingAnimation/SpinnerLoader.component';
import ExportCSVButton from './ExportCSVButton.component';

export default function HighLevelDataSummary(props) {

  // API Endpoint for getting the high level stats summary for LLM
  const HIGH_LEVEL_DATA_SUMMARY_DATA_API = useMemo(() => (
    `/api/v1/internal/new_qa_reports/chat_gpt_data_matching_summary?input_file_path=${props?.inputFilePath}`
  ), [props?.inputFilePath])

  // Misc.
  const [loading, setLoading] = useState(false);

  // States for storing the data fetched from API
  const [summaryReport, setSummaryReport] = useState([]);
  const [metaInformation, setMetaInformation] = useState([]);

  useEffect(() => {
    const controller = new AbortController();

    fetchSummaryData(controller.signal);

    // cleanup
    return () => {
      controller.abort();
      setSummaryReport([]);
      setMetaInformation([]);

    }
  }, [HIGH_LEVEL_DATA_SUMMARY_DATA_API])

  // Helper function to fetch table data
  async function fetchSummaryData(signal) {
    try {
      setLoading(true);
      const response = await axios.get(HIGH_LEVEL_DATA_SUMMARY_DATA_API, { signal: signal });
      const data = await response?.data;
      setMetaInformation(data?.meta_info);
      setSummaryReport(data?.summary_report);
      setLoading(false);
    }
    catch (error) {
      console.error(error?.response?.message);
      setLoading(false);
    }
  }

  // Table data columns for showing summary data
  const summaryTableColumns = [
    { name: "Field Names", selector: row => row?.field_name, center: true, sortable: true },
    { name: "ChatGPT Null Values", selector: row => row?.chatgpt_null_values, center: true, sortable: true },
    { name: "Exact Match", selector: row => row?.exact_match, center: true, sortable: true },
    { name: "Crawled Null Values", selector: row => row?.crawled_null_values, center: true, sortable: true },
    { name: "Match Score > 60", selector: row => row?.match_score_greater_than_60, center: true, sortable: true },
    { name: "Match Score > 80", selector: row => row?.match_score_greater_than_80, center: true, sortable: true },
    { name: "Match Score < 40", selector: row => row?.match_score_less_than_40, center: true, sortable: true },
    { name: "Total Records", selector: row => row?.total_records, center: true, sortable: true },
  ]

  // Custom table styling
  const customStyles = {
    header: {
      style: {
        minHeight: '100px',

      },
    },
    headRow: {
      style: {
        backgroundColor: "#9dbaed",
        color: "black",
        fontWeight: "bold",
      },
    },
    headCells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: "#0000",
        },
        width: "195px",

      },
    },
    cells: {
      style: {
        '&:first-of-type': {
          minWidth: "80px",
          maxWidth: "250px",
        },
        border: "1px solid black",
        cursor: "pointer",
        fontSize: "14px"
      },
    },
  }

  return (
    <Fragment>
      <Modal
        {...props}
        size="xl"
        dialogClassName="custom_modal_90w"
        centered
        scrollable={true}
        fullscreen='xl-down'
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            High Level Data Summary Report
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SpinnerLoader loading={loading}>
            <div>
              {
                summaryReport ?
                <>
                  <div className='d-flex flex-row justify-content-between align-items-center'>
                    <div> <h6 className='fw-bold'>{`Token Size > 15k : ${metaInformation?.token_size_more_than_15k ? metaInformation?.token_size_more_than_15k : 0}`}</h6></div>
                    <div>
                      <ExportCSVButton data={summaryReport} />
                    </div>
                  </div>
                  <DataTable
                    dense
                    striped
                    noHeader
                    pagination
                    responsive
                    data={summaryReport}
                    customStyles={customStyles}
                    columns={summaryTableColumns}
                  />
              </>
                  : "No data available!"
              }
            </div>
          </SpinnerLoader>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props?.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
}
