import React, { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { BreadcrumbItem, Card, CardBody, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane, Breadcrumb, Col } from "reactstrap";
import { Home } from "react-feather";
import axios from "axios";
import { useLocation, Link } from "react-router-dom";

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function ApiInformation() {
    const search = useLocation().search;
    const commit = new URLSearchParams(search).get("commit")
    const searchParams = {
        clientName: new URLSearchParams(search).get("filter[client_name]"),
        time: new URLSearchParams(search).get("filter[time]"),
    }
    const [clientName, setClientName] = useState({ label: 'select...', value: '' });
    const [clientNamesOptions, setClientNamesOptions] = useState([]);
    const [chooseTime, setChooseTime] = useState({ label: 'select...', value: '' });
    const [fromDate, setFromDate] = useState("now-30d");
    const [clientId, setClientId] = useState("*");
    const [showResult, setShowResult] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setSearchParamsData();
        axios.get('/api/v1/internal/dropdown_list_helper')
            .then(res => {
                setClientNamesOptions(res.data.client_names)
            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong')
            })
    }, []);

    const chooseTimeOptions = [
        { label: 'Last1 hour', value: 'last_one_hour' },
        { label: 'Last4 hour', value: 'last_four_hours' },
        { label: 'Last12 hour', value: 'last_twelve_hours' },
        { label: 'Last24 hour', value: 'last_twenty_four_hours' },
        { label: 'Last7 days', value: 'last_seven_days' },
        { label: 'Last30 days', value: 'last_thirty_days' },
        { label: 'Last60 days', value: 'last_sixty_days' }

    ]

    function setSearchParamsData() {
        if (searchParams.clientName && searchParams.clientName != clientName.value) {
            setClientName({ label: searchParams.clientName, value: searchParams.clientName })
        }
        if (searchParams.time && searchParams.time != chooseTime.value) {
            setChooseTime(chooseTimeOptions.filter(item => item.value == searchParams.time)[0]);
        }
        if (commit == "Get Data") {
            setShowResult(true);
            fetchData(searchParams.clientName, searchParams.time)
        }

    }

    const handleSubmit = () => {
        window.location.href = `/internal/api_information/search?filter[client_name]=${clientName.value}&filter[time]=${chooseTime.value}&commit=${'Get Data'}`
    }

    function fetchData(ClientName = clientName.value, ChooseTime = chooseTime.value) {
        setLoading(true)
        axios.get(`/api/v1/internal/api_information/search?filter[client_name]=${ClientName}&filter[time]=${ChooseTime}`)
            .then(res => {
                if (res.data.status == 200 && res.data?.client_id != null) {
                    setClientId(res.data.client_id);
                    setFromDate(res.data.from)
                }
                setLoading(false)
            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong');
                setLoading(false)
            })

    }


    function Form() {
        return <Card>
            <CardBody>
                <Row className="align-items-end">
                    <div className="col-sm-4">
                        <label className="fw-bold">Client Name</label>
                        <Select
                            value={clientName}
                            options={clientNamesOptions}
                            onChange={option => setClientName(option)} />
                    </div>
                    <div className="col-sm-4">
                        <label className="fw-bold">Choose Time</label>
                        <Select
                            value={chooseTime}
                            options={chooseTimeOptions}
                            onChange={option => setChooseTime(option)} />
                    </div>
                    <div className="col-sm-4">
                        <button className="btn btn-success w-100" onClick={handleSubmit} >Get Data</button>
                    </div>
                </Row>
            </CardBody>
        </Card>
    }

    return <Container fluid>
        <Container fluid>
            <div className="page-title">
                <Row className=''>
                    <Col xs="6">
                        <h3>Internal Dashboard</h3>
                    </Col>
                    <Col xs="6">
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <Link to={`/internal`}><Home /></Link>
                            </BreadcrumbItem>

                            {commit == 'Get Data' ? <Fragment>
                                <BreadcrumbItem>
                                    <a href="/internal/api_information">All Info</a>
                                </BreadcrumbItem>
                                <BreadcrumbItem>
                                    <b>Search Results</b>
                                </BreadcrumbItem>
                            </Fragment> : <BreadcrumbItem className='fw-bold' >
                                API Information
                            </BreadcrumbItem>}
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
        </Container>
        <Form />
        <Card className="me-5">
            <CardBody>
                {showResult ? <div>
                    <div className="mb-3 text-center">
                        <b>Number of Hits</b><br />
                        <a className="link-primary" target={'_blank'} href={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${fromDate},mode:quick,to:now))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'*')),uiState:(vis:(legendOpen:!f)),vis:(aggs:!((id:'1',params:(),schema:metric,type:count),(id:'2',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:auto,min_doc_count:1),schema:segment,type:date_histogram),(id:'3',params:(filters:!((input:(query:(query_string:(analyze_wildcard:!t,query:'custom_key:%20%22data_api_call_count%22%20AND%20id:%20%22${clientId}%22'))),label:'API%20Request%20Count'))),schema:group,type:filters)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:data_api_call_count_status,type:line))`}>Click here to see below graph in kibana</a>
                    </div>
                    <iframe src={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${fromDate},mode:quick,to:now))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'*')),uiState:(vis:(legendOpen:!f)),vis:(aggs:!((id:'1',params:(),schema:metric,type:count),(id:'2',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:auto,min_doc_count:1),schema:segment,type:date_histogram),(id:'3',params:(filters:!((input:(query:(query_string:(analyze_wildcard:!t,query:'custom_key:%20%22data_api_call_count%22%20AND%20id:%20%22${clientId}%22'))),label:'API%20Request%20Count'))),schema:group,type:filters)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:data_api_call_count_status,type:line))&embed=true`} height="500" width="100%" className="border-0" ></iframe>
                    <div className="mb-3 text-center">
                        <b>Number of Files</b> <br />
                        <a className="link-primary" target={'_blank'} href={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${fromDate},mode:quick,to:now))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'*')),uiState:(vis:(legendOpen:!f)),vis:(aggs:!((id:'1',params:(field:files_returned_for_this_request),schema:metric,type:sum),(id:'2',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:auto,min_doc_count:1),schema:segment,type:date_histogram),(id:'3',params:(filters:!((input:(query:(query_string:(analyze_wildcard:!t,query:'custom_key:%20%22data_api_call_count%22%20AND%20id:%20%22${clientId}%22'))),label:'Files%20returned%20count%20for%20the%20request'))),schema:group,type:filters)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:data_api_call_count_file_returned_request,type:line))`}>Click here to see below graph in kibana</a>
                    </div>
                    <iframe src={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${fromDate},mode:quick,to:now))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'*')),uiState:(vis:(legendOpen:!f)),vis:(aggs:!((id:'1',params:(field:files_returned_for_this_request),schema:metric,type:sum),(id:'2',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:auto,min_doc_count:1),schema:segment,type:date_histogram),(id:'3',params:(filters:!((input:(query:(query_string:(analyze_wildcard:!t,query:'custom_key:%20%22data_api_call_count%22%20AND%20id:%20%22${clientId}%22'))),label:'Files%20returned%20count%20for%20the%20request'))),schema:group,type:filters)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:data_api_call_count_file_returned_request,type:line))&embed=true`} height="500" width="100%" className="border-0" ></iframe>

                </div> :
                    <div>
                        <div className="mb-3 text-center">
                            <b>Overall API Hits</b> <br />
                            <a className="link-primary" target={'_blank'} href={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${fromDate},mode:quick,to:now))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'*')),uiState:(vis:(legendOpen:!f)),vis:(aggs:!((id:'1',params:(),schema:metric,type:count),(id:'2',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:auto,min_doc_count:1),schema:segment,type:date_histogram),(id:'3',params:(filters:!((input:(query:(query_string:(analyze_wildcard:!t,query:'custom_key:%20%22data_api_call_count%22%20AND%20id:%20%22${clientId}%22'))),label:'API%20Request%20Count'))),schema:group,type:filters)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:data_api_call_count_status,type:line))`}>Click here to see below graph in kibana</a>
                        </div>
                        <iframe src={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${fromDate},mode:quick,to:now))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'*')),uiState:(vis:(legendOpen:!f)),vis:(aggs:!((id:'1',params:(),schema:metric,type:count),(id:'2',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:auto,min_doc_count:1),schema:segment,type:date_histogram),(id:'3',params:(filters:!((input:(query:(query_string:(analyze_wildcard:!t,query:'custom_key:%20%22data_api_call_count%22%20AND%20id:%20%22${clientId}%22'))),label:'API%20Request%20Count'))),schema:group,type:filters)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:data_api_call_count_status,type:line))&embed=true`} height="500" width="100%" className="border-0" ></iframe>

                    </div>
                }
            </CardBody>
        </Card>
    </Container >
}