import React from 'react';
import { useEffect, useState } from 'react';

import { Row, Card } from 'reactstrap';
import { useHistory } from "react-router-dom";
import StepZilla from "react-stepzilla";
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import axios from 'axios';

import { getItem, userLoggedin } from '../../../../localStorageMethods';
import RequirementsSubmissionCard from './Cards/RequirementsSubmissionCard.component';
import SiteDetails from './Stepzilla/SiteDetails/SiteDetails.component';
import SchemaDetails from './Stepzilla/SchemaDetails/SchemaDetails.component';
import AdditionalDetails from './Stepzilla/AdditionalDetails/AdditionalDetails.component';

export default function CreateNewOrganization(){
  let history = useHistory();
  const formikRef = React.useRef();
  const siteDetailsRef = React.useRef([
    {url:"", rss_urls_crawl_limit:""},
    {url:"", rss_urls_crawl_limit:""},
    {url:"", rss_urls_crawl_limit:""},
    {url:"", rss_urls_crawl_limit:""},
  ]);

  const [organizationDetails, setOrganizationDetails] = useState({})
  const [userEmail, setUserEmail] = useState(getItem('authEmail'))
  const [currentStep, setCurrentStep] = useState(0)

  const accessEnv = ['development','staging'];

  const token = localStorage.getItem("authToken");
  const email = localStorage.getItem("authEmail");
  const environment = localStorage.getItem("environment");

  useEffect(() => {
    axios.get(`/api/v1/app_status`).then(res => {
      if (res?.data?.app_status === "maintenance") {
        //history.push(res?.data?.maintain_url)
        window.location.replace(res?.data?.maintain_url)
      }
    }).catch(err => {
    })
  }, [])
  
  useEffect(() => {
    if (token && email) {
      axios.defaults.headers["X-Auth-Email"] = email;
      axios.defaults.headers["X-Auth-Token"] = token;
      axios.defaults.headers["Authorization"] = `Bearer ${token}`;
    }
  }, [token, email]);

  useEffect(() => {
    if (!userLoggedin()) {
      history.push('/users/sign_in')
    }
    axios.get(`/api/v1/user/details?email=${email}`).then(res => {
      if (res.data.user.organization_present_status && !(res.data.user.is_admin)) {
        history.push('/')
      }
    }).catch(err => {
      console.error(err?.response?.data?.message || 'Oops Something went wrong!');
    })
  }, []);

  // Helper function to handle logout
  async function handleLogout() {
    try {
      const response = await axios.delete(`/api/v1/users/logout`, {
        user: {
          email: localStorage.getItem("authEmail"),
          authenticity_token: localStorage.getItemhandleURL("authToken")
        }
      }
      )
    }
    catch (error) {
      toast.error(error?.response?.data?.message);
    }
    finally {
      localStorage.removeItem('authEmail');
      localStorage.removeItem('authToken');
      localStorage.removeItem('user_name');
      toast.success("User logged out successfully")
      history.push("/users/sign_in")
    }
  }

  const steps = [
    {
      name: 'Schema Details',
      component: <SchemaDetails formikRef={formikRef} accessEnv={accessEnv} />
    },
    {
      name: 'Site Details',
      component: <SiteDetails formikRef={formikRef} accessEnv={accessEnv} />
    },
    {
      name: 'Additional Details',
      component: <AdditionalDetails formikRef={formikRef} accessEnv={accessEnv} />
    },
  ];

  Yup.addMethod(Yup.object, 'unique', function (propertyName, message) {
    return this.test('unique', message, function (value) {
      if (!value || !value[propertyName]) {
        return true;
      }

      const { path } = this;
      const options = [...this.parent];
      const currentIndex = options.indexOf(value);
  
      const subOptions = options.slice(0, currentIndex);
  
      if (subOptions.some((option) => option[propertyName] === value[propertyName])) {
        throw this.createError({
          path: `${path}.${propertyName}`,
          message,
        });
      }
  
      return true;
    });
  });


  const conditionalSchema = Yup.object().shape({
    url: Yup.string().when("rss_urls_crawl_limit", {
      is: (rss_urls_crawl_limit) => rss_urls_crawl_limit?.length > 0 && accessEnv.includes(environment),
      then:() => Yup.string()
        .required("This field is required if rss urls crawl limit has value!")
        .min(1, "This field is required if rss urls crawl limit has value!")
        .matches(/^((www\.)|(http(s*):\/\/))/i, "Please enter a valid URL"),
      otherwise: () => Yup.string().matches(/^((www\.)|(http(s*):\/\/))/i, {message:"Please enter a valid URL", excludeEmptyString: true}),
    }),
    rss_urls_crawl_limit: Yup.string().when("url", {
      is: (url) => url?.length > 0 && accessEnv.includes(environment),
      then:() => Yup.string()
        .required('This field is required if url has value!')
        .min(1, 'This field is required if url has value!')
        .matches(/^\d+$/, "This field should be integer!"),
      otherwise: () => Yup.string(),
    })
  },['rss_urls_crawl_limit','url'])
  
  const site_details_schema = Yup.array().of(conditionalSchema.unique("url", "Entered URL is already present"))

  const validationSchema = Yup.object({
    organization_name: Yup.string()
      .required("Organization name is required"),
    industry: Yup.string()
      .min(1,"Industry is required")
      .required('Industry is required'),
    country: Yup.string()
      .min(1,"Country is required")
      .required('Country is required'),
    sitegroup_name: Yup.string()
      .required('This field is required')
      .min(2, 'Sitegroup Name must be at least 2 characters long')
      .matches(/^[\w-]+$/, 'Sitegroup Name should only contain letters, numbers, underscores, and hyphens'),
    crawl_type: Yup.string()
      .min(1,"This field is required")
      .required("This field is required"),
    data_delivery: Yup.string()
      .min(1,"This field is required")
      .required("This field is required"),
    rss_urls_crawl_limit: Yup.string()
      .when('/', {
        is: (value) => value && value.length > 0,
        then: Yup.string().test('is-integer', 'This field should be an integer!', (value) => /^\d+$/.test(value))
      }),
    data_format: Yup.string()
      .min(1,"This field is required")
      .required("This field is required"),
    frequency: Yup.string()
      .min(1,"This field is required")
      .required("This field is required"),
    weekly: Yup.string().when('frequency', {
      is: (frequency) => frequency == 'weekly',
      then: () => Yup.string().required("This field is required"),
    }),
    details: Yup.string().when('frequency', {
      is: (frequency) => frequency == 'other_frequency',
      then: () => Yup.string()
        .required("This field is required")
        .min(1, 'Minimum requirement - at least one crawl per month, for a minimum period of 3 months')
    }),
    monthly: Yup.date().when('frequency', {
      is: (frequency) => frequency == 'monthly',
      then: () => Yup.date().required('This field is required'),
    }),
    site_details: site_details_schema,
    schema_fields_details: Yup.array().of(
      Yup.object({
        field_name: Yup.string().required('This field is required'),
        data_type: Yup.string().min(1,"This field is required").required('This field is required'),
        is_mandatory: Yup.string().min(1,"This field is required").required('This field is required')
      }).unique("field_name","Field name should be unique")
    ),
  });

  return(
    <>
      <div className='pre-header d-flex justify-content-between align-items-center p-4'>
        <p className='m-0'>
          Hi {userEmail}!
        </p>
        <button className='btn btn-outline-secondary'
          onClick={handleLogout}>
          Logout
        </button>
      </div>
      <div className='mx-4 my-2'>
        <Card className='px-4 py-3 mycard'>
          <Row className='pt-2'>
            <h4 className='fw-light'>
              Submit Requirements
            </h4>
          </Row>
        </Card>
        <Formik
         initialValues={{
          organization_name:"",
          industry:"",
          country:"",
          sitegroup_name:"",
          crawl_type:"",
          data_delivery: "api",
          rss_urls_crawl_limit:"",
          data_format:"",
          frequency:"",
          monthly: new Date(),
          schema_fields_details:[
            {field_name:"uniq_id", data_type:"string", is_mandatory:"yes", default_value:"system_generated", sample_value:"default",comments:""},
            {field_name:"crawl_timestamp", data_type:"date", is_mandatory:"yes", default_value:"system_generated", sample_value:"default",comments:""},
            {field_name:"pageurl", data_type:"string", is_mandatory:"yes", default_value:"system_generated", sample_value:"",comments:""},
            {field_name: "", data_type: "", is_mandatory: "no", default_value: "", sample_value: "", comments: "" }
          ],
          site_details:[
            { url:"",rss_urls_crawl_limit:""},
            { url:"",rss_urls_crawl_limit:""},
            { url:"",rss_urls_crawl_limit:""},
            { url:"",rss_urls_crawl_limit:""},
          ],
          additional_information:""
         }}
         validationSchema={validationSchema}
         enableReinitialize={true}
         validateOnChange={false}
         validateOnBlur={true}
         innerRef={formikRef}
        >
          {({ isSubmitting, values, setFieldValue, validateForm , errors, setTouched, touched, validateField})=>{
            return(
              <Form>
                <RequirementsSubmissionCard />
                <Card className='px-5 py-4'>
                  <StepZilla
                    steps={steps}
                    preventEnterSubmission={true}
                    showSteps={true}
                    showNavigation={true}
                    stepsNavigation={true}
                    prevBtnOnLastStep={true}
                    dontValidate={false}
                    onStepChange={(step)=>{
                      if(step == 0) setTouched({...touched, site_details:{}})
                    }}
                    />
                </Card>
              </Form>
            )
          }}
        </Formik>
      </div>
      <div className='organization-footer p-3 d-flex align-items-center justify-content-center'>
        Copyright © 2021 PromptCloud All Rights Reserved.
        <a href='#' target='_blank'> Privacy Policy </a>
      </div>
    </>
  )
}