import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { UserOrganizationContext } from '../../Exports/ContextExports';
import axios from 'axios';
export default function UserOrganizationContextProvider({children}) {
    const BASE_URL = 'api/v1/my_organization';
    const [organization, setOrganization] = useState('');
    const value = {
        userOrganization:organization
    };

    useEffect(async()=>{
        const controller = new AbortController();
        await getOrganizationIdentifier(BASE_URL, controller.signal);
        // cleanup
        return ()=>{
            controller.abort();
        }
    },[]);

    async function getOrganizationIdentifier(url, signal) {
        try{
            const response = await axios.get(url, {signal:signal});
            const orgIdentifier = await response?.data?.organization?.org_identifier;
            setOrganization(orgIdentifier);
        }
        catch(error){
            console.log(error?.response?.data?.message);
        }
    }
  return (
    <div>
        <UserOrganizationContext.Provider value={value}>
            {children}
        </UserOrganizationContext.Provider>
    </div>
  )
}
