import React, { Fragment } from 'react'

import DataTable from 'react-data-table-component'

import { NewInternalBasePoint } from '../../../../Common/BaseEsPoints/InternalEsBasePoint';

export default function QualityAnalysisTable(props) {

  const columns = [
    {
      name: 'Site', 
      selector: 'site_name', 
      center: true, 
      sortable: true, 
      grow: 2,
      cell: (each) => <a href={`${NewInternalBasePoint}/qa/${each.site_name}`} target="_blank">{each.site_name}</a>
    },
    { name: 'Dev QA Status', selector: 'dev_site_qa_status_in_text', center: true, sortable: true, },
    { name: 'Dev QA Done By', selector: 'assignee1', center: true, sortable: true, },
    { name: 'Dev"s Comment', selector: 'dev_comment', center: true, sortable: true, },
    { name: 'BizQA status', selector: 'biz_site_qa_status_in_text', center: true, sortable: true, },
    { name: 'BizQA Done By', selector: 'assignee2', center: true, sortable: true, },
    { name: 'Biz"s Comment', selector: 'comment', center: true, sortable: true, },
    { name: 'Sample Record', selector: 'sample_data_record', center: true, sortable: true, },
    { name: 'Last Updated at', selector: 'updated_at', center: true, sortable: true, },
  ]

    return (
      <Fragment>
        <DataTable
          columns={columns}
          data={props?.data}
          noHeader
          responsive
          pagination/>
      </Fragment>
    )
  
}
