import React, {useState, useEffect} from 'react'
import { CardBody, Nav, NavItem, Card,TabContent, TabPane, 
    NavLink } from 'reactstrap';
import Breadcrumb from '../../global/Breadcrumb'
import CustomTable from '../../global/CustomTable'
import axios from 'axios'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function FeasibilityCheck() {
    const [BasicTab, setBasicTab] = useState('1');
    const [orgData, setOrgData] = useState([])
    const [siteData, setSiteData] = useState([])
    const [loading, setLoading] = useState(true)
    const [bizList, setBizList] = useState([])

    useEffect(() => {
        axios.get('/api/v1/admins/feasibility_checks/biz_assignee_list').then(res => {
            setBizList(res.data.biz_assignee_list)
        }).catch(err => {
            toast.error(err.response.data.message)
        })
    }, [])

    useEffect(() => {
        if(bizList.length > 0){
            axios.get('/api/v1/admins/feasibility_checks')
            .then(res => {
                const data = res.data;
                if (data.status == 'success'){
                    let organizations = data.feasibility_checks_list.new_organizations
                    let sites = data.feasibility_checks_list.new_sitegroups_or_sites
                    let temp1 = []
                    organizations.forEach(each => {
                        let tempObj = {
                            country_name: each.country_name,
                            created_at: each.created_at,
                            organization_name: each.organization_name,
                            user_email: each.user_email,
                            biz_assignee: <select defaultValue={each.biz_assignee}
                                onChange={e => handleOrgChange(e, each.organization_id, each.feasibility_check_id)} 
                                className='form-control show-arrow'>
                                <option value={null}>Select a Biz Assignee</option>
                                {bizList.map(each => (
                                    <option value={each} key={each}>{each}</option>
                                ))}
                            </select>,
                            actions: <Link to={`/admins/feasibility_checks/${each.feasibility_check_id}`} className='btn btn-primary'>
                                Check</Link>
                        }
                        temp1.push(tempObj)
                    })
                    let temp2 = []
                    sites.forEach(each => {
                        let tempObj = {
                            country_name: each.country_name,
                            created_at: each.created_at,
                            organization_name: each.organization_name,
                            sitegroup_name: each.sitegroup_name,
                            sitegroup_status: each.sitegroup_status,
                            user_email: each.user_email,
                            biz_assignee: <select defaultValue={each.biz_assignee}
                                onChange={e => handleSiteChange(e, each.organization_id, each.feasibility_check_id)} 
                                className='form-control show-arrow'>
                                <option value={null}>Select a Biz Assignee</option>
                                {bizList.map(each => (
                                    <option value={each} key={each}>{each}</option>
                                ))} </select>,
                            actions: <Link to={`/admins/feasibility_checks/${each.feasibility_check_id}`} className='btn btn-primary'>
                                Check</Link>
                        }
                        temp2.push(tempObj)
                    })
                    setOrgData(temp1)
                    setSiteData(temp2)
                    setLoading(false)
                }
            })
        }
    }, [bizList])

    const handleOrgChange = (e, orgID, feasID) => {
        axios.put('/api/v1/admins/feasibility_checks/update_biz_contact', {
            organization_id: orgID, biz_contact_email: e.target.value
        }).then(res => {
            toast.success(res.data.message)
        }).catch(err => {
            toast.error(err.response.data.message)
        })
    }

    const handleSiteChange = (e, orgID, feasID) => {
        axios.put('/api/v1/admins/feasibility_checks/update_biz_contact', {
            organization_id: orgID, biz_contact_email: e.target.value
        }).then(res => {
            toast.success(res.data.message)
        }).catch(err => {
            toast.error(err.response.data.message)
        })
    }

    const orgColumns = [
        {name: 'Organization',selector: 'organization_name',sortable: true,center:true,},
        {name: 'Country',selector: 'country_name',sortable: true,center:true,},
        {name: 'User',selector: 'user_email',sortable: true,center:true},
        {name: 'Created At',selector: 'created_at',sortable: true,center:true},
        {name: 'Biz Assignee',selector: 'biz_assignee',center:true},
        {name: 'Actions',selector: 'actions',center:true},
    ];

    const siteColumns = [
        {name: 'Sitegroup',selector: 'sitegroup_name',sortable: true,center:true,},
        {name: 'Status',selector: 'sitegroup_status',sortable: true,center:true,},
        {name: 'Organization',selector: 'organization_name',sortable: true,center:true},
        {name: 'Country',selector: 'country_name',sortable: true,center:true},
        {name: 'User',selector: 'user_email',sortable: true,center:true},
        {name: 'Created At',selector: 'created_at',sortable: true,center:true},
        {name: 'Biz Assignee',selector: 'biz_assignee',center:true},
        {name: 'Actions',selector: 'actions',center:true},
    ];
    return (
        <div className='container container-fluid pt-2 feasibility__check'>
            {!loading && 
            <>
            <Breadcrumb parent={"Feasibility Check"} title={"New Requirements"} />
            <Card>
                <CardBody>
                    <Nav tabs>
                        <NavItem>
                            <NavLink href="#" 
                                className={BasicTab === '1' ? 'active' : ''}
                                onClick={() => setBasicTab('1')}>
                                    New organizations
                            </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink href="#" 
                            className={BasicTab === '3' ? 'active' : ''} 
                            onClick={() => setBasicTab('3')}>
                                New sitegroups/sites</NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={BasicTab}>
                        <TabPane className="fade show" tabId="1">
                            <CustomTable data={orgData} 
                                columns={orgColumns} />
                        </TabPane>
                        <TabPane tabId="3">
                        <CustomTable data={siteData} 
                            columns={siteColumns} />
                        </TabPane>
                    </TabContent> 
                </CardBody>
            </Card>
            </>}
        </div>
    )
}
