import React, {useState, useEffect} from 'react'
import { Form, FormGroup, Input, Label, Button } from 'reactstrap'
import { setItem, userLoggedin, userRegistered } from  '../localStorageMethods'
import {useForm} from 'react-hook-form'
import { toast } from 'react-toastify'
import { useHistory } from "react-router-dom";
import axios from 'axios'
import Loader from 'react-loader-spinner'
import ReCAPTCHA from "react-google-recaptcha";

export default function Users_signin() {
  let history = useHistory();

  const [togglePassword,setTogglePassword] = useState(false)
  const [selected, setSelected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingBtns, setLoadingBtns] = useState(false)

  const [recaptcha, setRecaptcha] = useState("")
  const [recaptchaSiteKey, setRecaptchaSiteKey] = useState("")

  useEffect(() => {
    axios.get('/api/v1/google_recaptcha_site_key?page_type=client&controller_name=users').then((response) => {
      setRecaptchaSiteKey(response?.data?.recaptcha_site_key)
    });
  }, [])

  function onChange(value) {
    setRecaptcha(value);
  }

  useEffect(() => {
    axios.get(`/api/v1/app_status?page_type=client&controller_name=dashboard`).then(res => {
      if (res?.data?.app_status === "maintenance") {
        //history.push(res?.data?.maintain_url)
        window.location.replace(res?.data?.maintain_url)
      }
    }).catch(err => {
    })
  }, [])

  useEffect(() => {
    if (userLoggedin()){
      toast.warning('You are loggedin already')
      history.push('/')
    }
    else if (userRegistered()){
      toast.warning('Please complete your signup')
      history.push('/users/confirm_email')
    }
    setLoading(false)
  }, [])

  const HideShowPassword  = (tPassword) => {
    setTogglePassword(!tPassword)
  }

  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = data => {
    if (data !== '') {
      handleSignIn(data)
    } else {
      errors.showMessages();
    }
  };

  const handleSignIn = (data) => {
    setLoadingBtns(true)
    axios.post('/api/v1/users/login', {
      user: {email: data.email, password: data.passwd},
      "g-recaptcha-response": recaptcha,
      controller_name: "sessions",
      page_type: "client"
    }).then(res => {
      const data1 = res.data
      setItem('authToken', data1.authenticity_token)
      setItem('authEmail', data1.user_email)
      setItem('user_name', data1.user_name)
      setItem('environment', data1.env)
      toast.success(`${data1.message}`)
      if(data1.has_organization || data1.admin_user){
        const redirectUrl = sessionStorage.getItem('redirectUrl')
        if (redirectUrl){
          window.location = redirectUrl
          sessionStorage.removeItem('redirectUrl')
        }else{
          history.push('/')
        }
      }
      else{
        history.push('/organizations/new')
      }
      setLoadingBtns(false)
    })
      .catch(res => {
        if (res.response?.status == 422){
          window.location.reload(true)
        }
        toast.error(res.response.data.message)
        setLoadingBtns(false)
      })
  }

  return (
    <>
      {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
          : (<div className='login-card'>
            <div className='login-main login-tab'>
              <Form className="theme-form needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                <h4>Sign In</h4>
                <p>Please enter your email & password to login</p>
                <FormGroup>
                  <Label className="col-form-label">Email Address</Label>
                  <input className="form-control" id="email"
                    {...register("email", { required: true })}
                    type="email" placeholder="test@gmail.com"/>
                  <span className="error-msg">{ errors.email && 'Email is required'}</span>
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">Password</Label>
                  <input className="form-control" 
                    type={togglePassword ?  "text" : "password" } id="passwd"
                    {...register("passwd", { required: true })} placeholder="****"/>
                  <div className="show-hide" 
                    onClick={() => HideShowPassword(togglePassword)}>
                    <span className={togglePassword ? "Hide" : "show"}></span>
                  </div>
                  <span className="error-msg">{errors.passwd && 'Password is required'}</span>
                </FormGroup>
                <div className="form-group mb-0">
                  <div className="checkbox ml-3">
                    <Input id="checkbox1" type="checkbox" checked={selected}
                      onChange={(e) => setSelected(!selected)} />
                    <Label className="text-muted" for="checkbox1"> Remember Me </Label>
                  </div>
                  <FormGroup>
                  </FormGroup>
                  { recaptchaSiteKey &&
                  <FormGroup>
                    <ReCAPTCHA
                      sitekey={recaptchaSiteKey}
                      onChange={onChange}
                    />
                  </FormGroup>
                  }
                  <a className="link" href="/users/password/new"> Forgot Password ?</a>
                  <button type='submit' className="btn btn-primary btn-block w-100 m-t-5"
                    disabled={loadingBtns}>
                    Sign In
                  </button>
                </div>
                <p className="mt-4 mb-0">Don't have account?
                  <a className="ml-2" href="/users/sign_up"> Register here </a>
                </p>
              </Form>
            </div>
        </div>)}
      </>
  )
}
