import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";
import moment from "moment";
import AsyncSelect from 'react-select/async';
import axios from "axios";

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function OverallRecordsCountBySiteWise() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const tabNameFromSearchUrl = new URLSearchParams(search).get("filter[tab_name]");
  const tabNameFromUrl = window.location.hash.substr(1);
  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[site_name]"),
    pipelineStage: new URLSearchParams(search).get("filter[pipeline_stage]"),
    fromTime: new URLSearchParams(search).get("filter[time]"),
    tabName: tabNameFromSearchUrl ? tabNameFromSearchUrl : tabNameFromUrl
  }
  const [siteName, setSiteName] = useState({label: 'All', value: '*'});
  const [siteNameOptions, setSiteNameOptions] = useState([]);
  const [pipelineStage, setPipelineStage] = useState({ label: 'All', value: '*' });
  const [fromTime, setFromTime] = useState({ label: 'Last 4 hours', value: 'now-4h' });
  const [activeTab, setActiveTab] = useState(tabNameFromUrl ? tabNameFromUrl : 'rss');

  const pipelineStageOptions = [
    { label: 'All', value: '*' },
    { label: 'Rss', value: 'rss' },
    { label: 'Feedcrawl', value: 'diskfetcher' },
    { label: 'Extraction', value: 'extraction' },
    { label: 'Dedup', value: 'dedup' },
    { label: 'Normalization', value: 'normalization' },
    { label: 'Upload', value: 'upload' },
  ]

  const timeOptions = [
    { label: 'Today', value: 'now%2Fd' },
    { label: 'Last 30 minutes', value: 'now-30m' },
    { label: 'Last 1 hour', value: 'now-1h' },
    { label: 'Last 2 hours', value: 'now-2h' },
    { label: 'Last 4 hours', value: 'now-4h' },
    { label: "Last 10 hours", value: "now-10h" },
    { label: 'Last 12 hours', value: 'now-12h' },
    { label: 'Last 24 hours', value: 'now-24h' },
    { label: 'Last 2 days', value: 'now-2d' },
    { label: 'Last 3 days', value: 'now-3d' },
    { label: 'Last 4 days', value: 'now-4d' },
    { label: 'Last 7 days', value: 'now-7d' },
    { label: 'Last 15 days', value: 'now-15d' },
    { label: 'Last 30 days', value: 'now-30d' },
    { label: 'Last 60 days', value: 'now-60d' },
    { label: 'Last 1 year', value: 'now-1y' }
  ];

  const [UrlParams, setUrlParams] = useState({
    siteName: "*",
    pipelineStage: '*',
    fromTime: 'now-4h',
    tabName: "rss"
  })

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let site_names = res.data.site_names, site_names_options = []
        for (var key in site_names) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
            site_names[key].map(item => {
              children.push({ label: item, value: item })
            })
          group['options'] = children;
          site_names_options.push(group)
        }
        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: '*' }] })
        setSiteNameOptions(site_names_options);
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      });
  }, []);

  function titleize(value){
    return value.substr(0,1).toUpperCase() + value.substr(1).toLowerCase();
  }

  function pipelineStageLabel(){
    var labelValue;
    if (searchParams.pipelineStage == '*'){
      labelValue = "All";
    }
    else if (searchParams.pipelineStage == 'diskfetcher'){
      labelValue = "Feedcrawl";
    }
    else {
      labelValue = titleize(searchParams.pipelineStage);
    }
    return labelValue;
  }

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.siteName && searchParams.siteName != siteName.value) {
      setSiteName({ label: searchParams.siteName  == '*' ? 'All' :  searchParams.siteName, value: searchParams.siteName });
    }
    if (searchParams.pipelineStage && searchParams.pipelineStage != pipelineStage.value) {
      setPipelineStage({
        label: pipelineStageLabel(),
        value: searchParams.pipelineStage
      });
    }
    if (searchParams.fromTime && searchParams.fromTime != fromTime.value) {
      setFromTime(timeOptions.filter(item => item.value == searchParams.fromTime)[0]);
    }
    if (searchParams.tabName && searchParams.tabName != activeTab) {
      setActiveTab(searchParams.tabName);
    }
    if (commit == 'Get Data' && searchParams.fromTime && searchParams.pipelineStage && searchParams.siteName) {
      setUrlParams(searchParams)
    }
  }

  function rssKibanaUrl(){
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${UrlParams.fromTime}',mode:quick,to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'rss_status_tracker+:+true+and+site_name:+${UrlParams.siteName}+'),uiState:(vis:(colors:(Rss:%230A437C))),vis:(aggs:!((id:'1',params:(customLabel:Rss,field:pushed_urls_count),schema:metric,type:sum),(id:'2',params:(field:site_name,order:desc,orderBy:'1',size:20),schema:segment,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Overall%20Records%20Count%20By%20Site%20Wise%20-%20Rss',type:histogram))`
  }

  function feedCrawlKibanaUrl(){
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${UrlParams.fromTime}',mode:quick,to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'diskfetcher_crawl_time_tracker+:true+and+site_name+:+${UrlParams.siteName}+'),uiState:(vis:(colors:(Feedcrawl:%23629E51))),vis:(aggs:!((id:'2',params:(customLabel:Feedcrawl,field:crawled_urls_count),schema:metric,type:sum),(id:'3',params:(field:site_name,order:desc,orderBy:'2',size:20),schema:segment,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Overall%20Records%20Count%20By%20Site%20Wise%20-%20Feedcrawl',type:histogram))`
  }

  function ednuKibanaUrl(pipeline_stage_val, field){
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${UrlParams.fromTime}',mode:quick,to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'ednu_status_tracker+:+true+AND+ednu_stage+:+${pipeline_stage_val}+and+site_name+:+${UrlParams.siteName}+'),uiState:(),vis:(aggs:!((id:'5',params:(customLabel:${titleize(pipeline_stage_val)},field:${field}),schema:metric,type:sum),(id:'6',params:(field:site_name,order:desc,orderBy:'5',size:20),schema:segment,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Overall+Records+Count+By+Site+Wise+-+${titleize(pipeline_stage_val)}',type:histogram))`
  }

  const handleGetData = () => {
    window.location.href = `/internal/overall_system_reports/overall_records_count_by_site_wise?filter[site_name]=${siteName.value}&filter[pipeline_stage]=${pipelineStage.value}&filter[time]=${fromTime.value}&filter[tab_name]=${activeTab}&commit=${'Get Data'}`
  }

  const promiseOptions = (inputValue) => {
    if(inputValue.length < 3){
      return null
    } else {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200'){
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
              site_names[key].map(item => {
                children.push({ label: item, value: item })
              })
              group['options'] = children;
              site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setSiteNameOptions(site_names_options)
            resolve(site_names_options)
          }
        }).catch(err => {
          console.log(`err`, err)
        })
      })
    }
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/overall_system_reports/overall_records_count_by_site_wise">Overall Records Count By Site Wise</a> : 'Overall Records Count By Site Wise'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                Search
                </BreadcrumbItem>}
          </Breadcrumb>
        </Col>
      </Row>
    </div>

    <Card>
      <CardBody>
        <Row>
          <Col sm="8">
            <label className="fw-bold">Site Name</label>
            <AsyncSelect cacheOptions defaultOptions={siteNameOptions} name='site_name' 
                value={siteName} loadOptions={promiseOptions} onChange={res => setSiteName(res)}
                placeholder="Select a site name" />
                <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
          </Col>
        </Row>
        <Row className="align-items-end mt-2">
          <Col sm="4">
            <label className="fw-bold">Pipeline Stage</label>
            <Select
              value={pipelineStage}
              options={pipelineStageOptions}
              onChange={option => setPipelineStage(option)} />
          </Col>
          <Col sm="4">
            <label className="fw-bold">Time</label>
            <Select
              value={fromTime}
              options={timeOptions}
              onChange={option => setFromTime(option)} />
          </Col>
          <Col sm="4">
            <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
          </Col>
        </Row>
      </CardBody>
    </Card>
    <Card>
      <CardBody>
        <div className="mb-1">
          <b>NOTE:</b>
          If the graph is not shown after your form submission then you should do either maximize its size(the option is available on the right side of graph) or minimize the sidebar.
        </div>
        <Nav tabs>
          <NavItem>
            <NavLink className={activeTab == 'rss' ? 'active' : ''} onClick={() => setActiveTab('rss')}>
              Rss
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab == 'feedcrawl' ? 'active' : ''} onClick={() => setActiveTab('feedcrawl')}>
              Feedcrawl
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab == 'extraction' ? 'active' : ''} onClick={() => setActiveTab('extraction')}>
              Extraction
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab == 'dedup' ? 'active' : ''} onClick={() => setActiveTab('dedup')}>
              Dedup
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab == 'normalization' ? 'active' : ''} onClick={() => setActiveTab('normalization')}>
              Normalization
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab == 'upload' ? 'active' : ''} onClick={() => setActiveTab('upload')}>
              Upload
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={'rss'}>
            {(pipelineStage?.value == '*' || pipelineStage?.value == 'rss') && <div className="panel-body">
              <center>
                <span className="fw-bold">Rss</span><br />
                <a target="_blank" className="link-primary" href={rssKibanaUrl()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={rssKibanaUrl() + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
            </div>}
          </TabPane>
          <TabPane tabId={'feedcrawl'}>
            {(pipelineStage?.value == '*' || pipelineStage?.value == 'diskfetcher') && <div className="panel-body">
              <center>
                <span className="fw-bold">Feedcrawl</span><br />
                <a target="_blank" className="link-primary" href={feedCrawlKibanaUrl()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={feedCrawlKibanaUrl() + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
            </div>}
          </TabPane>
          <TabPane tabId={'extraction'}>
            {(pipelineStage?.value == '*' || pipelineStage?.value == 'extraction') && <div className="panel-body">
              <center>
                <span className="fw-bold">Extraction</span><br />
                <a target="_blank" className="link-primary" href={ednuKibanaUrl("extraction", "extract_output_record_count")}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={ednuKibanaUrl("extraction", "extract_output_record_count") + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
            </div>}
          </TabPane>
          <TabPane tabId={'dedup'}>
            {(pipelineStage?.value == '*' || pipelineStage?.value == 'dedup') && <div className="panel-body">
              <center>
                <span className="fw-bold">Dedup</span><br />
                <a target="_blank" className="link-primary" href={ednuKibanaUrl("dedup", "upload_count")}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={ednuKibanaUrl("dedup", "upload_count") + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
            </div>}
          </TabPane>
          <TabPane tabId={'normalization'}>
            {(pipelineStage?.value == '*' || pipelineStage?.value == 'normalization') && <div className="panel-body">
              <center>
                <span className="fw-bold">Normalization</span><br />
                <a target="_blank" className="link-primary" href={ednuKibanaUrl("normalization", "normalized_count")}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={ednuKibanaUrl("normalization", "normalized_count") + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
            </div>}
          </TabPane>
          <TabPane tabId={'upload'}>
            {(pipelineStage?.value == '*' || pipelineStage?.value == 'upload') && <div className="panel-body">
              <center>
                <span className="fw-bold">Upload</span><br />
                <a target="_blank" className="link-primary" href={ednuKibanaUrl("upload", "upload_count_per_segment")}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={ednuKibanaUrl("upload", "upload_count_per_segment") + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
            </div>}
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  </Container>
}

