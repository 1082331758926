// React imports
import React from 'react';

import { Alert } from 'reactstrap';

export default function PaymentUnderProcessNotifier({ underProcess }) {
  if (underProcess) {
    return (
      <div>

        <Alert color='primary' className='mb-3'>
          Payment is being processed, please refresh the page after a minute to downoad the invoice.
        </Alert>

      </div>
    )
  }
  return (null);
}
