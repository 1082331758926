import React, { Fragment, useState, useEffect, useContext } from 'react';
import {
  Activity, LogIn, HardDrive, User,
  Bell, Search, HelpCircle, Award, Plus, List 
} from 'react-feather';
import { useHistory } from 'react-router-dom'
import { translate } from 'react-switch-lang';
import { toast } from 'react-toastify'
import moment from 'moment'
import { CheckAllNotification } from '../sidebar/theme-customizer/constant'
import axios from 'axios'
import OrgChangeModal from './OrgChangeModal';
import '../../stylesheets/custom.scss';
import HelperText from './HelperText';
import { OrgnaziationContext } from '../Home';



const Rightbar = ({ orgName, internalDashboard }) => {
  const history = useHistory();
  const [name, setName] = useState('');
  const [searchresponsive, setSearchresponsive] = useState(false);
  const [moonlight, setMoonlight] = useState(false);
  const [user, setUser] = useState({});
  const [notificationDropDown, setNotificationDropDown] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const email = localStorage.getItem("authEmail");
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [userId, setUserId] = useState(0);
  const [userSelectedOrg, setUserSelectedOrg] = useState('');
  const {setOrgName} = useContext(OrgnaziationContext);


  useEffect(() => {
    setName(localStorage.getItem('Name'))
    if (localStorage.getItem("layout_version") === "dark-only") {
      setMoonlight(true)
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("authEmail")) {
      setLoading(true)

      getUserData();
    }
    // Cleanup
    return()=>{
      setUser({});
      setUserId(0);
    }
  }, []);


  useEffect(() => {
    if (localStorage.getItem("authEmail") && orgName !== '') {
      getNotifications(orgName)
    }
    setUserSelectedOrg('');
  }, [orgName])

  const getUserData = async() =>{
    try{
      const data = (await axios.get(`/api/v1/user/details?page_type=client&controller_name=dashboard&email=${email}`)).data;
      setUser(data.user);
      setUserId(data.user.id);
      setLoading(false);
    }
    catch(error){
      console.error(error.message);
      setLoading(false);
    }
  };


  const getNotifications = (id) => {
    axios.get(`/api/v1/organizations/${id}/unread_notifications?page_type=client&controller_name=notifications`).then(res => {
      setNotifications(res.data.notifications)
    }).catch(err => {
      setNotifications([])
      if (err.response.status == 401){
        history.push('/');
      }
      toast.error(err.response.data.message)
    })
  }

  const UserMenuRedirect = (redirect) => {
    history.push(redirect)
  }

  const handleLogout = () => {
    axios.delete(`/api/v1/users/logout?page_type=client&controller_name=sessions`, {
      user: {
        email: localStorage.getItem("authEmail"),
        authenticity_token: localStorage.getItem("authToken")
      }
    }).then(res => {
      toast.success(res.data?.message)
    }).catch(err => {
      console.log('err', err);
    })
    localStorage.removeItem('authEmail');
    localStorage.removeItem('authToken');
    localStorage.removeItem('user_name');
    history.push("/users/sign_in")
  }


  const SeacrhResposive = (searchresponsive) => {
    if (searchresponsive) {
      setSearchresponsive(!searchresponsive)
      document.querySelector(".search-full").classList.add("open");
      document.querySelector(".more_lang").classList.remove("active");
    } else {
      setSearchresponsive(!searchresponsive)
      document.querySelector(".search-full").classList.remove("open");
    }
  }


  const toggle = () => {
    setModal(!modal);
  };


  // const onSubmitHandler = () => {
  //   if(userSelectedOrg !== ''){
  //     setOrgName(userSelectedOrg);
  //     history.push('/');
  //     // toggle();
  //   }
  //   else{
  //     toast.error("Organization name cannot be empty!!");
  //   }
  // };

  const onOptionSelectHandler = (event) => {
    setUserSelectedOrg(userSelectedOrg);
    let { value } = event.target;
    setUserSelectedOrg(value);
  };

//   const updateOrganization = () => {
//     if (selectedOrg === ""){
//         toast.error("Please select organization")
//     }
//     else{
//         setLoadingBtns(true)
//         axios.put(`/api/v1/default_org/${profile_id}`, {
//             user: {
//                 default_organization_id: selectedOrg
//             }
//         }).then(res => {
//             toast.success(res.data.message)
//             setModal3(false)
//             setLoadingBtns(false)
//             setToggleData(curr => !curr)
//         }).catch(err => {
//             toast.error(err.response.data.message)
//             setLoadingBtns(false)
//         })
//     }
// }

const changeOrganization = async () => {
     if (userSelectedOrg === ""){
        toast.error("Please select organization");
        return;
    }
    else{
        setLoading(true)
        await axios.put(`/api/v1/default_org/${userId}`, {
            user: {
                default_organization_id: userSelectedOrg
            }
        }).then(res => {
            setLoading(false);
            toast.success("Organization Changed Successfully!");
            setTimeout(()=>{
              history.push('/');
              location.reload();
              toggle();
            }, 1000)
            
        }).catch(err => {
            toast.error(err.response.data.message)
            setLoading(false)
        })
    }
}

const CurrentOrgPCMaster = async () => {
  setLoading(true)
        await axios.put(`/api/v1/default_org/${userId}`, {
            user: {
                default_organization_id: "582"
            }
        }).then(res => {
            toast.success("Organization Changed Successfully!");
            setLoading(false)
            toggle();
        }).catch(err => {
            toast.error(err.response.data.message)
            setLoading(false)
        })
}

  return (
    <Fragment>
      <div className="nav-right col-12 pull-right right-header p-0">
        <ul className="nav-menus">
          {
            orgName === 'promptcloud'?
            <HelperText />
            :''
          }
          {
            !internalDashboard && (
              <OrgChangeModal 
                toggle = {toggle} 
                isOpen={modal} 
                disabled = {false}
                onButtonClickedHandler={changeOrganization} 
                orgName={orgName} 
                userId={userId}
                onOptionSelectHandler={onOptionSelectHandler}  
              />
            )
          }
           

          {!(internalDashboard) && <li className="onhover-dropdown">
            <div className="notification-box" onClick={() => setNotificationDropDown(!notificationDropDown)}><Bell /><span className="badge badge-pill badge-secondary">
              {notifications.length}
            </span>
            </div>
            <ul className={`notification-dropdown onhover-show-div ${notificationDropDown ? "active" : ""}`}
              style={{ width: 350 }}>
              <li>
                <Bell />
                <h6 className="f-18 mb-0">Notification</h6>
              </li>
              {notifications?.map(each => (
                <li key={each.id}>
                  <div onClick={() => {
                    axios.post(`/api/v1/organizations/${orgName}/notifications/${each.id}/mark_as_read`).then(res => {
                      getNotifications(orgName)
                      history.push(each.url)
                    })
                  }}
                    style={{ fontSize: 13 }} className='text-dark p-2'><i className="fa fa-circle-o me-2 font-primary"></i>
                    {each.notification} <span className="pull-right">
                      {moment(each?.created?.substring(0, 10), "YYYY-MM-DD").fromNow()}
                    </span></div>
                </li>
              ))}
              <li><a href='/notifications/all_notifications' className="btn btn-primary" >{CheckAllNotification}</a>
              </li>
            </ul>
          </li>}
          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media">
              <div className="media-body"><span>{name}</span>
                <p className="mb-0 font-rubik">
                  {localStorage.getItem('authEmail')}
                  <span><i className="middle fa fa-angle-down"></i></span>
                  
                </p>
              </div>
            </div>
            {!loading && <ul className="profile-dropdown onhover-show-div">
              {!(internalDashboard) && <li onClick={() => UserMenuRedirect(`/organizations/${orgName}`)}>
                <HardDrive /><span>Organization</span>
              </li>}
              {!(internalDashboard) && user.new_org_creation_authorized && <li onClick={() => UserMenuRedirect(`/organizations/new`)}>
                <Plus /><span>Create New Organization</span>
              </li>}
              {!(internalDashboard) && <li onClick={() => UserMenuRedirect(`/organizations/${orgName}/manage_users`)}>
                <List /><span>Manage Users</span>
              </li>}
              <li onClick={() => UserMenuRedirect(`/profile/${user?.id}`)}>
                <User /><span>Profile</span>
              </li>
              {!(internalDashboard) && <li onClick={() => UserMenuRedirect(`/faq`)}>
                <HelpCircle /><span>FAQ</span>
              </li>}
              {!(internalDashboard) && <li onClick={() => UserMenuRedirect(`/help`)}>
                <HelpCircle /><span>Help</span>
              </li>}
              <li onClick={handleLogout}><LogIn /><span>Log out</span></li>
            </ul>}
          </li>
        </ul>
      </div>
    </Fragment>

  );
}
export default translate(Rightbar);
