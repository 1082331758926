import React from 'react';

import { Clock } from 'react-feather';

export default function RedmineTickets({ organization_name, data }) {
  return (
    <div>
      <div className='my-3 bg-light px-3 py-4' style={{ borderLeft: '2px solid #0d6efd' }}>
        <a href={`/organizations/${organization_name}/issues/${data?.id}`}
          className={`badge bg-${data?.tracker_name === "NewRequirement" ? 'success' : data?.tracker_name === 'OtherIssues' ? 'info' : data.tracker_name === 'DataIssues' ? 'danger' : 'primary'} text-white text-end float-end`}>
          {data?.tracker_name} #{data?.id}
        </a>
        <div></div>
        <p className='text-dark mt-4 mb-3 pt-2'>
          {data?.subject}
        </p>
        <div></div>
        <div className='d-flex justify-content-between align-items-center'>
          <p className='text-muted m-0'>
            <Clock size={12} /> {data?.updated_on}
          </p>
          <a href='#' className='btn badge bg-white fw-light text-dark'
            style={{ fontSize: 12, padding: '4px 10px' }}>
            {data?.author_name}
          </a>
        </div>
      </div>
    </div>
  )
}
