import React, {useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import Breadcrumb from '../../global/Breadcrumb';
import StepZilla from "react-stepzilla";
import { Card, Container } from 'reactstrap';
import axios from 'axios'
import Feasibility_SchemaDetails from './Feasibility_SchemaDetails';
import Feasibility_SiteDetails from './Feasibility_SiteDetails';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import Loader from "react-loader-spinner";

export default function EditFeasibilityCheck() {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    let history = useHistory()
    const [dataToggle, setDataToggle] = useState(true)
    const [globalTableData, setGlobalTableData] = useState({})
    const [dataToggle2, setDataToggle2] = useState(false)
    const [v2Data, setV2Data] = useState({})
    let { feasid } = useParams();

    useEffect(() => {
        setLoading(true)
        axios.get(`/api/v1/admins/feasibility_checks/${feasid}`).then(res => {
            if (!(res.data.feasibility_report?.feasibility_report_details?.can_edit)){
                history.push(`/admins/feasibility_checks/${res.data.feasibility_report.id}`)
            }
            setLoading(false)
        }).catch(err => {
            toast.error(err.response.data.message)
            setLoading(false)
        })
    }, [dataToggle])

    useEffect(() => {
        axios.get(`/api/v1/admins/feasibility_checks/${feasid}/edit?feasibility_report_id=${feasid}`).then(res => {
            setData(res.data.feasibility_report)
        }).catch(err => {
            toast.error(err.response.data.message)
        })
    }, [dataToggle, dataToggle2])

    const steps =
        [
            { name: 'Schema Details', 
                component: <Feasibility_SchemaDetails data={data} 
                    v2Data={v2Data} setV2Data={setV2Data} dataDisable={true} 
                   setDataToggle={setDataToggle} globalTableData={globalTableData}
                   dataToggle={dataToggle} setGlobalTableData={setGlobalTableData} /> },
            { name: 'Site Details', component: <Feasibility_SiteDetails data={data} 
               setDataToggle={setDataToggle} dataToggle={dataToggle} setDataToggle2={setDataToggle2}
               v2Data={v2Data} setV2Data={setV2Data} dataDisable={true}
               globalTableData={globalTableData} setGlobalTableData={setGlobalTableData} /> },
    ]

    return (
        <Container fluid>
            <Breadcrumb parent={"Feasibility Check"} title={feasid}
                childrenObj={[{name: 'Feasibility Checks', url: '/admins/feasibility_checks'}]} />

            {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />: 
            <div>
                <Card className='p-5'>
                    <StepZilla steps={steps} showSteps={true} 
                        showNavigation={true} stepsNavigation={true}
                        prevBtnOnLastStep={true} dontValidate={false} />
                </Card>
            </div>}
        </Container>
    )
}
