import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Card, CardBody } from "reactstrap";
import DatePicker from "react-datepicker";
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import axios from 'axios';
import moment from "moment";
import { toast } from 'react-toastify';

import { NewInternalBasePoint } from '../../../../Common/BaseEsPoints/InternalEsBasePoint';

const crawlStatusOptions = [
    { label: 'Any', value: 'any' },
    { label: 'started', value: 'started' },
    { label: 'done', value: 'done' },
    { label: 'failed', value: 'failed' }
]

const extractStatusOptions = [
    { label: 'All other than done', value: 'all_other_than_done'},
    { label: 'started', value: 'started' },
    { label:  'extractiondone', value:'extractiondone'},
    { label: 'failed', value: 'failed' },
]

export default function SiteSearchCard(props) {

    const DROPDOWN_LIST_OPTIONS_API_ENDPOINT = '/api/v1/internal/dropdown_list_helper';
    const SEARCH_SITE_NAME_API_ENDPOINT = '/api/v1/internal/search_site_names?site_name=';


    const search = useLocation().search;
    const commit = new URLSearchParams(search).get("commit");
    const extractstatus = new URLSearchParams(search).get("filter[extractstatus]");
    const crawlstatus = new URLSearchParams(search).get("filter[crawlstatus]");

    const searchParams = {
        siteName: new URLSearchParams(search).get("filter[site]"),
        extractStatus: extractstatus ? extractstatus : "all_other_than_done",
        crawlStatus: crawlstatus ? crawlstatus : "any",
        fromDate: new URLSearchParams(search).get("filter[crawldate][from]"),
        toDate: new URLSearchParams(search).get("filter[crawldate][to]"),
    }

    const [UrlParams, setUrlParams] = useState({
        siteName: '',
        extractStatus: 'all_other_than_done',
        crawlStatus: 'any',
        fromDate: new Date(moment().subtract(2, 'days')),
        toDate: now
    })

    const now = new Date();
    const [siteName, setSiteName] = useState({ label: '', value: '' });
    const [fromDate, setFromDate] = useState(new Date(moment().subtract(2, 'days')));
    const [toDate, setToDate] = useState(now);
    const [siteNameOptions, setSiteNameOptions] = useState([{ label: null, value: null }]);
    const [extractStatus, setExtractStatus] = useState({ label: 'All other than done', value: 'all_other_than_done' });
    const [crawlStatus, setCrawlStatus] = useState({ label: 'Any', value: 'any' });


    // useEffect for when card is loaded
    useEffect(async () => {
        const controller = new AbortController();

        await setSearchParamsData();
        await fetchDropdownOptionsFromDB(controller.signal);

        return () => {
            controller.abort();
        }
    }, []);

    // Helper function to fetch dropdown options from backend
    async function fetchDropdownOptionsFromDB(signal) {
        try {
            const res = await axios.get(DROPDOWN_LIST_OPTIONS_API_ENDPOINT, { signal: signal });
            const siteNames = res?.data?.site_names;
            await optionsDataFormatter(siteNames);
        }
        catch (error) {
            toast.error(error?.message || 'Oops something went wrong');
        }
    };


    // Helper function to fetch name of site based on site name entered by user in async-select
    async function onAsyncSelectInputChangeHandler(inputValue) {
        if (inputValue.length < 3) {
            return null
        } else {
            return new Promise((resolve) => {
                axios.get(SEARCH_SITE_NAME_API_ENDPOINT + inputValue).then(res => {
                    if (res.status == '200') {
                        // console.log(res.data)
                        let site_names = res.data.site_names, site_names_options = []
                        for (var key in site_names) {
                            let group = {}, children = []
                            group['label'] = <div className="fw-bold col text-black">{key}</div>
                            site_names[key].map(item => {
                                children.push({ label: item, value: item })
                            })
                            group['options'] = children;
                            site_names_options.push(group)
                        }
                        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                        setSiteNameOptions(site_names_options)
                        resolve(site_names_options)
                    }
                }).catch(err => {
                    console.log(`err`, err)
                })
            })
        }
    };

    // Helper function for get data button click
    function onGetDataButtonClickHandler() {
        window.location.href = `${NewInternalBasePoint}/df?filter[site]=${siteName.value}&filter[extractstatus]=${extractStatus.value}&filter[crawlstatus]=${crawlStatus.value}&filter[crawldate][from]=${moment(fromDate).format('YYYY/MM/DD')}&filter[crawldate][to]=${moment(toDate).format('YYYY/MM/DD')}&commit=${'Get Data'}`;
    };

    // Helper function to format options data
    async function optionsDataFormatter(sitesData) {

        let siteNameOptions = [];

        for (let key in sitesData) {
            let group = {};
            let children = [];

            group['label'] = <div className='fw-bold col text-black'>{key}</div>

            sitesData[key].map((item) => {
                children.push({ label: item, value: item });
            });
            group['options'] = children;
            siteNameOptions.push(group);
        }
        siteNameOptions.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] });
        setSiteNameOptions(siteNameOptions);

    };

    // Helper function to set search params
    async function setSearchParamsData() {
        if (searchParams.siteName && searchParams.siteName != siteName.value) {
            setSiteName({ label: searchParams.siteName, value: searchParams.siteName });
        }
        if (searchParams.extractStatus && searchParams.extractStatus != extractStatus.value) {
            setExtractStatus({ label: searchParams.extractStatus == 'all_other_than_done' ? 'All' : searchParams.extractStatus, value: searchParams.extractStatus });
        }
        if (searchParams.crawlStatus && searchParams.crawlStatus != crawlStatus.value) {
            setCrawlStatus({ label: searchParams.crawlStatus == 'any' ? 'Any' : searchParams.crawlStatus, value: searchParams.crawlStatus });
        }
        if (searchParams.fromDate && searchParams.fromDate != fromDate) {
            setFromDate(new Date(searchParams.fromDate));
        }
        if (searchParams.toDate && searchParams.toDate != toDate) {
            setToDate(new Date(searchParams.toDate));
        }
        if (commit == 'Get Data') {
            setUrlParams(searchParams)
            props.fetchData(searchParams.siteName, searchParams.extractStatus, searchParams.crawlStatus, searchParams.fromDate, searchParams.toDate);
        }
    };

    return (
        <div>
            <Card>
                <CardBody>
                    <Row>
                        <div className="col-sm-6">
                            <label className="fw-bold">Site Name</label>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions={siteNameOptions}
                                name='site_name'
                                value={siteName}
                                loadOptions={onAsyncSelectInputChangeHandler}
                                onChange={(site)=>setSiteName(site)}
                                placeholder="Select a site name"
                            />
                            <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
                        </div>
                        <Col sm="3">
                            <label className="fw-bold">From Date</label>
                            <DatePicker
                                className="form-control"
                                selected={new Date(fromDate)}
                                dateFormat={"YYY/MM/dd"}
                                maxDate={new Date()}
                                onChange={date => setFromDate(date)} />
                        </Col>
                        <Col sm="3">
                            <label className="fw-bold">To Date</label>
                            <DatePicker
                                className="form-control"
                                selected={new Date(toDate)}
                                dateFormat={"YYY/MM/dd"}
                                maxDate={new Date()}
                                onChange={date => setToDate(date)} />
                        </Col>
                    </Row>
                    <Row className="align-items-end mt-2">
                        <Col sm="4">
                            <label className="fw-bold">Crawl Status</label>
                            <Select
                                value={crawlStatus}
                                options={crawlStatusOptions}
                                onChange={option => setCrawlStatus(option)} />
                        </Col>
                        <Col sm="4">
                            <label className="fw-bold">Extract Status</label>
                            <Select
                                value={extractStatus}
                                options={extractStatusOptions}
                                onChange={option => setExtractStatus(option)} />
                        </Col>
                        <Col sm="4">
                            <button type='button' className="btn btn-success w-100" onClick={onGetDataButtonClickHandler}>Get Data</button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    )
}
