/**
 * CREATED ON: 26 July 2023
 * CREATED BY: atramaniket@promptcloud.com
 * DESCRIPTION: The components is rendered in internaldashboard/Jobspike V3 Internal Dashboard/InternalQALinks/100 Random Records for QA
 */
import React, { useState, useEffect, Fragment } from 'react'

import { Container, Row, Col, Card, CardBody, Collapse } from 'reactstrap'
import BreadCrumbs from '../../../../Common/BreadCrumb/BreadCrumbs.component'
import SpinnerLoader from '../../../../Common/LoadingAnimation/SpinnerLoader.component'

import axios from 'axios'
import moment from 'moment'
import { toast } from 'react-toastify'
import ReactJson from 'react-json-view';


const BASE_URL = '/api/v1/jobspikr_v3_internal/jobspikr_graphs/random_records_for_qa'

export default function HundredRandomRecordsForQA() {

  const [randomRecords, setRandomRecords] = useState([])
  const [cardOpen, setCardOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const customCardStyle = {
    cursor: "pointer"
  }

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function fetchRandomRecords() {
      try {
        setLoading(true);
        const response = await axios.get(BASE_URL, { signal: signal });
        const responseData = response?.data?.table_data;
        if (!signal.aborted && responseData?.length > 0) {
          setRandomRecords(responseData);
        }
        setLoading(false);
      } catch (error) {
        toast.error(error?.message);
        setLoading(false);
      }
    }

    fetchRandomRecords();

    // Cleanup
    return () => {
      controller.abort();
    };
  }, []);

  // Helper function to open and collapse the card
  function openCard() {
    setCardOpen(!cardOpen)
  }

  return (
    <Fragment>
      <BreadCrumbs parent={"JobsPikr V3 Internal Dashboard"} title={"100 Random Records For QA"} />
      <Container fluid>
        <Card>
          <CardBody>
            <div className="fw-bold">
              Date: {moment().subtract(1, "days").format("YYYY/MM/DD")}
            </div>
            <SpinnerLoader loading={loading}>
              {
                randomRecords.length > 0 &&
                randomRecords
                  .map((validRecord) => (
                    <div key={validRecord?.id} style={customCardStyle}>
                      <div className="w-100 p-3 text-start mt-2 panel-heading" role="presentation" onClick={() => {
                        setCardOpen(cardOpen === validRecord.id ? '' : validRecord.id)
                      }}>
                        <p className="fw-bold">{validRecord.site_name}</p>
                      </div>
                      <Collapse isOpen={cardOpen === validRecord.id ? true : false}>
                        <Row>
                          <p>
                            <strong>_id: {validRecord.id} &emsp; _index: {validRecord.index}</strong>
                          </p>
                          <br />
                        </Row>
                        <ReactJson src={validRecord.data} theme="rjv-github" />
                      </Collapse>
                    </div>
                  ))
              }
            </SpinnerLoader>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  )
}
