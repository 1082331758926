import React from 'react';
import { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker";
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { toast } from 'react-toastify';



export default function SearchSitesCard(props) {
  return (
    <div>
      <Card>
        <CardBody>
          <Row className='my-3'>
            <Col md={12}>
              <h6 className='fw-bold'>Site Name</h6>
              <AsyncSelect
                cacheOptions
                defaultOptions={props.dropdownOptions}
                name='site_name'
                value={props.selectedSite}
                loadOptions={(inputValue) => props.onSearchHandler(inputValue)}
                onChange={res => props.setSelectedSite(res)}
                placeholder="Select a site name" />
              <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
            </Col>
          </Row>
          <Row className='align-items-end my-3'>
            <Col md={5}>
              <h6 className='fw-bold'>From date</h6>
              <DatePicker selected={props.fromDate} onChange={(date) => props.setFromDate(date)}
                maxDate={new Date()} className='form-control' dateFormat={"YYY/MM/dd"} />
            </Col>
            <Col md={5}>
              <h6 className='fw-bold'>To date</h6>
              <DatePicker selected={props.endDate} onChange={(date) => props.setEndDate(date)}
                maxDate={new Date()} className='form-control' dateFormat={"YYY/MM/dd"} />
            </Col>
            <Col md={2} className='d-flex justify-content-center mt-3'>
              <button type='button' className='btn btn-success my-auto w-100' onClick={() => props.onButtonClickHandler()}>Get Data</button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  )
}
