import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, FormGroup, Label, Row, Col } from 'reactstrap';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import axios from 'axios';

const EditProfileModal = ({ isOpen, setModal, profile_id, data, toggleData, setToggleData }) => {

  const toggleModal = () => setModal(!isOpen)
  const closeBtn = <button className="close" onClick={() => setModal(false)}> &times; </button>;

  const handleSubmit = (values, { setSubmitting, validateForm }) => {
    axios.put(`/api/v1/profiles/${profile_id}`, {
        profile: {
          first_name: values.first_name, 
          last_name: values.last_name, 
          phone_number: values.phone_number
        },
      page_type: "client",
      controller_name: "profiles"
      }).then(res => {
        toast.success(res.data.message)
        setSubmitting(false);
        setToggleData(!toggleData)
        setModal(false)
      }).catch(err => {
        toast.error(err.response.data.message)
        setSubmitting(false)
      })
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} backdrop='static' size='lg'>
     <ModalHeader toggle={toggleModal} close={closeBtn}>
      Edit Profile: {localStorage.getItem("authEmail")}
     </ModalHeader>
     <ModalBody>
        <Formik
        initialValues={{
          first_name: data?.first_name || "",
          last_name: data?.last_name || "",
          phone_number: data?.phone_number || '',
        }}
        validationSchema={Yup.object().shape({
          first_name: Yup.string().required('This field is required!'),
          last_name: Yup.string()
            .required('This field is required!'),
          phone_number: Yup.string()
          .matches(/^[0-9]{10}$/, 'please enter a valid phone number')
          .required('Phone number is required')
        })}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <Row className='d-flex justify-content-center'>
              <Col sm={11}>
                <FormGroup row className='my-4'>
                  <Label htmlFor="first_name" tag="h6" className='fw-bold my-0 text-end h6' sm={6}>First Name:</Label>
                  <Col sm={6}>
                    <Field type={"text"} name="first_name" className="form-control" />
                    <ErrorMessage name="first_name" component="p" className='text-danger'/>
                  </Col>
                </FormGroup>
                <FormGroup row className='my-4'>
                  <Label htmlFor="last_name" tag="h6" className='fw-bold my-0 text-end h6' sm={6}>Last Name:</Label>
                  <Col sm={6}>
                    <Field type={"text"} name="last_name" className="form-control"/>
                    <ErrorMessage name="last_name" component="p" className='text-danger' />
                  </Col>
                </FormGroup>
                <FormGroup row className='my-4'>
                  <Label htmlFor="phone_number" tag="h6" className='fw-bold my-0 text-end h6' sm={6}>Phone Number:</Label>
                  <Col sm={6}>
                    <Field type={"text"} name="phone_number" className="form-control" />
                    <ErrorMessage name="phone_number" component="p" className='text-danger' />
                  </Col>
                </FormGroup>
              </Col>
              <hr className="my-3" />
              <Col sm={12} className='d-flex justify-content-end'>
                <Button color='light' onClick={() => setModal(false)} className='mx-2'> Close </Button>
                <Button color="success" type='submit'
                  disabled={isSubmitting}>Update Profile</Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
     </ModalBody>
    </Modal>
  );
};

export default EditProfileModal;
