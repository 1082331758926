import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { BreadcrumbItem, Container, Row, Col, Card, CardBody, Breadcrumb } from "reactstrap";
import { useLocation, Link } from "react-router-dom";
import { Home } from "react-feather";
import moment from "moment";
import Loader from "react-loader-spinner";

export default function ShowFileList() {
    const search = useLocation().search;
    const searchParams = {
        siteName: new URLSearchParams(search).get("filter[site_name]"),
        fromDate: new URLSearchParams(search).get("filter[from_date]"),
        toDate: new URLSearchParams(search).get("filter[to_date]"),
        activityId: new URLSearchParams(search).get("filter[activity_id]"),
        activityType: new URLSearchParams(search).get("filter[activity_type]"),
        fileProcessStatus: new URLSearchParams(search).get("filter[file_process_status]"),
        crawlDate: new URLSearchParams(search).get("filter[crawl_date]")
    }

    const [data, setData] = useState([]);
    const [fileDetails, setFileDetails] = useState([]);
    const [loading, setLoading] = useState(false)
    const columns = [
        { name: 'File Name', selector: 'file_name', center: true, sortable: true }
    ]
    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [noParams, setNoParams] = useState(false);
    const [fileStatus,setFileStatus]=useState('');
    const [crawlDate,setCrawlDate]=useState('');

    function getId() {
        if (searchParams.activityType == 'dependent_data_upload') {
            return 'CrawlDate'
        }
        else {
            return "Activity ID"
        }
    }

    function getIdValue() {
        if (searchParams.activityType == 'dependent_data_upload') {
            return crawlDate
        }
        else {
            return searchParams.activityId
        }
    }

    function getStatus() {
        if (searchParams.activityType == 'merge_files_and_upload') {
            return 'File Merge Status'
        }
        else if (searchParams.activityType == 'multi_target_upload') {
            return 'File Merge Status'

        }
        else if (searchParams.activityType == 'dependent_data_upload') {
            return 'Dependent Data File Upload Status'
        }
    }

    const fileDetailsColumns = [
        { name: 'Site Name', selector: 'site_name', center: true, sortable: true },
        { name: 'Activity Type', selector: 'activity_type', center: true, sortable: true, },
        { name: getId(), selector: '', center: true, sortable: true, cell: () => <div>{getIdValue()}</div>  },
        { name: getStatus(), selector: 'file_merge_status', center: true, sortable: true, cell: () => <div>{fileStatus}</div> },
        { name: 'Kibana Link', selector: 'kibana_link', center: true, sortable: true, cell: row => <a target={'_blank'} href={row.kibana_link} className="link-primary">Click here</a> },

    ]

    useEffect(() => {
        setLoading(true)
        axios.get(`/api/v1/internal/extended_upload_process_status/show_file_list?site_name=${searchParams.siteName}&${searchParams.activityType == 'dependent_data_upload' ? `crawl_date=${searchParams.crawlDate}`  : `activity_id=${searchParams.activityId}`}&activity_type=${searchParams.activityType}&file_process_status=${searchParams.fileProcessStatus}&from_date=${moment(searchParams.fromDate).format('YYYY/MM/DD')}&to_date=${moment(searchParams.toDate).format('YYYY/MM/DD')}`)
            .then(res => {
                setFileDetails([{
                    site_name: searchParams.siteName,
                    activity_type: searchParams.activityType,
                    activity_id: searchParams.activityId,
                    file_merge_status: searchParams.fileProcessStatus,
                    kibana_link: res.data.kibana_link
                }])
                setFileStatus(res.data.status)
                setCrawlDate(res.data.crawl_date)
                setData(res.data.file_list?.map(item => ({ file_name: item })))
                setSearchResults(res.data.file_list?.map(item => ({ file_name: item })))
                setLoading(false)

            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong')
                setLoading(false)
            })



    }, []);

    const handleInputChange = event => {
        if (event.target.value == '') {
            setSearchResults(data);
        }
        else {
            setSearchResults(searchResults.filter(item => item.file_name.includes(event.target.value)))
        }
        setSearchText(event.target.value)
    }

    function getURL(){
        if(searchParams.activityType == 'merge_files_and_upload'){
            return `/internal/extended_upload_process_status/merge_files_and_upload?filter[site_name]=${searchParams.siteName}&filter[from_date]=${searchParams.fromDate}&filter[to_date]=${searchParams.toDate}&commit=${'Get Data'}`

        }
        else if(searchParams.activityType == 'multi_target_upload'){
            return `/internal/extended_upload_process_status/multi_target_upload?filter[site_name]=${searchParams.siteName}&filter[from_date]=${searchParams.fromDate}&filter[to_date]=${searchParams.toDate}&commit=${'Get Data'}`

        }
        else if(searchParams.activityType == 'dependent_data_upload'){
            return `/internal/extended_upload_process_status/dependent_data_upload?filter[site_name]=${searchParams.siteName}&filter[from_date]=${searchParams.fromDate}&filter[to_date]=${searchParams.toDate}&commit=${'Get Data'}`

        }
    }


    return <Container fluid>
        <div className="page-title">
            <Row>
                <Col xs="6">
                    <h3> Internal Dashboard</h3>
                </Col>
                <Col xs="6">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={'/internal'}>
                                <Home />
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <Link to={getURL()}>
                                Back
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem className="fw-bold">
                            {searchParams.activityType == 'dependent_data_upload' && " Extended Upload Process Status - Dependent Data Upload's File List"}
                            { searchParams.activityType == 'merge_files_and_upload'&& "Extended Upload Process Status - Merge Files and Upload's File List"}
                            {searchParams.activityType == 'multi_target_upload' && "Extended Upload Process Status - Multi Target Upload's File List"}
                        </BreadcrumbItem>

                    </Breadcrumb>
                </Col>
            </Row>
        </div>

        {noParams ? <div className="fw-bold"> No Params Present!</div> : <Fragment>
            {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> : <Card>
                <CardBody>
                    <DataTable
                        columns={fileDetailsColumns}
                        data={fileDetails}
                        noHeader
                        highlightOnHover
                    />
                    <Row className="my-3">
                        <Col>
                            <div className="float-end">
                                <Row className="align-items-center">
                                    <Col sm="3">Search</Col>
                                    <Col>
                                        <input type="text" className="form-control" value={searchText} onChange={handleInputChange} />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <DataTable
                        columns={columns}
                        data={searchResults}
                        pagination
                        noHeader
                        highlightOnHover />
                </CardBody>
            </Card>}
        </Fragment>}

    </Container>
}