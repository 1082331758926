import React, {useState, useEffect, Fragment} from 'react'
import { Card, CardBody, Col, Container, Breadcrumb, BreadcrumbItem, Row } from 'reactstrap'
import AsyncSelect from 'react-select/async';
import Loader from 'react-loader-spinner';
import DatePicker from "react-datepicker";
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify';
import { Info } from 'react-feather';
import DataTable from 'react-data-table-component';
import ReactTooltip from 'react-tooltip';
import { Home } from 'react-feather';
import { Link } from 'react-router-dom'

export default function TestSiteStats() {
  const search = useLocation().search
  const siteValue = new URLSearchParams(search).get('filter[site]')
  const fromValue = new URLSearchParams(search).get('filter[crawldate][from]')
  const toValue = new URLSearchParams(search).get('filter[crawldate][to]')
  const commitValue = new URLSearchParams(search).get('commit')

  const [data, setData] = useState({})
  const [dropdownOptions, setDropdownOptions] = useState([])
  const [selectedSite, setSelectedSite] = useState({})
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [tempTableData, setTempTableData] = useState([])

  useEffect(() => {
    if (siteValue){
      setSelectedSite({label: siteValue, value: siteValue})
    }
    if (fromValue){
      setFromDate(new Date(fromValue))
    }
    if (toValue){
      setEndDate(new Date(toValue))
    }
    if (commitValue == 'Get Data' && siteValue && fromValue && toValue){
      getUploadData1()
    }
  }, [siteValue, fromValue, toValue, commitValue])

  useEffect(() => {
    fetchSiteNames();
  }, [])

  function fetchSiteNames() {
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let site_names = res.data.site_names, site_names_options = []
        for (var key in site_names) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
          site_names[key].map(item => {
            children.push({ label: item, value: item })
          })
          group['options'] = children;
          site_names_options.push(group)
        }
        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
        setDropdownOptions(site_names_options)
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      })
  }

  const today = new Date()
  const [fromDate, setFromDate] = useState(fromValue ? new Date(fromValue) : new Date(today.getFullYear(),today.getMonth(),today.getDate()-2))
  const [endDate, setEndDate] = useState(toValue ? new Date(toValue) : today)
  
  function getFormattedDate(date) {
    var year = date.getFullYear();
  
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
  
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    return year + '/' + month + '/' + day;
  }

  const getUploadData1 = () => {
    setLoading(true)
    axios.get(`/api/v1/internal/test_site_stats?filter[site]=${(selectedSite?.value || siteValue)}&filter[from_date]=${getFormattedDate(fromDate)}&filter[to_date]=${getFormattedDate(endDate)}`).then(res => {
      console.log('res.data', res.data)
      setData(res.data)
      setLoading(false)
    }).catch(err => {
      toast.error(err.response.data.message)
      setLoading(false)
    })
  }

  const getUploadData = () => {
    window.location.href = `/internal/test_site_stats/search?utf8=✓&filter%5Bsite%5D=${selectedSite.value}&filter%5Bcrawldate%5D%5Bfrom%5D=${getFormattedDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)}&commit=Get Data`
  }

  const columns = [
    { name: <div style={{color: "#337ab7"}}>Site</div>, selector: 'site', width: '400px', sortable: true, center: true, grow: 4, cell: each => <>
        <a href={`https://app.promptcloud.com/internal/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${getFormattedDate(fromDate)?.replaceAll('/', '%2F')}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)?.replaceAll('/', '%2F')}&filter%5Bdedupstatus%5D=&filter%5Bhostname%5D=&filter%5Bnormalizationstatus%5D=&filter%5Bsite%5D=${each?.site}&filter%5Buploadstatus%5D=&test_run_status=true`} target='_blank'
            data-tip data-for='segment_view' className='d-flex justify-content-center align-items-center'> {each?.site}  </a> {each?.site != '' && <Info size={24} className='ms-2' />}
        <ReactTooltip id='segment_view'>Go to Segment view</ReactTooltip>
        </>
    },
    { name: <div style={{color: "#337ab7"}}>Queue Size</div>, selector: 'queue_size', sortable: true, width: '120px', center: true, cell: each => <>
            <a href={`https://appv2.promptcloud.com/internal/queue_sizes/search?filter%5Bis_test_site%5D=true&filter%5Bsite%5D=${each?.site}`} target='_blank'>
                {each?.queue_size}
            </a>
    </> },
    { name: <div style={{color: "#337ab7"}}>Date</div>, selector: 'date', sortable: true, center: true, grow:2, cell: each => <div>
            { each?.site == '' ? <b>{each.date}</b>
            : <><p data-tip data-for='Kibana_Link' className='d-flex justify-content-center align-items-center m-0'>
            {each?.date} <Info size={14} className='mx-2' /> </p>
            <ReactTooltip clickable multiline  delayHide={1000} effect='solid' id='Kibana_Link'>
            <a href={`https://kibana.promptcloud.com/app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:%5Blogstash-%5DYYYY.MM.DD,interval:auto,query:(query_string:(analyze_wildcard:!t,query:'(site_name:%20${each?.site}%20OR%20sitename:%20${each?.site}%20OR%20site:%20${each?.site})%20AND%20yyyymmdd:${getFormattedDate(fromDate).split('/').join('')}')),sort:!('@timestamp',desc))`} target='_blank' className='text-white'>Kibana Link</a>
            </ReactTooltip></>}
    </div> },
    { name: <div style={{color: "#337ab7"}}>RSS</div>, selector: 'rss_count', sortable: true, center: true, cell: each => <>
        <a href={`https://appv2.promptcloud.com/internal/rss?filter%5Bcrawldate%5D%5Bfrom%5D=${getFormattedDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)}&filter%5Bsite%5D=${each?.site}&test_run_status=true`} 
            className='d-flex justify-content-center align-items-center' data-tip data-for='kibana_and_alert'>
            {each?.rss_count} <Info size={18} className='mx-2' /> </a>
        <ReactTooltip clickable multiline  delayHide={1000} effect='solid' id='kibana_and_alert'>
        <a href={`https://kibana.promptcloud.com/app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:%5Blogstash-%5DYYYY.MM.DD,interval:auto,query:(query_string:(analyze_wildcard:!t,query:'(site_name:%20${each?.site}%20OR%20sitename:%20${each?.site}%20OR%20site:%20${each?.site})%20AND%20yyyymmdd:${getFormattedDate(fromDate).split('/').join('')}%20AND%20pipeline_stage:rss')),sort:!('@timestamp',desc))`} target='_blank' className='text-white'>
            Kibana Link (To Do)
        </a> <br />
        <a href='http://alerta.promptcloud.com/#/alerts?status=open' target='_blank' className='text-white'>
            Alerta link (To Do)
        </a> <br />
        </ReactTooltip>
    </> },
    { name: <div style={{color: "#337ab7"}}>Feedcrawl</div>, selector: 'feed_crawled_urls_count', sortable: true, center: true, cell: each => <>
        <a href={`https://appv2.promptcloud.com/internal/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${getFormattedDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)}&filter%5Bdedupstatus%5D=&filter%5Bhostname%5D=&filter%5Bnormalizationstatus%5D=&filter%5Bsite%5D=b${each?.site}&filter%5Buploadstatus%5D=&test_run_status=true`} 
            className='d-flex justify-content-center align-items-center' data-tip data-for='kibana_and_alert2'>
            {each?.feed_crawled_urls_count} <Info size={18} className='mx-2' /> </a>
        <ReactTooltip clickable multiline  delayHide={1000} effect='solid' id='kibana_and_alert2'>
            <a href={`https://kibana.promptcloud.com/app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:%5Blogstash-%5DYYYY.MM.DD,interval:auto,query:(query_string:(analyze_wildcard:!t,query:'(site_name:%20${each?.site}%20OR%20sitename:%20${each?.site}%20OR%20site:%20${each?.site})%20%20AND%20yyyymmdd:${getFormattedDate(fromDate).split('/').join('')}%20AND%20(pipeline_stage:%20feedcrawl%20OR%20pipeline_stage:%20diskfetcher)')),sort:!('@timestamp',desc))`} target='_blank' className='text-white'>
            Kibana Link
            </a> <br />
            <a href='http://alerta.promptcloud.com/#/alerts?status=open' target='_blank' className='text-white'>
            Alerta link (To Do)
            </a> <br />
        </ReactTooltip>
    </> },
    { name: <div style={{color: "#337ab7"}}>Extraction</div>, selector: 'segment_extr_count', sortable: true, center: true, cell: each => <>
        <a href={`https://appv2.promptcloud.com/internal/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${getFormattedDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)}&filter%5Bdedupstatus%5D=&filter%5Bhostname%5D=&filter%5Bnormalizationstatus%5D=&filter%5Bsite%5D=${each?.site}&filter%5Buploadstatus%5D=&test_run_status=true`} 
            className='d-flex justify-content-center align-items-center' data-tip data-for='kibana_alert_dropped'>
            {each?.segment_extr_count} <Info size={18} className='mx-2' /> </a>
        <ReactTooltip clickable multiline  delayHide={1000} effect='solid' id='kibana_alert_dropped'>
            <p className='m-0'>Dropped: {each?.dropped_ext}</p>
            <a href={`https://kibana.promptcloud.com/app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:%5Blogstash-%5DYYYY.MM.DD,interval:auto,query:(query_string:(analyze_wildcard:!t,query:'(site_name:%20${each?.site}%20OR%20sitename:%20${each?.site}%20OR%20site:%20${each?.site})%20%20AND%20yyyymmdd:${getFormattedDate(fromDate).split('/').join('')}%20AND%20(pipeline_stage:%20feedcrawl%20OR%20pipeline_stage:%20diskfetcher)')),sort:!('@timestamp',desc))`} target='_blank' className='text-white'>
            Kibana Link
            </a> <br />
            <a href='http://alerta.promptcloud.com/#/alerts?status=open' target='_blank' className='text-white'>
            Alerta link (To Do)
            </a> <br />
        </ReactTooltip>
    </> },
    { name: <div style={{color: "#337ab7"}}>Dedup</div>, selector: 'segment_dedup_count', sortable: true, center: true, cell: each => <>
        <a href={`https://appv2.promptcloud.com/internal/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${getFormattedDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)}9&filter%5Bdedupstatus%5D=&filter%5Bhostname%5D=&filter%5Bnormalizationstatus%5D=&filter%5Bsite%5D=${each?.site}&filter%5Buploadstatus%5D=&test_run_status=true`} 
        className='d-flex justify-content-center align-items-center' data-tip data-for='kibana_alert_dropped2'>
        {each?.segment_dedup_count} <Info size={18} className='mx-2' /> </a>
        <ReactTooltip clickable multiline  delayHide={1000} effect='solid' id='kibana_alert_dropped2'>
        <p className='m-0'>Dropped: {each?.dropped_ded}</p>
        <a href={`https://kibana.promptcloud.com/app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:%5Blogstash-%5DYYYY.MM.DD,interval:auto,query:(query_string:(analyze_wildcard:!t,query:'(site_name:%20${each?.site}%20OR%20sitename:%20${each?.site}%20OR%20site:%20${each?.site})%20%20AND%20yyyymmdd:${getFormattedDate(fromDate).split('/').join('')}%20AND%20(pipeline_stage:%20feedcrawl%20OR%20pipeline_stage:%20diskfetcher)')),sort:!('@timestamp',desc))`} target='_blank' className='text-white'>
            Kibana Link 
        </a> <br />
        <a href='http://alerta.promptcloud.com/#/alerts?status=open' target='_blank' className='text-white'>
            Alerta link (To Do)
        </a> <br />
        </ReactTooltip>
    </> },
    { name: <div style={{color: "#337ab7"}}>Normalize</div>, selector: 'segment_normalize_count', sortable: true, center: true, cell: each => <>
        <a href={`https://appv2.promptcloud.com/internal/segment?filter%5Bcrawldate%5D%5Bfrom%5D${getFormattedDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)}&filter%5Bdedupstatus%5D=&filter%5Bhostname%5D=&filter%5Bnormalizationstatus%5D=&filter%5Bsite%5D=${each?.site}&filter%5Buploadstatus%5D=&test_run_status=true`} 
            className='d-flex justify-content-center align-items-center' data-tip data-for='kibana_alert_dropped3'>
            {each?.segment_normalize_count} <Info size={18} className='mx-2' /> </a>
        <ReactTooltip clickable multiline  delayHide={1000} effect='solid' id='kibana_alert_dropped3'>
            <p className='m-0'>Dropped: {each?.dropped_nor}</p>
            <a href={`https://kibana.promptcloud.com/app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:%5Blogstash-%5DYYYY.MM.DD,interval:auto,query:(query_string:(analyze_wildcard:!t,query:'(site_name:%20${each?.site}%20OR%20sitename:%20${each?.site}%20OR%20site:%20${each?.site})%20%20AND%20yyyymmdd:${getFormattedDate(fromDate).split('/').join('')}%20AND%20(pipeline_stage:%20feedcrawl%20OR%20pipeline_stage:%20diskfetcher)')),sort:!('@timestamp',desc))`} target='_blank' className='text-white'>
            Kibana Link
            </a> <br />
            <a href='http://alerta.promptcloud.com/#/alerts?status=open' target='_blank' className='text-white'>
            Alerta link (To Do)
            </a> <br />
        </ReactTooltip>
    </> },
    { name: <div style={{color: "#337ab7"}}>Upload</div>, selector: 'segment_upload_count', sortable: true, center: true, cell: each => <>
        <a href={`https://appv2.promptcloud.com/internal/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${getFormattedDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)}&filter%5Bdedupstatus%5D=&filter%5Bhostname%5D=&filter%5Bnormalizationstatus%5D=&filter%5Bsite%5D=${each?.site}&filter%5Buploadstatus%5D=&test_run_status=true`} 
        className='d-flex justify-content-center align-items-center' data-tip data-for='kibana_alert_dropped3'>
        {each?.segment_upload_count} <Info size={18} className='mx-2' /> </a>
        <ReactTooltip clickable multiline  delayHide={1000} effect='solid' id='kibana_alert_dropped3'>
        <p className='m-0'>Dropped: {each?.dropped_upload}</p>
        <a href={`https://kibana.promptcloud.com/app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:%5Blogstash-%5DYYYY.MM.DD,interval:auto,query:(query_string:(analyze_wildcard:!t,query:'(site_name:%20${each?.site}%20OR%20sitename:%20${each?.site}%20OR%20site:%20${each?.site})%20%20AND%20yyyymmdd:${getFormattedDate(fromDate).split('/').join('')}%20AND%20(pipeline_stage:%20feedcrawl%20OR%20pipeline_stage:%20diskfetcher)')),sort:!('@timestamp',desc))`} target='_blank' className='text-white'>
            Kibana Link
        </a> <br />
        <a href='http://alerta.promptcloud.com/#/alerts?status=open' target='_blank'  className='text-white'>
            Alerta link (To Do)
        </a> <br />
        </ReactTooltip>
    </> },
    { name: <div style={{color: "#337ab7"}}>Kibana</div>, selector: 'kibana', sortable: true, center: true, cell: each => <>
        <a href={`https://kibana.promptcloud.com/app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:%5Blogstash-%5DYYYY.MM.DD,interval:auto,query:(query_string:(analyze_wildcard:!t,query:'site_name:%20${each?.site}%20OR%20sitename:%20${each?.site}%20OR%20site:%20${each?.site}')),sort:!('@timestamp',desc))`} target='_blank'>Kibana Link</a>
    </> },
  ]

  useEffect(() => {
    if (searchValue){
      let res = []
      tableData.forEach(each => {
        if (each?.site?.includes(searchValue) || each?.date?.toString()?.includes(searchValue)){
          res.push(each)
        }
      })
      setTempTableData(res)
    } else {
      setTempTableData(tableData)
    }
  }, [searchValue])
  

  useEffect(() => {
    if (data?.data){
      let tempBackendData = data?.data
      tempBackendData.push({
        site: '', queue_size: '', date: <b>Total: </b>,
        rss_count: <b>{data?.total_rss_count}</b>,
        feed_crawled_urls_count: <b>{data?.total_feed_crawled_urls_count}</b>,
        segment_extr_count: <b>{data?.total_segment_extr_count}</b>,
        segment_dedup_count: <b>{data?.total_segment_dedup_count}</b>,
        segment_normalize_count: <b>{data?.total_segment_normalize_count}</b>,
        segment_upload_count: <b>{data?.total_segment_upload_count}</b>,
        kibana: '',
      })
      setTableData(tempBackendData)
      setTempTableData(tempBackendData)
    }
  }, [data])
  
  const promiseOptions = (inputValue) => {
    if(inputValue.length < 3){
      return null
    } else {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200'){
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
              site_names[key].map(item => {
                children.push({ label: item, value: item })
              })
              group['options'] = children;
              site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setDropdownOptions(site_names_options)
            resolve(site_names_options)
          }
        }).catch(err => {
          console.log(`err`, err)
        })
      })
    }
  }

  return (
    <Container fluid>
      <Fragment>
        <Container fluid>
            <div className="page-title">
                <Row className=''>
                    <Col xs="6">
                        <h3>Internal Dashboard</h3>
                    </Col>
                    <Col xs="6">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={`/internal`}><Home /></Link>
                        </BreadcrumbItem>
                        {window.location.pathname.includes('/test_site_stats/search')
                         ? <BreadcrumbItem>
                              <Link to={'/internal/test_site_stats'}>
                                  Test Site Stats
                              </Link>
                          </BreadcrumbItem>
                         : <BreadcrumbItem active>
                            Test Site Stats
                        </BreadcrumbItem>}

                        {window.location.pathname.includes('/test_site_stats/search') && <BreadcrumbItem active>
                            <b>Search </b>
                        </BreadcrumbItem>}
                    </Breadcrumb>
                    </Col>
                </Row>
            </div>
        </Container>
    </Fragment>

      { loading ? <Loader type="Puff" color="#00BFFF" height={60} 
        width={60} className='pc-loader' />
      : <Card>
          <CardBody>
            <Row className='my-3'>
              <Col md={12}>
                <h6 className='fw-bold'>Site Name</h6>
                <AsyncSelect cacheOptions defaultOptions={dropdownOptions} name='site_name' 
                    value={selectedSite} loadOptions={promiseOptions} onChange={res => setSelectedSite(res)}
                    placeholder="Select a site name" />
                    <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
              </Col>
            </Row>
            <Row className='align-items-end my-3'>
              <Col md={5}>
                <h6 className='fw-bold'>From date</h6>
                <DatePicker selected={fromDate} onChange={(date) => setFromDate(date)}
                  maxDate={new Date()} className='form-control' dateFormat={"YYY/MM/dd"} />
              </Col>
              <Col md={5}>
                <h6 className='fw-bold'>To date</h6>
                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)}
                  maxDate={new Date()} className='form-control' dateFormat={"YYY/MM/dd"} />
              </Col>
              <Col md={2} className='d-flex justify-content-center mt-3'>
                <button className='btn btn-success my-auto w-100' onClick={() => getUploadData()}>Get Data</button>
              </Col>
            </Row>
          </CardBody>
        </Card> }

      { data?.data?.length > 0 && 
      <Card>
        <CardBody>
          <h5>Note:</h5>
          <h6> <Info size={13} /> Indicates hover is there. It has some informations or links  </h6>
          <Row className='mb-2'>
            <Col md={7}></Col>
            <Col md={5}>
              <div className='d-flex justify-content-center align-items-center'>
                <h6 className='my-0 me-2'>Search:</h6>
                <input type='text' className='form-control' value={searchValue} 
                  onChange={(e) => setSearchValue(e.target?.value)} />
              </div>
            </Col>
          </Row>
          <DataTable className='mb-2' data={tempTableData} columns={columns} noHeader pagination
            responsive />
        </CardBody>
      </Card> }
    </Container>
  )
}
