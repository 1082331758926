import React , { forwardRef, useImperativeHandle, useState, useEffect } from 'react';

import { Row, Col, Form, Label, Input, Table } from 'reactstrap';
import { Field, ErrorMessage, FieldArray, useFormikContext, setNestedObjectValues } from 'formik';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone-uploader';
import axios from 'axios';
import XLSX from 'xlsx';
import * as Yup from 'yup';

const SiteDetails = forwardRef(({ formikRef, accessEnv }, ref) => {
  
    const environment = localStorage.getItem("environment");

    const { values, setValues, validateForm, touched, setTouched, errors, setErrors, setFieldValue, setFieldTouched, validateField, setFieldError, setSubmitting } = useFormikContext()

    const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

    //We re validating the url for feasibility manually while seperating 
    const validationSchema = Yup.array().of(
      Yup.lazy((values) => {
        
        const isUrlPresent = values.url && values.url.trim().length > 0;
    
        return Yup.object().shape({
          url: isUrlPresent
            ? Yup.string()
                .test('is-feasible', 'This URL is not feasible', async function (value) {
                  const { path } = this;
    
                  if (/^((www\.)|(http(s*):\/\/))/i.test(value?.trim())) {
                    const url = value?.trim();
    
                    try {
                      const response = await axios.post('/api/v1/sites/check_feasibility', {
                        sites: { sample_page_url: url },
                      });
    
                      if (response.data.status !== 'success') {
                        return this.createError({
                          path: `${path}`,
                          message: 'This URL is not feasible',
                        });
                      }
                    } catch (error) {
                      console.error("API call failed:", error);
                      return this.createError({
                        path: `${path}`,
                        message: 'This URL is not feasible',
                      });
                    }
                  }
                  return true;
                })
            : Yup.string().strip(),
          rss_urls_crawl_limit: Yup.number().strip(),
        });
      })
    );
    
    

    useImperativeHandle(ref, () => ({
      isValidated() {
        return new Promise((resolve, reject) => {
          values.site_details.forEach((item, index) => {
            Object.keys(item).forEach(field => {
              if (item[field] && (!touched.site_details || !touched.site_details[index] || !touched.site_details[index][field])) {
                setFieldTouched(`site_details[${index}].${field}`, true);
              }
            });
          });
    
          formikRef.current.validateForm()
            .then(errors => {
              setTouched(setNestedObjectValues(errors, true));
    
              if (!checkForValues(values?.site_details)) {
                toast.error("Please enter at least one feasible URL");
                setSubmitting(false);
                reject(errors);
              } 
              else if (Object.keys(errors).length === 0) {
                validationSchema.validate(values?.site_details, { abortEarly: false })
                  .then(validData => {
                    setSubmitting(false);
                    resolve();
                  })
                  .catch(validationErrors => {
                    const errorIndices = validationErrors.inner.map(error => {
                      const match = error.path.match(/\[(\d+)\]/);
                      return match ? parseInt(match[1], 10) : -1;
                    }).filter(index => index !== -1);
    
                    let formattedErrors = Array(values.site_details.length).fill({ url: "", rss_urls_crawl_limit: "" });
                    errorIndices.forEach(index => {
                      formattedErrors[index] = {
                        url: "This URL is not feasible",
                        rss_urls_crawl_limit: formattedErrors[index].rss_urls_crawl_limit,
                      };
                    });
    
                    setErrors({ site_details: formattedErrors });
                    setTouched(setNestedObjectValues({ site_details: formattedErrors }, true), false);
                    toast.error("The form is invalid. Please check it!");
                    setSubmitting(false);
                    reject(validationErrors);
                  });
              } else {
                setTouched(setNestedObjectValues(errors, true));
                reject(errors);
                setSubmitting(false);
                toast.error("The form is invalid. Please check it!");
              }
            })
            .catch(error => {
              reject(error);
              setSubmitting(false);
            });
        });
      }
    }));
    

    const handleChangeStatus = (uploadData, status, form) => {
      const { meta, file } = uploadData
      if (status === "done") {
        var fileReader = new FileReader();
        fileReader.onload = function (event) {
          var workbook = XLSX.read(event.target.result, {
            type: "binary"
          });
          workbook.SheetNames.forEach(sheet => {
            let rowObject = XLSX.utils.sheet_to_row_object_array(
              workbook.Sheets[sheet]
            );
            let headerCondition;
            if (accessEnv.includes(environment)) {
              headerCondition = ("page_url" in rowObject[0] && "rss_urls_crawl_limit" in rowObject[0])
            }
            else {
              headerCondition = ("page_url" in rowObject[0])
            }
            if (rowObject.length === 0) {
              toast.error("Uploaded file is empty")
            }
            else if (headerCondition) {
              let newRows = []
              newRows = form?.values
              .filter(item => item?.url?.length > 0 || String(item?.rss_urls_crawl_limit).length > 0)
              .map(item => ({
                url: item.url || "",
                rss_urls_crawl_limit: item.rss_urls_crawl_limit || ""
              }));
              rowObject.forEach(item => {
                newRows.push({
                  url: item?.page_url || "",
                  rss_urls_crawl_limit: accessEnv.includes(environment) ? item?.rss_urls_crawl_limit || "" : ""
                });
              });
              setFieldValue("site_details",newRows,true).then(()=>{
                formikRef.current.validateForm({...values, site_details: newRows})
                .then(errors => {
                  setTouched(setNestedObjectValues(errors, true))
                  if(!checkForValues(newRows)){
                    toast.error("please enter atleast one feasible url")
                  } else if (Object.keys(errors).length === 0) {
                    return
                  } else {
                    setTouched(setNestedObjectValues(errors, true))
                    toast.error("The form is invalid so please check it!")
                  }
                })
                .catch(error => console.log(error));
              })
            }
            else {
              toast.error("Please keep column headers as mentioned in sample excel file")
            }
          });
        };
        fileReader.readAsBinaryString(file);
      }
      else if (status === "removed") {
        let empty_rows = [
          {url:"", rss_urls_crawl_limit:""},
          {url:"", rss_urls_crawl_limit:""},
          {url:"", rss_urls_crawl_limit:""},
          {url:"", rss_urls_crawl_limit:""},
        ]
        setFieldValue("site_details",empty_rows)
        setFieldTouched("site_details", false)
        setFieldError("site_details",[])
      }
      else if (status === 'rejected_file_type') {
        toast.error("ERROR: Unsupported File Format!" + "\n" + "Supported file formats are .xls, .xlsx, .xlsb, .xlsm, .ods, .ots, .uos");
      }
    }

    const handleExcelDownload = () => {
      axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem("authToken")}`;
      axios.defaults.headers["Content-Type"] = `application/vnd.ms-excel`;
      axios.defaults.headers["Accept"] = `application/vnd.ms-excel`;
      axios({
        url: `/api/v1/download_sample_site_details`,
        method: 'GET',
        responseType: 'blob'
      })
        .then((res) => {
          const url_to_download = window.URL.createObjectURL(new Blob([res.data]));
          const a_tag = document.createElement('a');
          a_tag.href = url_to_download;
          a_tag.setAttribute('download', `sample_site_urls.xls`);
          document.body.appendChild(a_tag);
          a_tag.click();
        });
      axios.defaults.headers["Content-Type"] = `application/json`;
      axios.defaults.headers["Accept"] = `application/json`;
    }

    const checkForValues = (details) => {
      let feasible = false
      var url = new RegExp(/^((www\.)|(http(s*)))/i);
      var rss_urls = new RegExp(/^\d+$/)
      details?.map(obj => {
        if(accessEnv.includes(environment)){
          if(url.test(obj?.url) && rss_urls.test(obj?.rss_urls_crawl_limit)) feasible = true
        } else {
          if(url.test(obj?.url)) feasible = true
        } 
      })
      return feasible
    }

    const url_schema = Yup.string().matches(/^((www\.)|(http(s*):\/\/))/i, {message:"Please enter a valid URL", excludeEmptyString: true})

    return(
      <FieldArray name="site_details"  validateOnChange={true}>
        {({ push, remove, form, insert }) => {
          const { values } = form;
          const { site_details } = values;

          return(
            <Row className='my-5 py-3'>
              <Col span={12}>
                <Table hover className='site-details-table'>
                  <thead>
                    <tr>
                      <th>SI.No</th>
                      <th>URL</th>
                      {accessEnv.includes(environment) ? <th>Rss urls crawl limit</th> : ""}
                    </tr>
                  </thead>
                  <tbody>
                    {
                       site_details.map((row, index)=>{
                          return(
                            <tr>
                              <td> {index + 1}</td>
                              <td style={{ width: accessEnv.includes(environment) ? "70%" : "100%" }}>
                                <Field
                                  name={`site_details[${index}].url`}
                                  className="form-control"
                                  type="text"
                                  placeholder='http://www.ebay.com/sch/DSLR-Cameras/31388/bn_732/i.html'
                                  validate={async value => {
                                    try {
                                      await url_schema.validate(value.trim())
                                      return ""
                                    } catch (error) {
                                      return error.message;
                                    }
                                  }}
                                />
                                <ErrorMessage name={`site_details[${index}].url`} component="p" className='m-0 error-msg mt-1 mb-2'/>
                              </td>
                              <td>
                                {
                                   accessEnv.includes(environment) ?
                                   <>
                                    <Field
                                    name={`site_details[${index}].rss_urls_crawl_limit`}
                                    className="form-control"
                                    type="text"
                                    placeholder='It will be 30k by default'
                                    />
                                    <ErrorMessage name={`site_details[${index}].rss_urls_crawl_limit`} component="p" className='m-0 error-msg mt-1 mb-2'/>
                                  </>
                                  : ""
                                }
                              </td>
                            </tr>
                          )
                       })
                    }
                  </tbody>
                </Table>
                <button className='btn btn-outline-primary my-3 w-100' type='button'
                  onClick={() => push({url:"", rss_urls_crawl_limit:""})}>
                  + Add more fields
                </button>
              </Col>
              <div className='my-4 d-flex justify-content-center fw-bold'>
                OR
              </div>
              <Col span={12}>
                <Row> 
                  <Col sm={3} className='d-flex justify-content-end'>
                    <h6 className='fw-bold'>
                      Add Sites via a file: </h6>
                  </Col>
                  <Col sm={5}>
                    <div className="dz-message needsclick">
                      <Dropzone
                        getUploadParams={getUploadParams} maxFiles={1}
                        onChangeStatus={(uploadData, status) => handleChangeStatus(uploadData, status, { push, values: values?.site_details, insert})}
                        multiple={false}
                        canCancel={true}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.ms-excel.sheet.binary.macroEnabled.12,application/vnd.oasis.opendocument.spreadsheet,application/vnd.ms-excel.sheet.macroEnabled.12,application/vnd.oasis.opendocument.spreadsheet-template"
                        inputContent={(files, extra) => (extra.reject ? 'Please enter only supported files' : 'Upload / Drop an Excel File')}
                        styles={{
                          dropzone: { height: 40 },
                          dropzoneActive: { borderColor: 'green' },
                        }}
                      />
                    </div>
                    <p className='text-muted m-0 mt-2 text-sm'>
                      Support file formats are .xls, .xlsx, .xlsb, .xlsm, .ods, .ots, .uos
                    </p>
                  </Col>
                    <p className='my-5 d-flex align-center'>
                      <button type='button'
                        className='btn text-primary' onClick={handleExcelDownload}> Click here for a sample Excel file. 
                      </button>
                      Please do not change column headers
                    </p>
                </Row>
              </Col>
            </Row>
          )
        }}
      </FieldArray>
    )
})

export default SiteDetails;