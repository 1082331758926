import React from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Row, Col, Input, Button, Table, Form } from 'reactstrap';
import Dropzone from 'react-dropzone-uploader';
import axios from 'axios';

export default function AddNewSite({ 
  dataDisable,
  environment, 
  accessEnv, 
  localData, 
  newRows, 
  submitNewSiteRequirements,
  modalOpen,
  toggle, 
  handleChangeStatus, 
  getUploadParams,
  handleRowsUpdate,
  handleURL,
  handleRssUrlsCrawlLimit,
  handleSubmit,
  errors,
  urlData
 }) {


  return (
    <div>
      {!dataDisable && (<Button color='success' className='btn-sm d-flex mx-auto mt-3' onClick={() => toggle()}>Add new site</Button>)}
      <Modal isOpen={modalOpen} toggle={() => toggle()} backdrop='static' size='xl'>
        <ModalHeader toggle={() => toggle()} >
          Submit requirements
        </ModalHeader>
        <ModalBody>
          <Row className='my-2 ps-4'>
            <Row>
              <h6>
                <b>Sitegroup name: </b>
                {localData?.sitegroup_name ?
                  localData?.sitegroup_name : ''}
              </h6>
              <Table hover className='site-details-table'>
                <thead>
                  <tr>
                    <th>SI.No</th>
                    <th>URL</th>
                    {accessEnv?.includes(environment) ? <th>Rss urls crawl limit</th> : ""}
                  </tr>
                </thead>
                <tbody>
                  {newRows?.map((element, index) => {
                    return (
                      <tr key={element}>
                        <td>
                          {index + 1}
                        </td>
                        <td style={{ width: accessEnv?.includes(environment) ? "70%" : "100%" }}>
                          <Input
                            type="text" name={`url${index}`} id={`url${index}`}
                            onChange={(e) => { handleURL(e.target.value, element) }}
                            value={urlData ? urlData[element]?.url : ''}
                            placeholder='http://www.ebay.com/sch/DSLR-Cameras/31388/bn_732/i.html' />
                          {errors && errors[element] && <p className='m-0 error-msg mt-1 mb-2'>
                            {errors[element].url}
                          </p>}
                        </td>
                        {accessEnv?.includes(environment) ?
                          <td>
                            <Input
                              type="text" name={`rss_urls_crawl_limit${index}`}
                              id={`rss_urls_crawl_limit${index}`}
                              onChange={(e) => { handleRssUrlsCrawlLimit(e.target.value, element) }}
                              value={urlData ? urlData[element]?.rss_urls_crawl_limit : ''}
                              placeholder='It will be 30k by default' />
                            {errors && errors[element] && <p className='m-0 error-msg mt-1 mb-2'>
                              {errors[element].rss_urls_crawl_limit}
                            </p>}
                          </td>
                          : ""
                        }
                      </tr>)
                  })}
                </tbody>
              </Table>
              <button className='btn btn-outline-primary my-3'
                onClick={(e) =>  handleRowsUpdate() }>
                + Add more fields
              </button>
            </Row>
            <div className='my-4 d-flex justify-content-center fw-bold'>
              OR
            </div>
            <Row>
              <Col lg={3}>
                <h6 className='fw-bold d-flex justify-content-end'>
                  Add Sites via a file: </h6>
              </Col>
              <Col lg={5}>
                <Form onSubmit={handleSubmit}>
                  <div className="dz-message needsclick">
                    <Dropzone 
                    getUploadParams={getUploadParams} 
                    maxFiles={1}
                    onChangeStatus={handleChangeStatus}
                    multiple={false}
                    canCancel={true} 
                    inputContent="Upload / Drop an Excel File"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.ms-excel.sheet.binary.macroEnabled.12,application/vnd.oasis.opendocument.spreadsheet,application/vnd.ms-excel.sheet.macroEnabled.12,application/vnd.oasis.opendocument.spreadsheet-template"
                    styles={{
                        dropzone: { height: 40 },
                        dropzoneActive: { borderColor: 'green' },
                      }}
                    />
                  </div>
                </Form>
                <p className='text-muted m-0 mt-2 text-sm'>
                  Support file formats are .xls, .xlsx, .xlsb, .xlsm, .ods, .ots, .uos
                </p>
              </Col>
            </Row>
            <p className='my-5 d-flex align-center'>
              <button onClick={handleExcelDownload}
                className='btn text-primary'> Click here for a sample Excel file. </button>
              Please do not change column headers
            </p>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => toggle()}>Discard</Button>
          <Button color="success" onClick={()=>submitNewSiteRequirements()}>Add sites</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
  

  // Helper function to download excel files
  function handleExcelDownload(){
    axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem("authToken")}`;
    axios.defaults.headers["Content-Type"] = `application/vnd.ms-excel`;
    axios.defaults.headers["Accept"] = `application/vnd.ms-excel`;
    axios({
      url: `/api/v1/download_sample_site_details`,
      method: 'GET',
      responseType: 'blob'
    })
      .then((res) => {
        const url_to_download = window.URL.createObjectURL(new Blob([res.data]));
        const a_tag = document.createElement('a');
        a_tag.href = url_to_download;
        a_tag.setAttribute('download', `sample_site_urls.xls`);
        document.body.appendChild(a_tag);
        a_tag.click();
      });
    axios.defaults.headers["Content-Type"] = `application/json`;
    axios.defaults.headers["Accept"] = `application/json`;
  }
}
