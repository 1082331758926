import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home, Info } from "react-feather";
import moment from "moment";
import axios from "axios";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";
import Select, { components } from "react-select";
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify'
import Top20SitesRssCount from './top_20_sites_rss_count';
import Top20SitesFeedcrawlCount from './top_20_sites_feedcrawl_count';
import CustomList from './custom_list';

import { MysqlInternalBasePoint } from '../../Common/BaseEsPoints/InternalEsBasePoint';

export default function Index() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const tabNameFromSearchUrl = new URLSearchParams(search).get("filter[tab_name]");
  const tabNameFromUrl = window.location.hash.substr(1);
  const searchParams = {
    freqUsedOrg: new URLSearchParams(search).get("filter[freq_used_org]") || "",
    orgIdentifier: new URLSearchParams(search).get("filter[org_identifier]") || "",
    projectTitle: new URLSearchParams(search).get("filter[project_title]") || "",
    customListName: new URLSearchParams(search).get("filter[custom_list_name]") || "",
    geoCluster: new URLSearchParams(search).get("filter[geo_cluster]") || "",
    topNsites: new URLSearchParams(search).get("filter[top_n_sites]") || "",
    fromDate: new URLSearchParams(search).get("filter[from_date]") || "",
    toDate: new URLSearchParams(search).get("filter[to_date]") || "",
    tabName: tabNameFromSearchUrl ? tabNameFromSearchUrl : tabNameFromUrl
  }
  const [freqUsedOrg, setFreqUsedOrg] = useState({label: '', value: ''});
  const [orgIdentifier, setOrgIdentifier] = useState({label: '', value: ''});
  const [projectTitle, setProjectTitle] = useState({label: '', value: ''});
  const [customListName, setCustomListName] = useState({label: '', value: ''});
  const [geoCluster, setGeoCluster] = useState({label: '', value: ''});
  const [topNsites, setTopNsites] = useState({label: '', value: ''});
  const [freqUsedOrgsOptions, setFreqUsedOrgsOptions] = useState([]);
  const [orgListOptions, setOrgListOptions] = useState([]);
  const [sitegroupListOptions, setSitegroupListOptions] = useState([]);
  const [customListsOptions, setCustomListsOptions] = useState([]);
  const [geoClusterOptions, setGeoClusterOptions] = useState([]);
  const [topNSitesListOptions, setTopNSitesListOptions] = useState([]);
  const [fromDateVolumeStatsData, setFromDateVolumeStatsData] = useState([]);
  const [toDateVolumeStatsData, setToDateVolumeStatsData] = useState([]);
  const [drlConfigWorkers, setDrlConfigWorkers] = useState([]);
  const [summaryTitle, setSummaryTitle] = useState();
  const now = new Date();
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(1, 'days')));
  const [toDate, setToDate] = useState(now);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(tabNameFromUrl ? tabNameFromUrl : 'volume_stats');

  const [searchFromDateVolumeStatsDataText, setSearchFromDateVolumeStatsDataText] = useState('');
  const [searchFromDateVolumeStatsDataResults, setSearchFromDateVolumeStatsDataResults]= useState([]);
  const [searchToDateVolumeStatsDataText, setSearchToDateVolumeStatsDataText] = useState('');
  const [searchToDateVolumeStatsDataResults, setSearchToDateVolumeStatsDataResults]= useState([]);

  const [searchDrlConfigWorkersText, setSearchDrlConfigWorkersText] = useState('');
  const [searchDrlConfigWorkersResults, setSearchDrlConfigWorkersResults]= useState([]);

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v1/internal/high_volume_clients_and_sites')
      .then(res => {
        let geo_clusters = []
        res.data.geo_clusters.forEach(item => {
          geo_clusters.push({ label: item, value: item })
        })
        geo_clusters.push({ label: 'All', value: 'all' })
        setGeoClusterOptions(geo_clusters);
        setFreqUsedOrgsOptions(res.data.freq_used_org_list);
        setOrgListOptions(res.data.org_list);
        setSitegroupListOptions(res.data.sitegroup_list);
        setCustomListsOptions(res.data.custom_lists);
        setTopNSitesListOptions(res.data.top_n_sites_list);
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong');
        toast.error(err?.response.data.message || 'Oops something went wrong');
      });
  }, []);

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.freqUsedOrg && searchParams.freqUsedOrg != freqUsedOrg.value) {
      setFreqUsedOrg({ label: searchParams.freqUsedOrg, value: searchParams.freqUsedOrg });
    }
    if (searchParams.orgIdentifier && searchParams.orgIdentifier != orgIdentifier.value) {
      setOrgIdentifier({ label: searchParams.orgIdentifier, value: searchParams.orgIdentifier });
    }
    if (searchParams.projectTitle && searchParams.projectTitle != projectTitle.value) {
      setProjectTitle({ label: searchParams.projectTitle, value: searchParams.projectTitle });
    }
    if (searchParams.customListName && searchParams.customListName != customListName.value) {
      setCustomListName({ label: searchParams.customListName, value: searchParams.customListName });
    }
    if (searchParams.geoCluster && searchParams.geoCluster != geoCluster.value) {
      setGeoCluster({ label: searchParams.geoCluster  == 'all' ? 'All' :  searchParams.geoCluster, value: searchParams.geoCluster });
    }
    if (searchParams.topNsites && searchParams.topNsites != topNsites.value) {
      setTopNsites({ label: searchParams.topNsites, value: searchParams.topNsites });
    }
    if (searchParams.tabName && searchParams.tabName != activeTab) {
      setActiveTab(searchParams.tabName);
    }
    if (searchParams.fromDate && searchParams.fromDate != fromDate) {
      setFromDate(new Date(searchParams.fromDate));
    }
    if (searchParams.toDate && searchParams.toDate != toDate) {
      setToDate(new Date(searchParams.toDate));
    }
    if (commit == 'Get Data') {
      if (searchParams.tabName == "drl_config_workers"){
        fetchDrlConfigWorkersData(searchParams);
      }
      else{
        fetchVolumeStatsData(searchParams);
      }
    }
  }

  function fetchVolumeStatsData(filterParams) {
    setLoading(true)
    axios.get(`/api/v1/internal/high_volume_clients_and_sites/volume_stats_data?filter[freq_used_org]=${filterParams.freqUsedOrg}&filter[org_identifier]=${filterParams.orgIdentifier}&filter[project_title]=${filterParams.projectTitle}&filter[custom_list_name]=${filterParams.customListName}&filter[top_n_sites]=${filterParams.topNsites}&filter[geo_cluster]=${filterParams.geoCluster}&filter[from_date]=${filterParams?.fromDate? moment(filterParams.fromDate).format('YYYY/MM/DD') : filterParams.fromDate}&filter[to_date]=${filterParams?.toDate? moment(filterParams.toDate).format('YYYY/MM/DD') : filterParams.toDate}`)
      .then(res => {
        console.log("res", res.data);
        setSummaryTitle(res.data?.summary_title);
        setFromDateVolumeStatsData(res.data?.from_date_volume_stats);
        setToDateVolumeStatsData(res.data?.to_date_volume_stats);
        setSearchFromDateVolumeStatsDataResults(res.data?.from_date_volume_stats);
        setSearchToDateVolumeStatsDataResults(res.data?.to_date_volume_stats);
        setLoading(false)
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong');
        toast.error(err?.response.data.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  function fetchDrlConfigWorkersData(filterParams) {
    setLoading(true)
    axios.get(`/api/v1/internal/high_volume_clients_and_sites/drl_config_workers_data?filter[freq_used_org]=${filterParams.freqUsedOrg}&filter[org_identifier]=${filterParams.orgIdentifier}&filter[project_title]=${filterParams.projectTitle}&filter[custom_list_name]=${filterParams.customListName}&filter[top_n_sites]=${filterParams.topNsites}&filter[geo_cluster]=${filterParams.geoCluster}&filter[from_date]=${filterParams?.fromDate? moment(filterParams.fromDate).format('YYYY/MM/DD') : filterParams.fromDate }&filter[to_date]=${filterParams?.toDate? moment(filterParams.toDate).format('YYYY/MM/DD') : filterParams.toDate}`)
      .then(res => {
        setSummaryTitle(res.data?.summary_title);
        setDrlConfigWorkers(res.data?.drl_config_workers);
        setSearchDrlConfigWorkersResults(res.data?.drl_config_workers);
        setLoading(false)
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong');
        toast.error(err?.response.data.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  const volumeStatsData = (e) => {
    e.preventDefault();
    setActiveTab('volume_stats');
    fetchVolumeStatsData(searchParams);
  }

  const drlConfigWorkersData = (e) => {
    e.preventDefault();
    setActiveTab('drl_config_workers');
    fetchDrlConfigWorkersData(searchParams);
  }

  const volumeStatsTableColumns = [
    {name: 'Site Name', selector: 'site', sortable: true},
    {name: 'Rss Q size', selector: 'rss_queue_size', sortable: true},
    {
      name: 'Rss Count', 
      selector: 'rss_count',
      sortable: true,
      cell: (row, index) => (
        <>
          {
            row.site.includes("social_standards_inc") && (row.rss_count > 0) ? 
            <>
              <p data-tip data-for={'rss-count-' + index}>{row.rss_count} <Info size={18} className='mx-2' /></p>
              <ReactTooltip id={'rss-count-' + index} clickable multiline delayHide={1000} effect='solid'>
                <div className="text-break">
                  <span>Expected RSS Count: {row.expected_rss_count}</span>
                </div>
              </ReactTooltip>
            </>
            :
            <>
              <p data-tip data-for={'rss-count-' + index}>{row.rss_count}</p>
            </>
          }
        </>
      ),
      conditionalCellStyles: [
        {
          when: row => row.rss_percentage < 20 && row.site.includes("social_standards_inc"),
          style: {
            backgroundColor: '#dc7633',
            color: 'white',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
        {
          when: row => row.rss_percentage > 30 && row.site.includes("social_standards_inc"),
          style: {
            backgroundColor: '#52be80',
            color: 'white',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        }
      ],
    },
    {name: 'Feedcrawl Count', selector: 'feedcrawl_count', sortable: true},
    {
      name: 'Upload Count', 
      selector: 'upload_count',
      sortable: true,
      cell: (row, index) => (
        <>
          {
            row.site.includes("social_standards_inc") && (row.upload_count > 0) ? 
            <>
              <p data-tip data-for={'rss-count-' + index}>{row.upload_count} <Info size={18} className='mx-2' /></p>
              <ReactTooltip id={'rss-count-' + index} clickable multiline delayHide={1000} effect='solid'>
                <div className="text-break">
                  <span>Expected Upload Count: {row.expected_upload_count}</span>
                </div>
              </ReactTooltip>
            </>
            :
            <>
              <p data-tip data-for={'rss-count-' + index}>{row.upload_count}</p>
            </>
          }
        </>
      ),
      conditionalCellStyles: [
        {
          when: row => row.upload_percentage < 20 && (row.site.includes("social_standards_inc")) || (row.site.includes("jato_dynamics")),
          style: {
            backgroundColor: '#dc7633',
            color: 'white',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
        {
          when: row => row.upload_percentage > 30 && (row.site.includes("social_standards_inc")) || (row.site.includes("jato_dynamics")),
          style: {
            backgroundColor: '#52be80',
            color: 'white',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        }
      ],
    },
  ]

  const drlConfigureWorkerTableColumns = [
    {name: 'Site Name', selector: 'site', sortable: true},
    {name: 'Rss Q size', selector: 'rss_queue_size', sortable: true},
    {name: 'Rss Count', selector: 'rss_count', sortable: true},
    {name: 'Feedcrawl Count', selector: 'feedcrawl_count', sortable: true},
    {name: 'Upload Count', selector: 'upload_count', sortable: true},
    {name: 'Job Type', selector: 'job_type', sortable: true},
    {name: 'Geographical Cluster', selector: 'cluster_name', sortable: true},
    {name: 'Workers Per Host', selector: 'workers_per_host', sortable: true},
    {name: 'Max Hosts', selector: 'max_hosts', sortable: true},
    {name: 'Tot workers', selector: 'tot_workers', sortable: true},
  ]

  const handleFromDateVolumeStatsDataSearch = event =>{
    if(event.target.value == ''){
      setSearchFromDateVolumeStatsDataResults(fromDateVolumeStatsData);
    }
    else{
      setSearchFromDateVolumeStatsDataResults(searchFromDateVolumeStatsData(event.target.value));
    }
    setSearchFromDateVolumeStatsDataText(event.target.value);
  }

  const handleToDateVolumeStatsDataSearch = event =>{
    if(event.target.value == ''){
      setSearchToDateVolumeStatsDataResults(toDateVolumeStatsData);
    }
    else{
      setSearchToDateVolumeStatsDataResults(searchToDateVolumeStatsData(event.target.value));
    }
    setSearchToDateVolumeStatsDataText(event.target.value);
  }

  const handleDrlConfigWorkersSearch = event =>{
    if(event.target.value == ''){
      setSearchDrlConfigWorkersResults(drlConfigWorkers);
    }
    else{
      setSearchDrlConfigWorkersResults(drlConfigWorkers, searchDrlConfigWorkerData(event.target.value));
    }
    setSearchDrlConfigWorkersText(event.target.value);
  }

  function searchDrlConfigWorkerData(tableData, value){
    var search_result = tableData.filter(item => item.site.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.rss_queue_size.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.rss_count.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.feedcrawl_count.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.upload_count.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.job_type.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.cluster_name.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.workers_per_host.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.max_hosts.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.tot_workers.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
  }

  function searchFromDateVolumeStatsData(value){
    var search_result = fromDateVolumeStatsData.filter(item => item.site.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = fromDateVolumeStatsData.filter(item => item.rss_queue_size.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = fromDateVolumeStatsData.filter(item => item.rss_count.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = fromDateVolumeStatsData.filter(item => item.feedcrawl_count.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = fromDateVolumeStatsData.filter(item => item.upload_count.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
  }

  function searchToDateVolumeStatsData(value){
    var search_result = toDateVolumeStatsData.filter(item => item.site.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = toDateVolumeStatsData.filter(item => item.rss_queue_size.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = toDateVolumeStatsData.filter(item => item.rss_count.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = toDateVolumeStatsData.filter(item => item.feedcrawl_count.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = toDateVolumeStatsData.filter(item => item.upload_count.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
  }

  const handleGetData = () => {
    window.location.href = `${MysqlInternalBasePoint}/high_volume_clients_and_sites?filter[freq_used_org]=${freqUsedOrg ? freqUsedOrg.value : ""}&filter[org_identifier]=${orgIdentifier ? orgIdentifier.value : ""}&filter[project_title]=${projectTitle ? projectTitle.value : ""}&filter[custom_list_name]=${customListName ? customListName.value : ""}&filter[top_n_sites]=${topNsites ? topNsites.value : ""}&filter[geo_cluster]=${geoCluster ? geoCluster.value : ""}&filter[from_date]=${fromDate? moment(fromDate).format('YYYY/MM/DD') : fromDate}&filter[to_date]=${toDate? moment(toDate).format('YYYY/MM/DD') : toDate}&filter[tab_name]=${activeTab}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/high_volume_clients_and_sites">High volume clients and sites</a> : 'High volume clients and sites'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
          </BreadcrumbItem>}
        </Breadcrumb>
      </Col>
    </Row>
  </div>

  <Row>
    <Col xs="2"></Col>
    <Col xs="10">
      <p className="float-right">
        Top 20 sites of max rss and feed crawl count (yesterday data):
        <Top20SitesRssCount />
        <Top20SitesFeedcrawlCount />
      </p>
    </Col>
  </Row>

  <Card>
    <CardBody>
      <Row>
        <Col sm="4">
          <label className="fw-bold">Frequently Used Orgs List</label>
          <Select
            value={freqUsedOrg?.value.length > 0 ? freqUsedOrg : "" }
            placeholder={"Select an option"}
            options={freqUsedOrgsOptions}
            isSearchable
            isClearable
            onChange={option => setFreqUsedOrg(option)} />
        </Col>
        <Col sm="4">
          <label className="fw-bold">Organization List</label>
          <Select
            value={orgIdentifier?.value.length > 0 ? orgIdentifier : "" }
            placeholder={"Select an option"}
            options={orgListOptions}
            isSearchable
            isClearable
            onChange={option => setOrgIdentifier(option)} />
        </Col>
        <Col sm="4">
          <label className="fw-bold">Sitegroup List </label>
          <Select
            value={projectTitle?.value.length > 0 ? projectTitle : "" }
            placeholder={"Select an option"}
            options={sitegroupListOptions}
            isSearchable
            isClearable
            onChange={option => setProjectTitle(option)} />
        </Col>
      </Row>
      <Row className="align-items-end mt-2">
        <Col sm="4">
          <label className="fw-bold">Custom List <CustomList /></label>
          <Select
            value={customListName?.value.length > 0 ? customListName : "" }
            placeholder={"Select an option"}
            options={customListsOptions}
            isSearchable
            isClearable
            onChange={option => setCustomListName(option)} />
        </Col>
        <Col sm="2">
          <label className="fw-bold">Date1</label>
          <DatePicker
            className="form-control"
            selected={fromDate ? new Date(fromDate) : fromDate}
            dateFormat={"YYY/MM/dd"}
            onChange={date => setFromDate(date)} />
        </Col>
        <Col sm="2">
          <label className="fw-bold">Date2</label>
          <DatePicker
            className="form-control"
            selected={toDate ? new Date(toDate) : toDate}
            dateFormat={"YYY/MM/dd"}
            onChange={date => setToDate(date)} />
        </Col>
        <Col sm="4">
          <label className="fw-bold">Geographical List </label>
          <Select
            value={geoCluster?.value.length > 0 ? geoCluster : "" }
            placeholder={"Select an option"}
            options={geoClusterOptions}
            isSearchable
            isClearable
            onChange={option => setGeoCluster(option)} />
        </Col>
      </Row>
      <Row className="align-items-end mt-2">
        <Col sm="4">
          <label className="fw-bold">Top N sites (Get yesterday data only)</label>
          <Select
            value={topNsites?.value.length > 0 ? topNsites : "" }
            placeholder={"Select an option"}
            options={topNSitesListOptions}
            isSearchable
            isClearable
            onChange={option => setTopNsites(option)} />
        </Col>
        <Col sm="4">
          <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
        </Col>
      </Row>
    </CardBody>
  </Card>

  {
    commit == 'Get Data' ?
    <>
      <Card>
        <CardBody>
          <Nav tabs>
            <NavItem>
              <NavLink className={activeTab == 'volume_stats' ? 'active' : ''} onClick={volumeStatsData}>
                Volume Stats
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={activeTab == 'drl_config_workers' ? 'active' : ''} onClick={drlConfigWorkersData}>
                Volume stats & Drl Configured workers
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={'volume_stats'}>
              <div className="panel-body">
                <center>
                  <h4>{summaryTitle}</h4><br />
                </center>
                <Row className='my-2'>
                  <Col lg={6}>
                    <center>
                      <span className="fw-bold">{fromDate? moment(fromDate).format("DD, MMM YYYY") : moment().subtract(1, "days").format("DD, MMM YYYY")}'s Data</span><br />
                    </center>
                    {
                      loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> 
                      :
                      <Row className="align-items-end mt-2">
                        <Col>
                          <div className="float-end mb-3">
                            <Row className="align-items-center">
                              <Col sm="3">Search</Col>
                              <Col>
                                <input type="text" className="form-control" value={searchFromDateVolumeStatsDataText} onChange={handleFromDateVolumeStatsDataSearch} />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <DataTable
                          columns={volumeStatsTableColumns}
                          data={searchFromDateVolumeStatsDataResults}
                          noHeader
                          pagination
                          progressPending={loading}
                        />
                      </Row>
                    }
                  </Col>
                  <Col lg={6}>
                    <center>
                      <span className="fw-bold">{toDate? moment(toDate).format("DD, MMM YYYY") : moment().format("DD, MMM YYYY") }'s Data</span><br />
                    </center>
                    {
                      loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> 
                      :
                      <Row className="align-items-end mt-2">
                        <Col>
                          <div className="float-end mb-3">
                            <Row className="align-items-center">
                              <Col sm="3">Search</Col>
                              <Col>
                                <input type="text" className="form-control" value={searchToDateVolumeStatsDataText} onChange={handleToDateVolumeStatsDataSearch} />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <DataTable
                          columns={volumeStatsTableColumns}
                          data={searchToDateVolumeStatsDataResults}
                          noHeader
                          pagination
                          progressPending={loading}
                        />
                      </Row>
                    }
                  </Col>
                </Row>
              </div>
            </TabPane>
            <TabPane tabId={'drl_config_workers'}>
              <div className="panel-body">
                <center>
                  <span className="fw-bold">{summaryTitle}</span><br />
                </center>
                <Row className='my-2'>
                  <Col lg={12}>
                    <center>
                      <span className="fw-bold">{toDate? moment(toDate).format("DD, MMM YYYY") : moment().format("DD, MMM YYYY") }'s Data</span><br />
                    </center>
                    {
                      loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> 
                      :
                      <Row className="align-items-end mt-2">
                        <Col>
                          <div className="float-end mb-3">
                            <Row className="align-items-center">
                              <Col sm="3">Search</Col>
                              <Col>
                                <input type="text" className="form-control" value={searchDrlConfigWorkersText} onChange={handleDrlConfigWorkersSearch} />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <DataTable
                          columns={drlConfigureWorkerTableColumns}
                          data={searchDrlConfigWorkersResults}
                          noHeader
                          pagination
                          progressPending={loading}
                        />
                      </Row>
                    }
                  </Col>
                </Row>
              </div>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </>
    : ""
  }
</Container>
}

