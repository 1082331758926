import axios from "axios";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import { Home } from "react-feather";
import Loader from "react-loader-spinner";
import { Link, useLocation } from "react-router-dom";
import AsyncSelect from 'react-select/async';
import Select, { components } from "react-select";
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";

export default function DependentDataUpload() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[site_name]"),
    fromDate: new URLSearchParams(search).get("filter[from_date]"),
    toDate: new URLSearchParams(search).get("filter[to_date]"),
  }
  const now = new Date()
  const [data, setData] = useState([]);
  const [siteName, setSiteName] = useState({label: "", value: ""});
  const [siteNameOptions, setSiteNameOptions] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const columns = [
    { name: 'Crawl Date', selector: 'crawl_date', center: true, sortable: true, cell: row => <a href={row.kibana_link} target={'_blank'} className="link-primary">{row.crawl_date}</a> },
    { name: 'Dependent Data Upload Job Creation Time', selector: 'dependent_data_upload_job_creation_time', center: true, sortable: true },
    { name: 'Dependent Data Upload Job Finished Time', selector: 'dependent_data_upload_job_finished_time', center: true, sortable: true },

    {
      name: 'Dependent Data Upload Status Started(%)', center: true, sortable: true, cell: row => <div id={"Id"+row.crawl_date} >
        <span className="fw-bold text-warning"  >{row.dd_upload_started}% </span>
        {row.dd_upload_started != 0 && <i className="fa fa-info-circle"></i>}
        {row.dd_upload_started != 0 && <UncontrolledTooltip style={{ backgroundColor: '#1976d2', color: '#ffffff' }} target={"Id"+row.crawl_date} placement="bottom-end" autohide={false} >
          <a target={'_blank'} href={row?.dd_upload_started_link} className="text-white">Kibana Link</a><br />
          <Link
            to={`/internal/extended_upload_process_status/show_file_list?filter[site_name]=${siteName.value}&filter[crawl_date]=${row.crawl_date}&filter[activity_type]=${'dependent_data_upload'}&filter[file_process_status]=${'started'}&filter[from_date]=${moment(fromDate).format('YYYY/MM/DD')}&filter[to_date]=${moment(toDate).format('YYYY/MM/DD')}`}
            className="text-white" target={'_blank'}>
            Click to see file names
          </Link><br />
          Host names: {row?.dd_started_hosts}
        </UncontrolledTooltip>}
      </div>
      },
    {
      name: 'Dependent Data Upload Status Failed(%)',  center: true, sortable: true, cell: row => <div id={`failed-${"Id"+row.crawl_date}`} >
        <span className="fw-bold text-danger"  >{row.dd_upload_failed}% </span>
        {row.dd_upload_failed != 0 && <i className="fa fa-info-circle"></i>}
        {row.dd_upload_failed != 0 && <UncontrolledTooltip style={{ backgroundColor: '#1976d2', color: '#ffffff' }} target={`failed-${"Id"+row.crawl_date}`} placement="bottom-end" autohide={false} >
          <a target={'_blank'} href={row?.dd_upload_failed_link} className="text-white">Kibana Link</a><br />
          <Link
            to={`/internal/extended_upload_process_status/show_file_list?filter[site_name]=${siteName.value}&filter[crawl_date]=${row.crawl_date}&filter[activity_type]=${'dependent_data_upload'}&filter[file_process_status]=${'failed'}&filter[from_date]=${moment(fromDate).format('YYYY/MM/DD')}&filter[to_date]=${moment(toDate).format('YYYY/MM/DD')}`}
            className="text-white" target={'_blank'}>
            Click to see file names
          </Link><br />
          Host names: {row?.dd_failed_hosts}
        </UncontrolledTooltip>}
      </div>
      },
    {
      name: 'Dependent Data Upload Status Success(%)', selector: 'file_merge_status_success', center: true, sortable: true, cell: row => <div id={`success-${"Id"+row.crawl_date}`} >
        <span className="fw-bold text-success"  >{row.dd_upload_success}% </span>
        {row.dd_upload_success != 0 && <i className="fa fa-info-circle"></i>}
        {row.dd_upload_success != 0 && <UncontrolledTooltip style={{ backgroundColor: '#1976d2', color: '#ffffff' }} target={`success-${"Id"+row.crawl_date}`} placement="bottom-end" autohide={false} >
          <a target={'_blank'} href={row?.dd_upload_success_link} className="text-white">Kibana Link</a><br />

      </UncontrolledTooltip>}
    </div>
    },

  ]

  useEffect(() => {
    setSearchParamsData()
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let site_names = res.data.site_names, site_names_options = []
        for (var key in site_names) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
          site_names[key].map(item => {
            children.push({ label: item, value: item })
          })
          group['options'] = children;
          site_names_options.push(group)
        }
        setSiteNameOptions(site_names_options);
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong');
      })
  }, []);

  function setSearchParamsData() {
    if (searchParams.siteName && searchParams.siteName != siteName.value) {
      setSiteName({
        label: searchParams.siteName,
        value: searchParams.siteName
      })
    }
    if (searchParams.fromDate && searchParams.fromDate != fromDate) {
      setFromDate(new Date(searchParams.fromDate))
    }
    if (searchParams.toDate && searchParams.toDate != toDate) {
      setToDate(new Date(searchParams.toDate))
    }
    if (commit == 'Get Data' && searchParams.siteName && searchParams.fromDate && searchParams.toDate) {
      fetchData(searchParams.siteName, searchParams.fromDate, searchParams.toDate)
    }

  }

  function fetchData(site_name = siteName.value, from_date = fromDate, to_date = toDate) {
    setLoading(true)
    axios.get(`/api/v1/internal/extended_upload_process_status/dependent_data_upload?filter[site_name]=${site_name? site_name : ""}&filter[from_date]=${from_date? moment(from_date).format('YYYY/MM/DD') : ""}&filter[to_date]=${to_date? moment(to_date).format('YYYY/MM/DD') : ""}`)
      .then(res => {
        setData(res.data.data);
        setSearchResults(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  const promiseOptions = (inputValue) => {
    if(inputValue.length < 3){
      return null
    } else {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200'){
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
              site_names[key].map(item => {
                children.push({ label: item, value: item })
              })
              group['options'] = children;
              site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setSiteNameOptions(site_names_options)
            resolve(site_names_options)
          }
        }).catch(err => {
          console.log(`err`, err)
        })
      })
    }
  }

  const handleInputChange = event => {
    if (event.target.value == '') {
      setSearchResults(data)
    }
    else {
      setSearchResults(searchFromData(event.target.value));
    }
    setSearchText(event.target.value);
  }

  function searchFromData(value){
    var search_result = data.filter(item => item.crawl_date.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = data.filter(item => item.dependent_data_upload_job_creation_time.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = data.filter(item => item.dependent_data_upload_job_finished_time.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = data.filter(item => item.dd_upload_started.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = data.filter(item => item.dd_upload_failed.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = data.filter(item => item.dd_upload_success.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
  }

  const handleSiteNameChange = (data) => {
    setSiteName(data)
  }

  const handleGetData = () => {
    window.location.href = `/internal/extended_upload_process_status/dependent_data_upload?filter[site_name]=${siteName? siteName.value : ""}&filter[from_date]=${fromDate? moment(fromDate).format('YYYY/MM/DD') : ""}&filter[to_date]=${toDate? moment(toDate).format('YYYY/MM/DD') : ""}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row>
        <Col xs="6">
          <h3> Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a className="link-primary" href={'/internal/extended_upload_process_status/dependent_data_upload'}>Extended Upload Process Status - Dependent Data Upload</a> : 'Extended Upload Process Status - Dependent Data Upload'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search Results
          </BreadcrumbItem>}
        </Breadcrumb>
      </Col>
    </Row>
  </div>
  {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> : <Fragment>
    <Card>
      <CardBody>
        <Row>
          <Col sm="12">
            <label className="fw-bold">Site Name</label>
            <AsyncSelect cacheOptions defaultOptions={siteNameOptions} name='site_name' value={siteName}
              loadOptions={promiseOptions} onChange={handleSiteNameChange}
              placeholder="Select a site name"
            />
            <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
          </Col>
        </Row>
        <Row className="mt-3 align-items-end">
          <Col sm="4">
            <label className="fw-bold">From Date</label>
            <DatePicker
              className="form-control"
              placeholderText="Select Date"
              maxDate={new Date()}
              selected={fromDate ? new Date(fromDate) : fromDate}
              dateFormat={"YYY/MM/dd"}
              onChange={date => setFromDate(date)} />
          </Col>
          <Col sm="4">
            <label className="fw-bold">To Date</label>
            <DatePicker
              className="form-control"
              selected={toDate ? new Date(toDate) : toDate}
              placeholderText="Select Date"
              maxDate={new Date()}
              dateFormat={"YYY/MM/dd"}
              onChange={date => setToDate(date)} />
          </Col>
          <Col sm="4">
            <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
          </Col>
        </Row>
      </CardBody>
    </Card>
    { searchParams?.siteName?.length > 0 ? data.length == 0 ? <div className="fw-bold">No Data Available</div> : <Card>
      <CardBody>
        <center>
          <span><h6><b>Site Name:</b> {siteName?.value}</h6></span>
          <span>
            <b>NOTE:</b><i className="fa fa-info-circle"></i> indicates the hover is there. It has some informations or links.
          </span>
        </center>
        <Row className="my-3">
          <Col>
            <div className="float-end">
              <Row className="align-items-center">
                <Col sm="3">Search</Col>
                <Col>
                  <input type="text" className="form-control" value={searchText} onChange={handleInputChange} />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <DataTable
          columns={columns}
          data={searchResults}
          noHeader
          highlightOnHover
          pagination
          progressPending={loading}
        />
      </CardBody>
  </Card> : ""}
</Fragment>}
      </Container>
}
