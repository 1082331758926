import React, { Fragment, useEffect, useState } from 'react'
import { Button, Modal, Row, Col } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import ShowMoreText from "react-show-more-text";

export default function ChatGPTDataComparison(props) {

  const { rowData, comparisonDataTableArray } = props; // props

  // states
  const [openModal, setOpenModal] = useState(false);
  const [filterComparisonTable, setFilterComparisonTable] = useState(comparisonDataTableArray);
  const [filterValue, setFilterValue] = useState("");

  // get product url and uniq id from the table data
  const uniqId = comparisonDataTableArray.find((data) => data?.field_name === "uniq_id");
  const productUrl = comparisonDataTableArray.find((data) => data?.field_name === "product_url");

  // Helper function to open and close modal
  const handleClose = () => setOpenModal(false);
  const handleShow = () => setOpenModal(true);


  const executeOnClick = (isExpanded) => {
    console.log(isExpanded);
  }

  const comparisonTableColumns = [
    {
      name: "Field Name",
      selector: row => row?.field_name,
      left: true,
      sortable: true
    },
    {
      name: "Raw Data",
      selector: row => (
        <ShowMoreText
          lines={2}
          more="Show more"
          less="Show less"
          className="content-css"
          anchorClass="show-more-less-clickable btn btn-primary"
          onClick={(event) => executeOnClick(event)}
          expanded={false}
          width={300}
          truncatedEndingComponent={"... "}
        ><p>{row?.raw_data}</p>
        </ShowMoreText>
      ),
      center: true,
      sortable: true,
      wrap: true
    },
    {
      name: "Pre-Fetched Data From ChatGPT",
      selector: row => (
        <ShowMoreText
          lines={2}
          more="Show more"
          less="Show less"
          className="content-css"
          anchorClass="show-more-less-clickable btn btn-primary"
          onClick={(event) => executeOnClick(event)}
          expanded={false}
          width={300}
          truncatedEndingComponent={"... "}
        ><p>{row?.pre_fetched_data}</p>
        </ShowMoreText>
      ),
      center: true,
      sortable: true,
      wrap: true
    },
  ]

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#cce5ff",
        color: "#004085",
      },
    },
    cells: {
      style: {
        border: "1px solid black",
        fontSize: "14px",
      },
    }
  };

  const customRowStyles = [
    {
      when: (row) => row.raw_data === row.pre_fetched_data && row.pre_fetched_data.includes(row.raw_data),
      style: {
        backgroundColor: '#fff3cd',
        color: "black",
      },
    },
    {
      when: (row) => row.raw_data.toString().toLowerCase() !== row.pre_fetched_data.toString().toLowerCase(),
      style: {
        backgroundColor: '#f8d7da',
        color: "black",
      },
    },
    {
      when: (row) => row.raw_data.toString().toLowerCase() === row.pre_fetched_data.toString().toLowerCase(),
      style: {
        backgroundColor: '#d4edda',
        color: "black",
      },
    },
  ];

  function filterData(event) {
    const { value } = event?.target;
    setFilterValue(value);
    if (value.length > 0) {
      const dataCopy = comparisonDataTableArray
      const filteredData = dataCopy?.filter((data) => (data?.data_match.trim() === value.trim()));
      setFilterComparisonTable(filteredData);
    }
    else {
      setFilterComparisonTable(comparisonDataTableArray);
    }
  }

  if (rowData?.prefetched_chatgpt_response !== "NA") {
    return (
      <Fragment>
        <Button variant="primary" onClick={handleShow}>Compare Data</Button>
        <Modal
          size="xl"
          show={openModal}
          onHide={handleClose}
          dialogClassName="custom_modal_70w"
          scrollable={true}
          fullscreen='xl-down'
        >
          <Modal.Header closeButton>
            <Modal.Title as="h5">Pre-fetched ChatGPT Data VS Raw Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='mb-3'>
              <Row>
                <Col md="6">
                  <div>
                    <p className='fw-bold'>{`Uniq Id: ${uniqId?.raw_data}`}</p>
                  </div>
                  <div>
                    <p className='fw-bold'>Product URL: <a href={productUrl?.raw_data}>{productUrl?.raw_data}</a></p>
                  </div>
                </Col>
                <Col md="4"></Col>
                <Col md="2">
                  <label htmlFor="filter-comparison-view">Filter By</label>
                  <select
                    name="filter-comparison"
                    id="filter-comparison-view"
                    className='form-select show-arrow'
                    value={filterValue}
                    onChange={(event) => filterData(event)}
                  >
                    <option value="" key={"all"}>All</option>
                    <option value="matched" key={"matched"}>Matched</option>
                    <option value="not matched" key={"not matched"}>Not matched</option>
                  </select>
                </Col>
              </Row>
            </div>
            <div>
              <div className="chat-gpt-data-comparison-table">
                <DataTable
                  columns={comparisonTableColumns}
                  data={filterComparisonTable}
                  conditionalRowStyles={customRowStyles}
                  customStyles={customStyles}
                  striped
                  noHeader
                  responsive
                  pagination
                  paginationPerPage={5}
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                  dense
                />
              </div>
              <div className='d-flex flex-column justify-content-center align-items-center align-content-center'>
                <div className='d-flex flex-row justify-content-center align-items-center'>
                  <div className='d-flex flex-row justify-content-center align-items-center'>
                    <div style={{ width: "20px", height: "20px", backgroundColor: "#d4edda" }} className='mx-2'></div>
                    Matched
                    <div style={{ width: "20px", height: "20px", backgroundColor: "#f8d7da" }} className='mx-2'></div>
                    Not Matched
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    )
  }
  else {
    return (
      <Fragment>
        "N/A"
      </Fragment>
    )
  }
}

function createFinalArrayOfData(inputArrayA, inputArrayB) {
  const arrayA = findCommonElements(inputArrayA, inputArrayB)
  const newArrayA = arrayA.map(obj => ({ ...obj, data_source: "Data from Raw File" }));
  const newArrayB = inputArrayB.map(obj => ({ ...obj, data_source: "Pre-fetched ChatGPT Data" }));

  const arrayC = [...newArrayA, ...newArrayB];
  return arrayC;
};

const findCommonElements = (arrayA, arrayB) => {
  const commonElements = {};

  // Check if both arrays have objects
  if (arrayA.length > 0 && arrayB.length > 0) {
    const objA = arrayA[0];

    // Iterate through the properties of the first object in arrayA
    for (const key in objA) {
      // Check if the key is present in the second object in arrayB
      if (arrayB[0].hasOwnProperty(key)) {
        // If present, add it to the commonElements object with value from objA
        commonElements[key] = objA[key];
      }
    }
  }

  // Convert the commonElements object to an array with a single object
  const arrayC = [commonElements];
  return arrayC;
};

function generateComparisonOutput(rawDataArray, prefetchedDataArray) {
  const output = [];

  for (let i = 0; i < rawDataArray.length; i++) {
    const rawData = rawDataArray[i];
    const prefetchedData = prefetchedDataArray[i] || {};

    for (const key in rawData) {
      if (Object.prototype.hasOwnProperty.call(rawData, key)) {
        const field_name = key;
        const raw_data = rawData[key];
        const pre_fetched_data = prefetchedData[key] || "N/A";

        output.push({ field_name, raw_data, pre_fetched_data });
      }
    }
  }

  return output;
};
