import React, { useState, useEffect, useContext } from 'react'
import { useParams } from "react-router-dom";
import axios from 'axios'
import { toast } from 'react-toastify'
import { Row, Table, Col } from 'reactstrap';
import Breadcrumbs from '../../../Common/BreadCrumb/BreadCrumbs.component';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import { OrgnaziationContext } from '../../../../Home';

export default function ClientFeasibilityReportSummary({ environment }) {
  let { organization_name, feas_id } = useParams();
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  let history = useHistory()

  let accessEnv = ['development', 'staging']

  const { setOrgName } = useContext(OrgnaziationContext)
  useEffect(() => {
    setOrgName(organization_name)
  }, [organization_name])

  useEffect(() => {
    axios.get(`/api/v1/organizations/${organization_name}/feasibility_reports/${feas_id}?page_type=client&controller_name=feasibility_reports`)
      .then(res => {
        setData(res?.data?.feasibility_report)
        setLoading(false)
      })
      .catch(err => {
        toast.error(err?.response?.data?.message)
      })
  }, []);

  // Helper function to handle button clicks
  const handleClick = () => {
    if (data?.address_contain_nessary_values) {
      axios.put(`/api/v1/organizations/${organization_name}/feasibility_reports/${feas_id}`, {
        organization: data?.address?.country == "IN"
          ? { name: data?.organization_name, gstin_id: data?.address?.gstin_id }
          : { name: data?.organization_name },
        address: {
          line1: data?.address?.line1, line2: data?.address?.line2, city: data?.address?.city,
          state: data?.address?.state, zip: data?.address?.zip
        },
        page_type: "client", controller_name: "feasibility_reports"
      }).then(res => {
        toast.success(res.data.message)
        if (data?.skip_agreements && data?.skip_invoice) {
          history.push('/')
        }
        else if (data?.skip_agreements) {
          history.push(`/organizations/${organization_name}/invoices/${res.data.invoice_id}`)
        }
        else {
          history.push(`/organizations/${organization_name}/feasibility_reports/${feas_id}/user_agreements`)
        }
      }).catch(err => {
        toast.error(err.response.data.message)
      })
    }
    else {
      history.push(`/organizations/${organization_name}/feasibility_reports/${feas_id}/billing_details`)
    }
  }

  const capitalize = (str, lower = false) => (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
  return (
    <>
      <div className='container container-fluid'>
        <Breadcrumbs parent={"Feasibility Report"} title={data?.id && data?.id}
          childrenObj={[{
            name: 'Feasibility Reports',
            url: `/organizations/${organization_name}/feasibility_reports/`
          }]} />

        <div className='card p-5'>
          <Row>
            <div>
              <span className='text-muted f-left label'>
                <i className='fa fa-info-circle me-1'></i>
                {data?.feasibility_check_status?.replaceAll("_", " ")}
              </span>

              <span className='text-muted f-right label'>
                <i className='fa fa-clock-o me-1'></i>
                {data?.created_at}
              </span>
            </div>
            <h4 className='f-w-100 text-center my-4'>
              Feasibility Report for Sitegroup {" "}
              <a href={`/organizations/${organization_name}/sites`}>{data?.sitegroup_name}</a>
            </h4>

            {data?.feasibility_check_status == "under_review" && <div className='my-4'>
              <Row>
                <Col md={3}>
                  <h6 className='fw-bold'>Sitegroup Name</h6>
                  <p>{data?.sitegroup_name}</p>
                </Col>
                <Col md={3}>
                  <h6 className='fw-bold'>Organization</h6>
                  <p>{data?.organization_name}</p>
                </Col>
                <Col md={3}>
                  <h6 className='fw-bold'>Country</h6>
                  <p>{data?.country_name}</p>
                </Col>
                <Col md={3}>
                  <h6 className='fw-bold'>Creator</h6>
                  <p>{data?.creator}</p>
                </Col>
                <Col md={3}>
                  <h6 className='fw-bold'>Crawl Type</h6>
                  <p>{data?.crawl_type.replaceAll('_', ' ')}</p>
                </Col>
                <Col md={3}>
                  <h6 className='fw-bold'>Additionl Informartion</h6>
                  <p>{data?.additional_information}</p>
                </Col>
                <Col md={3}>
                  <h6 className='fw-bold'>Attachments</h6>
                  {data?.attachments?.length > 0 ?
                    data?.attachments.map((each, index) => (<a href={each.url} key={index} download> {each.file_name} </a>))
                    : "NA"}
                </Col>
              </Row>
            </div>}

            <div>
              {data?.feasibility_check_status != "under_review" && <h6 className='my-3'>
                Thank you for providing us with the requirements. We had a close look at the requirements. Please find our analysis below.
              </h6>}

              {data?.fields_to_extract &&
                <h6 className='my-3'>
                  <b>Fields to Extract: </b> {data?.fields_to_extract}
                </h6>}

              {(data?.project_version === "v3") &&
                <div className='my-2'>
                  <h6 className='fw-bold'>Schema Details: </h6>
                  <Table className='my-4' bordered>
                    <thead className='bg-light text-dark fw-bold'>
                      <tr>
                        <th scope='col'>Field Name</th>
                        <th scope='col'>Data Type</th>
                        <th scope='col'>Is Mandatory</th>
                        <th scope='col'>Default Value</th>
                        <th scope='col'>Sample Value</th>
                        <th scope='col'>Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.schema_fields_details?.map((each, index) => (
                        <tr key={each.id}>
                          <td>{each.field_name}</td>
                          <td>{each.data_type}</td>
                          <td>{each.is_mandatory}</td>
                          <td>{each.default_value}</td>
                          <td>{each.sample_value}</td>
                          <td>{each.comments}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>}

              {/* --------------------------------------------Block Two---------------------------------------------------------------------------- */}

              {(data?.overall_feasibility && data?.feasibility_check_status != "under_review") &&
                <h6 className='my-3'>
                  <b>Overall Feasibility: </b> {capitalize(data?.overall_feasibility?.replaceAll("_", " "))}.
                  Please check the comments section for further details.
                </h6>}



              <Table className='my-4' bordered>
                <thead className='bg-light text-dark fw-bold'>
                  <tr>
                    <th scope='col'>#</th>
                    <th scope='col'>Site Name</th>
                    <th scope='col'>Frequency</th>
                    <th scope='col'>Feasibility</th>
                    <th scope='col'>{accessEnv.includes(environment) ? "Rss Urls Crawl Limit" : ""}</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.sites?.map((each, index) => (
                    <tr key={each.id}>
                      <td scope='row'>{index + 1}</td>
                      <td>{each.site_name}</td>
                      <td>{data?.frequency_in_text}</td>
                      <td>{capitalize(each.feasibility_status.replaceAll("_", " "))}</td>
                      <td>{accessEnv.includes(environment) ? each.rss_urls_crawl_limit : ""}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {data?.feasibility_check_status != "under_review" &&
                <div className='my-2'>
                  <h6> Our pricing structure would be as follow: </h6>
                  <Table className='my-4' bordered>
                    <thead className='bg-light text-dark fw-bold'>
                      <tr>
                        <th scope='col'>#</th>
                        <th scope='col'>Site Name</th>
                        <th scope='col'>One-time Site Setup Fee</th>
                        <th scope='col'>Monthly Maintenance Fee</th>
                        <th scope='col'>Volume Fee (per 10k records - prorated)</th>
                        <th scope='col'>Number of Free Records</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.sites?.map((each, index) => (
                        <tr key={each.id}>
                          <td scope='row'>{index + 1}</td>
                          <td>{each.site_name}</td>
                          <td>{each.pricing_detail.site_setup_fee}</td>
                          <td>{each.pricing_detail.monthly_maintenance_fee}</td>
                          <td>{each.pricing_detail.volume_charges}</td>
                          <td>{each.pricing_detail.number_of_free_records}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>}
              <h6>
                <b>Additional Cost: </b>{data?.any_additional_cost || 'NA'}
              </h6>
              {data?.feasibility_check_status != "under_review" && <>
                <h6 className='my-3'>
                  <b>Comments: </b> {data?.comments || " "}
                </h6>
                {data?.overall_feasibility === "not_feasible" ?
                  <h6 className='my-3'>
                    Though the current set of requirements do not look feasible,
                    please feel free to create new SiteGroup in case you wish to
                    evaluate feasibility of crawling a different source to collect the required data.
                  </h6>
                  : <h6 className='my-3'>
                    If everything sounds good, please proceed to view our Terms and Conditions. Upon accepting the same, your initial invoice (one-time setup fee per site) shall be generated. The prorated Monthly Maintenance Fee as well as the Volume charges will be billed separately at month-end based on the actual number of records delivered and adjustments, if any, shall be made against your next invoice.
                    <br /><br />
                    We shall get started as soon as the payment is made. The first set of data can be expected within 7 days (unless otherwise mentioned).
                    <br /><br />
                    Looking forward to working with you.
                  </h6>}
                <h6 className='my-3'>
                  Thanks, <br />
                  Team PromptCloud
                </h6>
                <hr />
              </>}
            </div>
          </Row>

          {data?.feasibility_check_status == "under_review" && <Row>
            <Col lg={6} className='p-2'>
              <h6 className='fw-bold'>Delivery Details</h6>
              <Table>
                <tbody>
                  <tr>
                    <td>
                      Output Format
                    </td>
                    <td className='fw-bold text-capitalize'>
                      {data?.data_format.toUpperCase()}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Frequency
                    </td>
                    <td className='fw-bold text-capitalize'>
                      {data?.frequency_in_text}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Delivery Method
                    </td>
                    <td className='fw-bold text-capitalize'>
                      {data?.data_delivery.toUpperCase()}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col lg={6} className='p-2'>
              <h6 className='fw-bold'>Additional Details</h6>
              <Table>
                <tbody>
                  {Object.keys(data?.additional_details).map((each, index) => (
                    <tr key={index}>
                      <td>
                        {each == "image_downloads" ? 'Enable Image / File Downloads' : capitalize(each.replaceAll("_", " "))}
                      </td>
                      <td>
                        {data?.additional_details[each] == 0 ? 'Disabled' : 'Enabled'}
                      </td>
                      <td>
                        {data?.additional_details[each] == 0 ? 'NA' : data?.additional_details[each]}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>}

          <div className='mt-3 d-flex justify-content-center'>
            {data?.feasibility_check_status == "not_feasible_and_closed" &&
              <a href={`/organizations/${organization_name}/sites/new`} className='btn btn-primary text-white'>Create new Sitegroup</a>}

            {data?.feasibility_check_status == "paid_and_proceeding" &&
              <p className='text-muted'>Crawl works are in progress </p>}

            {data?.feasibility_check_status == "report_generated" &&
              ((data?.skip_agreements && data?.skip_invoice) ? <button onClick={handleClick}
                className='text-white btn btn-success'> Accept and Proceed
              </button> : data?.skip_agreements ? <button onClick={handleClick} className='text-white btn btn-success'>
                Proceed to Pay
              </button> : <a href={`/organizations/${organization_name}/feasibility_reports/${feas_id}/user_agreements`}
                className='text-white btn btn-success'>
                Proceed
              </a>)
            }

            {data?.feasibility_check_status == "payment_pending" &&
              <a href={`/organizations/${organization_name}/invoices/${data?.invoice_id}`}
                className='text-white btn btn-primary'>
                Proceed to Pay
              </a>}
          </div>
        </div>
      </div>

    </>
  )
}
