import React, {useState} from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter, Input, Button, Row, Col } from 'reactstrap';
import axios from 'axios'
import { toast } from 'react-toastify';

export default function Feedback() {
    const [modal, setModal] = useState(false)
    const [feedbackObj, setFeedbackObj] = useState({
        name: "", subject: "", message: ""
    })
    const [loadingBtns, setLoadingBtns] = useState(false)

    const toggle = () => {
        setModal(curr => !curr)
    }

    const closeBtn = <button className="close" 
        onClick={() => setModal(false)}> &times; </button>;

    const sendFeedback = () => {
        if (feedbackObj.name.trim().length === 0 || feedbackObj.subject.trim().length < 2 ||
            feedbackObj.message.trim().length < 4){
            if (feedbackObj.name.trim().length === 0){
                toast.error("Please enter valid name")
            }
            if (feedbackObj.subject.trim().length < 2){
                toast.error("Please enter valid subject")
            }
            if (feedbackObj.message.trim().length < 4){
                toast.error("Please enter valid message")
            }
        }
        else{
            setLoadingBtns(true)
            axios.post(`/api/v1/feedback`, {
                feedback: {name: feedbackObj.name, email: localStorage.getItem("authEmail"),
                subject: feedbackObj.subject, message: feedbackObj.message}
            }).then(res => {
                toast.success(res.data.message)
                setModal(false)
                setLoadingBtns(false)
            }).catch(err => {
                toast.error(err.response.data.message)
                setLoadingBtns(false)
            })
        }
    }
    
    return (
        <>
            <Modal isOpen={modal} toggle={toggle} backdrop='static' size='md'>
                <ModalHeader toggle={toggle} close={closeBtn}>
                    CrawlBoard Feedback
                </ModalHeader>
                <ModalBody className=''>
                    <Row className='my-3'>
                        <Col lg={3}>
                            <h6 className='fw-bold my-0 text-end'>Name* </h6>
                        </Col>
                        <Col lg={9}>
                            <input type="text" className='form-control' name="name" value={feedbackObj.name}
                                onChange={(e) => setFeedbackObj({...feedbackObj, name: e.target.value})} />
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <Col lg={3}>
                            <h6 className='fw-bold my-0 text-end'>Subject* </h6>
                        </Col>
                        <Col lg={9}>
                            <input type="text" className='form-control' name="subject" value={feedbackObj.subject}
                                onChange={(e) => setFeedbackObj({...feedbackObj, subject: e.target.value})} />
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <Col lg={3}>
                            <h6 className='fw-bold my-0 text-end'>Message* </h6>
                        </Col>
                        <Col lg={9}>
                        <textarea rows="3" className='form-control' name="message" value={feedbackObj.message}
                            onChange={(e) => setFeedbackObj({...feedbackObj, message: e.target.value})} />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={sendFeedback}
                        disabled={loadingBtns}>Submit</Button>
                </ModalFooter>
            </Modal>
            <button className="btn btn-primary btn-xs feedback-btn" 
                onClick={toggle}>
                Feedback
            </button>
        </>
    )
}
