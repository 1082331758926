import React, { useState } from "react";
import { Card, CardBody, Col, Row, Container, Nav, NavItem, NavLink, TabContent, TabPane, Breadcrumb, BreadcrumbItem } from "reactstrap";
import DataTable from "react-data-table-component";
import axios from "axios";
import Loader from "react-loader-spinner";
import moment from "moment";
import { useEffect } from "react";
import { Home } from 'react-feather';
import { Link } from 'react-router-dom';

export default function HighRecordDropInExtractionOrDedup() {
    const [activeTab, setActiveTab] = useState('1');
    const [extractData, setExtractData] = useState([]);
    const [dedupData, setDedupData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState('');
    const [extSearchText, setExtSearchText] = useState('');
    const [extSearchResults, setExtSearchResults] = useState([]);
    const [dedupSearchText, setDedupSearchText] = useState('');
    const [dedupSearchResults, setDedupSearchResults] = useState([]);
    const dedupColumns = [
        { name: 'Site Name', selector: 'site_name', sortable: true, center: true, cell: row => <a target={'_blank'} href={`/internal/upload?filter[site]=${row.site_name}&commit=${'Get Data'}`}>{row.site_name}</a> },
        { name: 'Extraction Input', selector: 'dedup_input', sortable: true, center: true },
        { name: 'Extraction Dropped', selector: 'dedup_drop', sortable: true, center: true },
        { name: 'Percentage Drop In Dedup', selector: 'percentage_drop_in_dedup', sortable: true, center: true, cell: row => <div>{row.percentage_drop_in_dedup}%</div> },
        { name: 'Upload Count', selector: 'upload_count', sortable: true, center: true },
    ]
    const extractColumns = [
        { name: 'Site Name', selector: 'site_name', sortable: true, center: true, cell: row => <a target={'_blank'} href={`/internal/upload?filter[site]=${row.site_name}&commit=${'Get Data'}`}>{row.site_name}</a> },
        { name: 'Extraction Input', selector: 'ext_input', sortable: true, center: true },
        { name: 'Extraction Dropped', selector: 'ext_drop', sortable: true, center: true },
        { name: 'Percentage Drop In Dedup', selector: 'percentage_drop_in_ext', sortable: true, center: true, cell: row => <div>{row.percentage_drop_in_ext}%</div> },
        { name: 'Upload Count', selector: 'upload_count', sortable: true, center: true },
    ]

    useEffect(() => {
        setLoading(true)
        axios.get('/api/v1/internal/pipeline_stage/high_record_drop_in_extraction_or_dedup')
            .then(res => {
                setExtractData(res.data.sites_dropping_ext_data);
                setExtSearchResults(res.data.sites_dropping_ext_data)
                setDedupData(res.data.sites_dropping_dedup_data);
                setDedupSearchResults(res.data.sites_dropping_dedup_data)
                setDate(res.data.date)
                setLoading(false);
            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong')
                setLoading(false);
            })
    }, [])

    const handleExtChange = event => {
        if (event.target.value == '') {
            setExtSearchResults(extractData);
        }
        else {
            setExtSearchResults(extractData.filter(item => item.site_name.includes(event.target.value)))

        }
        setExtSearchText(event.target.value);
    }

    const handleDedupChange = event => {
        if (event.target.value == '') {
            setDedupSearchResults(dedupData);
        }
        else {
            setDedupSearchResults(dedupData.filter(item => item.site_name.includes(event.target.value)))
        }
        setDedupSearchText(event.target.value)
    }

    return <Container fluid>
        <div className="page-title">
            <Row className=''>
                <Col xs="6">
                    <h3>Internal Dashboard</h3>
                </Col>
                <Col xs="6">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={`/internal`}><Home /></Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem className="fw-bold">
                            Dropping Sites
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
        {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> : <Card>
            <CardBody>
                <Row>
                    <Col className="mb-3">
                        <ul>
                            <li>
                                <b>NOTE:</b>
                                <ul style={{ listStyleType: 'disc' }} className="ms-5">
                                    <li>We are not considering site with extraction count or dedup count less than 1000</li>
                                    <li>This report is generated each day for yesterdays sites and data</li>
                                    <li>Report is only generated 1's a day at 8:30 AM</li>
                                    <li>Right now listing only sites who's records dropped more than 50% in any stage</li>
                                </ul>
                            </li>
                        </ul>
                    </Col>
                    <Col className="text-end">
                        <Link to={'/internal/pipeline_stage/download_dropping_sites'} onClick={event => {
                            event.preventDefault();
                            window.open(`/api/v1/internal/pipeline_stage/download_dropping_sites`)
                        }} >Download an original file</Link>
                    </Col>
                </Row>
                {<center>The file generated on:<b>{moment(date).format("YYYY/MM/DD")}</b></center>}
                <Nav tabs>
                    <NavItem>
                        <NavLink onClick={() => setActiveTab('1')} className={activeTab == '1' ? 'active' : ''}>Site Dropping in Extraction Stage</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => setActiveTab('2')} className={activeTab == '2' ? 'active' : ''}>Site Dropping in Dedup Stage</NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={'1'}>
                        <Row className="align-items-center float-end my-3">
                            <div className="col-sm-3">Search</div>
                            <div className="col">
                                <input className="form-control" type={'text'} value={extSearchText} onChange={handleExtChange} />

                            </div>
                        </Row>
                        <DataTable
                            columns={extractColumns}
                            data={extSearchResults}
                            pagination
                            highlightOnHover
                            noHeader
                        />
                    </TabPane>
                    <TabPane tabId={'2'}>
                        <Row className="align-items-center float-end my-3">
                            <div className="col-sm-3 text-end">Search</div>
                            <div className="col">
                                <input className="form-control" type={'text'} value={dedupSearchText} onChange={handleDedupChange} />

                            </div>
                        </Row>
                        <DataTable
                            columns={dedupColumns}
                            data={dedupSearchResults}
                            pagination
                            noHeader
                            highlightOnHover
                        />
                    </TabPane>
                </TabContent>
            </CardBody>
        </Card>}
    </Container>
}