import React from 'react';
import { useMemo, useRef } from 'react';
import { Row, Card, Col, FormGroup, Label, Input } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage, FastField, useFormikContext } from 'formik';
import countryList from 'react-select-country-list';
import axios from 'axios';

export default function RequirementsSubmissionCard({}) {

  const previousValueRef = useRef('')
  const { errors, validateField, setFieldValue } = useFormikContext()
  const options = useMemo(() => countryList().getData(), []);

  const industryOptions = [
    { value: "", label: 'Select an industry type' },
    { value: 'ecommerce_retail', label: 'Ecommerce & Retail' },
    { value: 'travel', label: 'Travel' },
    { value: 'healthcare', label: 'Healthcare' },
    { value: 'banking_financial', label: 'Banking & Financial Services' },
    { value: 'software_it', label: 'Software & IT Services' },
    { value: 'market_research', label: 'Market research, Marketing & Advertising' },
    { value: 'publishing', label: 'Publishing' },
    { value: 'analytics', label: 'Analytics' },
    { value: 'recruitment', label: 'Recruitment' },
    { value: 'entertainment', label: 'Entertainment' },
    { value: 'education', label: 'Education' },
    { value: 'automotive', label: 'Automotive' },
    { value: 'other', label: 'Other' }
  ]

  const validateOrganization = async (value) => {
    let errorMessage;
    if( value == previousValueRef.current) return errors?.organization_name
    if (!value) {
      errorMessage = 'Organization name is required';
    } else if (value.trim().length < 3) {
      errorMessage = 'Please enter at least 3 characters';
    } else if (!/^[a-zA-Z0-9\s]+$/.test(value)) {
      errorMessage = 'Special characters not allowed in organization name.';
    } else if (['something', 'none', 'dummy', 'na', 'null', 'noname', 'blank', 'test'].includes(value.toLowerCase())) {
      errorMessage = 'Organization name should be valid.';
    } else if (!/^(?!\d)[0-9a-zA-Z\s]*$/.test(value)) {
      errorMessage = 'Organization name should not be just numbers (or) not start with numbers';
    } else {
      try {
        const response = await axios.post('/api/v1/organizations/validate_uniqness', {
          organization: { name: value }
        });
        if (response.data.status === 'success') {
          errorMessage = undefined;
        }
      } catch (error) {
        errorMessage = 'Organization name is taken already';
      }
    }
    previousValueRef.current = value
    return errorMessage;
  };

  return (
    <div>
      <Card className='px-5 py-4 mycard'>
      <Row className="py-2">
        <Col lg={4}>
          <FormGroup>
            <Label for="organization_name">Organization Name* </Label>
            <Field
              type="text"
              name="organization_name"
              id="organization_name"
              placeholder="Enter Organization name"
              className="form-control"
              validate={validateOrganization}
              onChange={(e)=> {
                setFieldValue("organization_name",e.target.value).then(()=> validateField("organization_name"))
              }}
            />
            <ErrorMessage name="organization_name" component="p" className="m-0 error-msg mt-1 mb-2" />
            <p className="text-muted m-0 mt-2 text-sm">
              Please give legal name of the company, it can't be changed later. This name is used while generating invoices.
            </p>
          </FormGroup>
        </Col>
        <Col lg={4}>
          <FormGroup>
            <Label for="industry">Industry* </Label>
            <Field
              as="select"
              name="industry"
              id="industry"
              className="form-control"
            >
              {industryOptions.map(industry => (
                <option key={industry.value} value={industry.value}>{industry.label}</option>
              ))}
            </Field>
            <ErrorMessage name="industry" component="p" className="m-0 error-msg mt-1 mb-2" />
          </FormGroup>
        </Col>
        <Col lg={4}>
          <FormGroup>
            <Label for="country">Country* </Label>
            <Field
              as="select"
              name="country"
              id="country"
              className="form-control"
            >
              <option value="">Select a country</option>
              {options.map(each => (
                <option value={each.value} key={each.value}>
                  {each.label}
                </option>
              ))}
            </Field>
            <ErrorMessage name="country" component="p" className="m-0 error-msg mt-1 mb-2" />
            <p className="text-muted m-0 mt-2 text-sm">
              Required to serve the right payment gateway.
            </p>
          </FormGroup>
        </Col>
          </Row>
      </Card>
    </div>
  )
}
