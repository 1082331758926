// These are the list of business assignees for the organization
// As these are static and do not change more frequently, it can be refrenced without an API request
// NOTE: CONTACT DEV TEAM (ROR) BEFORE MAKING ANY CHANGES HERE
export const  businessAssignees = [
    {
        id: 0,
        label: "arpan@promptcloud.com",
        value: "arpan@promptcloud.com"
    },
    {
        id: 1,
        label: "vivek@promptcloud.com",
        value: "vivek@promptcloud.com"
    },
    {
        id: 2,
        label: "sukeshc@promptcloud.com",
        value: "sukeshc@promptcloud.com"
    },
    {
        id: 3,
        label: "prashant@promptcloud.com",
        value: "prashant@promptcloud.com"
    },
    {
        id: 4,
        label: "gauravk@promptcloud.com",
        value: "gauravk@promptcloud.com"
    },
    {
        id: 5,
        label: "shivanig@prompt-cloud.com",
        value: "shivanig@prompt-cloud.com"
    },
    {
        id: 6,
        label: "aarthi@promptcloud.com",
        value: "aarthi@promptcloud.com"
    },
    {
        id: 7,
        label: "pavankj@promptcloud.com",
        value: "pavankj@promptcloud.com"
    },
    {
        id: 8,
        label: "ankitk@promptcloud.com",
        value: "ankitk@promptcloud.com"
    },
    {
        id: 9,
        label: "surendars@promptcloud.com",
        value: "surendars@promptcloud.com"
    },
    {
        id: 10,
        label: "raghunandana@promptcloud.com",
        value: "raghunandana@promptcloud.com"
    },
    {
        id: 11,
        label: "vinodhini@promptcloud.com",
        value: "vinodhini@promptcloud.com"
    },
    {
        id: 12,
        label: "anshika@promptcloud.com",
        value: "anshika@promptcloud.com"
    },
    {
        id: 13,
        label: "shashank@promptcloud.com",
        value: "shashank@promptcloud.com"
    },
    {
        id: 14,
        label: "divyar@promptcloud.com",
        value: "divyar@promptcloud.com"
    },
    {
        id: 15,
        label: "abhinav@promptcloud.com",
        value: "abhinav@promptcloud.com"
    },
]; 
