import React, { useState, useEffect, Fragment } from 'react'
import { Card, CardHeader, CardBody, Col, Row, Button } from 'reactstrap'
import Loader from 'react-loader-spinner';
import axios from 'axios'
import { toast } from 'react-toastify';
import { AlertTriangle, ArrowDown } from 'react-feather';
import DataTable from 'react-data-table-component'

import XpathsWithTwoStepsModal from './XpathsWithTwoStepsModal';
import ChatGPTDataComparison from './ChatGPTDataComparison';

export default function RawDataFile({ site_name, apiEndpoint, rssCrawlPushId }) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [fieldName, setFieldName] = useState([]);

  useEffect(() => {
    setLoading(true)
    if (rssCrawlPushId) {
      axios.get(apiEndpoint).then(res => {
        setData(res?.data?.data);
        setLoading(false)
      }).catch(err => {
        toast.error(err.response?.data?.message)
        setLoading(false)
      })
    }
  }, [site_name, apiEndpoint, rssCrawlPushId])

  const RAW_FILE_DOWNLOAD_API_ENDPOINT = `/api/v1/internal/new_qa_reports/${site_name}/rss_crawl_push_id/${rssCrawlPushId}/download_raw_file`

  const downloadCsvGzFile = (apiEndpoint, fileName) => {
    if (data?.raw_data_file_present) {
      axios.get(apiEndpoint, {
        responseType: 'blob',
      }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/gzip' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }).catch((err) => {
        toast.error(err?.message)
      });
    }
  };

  const tableHeaders = (headers) => {
    let tableColumns = []
    headers?.forEach((header) => {
      if (header?.selector === "file_paths") {
        tableColumns.push(
          {
            id: header?.id,
            name: header?.name,
            selector: header?.selector,
            cell: row => (
              <XpathsWithTwoStepsModal
                siteName={site_name}
                rowData={row?.prefetched_chatgpt_response !== "NA" ? Array(row?.prefetched_chatgpt_response) : []}
                inputFilePath={row?.file_paths}
                fieldName={fieldName}
                setFieldName={setFieldName}
              />
            ),
            center: true,
            responsive: true,
            sortable: true,
            wrap: true,
            compact: true,
            maxWidth: "87rem",
          }
        )
      }
      else if (header?.selector === "prefetched_chatgpt_response") {
        tableColumns.push(
          {
            id: header?.id,
            name: header?.name,
            selector: header?.selector,
            cell: row => (
              <ChatGPTDataComparison
                rowData={row}
                comparisonDataTableArray={generateComparisonOutput(Array(row), formatPrefetchData(row?.prefetched_chatgpt_response))}
                prefetchedData={
                  (row?.prefetched_chatgpt_response && row?.prefetched_chatgpt_response !== "NA") ? formatPrefetchData(row?.prefetched_chatgpt_response) : []
                }
                prefetchedDataColumns={
                  (row?.prefetched_chatgpt_response && row?.prefetched_chatgpt_response !== "NA") ? transformToColumnsArray(row?.prefetched_chatgpt_response) : []
                }
              />
            ),
            center: true,
            responsive: true,
            sortable: true,
            wrap: true,
            compact: true,
            maxWidth: "87rem",
          }
        )
      }
      else {
        tableColumns.push(
          {
            id: header.id,
            name: header.name,
            selector: header.selector,
            center: true,
            sortable: true,
            responsive: true,
            wrap: true,
            compact: true,
            maxWidth: "87rem",
          }
        )
      }
    })
    return tableColumns
  }

  return (
    <>
      {
        loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
          :
          <Card className='my-3'>
            <CardBody style={{ padding: '0px' }} className='new-qa-reports-raw-data-file'>
              <>
                {
                  data?.raw_data_file_present ?
                    <div className="row" style={{ padding: '20px' }}>
                      <div className="col">
                        Rss Crawl Push Id: <strong>{rssCrawlPushId ? rssCrawlPushId : "NA"}</strong>
                      </div>
                      <div className="col">
                        <Button
                          color='success'
                          disabled={!data.raw_data_file_present}
                          onClick={() => downloadCsvGzFile(RAW_FILE_DOWNLOAD_API_ENDPOINT, data?.raw_data_file_name)}
                          className='btn-sm fw-bold d-flex justify-content-center align-items-center pull-right'>
                          {
                            data.raw_data_file_present ? <><ArrowDown size={14} className='mx-1' />Download Raw File</> : <><AlertTriangle size={14} className='mx-1' /> Raw File Not Found </>
                          }
                        </Button>
                      </div>
                    </div> : ""
                }
                {
                  data?.is_raw_data_available ?
                    <DataTable
                      columns={tableHeaders(data?.raw_data_headers)}
                      data={data?.raw_data}
                      responsive noHeader striped highlightOnHover pagination
                    />
                    :
                    <p>No data available!</p>
                }
              </>
            </CardBody>
          </Card>
      }
    </>
  )
}

function formatPrefetchData(inputString) {
  try {
    const data = JSON.parse(inputString);

    if (typeof data === 'object' && data !== null) {
      // Extract keys and values
      const result = [Object.fromEntries(Object.entries(data).map(([key, value]) => [key, value.llm_value]))];

      return result;
    } else {
      throw new Error('Invalid JSON format.');
    }
  } catch (error) {
    console.error('Error parsing data:', error.message);
    return [];
  }
}


function parseData(inputString) {
  try {
    const data = JSON.parse(inputString);

    if (typeof data === 'object' && data !== null) {
      // Extract keys and values
      const result = Object.entries(data).map(([key, value]) => ({ [key]: value.llm_value }));

      return result;
    } else {
      throw new Error('Invalid JSON format.');
    }
  } catch (error) {
    console.error('Error parsing data:', error.message);
    return [];
  }
}

function capitalizeWords(str) {
  return str.replace(/_/g, ' ').replace(/\b\w/g, match => match.toUpperCase());
}

function transformToColumnsArray(inputString) {
  const inputArray = parseData(inputString);

  const dataSourceColumn = {
    name: "Data Source",
    selector: row => row?.data_source,
    left: true,
    sortable: true,
  };

  const transformedColumns = inputArray.map(obj => {
    const key = Object.keys(obj)[0];
    const name = capitalizeWords(key);
    const selectorFunction = row => {
      const value = row?.[key];
      return (value !== null && value !== "") ? value : "NA";
    };

    return {
      name,
      selector: selectorFunction,
      left: true,
      sortable: true,
      maxWidth: "650px",
    };
  });

  return [dataSourceColumn, ...transformedColumns];
}

function generateComparisonOutput(rawDataArray, prefetchedDataArray) {
  const output = [];

  for (let i = 0; i < rawDataArray.length; i++) {
    const rawData = rawDataArray[i];
    const prefetchedData = prefetchedDataArray[i] || {};

    for (const key in rawData) {
      if (Object.prototype.hasOwnProperty.call(rawData, key)) {
        const field_name = key;
        const raw_data = rawData[key];
        const pre_fetched_data = prefetchedData[key] || "NA";

        output.push({ field_name, raw_data, pre_fetched_data });
      }
    }
  }

  return output;
};