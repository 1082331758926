import React from 'react'
import { useState, useMemo } from 'react';
import { Button} from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, Label, Input } from 'reactstrap';
import { FormGroup } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
export default function InviteMembersButton({ color, size, children, ...otherProps }) {

    // organization name
    const organization_name = otherProps.organization;

    // Default post request body
    const defaultRequestBody = useMemo(() => ({
        first_name: '',
        last_name: '',
        email: '',
        designation: '',
        invited_by_id: otherProps.id,
        product_type: 'custom_projects',
        accessible_modules: [],
        notification_preferences: [],
    }), [otherProps.id]);

    // Available options for roles
    const availableRoles = useMemo(() => [
        {
            id: 1,
            optionLabel: "CXO",
            value: "CXO",
        },
        {
            id: 2,
            optionLabel: "CTO",
            value: "CTO",
        },
        {
            id: 3,
            optionLabel: "Product Head",
            value: "Product Head",
        },
        {
            id: 4,
            optionLabel: "Technical Head",
            value: "Technical Head",
        },
        {
            id: 5,
            optionLabel: "Business owner",
            value: "Business owner",
        },
        {
            id: 6,
            optionLabel: "Analyst",
            value: "Analyst",
        },
        {
            id: 7,
            optionLabel: "Other",
            value: "Other",
        },
    ], []);

    // Modal State
    const [modalOpen, setModalOpen] = useState(false);
    // Error checks
    const [errors, setErrors] = useState({})
    // Body of the post request
    const [requestBody, setRequestBody] = useState(defaultRequestBody);

    // Helper function to open and close modal
    function onModalOpenHandler() {
        resetFields();
        setModalOpen(!modalOpen);
    };

    // Helper function to reset form fields
    function resetFields() {
        setRequestBody(defaultRequestBody);
    }

    // Input change handler
    function onInputChangeHandler(event) {
        const { name, value } = event.target;
        setRequestBody({ ...requestBody, [name]: value });
    }

    // helper function to set accessible modules
    function onAccessibleModulesCheckHandler(status, element) {
        let temp = { ...requestBody }
        if (status) {
            if (element == "data_access") {
                let filteredArray = temp.notification_preferences.filter(item => item !== "redmine")
                temp = { ...temp, accessible_modules: [...temp.accessible_modules, element], notification_preferences: filteredArray }
            }
            else if (element == "billing") {
                let filteredArray = temp.notification_preferences.filter(item => item !== "invoice")
                temp = { ...temp, accessible_modules: [...temp.accessible_modules, element], notification_preferences: filteredArray }
            }
            else {
                temp = { ...temp, accessible_modules: [...temp.accessible_modules, element] }
            }
        }
        else {
            temp = { ...temp, accessible_modules: temp.accessible_modules.filter(x => x != element) }
        }
        setRequestBody(temp)
    };

    // helper function to handle notification preferences
    function onNotificationPreferencesCheckHandler(status, element) {
        let temp = { ...requestBody }
        if (status) {
            temp = { ...temp, notification_preferences: [...temp.notification_preferences, element] }
        }
        else {
            temp = { ...temp, notification_preferences: temp.notification_preferences.filter(x => x != element) }
        }
        setRequestBody(temp);
    };

    // helper function to handle form submit
    const onInviteMemberFormSubmitHandler = () => {
        setErrors({})
        if (requestBody?.first_name?.length === 0 || requestBody?.last_name?.length === 0 ||
            requestBody?.email?.length === 0 || requestBody?.designation?.length === 0 || requestBody?.accessible_modules?.length === 0) {
            let tempObj = {}
            if (requestBody?.first_name?.length === 0) {
                tempObj.first_name = 'First Name is required'
            }
            if (requestBody?.last_name?.length === 0) {
                tempObj.last_name = 'Last Name is required'
            }
            if (requestBody?.email?.length === 0) {
                tempObj.email = 'Email is required'
            }
            if (requestBody?.designation?.length === 0) {
                tempObj.designation = 'Role is required'
            }
            if (requestBody?.accessible_modules?.length === 0) {
                tempObj.accessible_modules = 'Atleast one Accessible module is required'
            }
            setErrors(tempObj)
        }
        else {
            axios.post(`/api/v1/organizations/${organization_name}/user_invites`, {
                user_invite: requestBody
            }).then(res => {
                toast.success(res.data.message);
                setModalOpen(false);
                setTimeout(()=>{location.reload();},650);
            }).catch(err => {
                toast.error(err.response.data.message);
            })
        }
    };

    return (
        <>
            <div>
                <Modal funk={"true"} isOpen={modalOpen} toggle={onModalOpenHandler} size='lg'>
                    <ModalHeader toggle={onModalOpenHandler}>
                        Add Member: {organization_name.replaceAll("_", " ")}
                    </ModalHeader>
                    <ModalBody className='px-5'>
                        <div className='row mb-3 align-items-center'>
                            <Label className='col-sm-2 h6'>First Name </Label>
                            <div className='col-sm-10'>
                                <Input className='form-control' type='text' name="first_name" required={true} onChange={onInputChangeHandler} />
                                {errors.first_name && <div className='form-text text-danger'> {errors.first_name} </div>}
                            </div>
                        </div>

                        <div className='row mb-3 align-items-center'>
                            <Label className='col-sm-2 h6' >Last Name </Label>
                            <div className='col-sm-10'>
                                <Input className='form-control' type='text' name="last_name" required={true} onChange={onInputChangeHandler} />
                                {errors.last_name && <div className='form-text text-danger'> {errors.last_name} </div>}
                            </div>
                        </div>

                        <div className='row mb-3 align-items-center'>
                            <Label className='col-sm-2 h6' >Email </Label>
                            <div className='col-sm-10'>
                                <Input className='form-control' type='email' name="email" required={true} onChange={onInputChangeHandler} />
                                {errors.email && <div className='form-text text-danger'> {errors.email} </div>}
                            </div>
                        </div>

                        <div className='row mb-3 align-items-center'>
                            <Label className='col-sm-2 h6' >Designation </Label>
                            <div className='col-sm-10'>
                                <Input className='form-control show-arrow' type='select' name="designation" required={true} onChange={onInputChangeHandler} >
                                    <option value="" selected disabled >Select Designation</option>
                                    {availableRoles.map(role => (
                                        <option name={role.optionLabel} key={role.id} value={role.value}>{role.optionLabel}</option>
                                    ))}
                                </Input>
                                {errors.designation && <div className='form-text text-danger'> {errors.designation} </div>}
                            </div>
                        </div>
                        <div className='row mb-3 align-items-center'>
                            <Label className='col-sm-2 h6' >Accessible Modules </Label>
                            <FormGroup className='col-sm-10'>
                                <Input type="checkbox" onChange={(e) => onAccessibleModulesCheckHandler(e.target.checked, "data_access")} />
                                {' '}
                                <Label className='h6'>
                                    Data Access
                                </Label>
                                {' '}
                                <br />
                                <Input type="checkbox" onChange={(e) => onAccessibleModulesCheckHandler(e.target.checked, "billing")} />
                                {' '}
                                <Label className='h6'>
                                    Billing
                                </Label>
                                {' '}
                                <br />
                                <Input type="checkbox" onChange={(e) => onAccessibleModulesCheckHandler(e.target.checked, "user_management")} />
                                {' '}
                                <Label className='h6'>
                                    User Management
                                </Label>
                                {' '}
                            </FormGroup>
                            {errors.accessible_modules && <div className='form-text text-danger'> {errors.accessible_modules} </div>}
                        </div>
                        <div className='row mb-3 align-items-center'>
                            <Label className='col-sm-2 h6' >Notification Preferences </Label>
                            <FormGroup className='col-sm-10'>
                                <Input 
                                type="checkbox" 
                                disabled={!requestBody?.accessible_modules?.includes('data_access')}
                                onChange={(e) => onNotificationPreferencesCheckHandler(e.target.checked, "redmine")} />
                                {' '}
                                <Label className='h6'>
                                    Redmine
                                </Label>
                                {' '}
                                <br />
                                <Input
                                    type="checkbox"
                                    disabled={!requestBody?.accessible_modules?.includes('billing')}
                                    onChange={(e) => onNotificationPreferencesCheckHandler(e.target.checked, "invoice")}
                                />
                                {' '}
                                <Label className='h6'>
                                    Invoice
                                </Label>
                                {' '}
                            </FormGroup>
                            {errors.notification_preferences && <p className='text-danger'> {errors.notification_preferences} </p>}
                        </div>
                    </ModalBody>
                    <ModalFooter className='px-5'>
                        <Button color='light' onClick={onModalOpenHandler}>Cancel</Button>
                        <Button color='success' onClick={onInviteMemberFormSubmitHandler}>Send Invite</Button>
                    </ModalFooter>
                </Modal>
            </div>
            <Button color={color} size={size} onClick={onModalOpenHandler} {...otherProps} >{children}</Button>
        </>
    )
};
