import React from 'react'
import { Accordion } from 'react-accessible-accordion'
import { Card, CardBody, Container } from 'reactstrap'
import Breadcrumb from '../global/Breadcrumb'
import MyAccordion from '../global/MyAccordion'

export default function FAQ() {
    return (
        <Container fluid className='pb-5'>
            <Breadcrumb parent={"Frequently Asked Questions"} title="FAQ" />

            <div>
                <h6 className='fw-bold my-2'>Access Rights</h6> <br />
                <MyAccordion title='Do you have multi-user access ?'>
                    <p>Yes. As an admin, you could add as many users as you like to your organization by providing their emails (one by one) using the “Add members” option under your organization profile along with specifying their respective roles (Admin or User). Each such user will then receive a request email to join your organization and will be automatically registered on the tool.
                    <br />
                    Once added, each user will be able to view all data under your organization.</p>
                </MyAccordion>

                <MyAccordion title='Can I edit roles for users ?'>
                    Yes. As an admin, you can change a user’s role at any time. However, you cannot change access rights for existing admin and user roles.
                </MyAccordion>

                <MyAccordion title='Can I add more roles ?'>
                    Yes. You could add a new role and select the access rights you like.
                </MyAccordion>

                <MyAccordion title='Can I add my accountant to the system without him/her worrying about other data related stuff on CrawlBoard ?'>
                    Yes. There's a specific role for accountant on Crawlboard and access rights have been designed so that he/she can view invoices and make payments on them.
                </MyAccordion>
            </div>

            <hr className='my-5' />
            <div>
                <h6 className='fw-bold my-2'>Organization</h6> <br />
                <MyAccordion title='Can I create multiple organizations ?'>
                    <p>
                        As a user representing your company, you are expected to belong to a single organization only. However, in rare cases when you are affiliated to more than one organization from a single email, you could ask our support to create a new organization against your existing user account. You can then switch organizations from your Profile page and access all data related to that entity.
                    </p>
                </MyAccordion>

                <MyAccordion title='Where can I find details on my API key and the API documentation ?'>
                    <p>
                        Click on the API Details option from the menu bar. This opens up details on our API and illustrations on how to use the same.
                    </p>
                </MyAccordion>

                <MyAccordion title="Does anything change if my organization's country is India ?">
                    <p>
                        Yes. If you're registered as an Indian entity, you won't see any invoices under billing section as there's no auto-payment setup for Indian entities. Our finance team will be sending you invoices separately via email.
                    </p>
                </MyAccordion>
            </div>

            <hr className='my-5' />
            <div>
                <h6 className='fw-bold my-2'>SiteGroups and Sites</h6> <br />
                <MyAccordion title='Can I add multiple SiteGroups to my organization ?'>
                    <p>
                        No. You could add as many sites as you like.
                    </p>
                </MyAccordion>

                <MyAccordion title='Is there a limit on the number of sites I can add to a SiteGroup ?'>
                    <p>
                        Click on the API Details option from the menu bar. This opens up details on our API and illustrations on how to use the same.
                    </p>
                </MyAccordion>

                <MyAccordion title="What is the 'Mark' feature all about ?">
                    <p>
                        ‘Mark’ feature helps you open up a sample URL within the browser and choose the fields you wish to extract; thereby eliminating any scope of any misunderstanding while communicating your requirements. Though we would prefer sites to be marked, it is not a mandatory step and you may opt to simply key-in the list of fields you wish to extract (provided they are self-explanatory enough).
                    </p>
                </MyAccordion>

                <MyAccordion title="What if the field I want extracted is not present on the page I am marking ?">
                    <p>
                    Your best bet is to find a sample page on the site that has all the fields you are looking to extract. If the current page you selected does not have all the fields, you can go back to edit the destination URL and mark this page instead. Else, you may always add relevant screenshots or type in the list of fields you wish to extract.
                    </p>
                </MyAccordion>

                <MyAccordion title="What happens after I submit my requirements for feasibility check ?">
                    <p>
                        Once you send the requirements for feasibility check, it goes through technical and legal checks to assess feasibility of crawling and extracting the required data. If needed, we will get back to you with questions. A feasibility report will then be generated and sent to your registered email address along with the final pricing details. If you wish to proceed, the standard terms and conditions would be made available for review and subsequently the initial invoice too will be generated to help get started with the project.
                    </p>
                </MyAccordion>

                <MyAccordion title="Can I change my requirements after sending it for feasibility check ?">
                    <p>
                        No. Please wait for the feasibility report before making further changes. Be assured that we will get started with the project only once all requirements are thoroughly understood and mutually agreed upon.
                    </p>
                </MyAccordion>
            </div>

            <hr className='my-5' />
            <div>
                <h6 className='fw-bold my-2'>Data Access</h6> <br />
                <MyAccordion title='How do I access the data you deliver ?'>
                    <p>
                        All data files that we crawl for you get uploaded on the API by default. You can find your API key and all other documentation related to accessing these files under the API section on the left menu. It's recommended to set up an automated system at your end to directly download the files from our API into your DB. Please take time to go through the documentation for a smooth data acquisition process.
                    </p>
                </MyAccordion>

                <MyAccordion title='Is there any other delivery option than the API ?'>
                    <p>
                        Yes. Apart from the default API option, you can choose to receive the data on your S3, FTP or Dropbox locations. There might be additional charges depending on the plan you're on.
                    </p>
                </MyAccordion>
            </div>

            <hr className='my-5' />
            <div>
                <h6 className='fw-bold my-2'>Billing</h6> <br />
                <MyAccordion title='Is there an option to see my bill based on current data usage via this account ?'>
                    <p>
                    Yes. All invoicing (current status and past invoices) can be found under the ‘Billing’ section.
                    </p>
                </MyAccordion>

                <MyAccordion title='What"s the first invoice all about ?'>
                    <p>
                    The first invoice is generated for the one-time setup fees and first month's maintenance costs for your sites. Volume costs are adjusted towards the next bill. We don't start the project until the first invoice is settled.
                    </p>
                </MyAccordion>

                <MyAccordion title="What is the 'Mark' feature all about ?">
                    <p>
                        ‘Mark’ feature helps you open up a sample URL within the browser and choose the fields you wish to extract; thereby eliminating any scope of any misunderstanding while communicating your requirements. Though we would prefer sites to be marked, it is not a mandatory step and you may opt to simply key-in the list of fields you wish to extract (provided they are self-explanatory enough).
                    </p>
                </MyAccordion>

                <MyAccordion title="What's the billing cycle ?">
                    <p>
                    As noted in the agreement terms, we follow a 30-day billing cycle. Data uploads between the 1st to the end of any month are accounted towards your next bill which is ready on the 1st of the next month.
                    </p>
                </MyAccordion>
            </div>

            <hr className='my-5' />
            <div>
                <h6 className='fw-bold my-2'>Support and Stats</h6> <br />
                <MyAccordion title='How do I contact you if I find a bug in the data I received ?'>
                    <p>
                    You can create an issue from the tickets section (‘Issues’ menu) and the support team will take care of resolving these.
                    </p>
                </MyAccordion>

                <MyAccordion title='Is there a way I can check how many records are yet to be uploaded as part of current crawl ?'>
                    <p>
                    Yes. All details like current status, data uploads, next scheduled run and many more can be found under the “Sites” option from the dashboard.
                    </p>
                </MyAccordion>

                <MyAccordion title="Can I edit issues already created ?">
                    <p>
                    Yes. From the issues page, clicking on an issue will take you to its individual page from where you can reply to it. You can also see all issue activities on your project from the Activities tab.
                    </p>
                </MyAccordion>
            </div>
        </Container>
    )
}
