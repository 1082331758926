import React, { useEffect, useState } from "react";
import { Home } from "react-feather";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Container, Row, Col, Card, CardBody } from "reactstrap";
import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";
import axios from "axios";

export default function RabbitmqDuplicateQueueReport() {
    const [loading, setLoading] = useState(false);
    const [searchText,setSearchText]=useState('');
    const [data,setData]=useState([]);
    const [searchResults,setSearchResults]=useState([])
    const columns=[
        {name: 'Site Name', selector: 'site_name', center: true, sortable: true},
        {name: 'Queue Count', selector: 'queue_size', center: true, sortable: true},
        {name: 'Server Name',selector: 'server', center: true, sortable: true},
    ]

    useEffect(()=>{
        axios.get(`/api/v1/internal/rabbitmq_queue_report/duplicate_queue_report`)
            .then( res =>{
                setData(res.data.duplicate_queues);
                setSearchResults(res.data.duplicate_queues);
            })
            .catch(err =>{
                console.error(err?.message || 'Oops something')
            })
    },[])

    const handleSearch = event =>{
        if(event.target.value == ''){
            setSearchResults(data)
        }
        else{
            setSearchResults(data.filter(item => item.site_name.includes(event.target.value)))
        }
        setSearchText(event.target.value);
    }

    return <Container fluid>
        <div className="page-title">
            <Row className=''>
                <Col xs="6">
                    <h3>Internal Dashboard</h3>
                </Col>
                <Col xs="6">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={'/internal'}>
                                <Home />
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem className="fw-bold">
                            Rabbitmq duplicate queue reports
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
        {loading ? <Loader /> : <Card>
            <CardBody>
                <Row className="my-3">
                    <Col>
                        <div className="float-end">
                            <Row className="align-items-center">
                                <Col sm="3">Search</Col>
                                <Col>
                                    <input type="text" className="form-control" value={searchText} onChange={handleSearch} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <DataTable 
                columns={columns} 
                noHeader
                pagination
                defaultSortField="queue_size"
                defaultSortAsc={false}
                data={searchResults} />
            </CardBody>
        </Card>}
    </Container>
}