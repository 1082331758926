import React, {Fragment, forwardRef, useImperativeHandle,
    useState, useEffect} from 'react';
  import { Card, CardBody, Row, Col, CardHeader, 
    Modal, ModalBody, ModalHeader, ModalFooter, Button} from 'reactstrap';
  import axios from 'axios';
  import Loader from 'react-loader-spinner';
  import DataTable from 'react-data-table-component';
  import { toast } from 'react-toastify';
  import DatePicker from 'react-datepicker';
  
  const ScheduleCrawls = forwardRef(({orgName, sitegroupNames, siteNames}, ref) => {
    const [modal, setModal] = useState(false);
    const [modalInfo, setModalInfo] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [loading, setLoading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState('all')
    const [selectedRows, setSelectedRows] = useState([]);
  
    const today = new Date(Date.now())
  
    const is_org_body_cupid = (orgName == "bodycupid") ? true : false
  
    const toggle = () => {
      setModal(curr => !curr)
    }
  
    const handleDateChange = (data) => {
      setSelectedDate(data)
    }
  
    useImperativeHandle(ref, () => ({
      handleRowChange(selectedRows){
        setSelectedRows(selectedRows)
        if (selectedRows.length === 0){
          setSelectedOptions('all')
        } else{
          setSelectedOptions('selected_sites')
        }
      }
    }));
  
    function getFormattedDate(date) {
      var year = date.getFullYear();
  
      var month = (1 + date.getMonth()).toString();
      month = month.length > 1 ? month : '0' + month;
  
      var day = date.getDate().toString();
      day = day.length > 1 ? day : '0' + day;
  
      return month + '/' + day + '/' + year;
    }
  
    const scheduleCrawl = (type) => {
      let selectedSiteIDs = []
      selectedRows.forEach(each => {
        selectedSiteIDs.push(each.id)
      })
      setLoading(true)
      if (selectedOptions === 'selected_sites'){
        axios.post(`/api/v1/organizations/${orgName}/sites/crawl_schedules`, {
          schedule: {
            selected_sites: selectedSiteIDs.toString(), date: getFormattedDate(selectedDate),
            site_options: 'selected_sites', crawl_type: type
          },
          page_type: "client",
          controller_name: "crawl_schedules"
        }).then(res => {
          toast.success(res.data.message)
          if (!is_org_body_cupid) {
            setModalInfo(res?.data?.crawl_schedule_today)
          }
          setModal(false)
          setLoading(false)
        }).catch(err => {
          toast.error(err?.response?.data?.message, { autoClose: false })
          setLoading(false)
        })
      }
      else if (selectedOptions === 'all'){
        axios.post(`/api/v1/organizations/${orgName}/sites/crawl_schedules`, {
          schedule: {
            date: getFormattedDate(selectedDate), 
            site_options: 'all_sites', crawl_type: type
          },
          page_type: "client",
          controller_name: "crawl_schedules"
        }).then(res => {
          toast.success(res.data.message)
          if (!is_org_body_cupid) {
            setModalInfo(res?.data?.crawl_schedule_today)
          }
          setModal(false)
          setLoading(false)
        }).catch(err => {
          toast.error(err?.response?.data?.message, { autoClose: false })
          setLoading(false)
        })
      }
      else{
        axios.post(`/api/v1/organizations/${orgName}/sites/crawl_schedules`, {
          schedule: {
            date: getFormattedDate(selectedDate), site_options: selectedOptions, crawl_type: type
          },
          page_type: "client",
          controller_name: "crawl_schedules"
        }).then(res => {
          toast.success(res.data.message)
          if (!is_org_body_cupid) {
            setModalInfo(res?.data?.crawl_schedule_today)
          }
          setModal(false)
          setLoading(false)
        }).catch(err => {
          toast.error(err?.response?.data?.message, { autoClose: false })
          setLoading(false)
        })
      }
    }
  
    const toggleModalInfo = () => {
      setModalInfo(curr => !curr)
    }
  
    const closeBtnModalInfo = <button className="close" 
      onClick={() => setModalInfo(false)}> &times; </button>;
  
    const closeBtn = <button className="close"
      onClick={() => setModal(false)}> &times; </button>;
  
    return (
      <>
        <Modal isOpen={modal} toggle={toggle} backdrop='static' size='lg'>
          <ModalHeader toggle={toggle} close={closeBtn}>
            { is_org_body_cupid ? "Initiate Recrawl" : "Schedule Crawls" }
          </ModalHeader>
          <ModalBody className='p-5'>
            <div>
              <div className='my-3 d-flex align-center'>
                <h6 className='me-2 fw-bold'>
                  Sites: 
                </h6>
                {
                  is_org_body_cupid ?
                  <select id="siteName" className='form-control show-arrow' value={selectedOptions}
                    onChange={(e) => setSelectedOptions(e.target.value)}>
                    <option value="selected_sites" disabled={selectedRows.length === 0}>
                      Selected sites
                    </option>
                    <option value='all'>All options</option>
                    {siteNames.map(each => (
                      <option key={each[0]} value={each[0]}>
                        {each[1]}
                      </option>
                    ))}
                  </select> :
                  <select id="siteName" className='form-control show-arrow' value={selectedOptions}
                    onChange={(e) => setSelectedOptions(e.target.value)}>
                    <option value="selected_sites" disabled={selectedRows.length === 0}>
                      Selected sites
                    </option>
                    <option value='all'>All options</option>
                    {sitegroupNames.map(each => (
                      <option key={each[0]} value={each[1]}>
                        Active sites of {each[1]}
                      </option>
                    ))}
                  </select>
                }
              </div>
              {
                !is_org_body_cupid &&
                <div className='mb-5 mt-2 d-flex align-center'>
                  <h6 className='me-2 fw-bold'>
                    Date: 
                  </h6>
                  <DatePicker id="crawlScheduleDate" className="form-control" selected={selectedDate}
                    minDate={today} onChange={handleDateChange} />
                </div>
              }
              <p>
                <b>NOTE: </b> These crawls will be performed only 
                if it does not clash with already running crawl
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            {
              loading ? 
              <>
                <Button className='primary mx-2' disabled>
                  Loading ...
                </Button>
                <Button className='success mx-2' disabled>
                  Loading ...
                </Button>
              </> :
              <>
                <Button color="primary" className='mx-2' onClick={() => scheduleCrawl("test_crawl")}>
                  { is_org_body_cupid ? "Initiate a Test Run" : "Schedule a Test Run" }
                </Button>
                <Button color="success"  className='mx-2' onClick={() => scheduleCrawl("full_crawl")}>
                  { is_org_body_cupid ? "Initiate a Complete Recrawl" : "Schedule a Complete Recrawl" }
                </Button>
              </> 
            }
          </ModalFooter>
        </Modal>
        <Button className='mx-2' color="primary" onClick={toggle}> { is_org_body_cupid ? "Initiate Recrawl" : "Schedule Crawls" } </Button>
  
        <Modal isOpen={modalInfo} toggle={toggleModalInfo} backdrop='static' size='lg'>
          <ModalHeader toggle={toggleModalInfo} close={closeBtnModalInfo}>
            Thank you for scheduling the crawl
          </ModalHeader>
          <ModalBody className='p-5'>
            <div>
              <h6 className='me-2 fw-bold'>Please note: </h6>
              <div className='my-3 d-flex align-center'>
                <ul>
                  <li>1. Our process will initiate the requested crawl in the next 1-2 hours.</li>
                  <li>2. The actual crawls may take a few hours to run and complete (based on the expected data volume).</li>
                  <li>3. The crawls run in smaller batches and data shall get uploaded to the API as soon as the first batch is processed.</li>
                  <li>4. You may enable Crawl Notifications <a href={`/organizations/${orgName}/sites`} >here</a> separately to get notified of crawl start and end.</li>
                </ul>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color='light' onClick={() => setModalInfo(false)}> Close </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  });
  
  export {ScheduleCrawls as default}
  