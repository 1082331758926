import React, {useState, useEffect} from 'react'
import { Container, Row, Col, ModalBody, Modal, ModalHeader,Card, CardBody, ModalFooter,
    Button, CardHeader, Table } from 'reactstrap'
import MembersRow from './MembersRow';
import moment from 'moment'
import { toast } from 'react-toastify';
import Breadcrumb from '../../global/Breadcrumb';
import axios from 'axios'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import { Eye } from 'react-feather';
import Loader from 'react-loader-spinner';

export default function NotificationPage({myOrganization}) {
    const [organization, setOrganization] = useState({})
    const [loading, setLoading] = useState(true)
    const [notificationsList, setNotificationsList] = useState([])

    useEffect(() => {
        console.log(`myOrganization`, myOrganization)
        if ("org_identifier" in myOrganization){
            setOrganization(myOrganization)
            getNotifications(myOrganization?.org_identifier)
        }
    }, [myOrganization])

    const getNotifications = (id) => {
        axios.get(`/api/v1/organizations/${id}/notifications?page_type=client&controller_name=notifications`).then(res => {
            setNotificationsList(res.data.notifications)
            setLoading(false)
        }).catch(err => {
            toast.error(err.response.data.message)
            setLoading(false)
        })
    }

    return (
        <Container fluid>
            <Breadcrumb parent="Notifications" title="Notifications (Last 1 month)" />

            {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> : 
            <Card>
                <CardHeader className='p-4'>
                    <h5 className='my-0'>{organization?.name}: Notifications</h5>
                </CardHeader>
                <CardBody>
                    <VerticalTimeline layout='1-column'>
                    { notificationsList?.map(each => (
                        <VerticalTimelineElement className="vertical-timeline-element--work" key={each.id}
                            date={moment(each?.created?.substring(0, 10), "YYYY-MM-DD").fromNow()}
                            iconStyle={{ background: '#F6F6F6', color: 'black' }} icon={<Eye />}>
                            <a href={each.url} className={each.read_at === null ? "vertical-timeline-element-title text-primary d-flex": "vertical-timeline-element-title text-dark d-flex"}
                                style={{fontSize: 16}}>{each.notification}</a>
                        </VerticalTimelineElement>
                    ))}
                    </VerticalTimeline>
                </CardBody>
            </Card>}
        </Container>
    )
}
