import React from 'react';
import { useState } from "react";

import { Container, Card, CardBody, Row, Col } from "reactstrap";
import { toast } from 'react-toastify';
import { Info } from 'react-feather';
import moment from "moment";
import axios from 'axios';
import { useLocation } from 'react-router-dom'

import SpinnerLoader from '../../../V2/Common/LoadingAnimation/SpinnerLoader.component';
import SegmentLevelCrawlTable from './DataTable/SegmentLevelCrawlTable.component';
import BreadCrumb from './Breadcrumb/BreadCrumb.component';
import SearchCard from './Cards/SearchCard.component';

export default function SegmentLevelCrawlExtractionStats() {
  const [crawlExtractionStatsData, setCrawlExtractionStatsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [displayTable, setDisplayTable] = useState(false);

  const search = useLocation().search;
  const siteValue = new URLSearchParams(search).get('filter[site]');

  const handleSearch = event => {
    if (event.target.value == '') {
      setSearchResults(crawlExtractionStatsData);
    }
    else {
      setSearchResults(searchFromData(event.target.value));
    }
  }

  function searchFromData(value) {
    var search_result = crawlExtractionStatsData.filter(item => item.segment_name.includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
    var search_result = crawlExtractionStatsData.filter(item => item.hostname.includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
    var search_result = crawlExtractionStatsData.filter(item => item.crawldate.toString().includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
    var search_result = crawlExtractionStatsData.filter(item => item.crawled_urls_count.toString().includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
    var search_result = crawlExtractionStatsData.filter(item => item.extract_output_record_count.toString().includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
    var search_result = crawlExtractionStatsData.filter(item => item.dedup_output_records_count.toString().includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
    var search_result = crawlExtractionStatsData.filter(item => item.normalize_output_records_count.toString().includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
    var search_result = crawlExtractionStatsData.filter(item => item.upload_output_records_count.toString().includes(value));
    if (search_result.length > 0) {
      return search_result;
    }
  };

  function fetchData(filterParams) {
    setLoading(true)
    setDisplayTable(true)
    axios.get(`/api/v2/internal/segment_level_crawl_extraction_stats?filter[site_name]=${filterParams.siteName}&filter[geo_cluster]=${filterParams.feedcrawlGeoCluster}&filter[hostname]=${filterParams.hostName}&filter[crawlstatus]=${filterParams.crawlStatus}&filter[dedupstatus]=${filterParams.dedupStatus}&filter[uploadstatus]=${filterParams.uploadStatus}&filter[normalizationstatus]=${filterParams.normalizationStatus}&filter[from_date]=${filterParams.fromDate}&filter[to_date]=${filterParams.toDate}&filter[test_run_status]=${filterParams.testRunStatus}`)
      .then(res => {
        console.log("res", res);
        setCrawlExtractionStatsData(res?.data?.crawl_extraction_stats_data);
        setSearchResults(res?.data?.crawl_extraction_stats_data);
        setLoading(false);
      })
      .catch(err => {
        toast.error(err?.response?.data?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  return (
    <div>
      <Container fluid>
        <BreadCrumb />
        <SearchCard
          fetchData={fetchData}
        />
        {displayTable ?
          <SpinnerLoader loading={loading}>
            {
              (siteValue && crawlExtractionStatsData.length > 0) ?
                <Card>
                  <CardBody>
                    <div className="mb-1">
                      <b>NOTE: </b> <br />
                      <span> 1) <Info size={18} className='mx-2' /> indicates the hover is there. It has some informations or links.</span><br />
                      <span> 2)  For "social_standards_inc" only latest 1000 segments are considered.</span><br />
                    </div>
                    <Row className="align-items-end mt-2">
                      <Col>
                        <div className="float-end mb-3">
                          <Row className="align-items-center">
                            <Col sm="3">Search</Col>
                            <Col>
                              <input type="text" className="form-control" onChange={(event) => handleSearch(event)} />
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <SegmentLevelCrawlTable
                        data={searchResults}
                        progressPending={loading}
                      />
                    </Row>
                  </CardBody>
                </Card>
                : <Card>
                  <CardBody>
                    <center>
                      <h5>There are no records to display</h5>
                    </center>
                  </CardBody>
                </Card>
            }
          </SpinnerLoader>
          : ""}
      </Container>
    </div>
  )
}
