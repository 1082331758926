import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home, Info, Copy } from "react-feather";
import moment from "moment";
import axios from "axios";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";
import AsyncSelect from 'react-select/async';
import Select, { components } from "react-select";
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify'

import { KIBANA_PLM_URL } from "../../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function New() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const host_name = new URLSearchParams(search).get("filter[hostname]");
  const dedup_status = new URLSearchParams(search).get("filter[dedupstatus]");
  const upload_status = new URLSearchParams(search).get("filter[uploadstatus]");
  const normalization_status = new URLSearchParams(search).get("filter[normalizationstatus]");
  const crawl_status = new URLSearchParams(search).get("filter[crawlstatus]");
  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[site]"),
    hostName: host_name ? host_name : "all",
    dedupStatus: dedup_status ? dedup_status : "any",
    uploadStatus: upload_status ? upload_status : "any",
    normalizationStatus: normalization_status ? normalization_status : "any",
    feedcrawlGeoCluster: new URLSearchParams(search).get("filter[geo_cluster]") || 'all',
    crawlStatus: crawl_status ? crawl_status : "any",
    testRunStatus: new URLSearchParams(search).get("filter[test_run_status]") || 'false',
    fromDate: new URLSearchParams(search).get("filter[crawldate][from]"),
    toDate: new URLSearchParams(search).get("filter[crawldate][to]"),
  }
  const [siteName, setSiteName] = useState({label: '', value: ''});
  const [hostName, setHostName] = useState({label: 'All', value: 'all'});
  const [dedupStatus, setDedupStatus] = useState({label: 'Any', value: 'any'});
  const [uploadStatus, setUploadStatus] = useState({label: 'Any', value: 'any'});
  const [normalizationStatus, setNormalizationStatus] = useState({label: 'Any', value: 'any'});
  const [feedcrawlGeoCluster, setFeedcrawlGeoCluster] = useState({label: 'All', value: 'all'});
  const [crawlStatus, setCrawlStatus] = useState({label: 'Any', value: 'any'});
  const [testRunStatus, setTestRunStatus] = useState('false');
  const [siteNameOptions, setSiteNameOptions] = useState([]);
  const [hostNameOptions, setHostNameOptions] = useState([]);
  const [feedcrawlGeoClusterOptions, setFeedcrawlGeoClusterOptions] = useState([]);
  const [crawlExtractionStatsData, setCrawlExtractionStatsData] = useState([]);
  const now = new Date();
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(2, 'days')));
  const [toDate, setToDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults]= useState([]);

  const dedupStatusOptions = [
    { label: 'Any', value: 'any' },
    { label: 'dedupdone', value: 'dedupdone' },
    { label: 'failed', value: 'failed' },
    { label: 'inprogress', value: 'inprogress' },
    { label: 'done', value: 'done' },
    { label: 'started', value: 'started' }
  ]

  const uploadStatusOptions = [
    { label: 'Any', value: 'any' },
    { label: 'uploaded', value: 'uploaded' },
    { label: 'dropped', value: 'dropped' },
    { label: 'failed', value: 'failed' },
    { label: 'inprogress', value: 'inprogress' },
    { label: 'done', value: 'done' },
    { label: 'started', value: 'started' }
  ]

  const normalizationStatusOptions = [
    { label: 'Any', value: 'any' },
    { label: 'normalized', value: 'normalized' },
    { label: 'inprogress', value: 'inprogress' },
    { label: 'failed', value: 'failed' },
    { label: 'done', value: 'done' },
    { label: 'started', value: 'started' }
  ]

  const crawlStatusOptions = [
    { label: 'Any', value: 'any' },
    { label: 'started', value: 'started' },
    { label: 'inprogress', value: 'inprogress' },
    { label: 'done', value: 'done' },
    { label: 'failed', value: 'failed' }
  ]

  function copyToClipboard(value){
    navigator.clipboard.writeText(value)
  }

  const tableColumns = [
    { 
      name: 'Segment Name', 
      selector: 'segment_name', 
      sortable: true, 
      cell: (row, index) => (
        <>
          <p data-tip data-for={'segment-name-tooltip-' + index}>{row.segment_name} <Info size={18} className='mx-2' /></p>
          <ReactTooltip id={'segment-name-tooltip-' + index} clickable multiline delayHide={1000} effect='solid'>
            <div className="text-break">
              <a href={segmentKibanaUrl(row)} target="_blank" className='text-white '>Kibana Link</a><br />
              <a href="http://alerta.promptcloud.com/#/alerts?status=open" target='_blank' className='text-white'>Alert Link (To Do)</a><br />
              <span>site path: {row.complete_site_path}</span>
            </div>
          </ReactTooltip>
        </>
        )
    },
    { 
      name: 'Date', 
      selector: 'crawldate', 
      sortable: true, 
      cell: (row, index) => (
        <>
          <p data-tip data-for={'crawldate-tooltip-' + index}>{row.crawldate} <Info size={18} className='mx-2' /></p>
          <ReactTooltip id={'crawldate-tooltip-' + index} clickable multiline  delayHide={1000} effect='solid'>
            <a href={dateKibanaUrl(row)} target="_blank" className='text-white'>Kibana Link</a><br />
            <a href="http://alerta.promptcloud.com/#/alerts?status=open" target='_blank' className='text-white'>Alert Link (To Do)</a>
          </ReactTooltip>
        </>
        )
    },
    { 
      name: 'Crawl', 
      selector: 'crawled_urls_count', 
      sortable: true, 
      cell: (row, index) => (
        <>
          <p data-tip data-for={'crawl-tooltip-' + index}>{row.crawled_urls_count} <Info size={18} className='mx-2' /></p>
          {
            row.crawled_urls_count == 0 ? 
            <ReactTooltip id={'crawl-tooltip-' + index} clickable multiline  delayHide={1000} effect='solid'>
              <span className='text-white'>No path</span>
            </ReactTooltip>
            :
            <ReactTooltip id={'crawl-tooltip-' + index} clickable multiline  delayHide={1000} effect='solid'>
              <span className='text-white'>Status: {row.crawlstatus}</span><br />
              <span className='text-white'>Crawl start time: {row.crawl_begin_time}</span><br />
              <span className='text-white'>Crawl end time: {row.crawl_end_time}</span><br />
              <span className='text-white'>Duration: {row.crawlstat_duration}</span><br />
              <span className='text-white'>Previous Host Name: {row.previous_hostname}</span><br />
              <span className='text-white'>Command: ./ruby.sh -l info ./recrawl_and_feedcrawl_automation.rb -f --noloop -s {row.site_name} [-i|--custom_flag]</span><br />
              <span className='text-white'>log File: {row.complete_site_path}.log</span><br />
              <span className='text-white'>input url: {row.complete_site_path}/urls</span><br />
              <span className='text-white'>crawled_data: {row.complete_site_path}</span><br />
              <span className='text-white'>God log files: {row.complete_site_path.split("/")[0]}/mnt/sdf/crawlspace/sdflogs/god/*feedcrawl*.log</span><br />
            </ReactTooltip>
          }
        </>
        )
    },
    { 
      name: 'Extraction', 
      selector: 'extract_output_record_count', 
      sortable: true,
      cell: (row, index) => (
        <>
          <p data-tip data-for={'extraction-tooltip-' + index}>{row.extract_output_record_count} <Info size={18} className='mx-2' /></p>
          {
            row.extract_output_record_count == 0 ? 
            <ReactTooltip id={'extraction-tooltip-' + index} clickable multiline  delayHide={1000} effect='solid'>
              <span className='text-white'>No path</span>
            </ReactTooltip>
            :
            <ReactTooltip id={'extraction-tooltip-' + index} clickable multiline  delayHide={1000} effect='solid'>
              <span className='text-white'>Status: {row.extractstatus}</span><br />
              <span className='text-white'>Extraction start time: {row.extraction_start_time}</span><br />
              <span className='text-white'>Extraction end time: {row.extraction_end_time}</span><br />
              <span className='text-white'>Duration: {row.extract_duration}</span><br />
              <span className='text-white'>Command: ./ruby.sh -l info ./extraction_and_dedup_automation_new.rb -e -s {row.site_name} --date {row.segment_path.split("-").pop().split("_")[0]}</span><br />
              {
                row.segment_path == "no_path" ? "No path" : 
                <>
                  <span className='text-white'>log directory: {row.segment_path.split("/")[0]}/mnt/sdf/crawlspace/extractions/{row.segment_path.split("crawlspace/")[1].split(row.segment_name)[0]}log/{row.segment_name}/</span><br />
                </>
              }
            </ReactTooltip>
          }
        </>
        )
    },
    { 
      name: 'Dedup', 
      selector: 'dedup_output_records_count', 
      sortable: true,
      cell: (row, index) => (
        <>
          <p data-tip data-for={'dedup-tooltip-' + index}>{row.dedup_output_records_count} <Info size={18} className='mx-2' /></p>
          {
            row.dedup_output_records_count == 0 ? 
            <ReactTooltip id={'dedup-tooltip-' + index} clickable multiline  delayHide={1000} effect='solid'>
              <span className='text-white'>No path</span>
            </ReactTooltip>
            :
            <ReactTooltip id={'dedup-tooltip-' + index} clickable multiline  delayHide={1000} effect='solid'>
              <span className='text-white'>Status: {row.dedupstatus}</span><br />
              <span className='text-white'>Dedup start time: {row.dedup_start_time}</span><br />
              <span className='text-white'>Dedup end time: {row.dedup_end_time}</span><br />
              <span className='text-white'>Duration: {row.dedup_duration}</span><br />
              <span className='text-white'>Command: ./ruby.sh -l info ./extraction_and_dedup_automation_new.rb -e -s {row.site_name} --date {row.segment_path.split("-").pop().split("_")[0]}</span><br />
              {
                row.segment_path == "no_path" ? "No path" : 
                <>
                  <span className='text-white'>{row.segment_path.split("/")[0]}/mnt/sdf/crawlspace/extractions/{row.segment_path.split("crawlspace/")[1].split(row.segment_name)[0]}dedup/{row.segment_name}/{row.segment_name.split("-")[0]}_deduped-{row.segment_name.split("-")[1]}.xml.delete</span><br />
                </>
              }
            </ReactTooltip>
          }
        </>
        )
    },
    { 
      name: 'Normalize', 
      selector: 'normalize_output_records_count', 
      sortable: true,
      cell: (row, index) => (
        <>
          <p data-tip data-for={'normalize-tooltip-' + index}>{row.normalize_output_records_count} <Info size={18} className='mx-2' /></p>
          {
            row.normalize_output_records_count == 0 ? 
            <ReactTooltip id={'normalize-tooltip-' + index} clickable multiline  delayHide={1000} effect='solid'>
              <span className='text-white'>No path</span>
            </ReactTooltip>
            :
            <ReactTooltip id={'normalize-tooltip-' + index} clickable multiline  delayHide={1000} effect='solid'>
              <span className='text-white'>Status: {row.normalizationstatus}</span><br />
              <span className='text-white'>Normalization start time: {row.normalization_start_time}</span><br />
              <span className='text-white'>Normalization end time: {row.normalization_end_time}</span><br />
              <span className='text-white'>Duration: {row.normalization_duration}</span><br />
              <span className='text-white'>Command: ./ruby.sh -l info ./extraction_and_dedup_automation_new.rb -e -s {row.site_name} --date {row.segment_path.split("-").pop().split("_")[0]}</span><br />
              {
                row.segment_path == "no_path" ? "No path" : 
                <>
                  <span className='text-white'>{row.segment_path.split("/")[0]}/mnt/sdf/crawlspace/extractions/{row.segment_path.split("crawlspace/")[1].split(row.segment_name)[0]}normalized/{row.segment_name}/{row.segment_name.split("-")[0]}_deduped_n-{row.segment_name.split("-")[1]}.csv.gz.delete</span><br />
                </>
              }
            </ReactTooltip>
          }
        </>
        )
    },
    { 
      name: 'Upload', 
      selector: 'upload_output_records_count', 
      sortable: true,
      cell: (row, index) => (
        <>
          <p data-tip data-for={'normalize-tooltip-' + index}>{row.upload_output_records_count} <Info size={18} className='mx-2' /></p>
          {
            row.upload_output_records_count == 0 ? 
            <ReactTooltip id={'normalize-tooltip-' + index} clickable multiline  delayHide={1000} effect='solid'>
              <span className='text-white'>No path</span>
            </ReactTooltip>
            :
            <ReactTooltip id={'normalize-tooltip-' + index} clickable multiline  delayHide={1000} effect='solid'>
              <span className='text-white'>Status: {row.uploadstatus}</span><br />
              <span className='text-white'>Upload start time: {row.upload_start_time}</span><br />
              <span className='text-white'>Upload end time: {row.upload_end_time}</span><br />
              <span className='text-white'>Duration: {row.upload_duration}</span><br />
              <span className='text-white'>Command: ./ruby.sh -l info ./extraction_and_dedup_automation_new.rb -e -s {row.site_name} --date {row.segment_path.split("-").pop().split("_")[0]}</span><br />
              {
                row.segment_path == "no_path" ? "No path" : 
                <>
                  <span className='text-white'>{row.segment_path.split("/")[0]}/mnt/sdf/crawlspace/extractions/{row.segment_path.split("crawlspace/")[1].split(row.segment_name)[0]}toupload/{row.segment_name}/{row.segment_name.split("-")[0]}_deduped-{row.segment_name.split("-")[1]}.csv.gz</span><br />
                </>
              }
            </ReactTooltip>
          }
        </>
        )
    },
    { 
      name: 'Host', 
      selector: 'hostname', 
      sortable: true,
      cell: (row, index) => (
        <>
          <p data-tip data-for={'host-tooltip-' + index}>{row.hostname} <Info size={18} className='mx-2' /></p>
          <ReactTooltip id={'host-tooltip-' + index} clickable multiline  delayHide={1000} effect='solid'>
            <span className='text-white'>Previous Host Name: {row.previous_hostname}</span><br />
          </ReactTooltip>
        </>
        )
    },
    { 
      name: 'Copy Site Path', 
      selector: 'complete_site_path', 
      sortable: true,
      cell: (row, index) => (
        <>
          <button className='btn btn-sm btn-outline-success' 
            onClick={() => copyToClipboard(row.complete_site_path)}> 
            <Copy size={14}/>
          </button>
        </>
        )
    },
  ]

  function segmentKibanaUrl(data) {
    return `${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(segid),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'segid.raw+:+%22${data.segment_name}%22'),sort:!(!('@timestamp',desc)))`
  }

  function dateKibanaUrl(data) {
    return `${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(segid,yyyymmdd),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'segid.raw+:+%22${data.segment_name}%22+and+yyyymmdd:${data.crawldate}'),sort:!(!('@timestamp',desc)))`
  }

  function statusKibanaUrl(data) {
    return `${KIBANA_PLM_URL}app/kibana#/discover?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:'${data.kibana_from_date}',to:'${data.kibana_to_date}'))&_a=(columns:!(date,current_status),filters:!(('$state':(store:appState),exists:(field:rss_status_tracker),meta:(alias:!n,disabled:!f,index:'76972760-1ad0-11ec-9735-177ab3888d9e',key:rss_status_tracker,negate:!f,type:exists,value:exists))),index:'76972760-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+${data.site_name}+OR+sitename:+${data.site_name}+OR+site:+${data.site_name})+and+date:+${data.crawldate}+and+current_status:+%22${data.rss_crawlstatus}%22'),sort:!(!('@timestamp',desc)))`
  }

  const [UrlParams, setUrlParams] = useState({
    siteName: '',
    hostName: 'all',
    dedupStatus: 'any',
    uploadStatus: 'any',
    normalizationStatus: 'any',
    feedcrawlGeoCluster: 'all',
    crawlStatus: 'any',
    testRunStatus: 'false',
    fromDate: new Date(moment().subtract(2, 'days')),
    toDate: now
  })

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let site_names = res.data.site_names, site_names_options = []
        for (var key in site_names) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
          site_names[key].map(item => {
            children.push({ label: item, value: item })
          })
          group['options'] = children;
          site_names_options.push(group)
        }
        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: '', value: '' }] })
        setSiteNameOptions(site_names_options);
        let geo_clusters = []
        res.data.geo_clusters.forEach(item => {
          geo_clusters.push({ label: item, value: item })
        })
        geo_clusters.push({ label: 'All', value: 'all' })
        setFeedcrawlGeoClusterOptions(geo_clusters);
        let machine_names = []
        res.data.machine_names.forEach(item => {
          machine_names.push({ label: item, value: item })
        })
        machine_names.push({ label: 'All', value: 'all' })
        setHostNameOptions(machine_names);
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong');
      });
  }, []);

  const promiseOptions = (inputValue) => {
    if(inputValue.length < 3){
      return null
    } else {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200'){
            console.log(res.data)
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
              site_names[key].map(item => {
                children.push({ label: item, value: item })
              })
              group['options'] = children;
              site_names_options.push(group)
            }
            setSiteNameOptions(site_names_options)
            resolve(site_names_options)
          }
        }).catch(err => {
          console.error(err?.message || 'Oops something went wrong');
        })
      })
    }
  }

  const handleSiteNameChange = (data) => {
    setSiteName(data)
  }

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.siteName && searchParams.siteName != siteName.value) {
      setSiteName({ label: searchParams.siteName, value: searchParams.siteName });
    }
    if (searchParams.feedcrawlGeoCluster && searchParams.feedcrawlGeoCluster != feedcrawlGeoCluster.value) {
      setFeedcrawlGeoCluster({ label: searchParams.feedcrawlGeoCluster  == 'all' ? 'All' :  searchParams.feedcrawlGeoCluster, value: searchParams.feedcrawlGeoCluster });
    }
    if (searchParams.hostName && searchParams.hostName != hostName.value) {
      setHostName({ label: searchParams.hostName, value: searchParams.hostName });
    }
    if (searchParams.dedupStatus && searchParams.dedupStatus != dedupStatus.value) {
      setDedupStatus({ label: searchParams.dedupStatus, value: searchParams.dedupStatus });
    }
    if (searchParams.uploadStatus && searchParams.uploadStatus != uploadStatus.value) {
      setUploadStatus({ label: searchParams.uploadStatus, value: searchParams.uploadStatus });
    }
    if (searchParams.normalizationStatus && searchParams.normalizationStatus != normalizationStatus.value) {
      setNormalizationStatus({ label: searchParams.normalizationStatus, value: searchParams.normalizationStatus });
    }
    if (searchParams.crawlStatus && searchParams.crawlStatus != crawlStatus.value) {
      setCrawlStatus({ label: searchParams.crawlStatus, value: searchParams.crawlStatus });
    }
    if (searchParams.testRunStatus && searchParams.testRunStatus != testRunStatus) {
      setTestRunStatus(searchParams.testRunStatus);
    }
    if (searchParams.fromDate && searchParams.fromDate != fromDate) {
      setFromDate(new Date(searchParams.fromDate));
    }
    if (searchParams.toDate && searchParams.toDate != toDate) {
      setToDate(new Date(searchParams.toDate));
    }
    if (commit == 'Get Data' || searchParams.siteName && searchParams.fromDate && searchParams.toDate) {
      setUrlParams(searchParams)
      fetchData(searchParams);
    }
  }

  function fetchData(filterParams) {
    setLoading(true)
    axios.get(`/api/v1/internal/segment_level_crawl_extraction_stats?filter[site_name]=${filterParams.siteName}&filter[geo_cluster]=${filterParams.feedcrawlGeoCluster}&filter[hostname]=${filterParams.hostName}&filter[crawlstatus]=${filterParams.crawlStatus}&filter[dedupstatus]=${filterParams.dedupStatus}&filter[uploadstatus]=${filterParams.uploadStatus}&filter[normalizationstatus]=${filterParams.normalizationStatus}&filter[from_date]=${moment(filterParams.fromDate).format('YYYY/MM/DD')}&filter[to_date]=${moment(filterParams.toDate).format('YYYY/MM/DD')}&filter[test_run_status]=${filterParams.testRunStatus}`)
      .then(res => {
        setCrawlExtractionStatsData(res.data.crawl_extraction_stats_data);
        setSearchResults(res.data.crawl_extraction_stats_data);
        setLoading(false);
      })
      .catch(err => {
        toast.error(err?.response.data.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  const handleSearch = event =>{
    if(event.target.value == ''){
      setSearchResults(crawlExtractionStatsData);
    }
    else{
      setSearchResults(searchFromData(event.target.value));
    }
    setSearchText(event.target.value);
  }

  function searchFromData(value){
    var search_result = crawlExtractionStatsData.filter(item => item.segment_name.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = crawlExtractionStatsData.filter(item => item.hostname.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = crawlExtractionStatsData.filter(item => item.crawldate.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = crawlExtractionStatsData.filter(item => item.crawled_urls_count.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = crawlExtractionStatsData.filter(item => item.extract_output_record_count.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = crawlExtractionStatsData.filter(item => item.dedup_output_records_count.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = crawlExtractionStatsData.filter(item => item.normalize_output_records_count.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = crawlExtractionStatsData.filter(item => item.upload_output_records_count.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
  }


  const handleGetData = () => {
    window.location.href = `/internal/segment?filter[site]=${siteName.value}&filter[geo_cluster]=${feedcrawlGeoCluster.value}&filter[hostname]=${hostName.value}&filter[crawlstatus]=${crawlStatus.value}&filter[dedupstatus]=${dedupStatus.value}&filter[uploadstatus]=${uploadStatus.value}&filter[normalizationstatus]=${normalizationStatus.value}&filter[test_run_status]=${testRunStatus}&filter[crawldate][from]=${moment(fromDate).format('YYYY/MM/DD')}&filter[crawldate][to]=${moment(toDate).format('YYYY/MM/DD')}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/segment/new">Segment Level Crawl Extraction Stats</a> : 'Segment Level Crawl Extraction Stats'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
          </BreadcrumbItem>}
        </Breadcrumb>
      </Col>
    </Row>
  </div>

  <Card>
    <CardBody>
      <Row className="mb-2">
        <div className="col-sm-6">
          <label className="fw-bold">Site Name</label>
          <AsyncSelect cacheOptions defaultOptions={siteNameOptions} name='site_name' value={siteName}
            loadOptions={promiseOptions} onChange={handleSiteNameChange}
            placeholder="Select a site name"
          />
          <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
        </div>
        <Col sm="3">
          <label className="fw-bold">Feedcrawl Geo Cluster</label>
          <Select
            value={feedcrawlGeoCluster}
            options={feedcrawlGeoClusterOptions}
            onChange={option => setFeedcrawlGeoCluster(option)} />
        </Col>
        <Col sm="3">
          <label className="fw-bold">Crawl Status </label>
          <Select
            value={crawlStatus}
            options={crawlStatusOptions}
            onChange={option => setCrawlStatus(option)} />
        </Col>
      </Row>
      <Row className="align-items-end mb-2">
        <Col sm="3">
          <label className="fw-bold">Host Name</label>
          <Select
            value={hostName}
            options={hostNameOptions}
            onChange={option => setHostName(option)} />
        </Col>
        <Col sm="3">
          <label className="fw-bold">Dedup Status </label>
          <Select
            value={dedupStatus}
            options={dedupStatusOptions}
            onChange={option => setDedupStatus(option)} />
        </Col>
        <Col sm="3">
          <label className="fw-bold">Upload Status </label>
          <Select
            value={uploadStatus}
            options={uploadStatusOptions}
            onChange={option => setUploadStatus(option)} />
        </Col>
        <Col sm="3">
          <label className="fw-bold">Normalization Status </label>
          <Select
            value={normalizationStatus}
            options={normalizationStatusOptions}
            onChange={option => setNormalizationStatus(option)} />
        </Col>
      </Row>
      <Row className="align-items-end mb-2">
        <Col sm="3">
          <label className="fw-bold">From Date</label>
          <DatePicker
            className="form-control"
            selected={new Date(fromDate)}
            dateFormat={"YYY/MM/dd"}
            maxDate={new Date()}
            onChange={date => setFromDate(date)} />
        </Col>
        <Col sm="3">
          <label className="fw-bold">To Date</label>
          <DatePicker
            className="form-control"
            selected={new Date(toDate)}
            dateFormat={"YYY/MM/dd"}
            minDate={new Date(fromDate)}
            maxDate={new Date()}
            onChange={date => setToDate(date)} />
        </Col>
        <Col sm="3">
          <input checked={testRunStatus == "true" ? true : false} onChange={() => setTestRunStatus(testRunStatus == "true" ? "false" : "true")} type={'checkbox'} name='test_run_status' /> Test Run Status
        </Col>
        <Col sm="3">
          <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
        </Col>
      </Row>
    </CardBody>
  </Card>
  {
    UrlParams.siteName ? 
    <>
      {
        loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
        :
        <>
          <Card>
            <CardBody>
              {
                crawlExtractionStatsData.length > 0 ? 
                <>
                  <div className="mb-1">
                    <b>NOTE: </b> <br />
                    <span> 1) <Info size={18} className='mx-2'/> indicates the hover is there. It has some informations or links.</span><br />
                    <span> 2) For "social_standards_inc" only latest 1000 segments are considered.</span><br />
                  </div>
                  <Row className="align-items-end mt-2">
                    <Col>
                      <div className="float-end mb-3">
                        <Row className="align-items-center">
                          <Col sm="3">Search</Col>
                          <Col>
                            <input type="text" className="form-control" value={searchText} onChange={handleSearch} />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <DataTable
                      columns={tableColumns}
                      data={searchResults}
                      noHeader
                      pagination
                      progressPending={loading}
                    />
                  </Row>
                </> : "No data available"
              }
            </CardBody>
          </Card>
        </>
      }
    </>
    : ""
  }
</Container>
}

