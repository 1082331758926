import React, {useState, useEffect} from 'react'
import { useParams } from "react-router-dom";
import Breadcrumb from '../../global/Breadcrumb';
import { Card, CardBody, CardHeader, Container, Row, Col, Button, Modal, ModalBody, ModalFooter,
  ModalHeader, CardFooter } from 'reactstrap'
import axios from 'axios'
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import PasswordRow from './PasswordRow';

export default function ProfilePage() {
  let { profile_id } = useParams();

  const [errors, setErrors] = useState({});
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [modal, setModal] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [modal3, setModal3] = useState(false)
  const [loadingBtns, setLoadingBtns] = useState(false)
  const [toggleData, setToggleData] = useState(false)
  const [passwordObj, setPasswordObj] = useState({
    currentPassword: '', newPassword1: '', newPassword2: '',
  })
  const [nameObj, setNameObj] = useState({firstName: "", lastName: "",  phone: ''})
  const [orgList, setOrgList] = useState([])
  const [selectedOrg, setSelectedOrg] = useState('')

  const closeBtn = <button className="close" 
    onClick={() => setModal(false)}> &times; </button>;

  const toggleFunc = () => {
    setModal(curr => !curr)
  }

  const closeBtn2 = <button className="close" 
    onClick={() => setModal2(false)}> &times; </button>;

  const toggleFunc2 = () => {
    setModal2(curr => !curr)
  }

  const closeBtn3 = <button className="close" 
    onClick={() => setModal3(false)}> &times; </button>;

  const toggleFunc3 = () => {
    setModal3(curr => !curr)
  }

  useEffect(() => {
    axios.get(`/api/v1/profiles/${profile_id}`).then(res => {
      setData(res.data.profile)
      setNameObj({firstName: res.data.profile.first_name || "", lastName: res.data.profile.last_name || "", phone: res.data.profile.phone_number || ""})
      setLoading(false)
    }).catch(err => {
      toast.error(err.response.data.message)
      setLoading(false)
    })
  }, [toggleData])

  useEffect(() => {
    axios.get(`/api/v1/default_org?id=${profile_id}`).then(res => {
      setOrgList(res.data.org_list)
    })
  }, [toggleData])

  const updatePassword = () => {
    setErrors({})
    let errorsObj = {}

    if (passwordObj.currentPassword.length == 0){
      errorsObj = {...errorsObj, currentPassword: 'This field is required!'}
    }
    if (passwordObj.newPassword1.length == 0){
      errorsObj = {...errorsObj, newPassword1: 'This field is required!'}
    }
    if (passwordObj.newPassword1 && passwordObj.newPassword1.length < 8) {
      errorsObj = {...errorsObj, newPassword1: 'Password is too short (minimum is 8 characters)'}
    }
    if (passwordObj.newPassword2.length == 0){
      errorsObj = {...errorsObj, confirmNewPassword: 'This field is required!'}
    }
    if (passwordObj.newPassword1 !== passwordObj.newPassword2){
      errorsObj = {...errorsObj, confirmNewPassword: 'New password and Confirm new password fields are mismatched!'}
    }
    setErrors(errorsObj)

    if (Object.keys(errorsObj).length === 0){
      setLoadingBtns(true)
      axios.put(`/api/v1/custom_password/${profile_id}`, {
        user: {
          current_password: passwordObj.currentPassword, 
          password: passwordObj.newPassword1,
          password_confirmation: passwordObj.newPassword2
        }
      }).then(res => {
        toast.success(res.data.message)
        setModal(false)
        setLoadingBtns(false)
        setPasswordObj({
          currentPassword: '', newPassword1: '', newPassword2: ''
        })
      }).catch(err => {
        toast.error(err.response.data.message)
        setLoadingBtns(false)
      })
    }
  }

  const updateProfile = () => {
    if (nameObj?.firstName?.trim()?.length < 1 || nameObj?.lastName?.trim()?.length < 1 ||
      nameObj?.phone?.trim()?.length < 9){
      toast.error("Please enter valid details in all fields")
    }
    else if (nameObj?.phone?.trim()?.length > 10){
      toast.error("please enter a valid phone number")
    }
    else{
      setLoadingBtns(true)
      axios.put(`/api/v1/profiles/${profile_id}`, {
        profile: {
          first_name: nameObj.firstName, last_name: nameObj.lastName, phone_number: nameObj.phone
        }
      }).then(res => {
        toast.success(res.data.message)
        setModal2(false)
        setLoadingBtns(false)
        setToggleData(curr => !curr)
      }).catch(err => {
        toast.error(err.response.data.message)
        setLoadingBtns(false)
      })
    }
  }

  const updateOrganization = () => {
    if (selectedOrg === ""){
      toast.error("Please select organization")
    }
    else{
      setLoadingBtns(true)
      axios.put(`/api/v1/default_org/${profile_id}`, {
        user: {
          default_organization_id: selectedOrg
        }
      }).then(res => {
        toast.success(res.data.message)
        setModal3(false)
        setLoadingBtns(false)
        setToggleData(curr => !curr)
      }).catch(err => {
        toast.error(err.response.data.message)
        setLoadingBtns(false)
      })
    }
  }

  return (
    <Container fluid>
      <Breadcrumb parent="Profile Details" title="User Profile" />

      {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
          : 
          <>
            <Modal isOpen={modal} toggle={toggleFunc} backdrop='static' size='lg'>
              <ModalHeader toggle={toggleFunc} close={closeBtn}>
                Change Password: {localStorage.getItem("authEmail")}
              </ModalHeader>
              <ModalBody className='px-5'>
                <Row className='my-3'>
                  <Col lg={6}>
                    <h6 className='fw-bold my-0 text-end'>Current Password </h6>
                  </Col>
                  <Col lg={6}>
                    <PasswordRow id="currentPassword" value={passwordObj.currentPassword} 
                      onChange={(e) => setPasswordObj({...passwordObj, currentPassword: e.target.value})} />
                    {errors?.currentPassword && <p className='text-danger'> {errors?.currentPassword} </p>}
                  </Col>
                </Row>
                <Row className='my-3'>
                  <Col lg={6}>
                    <h6 className='fw-bold my-0 text-end'>New Password </h6>
                  </Col>
                  <Col lg={6}>
                    <PasswordRow id="newPassword" value={passwordObj.newPassword1} 
                      onChange={(e) => setPasswordObj({...passwordObj, newPassword1: e.target.value})} />
                    {errors?.newPassword1 && <p className='text-danger'> {errors?.newPassword1} </p>}
                  </Col>
                </Row>
                <Row className='my-3'>
                  <Col lg={6}>
                    <h6 className='fw-bold my-0 text-end'>Confirm New Password: </h6>
                  </Col>
                  <Col lg={6}>
                    <PasswordRow id="confirmPassword" value={passwordObj.newPassword2}
                      onChange={(e) => setPasswordObj({...passwordObj, newPassword2: e.target.value})} />
                    {errors?.confirmNewPassword && <p className='text-danger'> {errors?.confirmNewPassword} </p>}
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color='light' onClick={() => setModal(false)}> Close </Button>
                <Button color="success" onClick={updatePassword}
                  disabled={loadingBtns}>Update Password</Button>
              </ModalFooter>
            </Modal>
            <Modal isOpen={modal2} toggle={toggleFunc2} backdrop='static' size='lg'>
              <ModalHeader toggle={toggleFunc2} close={closeBtn2}>
                Edit Profile: {localStorage.getItem("authEmail")}
              </ModalHeader>
              <ModalBody className='px-5'>
                <Row className='my-3'>
                  <Col lg={6}>
                    <h6 className='fw-bold my-0 text-end'>First Name: </h6>
                  </Col>
                  <Col lg={6}>
                    <input placeholder='First Name' type="text" className='form-control' value={nameObj.firstName}
                      onChange={(e) => setNameObj({...nameObj, firstName: e.target.value})} />
                  </Col>
                </Row>
                <Row className='my-3'>
                  <Col lg={6}>
                    <h6 className='fw-bold my-0 text-end'>Last Name: </h6>
                  </Col>
                  <Col lg={6}>
                    <input placeholder='Last Name' type="text" className='form-control' value={nameObj.lastName}
                      onChange={(e) => setNameObj({...nameObj, lastName: e.target.value})} />
                  </Col>
                </Row>
                <Row className='my-3'>
                  <Col lg={6}>
                    <h6 className='fw-bold my-0 text-end'>Phone Number: </h6>
                  </Col>
                  <Col lg={6}>
                    <input type="text" className='form-control' placeholder='phone number'
                      value={nameObj.phone} onChange={(e) => {
                        if (e.target.value / 1){
                          setNameObj({...nameObj, phone: e.target.value})
                        }
                      }} />
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button color='light' onClick={() => setModal2(false)}> Close </Button>
                  <Button color="success" onClick={updateProfile}
                    disabled={loadingBtns}>Update Profile</Button>
                </ModalFooter>
              </Modal>
              <Modal isOpen={modal3} toggle={toggleFunc3} backdrop='static' size='lg'>
                <ModalHeader toggle={toggleFunc3} close={closeBtn2}>
                  Change current organization: {data.organization_name}
                </ModalHeader>
                <ModalBody className='px-5'>
                  <Row className='my-3 d-flex align-items-center'>
                    <Col lg={6}>
                      <h6 className='fw-bold my-0 text-end'>Select Current Organization: </h6>
                    </Col>
                    <Col lg={6}>
                      <select className='form-control show-arrow organizationChangeSelector' value={selectedOrg}
                        onChange={(e) => setSelectedOrg(e.target.value)}>
                        <option value="">Select organization</option>
                        {orgList.map(each => (
                          <option value={each.id} key={each.id}>
                            {each.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button color='light' onClick={() => setModal3(false)}> Close </Button>
                  <Button color="success" onClick={updateOrganization}
                    disabled={loadingBtns}>Change Current Organization</Button>
                </ModalFooter>
              </Modal>
              <Card className='mb-4'>
                <CardBody>
                  <div className='d-flex justify-content-between mb-4'>
                    <h5 className='fw-lighter'>
                      {localStorage.getItem("authEmail")}
                    </h5>
                    <div>
                      <button className='btn btn-primary mx-2' onClick={toggleFunc}>Change Password</button>
                      <button className='btn btn-primary mx-2' onClick={toggleFunc2}>Edit Profile</button>
                    </div>
                  </div>
                  <div className='px-5 py-2'>
                    <h6>
                      <b>Name: </b> {data?.name}
                    </h6>
                    <h6>
                      <b>Phone number: </b> {data?.phone_number || ""}
                    </h6>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <div className='d-flex justify-content-between mb-4'>
                    <h5 className='fw-lighter'>
                      Current organization
                    </h5>
                    <div>
                      <button className='btn btn-primary' onClick={toggleFunc3}>Change Current Organization</button>
                    </div>
                  </div>
                  <div className='p-5'>
                    <h6>
                      <b>Organization Name: </b> {data?.organization_name}
                    </h6>
                    <h6>
                      <b>Country: </b> {data?.country}
                    </h6>
                  </div>
                </CardBody>
              </Card>
            </>
      }
    </Container>
  )
}
