import React from 'react';
import { useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import DatePicker from 'react-datepicker';
import AsyncSelect from 'react-select/async';
import SpinnerButton from '../../../../../V2/Common/SpinnerButton/SpinnerButton.component';

export default function SiteSelectorBackup({ sitesData, dataFetcher, loading, siteNamesDropdownOptions }) {
    const[startDate, setStartDate] = useState(new Date());
    const[endDate, setEndDate] = useState(new Date());
    const[siteName, setSitename] = useState('');
    
    function onButtonClickHandler(fromDate=startDate, toDate=endDate, site=siteName['value']){
        dataFetcher(fromDate, toDate, site);
    }

    // Helper function to filter the values and return the object
    // based on user input
    const filterValues = (inputValue) => {
        return siteNamesDropdownOptions.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };
  
    // AsyncMethod to traverse the array and return resut that matches 
    // the input query entered by the user 
    const loadOptions = (inputValue, callback) => {
        setTimeout(() => {
        callback(filterValues(inputValue));
        }, 650);
    };

    return (
        <>
            <Row className='mb-2'>
                <Col lg={3}>
                    <h6 className='fw-bold'>Sitename</h6>
                    {/* <select className='form-control show-arrow' defaultValue={siteName} required
                        onChange={(e) => setSitename(e.target.value)}>
                        <option value=''>Choose a sitename</option>
                        {sitesData.map((each, index) => (
                            <option key={index} value={each}> {each} </option>
                        ))}
                    </select> */}
                     <AsyncSelect
                        className=''
                        name='site_name'
                        value={siteName}
                        placeholder="Select a Site Name"
                        cacheOptions
                        defaultOptions={siteNamesDropdownOptions}
                        onChange={res => setSitename(res)}
                        loadOptions={loadOptions}
                    />
                </Col>
                <Col lg={3}>
                    <h6 className='fw-bold'>From Date</h6>
                    <DatePicker className="form-control" selected={startDate} maxDate={new Date()}
                        onChange={(date) => setStartDate(date)} />
                </Col>
                <Col lg={3}>
                    <h6 className='fw-bold'>To Date</h6>
                    <DatePicker 
                        className="form-control" 
                        selected={endDate}
                        minDate={startDate} 
                        maxDate={new Date()}
                        onChange={(date) => setEndDate(date)} />
                </Col>
                <Col lg={3}>
                    <SpinnerButton loading={loading} color='success' className='py-2 mt-4' onClick={()=>onButtonClickHandler()} disabled={false}>
                        Get Files
                    </SpinnerButton>
                </Col>
            </Row>
        </>
    )
}
