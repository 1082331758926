import React, { useState } from "react";



import { Row, Card, CardBody, Container } from "reactstrap";
import { Info } from "react-feather";
import moment from "moment";
import axios from "axios";


import SegmentLevelRssStatsDataTable from "./DataTables/SegmentLevelRssStatsDataTable.component";
import SpinnerLoader from '../../../V2/Common/LoadingAnimation/SpinnerLoader.component'
import BreadCrumbComponent from "./Breadcrumb/BreadcrumbComponent";
import SiteSearchCard from "./Cards/SiteSearchCard.Component";



export default function SegmentLevelRssStats() {

  const [loading, setLoading] = useState(false);
  const [rssStatsData, setRssStatsData] = useState([]);
  const [displayTable, setDisplayTable] = useState(false);

  // Helper method to fetch table data based on input parameters
  function fetchData(filterParams) {
    setLoading(true)
    setDisplayTable(true)
    axios.get(`/api/v2/internal/segment_level_rss_stats?filter[site_name]=${filterParams.siteName}&filter[geo_cluster]=${filterParams.recrawlGeoCluster}&filter[rss_job_type]=${filterParams.rssJobType}&filter[from_date]=${filterParams.fromDate}&filter[to_date]=${filterParams.toDate}&filter[test_run_status]=${filterParams.testRunStatus}`)
      .then(res => {
        setRssStatsData(res?.data?.rss_stats_data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  return (
    <Container fluid>
      <BreadCrumbComponent />
      <SiteSearchCard
        fetchData={fetchData}
      />
      {displayTable ?
        <SpinnerLoader loading={loading}>
          <Card>
            <CardBody>
              {
                rssStatsData?.length > 0 ?
                  <>
                    <div className="mb-1">
                      <b>NOTE:</b>
                      <Info size={18} className='mx-2' /> indicates the hover is there. It has some informations or links.
                    </div>
                    <Row className="align-items-end mt-2">
                      <SegmentLevelRssStatsDataTable
                        data={rssStatsData}
                        progressPending={loading}
                      />
                    </Row>
                  </>
                  :
                  <center>
                    <h5>There are no records to display</h5>
                  </center>
              }
            </CardBody>
          </Card>
        </SpinnerLoader>
        : ""}
    </Container>)
}
