import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import ScheduleCrawls from '../Buttons/ScheduleCrawls.component';
import SetBillingAlarms from '../Buttons/SetBillingAlarms.component';
import SetCrawlNotifications from '../Buttons/SetCrawlNotifications.component';
import SitesInformationTable from '../DataTables/SitesInformationTable.component';

export default function SitesInfoCard() {
 
  return (
    <div>
      <Card className='my-2'>
        <CardHeader className='m-0 p-4 d-flex align-center'>
          Bulk Actions:
          <ScheduleCrawls />
          <SetCrawlNotifications />
          <SetBillingAlarms />
        </CardHeader>
        <CardBody>
          <SitesInformationTable />
        </CardBody>
      </Card>
    </div>
  )
}
