import axios from "axios";
import { toast } from "react-toastify";

// Helper function to get data for New Organization Table
export async function getOrganizationTableData(url) {
  const response = await axios.get(url);
  const organizations = await response?.data?.feasibility_checks_list?.new_organizations;
  const tableValues = [];
  await organizations.forEach(data => {
    let dataObj = {
      country_name: data?.country_name,
      created_at: data?.created_at,
      organization_name: data?.organization_name,
      organization_id: data?.organization_id,
      user_email: data?.user_email,
      biz_assignee: data?.biz_assignee,
      feas_check_id: data?.feasibility_check_id,
    }
    tableValues.push(dataObj);
  });
  return tableValues;
};

// Helper function to get data from New Sites or Site Group Table
export async function getSitesTableData(url) {
  const response = await axios.get(url);
  const sites = await response?.data?.feasibility_checks_list?.new_sitegroups_or_sites;
  const tableValues = [];
  await sites.forEach(data => {
    let dataObj = {
      country_name: data?.country_name,
      created_at: data?.created_at,
      organization_name: data?.organization_name,
      organization_id: data?.organization_id,
      user_email: data?.user_email,
      sitegroup_name: data?.sitegroup_name,
      sitegroup_status: data?.sitegroup_status,
      user_email: data?.user_email,
      biz_assignee: data?.biz_assignee,
      feas_check_id: data?.feasibility_check_id,
    }
    tableValues.push(dataObj);
  });
  return tableValues;
};

// Helper function to assign a business assignee in both tables
export async function onBusinessAssigneeChangeHandler(inputVlaue, orgID) {
  try {
    const response = await axios.put('/api/v1/admins/feasibility_checks/update_biz_contact',
      {
        organization_id: orgID,
        biz_contact_email: inputVlaue
      });
    toast.success(response?.data?.message);
  }
  catch (error) {
    toast.error(error?.response?.data?.message);
  }
};