import React from 'react';
import { useState } from 'react';
import { OVERALL_DEADUP_URL } from './../KibanaEndpoints/KibanaEndpoints';
import { API_PREFIX, SEARCH_QUERY } from '../DedupTracker.index';
import RssQInfoTable from '../DataTables/RssQInfoTable.component';
import ESBUlkUpdatesTable from '../DataTables/ESBulkUpdatesTable.component';
import RssCountsTable from '../DataTables/RssCountsTable.component';
import { Row, Col } from 'reactstrap';
import DatePicker from "react-datepicker";
import moment from "moment";

export default function OverallDeadupStatusTab({ kibanaUrl, rssQueueInfo, queueSizeInfo, siteRssCount, loading }) {
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(7, 'days')));
  const [toDate, setToDate] = useState(new Date());

  // Get data button click handler
  function onButtonClickHandler() {
    const startDate = moment(fromDate).format('YYYY/MM/DD');
    const endDate = moment(toDate).format('YYYY/MM/DD');
    const path = API_PREFIX + SEARCH_QUERY.replace('$[fromDate]', startDate).replace('$[toDate]', endDate);
    window.location.href = path;
  };
  return (
    <>
      <div className='ibox-content m-b-sm border-bottom'>
        <Row className='align-items-end'>
          <Col sm="3">
            <label className="fw-bold">From Date</label>
            <DatePicker
              className="form-control"
              selected={new Date(fromDate)}
              dateFormat={"YYY/MM/dd"}
              onChange={date => setFromDate(date)} />
          </Col>
          <Col sm="3">
            <label className="fw-bold">To Date</label>
            <DatePicker
              className="form-control"
              selected={new Date(toDate)}
              dateFormat={"YYY/MM/dd"}
              onChange={date => setToDate(date)} />
          </Col>
          <Col sm="3">
            <button className="btn btn-success w-100" onClick={() => onButtonClickHandler()}>Get Data</button>
          </Col>
        </Row>
      </div>
      <div className='panel-body'>
        <center>
          <span className="fw-bold">Kibana Dashboard</span> - <a target="_blank" className="link-primary" href={kibanaUrl}>Click here to see below graph in kibana</a>
        </center>
        <iframe src={kibanaUrl + "&embed=true"} height="500" width="100%" className="border-0"></iframe>
        <Row className="align-items-end mb-3">
          <center><span className="fw-bold">Rss Q info</span></center><br />
          <RssQInfoTable data={rssQueueInfo} loading={loading} />
        </Row>
        <Row className="align-items-end mb-3">
          <center><span className="fw-bold">ES bulk updates pending</span></center><br />
          <ESBUlkUpdatesTable data={queueSizeInfo} loading={loading} />
        </Row>
        <Row className="align-items-end">
          <center><span className="fw-bold">RSS Counts</span></center><br />
          <RssCountsTable data={siteRssCount} loading={loading} />
        </Row>
      </div>
    </>
  )
}
