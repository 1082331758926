/******************************************************************************************************************
 *                              This is the filter options card displayed on top of Data Table                    *
 *                                   This component is rendered at AllInvoices.index.jsx                          *
 ******************************************************************************************************************
 * CREATED BY: Aniket Atram                                                                                       *
 * CREATED ON: 3 April 2023                                                                                       * 
 * UPDATED ON: 6 April 2023                                                                                       *
 * ****************************************************************************************************************
 * ***************************************************** PROPS ***************************************************************
 * |-------     Name     ------|-------------------------------|     Description   |-----------------------------------------|
 * |inputVlaues                | The values stored in the state                                                              |
 * |onChangeHandler            | Method to store user input values in the state                                              |
 * |onResetFilterClickHandler  | A method to reset the applied filters on teh API                                            |
 * |---------------------------|---------------------------------------------------------------------------------------------|
 ****************************************************************************************************************************/
import React from 'react'

import { Row, Col, Card, CardBody, Label, Input, Button } from 'reactstrap'


export default function Options(props) {

  const { name, identifier, invoiceID, fbQbInvoiceId, invoiceNumber, serviceType, productType, invoiceStatus } = props.inputVlaues;
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <Label className='h6'><b>Name</b></Label>
              <Input
                name='name'
                value={name}
                onChange={(event) => { props.onChangeHandler(event.target.name, event.target.value) }} />
            </Col>
            <Col>
              <Label className='h6'><b>Identifier</b></Label>
              <Input
                name="identifier"
                value={identifier}
                onChange={(event) => { props.onChangeHandler(event.target.name, event.target.value) }} />
            </Col>
            <Col>
              <Label className='h6'><b>Invoice ID</b></Label>
              <Input
                name="invoiceId"
                value={invoiceID}
                onChange={(event) => { props.onChangeHandler(event.target.name, event.target.value) }} />
            </Col>
            <Col>
              <Label className='h6'><b>FB or QB Invoice ID</b></Label>
              <Input
                name="fbqb"
                value={fbQbInvoiceId}
                onChange={(event) => { props.onChangeHandler(event.target.name, event.target.value) }} />
            </Col>
          </Row>
          <Row className='pt-3'>
            <Col>
              <Label className='h6'><b>Invoice Number</b></Label>
              <Input
                name="invoiceNumber"
                value={invoiceNumber}
                onChange={(event) => { props.onChangeHandler(event.target.name, event.target.value) }} />
            </Col>
            <Col>
              <Label className='h6'><b>Service Type</b></Label>
              <select className="form-select"
                value={serviceType}
                name="serviceType"
                onChange={(event) => { props.onChangeHandler(event.target.name, event.target.value) }}>
                <option disabled hidden value={""}>Select Service Type</option>
                <option value={"freshbooks"}>Freshbooks</option>
                <option value={"quickbooks"}>Quickbooks</option>
                <option value={"zohobooks"}>Zohobooks</option>
                <option value={"no_service"}>No Service</option>
              </select>
            </Col>
            <Col>
              <Label className='h6'><b>Product Type</b></Label>
              <select className="form-select"
                value={productType}
                name="productType"
                onChange={(event) => { props.onChangeHandler(event.target.name, event.target.value) }}>
                <option disabled hidden value={""}>Select Product Type</option>
                <option value={"FeasibilityReport"}>Feasibility Reports</option>
                <option value={"Monthly"}>Monthly</option>
              </select>
            </Col>
            <Col>
              <Label className='h6'><b>Invoice Status</b></Label>
              <select className="form-select"
                value={invoiceStatus}
                name="invoiceStatus"
                onChange={(event) => { props.onChangeHandler(event.target.name, event.target.value) }}>
                <option disabled hidden value={""}>Select Invoice Status</option>
                <option value={"paid"}>Paid</option>
                <option value={"pending"}>Pending</option>
              </select>
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col></Col>
            <Col>
              <Button className='w-100 mt-4 fw-bold'
                color='primary'
                onClick={props.onSearchButtonClickHandler}
              >Search</Button>
            </Col>
            <Col>
              <Button className='w-100 mt-4 fw-bold'
                color='danger'
                onClick={props.onResetFilterClickHandler}
              >Reset Filters</Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
