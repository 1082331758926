import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";

import { KIBANA_JP_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function RailsResqueLogs() {

  function KibanaUrl(){
    return `${KIBANA_JP_URL}app/kibana#/dashboard/Rails-Resque-Logs-Dashboard?_g=(refreshInterval:(pause:!t,value:0),time:(from:now-7d,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(darkTheme:!f),panels:!((embeddableConfig:(vis:(legendOpen:!f)),gridData:(h:12,i:'2',w:32,x:16,y:0),id:Rails-Resque-Failure-Bar-Visual,panelIndex:'2',type:visualization,version:'7.5.1'),(embeddableConfig:(columns:!(host,job_name,error),sort:!('@timestamp',desc)),gridData:(h:12,i:'3',w:32,x:16,y:12),id:Rails-Resque-Jobs-Failure-Logs,panelIndex:'3',type:search,version:'7.5.1'),(embeddableConfig:(vis:(params:(sort:(columnIndex:!n,direction:!n)))),gridData:(h:16,i:'4',w:16,x:0,y:8),id:Resque-Rails-Failure-Log-Count-Viz,panelIndex:'4',type:visualization,version:'7.5.1'),(embeddableConfig:(),gridData:(h:8,i:'5',w:8,x:0,y:0),id:Rails-Resque-Jobs-Exception-Logs-Count,panelIndex:'5',type:visualization,version:'7.5.1'),(embeddableConfig:(),gridData:(h:8,i:'6',w:8,x:8,y:0),id:Rails-resque-jobs-failure-jobs-count,panelIndex:'6',type:visualization,version:'7.5.1')),query:(language:lucene,query:'*'),timeRestore:!t,title:'Rails+Resque+Logs+Dashboard',viewMode:view)`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>Rails Resque Logs</BreadcrumbItem>
        </Breadcrumb>
      </Col>
    </Row>
  </div>

  <Card>
    <CardBody>
      <center>
        <a target="_blank" className="link-primary" href={KibanaUrl()}>Click here to see below graph in kibana</a>
      </center>
      <iframe src={KibanaUrl() + "&embed=true"} height="500" width="100%" className="border-0"></iframe>
    </CardBody>
  </Card>
</Container>
}

