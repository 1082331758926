import React, { Fragment, useState, useEffect } from 'react';
import { Code, Globe, FileText, PieChart, LifeBuoy, BarChart, ArrowRight, ArrowLeft, Grid, BarChart2, Calendar } from 'react-feather'
import { Link } from 'react-router-dom'
import { translate } from 'react-switch-lang';
import configDB from '../../sidebar/config';
import axios from 'axios';
import './../../../stylesheets/custom.scss';

const Sidebar = (props) => {

  // eslint-disable-next-line
  const [mainmenu, setMainMenu] = useState([]);
  const [margin, setMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const [sidebartoogle, setSidebartoogle] = useState(true)
  const wrapper = configDB.data.settings.sidebar.type;

  const token = localStorage.getItem("authToken");
  const email = localStorage.getItem("authEmail");
  const ror_users = ["karthick@promptcloud.com", "adityap@promptcloud.com", "atramaniket@promptcloud.com"];

  useEffect(() => {
    axios.defaults.headers["X-Auth-Email"] = email;
    axios.defaults.headers["X-Auth-Token"] = token;
    axios.defaults.headers["Authorization"] = `Bearer ${token}`;
    getSidebarReady()
  }, [])

  async function getData(url) {
    const res = await axios.get(url)
    return await res?.data
  }

  async function getSidebarReady() {
    setMainMenu([
      {
        menutitle: "Item1",
        menucontent: "Item1.1",
        Items: [
          {
            title: 'Overall & Test Site Stats',
            icon: BarChart2, type: 'sub',
            active: false,
            children: [
              { path: `/internal/upload/new`, title: 'Overall Stage Wise Stats', type: 'link' },
              { path: `/internal/test_site_stats`, title: 'Test Site Stats', type: 'link' },
            ]
          }
        ]
      },

      {
        menutitle: "Item2",
        menucontent: "Item2.1",
        Items: [
          { path: `/internal/end_to_end_crawl_tracker`, icon: Globe, title: 'End to End Crawl Tracker', type: 'link' },
        ]
      },

      {
        menutitle: "Item3",
        menucontent: "Item3.1",
        Items: [
          {
            title: 'Currently Running Processes',
            icon: BarChart, type: 'sub',
            active: false,
            children: [
              { path: `/internal/df/new`, title: 'Diskfetcher Processes', type: 'link' },
              { path: `/internal/rss_process/new`, title: 'Rss Processes', type: 'link' },
              { path: `/internal/ednu_processes`, title: 'Current EDNU Stage of Segments', type: 'link' },
            ]
          }
        ]
      },

      {
        menutitle: "Item4",
        menucontent: "Item4.1",
        Items: [
          {
            title: 'Segment Level Stats',
            icon: Code, type: 'sub',
            active: false,
            children: [
              { path: `/internal/rss/new`, title: 'Segment Level RSS Stats', type: 'link' },
              { path: `/internal/segment/new`, title: 'Segment Level Crawl Extraction Stats', type: 'link' },
            ]
          }
        ]
      },

      {
        menutitle: "Item5",
        menucontent: "Item5.1",
        Items: [
          {
            title: 'Command Dashboard & QA page',
            icon: LifeBuoy, type: 'sub',
            active: false,
            children: [
              { path: `/internal/command_dashboard`, title: 'Command Dashboard', type: 'link' },
              { path: `/internal/qa/pending`, title: 'Quality Analysis', type: 'link' },
              { path: `/internal/qa_report/pending`, title: 'QA reports', type: 'link' },
              { path: `/internal/new_qa_reports/pending`, title: 'QA Reports(new)', type: 'link' },
            ]
          }
        ]
      },

      {
        menutitle: "Item6",
        menucontent: "Item6.1",
        Items: [
          {
            title: 'Site & System Reports',
            icon: FileText, type: 'sub',
            active: false,
            children: [
              { path: `/internal/amazon_request_type_performance_tracker`, title: 'Amazon Request Type Performance Tracker', type: 'link' },
              { path: `/internal/api_information`, title: 'API Information', type: 'link' },
              {
                title: 'AWS and GCE Machine Info', icon: Code, type: 'sub', active: false, children: [
                  { path: `/internal/aws_and_gce_machine_info/currently_running_machines`, title: 'AWS and GCE Currently Running Machines', type: 'link' },
                  { path: `/internal/aws_and_gce_machine_info/cloud_machines_count_estimation_new`, title: 'Cloud Machines Count Estimation', type: 'link' },
                ]
              },
              { path: `/internal/blocking_solution_config_files`, title: 'Blocking Solution Config Files', type: 'link' },

              {
                title: 'Blocking URL Stats', icon: Code, type: 'sub', active: false, children: [
                  { path: `/internal/blocking_url_stats/diskfetcher_stats`, title: 'Diskfetcher Stats', type: 'link' },
                  { path: `/internal/blocking_url_stats/rss_stats`, title: 'Rss Stats', type: 'link' },
                ]
              },
              { path: `/internal/chat_gpt_xpath_results`, title: 'ChatGPT Xpath Results', type: 'link' },
              { path: `/internal/clusterwise_worker_and_jobs_info`, title: 'Clusterwise Worker and Jobs Info', type: 'link' },
              {
                title: 'DevOps', icon: Code, type: 'sub', active: false, children: [
                  { path: '/internal/devops/host_wise_load_stats', title: 'Host wise load stats', type: 'link' },
                  { path: '/internal/devops/sdf_config_services_and_server_mappings', title: 'SDF Config Services and Server Mappings', type: 'link' },

                ]
              },
              {
                title: 'Diskfetcher Segment Stats', type: 'sub', active: false, children: [
                  { path: '/internal/diskfetcher_segment_stats/sitewise_failed_and_succeeded_url_stats', title: 'Sitewise Failed and Succeeded Url Stats', type: 'link' },
                  { path: '/internal/diskfetcher_segment_stats/failed_segments_count', title: 'Failed Segments Count', type: 'link' },
                  { path: '/internal/diskfetcher_segment_stats/overall_failed_urls_count', title: 'Overall Failed URLs Count', type: 'link' },


                ]
              },
              { path: '/internal/pipeline_stage/end_to_end_url_tracker', title: 'End to End Url Tracker', type: 'link' },

              {
                title: 'ES Cluster Health', type: 'sub', active: false, children: [
                  { path: '#', title: 'Old Es (Needs to be added)', type: 'external_link' },

                  { path: '/internal/es_cluster_health/es_jp', title: 'ES JP', type: 'link' },
                  { path: '/internal/es_cluster_health/es_be', title: 'ES BE', type: 'link' },
                  { path: '/internal/es_cluster_health/es_plm', title: 'ES PLM', type: 'link' },
                  { path: '#', title: 'ElasticHQ (Needs to be added)', type: 'external_link' },
                  { path: '/internal/es_cluster_health/es_42signals', title: 'ES 42Signals', type: 'link' },

                ]
              },
              { path: '/internal/elastic_alerts', title: 'Elastic Alerts', type: 'link' },
              { path: '/internal/es_stores/es_store_connections_tracker', title: 'ES store connections tracker', type: 'link' },
              {
                title: 'Extended Upload Stats', icon: Code, type: 'sub', active: false, children: [
                  { path: '/internal/extended_upload_failed_stats/dependent_data_upload_failed', title: 'Dependent Data Upload Failed', type: 'link' },
                  { path: '/internal/extended_upload_failed_stats/multi_target_upload_failed', title: 'Multi Target Upload Failed', type: 'link' },
                  { path: '/internal/extended_upload_failed_stats/merge_files_failed', title: 'Merge Files Failed', type: 'link' },

                  { path: '/internal/extended_upload_success_stats/dependent_data_upload_success', title: 'Dependent Data Upload Success', type: 'link' },
                  { path: '/internal/extended_upload_success_stats/multi_target_upload_success', title: 'Multi Target Upload Success', type: 'link' },
                  { path: '/internal/extended_upload_success_stats/merge_files_success', title: 'Merge Files Success', type: 'link' },

                ]
              },
              {
                title: 'Extended Upload Process Status', icon: Code, type: 'sub', active: false, children: [
                  { path: '/internal/extended_upload_process_status/merge_files_and_upload', title: 'Merge Files and Upload', type: 'link' },
                  { path: '/internal/extended_upload_process_status/multi_target_upload', title: 'Multi Target Upload', type: 'link' },
                  { path: '/internal/extended_upload_process_status/dependent_data_upload', title: 'Dependent Data Upload', type: 'link' },
                ]
              },
              { path: '/internal/failed_urls', title: 'Failed URLs', type: 'link' },
              {
                title: 'God Reports', icon: Code, type: 'sub', active: false, children: [
                  { path: '/internal/god_report/fetch_god_reports_by_machine', title: 'God Reports By Machine', type: 'link' },
                  { path: '/internal/god_report/fetch_god_reports_by_job_type', title: 'God Reports by Job Type', type: 'link' },
                ]
              },
              { path: '/internal/high_volume_clients_and_sites', title: 'High volume clients and sites', type: 'link' },
              { path: '/internal/volume_fluctuations', title: 'Volume Fluctuations', type: 'link' },

              { path: '/internal/pipeline_stage/high_record_drop_in_extraction_or_dedup', title: 'High record drop in Extraction or Dedup', type: 'link' },

              {
                title: 'Log Stats', icon: Code, type: 'sub', active: false, children: [
                  { path: '/internal/log_stats/logstash_stats', title: 'Logstash Stats', type: 'link' },
                  { path: '/internal/log_stats/sdf_metrics_stats', title: 'Sdf Metrics Stats', type: 'link' },
                  { path: '/internal/log_stats/indexing_errors', title: 'Indexing Errors', type: 'link' },
                ]
              },

              { path: '/internal/missing_fields', title: 'Missing Fields', type: 'link' },
              { path: '/internal/manually_push_to_queue_tracker', title: 'Manually Push To Queue Tracker', type: 'link' },

              {
                title: 'Overall System Reports', icon: Code, type: 'sub', active: false, children: [
                  {
                    title: 'Overall Records Count', type: 'sub', active: false, children: [
                      { path: '/internal/overall_system_reports/overall_records_count_by_system_wide', title: 'System Wide', type: 'link' },
                      { path: '/internal/overall_system_reports/overall_records_count_by_site_wise', title: 'Site Wise', type: 'link' },
                      { path: '/internal/overall_system_reports/overall_records_count_by_host_wise', title: 'Host Wise', type: 'link' },

                    ]
                  },
                  {
                    title: 'Overall Segments Report', type: 'sub', active: false, children: [
                      { path: '/internal/overall_system_reports/segments_created_by_across_system', title: 'Segments Created By Across System', type: 'link' },
                      { path: '/internal/overall_system_reports/overall_successful_segments', title: 'Overall Successful Segments', type: 'link' },
                      { path: '/internal/overall_system_reports/overall_failed_segments', title: 'Overall Failed Segments', type: 'link' },
                      { path: '/internal/overall_system_reports/overall_pending_segments', title: 'Overall Pending Segments', type: 'link' },
                      { path: '/internal/overall_system_reports/host_wise_avg_time_to_pick_segment', title: 'Host Wise Average Time To Pick A Segment', type: 'link' },

                    ]
                  },
                  {
                    title: 'Average Time Stats', type: 'sub', active: false, children: [
                      { path: '/internal/overall_system_reports/avg_time_stats_table_per_pipeline_stage', title: 'Average Time Stats Table Per Pipeline Stage', type: 'link' },
                      { path: '/internal/overall_system_reports/overall_avg_time_stats_per_pipeline_stage', title: 'Avg Time Stats graphs Per Pipeline Stage', type: 'link' },
                      { path: '/internal/overall_system_reports/avg_time_per_pipeline_stage_host_wise', title: 'Average Time Per Pipeline Stage By Host Wise', type: 'link' },
                      { path: '/internal/overall_system_reports/avg_time_per_pipeline_stage_site_wise', title: 'Average Time Per Pipeline Stage By Site Wise', type: 'link' },
                      { path: '/internal/overall_system_reports/avg_time_per_pipeline_stage_geo_wise', title: 'Average Time Per Pipeline Stage By Site Wise', type: 'link' },
                      { path: '/internal/overall_system_reports/host_wise_avg_time_to_pick_segment', title: 'Host Wise Average Time To Pick A Segment', type: 'link' },

                    ]
                  },
                  { path: '/internal/overall_system_reports/total_rss_and_feed_crawl_count', title: 'Total RSS Crawl Counts vs Total Feedcrawl Counts', type: 'link' },
                  { path: '/internal/overall_system_reports/aggregate_urls_crawled_by_request_type', title: 'Aggregate URLs Crawled By Request Type', type: 'link' },
                  { path: '/internal/overall_system_reports/aggregate_urls_crawled_by_request_type', title: 'Fetch Request Type Stats', type: 'link' },
                  { path: '/internal/overall_system_reports/site_upload_spike_and_drop', title: 'Site Upload Spike/Drop', type: 'link' },
                  { path: '/internal/overall_system_reports/field_upload_spike_and_drop', title: 'Field Upload Spike/Drop', type: 'link' },

                ]
              },
              {
                title: 'Proxy Sources', icon: Code, type: 'sub', active: false, children: [
                  { path: '/internal/proxy_sources/failure_and_success_rates', title: 'Failure and Success Rates', type: 'link' },
                ]
              },
              {
                title: 'Proxy/Selenium usage graph', type: 'sub', icon: Code, active: false, children: [
                  {
                    title: 'Site vs Worker Count', type: 'sub', active: false, children: [
                      { path: '/internal/site_or_project_or_client_vs_no_of_workers', title: 'Site/Project/Client vs No of workers', type: 'link' },
                      { path: '/internal/site_or_project_or_client_vs_no_of_selenium_workers', title: 'Site/Project/Client vs No of selenium workers', type: 'link' },

                    ]
                  },
                  {
                    title: 'Site vs Proxy/Cluster/Time', type: 'sub', active: false, children: [
                      { path: '/internal/site_or_project_or_client_vs_selenium_cluster', title: 'Site/Project/Client vs Selenium Cluster', type: 'link' },
                      { path: '/internal/site_or_project_or_client_vs_proxy_source', title: 'Site/Project/Client vs Proxy Source', type: 'link' },
                      { path: '/internal/site_or_project_or_client_vs_total_request', title: 'Site/Project/Client vs Total Request', type: 'link' },
                      { path: '/internal/site_or_project_or_client_vs_avg_time_for_crawl', title: 'Site/Project/Client vs Avgerage time for crawl', type: 'link' },

                    ]
                  },
                ]
              },
              {
                title: 'Resque Reports', type: 'sub', icon: Code, active: false, children: [
                  { path: '/internal/resque_info/system_wide_resque_jobs', title: 'System Wide Resque Jobs', type: 'link' },
                  { path: '/internal/resque_info/system_wide_resque_workers', title: 'System Wide Resque Workers', type: 'link' },
                  { path: '/internal/resque_info/site_wise_resque_running', title: 'Site Wise Resque Running', type: 'link' },
                  { path: '/internal/resque_info/site_wise_resque_waiting', title: 'Site Wise Resque Waiting', type: 'link' },
                  { path: '/internal/resque_info/killed_and_failed_stats', title: 'Resque Reports For Blocked Sites', type: 'link' },
                  { path: '/internal/resque_info/resque_reports_for_blocked_sites', title: 'Resque Reports For Blocked Sites', type: 'link' },
                  { path: '/internal/resque_info/avg_available_workers_in_resque_jobs', title: 'Avg available workers in resque jobs', type: 'link' },

                ]
              },
              {
                title: 'Rabbitmq Reports', type: 'sub', icon: Code, active: false, children: [
                  { path: '/internal/queue_sizes', title: 'Rabbitmq Queue Size', type: 'link' },
                  { path: '/internal/rabbitmq_queue_report/duplicate_queue_report', title: 'Rabbitmq duplicate queue reports', type: 'link' },

                ]
              },
              { path: `/internal/runforce_segment_status`, title: ' Runforce Segment Status', type: 'link' },

              { path: `/internal/scraper_api_crawl_tracker`, title: 'Scraper Api Crawl Tracker', type: 'link' },

              { path: `/internal/scraper_api_feasibility_check`, title: 'Scraper Api Feasibility Check', type: 'link' },
              {
                title: 'Selenium Status', type: 'sub', icon: Code, active: false, children: [
                  { path: '/internal/selenium/selenium_used_and_free_browser_counts_and_waiting_request_counts', title: 'Selenium Used and Free Browser Counts and Waiting Request Counts', type: 'link' },
                  { path: '/internal/selenium/sitewise_failed_and_succeeded_url_stats', title: 'Sitewise Failed and Succeeded Url Stats', type: 'link' },
                  { path: '/internal/selenium/ajax_initialization_time', title: 'Ajax Initialization Time', type: 'link' },
                  { path: '/internal/selenium/ajax_initialization_tries', title: 'Ajax Initialization Tries', type: 'link' },
                  { path: '/internal/selenium/site_wise_failure_and_retry_count', title: 'Site Wise Failure and Retry Count', type: 'link' },
                  { path: '/internal/selenium/selenium_cluster_wise_failure_and_retry_count', title: 'Selenium Cluster Wise Failure and Retry Count', type: 'link' },
                  { path: '/internal/selenium/selenium_cluster_diagnostics', title: 'Selenium Cluster Diagnostics', type: 'link' },
                  { path: '/internal/selenium/site_wise_selenium_browser_req_count', title: 'Site Wise Selenium Browser Request Count', type: 'link' },
                  { path: '/internal/selenium/pipeline_stage_wise_selenium_browser_req_count', title: 'Pipeline Stage Wise Selenium Browser Request Count', type: 'link' },
                  { path: '/internal/selenium/selenium_cluster_wise_browser_req_count', title: 'Selenium Cluster Wise Browser Request Count', type: 'link' },

                ]
              },
              { path: `/internal/overall_system_reports/segments_take_more_time_by_ednu_stage_wise`, title: 'Segments Take more time by ednu stage wise', type: 'link' },
              { path: `/internal/site_wise_worker_count`, title: 'Site Wise Worker Count', type: 'link' },
              { path: `/internal/proxy_log_reports`, title: 'Proxy Log Reports', type: 'link' },

            ]
          }
        ]
      },
      {
        menutitle: "Item7",
        menucontent: "Item7.1",
        Items: [
          {
            title: 'Jobspikr v3 internal dashboard',
            icon: LifeBuoy, type: 'sub',
            active: false,
            children: [
              { path: `#`, title: 'Random record from each JP site (Needs to be added)', type: 'external_link' },

              { path: `/jobspikr_v3_internal/jobspikr_graphs/jp_main_qa`, title: 'JP Main QA', type: 'link' },
              { path: `/jobspikr_v3_internal/jobspikr_graphs/dedup_tracker`, title: 'Dedup Tracker', type: 'link' },
              { path: `/jobspikr_v3_internal/jobspikr_graphs/jp_imp_filters`, title: 'JP Important Filters', type: 'link' },
              { path: `/jobspikr_v3_internal/jobspikr_graphs/jp_inferring_errors`, title: 'Overall JP Inferring Errors ', type: 'link' },
              { path: `/jobspikr_v3_internal/jobspikr_graphs/jp_inferred_location_error_by_site`, title: ' JP Inferred Location Error By Site', type: 'link' },
              { path: `/jobspikr_v3_internal/jobspikr_graphs/upload_stats_of_jp_sites`, title: 'Upload stats of JP sites', type: 'link' },
              { path: `/jobspikr_v3_internal/jobspikr_graphs/jp_index_progress`, title: 'JP index progress', type: 'link' },
              { path: `/jobspikr_v3_internal/jobspikr_graphs/countries_30d_vs_7d`, title: 'Countries-30d vs 7d', type: 'link' },
              { path: `/jobspikr_v3_internal/jobspikr_graphs/domains_7d_vs_2d`, title: 'Domains-7d vs 2d', type: 'link' },
              { path: `#`, title: 'One JP record from each jobboard (Needs to be added)', type: 'external_link' },

              { path: `/jobspikr_v3_internal/jobspikr_graphs/jp_indexing_errors`, title: 'Jobspikr Indexing Errors', type: 'link' },
              {
                title: 'Internal QA links', type: 'sub', active: false, children: [
                  { path: '/jobspikr_v3_internal/jobspikr_graphs/upload_count_vs_index_count_of_jp_sites', title: 'Upload count vs Indexed count of JP sites', type: 'link' },
                  { path: '/jobspikr_v3_internal/jobspikr_graphs/count_data_of_most_used_sites', title: 'Upload count vs Indexed count of most used JP sites', type: 'link' },
                  { path: '/jobspikr_v3_internal/jobspikr_graphs/volume_of_listings_from_top_countries', title: 'Volume of job listings from top countries', type: 'link' },
                  { path: '#', title: 'Count of most frequent client queries (Needs to be added)', type: 'external_link' },
                  { path: '/jobspikr_v3_internal/jobspikr_graphs/random_records_for_qa', title: '100 Random records for QA', type: 'link' },
                  { path: '/jobspikr_v3_internal/jobspikr_graphs/freshness_check_stats', title: 'Freshness check stats', type: 'link' },

                ]
              },
            ]
          }
        ]
      },
      {
        menutitle: "Item8",
        menucontent: "Item8.1",
        Items: [
          { path: `/internal/stack`, icon: FileText, title: 'Our Stack', type: 'link' },

        ]
      },
      {
        menutitle: "Item9",
        menucontent: "Item9.1",
        Items: [
          {
            title: 'Rails Internal Dashboard',
            icon: PieChart, type: 'sub',
            active: false,
            children: [
              { path: `/internal/crawlboard_request_logs`, title: 'Crawlboard Request Logs', type: 'link' },
              { path: `/internal/jobspikr_request_logs`, title: ' Jobspikr Request Logs', type: 'link' },
              { path: `/internal/datastock_request_logs`, title: 'DataStock Request Logs', type: 'link' },
              { path: `/internal/rails_resque_logs`, title: 'Rails Resque Logs ', type: 'link' },


            ]
          }
        ]
      },

    ])
  }

  const handleScroll = () => {
    if (window.scrollY > 400) {
      if (configDB.data.settings.sidebar.type.split(' ').pop() === 'material-type' || configDB.data.settings.sidebar.type.split(' ').pop() === 'advance-layout')
        document.querySelector(".sidebar-main").className = "sidebar-main hovered"
    } else {
      if (document.getElementById("sidebar-main"))
        document.querySelector(".sidebar-main").className = "sidebar-main"
    }
  }

  useEffect(() => {
    document.querySelector(".left-arrow").classList.add("d-none")
    window.addEventListener('resize', handleResize)
    handleResize();
    const currentUrl = window.location.pathname;
    mainmenu?.map(items => {
      items.Items.filter((Items) => {
        if (Items.path === currentUrl)
          setNavActive(Items)
        if (!Items.children) return false
        Items.children.filter(subItems => {
          if (subItems.path === currentUrl)
            setNavActive(subItems)
          if (!subItems.children) return false
          subItems.children.filter(subSubItems => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems)
              return true
            }
            else {
              return false
            }
          })
          return subItems
        })
        return Items
      })
      return items
    })
    window.addEventListener('scroll', handleScroll)
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
    }

    // eslint-disable-next-line
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth - 500);
  }

  const setNavActive = (item) => {
    let temp = mainmenu?.map(menuItems => {
      menuItems.Items.filter(Items => {
        if (Items !== item) {
          Items.active = false
          document.querySelector(".bg-overlay1").classList.remove("active")
        }
        if (Items.children && Items.children.includes(item)) {
          Items.active = true
          document.querySelector(".sidebar-link").classList.add("active")
        }
        if (Items.children) {
          Items.children.filter(submenuItems => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              Items.active = true
              submenuItems.active = true
              return true
            }
            else {
              return false
            }
          })
        }
        return Items
      })
      return menuItems
    })
    item.active = !item.active
    setMainMenu(temp)
  }

  const toggletNavActive = (item) => {
    if (window.innerWidth <= 991) {
      document.querySelector(".page-header").className = "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon "
      document.querySelector(".mega-menu-container").classList.remove("d-block");
      if (item.type === "sub") {
        document.querySelector(".page-header").className = "page-header ";
        document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper "
      }
    }
    let temp = [...mainmenu]
    if (!item.active) {
      temp = mainmenu?.map(a => {
        a.Items.filter((Items) => {
          if (a.Items.includes(item))
            Items.active = false
          if (!Items.children) return false
          Items.children.forEach(b => {
            if (Items.children.includes(item)) {
              b.active = false
            }
            if (!b.children) return false
            b.children.forEach(c => {
              if (b.children.includes(item)) {
                c.active = false
              }
            })
          })
          return Items
        });
        return a
      });
    }
    item.active = !item.active
    setMainMenu(temp)
  }

  const scrollToRight = () => {
    if (margin <= -2598 || margin <= -2034) {
      if (width === 492) {
        setMargin(-3570)
      } else {
        setMargin(-3464)
      }
      document.querySelector(".right-arrow").classList.add("d-none")
      document.querySelector(".left-arrow").classList.remove("d-none")
    } else {
      setMargin(margin => margin += (-width));
      document.querySelector(".left-arrow").classList.remove("d-none")
    }
  }

  const scrollToLeft = () => {
    if (margin >= -width) {
      setMargin(0)
      document.querySelector(".left-arrow").classList.add("d-none")
      document.querySelector(".right-arrow").classList.remove("d-none")
    } else {
      setMargin(margin => margin += width);
      document.querySelector(".right-arrow").classList.remove("d-none")
    }
  }

  const closeOverlay = () => {
    document.querySelector(".bg-overlay1").classList.remove("active")
    document.querySelector(".sidebar-link").classList.remove("active")
  }

  const activeClass = () => {
    document.querySelector(".sidebar-link").classList.add("active")
    document.querySelector(".bg-overlay1").classList.add("active")
  }

  const openCloseSidebar = (toggle) => {
    if (toggle) {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className = "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon "
    } else {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className = "page-header";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper "
    }
  };

  const responsiveSidebar = () => {
    document.querySelector(".page-header").className = "page-header close_icon";
    document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon"
  }

  return (
    <Fragment>
      <div className={`bg-overlay1`} onClick={() => { closeOverlay() }} ></div>
      <div className="sidebar-wrapper" id="sidebar-wrapper">
        <div className="logo-wrapper">
          <Link to={`/`}>
            <img className="img-fluid for-light" src={require("../../sidebar/logo/logo.png")} alt=""
              style={{ height: 55, width: 80, display: 'flex', margin: '0 auto' }} />
          </Link>
          <div className="back-btn" onClick={() => responsiveSidebar()}><i className="fa fa-angle-left"></i></div>
          <div className="toggle-sidebar" onClick={() => openCloseSidebar(sidebartoogle)}><Grid className="status_toggle middle sidebar-toggle" /></div>
        </div>
        <div className="logo-icon-wrapper">
          <Link to={`/`}><img className="img-fluid" src={require("../../sidebar/logo/logo-icon.png")} alt="" /></Link>
        </div>
        <nav className="sidebar-main" id="sidebar-main">
          <div className="left-arrow" onClick={scrollToLeft}><ArrowLeft /></div>
          <div id="sidebar-menu" style={wrapper.split(' ').includes('horizontal-wrapper') ? { 'marginLeft': margin + 'px' } : { margin: '0px' }}>
            <ul className="sidebar-links custom-scrollbar" >
              <li className="back-btn">
                <div className="mobile-back text-right"><span>{"Back"}</span><i className="fa fa-angle-right pl-2" aria-hidden="true"></i></div>
              </li>
              {
                mainmenu?.map((Item, i) =>
                  <Fragment key={i}>
                    {Item.Items.map((menuItem, i) =>
                      <li className="sidebar-list" key={i}>
                        {(menuItem.type === 'sub') ?
                          <a href="javascript" className={`sidebar-link pe-4 sidebar-title ${menuItem.active ? 'active' : ''}`} onClick={(event) => { event.preventDefault(); setNavActive(menuItem) }}>
                            <menuItem.icon />
                            <span>{props.t(menuItem.title)}</span>
                            {menuItem.badge ? <label className={menuItem.badge}>{menuItem.badgetxt}</label> : ""}
                            <div className="according-menu">
                              {menuItem.active ?
                                <i className="fa fa-angle-down"></i>
                                : <i className="fa fa-angle-right"></i>
                              }
                            </div>
                          </a>
                          : (menuItem.type === 'link') ?
                            <Link to={menuItem.path} className={`sidebar-link pe-4 sidebar-title link-nav  ${menuItem.active ? 'active' : ''}`} onClick={() => toggletNavActive(menuItem)}>
                              <menuItem.icon />
                              <span>{props.t(menuItem.title)}</span>
                              {menuItem.badge ? <label className={menuItem.badge}>{menuItem.badgetxt}</label> : ""}
                            </Link>
                            : (menuItem.type === 'external_link' ? <a href={menuItem.path} className={`sidebar-link pe-4 sidebar-title link-nav  ${menuItem.active ? 'active' : ''}`}
                              onClick={() => toggletNavActive(menuItem)} target='_blank'>
                              <menuItem.icon />
                              <span>{props.t(menuItem.title)}</span>
                              {menuItem.badge ? <label className={menuItem.badge}>{menuItem.badgetxt}</label> : ""}
                            </a> : ((menuItem.type === 'mysql_page_link') && ror_users.includes(email)) ?
                            <Link to={menuItem.path} className={`sidebar-link pe-4 sidebar-title link-nav  ${menuItem.active ? 'active' : ''}`} onClick={() => toggletNavActive(menuItem)}>
                              <menuItem.icon />
                              <span>{props.t(menuItem.title)}</span>
                              {menuItem.badge ? <label className={menuItem.badge}>{menuItem.badgetxt}</label> : ""}
                            </Link>
                              : '')}

                        {menuItem.children ?

                          <ul className="sidebar-submenu"
                            style={menuItem.active ? sidebartoogle ? { opacity: 1, transition: 'opacity 500ms ease-in' } : { display: "block" } : { display: "none" }}>

                            {menuItem.children.map((childrenItem, index) => {

                              return (
                                <li key={index}>

                                  {(childrenItem.type === 'sub') ?
                                    <a href="javascript" className={`${childrenItem.active ? 'active ll' : 'll'}`} onClick={(event) => { event.preventDefault(); toggletNavActive(childrenItem) }}>{props.t(childrenItem.title)}
                                      <span className="sub-arrow">
                                        <i className="fa fa-chevron-right"></i>
                                      </span>
                                      <div className="according-menu">
                                        {childrenItem.active ?
                                          <i className="fa fa-angle-down"></i>
                                          : <i className="fa fa-angle-right"></i>
                                        }
                                      </div>
                                    </a>
                                    : ""}

                                  {(childrenItem.type === 'link') ?
                                    <Link
                                    to={childrenItem.path + '/'}
                                    className={`${childrenItem.active ? 'active submenu-item-link' : 'submenu-item-link'}`}
                                    onClick={() => toggletNavActive(childrenItem)}>
                                    <p className={`${childrenItem.active ? 'submenu-item-link-is-active' : 'submenu-item-link'}`}>{props.t(childrenItem.title)}</p>
                                    {/* {childrenItem.active ? <span className='childern-itm-link-content'><p className='sidebar-submenu-link-active'>{props.t(childrenItem.title)}</p></span> : <span className='childern-itm-link-content'>{props.t(childrenItem.title)}</span>} */}
                                    </Link>
                                    : (childrenItem.type === 'external_link') ?
                                    <a href={childrenItem.path} className={`${childrenItem.active ? 'active kk' : 'kk'}`} onClick={() => toggletNavActive(childrenItem)} target='_blank'>{props.t(childrenItem.title)}</a>
                                    : ((childrenItem.type === 'mysql_page_link') && ror_users.includes(email)) ? <a href={childrenItem.path} className={`${childrenItem.active ? 'active kk' : 'kk'}`} onClick={() => toggletNavActive(childrenItem)} target='_blank'>{props.t(childrenItem.title)}</a> : ''
                                  }

                                  {childrenItem.children ?
                                    <ul className="nav-sub-childmenu submenu-content"
                                      style={childrenItem.active ? { display: "block" } : { display: "none" }}>
                                      {childrenItem.children.map((childrenSubItem, key) =>
                                        <li key={key}>
                                          {((childrenSubItem.type === 'link') || (childrenSubItem.type === 'mysql_page_link') && ror_users.includes(email)) ?
                                            <Link 
                                              to={childrenSubItem.path + '/'} 
                                              className={` ${childrenSubItem.active ? ' childrenSubItem active' : ''}`} 
                                              onClick={() => toggletNavActive(childrenSubItem)}>
                                                {props.t(childrenSubItem.title)}
                                            </Link>
                                            : 
                                            (childrenSubItem.type === 'external_link') ?
                                            <Link 
                                              to={childrenSubItem.path + '/'} 
                                              target="_blank"
                                              className={` ${childrenSubItem.active ? ' childrenSubItem active' : ''}`} 
                                              onClick={() => toggletNavActive(childrenSubItem)}>
                                                {props.t(childrenSubItem.title)}
                                            </Link>
                                            :
                                            (childrenSubItem.type === 'sub')?
                                            <>
                                              <a href="javascript" className={`${childrenSubItem.active ? 'active ll' : 'll'}`} onClick={(event) => { event.preventDefault(); toggletNavActive(childrenSubItem) }}>{props.t(childrenSubItem.title)}
                                                <span className="sub-arrow">
                                                  <i className="fa fa-chevron-right"></i>
                                                </span>
                                                <div className="according-menu">
                                                  {childrenSubItem.active ?
                                                    <i className="fa fa-angle-down"></i>
                                                    : <i className="fa fa-angle-right"></i>
                                                  }
                                                </div>
                                              </a>
                                              {
                                                childrenSubItem.type === 'sub' && childrenSubItem?.children?.map((itm, indx)=>{
                                                  return(
                                                    <ul
                                                     className="nav-sub-childmenu submenu-content"
                                                     style={childrenSubItem.active ? { display: "block" } : { display: "none" }}
                                                     >
                                                      <li key={indx} className='px-2'>
                                                        <Link 
                                                          to={itm?.path + '/'} 
                                                          className={` ${itm?.active ? ' childrenSubItem active' : ''}`} 
                                                          onClick={() => toggletNavActive(itm)}>
                                                          {"- " + props.t(itm?.title)}
                                                        </Link>

                                                      </li>
                                                    </ul>
                                                  )
                                                })
                                              }
                                            </>
                                            :
                                            ''
                                          }
                                        </li>
                                      )}
                                    </ul>
                                    : ""}

                                </li>
                              )
                            })}
                          </ul>
                          : ''}
                      </li>)}
                  </Fragment>
                )}
            </ul>
          </div>
          <div className="right-arrow" onClick={scrollToRight}><ArrowRight /></div>
        </nav>
      </div>
    </Fragment>
  );
}

export default translate(Sidebar);
