import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";
import moment from "moment";
import axios from "axios";
import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";

export default function CloudMachinesCountEstimation() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const searchParams = {
    clusterName: new URLSearchParams(search).get("filter[cluster_name]")
  }
  const [clusterName, setClusterName] = useState({label: 'All', value: 'All'});
  const [clusterNameOptions, setClusterNameOptions] = useState([]);
  const [gceMachineCountData, setGceMachineCountData] = useState([]);
  const [awsMachineCountData, setAwsMachineCountData] = useState([]);
  const [loading, setLoading] = useState(false);

  const tableColumns = [
    { name: 'Cluster Name', selector: 'cluster_name', sortable: true },
    { name: 'Job Type', selector: 'job_type', sortable: true },
    { name: 'Count', selector: 'count', sortable: true },
  ]

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let geo_clusters = []
        var arr = ["aws_us","aws_eu","aws_sg","aws_india","aws_au"];
        var skip_cluster_names = ["hetzner_de", "dedicated_crawl_cluster"];
        res.data.geo_clusters.forEach(item => {
          if (!(skip_cluster_names.includes(item))){
            if (arr.includes(item)){
              geo_clusters.push({ label: item, value: item, isDisabled: true})
            }
            else{
              geo_clusters.push({ label: item, value: item })
            }
          }
        })
        geo_clusters.push({ label: 'All', value: 'All' })
        setClusterNameOptions(geo_clusters.reverse());
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      });
  }, []);

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.clusterName && searchParams.clusterName != clusterName.value) {
      setClusterName({ label: searchParams.clusterName, value: searchParams.clusterName });
    }
    if (commit == 'Get Data') {
      fetchDataWithParams(searchParams.clusterName);
    }
    else {
      fetchData();
    }
  }

  function fetchDataWithParams(cluster_name = clusterName) {
    setLoading(true)
    axios.get(`/api/v1/internal/aws_and_gce_machine_info/cloud_machines_count_estimation?filter[cluster_name]=${cluster_name}`)
      .then(res => {
        setGceMachineCountData(res.data.gce_machine_count);
        setAwsMachineCountData(res.data.aws_machine_count);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  function fetchData() {
    setLoading(true)
    axios.get(`/api/v1/internal/aws_and_gce_machine_info/cloud_machines_count_estimation`)
      .then(res => {
        setGceMachineCountData(res.data.gce_machine_count);
        setAwsMachineCountData(res.data.aws_machine_count);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  const handleGetData = () => {
    window.location.href = `/internal/aws_and_gce_machine_info/cloud_machines_count_estimation_new?filter[cluster_name]=${clusterName.value}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/aws_and_gce_machine_info/cloud_machines_count_estimation_new">Cloud Machines Estimated Count</a> : 'Cloud Machines Estimated Count'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
          </BreadcrumbItem>}
        </Breadcrumb>
      </Col>
    </Row>
  </div>

  <Card>
    <CardBody>
      <Row>
        <Col sm="4">
          <label className="fw-bold">Cluster Name</label>
          <Select
            value={clusterName}
            options={clusterNameOptions}
            onChange={option => setClusterName(option)} />
        </Col>
        <Col sm="4">
          <label className="fw-bold">&nbsp;</label>
          <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
        </Col>
      </Row>
    </CardBody>
  </Card>
  <Card>
    <CardBody>
      <h5 className="mb-2">GCE machine count</h5>
      {
        gceMachineCountData.length > 0 ? 
        <>
          <Row className="align-items-end mt-2">
            <DataTable
              columns={tableColumns}
              data={gceMachineCountData}
              noHeader
              pagination
              progressPending={loading}
            />
          </Row>
        </>
        : "No data available!"
      }
      <h5 className="mb-2 mt-2">AWS machine count</h5>
      {
        awsMachineCountData.length > 0 ? 
        <>
          <h5 className="mb-2">AWS machine count</h5>
          <Row className="align-items-end mt-2">
            <DataTable
              columns={tableColumns}
              data={awsMachineCountData}
              noHeader
              pagination
              progressPending={loading}
            />
          </Row>
        </>
        : "No data available!"
      }
    </CardBody>
  </Card>
</Container>
}

