import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";
import moment from "moment";
import axios from "axios";

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function SiteWiseResqueRunning() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const tabNameFromSearchUrl = new URLSearchParams(search).get("filter[tab_name]");
  const tabNameFromUrl = window.location.hash.substr(1);
  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[site_name]"),
    geoCluster: new URLSearchParams(search).get("filter[geo_cluster]"),
    jobType: new URLSearchParams(search).get("filter[job_type]"),
    fromTime: new URLSearchParams(search).get("filter[time]"),
    tabName: tabNameFromSearchUrl ? tabNameFromSearchUrl : tabNameFromUrl
  }
  const [siteName, setSiteName] = useState({label: 'All', value: '*'});
  const [siteNameOptions, setSiteNameOptions] = useState([]);
  const [geoCluster, setGeoCluster] = useState({label: 'All', value: '*'});
  const [geoClusterOptions, setGeoClusterOptions] = useState([]);
  const [jobType, setJobType] = useState({label: 'All', value: '*'});
  const [fromTime, setFromTime] = useState({ label: '', value: '' });
  const [activeTab, setActiveTab] = useState(tabNameFromUrl ? tabNameFromUrl : 'site_wise_resque_running');

  const jobTypeOptions = [
    { label: 'All', value: '*' },
    { label: 'Recrawl', value: 'recrawl' },
    { label: 'Distributed Recrawl', value: 'distributed_recrawl' },
    { label: 'Feedcrawl', value: 'feedcrawl3' },
    { label: 'Dependent Data Upload', value: 'dependent_data_upload' },
  ]

  const timeOptions = [
    { label: 'Today', value: 'now%2Fd' },
    { label: 'Last 30 minutes', value: 'now-30m' },
    { label: 'Last 1 hour', value: 'now-1h' },
    { label: 'Last 2 hours', value: 'now-2h' },
    { label: 'Last 4 hours', value: 'now-4h' },
    { label: "Last 10 hours", value: "now-10h" },
    { label: 'Last 12 hours', value: 'now-12h' },
    { label: 'Last 24 hours', value: 'now-24h' },
    { label: 'Last 2 days', value: 'now-2d' },
    { label: 'Last 3 days', value: 'now-3d' },
    { label: 'Last 4 days', value: 'now-4d' },
    { label: 'Last 7 days', value: 'now-7d' },
    { label: 'Last 15 days', value: 'now-15d' },
    { label: 'Last 30 days', value: 'now-30d' },
    { label: 'Last 60 days', value: 'now-60d' },
    { label: 'Last 1 year', value: 'now-1y' }
  ];

  const [UrlParams, setUrlParams] = useState({
    siteName: "*",
    geoCluster: "*",
    jobType: '*',
    fromTime: '',
    tabName: "site_wise_resque_running"
  })

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let site_names = res.data.site_names, site_names_options = []
        for (var key in site_names) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
            site_names[key].map(item => {
              children.push({ label: item, value: item })
            })
          group['options'] = children;
          site_names_options.push(group)
        }
        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: '*' }] })
        setSiteNameOptions(site_names_options);
        let geo_clusters = []
        res.data.geo_clusters.forEach(item => {
          geo_clusters.push({ label: item, value: item })
        })
        geo_clusters.push({ label: 'All', value: '*' })
        setGeoClusterOptions(geo_clusters);
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      });
  }, []);

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.siteName && searchParams.siteName != siteName.value) {
      setSiteName({ label: searchParams.siteName  == '*' ? 'All' :  searchParams.siteName, value: searchParams.siteName });
    }
    if (searchParams.geoCluster && searchParams.geoCluster != geoCluster.value) {
      setGeoCluster({ label: searchParams.geoCluster  == '*' ? 'All' :  searchParams.geoCluster, value: searchParams.geoCluster });
    }
    if (searchParams.jobType && searchParams.jobType != jobType.value) {
      setJobType(
       
        jobTypeOptions.filter(item => item.value == searchParams.jobType)
        );
    }
    if (searchParams.fromTime && searchParams.fromTime != fromTime.value) {
      setFromTime(timeOptions.filter(item => item.value == searchParams.fromTime)[0]);
    }
    if (searchParams.tabName && searchParams.tabName != activeTab) {
      setActiveTab(searchParams.tabName);
    }
    if (commit == 'Get Data' && searchParams.geoCluster && searchParams.jobType && searchParams.siteName) {
      setUrlParams(searchParams)
    }
  }

  function siteWiseResqueRunningKibanaUrl(){
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
    var from_time_val = UrlParams.fromTime ? UrlParams.fromTime : "now%2Fd";

    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${from_time_val}',mode:quick,to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:lucene,query:(query_string:(analyze_wildcard:!t,query:'file_name:%22resque_utils.rb%22++AND+%22*send_resque_logs_to_es%22+AND+current_status:%22running%22+AND+job_type:+${UrlParams.jobType}+AND+site_name:+${UrlParams.siteName}+AND+geo_cluster:+${UrlParams.geoCluster}+AND+NOT(custom:+resque_jobs_tracker)'))),uiState:(),vis:(aggs:!((id:'1',params:(customLabel:job_count,field:job_count),schema:metric,type:sum),(id:'2',params:(customInterval:'0.5h',extended_bounds:(),field:'@timestamp',interval:custom,min_doc_count:1),schema:segment,type:date_histogram),(id:'3',params:(customLabel:job,field:job_type.raw,order:desc,orderBy:'1',row:!t,size:5),schema:split,type:terms),(id:'4',params:(customLabel:geo,field:geo_cluster.raw,order:desc,orderBy:'1',size:5),schema:group,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:'Site+Wise+Resque+Graph+-+Running',type:line))`
  }

  function topSitesHaveRunningJobsKibanaUrl(){
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
    var from_time_val = UrlParams.fromTime ? UrlParams.fromTime : "now-1h";

    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${from_time_val}',mode:quick,to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:lucene,query:(query_string:(analyze_wildcard:!t,query:'file_name:%22resque_utils.rb%22++AND+%22*send_resque_logs_to_es%22+AND+current_status:%22running%22++AND+NOT(custom:+resque_jobs_tracker)+AND+job_type:+${UrlParams.jobType}+AND+site_name:+${UrlParams.siteName}+AND+geo_cluster:+${UrlParams.geoCluster}'))),uiState:(vis:(colors:(post_upload_merge_files:%233F6833))),vis:(aggs:!((id:'1',params:(field:job_count),schema:metric,type:avg),(id:'2',params:(customLabel:site_name,field:site_name,order:desc,orderBy:'1',size:30),schema:segment,type:terms),(id:'3',params:(customLabel:job_type,field:job_type.raw,order:asc,orderBy:'1',size:10),schema:group,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Top+Sites+Have+Running+Jobs+In+Resque',type:histogram))`
  }

  const handleGetData = () => {
    window.location.href = `/internal/resque_info/site_wise_resque_running?filter[geo_cluster]=${geoCluster.value}&filter[job_type]=${jobType.value}&filter[site_name]=${siteName.value}&filter[time]=${fromTime.value}&filter[tab_name]=${activeTab}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/resque_info/site_wise_resque_running">Site Wise Resque Running</a> : 'Site Wise Resque Running'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                Search
                </BreadcrumbItem>}
          </Breadcrumb>
        </Col>
      </Row>
    </div>

    <Card>
      <CardBody>
        <Row>
          <Col sm="8">
            <label className="fw-bold">Site Name</label>
            <Select
              value={siteName}
              options={siteNameOptions}
              onChange={option => setSiteName(option)} />
          </Col>
          <Col sm="4">
            <label className="fw-bold">Time</label>
            <Select
              value={fromTime}
              options={timeOptions}
              onChange={option => setFromTime(option)} />
          </Col>
        </Row>
        <Row className="align-items-end mt-4">
          <Col sm="4">
            <label className="fw-bold">Geo Cluster</label>
            <Select
              value={geoCluster}
              options={geoClusterOptions}
              onChange={option => setGeoCluster(option)} />
          </Col>
          <Col sm="4">
            <label className="fw-bold">Job Type</label>
            <Select
              value={jobType}
              options={jobTypeOptions}
              onChange={option => setJobType(option)} />
          </Col>
          <Col sm="4">
            <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
          </Col>
        </Row>
      </CardBody>
    </Card>
    <Card>
      <CardBody>
        <div className="mb-1">
          <b>NOTE:</b>
          If the graph is not shown after your form submission then you should do either maximize its size(the option is available on the right side of graph) or minimize the sidebar.
        </div>
        <Nav tabs>
          <NavItem>
            <NavLink className={activeTab == 'site_wise_resque_running' ? 'active' : ''} onClick={() => setActiveTab('site_wise_resque_running')}>
              Site Wise Resque Running
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab == 'top_sites_have_running_jobs' ? 'active' : ''} onClick={() => setActiveTab('top_sites_have_running_jobs')}>
              Top Sites Have Running Jobs In Resque
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={'site_wise_resque_running'}>
            <div className="panel-body">
              <center>
                <span className="fw-bold">Site Wise Resque Running</span><br />
                <a target="_blank" className="link-primary" href={siteWiseResqueRunningKibanaUrl()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={siteWiseResqueRunningKibanaUrl() + "&embed=true"} height="500" width="100%" className="border-0"></iframe>
            </div>
          </TabPane>
          <TabPane tabId={'top_sites_have_running_jobs'}>
            <div className="panel-body">
              <center>
                <span className="fw-bold">Top Sites Have Running Jobs In Resque</span><br />
                <a target="_blank" className="link-primary" href={topSitesHaveRunningJobsKibanaUrl()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={topSitesHaveRunningJobsKibanaUrl() + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
            </div>
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  </Container>
}

