import React, { Fragment, useState, useEffect } from 'react'
import { Modal, Button, Row, Col, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios'
import CustomTable from './CustomTable.component'
import { stringify } from "json-to-pretty-yaml2";

import SpinnerLoader from '../../../../Common/LoadingAnimation/SpinnerLoader.component'

export default function ModifyXpathModal(props) {

  const [modifiedXpathValue, setModifiedXpathValue] = useState("") 
  const [loading, setLoading] = useState(false) 
  const [data, setData] = useState([])

  // API endpoint
  const API_ENDPOINT = `/api/v1/internal/chat_gpt_xpath_result_page/modified_xpath_data`

  async function onSubmitHandler() {
    const responseBody = {
      filters: {
        field_name: props?.fieldName,
        modified_xpath_value: modifiedXpathValue,
        input_file_path: props?.inputFilePath,
      }
    }
    try {
      setLoading(true)
      const response = await axios.post(API_ENDPOINT, responseBody)
      const resp_data = response?.data?.data
      setData([resp_data]);
      props.setModalRespData(resp_data);
      setLoading(false)
      toast.success(response?.data?.message)
    }
    catch (error) {
      setData();
      setLoading(false)
      toast.error(error?.response?.data?.message)
    }
  }

  const disableSubmitBtn = () => {
    let status = false;
    if (modifiedXpathValue?.length == 0) {
      status = true;
    }
    if (props?.inputFilePath?.length == 0) {
      status = true;
    }
    return status
  }

  const copyXpath = (data) => {
    let copy_data = {};
    copy_data[data.field_name] = {
      desc_of_xpath: data.xpath,
      standard_nodeset_range: "first",
      standard_nodeset_join_char: "|",
      standard_post_processing_functions_on_text: ["remove_newlines_and_whitespaces"]
    }
    const yml_data = stringify(copy_data);
    navigator.clipboard.writeText(yml_data)
    toast.success("Copied the data successfully!")
  }

  const copyXpathAsTld = (data) => {
    let copy_data = {};
    copy_data["tld_"+ data.field_name] = {
      desc_of_xpath: data.xpath,
      standard_nodeset_range: "first",
      standard_nodeset_join_char: "|",
      standard_post_processing_functions_on_text: ["remove_newlines_and_whitespaces"]
    }
    const yml_data = stringify(copy_data);
    navigator.clipboard.writeText(yml_data)
    toast.success("Copied the data successfully!")
  }

  const columns = [
    { name: "Field Name", selector: row => row?.field_name, sortable: true, left: true },
    { name: "XPATH", selector: row => row?.xpath, sortable: true, center: true },
    { name: "Disk Value", selector: row => row?.disk_value, sortable: true, center: true },
    { name: "Actions", selector: row => (
      <>
        <Button 
          style={{ marginBottom: "10px"}}
          variant='success' 
          onClick={() => copyXpath(row)}  
          data-tip='copy to clipboard'>
          Copy XPATH
        </Button>
        <Button 
          style={{ marginBottom: "10px"}}
          variant='success' 
          onClick={() => copyXpathAsTld(row)}  
          data-tip='copy to clipboard'>
          Copy XPATH as tld
        </Button> 
      </>), 
      sortable: true, 
      center: true 
    }
  ]

  const handleHideModal = () => {
    props.onHide();
  }

  return (
    <Fragment>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" dialogClassName="custom_modal_70w" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Modify XPATH for {props.fieldName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row className='align-items-end'>
              <Col md="12">
                <label className='fw-bold'>Enter New XPATH</label>
                <input 
                  type="text" 
                  className='form-control w-100' 
                  placeholder="Ex: //div[@id='titleBlock']//div[@id='titleSection']//span[@id='productTitle']"
                  onChange={(event) => setModifiedXpathValue(event?.target?.value)} 
                />
              </Col>
            </Row>
            <Row className='my-3'>
              <Col md="12">
                <SpinnerLoader loading={loading}>
                  {data && data.length > 0 &&
                  <Card>
                    <Card.Header as="h6" style={{padding:"10px", fontWeight: "bold" }}>New Xpath Data: </Card.Header>
                    <Card.Body style={{padding:"10px" }}>
                      <CustomTable data={data} columns={columns} />
                    </Card.Body>
                  </Card>
                  }
                </SpinnerLoader>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant='success' 
            disabled={disableSubmitBtn()} 
            onClick={() => onSubmitHandler()}>
            Submit
          </Button>
          <Button onClick={() => handleHideModal()}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
}
