import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Info } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import DataTable from "react-data-table-component";
import axios from "axios";


import { KIBANA_JP_URL } from "../../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function DedupTracker() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const tabNameFromSearchUrl = new URLSearchParams(search).get("filter[tab_name]");
  const tabNameFromUrl = window.location.hash.substr(1);
  const searchParams = {
    uniqId: new URLSearchParams(search).get("filter[uniq_id]"),
    fromDate: new URLSearchParams(search).get("filter[from_date]"),
    toDate: new URLSearchParams(search).get("filter[to_date]"),
    tabName: tabNameFromSearchUrl ? tabNameFromSearchUrl : tabNameFromUrl
  }
  const now = new Date();
  const [uniqId, setUniqId] = useState("");
  const [siteName, setSiteName] = useState("jobspikr_com_dedup_record_linkage_pc_jobspikr");
  const [rssQueueInfo, setRssQueueInfo] = useState([]);
  const [queueSizeInfo, setQueueSizeInfo] = useState([]);
  const [siteRssCount, setSiteRssCount] = useState([]);
  const [jobStatusData, setJobStatusData] = useState([]);
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [activeTab, setActiveTab] = useState(tabNameFromUrl ? tabNameFromUrl : 'overall_dedup_status');
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(7, 'days')));
  const [toDate, setToDate] = useState(now);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSearchParamsData()
  }, []);

  const rssQueueInfoColumns = [
    { name: 'Site', selector: 'site_name', sortable: true, cell: (each) => <a href={`/internal/upload?filter%5Bsite%5D=${each?.site_name}`} 
      target='_blank'>{each?.site_name}</a> },
    { name: 'Rss Q Size', selector: 'queue_size', sortable: true, cell: (each) => <>
      <a href={`/internal/queue_sizes/search?filter%5Bqueue_type%5D=rss_queue&filter%5Bsite%5D=${each?.site_name}`} 
        target='_blank' style={{fontSize: 16}}>{each?.queue_size}</a> <Info size={16} className='mx-2' data-tip data-for='goto_view' /> 
        <ReactTooltip id='goto_view' clickable effect='solid'>
          Go to Queue Size View
    </ReactTooltip></> },
  ]

  const queueSizeInfoColumns = [
    { name: 'Queue Name', selector: 'queue_name', sortable: true },
    { name: 'Queue Size', selector: 'queue_size', sortable: true, cell: (each) => <>
    <a href={`/internal/queue_sizes/search?filter%5Bsite%5D=${siteName}`} 
        target='_blank' style={{fontSize: 16}}>{each?.queue_size}</a> <Info size={16} className='mx-2' data-tip data-for='goto_view' /> 
        <ReactTooltip id='goto_view' clickable effect='solid'>
          Go to Queue Size View
    </ReactTooltip></> },
    { name: 'Server Name', selector: 'server', sortable: true },
  ]

  const siteRssCountColumns = [
    { name: 'Site Name', selector: 'site', sortable: true },
    { name: 'Crawl Date', selector: 'date', sortable: true },
    { name: 'Rss Count', selector: 'rss_count', sortable: true },
  ]

  const UniqIdColumns = [
    { name: 'Uniq Id', selector: 'uniq_id', sortable: true },
    { name: 'Duplicate Status', selector: 'duplicate_status', sortable: true },
    { name: 'Duplicate of', selector: 'duplicate_of', sortable: true },
  ]

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.fromDate && searchParams.fromDate != fromDate) {
      setFromDate(new Date(searchParams.fromDate));
    }
    if (searchParams.toDate && searchParams.toDate != toDate) {
      setToDate(new Date(searchParams.toDate));
    }
    if (searchParams.tabName && searchParams.tabName != activeTab) {
      setActiveTab(searchParams.tabName);
    }
    if (commit == 'Get Data' && searchParams.fromDate && searchParams.toDate) {
      setActiveTab('overall_dedup_status');
      fetchOverallDedupStatusData(searchParams.fromDate, searchParams.toDate)
    }
    if (commit == 'Get Data' && searchParams.uniqId) {
      setActiveTab('job_status_by_uniq_id');
      fetchJobStatusByUniqIdData(searchParams.uniqId);
    }
    else {
      fetchOverallDedupStatusData(fromDate, toDate);
    }
  }

  function fetchOverallDedupStatusData(from_date = fromDate, to_date = toDate) {
    setLoading(true)
    axios.get(`/api/v1/jobspikr_v3_internal/jobspikr_graphs/dedup_tracker?filter[from_date]=${moment(from_date).format('YYYY/MM/DD')}&filter[to_date]=${moment(to_date).format('YYYY/MM/DD')}`)
      .then(res => {
        setSiteName(res.data.site_name);
        setRssQueueInfo(res.data.rss_queue_info);
        setQueueSizeInfo(res.data.queue_size_info);
        setSiteRssCount(res.data.site_rss_count);
        setUniqId(res.data.uniq_id);
        setJobStatusData(res.data.job_status_data);
        setFromTime(res.data.from_time);
        setToTime(res.data.to_time);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  function fetchJobStatusByUniqIdData(uniq_id = uniqId) {
    setLoading(true)
    axios.get(`/api/v1/jobspikr_v3_internal/jobspikr_graphs/dedup_tracker?filter[uniq_id]=${uniq_id}`)
      .then(res => {
        setSiteName(res.data.site_name);
        setRssQueueInfo(res.data.rss_queue_info);
        setQueueSizeInfo(res.data.queue_size_info);
        setSiteRssCount(res.data.site_rss_count);
        setUniqId(res.data.uniq_id);
        setJobStatusData(res.data.job_status_data);
        setFromTime(res.data.from_time);
        setToTime(res.data.to_time);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  function overallDedupStatusKibanaUrl(){
    return `${KIBANA_JP_URL}app/kibana#/dashboard/bf2c8c20-9400-11ea-8910-5d7412b1fc26?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:'${fromTime}',to:'${toTime}'))&_a=(description:'',filters:!(('$state':(store:appState),meta:(alias:'Posted+in+last+1+month',disabled:!f,index:'1bfae8a0-47e6-11ea-8910-5d7412b1fc26',key:post_date,negate:!f,params:(gte:now-1M,lt:now),type:range),range:(post_date:(gte:now-1M,lt:now)))),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),panels:!((embeddableConfig:(),gridData:(h:15,i:'146c26dc-d721-43aa-9829-72ef9de8e06b',w:24,x:0,y:0),id:'3f6dd400-93fe-11ea-806d-951c44727d29',panelIndex:'146c26dc-d721-43aa-9829-72ef9de8e06b',type:visualization,version:'7.5.1'),(embeddableConfig:(),gridData:(h:15,i:c217b9e5-f474-4690-a30c-8bc759b61fcb,w:24,x:24,y:0),id:e68ab380-93fd-11ea-806d-951c44727d29,panelIndex:c217b9e5-f474-4690-a30c-8bc759b61fcb,type:visualization,version:'7.5.1'),(embeddableConfig:(),gridData:(h:14,i:ba2983f8-b183-4c3c-bd48-09fb11f2d51e,w:48,x:0,y:15),id:c0f7cbb0-93ff-11ea-8910-5d7412b1fc26,panelIndex:ba2983f8-b183-4c3c-bd48-09fb11f2d51e,type:visualization,version:'7.5.1'),(embeddableConfig:(),gridData:(h:15,i:f623a92e-4afd-42ea-b31b-5a9922885127,w:48,x:0,y:29),id:'73579f80-93fe-11ea-8910-5d7412b1fc26',panelIndex:f623a92e-4afd-42ea-b31b-5a9922885127,type:visualization,version:'7.5.1')),query:(language:kuery,query:''),timeRestore:!f,title:'JPP+QA+-+Duplication+Status+Dashboard',viewMode:view)`
  }

  function jobStatusByUniqIdKibanaUrl(){
    return `${KIBANA_JP_URL}app/kibana#/discover?_g=(refreshInterval:(pause:!t,value:0),time:(from:now-30d,to:now))&_a=(columns:!(_source),index:'1bfae8a0-47e6-11ea-8910-5d7412b1fc26',interval:auto,query:(language:kuery,query:'uniq_id:+%22${uniqId}%22'),sort:!(!('@timestamp',desc)))`
  }

  const handleInputChange = event => {
    setUniqId(event.target.value);
  }

  const handleJobStatusByUniqIdData = () => {
    window.location.href = `/jobspikr_v3_internal/jobspikr_graphs/dedup_tracker?filter[uniq_id]=${uniqId}&filter[tab_name]=job_status_by_uniq_id&commit=${'Get Data'}`
  }

  const handleOverallDedupStatusData = () => {
    window.location.href = `/jobspikr_v3_internal/jobspikr_graphs/dedup_tracker?filter[from_date]=${moment(fromDate).format('YYYY/MM/DD')}&filter[to_date]=${moment(toDate).format('YYYY/MM/DD')}&filter[tab_name]=overall_dedup_status&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/jobspikr_v3_internal/jobspikr_graphs/dedup_tracker">Dedup Tracker</a> : 'Dedup Tracker'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
          </BreadcrumbItem>}
        </Breadcrumb>
      </Col>
    </Row>
  </div>
  <Card>
    <CardBody>
      <Nav tabs>
        <NavItem>
          <NavLink className={activeTab == 'overall_dedup_status' ? 'active' : ''} onClick={() => setActiveTab('overall_dedup_status')}>
            Overall Dedup Status & RSS counts
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={activeTab == 'job_status_by_uniq_id' ? 'active' : ''} onClick={() => setActiveTab('job_status_by_uniq_id')}>
            Job Status By Uniq Id
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={'overall_dedup_status'}>
          <div className="ibox-content m-b-sm border-bottom">
            <Row className="align-items-end ">
              <Col sm="3">
                <label className="fw-bold">From Date</label>
                <DatePicker
                  className="form-control"
                  selected={new Date(fromDate)}
                  dateFormat={"YYY/MM/dd"}
                  onChange={date => setFromDate(date)} />
              </Col>
              <Col sm="3">
                <label className="fw-bold">To Date</label>
                <DatePicker
                  className="form-control"
                  selected={new Date(toDate)}
                  dateFormat={"YYY/MM/dd"}
                  onChange={date => setToDate(date)} />
              </Col>
              <Col sm="3">
                <button className="btn btn-success w-100" onClick={handleOverallDedupStatusData}>Get Data</button>
              </Col>
            </Row>
          </div>
          <div className="panel-body">
            <center>
              <span className="fw-bold">Kibana Dashboard</span> - <a target="_blank" className="link-primary" href={overallDedupStatusKibanaUrl()}>Click here to see below graph in kibana</a>
            </center>
            <iframe src={overallDedupStatusKibanaUrl() + "&embed=true"} height="500" width="100%" className="border-0"></iframe>
            <Row className="align-items-end mb-3">
              <center><span className="fw-bold">Rss Q info</span></center><br />
              <DataTable
                columns={rssQueueInfoColumns}
                data={rssQueueInfo}
                noHeader
                pagination
                progressPending={loading}
              />
            </Row>
            <Row className="align-items-end mb-3">
              <center><span className="fw-bold">ES bulk updates pending</span></center><br />
              <DataTable
                columns={queueSizeInfoColumns}
                data={queueSizeInfo}
                noHeader
                pagination
                progressPending={loading}
              />
            </Row>
            <Row className="align-items-end">
              <center><span className="fw-bold">RSS Counts</span></center><br />
              <DataTable
                columns={siteRssCountColumns}
                data={siteRssCount}
                noHeader
                pagination
                progressPending={loading}
              />
            </Row>
          </div>
        </TabPane>
        <TabPane tabId={'job_status_by_uniq_id'}>
          <div className="ibox-content m-b-sm border-bottom">
            <Row className="align-items-end mb-3">
              <Col sm="6">
                <label className="fw-bold">Uniq Id</label>
                <input type="text" className="form-control" value={uniqId} onChange={handleInputChange}/>
              </Col>
              <Col sm="3">
                <button className="btn btn-success w-100" onClick={handleJobStatusByUniqIdData}>Get Data</button>
              </Col>
            </Row>
          </div>
          <div className="panel-body">
            {
              jobStatusData.length > 0 ? 
              <>
                <center><span className="fw-bold">Job Status</span></center><br />
                <p>Index: <b>jobspikr_v3_data-*</b></p>
                <center>
                  <a target="_blank" className="link-primary" href={jobStatusByUniqIdKibanaUrl()}>Click here to see below graph in kibana</a>
                </center>
              </>
              : ""
            }
            <Row className="align-items-end ">
              <DataTable
                columns={UniqIdColumns}
                data={jobStatusData}
                noHeader
                pagination
                progressPending={loading}
              />
            </Row>
          </div>
        </TabPane>
      </TabContent>
    </CardBody>
  </Card>
</Container>
}
