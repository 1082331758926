/*
  This Data TAble component is rendered by Index component
*/
import React from 'react';
import { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Info } from 'react-feather';
import ReactTooltip from 'react-tooltip';
export default function VolumeStatsTable({ data, progressPending }) {
  // Table column definitons
  const columns = useMemo(()=>[
    { name: 'Site Name', selector: 'site', sortable: true },
    { name: 'Rss Q size', selector: 'rss_queue_size', sortable: true },
    {
      name: 'Rss Count',
      selector: 'rss_count',
      sortable: true,
      cell: (row, index) => (
        <>
          {
            row.site.includes("social_standards_inc") && (row.rss_count > 0) ?
              <>
                <p data-tip data-for={'rss-count-' + index}>{row.rss_count} <Info size={18} className='mx-2' /></p>
                <ReactTooltip id={'rss-count-' + index} clickable multiline delayHide={1000} effect='solid'>
                  <div className="text-break">
                    <span>Expected RSS Count: {row.expected_rss_count}</span>
                  </div>
                </ReactTooltip>
              </>
              :
              <>
                <p data-tip data-for={'rss-count-' + index}>{row.rss_count}</p>
              </>
          }
        </>
      ),
      conditionalCellStyles: [
        {
          when: row => row.rss_percentage < 20 && row.site.includes("social_standards_inc"),
          style: {
            backgroundColor: '#dc7633',
            color: 'white',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
        {
          when: row => row.rss_percentage > 30 && row.site.includes("social_standards_inc"),
          style: {
            backgroundColor: '#52be80',
            color: 'white',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        }
      ],
    },
    { name: 'Feedcrawl Count', selector: 'feedcrawl_count', sortable: true },
    {
      name: 'Upload Count',
      selector: 'upload_count',
      sortable: true,
      cell: (row, index) => (
        <>
          {
            row.site.includes("social_standards_inc") && (row.upload_count > 0) ?
              <>
                <p data-tip data-for={'rss-count-' + index}>{row.upload_count} <Info size={18} className='mx-2' /></p>
                <ReactTooltip id={'rss-count-' + index} clickable multiline delayHide={1000} effect='solid'>
                  <div className="text-break">
                    <span>Expected Upload Count: {row.expected_upload_count}</span>
                  </div>
                </ReactTooltip>
              </>
              :
              <>
                <p data-tip data-for={'rss-count-' + index}>{row.upload_count}</p>
              </>
          }
        </>
      ),
      conditionalCellStyles: [
        {
          when: row => row.upload_percentage < 20 && (row.site.includes("social_standards_inc")) || (row.site.includes("jato_dynamics")),
          style: {
            backgroundColor: '#dc7633',
            color: 'white',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
        {
          when: row => row.upload_percentage > 30 && (row.site.includes("social_standards_inc")) || (row.site.includes("jato_dynamics")),
          style: {
            backgroundColor: '#52be80',
            color: 'white',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        }
      ],
    },
  ], []);

  return (
    <>
      <DataTable
        data={data}
        columns={columns}
        noHeader
        pagination
        progressPending={progressPending}
      />
    </>
  )
};
