import React, { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Card, CardBody, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import axios from "axios";
import { Home } from "react-feather";
import { useLocation, Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import moment from "moment";
import AsyncSelect from 'react-select/async';
import Select, { components } from "react-select";

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function AmazonRequestTypePerformanceTracker() {
  const search = useLocation().search
  const commit = new URLSearchParams(search).get("commit");
  const now = new Date()
  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[site_name]"),
    requestType: new URLSearchParams(search).get("filter[request_type]"),
    urlFetchStatus: new URLSearchParams(search).get("filter[amazon_url_fetch_status]"),
    fromDate: new URLSearchParams(search).get("filter[from]"),
    toDate: new URLSearchParams(search).get("filter[to]"),

  }
  const [siteName, setSiteName] = useState({ label: 'All', value: '*' });
  const [siteNameOptions, setSiteNameOptions] = useState([]);
  const [requestType, setRequestType] = useState({ label: 'All', value: '*' });
  const [urlFetchStatus, setUrlFetchStatus] = useState({ label: 'All', value: '*' });
  const [fromDate, setFromDate] = useState(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7));
  const [toDate, setToDate] = useState(now);
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [urlData, setUrlData] = useState({
    siteName: { label: 'All', value: '*' },
    requestType: { label: 'All', value: '*' },
    urlFetchStatus: { label: 'All', value: '*' },
    fromDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
    toDate: now,

  });

  useEffect(() => {
    setLoading(true);
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let site_names = res.data.site_names, site_names_options = []
        for (var key in site_names) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
          site_names[key].map(item => {
            children.push({ label: item, value: item })
          })
          group['options'] = children;
          site_names_options.push(group)
        }
        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: '*' }] })
        setSiteNameOptions(site_names_options)
        setLoading(false)
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
        setLoading(false)
      })
  }, []);

  const requestTypeOptions = [
    { label: 'scraper_api', value: 'scraper_api' },
    { label: 'open_url_with_normal_proxies', value: 'open_url_with_normal_proxies' },
    { label: 'open_url_with_microleaves_proxies', value: 'open_url_with_microleaves_proxies' },
    { label: 'All', value: '*' }
  ]

  const urlStatusOptions = [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' },
    { label: 'All', value: '*' }
  ]

  useEffect(() => {
    console.log(searchParams, 'search params')
    if (searchParams.siteName && searchParams.siteName != siteName.value) {
      setSiteName({ label: searchParams.siteName == '*' ? 'All' : searchParams.siteName, value: searchParams.siteName });
    }
    if (searchParams.requestType && searchParams.requestType != requestType.value) {
      setRequestType({ label: searchParams.requestType == '*' ? 'All' : searchParams.requestType, value: searchParams.requestType });
    }
    if (searchParams.fromDate && searchParams.fromDate != fromDate) {
      setFromDate(new Date(searchParams.fromDate))
    }
    if (searchParams.urlFetchStatus && searchParams.urlFetchStatus) {
      setUrlFetchStatus({
        label: searchParams.urlFetchStatus == '*' ? 'All' : searchParams.urlFetchStatus[0].toUpperCase() + searchParams.urlFetchStatus.slice(1),
        value: searchParams.urlFetchStatus
      })
    }
    if (searchParams.toDate && searchParams.toDate != toDate) {
      setToDate(new Date(searchParams.toDate));
    }
    if (commit == 'Get Data' && searchParams.siteName && searchParams.requestType && searchParams.fromDate && searchParams.toDate && searchParams.urlFetchStatus) {
      setUrlData({
        siteName: { label: searchParams.siteName == '*' ? 'All' : searchParams.siteName, value: searchParams.siteName },
        requestType: { label: searchParams.requestType == '*' ? 'All' : searchParams.requestType, value: searchParams.requestType },
        urlFetchStatus: {
          label: searchParams.urlFetchStatus == '*' ? 'All' : searchParams.urlFetchStatus[0].toUpperCase() + searchParams.urlFetchStatus.slice(1),
          value: searchParams.urlFetchStatus
        },
        fromDate: new Date(searchParams.fromDate),
        toDate: new Date(searchParams.toDate)
      })
    }
  }, [])


  function getDate() {
    let days = Math.abs(urlData.toDate - urlData.fromDate) / (1000 * 3600 * 24);
    days = Math.floor(days)
    return days == 0 ? 'now-24h' : `now-${days}d`
  }

  const promiseOptions = (inputValue) => {
    if(inputValue.length < 3){
      return null
    } else {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200'){
            console.log(res.data)
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
              site_names[key].map(item => {
                children.push({ label: item, value: item })
              })
              group['options'] = children;
              site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: '*' }] })
            setSiteNameOptions(site_names_options)
            resolve(site_names_options)
          }
        }).catch(err => {
          console.log(`err`, err)
        })
      })
    }
  }

  const handleSiteNameChange = (data) => {
    setSiteName(data)
  }

  const handleSubmit = () => {
    window.location.href = `/internal/amazon_request_type_performance_tracker?filter[site_name]=${siteName.value}&filter[request_type]=${requestType.value}&filter[amazon_url_fetch_status]=${urlFetchStatus.value}&filter[from]=${moment(fromDate).format('YYYY/MM/DD')}&filter[to]=${moment(toDate).format("YYYY/MM/DD")}&commit=${'Get Data'}`
  }

  function Form() {
    return <Card>
      <CardBody>
        <Row className="mb-3">
          <div className="col-sm-8">
            <label className="fw-bold">Site Name</label>
            <AsyncSelect cacheOptions defaultOptions={siteNameOptions} name='site_name' value={siteName}
              loadOptions={promiseOptions} onChange={handleSiteNameChange}
              placeholder="Select a site name"
            />
            <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
          </div>
          <div className="col-sm-4">
            <label className="fw-bold">Request Type</label>
            <Select
              value={requestType}
              options={requestTypeOptions}
              onChange={option => setRequestType(option)} />
          </div>
        </Row>
        <Row className="align-items-end">
          <div className="col-sm-3">
            <label className="fw-bold">Amazon URL Fetch Status</label>
            <Select
              value={urlFetchStatus}
              options={urlStatusOptions}
              onChange={option => setUrlFetchStatus(option)} />
          </div>
          <div className="col-sm-3">
            <label className="fw-bold">From Date</label>
            <DatePicker
              selected={new Date(fromDate)}
              onChange={date => setFromDate(date)}
              maxDate={now}
              className="form-control"
              dateFormat={"YYY/MM/dd"} />
          </div>
          <div className="col-sm-3">
            <label className="fw-bold">To Date</label>
            <DatePicker
              selected={new Date(toDate)}
              onChange={date => setToDate(date)}
              maxDate={now}
              className="form-control"
              dateFormat={"YYY/MM/dd"} />
          </div>
          <div className="col-sm-3">
            <button className="btn btn-success w-100" onClick={handleSubmit} >Get Data</button>
          </div>
        </Row>
      </CardBody>
    </Card>
  }

  return <Container fluid>
    <Container fluid>
      <div className="page-title">
        <Row className=''>
          <Col xs="6">
            <h3>Internal Dashboard</h3>
          </Col>
          <Col xs="6">
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={`/internal`}><Home /></Link>
              </BreadcrumbItem>

              <BreadcrumbItem className={commit == 'Get Data' ? '' : 'fw-bold'} >
                {commit == 'Get Data' ? <a href={'/internal/amazon_request_type_performance_tracker'}>Amazon Request Type Performance Tracker</a> : 'Amazon Request Type Performance Tracker'}
              </BreadcrumbItem>
              {commit == 'Get Data' && <BreadcrumbItem>
                <b>Search</b>
            </BreadcrumbItem>}
          </Breadcrumb>
        </Col>
      </Row>
    </div>
  </Container>
  {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> :
  <Fragment>
    <Form />
    <Card>
      <CardBody>
        <div className="mb-3">
          <b>NOTE:</b> If the graph is not shown after your form submission then you should do either maximize its size(the option is available on the right side of graph) or minimize the sidebar.
        </div>
        <Nav tabs>
          <NavItem>
            <NavLink href="#" className={activeTab == 1 ? 'active' : ''} onClick={() => setActiveTab(1)}>
              Site Wise
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#" className={activeTab == 2 ? 'active' : ''} onClick={() => setActiveTab(2)}>
              Date Wise
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1} className="mt-4">
            <div className="text-center">
              <div className="fw-bold">
                Amazon Request Type Performance Tracker By Site Wise
              </div>
              <div>
                <a href={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${getDate()},mode:quick,to:now))&_a=(filters:!(),linked:!f,query:(language:lucene,query:(query_string:(analyze_wildcard:!t,query:'custom:amazon_request_type_perfomance_tracker+AND+site_name:+${urlData.siteName.value}+AND+request_type:+${urlData.requestType.value}+AND+amazon_url_fetch_status:+${urlData.urlFetchStatus.value}'))),uiState:(),vis:(aggs:!((id:'1',params:(),schema:metric,type:count),(id:'2',params:(field:site_name.raw,order:desc,orderBy:'1',size:30),schema:segment,type:terms),(id:'3',params:(field:request_type.raw,order:desc,orderBy:'1',size:5),schema:group,type:terms),(id:'4',params:(customLabel:fetch_status,field:amazon_url_fetch_status.raw,order:desc,orderBy:'1',row:!t,size:5),schema:split,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Amazon+Request+Type+Performance+Tracker+By+Site+Wise',type:histogram))`} target="_blank" className="link-primary">Click here to see below graph in kibana</a>
              </div>
            </div>
            <iframe className="botder-0" src={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&embed=true&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${getDate()},mode:quick,to:now))&_a=(filters:!(),linked:!f,query:(language:lucene,query:(query_string:(analyze_wildcard:!t,query:'custom:amazon_request_type_perfomance_tracker+AND+site_name:+${urlData.siteName.value}+AND+request_type:+${urlData.requestType.value}+AND+amazon_url_fetch_status:+${urlData.urlFetchStatus.value}'))),uiState:(),vis:(aggs:!((id:'1',params:(),schema:metric,type:count),(id:'2',params:(field:site_name.raw,order:desc,orderBy:'1',size:30),schema:segment,type:terms),(id:'3',params:(field:request_type.raw,order:desc,orderBy:'1',size:5),schema:group,type:terms),(id:'4',params:(customLabel:fetch_status,field:amazon_url_fetch_status.raw,order:desc,orderBy:'1',row:!t,size:5),schema:split,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Amazon+Request+Type+Performance+Tracker+By+Site+Wise',type:histogram))`} height="800" width="100%"></iframe>

          </TabPane>
          <TabPane tabId={2}>
            <div className="text-center mt-4">
              <div className="fw-bold">
                Amazon Request Type Performance Tracker By Date Wise
              </div>
              <div>
                <a href={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${getDate()},mode:quick,to:now))&_a=(filters:!(),linked:!f,query:(language:lucene,query:(query_string:(analyze_wildcard:!t,query:'custom:amazon_request_type_perfomance_tracker+AND+site_name:+${urlData.siteName.value}+AND+request_type:+${urlData.requestType.value}+AND+amazon_url_fetch_status:+${urlData.urlFetchStatus.value}'))),uiState:(),vis:(aggs:!((id:'1',params:(),schema:metric,type:count),(id:'2',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:d,min_doc_count:1),schema:segment,type:date_histogram),(id:'3',params:(customLabel:fetch_status,field:amazon_url_fetch_status.raw,order:desc,orderBy:'1',row:!t,size:5),schema:split,type:terms),(id:'4',params:(customLabel:request_type,field:request_type.raw,order:desc,orderBy:'1',size:5),schema:group,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Amazon+Request+Type+Performance+Tracker+By+Date+Wise',type:histogram))`} target="_blank" className="link-primary">Click here to see below graph in kibana</a>
              </div>
            </div>
            <iframe className="border-0" src={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&embed=true&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${getDate()},mode:quick,to:now))&_a=(filters:!(),linked:!f,query:(language:lucene,query:(query_string:(analyze_wildcard:!t,query:'custom:amazon_request_type_perfomance_tracker+AND+site_name:+${urlData.siteName.value}+AND+request_type:+${urlData.requestType.value}+AND+amazon_url_fetch_status:+${urlData.urlFetchStatus.value}'))),uiState:(),vis:(aggs:!((id:'1',params:(),schema:metric,type:count),(id:'2',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:d,min_doc_count:1),schema:segment,type:date_histogram),(id:'3',params:(customLabel:fetch_status,field:amazon_url_fetch_status.raw,order:desc,orderBy:'1',row:!t,size:5),schema:split,type:terms),(id:'4',params:(customLabel:request_type,field:request_type.raw,order:desc,orderBy:'1',size:5),schema:group,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Amazon+Request+Type+Performance+Tracker+By+Date+Wise',type:histogram))`} height="800" width="100%"></iframe>

          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  </Fragment>
}
    </Container >
  }
