import React, { useState, useEffect, useMemo, Fragment } from 'react'
import { useLocation, Link } from 'react-router-dom'

import { Card, CardBody, Col, Container, Row, Breadcrumb, BreadcrumbItem, Alert } from 'reactstrap'
import DataTable from 'react-data-table-component';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { Home } from 'react-feather';
import axios from 'axios'

import SpinnerLoader from '../../../V2/Common/LoadingAnimation/SpinnerLoader.component'
import QaReportsTable from './QaReportsTable'

export default function SearchQaReports() {
  const search = useLocation().search
  const pendingQaValue = new URLSearchParams(search).get('filter[pending_qa]')
  const siteValue = new URLSearchParams(search).get('filter[site]')
  const devSiteQaValue = new URLSearchParams(search).get('filter[dev_site_qa_status]')
  const bizSiteQaValue = new URLSearchParams(search).get('filter[biz_site_qa_status]')
  const commitValue = new URLSearchParams(search).get('commit')

  const [data, setData] = useState({})
  const [dropdownOptions, setDropdownOptions] = useState({})
  const [selectedSite, setSelectedSite] = useState({ value: '', label: 'All' })
  const [loading, setLoading] = useState(false)
  const [selectedDevQaStatus, setSelectedDevQaStatus] = useState('')
  const [selectedBizQaStatus, setSelectedBizQaStatus] = useState('')
  const [tableData, setTableData] = useState([])

  const [searchValue, setSearchValue] = useState('')
  const [searchResults, setSearchResults]= useState([]);

  // THis fetches site names from backend
  useEffect(() => {
    fetchSiteNames();
    getUploadData();
  }, [])

  // Helper function to fetch and set data table data from all qa reports
  const getUploadData = () => {
    setLoading(true)
    let site_name = selectedSite.value ? selectedSite.value : (siteValue || '')
    let pendingQaValue = pendingQaValue ? (parseInt(pendingQaValue) == 1) ? "yes" : "no" : "no"
    let dev_site_qa_status = devSiteQaValue || (selectedDevQaStatus != '' ? selectedDevQaStatus : 0)
    let biz_site_qa_status = bizSiteQaValue || (selectedBizQaStatus != '' ? selectedBizQaStatus : 0)
    let url = `/api/v1/internal/new_qa_reports/search?filter[qa_pending_records]=${pendingQaValue}&filter[site_name]=${site_name}&filter[dev_site_qa_status]=${dev_site_qa_status}&filter[biz_site_qa_status]=${biz_site_qa_status}`
    axios.get(url).then(res => {
      setData(res?.data?.qa_list)
      setSearchResults(res?.data?.qa_list)
      setSelectedBizQaStatus(res?.data?.current_user_can_access_biz_qa)
      setLoading(false)
    }).catch(err => {
      toast.error(err?.response?.data?.message)
      setLoading(false)
    })
  }

  // Helper function to fetch the site names for dropdown options
  function fetchSiteNames() {
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let siteNames = res?.data?.site_names, siteNamesOptions = []
        for (var key in siteNames) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
            siteNames[key].map(item => {
              children.push({ label: item, value: item })
            })
          group['options'] = children;
          siteNamesOptions.push(group)
        }
        siteNamesOptions.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
        setDropdownOptions(siteNamesOptions)
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      })
  }

  // Helper function to fetch the site names for dropdown options based on user input
  const promiseOptions = (inputValue) => {
    if(inputValue.length < 3){
      return null
    } else {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200'){
            let siteNames = res?.data?.site_names, siteNamesOptions = []
            for (var key in siteNames) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
                siteNames[key].map(item => {
                  children.push({ label: item, value: item })
                })
              group['options'] = children;
              siteNamesOptions.push(group)
            }
            siteNamesOptions.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setDropdownOptions(siteNamesOptions)
            resolve(siteNamesOptions)
          }
        }).catch(err => {
          console.log(`err`, err)
        })
      })
    }
  }

  const onGetDataButtonClickHandler = () => {
    if (data?.current_user_can_access_biz_qa) {
      window.location.href = `/internal/new_qa_reports/search?utf8=✓&filter%5Bpending_qa%5D=${pendingQaValue}&filter%5Bsite%5D=${selectedSite.value || ''}&filter%5Bdev_site_qa_status%5D=${(selectedDevQaStatus != '' ? selectedDevQaStatus : 0)}&filter%5Bbiz_site_qa_status%5D=${(selectedBizQaStatus != '' ? selectedBizQaStatus : 0)}&commit=Get+Data`
    }
    else {
      window.location.href = `/internal/new_qa_reports/search?utf8=✓&filter%5Bpending_qa%5D=${pendingQaValue}&filter%5Bsite%5D=${selectedSite.value || ''}&filter%5Bdev_site_qa_status%5D=${(selectedDevQaStatus != '' ? selectedDevQaStatus : 0)}&commit=Get+Data`
    }
  }

  function searchFromData(value){
    var search_result = data.filter(item => item?.site_name?.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = data.filter(item => item?.dev_site_qa_status_in_text?.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = data.filter(item => item?.assignee1?.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = data.filter(item => item?.dev_comment?.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = data.filter(item => item?.biz_site_qa_status_in_text?.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = data.filter(item => item?.assignee2?.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = data.filter(item => item?.comment?.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = data.filter(item => item?.sample_data_record?.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = data.filter(item => item?.updated_at?.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
  }

  const handleSearch = event =>{
    if(event.target.value == ''){
      setSearchResults(data);
    }
    else{
      setSearchResults(searchFromData(event.target.value));
    }
    setSearchValue(event.target.value);
  }

  return (
    <Container fluid>
      <div className="page-title">
        <Row className=''>
          <Col xs="6">
            <h3>Internal Dashboard</h3>
          </Col>
          <Col xs="6">
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={`/internal`}><Home /></Link>
              </BreadcrumbItem>
              {
                (parseInt(pendingQaValue) == 0) ? 
                <BreadcrumbItem>
                  <a href={`/internal/new_qa_reports`}>All Qa Reports</a>
                </BreadcrumbItem>
                  :
                  <BreadcrumbItem>
                    <a href={`/internal/new_qa_reports/pending`}>List of Sites Pending for QA</a>
                  </BreadcrumbItem>
              }
              <BreadcrumbItem>
                <b> Search </b>
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
      </div>

      <SpinnerLoader loading={loading}>
        <Card className='my-3'>
          <CardBody>
            <Row className='my-3'>
              <Col md={12}>
                <h6 className='fw-bold'>Site Name: </h6>
                <AsyncSelect
                  onChange={res => setSelectedSite(res)}
                  defaultOptions={dropdownOptions}
                  placeholder="Select a site name"
                  loadOptions={promiseOptions}
                  value={selectedSite}
                  name='site_name'
                  cacheOptions
                />
                <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
              </Col>
            </Row>
            <Row className='my-3'>
              <Col md={4}>
                <h6 className='fw-bold'>DevQA Status</h6>
                <select className='form-control' value={selectedDevQaStatus}
                  onChange={(e) => setSelectedDevQaStatus(e.target.value)}>
                  <option value=''></option>
                  <option value='0'>Does not look good!</option>
                  <option value='1'>All Fine</option>
                </select>
              </Col>
              <Col md={4}>
                <h6 className='fw-bold'>BizQA Status</h6>
                <select className='form-control' value={selectedBizQaStatus}
                  disabled={!(data?.current_user_can_access_biz_qa)}
                  onChange={(e) => setSelectedBizQaStatus(e.target.value)}>
                  <option value=''></option>
                  <option value='0'>Does not look good!</option>
                  <option value='1'>All Fine</option>
                </select>
              </Col>
              <Col md={4}>
                <button
                  className='btn btn-success'
                  style={{ width: '100%', marginTop: 27 }}
                  onClick={() => onGetDataButtonClickHandler()}> Get Data </button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            {
              searchResults?.length > 0 ?
              <>
                <Row className='mb-2'>
                  <Col md={7} className='my-3'></Col>
                  <Col md={5} className='my-3'>
                    <div className='d-flex justify-content-center align-items-center'>
                      <h6 className='my-0 me-2'>Search:</h6>
                      <input type="text" className="form-control" value={searchValue} onChange={handleSearch} />
                    </div>
                  </Col>
                </Row>
                <QaReportsTable data={searchResults} />
              </>
                :
                <center>Sorry! No records found!</center>
            }
          </CardBody>
        </Card>
      </SpinnerLoader >
    </Container >
  )
}
