import React, { Fragment } from 'react'
import { XCircle } from 'react-feather';

export function DataNotFoundAlerts(props) {
  if (!props?.display) {
    return (
      <Fragment>
        <div className='d-flex flex-column justify-content-center align-items-center align-content center p-4'>
          <div>
            <XCircle size={44} color='red' strokeWidth={2.5} />
          </div>
          <div>
            <p className='h5'>{props?.alertMessage}</p>
          </div>
        </div>
      </Fragment>
    )
  }
  else {
    return (
      <Fragment>{props?.children}</Fragment>
    )
  }
}
