import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { Edit } from 'react-feather';
import axios from 'axios';
import SpinnerBotton from '../Botton/SpinnerBotton.component';

const OrgChangeModal = ({ onButtonClickedHandler, onOptionSelectHandler, isOpen, toggle, orgName, userId,  }) => {
    const [orgList, setOrgList] = useState([]);
    const [currentOrganization, setCurrentOrganization] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(async()=>{
        const cancelToken = axios.CancelToken.source();
        // if user id is not present then axios call will not be made
        if(userId === 0){
            return;
        }

        try{
            const org_list = (await axios.get(`/api/v1/default_org?id=${userId}`,{cancelToken:cancelToken.token,})).data.org_list;
            const currentOrg = (await axios.get(`/api/v1/profiles/${userId}`,{cancelToken:cancelToken.token,})).data.profile.organization_name;
            setOrgList(org_list);
            setCurrentOrganization(currentOrg);

        }
        catch(error){             
            console.log(error.message);
        } 
        // Cleanup function for when the component is unmounted
        return()=>{
            cancelToken.cancel();
        }
    },[userId]);


    const onSubmitButtonClick = () => {
        
        setDisabled(true);
        setLoading(true);
        onButtonClickedHandler();
        setDisabled(false);
        setLoading(false);

        
    }


    return (
        <>
            <div className='modal-index-container'>
                <span className='org-link'><a onClick={toggle}>Current Organization:{orgName}<Edit className='edit-icon' /></a></span>
                <div>
                    <Modal funk={"true"} isOpen={isOpen} toggle={toggle} size='lg'>
                        <ModalHeader toggle={toggle}>Change Current Organization: {currentOrganization}</ModalHeader>
                        <ModalBody>
                            <Form inline>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0 form-container">
                                    <Label for="exampleSelect" className='select-org-label'>Select Current Organization</Label>
                                    <Input type="select" name="select" className="exampleSelect form-select" onChange={onOptionSelectHandler}>
                                        <option value={""} >select organization</option>
                                        {
                                            orgList.map((data)=>(
                                                <option key={data.identifier} value={data.id}>
                                                    {data.name}
                                                </option>
                                            )) 
                                        }
                                    </Input>
                                </FormGroup>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="light" onClick={()=>{toggle(); setLoading(false);}}>Cancel</Button>{' '}
                            <SpinnerBotton 
                                children="Change Current Organization" 
                                loading={loading} 
                                disabled={disabled}
                                color='success'
                                onClick={onSubmitButtonClick}  
                            />{' '}
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default OrgChangeModal;
