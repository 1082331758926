import React, { useEffect, useState } from "react";
import { Row, Col} from "reactstrap";
import DataTable from "react-data-table-component";
import { DownloadCloud } from "react-feather";

export default function SiteWiseProxyStats({site_wise_proxy_stats_data, weekNumber, downloadCSVFile}) {

  const [tableData, setTableData] = useState(site_wise_proxy_stats_data);
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults]= useState(site_wise_proxy_stats_data);
  const [loading, setLoading] = useState(false);

  const tableColumns = [
    { name: 'Domain', selector: 'domain', sortable: true },
    { name: 'Proxy Source', selector: 'proxy_source', sortable: true },
    { name: 'Success(#)', selector: 'success', sortable: true},
    { name: 'Failed(#)', selector: 'failed', sortable: true},
    { name: 'Retries(#)', selector: 'retries', sortable: true},
    { name: 'Total(#)', selector: 'total', sortable: true},
    { name: 'Success Traffic(B)', selector: 'success_traffic', sortable: true},
    { name: 'Failed Traffic(B)', selector: 'failed_traffic', sortable: true},
    { name: 'CPM($)', selector: 'cpm', sortable: true},
    { name: 'Total Cost($)', selector: 'total_cost', sortable: true},
    { name: 'Site', selector: 'site', sortable: true},
    { name: 'Project', selector: 'project', sortable: true},
    { name: 'Client', selector: 'client', sortable: true},
  ]

  const handleSearch = event =>{
    if(event.target.value == ''){
      setSearchResults(tableData);
    }
    else{
      setSearchResults(searchFromData(event.target.value));
    }
    setSearchText(event.target.value);
  }

  function searchFromData(value){
    var search_result = tableData.filter(item => item.domain.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.proxy_source.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.success.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.failed.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.retries.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.total.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.success_traffic.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.failed_traffic.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.cpm.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.total_cost.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.site.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.project.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.client.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
  }

  return (
    <>
      <center>
        <h5 className="mb-2">Site wise proxy stats</h5>
      </center>
      {
        site_wise_proxy_stats_data.length > 0 && (
          <div className="position-absolute top-0 end-0">
            <button 
            className="btn btn-primary btn-sm mw-25 mh-25 float-right mx-3 my-3 d-inline"
            onClick={()=>downloadCSVFile(weekNumber, 'site_wise_proxy_stats')}>
            <span>Download CSV  <DownloadCloud style={{verticalAlign:"middle", width:"20px", height:"20px"}}/></span>
            </button>
          </div>
        )
      }
      <Row className="align-items-end mt-2">
        <Col>
          <div className="float-end mb-3">
            <Row className="align-items-center">
              <Col sm="3">Search</Col>
              <Col>
                <input type="text" className="form-control mx-1 my-3" value={searchText} onChange={handleSearch} />
              </Col>
            </Row>
          </div>
        </Col>
        <DataTable
          columns={tableColumns}
          data={searchResults}
          noHeader
          pagination
          progressPending={loading}
        />
      </Row>
    </>
  )
}
