import React from 'react';
import { Info } from 'react-feather';
import { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Card, CardBody } from 'reactstrap';
import TestSitesStatsTable from '../DataTables/TestSitesStatsTable.component';

export default function DataTableCard({ data }) {
  const [tableData, setTableData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (data?.data) {
      let formattedTableData = data?.data;
      formattedTableData.push({
        site: '', queue_size: '', date: <b>Total: </b>,
        rss_count: <b>{data?.total_rss_count}</b>,
        feed_crawled_urls_count: <b>{data?.total_feed_crawled_urls_count}</b>,
        segment_extr_count: <b>{data?.total_segment_extr_count}</b>,
        segment_dedup_count: <b>{data?.total_segment_dedup_count}</b>,
        segment_normalize_count: <b>{data?.total_segment_normalize_count}</b>,
        segment_upload_count: <b>{data?.total_segment_upload_count}</b>,
        kibana: '',
      })
      setTableData(formattedTableData);
      setSearchResults(formattedTableData);
    }
  }, [data]);

  // useEffect for when the search value changes 
  useEffect(() => {
    if (searchValue){
      let res = []
      tableData.forEach(each => {
        if (each?.site?.includes(searchValue) || each?.date?.toString()?.includes(searchValue)){
          res.push(each)
        }
      })
      setSearchResults(res)
    } else {
      setSearchResults(tableData)
    }
  }, [searchValue]);

  // Helper function to search entries in table
  function onSiteSearchHandler(siteName) {
    setSearchValue(siteName);
  }

  console.log(searchResults);

  return (
    <div>
      <Card>
        <CardBody>
          <h5>Note:</h5>
          <h6> <Info size={13} /> Indicates hover is there. It has some informations or links  </h6>
          <Row className='mb-2'>
            <Col md={7}></Col>
            <Col md={5}>
              <div className='d-flex justify-content-center align-items-center'>
                <h6 className='my-0 me-2'>Search:</h6>
                <input type='text'
                  className='form-control'
                  value={searchValue}
                  onChange={(event) => onSiteSearchHandler(event.target.value)} />
              </div>
            </Col>
          </Row>
          <TestSitesStatsTable
          data={searchResults.length!== 0?searchResults:tableData} />
        </CardBody>
      </Card>
    </div>
  )
}
