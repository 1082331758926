import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";
import moment from "moment";
import axios from "axios";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";

export default function AvgTimeStatsTablePerPipelineStage() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const searchParams = {
    geoCluster: new URLSearchParams(search).get("filter[geo_cluster]"),
    avgTimeType: new URLSearchParams(search).get("filter[avg_time_type]"),
    diskfetcherType: new URLSearchParams(search).get("filter[diskfetcher_type]"),
    fromDate: new URLSearchParams(search).get("filter[from]"),
    toDate: new URLSearchParams(search).get("filter[to]"),
  }
  const [geoCluster, setGeoCluster] = useState({label: 'All', value: '*'});
  const [diskfetcherType, setDiskfetcherType] = useState({label: 'All', value: '*'});
  const [avgTimeType, setAvgTimeType] = useState({ label: 'All', value: '*' });
  const [geoClusterOptions, setGeoClusterOptions] = useState([]);
  const [avgNormalizedTimeData, setAvgNormalizedTimeData] = useState([]);
  const [avgTimeDurationData, setAvgTimeDurationData] = useState([]);
  const now = new Date();
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(3, 'days')));
  const [toDate, setToDate] = useState(now);
  const [loading, setLoading] = useState(false);

  const diskfetcherTypeOptions = [
    { label: 'All', value: '*' },
    { label: 'Get', value: 'get' },
    { label: 'Post', value: 'post' },
    { label: 'Selenium', value: 'selenium' },
    { label: 'Open-uri', value: 'open_uri' }
  ]

  const avgTimeTypeOptions = [
    { label: 'All', value: '*' },
    { label: 'Average Time Duration', value: 'avg_time_duration' },
    { label: 'Average Normalized Time', value: 'avg_normalized_time' },
  ];

  const tableColumns = [
    { name: 'Site Name', selector: 'site_name', sortable: true },
    { name: 'Rss', selector: 'rss_avg_time', sortable: true },
    { name: 'Feedcrawl', selector: 'feedcrawl_avg_time', sortable: true },
    { name: 'Extraction', selector: 'extraction_avg_time', sortable: true },
    { name: 'Dedup', selector: 'dedup_avg_time', sortable: true },
    { name: 'Normalization', selector: 'normalization_avg_time', sortable: true },
    { name: 'Max Pop Urls Count', selector: 'max_pop_urls_count', sortable: true },
  ]

  const [UrlParams, setUrlParams] = useState({
    geoCluster: "*",
    avgTimeType: '*',
    diskfetcherType: '*',
    fromDate: new Date(moment().subtract(3, 'days')),
    toDate: now
  })

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let geo_clusters = []
        res.data.geo_clusters.forEach(item => {
          geo_clusters.push({ label: item, value: item })
        })
        geo_clusters.push({ label: 'All', value: '*' })
        setGeoClusterOptions(geo_clusters);
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      });
  }, []);

  function humanize(str) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.geoCluster && searchParams.geoCluster != geoCluster.value) {
      setGeoCluster({ label: searchParams.geoCluster  == '*' ? 'All' :  searchParams.geoCluster, value: searchParams.geoCluster });
    }
    if (searchParams.diskfetcherType && searchParams.diskfetcherType != diskfetcherType.value) {
      setDiskfetcherType({ label: searchParams.diskfetcherType  == '*' ? 'All' :  searchParams.diskfetcherType, value: searchParams.diskfetcherType });
    }
    if (searchParams.avgTimeType && searchParams.avgTimeType != avgTimeType.value) {
      setAvgTimeType({ label: searchParams.avgTimeType  == '*' ? 'All' :  humanize(searchParams.avgTimeType), value: searchParams.avgTimeType });
    }
    if (searchParams.fromDate && searchParams.fromDate != fromDate) {
      setFromDate(new Date(searchParams.fromDate));
    }
    if (searchParams.toDate && searchParams.toDate != toDate) {
      setToDate(new Date(searchParams.toDate));
    }
    if (commit == 'Get Data') {
      setUrlParams(searchParams)
      fetchDataWithParams(searchParams.geoCluster, searchParams.diskfetcherType, searchParams.avgTimeType, searchParams.fromDate, searchParams.toDate);
    }
    else {
      fetchData();
    }
  }

  function fetchDataWithParams(geo_cluster = geoCluster, diskfetcher_type = diskfetcherType, avg_time_type = avgTimeType, from_date = fromDate, to_date = toDate) {
    setLoading(true)
    axios.get(`/api/v1/internal/overall_system_reports/avg_time_stats_table_per_pipeline_stage?filter[geo_cluster]=${geo_cluster}&filter[diskfetcher_type]=${diskfetcher_type}&filter[avg_time_type]=${avg_time_type}&filter[from]=${moment(from_date).format('YYYY/MM/DD')}&filter[to]=${moment(to_date).format('YYYY/MM/DD')}`)
      .then(res => {
        setAvgNormalizedTimeData(res.data.avg_normalized_time_data);
        setAvgTimeDurationData(res.data.avg_time_duration_data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  function fetchData() {
    setLoading(true)
    axios.get(`/api/v1/internal/overall_system_reports/avg_time_stats_table_per_pipeline_stage`)
      .then(res => {
        setAvgNormalizedTimeData(res.data.avg_normalized_time_data);
        setAvgTimeDurationData(res.data.avg_time_duration_data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  const handleGetData = () => {
    window.location.href = `/internal/overall_system_reports/avg_time_stats_table_per_pipeline_stage?filter[geo_cluster]=${geoCluster.value}&filter[diskfetcher_type]=${diskfetcherType.value}&filter[avg_time_type]=${avgTimeType.value}&filter[from]=${moment(fromDate).format('YYYY/MM/DD')}&filter[to]=${moment(toDate).format('YYYY/MM/DD')}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/overall_system_reports/avg_time_stats_table_per_pipeline_stage">Average Time Stats Table Per Pipeline Stage</a> : 'Average Time Stats Table Per Pipeline Stage'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
          </BreadcrumbItem>}
        </Breadcrumb>
      </Col>
    </Row>
  </div>

  <Card>
    <CardBody>
      <div className="mb-1">
        <center>
          NOTE:
          <b>The average time shown here is normalized to 2000 urls from RSS queue</b>
        </center>
      </div>
      <Row>
        <Col sm="4">
          <label className="fw-bold">From Date</label>
          <DatePicker
            className="form-control"
            selected={new Date(fromDate)}
            dateFormat={"YYY/MM/dd"}
            onChange={date => setFromDate(date)} />
        </Col>
        <Col sm="4">
          <label className="fw-bold">To Date</label>
          <DatePicker
            className="form-control"
            selected={new Date(toDate)}
            dateFormat={"YYY/MM/dd"}
            onChange={date => setToDate(date)} />
        </Col>
        <Col sm="4">
          <label className="fw-bold">Average Time Type</label>
          <Select
            value={avgTimeType}
            options={avgTimeTypeOptions}
            onChange={option => setAvgTimeType(option)} />
        </Col>
      </Row>
      <Row className="align-items-end mt-2">
        <Col sm="4">
          <label className="fw-bold">Geo Cluster</label>
          <Select
            value={geoCluster}
            options={geoClusterOptions}
            onChange={option => setGeoCluster(option)} />
        </Col>
        <Col sm="4">
          <label className="fw-bold">Diskfetcher Type</label>
          <i>(This filter use only for feedcrawl )</i>
          <Select
            value={diskfetcherType}
            options={diskfetcherTypeOptions}
            onChange={option => setDiskfetcherType(option)} />
        </Col>
        <Col sm="4">
          <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
        </Col>
      </Row>
    </CardBody>
  </Card>
  <Card>
    <CardBody>
      {UrlParams.avgTimeType == "avg_normalized_time" && (
        <>
          <center><h5 className="mb-2">Average Normalized Time</h5></center>
          <Row className="align-items-end mt-2">
            <DataTable
              columns={tableColumns}
              data={avgNormalizedTimeData}
              noHeader
              pagination
              progressPending={loading}
            />
          </Row>
        </>
      )}
      {UrlParams.avgTimeType == "avg_time_duration" && (
        <>
          <center><h5 className="mb-2">Average Time Duration</h5></center>
          <Row className="align-items-end mt-2">
            <DataTable
              columns={tableColumns}
              data={avgTimeDurationData}
              noHeader
              pagination
              progressPending={loading}
            />
          </Row>
        </>
      )}
  {UrlParams.avgTimeType == "*" && (
    <>
      <center><h5 className="mb-2">Average Time Duration</h5></center>
      <Row className="align-items-end mt-2">
        <DataTable
          columns={tableColumns}
          data={avgTimeDurationData}
          noHeader
          pagination
          progressPending={loading}
        />
      </Row>
      <center><h5 className="mb-2">Average Normalized Time</h5></center>
      <Row className="align-items-end mt-2">
        <DataTable
          columns={tableColumns}
          data={avgNormalizedTimeData}
          noHeader
          pagination
          progressPending={loading}
        />
      </Row>
    </>
  )}
</CardBody>
  </Card>
</Container>
}

