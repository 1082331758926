import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from "react-router-dom";

import StepZilla from "react-stepzilla";
import { toast } from 'react-toastify';
import { Card, Container } from 'reactstrap';
import axios from 'axios'

import SchemaDetails from './Stepzilla/Steps/SchemaDetails.component';
import SiteDetails from './Stepzilla/Steps/SiteDetails.component';
import Breadcrumbs from '../../Common/BreadCrumb/BreadCrumbs.component';
import Loader from "react-loader-spinner";

export default function EditFeasibilityCheck() {

  const [data, setData] = useState({});
  const [globalTableData, setGlobalTableData] = useState({});
  const [v2Data, setV2Data] = useState({});
  const [loading, setLoading] = useState(true);
  const [dataToggle, setDataToggle] = useState(true);
  const [dataToggle2, setDataToggle2] = useState(false);

  let history = useHistory();
  let { feasid } = useParams();

  useEffect(() => {
    setLoading(true)
    axios.get(`/api/v1/admins/feasibility_checks/${feasid}`).then(res => {
      if (!(res.data.feasibility_report?.feasibility_report_details?.can_edit)){
        history.push(`/admins/feasibility_checks/${res.data.feasibility_report.id}`)
      }
      setLoading(false)
    }).catch(err => {
      toast.error(err.response.data.message)
      setLoading(false)
    })
  }, [dataToggle])

  useEffect(() => {
    axios.get(`/api/v1/admins/feasibility_checks/${feasid}/edit?feasibility_report_id=${feasid}`).then(res => {
      setData(res.data.feasibility_report)
    }).catch(err => {
      toast.error(err.response.data.message)
    })
  }, [dataToggle, dataToggle2])

  const steps =
    [
      {
        name: 'Schema Details',
        component: <SchemaDetails
          data={data}
          v2Data={v2Data}
          setV2Data={setV2Data}
          dataDisable={true}
          setDataToggle={setDataToggle}
          globalTableData={globalTableData}
          dataToggle={dataToggle}
          setGlobalTableData={setGlobalTableData} />
      },
      {
        name: 'Site Details', component: <SiteDetails
          data={data}
          setDataToggle={setDataToggle}
          dataToggle={dataToggle}
          setDataToggle2={setDataToggle2}
          v2Data={v2Data}
          setV2Data={setV2Data}
          dataDisable={true}
          globalTableData={globalTableData}
          setGlobalTableData={setGlobalTableData} />
      },
    ]
  return (
    <div>
      <Breadcrumbs parent={"Feasibility Check"} title={feasid}
        childrenObj={[{ name: 'Feasibility Checks', url: '/admins/feasibility_checks' }]} />

      {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> :
      <div>
        <Card className='p-4'>
          <StepZilla 
            steps={steps} 
            showSteps={true}
            showNavigation={true} 
            stepsNavigation={true}
            prevBtnOnLastStep={true} 
            dontValidate={false} />
        </Card>
    </div>}
  </div>
  )
}
