import React from 'react';
import axios from 'axios';
import { Download } from 'react-feather';
import { Card, CardBody } from 'reactstrap';
import { useParams } from 'react-router-dom';
import {toast} from 'react-toastify'
import DataTable from 'react-data-table-component';

// Helper function to convert string to capital case
export function ToCapitalCase(str) {
  const first_letter = str.split("_")[0][0].toUpperCase();
  const remaining = str.split("_").join(" ").substring(1)
  return first_letter + remaining
};


export default function ClientFeasibilityReportsTable({ tableData, onSearch }) {

  const {organization_name} = useParams();

  // Helper function to downlaod feasibility report
  function downloadReport(reportID) {
    axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem("authToken")}`;
    axios.defaults.headers["Content-Type"] = `application/pdf`;
    axios.defaults.headers["Accept"] = `application/pdf`;
    axios({
      url: `/api/v1/organizations/${organization_name}/feasibility_reports/${reportID}/download_as_pdf`,
      method: 'GET',
      responseType: 'blob'
    }).then((res) => {
        const url_to_download = window.URL.createObjectURL(new Blob([res.data]));
        const a_tag = document.createElement('a');
        a_tag.href = url_to_download;
        a_tag.setAttribute('download', `FeasibilityReport-${reportID}.pdf`);
        document.body.appendChild(a_tag);
        a_tag.click();
      }).catch((error) =>{toast.error(error?.message)});
    axios.defaults.headers["Content-Type"] = `application/json`;
    axios.defaults.headers["Accept"] = `application/json`;
  }

  const columns = [
    {
      name: 'ID',
      selector: row => (
        row?.id ? row?.id : "N/A"
      ),
      sortable: true
    },
    {
      name: 'Sitegroup',
      selector: row => (
        row?.sitegroup_name ? <a href={`/organizations/${organization_name}/feasibility_reports/${row.id}`}>{row?.sitegroup_name}</a> : "N/A"
      ),
      sortable: true,
      center: true,
    },
    {
      name: 'No of sites',
      selector: row => (
        row?.no_of_sites ? row?.no_of_sites : "N/A"
      ),
      center:true,
      sortable: true
    },
    {
      name: 'Status',
      selector: row => (
        row?.feasibility_check_status ? ToCapitalCase(row?.feasibility_check_status) : "N/A"
      ),
      sortable: true
    },
    {
      name: 'Added By',
      selector: row => (
        row?.added_by ? row?.added_by : "N/A"
      ),
      sortable: true
    },
    {
      name: 'Download Documents',
      selector: row => {
        return <button className='btn btn-xs btn-light' onClick={()=>downloadReport(row?.id)}><Download/></button>
      },
      center:true,
    },
  ];

  return (
    <>
      <Card>
        <CardBody className='pt-5'>
          <div className='row'>
            <div className='col-md-7'></div>
            <div className='col-md-5'>
              <div className='form-group my-3 d-flex align-items-center'>
                <label className='m-0 pe-2'>Search: </label> {"  "}
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter sitegroup name...'
                  onChange={(event) => onSearch(event)} />
              </div>
            </div>
          </div>
          <DataTable
            data={tableData}
            columns={columns}
            responsive
            pagination
            noHeader />
        </CardBody>
      </Card>
    </>
  )
}
