import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { SitesContext } from '../Context/SitesState';
import {Modal, Button, ModalBody, ModalHeader, ModalFooter, FormGroup, Input} from 'reactstrap';
import SpinnerButton from '../../../Common/SpinnerButton/SpinnerButton.component';
import axios from 'axios';
import {toast} from 'react-toastify';

export default function SetCrawlNotifications() {
  const {organization_name} = useParams();
  const {siteGroupNames,tableSelection} = useContext(SitesContext);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState('all');
  const [crawlNotifications, setCrawlNotifications] = useState({start: false, completion: false});

  // use effect hook for everytime the modal is loaded
  useEffect(() => {
    if (tableSelection.length > 0) {
      setSelectedOptions("selected_sites");
    }
    else {
      setSelectedOptions("all");
    }
  }, [tableSelection])


  // Function to open and close modal
  function openCloseModal() {
    setModalOpen(!modalOpen);
  }

  const postCrawlNotifications = () => {
    setLoading(true)
    if (selectedOptions === 'selected_sites'){
      axios.post(`/api/v1/organizations/${organization_name}/website_notification_preferences`, {
        website_notification_preference: {
          selected_sites: tableSelection, request_from: 'site_list', site_options: 'selected_sites', 
          crawl_start_notification_preference: crawlNotifications.start ? 'notify_crawl_start' : 'dont_notify_crawl_start',
          crawl_end_notification_preference: crawlNotifications.completion ? 'notify_crawl_end' : 'dont_notify_crawl_end'
        }
      }).then(res => {
        toast.success(res?.data?.message)
        setModalOpen(false)
        setLoading(false)
        window.location.reload()
      }).catch(err => {
        toast.error(err?.response?.data.message)
        setLoading(false)
      })
    }
    else if (selectedOptions === 'all'){
      axios.post(`/api/v1/organizations/${organization_name}/website_notification_preferences`, {
        website_notification_preference: {
          request_from: 'site_list', site_options: 'all_sites', 
          crawl_start_notification_preference: crawlNotifications.start ? 'notify_crawl_start' : 'dont_notify_crawl_start',
          crawl_end_notification_preference: crawlNotifications.completion ? 'notify_crawl_end' : 'dont_notify_crawl_end'
        }
      }).then(res => {
        toast.success(res?.data?.message)
        setModalOpen(false)
        setLoading(false)
        window.location.reload()
      }).catch(err => {
        toast.error(err?.response?.data?.message)
        setLoading(false)
      })
    }
    else{
      axios.post(`/api/v1/organizations/${organization_name}/website_notification_preferences`, {
        website_notification_preference: {
          request_from: 'site_list', site_options: selectedOptions, 
          crawl_start_notification_preference: crawlNotifications.start ? 'notify_crawl_start' : 'dont_notify_crawl_start',
          crawl_end_notification_preference: crawlNotifications.completion ? 'notify_crawl_end' : 'dont_notify_crawl_end'
        }
      }).then(res => {
        toast.success(res?.data?.message)
        setModalOpen(false)
        setLoading(false)
        window.location.reload()
      }).catch(err => {
        setLoading(false)
        toast.error(err?.response?.data?.message)
      })
    }
  }

  return (
    <div>
      <Button className='mx-2' color='primary' onClick={()=>openCloseModal()}>Set Crawl Notifications</Button>
      <Modal funk={"true"} isOpen={modalOpen} toggle={()=>openCloseModal()} backdrop='static' size='lg'>
        <ModalHeader toggle={()=>openCloseModal()}>
          Schedule Crawl Notifications
        </ModalHeader>
        <ModalBody className='p-5'>
          <div>
            <div className='my-3 d-flex align-center'>
              <h6 className='me-2 fw-bold'>
                Sites:
              </h6>
              <select className='form-control show-arrow' value={selectedOptions}
                onChange={(e) => setSelectedOptions(e.target.value)}>
                <option value="selected_sites" disabled={tableSelection?.length === 0}>
                  Selected sites
                </option>
                <option value='all'>All options</option>
                {siteGroupNames.map(each => (
                  <option key={each[0]} value={each[1]}>
                    Active sites of {each[1]}
                  </option>
                ))}
              </select>
            </div>
            <div className='my-5'>
              <FormGroup check className='my-3'>
                <Input type="checkbox" name='start'
                  checked={crawlNotifications.start}
                  onChange={(e) => setCrawlNotifications({ ...crawlNotifications, [e.target.name]: e.target.checked })} />
                {' '}
                <h6>Crawl Start Notification</h6>
              </FormGroup>
              <FormGroup check className='my-3'>
                <Input type="checkbox" name='completion'
                  checked={crawlNotifications.completion}
                  onChange={(e) => setCrawlNotifications({ ...crawlNotifications, [e.target.name]: e.target.checked })} />
                {' '}
                <h6>Crawl Completion Notification</h6>
              </FormGroup>
            </div>
            <a href={`/organizations/${organization_name}/sites/crawl_notification_preferences`}
              className='text-primary mt-4 mb-2'>
              Edit Crawl Notification Preferences
            </a>
          </div>
        </ModalBody>
        <ModalFooter>
          <SpinnerButton color='primary' loading={loading} disabled={loading} onClick={() => postCrawlNotifications()}>
            Save Preference
          </SpinnerButton>
        </ModalFooter>
      </Modal>
    </div>
  )
}
