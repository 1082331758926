import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useLocation, Link } from "react-router-dom";
import Select from "react-select";
import { Home } from "react-feather";
import moment from "moment";
import DatePicker from "react-datepicker";

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function EsStoreConnectionTracker() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const tabNameFromSearchUrl = new URLSearchParams(search).get("filter[tab_name]");
  const tabNameFromUrl = window.location.hash.substr(1);
  const searchParams = {
    fromDate: new URLSearchParams(search).get("filter[from_date]"),
    toDate: new URLSearchParams(search).get("filter[to_date]"),
    tabName: tabNameFromSearchUrl ? tabNameFromSearchUrl : tabNameFromUrl
  }
  const now = new Date(moment().subtract(5.5, 'hours'))
  const [activeTab, setActiveTab] = useState('es_store_connections_tracker');
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(9.5, 'hours')));
  const [toDate, setToDate] = useState(now);

  const [UrlParams, setUrlParams] = useState({
    fromDate: new Date(moment().subtract(9.5, 'hours')),
    toDate: now,
    tabName: "es_store_connections_tracker"
  })

  useEffect(() => {
    setSearchParamsData();
  }, []);
  

  function setSearchParamsData() {
    if (searchParams.fromDate && searchParams.fromDate != fromDate) {
      setFromDate(new Date(searchParams.fromDate));
    }
    if (searchParams.toDate && searchParams.toDate != toDate) {
      setToDate(new Date(searchParams.toDate));
    }
    if (searchParams.tabName && searchParams.tabName != activeTab) {
      setActiveTab(searchParams.tabName);
    }
    if (commit == 'Get Data') {
      setUrlParams(searchParams)
    }
  }

  function esStoreConnectionsTrackerKibanaUrl(){
    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${moment(UrlParams.fromDate).format('YYYY-MM-DDTHH:mm:ss')}',mode:absolute,to:'${moment(UrlParams.toDate).format('YYYY-MM-DDTHH:mm:ss')}'))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'custom:%22es_connection_create_tracker%22')),uiState:(),vis:(aggs:!((id:'1',params:(),schema:metric,type:count),(id:'2',params:(field:es_store_name.raw,order:desc,orderBy:'1',size:20),schema:segment,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'New%20Visualization',type:histogram))`
  }

  function concurrentNumberOfConnectionsKibanaUrl(){
    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=line&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${moment(UrlParams.fromDate).format('YYYY-MM-DDTHH:mm:ss')}',mode:absolute,to:'${moment(UrlParams.toDate).format('YYYY-MM-DDTHH:mm:ss')}'))&_a=(filters:!(),linked:!f,query:(language:lucene,query:(query_string:(analyze_wildcard:!t,query:'custom:%22es_connection_create_tracker%22'))),uiState:(),vis:(aggs:!((id:'1',params:(),schema:metric,type:count),(id:'2',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:auto,min_doc_count:1),schema:segment,type:date_histogram)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:'New+Visualization',type:line))`
  }

  const handleGetData = () => {
    window.location.href = `/internal/es_stores/es_store_connections_tracker?filter[from_date]=${moment(fromDate).format('YYYY-MM-DD HH:mm')}&filter[to_date]=${moment(toDate).format('YYYY-MM-DD HH:mm')}&filter[tab_name]=${activeTab}&commit=${'Get Data'}`
  }

  return ( 
    <Container fluid>
      <div className="page-title">
        <Row className=''>
          <Col xs="6">
            <h3>Internal Dashboard</h3>
          </Col>
          <Col xs="6">
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={'/internal'}>
                  <Home />
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                {commit == 'Get Data' ? <a href={'/internal/es_stores/es_store_connections_tracker'}>ES Store Connections Tracker</a> : 'ES Store Connections Tracker'}
            </BreadcrumbItem>
              {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">Search Results</BreadcrumbItem>}
          </Breadcrumb>
        </Col>
      </Row>
    </div>
      <Card>
        <CardBody>
          <Row className="align-items-end mt-2">
            <Col sm="3">
              <label className="fw-bold">From Date</label>
              <DatePicker
                className="form-control"
                selected={new Date(fromDate)}
                showTimeSelect
                timeIntervals={15}
                dateFormat={"YYY-MM-dd HH:mm"}
                onChange={date => setFromDate(date)} />
            </Col>
            <Col sm="3">
              <label className="fw-bold">To Date</label>
              <DatePicker
                className="form-control"
                selected={new Date(toDate)}
                showTimeSelect
                timeIntervals={15}
                dateFormat={"YYY-MM-dd HH:mm"}
                onChange={date => setToDate(date)} />
            </Col>
            <Col sm="4">
              <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <div className="mb-3">
            Note: The counts shown in this graph are 20% of the total number of connections.
          </div>
          <div className="mb-1">
            <b>NOTE: </b>
            If the graph is not shown after your form submission then you should do either maximize its size(the option is available on the right side of graph) or minimize the sidebar.
          </div>
          <Nav tabs>
            <NavItem>
              <NavLink className={activeTab == 'es_store_connections_tracker' ? 'active' : ''} onClick={() => setActiveTab('es_store_connections_tracker')} >
                ES Store Connections Tracker
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={activeTab == 'concurrent_number_of_connections' ? 'active' : ''} onClick={() => setActiveTab('concurrent_number_of_connections')} >
                Concurrent Number Of Connections
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={'es_store_connections_tracker'}>
              <center className="mt-3">
                <span className="fw-bold pb-1">ES Store Connections Tracker</span><br />
                <a target="_blank" className="link-primary" href={esStoreConnectionsTrackerKibanaUrl()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={esStoreConnectionsTrackerKibanaUrl() + "&embed=true"} height="1000" width="100%" className="border-0"></iframe>
            </TabPane>
            <TabPane tabId={'concurrent_number_of_connections'}>
              <center className="mt-3">
                <span className="fw-bold">Concurrent Number Of Connections</span><br />
                <a target="_blank" className="link-primary" href={concurrentNumberOfConnectionsKibanaUrl()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={concurrentNumberOfConnectionsKibanaUrl() + "&embed=true"} height="1000" width="100%" className="border-0"></iframe>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
                  </Container>
  )
}
