import React from 'react';
import { useHistory, useParams } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react';
import { Card, CardBody, CardHeader, Alert, Button } from 'reactstrap';
import { OrgnaziationContext } from '../../../../Home';
import Breadcrumbs from '../../../../global/Breadcrumb';
import BannerNotifications from '../../Home/BannerNotifications/BannerNotifications.component';
import SiteSelector from './SiteSelector/SiteSelector.component';
import SitesTable from './DataTable/SitesTable.component';
import ClientPageLoader from '../../ClientPagesLoader/ClientPageLoader.component';
import { toast } from 'react-toastify';
import axios from 'axios';
import '../../../../../stylesheets/custom.scss';


export default function DataDownload({ banner_notifications, current_organization, current_user }) {
  const history = useHistory()
  const { organization_name } = useParams();
  const { setOrgName } = useContext(OrgnaziationContext)

  const [organizationName, setOrganization_name] = useState('')
  const [bannerNotifications, setBannerNotifications] = useState([]);
  const [sitesData, setSitesData] = useState([]);
  const [successData, setSuccessData] = useState({});
  const [unsuccessData, setUnsuccessData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [siteNamesDropdownOptions, setSiteNamesDropdownOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (current_organization?.org_identifier) {
      setOrganization_name(current_organization?.org_identifier)
    } else {
      setOrganization_name(organization_name)
    }
  }, []);


  useEffect(() => {
    if (!(current_organization?.org_identifier)) {
      setOrgName(organizationName)
    }
  }, [organizationName]);

  useEffect(() => {
    if ((current_user?.id && !(current_user?.is_admin)) && !(current_user?.accessible_modules.includes("data_access"))) {
      history.push(`/`)
      toast.error('You are not authorized to access this page')
    }
  }, [current_user]);


  useEffect(() => {
    setBannerNotifications(banner_notifications)
  }, [banner_notifications]);


  useEffect(() => {
    setTableData([])
    if (successData.data_files) {
      let tempData = []
      successData.data_files.forEach(element => {
        tempData.push({
          site_name: element.site_name, upload_time: element.upload_time,
          record_count: element.record_count, id: element.id,
          download_file_link: <a href={element.download_file_link} target='_blank' className='btn btn-success btn-sm text-white' download>Download</a>
        })
      });
      setTableData(tempData)
    }
  }, [successData]);


  useEffect(() => {
    if (organizationName) {
      axios.get(`/api/v1/organizations/${organizationName}/data_download?page_type=client&controller_name=data_download`).then(res => {
        setSitesData(res.data.sites)
        setSiteNamesDropdownOptions(convertArrayToObjectArray(res?.data?.sites))
        setLoading(false)
      }).catch(err => {
        setLoading(false)
      })
    }
  }, [organizationName])


  function getFormattedDate(date) {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  };


  const getFiles = (startDate, endDate, sitename) => {
    setLoading(true)
    setUnsuccessData({})
    axios.post(`/api/v1/organizations/${organizationName}/data_download/fetch_data_files`, {
      data_download: { site_name: sitename, from_date: getFormattedDate(startDate), to_date: getFormattedDate(endDate) },
      page_type: "client", controller_name: "data_download"
    }).then(res => {
      setSuccessData(res?.data)
      setLoading(false)
    }).catch(err => {
      let errObj = err?.response?.data
      if (errObj.lots_of_files_in_api_link) {
        setUnsuccessData(errObj)
      } else {
        toast.error(err?.response?.data?.message)
      }
      setLoading(false)
    })
  };


  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };


  const downloadAsZip = () => {
    let idList = []
    selectedRows.forEach(each => {
      idList.push(each.id)
    })
    axios({
      url: `/api/v1/organizations/${organizationName}/data_download/download_as_zip`,
      method: 'POST', responseType: 'blob',
      data: { selected_file_ids: idList, page_type: "client", controller_name: "data_download" }
    }).then(res => {
      const url_to_download = window.URL.createObjectURL(new Blob([res.data]));
      const a_tag = document.createElement('a');
      a_tag.href = url_to_download;
      a_tag.setAttribute('download', `data_download.zip`);
      document.body.appendChild(a_tag);
      a_tag.click();
      toast.success("data downloaded successfully")
    }).catch(err => {
      toast.error(err.response.data.message)
    })
  };

  // Helper function to convert the array into array of objects
  function convertArrayToObjectArray(arrayInput) {
    var objectArray = [];

    for (var i = 0; i < arrayInput.length; i++) {
      var obj = {
        label: arrayInput[i],
        value: arrayInput[i]
      };

      objectArray.push(obj);
    }

    return objectArray;
  }
  

  return (
    <div>
      <Breadcrumbs parent="Data Download" title="Data Download" />

      <BannerNotifications notifications={bannerNotifications} />

      <Card>
        <CardHeader className="internal-dash-card-header">
          <h6 className='fw-bold'>API Endpoint</h6>
        </CardHeader>
        <CardBody>
          <SiteSelector sitesData={sitesData} dataFetcher={getFiles} loading={loading} siteNamesDropdownOptions={siteNamesDropdownOptions} />
          {"lots_of_files_in_api_link" in unsuccessData ? (
            <Alert color='light' className='my-4'>
              The number of files generated during the selected date range is too large to be displayed here. Please try again with a smaller date range, or use
              <a href={unsuccessData.data_download_api_link} className='alert-link'> this API link </a>
              to access the files.
            </Alert>
          ) : ("data_files" in successData) ? (
            <>
              <Alert color='light' className='my-4'>
                <a href={successData.data_download_api_link} className='alert-link' target='_blank'>Click here </a> to open the API in a new window.
              </Alert>
              <SitesTable data={tableData} rowSelect={handleChange} />
            </>
          ) : (!unsuccessData.lots_of_files_in_api_link) && (
            <Alert color='light' className='mb-3 mt-4'>
              <h6>
                No files found to download
              </h6>
            </Alert>
          )
          }
          <Button className='text-center mt-3 py-2' color='success'
            onClick={downloadAsZip}
            disabled={selectedRows.length === 0}>
            Download as Zip
          </Button>
        </CardBody>
      </Card>
      
    </div>
  )
}
