import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home, Info } from "react-feather";
import moment from "moment";
import axios from "axios";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";
import Select, { components } from "react-select";
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify'
import CustomList from '../../high-volume-clients-and-sites/custom_list';

import { NewInternalBasePoint } from '../../../Common/BaseEsPoints/InternalEsBasePoint';

export default function Index() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const searchParams = {
    projectTitle: new URLSearchParams(search).get("filter[project_title]") || "",
    customListName: new URLSearchParams(search).get("filter[custom_list_name]") || "",
    fromDate: new URLSearchParams(search).get("filter[from_date]") || "",
    toDate: new URLSearchParams(search).get("filter[to_date]") || ""
  }
  const [projectTitle, setProjectTitle] = useState({label: "", value: ""});
  const [customListName, setCustomListName] = useState({label: "", value: ""});
  const [sitegroupListOptions, setSitegroupListOptions] = useState([]);
  const [customListsOptions, setCustomListsOptions] = useState([]);
  const [volumeFluctuationsData, setVolumeFluctuationsData] = useState([]);
  const [dateArr, setDateArr] = useState([]);
  const now = new Date();
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(7, 'days')));
  const [toDate, setToDate] = useState(now);
  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults]= useState([]);

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v2/internal/high_volume_clients_and_sites')
      .then(res => {
        setSitegroupListOptions(res.data.sitegroup_list);
        setCustomListsOptions(res.data.custom_lists);
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong');
        toast.error(err?.response.data.message || 'Oops something went wrong');
      });
  }, []);

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.projectTitle && searchParams.projectTitle != projectTitle.value) {
      setProjectTitle({ label: searchParams.projectTitle, value: searchParams.projectTitle });
    }
    if (searchParams.customListName && searchParams.customListName != customListName.value) {
      setCustomListName({ label: searchParams.customListName, value: searchParams.customListName });
    }
    if (searchParams.fromDate && searchParams.fromDate != fromDate) {
      setFromDate(new Date(searchParams.fromDate));
    }
    if (searchParams.toDate && searchParams.toDate != toDate) {
      setToDate(new Date(searchParams.toDate));
    }
    if (commit == 'Get Data') {
      fetchData(searchParams);
      buildTableColums();
    }
  }

  function fetchData(filterParams) {
    setLoading(true)
    axios.get(`/api/v2/internal/volume_fluctuations?filter[project_title]=${filterParams.projectTitle}&filter[custom_list_name]=${filterParams.customListName}&filter[from_date]=${moment(filterParams.fromDate).format('YYYY/MM/DD')}&filter[to_date]=${moment(filterParams.toDate).format('YYYY/MM/DD')}`)
      .then(res => {
        setVolumeFluctuationsData(res.data?.volume_fluctuations_data);
        setSearchResults(res.data?.volume_fluctuations_data);
        setDateArr(res.data?.date_arr);
        setLoading(false)
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong');
        toast.error(err?.response.data.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  function buildTableColums() {
    const tableColumns = [
      {name: 'Site Name', selector: 'site_name', sortable: true},
    ]
    dateArr.map(item => tableColumns.push({name: `${item}`, selector: `${item}`, sortable: true}));
    return tableColumns;
  }

  const handleSearch = event =>{
    if(event.target.value == ''){
      setSearchResults(volumeFluctuationsData);
    }
    else{
      setSearchResults(searchFromData(event.target.value));
    }
    setSearchText(event.target.value);
  }

  function searchFromData(value){
    var search_result = volumeFluctuationsData.filter(item => item.site_name.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
  }

  const handleGetData = () => {
    window.location.href = `${NewInternalBasePoint}/volume_fluctuations?filter[project_title]=${projectTitle? projectTitle.value : ""}&filter[custom_list_name]=${customListName? customListName.value : ""}&filter[from_date]=${fromDate? moment(fromDate).format('YYYY/MM/DD') : fromDate}&filter[to_date]=${toDate? moment(toDate).format('YYYY/MM/DD') : toDate}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href={`${NewInternalBasePoint}/volume_fluctuations`}>Volume Fluctuations</a> : 'Volume Fluctuations'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
          </BreadcrumbItem>}
        </Breadcrumb>
      </Col>
    </Row>
  </div>

  <Card>
    <CardBody>
      <Row className="align-items-end mt-2">
        <Col sm="6">
          <label className="fw-bold">Custom List <CustomList /></label>
          <Select
            value={customListName?.value.length > 0 ? customListName : "" }
            placeholder={"Select an option"}
            options={customListsOptions}
            isSearchable
            isClearable
            classNamePrefix
            onChange={option => setCustomListName(option)} />
        </Col>
        <Col sm="6">
          <label className="fw-bold">Sitegroup List </label>
          <Select
            value={projectTitle?.value.length > 0 ? projectTitle : "" }
            placeholder={"Select an option"}
            options={sitegroupListOptions}
            isSearchable
            isClearable
            classNamePrefix
            onChange={option => setProjectTitle(option)} />
        </Col>
      </Row>
      <Row className="align-items-end mt-2">
        <Col sm="4">
          <label className="fw-bold">From Date</label>
          <DatePicker
            className="form-control"
            selected={new Date(fromDate)}
            dateFormat={"YYY/MM/dd"}
            onChange={date => setFromDate(date)} />
        </Col>
        <Col sm="4">
          <label className="fw-bold">To Date</label>
          <DatePicker
            className="form-control"
            selected={new Date(toDate)}
            dateFormat={"YYY/MM/dd"}
            onChange={date => setToDate(date)} />
        </Col>
        <Col sm="4">
          <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
        </Col>
      </Row>
    </CardBody>
  </Card>

  {
    commit == 'Get Data' ?
    <>
      <Card>
        <CardBody>
          <Row className='my-2'>
            <Col lg={12}>
              {
                loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> 
                :
                <Row className="align-items-end mt-2">
                  <Col>
                    <div className="float-end mb-3">
                      <Row className="align-items-center">
                        <Col sm="3">Search</Col>
                        <Col>
                          <input type="text" className="form-control" value={searchText} onChange={handleSearch} />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <DataTable
                    columns={buildTableColums()}
                    data={searchResults}
                    noHeader
                    pagination
                    progressPending={loading}
                  />
                </Row>
              }
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
    : ""
  }
</Container>
}

