import React, {useState, useEffect, useContext} from 'react'
import { useParams } from "react-router-dom";
import Breadcrumb from '../../global/Breadcrumb';
import { Card, CardBody, CardHeader, Container, Row, Col, Button, Modal, ModalBody, Nav, 
    NavItem, NavLink, ModalFooter, ModalHeader, TabContent, TabPane, Input, CardFooter } from 'reactstrap'
import axios from 'axios'
import { toast } from 'react-toastify';
import StepZilla from "react-stepzilla";
import AddressDetails from './address-steps/AddressDetails';
import BusinessDetails from './address-steps/BusinessDetails';
import { OrgnaziationContext } from '../../Home';

export default function EditOrganization({myOrganization}) {
    let { organization_name } = useParams();
    const [globalData, setGlobalData] = useState({})
    const [data, setData] = useState({})
    const [toggle, setToggle] = useState(false)

    const {setOrgName} = useContext(OrgnaziationContext)
    useEffect(() => {
        setOrgName(organization_name)
    }, [organization_name])

    useEffect(() => {
        axios.get(`/api/v1/organizations/${organization_name}?page_type=client&controller_name=organizations`).then(res => {
            setGlobalData(res.data.organization)
        }).catch(err => {
            toast.error(err.response.data.message)
        })
    }, [toggle])

    useEffect(() => {
        setData(globalData)
    }, [globalData])

    const steps =
        [
            { name: 'Address Details', 
                component: <AddressDetails data={data} setData={setData} myOrganization={myOrganization} /> },
            { name: 'Business Details', component: <BusinessDetails data={data} setData={setData}
                organization_name={organization_name} myOrganization={myOrganization} /> },
        ]

    return (
        <Container fluid>
            <Breadcrumb parent="Organization Details" title="Edit"
                childrenObj={[{name: 'Organization', url: `/organizations/${organization_name}`}]} />

            <Card className='p-4'>
                <Row>
                    <Col lg={6} className='d-flex'>
                        <h6 className='fw-bold my-0 me-2'>
                            Organization Name:
                        </h6>
                        <h6 className='my-0'>
                            {globalData.billing_name}
                        </h6>
                    </Col>
                    <Col lg={6} className='d-flex'>
                        <h6 className='fw-bold my-0 me-2'>
                            Country:
                        </h6>
                        <h6 className='my-0'>
                            {globalData?.address?.country_name}
                        </h6>
                    </Col>
                </Row>
            </Card>

            <Card>
                <CardHeader>
                    <h6 className='fw-bold my-0'>Edit Organization Details: </h6>
                </CardHeader>
                <CardBody>
                    <StepZilla steps={steps} showSteps={true} showNavigation={true} 
                        stepsNavigation={true} prevBtnOnLastStep={true} dontValidate={false} />
                </CardBody>
            </Card>
        </Container>
    )
}
