import React, {useState, useEffect, useMemo} from 'react'
import { CardBody, Col, Card, Container, Row } from 'reactstrap';
import Breadcrumb from '../../global/Breadcrumb'
import countryList from 'react-select-country-list'
import axios from 'axios'
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component'
import Loader from 'react-loader-spinner';


export default function AllOrganizations() {

  const [filtersData, setFiltersData] = useState({
    email: '', name: '', identifier: '', organization_status: '', country: ''
  });
  const [organizations, setOrganizations] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 20;
  const [pageTotal, setPageTotal] = useState(0);
  const [organizationStatuses, setOrganizationStatuses] = useState([]);
  const [countrySelected, setCountrySelected] = useState('none')
  const [loading, setLoading] = useState(true);
  const [sortString, setSortString] = useState('');

  

  const options = useMemo(() => countryList().getData(), [])

  useEffect(() => {
    setLoading(true)
    axios.get(`/api/v1/admins/organizations?filterrific[search_by_name]=${filtersData.name}&filterrific[search_by_identifier]=${filtersData.identifier}&filterrific[filter_by_country]=${filtersData.country}&filterrific[filter_by_organization_status]=${filtersData.organization_status}&page=${1}`)
      .then(res => {
        setOrganizations(res.data?.organizations)
        setPageTotal(res.data?.total_organizations_count)
        setOrganizationStatuses(res.data?.organization_statuses)
        setLoading(false)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
        setLoading(false)
      })
  }, []);

  useEffect(() => {
    if (sortString !== '' && sortString !== null){
      setPage(1)
      setLoading(true)
      axios.get(`/api/v1/admins/organizations?filterrific[search_by_name]=${filtersData.name}&filterrific[search_by_identifier]=${filtersData.identifier}&filterrific[filter_by_country]=${filtersData.country}&filterrific[filter_by_organization_status]=${filtersData.organization_status}&filterrific[sorted_by]=${sortString}&page=${1}`)
        .then(res => {
          setOrganizations(res.data?.organizations)
          setPageTotal(res.data?.total_organizations_count)
          setOrganizationStatuses(res.data?.organization_statuses)
          setLoading(false)
        }).catch(err => {
          toast.error(err?.response?.data?.message)
          setLoading(false)
        })
    }
  }, [sortString]);

  const handleSearch = () => {
    setPage(1)
    setLoading(true)
    axios.get(`/api/v1/admins/organizations?filterrific[search_by_name]=${filtersData.name}&filterrific[search_by_identifier]=${filtersData.identifier}&filterrific[filter_by_country]=${filtersData.country}&filterrific[filter_by_organization_status]=${filtersData.organization_status}&page=${1}`)
      .then(res => {
        setOrganizations(res.data?.organizations)
        setPageTotal(res.data?.total_organizations_count)
        setOrganizationStatuses(res.data?.organization_statuses)
        setLoading(false)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
        setLoading(false)
      })
  }

  const handleFormReset = () => {
    setFiltersData({
      email: '', name: '', identifier: '', organization_status: '', country: ''
    })
  }

  const customSort = (rows, selector, direction) => {
    if (selector != null){
      setSortString(`${selector}_${direction}`)
    }
    return rows
  }
  const columns = [
    {name: 'Name',selector: 'name',sortable: true,center:true, grow:2},
    {name: 'Identifier',
     cell: row =>(<a href={row.organization_link} target="_blank">{row.identifier}</a>),
      sortable: true,
      left:true
    },
    {name: 'Org Status',selector: 'organization_status',center:true},

    {name: 'Country',
    selector: '',
    center:true, 
    sortable: true,
    cell: row => (
        row.country?row.country:"N/A"
      )
    },
    {name: 'Currency',selector: 'currency',center:true},
    {
      name: 'Subscribed Products',
      selector: '',
      left:true,
      cell: row => (
        row.subscribed_products?row.subscribed_products:"N/A"
      )
    },
    {name: 'Created at',selector: 'created_at',sortable: true, center:true},
  ]

  const handlePageChange = pageNum => {
    setPage(pageNum)
    axios.get(`/api/v1/admins/organizations?filterrific[search_by_name]=${filtersData.name}&filterrific[search_by_identifier]=${filtersData.identifier}&filterrific[filter_by_country]=${filtersData.country}&filterrific[filter_by_organization_status]=${filtersData.organization_status}&page=${pageNum}`)
      .then(res => {
        setOrganizations(res.data?.organizations)
        setPageTotal(res.data?.total_organizations_count)
        setOrganizationStatuses(res.data?.organization_statuses)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })
  }

  return (
    <Container fluid>
      <Breadcrumb parent={"Organizations"} title={"Organizations"} />

      <Card>
        <CardBody>
          <Row className="align-items-end">
            <Col md={3} className='my-2'>
              <h6 className='fw-bold'>Email</h6>
              <input type="text" className='form-control' value={filtersData.email} placeholder="Enter Email" 
                onChange={(e) => setFiltersData({...filtersData, email: e.target.value})} />
            </Col>
            <Col md={3} className='my-2'>
              <h6 className='fw-bold'>Name</h6>
              <input type="text" className='form-control' value={filtersData.name} placeholder="Enter Name of Organization"
                onChange={(e) => setFiltersData({...filtersData, name: e.target.value})} />
            </Col>
            <Col md={3} className='my-2'>
              <h6 className='fw-bold'>Identifier</h6>
              <input type="text" className='form-control' value={filtersData.identifier}  placeholder="Enter Organization Identifier"
                onChange={(e) => setFiltersData({...filtersData, identifier: e.target.value})} />
            </Col>
            <Col md={3} className='my-2'>
              <h6 className='fw-bold'>Organization Status</h6>
              <select className='form-select' value={filtersData.organization_status}
                onChange={(e) => setFiltersData({...filtersData, organization_status: e.target.value})} >
                <option value="" hidden >Select Organization Status</option>
                {organizationStatuses.map(each => (
                    <option value={each[1]} key={each[0]}>
                {each[0]}
              </option>
                ))}
            </select>
          </Col>
            <Col md={3} className='my-2'>
              <h6 className='fw-bold'>Country</h6>
              <select className='form-select' value={filtersData.country}
                onChange={(e) => setFiltersData({...filtersData, country: e.target.value})} >
                <option value="" hidden>Select Country</option>
                {options.map(each => {
                  return(
                    <option value={each.value} key={each.value}>
                {each.label}
              </option>
                )})}
            </select>
        </Col>
            <Col md={3} className='my-2'>
              <button className='btn btn-primary w-100'
                onClick={handleSearch}>Search</button>
            </Col>
            <Col md={3} className='my-2'>
              <button className='btn btn-danger w-100'
                onClick={handleFormReset}>Form Reset</button>
            </Col>
      </Row>
    </CardBody>
            </Card>

      <Card>
        <CardBody>
          {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> 
        :<DataTable title={`Displaying Organization ${((page - 1) * countPerPage) + 1} - ${(page * countPerPage) > pageTotal ? pageTotal : page * countPerPage} of ${pageTotal} in total | Sort By ${sortString.split('_').join(' ')}`} 
          columns={columns} data={organizations} sortFunction={customSort} 
          highlightOnHover pagination paginationServer progressPending={loading}
          paginationTotalRows={pageTotal} paginationPerPage={countPerPage}
          paginationComponentOptions={{
            noRowsPerPage: true
          }} onChangePage={handlePageChange}
        />}
        </CardBody>
      </Card>
        </Container>
  );
                }
