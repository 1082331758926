import React from 'react'
import { useState } from 'react'
import { Card, Container, Tab, Tabs } from 'react-bootstrap'

import FieldValue from './TabElements/FieldValue.tab'
import XpathsByValue from './TabElements/XpathsByValue.tab'
import XpathsWithTwoSteps from './TabElements/XpathsWithTwoSteps.tab'
import Breadcrumbs from '../../../Common/BreadCrumb/BreadCrumbs.component'
import { CardHeader } from 'reactstrap'


export default function XpathResults() {
  const [key, setKey] = useState("xpaths_with_two_steps")

  return (
    <React.Fragment>
      <Container fluid>
        <Breadcrumbs parent="Internal Dashboard" title="XPATH Results" />
        <Card>
          <CardHeader style={{ padding: "20px" }}>
            <div className="form-text">
              NOTE: We can't get the data present in Script tags
            </div>
          </CardHeader>
          <Card.Body>
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
              justify
            >
              <Tab eventKey="xpaths_with_two_steps" title="Xpaths With Two Steps">
                <XpathsWithTwoSteps />
              </Tab>
              <Tab eventKey="field_value" title="Field Value">
                <FieldValue />
              </Tab>
              <Tab eventKey="xpaths_by_value" title="Xpaths By Value">
                <XpathsByValue />
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  )
}
