import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Container, Nav, NavItem, TabContent, TabPane, Col, NavLink, Row, BreadcrumbItem, Breadcrumb } from "reactstrap";
import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";
import { Home } from "react-feather";
import { Link } from "react-router-dom";

export default function ResqueReportsForBlockedSites() {
    const [activeTab, setActiveTab] = useState('1');
    const [runningReports, setRunningReports] = useState([]);
    const [waitingReports, setWaitingReports] = useState([]);
    const [failedReports, setFailedReports] = useState([]);
    const [loading, setLoading] = useState(false);
    const [runningSearchText, setRunningSearchText] = useState('');
    const [waitingSearchText, setWaitingSearchText] = useState('');
    const [failedSearchText, setFailedSearchText] = useState('');
    const [runningSearch, setRunningSearch] = useState([]);
    const [waitingSearch, setWaitingSearch] = useState([]);
    const [failedSeach, setFailedSearch] = useState([]);
    const perPage = 10;
    const columns = [
        { name: 'Site Name', selector: 'sitename', sortable: true, grow: 2, center: true, cell: row => <a target={'_blank'} href={`/internal/upload?filter[site]=${row.sitename}`}>{row.sitename}</a> },
        { name: 'Distributed Recrawl', selector: 'distributed_recrawl', sortable: true, center: true },
        { name: 'Recrawl', selector: 'recrawl', sortable: true, center: true },
        { name: 'Feedcrawl3', selector: 'feedcrawl3', sortable: true, center: true },
        { name: 'Dependent Data Upload', selector: 'dependent_data_upload', sortable: true, center: true },
        { name: 'Entire Pipeline', selector: 'entirepipeline', sortable: true, center: true },

    ];
    const rowsPerPageOptions = [
        { label: 10, value: 10 },
        { label: 25, value: 25 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
    ]

    useEffect(() => {
        setLoading(true);
        axios.get(`/api/v1/internal/resque_info/resque_reports_for_blocked_sites`)
            .then(res => {
                setRunningReports(res.data.running_reports);
                setWaitingReports(res.data.waiting_reports);
                setFailedReports(res.data.failed_reports);
                setFailedSearch(res.data.failed_reports);
                setWaitingSearch(res.data.waiting_reports);
                setRunningSearch(res.data.running_reports);
                setLoading(false);
            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong')
            })
    }, []);

    const handleRunningSearch = event => {
        if (event.target.value == '') {
            setRunningSearch(runningReports)
        }
        else {
            setRunningSearch(runningReports.filter(item => item.sitename.includes(event.target.value)))
        }
        setRunningSearchText(event.target.value)

    }

    const handleWaitingSearch = event => {
        if (event.target.value == '') {
            setWaitingSearch(waitingReports)
        }
        else {
            setWaitingSearch(waitingReports.filter(item => item.sitename.includes(event.target.value)))
        }
        setWaitingSearchText(event.target.value)

    }
    const handleFailedSearch = event => {
        if (event.target.value == '') {
            setFailedSearch(failedReports)
        }
        else {
            setFailedSearch(failedReports.filter(item => item.sitename.includes(event.target.value)))
        }
        setFailedSearchText(event.target.value)

    }

    return <Container fluid>
        <Container fluid>
            <div className="page-title">
                <Row className=''>
                    <Col xs="6">
                        <h3>Internal Dashboard</h3>
                    </Col>
                    <Col xs="6">
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <Link to={`/internal`}><Home /></Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem className="fw-bold">
                                Resque Reports For Blocked Sites
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
        </Container>
        {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> : <Card>
            <CardBody>
                <ul className="mb-3">
                    <li><b>NOTE:</b>
                        <ul style={{ listStyleType: 'disc' }} className="ms-5">
                            <li>Resque running job blocking for more than 1 day</li>
                            <li>Resque waiting job blocking for more than 1 hour</li>
                        </ul>
                    </li>
                </ul>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={activeTab == '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                            Resque Running
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={activeTab == '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                            Resque Waiting
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={activeTab == '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                            Resque Failed
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>

                    <TabPane tabId={'1'}>
                        <Row className="my-3">
                            <Col>
                                <div className="float-end">
                                    <Row className="align-items-center">
                                        <Col sm="3">Search</Col>
                                        <Col>
                                            <input type="text" className="form-control" value={runningSearchText} onChange={handleRunningSearch} />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <DataTable
                            data={runningSearch}
                            columns={columns}
                            pagination
                            highlightOnHover
                            noHeader
                        />
                    </TabPane>
                    <TabPane tabId={'2'}>
                        <Row className="my-3">
                            <Col>
                                <div className="float-end">
                                    <Row className="align-items-center">
                                        <Col sm="3">Search</Col>
                                        <Col>
                                            <input type="text" className="form-control" value={waitingSearchText} onChange={handleWaitingSearch} />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <DataTable
                            data={waitingSearch}
                            columns={columns}
                            pagination
                            highlightOnHover
                            noHeader
                        />
                    </TabPane>
                    <TabPane tabId={'3'}>
                        <Row className="my-3">
                            <Col>
                                <div className="float-end">
                                    <Row className="align-items-center">
                                        <Col sm="3">Search</Col>
                                        <Col>
                                            <input type="text" className="form-control" value={failedSearchText} onChange={handleFailedSearch} />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <DataTable
                            data={failedSeach}
                            columns={columns}
                            pagination
                            highlightOnHover
                            noHeader
                        />
                    </TabPane>
                </TabContent>
            </CardBody>
        </Card>
        }
    </Container >
}