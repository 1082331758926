import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';
import BreadcrumbComponent from './Breadcrumb/Breadcrumb.component';
import LoadingAnimation from './LoadingAnimation/LoadingAnimation.component';
import SearchSitesCard from './Cards/SearchSItesCard.component';
import DataTableCard from './Cards/DataTableCard.component';
import { toast } from 'react-toastify';
import axios from 'axios';

// SEARCH OPTIONS API ENDPOINTS
const DATA_API_ENDPOINT = '/api/v1/internal/dropdown_list_helper';
const SEARCH_OPT_ENDPOINT = '/api/v1/internal/search_site_names?site_name=';

import { NewInternalBasePoint } from '../../../Common/BaseEsPoints/InternalEsBasePoint';


export default function TestSiteStats() {
  const search = useLocation().search;
  const siteValue = new URLSearchParams(search).get('filter[site]');
  const fromValue = new URLSearchParams(search).get('filter[crawldate][from]');
  const toValue = new URLSearchParams(search).get('filter[crawldate][to]');
  const commitValue = new URLSearchParams(search).get('commit');


  const [dropdownOptions, setDropdownOptions] = useState([{ label: "", value: "" }]);
  const [selectedSite, setSelectedSite] = useState([]);

  const today = new Date();
  const [fromDate, setFromDate] = useState(fromValue ? new Date(fromValue) : new Date(today.getFullYear(), today.getMonth(), today.getDate() - 2));
  const [endDate, setEndDate] = useState(toValue ? new Date(toValue) : today);


  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [searchValue, setSearchValue] = useState('');

  // useEffect for when the component is loaded
  useEffect(async () => {
    await fetchOptionsFromDB();
  }, []);

  // UseEffect for when the url changes
  useEffect(() => {
    if (siteValue) {
      setSelectedSite({ label: siteValue, value: siteValue })
    }
    if (fromValue) {
      setFromDate(new Date(fromValue))
    }
    if (toValue) {
      setEndDate(new Date(toValue))
    }
    if (commitValue === 'Get Data' && siteValue && fromValue && toValue) {
      getDataFromBackEnd()
    }
  }, [siteValue, fromValue, toValue, commitValue]);

  // Helper function to format date
  function getFormattedDate(date) {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return year + '/' + month + '/' + day;
  };

  // Helper function to fetch options data from back-end DB
  async function fetchOptionsFromDB() {
    const controller = new AbortController();
    try {
      const response = await axios.get(DATA_API_ENDPOINT, { signal: controller.signal });
      let site_names = await response?.data?.site_names
      let site_names_options = []
      for (var key in site_names) {
        let group = {}, children = []
        group['label'] = <div className="fw-bold col text-black">{key}</div>
        site_names[key].map(item => {
          children.push({ label: item, value: item })
        })
        group['options'] = children;
        site_names_options.push(group)
      }
      site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
      setDropdownOptions(site_names_options)
    }
    catch (error) {
      toast.error(error?.message || 'Oops something went wrong');
    }
  };

  // Helper function to fetch site names when user enters value in search field
  function onSiteSearchHandler(inputValue) {
    if (inputValue.length < 3) {
      return null;
    }
    else {
      const promise = new Promise(async (resolve) => {
        try {
          const response = await axios.get(SEARCH_OPT_ENDPOINT + inputValue);
          if (response?.status === 200) {
            let site_names = await response?.data?.site_names
            let site_names_options = []
            for (var key in site_names) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
              site_names[key].map(item => {
                children.push({ label: item, value: item })
              })
              group['options'] = children;
              site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setDropdownOptions(site_names_options)
            resolve(site_names_options)
          }
        }
        catch (error) {
          console.log("err", error);
        }
      });

      return promise;
    }
  };

  // Helper function to get data from backend
  function getDataFromBackEnd() {
    setLoading(true)
    axios.get(`/api/v2/internal/test_site_stats?filter[site]=${(selectedSite?.value || siteValue)}&filter[from_date]=${getFormattedDate(fromDate)}&filter[to_date]=${getFormattedDate(endDate)}`).then(res => {
      console.log('res.data', res.data);
      setData(res.data);
      setLoading(false);
    }).catch(err => {
      toast.error(err?.response?.data?.message);
      setLoading(false);
    })
  };

  // Helper function to set the url
  function onGetDataButtonClickHandler() {
    if (selectedSite.length === 0 || siteValue === 'undefined') {
      toast.error("Site Name cannot be empty");
      return;
    }
    else {
      window.location.href = `${NewInternalBasePoint}/test_site_stats/search?utf8=✓&filter%5Bsite%5D=${selectedSite.value}&filter%5Bcrawldate%5D%5Bfrom%5D=${getFormattedDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)}&commit=Get Data`;
    }
  };

  return (
    <div>
      <Container fluid>
        <BreadcrumbComponent />
        {
          loading ? <LoadingAnimation /> :
            <SearchSitesCard
              dropdownOptions={dropdownOptions}
              selectedSite={selectedSite}
              setSelectedSite={setSelectedSite}
              fromDate={fromDate}
              endDate={endDate}
              setFromDate={setFromDate}
              setEndDate={setEndDate}
              onButtonClickHandler={onGetDataButtonClickHandler}
              onSearchHandler={onSiteSearchHandler}
            />
        }
        {
          data?.data?.length > 0 ? <DataTableCard data={data} /> : ''
        }
      </Container>
    </div>
  )
}
