import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { Modal, ModalBody, ModalHeader, Row, Col, ModalFooter, Button } from 'reactstrap'
import CustomButtons from './CustomButtons';
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify';
import getSymbolFromCurrency from 'currency-symbol-map'
import Loader from 'react-loader-spinner';

export default function ShowSitegroupDetails(data) {
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sitegroupDetail, setSitegroupDetail] = useState({});
  const [additionalDetails, setAdditionalDetails] = useState([]);
  const [loadingBtns, setLoadingBtns] = useState(false)
  const [schemaFieldPageTotal, setSchemaFieldPageTotal] = useState(0);
  const [sitesPageTotal, setSitesPageTotal] = useState(0);
  const [unpaidFeasibilityFeportsPagesTotal, setUnpaidFeasibilityFeportsPagesTotal] = useState(0);
  const [currencyCode, setCurrencyCode] = useState('');

  const toggleFunc = (e) => {
    e.preventDefault();
    GetSitegroupDetail();
    setModal(curr => !curr)
  }

  const closeBtn = <button className="close" 
    onClick={() => setModal(false)}> &times; </button>;

  const GetSitegroupDetail = () => {
    setLoading(true)
    axios.get(`/api/v1/admins/feasibility_reports/sitegroup_details?sitegroup_id=${data.sitegroup_id}`)
      .then(res => {
        setCurrencyCode(getSymbolFromCurrency(res?.data?.currency_code))
        setSitegroupDetail(res.data?.sitegroup)
        setSchemaFieldPageTotal(res.data?.schema_field_details_count)
        setAdditionalDetails(res.data?.sitegroup.additional_details)
        setSitesPageTotal(res.data?.websites_count)
        setUnpaidFeasibilityFeportsPagesTotal(res.data?.unpaid_feasibility_reports_count)
        setLoading(false)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
        setLoading(false)
      })
  }

  const SchemaFieldColumns = [
    {name: 'FieldName',selector: 'field_name'},
    {name: 'DataType',selector: 'data_type'},
    {name: 'Is Mandatory',selector: 'is_mandatory'},
    {name: 'Default Value',selector: 'default_value'},
    {name: 'Sample Value',selector: 'sample_value'},
    {name: 'Comments',selector: 'comments'},
  ]

  const SitesColumns = [
    {name: 'Sample URL',selector: 'site_url'},
    {name: 'Site Status',selector: 'site_status'},
    {name: 'Rss Urls Crawl Limit',selector: 'rss_urls_crawl_limit'},
    {
      name: 'Site Setup Fee',
      selector:(row)=>(`${currencyCode} ${row?.site_setup_fee}`)
    },
    {
      name:'Monthly Maintenance Fee',
      selector:(row)=>(`${currencyCode} ${row?.monthly_maintenance_fee}`)
    },
    {
      name: 'Volume Charges',
      selector:(row)=>(`${currencyCode} ${row?.volume_charges}`)
    },
    {name: 'No.of Free Records',selector: 'number_of_free_records'},
  ]

  const UnpaidFeasibilityFeportsColumns = [
    {name: '# Sites',selector: 'sites_count'},
    {name: 'Overall Feasibility',selector: 'overall_feasibility'},
    {name: 'Created By',selector: 'created_by'},
    {name: '# Viewed',selector: 'viewed_count'},
    {name: 'Accepted By',selector: 'accepted_by'},
    {name: 'Current Status',selector: 'client_acceptance_status'},
    {
      name: 'Action',
      selector: '', 
      sortable: false,
      button: true,
      cell: row => <CustomButtons view_link={row.view_link} edit_link={row.edit_link} mark_as_paid_link={row.mark_as_paid_link}
      editable={row.editable} can_mark_as_paid={row.can_mark_as_paid}/>
    },
  ]

  return (
    <>
      <Modal isOpen={modal} toggle={toggleFunc} backdrop='static' size='xl'>
        <ModalHeader toggle={toggleFunc} close={closeBtn}>
          Sitegroup Full Details 
        </ModalHeader>
        <ModalBody className='px-5'>
          {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
        : <>
          <Row className='my-2'>
            <Col lg={4}>
              <h6 className='my-1 f-w-600'>Sitegroup Name</h6>
              <h6 className='my-0 word-break'>{sitegroupDetail.sitegroup_name}</h6>
            </Col>
            <Col md={4}>
              <h6 className='my-1 f-w-600'>Fields to extract</h6>
              <h6 className='my-0 word-break'>{sitegroupDetail.fields_to_extract}</h6>
            </Col>
            <Col md={4}>
              <h6 className='my-1 f-w-600'>Additional Information</h6>
              <h6 className='my-0 word-break'>{sitegroupDetail.additional_information}</h6>
            </Col>
          </Row>
          <Row className='my-2'>
            <Col lg={4}>
              <h6 className='my-1 f-w-600'>Sitegroup Status</h6>
              <h6 className='my-0'>{sitegroupDetail.sitegroup_status}</h6>
            </Col>
          </Row>
          <Row className='my-2'>
            <Col lg={12}>
              {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> 
            :<DataTable title={`Schema Details`} 
              columns={SchemaFieldColumns} data={sitegroupDetail.schema_field_details} 
              highlightOnHover pagination progressPending={loading}
              paginationTotalRows={schemaFieldPageTotal} 
            />}
            </Col>
          </Row>
          <Row className='my-2'>
            <Col lg={12}>
              {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> 
            :<DataTable title={`Site Details`} 
              columns={SitesColumns} data={sitegroupDetail.websites} 
              highlightOnHover pagination paginationServer progressPending={loading}
              paginationTotalRows={sitesPageTotal} paginationPerPage={6}
              paginationComponentOptions={{
                noRowsPerPage: true
              }}
            />}
            </Col>
          </Row>
          <Row className='my-2'>
            <Col lg={6}>
              <h6 className='my-0 f-w-600'>Delivery Details</h6>
              <Row className='my-2'>
                <Col lg={6}>
                  <h6 className='my-0'>Crawl Type</h6>
                </Col>
                <Col lg={6}>
                  <h6 className='my-0'>{sitegroupDetail.crawl_type}</h6>
                </Col>
              </Row>
              <Row className='my-2'>
                <Col lg={6}>
                  <h6 className='my-0'>Data Format</h6>
                </Col>
                <Col lg={6}>
                  <h6 className='my-0'>{sitegroupDetail.delivery_format}</h6>
                </Col>
              </Row>
              <Row className='my-2'>
                <Col lg={6}>
                  <h6 className='my-0'>Frequency</h6>
                </Col>
                <Col lg={6}>
                  <h6 className='my-0'>{sitegroupDetail.delivery_frequency_in_text}</h6>
                </Col>
              </Row>
              <Row className='my-2'>
                <Col lg={6}>
                  <h6 className='my-0'>Data Delivery</h6>
                </Col>
                <Col lg={6}>
                  <h6 className='my-0'>{sitegroupDetail.delivery_method}</h6>
                </Col>
              </Row>
              <Row className='my-2'>
                <Col lg={6}>
                  <h6 className='my-0'>Creator</h6>
                </Col>
                <Col lg={6}>
                  <h6 className='my-0'>{sitegroupDetail.creator}</h6>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <h6 className='my-0 f-w-600'>Additional Details</h6>
              {additionalDetails.map(each => (
                  <Row className='my-2'>
                  <Col lg={4}>
              <h6 className='my-0'>{each.title}</h6>
            </Col>
            <Col lg={4}>
              <h6 className='my-0'>{each.status}</h6>
            </Col>
            <Col lg={4}>
              <h6 className='my-0'>{currencyCode} {each.fee}</h6>
            </Col>
          </Row>
          ))}
        </Col>
        </Row>
          <Row className='my-2'>
            <Col lg={3}>
              <h6 className='my-1 f-w-600'>Site Setup Cost</h6>
              <h6 className='my-0'>{currencyCode} {sitegroupDetail.site_setup_fee}</h6>
            </Col>
            <Col lg={3}>
              <h6 className='my-1 f-w-600'>Maintenance Cost</h6>
              <h6 className='my-0'>{currencyCode} {sitegroupDetail.monthly_maintenance_fee}</h6>
            </Col>
            <Col lg={3}>
              <h6 className='my-1 f-w-600'>Volume Costs</h6>
              <h6 className='my-0'>{currencyCode} {sitegroupDetail.volume_charges}</h6>
            </Col>
            <Col lg={3}>
              <h6 className='my-1 f-w-600'>Any Additional Cost</h6>
              <h6 className='my-0'>{currencyCode} {sitegroupDetail.any_additional_cost}</h6>
            </Col>
          </Row>
          {sitegroupDetail.unpaid_feasibility_reports_status && 
              <Row className='my-2'>
              <Col lg={12}>
          {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> 
        :<DataTable title={`Unpaid Feasibility Reports`} 
          columns={UnpaidFeasibilityFeportsColumns} data={sitegroupDetail.unpaid_feasibility_reports} 
          highlightOnHover pagination paginationServer progressPending={loading}
          paginationTotalRows={unpaidFeasibilityFeportsPagesTotal} paginationPerPage={6}
          paginationComponentOptions={{
            noRowsPerPage: true
          }}
        />}
      </Col>
    </Row>
        }
        </>}
      </ModalBody>
      <ModalFooter>
        <Button color='light' onClick={() => setModal(false)}> Close </Button>
      </ModalFooter>
    </Modal>
      <a href="#" onClick={toggleFunc}>
        {data.sitegroup_name}
      </a>
    </>
  )
}
