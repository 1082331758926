import React from 'react'
import { useState, useMemo, useEffect } from 'react';
import { Button} from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Row, Col, FormGroup } from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import ErrorMessageModal from '../../Modal/ErrorMessageModal.component';


export default function EditMembersDetailsButtonV2({ color, size, children, user, organizationName, data }){
  
  const organization_name = organizationName;
  const [initialValues, setInitialValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    designation: '',
    accessible_modules: [],
    notification_preferences: [],
  })
  const [modalOpen, setModalOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [errorModal, setErrorModal] = useState(false);

  const availableRoles = useMemo(() => [
    {
        id: 1,
        optionLabel: "CXO",
        value: "CXO",
    },
    {
        id: 2,
        optionLabel: "CTO",
        value: "CTO",
    },
    {
        id: 3,
        optionLabel: "Product Head",
        value: "Product Head",
    },
    {
        id: 4,
        optionLabel: "Technical Head",
        value: "Technical Head",
    },
    {
        id: 5,
        optionLabel: "Business owner",
        value: "Business owner",
    },
    {
        id: 6,
        optionLabel: "Analyst",
        value: "Analyst",
    },
    {
        id: 7,
        optionLabel: "Other",
        value: "Other",
    },
  ], []);

  useEffect(() => {
    if (modalOpen && data) {
      axios.get(`/api/v1/organizations/${organizationName}/members/${data.id}/edit?page_type=client&controller_name=members`).then(res => {
        setInitialValues({
          first_name: res.data?.member?.first_name, 
          last_name: res.data?.member?.last_name,
          email: res.data?.member?.email,
          designation: res.data?.member?.designation, invited_by_id: res.data?.member.invited_by_id,
          accessible_modules: res.data?.member?.accessible_modules,
          notification_preferences: res.data?.member?.notification_preferences
        });
      }).catch(err => {
        toast.error(err.response.data.message);
      })
    }
  }, [modalOpen, data]);

  function onModalOpenHandler() {
    setModalOpen(!modalOpen);
  };

  function onErrorModalOpenHandler(){
    setErrorModal(!errorModal);
  }

  const handleSubmit = (values, { setSubmitting }) => {
    axios.put(`/api/v1/organizations/${organizationName}/members/${data.id}`, {
      member: values,
      page_type: "client",
      controller_name: "members"
    }).then(res => {
      toast.success(res.data.message)
      setModalOpen(false)
      setSubmitting(false)
      setTimeout(()=>{location.reload();},650)
    }).catch(err => {
      toast.error(err.response.data.message);
    })
  }

  function handleNotificationPreferences(memberHash, accessible_module) {
    let notificationPreferences = memberHash.notification_preferences
    if (accessible_module === "data_access") {
      notificationPreferences = notificationPreferences.filter(item => item !== "redmine")
    }
    else if (accessible_module === "billing") {
      notificationPreferences = notificationPreferences.filter(item => item !== "invoice")
    }
    return notificationPreferences;
  };

  function onAccessibleModulesCheckHandler(status, element, form) {
    const { values ,setFieldValue } = form
    let temp = values
    let notification_preferences = handleNotificationPreferences(temp, element)
    if(status){
      setFieldValue("accessible_modules",[...temp.accessible_modules, element])
      setFieldValue("notification_preferences", notification_preferences)
    } else {
      axios.post(`/api/v1/organizations/${organizationName}/members/${data.id}/can_edit_accessible_modules`, {
        accessible_modules: [element],
        page_type: "client",
        controller_name: "members"
      }).then(res => {
        if (res?.data?.can_edit_accessible_modules) {
          setFieldValue("accessible_modules", temp.accessible_modules.filter(x => x != element))
          setFieldValue("notification_preferences", notification_preferences)
        } else {
          setPopupMessage(res?.data?.popup_message);
          setErrorModal(true);
        }
      }).catch(err => {
        toast.error(err?.response.data.message);
      })
    }
  };

  function onNotificationPreferencesCheckHandler(status, element, form) {
    const { values ,setFieldValue } = form
    let temp = values
    if(status){
      setFieldValue("notification_preferences",[...temp.notification_preferences, element])
    } else {
      axios.post(`/api/v1/organizations/${organizationName}/members/${data.id}/can_edit_notification_preferences`, {
        notification_preferences: [element],
        page_type: "client",
        controller_name: "members"
      }).then(res => {
        if (res.data.can_edit_notification_preferences) {
          setFieldValue("notification_preferences", temp.notification_preferences.filter(x => x != element))
        } else {
          setPopupMessage(res?.data?.popup_message);
          setErrorModal(true);
        }
      }).catch(err => {
        toast.error(err.response.data.message);
      })
    }
  };

  const renderFormGroup = (name, label, type) => {
    return(
      <FormGroup row className='my-4'>
        <Label htmlFor={name} tag="h6" className='my-0 h6' sm={2}>{label}</Label>
        <Col sm={10}>
          <Field type={type} name={name} className="form-control" />
          <ErrorMessage name={name} component="p" className='text-danger m-0' />
        </Col>
      </FormGroup>
    )
  }

  return(
      <>
        <div>
          <Modal funk={"true"} isOpen={modalOpen} toggle={onModalOpenHandler} size='lg'>
            <ModalHeader toggle={onModalOpenHandler}>
                Edit Member: {organization_name.replaceAll("_", " ")}
            </ModalHeader>
            <ModalBody className='px-5'>
                <Formik
                  initialValues={initialValues}
                  enableReinitialize={true}
                  validationSchema={Yup.object().shape({
                    first_name: Yup.string().required("First Name is required"),
                    last_name: Yup.string().required("Last Name is required"),
                    email: Yup.string().required("Email is required"),
                    designation: Yup.string().required("Role is required"),
                    accessible_modules: Yup.array().min(1,"Atleast one Accessible module is required")
                  })}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting, values, setFieldValue})=>(
                    <Form>
                      <Row className='d-flex justify-content-center'>
                        <Col sm={11}>
                          {renderFormGroup("first_name", "First Name", "text")}
                          {renderFormGroup("last_name", "Last Name", "text")}
                          {renderFormGroup('email', 'Email', 'email')}
                          <FormGroup row className='my-4'>
                            <Label htmlFor="designation" tag="h6" className='my-0 h6' sm={2}>Designation</Label>
                            <Col sm={10}>
                              <Field name="designation" as="select" className="form-select">
                                <option value={""} selected>Select Designation</option>
                                {
                                  availableRoles?.map(item => <option value={item?.value}>{item?.optionLabel}</option>)
                                }
                              </Field>
                              <ErrorMessage name="designation" component="p" className='text-danger m-0' />
                            </Col>
                          </FormGroup>
                          <FormGroup row className='my-4'>
                            <Label htmlFor='accessible_modules' tag={"h6"} className='my-0 h6 d-flex align-items-center' sm={2}>Accessible Modules</Label> 
                            <Col sm={10} className='d-flex flex-column justify-content-center'>
                              <Label className="form-check-label" check>
                                <Field 
                                type="checkbox" 
                                name="accessible_modules" 
                                value="data_access" 
                                className="form-check-input "
                                onChange={(event) => onAccessibleModulesCheckHandler(event.target.checked, 'data_access', {values, setFieldValue})}
                                 />
                                <span className='mx-1 h6'>Data Access </span>
                              </Label>
                              <Label className="form-check-label" check>
                                <Field 
                                type="checkbox" 
                                name="accessible_modules" 
                                value="billing" 
                                className="form-check-input" 
                                onChange={(event) => onAccessibleModulesCheckHandler(event.target.checked, 'billing', {values, setFieldValue})}
                                />
                                <span className='mx-1 h6'>Billing</span>
                              </Label>
                              <Label className="form-check-label" check>
                                <Field 
                                type="checkbox" 
                                name="accessible_modules" 
                                value="user_management" 
                                className="form-check-input"
                                onChange={(event) => onAccessibleModulesCheckHandler(event.target.checked, 'user_management', {values, setFieldValue})} />                                
                                <span className='mx-1 h6'>User Management</span>
                              </Label>
                            </Col>
                            <ErrorMessage name="accessible_modules" component="p" className='text-danger m-0' />
                          </FormGroup>
                          <FormGroup row className='my-4'>
                            <Label htmlFor='notification_preferences' tag={"h6"} className='my-0 h6 d-flex align-items-center' sm={2}>Notification Preferences</Label> 
                            <Col sm={10} className='d-flex flex-column justify-content-center'>
                              <Label className="form-check-label" check>
                                <Field 
                                type="checkbox" 
                                name="notification_preferences" 
                                value="redmine" 
                                disabled={!values.accessible_modules.includes("data_access")} 
                                className="form-check-input "
                                onChange={(event) => onNotificationPreferencesCheckHandler(event.target.checked, 'redmine', {values, setFieldValue})}
                                />
                                <span className='mx-1 h6'>Redmine</span>
                              </Label>
                              <Label className="form-check-label" check>
                                <Field 
                                type="checkbox" 
                                name="notification_preferences"
                                 value="invoice" 
                                 disabled={!values.accessible_modules.includes("billing")}  
                                 className="form-check-input" 
                                 onChange={(event) => onNotificationPreferencesCheckHandler(event.target.checked, 'invoice', {values, setFieldValue})}
                                 />
                                <span className='mx-1 h6'>Invoice</span>
                              </Label>
                            </Col>
                            <ErrorMessage name="notification_preferences" component="p" className='text-danger m-0' />
                          </FormGroup>
                        </Col>
                        <hr className="my-3" />
                        <Col sm={12} className='d-flex justify-content-end'>
                          <Button color='light' onClick={() => setModalOpen(false)} className='mx-2' size='md'>Close</Button>
                          <Button color="success" type='submit'
                            disabled={isSubmitting} size='md'>Update</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
            </ModalBody>
          </Modal>
          {
          errorModal && (<ErrorMessageModal messageContent={popupMessage} errorModalOpenHandler={onErrorModalOpenHandler} />)
          }
        </div>
        <Button color={color} size={size} onClick={onModalOpenHandler} >{children}</Button>
      </>
  )
}
