import React, {useEffect, useState} from 'react';
import { Card, CardBody, Row, Col, CardHeader, Modal, Button, 
  ModalBody, ModalHeader, ModalFooter, FormGroup, Input, Label} from 'reactstrap';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import getSymbolFromCurrency from 'currency-symbol-map';

export default function SetBillingAlarms({orgName, country}) {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [billingAlarms, setBillingAlarms] = useState({
    'records_count' : {'alert_by': 'records_count', 'records_count': 0, selected: true},
    'bill_amount': {'alert_by': 'bill_amount', 'bill_amount': 0, selected: false},
  })

  const toggle = () => {
    setModal(curr => !curr)
  }

  const closeBtn = <button className="close" 
    onClick={() => setModal(false)}> &times; </button>;

  const handleRadioChange = (e, name) => {
    if (name === 'bill_amount') {
      setBillingAlarms({
        bill_amount: {...billingAlarms.bill_amount, selected: true},
        records_count: {...billingAlarms.records_count, selected: false},
      })
    }
    else{
      setBillingAlarms({
        bill_amount: {...billingAlarms.bill_amount, selected: false},
        records_count: {...billingAlarms.records_count, selected: true},
      })
    }
  }

  const postBillingAlarms = () => {
    setLoading(true)
    if (billingAlarms.records_count.selected){
      axios.post(`/api/v1/organizations/${orgName}/organization_billing_alerts`, {
        billing_alerts: billingAlarms.records_count,
        page_type: "client",
        controller_name: "organization_billing_alerts"
      }).then(res => {
        toast.success(res.data.message)
        setModal(false)
      }).catch(err => {
        toast.error(err.response.data.message)
      })
      setLoading(false)
    }
    else{
      axios.post(`/api/v1/organizations/${orgName}/organization_billing_alerts`, {
        billing_alerts: billingAlarms.bill_amount,
        page_type: "client",
        controller_name: "organization_billing_alerts"
      }).then(res => {
        toast.success(res.data.message)
        setModal(false)
      }).catch(err => {
        toast.error(err.response.data.message)
      })
      setLoading(false)
    }

  }

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop='static' size='lg'>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Set Billing Alarms
        </ModalHeader>
        <ModalBody className='p-5'>
          <div>
            <h6 className='fw-bold mb-5'>
              Notify me when:
            </h6>
            <FormGroup check className='d-flex my-3'>
              <Input name="radio1" type="radio" className='me-3'
                checked={billingAlarms.records_count.selected}
                onChange={(e) => handleRadioChange(e, 'records_count')}/>
              {' '}
              <Label check className='px-1'>
                total records count for the month exceeds
              </Label>
              <Input id="billingAlarms" className='form-control ms-2' type='text' placeholder='100'
                value={billingAlarms.records_count.records_count} onChange={(e) => setBillingAlarms({
                  ...billingAlarms, records_count: {...billingAlarms.records_count, records_count: e.target.value}
                })}
                disabled={!billingAlarms.records_count.selected} style={{width: 240}} />
            </FormGroup>
            <FormGroup check className='d-flex my-3'>
              <Input name="radio1" type="radio" className='me-3'
                checked={billingAlarms.bill_amount.selected}
                onChange={(e) => handleRadioChange(e, 'bill_amount')}/>
              {' '}
              <Label check className='px-1'>
                total charges for the month exceeds
              </Label>
              <div className='d-flex align-items-center ms-2'>
                <button className='btn text-primary px-1'>{getSymbolFromCurrency(country)}</button>
                <Input className='form-control ms-2' type='number'
                  value={billingAlarms.bill_amount.bill_amount} onChange={(e) => setBillingAlarms({
                    ...billingAlarms, bill_amount: {...billingAlarms.bill_amount, bill_amount: e.target.value}
                  })}
                  disabled={!billingAlarms.bill_amount.selected} style={{width: 240}} />
              </div>
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          {
            loading ?
            <>
              <Button className='primary mx-2' disabled>
                Loading ...
              </Button>
            </> :
            <>
              <Button color="primary mx-2" onClick={() => postBillingAlarms()}>
                Set Alarm
              </Button>
            </> 
          }
        </ModalFooter>
      </Modal>
      <Button className='mx-2' color="primary" onClick={toggle}> Set Billing Alarms </Button>
    </>
  )
}

