import React, {useState, useEffect} from 'react'
import { Container, Row, Col } from 'reactstrap'
import axios from 'axios'
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker'

export default function DataStockGenerator() {
    const [loadingBtns, setLoadingBtns] = useState(false)
    const [errors, setErrors] = useState({})
    const [dropdownOptions, setDropdownOptions] = useState({})
    const [selectedSite, setSelectedSite] = useState({})
    const [selectedFromDate, setSelectedFromDate] = useState('')
    const [selectedToDate, setSelectedToDate] = useState('')
    const [category, setCategory] = useState('')
    const [domain, setDomain] = useState('')
    const [unique, setUnique] = useState(false)
    const [normalizationSep, setNormalizationSep] = useState('')
    const [ticketNumber, setTicketNumber] = useState('')

    const today = new Date(Date.now())
    useEffect(() => {
        fetchSiteNames();
    }, [])
    
    function fetchSiteNames() {
        axios.get('/api/v1/internal/dropdown_list_helper')
        .then(res => {
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
            let group = {}, children = []
            group['label'] = <div className="fw-bold col text-black">{key}</div>
            site_names[key].map(item => {
                children.push({ label: item, value: item })
            })
            group['options'] = children;
            site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setDropdownOptions(site_names_options)
        })
        .catch(err => {
            console.error(err?.message || 'Oops something went wrong')
        })
    }
      
    const promiseOptions = (inputValue) => {
        if(inputValue.length < 3){
            return null
        } else {
            return new Promise((resolve) => {
            axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
                if (res.status == '200'){
                let site_names = res.data.site_names, site_names_options = []
                for (var key in site_names) {
                    let group = {}, children = []
                    group['label'] = <div className="fw-bold col text-black">{key}</div>
                    site_names[key].map(item => {
                    children.push({ label: item, value: item })
                    })
                    group['options'] = children;
                    site_names_options.push(group)
                }
                site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                setDropdownOptions(site_names_options)
                resolve(site_names_options)
                }
            }).catch(err => {
                    console.log(`err`, err)
                })
            })
        }
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('/');
    }

    const handleSubmit = () => {
        setLoadingBtns(true)
        setErrors({})
        let tempObj = {}
        if (!(selectedSite?.value)){
            tempObj = {...tempObj, site:true}
        }
        if (selectedFromDate?.length < 1){
            tempObj = {...tempObj, from_date:true}
        }
        if (selectedToDate?.length < 1){
            tempObj = {...tempObj, to_date:true}
        }
        if (category?.length < 1){
            tempObj = {...tempObj, category:true}
        }
        if (domain?.length < 1){
            tempObj = {...tempObj, domain:true}
        }
        if (ticketNumber?.length < 1){
            tempObj = {...tempObj, ticket:true}
        }
        setErrors(tempObj)

        if (Object.keys(tempObj)?.length == 0){
            let resultObj = {
                "outer_command": "datastock_generator", "inner_command": "datastock_generator",
                "nested_inner_command": "datastock_generator", "to_date": formatDate(selectedToDate),
                "site_name": selectedSite?.value, "from_date": formatDate(selectedFromDate), 
                "category": category, "domain_name": domain, "unique": unique ? "true": "false",
                "normalization_separator": normalizationSep, "ticket_id": ticketNumber
            }
            axios.post('/api/v1/internal/command_dashboard/datastock_generator', {
                filter: resultObj
            }).then(res => {
                toast.success(res?.data?.message)
                setLoadingBtns(false)
                window.location = `/internal/command_dashboard`
            }).catch(err => {
                toast.error(err?.response?.data?.message)
                setLoadingBtns(false)
            })
        }
        else {
            setLoadingBtns(false)
        }
    }

    return (
        <Container fluid>
            <Row>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Outer Command (Non-Editable)</h6>
                    <input className='form-control' value='datastock_generator'
                        disabled />
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Inner Command (Non-Editable)</h6>
                    <input className='form-control' value='datastock_generator'
                        disabled />
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Nested Inner Command (Non-Editable)</h6>
                    <input className='form-control' value='datastock_generator'
                        disabled />
                </Col>
                <Col md={12} className='my-3'>
                    <h6 className='fw-bold'>Site Name*</h6>
                    <AsyncSelect cacheOptions defaultOptions={dropdownOptions} name='site_name' 
                        value={selectedSite} loadOptions={promiseOptions} onChange={res => setSelectedSite(res)}
                        placeholder="Select a site name" />
                    {errors?.site && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                    <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>From Date*</h6>
                    <DatePicker className="form-control my-2" selected={selectedFromDate}
                        dateFormat="yyyy/MM/dd"
                        maxDate={today} onChange={(date) => setSelectedFromDate(date)} />
                    {errors?.from_date && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>To Date*</h6>
                    <DatePicker className="form-control my-2" selected={selectedToDate}
                        dateFormat="yyyy/MM/dd"
                        maxDate={today} onChange={(date) => setSelectedToDate(date)} />
                    {errors?.to_date && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Category*</h6>
                    <input className='form-control' type='text' value={category}
                        onChange={(e) => setCategory(e.target?.value)} />
                    {errors?.category && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Domain*</h6>
                    <input className='form-control' type='text' value={domain}
                        onChange={(e) => setDomain(e.target?.value)} />
                    {errors?.domain && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Select Option</h6>
                    <div className='d-flex'>
                        <input type="checkbox" className='form-check me-2' checked={unique}
                            onChange={(e) => setUnique(e?.target?.checked)} />
                        <h6 className='fw-bold m-0'>Unique</h6>
                    </div>
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Normalization Separator (Optional)</h6>
                    <input className='form-control' type='text' value={normalizationSep}
                        onChange={(e) => setNormalizationSep(e.target?.value)} />
                    <p className='text-muted'>Please use comma(,) for CSV</p>
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Ticket Number*</h6>
                    <input className='form-control' type='text' value={ticketNumber}
                        onChange={(e) => setTicketNumber(e.target?.value)} />
                    {errors?.ticket && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                </Col>

                <Col md={6} className='my-3'>
                    <button className='btn btn-success' onClick={handleSubmit}
                        disabled={loadingBtns} style={{margin: '25px auto', width: '100%'}}> Submit </button>
                </Col>
            </Row>
        </Container>
    )
}
