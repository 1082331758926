import React, {Fragment, useEffect, useState} from 'react'
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, CardBody, Card, Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import AsyncSelect from 'react-select/async';
import axios from 'axios'
import DataTable from 'react-data-table-component';
import ReactTooltip from 'react-tooltip';
import Loader from 'react-loader-spinner';
import { useLocation } from 'react-router-dom'
import { Home, Info, RefreshCcw } from 'react-feather';

import { KIBANA_PLM_URL } from '../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints';

export default function RabbitmqQueueSearch() {
    const search = useLocation().search
    const siteValue = new URLSearchParams(search).get('filter[site]')
    const queueValue = new URLSearchParams(search).get('filter[queue_type]')
    const serverValue = new URLSearchParams(search).get('filter[server_name]')
    const refreshQueueValue = new URLSearchParams(search).get('filter[refresh_queue_name]')

    const [dropdownOptions, setDropdownOptions] = useState({})
    const [selectedSite, setSelectedSite] = useState({value:siteValue, label: siteValue == '' ? 'All' : siteValue})
    const [searchValue, setSearchValue] = useState('')
    const [data, setData] = useState({default_from: new Date().toISOString().split('T')[0].replaceAll('-', '/'), 
        default_to: new Date().toISOString().split('T')[0].replaceAll('-', '/')})
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchSiteNames();
    }, [])

    useEffect(() => {
        if (siteValue && serverValue ){
            setLoading(true)
            axios.get(`/api/v1/internal/queue_sizes/search?filter[site]=${siteValue}${refreshQueueValue ? `&filter[refresh_queue_name]=${refreshQueueValue}` : ''}${serverValue ? `&filter[server_name]=${serverValue}` : ''}${queueValue ? `&filter[queue_type]=${queueValue}` : ''}`).then(res => {
                console.log(res?.data)
                setData(res?.data)
                setLoading(false)
            }).catch(err => {
                setLoading(false)
            })
        }
        else if (siteValue){
            setLoading(true)
            axios.get(`/api/v1/internal/queue_sizes/search?filter[site]=${siteValue}`).then(res => {
                console.log(res?.data)
                setData(res?.data)
                setLoading(false)
            }).catch(err => {
                setLoading(false)
            })
        }
    }, [siteValue, serverValue, queueValue, refreshQueueValue])
    
    useEffect(() => {
        setTableData(data?.queue_sizes)
    }, [data])

    useEffect(() => {
        if (searchValue){
            let res = []
            data?.queue_sizes.forEach(each => {
              if (each?.queue_name?.includes(searchValue) || each?.server?.toString()?.includes(searchValue)
                || each?.rss_crawl_push_id?.toString()?.includes(searchValue)
                || each?.queue_size?.toString()?.includes(searchValue) || each?.queue_size?.toString()?.includes(searchValue)){
                res.push(each)
              }
            })
            setTableData(res)
          } else {
            setTableData(data?.queue_sizes)
          }
    }, [searchValue])
    

    function fetchSiteNames() {
        axios.get('/api/v1/internal/dropdown_list_helper')
          .then(res => {
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
              site_names[key].map(item => {
                children.push({ label: item, value: item })
              })
              group['options'] = children;
              site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setDropdownOptions(site_names_options)
          })
          .catch(err => {
            console.error(err?.message || 'Oops something went wrong')
          })
    }

    const promiseOptions = (inputValue) => {
        if(inputValue.length < 3){
          return null
        } else {
          return new Promise((resolve) => {
            axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
              if (res.status == '200'){
                let site_names = res.data.site_names, site_names_options = []
                for (var key in site_names) {
                  let group = {}, children = []
                  group['label'] = <div className="fw-bold col text-black">{key}</div>
                  site_names[key].map(item => {
                    children.push({ label: item, value: item })
                  })
                  group['options'] = children;
                  site_names_options.push(group)
                }
                site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                setDropdownOptions(site_names_options)
                resolve(site_names_options)
              }
            }).catch(err => {
              console.log(`err`, err)
            })
          })
        }
    }

    const getData = () => {
        window.location.href= `/internal/queue_sizes/search?utf8=✓&filter%5Bsite%5D=${selectedSite?.value}&commit=Get+Data`
    }

    const columns = [
        {name: 'Queue Name',selector: 'queue_name',center:true, grow: 3, sortable: true },
        {name: 'Rss Crawl Push Id', selector: 'rss_crawl_push_id',center:true, sortable: true, cell: each => <>
            {each?.rss_crawl_push_id ? <>{each?.rss_crawl_push_id}</> : 'Not Available'}
        </> },
        {name: 'Queue Size',selector: 'queue_size',center:true, sortable: true, cell: each => <div className='d-flex align-items-center justify-content-between'>
            <p className='my-0 me-2'>{each?.queue_size}</p>
            <a href={`/internal/queue_sizes/search?filter[refresh_queue_name]=${each?.queue_name}${each?.server ? `&filter[server_name]=${each?.server}` : ''}${queueValue ? `&filter[queue_type]=${queueValue}`: ''}${siteValue ? `&filter[site]=${siteValue}`: ''}`} target='_blank'>
                <RefreshCcw size={14} /> </a>
        </div>},
        {name: 'Queue Type',selector: 'queue_type', center:true, sortable: true },
        {name: 'Server Name',selector: 'server',center:true, sortable: true },
        {name: 'Actions',selector: 'action',center:true, cell: () => <button className='btn btn-xs btn-danger'>Delete Queue</button>},
    ]

    return (
        <Container fluid>
            <Fragment>
                <Container fluid>
                    <div className="page-title">
                        <Row className=''>
                            <Col xs="6">
                                <h3>Internal Dashboard</h3>
                            </Col>
                            <Col xs="6">
                            <Breadcrumb>
                                <BreadcrumbItem>
                                    <Link to={`/internal`}><Home /></Link>
                                </BreadcrumbItem>
                                <BreadcrumbItem>
                                    <Link to={`/internal/queue_sizes`}>
                                        All Queues
                                    </Link>
                                </BreadcrumbItem>
                                <BreadcrumbItem active>
                                    <b style={{textTransform: 'lowercase'}}>{siteValue}</b>
                                </BreadcrumbItem>
                            </Breadcrumb>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </Fragment>

            <Card>
                <CardBody>
                    <Row className='my-3'>
                        <Col md={10}>
                            <h6 className='fw-bold'>Site Name: </h6>
                            <AsyncSelect cacheOptions defaultOptions={dropdownOptions} name='site_name' 
                                value={selectedSite} loadOptions={promiseOptions} onChange={res => setSelectedSite(res)}
                                placeholder="Select a site name" />
                                <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
                        </Col>
                        <Col md={2}>
                            <button className='btn btn-success btn-block w-100 d-flex text-center' 
                                style={{margin: '26px auto'}} onClick={getData}>Get Data</button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
            : <Card>
                <CardBody>
                    <p>Queue name and sizes from <b>All server</b> {queueValue && `, Queue type: ${queueValue}` }</p>
                    <div className='my-2'>
                        <Table responsive bordered>
                            <thead>
                                <tr>
                                    <th className='fw-bold'>Site Name</th>
                                    <th className='fw-bold'>Aggregated queue size</th>
                                    <th className='fw-bold'>Kibana Link</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{data?.site_name}</td>
                                    <td>{data?.summation}</td>
                                    <td>
                                        <a href={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=line&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(pause:!t,value:0),time:(from:'${new Date(data?.default_from).toISOString()}',to:'${new Date(data?.default_to).toISOString()}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'queue_size_tracker:true+and+name:%22+${data?.site_name}_*%22'),uiState:(),vis:(aggs:!((enabled:!t,id:'1',params:(),schema:metric,type:count),(enabled:!t,id:'2',params:(drop_partials:!f,extended_bounds:(),field:'@timestamp',interval:h,min_doc_count:1,scaleMetricValues:!f,timeRange:(from:now-30d,to:now),useNormalizedEsInterval:!t),schema:segment,type:date_histogram)),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,categoryAxes:!((id:CategoryAxis-1,labels:(filter:!t,show:!t,truncate:100),position:bottom,scale:(type:linear),show:!t,style:(),title:(),type:category)),dimensions:(x:(accessor:0,aggType:date_histogram,format:(id:date,params:(pattern:'YYYY-MM-DD+HH:mm')),params:(bounds:(max:'2022-02-17T06:51:09.516Z',min:'2022-01-18T06:51:09.516Z'),date:!t,format:'YYYY-MM-DD+HH:mm',interval:PT12H,intervalESUnit:h,intervalESValue:12)),y:!((accessor:1,aggType:count,format:(id:number),params:()))),grid:(categoryLines:!f),labels:(),legendPosition:right,seriesParams:!((data:(id:'1',label:Count),drawLinesBetweenPoints:!t,interpolate:linear,lineWidth:2,mode:normal,show:!t,showCircles:!t,type:line,valueAxis:ValueAxis-1)),thresholdLine:(color:%2334130C,show:!f,style:full,value:10,width:1),times:!(),type:line,valueAxes:!((id:ValueAxis-1,labels:(filter:!f,rotate:0,show:!t,truncate:100),name:LeftAxis-1,position:left,scale:(mode:normal,type:linear),show:!t,style:(),title:(text:Count),type:value))),title:'',type:line))`} target='_blank'>Click Here</a>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className='mb-2 mt-4'>
                        <h4>Queue Size of Running Crawl</h4>
                        <Row className='mb-2'>
                            <Col md={7} className='my-3'></Col>
                            <Col md={5} className='my-3'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <h6 className='my-0 me-2'>Search:</h6>
                                    <input type='text' className='form-control' value={searchValue} 
                                    onChange={(e) => setSearchValue(e.target?.value)} />
                                </div>
                            </Col>
                            <DataTable columns={columns} data={tableData} noHeader responsive className='my-2' pagination />
                        </Row>
                    </div>
                </CardBody>
            </Card>}
        </Container>
    )
}
