/**
 * This is the legacy component which is scheduled for removal once the newly refactored component passes the QA
 * The newly re-factored component can be found in /app/javascript/components/V2/InternalDashboardPages/SegmentsTakeMoreTimeByEDNUStageWise/SegmentsTakeMoreTimeByEDNUStageWise.index.jsx
 * This component is no longer rendered
 */

import React, { useState } from "react";
import { Card, CardBody, Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import { Home } from "react-feather";
import DatePicker from "react-datepicker";

import { useLocation } from "react-router-dom";
import moment from "moment";

import DataTab from "./Tabs/DataTab.component";

export default function SegmentTakeMoreTimeEDNUStagewise() {
  const seach = useLocation().search;
  const commit = new URLSearchParams(seach).get("commit");

  const [date, setDate] = useState(new Date());
  const [activeTab, setActiveTab] = useState('Extraction');
  

  const handleGetData = () => {
    let crawlDate = moment(date).format('YYYY/MM/DD');
    window.location.href = `/internal/overall_system_reports/segments_take_more_time_by_ednu_stage_wise?filter[crawl_date]=${crawlDate}&commit=${'Get Data'}`
  }
  return (
    <div>
      <Container fluid>
        <Container fluid>
          <div className="page-title">
            <Row className=''>
              <Col xs="6">
                <h3>Internal Dashboard</h3>
              </Col>
              <Col xs="6">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`/internal`}><Home /></Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem >
                    {commit == 'Get Data' ? <Link to={'/internal/overall_system_reports/segments_take_more_time_by_ednu_stage_wise'}>Segments take more time by ednu stage wise</Link> : 'Segments take more time by ednu stage wise'}
                  </BreadcrumbItem>
                  {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                    Search
                  </BreadcrumbItem>}
                </Breadcrumb>
              </Col>
            </Row>
          </div>
        </Container>
            <Card>
              <CardBody>
                <Row className="align-items-end">
                  <Col sm="5">
                    <label className="fw-bold">Crawl Date</label>
                    <DatePicker
                      className="form-control"
                      selected={new Date(date)}
                      maxDate={new Date()}
                      onChange={(value) => setDate(value)}
                      dateFormat="yyyy/MM/dd"
                    />
                  </Col>
                  <Col sm="2">
                    <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Nav tabs>
                  <NavItem>
                    <NavLink className={activeTab == 'Extraction' ? 'active' : ''} onClick={() => setActiveTab('Extraction')}>
                      Extraction
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={activeTab == 'Deadup' ? 'active' : ''} onClick={() => setActiveTab('Deadup')}>
                      Dedup
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={activeTab == 'Normalization' ? 'active' : ''} onClick={() => setActiveTab('Normalization')}>
                      Normalization
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={activeTab == 'Upload' ? 'active' : ''} onClick={() => setActiveTab('Upload')}>
                      Upload
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={'Extraction'}>
                    <DataTab title='Extraction' />
                  </TabPane>
                  <TabPane tabId={'Deadup'}>
                    <DataTab title='Deadup' />
                  </TabPane>
                  <TabPane tabId={'Normalization'}>
                    <DataTab title='Normalization' />
                  </TabPane>
                  <TabPane tabId={'Upload'}>
                    <DataTab title='Upload' />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
      </Container>
    </div>
  )
}
