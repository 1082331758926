import React from 'react'
import { useState, useMemo } from 'react';
import { Button} from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Row, Col, FormGroup } from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

export default function InviteMembersButtonV2({ color, size, children, ...otherProps }){

  const organization_name = otherProps.organization;
  const [modalOpen, setModalOpen] = useState(false);

  const availableRoles = useMemo(() => [
    {
        id: 1,
        optionLabel: "CXO",
        value: "CXO",
    },
    {
        id: 2,
        optionLabel: "CTO",
        value: "CTO",
    },
    {
        id: 3,
        optionLabel: "Product Head",
        value: "Product Head",
    },
    {
        id: 4,
        optionLabel: "Technical Head",
        value: "Technical Head",
    },
    {
        id: 5,
        optionLabel: "Business owner",
        value: "Business owner",
    },
    {
        id: 6,
        optionLabel: "Analyst",
        value: "Analyst",
    },
    {
        id: 7,
        optionLabel: "Other",
        value: "Other",
    },
  ], []);

  function onModalOpenHandler() {
    setModalOpen(!modalOpen);
  };

  const renderFormGroup = (name, label, type) => {
    return(
      <FormGroup row className='my-4'>
        <Label htmlFor={name} tag="h6" className='my-0 h6' sm={2}>{label}</Label>
        <Col sm={10}>
          <Field type={type} name={name} className="form-control" />
          <ErrorMessage name={name} component="p" className='text-danger m-0' />
        </Col>
      </FormGroup>
    )
  }

  const handleSubmit = (values, { setSubmitting }) => {
    let updatedNotificationPreferences = [...values.notification_preferences];

    if (!values.accessible_modules.includes("data_access")) {
        updatedNotificationPreferences = updatedNotificationPreferences.filter(pref => pref !== "redmine");
    }

    if (!values.accessible_modules.includes("billing")) {
        updatedNotificationPreferences = updatedNotificationPreferences.filter(pref => pref !== "invoice");
    }

    const updatedValues = {
        ...values,
        notification_preferences: updatedNotificationPreferences
    };

    updatedValues["invited_by_id"] = otherProps.id
    updatedValues["product_type"] = 'custom_projects'

    axios.post(`/api/v1/organizations/${organization_name}/user_invites`, {
      user_invite: updatedValues,
      page_type: "client",
      controller_name: "user_invites"
    }).then(res => {
        toast.success(res.data.message);
        setModalOpen(false);
        setSubmitting(false)
        setTimeout(()=>{location.reload();},650);
    }).catch(err => {
        toast.error(err.response.data.message);
        setSubmitting(false)
    })
  }


  return(
      <>
        <div>
          <Modal funk={"true"} isOpen={modalOpen} toggle={onModalOpenHandler} size='lg'>
            <ModalHeader toggle={onModalOpenHandler}>
                Add Member: {organization_name.replaceAll("_", " ")}
            </ModalHeader>
            <ModalBody className='px-5'>
                <Formik
                  initialValues={{
                    first_name:"",
                    last_name:"",
                    email:"",
                    designation:"",
                    accessible_modules: [],
                    notification_preferences: []
                  }}
                  validationSchema={Yup.object().shape({
                    first_name: Yup.string().required("First Name is required"),
                    last_name: Yup.string().required("Last Name is required"),
                    email: Yup.string().required("Email is required"),
                    designation: Yup.string().required("Role is required"),
                    accessible_modules: Yup.array().min(1,"Atleast one Accessible module is required")
                  })}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting, values, setFieldValue})=>(
                    <Form>
                      <Row className='d-flex justify-content-center'>
                        <Col sm={11}>
                          {renderFormGroup("first_name", "First Name", "text")}
                          {renderFormGroup("last_name", "Last Name", "text")}
                          {renderFormGroup('email', 'Email', 'email')}
                          <FormGroup row className='my-4'>
                            <Label htmlFor="designation" tag="h6" className='my-0 h6' sm={2}>Designation</Label>
                            <Col sm={10}>
                              <Field name="designation" as="select" className="form-select">
                                <option value={""} selected>Select Designation</option>
                                {
                                  availableRoles?.map(item => <option value={item?.value}>{item?.optionLabel}</option>)
                                }
                              </Field>
                              <ErrorMessage name="designation" component="p" className='text-danger m-0' />
                            </Col>
                          </FormGroup>
                          <FormGroup row className='my-4'>
                            <Label htmlFor='accessible_modules' tag={"h6"} className='my-0 h6 d-flex align-items-center' sm={2}>Accessible Modules</Label> 
                            <Col sm={10} className='d-flex flex-column justify-content-center'>
                              <Label className="form-check-label" check>
                                <Field type="checkbox" name="accessible_modules" value="data_access" className="form-check-input " />
                                <span className='mx-1 h6'>Data Access </span>
                              </Label>
                              <Label className="form-check-label" check>
                                <Field type="checkbox" name="accessible_modules" value="billing" className="form-check-input" />
                                <span className='mx-1 h6'>Billing</span>
                              </Label>
                              <Label className="form-check-label" check>
                                <Field type="checkbox" name="accessible_modules" value="user_management" className="form-check-input" />                                
                                <span className='mx-1 h6'>User Management</span>
                              </Label>
                            </Col>
                            <ErrorMessage name="accessible_modules" component="p" className='text-danger m-0' />
                          </FormGroup>
                          <FormGroup row className='my-4'>
                            <Label htmlFor='notification_preferences' tag={"h6"} className='my-0 h6 d-flex align-items-center' sm={2}>Notification Preferences</Label> 
                            <Col sm={10} className='d-flex flex-column justify-content-center'>
                              <Label className="form-check-label" check>
                                <Field type="checkbox" name="notification_preferences" value="redmine" disabled={!values.accessible_modules.includes("data_access")} className="form-check-input " />
                                <span className='mx-1 h6'>Redmine</span>
                              </Label>
                              <Label className="form-check-label" check>
                                <Field type="checkbox" name="notification_preferences" value="invoice" disabled={!values.accessible_modules.includes("billing")}  className="form-check-input" />
                                <span className='mx-1 h6'>Invoice</span>
                              </Label>
                            </Col>
                            <ErrorMessage name="notification_preferences" component="p" className='text-danger m-0' />
                          </FormGroup>
                        </Col>
                        <hr className="my-3" />
                        <Col sm={12} className='d-flex justify-content-end'>
                          <Button color='light' onClick={() => setModalOpen(false)} className='mx-2' size='md'>Close</Button>
                          <Button color="success" type='submit'
                            disabled={isSubmitting} size='md'>Send Invite</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
            </ModalBody>
          </Modal>
        </div>
        <Button color={color} size={size} onClick={onModalOpenHandler} {...otherProps} >{children}</Button>
      </>
  )
}
