import axios from "axios";
import React, { useEffect, useState } from "react";
import { Home } from "react-feather";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row } from "reactstrap";

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function PipeLineStageWiseSeleniumBrowserReqCount() {
    const search = useLocation().search;
    const commit = new URLSearchParams(search).get("commit");
    const searchParams = {
        siteName: new URLSearchParams(search).get("filter[site_name]"),
        time: new URLSearchParams(search).get("filter[time]"),
        pipeLineStage: new URLSearchParams(search).get("filter[pipeline_stage]")
    }

    const [time, setTime] = useState({ label: 'Last 4 hours', value: 'now-4h' });
    const [siteName, setSiteName] = useState({ label: 'All', value: 'all' });
    const [pipeLineStage, setPipeLineStage] = useState({ label: 'All', value: 'all' });
    const [siteNameOptions, setSiteNameOptions] = useState([]);
    const [urlParams, setUrlParams] = useState({
        siteName: '*',
        pipeLineStage: '*',
        time: 'now-4h'
    })
    const timeOptions = [
        { label: 'Today', value: 'now%2Fd' },
        { label: 'Last 30 minutes', value: 'now-30m' },
        { label: 'Last 1 hour', value: 'now-1h' },
        { label: 'Last 2 hours', value: 'now-2h' },
        { label: 'Last 4 hours', value: 'now-4h' },
        { label: "Last 10 hours", value: "now-10h" },
        { label: 'Last 12 hours', value: 'now-12h' },
        { label: 'Last 24 hours', value: 'now-24h' },
        { label: 'Last 2 days', value: 'now-2d' },
        { label: 'Last 3 days', value: 'now-3d' },
        { label: 'Last 4 days', value: 'now-4d' },
        { label: 'Last 7 days', value: 'now-7d' },
        { label: 'Last 15 days', value: 'now-15d' },
        { label: 'Last 30 days', value: 'now-30d' },
        { label: 'Last 60 days', value: 'now-60d' },
        { label: 'Last 1 year', value: 'now-1y' }
    ];

    const pipeLineOptions = [
        { label: 'All', value: 'all' },
        { label: 'Rss', value: 'RSS' },
        { label: 'Feedcrawl', value: 'feedcrawl' },
        { label: 'Dependent Data Upload', value: 'dependent_data_upload' },

    ]

    useEffect(() => {
        setSearchParamsData();
        axios.get('/api/v1/internal/dropdown_list_helper')
            .then(res => {
                let site_names = res.data.site_names, site_names_options = []
                for (var key in site_names) {
                    let group = {}, children = []
                    group['label'] = <div className="fw-bold col text-black">{key}</div>
                    site_names[key].map(item => {
                        children.push({ label: item, value: item })
                    })
                    group['options'] = children;
                    site_names_options.push(group)
                }
                site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                setSiteNameOptions(site_names_options);

            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong')
            });
    }, []);

    function setSearchParamsData() {
        if (searchParams.siteName && searchParams.siteName != siteName.value) {
            setSiteName({ label: (searchParams.siteName == 'all' || searchParams.siteName == '*') ? 'All' : searchParams.siteName, value: searchParams.siteName });
        }
        if (searchParams.time && searchParams.time != time.value) {
            setTime(timeOptions.filter(item => searchParams.time == item.value)[0])
        }
        if (searchParams.pipeLineStage && searchParams.pipeLineStage != pipeLineStage.value) {
            setPipeLineStage(pipeLineOptions.filter(item => searchParams.pipeLineStage == item.value)[0])
        }
        if (commit == 'Get Data' && searchParams.siteName && searchParams.time && searchParams.pipeLineStage) {
            setUrlParams(searchParams)
        }
    }

    const handleGetData = () => {
        window.location.href = `/internal/selenium/pipeline_stage_wise_selenium_browser_req_count?filter[site_name]=${siteName.value}&filter[pipeline_stage]=${pipeLineStage.value}&filter[time]=${time.value}&commit=${'Get Data'}`
    }

    const promiseOptions = (inputValue) => {
        if (inputValue.length < 3) {
            return null
        } else {
            return new Promise((resolve) => {
                axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
                    if (res.status == '200') {
                        let site_names = res.data.site_names, site_names_options = []
                        for (var key in site_names) {
                            let group = {}, children = []
                            group['label'] = <div className="fw-bold col text-black">{key}</div>
                            site_names[key].map(item => {
                                children.push({ label: item, value: item })
                            })
                            group['options'] = children;
                            site_names_options.push(group)
                        }
                        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                        setSiteNameOptions(site_names_options)
                        resolve(site_names_options)
                    }
                }).catch(err => {
                    console.log(`err`, err)
                })
            })
        }
    }


    return <Container fluid>
        <div className="page-title">
            <Row className=''>
                <Col xs="6">
                    <h3>Internal Dashboard</h3>
                </Col>
                <Col xs="6">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={'/interanal'}>
                                <Home />
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            {commit == 'Get Data' ? <a href={'/internal/selenium/pipeline_stage_wise_selenium_browser_req_count'}>Pipeline Stage Wise Selenium Browser Request Count </a> : 'Pipeline Stage Wise Selenium Browser Request Count'}
                        </BreadcrumbItem>
                        {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                            Search Results
                        </BreadcrumbItem>}
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
        <Card>
            <CardBody>
                <Row>
                    <Col sm="8">
                        <label className="fw-bold">Site Name</label>
                        <AsyncSelect cacheOptions defaultOptions={siteNameOptions} name='site_name'
                            value={siteName} loadOptions={promiseOptions} onChange={res => setSiteName(res)}
                            placeholder="Select a site name" />
                            <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
                    </Col>
                </Row>
                <Row className="align-items-end mt-3">
                    <Col sm="4">
                        <label className="fw-bold">Pipeline Stage</label>
                        <Select
                            value={pipeLineStage}
                            options={pipeLineOptions}
                            onChange={option => setPipeLineStage(option)} />
                    </Col>
                    <Col sm="4">
                        <label className="fw-bold">Choose Time</label>
                        <Select
                            value={time}
                            options={timeOptions}
                            onChange={option => setTime(option)} />
                    </Col>
                    <Col sm="4">
                        <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
        <Card>
            <CardBody>
                <center>
                    <span className="fw-bold">These graphs display 5% of the request count made for initializing ajax driver.</span><br />
                    <a target="_blank" className="link-primary" href={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&embed=true&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.time},mode:quick,to:${urlParams.time == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'custom:%22browser_request_tracker%22%20AND%20site_name:%20%22${urlParams.siteName == 'all' ? '*' : urlParams.siteName}%22%20AND%20pipeline_stage:%20%22${urlParams.pipeLineStage == 'all' ? '*' : urlParams.pipeLineStage}%22')),uiState:(),vis:(aggs:!((id:'1',params:(customLabel:browser_requests_count),schema:metric,type:count),(id:'2',params:(customLabel:pipeline_stage,field:pipeline_stage.raw,order:desc,orderBy:'1',size:5),schema:segment,type:terms),(id:'3',params:(customLabel:site_name,field:site_name.raw,order:desc,orderBy:'1',size:30),schema:group,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Pipeline%20Stage%20Wise%20Selenium%20Browser%20Request%20Count',type:histogram))`}>
                        Click here to see below graph in kibana
                    </a>
                </center>
                <iframe src={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&embed=true&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.time},mode:quick,to:${urlParams.time == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'custom:%22browser_request_tracker%22%20AND%20site_name:%20%22${urlParams.siteName == 'all' ? '*' : urlParams.siteName}%22%20AND%20pipeline_stage:%20%22${urlParams.pipeLineStage == 'all' ? '*' : urlParams.pipeLineStage}%22')),uiState:(),vis:(aggs:!((id:'1',params:(customLabel:browser_requests_count),schema:metric,type:count),(id:'2',params:(customLabel:pipeline_stage,field:pipeline_stage.raw,order:desc,orderBy:'1',size:5),schema:segment,type:terms),(id:'3',params:(customLabel:site_name,field:site_name.raw,order:desc,orderBy:'1',size:30),schema:group,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Pipeline%20Stage%20Wise%20Selenium%20Browser%20Request%20Count',type:histogram))&embed=true`} height="500" width="100%" className="border-0">

                </iframe>
            </CardBody>
        </Card>
    </Container>
}