import React from 'react';
import { Info, Copy } from "react-feather";
import ReactTooltip from 'react-tooltip';
import DataTable from 'react-data-table-component';
import { KIBANA_PLM_URL } from '../../../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints';

export default function SegmentLevelCrawlTable(props) {
  // Table column definitions
  const columns = [
    {
      name: 'Segment Name',
      selector: 'segment_name',
      sortable: true,
      cell: (row, index) => (
        <>
          <p data-tip data-for={'segment-name-tooltip-' + index}>{row.segment_name} <Info size={18} className='mx-2 d-block' /></p>
          <ReactTooltip id={'segment-name-tooltip-' + index} clickable multiline delayHide={1000} effect='solid'>
            <div className="text-break">
              <a href={segmentKibanaUrl(row)} target="_blank" className='text-white '>Kibana Link</a><br />
              <a href="http://alerta.promptcloud.com/#/alerts?status=open" target='_blank' className='text-white'>Alert Link (To Do)</a><br />
              <span>site path: {row.complete_site_path}</span>
            </div>
          </ReactTooltip>

        </>
      )
    },
    {
      name: 'Date',
      selector: 'crawldate',
      sortable: true,
      cell: (row, index) => (
        <>
          <p data-tip data-for={'crawldate-tooltip-' + index}>{row.crawldate} <Info size={18} className='mx-2' /></p>
          <ReactTooltip id={'crawldate-tooltip-' + index} clickable multiline delayHide={1000} effect='solid'>
            <a href={dateKibanaUrl(row)} target="_blank" className='text-white'>Kibana Link</a><br />
            <a href="http://alerta.promptcloud.com/#/alerts?status=open" target='_blank' className='text-white'>Alert Link (To Do)</a>
          </ReactTooltip>
        </>
      )
    },
    {
      name: 'Crawl',
      selector: 'crawled_urls_count',
      sortable: true,
      cell: (row, index) => (
        <>
          <p data-tip data-for={'crawl-tooltip-' + index}>{row.crawled_urls_count} <Info size={18} className='mx-2' /></p>
          {
            row.crawled_urls_count == 0 ?
              <ReactTooltip id={'crawl-tooltip-' + index} clickable multiline delayHide={1000} effect='solid'>
                <span className='text-white'>No path</span>
              </ReactTooltip>
              :
              <ReactTooltip id={'crawl-tooltip-' + index} clickable multiline delayHide={1000} effect='solid'>
                <span className='text-white'>Status: {row.crawlstatus}</span><br />
                <span className='text-white'>Crawl start time: {row.crawl_begin_time}</span><br />
                <span className='text-white'>Crawl end time: {row.crawl_end_time}</span><br />
                <span className='text-white'>Duration: {row.crawlstat_duration}</span><br />
                <span className='text-white'>Previous Host Name: {row.previous_hostname}</span><br />
                <span className='text-white'>Command: ./ruby.sh -l info ./recrawl_and_feedcrawl_automation.rb -f --noloop -s {row.site_name} [-i|--custom_flag]</span><br />
                <span className='text-white'>log File: {row.complete_site_path}.log</span><br />
                <span className='text-white'>input url: {row.complete_site_path}/urls</span><br />
                <span className='text-white'>crawled_data: {row.complete_site_path}</span><br />
                <span className='text-white'>God log files: {row.complete_site_path.split("/")[0]}/mnt/sdf/crawlspace/sdflogs/god/*feedcrawl*.log</span><br />
              </ReactTooltip>
          }
        </>
      )
    },
    {
      name: 'Extraction',
      selector: 'extract_output_record_count',
      sortable: true,
      cell: (row, index) => (
        <>
          <p data-tip data-for={'extraction-tooltip-' + index}>{row.extract_output_record_count} <Info size={18} className='mx-2' /></p>
          {
            row.extract_output_record_count == 0 ?
              <ReactTooltip id={'extraction-tooltip-' + index} clickable multiline delayHide={1000} effect='solid'>
                <span className='text-white'>No path</span>
              </ReactTooltip>
              :
              <ReactTooltip id={'extraction-tooltip-' + index} clickable multiline delayHide={1000} effect='solid'>
                <span className='text-white'>Status: {row.extractstatus}</span><br />
                <span className='text-white'>Extraction start time: {row.extraction_start_time}</span><br />
                <span className='text-white'>Extraction end time: {row.extraction_end_time}</span><br />
                <span className='text-white'>Duration: {row.extract_duration}</span><br />
                <span className='text-white'>Command: ./ruby.sh -l info ./extraction_and_dedup_automation_new.rb -e -s {row.site_name} --date {row.segment_path.split("-").pop().split("_")[0]}</span><br />
                {
                  row.segment_path == "no_path" ? "No path" :
                    <>
                      <span className='text-white'>log directory: {row.segment_path.split("/")[0]}/mnt/sdf/crawlspace/extractions/{row.segment_path.split("crawlspace/")[1].split(row.segment_name)[0]}log/{row.segment_name}/</span><br />
                    </>
                }
              </ReactTooltip>
          }
        </>
      )
    },
    {
      name: 'Dedup',
      selector: 'dedup_output_records_count',
      sortable: true,
      cell: (row, index) => (
        <>
          <p data-tip data-for={'dedup-tooltip-' + index}>{row.dedup_output_records_count} <Info size={18} className='mx-2' /></p>
          {
            row.dedup_output_records_count == 0 ?
              <ReactTooltip id={'dedup-tooltip-' + index} clickable multiline delayHide={1000} effect='solid'>
                <span className='text-white'>No path</span>
              </ReactTooltip>
              :
              <ReactTooltip id={'dedup-tooltip-' + index} clickable multiline delayHide={1000} effect='solid'>
                <span className='text-white'>Status: {row.dedupstatus}</span><br />
                <span className='text-white'>Dedup start time: {row.dedup_start_time}</span><br />
                <span className='text-white'>Dedup end time: {row.dedup_end_time}</span><br />
                <span className='text-white'>Duration: {row.dedup_duration}</span><br />
                <span className='text-white'>Command: ./ruby.sh -l info ./extraction_and_dedup_automation_new.rb -e -s {row.site_name} --date {row.segment_path.split("-").pop().split("_")[0]}</span><br />
                {
                  row.segment_path == "no_path" ? "No path" :
                    <>
                      <span className='text-white'>{row.segment_path.split("/")[0]}/mnt/sdf/crawlspace/extractions/{row.segment_path.split("crawlspace/")[1].split(row.segment_name)[0]}dedup/{row.segment_name}/{row.segment_name.split("-")[0]}_deduped-{row.segment_name.split("-")[1]}.xml.delete</span><br />
                    </>
                }
              </ReactTooltip>
          }
        </>
      )
    },
    {
      name: 'Normalize',
      selector: 'normalize_output_records_count',
      sortable: true,
      cell: (row, index) => (
        <>
          <p data-tip data-for={'normalize-tooltip-' + index}>{row.normalize_output_records_count} <Info size={18} className='mx-2' /></p>
          {
            row.normalize_output_records_count == 0 ?
              <ReactTooltip id={'normalize-tooltip-' + index} clickable multiline delayHide={1000} effect='solid'>
                <span className='text-white'>No path</span>
              </ReactTooltip>
              :
              <ReactTooltip id={'normalize-tooltip-' + index} clickable multiline delayHide={1000} effect='solid'>
                <span className='text-white'>Status: {row.normalizationstatus}</span><br />
                <span className='text-white'>Normalization start time: {row.normalization_start_time}</span><br />
                <span className='text-white'>Normalization end time: {row.normalization_end_time}</span><br />
                <span className='text-white'>Duration: {row.normalization_duration}</span><br />
                <span className='text-white'>Command: ./ruby.sh -l info ./extraction_and_dedup_automation_new.rb -e -s {row.site_name} --date {row.segment_path.split("-").pop().split("_")[0]}</span><br />
                {
                  row.segment_path == "no_path" ? "No path" :
                    <>
                      <span className='text-white'>{row.segment_path.split("/")[0]}/mnt/sdf/crawlspace/extractions/{row.segment_path.split("crawlspace/")[1].split(row.segment_name)[0]}normalized/{row.segment_name}/{row.segment_name.split("-")[0]}_deduped_n-{row.segment_name.split("-")[1]}.csv.gz.delete</span><br />
                    </>
                }
              </ReactTooltip>
          }
        </>
      )
    },
    {
      name: 'Upload',
      selector: 'upload_output_records_count',
      sortable: true,
      cell: (row, index) => (
        <>
          <p data-tip data-for={'normalize-tooltip-' + index}>{row.upload_output_records_count} <Info size={18} className='mx-2' /></p>
          {
            row.upload_output_records_count == 0 ?
              <ReactTooltip id={'normalize-tooltip-' + index} clickable multiline delayHide={1000} effect='solid'>
                <span className='text-white'>No path</span>
              </ReactTooltip>
              :
              <ReactTooltip id={'normalize-tooltip-' + index} clickable multiline delayHide={1000} effect='solid'>
                <span className='text-white'>Status: {row.uploadstatus}</span><br />
                <span className='text-white'>Upload start time: {row.upload_start_time}</span><br />
                <span className='text-white'>Upload end time: {row.upload_end_time}</span><br />
                <span className='text-white'>Duration: {row.upload_duration}</span><br />
                <span className='text-white'>Command: ./ruby.sh -l info ./extraction_and_dedup_automation_new.rb -e -s {row.site_name} --date {row.segment_path.split("-").pop().split("_")[0]}</span><br />
                {
                  row.segment_path == "no_path" ? "No path" :
                    <>
                      <span className='text-white'>{row.segment_path.split("/")[0]}/mnt/sdf/crawlspace/extractions/{row.segment_path.split("crawlspace/")[1].split(row.segment_name)[0]}toupload/{row.segment_name}/{row.segment_name.split("-")[0]}_deduped-{row.segment_name.split("-")[1]}.csv.gz</span><br />
                    </>
                }
              </ReactTooltip>
          }
        </>
      )
    },
    {
      name: 'Host',
      selector: 'hostname',
      sortable: true,
      cell: (row, index) => (
        <>
          <p data-tip data-for={'host-tooltip-' + index}>{row.hostname} <Info size={18} className='mx-2' /></p>
          <ReactTooltip id={'host-tooltip-' + index} clickable multiline delayHide={1000} effect='solid'>
            <span className='text-white'>Previous Host Name: {row.previous_hostname}</span><br />
          </ReactTooltip>
        </>
      )
    },
    {
      name: 'Copy Site Path',
      selector: 'complete_site_path',
      sortable: true,
      cell: (row, index) => (
        <>
          <button className='btn btn-sm btn-outline-success'
            onClick={() => copyToClipboard(row.complete_site_path)}>
            <Copy size={14} />
          </button>
        </>
      )
    },
  ];

  function segmentKibanaUrl(data) {
    return `${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(segid),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'segid.raw+:+%22${data.segment_name}%22'),sort:!(!('@timestamp',desc)))`
  };

  function dateKibanaUrl(data) {
    return `${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(segid,yyyymmdd),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'segid.raw+:+%22${data.segment_name}%22+and+yyyymmdd:${data.crawldate}'),sort:!(!('@timestamp',desc)))`
  };

  function statusKibanaUrl(data) {
    return `${KIBANA_PLM_URL}app/kibana#/discover?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:'${data.kibana_from_date}',to:'${data.kibana_to_date}'))&_a=(columns:!(date,current_status),filters:!(('$state':(store:appState),exists:(field:rss_status_tracker),meta:(alias:!n,disabled:!f,index:'76972760-1ad0-11ec-9735-177ab3888d9e',key:rss_status_tracker,negate:!f,type:exists,value:exists))),index:'76972760-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+${data.site_name}+OR+sitename:+${data.site_name}+OR+site:+${data.site_name})+and+date:+${data.crawldate}+and+current_status:+%22${data.rss_crawlstatus}%22'),sort:!(!('@timestamp',desc)))`
  };

  function copyToClipboard(value) {
    navigator.clipboard.writeText(value)
  }

  return (
    <div>
      <DataTable
        columns={columns}
        data={props?.data}
        progressPending={props?.progressPending}
        noHeader
        pagination
        responsive />
    </div>
  )
}
