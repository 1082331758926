import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, ModalFooter, Button } from 'reactstrap'
import { Link, useLocation } from "react-router-dom";
import { Home, Info } from "react-feather";
import axios from "axios";
import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";
import { toast } from 'react-toastify'
import Select, { components } from "react-select";

export default function UpdateCustomList(data) {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [siteNames, setSiteNames] = useState();
  const [listName, setListName] = useState(data.listName);
  const [sitesOptions, setSitesOptions] = useState([]);

  const toggleFunc = (e) => {
    e.preventDefault();
    savedSiteList();
    activeSiteList();
    setModal(curr => !curr)
  }

  const closeBtn = <button className="close" 
    onClick={() => setModal(false)}> &times; </button>;

  const activeSiteList = () => {
    setLoading(true)
    axios.get(`/api/v1/internal/high_volume_clients_and_sites/active_site_list`)
      .then(res => {
        setSitesOptions(res.data?.sites);
        setLoading(false)
      }).catch(err => {
        console.error(err?.message || 'Oops something went wrong');
        // toast.error(err?.response.data.message || 'Oops something went wrong');
        setLoading(false)
      })
  }

  const savedSiteList = () => {
    setLoading(true)
    axios.post(`/api/v1/internal/high_volume_clients_and_sites/sites_from_custom_list`, {
      list_name: data.listName
    })
      .then(res => {
        setSiteNames(res.data?.saved_sites);
        setLoading(false)
      }).catch(err => {
        console.error(err?.message || 'Oops something went wrong');
        // toast.error(err?.response.data.message || 'Oops something went wrong');
        setLoading(false)
      })
  }

  const UpdateCustomList = (e) => {
    e.preventDefault()
    setLoading(true)
    setModal(false)
    axios.post(`/api/v1/internal/high_volume_clients_and_sites/update_custom_list`, {
      filter: {existing_list_name: data.listName, list_name: listName, site_names: siteNames.map(x=> x["value"])} 
    }).then(res => {
      toast.success(res.data?.message);
      setLoading(false)
    }).catch(err => {
      setModal(true)
      console.error(err?.message || 'Oops something went wrong');
      // toast.error(err?.response.data.message || 'Oops something went wrong');
      setLoading(false)
    })
  }

  return (
    <>
      <Modal isOpen={modal} toggle={toggleFunc} backdrop='static' size='lg'>
        <ModalHeader toggle={toggleFunc} close={closeBtn}>
          Custom List
        </ModalHeader>
        <ModalBody className='px-5'>
          <Row className='my-2'>
            <Col lg={12}>
              <h6 className='my-1 f-w-600'>List Name</h6>
              <input type="text" value={listName} className='form-control'
                onChange={(e) => setListName(e.target.value)} />
            </Col>
            <Col md={12}>
              <h6 className='my-1 f-w-600'>Site names</h6>
              <Select className='show-arrow basic-multi-select' value={siteNames} isMulti isSearchable 
                onChange={(res) => setSiteNames(res)} 
                options={sitesOptions} />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color='light' onClick={() => setModal(false)}> Back </Button>
          <Button className="btn btn-success btn-sm" onClick={UpdateCustomList}> Update </Button>
        </ModalFooter>
      </Modal>
      <button className="btn btn-success btn-sm me-1 px-xl-3" onClick={toggleFunc}>Edit</button>
    </>
    )
}

