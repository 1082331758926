import React, {useEffect} from 'react'
import { Switch, Route, useRouteMatch } from "react-router-dom";
// import EachFeasibilityCheck from './feasibility-checks/EachFeasibilityCheck';
// import FeasibilityCheck from './feasibility-checks/FeasibilityCheck';
// import EditFeasibilityCheck from './feasibility-checks/EditFeasibilityCheck'
import AllPayments from './payments/AllPayments';
import AllOrganizations from './organizations/AllOrganizations';
import AllFeasibilityReports from './feasibility-reports/AllFeasibilityReports';
import AllCoupons from './coupons/AllCoupons';
import AllInvoices from './AllInvoices/AllInvoices.index';
import NewCoupon from './coupons/NewCoupon';
import EditCoupon from './coupons/EditCoupon';
import NewAnnouncement from './new-announcements/NewAnnouncements';
import DataStockFiles from './data-stock-files/DataStockFiles';
import NotFeasibleWebsites from './NotFeasibleWebsites/NotFeasibleWebsites.component';
import OrgMapping from './org-mapping/OrgMapping';
import OrganizationSearch from './organizations/OrganizationSearch';
import FreshbooksOauth2CallbacksNew from './FreshbooksOauth2Callbacks/New';
import FreshbooksOauth2CallbacksCreate from './FreshbooksOauth2Callbacks/Create';
import ZohobooksOauth2CallbacksNew from './ZohobooksOauth2Callbacks/New';
import ZohobooksOauth2CallbacksCreate from './ZohobooksOauth2Callbacks/Create';
import { useHistory } from "react-router-dom";

/* Refactored V2 Pages */
import AdminFeasibilityChecks from '../V2/AdminPages/FeasibilityChecks/AdminFeasibilityChecks.index';
import FeasibilityCheckSteps from '../V2/AdminPages/FeasibilityChecks/FeasibilityCheckSteps.component';
import EditFeasibilityCheck from '../V2/AdminPages/FeasibilityChecks/EditFeasibilityCheck.component';

export default function Admin({user}) {
  const { path } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (user?.is_admin === false){
      history.push('/')
    }
  }, [user])

  return (
    <Switch>
      <Route path={`${path}feasibility_checks`} component={AdminFeasibilityChecks} exact/>
      <Route path={`${path}feasibility_checks/:feasid`} component={FeasibilityCheckSteps} exact/>
      <Route path={`${path}feasibility_checks/:feasid/edit`} component={EditFeasibilityCheck} exact/>
      <Route path={`${path}payments`} component={AllPayments} exact />
      <Route path={`${path}invoices`} component={AllInvoices} exact />
      <Route path={`${path}feasibility_reports`} component={AllFeasibilityReports} exact />
      <Route path={`${path}organizations`} component={AllOrganizations} exact />
      <Route path={`${path}coupons/:id/edit`} component={EditCoupon} exact />
      <Route path={`${path}coupons/new`} component={NewCoupon} exact />
      <Route path={`${path}feasibility_reports`} component={AllFeasibilityReports} exact />
      <Route path={`${path}search/new`} component={OrganizationSearch}  exact/>
      <Route path={`${path}coupons`} component={AllCoupons} exact />
      <Route path={`${path}announcements/new`} component={NewAnnouncement}  exact/>
      <Route path={`${path}data_stock_files`} component={DataStockFiles}  exact/>
      <Route path={`${path}not_feasible_websites`} component={NotFeasibleWebsites}  exact/>
      <Route path={`${path}org_mapping`} component={OrgMapping} exact/>
      <Route path={`${path}freshbooks_oauth2_callbacks/new`} component={FreshbooksOauth2CallbacksNew} exact/>
      <Route path={`${path}freshbooks_oauth2_callbacks`} component={FreshbooksOauth2CallbacksCreate} exact/>
      <Route path={`${path}zohobooks_oauth2_callbacks/new`} component={ZohobooksOauth2CallbacksNew} exact/>
      <Route path={`${path}zohobooks_oauth2_callbacks`} component={ZohobooksOauth2CallbacksCreate} exact/>
    </Switch>
  )
}
