import React from 'react';
import { Container } from 'reactstrap';
import SuperUserContext from '../../Context/Providers/SuperUserPrivilege/SuperUserContext.component';
import BannerNotification from '../../Common/BannerNotification/BannerNotifications.component';
import BreadCrumbs from '../../Common/BreadCrumb/BreadCrumbs.component';
import OrganizationDetailsCard from './Cards/OrganizationDetialsCard.component';
import AdminOperationsCard from './Cards/AdminOperationsCard.component';


export default React.memo(function Organization({ banner_notifications }) {
	return (
		<>
			<SuperUserContext>
				<Container fluid>
					<BreadCrumbs title={"Organization"} parent={"Organization Details"} />
					<BannerNotification notificationsArray={banner_notifications} />
					<OrganizationDetailsCard  />
					<AdminOperationsCard />
				</Container>
			</SuperUserContext>
		</>
	)
})
