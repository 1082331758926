import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody } from "reactstrap";
import { useLocation, Link } from "react-router-dom";
import Select from "react-select";
import { Home } from "react-feather";
import axios from "axios";

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";


export default function SeleniumClusterWiseFailureAndRetryCount() {
    const search = useLocation().search;
    const commit = new URLSearchParams(search).get("commit");
    const searchParams = {
        pipeLineStage: new URLSearchParams(search).get("filter[pipeline_stage]"),
        time: new URLSearchParams(search).get("filter[time]"),
        seleniumCluster: new URLSearchParams(search).get("filter[selenium_cluster]")
    }

    const [time, setTime] = useState({ label: 'Last 4 hours', value: 'now-4h' });
    const [seleniumCluster, setSeleniumCluster] = useState({ label: 'All', value: 'all' });
    const [seleniumOptions, setSeleniumOptions] = useState([]);
    const [pipeLineStage, setPipeLineStage] = useState({ label: 'All', value: 'all' })
    const [urlParams, setUrlParams] = useState({
        pipeLineStage: '*',
        seleniumCluster: '*',
        time: 'now-4h'
    })
    const timeOptions = [
        { label: 'Today', value: 'now%2Fd' },
        { label: 'Last 30 minutes', value: 'now-30m' },
        { label: 'Last 1 hour', value: 'now-1h' },
        { label: 'Last 2 hours', value: 'now-2h' },
        { label: 'Last 4 hours', value: 'now-4h' },
        { label: "Last 10 hours", value: "now-10h" },
        { label: 'Last 12 hours', value: 'now-12h' },
        { label: 'Last 24 hours', value: 'now-24h' },
        { label: 'Last 2 days', value: 'now-2d' },
        { label: 'Last 3 days', value: 'now-3d' },
        { label: 'Last 4 days', value: 'now-4d' },
        { label: 'Last 7 days', value: 'now-7d' },
        { label: 'Last 15 days', value: 'now-15d' },
        { label: 'Last 30 days', value: 'now-30d' },
        { label: 'Last 60 days', value: 'now-60d' },
        { label: 'Last 1 year', value: 'now-1y' }
    ];
    const pipeLineOptions = [
        { label: 'All', value: 'all' },
        { label: 'Rss', value: 'RSS' },
        { label: 'Feedcrawl', value: 'feedcrawl' },
        { label: 'Dependent Data Upload', value: 'dependent_data_upload' },

    ]

    useEffect(() => {
        setSearchParamsData();
        axios.get('/api/v1/internal/dropdown_list_helper')
            .then(res => {
                let result = res.data.geo_clusters.map(item => ({ label: item, value: item }));
                result.push({ label: 'All', value: 'all' })
                setSeleniumOptions(result);
            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong')
            });
    }, []);

    function setSearchParamsData() {
        if (searchParams.seleniumCluster && searchParams.seleniumCluster != seleniumCluster.value) {
            setSeleniumCluster({ label: searchParams.seleniumCluster == 'all' ? 'All' : searchParams.seleniumCluster, value: searchParams.seleniumCluster });
        }
        if (searchParams.pipeLineStage && searchParams.pipeLineStage != pipeLineStage.value) {
            setPipeLineStage(pipeLineOptions.filter(item => searchParams.pipeLineStage == item.value)[0])
        }
        if (searchParams.time && searchParams.time != time.value) {
            setTime(timeOptions.filter(item => searchParams.time == item.value)[0])
        }
        if (commit == 'Get Data' && searchParams.seleniumCluster && searchParams.pipeLineStage && searchParams.time) {
            setUrlParams(searchParams)
        }
    }

    const handleGetData = () => {
        window.location.href = `/internal/selenium/selenium_cluster_wise_failure_and_retry_count?filter[selenium_cluster]=${seleniumCluster.value}&filter[pipeline_stage]=${pipeLineStage.value}&filter[time]=${time.value}&commit=${'Get Data'}`
    }

    return <Container fluid>
        <div className="page-title">
            <Row className=''>
                <Col xs="6">
                    <h3>Internal Dashboard</h3>
                </Col>
                <Col xs="6">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={'/internal'}>
                                <Home />
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            {commit == 'Get Data' ? <a href={'/internal/selenium/selenium_cluster_wise_failure_and_retry_count'}>Selenium Cluster Wise Failure and Retry Count</a> : 'Selenium Cluster Wise Failure and Retry Count'}
                        </BreadcrumbItem>
                        {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                            Search Results
                        </BreadcrumbItem>}
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
        <Card>
            <CardBody>
                <Row className="align-items-end">
                    <Col sm="3">
                        <label className="fw-bold">Selenium Cluster</label>
                        <Select
                            value={seleniumCluster}
                            options={seleniumOptions}
                            onChange={option => setSeleniumCluster(option)} />
                    </Col>
                    <Col sm="3">
                        <label className="fw-bold">Pipeline Stage</label>
                        <Select
                            value={pipeLineStage}
                            options={pipeLineOptions}
                            onChange={option => setPipeLineStage(option)} />
                    </Col>
                    <Col sm="3">
                        <label className="fw-bold">Time</label>
                        <Select
                            value={time}
                            options={timeOptions}
                            onChange={option => setTime(option)} />
                    </Col>
                    <Col sm="3">
                        <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
        <Card>
            <CardBody>
                <center>
                    <span className="fw-bold">Selenium Cluster Wise Failure and Retry Count</span><br />
                    <a target="_blank" className="link-primary" href={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.time},mode:quick,to:${urlParams.time == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'(custom:%22selenium_node_connection_issue%22%20%20OR%20custom:%22selenium_tracking1%22%20%20OR%20%22Selenium%20ALERT::No%20Active%20Nodes%20found%22)%20AND%20selenium_cluster:%20%22${urlParams.seleniumCluster == 'all' ? '*' : urlParams.seleniumCluster}%22%20AND%20pipeline_stage:%20%22${urlParams.pipeLineStage == 'all' ? '*' : urlParams.pipeLineStage}%22')),uiState:(),vis:(aggs:!((id:'1',params:(customLabel:'Failure%2FRetry%20Count'),schema:metric,type:count),(id:'2',params:(customLabel:selenium_cluster,field:selenium_cluster.raw,order:desc,orderBy:'1',size:0),schema:segment,type:terms),(id:'3',params:(customLabel:pipeline_stage,field:pipeline_stage.raw,order:desc,orderBy:'1',size:0),schema:group,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Selenium%20Cluster%20Wise%20Failure%20and%20Retry%20Count',type:histogram))`}>
                        Click here to see below graph in kibana
                    </a>
                </center>
                <iframe src={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.time},mode:quick,to:${urlParams.time == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'(custom:%22selenium_node_connection_issue%22%20%20OR%20custom:%22selenium_tracking1%22%20%20OR%20%22Selenium%20ALERT::No%20Active%20Nodes%20found%22)%20AND%20selenium_cluster:%20%22${urlParams.seleniumCluster == 'all' ? '*' : urlParams.seleniumCluster}%22%20AND%20pipeline_stage:%20%22${urlParams.pipeLineStage == 'all' ? '*' : urlParams.pipeLineStage}%22')),uiState:(),vis:(aggs:!((id:'1',params:(customLabel:'Failure%2FRetry%20Count'),schema:metric,type:count),(id:'2',params:(customLabel:selenium_cluster,field:selenium_cluster.raw,order:desc,orderBy:'1',size:0),schema:segment,type:terms),(id:'3',params:(customLabel:pipeline_stage,field:pipeline_stage.raw,order:desc,orderBy:'1',size:0),schema:group,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Selenium%20Cluster%20Wise%20Failure%20and%20Retry%20Count',type:histogram))&embed=true`} height="500" width="100%" className="border-0"></iframe>
            </CardBody>
        </Card>
    </Container>
}