import React from 'react';
import { useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import DatePicker from 'react-datepicker';
import AsyncSelect from 'react-select/async';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import SpinnerButton from '../../../../../V2/Common/SpinnerButton/SpinnerButton.component';

export default function SiteSelector({ sitesData, dataFetcher, loading, siteNamesDropdownOptions }) {
  
  const filterValues = (inputValue) => {
    return siteNamesDropdownOptions.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  }

  const loadOptions = (inputValue, callback) => {
      setTimeout(() => {
          callback(filterValues(inputValue));
      }, 650);
  }

  const validationSchema = Yup.object({
    siteName: Yup.object().shape({
      label: Yup.string().required("Sitename is required").min(1,"Sitename is required"),
      value: Yup.string().required("Sitename is required").min(1,"Sitename is required"),
    }).required("Sitename is required"),
    startDate: Yup.date()
    .required('Start date is required'),
    endDate: Yup.date()
    .required('End date is required')
    .test('is-after', 'End date must be after start date', function(value) {
      const { startDate } = this.parent
      let start = new Date(startDate).setHours(0, 0, 0, 0)
      let end = new Date(value).setHours(0, 0, 0, 0)
      return !startDate || !value || start <= end
    }),
  });


  return (
    <Formik
        initialValues={{
            siteName: {label:"", value:""},
            startDate: new Date(),
            endDate: new Date(),
        }}
        validationSchema={validationSchema}
        onSubmit={(values, formik) => {
            const { startDate, endDate, siteName } = values;
            formik.setTouched({startDate: true, endDate: true, siteName: true}, true)
            .then((err)=>{
              if(Object.keys(err).length == 0){
                dataFetcher(startDate, endDate, siteName.value || "");
              } else {
                toast.error("Please fill in required fields")
              }
            }) 
        }}
        validateOnChange={true}
        validateOnBlur={false}
    >
        {({ setFieldValue, values, errors, touched, setFieldTouched, validateField, handleSubmit }) =>{ 
          return(
              <Form onSubmit={handleSubmit}>
                  <Row className='mb-2'>
                      <Col lg={3}>
                          <h6 className='fw-bold'>Sitename</h6>
                          <Field name="siteName">
                            {({ field, form }) => (
                              <AsyncSelect
                                className=''
                                name='siteName'
                                value={field.value}
                                placeholder="Select a Site Name"
                                cacheOptions
                                defaultOptions={siteNamesDropdownOptions}
                                defaultValue={{label:"", value:""}}
                                onChange={(option) => {
                                  setFieldValue('siteName', option);
                                }}
                                onBlur={() => {
                                  setFieldTouched('siteName', true).then(()=> validateField('siteName'))
                                }}
                                loadOptions={loadOptions}
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                              />
                            )}
                          </Field>
                          {errors?.siteName?.value && touched?.siteName ? (
                            <p className='m-0 error-msg mt-1 mb-2'>{errors?.siteName?.value}</p>
                          ) : null}
                      </Col>
                      <Col lg={3}>
                          <h6 className='fw-bold'>From Date</h6>
                          <Field name="startDate">
                            {({ field, form }) => (
                              <DatePicker
                                className="form-control"
                                selected={field.value}
                                maxDate={new Date()}
                                onBlur={() => {
                                  setFieldTouched('startDate', true).then(()=> validateField('startDate'))
                                }}
                                onSelect={() => {
                                  if(!touched?.startDate) form.setFieldTouched('startDate', true)
                                }}
                                onChange={(date) => {
                                  if(!touched?.startDate) form.setFieldTouched('startDate', true)
                                  setFieldValue('startDate', date)
                                }}
                              />
                            )}
                          </Field>
                          <ErrorMessage name="startDate" component="p" className="m-0 error-msg mt-1 mb-2" />
                      </Col>
                      <Col lg={3}>
                          <h6 className='fw-bold'>To Date</h6>
                          <Field name="endDate">
                            {({ field, form }) => (
                              <DatePicker
                                className="form-control"
                                selected={field.value}
                                maxDate={new Date()}
                                onBlur={() => {
                                  setFieldTouched('endDate', true).then(()=> validateField('endDate'))
                                }}
                                onSelect={() => {
                                  if(!touched?.endDate) form.setFieldTouched('endDate', true)
                                }}
                                onChange={(date) => {
                                  if(!touched?.endDate) form.setFieldTouched('endDate', true)
                                  setFieldValue('endDate', date)
                                }}
                              />
                            )}
                          </Field>
                          <ErrorMessage name="endDate" component="p" className="m-0 error-msg mt-1 mb-2" />
                      </Col>
                      <Col lg={3}>
                          <SpinnerButton
                              loading={loading}
                              color='success'
                              className='py-2 mt-4'
                              type="submit"
                              disabled={false}
                          >
                              Get Files
                          </SpinnerButton>
                      </Col>
                  </Row>
              </Form>
          )}}
    </Formik>
  )
}