import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function SiteOrProjectOrClientVsNoOfWorkers() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[site_name]"),
    pipelineStage: new URLSearchParams(search).get("filter[pipeline_stage]"),
    fromTime: new URLSearchParams(search).get("filter[time]"),
  }

  const [siteName, setSiteName] = useState("");
  const [pipelineStage, setPipelineStage] = useState({ label: 'All', value: '*' });
  const [fromTime, setFromTime] = useState({ label: 'Last 7 days', value: 'now-7d' });

  const pipelineStageOptions = [
    { label: 'All', value: '*' },
    { label: 'RSS', value: 'RSS' },
    { label: 'feedcrawl', value: 'feedcrawl' }
  ]

  const timeOptions = [
    { label: 'Today', value: 'now%2Fd' },
    { label: 'Last 30 minutes', value: 'now-30m' },
    { label: 'Last 1 hour', value: 'now-1h' },
    { label: 'Last 2 hours', value: 'now-2h' },
    { label: 'Last 4 hours', value: 'now-4h' },
    { label: "Last 10 hours", value: "now-10h" },
    { label: 'Last 12 hours', value: 'now-12h' },
    { label: 'Last 24 hours', value: 'now-24h' },
    { label: 'Last 2 days', value: 'now-2d' },
    { label: 'Last 3 days', value: 'now-3d' },
    { label: 'Last 4 days', value: 'now-4d' },
    { label: 'Last 7 days', value: 'now-7d' },
    { label: 'Last 15 days', value: 'now-15d' },
    { label: 'Last 30 days', value: 'now-30d' },
    { label: 'Last 60 days', value: 'now-60d' },
    { label: 'Last 1 year', value: 'now-1y' }
  ];

  const [UrlParams, setUrlParams] = useState({
    siteName: '',
    pipelineStage: '*',
    fromTime: 'now-7d'
  })

  useEffect(() => {
    setSearchParamsData();
  }, []);


  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.siteName && searchParams.siteName != siteName) {
      setSiteName(searchParams.siteName);
    }
    if (searchParams.pipelineStage && searchParams.pipelineStage != pipelineStage.value) {
      setPipelineStage({ label: searchParams.pipelineStage  == '*' ? 'All' :  searchParams.pipelineStage, value: searchParams.pipelineStage });
    }
    if (searchParams.fromTime && searchParams.fromTime != fromTime.value) {
      setFromTime(timeOptions.filter(item => item.value == searchParams.fromTime)[0]);
    }
    if (commit == 'Get Data' && searchParams.fromTime && searchParams.siteName && searchParams.pipelineStage) {
      setUrlParams(searchParams)
    }
  }

  const handleInputChange = event => {
    setSiteName(event.target.value);
  }

  function SiteOrProjectOrClientVsNoOfWorkersKibanaUrl(){
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
    var site_name_val = UrlParams.siteName ? `*${UrlParams.siteName}*` : "*";
    var pipeline_stage_val = UrlParams.pipelineStage ? UrlParams.pipelineStage : "*";
    console.log("UrlParams", UrlParams);

    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=line&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(pause:!t,value:0),time:(from:'${UrlParams.fromTime}',to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'site_name:${site_name_val}+AND+msg:+%22Fetch+status+when+crawl+are+using+proxies%22+AND+pipeline_stage:${pipeline_stage_val}'),uiState:(),vis:(aggs:!((enabled:!t,id:'1',params:(customLabel:'No.+of+segments+processed',field:segid.raw),schema:metric,type:cardinality),(enabled:!t,id:'2',params:(drop_partials:!f,extended_bounds:(),field:'@timestamp',interval:d,min_doc_count:1,scaleMetricValues:!f,timeRange:(from:now-30d,to:now),useNormalizedEsInterval:!t),schema:segment,type:date_histogram)),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,categoryAxes:!((id:CategoryAxis-1,labels:(filter:!t,show:!t,truncate:100),position:bottom,scale:(type:linear),show:!t,style:(),title:(),type:category)),dimensions:(x:(accessor:0,aggType:date_histogram,format:(id:date,params:(pattern:YYYY-MM-DD)),params:(bounds:(max:'2021-11-22T08:03:44.066Z',min:'2021-10-23T08:03:44.066Z'),date:!t,format:YYYY-MM-DD,interval:P1D,intervalESUnit:d,intervalESValue:1)),y:!((accessor:1,aggType:cardinality,format:(id:number),params:()))),grid:(categoryLines:!f),labels:(),legendPosition:right,seriesParams:!((data:(id:'1',label:'No.+of+segments+processed'),drawLinesBetweenPoints:!t,interpolate:linear,lineWidth:2,mode:normal,show:!t,showCircles:!t,type:line,valueAxis:ValueAxis-1)),thresholdLine:(color:%2334130C,show:!f,style:full,value:10,width:1),times:!(),type:line,valueAxes:!((id:ValueAxis-1,labels:(filter:!f,rotate:0,show:!t,truncate:100),name:LeftAxis-1,position:left,scale:(mode:normal,type:linear),show:!t,style:(),title:(text:'No.+of+segments+processed'),type:value))),title:'',type:line))`
  }

  const handleGetData = () => {
    window.location.href = `/internal/site_or_project_or_client_vs_no_of_workers?filter[site_name]=${siteName}&filter[pipeline_stage]=${pipelineStage.value}&filter[time]=${fromTime.value}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/site_or_project_or_client_vs_no_of_workers">Site/Project/Client vs No of workers</a> : 'Site/Project/Client vs No of workers'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                Search
                </BreadcrumbItem>}
          </Breadcrumb>
        </Col>
      </Row>
    </div>

    <Card>
      <CardBody>
        <Row>
          <Col sm="4">
            <label className="fw-bold">Enter Site/Project/Client Name</label>
            <input type="text" className="form-control" value={siteName} onChange={handleInputChange} />
          </Col>
          <Col sm="4">
            <label className="fw-bold">Pipeline Stage</label>
            <Select
              value={pipelineStage}
              options={pipelineStageOptions}
              onChange={option => setPipelineStage(option)} />
          </Col>
          <Col sm="4">
            <label className="fw-bold">Time</label>
            <Select
              value={fromTime}
              options={timeOptions}
              onChange={option => setFromTime(option)} />
          </Col>
        </Row>
        <Row className="align-items-end mt-4">
          <Col sm="4">
            <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
          </Col>
        </Row>
      </CardBody>
    </Card>
    <Card>
      <CardBody>
        <center>
          <span className="fw-bold">Site/Project/Client vs No of workers</span><br />
          <a target="_blank" className="link-primary" href={SiteOrProjectOrClientVsNoOfWorkersKibanaUrl()}>Click here to see below graph in kibana</a>
        </center>
        <iframe src={SiteOrProjectOrClientVsNoOfWorkersKibanaUrl() + "&embed=true"} height="500" width="100%" className="border-0"></iframe>
      </CardBody>
    </Card>
  </Container>
}

