import React, { Fragment } from 'react';
import sad from '../images/other-images/sad.png';
import { Link } from 'react-router-dom';
import {Container,Button,Media,Col} from "reactstrap"

const Error500 = () => {
    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="error-wrapper">
                <Container>
                    <Media body className="img-100" src={sad} alt="" />
                    <div className="error-heading">
                        <h2 className="headline font-primary">{"500"}</h2>
                    </div>
                    <Col md="8 offset-md-2">
                    <p className="sub-content">
                        The server encountered something unexpected that didn't allow it to complete the request. We apologize.
                        You can go back to main page:
                    </p>
                    </Col>
                    <Link to={`/`}><Button color="primary-gradien" size='lg'>
                        Back to Dashboard
                    </Button></Link>
                </Container>
                </div>
            </div>
        </Fragment>
    );
};

export default Error500;