import React from 'react';

export default function InvoiceTable({ data }) {
  return (
    <div>
      <div className='table-responsive invoice-table' id='table'>
        <table className='table table-bordered mt-4'>
          <tbody>
            <tr className='bg-light'>
              <td className="item">
                <h6 className="p-2 mb-0">Item Name</h6>
              </td>
              <td className="Hours">
                <h6 className="p-2 mb-0">Description</h6>
              </td>
              <td className="Rate">
                <h6 className="p-2 mb-0">Unit Cost</h6>
              </td>
              <td className="subtotal">
                <h6 className="p-2 mb-0">Quantity</h6>
              </td>
              <td className="subtotal">
                <h6 className="p-2 mb-0">Total Price</h6>
              </td>
            </tr>
            {data?.invoice_line_items && data?.invoice_line_items.map((each, index) => (
              <tr key={index}>
                <td>
                  <p className="m-0">{each?.name}</p>
                </td>
                <td>
                  <p className="m-0">{each?.description}</p>
                </td>
                <td>
                  <p className="m-0">{each?.unit_cost}</p>
                </td>
                <td>
                  <p className="m-0">{each?.quantity}</p>
                </td>
                <td>
                  <p className="m-0">{each?.total_price}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
