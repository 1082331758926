import axios from 'axios';
import { Row, Badge, Button, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { DataNotFoundAlerts } from './Alerts.component';
import React, { Fragment, useState, useEffect, useMemo } from 'react';
import SpinnerLoader from '../../../V2/Common/LoadingAnimation/SpinnerLoader.component';
import FieldReportTable from '../Temp/FieldReportTable';
export default function AllDataCheckReports(props) {

	const { rssCrawlPushID } = props;

	const { site_name } = useParams();

	const [loading, setLoading] = useState(false);

	const [searchString, setSearchString] = useState("");
	const [filteredData, setFilteredData] = useState([]);
	const [reportFileName, setReportFileName] = useState("");
	const [dataAvailable, setDataAvailable] = useState(false);
	const [reportTableData, setReportTableData] = useState([]);
	const [reportFileAvailableToDownload, setReportFileAvailableToDownload] = useState(false);
	const [missingColumnsInDataCheckReports, setMissingColumnsInDataCheckReports] = useState("");
	const [availableColumnsInDataCheckReports, setAvailableColumnsInDataCheckReports] = useState("");
	const [numberOfMissingColumnsInDataCheckReports, setNumberOfMissingColumnsInDataCheckReports] = useState(0);
	const [numberOfAvailableColumnsInDataCheckReports, setNumberOfAvailableColumnsInDataCheckReports] = useState(0);

	const [sortedReport, setSortedReport] = useState([])


	const DATA_CHECK_REPORTS_API_URL = useMemo(() => {
		const baseApi = '/api/v1/internal/new_qa_reports/get_all_data_check_reports?';
		const params = (rssCrawlPushID !== "") ? `site_name=${site_name}&rss_crawl_push_id=${rssCrawlPushID}` : `site_name=${site_name}`;
		return `${baseApi}${params}`;
	}, [site_name, rssCrawlPushID]);

	const CSV_FILE_DOWNLOAD_API_ENDPOINT = useMemo(() => `/api/v1/internal/new_qa_reports/${site_name}/rss_crawl_push_id/${rssCrawlPushID}/download_qa_reports`, [site_name, rssCrawlPushID]);

	useEffect(() => {

		const controller = new AbortController();

		fetchAllDataReportsTabData(controller.signal);

		// cleanup
		return () => {
			controller.abort();
			setReportFileName("");
			setReportTableData([]);
			setDataAvailable(false);
			setMissingColumnsInDataCheckReports("");
			setReportFileAvailableToDownload(false);
			setAvailableColumnsInDataCheckReports("");
			setNumberOfMissingColumnsInDataCheckReports(0);
			setNumberOfAvailableColumnsInDataCheckReports(0);
		}

	}, [DATA_CHECK_REPORTS_API_URL]);

	useEffect(() => {
		let filteredData = reportTableData.filter((report) => {
			return report?.name_of_field.toLocaleLowerCase().includes(searchString.toLocaleLowerCase());
		});
		setFilteredData(filteredData);
	}, [reportTableData, searchString])


	// Helper function to fetch data
	async function fetchAllDataReportsTabData(signal) {
		try {
			setLoading(true);
			const response = await axios.get(DATA_CHECK_REPORTS_API_URL, { signal: signal });
			const data = await response?.data;
			setDataAvailable(data?.is_data_available);
			const sorted = await sortByReportCounts(data?.report_table_data);
			setReportTableData(sorted);
			setMissingColumnsInDataCheckReports(data?.data_check_reports?.report_missing_fields);
			setAvailableColumnsInDataCheckReports(data?.data_check_reports?.report_available_fields);
			setNumberOfMissingColumnsInDataCheckReports(data?.data_check_reports?.no_of_missing_fields);
			setNumberOfAvailableColumnsInDataCheckReports(data?.data_check_reports?.no_of_fields_covered);
			if (data?.qa_report_file_present) {
				setReportFileAvailableToDownload(data?.qa_report_file_present);
				setReportFileName(data?.qa_report_file_name);
			}
			setLoading(false);
		}
		catch (error) {
			console.error(error?.message);
			setLoading(false);
		}
	}
	// Helper function to sort the array based on counts from both data_type_report and frequency_report
	function sortByReportCounts(dataArray) {
		return dataArray.slice().sort((a, b) => {
			const getReportCount = (report, reportName) => {
				const specificReport = report.values.find(
					(value) => value.hasOwnProperty(reportName)
				);

				return specificReport ? specificReport[reportName].count || 0 : 0;
			};

			const dataReportCountA = a.report_for_field_name.reduce(
				(sum, report) => sum + getReportCount(report, "data_type_report"),
				0
			);

			const frequencyReportCountA = a.report_for_field_name.reduce(
				(sum, report) => sum + getReportCount(report, "frequency_report"),
				0
			);

			const dataReportCountB = b.report_for_field_name.reduce(
				(sum, report) => sum + getReportCount(report, "data_type_report"),
				0
			);

			const frequencyReportCountB = b.report_for_field_name.reduce(
				(sum, report) => sum + getReportCount(report, "frequency_report"),
				0
			);

			// Sorting in descending order
			if (dataReportCountA !== dataReportCountB) {
				return dataReportCountB - dataReportCountA;
			}

			return frequencyReportCountB - frequencyReportCountA;
		});
	}

	// Helper function to download file
	const downloadCsvGzFile = (apiEndpoint, fileName) => {
		if (reportFileAvailableToDownload) {
			axios
				.get(apiEndpoint, {
					responseType: 'blob',
				})
				.then((response) => {
					const blob = new Blob([response.data], { type: 'application/gzip' });
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.style.display = 'none';
					a.href = url;
					a.download = fileName;
					document.body.appendChild(a);
					a.click();
					window.URL.revokeObjectURL(url);
				})
				.catch((err) => {
					toast.error(err?.message)
				});
		}
	};

	return (
		<Fragment>
			<SpinnerLoader loading={loading}>
				<DataNotFoundAlerts display={dataAvailable} alertMessage={"No Data Available"}>
					<div className='parent-container m-2'>
						<section className='body mb-4'>
							<div className='id-and-download-button d-flex flex-row justify-content-between align-items-end'>
								<div className='rss-crawl-id'>
									<p className='h6'>Report ID: <mark>{rssCrawlPushID}</mark></p>
								</div>
								<div className='download-report-button'>
									<Button
										color="success"
										disabled={!reportFileAvailableToDownload}
										onClick={() => downloadCsvGzFile(CSV_FILE_DOWNLOAD_API_ENDPOINT, reportFileName)}
									>
										{reportFileAvailableToDownload ? `Download Report` : "File Not Present"}
									</Button>
								</div>
							</div>
							<div className='table'></div>
						</section>
					</div>
					<div className='d-flex flex-column'>
						<div className='d-flex flex-row'>
							<div className='d-flex flex-column justify-content-center align-items-center align-content-center m-2 w-100'>
								<div className='d-flex flex-column justify-content-center align-items-center align-content-center p-1 w-100' style={{ backgroundColor: "#40A2E3" }}>
									<p className='fw-bold text-white mb-0'>AVAILABLE COLUMNS IN DATA CHECK REPORTS</p>
									<p className='fw-bold text-white'>TOTAL NUMBER OF FIELDS: {numberOfAvailableColumnsInDataCheckReports}</p>
								</div>
								<div className='d-flex flex-row justify-content-center align-items-center align-content-center flex-wrap my-3 p-2' style={{ backgroundColor: "#FFF6E9" }} >
									{
										availableColumnsInDataCheckReports?.length > 0 && availableColumnsInDataCheckReports?.split(", ").map((data, indx) => (
											<Badge key={indx} color='light'><p className='fw-bold text-dark'>{data}</p></Badge>
										))
									}
								</div>
							</div>
						</div>
						{
							numberOfMissingColumnsInDataCheckReports > 0 && (
								<div className='d-flex flex-row'>
									<div className='d-flex flex-column justify-content-center align-items-center align-content-center m-2 w-100'>
										<div className='d-flex flex-column justify-content-center align-items-center align-content-center p-1 w-100' style={{ backgroundColor: "#40A2E3" }}>
											<p className='fw-bold text-white mb-0'>MISSING COLUMNS IN DATA CHECK REPORTS</p>
											<p className='fw-bold text-white'>TOTAL NUMBER OF FIELDS: {numberOfMissingColumnsInDataCheckReports}</p>
										</div>
										<div className='d-flex flex-row justify-content-center align-items-center align-content-center flex-wrap my-3 p-2' style={{ backgroundColor: "#FFF6E9" }}>
											{
												missingColumnsInDataCheckReports?.length > 0 && missingColumnsInDataCheckReports?.split(", ").map((data, indx) => (
													<Badge key={indx} color='light'><p className='fw-bold text-dark'>{data}</p></Badge>
												))
											}
										</div>
									</div>
								</div>
							)
						}
						<div className='d-flex flex-row justify-content-between align-items-center'>
							<div></div>
							<div className='d-flex flex-row justify-content-center align-items-end'>
								<label htmlFor="search-input" className='mx-2 p-1'>Search:</label>
								<input
									type="text"
									id='search-input'
									value={searchString}
									className='form-control'
									placeholder='eg. product_name'
									onChange={(event) => setSearchString(event?.target?.value)}
								/>
							</div>
						</div>
						<div className='report-table mt-3'>
							<Row>
								{
									filteredData?.map((data, indx) => (
										<Col md="6" key={indx}>
											<FieldReportTable data={data} key={indx} />
										</Col>
									))
								}
							</Row>
						</div>
					</div>
				</DataNotFoundAlerts>
			</SpinnerLoader>
		</Fragment>
	)
}

