import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios'
import { toast } from 'react-toastify';
import { CardBody, Col, Card, Container, Row, Button } from 'reactstrap';
import Breadcrumb from '../../global/Breadcrumb'
import Loader from 'react-loader-spinner';
import DatePicker from 'react-datepicker'
import Select from 'react-select';

export default function EditCoupon() {
    let { id } = useParams();
    const [errors, setErrors] = useState({});
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [allowedOnOrganizations, setAllowedOnOrganizations] = useState([{
        value: 'All', label: 'All'
    }]);
    const [organizationOptions, setOrganizationOptions] = useState([{
        value: 'All', label: 'All'
    }]);
    const [selectedProduct, setSelectedProduct] = useState([{value: 'All', label: 'All'}]);
    const [selectedDataStock, setSelectedDataStock] = useState([{value: 'All', label: 'All'}]);
    const [selectedJobspikr, setSelectedJobspikr] = useState([{value: 'All', label: 'All'}]);
    const [selectedFrequency, setSelectedFrequency] = useState([{value: 'All', label: 'All'}]);
    const [selectedPermissions, setSelectedPermissions] = useState([{value: 'All', label: 'All'}]);
    const [productOptions, setProductOptions] = useState([])
    const [dataStockOptions, setDataStockOptions] = useState([])
    const [jobspikrOptions, setJobspikrOptions] = useState([])
    const [frequencyOptions, setFrequencyOptions] = useState([])
    const [allowedPlansOptions, setAllowedPlansOptions] = useState([])
    const [selectedJpFrequency, setSelectedJpFrequency] = useState([{value: 'All', label: 'All'}])
    const [selectedJpPlans, setSelectedJpPlans] = useState([{value: 'All', label: 'All'}])

    useEffect(() => {
      setLoading(true)
      axios.get(`/api/v2/admins/coupons/${id}/edit`).then(res => {
        console.log('res.data', res.data);
        if (res.data?.coupon_data?.organizations?.length > 0){
            setOrganizationOptions(res.data?.coupon_data?.organizations)
        }
        setProductOptions(res.data?.options?.all_products)
        setDataStockOptions(res.data?.options?.ds_product_categories?.map(each => ({value: each, label: each})))
        setJobspikrOptions(res.data?.options?.jp_product_categories?.map(each => ({value: each[1], label: each[0]})))
        setFrequencyOptions(res.data?.options?.jp_frequencies?.map(each => ({value: each[1], label: each[0]})))
        setAllowedPlansOptions(res.data?.options?.jp_current_plans?.map(each => ({value: each[1], label: each[0]})))
        transform(res.data?.coupon_data?.allowed_on_organizations_ids)

        setSelectedProduct(Objectify(res.data?.coupon_data?.allowed_on_products))
        setData(res.data.coupon_data)

        if (res.data?.coupon_data?.allowed_product_categories?.data_stocks?.product_categories){
            setSelectedDataStock(Objectify(res.data?.coupon_data?.allowed_product_categories?.data_stocks?.product_categories))
        }
        if (res.data?.coupon_data?.allowed_product_categories?.jobspikr?.product_categories){
            setSelectedJobspikr(Objectify(res.data?.coupon_data?.allowed_product_categories?.jobspikr?.product_categories))
        }
        if (res.data?.coupon_data?.allowed_product_categories?.jobspikr?.frequencies){
            setSelectedFrequency(Objectify(res.data?.coupon_data?.allowed_product_categories?.jobspikr?.frequencies))
        }
        if (res.data?.coupon_data?.allowed_product_categories?.jobspikr?.plans){
            setSelectedPermissions(Objectify(res.data?.coupon_data?.allowed_product_categories?.jobspikr?.plans))
        }
        if (res.data?.coupon_data?.allowed_product_categories?.jobspikr?.jobspikr_insights_plans){
            setSelectedJpPlans(Objectify(res.data?.coupon_data?.allowed_product_categories?.jobspikr?.jobspikr_insights_plans))
        }
        if (res.data?.coupon_data?.allowed_product_categories?.jobspikr?.jobspikr_insights_frequencies){
            setSelectedJpFrequency(Objectify(res.data?.coupon_data?.allowed_product_categories?.jobspikr?.jobspikr_insights_frequencies))
        }

        setLoading(false)
      }).catch(err => {
        toast.error(err.response.data.message)
        setLoading(false)
      })
    }, []);

    const transform = (array) => {
        let temp = []
        if (array.length == 0){
            temp.push({value: 'All', label: 'All'})
        } else{
            temp.push(...array)
        }
        setAllowedOnOrganizations(temp)
    }

    const today = new Date(Date.now())

    const Objectify = array => {
        let result = []
        if (array.length === 0){
            result.push({value: 'All', label: 'All'})
        }
        else {
            array.forEach(each => {
                result.push({ value: each, label: each })
            })
        }
        return result
    }

    const Objectify2 = array => {
        let result = []
        if (array.length === 0){
            result.push({value: 'All', label: 'All'})
        }
        else {
            array.forEach(each => {
                if (each == 'plan_trial_april_2021'){
                    result.push({ value: each, label: 'Trial' })
                }
                else if (each == 'plan_starter_april_2021'){
                    result.push({ value: each, label: 'Starter' })
                }
                else if (each == 'plan_business_april_2021'){
                    result.push({ value: each, label: 'Business' })
                }
                else if (each == 'plan_growth_april_2021'){
                    result.push({ value: each, label: 'Growth' })
                }
                else if (each == "custom"){
                    result.push({value: each, label: 'Custom'})
                }
            })
        }
        return result
    }

    const checkIsSelected = (array, string) => {
        let result = false
        array.forEach(eachObj => {
            if (eachObj.value === string){
                result = true
            }
        })
        return result
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setErrors({})
        let errorsObj = {}
        let selectedProductValues = []
        let selectedDataStockValues = []
        let selectedJobspikrValues = []
        let selectedFrequencyValues = []
        let selectedPermissionsValues = []
        let selectedJpFrequencyValues = []
        let selectedJpPlansValues = []

        selectedProduct.forEach(each => {
            selectedProductValues.push(each.value)
        })
        selectedDataStock.forEach(each => {
            selectedDataStockValues.push(each.value)
        })
        selectedJobspikr.forEach(each => {
            selectedJobspikrValues.push(each.value)
        })
        selectedFrequency.forEach(each => {
            selectedFrequencyValues.push(each.value)
        })
        selectedPermissions.forEach(each => {
            selectedPermissionsValues.push(each.value)
        })
        selectedJpFrequency.forEach(each => {
            selectedJpFrequencyValues.push(each.value)
        })
        selectedJpPlans.forEach(each => {
            selectedJpPlansValues.push(each.value)
        })

        if (selectedProductValues.includes('All') && selectedProductValues.length > 1){
            errorsObj = {...errorsObj, products: 'Please choose either all or other options'}
        }
        if (selectedProductValues.includes("data_stocks") && (selectedDataStockValues.includes('All') && selectedDataStockValues.length > 1)){
            errorsObj = {...errorsObj, data_stocks: 'Please choose either all or other options'}
        }
        if (selectedProductValues.includes("jobspikr") && 
            (selectedJobspikrValues.includes('All') && selectedJobspikrValues.length > 1)){
            errorsObj = {...errorsObj, jobspikr_product: 'Please choose either all or other options'}
        }
        if ((selectedProductValues.includes("jobspikr") && selectedJobspikrValues.includes("job_data")) && 
            (selectedFrequencyValues.includes('All') && selectedFrequencyValues.length > 1)){
            errorsObj = {...errorsObj, allowed_frequencies: 'Please choose either all or other options'}
        }
        if ((selectedProductValues.includes("jobspikr") && selectedJobspikrValues.includes("job_data")) && 
            (selectedPermissionsValues.includes('All') && selectedPermissionsValues.length > 1)){
            errorsObj = {...errorsObj, allowed_plans: 'Please choose either all or other options'}
        }
        if ((selectedProductValues.includes("jobspikr") && selectedJobspikrValues.includes("jobspikr_insights")) && 
            (selectedJpFrequencyValues.includes('All') && selectedJpFrequencyValues.length > 1)){
            errorsObj = {...errorsObj, jp_allowed_freq: 'Please choose either All or other options'}
        }
        if ((selectedProductValues.includes("jobspikr") && selectedJobspikrValues.includes("jobspikr_insights")) && 
            (selectedJpPlansValues.includes('All') && selectedJpPlansValues.length > 1)){
            errorsObj = {...errorsObj, jp_allowed_plans: 'Please choose either All or other options'}
        }
        if (data.coupon_code?.trim()?.length === 0){
            errorsObj = {...errorsObj, couponCode: 'Please enter coupon code to continue'}
        }
        if (data?.max_usage_per_org?.toString().trim()?.length === 0){
            errorsObj = {...errorsObj, maxUsage: 'Please enter 0 if there is no restriction'}
        }
        if (data?.expire_at?.toString().trim()?.length === 0){
            errorsObj = {...errorsObj, endDate: 'Please enter coupon expiry date'}
        }
        if (data?.discount_value?.length === 0 || data?.discount_value === 0.00){
            errorsObj = {...errorsObj, discountValue: 'Please enter valid discount value'}
        }
        setErrors(errorsObj)
        if (Object.keys(errorsObj).length === 0){
            let resObj1 = {}
            selectedDataStockValues = selectedDataStockValues.filter(each => each != 'All')
            if (selectedProductValues.includes("data_stocks") && selectedDataStockValues.filter(each => each != 'All').length > 0){
                resObj1 = {...resObj1, data_stocks: {
                    ...resObj1.data_stocks, product_categories: selectedDataStockValues
                }}
            }
            if (selectedProductValues.includes("jobspikr") && selectedJobspikrValues.filter(each => each != 'All').length > 0){
                resObj1 = {...resObj1, jobspikr: {
                    ...resObj1.jobspikr, product_categories: selectedJobspikrValues
                }}
            }
            if ((selectedProductValues.includes("jobspikr") && selectedJobspikrValues.includes("job_data")) 
                && selectedFrequencyValues.filter(each => each != 'All').length > 0){
                resObj1 = {...resObj1, jobspikr: {
                    ...resObj1.jobspikr, frequencies: selectedFrequencyValues
                }}
            }
            if ((selectedProductValues.includes("jobspikr") && selectedJobspikrValues.includes("job_data")) 
                && selectedPermissionsValues.filter(each => each != 'All').length > 0){
                resObj1 = {...resObj1, jobspikr: {
                    ...resObj1.jobspikr, plans: selectedPermissionsValues
                }}
            }
            if ((selectedProductValues.includes("jobspikr") && selectedJobspikrValues.includes("jobspikr_insights")) 
                && selectedJpFrequencyValues.filter(each => each != 'All').length > 0){
                resObj1 = {...resObj1, jobspikr: {
                    ...resObj1.jobspikr, jobspikr_insights_plans: selectedJpFrequencyValues
                }}
            }
            if ((selectedProductValues.includes("jobspikr") && selectedJobspikrValues.includes("jobspikr_insights")) 
                && selectedJpPlansValues.filter(each => each != 'All').length > 0){
                resObj1 = {...resObj1, jobspikr: {
                    ...resObj1.jobspikr, jobspikr_insights_frequencies: selectedJpPlansValues
                }}
            }

            let allowedOrganizationValues = []
            allowedOnOrganizations.forEach(each => {
                allowedOrganizationValues.push(each.value)
            })
            allowedOrganizationValues = allowedOrganizationValues.filter(each => each != 'All')
            selectedProductValues = selectedProductValues.filter(each => each != 'All')

            axios.put(`/api/v2/admins/coupons/${id}`, { coupon: {
                coupon_code: data?.coupon_code, discount_type: data?.discount_type,
                discount_value: data?.discount_value, max_usage_per_org: data?.max_usage_per_org, 
                start_at: data?.start_at, expire_at: data?.expire_at, allowed_on_organizations_ids: allowedOrganizationValues,
                allowed_on_products: selectedProductValues, for_new_org_only: data?.for_new_org_only, allowed_product_categories: resObj1
            }}).then(res => {
                toast.success(res.data?.message)
                history.push('/admins/coupons')
            }).catch(err => {
                toast.error(err.response.data.message)
            })
        }
    }
    
    return <Container fluid>
        <Breadcrumb title={"Edit Coupon"} parent={"Discount Coupon"}
            childrenObj={[{name: 'Coupons', url: '/admins/coupons'}]} />
        <Card>
          <CardBody>
              {loading 
              ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> 
              : <form onSubmit={handleSubmit}>
                  <Row className='my-4'>
                      <Col sm={3}>
                          <h6 className='fw-bold my-2 float-end'>Coupon Code*</h6>
                      </Col>
                      <Col sm={9}>
                          <input type="text" className='form-control' placeholder='required' disabled='true' 
                              value={data.coupon_code} onChange={(e) => setData({...data, coupon_code: e.target.value})}/>
                          {errors?.couponCode && <p className='text-danger'> {errors?.couponCode} </p>}
                      </Col>
                  </Row>
                  <Row className='my-4'>
                      <Col sm={3}>
                          <h6 className='fw-bold my-2 float-end'>Allowed on Organizations</h6>
                      </Col>
                      <Col sm={9}>
                          <Select value={allowedOnOrganizations} classNamePrefix="select" 
                              isMulti options={organizationOptions} className="basic-multi-select allowed_on_org" 
                              onChange={(res) => setAllowedOnOrganizations(res)} />
                          <p className='text-muted'>If nothing is selected it will be applied to everything</p>
                      </Col>
                  </Row>
                  <Row className='my-4'>
                      <Col sm={3}>
                          <h6 className='fw-bold my-2 float-end'>Max usage per Organization*</h6>
                      </Col>
                      <Col sm={9}>
                        <input type="text" className='form-control' name="max_usage_per_org"
                          placeholder='if there is no restriction enter 0' 
                              value={data?.max_usage_per_org} onChange={(e) => setData({...data, max_usage_per_org: e.target.value})}/>
                          <p className='text-muted my-0'>if there is no restriction enter 0</p>
                          {errors?.maxUsage && <p className='text-danger'> {errors?.maxUsage} </p>}
                      </Col>
                  </Row>
                  <Row className='my-4'>
                      <Col sm={3}>
                          <h6 className='fw-bold my-2 float-end'>Products</h6>
                      </Col>
                      <Col sm={9}>
                          <Select value={selectedProduct} classNamePrefix="select" 
                              isMulti options={productOptions} className="basic-multi-select products" 
                              onChange={(res) => setSelectedProduct(res)} />
                          <p className='text-muted my-0'>If nothing is selected it will be applied to everything</p>
                          {errors.products && <p className='text-danger'>{errors.products}</p>}
                      </Col>
                  </Row>
                  {checkIsSelected(selectedProduct, 'data_stocks') && <Row className='my-4'>
                      <Col sm={3}>
                          <h6 className='fw-bold my-2 float-end'>DataStock Product Categories</h6>
                      </Col>
                      <Col sm={9}>
                          <Select value={selectedDataStock} classNamePrefix="select" 
                              isMulti options={dataStockOptions} className="basic-multi-select datastock_product_categories" 
                              onChange={(res) => setSelectedDataStock(res)} />
                          <p className='text-muted my-0'>If nothing is selected it will be applied to everything</p>
                          {errors.data_stocks && <p className='text-danger'>{errors.data_stocks}</p>}
                      </Col>
                  </Row>}
                  {checkIsSelected(selectedProduct, 'jobspikr') && <Row className='my-4'>
                      <Col sm={3}>
                          <h6 className='fw-bold my-2 float-end'>Jobspikr Product Categories</h6>
                      </Col>
                      <Col sm={9}>
                          <Select value={selectedJobspikr} classNamePrefix="select" 
                              isMulti options={jobspikrOptions} className="basic-multi-select jobspikr_product_categories" 
                              onChange={(res) => setSelectedJobspikr(res)} />
                          <p className='text-muted my-0'>If nothing is selected it will be applied to everything</p>
                          {errors?.jobspikr_product && <p className='text-danger'> {errors?.jobspikr_product} </p>}
                      </Col>
                  </Row>}
                  {(checkIsSelected(selectedProduct, 'jobspikr') && 
                        checkIsSelected(selectedJobspikr, 'job_data')) && <Row className='my-4'>
                      <Col sm={3}>
                          <h6 className='fw-bold my-2 float-end'>Allowed on Frequencies</h6>
                      </Col>
                      <Col sm={9}>
                          <Select value={selectedFrequency} classNamePrefix="select" 
                              isMulti options={frequencyOptions} className="basic-multi-select allowed_on_frequencies" 
                              onChange={(res) => setSelectedFrequency(res)} />
                          <p className='text-muted my-0'>If nothing is selected it will be applied to everything</p>
                          {errors?.allowed_frequencies && <p className='text-danger'> {errors?.allowed_frequencies} </p>}
                      </Col>
                  </Row>}
                  {(checkIsSelected(selectedProduct, 'jobspikr') && 
                        checkIsSelected(selectedJobspikr, 'job_data')) && <Row className='my-4'>
                      <Col sm={3}>
                          <h6 className='fw-bold my-2 float-end'>Allowed on Plans</h6>
                      </Col>
                      <Col sm={9}>
                          <Select value={selectedPermissions} classNamePrefix="select" 
                              isMulti options={allowedPlansOptions} className="basic-multi-select allowed_on_plans" 
                              onChange={(res) => setSelectedPermissions(res)} />
                          <p className='text-muted my-0'>If nothing is selected it will be applied to everything</p>
                          {errors?.allowed_plans && <p className='text-danger'> {errors?.allowed_plans} </p>}
                      </Col>
                  </Row>}
                  {(checkIsSelected(selectedProduct, 'jobspikr') && 
                    checkIsSelected(selectedJobspikr, 'jobspikr_insights')) && <Row className='my-4'>
                    <Col sm={3}>
                        <h6 className='fw-bold my-2 float-end'>Allowed on Jp Insight Frequencies</h6>
                    </Col>
                    <Col sm={9}>
                        <Select value={selectedJpFrequency} classNamePrefix="select" 
                            isMulti options={frequencyOptions} className="basic-multi-select allowed_on_jp_insight_frequencies" 
                            onChange={(res) => setSelectedJpFrequency(res)} />
                        <p className='text-muted my-0'>If nothing is selected it will be applied to everything</p>
                        {errors?.jp_allowed_freq && <p className='text-danger'> {errors?.jp_allowed_freq} </p>}
                    </Col>
                    </Row>}
                    {(checkIsSelected(selectedProduct, 'jobspikr') && 
                    checkIsSelected(selectedJobspikr, 'jobspikr_insights')) && <Row className='my-4'>
                    <Col sm={3}>
                        <h6 className='fw-bold my-2 float-end'>Allowed on JP Insights Plans</h6>
                    </Col>
                    <Col sm={9}>
                        <Select value={selectedJpPlans} classNamePrefix="select" 
                            isMulti options={allowedPlansOptions} className="basic-multi-select allowed_on_jp_insights_plans" 
                            onChange={(res) => setSelectedJpPlans(res)} />
                        <p className='text-muted my-0'>If nothing is selected it will be applied to everything</p>
                        {errors?.jp_allowed_plans && <p className='text-danger'> {errors?.jp_allowed_plans} </p>}
                    </Col>
                    </Row>}
                  <Row className='my-4'>
                      <Col sm={3}>
                          <h6 className='fw-bold my-2 float-end'>Coupon Type</h6>
                      </Col>
                      <Col sm={9}>
                          <select className='form-control' name="coupon_type" value={data?.discount_type} 
                            onChange={(e) => setData({...data, discount_type: e.target.value})}>
                              <option value="fixed">Fixed</option>
                              <option value="percent">Percent</option>
                          </select>
                      </Col>
                  </Row>
                  <Row className='my-4'>
                      <Col sm={3}>
                          <h6 className='fw-bold my-2 float-end'>Discount Value*</h6>
                      </Col>
                      <Col sm={9}>
                          <input className="form-control"type="text" name="discount_value"
                              value={data?.discount_value} onChange={(e) => setData({...data, discount_value: e.target.value})} />
                          {errors?.discountValue && <p className='text-danger'> {errors?.discountValue} </p>}
                      </Col>
                  </Row>
                  <Row className='my-4'>
                      <Col sm={3}>
                          <h6 className='fw-bold my-2 float-end'>Allow only for new Org</h6>
                      </Col>
                      <Col sm={9}>
                          <input className="form-check form-checkbox-align-top" name="allow_only_for_new_org" type="checkbox" checked={data?.for_new_org_only}
                              onChange={(e) => setData({...data, for_new_org_only: e.target.checked})} />
                      </Col>
                  </Row>
                  <Row className='my-4'>
                      <Col sm={3}>
                          <h6 className='fw-bold my-2 float-end'>Start At*</h6>
                      </Col>
                      <Col sm={9}>
                          <DatePicker className="form-control" name="start_at" minDate={today} selected={new Date(data?.start_at)}
                              onChange={(date) => setData({...data, start_at: date})} />
                      </Col>
                  </Row>
                  <Row className='my-4'>
                      <Col sm={3}>
                          <h6 className='fw-bold my-2 float-end'>Expire At*</h6>
                      </Col>
                      <Col sm={9}>
                          <DatePicker className="form-control" name="expire_at" minDate={data?.start_at} selected={new Date(data?.expire_at)}
                              onChange={(date) => setData({...data, expire_at: date})} />
                          {errors?.endDate && <p className='text-danger'> {errors?.endDate} </p>}
                      </Col>
                  </Row>
                  <Button color='success' className='float-end' type='submit'>
                      Submit
                  </Button>
              </form>}
          </CardBody>
      </Card>
    </Container>;
}
