import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import { Home } from "react-feather";
import Loader from "react-loader-spinner";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import { Container, Row, Col, BreadcrumbItem, Breadcrumb, Card, CardBody } from "reactstrap";

import { NewJpInternalBasePoint, NewInternalBasePoint } from '../../../../Common/BaseEsPoints/InternalEsBasePoint';

export default function UploadCountVsIndexCountOfJpSites() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[site_name]"),
    fromDate: new URLSearchParams(search).get("filter[from_date]"),
    toDate: new URLSearchParams(search).get("filter[to_date]"),
    platformType: new URLSearchParams(search).get("filter[platform_type]")
  }

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [platformType, setPlatformType] = useState({ label: 'All', value: null });
  const [data, setData] = useState([])
  const [searchData, setSearchData] = useState([])
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);

  const platformTypesOptions = [
    { label: 'All', value: null },
    { label: "f500", value: 'f500' },
    { label: "Job Board", value: "job_board" },
    { label: "Platforms", value: "platforms" },
    { label: "Common", value: 'common' }
  ]

  const columnsWithCount = [
    { name: "Jobspikr site name", sortable: true, center: true, cell: row => <a className="link-primary" href={`${NewInternalBasePoint}/upload?filter[site]=${'abm_jobaline_com_common_set2_pc_jobspikr'}`}>{row.site_name}</a>, grow: 2 },
    { name: "Upload count last 24 hours", selector: "upload_count_24_hours", sortable: true, center: true },
    { name: "Indexed count last 24 hours", selector: "indexed_count_24_hours", sortable: true, center: true },
    { name: "Upload count last 7 days", selector: "upload_count_7_days", sortable: true, center: true },
    { name: "Indexed count last 7 days", selector: "indexed_count_7_days", sortable: true, center: true },
    { name: "Upload count last 30 days", selector: "upload_count_30_days", sortable: true, center: true },
    { name: "Indexed count last 30 days", selector: "indexed_count_30_days", sortable: true, center: true },
    { name: `Upload count ${moment(fromDate).format("YYYY/MM/DD")} to ${moment(toDate).format("YYYY/MM/DD")}`, selector: "upload_count_custom_date", sortable: true, center: true },
    { name: `Indexed count ${moment(fromDate).format("YYYY/MM/DD")} to ${moment(toDate).format("YYYY/MM/DD")}`, selector: "indexed_count_custom_date", sortable: true, center: true }
  ]

  const columnsWithOutCount = [
    { name: "Jobspikr site name", sortable: true, center: true, cell: row => <a className="link-primary" href={`${NewInternalBasePoint}/upload?filter[site]=${'abm_jobaline_com_common_set2_pc_jobspikr'}`}>{row.site_name}</a>, grow: 2 },
    { name: "Upload count last 24 hours", selector: "upload_count_24_hours", sortable: true, center: true },
    { name: "Indexed count last 24 hours", selector: "indexed_count_24_hours", sortable: true, center: true },
    { name: "Upload count last 7 days", selector: "upload_count_7_days", sortable: true, center: true },
    { name: "Indexed count last 7 days", selector: "indexed_count_7_days", sortable: true, center: true },
    { name: "Upload count last 30 days", selector: "upload_count_30_days", sortable: true, center: true },
    { name: "Indexed count last 30 days", selector: "indexed_count_30_days", sortable: true, center: true },
  ]

  useEffect(() => {
    setSearchParamsData();
  }, []);

  function setSearchParamsData() {
    if (searchParams.fromDate && searchParams.fromDate != fromDate) {
      setFromDate(new Date(searchParams.fromDate))
    }
    if (searchParams.toDate && searchParams.toDate != toDate) {
      setToDate(new Date(searchParams.toDate))
    }
    if (searchParams.platformType && searchParams.platformType != platformType.value) {
      setPlatformType(platformTypesOptions.filter(item => item.value == searchParams.platformType)[0])
    }
    if (commit == 'Get Data') {

      fetchData(searchParams.fromDate, searchParams.toDate, searchParams?.platformType || '')
    }
    else {
      fetchData();
    }

  }

  function fetchData(FromDate = fromDate, ToDate = toDate, PlatformType = platformType.value) {
    setLoading(true);
    axios.get(`/api/v2/jobspikr_v3_internal/jobspikr_graphs/upload_count_vs_index_count_of_jp_sites?filter[from_date]=${FromDate ? moment(FromDate).format("YYYY/MM/DD") : ''}&filter[to_date]=${ToDate ? moment(ToDate).format("YYYY/MM/DD") : ''}&filter[platform_type]=${PlatformType ? PlatformType : ''}`)
      .then(res => {
        setData(res.data.data);
        setSearchData(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong');
        setLoading(false);

      })
  }

  const handleInputChange = event => {
    if (event.target.value == '') {
      setSearchData(data);
    }
    else {
      setSearchData(data.filter(item => item.site_name.includes(event.target.value)))
    }
    setSearchText(event.target.value)
  }

  const handleGetData = () => {
    window.location.href = `${NewJpInternalBasePoint}/jobspikr_graphs/upload_count_vs_index_count_of_jp_sites?filter[from_date]=${fromDate ? moment(fromDate).format("YYYY/MM/DD") : ''}&filter[to_date]=${toDate ? moment(toDate).format("YYYY/MM/DD") : ''}&filter[platform_type]=${platformType.value ? platformType.value : ''}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row>
        <Col xs="6" className="flex-column">
          <h4>Internal Dashboard</h4>

        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              Internal QA
            </BreadcrumbItem>
            <BreadcrumbItem className="fw-bold">
              {commit == 'Get Data' ? <a className="link-primary fw-normal" href={`${NewJpInternalBasePoint}/jobspikr_graphs/upload_count_vs_index_count_of_jp_sites`}>Upload vs Indexed count of JP sites</a> : 'Upload vs Indexed count of JP sites'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
          </BreadcrumbItem>}
        </Breadcrumb>
      </Col>
    </Row>
    <div className="d-flex justify-content-end">
      <a className="btn btn-primary" target="_blank" href={`${NewJpInternalBasePoint}/jobspikr_graphs/count_data_of_most_used_sites`}>Data of top JP sites</a>
    </div>
  </div>
  <Card>
    <CardBody>
      <Row className="align-items-end" >
        <Col sm="3">
          <label className="fw-bold">From Date</label>
          <DatePicker
            className="form-control"
            placeholderText="Select Date"
            selected={fromDate ? new Date(fromDate) : fromDate}
            dateFormat={"YYY/MM/dd"}
            onChange={date => setFromDate(date)} />
        </Col>
        <Col sm="3">
          <label className="fw-bold">To Date</label>
          <DatePicker
            className="form-control"
            selected={toDate ? new Date(toDate) : toDate}
            placeholderText="Select Date"
            dateFormat={"YYY/MM/dd"}
            onChange={date => setToDate(date)} />
        </Col>
        <Col sm="3">
          <label className="fw-bold">Platform type</label>
          <Select
            options={platformTypesOptions}
            value={platformType}
            onChange={option => setPlatformType(option)} />
        </Col>
        <Col sm="3">
          <button className="btn btn-success w-100" onClick={handleGetData} >Get Data</button>
        </Col>
      </Row>
    </CardBody>
  </Card>
  {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> : <Card>
    <CardBody>
      <center className="fw-bold">Upload count vs Indexed count</center>
      <Row className="my-3">
        <Col>
          <div className="float-end">
            <Row className="align-items-center">
              <Col sm="3">Search</Col>
              <Col>
                <input type="text" className="form-control" value={searchText} onChange={handleInputChange} />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <DataTable
        columns={(fromDate && toDate && commit == 'Get Data') ? columnsWithCount : columnsWithOutCount}
        data={searchData}
        pagination
        highlightOnHover
        noHeader />
    </CardBody>
</Card>}
    </Container>
}
