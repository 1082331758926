import axios from "axios";
import React, { useEffect, useState } from "react";
import { Home } from "react-feather";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row } from "reactstrap";


import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";


export default function SiteWiseFailedAndSucceededUrlStats() {
    const search = useLocation().search;
    const commit = new URLSearchParams(search).get("commit");
    const searchParams = {
        siteName: new URLSearchParams(search).get("filter[site_name]"),
        time: new URLSearchParams(search).get("filter[time]"),
        seleniumCluster: new URLSearchParams(search).get("filter[selenium_cluster]")
    }

    const [time, setTime] = useState({ label: 'Last 2 days', value: 'now-2d' });
    const [siteName, setSiteName] = useState({ label: 'All', value: 'all' });
    const [siteNameOptions, setSiteNameOptions] = useState([]);
    const [seleniumCluster, setSeleniumCluster] = useState({ label: 'All', value: 'all' });
    const [seleniumOptions, setSeleniumOptions] = useState([]);
    const [urlParams, setUrlParams] = useState({
        siteName: '*',
        seleniumCluster: '*',
        time: 'now-2d'
    })
    const timeOptions = [
        { label: 'Today', value: 'now%2Fd' },
        { label: 'Last 30 minutes', value: 'now-30m' },
        { label: 'Last 1 hour', value: 'now-1h' },
        { label: 'Last 2 hours', value: 'now-2h' },
        { label: 'Last 4 hours', value: 'now-4h' },
        { label: "Last 10 hours", value: "now-10h" },
        { label: 'Last 12 hours', value: 'now-12h' },
        { label: 'Last 24 hours', value: 'now-24h' },
        { label: 'Last 2 days', value: 'now-2d' },
        { label: 'Last 3 days', value: 'now-3d' },
        { label: 'Last 4 days', value: 'now-4d' },
        { label: 'Last 7 days', value: 'now-7d' },
        { label: 'Last 15 days', value: 'now-15d' },
        { label: 'Last 30 days', value: 'now-30d' },
        { label: 'Last 60 days', value: 'now-60d' },
        { label: 'Last 1 year', value: 'now-1y' }
    ];

    useEffect(() => {
        setSearchParamsData();
        axios.get('/api/v1/internal/dropdown_list_helper')
            .then(res => {
                let site_names = res.data.site_names, site_names_options = []
                for (var key in site_names) {
                    let group = {}, children = []
                    group['label'] = <div className="fw-bold col text-black">{key}</div>
                    site_names[key].map(item => {
                        children.push({ label: item, value: item })
                    })
                    group['options'] = children;
                    site_names_options.push(group)
                }
                site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                setSiteNameOptions(site_names_options);
                let result = res.data.geo_clusters.map(item => ({ label: item, value: item }));
                result.push({ label: 'All', value: 'all' })
                setSeleniumOptions(result);
            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong')
            });
    }, []);

    function setSearchParamsData() {
        if (searchParams.siteName && searchParams.siteName != siteName.value) {
            setSiteName({ label: searchParams.siteName == 'all' ? 'All' : searchParams.siteName, value: searchParams.siteName });
        }
        if (searchParams.time && searchParams.time != time.value) {
            setTime(timeOptions.filter(item => searchParams.time == item.value)[0])
        }
        if (searchParams.seleniumCluster && searchParams.seleniumCluster != seleniumCluster.value) {
            setSeleniumCluster({ label: searchParams.seleniumCluster == 'all' ? 'All' : searchParams.seleniumCluster, value: searchParams.seleniumCluster })
        }
        if (commit == 'Get Data' && searchParams.siteName && searchParams.time && searchParams.seleniumCluster) {
            setUrlParams(searchParams)
        }
    }

    const handleGetData = () => {
        window.location.href = `/internal/selenium/sitewise_failed_and_succeeded_url_stats?filter[site_name]=${siteName.value}&filter[selenium_cluster]=${seleniumCluster.value}&filter[time]=${time.value}&commit=${'Get Data'}`
    }

    const promiseOptions = (inputValue) => {
        if (inputValue.length < 3) {
            return null
        } else {
            return new Promise((resolve) => {
                axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
                    if (res.status == '200') {
                        let site_names = res.data.site_names, site_names_options = []
                        for (var key in site_names) {
                            let group = {}, children = []
                            group['label'] = <div className="fw-bold col text-black">{key}</div>
                            site_names[key].map(item => {
                                children.push({ label: item, value: item })
                            })
                            group['options'] = children;
                            site_names_options.push(group)
                        }
                        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                        setSiteNameOptions(site_names_options)
                        resolve(site_names_options)
                    }
                }).catch(err => {
                    console.log(`err`, err)
                })
            })
        }
    }

    return <Container fluid>
        <div className="page-title">
            <Row className=''>
                <Col xs="6">
                    <h3>Internal Dashboard</h3>
                </Col>
                <Col xs="6">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={'/interanal'}>
                                <Home />
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            {commit == 'Get Data' ? <a href={'/internal/selenium/sitewise_failed_and_succeeded_url_stats'}>Sitewise Failed and Succeeded Url Stats</a> : 'Sitewise Failed and Succeeded Url Stats'}
                        </BreadcrumbItem>
                        {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                            Search Results
                        </BreadcrumbItem>}
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
        <Card>
            <CardBody>
                <Row>
                    <Col sm="8">
                        <label className="fw-bold">Site Name</label>
                        <AsyncSelect cacheOptions defaultOptions={siteNameOptions} name='site_name'
                            value={siteName} loadOptions={promiseOptions} onChange={res => setSiteName(res)}
                            placeholder="Select a site name" />
                            <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
                    </Col>
                </Row>
                <Row className="align-items-end mt-3">
                    <Col sm="4">
                        <label className="fw-bold">Choose Time</label>
                        <Select
                            value={time}
                            options={timeOptions}
                            onChange={option => setTime(option)} />
                    </Col>
                    <Col sm="4">
                        <label className="fw-bold">Selenium Cluster</label>
                        <Select
                            value={seleniumCluster}
                            options={seleniumOptions}
                            onChange={option => setSeleniumCluster(option)} />
                    </Col>
                    <Col sm="4">
                        <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
        <Card>
            <CardBody>
                <center>
                    <a target="_blank" className="link-primary" href={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.time},mode:quick,to:${urlParams.time == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'diskfetcher_crawl_time_tracker:true%20AND%20diskfetcher_type:%20%22${'selenium'}%22%20AND%20site_name:%20${urlParams.siteName == 'all' ? '*' : urlParams.siteName}%20AND%20selenium_cluster:%20%22${urlParams.seleniumCluster == 'all' ? '*' : urlParams.seleniumCluster}%22')),uiState:(),vis:(aggs:!((id:'1',params:(field:crawled_urls_count),schema:metric,type:sum),(id:'2',params:(field:failed_count),schema:metric,type:sum),(id:'3',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:auto,min_doc_count:1),schema:segment,type:date_histogram)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:'Sitewise%20Failed%20and%20Succeeded%20Url%20Stats',type:line))`}>
                        Click here to see below graph in kibana
                    </a>
                </center>
                <iframe src={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.time},mode:quick,to:${urlParams.time == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'diskfetcher_crawl_time_tracker:true%20AND%20diskfetcher_type:%20%22${'selenium'}%22%20AND%20site_name:%20${urlParams.siteName == 'all' ? '*' : urlParams.siteName}%20AND%20selenium_cluster:%20%22${urlParams.seleniumCluster == 'all' ? '*' : urlParams.seleniumCluster}%22')),uiState:(),vis:(aggs:!((id:'1',params:(field:crawled_urls_count),schema:metric,type:sum),(id:'2',params:(field:failed_count),schema:metric,type:sum),(id:'3',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:auto,min_doc_count:1),schema:segment,type:date_histogram)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:'Sitewise%20Failed%20and%20Succeeded%20Url%20Stats',type:line))&embed=true`} height="500" width="100%" className="border-0">

                </iframe>
            </CardBody>
        </Card>
    </Container>
}