import React, {useState, useEffect} from 'react'
import { Alert } from 'reactstrap'
import Loader from 'react-loader-spinner';
import axios from 'axios'
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { useParams } from "react-router-dom";

export default function SegmentLevelQaFile({downloadDoc, activeTabName}) {
  let { sitename } = useParams();
  const [data, setData] = useState({});
  const [download, setDownload] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (activeTabName == "qa_file"){
      setLoading(true)
      axios.get(`/api/v2/internal/qa/segment_level_qa?site_name=${sitename}&data_fetch_type=segment_level_qa_file`).then(res => {
        setData(res?.data?.qa_json_data)
        setLoading(false)
      }).catch(err => {
        console.log("err", err);
        toast.error(err.response?.data?.message)
        setLoading(false)
      })
    }
  }, [activeTabName])

  const qaFileTableColoum = [
    {name: 'Field Name',selector: 'field_name',sortable: false, center: false},
    {
      name: 'present%',
      selector: 'percentage',
      sortable: false, 
      center: false,
      conditionalCellStyles: [
        {
          when: row => row.percentage >= 80,
          style: {
            backgroundColor: '#51bb25 !important',
            color: 'white',
            fontWeight: '700 !important',
          },
        },
        {
          when: row => row.percentage < 80 && row.percentage >= 50,
          style: {
            backgroundColor: '#f8d62b !important',
            color: 'rgba(33,37,41,var(--bs-text-opacity)) !important',
            fontWeight: '700 !important',
          },
        },
        {
          when: row => row.percentage < 50,
          style: {
            backgroundColor: '#dc3545 !important',
            color: 'white',
            fontWeight: '700 !important',
          },
        },
      ],
    },
  ]

  const handleJSONDownload = () => {
    setDownload(true)
    axios.get(`/api/v2/internal/qa/${sitename}/download_qa_json_file`).then(res => {
      downloadDoc(JSON.stringify(res.data), `download_${sitename}.json`, "text/json")
      setDownload(false)
    }).catch(err => {
      toast.error(err.response.data?.message)
      setDownload(false)
    })
  }

  return (
    <>
      {
        loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
          :
          <>
            {
              data?.file_present == false ? 
              <Alert color='light'>
                <div dangerouslySetInnerHTML={{__html: data?.error_message}} />
              </Alert>
                :
                <>
                  <div className='my-4'>
                    <p className='fw-bold m-0' style={{float: 'right'}}>
                      Last updated: {data?.last_updated_at}
                    </p> 
                    <br /> 
                    <br />
                    {
                      download ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
                        : 
                        <Alert color='primary'>
                          <h6 className="hidden"> <button className='btn text-white fw-bold' onClick={handleJSONDownload}> Click here </button> to download the actual file</h6>
                          <h6> <a href={data?.download_link} target="_blank" rel="noopener noreferrer" className='btn text-white fw-bold'> Click here </a>to download the actual file</h6>
                          <h6>Number of records considered for QA Report: <b> {data?.qa_total_records} </b> </h6>
                        </Alert>
                    }
                  </div>

                  <DataTable 
                    columns={qaFileTableColoum} 
                    responsive
                    data={data?.qa_json_table_data} 
                    pagination 
                  />
              </>
            }
          </>
      }
    </>
  )
}
