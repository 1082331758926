import React, {useState, useEffect} from 'react'
import Breadcrumb from '../../global/Breadcrumb'
import axios from 'axios'
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import { useHistory, useLocation } from "react-router-dom";

export default function Create() {
  let history = useHistory();

  const [loading, setLoading] = useState(true);

  const search = useLocation().search;
  const code = new URLSearchParams(search).get("code");

  useEffect(() => {
    if (code) {
      setLoading(true)
      axios.post(`/api/v1/admins/zohobooks_oauth2_callbacks`, {code: code})
        .then(res => {
          toast.success(response?.data?.message)
          setLoading(false)
          history.push('/admins/zohobooks_oauth2_callbacks/new')
        }).catch(err => {
          toast.error(err?.response?.data?.message)
          setLoading(false)
          history.push('/admins/zohobooks_oauth2_callbacks/new')
        })
    }
  }, []);

  return (
    <>
      {
        loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
          : "Error------"
      }
    </>
  )
}
