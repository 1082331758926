import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";

import { KIBANA_JP_URL } from "../../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function JpImportantFilters() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const tabNameFromSearchUrl = new URLSearchParams(search).get("filter[tab_name]");
  const tabNameFromUrl = window.location.hash.substr(1);
  const searchParams = {
    fromTime: new URLSearchParams(search).get("filter[time]"),
    tabName: tabNameFromSearchUrl ? tabNameFromSearchUrl : tabNameFromUrl
  }
  const [fromTime, setFromTime] = useState({ label: 'Last 1 year', value: 'now-1y' });
  const [activeTab, setActiveTab] = useState(tabNameFromUrl ? tabNameFromUrl : 'jobspikr_v3_data');

  const timeOptions = [
    { label: 'Today', value: 'now%2Fd' },
    { label: 'Last 30 minutes', value: 'now-30m' },
    { label: 'Last 1 hour', value: 'now-1h' },
    { label: 'Last 2 hours', value: 'now-2h' },
    { label: 'Last 4 hours', value: 'now-4h' },
    { label: "Last 10 hours", value: "now-10h" },
    { label: 'Last 12 hours', value: 'now-12h' },
    { label: 'Last 24 hours', value: 'now-24h' },
    { label: 'Last 2 days', value: 'now-2d' },
    { label: 'Last 3 days', value: 'now-3d' },
    { label: 'Last 4 days', value: 'now-4d' },
    { label: 'Last 7 days', value: 'now-7d' },
    { label: 'Last 15 days', value: 'now-15d' },
    { label: 'Last 30 days', value: 'now-30d' },
    { label: 'Last 60 days', value: 'now-60d' },
    { label: 'Last 1 year', value: 'now-1y' }
  ];

  const [UrlParams, setUrlParams] = useState({
    fromTime: 'now-1y',
    tabName: "jobspikr_v3_data"
  })

  useEffect(() => {
    setSearchParamsData();
  }, []);

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.fromTime && searchParams.fromTime != fromTime.value) {
      setFromTime(timeOptions.filter(item => item.value == searchParams.fromTime)[0]);
    }
    if (searchParams.tabName && searchParams.tabName != activeTab) {
      setActiveTab(searchParams.tabName);
    }
    if (commit == 'Get Data' && searchParams.fromTime) {
      setUrlParams(searchParams)
    }
  }

  function kibanaToTimeVal() {
    return UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
  }

  function jobspikrV3DataKibanaUrl(){
    return `${KIBANA_JP_URL}app/kibana#/dashboard/JPP-QA-Sample-Counts-Dashboard?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${UrlParams.fromTime}',mode:quick,to:'${kibanaToTimeVal()}'))&_a=(filters:!(),options:(darkTheme:!f),panels:!((col:1,id:JPP-QA-Total-Records,panelIndex:1,row:1,size_x:6,size_y:2,type:visualization),(col:7,id:JPP-QA-Region-Presence-Counts,panelIndex:2,row:1,size_x:6,size_y:3,type:visualization),(col:4,id:JPP-QA-US-States-Count,panelIndex:3,row:3,size_x:3,size_y:3,type:visualization),(col:7,id:JPP-QA-Post-Date-Filters,panelIndex:4,row:4,size_x:6,size_y:2,type:visualization),(col:1,id:JPP-QA-Sample-Links,panelIndex:5,row:3,size_x:3,size_y:3,type:visualization)),query:(query_string:(analyze_wildcard:!t,query:'*')),title:'JPP%20QA%20-%20Sample%20Counts%20Dashboard',uiState:())`
  }

  function jobspikrV3StagingDataKibanaUrl(){
    return `${KIBANA_JP_URL}app/kibana#/dashboard/JPS-QA-Sample-Counts-Dashboard?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${UrlParams.fromTime}',mode:quick,to:'${kibanaToTimeVal()}'))&_a=(filters:!(),options:(darkTheme:!f),panels:!((col:7,id:JPS-QA-Region-Presence-Counts,panelIndex:4,row:1,size_x:6,size_y:3,type:visualization),(col:1,id:JPS-QA-Sample-Links,panelIndex:5,row:3,size_x:3,size_y:3,type:visualization),(col:7,id:JPS-QA-Post-Date-Filters,panelIndex:6,row:4,size_x:6,size_y:2,type:visualization),(col:1,id:JPS-QA-Total-Records,panelIndex:7,row:1,size_x:6,size_y:2,type:visualization),(col:4,id:JPS-QA-US-States-Count,panelIndex:8,row:3,size_x:3,size_y:3,type:visualization)),query:(query_string:(analyze_wildcard:!t,query:'*')),title:'JPS%20QA%20-%20Sample%20Counts%20Dashboard',uiState:(P-7:(spy:(mode:(fill:!f,name:!n)))))`
  }

  const handleGetData = () => {
    window.location.href = `/jobspikr_v3_internal/jobspikr_graphs/jp_imp_filters?filter[time]=${fromTime.value}&filter[tab_name]=${activeTab}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/jobspikr_v3_internal/jobspikr_graphs/jp_imp_filters">JP Important Filters</a> : 'JP Important Filters'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
          </BreadcrumbItem>}
        </Breadcrumb>
      </Col>
    </Row>
  </div>

  <Card>
    <CardBody>
      <Row>
        <Col sm="3">
          <label className="fw-bold">Time</label>
          <Select
            value={fromTime}
            options={timeOptions}
            onChange={option => setFromTime(option)} />
        </Col>
        <Col sm="3">
          <label className="fw-bold">&nbsp;</label>
          <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
        </Col>
      </Row>
    </CardBody>
  </Card>
  <Card>
    <CardBody>
      <Nav tabs>
        <NavItem>
          <NavLink className={activeTab == 'jobspikr_v3_data' ? 'active' : ''} onClick={() => setActiveTab('jobspikr_v3_data')}>
            jobspikr_v3_data-*
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={activeTab == 'jobspikr_v3_staging_data' ? 'active' : ''} onClick={() => setActiveTab('jobspikr_v3_staging_data')}>
            jobspikr_v3_staging_data-*
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={'jobspikr_v3_data'}>
          <div className="panel-body">
            <center>
              <a target="_blank" className="link-primary" href={jobspikrV3DataKibanaUrl()}>Click here to see below graph in kibana</a>
            </center>
            <iframe src={jobspikrV3DataKibanaUrl() + "&embed=true"} height="600" width="100%" className="border-0"></iframe>
          </div>
        </TabPane>
        <TabPane tabId={'jobspikr_v3_staging_data'}>
          <div className="panel-body">
            <center>
              <a target="_blank" className="link-primary" href={jobspikrV3StagingDataKibanaUrl()}>Click here to see below graph in kibana</a>
            </center>
            <iframe src={jobspikrV3StagingDataKibanaUrl() + "&embed=true"} height="600" width="100%" className="border-0"></iframe>
          </div>
        </TabPane>
      </TabContent>
    </CardBody>
  </Card>
</Container>
}

