import React from 'react';
import { Row, Col } from 'reactstrap';
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { Home } from 'react-feather';

import { NewInternalBasePoint } from '../../../../Common/BaseEsPoints/InternalEsBasePoint';

export default function BreadCrumb() {

  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");

  return (
    <div className='page-title'>
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard: Segment Level Crawl Extraction Stats</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit === 'Get Data' ? <a href={`${NewInternalBasePoint}/segment/new`}>Segment Level Crawl Extraction Stats</a> : 'Segment Level Crawl Extraction Stats'}
            </BreadcrumbItem>
            {commit === 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
            </BreadcrumbItem>}
          </Breadcrumb>
        </Col>
      </Row>
    </div>
  )
}
