import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function LogstashStats() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const searchParams = {
    fromTime: new URLSearchParams(search).get("filter[time]"),
  }
  const [fromTime, setFromTime] = useState({ label: 'Last 4 hours', value: 'now-4h' });

  const timeOptions = [
    { label: 'Today', value: 'now%2Fd' },
    { label: 'Last 30 minutes', value: 'now-30m' },
    { label: 'Last 1 hour', value: 'now-1h' },
    { label: 'Last 2 hours', value: 'now-2h' },
    { label: 'Last 4 hours', value: 'now-4h' },
    { label: "Last 10 hours", value: "now-10h" },
    { label: 'Last 12 hours', value: 'now-12h' },
    { label: 'Last 24 hours', value: 'now-24h' },
    { label: 'Last 2 days', value: 'now-2d' },
    { label: 'Last 3 days', value: 'now-3d' },
    { label: 'Last 4 days', value: 'now-4d' },
    { label: 'Last 7 days', value: 'now-7d' },
    { label: 'Last 15 days', value: 'now-15d' },
    { label: 'Last 30 days', value: 'now-30d' },
    { label: 'Last 60 days', value: 'now-60d' },
    { label: 'Last 1 year', value: 'now-1y' }
  ];

  const [UrlParams, setUrlParams] = useState({
    fromTime: 'now-4h',
  })

  useEffect(() => {
    setSearchParamsData();
  }, []);

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.fromTime && searchParams.fromTime != fromTime.value) {
      setFromTime(timeOptions.filter(item => item.value == searchParams.fromTime)[0]);
    }
    if (commit == 'Get Data' && searchParams.fromTime) {
      setUrlParams(searchParams)
    }
  }

  function logstashStatsKibanaUrl(){
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";

    return `${KIBANA_PLM_URL}app/kibana#/dashboard/LogstashStatistics?_g=(refreshInterval:(pause:!t,value:0),time:(from:'${UrlParams.fromTime}',to:'${to_time_val}'))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(darkTheme:!f),panels:!((embeddableConfig:(),gridData:(h:16,i:'1916f1fa-19b0-4fb4-8774-e148f00a0ef0',w:48,x:0,y:0),id:LogstashEventOverHour,panelIndex:'1916f1fa-19b0-4fb4-8774-e148f00a0ef0',type:visualization,version:'7.5.1'),(embeddableConfig:(),gridData:(h:42,i:f51b4a28-3e22-4c87-84f5-d36a88142b3f,w:48,x:0,y:55),id:file_and_function_name_counts,panelIndex:f51b4a28-3e22-4c87-84f5-d36a88142b3f,type:visualization,version:'7.5.1'),(embeddableConfig:(),gridData:(h:39,i:'433b6ac1-c86f-4eb8-9f79-43cf435f30c2',w:48,x:0,y:16),id:'50bdff30-a3d2-11ea-8b4a-69464b0a274d',panelIndex:'433b6ac1-c86f-4eb8-9f79-43cf435f30c2',type:visualization,version:'7.5.1'),(embeddableConfig:(),gridData:(h:28,i:'180e7b5f-e036-4bd1-bd43-64f95d5fada5',w:48,x:0,y:97),id:LogstashHostAndFunctionNameSegmentation,panelIndex:'180e7b5f-e036-4bd1-bd43-64f95d5fada5',type:visualization,version:'7.5.1'),(embeddableConfig:(),gridData:(h:16,i:c06dd72f-0775-4e1a-afaf-ff61b632c6b3,w:48,x:0,y:125),id:LogstashHistogram,panelIndex:c06dd72f-0775-4e1a-afaf-ff61b632c6b3,type:visualization,version:'7.5.1'),(embeddableConfig:(),gridData:(h:21,i:'3e90612c-8aee-4cb3-a4b2-e78f9cfe170b',w:48,x:0,y:141),id:Top10LogstahLogFilesCountAndItsFounction,panelIndex:'3e90612c-8aee-4cb3-a4b2-e78f9cfe170b',type:visualization,version:'7.5.1'),(embeddableConfig:(columns:!(host,path,file_name,function_name,level,msg,line_number,client_name,exception_class,exception_message,project_name,site,site_name,site_dir),sort:!('@timestamp',desc)),gridData:(h:36,i:'3b3b3fda-c76b-4adb-bbf3-adc8d2197310',w:48,x:0,y:162),id:LogstashTable,panelIndex:'3b3b3fda-c76b-4adb-bbf3-adc8d2197310',type:search,version:'7.5.1')),query:(language:lucene,query:'*'),timeRestore:!f,title:LogstashStatistics,viewMode:view)`
  }

  const handleGetData = () => {
    window.location.href = `/internal/log_stats/logstash_stats?filter[time]=${fromTime.value}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/log_stats/logstash_stats">Log Stats - Logstash Stats</a> : 'Log Stats - Logstash Stats'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                Search
                </BreadcrumbItem>}
          </Breadcrumb>
        </Col>
      </Row>
    </div>

    <Card>
      <CardBody>
        <Row>
          <Col sm="4">
            <label className="fw-bold">Time</label>
            <Select
              value={fromTime}
              options={timeOptions}
              onChange={option => setFromTime(option)} />
          </Col>
          <Col sm="4">
            <label className="fw-bold">&nbsp;</label>
            <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
          </Col>
        </Row>
      </CardBody>
    </Card>
    <Card>
      <CardBody>
        <center>
          <span className="fw-bold">Logstash Statistics</span><br />
          <a target="_blank" className="link-primary" href={logstashStatsKibanaUrl()}>Click here to see below graph in kibana</a>
        </center>
        <iframe src={logstashStatsKibanaUrl() + "&embed=true"} height="1000" width="100%" className="border-0"></iframe>
      </CardBody>
    </Card>
  </Container>
}

