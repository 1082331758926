import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import { Home } from "react-feather";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import axios from 'axios';
import { useLocation } from "react-router-dom";
import moment from "moment";
import Loader from "react-loader-spinner";

export default function SegmentsTakeMoreTimeByEdnuStageWise() {
    const seach = useLocation().search;
    const commit = new URLSearchParams(seach).get("commit");
    const urlDate = new URLSearchParams(seach).get("filter[crawl_date]");

    const [date, setDate] = useState(new Date());
    const [activeTab, setActiveTab] = useState('1');
    const [extData, setExtData] = useState([]);
    const [dedupData, setDedupData] = useState([]);
    const [uploadData, setUploadData] = useState([]);
    const [normaliztionData, setNormalizationData] = useState([]);
    const [extSearch, setExtSearch] = useState([]);
    const [dedupSearch, setDedupSearch] = useState([]);
    const [uploadSearch, setUploadSearch] = useState([]);
    const [normaliztionSearch, setNormalizationSearch] = useState([]);
    const [loading, setLoading] = useState(false);
    const [extSearchText, setExtSearchText] = useState('');
    const [dedupSearchText, setDedupSearchText] = useState('');
    const [uploadSearchText, setUploadSearchText] = useState('');
    const [normalizationSearchText, setNormalizationSearchText] = useState('');
    const extColumns = [
        { name: 'Site', selector: 'site', center: true, sortable: true, cell: row => <div data-bs-toggle="tooltip" data-bs-placement="bottom" title={row.segment_path}>{row.site}</div> },
        { name: 'Host Name', selector: 'hostname', center: true, sortable: true },
        { name: 'Crawl Date', selector: 'crawldate', center: true, sortable: true },
        { name: 'Extraction Start Time', selector: 'extraction_start_time', center: true, sortable: true, cell: row => <div>{moment.utc(row.extraction_start_time).format('YYYY-MM-DD HH:mm:ss')} UTC</div> },
        { name: 'Extraction End Time', selector: 'extraction_end_time', center: true, sortable: true, cell: row => <div>{moment.utc(row.extraction_end_time).format('YYYY-MM-DD HH:mm:ss')} UTC</div> },
        { name: 'Takes More Time', center: true, sortable: true, cell: row => <div>{getDifferenceInHours(row.extraction_start_time, row.extraction_end_time)}</div> },
    ];
    const dedupColumns = [
        { name: 'Site', selector: 'site', center: true, sortable: true, cell: row => <div data-bs-toggle="tooltip" data-bs-placement="bottom" title={row.segment_path}>{row.site}</div> },
        { name: 'Host Name', selector: 'hostname', center: true, sortable: true },
        { name: 'Crawl Date', selector: 'crawldate', center: true, sortable: true },
        { name: 'Extraction Start Time', selector: 'dedup_start_time', center: true, sortable: true, cell: row => <div>{moment.utc(row.dedup_start_time).format('YYYY-MM-DD HH:mm:ss')} UTC</div> },
        { name: 'Extraction End Time', selector: 'dedup_end_time', center: true, sortable: true, cell: row => <div>{moment.utc(row.dedup_start_time).format('YYYY-MM-DD HH:mm:ss')} UTC</div> },
        { name: 'Takes More Time', center: true, sortable: true, cell: row => <div>{getDifferenceInHours(row.dedup_start_time, row.dedup_end_time)}</div> },
    ]
    const normalizationColumns = [
        { name: 'Site', selector: 'site', center: true, sortable: true, cell: row => <div data-bs-toggle="tooltip" data-bs-placement="bottom" title={row.segment_path}>{row.site}</div> },
        { name: 'Host Name', selector: 'hostname', center: true, sortable: true },
        { name: 'Crawl Date', selector: 'crawldate', center: true, sortable: true },
        { name: 'Extraction Start Time', selector: 'normalization_start_time', center: true, sortable: true, cell: row => <div>{moment.utc(row.normalization_start_time).format('YYYY-MM-DD HH:mm:ss')} UTC</div> },
        { name: 'Extraction End Time', selector: 'normalization_end_time', center: true, sortable: true, cell: row => <div>{moment.utc(row.normalization_start_time).format('YYYY-MM-DD HH:mm:ss')} UTC</div> },
        { name: 'Takes More Time', center: true, sortable: true, cell: row => <div>{getDifferenceInHours(row.normalization_start_time, row.normalization_end_time)}</div> },
    ]
    const uploadColumns = [
        { name: 'Site', selector: 'site', center: true, sortable: true, cell: row => <div data-bs-toggle="tooltip" data-bs-placement="bottom" title={row.segment_path}>{row.site}</div> },
        { name: 'Host Name', selector: 'hostname', center: true, sortable: true },
        { name: 'Crawl Date', selector: 'crawldate', center: true, sortable: true },
        { name: 'Extraction Start Time', selector: 'upload_start_time', center: true, sortable: true, cell: row => <div>{moment.utc(row.upload_start_time).format('YYYY-MM-DD HH:mm:ss')} UTC</div> },
        { name: 'Extraction End Time', selector: 'upload_end_time', center: true, sortable: true, cell: row => <div>{moment.utc(row.upload_start_time).format('YYYY-MM-DD HH:mm:ss')} UTC</div> },
        { name: 'Takes More Time', center: true, sortable: true, cell: row => <div>{getDifferenceInHours(row.upload_start_time, row.upload_end_time)}</div> },
    ]

    useEffect(() => {
        if (urlDate && new Date(urlDate) != date) {
            setDate(new Date(urlDate));
        }
        if (commit == 'Get Data' && urlDate) {
            fetchData(urlDate)
        }
        else {
            fetchData();
        }
    }, []);

    function fetchData(crawlDate = date) {
        setLoading(true);
        axios.get(`/api/v1/internal/overall_system_reports/segments_take_more_time_by_ednu_stage_wise?filter[crawl_date]=${crawlDate}`)
            .then(res => {
                setExtData(res.data.seg_by_extraction);
                setExtSearch(res.data.seg_by_extraction);
                setDedupData(res.data.seg_by_dedup);
                setDedupSearch(res.data.seg_by_dedup);
                setNormalizationData(res.data.seg_by_normalization);
                setNormalizationSearch(res.data.seg_by_normalization);
                setUploadData(res.data.seg_by_upload);
                setUploadSearch(res.data.seg_by_upload);
                setLoading(false);
            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong');
                setLoading(false);
            })
    }

    function getDifferenceInHours(start_date, end_date) {
        let a = moment(end_date);
        let b = moment(start_date);
        let hours = a.diff(b, 'hours');
        let minutes =  moment.utc(a.diff(b)).format("mm");
        let seconds = moment.utc(a.diff(b)).format("ss");
        return `${Math.floor(hours)}h ${Math.floor(minutes)}m ${seconds}s`
    }

    const handleExtSearch = event => {
        if (event.target.value == '') {
            setExtSearch(extData);
        }
        else {
            setExtSearch(extData.filter(item => item.site.includes(event.target.value)))
        }
        setExtSearchText(event.target.value);
    }

    const handleDedupSearch = event => {
        if (event.target.value == '') {
            setDedupSearch(dedupData);
        }
        else {
            setDedupSearch(dedupData.filter(item => item.site.includes(event.target.value)))
        }
        setDedupSearchText(event.target.value);
    }

    const handleNormalizationSearch = event => {
        if (event.target.value == '') {
            setNormalizationSearch(normaliztionData);
        }
        else {
            setNormalizationSearch(normaliztionData.filter(item => item.site.includes(event.target.value)))
        }
        setNormalizationSearchText(event.target.value);
    }

    const handleUploadSearch = event => {
        if (event.target.value == '') {
            setUploadSearch(uploadData);
        }
        else {
            setUploadSearch(uploadData.filter(item => item.site.includes(event.target.value)))
        }
        setUploadSearchText(event.target.value);
    }

    const handleGetData = () => {
        let crawlDate = moment(date).format('YYYY/MM/DD');
        window.location.href = `/internal/overall_system_reports/segments_take_more_time_by_ednu_stage_wise?filter[crawl_date]=${crawlDate}&commit=${'Get Data'}`
    }

    return <Container fluid>
        <Container fluid>
            <div className="page-title">
                <Row className=''>
                    <Col xs="6">
                        <h3>Internal Dashboard</h3>
                    </Col>
                    <Col xs="6">
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <Link to={`/internal`}><Home /></Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem >
                                {commit == 'Get Data' ? <Link to={'/internal/overall_system_reports/segments_take_more_time_by_ednu_stage_wise'}>Segments take more time by ednu stage wise</Link>: 'Segments take more time by ednu stage wise'}
                            </BreadcrumbItem>
                            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                                Search
                            </BreadcrumbItem>}
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
        </Container>
        {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> : <Fragment>
            <Card>
                <CardBody>
                    <Row className="align-items-end">
                        <Col sm="5">
                            <label className="fw-bold">Crawl Date</label>
                            <DatePicker
                                className="form-control"
                                selected={new Date(date)}
                                maxDate={new Date()}
                                onChange={(value) => setDate(value)}
                                dateFormat="yyyy/MM/dd"
                            />
                        </Col>
                        <Col sm="2">
                            <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={activeTab == '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                Extraction
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={activeTab == '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                Dedup
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={activeTab == '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                                Normalization
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={activeTab == '4' ? 'active' : ''} onClick={() => setActiveTab('4')}>
                                Upload
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={'1'}>
                            <center className="fw-bold mt-4">Extraction</center>
                            <Row className="align-items-center float-end my-3">
                                <div className="col-sm-3">Search</div>
                                <div className="col">
                                    <input className="form-control" type={'text'} value={extSearchText} onChange={handleExtSearch} />
                                </div>
                            </Row>
                            <DataTable
                                columns={extColumns}
                                data={extSearch}
                                pagination
                                noHeader
                                progressPending={loading}
                                highlightOnHover />
                        </TabPane>
                        <TabPane tabId={'2'}>
                            <center className="fw-bold mt-4">Dedup</center>
                            <Row className="align-items-center float-end my-3">
                                <div className="col-sm-3">Search</div>
                                <div className="col">
                                    <input className="form-control" type={'text'} value={dedupSearchText} onChange={handleDedupSearch} />
                                </div>
                            </Row>
                            <DataTable
                                columns={dedupColumns}
                                data={dedupSearch}
                                pagination
                                progressPending={loading}
                                highlightOnHover
                                noHeader />
                        </TabPane>
                        <TabPane tabId={'3'}>
                            <center className="fw-bold mt-4">Normalization</center>
                            <Row className="align-items-center float-end my-3">
                                <div className="col-sm-3">Search</div>
                                <div className="col">
                                    <input className="form-control" type={'text'} value={normalizationSearchText} onChange={handleNormalizationSearch} />
                                </div>
                            </Row>
                            <DataTable
                                columns={normalizationColumns}
                                data={normaliztionSearch}
                                pagination
                                noHeader
                                progressPending={loading}
                                highlightOnHover />
                        </TabPane>
                        <TabPane tabId={'4'}>
                            <center className="fw-bold mt-4">Upload</center>
                            <Row className="align-items-center float-end my-3">
                                <div className="col-sm-3">Search</div>
                                <div className="col">
                                    <input className="form-control" type={'text'} value={uploadSearchText} onChange={handleUploadSearch} />
                                </div>
                            </Row>
                            <DataTable
                                columns={uploadColumns}
                                data={uploadSearch}
                                progressPending={loading}
                                pagination
                                progressPending={loading}
                                highlightOnHover
                                noHeader
                            />
                        </TabPane>
                    </TabContent>
                </CardBody>
            </Card>
        </Fragment>}
    </Container>
}