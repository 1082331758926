import React from 'react'
import { useState, useEffect } from 'react'
import { Info } from "react-feather";
import ReactTooltip from "react-tooltip";
import DataTable from "react-data-table-component";
import { KIBANA_PLM_URL } from '../../../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints';

import { NewInternalBasePoint } from '../../../../Common/BaseEsPoints/InternalEsBasePoint';

export default function QueueSizeTable({ data, fromDate, endDate, tableData }) {

    const [queueSizeTableData, setQueueSizeTableData] = useState([]);

    useEffect(() => {
      let tempSitesData = [...data?.site_data]
      tempSitesData.push({
          site: '', date: <b>Total: </b>,
          drss_count: <b>{data?.total_drss_count}</b>,
          rss_count: <b>{data?.total_rss_count}</b>,
          feedcrawl_count: <b>{data?.total_feedcrawl_count}</b>,
          segment_extr_count: <b>{data?.total_segment_extr_count}</b>,
          segment_dedup_count: <b>{data?.total_segment_dedup_count}</b>,
          segment_normalize_count: <b>{data?.total_segment_normalize_count}</b>,
          segment_upload_count: <b>{data?.total_segment_upload_count}</b>,
          qa: '', kibana: ''
      })
      setQueueSizeTableData(tempSitesData)
      return;
    }, []);
    
    // Helper function to format date
    function formatDateYYYYMMDD(date) {
        let d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();
        if (month.length < 2) {

            month = '0' + month;
        }
        if (day.length < 2) {

            day = '0' + day;
        }
        return [year, month, day].join('/');
    }

    // Helper function to format time
    const transformTime = (string) => {
        if (string != undefined) {
            let temp = new Date(string * 1000)?.toISOString()?.substring(11, 8);
            return temp.substring(0, 2) + ' h ' + temp.substring(3, 2) + ' m ' + temp.substring(6, 2) + ' s'
        } else {
            return ''
        }
    };

    // Helper function to change date format
    const changeFormat = (dateString) => {
        const year = +dateString.toString().substring(0, 4);
        const month = +dateString.toString().substring(4, 6);
        const day = +dateString.toString().substring(6, 8);
        const date = new Date(year, month - 1, day);
        return date.toDateString()
    };

    // Data table column definitions
    const columns = [
        {
            name: 'Site',
            selector: 'site',
            center: true,
            sortable: true,
            width: '250px',
            cell: (each) => <div className='d-flex justify-content-center align-items-center'>
              <a href={`${NewInternalBasePoint}/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${formatDateYYYYMMDD(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${formatDateYYYYMMDD(endDate)}&filter%5Bsite%5D=${each['site']}`} target='_blank'
                    data-tip data-for='goto_segment_view' className='d-flex justify-content-center align-items-center'>
                    {each['site']}
                </a> {each?.site != '' && <Info size={18} className='mx-2' />}
            </div>
        },
        {
            name: 'Date', selector: 'date', center: true, sortable: true, width: '160px', cell: (each) => <>{each?.site == ''
                ? <b>Total: </b>
                : <div className='pt-1'> <p data-tip data-for='kibana_link' className='d-flex justify-content-center align-items-center'>
                    {each['date']} <Info size={18} className='mx-2' /> </p>
                    <ReactTooltip id='kibana_link' clickable multiline delayHide={1000} effect='solid'>
                        <a href={`${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+%22${each?.site}%22+or+sitename:+%22${each?.site}%22)+and+yyyymmdd:${each?.date}'),sort:!(!('@timestamp',desc)))`} target='_blank'
                            className='text-white'>
                            Kibana Link </a>
                    </ReactTooltip> </div>}</>
        },
        {
            name: 'Drss', center: true, sortable: true, grow: 0, cell: (each) => <>{each.site == ''
                ? <b>{data?.total_drss_count}</b>
                : <><a href={`${NewInternalBasePoint}/rss?filter%5Bcrawldate%5D%5Bfrom%5D=${formatDateYYYYMMDD(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${formatDateYYYYMMDD(endDate)}&filter%5Brss_job_type%5D=drss&filter%5Bsite%5D=${each?.site}`} target='_blank'
                    data-tip data-for={`kibana_link_custom_${each["date"]}`} className='d-flex justify-content-center align-items-center' style={{whiteSpace:"nowrap"}}>{each['drss_count']} <Info size={18} className='mx-2' /> </a>
                    <ReactTooltip id={`kibana_link_custom_${each["date"]}`} clickable multiline delayHide={1000} effect='solid'>
                        <a href={`${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+%22${each?.site}%22++or+sitename+:%22${each?.site}%22)+and+yyyymmdd:${each?.date}+and+(pipeline_stage:+rss+or+pipeline_stage:+RSS)'),sort:!(!('@timestamp',desc)))`} target="_blank" className='text-white'>Kibana Link</a>
                        <br />
                        <a href={`http://alerta.promptcloud.com/#/alerts?status=open`} target='_blank' className='text-white'>Alert Link (To Do)</a>
                        <br />
                        <p className='m-0'>Avg Time Taken: {transformTime(each?.rss_avg_time_taken)}</p>
                    </ReactTooltip></>}</>
        },
        {
            name: 'Rss', center: true, sortable: true, grow: 0, cell: (each) => <>{each.site == ''
                ? <b>{data?.total_rss_count}</b>
                :
                <>
                  <a href={`${NewInternalBasePoint}/rss?filter%5Bcrawldate%5D%5Bfrom%5D=${formatDateYYYYMMDD(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${formatDateYYYYMMDD(endDate)}&filter%5Brss_job_type%5D=rss&filter%5Bsite%5D=${each?.site}`} target='_blank'
                        data-tip data-for={`kibana_link_custom2_${each["date"]}`} className='d-flex justify-content-center align-items-center' style={{whiteSpace:"nowrap"}}>{each['rss_count']} <Info size={18} className='mx-2' />
                    </a>
                    <ReactTooltip id={`kibana_link_custom2_${each["date"]}`} clickable multiline delayHide={1000} effect='solid' >
                        <a href={`${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+%22${each?.site}%22++or+sitename+:%22${each?.site}%22)+and+yyyymmdd:${each?.date}+and+(pipeline_stage:+rss+or+pipeline_stage:+RSS)'),sort:!(!('@timestamp',desc)))`} target="_blank" className='text-white'>Kibana Link</a>
                        <br />
                        <a href={`http://alerta.promptcloud.com/#/alerts?status=open`} target='_blank' className='text-white'>Alert Link (To Do)</a>
                        <br />
                        <p className='m-0'>Avg Time Taken: {transformTime(each?.rss_avg_time_taken)}</p>
                    </ReactTooltip>
                </>
            }
            </>
        },
        {
            name: 'Feedcrawl', center: true, sortable: true, grow: 0, cell: (each) => <>{each.site == ''
                ? <b>{data?.total_feedcrawl_count}</b>
                : <div>
                  <a href={`${NewInternalBasePoint}/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${formatDateYYYYMMDD(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${formatDateYYYYMMDD(endDate)}&filter%5Bsite%5D=${each?.site}`} target='_blank'
                        data-tip data-for={`kibana_link_custom31_${each["date"]}`}
                        className={`d-flex justify-content-center align-items-center ${((changeFormat(each['date']) < new Date().toDateString()) && (each['feedcrawl_count'] < (each['rss_count'] * 0.25))
                            || ((each['feedcrawl_count'] * 0.1) < each['failed_url_count'])) ? 'text-danger fw-bold' : ''}`} style={{whiteSpace:"nowrap"}}>{each['feedcrawl_count']} <Info size={18} className='mx-2' /> </a>
                    <ReactTooltip id={`kibana_link_custom31_${each["date"]}`} clickable multiline delayHide={1000} effect='solid' >
                        {(changeFormat(each['date']) < new Date().toDateString()) && (each['feedcrawl_count'] < (each['rss_count'] * 0.25)) ?
                            <p className='m-0 mb-2 fw-bold'>Probably the site is blocking or crawler is broken</p> :
                            ((each['feedcrawl_count'] * 0.1) < each['failed_url_count']) ?
                                <p className='m-0 mb-2 fw-bold'>Total failed url is more than 10% of the total crawled</p>
                                : null}
                        <a href={`${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+%22${each?.site}%22++or+sitename+:%22${each?.site}%22)+and+yyyymmdd:${each?.date}+and+(pipeline_stage:+rss+or+pipeline_stage:+RSS)'),sort:!(!('@timestamp',desc)))`} target="_blank" className='text-white'>Kibana Link</a>
                        <br />
                        <a href={`http://alerta.promptcloud.com/#/alerts?status=open`} target='_blank' className='text-white'>Alert Link (To Do)</a>
                        <br />
                        <p className='m-0'>Avg Time Taken: {transformTime(each['feedcrawl_avg_time_taken'])}</p>
                        <p className='m-0'>Failed Count: {each['failed_url_count']} </p>
                    </ReactTooltip>
                </div>} </>
        },
        {
            name: 'Extraction', center: true, sortable: true, grow: 0, cell: (each) => <>{each.site == ''
                ? <b>{data?.total_segment_extr_count}</b>
                : <><a href={`${NewInternalBasePoint}/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${formatDateYYYYMMDD(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${formatDateYYYYMMDD(endDate)}&filter%5Bsite%5D=${each?.site}`} target='_blank'
                    data-tip data-for={`kibana_link_custom3_${each["date"]}`} className='d-flex justify-content-center align-items-center' style={{whiteSpace:"nowrap"}}>{each['segment_extr_count']} <Info size={18} className='mx-2' /> </a>
                    <ReactTooltip id={`kibana_link_custom3_${each["date"]}`} clickable multiline delayHide={1000} effect='solid' >
                        <p className='m-0'>Dropped: {each?.dropped_ext}</p>
                        <a href={`${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+%22${each?.site}%22++or+sitename+:%22${each?.site}%22)+and+yyyymmdd:${each?.date}+and+(pipeline_stage:+rss+or+pipeline_stage:+RSS)'),sort:!(!('@timestamp',desc)))`} target="_blank" className='text-white'>Kibana Link</a>
                        <br />
                        <a href={`http://alerta.promptcloud.com/#/alerts?status=open`} target='_blank' className='text-white'>Alert Link (To Do)</a>
                        <p className='m-0'>Avg Time Taken: {transformTime(each?.extr_avg_time_taken)}</p>
                        <p className='m-0'>Failed Count: {each?.failed_url_count} </p>
                    </ReactTooltip></>} </>
        },
        {
            name: 'Dedup', center: true, sortable: true, grow: 0, cell: (each) => <>{each.site == ''
                ? <b>{data?.total_segment_dedup_count}</b>
                : <><a href={`${NewInternalBasePoint}/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${formatDateYYYYMMDD(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${formatDateYYYYMMDD(endDate)}&filter%5Bsite%5D=${each?.site}`} target='_blank'
                    data-tip data-for={`kibana_link_custom4_${each["date"]}`} className={`d-flex justify-content-center align-items-center ${(each['segment_dedup_count'] > 1000 || (each['segment_dedup_count'] > 0.3 * each['dropped_ded'])) ? 'text-danger fw-bold' : ''}`} style={{whiteSpace:"nowrap"}}>{each['segment_dedup_count']} <Info size={18} className='mx-2' /> </a>
                    <ReactTooltip id={`kibana_link_custom4_${each["date"]}`} clickable multiline delayHide={1000} effect='solid' >
                        <p className='m-0'>Dropped: {each?.dropped_ded}</p>
                        <a href={`${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+%22${each?.site}%22++or+sitename+:%22${each?.site}%22)+and+yyyymmdd:${each?.date}+and+(pipeline_stage:+rss+or+pipeline_stage:+RSS)'),sort:!(!('@timestamp',desc)))`} target="_blank" className='text-white'>Kibana Link</a>
                        <br />
                        <a href={`http://alerta.promptcloud.com/#/alerts?status=open`} target='_blank' className='text-white'>Alert Link (To Do)</a>
                        <p className='m-0'>Avg Time Taken: {transformTime(each?.dedup_avg_time_taken)}</p>
                    </ReactTooltip></>} </>
        },
        {
            name: 'Normalize', center: true, sortable: true, grow: 0, cell: (each) => <>{each.site == ''
                ? <b>{data?.total_segment_normalize_count}</b>
                : <><a href={`${NewInternalBasePoint}/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${formatDateYYYYMMDD(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${formatDateYYYYMMDD(endDate)}&filter%5Bsite%5D=${each?.site}`} target='_blank'
                    data-tip data-for={`kibana_link_custom5_${each["date"]}`} className='d-flex justify-content-center align-items-center' style={{whiteSpace:"nowrap"}} >{each['segment_normalize_count']} <Info size={18} className='mx-2' /> </a>
                    <ReactTooltip id={`kibana_link_custom5_${each["date"]}`} clickable multiline delayHide={1000} effect='solid' >
                        <p className='m-0'>Dropped: {each?.dropped_nor}</p>
                        <a href={`${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+%22${each?.site}%22++or+sitename+:%22${each?.site}%22)+and+yyyymmdd:${each?.date}+and+(pipeline_stage:+rss+or+pipeline_stage:+RSS)'),sort:!(!('@timestamp',desc)))`} target="_blank" className='text-white'>Kibana Link</a>
                        <br />
                        <a href={`http://alerta.promptcloud.com/#/alerts?status=open`} target='_blank' className='text-white'>Alert Link (To Do)</a>
                        <p className='m-0'>Avg Time Taken: {transformTime(each?.norm_avg_time_taken)}</p>
                    </ReactTooltip></>} </>
        },
        {
            name: 'Upload',
            center: true,
            sortable: true,
            grow: 0,
            cell: (each) => <>{each.site == ''
                ? <b>{data?.total_segment_upload_count}</b>
                : <><a href={`${NewInternalBasePoint}/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${formatDateYYYYMMDD(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${formatDateYYYYMMDD(endDate)}&filter%5Bsite%5D=${each?.site}`} target='_blank'
                    data-tip data-for={`kibana_link_custom6_${each["date"]}`} className='d-flex justify-content-center align-items-center' style={{whiteSpace:"nowrap"}}>{each['segment_upload_count']} <Info size={18} className='mx-2' /> </a>
                    <ReactTooltip id={`kibana_link_custom6_${each["date"]}`} clickable multiline delayHide={1000} effect='solid' >
                        <p className='m-0'>Dropped: {each?.dropped_upload}</p>
                        <a href={`${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'(site_name:+%22${each?.site}%22++or+sitename+:%22${each?.site}%22)+and+yyyymmdd:${each?.date}+and+(pipeline_stage:+rss+or+pipeline_stage:+RSS)'),sort:!(!('@timestamp',desc)))`} target="_blank" className='text-white'>Kibana Link</a>
                        <a href={`http://alerta.promptcloud.com/#/alerts?status=open`} target='_blank' className='text-white'>Alert Link (To Do)</a>
                        <p className='m-0'>Avg Time Taken: {transformTime(each?.upload_avg_time_taken)}</p>
                    </ReactTooltip></>} </>
        },
        {
            name: 'QA',
            center: true,
            grow: 0,
            cell: (each) => <>{each.site == '' ? '' : <a href={`${NewInternalBasePoint}/qa/${each?.site}`} target='_blank'>See Report</a>} </>
        },
        {
            name: 'Kibana',
            center: true,
            grow: 0,
            cell: (each) => <>{each.site == '' ? '' :
                <a href={`${KIBANA_PLM_URL}app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'site_name:+%22${each?.site}%22+or+sitename:+%22${each?.site}%22'),sort:!(!('@timestamp',desc)))`} target='_blank'>Kibana Link</a>} </>
        }
    ];

    return (
        <>
            <DataTable
                columns={columns}
                data={queueSizeTableData}
                className='sites_table'
                noHeader
                responsive
            />
        </>
    )
}
