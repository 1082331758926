import axios from 'axios';
import React, {useState, useEffect} from 'react';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalHeader, Row, Col, ModalFooter, Button } from 'reactstrap';
import Loader from 'react-loader-spinner';
import { useParams } from "react-router-dom";
import JSONPretty from 'react-json-pretty';

export default function ViewPayloadDetails({data, setToggle}) {
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  console.log("data", data);
  let { organization_name } = useParams();
  const [loadingBtns, setLoadingBtns] = useState(false)

  const toggleFunc = () => {
    setModal(curr => !curr)
  }

  const closeBtn = <button className="close" 
    onClick={() => setModal(false)}> &times; </button>;

  const resendWebhookJob = () => {
    setLoading(true)
    axios.post(`/api/v1/organizations/${organization_name}/resend_webhook_job`, {
      subscribe_hook: {
        site_name: data.site_name, notification_hash: JSON.stringify(data.notification_hash),
        parent_webhook_id: data.doc_id
      }
    }).then(res => {
      toast.success(res.data.message)
      setLoading(false)
      setModal(false)
      setToggle(curr => !curr)
    }).catch(err => {
      toast.error(err.response.data.message)
      setLoading(false)
    })
  }

  return (
    <>
      <Modal isOpen={modal} toggle={toggleFunc} backdrop='static' size='lg'>
        <ModalHeader toggle={toggleFunc} close={closeBtn}>
          Payload Details: {data.site_name}
        </ModalHeader>
        <ModalBody className='px-5'>
          {
            loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
              : <>
              <Row className='my-2'>
                <Col md={12}>
                  <h6 className='my-0'><strong>Response</strong></h6>
                  <JSONPretty id="json-pretty" data={data.response_body}></JSONPretty>
                </Col>
              </Row>
              <Row className='my-2'>
                <Col lg={12}>
                  <h6 className='my-0'><strong>Request</strong></h6>
                  <JSONPretty id="json-pretty" data={data.payload_details}></JSONPretty>
                </Col>
              </Row>
            </>
          }
        </ModalBody>
        <ModalFooter>
          <Button color='light' onClick={() => setModal(false)}> Close </Button>
          <Button color="success" disabled={loadingBtns} onClick={resendWebhookJob}>Resend</Button>
        </ModalFooter>
      </Modal>
      <button className='btn btn-xs btn-primary mx-1' onClick={toggleFunc}>Payload Details</button>
    </>
  )
}
