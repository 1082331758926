/**
 * Define all Context that will be exported to application here
 */
import { createContext } from 'react';

// This is the context that provides information about user's organization to which it belongs
export const UserOrganizationContext = createContext();

// The context to check if the user has Super User privileges or not
export const AdminContext = createContext();


