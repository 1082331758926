import React, {useState, useEffect, useContext} from 'react'
import { CardBody, CardHeader, Container, Card, Table, TabContent, TabPane,NavLink,
     Nav, NavItem, CardFooter, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Button } from 'reactstrap'
import axios from 'axios'
import { useParams } from "react-router-dom";
import Breadcrumb from '../../global/Breadcrumb';
import { toast } from 'react-toastify';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { xcode } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import SweetAlert from 'react-bootstrap-sweetalert';
import {useHistory} from 'react-router-dom'
import Loader from 'react-loader-spinner';
import { OrgnaziationContext } from '../../Home';
import '../../../stylesheets/custom.scss';
export default function Webhooks({current_user}) {
    const [BasicTab, setBasicTab] = useState('1');
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    let { organization_name } = useParams();
    const [toggle, setToggle] = useState(false)
    const [error, setError] = useState({})
    const [targetUrl, setTargetUrl] = useState('')
    const [eventName, setEventName] = useState('')
    const [project, setProject] = useState('')
    const [environment, setEnvironment] = useState('')
    const [bearerToken, setBearerToken] = useState('')
    const [alert, setAlert] = useState({
        token: '', visible: false
    })
    const [modal, setModal] = useState(false)
    const [modalBT, setModalBT] = useState('')
    const [selectedId, setSelectedId] = useState('')
    const [secondBT, setSecondBT] = useState('')
    let history = useHistory()

    const {setOrgName} = useContext(OrgnaziationContext)
    useEffect(() => {
        setOrgName(organization_name)
    }, [organization_name])

    useEffect(() => {
        if ((current_user?.id && !(current_user?.is_admin)) && !(current_user?.accessible_modules.includes("data_access"))){
            history.push(`/`)
            toast.error('You are not authorized to access this page')
        }
    }, [current_user])

    const toggle2 = () => {
        setModal(curr => !curr)
    };

    const closeBtn = <button className="close" 
        onClick={() => setModal(false)}> &times; </button>;

    const codeString1 = `
{
    notification_id: “abc123”
    event_name: “crawl_completed”,
    site_name: “”,
    crawl_id: “”,
    data_api_url: “”
    timestamp: “2018-11-02T08:44:22.818+00:00”
}    
`

    const codeString2 = `
{
    notification_id: “abc123”
    event_name: “individual_file_uploaded”,
    site_name: “”,
    crawl_id: “”,
    file_download_url: “”
    timestamp: “2018-11-02T08:44:22.818+00:00”
}
`

    const codeString3 = `
{
    notification_id: “abc123”
    event_name: “merge_files_uploaded”,
    site_name: “”,
    file_download_url: ["", "", ..]
    timestamp: “2018-11-02T08:44:22.818+00:00”
}
`

    const codeString6 = `
$ curl -XPOST -H 'Authorization: Bearer cGFzc3dvcmQxMjM=' -d '
{
    notification_id: 'abc123',
    event_name: 'crawl_completed',
    site_name: 'site_abc',
    crawl_id: '201912XXX...',
    data_api_url: 'http://data.promptcloud.com/...',
    timestamp: '2019-12-12T08:44:22.818+00:00'
}'    
`
    useEffect(() => {
        axios.get(`/api/v1/organizations/${organization_name}/subscribed_hooks?page_type=client&controller_name=subscribed_hooks`).then(res => {
            setData(res.data)
            setLoading(false)
        }).catch(err => {
            toast.error(err.response.data.message)
            setLoading(false)
        })
    }, [toggle])

    function isURL(str) {
        var url = new RegExp(/^((www\.)|(http(s*)))/i);
        return str.length < 2083 && url.test(str)
    }

    const handleWebhooksSubmit = (e) => {
        e.preventDefault()
        setError({})
        let tempErrs = {}
        if (organization_name === "influsoft"){
            if(eventName.trim().length < 1){
                tempErrs = {...tempErrs, eventName: "Please select an event name"}
            }
            if (!isURL(targetUrl)){
                tempErrs = {...tempErrs, targetUrl: "Please enter a valid URL"}
            }
            if(project.trim().length < 1){
                tempErrs = {...tempErrs, project: "Please select a project"}
            }
            if(environment.trim().length < 1){
                tempErrs = {...tempErrs, environment: "Please select an environment"}
            }
            if(bearerToken.trim().length > 1 && bearerToken.trim().length < 16){
                tempErrs = {...tempErrs, bearerToken: "Please give valid bearer token"}
            }
            setError(tempErrs)
            if (Object.keys(tempErrs).length == 0){
                let tempObj = { target_url: targetUrl, event_name: eventName,
                    project_id: project, hook_env: environment}
                if (bearerToken.length >= 16){
                    tempObj['bearer_token'] = bearerToken
                }
                axios.post(`/api/v1/organizations/${organization_name}/subscribed_hooks`, {
                  subscribe_hook: tempObj,
                  page_type: "client",
                  controller_name: "subscribed_hooks"
                }).then(res => {
                    toast.success(res.data.message)
                    setToggle(curr => !curr)
                    setEventName("")
                    setTargetUrl("")
                }).catch(err => {
                    toast.error(err.response.data.message)
                })
            }
        }
        else{
            if(eventName.trim().length < 1){
                tempErrs = {...tempErrs, eventName: "Please select an event name"}
            }
            if (!isURL(targetUrl)){
                tempErrs = {...tempErrs, targetUrl: "Please enter a valid URL"}
            }
            setError(tempErrs)
            if (Object.keys(tempErrs).length == 0){
                axios.post(`/api/v1/organizations/${organization_name}/subscribed_hooks`, {
                    subscribe_hook: {
                        target_url: targetUrl, event_name: eventName
                    },
                  page_type: "client",
                  controller_name: "subscribed_hooks"
                }).then(res => {
                    toast.success(res.data.message)
                    setToggle(curr => !curr)
                    setEventName("")
                    setTargetUrl("")
                }).catch(err => {
                    toast.error(err.response.data.message)
                })
            }
        }
    }

    const deleteWebhook = (data) => {
        axios.delete(`/api/v1/organizations/${organization_name}/subscribed_hooks/${data.id}?page_type=client&controller_name=subscribed_hooks`).then(res => {
            toast.success(res.data.message)
            setToggle(curr => !curr)
        }).catch(err => {
            toast.error(err.response.data.message)
        })
    }

    const removeBearerToken = (id) => {
        axios.delete(`/api/v1/organizations/${organization_name}/webhook_configurations/${id}?page_type=client&controller_name=webhook_configurations`).then(res => {
            toast.success(res.data.message)
            setToggle(curr => !curr)
        }).catch(err => {
            toast.error(err.response.data.message)
        })
    }

    const updateBearerToken = () => {
        if (modalBT.trim().length < 16){
            toast.error("please give atleast 16 characters")
        }
        else{
            axios.put(`/api/v1/organizations/${organization_name}/webhook_configurations/${selectedId}`, {
                webhook_config: {
                    bearer_token: modalBT
                },
              page_type: "client",
              controller_name: "webhook_configurations"
            }).then(res => {
                toast.success(res.data.message)
                setSelectedId('')
                setModal(false)
                setToggle(curr => !curr)
            }).catch(err => {
                toast.error(err.response.data.message)
            })
        }
    }

    const addBearerToken = () => {
        if (secondBT.trim().length < 16){
            toast.error("please give atleast 16 characters")
        }
        else{
            axios.post(`/api/v1/organizations/${organization_name}/webhook_configurations`, {
                webhook_config: {
                    bearer_token: secondBT
                },
              page_type: "client",
              controller_name: "webhook_configurations"
            }).then(res => {
                toast.success(res.data.message)
                setModal(false)
                setToggle(curr => !curr)
            }).catch(err => {
                toast.error(err.response.data.message)
            })
        }
    }

    return (
        <Container fluid>
            { loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> :
            <>
            <Breadcrumb parent="Webhooks Details" title="Subscribed Hooks" />

            <Nav tabs>
                <NavItem>
                    <NavLink href="#" className={BasicTab === '1' ? 'active' : ''}
                        onClick={() => setBasicTab('1')}>
                            Webhooks
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="#" className={BasicTab === '2' ? 'active' : ''} 
                        onClick={() => setBasicTab('2')}>
                        API Docs
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="#" className={BasicTab === '3' ? 'active' : ''} 
                        onClick={() => setBasicTab('3')}>
                        Bearer Token
                    </NavLink>
                </NavItem>   
            </Nav>

            {alert.visible && <SweetAlert success title="Bearer Token" onConfirm={() => setAlert({token: '', visible: false})} onCancel={() => setAlert({token: '', visible: false})}>
                <p className='p-3'>
                    {alert.token}
                </p>    
            </SweetAlert>}

            <Modal isOpen={modal} toggle={toggle2} backdrop='static' size='lg'>
                <ModalHeader toggle={toggle2} close={closeBtn}>
                    Update Bearer Token
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg={3}>
                            <h6 className='my-0 fw-bold'>Bearer Token</h6>
                        </Col>
                        <Col lg={3}></Col>
                        <Col lg={6}>
                            <input type="text" className='form-control' placeholder='Ex: cGFzc3dvcmQxMjM' 
                                value={modalBT} onChange={(e) => setModalBT(e.target.value)} />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={() => setModal(false)}>Cancel</Button>
                    <Button color="success" onClick={updateBearerToken}>Save</Button>
                </ModalFooter>
            </Modal>

            <TabContent activeTab={BasicTab}>
                    <TabPane className="fade show" tabId="1">
                        <Card className='mt-2'>
                            <CardHeader className='fw-bold py-4 px-5 internal-dash-card-header'>
                                You may subscribe to various webhooks events to receive programmatic and automated updates for crawl activities.
                            </CardHeader>
                        </Card>

                        <Card className='mt-2'>
                            <CardHeader className='fw-bold d-flex justify-content-between internal-dash-card-header'>
                                New Webhook
                            </CardHeader>
                            <CardBody className='py-4 px-5'>
                                { organization_name === "influsoft" ?
                                <form onSubmit={handleWebhooksSubmit}>
                                    <div className='my-3'>
                                        <label className='form-label fw-bold'>Target URL (URL where you wish to receive webhooks event updates)*</label>
                                        <input type="text" className='form-control' required
                                            value={targetUrl} onChange={(e) => setTargetUrl(e.target.value)}
                                            placeholder='https://www.mywebsite.com/receive_webhooks_updates' />
                                        {error.targetUrl && <p className="text-danger"> {error.targetUrl} </p>}
                                    </div>
                                    <div className='my-3'>
                                        <label className='form-label fw-bold'>Event Name*</label>
                                        <select className='form-control show-arrow' required
                                        defaultValue={eventName} onChange={(e) => setEventName(e.target.value)}>
                                            <option value="">Select:</option>
                                            { data?.dropdown_options?.event_names?.map((each, index) => (
                                                <option value={each} key={index}>{each}</option>
                                            ))}
                                        </select>
                                        {error.eventName && <p className="text-danger"> {error.eventName} </p>}
                                    </div>
                                    <div className='my-3'>
                                        <label className='form-label fw-bold'>Project*</label>
                                        <select className='form-control show-arrow' required defaultValue={project}
                                            onChange={(e) => setProject(e.target.value)}>
                                            <option value="">Select:</option>
                                            { data?.dropdown_options?.projects?.map((each) => (
                                                <option value={each[1]} key={each[1]}>{each[0]}</option>
                                            ))}
                                        </select>
                                        {error.project && <p className="text-danger"> {error.project} </p>}
                                    </div>
                                    <div className='my-3'>
                                        <label className='form-label fw-bold'>Environment*</label>
                                        <select className='form-control show-arrow' required defaultValue={environment}
                                            onChange={(e) => setEnvironment(e.target.value)}>
                                            <option value="">Select:</option>
                                            { data?.dropdown_options?.hook_envs?.map((each, index) => (
                                                <option value={each} key={index}>{each}</option>
                                            ))}
                                        </select>
                                        {error.environment && <p className="text-danger"> {error.environment} </p>}
                                    </div>
                                    <div className='my-3'>
                                        <label className='form-label fw-bold'>Bearer Token</label>
                                        <input type="text" className='form-control' value={bearerToken}
                                            onChange={(e) => setBearerToken(e.target.value)} placeholder='Ex: cGFzc3dvcmQxMjM' />
                                        {error.bearerToken && <p className="text-danger"> {error.bearerToken} </p>}
                                    </div>
                                    <button type='submit' className='btn btn-success text-center'>Submit</button>
                                </form>
                                : <form onSubmit={handleWebhooksSubmit}>
                                    <div className='my-3'>
                                        <label className='form-label fw-bold'>Target URL (URL where you wish to receive webhooks event updates)*</label>
                                        <input type="text" className='form-control' name="target_url" required value={targetUrl}
                                            placeholder='https://www.mywebsite.com/receive_webhooks_updates'
                                            onChange={(e) => setTargetUrl(e.target.value)} />
                                        {error.targetUrl && <p className='text-danger'> {error.targetUrl} </p>}
                                    </div>
                                    <div className='my-3'>
                                        <label className='form-label fw-bold'>Event Name*</label>
                                        <select className='form-control show-arrow' name="event_name" defaultValue={eventName}
                                            onChange={(e) => setEventName(e.target.value)}>
                                                <option value=""> Select: </option>
                                            { data?.dropdown_options?.event_names?.map((each, index) => (
                                                <option value={each} key={index}>{each}</option>
                                            ))}
                                        </select>
                                        {error.eventName && <p className='text-danger'> {error.eventName} </p>}
                                    </div>
                                    <button className='btn btn-success' type='submit'>Submit</button>
                                </form>}
                            </CardBody>
                        </Card>

                        {data?.subscribed_hooks?.length > 0 && 
                        <Card className='mt-2'>
                            <CardHeader className='fw-bold d-flex justify-content-between internal-dash-card-header'>
                                Subscribed Hooks
                            </CardHeader>
                            <CardBody>
                                {organization_name === "influsoft" ?
                                    <>
                                    <Table responsive>
                                        <thead>
                                            <tr className='bg-light'>
                                                <td className='fw-bold'>Organization</td>
                                                <td className='fw-bold'>URL</td>
                                                <td className='fw-bold'>Event Name</td>
                                                <td className='fw-bold'>Project</td>
                                                <td className='fw-bold'>Environment</td>
                                                <td className='fw-bold'>Bearer Token</td>
                                                <td className='fw-bold'>Options</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.subscribed_hooks.map(each => (
                                                <tr key={each.id}>
                                                    <td>
                                                        {each.organization_name}
                                                    </td>
                                                    <td>
                                                        {each.url}
                                                    </td>
                                                    <td>
                                                        {each.event_name}
                                                    </td>
                                                    <td>
                                                        {each.project_title}
                                                    </td>
                                                    <td>
                                                        {each.hook_env}
                                                    </td>
                                                    <td>
                                                      {each?.webhook_configuration?.bearer_token? 
                                                        <div className='d-flex'>
                                                            <button className='btn btn-xs' onClick={() => setAlert({token: each.webhook_configuration.bearer_token, visible: true})}>View Bearer Token</button>
                                                            <button className='btn btn-xs mx-1' onClick={() => {
                                                                setSelectedId(each.webhook_configuration.id)
                                                                setModal(true)
                                                            }}>Update Bearer Token</button>
                                                        </div>
                                                          :  <p className="text-center">NA</p>
                                                      }
                                                    </td>
                                                    <td>
                                                        <button className='btn btn-xs text-primary' onClick={() => deleteWebhook(each)}>
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </>
                                : <>
                                    <Table responsive>
                                        <thead>
                                            <tr className='bg-light'>
                                                <td className='fw-bold'>Organization</td>
                                                <td className='fw-bold'>URL</td>
                                                <td className='fw-bold'>Event Name</td>
                                                <td className='fw-bold'>Options</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.subscribed_hooks.map(each => (
                                                <tr key={each.id}>
                                                    <td>
                                                        {each.organization_name}
                                                    </td>
                                                    <td>
                                                        {each.url}
                                                    </td>
                                                    <td>
                                                        {each.event_name}
                                                    </td>
                                                    <td>
                                                        <button className='btn text-primary' onClick={() => deleteWebhook(each)}>
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </>
                                }
                            </CardBody>
                        </Card>}
                    </TabPane>
                    <TabPane tabId="2">
                        <Card>
                            <CardHeader className='fw-bold d-flex justify-content-between internal-dash-card-header'>
                            Crawl Notifications via Webhooks
                            </CardHeader>
                            <CardBody>
                                <p>Client can subscribe to crawl notifications via webhooks. As of now there are 5 crawl events for which we can notify client via hooks.</p>
                                <ul style={{listStyleType: 'circle'}} className="px-3 mx-4 mb-3">
                                    <li>crawl_started</li>
                                    <li>crawl_completed</li>
                                    <li>upload_completed</li>
                                    <li>individual_file_uploaded</li>
                                    <li>merge_files_uploaded(opt-in, please contact support@promptcloud.com)</li>
                                </ul>
                                <p>
                                    Hooks are basically a post requests made from app.promptcloud.com to the target_url specified by client when they subscribe for hook. The schema of json is same for all the three events only the values changes.
                                </p>
                                <p>The payload schema for events <span className='code-red'> crawl_started, crawl_completed, upload_completed </span> are identicial. Below is an example paylod for these 3 events.</p>
                                <SyntaxHighlighter language="javascript" style={xcode} className="my-3">
                                    {codeString1}
                                </SyntaxHighlighter>
                                <p>The payload schema for <span className='code-red'>individual_file_uploaded</span> event is little different from the other events. Insted of <span className='code-red'>data_api_url</span> we have <span className='code-red'>file_download_url</span> in place of <span className='code-red'>data_api_url</span> which contains the URL to download the files directly. Below is an example payload for this event.</p>
                                <SyntaxHighlighter language="javascript" style={xcode} className="my-3">
                                    {codeString2}
                                </SyntaxHighlighter>
                                <p>The payload schema for <span className='code-red'>merge_files_uploaded</span> event is similar to <span className='code-red'>individual_file_uploaded</span>. Insted of <span className='code-red'>file_download_url</span> we have <span>file_download_urls</span> which is an array containing the URLto download the files directly. Below is an example payload for this event.</p>
                                <SyntaxHighlighter language="javascript" style={xcode} className="my-3">
                                    {codeString3}
                                </SyntaxHighlighter>
                                <p>So if a client is setting up code to handle the hook requests they should use the above schema.</p>
                            </CardBody>
                        </Card>

                        {/* <Card className='mt-3'>
                            <CardHeader className='fw-bold d-flex justify-content-between internal-dash-card-header'>
                                How to subscribe to hooks?
                            </CardHeader>
                            <CardBody>
                                <p>We have set up an api where clients can send requests to subscribe to hooks. It follows the RESTfull pattern(update is not available). All the requests should be in JSON. Response is also JSON.</p>
                                <a href="https://app.promptcloud.com/api/subscribed_hooks" target="_blank">https://app.promptcloud.com/api/subscribed_hooks</a>
                                <p>To authenticate client’s organization, they should send their org_uuid and api_key that they use in data api here in the headers as follows</p>

                                <div className='bg-light text-danger p-4 my-2'>
                                    <p>X-Organization-UUID &lt; &lt; insert_organization_uniq_identifier &gt; &gt; </p>
                                    <p>X-Api-Key &lt; &lt; insert_api_key &gt; &gt; </p>
                                </div>

                                <p>Also use the following header as well in all requests: <b>Content-Type application/json</b> </p>
                                <p>All the following requests should contain the X-Organization-UUID and X-Api-Key in the headers so that the requests are authorized properly. If there is any problem with authentication we should respond with 422 http status code.</p>
                            </CardBody>
                        </Card> */}

                        {/* <Card className='mt-3'>
                            <CardHeader className=' fw-bold d-flex justify-content-between internal-dash-card-header'>
                            How to create a hook subscription?
                            </CardHeader>
                            <CardBody>
                                <p>Send a POST request to the above url with all the required headers for authentication. The body of the request should contain the following JSON</p>

                                <SyntaxHighlighter language="javascript" style={xcode} className="my-3">
                                    {codeString4}
                                </SyntaxHighlighter>

                                <p>If this request is successful at our end clients will get the following JSON response with 201 as the status code</p>

                                <SyntaxHighlighter language="javascript" style={xcode} className="my-3">
                                    {codeString5}
                                </SyntaxHighlighter>

                                <p>Here the “id”: 11 is the id of hook to which client just subscribed. Its advisable to store the id somewhere so that it can be used again in case of unsubscribing. Also, possible event names that clients can subscribe to.</p>

                                <ul style={{listStyleType: "circle"}} className="mx-4">
                                    <li>crawl_started</li>
                                    <li>crawl_completed</li>
                                    <li>upload_completed</li>
                                </ul>
                            </CardBody>
                        </Card> */}

                        {/* <Card className='mt-3'>
                            <CardHeader className='fw-bold d-flex justify-content-between internal-dash-card-header'>
                                How to unsubscribe from a hook?
                            </CardHeader>
                            <CardBody>
                                <p>To unsubscribe from a hook, client just have to send a DELETE http request to</p>
                                <div className='bg-light text-danger p-4'>
                                    DELETE https://app.promptcloud.com/subscribed_hooks/&lt; &lt; id _of_the_hook &gt; &gt;
                                </div>
                                <p>Id_of_the_hook is the same id which is returned on successful hook subscription.</p>
                            </CardBody>
                        </Card> */}

                        {/* <Card className='mt-3'>
                            <CardHeader className='fw-bold d-flex justify-content-between internal-dash-card-header'>How to get the list of all subscribed hooks?</CardHeader>
                            <CardBody>
                                <p>No problem if you don’t have the id, clients can always get the id of the hook via api just by posting a GET http request at the following url</p>
                                <div className='p-4 text-danger bg-light'>GET https://app.promptcloud.com/subscribed_hooks</div>
                            </CardBody>
                        </Card> */}

                        <Card className='mt-3'>
                            <CardHeader className='fw-bold d-flex justify-content-between internal-dash-card-header'>IPs to whitelist</CardHeader>
                            <CardBody>
                                <p>We make changes to our IP addresses from time to time. We do not recommend whitelisting by IP address, however if you use this, following is the list of IP addresses where our webhook notifications may come from</p>
                                <p>We are in the process of migrating our servers and these are the new IP addresses where the webhooks requests will be made from. If you've already whitelisted out IPs please add these as well.</p>
                                <div className='p-4 bg-light text-danger'>
                                    <p>65.21.198.184</p>
                                    <p>65.21.76.239</p>
                                </div>
                            </CardBody>
                        </Card>
                    </TabPane>
                    <TabPane tabId="3">
                        <Card> 
                            <CardBody>
                                { data?.webhook_configuration?.bearer_token?.length > 0
                                ? <div className='text-center'>
                                    <p>You've opted to use Bearer Token to authenticate webhook requests</p>
                                    <button className='btn btn-primary mb-4' 
                                        onClick={() =>  setAlert({token: data?.webhook_configuration.bearer_token, visible: true})}>Show Bearer Token</button>
                                    <p>If you don't wish to use the Bearer token authorization for webhooks, please remove from below</p>
                                    <button className='btn btn-danger mb-3' onClick={() => removeBearerToken(data?.webhook_configuration?.id)}>Remove Bearer Token</button>
                                </div>
                                : 
                                <>
                                <p>If you wish to use Bearer Token to authenticate webhook requests please enter the token below</p>
                                <div className='d-flex align-items-center'>
                                    <h6 className='my-0 mx-2'>
                                        Bearer Token
                                    </h6>

                                    <input className='form-control mx-2' name="bearer_token" type="text" style={{width: 350}} value={secondBT}
                                        placeholder='Ex: cGFzc3dvcmQxMjM' onChange={(e) => setSecondBT(e.target.value)} />

                                    <button className='btn btn-primary mx-2' onClick={addBearerToken}>Save</button>
                                </div>
                                </>}
                            </CardBody>
                            <CardFooter>
                                <p>If you've given Bearer Token to authenticate webhook requests sent to your endpoint, please read below on how the token is used</p>
                                <p>With all the POST webhooks requests sent to your endpoint, the Bearer Token that you've given will be added to the header as follows</p>
                                <SyntaxHighlighter language="javascript" style={xcode} className="my-3">
                                    {codeString6}
                                </SyntaxHighlighter>
                            </CardFooter>
                        </Card>
                    </TabPane>
            </TabContent> 
            </>}
        </Container>
    )
}
