import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";
import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Bar } from "react-chartjs-2";

export default function GodReportsByMachine() {
    const search = useLocation().search;
    const commit = new URLSearchParams(search).get("commit");
    const searchParams = {
        fromDate: new URLSearchParams(search).get("filter[from]"),
        toDate: new URLSearchParams(search).get("filter[to]"),
        machineName: new URLSearchParams(search).get("filter[machine_name]")
    }
    const now = new Date()
    const [labels, setLabels] = useState([]);
    const [machineNameOptions, setMachineNameOptions] = useState([]);
    const [machineName, setMachineName] = useState({ label: 'All', value: 'All' });
    const [data, setData] = useState([])
    const [fromDate, setFromDate] = useState(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7))
    const [toDate, setToDate] = useState(now);
    const [loading, setLoading] = useState(false);
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Machines God Report',
            },
        },
    };

    useEffect(() => {
        setSearchParamsData()
    }, []);

    function setSearchParamsData() {
        if (searchParams.fromDate && searchParams.fromDate != fromDate) {
            setFromDate(new Date(searchParams.fromDate))
        }
        if (searchParams.toDate && searchParams.toDate != toDate) {
            setToDate(new Date(searchParams.toDate))
        }
        if (searchParams.machineName && machineName != machineName.value) {
            setMachineName({ label: searchParams.machineName, value: searchParams.machineName })
        }
        if (commit == 'Get Data') {

            fetchData(searchParams.fromDate, searchParams.toDate, searchParams.machineName)
        }
        else {
            fetchIntialData();
        }
    }

    function fetchData(FromDate = fromDate, ToDate = toDate, MachineName = machineName.value) {
        axios.get(`/api/v1/internal/god_report/search_by_machine?filter[from]=${ moment(FromDate).format("YYYY/MM/DD")}&filter[to]=${moment(ToDate).format("YYYY/MM/DD")}&filter[machine_name]=${MachineName}&commit=${'Get Data'}`)
            .then(res => {
                setLabels(res.data.date_range);
                setData(res.data.watcher_counts);
                setMachineNameOptions(res.data.all_machines.map(item => ({ label: item, value: item })));
            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong')
            })
    }
    function fetchIntialData() {
        axios.get(`/api/v1/internal/god_report/fetch_god_reports_by_machine?filter[from]=${moment(fromDate).format("YYYY/MM/DD")}&filter[to]=${moment(toDate).format("YYYY/MM/DD")}&filter[machine_name]=${'All'}&commit=${'Get Data'}`)
            .then(res => {
                setLabels(res.data.date_range);
                setData(res.data.watcher_counts);
                setMachineNameOptions(res.data.all_machines.map(item => ({ label: item, value: item })));

            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong')
            })
    }
    const handleGetData = () => {
        window.location.href = `/internal/god_report/search_by_machine?filter[from]=${moment(fromDate).format("YYYY/MM/DD")}&filter[to]=${moment(toDate).format("YYYY/MM/DD")}&filter[machine_name]=${machineName.value}&commit=${'Get Data'}`
    }


    return <Container fluid>
        <div className="page-title">
            <Row>
                <Col xs="6">
                    <h3> Internal Dashboard</h3>
                </Col>
                <Col xs="6">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={'/internal'}>
                                <Home />
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem className="fw-bold">
                            {commit == 'Get Data' ? <a className="link-primary fw-normal" href={'/internal/god_report/fetch_god_reports_by_machine'}>God Reports By Machine</a> : 'God Reports By Machine'}
                        </BreadcrumbItem>
                        {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                            Search
                        </BreadcrumbItem>}
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
        <Card>
            <CardBody>
                <Row className="align-items-end">
                    <Col sm="3">
                        <label className="fw-bold">From Date</label>
                        <DatePicker
                            className="form-control"
                            placeholderText="Select Date"
                            maxDate={now}
                            selected={fromDate ? new Date(fromDate) : fromDate}
                            dateFormat={"YYY/MM/dd"}
                            onChange={date => setFromDate(date)} />
                    </Col>
                    <Col sm="3">
                        <label className="fw-bold">To Date</label>
                        <DatePicker
                            className="form-control"
                            selected={toDate ? new Date(toDate) : toDate}
                            placeholderText="Select Date"
                            maxDate={now}
                            dateFormat={"YYY/MM/dd"}
                            onChange={date => setToDate(date)} />
                    </Col>
                    <Col sm="3">
                        <label className="fw-bold">Machine Name</label>
                        <Select
                            options={machineNameOptions}
                            value={machineName}
                            onChange={option => setMachineName(option)} />
                    </Col>
                    <Col sm="3">
                        <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
                    </Col>
                </Row>

            </CardBody>
        </Card>
        <Card>
            <CardBody>
                <Bar
                    options={options}
                    data={{
                        labels: labels,
                        datasets: [{
                            label: 'Overall Watcher Counts',
                            data: data,
                            backgroundColor: "#01579b",
                            borderColor: '#01579b'
                        }]
                    }} />
            </CardBody>
        </Card>
    </Container>
}