import React from 'react';
import moment from "moment";
import DataTable from 'react-data-table-component';


 // Helper function for getting difference in time
 function getDifferenceInHours(start_date, end_date) {
  let a = moment(end_date);
  let b = moment(start_date);
  let hours = a.diff(b, 'hours');
  let minutes = moment.utc(a.diff(b)).format("mm");
  let seconds = moment.utc(a.diff(b)).format("ss");
  return `${Math.floor(hours)}h ${Math.floor(minutes)}m ${seconds}s`
};

// Table column definitions
const columns = [
  {
    name: 'Site',
    selector: 'site',
    center: true,
    sortable: true,
    cell: row => <div data-bs-toggle="tooltip" data-bs-placement="bottom" title={row.segment_path}>{row.site}</div>
  },
  {
    name: 'Host Name',
    selector: 'hostname',
    center: true,
    sortable: true
  },
  {
    name: 'Crawl Date',
    selector: 'crawldate',
    center: true,
    sortable: true
  },
  {
    name: 'Extraction Start Time',
    selector: 'extraction_start_time',
    center: true, sortable: true,
    cell: row => <div>{moment.utc(row.extraction_start_time).format('YYYY-MM-DD HH:mm:ss')} UTC</div>
  },
  {
    name: 'Extraction End Time',
    selector: 'extraction_end_time',
    center: true,
    sortable: true,
    cell: row => <div>{moment.utc(row.extraction_end_time).format('YYYY-MM-DD HH:mm:ss')} UTC</div>
  },
  {
    name: 'Takes More Time',
    center: true, sortable: true,
    cell: row => <div>{getDifferenceInHours(row.extraction_start_time, row.extraction_end_time)}</div>
  },
];

export default function SegmentsDataTable(props) {
  return (
    <div>
      <DataTable
        columns={columns}
        data={props?.data}
        progressPending={props?.loading}
        noHeader
        pagination
        responsive />
    </div>
  )
}
