import React, { useState, useEffect } from 'react';
import { Page, Text, View, Document, Image, Font } from '@react-pdf/renderer';
import { styles } from './CustomStyles/CustomStyles';
import { ToCapitalCase } from '../ClientFeasibilityReportsTable/ClientFeasibilityReportsTable.component';
import logo from '../../../Common/Logo/logo.png';
import axios from 'axios';

Font.register({
  family: 'SourceSansPro', fonts: [
    { src: 'https://fonts.gstatic.com/s/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3aPw.ttf', fontStyle: 'normal', fontWeight: 'normal' },
    { src: 'https://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rAkA.ttf', fontWeight: 600 },
  ]
});

export default function FeasibilityReportPDF({ organizationName, id }) {

  const [data, setData] = useState({});

  useEffect(() => {
    axios.get(`/api/v1/organizations/${organizationName}/feasibility_reports/${id}`).then(res => {
      setData(res?.data?.feasibility_report)
    })
  }, [organizationName]);

  return (
    <Document>
      <Page size="B4" style={styles.page}>
        <View style={styles.topContainer}>
          <Text style={{ textTransform: 'ToCapitalCase' }}>
            {ToCapitalCase(data?.feasibility_check_status || "")}
          </Text>
          <Image style={styles.images}
            src={logo} />
          <Text>
            {data?.created_at}
          </Text>
        </View>
        <View style={styles.heading1}>
          <Text style={styles.fwBold}>Feasibility Report for Sitegroup {data?.sitegroup_name}</Text>
        </View>

        {data?.feasibility_check_status == 'report_generated' ? <Text style={{ fontSize: 13, margin: '6px 60px' }}>
          Thank you for providing us with the requirements. We had a close look at the requirements. Please find our analysis below. </Text>
          : <>
            <View style={styles.flexContainer}>
              <Text style={[styles.flex1, styles.fwBold]}>
                Sitegroup Name
              </Text>
              <Text style={styles.flex2}>
                :
              </Text>
              <Text style={styles.flex3}>
                {data?.sitegroup_name}
              </Text>
            </View>

            <View style={styles.flexContainer}>
              <Text style={[styles.flex1, styles.fwBold]}>
                Organization
              </Text>
              <Text style={styles.flex2}>
                :
              </Text>
              <Text style={styles.flex3}>
                {data?.organization_name}
              </Text>
            </View>

            <View style={styles.flexContainer}>
              <Text style={[styles.flex1, styles.fwBold]}>
                Country
              </Text>
              <Text style={styles.flex2}>
                :
              </Text>
              <Text style={styles.flex3}>
                {data?.country_name}
              </Text>
            </View>

            <View style={styles.flexContainer}>
              <Text style={[styles.flex1, styles.fwBold]}>
                Creator
              </Text>
              <Text style={styles.flex2}>
                :
              </Text>
              <Text style={styles.flex3}>
                {data?.creator}
              </Text>
            </View>
            <View style={styles.flexContainer}>
              <Text style={[styles.flex1, styles.fwBold]}>
                Additional Information
              </Text>
              <Text style={styles.flex2}>
                :
              </Text>
              <Text style={styles.flex3}>
                {data?.additional_information}
              </Text>
            </View>
          </>}

        {data?.project_version == "v2" && <View style={styles.flexContainer}>
          <Text style={[styles.flex1, styles.fwBold]}>
            Fields to extract
          </Text>
          <Text style={styles.flex2}>
            :
          </Text>
          <Text style={{ width: '40%', fontSize: 13, display: 'block' }}>
            {data?.fields_to_extract}
          </Text>
        </View>}

        {data?.project_version == "v3" &&
          <>
            <Text style={[styles.fwBold, { fontSize: 13, margin: '6px 60px' }]}>
              Schema Details:
            </Text>
            <View style={styles.table}>
              <View style={[styles.row, styles.bold, styles.header]}>
                <Text style={[styles.fwBold, styles.row11]}>Field Name</Text>
                <Text style={[styles.fwBold, styles.row12]}>Data Type</Text>
                <Text style={[styles.fwBold, styles.row13]}>Is Mandatory</Text>
                <Text style={[styles.fwBold, styles.row14]}>Default Value</Text>
                <Text style={[styles.fwBold, styles.row15]}>Sample Value</Text>
                <Text style={[styles.fwBold, styles.row16]}>Comments</Text>
              </View>
              {data?.schema_fields_details?.map((each, i) => (
                <View key={i} style={styles.row} wrap={false}>
                  <Text style={styles.row11}> {each?.field_name} </Text>
                  <Text style={styles.row12}> {each?.data_type} </Text>
                  <Text style={styles.row13}> {each?.is_mandatory} </Text>
                  <Text style={styles.row14}> {each?.default_value} </Text>
                  <Text style={styles.row15}> {each?.sample_value} </Text>
                  <Text style={styles.row16}> {each?.comments} </Text>
                </View>
              ))}
            </View>
          </>
        }

        {data?.feasibility_check_status == 'report_generated' ?
          <Text style={{ fontSize: 13, margin: '6px 60px' }}> Overall Feasibility: Feasible. Please check the comments section for further details.  </Text>
          : null}

        <Text style={styles.sideHeading}>
          Sites:
        </Text>
        <View style={styles.table}>
          <View style={[styles.row, styles.bold, styles.header]}>
            <Text style={[styles.row1, styles.fwBold]}>#</Text>
            <Text style={[styles.row2, styles.fwBold]}>Site Name</Text>
            <Text style={[styles.row3, styles.fwBold]}>Frequency</Text>
            <Text style={[styles.row4, styles.fwBold]}>Feasibility</Text>
          </View>
          {data?.sites?.map((row, i) => (
            <View key={i} style={styles.row} wrap={false}>
              <Text style={styles.row1}>
                <Text>{i + 1}</Text>
              </Text>
              <Text style={styles.row2}>{row?.site_name}</Text>
              <Text style={styles.row3}>{data?.frequency_in_text}</Text>
              <Text style={styles.row4}>
                {row?.feasibility_status?.replaceAll("_", " ")}
              </Text>
            </View>
          ))}
        </View>

        {data?.feasibility_check_status == 'report_generated' ?
          <>
            <Text style={{ fontSize: 13, margin: '6px 60px' }}> Our pricing structure would be as follow:  </Text>
            <View style={styles.table}>
              <View style={[styles.row, styles.bold, styles.header]}>
                <Text style={[styles.row21, styles.fwBold]}>#</Text>
                <Text style={[styles.row22, styles.fwBold]}>Site Name</Text>
                <Text style={[styles.row23, styles.fwBold]}>One-time Site Setup Fee</Text>
                <Text style={[styles.row24, styles.fwBold]}>Monthly Maintenance Fee</Text>
                <Text style={[styles.row25, styles.fwBold]}>Volume Fee (per 10k records - prorated)</Text>
              </View>
              {data?.sites?.map((row, i) => (
                <View key={i} style={styles.row} wrap={false}>
                  <Text style={styles.row21}>{i + 1}</Text>
                  <Text style={styles.row22}>{row?.site_name}</Text>
                  <Text style={styles.row23}>{row?.pricing_detail?.site_setup_fee}</Text>
                  <Text style={styles.row24}>{row?.pricing_detail?.monthly_maintenance_fee}</Text>
                  <Text style={styles.row25}>{row?.pricing_detail?.volume_charges}</Text>
                </View>
              ))}
            </View>
            {data.overall_feasibility === "not_feasible" ?
              <>
                <Text style={[styles.fwBold, { fontSize: 13, margin: '2px 60px' }]}>Comments: </Text>
                <Text style={{ fontSize: 13, margin: '2px 60px' }}>
                  Though the current set of requirements do not look feasible,
                  please feel free to create new SiteGroup in case you wish to
                  evaluate feasibility of crawling a different source to collect the required data.
                </Text>
              </>
              : <>
                <Text style={[styles.fwBold, { fontSize: 13, margin: '2px 60px' }]}>Comments: </Text>
                <Text style={{ fontSize: 13, margin: '2px 60px' }}> If everything sounds good, please proceed to view our Terms and Conditions. Upon accepting the same, your initial invoice (one-time setup fee per site) shall be generated.
                  The prorated Monthly Maintenance Fee as well as the Volume charges will be billed separately at month-end based on the actual number of records delivered and adjustments, if any, shall be made against your next invoice. </Text>
                <Text style={{ fontSize: 13, margin: '2px 60px' }}> We shall get started as soon as the payment is made. The first set of data can be expected within 7 days (unless otherwise mentioned). </Text>
                <Text style={{ fontSize: 13, margin: '2px 60px' }}>Looking forward to working with you.</Text>
                <Text style={{ fontSize: 13, margin: '2px 60px' }}> Thanks, </Text>
                <Text style={{ fontSize: 13, margin: '2px 60px' }}> Team PromptCloud </Text>
              </>}
          </>
          : <>
            <Text style={[styles.fwBold, { fontSize: 13, margin: '6px 60px' }]}>
              Delivery Details:
            </Text>
            <View style={styles.table}>
              <View style={[styles.row]}>
                <Text style={styles.row1}>1</Text>
                <Text style={styles.row2}>Output Format</Text>
                <Text style={styles.row3}>{data?.data_format}</Text>
                <Text style={styles.row4}></Text>
              </View>
              <View style={[styles.row]}>
                <Text style={styles.row1}>2</Text>
                <Text style={styles.row2}>Frequency</Text>
                <Text style={styles.row3}>{data?.frequency_in_text}</Text>
                <Text style={styles.row4}></Text>
              </View>
              <View style={[styles.row]}>
                <Text style={styles.row1}>3</Text>
                <Text style={styles.row2}>Delivery Method</Text>
                <Text style={styles.row3}>{data?.data_delivery}</Text>
                <Text style={styles.row4}></Text>
              </View>
            </View>

            <Text style={[styles.fwBold, { fontSize: 13, margin: '6px 60px' }]}>
              Additional Details:
            </Text>
            <View style={styles.table}>
              {data?.additional_details?.image_downloads?.toString()?.length > 0 && <View style={[styles.row]}>
                <Text style={styles.row1}></Text>
                <Text style={styles.row2}>Image Downloads</Text>
                <Text style={styles.row3}>{data?.additional_details?.image_downloads == '0' ? 'Disabled' : 'Enabled'}</Text>
                <Text style={styles.row4}>{data?.additional_details?.image_downloads == '0' ? 'NA' : data?.additional_details?.image_downloads}</Text>
              </View>}

              {data?.additional_details?.two_days_of_delivery?.toString()?.length > 0 && <View style={[styles.row]}>
                <Text style={styles.row1}></Text>
                <Text style={styles.row2}>Two Days of Delivery</Text>
                <Text style={styles.row3}>{data?.additional_details?.two_days_of_delivery == '0' ? 'Disabled' : 'Enabled'}</Text>
                <Text style={styles.row4}>{data?.additional_details?.two_days_of_delivery == '0' ? 'NA' : data?.additional_details?.two_days_of_delivery}</Text>
              </View>}

              {data?.additional_details?.hosted_indexing?.toString()?.length > 0 && <View style={[styles.row]}>
                <Text style={styles.row1}></Text>
                <Text style={styles.row2}>Hosted Indexing</Text>
                <Text style={styles.row3}>{data?.additional_details?.hosted_indexing == '0' ? 'Disabled' : 'Enabled'}</Text>
                <Text style={styles.row4}>{data?.additional_details?.hosted_indexing == '0' ? 'NA' : data?.additional_details?.hosted_indexing}</Text>
              </View>}

              {data?.additional_details?.merge_files?.toString()?.length > 0 && <View style={[styles.row]}>
                <Text style={styles.row1}></Text>
                <Text style={styles.row2}>Merge Files</Text>
                <Text style={styles.row3}>{data?.additional_details?.merge_files == '0' ? 'Disabled' : 'Enabled'}</Text>
                <Text style={styles.row4}>{data?.additional_details?.merge_files == '0' ? 'NA' : data?.additional_details?.merge_files}</Text>
              </View>}
            </View>
          </>}
      </Page>
    </Document>
  )
}
