import React, {useState, useEffect, Fragment} from 'react';
import { Card, CardBody, Col, Container, Row, Breadcrumb, BreadcrumbItem, Nav, Table, Alert,
  NavItem, NavLink, TabContent, TabPane, CardHeader } from 'reactstrap';
import Select from 'react-select';
import Loader from 'react-loader-spinner';
import axios from 'axios'
import { toast } from 'react-toastify';
import MyAccordion from '../../../../global/MyAccordion'
import { useParams, useLocation } from 'react-router-dom';

import SampleMaxRecord from './SampleMaxRecord';
import SampleMinRecord from './SampleMinRecord';
import SampleMissingFields from './SampleMissingFields';
import SegmentLevelQaFile from './SegmentLevelQaFile';
import SegmentLevelDataFile from './SegmentLevelDataFile';
import ExamplesForMissingFields from './ExamplesForMissingFields';
import DataFileComparison from './DataFileComparison';
import SpinnerButton from '../../../Common/SpinnerButton/SpinnerButton.component';

export default function SegmentLevelQa({downloadDoc, activeTabName}) {
  let { sitename } = useParams();
  const [selectedDevQA, setSelectedDevQA] = useState('false')
  const [selectedBizQA, setSelectedBizQA] = useState('false')
  const [devComment, setDevComment] = useState('')
  const [bizComment, setBizComment] = useState('')
  const [data, setData] = useState({})
  const [toggle, setToggle] = useState(false)
  const [loading, setLoading] = useState(true)
  const [saveButtonloading, setSaveButtonLoading] = useState(false)
  const [resetButtonloading, setResetButtonLoading] = useState(false)

  const search = useLocation().search
  const tabName = new URLSearchParams(search).get('tab_name')
  const [activeTab, setActiveTab] = useState(tabName? tabName : "qa_file")

  useEffect(() => {
    if (activeTabName == "segment_level_qa"){
      setLoading(true)
      axios.get(`/api/v2/internal/qa/${sitename}`).then(res => {
        setLoading(false)
        setData(res?.data)
        setSelectedDevQA(res?.data?.site_qa_db_data?.dev_site_qa_status);
        setSelectedBizQA(res?.data?.site_qa_db_data?.biz_site_qa_status);
        setDevComment(res?.data?.site_qa_db_data?.dev_comment);
        setBizComment(res?.data?.site_qa_db_data?.comment);
      }).catch(err => {
        setLoading(false)
      })
    }
  }, [toggle])

  const handleSave = () => {
    setSaveButtonLoading(true)
    axios.put(`/api/v2/internal/qa/${sitename}`, {
      process_model_site_qa: {
        dev_site_qa_status: selectedDevQA, biz_site_qa_status: selectedBizQA,
        comment: bizComment, dev_comment: devComment
      }
    }).then(res => {
      setSaveButtonLoading(false)
      toast.success(res.data.message)
      setToggle(curr => !curr)
    }).catch(err => {
      setSaveButtonLoading(false)
      toast.error(err.response.data?.message)
    })
  }

  const handleReset = () => {
    setResetButtonLoading(true)
    axios.put(`/api/v2/internal/qa/${sitename}/reset_manual_qa_status`).then(res => {
      setResetButtonLoading(false)
      toast.success(res.data?.message)
      setToggle(curr => !curr)
    }).catch(err => {
      setResetButtonLoading(false)
      toast.error(err.response.data?.message)
    })
  }

  const theme = {
    scheme: 'monokai',
    author: 'wimer hazenberg (http://www.monokai.nl)',
    base00: '#ffffff',
    base01: '#d76a1d',
    base02: '#575554',
    base03: '#272727',
    base04: '#3d4af8',
    base05: '#f83d4a',
    base06: '#2eb281',
    base07: '#812eb2',
    base08: '#b85c09',
    base09: '#af2eb2',
    base0A: '#0554b5',
    base0B: '#02949c',
    base0C: '#b505ac',
    base0D: '#b59305',
    base0E: '#b53305',
    base0F: '#2fb505',
  };

  return(
    <>
      <MyAccordion title='Segment Level Quality Analysis' preExpanded={["one"]}>
        {
          loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
            :
            <Row>
              <Col md='12' className='my-3'>
                <h6 className='fw-bold'>Site Name:</h6>
                <input type='text' value={sitename} className='form-control' disabled />
              </Col>
              <Col md='6' className='my-3'>
                <h6 className='fw-bold'>Dev Site QA Status:</h6>
                <select value={selectedDevQA} onChange={(e) => setSelectedDevQA(e.target.value)}
                  className='form-control'>
                  <option value='1'>All fine</option>
                  <option value='0'>Doesn't feel good</option>
                </select>
              </Col>
              <Col md='6' className='my-3'>
                <h6 className='fw-bold'>Biz Site QA Status:</h6>
                <select value={selectedBizQA} onChange={(e) => setSelectedBizQA(e.target.value)}
                  className='form-control' disabled={!(data?.current_user_can_access_biz_qa)}>
                  <option value='1'>All fine</option>
                  <option value='0'>Doesn't feel good</option>
                </select>
              </Col>
              <Col md='6' className='my-3'>
                <h6 className='fw-bold'>Dev's comment:</h6>
                <textarea rows='3' className='form-control' value={devComment}
                  onChange={(e) => setDevComment(e.target.value)}></textarea>
              </Col>
              <Col md='6' className='my-3'>
                <h6 className='fw-bold'>Biz's comment:</h6>
                <textarea rows='3' className='form-control' value={bizComment}
                  onChange={(e) => setBizComment(e.target.value)} disabled={!(data?.current_user_can_access_biz_qa)}></textarea>
              </Col>
            </Row>
        }
      </MyAccordion>
      <SampleMaxRecord theme={theme} activeTabName={activeTabName}/>
      <SampleMinRecord theme={theme} activeTabName={activeTabName}/>
      <SampleMissingFields activeTabName={activeTabName}/>
      <Row className='my-3'>
        <Col md={4}>
          <SpinnerButton
            className="btn btn-success w-100"
            color={'success'}
            loading={saveButtonloading}
            onClick={handleSave}>
            Save
          </SpinnerButton>
        </Col>
        <Col md={4}></Col>
        <Col md={4}>
          <SpinnerButton
            className="btn btn-success w-100"
            color={'success'}
            loading={resetButtonloading}
            onClick={handleReset}>
            Reset Manual QA Status
          </SpinnerButton>
          <p>This will make all the QA status false and re-upload a QA file from the next run</p>
        </Col>
      </Row>
      <div className='my-4'>
        <Nav tabs className="border-tab">
          <NavItem>
            <NavLink className={activeTab === "qa_file" ? "active" : ''} onClick={() => setActiveTab("qa_file")}> 
              Qa File 
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab === "data_file" ? "active" : ''} onClick={() => setActiveTab("data_file")}> 
              Data File 
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab === "missing_fields" ? "active" : ''} onClick={() => setActiveTab("missing_fields")}> 
              Examples for missing fields 
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab === "file_comparison" ? "active" : ''} onClick={() => setActiveTab("file_comparison")}> 
              File Comparison
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId='qa_file'>
            <SegmentLevelQaFile downloadDoc={downloadDoc} activeTabName={activeTab}/>
          </TabPane>
          <TabPane tabId='data_file'>
            <SegmentLevelDataFile theme={theme} downloadDoc={downloadDoc} activeTabName={activeTab}/>
          </TabPane>
          <TabPane tabId='missing_fields'>
            <ExamplesForMissingFields activeTabName={activeTab}/>
          </TabPane>
          <TabPane tabId='file_comparison'>
            <DataFileComparison downloadDoc={downloadDoc} activeTabName={activeTab}/>
          </TabPane>
        </TabContent>
      </div>
    </>
  )
}
