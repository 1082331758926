import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { Row, Col, Input, Table} from 'reactstrap'
import { toast } from 'react-toastify'
import axios from 'axios'
import XLSX from 'xlsx'

import EditDeliveryDetails from '../../Modals/EditDeliveryDetails/EditDeliveryDetails.component';
import AddNewSite from '../../Modals/AddNewSite/AddNewSite.component';


const SiteDetails = forwardRef(({ data, setDataToggle, dataToggle,
  globalTableData, v2Data, dataDisable, setDataToggle2 }, ref) => {

  const [overallCosts, setOverallCosts] = useState({
    siteSetup: 0, maintenance: 0, volume: 0, freeRecords: 0
  })

  const [checks, setChecks] = useState({
    feasibilityReport: true, skipAgreements: data.skip_agreements, skipInvoice: data.skip_invoice,
    customContracts: false
  })

  const [sitesData, setSitesData] = useState([])
  const [sitesUrlDetails, setSitesUrlDetails] = useState([])
  const [additionalCosts, setAdditionalCosts] = useState(0.0)
  const [deliveryDetailsModal, setDeliveryDetailsModal] = useState(false);
  const [addSiteModal, setAddSiteModal] = useState(false);
  const [feasibleStatus, setFeasibleStatus] = useState('feasible')
  const [newRows, setNewRows] = useState(['row1', 'row2', 'row3', 'row4'])
  const [urlData, setUrlData] = useState({
    'row1': { url: '', rss_urls_crawl_limit: '' },
    'row2': { url: '', rss_urls_crawl_limit: '' },
    'row3': { url: '', rss_urls_crawl_limit: '' },
    'row4': { url: '', rss_urls_crawl_limit: '' },
  })
  const [localData, setLocalData] = useState({})
  const [inpData, setInpData] = useState({})
  const [costData, setCostData] = useState({})
  const [errors, setErrors] = useState({})
  const [valid, setValid] = useState(true)
  const [version, setVersion] = useState('')
  const [comments, setComments] = useState('')
  const [additionalDetails, setAdditionalDetails] = useState({})
  
  let history = useHistory();
  const environment = localStorage.getItem("environment");
  let accessEnv = ['development', 'staging']

  useEffect(() => {
    let totalErrors = Object.values(errors).filter(x => x.url != '')
    let correctUrls = Object.values(urlData).filter(x => x.url != '').filter(y => isURL(y.url))
    if (correctUrls.length == 0 && sitesUrlDetails.length > 0) {
      Array.prototype.push.apply(correctUrls, sitesUrlDetails)
    }
    else if (totalErrors.length == 0 && correctUrls.length >= 1) {
      setValid(true)
      setSitesUrlDetails(correctUrls)
    }
    else {
      setValid(false)
    }
    setVersion(data.project_version)
    if (data.project_version === 'v3') {
      setAdditionalDetails({
        merge_files_fee: 0, image_downloads_fee: 0
      })
    }
    else if (data.project_version === 'v2') {
      setAdditionalDetails({
        merge_files_fee: 0, image_downloads_fee: 0,
        two_days_of_delivery_fee: 0, hosted_indexing_fee: 1,
      })
    }
  }, [errors, urlData])

  useEffect(() => {
    if (data.websites && data.websites.length > 0) {
      let websitesURLs = []
      data.websites.forEach(each => {
        websitesURLs.push(each.sample_url)
      })
      setSitesData(websitesURLs)
    }
    setLocalData(data)

  }, [dataToggle, data])

  useEffect(() => {

    if (dataDisable) {
      let keysObj = {}
      let keysObj2 = {}
      sitesData.forEach((each) => {
        let idObj = data.websites.filter(x => x.sample_url === each)[0]
        keysObj = {
          ...keysObj, [each]: !idObj?.feasibility_status,
        }
        keysObj2 = {
          ...keysObj2,
          [each]: {
            id: idObj.id,
            siteSetup: idObj.site_setup_fee, siteMaintenance: idObj.monthly_maintenance_fee,
            volumeCharges: idObj.volume_charges, freeRecords: idObj.number_of_free_records
          }
        }
      })
      setInpData(keysObj)
      setCostData(keysObj2)
    }
    else {
      let keysObj = {}
      let keysObj2 = {}
      sitesData.forEach((each) => {
        let idObj = data.websites.filter(x => x.sample_url === each)[0]
        keysObj = {
          ...keysObj, [each]: true
        }
        keysObj2 = {
          ...keysObj2,
          [each]: {
            id: idObj.id,
            siteSetup: 0, siteMaintenance: 0,
            volumeCharges: 0, freeRecords: 0
          }
        }
      })
      setInpData(keysObj)
      setCostData(keysObj2)
    }
  }, [sitesData])

  const handleOverall = (e, name) => {
    let tempObj = costData
    Object.keys(tempObj).forEach(each => {
      tempObj = {
        ...tempObj, [each]: {
          ...tempObj[each], [name]: e.target.value
        }
      }
    })
    setCostData(tempObj)
  }

  const toggleDeliveryDetailsModal = () => {
    setDeliveryDetailsModal(!deliveryDetailsModal);
  };
  
  const toggleAddSiteModal = () => {
    setAddSiteModal(!addSiteModal);
  };

  const updateDeliveryDetails = () => {
    if (localData.project_version === 'v3') {
      let tempRows = Object.values(localData.delivery_frequency_details)
        .filter(x => x !== "")
        .filter(x => x !== "none")
      if (localData.delivery_details.crawl_type === 'none') {
        toast.error('Please select crawl type')
      }
      else if ((localData.delivery_details.frequency !== 'daily') && tempRows.length === 0) {
        toast.error('Please select frequency method')
      }
      else {
        axios.put(`/api/v1/admins/feasibility_checks/${localData.id}/update_delivery_details`, {
          submit_requirements: {
            crawl_type: localData.delivery_details.crawl_type,
            delivery_format: localData.delivery_details.delivery_format,
            delivery_frequency: localData.delivery_details.frequency,
            delivery_method: localData.delivery_details.delivery_method,
            delivery_frequency_details: {
              ...localData.delivery_frequency_details
            }
          }
        }).then(res => {
          if (dataDisable) {
            history.push(`/admins/feasibility_checks/${localData.id}/edit`)
          }
          else {
            history.push(`/admins/feasibility_checks/${localData.id}`)
          }
          toast.success(res.data.message)
          setDeliveryDetailsModal(false)
        })
          .catch(err => {
            toast.error(err.response.data.message)
          })
      }
    }
    else {
      if (localData.delivery_details.delivery_method === 'api') {
        axios.put(`/api/v1/admins/feasibility_checks/${localData.id}/update_delivery_details`, {
          submit_requirements: {
            crawl_type: localData.delivery_details.crawl_type,
            delivery_format: localData.delivery_details.delivery_format,
            delivery_frequency: localData.delivery_details.frequency,
            delivery_method: localData.delivery_details.delivery_method,
            delivery_frequency_details: {
              ...localData.delivery_frequency_details
            }, delivery_method_details: {}
          }
        }).then(res => {
          setDataToggle(currentState => !currentState)
          toast.success(res.data.message)
          setDeliveryDetailsModal(false)
        })
          .catch(err => {
            toast.error(err.response.data.message)
          })
      }
      else if (localData.delivery_details.delivery_method === 's3') {
        try {
          if (localData.delivery_method_details &&
            localData.delivery_method_details.s3_bucket_name.length > 0 &&
            localData.delivery_method_details.s3_id.length > 0 &&
            localData.delivery_method_details.s3_key.length > 0 &&
            localData.delivery_method_details.s3_location.length > 0) {
            axios.put(`/api/v1/admins/feasibility_checks/${localData.id}/update_delivery_details`, {
              submit_requirements: {
                crawl_type: localData.delivery_details.crawl_type,
                delivery_format: localData.delivery_details.delivery_format,
                delivery_frequency: localData.delivery_details.frequency,
                delivery_method: localData.delivery_details.delivery_method,
                delivery_frequency_details: {
                  ...localData.delivery_frequency_details
                }, delivery_method_details: {
                  s3_bucket_name: localData.delivery_method_details.s3_bucket_name,
                  s3_id: localData.delivery_method_details.s3_id,
                  s3_key: localData.delivery_method_details.s3_key,
                  s3_location: localData.delivery_method_details.s3_location,
                }
              }
            }).then(res => {
              setDataToggle(currentState => !currentState)
              toast.success(res.data.message)
              setDeliveryDetailsModal(false)
            })
              .catch(err => {
                toast.error(err.response.data.message)
              })
          }
          else {
            toast.error('Please enter all bucket details')
          }
        } catch (error) {
          toast.error('Please enter all bucket details')
        }
      }
      else if (localData.delivery_details.delivery_method === 'dropbox') {
        try {
          if (localData.delivery_method_details &&
            localData.delivery_method_details.dropbox_access_token.length > 0) {
            axios.put(`/api/v1/admins/feasibility_checks/${localData.id}/update_delivery_details`, {
              submit_requirements: {
                crawl_type: localData.delivery_details.crawl_type,
                delivery_format: localData.delivery_details.delivery_format,
                delivery_frequency: localData.delivery_details.frequency,
                delivery_method: localData.delivery_details.delivery_method,
                delivery_frequency_details: {
                  ...localData.delivery_frequency_details
                }, delivery_method_details: {
                  dropbox_access_token: localData.delivery_method_details.dropbox_access_token,
                }
              }
            }).then(res => {
              setDataToggle(currentState => !currentState)
              toast.success(res.data.message)
              setDeliveryDetailsModal(false)
            })
              .catch(err => {
                toast.error(err.response.data.message)
              })
          }
          else {
            toast.error('Please enter all dropbox details')
          }
        } catch (error) {
          toast.error('Please enter all bucket details')
        }
      }
      else if (localData.delivery_details.delivery_method === 'box') {
        try {
          if (localData.delivery_method_details &&
            localData.delivery_method_details.box_id.length > 0 &&
            localData.delivery_method_details.box_secret.length > 0 &&
            localData.delivery_method_details.box_username.length > 0 &&
            localData.delivery_method_details.box_password.length > 0) {
            axios.put(`/api/v1/admins/feasibility_checks/${localData.id}/update_delivery_details`, {
              submit_requirements: {
                crawl_type: localData.delivery_details.crawl_type,
                delivery_format: localData.delivery_details.delivery_format,
                delivery_frequency: localData.delivery_details.frequency,
                delivery_method: localData.delivery_details.delivery_method,
                delivery_frequency_details: {
                  ...localData.delivery_frequency_details
                }, delivery_method_details: {
                  box_id: localData.delivery_method_details.box_id,
                  box_username: localData.delivery_method_details.box_username,
                  box_secret: localData.delivery_method_details.box_secret,
                  box_password: localData.delivery_method_details.box_password,
                }
              }
            }).then(res => {
              setDataToggle(currentState => !currentState)
              toast.success(res.data.message)
              setDeliveryDetailsModal(false)
            })
              .catch(err => {
                toast.error(err.response.data.message)
                setDeliveryDetailsModal(true)
              })
          }
          else {
            toast.error('Please enter all box details')
          }
        } catch (error) {
          toast.error('Please enter all box details')
        }
      }
      else if (localData.delivery_details.delivery_method === 'ftp') {
        try {
          if (localData.delivery_method_details &&
            localData.delivery_method_details.ftp_server.length > 0 &&
            localData.delivery_method_details.ftp_username.length > 0 &&
            localData.delivery_method_details.ftp_password.length > 0) {
            axios.put(`/api/v1/admins/feasibility_checks/${localData.id}/update_delivery_details`, {
              submit_requirements: {
                crawl_type: localData.delivery_details.crawl_type,
                delivery_format: localData.delivery_details.delivery_format,
                delivery_frequency: localData.delivery_details.frequency,
                delivery_method: localData.delivery_details.delivery_method,
                delivery_frequency_details: {
                  ...localData.delivery_frequency_details
                }, delivery_method_details: {
                  ftp_server: localData.delivery_method_details.ftp_server,
                  ftp_username: localData.delivery_method_details.ftp_username,
                  ftp_password: localData.delivery_method_details.ftp_password,
                }
              }
            }).then(res => {
              setDataToggle(currentState => !currentState)
              toast.success(res.data.message)
              setDeliveryDetailsModal(false)
            })
              .catch(err => {
                toast.error(err.response.data.message)
                setDeliveryDetailsModal(true)
              })
          }
          else {
            toast.error('Please enter all ftp details')
          }
        } catch (error) {
          toast.error('Please enter all ftp details')
        }
      }
    }
  }

  const submitNewSiteRequirements = () => {
    if(addSiteModal){
      stepValidate().then(res => {
        axios.post(`/api/v1/organizations/${localData.organization_identifier}/sites`, {
          "submit_requirements": {
            sitegroup_id: localData.sitegroup_id,
            site_add_from: "feasibility_check",
            site_details: sitesUrlDetails
          }
          
        }).then(res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            setAddSiteModal(false)
          }
        }).catch(err => {
          toast.error(err.response.data.message)
        }).finally(() => setDataToggle(curr => !curr))
      }).catch(error => {
        toast.error(error?.response?.data?.message)
      })}
    };

  const feasibleCheck = async (key, val) => {
    let promise = new Promise((resolve, reject) => {
      axios.post('/api/v1/sites/check_feasibility', {
        "sites": { "sample_page_url": val }
      }).then(res => {
        const data = res.data
        if (data.status != "success") {
          setErrors({
            ...errors, [key]: { url: 'This URL is not feasible' }
          })
          reject(false)
        }
        else {
          resolve(true)
        }
      }).catch(res => {
        setErrors({
          ...errors, [key]: { url: 'This URL is not feasible' }
        })
        reject(false)
      })
    });

    let result = await promise;
    return result
  }

  const validateUrl = async (key, value) => {
    let promise = new Promise((resolve, reject) => {
      let urls = Object.values(urlData).filter(x => x.url != '').map(x => x.url)
      let sitenames = urls.map(x => getSiteName(x))
      let sitename = getSiteName(value)
      let result = find_duplicate_in_array(sitenames);
      if (result.length > 0 && result.includes(sitename) && value != "") {
        setErrors({
          ...errors, [key]: { url: "Same sites can't be added again, Please enter a different site." }
        })
        reject(false)
      }
      else {
        setErrors({
          ...errors, [key]: { url: '' }
        })
        resolve(true)
      }
    });

    let result = await promise;
    return result
  }

  const stepValidate = async () => {
    let promise = new Promise((resolve, reject) => {
      let feasRows = Object.keys(urlData).filter(x => urlData[x].url != "").filter(x => isURL(urlData[x].url))
      if (feasRows.length === 0) {
        toast.error('please enter atleast one feasible url')
      }
      let wrongUrls = 0
      feasRows.forEach((x, index) => {
        feasibleCheck(x, urlData[x].url)
          .then(res => {
            setValid(res)
          })
          .catch(res => {
            wrongUrls += 1
          })
          .finally(() => {
            if (index == feasRows.length - 1 && wrongUrls == 0) {
              let tempErrs = Object.values(errors).filter(x => x.url != '')
              let stepPass = (feasRows.length >= 1 && tempErrs.length == 0) ? true : false
              if (stepPass) {
                resolve(true)
              } else {
                reject(false)
              }
            }
          })
        validateUrl(x, urlData[x].url)
          .then(res => {
            console.log("res", res);
          })
          .catch(res => {
            wrongUrls += 1
          })
          .finally(() => {
            if (index == feasRows.length - 1 && wrongUrls == 0) {
              let tempErrs = Object.values(errors).filter(x => x.url != '')
              let stepPass = (feasRows.length >= 1 && tempErrs.length == 0) ? true : false
              if (valid || stepPass) {
                resolve(true)
              } else {
                reject(false)
              }
            }
          })
      })
    });

    let result = await promise;
    return result
  }

  function isURL(str) {
    if (str == undefined) {
      return true
    }
    var url = new RegExp(/^((www\.)|(http(s*)))/i);
    return str.length < 2083 && url.test(str)
  }

  function getSiteName(str) {
    var url_arr = str.split("://")
    if (url_arr.length > 1) {
      url_arr.shift()
    }
    var sitename;
    sitename = url_arr.join("").split("/")[0].split(".").join("_")
    sitename = sitename.split("www_").join("")
    sitename = sitename.split("-").join("_")
    return sitename
  }

  function find_duplicate_in_array(arra1) {
    var object = {};
    var result = [];

    arra1.forEach(function (item) {
      if (!object[item])
        object[item] = 0;
      object[item] += 1;
    })

    for (var prop in object) {
      if (object[prop] >= 2) {
        result.push(prop);
      }
    }
    return result;
  }

  const handleURL = (value, element) => {
    setUrlData({
      ...urlData, [element]: { ...urlData[element], url: value }
    })
    let urls = Object.values(urlData).filter(x => x.url != '').map(x => x.url)
    let rss_urls_crawl_limit = urlData[element].rss_urls_crawl_limit
    if (urls.includes(value) && value != "") {
      setErrors({
        ...errors, [element]: { url: 'Entered URL is already present' }
      })
    }
    else if ((rss_urls_crawl_limit.toString().length > 0) && value.length == 0) {
      setErrors({
        ...errors, [element]: { url: 'This field is required if rss urls crawl limit has value!' }
      })
    }
    else if (rss_urls_crawl_limit.length > 0 && !/^\d+$/.test(rss_urls_crawl_limit)) {
      setErrors({
        ...errors, [element]: { rss_urls_crawl_limit: "This field should be integer!" }
      })
    }
    else if (isURL(value) || value == '') {
      setErrors({
        ...errors, [element]: { url: '' }
      })
    }
    else {
      setErrors({
        ...errors, [element]: { url: 'Please enter a valid URL' }
      })
    }
  }

  const handleRssUrlsCrawlLimit = (value, element) => {
    setUrlData({
      ...urlData, [element]: { ...urlData[element], rss_urls_crawl_limit: value }
    })
    if ((urlData[element].url.length == 0) && value.length > 0) {
      setErrors({
        ...errors, [element]: { url: 'This field is required if rss urls crawl limit has value!' }
      })
    }
    else if (value.length > 0 && !/^\d+$/.test(value)) {
      setErrors({
        ...errors, [element]: { rss_urls_crawl_limit: "This field should be integer!" }
      })
    }
    else {
      setErrors({
        ...errors, [element]: { url: '', rss_urls_crawl_limit: '' }
      })
    }
  }

  const handleRowsUpdate = () => {
    setNewRows([...newRows, `row${newRows.length + 1}`])
    let new_name = 'row' + (newRows.length + 1)
    setUrlData({ ...urlData, [new_name]: { url: '', rss_urls_crawl_limit: '' } })
  }


  useImperativeHandle(ref, () => ({
    isValidated() {
      return true
    }
  }));

  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

  const handleChangeStatus = (uploadData, status) => {
    const { meta, file } = uploadData
    if (status === "done") {

      var fileReader = new FileReader();
      fileReader.onload = function (event) {
        var workbook = XLSX.read(event.target.result, {
          type: "binary"
        });
        workbook.SheetNames.forEach(sheet => {
          let rowObject = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheet]
          );
          let headerCondition;
          if (accessEnv.includes(environment)) {
            headerCondition = ("page_url" in rowObject[0] && "rss_urls_crawl_limit" in rowObject[0])
          }
          else {
            headerCondition = ("page_url" in rowObject[0])
          }
          if (rowObject.length === 0) {
            toast.error("Uploaded file is empty")
          }
          else if (headerCondition) {
            let tempObj = {
              'row1': { url: '', rss_urls_crawl_limit: '' },
              'row2': { url: '', rss_urls_crawl_limit: '' },
              'row3': { url: '', rss_urls_crawl_limit: '' },
              'row4': { url: '', rss_urls_crawl_limit: '' },
            }

            let newRowsArr = []

            rowObject.forEach((each2, index) => {
              let rss_urls_crawl_limit = each2?.rss_urls_crawl_limit ? each2.rss_urls_crawl_limit : ""
              tempObj[`row${index + 1}`] = { url: each2.page_url, rss_urls_crawl_limit: rss_urls_crawl_limit }
              newRowsArr.push(`row${index + 1}`)
            })
            setNewRows(newRowsArr)
            setUrlData(tempObj)
          }
          else {
            toast.error("Please keep column headers as mentioned in sample excel file")
          }
        });
      };
      fileReader.readAsBinaryString(file);
    }
    else if (status === "removed") {

      setNewRows(['row1', 'row2', 'row3', 'row4'])
      setUrlData(
        {
          'row1': { url: '', rss_urls_crawl_limit: '' },
          'row2': { url: '', rss_urls_crawl_limit: '' },
          'row3': { url: '', rss_urls_crawl_limit: '' },
          'row4': { url: '', rss_urls_crawl_limit: '' },
        }
      )
    }
    else if (status === 'rejected_file_type') {
      toast.error("ERROR: Unsupported File Format!" + "\n" + "Supported file formats are .xls, .xlsx, .xlsb, .xlsm, .ods, .ots, .uos");
    }
    
  }

  const handleSubmit = (files, allFiles) => {
    allFiles.forEach(f => f.remove())
    toast.success("Dropzone successfully submitted !");
  }

  const HandleCB = (e, isOverall) => {
    if (isOverall) {
      let tempData = inpData
      Object.keys(tempData).forEach(each => {
        tempData = {
          ...tempData, [each]: !e.target.checked
        }
      })
      setInpData(tempData)
    }
    else {
      let tempData = inpData
      Object.keys(tempData).forEach(each => {
        if (e.target.name == each) {
          tempData = {
            ...tempData, [each]: !e.target.checked
          }
        }
      })
      setInpData(tempData)
    }
  }

  const discardOrganization = () => {
    axios.post(`/api/v1/admins/feasibility_checks/${localData.id}/discard_requirement`, {
      feasibility_report_id: localData.id
    }).then(res => {
      toast.success(res.data.message)
      history.push(`/admins/feasibility_checks/`)
    }).catch(err => {
      toast.error(err.response.data.message)
    })
  }

  const generateFeasibilityReport = async () => {
    let sitesDetails = []
    let schemaDetails = []
    let originalUrls = []
    let tempObj = {}

    Object.keys(additionalDetails).forEach(each => {
      if (additionalDetails[each] !== 0) {
        tempObj[each] = parseFloat(additionalDetails[each]) ? parseFloat(additionalDetails[each]) : 0
      }
    })
    await Object.keys(costData).forEach(eachUrl => {
      sitesDetails.push({
        site_id: costData[eachUrl].id,
        feasibility_status: inpData[eachUrl] ? 'not_feasible' : 'feasible',
        site_setup_fee: costData[eachUrl].siteSetup,
        monthly_maintenance_fee: costData[eachUrl].siteMaintenance,
        volume_charges: costData[eachUrl].volumeCharges,
        number_of_free_records: costData[eachUrl].freeRecords
      })
    })

    if (data.project_version === 'v2') {
      const resData = {
        org_feasibility_check: {
          sitegroup_id: data.sitegroup_id, feasibility_report_id: data.id,
          comments: comments, skip_agreements: checks.skipAgreements,
          skip_invoice: checks.skipInvoice, use_custom_contracts: false,
          overall_feasibility: feasibleStatus, project_version: data.project_version,
          organization_name: data.organization_name,
          send_feasibility_report: true, any_additional_cost: additionalCosts,
          additional_information: v2Data.additional_information ? v2Data.additional_information : data.additional_information,
          fields_to_extract: v2Data.fields_to_extract ? v2Data.fields_to_extract : data.fields_to_extract,
          sites_details: sitesDetails,
          additional_details_fee: tempObj
        }
      }
      if (dataDisable) {
        axios.put(`/api/v2/admins/feasibility_checks/${localData.id}`, resData)
          .then((res) => {
            toast.success(res.data.message)
            setTimeout(() => {
              setDataToggle2(curr => !curr)
              history.push(`/admins/feasibility_checks/${localData.id}`)
            }, 2000);
          })
          .catch(err => {
            toast.error(err.response.data.message)
          })
      }
      else {
        axios.post('/api/v2/admins/feasibility_checks', resData)
          .then((res) => {
            toast.success(res.data.message)
            setTimeout(() => {
              setDataToggle2(curr => !curr)
            }, 2000);
          })
          .catch(err => {
            toast.error(err.response.data.message)
          })
      }
    }
    else if (data.project_version === 'v3') {
      await data.schema_fields_details.forEach(each => {
        originalUrls.push(each.id)
      })
      await Object.keys(globalTableData).forEach(eachRow => {
        if (globalTableData[eachRow] !== null) {
          schemaDetails.push({
            id: globalTableData[eachRow].id,
            field_name: globalTableData[eachRow].field_name,
            data_type: globalTableData[eachRow].data_type,
            is_mandatory: globalTableData[eachRow].is_mandatory,
            default_value: globalTableData[eachRow].default_value,
            sample_value: globalTableData[eachRow].sample_value,
            comments: globalTableData[eachRow].comments,
          })
          originalUrls = originalUrls.filter(each => each !== globalTableData[eachRow].id)
        }
      })
      const resData = {
        org_feasibility_check: {
          sitegroup_id: data.sitegroup_id, feasibility_report_id: data.id,
          comments: comments, skip_agreements: checks.skipAgreements,
          skip_invoice: checks.skipInvoice, use_custom_contracts: false,
          overall_feasibility: feasibleStatus, project_version: data.project_version,
          organization_name: data.organization_name,
          send_feasibility_report: true, any_additional_cost: additionalCosts,
          additional_information: v2Data.additional_information ? v2Data.additional_information : data.additional_information,
          schema_fields_details: schemaDetails,
          sites_details: sitesDetails, remove_schema_fields: { ids: originalUrls },
          additional_details_fee: tempObj
        }
      }
      if (dataDisable) {
        axios.put(`/api/v2/admins/feasibility_checks/${localData.id}`, resData)
          .then((res) => {
            toast.success(res.data.message)
            setTimeout(() => {
              setDataToggle2(curr => !curr)
              history.push(`/admins/feasibility_checks/${localData.id}`)
            }, 2000);
          })
          .catch(err => {
            toast.error(err.response.data.message)
          })
      } else {
        axios.post('/api/v2/admins/feasibility_checks', resData)
          .then((res) => {
            toast.success(res.data.message)
            setTimeout(() => {
              setDataToggle2(curr => !curr)
            }, 2000);
          })
          .catch(err => {
            toast.error(err.response.data.message)
          })
      }
    }
  }


  return (
    <div className='p-3'>
      <div className='mt-3 feasibility__site-details'>
        <Table responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Sample URL</th>
              <th className=''>
                <input type="checkbox" className='form-check-input ms-2'
                  checked={Object.values(inpData).filter(x => x == true).length === 0}
                  name='feasibility' onChange={(e) => HandleCB(e, true)} />
              </th>
              <th>Site setup</th>
              <th>Site Maintenance</th>
              <th>Volume charges</th>
              <th>No.of free records</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(inpData).map((each, index) => {
              return (
                <tr key={index}>
                  <td scope='row'>
                    {index + 1}
                  </td>
                  <td>
                    <a href={each}>{each}</a>
                  </td>
                  <td>
                    {dataDisable ?
                      <input type="checkbox" name={each} className='form-check-input'
                        onChange={(e) => HandleCB(e, false)} checked={!inpData[each]} />
                      : <input type="checkbox" name={each} className='form-check-input'
                        onChange={(e) => HandleCB(e, false)} checked={!inpData[each]} />}
                  </td>
                  <td>
                    <input type="text" name={each} className='form-control'
                      disabled={inpData[each]} value={inpData[each] ? 0 : costData[each]?.siteSetup}
                      onChange={(e) => setCostData({
                        ...costData,
                        [each]: { ...costData[each], siteSetup: e.target.value }
                      })} />
                  </td>
                  <td>
                    <input type="text" name={each} className='form-control'
                      disabled={inpData[each]} value={inpData[each] ? 0 : costData[each]?.siteMaintenance}
                      onChange={(e) => setCostData({
                        ...costData,
                        [each]: { ...costData[each], siteMaintenance: e.target.value }
                      })} />
                  </td>
                  <td>
                    <input type="text" name={each} className='form-control'
                      disabled={inpData[each]} value={inpData[each] ? 0 : costData[each]?.volumeCharges}
                      onChange={(e) => setCostData({
                        ...costData,
                        [each]: { ...costData[each], volumeCharges: e.target.value }
                      })} />
                  </td>
                  <td>
                    <input type="text" name={each} className='form-control'
                      disabled={inpData[each]} value={inpData[each] ? 0 : costData[each]?.freeRecords}
                      onChange={(e) => setCostData({
                        ...costData,
                        [each]: { ...costData[each], freeRecords: e.target.value }
                      })} />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>

        {version == 'v3' &&
          (<AddNewSite
            urlData={urlData}
            dataDisable ={dataDisable}
            accessEnv = {accessEnv}
            environment={environment}
            localData={localData}
            newRows={newRows}
            submitNewSiteRequirements={submitNewSiteRequirements}
            toggle={toggleAddSiteModal}
            modalOpen = {addSiteModal}
            handleChangeStatus={handleChangeStatus}
            getUploadParams={getUploadParams}
            handleRowsUpdate={handleRowsUpdate}
            handleRssUrlsCrawlLimit={handleRssUrlsCrawlLimit}
            handleURL={handleURL}
            handleSubmit={handleSubmit}
            errors={errors}
            />)
        }
      </div>
      <div className='my-5'>
        <Row className='my-2'>
          <Col lg={6}>
            <div className="d-flex justify-content-between align-items-center">
              <h6>Delivery Details</h6>
              <EditDeliveryDetails
                version={version}
                localData={localData}
                toggle={toggleDeliveryDetailsModal}
                modalOpen={deliveryDetailsModal}
                setLocalData={setLocalData}
                updateDeliveryDetails={updateDeliveryDetails}
                dataDisable={dataDisable}
                setDataToggle={setDataToggle}
              />
            </div>

            {/* Table */}
            <Table>
              <tbody>
                <tr>
                  <td> Crawl Type </td>
                  <td className='fw-bold'>
                    {(localData.delivery_details && localData.delivery_details.crawl_type == 'incremental_recrawl') && 'Incremental Crawl'}
                    {(localData.delivery_details && localData.delivery_details.crawl_type == 'fullcrawl') && 'Full crawl'}
                    {(localData.delivery_details && localData.delivery_details.crawl_type == 'none') && 'N/A'}
                  </td>
                </tr>
                <tr>
                  <td> Data format </td>
                  <td className='fw-bold'>
                    {localData.delivery_details && localData?.delivery_details?.delivery_format?.toUpperCase()}
                  </td>
                </tr>
                <tr>
                  <td> Frequency </td>
                  <td className='fw-bold'>
                    {localData.delivery_details &&
                      (localData.delivery_details.frequency === 'monthly'
                        ? `Every month at ${localData.delivery_frequency_details.date && localData.delivery_frequency_details.date.substring(3, 5)}`
                        : localData.delivery_details.frequency === 'weekly'
                          ? `Every week on ${localData.delivery_frequency_details.days && localData.delivery_frequency_details.days}` :
                          localData.delivery_details.frequency === 'daily' ? 'Daily'
                            : `Other: ${localData.delivery_frequency_details.other_details && localData.delivery_frequency_details.other_details}`)}
                  </td>
                </tr>
                <tr>
                  <td> Data Delivery </td>
                  <td className='fw-bold'>
                    {localData.delivery_details && localData?.delivery_details?.delivery_method?.toUpperCase()}
                  </td>
                </tr>
              </tbody>
            </Table>
            <Row className='mt-3'>
              <Col lg={4}>
                <h6>Feasibility</h6>
              </Col>
              <Col lg={8}>
                <select className='form-control show-arrow' defaultValue={feasibleStatus}
                  onChange={(e) => setFeasibleStatus(e.target.value)}>
                  <option value='feasible'>Feasible</option>
                  <option value='partially_feasible'>Partially Feasible</option>
                  <option value='not_feasible'>Not Feasible</option>
                </select>
              </Col>
            </Row>
          </Col>
          <Col lg={6}>
            <h6>Additional Details</h6>
            <Table>
              {version == 'v2' &&
                <tbody>
                  {dataDisable ?
                    <>
                      {data?.additional_details.map(each => (
                        <tr key={each.key}>
                          <td>{each?.title}</td>
                          <td>{each?.status}</td>
                          <td><input className='form-control' value={each?.fee} disabled /></td>
                        </tr>
                      ))}
                    </>
                    : <>
                      <tr>
                        <td>Image Downloads</td>
                        <td>
                          {data?.additional_details?.image_downloads != '0' ? 'Enabled' : 'Disabled'}
                        </td>
                        <td>
                          <input className='form-control'
                            value={data?.additional_details?.image_downloads != '0' ? additionalDetails?.image_downloads_fee : ''}
                            onChange={(e) => setAdditionalDetails({ ...additionalDetails, image_downloads_fee: e.target.value })}
                            disabled={data?.additional_details?.image_downloads == '0' || dataDisable} />
                        </td>
                      </tr>
                      <tr>
                        <td>Two days of delivery</td>
                        <td>
                          {data?.additional_details?.two_days_of_delivery != '0' ? 'Enabled' : 'Disabled'}
                        </td>
                        <td>
                          <input className='form-control'
                            value={data?.additional_details?.two_days_of_delivery != '0' ? additionalDetails?.two_days_of_delivery_fee : ''}
                            onChange={(e) => setAdditionalDetails({ ...additionalDetails, two_days_of_delivery_fee: e.target.value })}
                            disabled={data?.additional_details?.two_days_of_delivery == '0' || dataDisable} />
                        </td>
                      </tr>
                      <tr>
                        <td>Hosted Indexing</td>
                        <td>
                          {data?.additional_details?.hosted_indexing != '0' ? 'Enabled' : 'Disabled'}
                        </td>
                        <td>
                          <input className='form-control'
                            value={data?.additional_details?.hosted_indexing != '0' ? additionalDetails.hosted_indexing_fee : ''}
                            onChange={(e) => setAdditionalDetails({ ...additionalDetails, hosted_indexing_fee: e.target.value })}
                            disabled={data?.additional_details?.hosted_indexing == '0' || dataDisable} />
                        </td>
                      </tr>
                      <tr>
                        <td>Merge files</td>
                        <td>
                          {data?.additional_details?.merge_files != '0' ? 'Enabled' : 'Disabled'}
                        </td>
                        <td>
                          <input className='form-control'
                            value={data?.additional_details?.merge_files != '0' ? additionalDetails.merge_files_fee : ''}
                            onChange={(e) => setAdditionalDetails({ ...additionalDetails, merge_files_fee: e.target.value })}
                            disabled={data?.additional_details?.merge_files == '0'} />
                        </td>
                      </tr>
                    </>}
                </tbody>}
              {version == 'v3' &&
                <tbody>
                  {dataDisable ?
                    <>
                      {data?.additional_details.map(each => (
                        <tr key={each.key}>
                          <td>{each?.title}</td>
                          <td>{each?.status}</td>
                          <td><input className='form-control' value={each?.fee} disabled /></td>
                        </tr>
                      ))}
                    </>
                    : <>
                      <tr>
                        <td>Enable merge</td>
                        <td>
                          {data?.additional_details?.merge_files != '0' ? 'Enabled' : 'Disabled'}
                        </td>
                        <td>
                          <input className='form-control'
                            value={data?.additional_details?.merge_files != '0' ? additionalDetails.merge_files : ''}
                            onChange={(e) => setAdditionalDetails({ ...additionalDetails, merge_files_fee: e.target.value })}
                            disabled={data?.additional_details?.merge_files == '0'} />
                        </td>
                      </tr>
                      <tr>
                        <td>Enable Image / File Downloads</td>
                        <td>
                          {data?.additional_details?.image_downloads != '0' ? 'Enabled' : 'Disabled'}
                        </td>
                        <td>
                          <input className='form-control'
                            value={data?.additional_details?.image_downloads != '0' ? additionalDetails.image_downloads_fee : ''}
                            onChange={(e) => setAdditionalDetails({ ...additionalDetails, image_downloads_fee: e.target.value })}
                            disabled={data?.additional_details?.image_downloads == '0'} />
                        </td>
                      </tr>
                    </>}
                </tbody>}
            </Table>
          </Col>
        </Row>
        <div className='mb-3 mt-5'>
          <div className='d-flex mb-2'> <h6 className='me-1'> Signup Url: </h6> {data?.sign_up_url ? data.sign_up_url : "NA"} </div>
          <textarea rows='3' className='form-control' placeholder='Enter the comments to be mailed'
            value={comments} onChange={(e) => setComments(e.target.value)} />
        </div>
        <Row className='my-2'>
          <Col lg={4} className='p-2'>
            <p className='m-0'> Site Setup Cost </p>
            <input className='form-control' name="site_setup_fee" value={dataDisable ? data.site_setup_fee : overallCosts.siteSetup}
              onChange={e => {
                setOverallCosts({
                  ...overallCosts,
                  siteSetup: e.target.value
                })
                handleOverall(e, 'siteSetup')
              }} disabled={dataDisable} />
          </Col>
          <Col lg={4} className='p-2'>
            <p className='m-0'> Maintenance Cost </p>
            <input className='form-control' name="maintenance_cost" value={dataDisable ? data.monthly_maintenance_fee : overallCosts.maintenance}
              onChange={e => {
                setOverallCosts({ ...overallCosts, maintenance: e.target.value })
                handleOverall(e, 'siteMaintenance')
              }} disabled={dataDisable} />
          </Col>
          <Col lg={4} className='p-2'>
            <p className='m-0'> Volume costs </p>
            <input className='form-control' name="volume_costs" value={dataDisable ? data.volume_charges : overallCosts.volume}
              onChange={e => {
                setOverallCosts({ ...overallCosts, volume: e.target.value })
                handleOverall(e, 'volumeCharges')
              }} disabled={dataDisable} />
          </Col>
          <Col lg={4} className='p-2'>
            <p className='m-0'> Number of free records </p>
            <input className='form-control' name="no_of_free_records" value={dataDisable ? data.number_of_free_records : overallCosts.freeRecords}
              onChange={e => {
                setOverallCosts({ ...overallCosts, freeRecords: e.target.value })
                handleOverall(e, 'freeRecords')
              }
              } disabled={dataDisable} />
          </Col>
          <Col lg={4} className='p-2'>
            <p className='m-0'> Any Additional cost </p>
            <input className='form-control' name="any_additional_cost" value={dataDisable ? data.any_additional_cost : additionalCosts}
              onChange={e => setAdditionalCosts(e.target.value)} disabled={dataDisable} />
          </Col>
        </Row>
        <Row className='mt-4 mb-3'>
          <Col lg={3}>
            <Input type="checkbox" checked={checks.feasibilityReport}
              onChange={(e) => setChecks({ ...checks, feasibilityReport: !checks.feasibilityReport })} />
            {' '} Send Feasibility Report
          </Col>
          <Col lg={3}>
            <Input type="checkbox" checked={checks.skipAgreements}
              onChange={(e) => setChecks({ ...checks, skipAgreements: !checks.skipAgreements })} />
            {' '} Skip Agreements
          </Col>
          <Col lg={3}>
            <Input type="checkbox" checked={checks.skipInvoice}
              onChange={(e) => setChecks({ ...checks, skipInvoice: !checks.skipInvoice })} />
            {' '} Skip Invoice
          </Col>
          <Col lg={3}>
            <Input type="checkbox" checked={dataDisable ? data.use_custom_contracts : checks.customContracts}
              onChange={(e) => setChecks({ ...checks, customContracts: !checks.customContracts })} />
            {' '} Use custom contracts
          </Col>
        </Row>
        {checks.customContracts && <Row className='my-2'>
          <Col lg={2}>
            <h6 className='fw-bold my-0'>Upload MSA*</h6>
          </Col>
          <Col lg={3}>
            File upload
          </Col>
          <Col lg={2}></Col>
          <Col lg={2}>
            <h6 className='fw-bold my-0'>Upload SoW*</h6>
          </Col>
          <Col lg={3}>
            File upload
          </Col>
        </Row>}
      </div>
      <div className='d-flex float-end mt-3'>
        <button className='btn btn-success mx-4' onClick={() => generateFeasibilityReport()}>
          Generate feasibility report
        </button>
        <button className='btn btn-danger' onClick={() => discardOrganization()}>
          Discard
        </button>
      </div>
    </div>
  );
});

export { SiteDetails as default }
