import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Container, Input } from "reactstrap";
import Breadcrumbs from "../../global/Breadcrumb";
import axios from 'axios'
import { toast } from 'react-toastify';
import Select from "react-select";
import Loader from 'react-loader-spinner';
import EditOrgDetailsForm from './EditOrgDetailsForm';
import BulkUpdateForm from './BulkUpdateForm';
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";

export default function OrgMapping() {
  const [selectedOrgs, setSelectedOrgs] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [allOragnizations, setAllOrganizations] = useState([]);
  const [orgNames, setOrgNames] = useState([]);
  const [primaryContacts, setPrimaryContacts] = useState([]);
  const [secondaryContacts, setSecondaryContacts] = useState([]);
  const [pmContacts, setPmContacts] = useState([]);
  const [bizContacts, setBizContacts] = useState([]);
  const [qaContacts, setQaContacts] = useState([]);
  const [orgStatuses, setOrgStatuses] = useState([]);
  const [invoiceGenerations, setInvoiceGenerations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    organization_id: "",
    primary_contact: "",
    secondary_contact: "",
    business_contact: "",
    pm_contact: "",
    qa_contact: "",
    organization_status: "",
    invoice_generation: ""
  });
  const [page, setPage] = useState(1);
  const [pageEntries, setPageEntries] = useState(10);
  const [toggle, setToggle] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    setLoading(true)
    axios.get(`/api/v1/admins/org_mapping`)
      .then(res => {
        setAllOrganizations(res.data?.orgs)
        setOrgNames(res.data?.org_dropdown_list)
        setPrimaryContacts(res.data?.pc_contacts)
        setSecondaryContacts(res.data?.pc_contacts)
        setPmContacts(res.data?.pm_contacts)
        setBizContacts(res.data?.biz_contacts)
        setQaContacts(res.data?.qa_contacts)
        setOrgStatuses(res.data?.organization_statuses)
        setInvoiceGenerations(res.data?.invoice_generations)
        setLoading(false)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
        setLoading(false)
      })
  }, []);

  const handleCheckboxChange = ({selectedRows}) => {
    setSelectedOrgs(selectedRows.map(item => item.org_id))
  };

  const handleSearch = () => {
    setPage(1)
    setLoading(true)
    axios.get(`/api/v1/admins/org_mapping/search?filter[organization_id]=${filters.organization_id}&filter[primary_contact]=${filters.primary_contact}&filter[secondary_contact]=${filters.secondary_contact}&filter[biz_contact]=${filters.business_contact}&filter[pm_contact]=${filters.pm_contact}&filter[organization_status]=${filters.organization_status}&filter[qa_contact]=${filters.qa_contact}&filter[invoice_generation]=${filters.invoice_generation}&filter[last_payment_start_date]=${startDate}&filter[last_payment_end_date]=${endDate}&page=${1}`)
      .then(res => {
        setAllOrganizations(res.data?.orgs)
        setLoading(false)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
        setLoading(false)
      })
  }

  const orgColumns = [
    { 
      name: 'No.', 
      selector: "",
      center: true,
      cell: (row, index) => (index + 1)
    },
    { 
      name: 'Organization', 
      selector: '', 
      sortable: true, 
      center:true,
      cell: row => (
        row.org_name?row.org_name:"N/A"
      )
    },
    { 
      name: 'Primary Contact', 
      selector: '', 
      sortable: true, 
      center:true,
      cell: row => (
        row.primary_contact?row.primary_contact:"N/A"
      )
    },
    { 
      name: 'Secondary Contact', 
      selector: '', 
      sortable: true, 
      center:true,
      cell: row => (
        row.secondary_contact?row.secondary_contact:"N/A"
      )
    },
    { 
      name: 'Business Contact',
      selector: '', 
      sortable: true, 
      center:true,
      cell: row => (
        row.biz_contact?row.biz_contact:"N/A"
      )
    },
    { 
      name: 'PM Contact', 
      selector: '', 
      sortable: true, 
      center:true,
      cell: row => (
        row.pm_contact?row.pm_contact:"N/A"
      )
    },
    { 
      name: 'QA Contact', 
      selector: '', 
      sortable: true, 
      center:true,
      cell: row => (
        row.qa_contact?row.qa_contact:"N/A"
      )
    },
    { 
      name: 'Organization status', 
      selector: '', 
      sortable: true, 
      center:true,
      cell: row => (
        row.organization_status?row.organization_status:"N/A"
      )
    },
    { 
      name: 'Invoice Generation Platform', 
      selector: '', 
      sortable: true, 
      center:true,
      cell: row => (
        row.invoice_generation?row.invoice_generation:"N/A"
      )
    },
    { 
      name: 'Last Payment At', 
      selector: '', 
      center: true, 
      center:true,
      cell: row => (
        row.custom_project_last_payment_at?row.custom_project_last_payment_at:"N/A"
      )
    },
    { 
      name: 'Creation Date', 
      selector: '', 
      sortable: true, 
      center:true,
      cell: row => (
        row.created_at?row.created_at:"N/A"
      )
    },
    { 
      name: 'Edit', 
      selector: 'edit', 
      center: true, 
      sortable: true, 
      cell: row => (
        <EditOrgDetailsForm data={row} key={row.org_id} setToggle={setToggle}
          primaryContacts={primaryContacts} secondaryContacts={secondaryContacts}
          bizContacts={bizContacts} pmContacts={pmContacts} qaContacts={qaContacts}
          orgStatuses={orgStatuses} invoiceGenerations={invoiceGenerations}
        />
      )
    },

  ];

  const handleDownload = () => {
    axios({
      url: `/api/v1/admins/org_mapping/download_csv_dump`,
      method: 'GET',
      responseType: 'blob'
    }).then((res) => {
      const url_to_download = window.URL.createObjectURL(new Blob([res.data]));
      const a_tag = document.createElement('a');
      a_tag.href = url_to_download;
      a_tag.setAttribute('download', `organization_contacts_dump.csv`);
      document.body.appendChild(a_tag);
      a_tag.click();
    }).catch(err => {
      toast.error(err.response.data.message)
    })
  }

  return (

    <Container fluid>
      <Breadcrumbs title="New Requirements" parent="Organization Mapping" />
      <div className='d-flex justify-content-between mb-3'>
        <a href="#" className=''></a>
        <button className="btn btn-primary" onClick={handleDownload}>Download as CSV</button>
      </div>
      <Card>
        <CardBody>
          <div className="d-flex flex-wrap floex-row">
            <div key="organization-name" className="w-25 p-2" >
              <label className='form-label fw-bold'>Organization Name</label>
              <Select options={orgNames} onChange={res => setFilters({ ...filters, organization_id: res.value })} />
            </div>
            <div key="primary-contact" className="w-25 p-2" >
              <label className='form-label fw-bold'>Primary Contact</label>
              <Select options={primaryContacts} onChange={res => setFilters({ ...filters, primary_contact: res.value })} />
            </div>
            <div key="secondary-contact" className="w-25 p-2" >
              <label className='form-label fw-bold'>Secondary Contact</label>
              <Select options={secondaryContacts} onChange={res => setFilters({ ...filters, secondary_contact: res.value })} />
            </div>
            <div key="business-contact" className="w-25 p-2" >
              <label className='form-label fw-bold'>Business Contact</label>
              <Select options={bizContacts} onChange={res => setFilters({ ...filters, business_contact: res.value })} />
            </div>
            <div key="pm-contact" className="w-25 p-2" >
              <label className='form-label fw-bold'>PM Contact</label>
              <Select options={pmContacts} onChange={res => setFilters({ ...filters, pm_contact: res.value })} />
            </div>
            <div key="qa-contact" className="w-25 p-2" >
              <label className='form-label fw-bold'>QA Contact</label>
              <Select options={qaContacts} onChange={res => setFilters({ ...filters, qa_contact: res.value })} />
            </div>
            <div key="last-payment-start-at" className="w-25 p-2" >
              <label className='form-label fw-bold'>Last Payment Start At</label>
              <DatePicker className="form-control" selected={startDate}
                onChange={(date) => setStartDate(date)} placeholderText="Select Date" />
            </div>
            <div key="last-payment-end-at" className="w-25 p-2" >
              <label className='form-label fw-bold'>Last Payment End At</label>
              <DatePicker className="form-control" selected={endDate}
                onChange={(date) => setEndDate(date)} placeholderText="Select Date" />
            </div>
            <div key="organization_status" className="w-25 p-2" >
              <label className='form-label fw-bold'>Organization Status</label>
              <Select options={orgStatuses} onChange={res => setFilters({ ...filters, organization_status: res.value })} />
            </div>
            <div key="invoice-generation-platform" className="w-25 p-2" >
              <label className='form-label fw-bold'>Invoice Generation Platform</label>
              <Select options={invoiceGenerations} onChange={res => setFilters({ ...filters, invoice_generation: res.value })} />
            </div>
          </div>
          <button className="btn btn-primary mt-2 w-25" onClick={handleSearch} >Get Data</button>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <div className="d-flex flex-row align-items-center justify-content-between" >
            <div>
              <span className="fw-bold" >NOTE:</span>If the table is not showing fully please minimize your sidebar or go to bottom of it and use scroll option.
            </div>
            <div>
              <BulkUpdateForm data={selectedOrgs} setToggle={setToggle}
                primaryContacts={primaryContacts} secondaryContacts={secondaryContacts}
                bizContacts={bizContacts} pmContacts={pmContacts} qaContacts={qaContacts}
                orgStatuses={orgStatuses} invoiceGenerations={invoiceGenerations}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <DataTable
            data={allOragnizations}
            columns={orgColumns}
            selectableRows
            onSelectedRowsChange={handleCheckboxChange}
            highlightOnHover
            pagination paginationPerPage={pageEntries} />
        </CardBody>
      </Card>
    </Container>
  )
}
