import React, {useState, useEffect} from 'react'
import { Container, Row, Col, Modal, ModalBody, ModalFooter, ModalHeader, 
    Button } from 'reactstrap'
import axios from 'axios'
import Select from 'react-select';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker'
import Loader from 'react-loader-spinner';

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
  
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};
  
const formatGroupLabel = (data) => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

export default function RunForceESDataDeletion() {
    const [loading, setLoading] = useState(false)
    const [loadingBtns, setLoadingBtns] = useState(false)
    const [errors, setErrors] = useState({})
    const [dropdownOptions, setDropdownOptions] = useState([])
    const [dropdownOptions2, setDropdownOptions2] = useState([])
    const [selectedSite, setSelectedSite] = useState([])
    const [selectedSite2, setSelectedSite2] = useState([])
    const [selectedFromDate, setSelectedFromDate] = useState('')
    const [selectedToDate, setSelectedToDate] = useState('')
    const [deleteData, setDeleteData] = useState(false)
    const [modal, setModal] = useState(false)
    const [count, setCount] = useState(null)


    const toggle = () => {
        setModal(curr => !curr)
    }

    const today = new Date(Date.now())

    const transformData = (inputObj) => {
        let temp = []
        Object.keys(inputObj).forEach(each => {
          temp.push({
            label: each,
            options: inputObj[each].map(each => ({value: each, label: each}))
          })
        })
        return temp
    }

    useEffect(() => {
        fetchSiteNames();
    }, [])

    function fetchSiteNames() {
        axios.get('/api/v1/internal/project_names')
        .then(res => {
            console.log('res.data', res.data)
            let site_names = res.data.project_names
            site_names.unshift({ label: 'All', value: 'all' })
            setDropdownOptions(site_names)
        })
        .catch(err => {
            console.error(err?.message || 'Oops something went wrong')
        })
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('/');
    }

    useEffect(() => {
        if (selectedSite?.value){
            setLoading(true)
            axios.post('/api/v1/internal/site_names_by_project', {
                projects: [selectedSite?.value]
            })
            .then(res => {
                let site_names = transformData(res.data.site_names)
                site_names.unshift({ label: 'All', value: 'all' })
                console.log('site_names', site_names)
                setDropdownOptions2(site_names)
                setLoading(false)
            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong')
                setLoading(false)
            })
        }
        else{
            setSelectedSite2()
        }
    }, [selectedSite])

    const handleSubmit = () => {
        setLoadingBtns(true)
        setErrors({})
        let tempObj = {}
        if (!(selectedSite?.value)){
            tempObj = {...tempObj, site:true}
        }
        if (!(selectedSite2?.value)){
            tempObj = {...tempObj, site2:true}
        }
        if (selectedFromDate?.length < 1){
            tempObj = {...tempObj, from_date:true}
        }
        if (selectedToDate?.length < 1){
            tempObj = {...tempObj, to_date:true}
        }
        setErrors(tempObj)

        if (Object.keys(tempObj)?.length == 0){
            axios.post(`/api/v1/internal/command_dashboard/query_data_from_es`, {
                filter: {
                    site_name: selectedSite2?.value, from_date: formatDate(selectedFromDate),
                    to_date: formatDate(selectedToDate)
                }
            }).then(res => {
                setCount(res.data.data_count_from_es)
                setModal(true)
            })
        }
        else {
            setLoadingBtns(false)
        }
    }

    const handleDataSubmission = () => {
        let resultObj = {
            "outer_command": "run_force_es_data_deletion",
            "inner_command": "run_force_es_data_deletion",
            "nested_inner_command": "run_force_es_data_deletion",
            "project_name": selectedSite?.value, "site_name": selectedSite2?.value,
            "from_date": formatDate(selectedFromDate), "to_date": formatDate(selectedToDate),
            "delete_data_from_latest_index": deleteData ? "true": "false",
        }
        axios.post('/api/v1/internal/command_dashboard/run_force_es_data_deletion', {
            filter: resultObj
        }).then(res => {
            toast.success(res?.data?.message)
            setLoadingBtns(false)
            window.location = `/internal/command_dashboard`
        }).catch(err => {
            toast.error(err?.response?.data?.message)
            setLoadingBtns(false)
        })
        setModal(false)
    }

    const closeBtn = <button className="close" 
        onClick={() => setModal(false)}> &times; </button>;

    return (
        <Container fluid>
            <Modal isOpen={modal} toggle={toggle} className="modal-body" backdrop='static' size='lg' >
                <ModalHeader toggle={toggle} close={closeBtn}>
                    <h6 className='fw-bold'>
                        Data Count Info
                    </h6>
                </ModalHeader>
                <ModalBody>
                    <Container>
                        {(count !== null) ? <div>
                            <h6> <b>Site Name:</b> {selectedSite2?.value} </h6>
                            <h6> <b>From Date:</b> {formatDate(selectedFromDate)} </h6>
                            <h6> <b>To Date</b> {formatDate(selectedToDate)}  </h6>
                            <h6> <b>Data count from ES:</b> {count} </h6>
                        </div> : <p>Loading ... </p> }
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => {
                        setModal(false)
                        setLoading(false)
                        setLoadingBtns(false)
                    }}>Cancel</Button>
                    <Button color="success" onClick={handleDataSubmission}>Proceed</Button>
                </ModalFooter>
            </Modal>
            <Row>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Outer Command (Non-Editable)</h6>
                    <input className='form-control' value='run_force_es_data_deletion'
                        disabled />
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Inner Command (Non-Editable)</h6>
                    <input className='form-control' value='run_force_es_data_deletion'
                        disabled />
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Nested Inner Command (Non-Editable)</h6>
                    <input className='form-control' value='run_force_es_data_deletion'
                        disabled />
                </Col>
                <Col md={12} className='my-3'>
                    <h6 className='fw-bold'>Project Name*</h6>
                    <Select options={dropdownOptions} value={selectedSite} onChange={res => setSelectedSite(res)}
                        placeholder="Select Project" isSearchable />
                    {errors?.site && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                </Col>
                {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
                : <Col md={12} className='my-3'>
                    <h6 className='fw-bold'>Project Sites (*Default All Sites Selected)</h6>
                    <Select options={dropdownOptions2} value={selectedSite2} isSearchable
                        onChange={res => setSelectedSite2(res)} formatGroupLabel={formatGroupLabel} />
                    {errors?.site2 && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                </Col>}
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>From Date*</h6>
                    <DatePicker className="form-control my-2" selected={selectedFromDate} dateFormat="yyyy/MM/dd"
                        maxDate={today} onChange={(date) => setSelectedFromDate(date)} />
                    {errors?.from_date && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>To Date*</h6>
                    <DatePicker className="form-control my-2" selected={selectedToDate} dateFormat="yyyy/MM/dd"
                        maxDate={today} onChange={(date) => setSelectedToDate(date)} />
                    {errors?.to_date && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Select Option</h6>
                    <div className='d-flex'>
                        <input type="checkbox" className='form-check me-2' checked={deleteData}
                            onChange={(e) => setDeleteData(e?.target?.checked)} />
                        <h6 className='fw-bold m-0'>Delete Data from Latest Index</h6>
                    </div>
                </Col>

                <Col md={6} className='my-3'>
                    <button className='btn btn-success' onClick={handleSubmit}
                        disabled={loadingBtns} style={{margin: '25px auto', width: '100%'}}> Submit </button>
                </Col>
            </Row>
        </Container>
    )
}
