import React, {useState} from 'react'

export default function PasswordRow({value, onChange, id}) {
    const [togglePassword, setTogglePassword] = useState(false)
    return (
        <div className='d-flex align-items-center'>
            <input id={id} className="form-control" value={value}
                type={togglePassword ?  "text" : "password" }
                placeholder="****" onChange={onChange} />
            <div onClick={() => setTogglePassword(curr => !curr)}
                style={{marginLeft: '-48px'}}>
                <span className='text-primary'>
                    {togglePassword ? "Hide" : "show"}
                </span>
            </div>
        </div>
    )
}
