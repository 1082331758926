import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, FormGroup, Label, Row, Col } from 'reactstrap';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import axios from 'axios';


export default function ChangeOrganizationModal({ isOpen, setModal, orgList, profile_id, data, toggleData, setToggleData }){

  const toggleModal = () => setModal(!isOpen)
  const closeBtn = <button className="close" onClick={() => setModal(false)}> &times; </button>;
  
  const handleSubmit = (values, { setSubmitting, validateForm }) => {
    axios.put(`/api/v1/default_org/${profile_id}`, {
      user: {
        default_organization_id: values.selectedOrganization
      },
      page_type: "client",
      controller_name: "default_org"
    }).then(res => {
      toast.success(res.data.message)
      setSubmitting(false)
      setToggleData(!toggleData)
      setModal(false)
    }).catch(err => {
      toast.error(err.response.data.message)
      setSubmitting(false)
    })
  };

  return(
      <Modal isOpen={isOpen} toggle={toggleModal} backdrop='static' size='lg' unmountOnClose={false}>
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          Change current organization: {data.organization_name}
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              selectedOrganization: '',
            }}
            validationSchema={Yup.object({
              selectedOrganization: Yup.string()
              .required('Please select organization')
              .test(
                'len',
                'Please select organization',
                val => val && val.length >= 1 && val != "Select organization"
              ),
            })}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            enableReinitialize={true}
            validate={values => {
              const error = {}
              if(values?.selectedOrganization?.length == 0){
                toast.error("Please select organization")
                error.selectedOrganization = "Please select organization"
              }
              return error
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <Row className='d-flex justify-content-center'>
                  <Col sm={11}>
                    <FormGroup row className='my-4'>
                      <Label htmlFor="selectedOrganization" tag={"h6"} className='fw-bold my-0 text-end h6' sm={6}>Select Current Organization:</Label>
                      <Col sm={6} className='d-flex align-items-center'>
                        <Field as="select" name="selectedOrganization" className="form-select" >
                          <option key={1} value={null}>Select organization</option>
                          {orgList.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </Field>
                      </Col>
                    </FormGroup>
                  </Col>
                  <hr className="my-3" />
                  <Col sm={12} className='d-flex justify-content-end'>
                    <Button color='light' onClick={() => setModal(false)} className='mx-2' size='md'> Close </Button>
                    <Button color="success" type='submit'
                      disabled={isSubmitting} size='md'>Change Current Organization</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
  )
}
