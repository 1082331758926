/**
 * This component is no longer being used and is kept for reference only. Will be removed in the future
 */

import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { Home } from "react-feather";
import Loader from "react-loader-spinner";
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Collapse } from "reactstrap";

export default function RandomRecordsFromQA() {
    const [records, setRecords] = useState([]);
    const [isOpen, setIsOpen] = useState('');
    const [fields, setFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([])
    useEffect(() => {
        setLoading(true)
        axios.get(`/api/v1/jobspikr_v3_internal/jobspikr_graphs/random_records_for_qa`)
            .then(res => {
                setFields(res.data.table_data);
                setLoading(false)
            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong');
                setLoading(false);
            })
    }, [])

    const columns = [
        { name: "Field", sortable: true, center: true, selector: "field" },
        { name: "Value", sortable: true, center: true, selector: "value" },

    ]


    return <Container fluid>
        <div className="page-title">
            <Row>
                <Col xs="6">
                    <h3> Internal Dashboard</h3>
                </Col>
                <Col xs="6">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={'/internal'}>
                                <Home />
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem className="fw-bold">
                            100 Random records for QA
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
        {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> : <Card>
            <CardBody>
                <div className="fw-bold">
                    Date: {moment().subtract(1, "days").format("YYYY/MM/DD")}
                </div>
                {fields?.map(record => {
                    return <div style={{cursor:"pointer"}} key={record['id']}>
                        <div className="w-100 p-3 text-start mt-2 panel-heading" role={'presentation'} onClick={() => {
                            setIsOpen(isOpen == record['id'] ? '': record['id'])
                        }}>
                            <p className="fw-bold">{record['site_name']}</p>
                        </div>
                        <Collapse isOpen={isOpen == record['id'] ? true : false}>
                            <Row>
                                <p>
                                    <strong>_id: {record['id']} &emsp; _index: {record['index']}</strong>
                                </p>
                                <br />
                            </Row>
                            <DataTable
                                columns={columns}
                                highlightOnHover
                                noHeader
                                data={record.data} />
                        </Collapse>
                    </div>
                })}
            </CardBody>
        </Card>}
    </Container>
}