import axios from 'axios';
import React from 'react';
import { useEffect, useState } from 'react';
import Breadcrumb from '../../global/Breadcrumb';
import Table from './Table/Table.component';

// Table columns
const Columns = [
  { name: 'Site Name', selector: 'sitename', left: true, sortable: true },
  {
      name: 'URL',
      cell: row => (<a href={row.url}>{row.url}</a>),
      left: true
  },
  { name: 'Host Name', selector: 'host_name', left: true, sortable: true },
  { name: 'Reason', selector: 'reason', left: true, sortable: true },
];

export default function NotFeasibleWebsites() {
  const notFeasibleWebsitesURL = '/api/v1/admins/not_feasible_websites/'
  const [tableData, setTableData] = useState([]);
  useEffect(async () => {
    const controller = new AbortController();
    try {
      const res = await axios.get(notFeasibleWebsitesURL, { signal: controller.signal });
      setTableData(res?.data?.data);
    }
    catch (error) {
      console.log(error.message);
    }
    return () => {
      controller.abort();
    }
  }, []);
  return (
    <>
      <Breadcrumb title="Not Feasible Websites" parent="Not Feasible Websites" />
      <Table TableData={tableData} Columns={Columns} />
    </>
  )
}
