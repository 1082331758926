import React, { useState, useEffect, useMemo, Fragment } from 'react'
import { useParams, useLocation, Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { Card, CardBody, Col, Container, Row, Breadcrumb, BreadcrumbItem, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap'
import SpinnerButton from '../../../V2/Common/SpinnerButton/SpinnerButton.component'
import { toast } from 'react-toastify';
import { Home } from 'react-feather';
import axios from 'axios'

import SchemaAndDataFields from './SchemaAndDataFields';
import SchemaReports from './SchemaReports';
import DataChecksReports from './DataChecksReports';
import RawDataFile from './RawDataFile';

export default function SiteSpecificQaReport() {
  let { site_name } = useParams();
  const [selectedDevQA, setSelectedDevQA] = useState(0)
  const [selectedBizQA, setSelectedBizQA] = useState(0)
  const [devComment, setDevComment] = useState('')
  const [bizComment, setBizComment] = useState('')
  const [rssCrawlPushId, setRssCrawlPushId] = useState("")
  const [rssCrawlPushIdOptions, setRssCrawlPushIdOptions] = useState([])
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)

  const search = useLocation().search
  const tabName = new URLSearchParams(search).get('tab_name')
  const [activeTab, setActiveTab] = useState(tabName? tabName : "data_check_reports")

  const SITE_SPECIFIC_QA_REPORTS_API_URL = useMemo(() => `/api/v1/internal/new_qa_reports/${site_name}`, [site_name])
  const LATEST_20_RSS_CRAWL_PUSH_ID_API_URL = useMemo(() => `/api/v1/internal/new_qa_reports/${site_name}/top_20_rss_crawl_push_ids`, [site_name])

  const SCHEMA_AND_DATA_FIELDS_API_URL = useMemo(() => {
    const baseApi = '/api/v1/internal/new_qa_reports/schema_and_data_fields?';
    const params = (rssCrawlPushId !== "") ? `site_name=${site_name}&rss_crawl_push_id=${rssCrawlPushId}` : `site_name=${site_name}`;
    return `${baseApi}${params}`;
  }, [site_name, rssCrawlPushId])

  const SCHEMA_REPORTS_API_URL = useMemo(() => {
    const baseApi = '/api/v1/internal/new_qa_reports/schema_reports?';
    const params = (rssCrawlPushId !== "") ? `site_name=${site_name}&rss_crawl_push_id=${rssCrawlPushId}` : `site_name=${site_name}`;
    return `${baseApi}${params}`;
  }, [site_name, rssCrawlPushId])

  const DATA_CHECK_REPORTS_API_URL = useMemo(() => {
    const baseApi = '/api/v1/internal/new_qa_reports/data_check_reports?';
    const params = (rssCrawlPushId !== "") ? `site_name=${site_name}&rss_crawl_push_id=${rssCrawlPushId}` : `site_name=${site_name}`;
    return `${baseApi}${params}`;
  }, [site_name, rssCrawlPushId])

  const RAW_DATA_API_URL = useMemo(() => {
    const baseApi = '/api/v1/internal/new_qa_reports/raw_data_of_data_checks_reports?';
    const params = (rssCrawlPushId !== "") ? `site_name=${site_name}&rss_crawl_push_id=${rssCrawlPushId}` : `site_name=${site_name}`;
    return `${baseApi}${params}`;
  }, [site_name, rssCrawlPushId])

  useEffect(()=>{
    const controller = new AbortController()
    setLoading(true)
    fetchData(controller.signal)
    setLoading(false)
    // cleanup
    return ()=>{
      controller.abort()
    }
  },[site_name, rssCrawlPushId])

  async function fetchData(signal) {
    await getAndSetLatest20RssCrawlPushId(LATEST_20_RSS_CRAWL_PUSH_ID_API_URL, signal)
    await getAndSetSiteSpecificQaReportsData(SITE_SPECIFIC_QA_REPORTS_API_URL, signal)
  }

  async function getAndSetSiteSpecificQaReportsData(apiUrl, signal) {
    try{
      const response = await axios.get(apiUrl, { signal: signal })
      const data = await response?.data
      setData(data)
      setSelectedDevQA(data?.site_qa_data?.dev_site_qa_status);
      setSelectedBizQA(data?.site_qa_data?.biz_site_qa_status);
      setDevComment(data?.site_qa_data?.dev_comment);
      setBizComment(data?.site_qa_data?.comment);
    }
    catch(error){
      toast.error(error.message)
    }
  }

  async function getAndSetLatest20RssCrawlPushId(apiUrl, signal) {
    try{
      const response = await axios.get(apiUrl, { signal: signal })
      const data = await response?.data?.data
      setRssCrawlPushIdOptions(data)
      // Set the first Id as default selected
      if(!rssCrawlPushId && data.length>0){
        setRssCrawlPushId(data[0].value)
      }
      setLoading(false)
    }
    catch(error){
      setLoading(false)
      toast.error(error.message)
    }
  }

  async function onRssCrawlPushIdChangeHandler(event) {
    const { value } = event?.target
    setRssCrawlPushId(value)
  }

  const handleSave = () => {
    setButtonLoading(true)
    axios.put(`/api/v1/internal/new_qa_reports/${site_name}`, {
      process_model_site_qa: {
        dev_site_qa_status: selectedDevQA, biz_site_qa_status: selectedBizQA,
        comment: bizComment, dev_comment: devComment
      }
    }).then(res => {
      toast.success(res.data.message)
      setButtonLoading(false)
      setToggle(curr => !curr)
    }).catch(err => {
      toast.error(err.response.data?.message)
      setButtonLoading(false)
    })
  }

  const handleReset = () => {
    setButtonLoading(true)
    axios.put(`/api/v1/internal/new_qa_reports/${site_name}/reset_manual_qa_status`).then(res => {
      toast.success(res.data?.message)
      setToggle(curr => !curr)
      setButtonLoading(false)
    }).catch(err => {
      toast.error(err.response.data?.message)
      setButtonLoading(false)
    })
  }

  return (
    <Container fluid>
      <Fragment>
        <Container fluid>
          <div className="page-title">
            <Row className=''>
              <Col xs="6">
                <h3>Internal Dashboard</h3>
              </Col>
              <Col xs="6">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`/internal`}><Home /></Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link to={`/internal/new_qa_reports`}>
                      All Sites
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link to={`/internal/new_qa_reports/pending`}>
                      List of Sites Pending for QA
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <b>{site_name}</b>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </div>
        </Container>
      </Fragment>

      {
        loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
          :
          <>
            <Card>
              <CardBody style={{ padding: "5px 20px" }}>
                <Row>
                  <Col md='12' className='my-3'>
                    <h6 className='fw-bold'>Site Name:</h6>
                    <input type='text' value={site_name} className='form-control' disabled />
                  </Col>
                  <Col md='6' className='my-3'>
                    <h6 className='fw-bold'>Dev Site QA Status:</h6>
                    <select value={selectedDevQA} onChange={(e) => setSelectedDevQA(parseInt(e.target.value))}
                      className='form-control'>
                      <option value='1'>All fine</option>
                      <option value='0'>Doesn't feel good</option>
                    </select>
                  </Col>
                  <Col md='6' className='my-3'>
                    <h6 className='fw-bold'>Biz Site QA Status:</h6>
                    <select value={selectedBizQA} onChange={(e) => setSelectedBizQA(parseInt(e.target.value))}
                      className='form-control' disabled={!(data?.current_user_can_access_biz_qa)}>
                      <option value='1'>All fine</option>
                      <option value='0'>Doesn't feel good</option>
                    </select>
                  </Col>
                  <Col md='6' className='my-3'>
                    <h6 className='fw-bold'>Dev's comment:</h6>
                    <textarea rows='3' className='form-control' value={devComment}
                      onChange={(e) => setDevComment(e.target.value)}></textarea>
                  </Col>
                  <Col md='6' className='my-3'>
                    <h6 className='fw-bold'>Biz's comment:</h6>
                    <textarea rows='3' className='form-control' value={bizComment}
                      onChange={(e) => setBizComment(e.target.value)} disabled={!(data?.current_user_can_access_biz_qa)}></textarea>
                  </Col>
                  <Col md="6">
                    <h6 className='fw-bold'>Report ID:</h6>
                    <select value={rssCrawlPushId} onChange={(e) => onRssCrawlPushIdChangeHandler(e)}
                      className='form-control'>
                      <option value={""} disabled hidden>Select Report Id</option>
                      {
                        rssCrawlPushIdOptions.map(data => (
                          <option value={data?.value}>{data?.label}</option>
                        ))
                      }
                    </select>
                  </Col>
                  <Col md="6"></Col>
                </Row>
              </CardBody>
            </Card>
            <SchemaAndDataFields site_name={site_name} rssIdSetter={setRssCrawlPushId} apiEndpoint = {SCHEMA_AND_DATA_FIELDS_API_URL}  />
            <SchemaReports site_name={site_name} apiEndpoint ={SCHEMA_REPORTS_API_URL} />
            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                    <Nav tabs>
                      <NavItem>
                        <NavLink className={activeTab === "data_check_reports" ? "active" : ''} onClick={() => setActiveTab("data_check_reports")}>
                          Data Checks Reports
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink className={activeTab === "raw_data_file" ? "active" : ''} onClick={() => setActiveTab("raw_data_file")}>
                          Raw Data File 
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId='data_check_reports'>
                        <DataChecksReports site_name={site_name} apiEndpoint={DATA_CHECK_REPORTS_API_URL} />
                      </TabPane>
                      <TabPane tabId='raw_data_file'>
                        <RawDataFile site_name={site_name} apiEndpoint={RAW_DATA_API_URL} rssCrawlPushId={rssCrawlPushId} />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className='my-3'>
              <Col md={4}>
                <SpinnerButton color="success" className="w-100" onClick={handleSave} loading={buttonLoading} disabled={buttonLoading} >Save</SpinnerButton>
              </Col>
              <Col md={4}></Col>
              <Col md={4}>
                <SpinnerButton color="success" className="w-100" onClick={handleReset} loading={buttonLoading} disabled={buttonLoading} >Reset Manual QA Status</SpinnerButton>
                <p>This will make all the QA status false and re-upload a QA file from the next run</p>
              </Col>
            </Row>
          </>
      }
    </Container>
  )
}
