import React from 'react';
import { useMemo } from 'react';
import { Row, Card, Col, FormGroup, Label, Input } from 'reactstrap';
import countryList from 'react-select-country-list';

export default function RequirementsSubmissionCard({
  errors,
  setErrors,
  handleOrganization,
  organizationName,
  industrySelected,
  setIndustrySelected,
  countrySelected,
  setCountrySelected }) {

  const options = useMemo(() => countryList().getData(), []);

  return (
    <div>
      <Card className='px-5 py-4 mycard'>
        <Row className='py-2'>
          <Col lg={4}>
            <FormGroup>
              <Label for="organization">Organization Name* </Label>
              <Input
                type="text"
                name="organization"
                id="organization"
                onChange={handleOrganization}
                required
                placeholder="Enter Organization name"
                value={organizationName} />
              {errors && errors.organization && <p className='m-0 error-msg mt-1 mb-2'>
                {errors.organization}
              </p>}
              <p className='text-muted m-0 mt-2 text-sm'>
                Please give legal name of the company, it cant be changed later. This name is used while generating invoices.
              </p>
            </FormGroup>
          </Col>
          <Col lg={4}>
            <FormGroup>
              <Label for="industry">Industry* </Label>
              <Input
                type="select"
                name="industry"
                id="industry"
                defaultValue={industrySelected}
                onChange={e => {
                  setIndustrySelected(e.target.value)
                  setErrors({
                    ...errors,
                    industry: e.target.value == 'none'
                      ? 'Industry is required'
                      : null
                  })
                }}>
                <option value="none">Select an industry type</option>
                <option value="ecommerce_retail">Ecommerce & Retail</option>
                <option value="travel">Travel</option>
                <option value="healthcare">Healthcare</option>
                <option value="banking_financial">Banking & Financial Services</option>
                <option value="software_it">Software & IT Services</option>
                <option value="market_research">Market research, Marketing & Advertising</option>
                <option value="publishing">Publishing</option>
                <option value="analytics">Analytics</option>
                <option value="recruitment">Recruitment</option>
                <option value="entertainment">Entertainment</option>
                <option value="education">Education</option>
                <option value="automotive">Automotive</option>
                <option value="other">Other</option>
              </Input>
              {errors && errors.industry && <p className='m-0 error-msg mt-1 mb-2'>
                {errors.industry}
              </p>}
            </FormGroup>
          </Col>
          <Col lg={4}>
            <FormGroup>
              <Label for="country">Country* </Label>
              <Input
                type="select"
                name="country"
                id="country"
                defaultValue={countrySelected}
                onChange={(e) => {
                  setCountrySelected(e.target.value)
                  setErrors({
                    ...errors,
                    country: e.target.value == 'none'
                      ? 'Country is required'
                      : null
                  })
                }}>
                <option value="none">
                  select a country
                </option>
                {options.map(each => {
                  return (
                    <option value={each.value} key={each.value}>
                      {each.label}
                    </option>
                  )
                })}
              </Input>
              {errors && errors.country && <p className='m-0 error-msg mt-1 mb-2'>
                {errors.country}
              </p>}
              <p className='text-muted m-0 mt-2 text-sm'>
                Required to serve the right payment gateway.
              </p>
            </FormGroup>
          </Col>
        </Row>
      </Card>
    </div>
  )
}
