import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";
import axios from "axios";
import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";

export default function AwsAndGceCurrentlyRunningMachines() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const tabNameFromSearchUrl = new URLSearchParams(search).get("filter[tab_name]");
  const tabNameFromUrl = window.location.hash.substr(1);
  const searchParams = {
    cloudType: new URLSearchParams(search).get("filter[cloud_type]"),
    region: new URLSearchParams(search).get("filter[region]"),
    jobType: new URLSearchParams(search).get("filter[job_type]"),
    tabName: tabNameFromSearchUrl ? tabNameFromSearchUrl : tabNameFromUrl
  }
  const [cloudType, setCloudType] = useState({ label: 'All', value: 'All' });
  const [region, setRegion] = useState({ label: 'All', value: 'All' });
  const [jobType, setJobType] = useState({ label: 'All', value: 'All' });
  const [activeTab, setActiveTab] = useState(tabNameFromUrl ? tabNameFromUrl : 'running_machines_details');
  const [machinesDetails, setMachinesDetails] = useState([]);
  const [machinesSummary, setMachinesSummary] = useState([]);
  const [totalMachineCount, setTotalMachineCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [searchMachinesDetails, setSearchMachinesDetails] = useState('');
  const [machinesDetailsSearchResults, setMachinesDetailsSearchResults]= useState([]);

  const [searchMachinesSummary, setSearchMachinesSummary] = useState('');
  const [machinesSummarySearchResults, setMachinesSummarySearchResults]= useState([]);

  const cloudTypeOptions = [
    { label: 'All', value: 'All' },
    { label: 'AWS', value: 'aws' },
    { label: 'GCE', value: 'gce' }
  ]

  const regionOptions = [
    { label: 'All', value: 'All' },
    { label: 'us', value: 'us' },
    { label: 'eu', value: 'eu' },
    { label: 'india', value: 'india' },
    { label: 'tw', value: 'tw' },
    { label: 'uk', value: 'uk' }
  ]

  const jobTypeOptions = [
    { label: 'All', value: 'All' },
    { label: 'Feedcrawl', value: 'feedcrawl' },
    { label: 'Recrawl', value: 'recrawl' },
    { label: 'Recrawl and Feedcrawl', value: 'recrawl_and_feedcrawl' },
    { label: 'Dependent Data Upload', value: 'dependent_data_upload' },
    { label: 'Selenium', value: 'selenium' }
  ]

  const totalMachineCountTableColumns = [
    { name: 'Cloud Type', selector: 'cloud_type', sortable: true },
    { name: 'Region', selector: 'region', sortable: true },
    { name: 'Job Type', selector: 'job_type', sortable: true },
    { name: 'Total Machine Count', selector: 'total_machine_count', sortable: true }
  ]

  const totalMachineCountTableData = [
    {
      id: 1,
      cloud_type: cloudType.label,
      region: region.label,
      job_type: jobType.label,
      total_machine_count: totalMachineCount
    }
  ]

  const machinesDetailsTableColumns = [
    { 
      name: 'S.No', 
      selector: "",
      cell: (row, index) => (index + 1)
    },
    { name: 'Instance Name', selector: 'instance_name', sortable: true },
    { name: 'Cloud Type', selector: 'cloud_type', sortable: true },
    { name: 'Region', selector: 'region', sortable: true },
    { name: 'Job Type', selector: 'job_type', sortable: true },
    { name: 'Public IP', selector: 'public_ip', sortable: true },
    { name: 'Running Since', selector: 'running_since', sortable: true },
    { 
      name: 'Status', 
      selector: 'status', 
      sortable: true,
      cell: row => (<button className="btn btn-warning btn-sm">{row.status.toUpperCase()}</button>)
    }
  ]

  const machinesSummaryTableColumns = [
    { 
      name: 'S.No', 
      selector: "",
      cell: (row, index) => (index + 1)
    },
    { name: 'Machine Name', selector: 'machine_name', sortable: true },
    { name: 'Machine Count', selector: 'machine_count', sortable: true },
  ]

  const [UrlParams, setUrlParams] = useState({
    cloudType: 'All',
    region: 'All',
    jobType: 'All',
    tabName: 'running_machines_details' 
  })

  useEffect(() => {
    setSearchParamsData()
  }, []);

  function humanize(str) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.cloudType && searchParams.cloudType != cloudType.value) {
      setCloudType({ label: searchParams.cloudType == "All" ? "All" : searchParams.cloudType.toUpperCase(), value: searchParams.cloudType });
    }
    if (searchParams.region && searchParams.region != region.value) {
      setRegion({ label: searchParams.region, value: searchParams.region });
    }
    if (searchParams.jobType && searchParams.jobType != jobType.value) {
      setJobType({ label: searchParams.jobType == "All" ? "All" : humanize(searchParams.jobType), value: searchParams.jobType });
    }
    if (searchParams.tabName && searchParams.tabName != activeTab) {
      setActiveTab(searchParams.tabName);
    }
    if (commit == 'Get Data') {
      setUrlParams(searchParams);
      fetchDataWithParams(searchParams.cloudType, searchParams.region, searchParams.jobType);
    }
    else{
      fetchData();
    }
  }

  function fetchDataWithParams(cloud_type = cloudType, region = region, job_type = jobType) {
    setLoading(true)
    axios.get(`/api/v1/internal/aws_and_gce_machine_info/currently_running_machines?filter[cloud_type]=${cloud_type}&filter[region]=${region}&filter[job_type]=${job_type}`)
      .then(res => {
        setMachinesDetails(res.data.machines_details);
        setMachinesDetailsSearchResults(res.data.machines_details);
        setMachinesSummary(res.data.machines_summary);
        setMachinesSummarySearchResults(res.data.machines_summary);
        setTotalMachineCount(res.data.total_machine_count);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  function fetchData() {
    setLoading(true)
    axios.get(`/api/v1/internal/aws_and_gce_machine_info/currently_running_machines`)
      .then(res => {
        setMachinesDetails(res.data.machines_details);
        setMachinesDetailsSearchResults(res.data.machines_details);
        setMachinesSummary(res.data.machines_summary);
        setMachinesSummarySearchResults(res.data.machines_summary);
        setTotalMachineCount(res.data.total_machine_count);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  const handleSearchMachinesDetails = event =>{
    if(event.target.value == ''){
      setMachinesDetailsSearchResults(machinesDetails);
    }
    else{
      setMachinesDetailsSearchResults(searchFromMachinesDetailsData(event.target.value));
    }
    setSearchMachinesDetails(event.target.value);
  }

  function searchFromMachinesDetailsData(value){
    var search_result = machinesDetails.filter(item => item.instance_name.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = machinesDetails.filter(item => item.cloud_type.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = machinesDetails.filter(item => item.region.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = machinesDetails.filter(item => item.job_type.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = machinesDetails.filter(item => item.public_ip.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = machinesDetails.filter(item => item.running_since.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
  }

  const handleSearchMachinesSummary = event =>{
    if(event.target.value == ''){
      setMachinesSummarySearchResults(machinesSummary);
    }
    else{
      setMachinesSummarySearchResults(searchFromMachinesSummaryData(event.target.value));
    }
    setSearchMachinesSummary(event.target.value);
  }

  function searchFromMachinesSummaryData(value){
    var search_result = machinesSummary.filter(item => item.machine_name.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = machinesSummary.filter(item => item.machine_count.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    } 
  }

  const handleGetData = () => {
    window.location.href = `/internal/aws_and_gce_machine_info/currently_running_machines?filter[cloud_type]=${cloudType.value}&filter[region]=${region.value}&filter[job_type]=${jobType.value}&filter[tab_name]=${activeTab}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/aws_and_gce_machine_info/currently_running_machines">AWS and GCE Currently Running Machines</a> : 'AWS and GCE Currently Running Machines'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
          </BreadcrumbItem>}
        </Breadcrumb>
      </Col>
    </Row>
  </div>
  <Card>
    <CardBody>
      <Nav tabs>
        <NavItem>
          <NavLink className={activeTab == 'running_machines_details' ? 'active' : ''} onClick={() => setActiveTab('running_machines_details')}>
            Running Machines Details
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={activeTab == 'running_machines_summary' ? 'active' : ''} onClick={() => setActiveTab('running_machines_summary')}>
            Running Machines Summary
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={'running_machines_details'}>
          <div className="ibox-content m-b-sm border-bottom mb-4">
            <Row className="align-items-end">
              <Col sm="3">
                <label className="fw-bold">Cloud Type</label>
                <Select
                  value={cloudType}
                  options={cloudTypeOptions}
                  onChange={option => setCloudType(option)}
                />
              </Col>
              <Col sm="3">
                <label className="fw-bold">Region</label>
                <Select
                  value={region}
                  options={regionOptions}
                  onChange={option => setRegion(option)}
                />
              </Col>
              <Col sm="3">
                <label className="fw-bold">Job Type</label>
                <Select
                  value={jobType}
                  options={jobTypeOptions}
                  onChange={option => setJobType(option)}
                />
              </Col>
              <Col sm="3">
                <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
              </Col>
            </Row>
          </div>
          <Row className="align-items-end mb-4">
            <div className="mb-2">
              <center>
                NOTE:
                <b> Machines info shown here might lag the current info by 5 min</b>
              </center>
            </div>
            <DataTable
              columns={totalMachineCountTableColumns}
              data={totalMachineCountTableData}
              noHeader
              pagination
              progressPending={loading}
            />
          </Row>
          <Row className="align-items-end ">
            <Col>
              <div className="float-end mb-3">
                <Row className="align-items-center">
                  <Col sm="3">Search</Col>
                  <Col>
                    <input type="text" className="form-control" value={searchMachinesDetails} onChange={handleSearchMachinesDetails} />
                  </Col>
                </Row>
              </div>
            </Col>
            <DataTable
              columns={machinesDetailsTableColumns}
              data={machinesDetailsSearchResults}
              noHeader
              pagination
              progressPending={loading}
            />
          </Row>
        </TabPane>
        <TabPane tabId={'running_machines_summary'}>
          <div className="ibox-content m-b-sm border-bottom mb-4">
            <Row className="align-items-end">
              <Col sm="3">
                <label className="fw-bold">Cloud Type</label>
                <Select
                  value={cloudType}
                  options={cloudTypeOptions}
                  onChange={option => setCloudType(option)}
                />
              </Col>
              <Col sm="3">
                <label className="fw-bold">Region</label>
                <Select
                  value={region}
                  options={regionOptions}
                  onChange={option => setRegion(option)}
                />
              </Col>
              <Col sm="3">
                <label className="fw-bold">Job Type</label>
                <Select
                  value={jobType}
                  options={jobTypeOptions}
                  onChange={option => setJobType(option)}
                />
              </Col>
              <Col sm="3">
                <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
              </Col>
            </Row>
          </div>
          <Row className="align-items-end ">
            <div className="mb-4">
              <center>
                NOTE:
                <b>Machines info shown here might lag the current info by 5 min</b>
              </center>
            </div>
            <Col>
              <div className="float-end mb-3">
                <Row className="align-items-center">
                  <Col sm="3">Search</Col>
                  <Col>
                    <input type="text" className="form-control" value={searchMachinesSummary} onChange={handleSearchMachinesSummary} />
                  </Col>
                </Row>
              </div>
            </Col>
            <DataTable
              columns={machinesSummaryTableColumns}
              data={machinesSummarySearchResults}
              noHeader
              pagination
              progressPending={loading}
            />
          </Row>
        </TabPane>
      </TabContent>
    </CardBody>
  </Card>
</Container>
}
