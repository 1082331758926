import React, { useState, useEffect, useMemo, Fragment } from 'react';

import axios from 'axios';
import { useParams } from 'react-router-dom';
import { DataNotFoundAlerts } from './Alerts.component';
import { Card, CardBody, Badge, Row, Col } from 'reactstrap';
import SpinnerLoader from '../../../V2/Common/LoadingAnimation/SpinnerLoader.component';

export default function SchemaReport(props) {

  // De-construct the props
  const { rssCrawlPushID } = props;

  // Read site name from params
  const { site_name } = useParams();

  // Misc.
  const [loading, setLoading] = useState(false)

  // States for handeling data from APi Request
  const [extraFields, setExtraFields] = useState([]);
  const [missingFields, setMissingFields] = useState([]);
  const [crawlDateRange, setCrawlDateRange] = useState("");
  const [dataAvailable, setDataAvailable] = useState(false);
  const [schemaFieldsInDRL, setSchemaFieldsInDRL] = useState("");
  const [numberOfExtraFields, setNumberOfExtraFields] = useState(0);
  const [dataFieldsInDataFile, setDataFieldsInDataFile] = useState("");
  const [numberOfMissingFields, setNumberOfMissingfields] = useState(0);
  const [numberOfRecordsChosenForQa, setNumberOfRecordsChosenForQa] = useState(0);
  const [totalNumberOfScheaFieldsInDRL, setTotalNumberOfSchemaFieldsInDRL] = useState(0);
  const [totalNumberOfDataFieldsInDataFile, setTotalNumberOfDataFieldsInDataFile] = useState(0);

  // IF THE RSS CRAWL PUSH ID IS PRESENT THEN ADD IT TO PARAMS AND MAKE API CALL
  // OTHERWISE MAKE API CALL WITHOUT IT
  const SCHEMA_AND_DATA_FIELDS_API_URL = useMemo(() => {
    const baseApi = '/api/v1/internal/new_qa_reports/get_schema_reports_tab_data?';
    const params = (rssCrawlPushID !== "") ? `site_name=${site_name}&rss_crawl_push_id=${rssCrawlPushID}` : `site_name=${site_name}`;
    return `${baseApi}${params}`;
  }, [site_name, rssCrawlPushID]);

  useEffect(() => {

    const controller = new AbortController();
    fetchSchemaAndDataFieldsData(controller.signal);

    // cleanup
    return () => {
      controller.abort();
      setDataFieldsInDataFile("");
      setSchemaFieldsInDRL("");
    }
  }, [SCHEMA_AND_DATA_FIELDS_API_URL]);

  async function fetchSchemaAndDataFieldsData(signal) {
    try {
      setLoading(true);
      const response = await axios.get(SCHEMA_AND_DATA_FIELDS_API_URL, { signal: signal });
      const data = await response?.data;
      setExtraFields(data?.schema_reports?.extra_fields);
      setMissingFields(data?.schema_reports?.missing_fields);
      setCrawlDateRange(data?.schema_and_data_fields?.crawl_date_range)
      setSchemaFieldsInDRL(data?.schema_and_data_fields?.schema_fields);
      setDataFieldsInDataFile(data?.schema_and_data_fields?.data_fields);
      setNumberOfRecordsChosenForQa(data?.schema_and_data_fields?.no_of_records);
      setTotalNumberOfSchemaFieldsInDRL(data?.schema_and_data_fields?.no_of_schema_fields);
      setTotalNumberOfDataFieldsInDataFile(data?.schema_and_data_fields?.no_of_data_fields);
      setDataAvailable(data?.is_schema_and_data_fields_data_available || data?.is_schema_reports_data_available);
      setLoading(false);
    }
    catch (error) {
      console.error("Ooops! Something went wrong!");
      setLoading(false);
    }
  }

  // Helper function to format dates
  function convertDateString(input) {
    if (input && input.length > 0) {
      const dateArray = input.split(" - ");
      const startDate = `${dateArray[0].slice(0, 4)}/${dateArray[0].slice(4, 6)}/${dateArray[0].slice(6)}`
      const endDate = `${dateArray[1].slice(0, 4)}/${dateArray[1].slice(4, 6)}/${dateArray[1].slice(6)}`
      return `${startDate} - ${endDate}`
    }
    else {
      return ""
    }
  };

  return (
    <Fragment>
      <SpinnerLoader loading={loading}>
        <DataNotFoundAlerts display={dataAvailable} alertMessage={"No Data Available"}>
          <section className='date-and-records-count'>
            <div className='d-flex flex-row'>
              <div className='d-flex flex-row justify-content-center align-items-center align-content-center m-2 w-100'>
                <div className='d-flex flex-row justify-content-between align-items-center align-content-center p-2 w-100' style={{ backgroundColor: "#40A2E3" }}>
                  <p className='fw-bold text-white mb-0'> Crawl Date: <span className='text-warning'>{convertDateString(crawlDateRange)}</span></p>
                  <p className='fw-bold text-white mb-0'><span className='text-warning'>{numberOfRecordsChosenForQa}</span> Records Chosen Randomly For QA</p>
                </div>
              </div>
            </div>
          </section>
          <section className='schema-fields-in-drl'>
            <div className='d-flex flex-row'>
              <div className='d-flex flex-column justify-content-center align-items-center align-content-center m-2 w-100'>
                <div className='d-flex flex-column justify-content-center align-items-center align-content-center p-1 w-100' style={{ backgroundColor: "#40A2E3" }}>
                  <p className='fw-bold text-white mb-0'>SCHEMA FIELDS IN DRL</p>
                  <p className='fw-bold text-white'>TOTAL NUMBER OF FIELDS: {totalNumberOfScheaFieldsInDRL}</p>
                </div>
                <div className='d-flex flex-row justify-content-center align-items-center align-content-center flex-wrap my-3 p-2' style={{ backgroundColor: "#FFF6E9" }} >
                  {
                    schemaFieldsInDRL?.length > 0 && schemaFieldsInDRL?.split(", ").map((data, indx) => (
                      <Badge key={indx} color='light'><p className='fw-bold text-dark'>{data}</p></Badge>
                    ))
                  }
                </div>
              </div>
            </div>
          </section>
          <section className='data-fields-in-data'>
            <div className='d-flex flex-row'>
              <div className='d-flex flex-column justify-content-center align-items-center align-content-center m-2 w-100'>
                <div className='d-flex flex-column justify-content-center align-items-center align-content-center p-1 w-100' style={{ backgroundColor: "#40A2E3" }}>
                  <p className='fw-bold text-white mb-0'>DATA FIELDS IN DATA FILE</p>
                  <p className='fw-bold text-white'>TOTAL NUMBER OF FIELDS: {totalNumberOfDataFieldsInDataFile}</p>
                </div>
                <div className='d-flex flex-row justify-content-center align-items-center align-content-center flex-wrap my-3 p-2' style={{ backgroundColor: "#FFF6E9" }} >
                  {
                    dataFieldsInDataFile?.length > 0 && dataFieldsInDataFile?.split(", ").map((data, indx) => (
                      <Badge key={indx} color='light'><p className='fw-bold text-dark'>{data}</p></Badge>
                    ))
                  }
                </div>
              </div>
            </div>
          </section>
          <section className='scheama-report'>
            <div className='d-flex flex-column justify-content-center align-items-center align-content-center p-1 w-100' style={{ backgroundColor: "#40A2E3" }}>
              <p className='fw-bold text-white'>SCHEMA FIELDS</p>
            </div>
            <div className='d-flex flex-row justify-content-around align-items-center'>
              <Card className='w-50'>
                <CardBody>
                  <div className='d-flex flex-column justify-content-around align-items-center'>
                    <div className='lead my-2'>MISSING FIELDS </div>
                    <div className='fw-bold'>{numberOfMissingFields || 0}</div>
                  </div>
                </CardBody>
              </Card>
              <Card className='w-50'>
                <CardBody>
                  <div className='d-flex flex-column justify-content-around align-items-center'>
                    <div className='lead'>EXTRA FIELDS </div>
                    <div className='fw-bold'>{numberOfExtraFields || 0}</div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className='missing-and-extra-fields-names'>
              <Row>
                <Col md="6">
                  <section className={`extra-fields-in-data d-${missingFields?.length <= 0 ? 'none' : ''}`}>
                    <div className='d-flex flex-row'>
                      <div className='d-flex flex-column justify-content-center align-items-center align-content-center m-2 w-100'>
                        <div className='d-flex flex-column justify-content-center align-items-center align-content-center p-1 w-100' style={{ backgroundColor: "#40A2E3" }}>
                          <p className='fw-bold text-white mb-0'>MISSING FIELDS</p>
                        </div>
                        <div className='d-flex flex-row justify-content-center align-items-center align-content-center flex-wrap my-3 p-2' style={{ backgroundColor: "#FFF6E9" }} >
                          {
                            missingFields?.length > 0 && missingFields?.split(", ").map((data, indx) => (
                              <Badge key={indx} color='light'><p className='fw-bold text-dark'>{data}</p></Badge>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </section>
                </Col>
                <Col md="6">
                  <section className={`extra-fields-in-data d-${extraFields?.length <= 0 ? 'none' : ''}`} >
                    <div className='d-flex flex-row'>
                      <div className='d-flex flex-column justify-content-center align-items-center align-content-center m-2 w-100'>
                        <div className='d-flex flex-column justify-content-center align-items-center align-content-center p-1 w-100' style={{ backgroundColor: "#40A2E3" }}>
                          <p className='fw-bold text-white mb-0'>EXTRA FIELDS</p>
                        </div>
                        <div className='d-flex flex-row justify-content-center align-items-center align-content-center flex-wrap my-3 p-2' style={{ backgroundColor: "#FFF6E9" }} >
                          {
                            extraFields?.length > 0 && extraFields?.split(", ").map((data, indx) => (
                              <Badge key={indx} color='light'><p className='fw-bold text-dark'>{data}</p></Badge>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </section>
                </Col>
              </Row>
            </div>
          </section>
        </DataNotFoundAlerts>
      </SpinnerLoader>
    </Fragment>
  )
}
