/**
 * This is a closed beta for new qa tool page and is undergoing development 
 * Move this to V2 folder once the UI as well as functionality is finalized
 */
import React, { Fragment, useEffect, useState, useMemo } from 'react'

import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom'
import SchemaReport from './PageElements/SchemaReport.component';
import NewQaToolBreadcrumb from './NewQaToolBreadcrumb.component';
import ResetQAStatusModal from './PageElements/ResetQAStatusModal';
import AllDataCheckReports from './PageElements/AllDataCheckReports.component';
import SpinnerLoader from '../../V2/Common/LoadingAnimation/SpinnerLoader.component';
import HighPriorityDataCheckReports from './PageElements/HighPriorityDataCheckReports.components';
import RawDataFileForDataCheckReports from './PageElements/RawDataFileForDataCheckReports.component';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'

const QaStatuses = [
  { label: "All Fine", value: "1" },
  { label: "Doesn't Look Good", value: "0" },
]

export default function NewQATool() {

  const { site_name } = useParams();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({ dev_comment: "", dev_site_qa_status: "", biz_comment: "", biz_site_qa_status: "", report_ids: [] });
  const [rssCrawlPushID, setRssCrawlPushID] = useState("");
  const [bizQaAccessible, setBizQaAccessible] = useState(false);
  const [activeTabName, setActiveTabName] = useState("high_priority_data_check_reports");

  // API endpoints
  const RESET_MANUAL_QA_STATUS_API = useMemo(() => `/api/v1/internal/new_qa_reports/${site_name}/reset_manual_qa_status`, [site_name]);
  const FORM_DATA_API = useMemo(() => `/api/v1/internal/new_qa_reports/get_form_data?site_name=${site_name}`, [site_name]);
  const SAVE_QA_STATUS_API = useMemo(() => `/api/v1/internal/new_qa_reports/${site_name}`, [site_name]);

  useEffect(() => {
    fetchFormData();
  }, [site_name])

  // Helper function to fetch data displayed in the form
  // This includes the qa statuses as well as the top 12 push ids
  async function fetchFormData() {
    try {
      setLoading(true)
      const response = await axios.get(FORM_DATA_API)
      const data = await response?.data
      const frmData = {
        dev_comment: data?.site_qa_data?.dev_comment,
        dev_site_qa_status: data?.site_qa_data?.dev_site_qa_status,
        biz_comment: data?.site_qa_data?.comment,
        biz_site_qa_status: data?.site_qa_data?.biz_site_qa_status,
        report_ids: data?.top_20_rss_crawl_push_ids,
      }
      setFormData(frmData)
      if (data?.top_20_rss_crawl_push_ids.length > 0) {
        setRssCrawlPushID(data?.top_20_rss_crawl_push_ids[0].value)
      }
      setBizQaAccessible(data?.current_user_can_access_biz_qa);
      setLoading(false)
    }
    catch (error) {
      toast.error(error?.message || 'Oops something went wrong')
      setLoading(false)
    }
  }

  // Helper function to set the qa statuses and comments
  function onInputFormInputChangeHandler(event) {
    const { name, value } = event?.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  // Helper function to save qa status
  async function onSaveQAStatusButtonClickHandler() {
    const requestBody = {
      process_model_site_qa: {
        dev_site_qa_status: formData?.dev_site_qa_status * 1,
        biz_site_qa_status: formData?.biz_site_qa_status * 1,
        comment: formData?.biz_comment,
        dev_comment: formData?.dev_comment
      }
    }
    try {
      const response = await axios.put(SAVE_QA_STATUS_API, requestBody);
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        fetchFormData();
      }
    }
    catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  // Helper function to reset manual qa status
  async function onResetManualQAStatusButtonClickHandler() {
    try {
      const response = await axios.put(RESET_MANUAL_QA_STATUS_API);
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        setModalOpen(false);
        fetchFormData();
      }
    }
    catch (error) {
      toast.error(error?.response?.data?.message);
      setModalOpen(false);
    }
  }

  // Helper function to display modal
  function onModalToggleHandler() {
    setModalOpen(!modalOpen);
  }

  // Helper function to navigate using tabs
  function tabSelectionHandler(tabName) {
    setActiveTabName(tabName)
  }

  return (
    <Fragment>
      <Container fluid className='qa-reports-page-index-container'>
        <NewQaToolBreadcrumb
          ParentPath={'/internal/new_qa_reports'}
          ParentTitle={'All Sites'}
          pagePath={'/internal/new_qa_reports/pending'}
          PagePathTitle={'List of Sites Pending for QA'}
          siteName={site_name}
        />
        <SpinnerLoader loading={loading}>
          <Card style={{marginLeft: "auto", marginRight: "auto" }}>
            <CardBody>
              <Form>
                <Row className=''>
                  <Col md="6">
                    <FormGroup className="">
                      <Label className='fw-bold mb-1'>Site Name:</Label>
                      <Input type='text' readOnly value={site_name} className='form-control' />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="">
                      <Label className='fw-bold mb-1 '>Report ID:</Label>
                      <select
                        defaultValue={rssCrawlPushID}
                        onChange={(event) => setRssCrawlPushID(event?.target?.value)}
                        className='form-select show-arrow' >
                        <option key={"placeholder"} value={""} disabled hidden>{rssCrawlPushID.length > 0 ? "Select ID" : "No IDs Found"}</option>
                        {
                          formData.report_ids.map((data, indx) => (
                            <option key={indx} value={data?.value}>{data?.label}</option>
                          ))
                        }
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className=''>
                  <Col md="6">
                    <FormGroup className="mt-2">
                      <Label className='fw-bold mb-1'>Dev. Site QA Status:</Label>
                      <select
                        defaultValue={formData?.dev_site_qa_status}
                        name="dev_site_qa_status"
                        onChange={(event) => onInputFormInputChangeHandler(event)}
                        className='form-select show-arrow'
                      >
                        <option key={"placeholder"} value={""} disabled hidden>Select Dev. Qa Status</option>
                        {
                          QaStatuses?.map((data, indx) => (
                            <option key={indx} value={data?.value}>{data?.label}</option>
                          ))
                        }
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mt-2">
                      <Label className='fw-bold mb-1'>Biz. Site QA Status:</Label>
                      <select
                        disabled={!bizQaAccessible}
                        defaultValue={formData?.biz_site_qa_status}
                        name="biz_site_qa_status"
                        onChange={(event) => onInputFormInputChangeHandler(event)}
                        className='form-select show-arrow'
                      >
                        <option key={"placeholder"} value={""} disabled hidden>Select Biz. Qa Status</option>
                        {
                          QaStatuses?.map((data, indx) => (
                            <option key={indx} value={data?.value}>{data?.label}</option>
                          ))
                        }
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className=''>
                  <Col md="6">
                    <FormGroup className="mt-2">
                      <Label className='fw-bold mb-1'>Dev's comment:</Label>
                      <textarea
                        rows={1}
                        className='form-control'
                        value={formData?.dev_comment}
                        name="dev_comment"
                        onChange={(event) => onInputFormInputChangeHandler(event)}
                      ></textarea>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mt-2">
                      <Label className='fw-bold mb-1'>Biz's comment:</Label>
                      <textarea
                        disabled={!bizQaAccessible}
                        rows={1}
                        className='form-control'
                        value={formData?.biz_comment}
                        name="biz_comment"
                        onChange={(event) => onInputFormInputChangeHandler(event)}
                      ></textarea>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col md="6">
                    <Label className='mb-1'></Label>
                    <Button
                      color='success'
                      className='w-100 p-2 fw-bold'
                      onClick={onSaveQAStatusButtonClickHandler}
                    >
                      Save
                    </Button>
                  </Col>
                  <Col md="6">
                    <Label className='mb-1'></Label>
                    <Button
                      color='success'
                      className='w-100 p-2 fw-bold'
                      onClick={onModalToggleHandler}
                    >
                      Reset Manual QA Status
                    </Button>
                    <ResetQAStatusModal
                      isOpen={modalOpen}
                      toggle={onModalToggleHandler}
                      onSubmitHandler={onResetManualQAStatusButtonClickHandler}
                    />
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          <Card style={{ marginLeft: "auto", marginRight: "auto" }}>
            <CardBody style={{ padding: "20px" }}>
              <Nav horizontal='center' pills>
                <NavItem>
                  <NavLink
                    className={activeTabName === 'high_priority_data_check_reports' ? 'fw-bold text-white' : "text-dark bg-white"}
                    style={activeTabName === 'high_priority_data_check_reports' ? { backgroundColor: "#40A2E3" } : {}}
                    active={activeTabName === 'high_priority_data_check_reports' ? true : false}
                    onClick={() => tabSelectionHandler("high_priority_data_check_reports")}>
                    High Priority Data Check Reports
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTabName === 'all_data_check_reports' ? 'fw-bold text-white' : "text-dark bg-white"}
                    style={activeTabName === 'all_data_check_reports' ? { backgroundColor: "#40A2E3" } : {}}
                    active={activeTabName === 'all_data_check_reports' ? true : false}
                    onClick={() => tabSelectionHandler("all_data_check_reports")}>
                    All Data Check Reports
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTabName === 'raw_data_file_of_data_check_report' ? 'fw-bold text-white' : "text-dark bg-white"}
                    style={activeTabName === 'raw_data_file_of_data_check_report' ? { backgroundColor: "#40A2E3" } : {}}
                    active={activeTabName === 'raw_data_file_of_data_check_report' ? true : false}
                    onClick={() => tabSelectionHandler("raw_data_file_of_data_check_report")}>
                    Raw Data File Of Data Check Reports
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTabName === 'schema_reports' ? 'fw-bold text-white' : "text-dark bg-white"}
                    style={activeTabName === 'schema_reports' ? { backgroundColor: "#40A2E3" } : {}}
                    active={activeTabName === 'schema_reports' ? true : false}
                    onClick={() => tabSelectionHandler("schema_reports")}>
                    Schema Reports
                  </NavLink>
                </NavItem>
              </Nav>
              <Card>
                <CardBody>
                  <TabContent activeTab={activeTabName}>
                    <TabPane tabId={"high_priority_data_check_reports"}>
                      <HighPriorityDataCheckReports rssCrawlPushID={rssCrawlPushID} />
                    </TabPane>
                    <TabPane tabId={"all_data_check_reports"}>
                      <AllDataCheckReports rssCrawlPushID={rssCrawlPushID} />
                    </TabPane>
                    <TabPane tabId={"raw_data_file_of_data_check_report"}>
                      <RawDataFileForDataCheckReports rssCrawlPushID={rssCrawlPushID} />
                    </TabPane>
                    <TabPane tabId={"schema_reports"}>
                      <SchemaReport rssCrawlPushID={rssCrawlPushID} />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </SpinnerLoader>
      </Container>
    </Fragment>
  )
}
