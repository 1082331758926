import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";
import axios from "axios";
import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";


import { KIBANA_JP_URL } from "../../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function JpIndexProgress() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const searchParams = {
    hostName: new URLSearchParams(search).get("filter[host_name]"),
    fromTime: new URLSearchParams(search).get("filter[time]")
  }
  const [hostName, setHostName] = useState({label: 'All', value: '*'});
  const [queueSizes, setQueueSizes] = useState([]);
  const [hostNameOptions, setHostNameOptions] = useState([]);
  const [fromTime, setFromTime] = useState({ label: 'Today', value: 'now%2Fd' });
  const [loading, setLoading] = useState(false);

  const timeOptions = [
    { label: 'Today', value: 'now%2Fd' },
    { label: 'Last 30 minutes', value: 'now-30m' },
    { label: 'Last 1 hour', value: 'now-1h' },
    { label: 'Last 2 hours', value: 'now-2h' },
    { label: 'Last 4 hours', value: 'now-4h' },
    { label: "Last 10 hours", value: "now-10h" },
    { label: 'Last 12 hours', value: 'now-12h' },
    { label: 'Last 24 hours', value: 'now-24h' },
    { label: 'Last 2 days', value: 'now-2d' },
    { label: 'Last 3 days', value: 'now-3d' },
    { label: 'Last 4 days', value: 'now-4d' },
    { label: 'Last 7 days', value: 'now-7d' },
    { label: 'Last 15 days', value: 'now-15d' },
    { label: 'Last 30 days', value: 'now-30d' },
    { label: 'Last 60 days', value: 'now-60d' },
    { label: 'Last 1 year', value: 'now-1y' }
  ];

  const columns = [
    { name: 'Queue Name', selector: 'queue_name', sortable: true },
    { name: 'Queue Size', selector: 'queue_size', sortable: true },
    { name: 'Server Name', selector: 'server', sortable: true },
  ]

  const [UrlParams, setUrlParams] = useState({
    hostName: '*',
    fromTime: 'now%2Fd'
  }) 

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v1/internal/jp_index_progress')
      .then(res => {
        let machine_names = []
        res.data.machine_names.forEach(item => {
          machine_names.push({ label: item, value: item })
        })
        machine_names.push({ label: 'All', value: '*' })
        setHostNameOptions(machine_names);
        setQueueSizes(res.data.queue_sizes);
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      });
  }, []);

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.hostName && searchParams.hostName != hostName.value) {
      setHostName({ 
        label: searchParams.hostName == '*' ? 'All' : searchParams.hostName, 
        value: searchParams.hostName == "All"? "*" : searchParams.hostName
      });
    }
    if (searchParams.fromTime && searchParams.fromTime != fromTime.value) {
      setFromTime(timeOptions.filter(item => item.value == searchParams.fromTime)[0]);
    }
    if (commit == 'Get Data') {
      setUrlParams(searchParams)
    }
  }

  function KibanaUrl(){
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
    var host_name_val = UrlParams.hostName == "All" ? "*" : UrlParams.hostName;

    return `${KIBANA_JP_URL}app/kibana#/visualize/edit/60b1d4f0-a1ae-11ea-8910-5d7412b1fc26?_g=(refreshInterval:(pause:!t,value:0),time:(from:'${UrlParams.fromTime}',to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'hostname:${host_name_val}'),uiState:(),vis:(aggs:!((enabled:!t,id:'1',params:(),schema:metric,type:count),(enabled:!t,id:'2',params:(drop_partials:!f,extended_bounds:(),field:'@timestamp',interval:h,min_doc_count:1,scaleMetricValues:!f,timeRange:(from:now%2Fd,to:now%2Fd),useNormalizedEsInterval:!t),schema:segment,type:date_histogram),(enabled:!t,id:'3',params:(field:hostname,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:5),schema:group,type:terms)),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,categoryAxes:!((id:CategoryAxis-1,labels:(filter:!t,show:!t,truncate:100),position:bottom,scale:(type:linear),show:!t,style:(),title:(),type:category)),dimensions:(series:!((accessor:1,aggType:terms,format:(id:terms,params:(id:string,missingBucketLabel:Missing,otherBucketLabel:Other)),params:())),x:(accessor:0,aggType:date_histogram,format:(id:date,params:(pattern:'YYYY-MM-DD+HH:mm')),params:(bounds:(max:'2020-09-08T18:29:59.999Z',min:'2020-09-07T18:30:00.000Z'),date:!t,format:'YYYY-MM-DD+HH:mm',interval:PT1H,intervalESUnit:h,intervalESValue:1)),y:!((accessor:2,aggType:count,format:(id:number),params:()))),grid:(categoryLines:!f),labels:(show:!f),legendPosition:right,seriesParams:!((data:(id:'1',label:Count),drawLinesBetweenPoints:!t,lineWidth:2,mode:stacked,show:!t,showCircles:!t,type:histogram,valueAxis:ValueAxis-1)),thresholdLine:(color:%2334130C,show:!f,style:full,value:10,width:1),times:!(),type:histogram,valueAxes:!((id:ValueAxis-1,labels:(filter:!f,rotate:0,show:!t,truncate:100),name:LeftAxis-1,position:left,scale:(mode:normal,type:linear),show:!t,style:(),title:(text:Count),type:value))),title:'Jobspikr+Index+Progress+Graph',type:histogram))`
  }

  const handleGetData = () => {
    window.location.href = `/jobspikr_v3_internal/jobspikr_graphs/jp_index_progress?filter[host_name]=${hostName.value}&filter[time]=${fromTime.value}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/jobspikr_v3_internal/jobspikr_graphs/jp_index_progress">JP index progress</a> : 'JP index progress'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
          </BreadcrumbItem>}
        </Breadcrumb>
      </Col>
    </Row>
  </div>

  <Card>
    <CardBody>
      <DataTable
        columns={columns}
        data={queueSizes}
        noHeader
        pagination
        progressPending={loading}
      />
    </CardBody>
  </Card>

  <Card>
    <CardBody>
      <Row>
        <Col sm="4">
          <label className="fw-bold">Host Name</label>
          <Select
            value={hostName}
            options={hostNameOptions}
            onChange={option => setHostName(option)} />
        </Col>
        <Col sm="4">
          <label className="fw-bold">Time</label>
          <Select
            value={fromTime}
            options={timeOptions}
            onChange={option => setFromTime(option)} />
        </Col>
        <Col sm="4">
          <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
        </Col>
      </Row>
    </CardBody>
  </Card>
  <Card>
    <CardBody>
      <center>
        <a target="_blank" className="link-primary mb-2" href={KibanaUrl()}>Click here to see below graph in kibana</a><br/>
        <span className="mb-2"><b>Jobspikr_v3_data-*</b> - Hourly index count from both data api servers</span>
      </center>
      <iframe src={KibanaUrl() + "&embed=true"} height="500" width="100%" className="border-0"></iframe>
    </CardBody>
  </Card>
</Container>
}

