import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";
import axios from "axios";

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function SeleniumUsedAndFreeBrowserCountsAndWaitingRequestCounts() {
    const search = useLocation().search;
    const commit = new URLSearchParams(search).get("commit");
    const searchParams = {
        overAllTime: new URLSearchParams(search).get("overall_selenium_filter[time]"),
        geoTime: new URLSearchParams(search).get("geo_wise_selenium_filter[time]"),
        geoCluster: new URLSearchParams(search).get("geo_wise_selenium_filter[selenium_cluster]"),
        geoTab: new URLSearchParams(search).get("geo_wise_selenium_filter[tab_name]"),
        waitingTime: new URLSearchParams(search).get("waiting_graphs_selenium_filter[time]"),
        waitingCluster: new URLSearchParams(search).get("waiting_graphs_selenium_filter[selenium_cluster]"),
        waitingTab: new URLSearchParams(search).get("waiting_graphs_selenium_filter[tab_name]")
    }
    const [activeTab, setActiveTab] = useState('1');
    const [overAllTime, setOverAllTime] = useState({ label: 'Last 7 days', value: 'now-7d' });
    const [geoTime, setGeoTime] = useState({ label: 'Today', value: 'now%2Fd' });
    const [geoCluster, setGeoCluster] = useState({ label: 'All', value: '*' });
    const [waitingCluster,setWaitingCluster]=useState({ label: 'All', value: '*' });
    const [waitingTime,setWaitingTime]=useState({ label: 'Today', value: 'now%2Fd' })
    const [clustersOptions, setClustersOptions] = useState([]);
    const [urlParams, setUrlParams] = useState({
        overAllTime: 'now-7d',
        geoTime: 'now%2Fd',
        geoCluster: '*',
        waitingTime: 'now%2Fd',
        waitingCluster: '*'
    })
    const timeOptions = [
        { label: 'Today', value: 'now%2Fd' },
        { label: 'Last 30 minutes', value: 'now-30m' },
        { label: 'Last 1 hour', value: 'now-1h' },
        { label: 'Last 2 hours', value: 'now-2h' },
        { label: 'Last 4 hours', value: 'now-4h' },
        { label: "Last 10 hours", value: "now-10h" },
        { label: 'Last 12 hours', value: 'now-12h' },
        { label: 'Last 24 hours', value: 'now-24h' },
        { label: 'Last 2 days', value: 'now-2d' },
        { label: 'Last 3 days', value: 'now-3d' },
        { label: 'Last 4 days', value: 'now-4d' },
        { label: 'Last 7 days', value: 'now-7d' },
        { label: 'Last 15 days', value: 'now-15d' },
        { label: 'Last 30 days', value: 'now-30d' },
        { label: 'Last 60 days', value: 'now-60d' },
        { label: 'Last 1 year', value: 'now-1y' }
    ];

    useEffect(() => {
        axios.get('/api/v1/internal/dropdown_list_helper')
            .then(res => {
                let result = res.data.geo_clusters.map(item => ({ label: item, value: item }));
                result.push({ label: 'All', value: 'all' })
                setClustersOptions(result);
                console.log(result, 'api data')
            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong')
            });
        setSearchParamsData()
    }, []);

    useEffect(() => {
        console.log(urlParams, 'params at this phase')
    })

    function setSearchParamsData() {
        if (searchParams.overAllTime && searchParams.overAllTime != overAllTime.value && commit == 'Get Data') {
            console.log('first tab satisfoed')
            setActiveTab('1');
            setOverAllTime(timeOptions.filter(item => item.value == searchParams.overAllTime)[0]);
            setUrlParams({
                ...urlParams,
                overAllTime: searchParams.overAllTime
            })
        }
        if (searchParams.geoTab) {
            setActiveTab('2')
        }
        if (searchParams.geoCluster && searchParams.geoCluster != geoCluster.value) {
            setGeoCluster({
                label: searchParams.geoCluster == 'all'  ? 'All' : searchParams.geoCluster,
                value: searchParams.geoCluster
            })
        }
        if (searchParams.geoTime && searchParams.geoTime != geoTime.value) {
            setGeoTime(timeOptions.filter(item => item.value == searchParams.geoTime)[0])
        }
        if (searchParams.geoCluster && searchParams.geoTime && commit == 'Get Data') {
            setUrlParams({
                ...urlParams,
                geoTime: searchParams.geoTime,
                geoCluster: searchParams.geoCluster
            })
        }
        if (searchParams.waitingTab) {
            setActiveTab('3')
        }
        if (searchParams.waitingCluster && searchParams.waitingCluster != waitingCluster.value) {
            setWaitingCluster({
                label: searchParams.waitingCluster == 'all'  ? 'All' : searchParams.waitingCluster,
                value: searchParams.waitingCluster
            })
        }
        if (searchParams.waitingTime && searchParams.waitingTime != waitingTime.value) {
            setWaitingTime(timeOptions.filter(item => item.value == searchParams.waitingTime)[0])
        }
        if (searchParams.waitingCluster && searchParams.waitingTime && commit == 'Get Data') {
            setUrlParams({
                ...urlParams,
                waitingTime: searchParams.waitingTime,
                waitingCluster: searchParams.waitingCluster
            })
        }

    }

    const handleOverAllGetData = () => {
        window.location.href = `/internal/selenium/selenium_used_and_free_browser_counts_and_waiting_request_counts?overall_selenium_filter[time]=${overAllTime.value}&commit=${'Get Data'}`
    }

    const handleGeoGetData = () => {
        window.location.href = `/internal/selenium/selenium_used_and_free_browser_counts_and_waiting_request_counts?geo_wise_selenium_filter[selenium_cluster]=${geoCluster.value}&geo_wise_selenium_filter[time]=${geoTime.value}&geo_wise_selenium_filter[tab_name]=${'geo_wise_selenium_browser_status'}&commit=${'Get Data'}`
    }

    const handleWaitingGetData = () => {
        window.location.href = `/internal/selenium/selenium_used_and_free_browser_counts_and_waiting_request_counts?waiting_graphs_selenium_filter[selenium_cluster]=${waitingCluster.value}&waiting_graphs_selenium_filter[time]=${waitingTime.value}&waiting_graphs_selenium_filter[tab_name]=${'waiting_graphs'}&commit=${'Get+Data'}`

    }

    return <Container fluid>
        <div className="page-title">
            <Row className=''>
                <Col xs="6">
                    <h3>Internal Dashboard</h3>
                </Col>
                <Col xs="6">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={'/internal'}>
                                <Home />
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            {commit == 'Get Data' ? <a href="/internal/selenium/selenium_used_and_free_browser_counts_and_waiting_request_counts">Selenium Used and Free Browser Counts and Waiting Request Counts</a> : 'Selenium Used and Free Browser Counts and Waiting Request Counts'}
                        </BreadcrumbItem>
                        {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                            Search
                        </BreadcrumbItem>}
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
        <Card>
            <CardBody>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={activeTab == '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                            Overall Selenium Browser Status
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={activeTab == '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                            Geo-wise Selenium Browser Status
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={activeTab == '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                            Waiting graphs for selenium browsers across clusters
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={'1'}>
                        <div className="ibox-content m-b-sm border-bottom">
                            <Row className="align-items-end ">
                                <Col sm="4">
                                    <label className="fw-bold">Time</label>
                                    <Select
                                        value={overAllTime}
                                        options={timeOptions}
                                        onChange={option => setOverAllTime(option)}
                                    />
                                </Col>
                                <Col sm="4">
                                    <button className="btn btn-success w-100" onClick={handleOverAllGetData}>Get Data</button>
                                </Col>
                            </Row>
                        </div>
                        <div className="panel-body">
                            <center>
                                <span className="fw-bold">Overall Selenium Browser Status</span><br />
                                <a target="_blank" className="link-primary" href={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.overAllTime},mode:quick,to:${urlParams.overAllTime == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(language:lucene,query:(query_string:(analyze_wildcard:!t,query:'*'))),uiState:(vis:(colors:('Browers%20Used%20Count':%232F575E,'Free%20Browsers%20Count':%23C15C17,'Total%20Browsers%20Count':%23890F02))),vis:(aggs:!((id:'1',params:(customLabel:'Free%20Browsers%20Count',field:number_of_free_browsers),schema:metric,type:max),(id:'2',params:(customLabel:'Browers%20Used%20Count',field:number_of_occupied_browers),schema:metric,type:max),(id:'3',params:(customLabel:'Total%20Browsers%20Count',field:total_available_browsers),schema:metric,type:max),(id:'4',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:auto,min_doc_count:1),schema:segment,type:date_histogram),(id:'5',params:(customLabel:'Waiting%20Resquests%20Count',field:number_of_waiting_requests),schema:metric,type:max)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:'Overall%20Selenium%20Browser%20Status',type:line))`}>Click here to see below graph in kibana</a>
                            </center>
                            <iframe src={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.overAllTime},mode:quick,to:${urlParams.overAllTime == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(language:lucene,query:(query_string:(analyze_wildcard:!t,query:'*'))),uiState:(vis:(colors:('Browers%20Used%20Count':%232F575E,'Free%20Browsers%20Count':%23C15C17,'Total%20Browsers%20Count':%23890F02))),vis:(aggs:!((id:'1',params:(customLabel:'Free%20Browsers%20Count',field:number_of_free_browsers),schema:metric,type:max),(id:'2',params:(customLabel:'Browers%20Used%20Count',field:number_of_occupied_browers),schema:metric,type:max),(id:'3',params:(customLabel:'Total%20Browsers%20Count',field:total_available_browsers),schema:metric,type:max),(id:'4',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:auto,min_doc_count:1),schema:segment,type:date_histogram),(id:'5',params:(customLabel:'Waiting%20Resquests%20Count',field:number_of_waiting_requests),schema:metric,type:max)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:'Overall%20Selenium%20Browser%20Status',type:line))&embed=true`} height="800" width="100%" className="border-0"></iframe>
                        </div>
                    </TabPane>
                    <TabPane tabId={'2'}>
                        <div className="ibox-content m-b-sm border-bottom">
                            <Row className="align-items-end ">
                                <Col sm="4">
                                    <label className="fw-bold">Selenium Cluster</label>
                                    <Select
                                        value={geoCluster}
                                        options={clustersOptions}
                                        onChange={option => setGeoCluster(option)}
                                    />
                                </Col>
                                <Col sm="4">
                                    <label className="fw-bold">Time</label>
                                    <Select
                                        value={geoTime}
                                        options={timeOptions}
                                        onChange={option => setGeoTime(option)}
                                    />
                                </Col>
                                <Col sm="4">
                                    <button className="btn btn-success w-100" onClick={handleGeoGetData}>Get Data</button>
                                </Col>
                            </Row>
                        </div>
                        <div className="panel-body">
                            <center>
                                <span className="fw-bold">Geo-wise Selenium Browser Status</span><br />
                                <a target="_blank" className="link-primary" href={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.geoTime},mode:quick,to:${urlParams.geoTime == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(language:lucene,query:(query_string:(analyze_wildcard:!t,query:'%22Geo+wise+selenium+browser+status%22+AND+selenium_cluster:+%22${urlParams.geoCluster == 'all' ? '*' : urlParams.geoCluster}%22'))),uiState:(vis:(colors:('Free%20Browsers%20Count':%230A437C,'Waiting%20Requests%20Count':%23C15C17))),vis:(aggs:!((id:'1',params:(customLabel:'Free%20Browsers%20Count',field:free_browsers),schema:metric,type:avg),(id:'2',params:(customLabel:'Waiting%20Requests%20Count',field:waiting_requests),schema:metric,type:avg),(id:'3',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:auto,min_doc_count:1),schema:segment,type:date_histogram)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:'Geo-wise%20Selenium%20Browser%20Status',type:line))`}>Click here to see below graph in kibana</a>
                            </center>
                            <iframe src={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.geoTime},mode:quick,to:${urlParams.geoTime == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(language:lucene,query:(query_string:(analyze_wildcard:!t,query:'%22Geo+wise+selenium+browser+status%22+AND+selenium_cluster:+%22${urlParams.geoCluster== 'all' ? '*' : urlParams.geoCluster}%22'))),uiState:(vis:(colors:('Free%20Browsers%20Count':%230A437C,'Waiting%20Requests%20Count':%23C15C17))),vis:(aggs:!((id:'1',params:(customLabel:'Free%20Browsers%20Count',field:free_browsers),schema:metric,type:avg),(id:'2',params:(customLabel:'Waiting%20Requests%20Count',field:waiting_requests),schema:metric,type:avg),(id:'3',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:auto,min_doc_count:1),schema:segment,type:date_histogram)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:'Geo-wise%20Selenium%20Browser%20Status',type:line))&embed=true`} height="800" width="100%" className="border-0"></iframe>
                        </div>
                    </TabPane>
                    <TabPane tabId={'3'}>
                        <div className="ibox-content m-b-sm border-bottom">
                            <Row className="align-items-end ">
                                <Col sm="4">
                                    <label className="fw-bold">Selenium Cluster</label>
                                    <Select
                                        value={waitingCluster}
                                        options={clustersOptions}
                                        onChange={option => setWaitingCluster(option)}
                                    />
                                </Col>
                                <Col sm="4">
                                    <label className="fw-bold">Time</label>
                                    <Select
                                        value={waitingTime}
                                        options={timeOptions}
                                        onChange={option => setWaitingTime(option)}
                                    />
                                </Col>
                                <Col sm="4">
                                    <button className="btn btn-success w-100" onClick={handleWaitingGetData}>Get Data</button>
                                </Col>
                            </Row>
                        </div>
                        <div className="panel-body">
                            <center>
                                <span className="fw-bold">Waiting graphs for selenium browsers across clusters</span> <br />
                                <a target="_blank" className="link-primary" href={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.waitingTime},mode:quick,to:${urlParams.waitingTime == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(language:lucene,query:(query_string:(analyze_wildcard:!t,query:'%22Geo+wise+selenium+browser+status%22+AND+selenium_cluster:+%22${urlParams.waitingCluster == 'all' ? '*' : urlParams.waitingCluster}%22'))),uiState:(),vis:(aggs:!((id:'1',params:(field:waiting_requests),schema:metric,type:max),(id:'2',params:(customLabel:selenium_cluster,field:selenium_cluster.raw,order:desc,orderBy:'1',size:5),schema:group,type:terms),(id:'3',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:auto,min_doc_count:1),schema:segment,type:date_histogram)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:'Waiting%20Graphs%20For%20Selenium%20Browsers%20Across%20Clusters',type:line))`}>Click here to see below graph in kibana</a>
                            </center>
                            <iframe src={`${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:${urlParams.waitingTime},mode:quick,to:${urlParams.waitingTime == 'now%2Fd' ? 'now%2Fd' : 'now'}))&_a=(filters:!(),linked:!f,query:(language:lucene,query:(query_string:(analyze_wildcard:!t,query:'%22Geo+wise+selenium+browser+status%22+AND+selenium_cluster:+%22${urlParams.waitingCluster == 'all' ? '*' : urlParams.waitingCluster}%22'))),uiState:(),vis:(aggs:!((id:'1',params:(field:waiting_requests),schema:metric,type:max),(id:'2',params:(customLabel:selenium_cluster,field:selenium_cluster.raw,order:desc,orderBy:'1',size:5),schema:group,type:terms),(id:'3',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:auto,min_doc_count:1),schema:segment,type:date_histogram)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:'Waiting%20Graphs%20For%20Selenium%20Browsers%20Across%20Clusters',type:line))&embed=true`} height="800" width="100%" className="border-0"></iframe>
                        </div>
                    </TabPane>
                </TabContent>
            </CardBody>
        </Card>
    </Container>
}