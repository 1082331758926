import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Info } from 'react-feather';
import DataTable from 'react-data-table-component';

export default function ESBulkUpdatesTable({ data, loading }) {
  const columns = [
    {
      name: 'Queue Name',
      selector: 'queue_name',
      sortable: true
    },
    {
      name: 'Queue Size',
      selector: 'queue_size',
      sortable: true,
      cell: (each) => <>
        <a href={`/internal/queue_sizes/search?filter%5Bsite%5D=${each?.site_name}`}
          target='_blank' style={{ fontSize: 16 }}>{each?.queue_size}</a> <Info size={16} className='mx-2' data-tip data-for='goto_view' />
        <ReactTooltip id='goto_view' clickable effect='solid'>
          Go to Queue Size View
        </ReactTooltip>
      </>
    },
    {
      name: 'Server Name',
      selector: 'server',
      sortable: true
    },
  ];

  return (
    <div>
      <DataTable
        data={data}
        columns={columns}
        progressPending={loading}
        noHeader
        responsive
        pagination />
    </div>
  )
}
