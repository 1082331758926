import React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { SitesContext } from './SitesState';

export default function SitesContextProvider({ children }) {
    const { organization_name } = useParams();
    const [loading, setLoading] = useState(false);
    const [siteGroupNames, setSiteGroupNames] = useState([]);
    const [siteGroupID, setSiteGroupID] = useState("");
    const [country, setCountry] = useState("");
    const [siteGroupData, setSiteGroupData] = useState({});
    const [selectedSitesFromTable, setSelectedSitesFromTable] = useState([]);
    const siteStatusDropdownOptions = useMemo(() => {
        return [
            { value: "", label: "All" },
            { value: "under_review", label: "Under Review" },
            { value: "report_generated", label: "Report Generated" },
            { value: "payment_pending", label: "Payment Pending" },
            { value: "queued", label: "Queued" },
            { value: "setup_in_progress", label: "Setup In Progress" },
            { value: "waiting_on_client", label: "Waiting on Client" },
            { value: "active", label: "Active" },
            { value: "pause_requested", label: "Pause Requested" },
            { value: "paused", label: "Paused" },
            { value: "archived", label: "Archived" },
        ]
    }, []);

    // Use effect hook for when the organization name changes
    useEffect(async () => {
        const controller = new AbortController();

        await getSiteGroupName(`/api/v1/organizations/${organization_name}/sitegroups?from=site_list`, controller.signal);

        // cleanup
        return () => {
            controller.abort();
        }
    }, [organization_name])

    // Use effect hook for when the site group ID changes
    // This use-effect is triggered when the sitegroupId changes
    // It populates the sitegroupData state with schema details of site for each individual Id
    useEffect(async () => {
        const controller = new AbortController();

        await getSchemaDetailsByID(siteGroupID, controller.signal);

        // cleanup
        return () => {
            controller.abort();
        }
    }, [siteGroupID])

    // Function to get all the sitegroup names from the DB
    async function getSiteGroupName(BASE_URL, signal) {
        setLoading(true);
        try {
            const response = await axios.get(BASE_URL, { signal: signal });
            const siteNames = await response?.data?.sitegroups;
            const country = await response?.data?.currency_code;
            setSiteGroupNames(siteNames);
            setCountry(country);
            setLoading(false);
        }
        catch (error) {
            toast.error(error?.response?.data?.message);
            setLoading(false);
        }
    }
    // Function to get sitegroup schema data from DB
    // This function fetched the schema details for specific sitegroup based on details
    async function getSchemaDetailsByID(sitegroupID, signal) {
        setLoading(true);
        try {
            const response = await axios.get(`/api/v1/organizations/${organization_name}/sitegroups/${sitegroupID}`, { signal: signal });
            const sitegroupData = await response?.data?.sitegroup;
            setSiteGroupData(sitegroupData);
            setLoading(false);
        }
        catch (error) {
            toast.error(error?.response?.data?.message);
            setLoading(false);
        }
    }

    // Custom hook to set sitegroup ID
    function setSitegroupId(siteId) {
        setSiteGroupID(siteId)
    }

    // Cutom hook to get the IDs of selected rows from the data table
    function TableSelection(arr_) {
        setSelectedSitesFromTable(arr_);
    }

    // Value passed to context
    const sitesContextValue = {
        loading: loading,
        siteGroupNames: siteGroupNames,
        sitegroupId: siteGroupID,
        country: country,
        sitegroupData: siteGroupData,
        setgroupId: setSitegroupId,
        siteStatus: siteStatusDropdownOptions,
        tableSelection:selectedSitesFromTable,
        selectionFromTable:TableSelection
    }
    return (
        <div>
            <SitesContext.Provider value={sitesContextValue}>
                {children}
            </SitesContext.Provider>
        </div>
    )
}
