import React, {Fragment, useEffect, useState} from 'react'
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, CardBody, Card } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Home, Info, RefreshCcw } from 'react-feather';
import AsyncSelect from 'react-select/async';
import axios from 'axios'
import DataTable from 'react-data-table-component';
import ReactTooltip from 'react-tooltip';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';

export default function RabbitmqQueueSizes() {
    const [dropdownOptions, setDropdownOptions] = useState({})
    const [selectedSite, setSelectedSite] = useState({value: '', label: 'All'})
    const [data, setData] = useState({})
    const [tableData, setTableData] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchSiteNames();
        fetchData()
    }, [])

    function fetchData(){
        setLoading(true)
        axios.get(`/api/v1/internal/queue_sizes`).then(res => {
            console.log('res.data', res.data)
            setData(res.data)
            setLoading(false)
        }).catch(err =>{
            console.log(err?.message || 'Oops something went wrong')
            setLoading(false)
        })
    }

    function fetchSiteNames() {
        axios.get('/api/v1/internal/dropdown_list_helper')
          .then(res => {
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
              site_names[key].map(item => {
                children.push({ label: item, value: item })
              })
              group['options'] = children;
              site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setDropdownOptions(site_names_options)
          })
          .catch(err => {
            console.error(err?.message || 'Oops something went wrong')
          })
    }

    const promiseOptions = (inputValue) => {
        if(inputValue.length < 3){
          return null
        } else {
          return new Promise((resolve) => {
            axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
              if (res.status == '200'){
                let site_names = res.data.site_names, site_names_options = []
                for (var key in site_names) {
                  let group = {}, children = []
                  group['label'] = <div className="fw-bold col text-black">{key}</div>
                  site_names[key].map(item => {
                    children.push({ label: item, value: item })
                  })
                  group['options'] = children;
                  site_names_options.push(group)
                }
                site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                setDropdownOptions(site_names_options)
                resolve(site_names_options)
              }
            }).catch(err => {
              console.log(`err`, err)
            })
          })
        }
    }

    useEffect(() => {
        setTableData(data?.queue_sizes)
    }, [data])    

    useEffect(() => {
        if (searchValue){
            let res = []
            data?.queue_sizes.forEach(each => {
              if (each?.queue_name?.includes(searchValue) || each?.server?.toString()?.includes(searchValue)
                || each?.queue_size?.toString()?.includes(searchValue)){
                res.push(each)
              }
            })
            setTableData(res)
          } else {
            setTableData(data?.queue_sizes)
          }
    }, [searchValue])
    
    

    const columns = [
        {name: 'Queue Name',selector: 'queue_name',center:true, sortable: true, grow: 4, cell: row => <>
            <a className='d-flex justify-content-center' data-tip data-for='info'
            href={`/internal/queue_sizes/${row.queue_name}`} target='_blank'>
                {row.queue_name} <Info size={18} className='mx-2' /> </a>
            <ReactTooltip id='info'>
                <p>Click this to see detailed stats </p>
            </ReactTooltip>
        </>},
        {name: 'Queue Size',selector: 'queue_size',center:true, sortable: true, grow: 2, cell: each => <div className='d-flex align-items-center justify-content-between'>
            <p className='my-0 me-2'>{each?.queue_size}</p>
            <a href={`/internal/queue_sizes/${each?.queue_name}?server_name=${each?.server}`} target='_blank'>
                <RefreshCcw size={14} /> </a>
        </div>},
        {name: 'Server Name',selector: 'server',center:true, sortable: true },
        {name: 'Actions',selector: 'action',center:true, cell: row => <button className='btn btn-xs btn-danger' onClick={()=>handleDelete(row.queue_name)} >Delete Queue</button>},
    ]

    const getData = () => {
        window.location.href= `/internal/queue_sizes/search?utf8=✓&filter%5Bsite%5D=${selectedSite?.value}&commit=Get+Data`
    }

    const handleDelete = queue_name =>{
        console.log('Executed....')
        axios.delete(`/api/v1/internal/queue_sizes/${queue_name}`).then(res =>{
            toast.success(res.data?.message || 'Successfully Added')
            fetchData()
        }).catch(err => {
            toast.error('Oops something went wrong')
            console.log(`err`, err)
          })
    }

    return (
        <Container fluid>
            <Fragment>
                <Container fluid>
                    <div className="page-title">
                        <Row className=''>
                            <Col xs="6">
                                <h3>Internal Dashboard</h3>
                            </Col>
                            <Col xs="6">
                            <Breadcrumb>
                                <BreadcrumbItem>
                                    <Link to={`/internal`}><Home /></Link>
                                </BreadcrumbItem>
                                <BreadcrumbItem active>
                                    <b>Queue Size</b>
                                </BreadcrumbItem>
                            </Breadcrumb>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </Fragment>

            <Card>
                <CardBody>
                    <Row className='my-3'>
                        <Col md={10}>
                            <h6 className='fw-bold'>Site Name: </h6>
                            <AsyncSelect cacheOptions defaultOptions={dropdownOptions} name='site_name' 
                                value={selectedSite} loadOptions={promiseOptions} onChange={res => setSelectedSite(res)}
                                placeholder="Select a site name" />
                                <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
                        </Col>
                        <Col md={2}>
                            <button className='btn btn-success btn-block text-center' 
                                style={{margin: '26px auto', width: '100%'}} onClick={getData}>Get Data</button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            { loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
            : <Card className='my-5'>
                <CardBody>
                    <h6>
                        <b>Note: </b> <Info size={14} /> indicates the hover is there. It has some informations or links
                    </h6>
                    <h6>Queues sizes from <b> All RabbitMQ server </b> </h6>
                    <Row className='mb-2'>
                        <Col md={7}></Col>
                        <Col md={5}>
                        <div className='d-flex justify-content-center align-items-center'>
                            <h6 className='my-0 me-2'>Search:</h6>
                            <input type='text' className='form-control' value={searchValue} 
                            onChange={(e) => setSearchValue(e.target?.value)} />
                        </div>
                        </Col>
                    </Row>
                    <DataTable columns={columns} data={tableData} noHeader responsive className='my-3' 
                        progressPending={loading}
                        pagination responsive dense />
                </CardBody>
            </Card>}
        </Container>
    )
}
