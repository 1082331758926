import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from 'react-feather';
import AsyncSelect from 'react-select/async';
import moment from "moment";
import axios from "axios";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";

export default function Index() {
  const search = useLocation().search;
  const now = new Date();
  const commit = new URLSearchParams(search).get("commit");
  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[site_name]"),
    fromDate: new URLSearchParams(search).get("filter[from]"),
    toDate: new URLSearchParams(search).get("filter[to]"),
  }
  const [siteName, setSiteName] = useState({label: 'All', value: 'all'});
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(7, 'days')));
  const [toDate, setToDate] = useState(new Date());
  const [siteNameOptions, setSiteNameOptions] = useState([]);
  const [missingFields, setMissingFields] = useState([]);
  const [loading, setLoading] = useState(false);

  const tableColumns = [
    { name: 'Site Name', selector: 'site_name', sortable: true },
    { 
      name: 'Missing Fields and Counts', 
      selector: 'missing_fields_arr', 
      sortable: true,
      cell: (row, index) => (
        <>
          {row.missing_fields_arr.length > 0 ? 
          <>
            <ul className="list-group">
              {row.missing_fields_arr.map((hash, index) => (
                <li>
                  <span>{hash.count}</span><br/>
                  {`${index + 1} ) ${hash.field_name}`}
                </li>
              ))}
            </ul>
          </> : "No data available"
          }
        </>
)
    },
    { 
      name: 'URLs',
      selector: 'urls', 
      sortable: true,
      cell: (row, index) => (
        <>
          {row.urls.length > 0 ? 
          <>
            <ul className="list-group">
              {row.urls.map((url, index) => (
                <li>{`${index + 1} ) ${url}`}</li>
              ))}
            </ul>
          </>
          : "No data available"
          }
        </>
)
    },
  ]

  const [UrlParams, setUrlParams] = useState({
    siteName: "all",
    fromDate: new Date(moment().subtract(7, 'days')),
    toDate: now,
  })

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v1/internal/all_site_names')
      .then(res => {
        let site_names = res.data.site_names, site_names_options = []
        for (var key in site_names) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
          site_names[key].map(item => {
            children.push({ label: item, value: item })
          })
          group['options'] = children;
          site_names_options.push(group)
        }
        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
        setSiteNameOptions(site_names_options)
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      })
  }, []);

  const promiseOptions = (inputValue) => {
    console.log("inputValue", inputValue);
    return new Promise((resolve) => {
      console.log("inputValue", inputValue);
      axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
        if (res.status == '200'){
          let site_names = res.data.site_names, site_names_options = []
          for (var key in site_names) {
            let group = {}, children = []
            group['label'] = <div className="fw-bold col text-black">{key}</div>
            site_names[key].map(item => {
              children.push({ label: item, value: item })
            })
            group['options'] = children;
            site_names_options.push(group)
          }
          site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
          setSiteNameOptions(site_names_options)
          resolve(site_names_options)
        }
      }).catch(err => {
        console.log(`err`, err)
      })
    })
  }

  function setSearchParamsData() {
    // console.log("searchParams", searchParams);
    if (searchParams.siteName && searchParams.siteName != siteName.value) {
      setSiteName({ label: searchParams.siteName == "all" ? "All" : searchParams.siteName, value: searchParams.siteName });
    }
    if (searchParams.fromDate && searchParams.fromDate != fromDate) {
      setFromDate(new Date(searchParams.fromDate));
    }
    if (searchParams.toDate && searchParams.toDate != toDate) {
      setToDate(new Date(searchParams.toDate));
    }
    if (commit == 'Get Data') {
      setUrlParams(searchParams)
      fetchDataWithParams();
    }
    else {
      fetchData();
    }
  }

  function fetchDataWithParams() {
    setLoading(true)
    axios.get(`/api/v1/internal/missing_fields?filter[site_name]=${searchParams.siteName}&filter[from]=${moment(searchParams.fromDate).format('YYYY/MM/DD')}&filter[to]=${moment(searchParams.toDate).format('YYYY/MM/DD')}`)
      .then(res => {
        // console.log("missing_fields", res.data.missing_fields);
        setMissingFields(res.data.missing_fields);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  function fetchData() {
    setLoading(true)
    axios.get(`/api/v1/internal/missing_fields`)
      .then(res => {
        console.log("missing_fields", res.data.missing_fields);
        setMissingFields(res.data.missing_fields);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  const handleGetData = () => {
    window.location.href = `/internal/missing_fields?filter[site_name]=${siteName.value}&filter[from]=${moment(fromDate).format('YYYY/MM/DD')}&filter[to]=${moment(toDate).format('YYYY/MM/DD')}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/missing_fields">Missing Fields</a> : 'Missing Fields'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
          </BreadcrumbItem>}
        </Breadcrumb>
      </Col>
    </Row>
  </div>

  <Card>
    <CardBody>
      <Row>
        <Col sm="12">
          <label className="fw-bold">Site Name</label>
          <AsyncSelect cacheOptions defaultOptions={siteNameOptions} name='site_name' 
            value={siteName} loadOptions={promiseOptions} onChange={res => setSiteName(res)}
            placeholder="Select a site name" isClearable={true}/>
            <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
        </Col>
      </Row>
      <Row className="align-items-end mt-2">
        <Col sm="4">
          <label className="fw-bold">From Date</label>
          <DatePicker
            className="form-control"
            selected={new Date(fromDate)}
            dateFormat={"YYY/MM/dd"}
            onChange={date => setFromDate(date)} />
        </Col>
        <Col sm="4">
          <label className="fw-bold">To Date</label>
          <DatePicker
            className="form-control"
            selected={new Date(toDate)}
            dateFormat={"YYY/MM/dd"}
            maxDate={new Date()}
            onChange={date => setToDate(date)} />
        </Col>
        <Col sm="4">
          <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
        </Col>
      </Row>
    </CardBody>
  </Card>
  <Card>
    <CardBody>
      <Row className="align-items-end mt-2">
        {
          loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
          :
          <DataTable
            columns={tableColumns}
            data={missingFields}
            noHeader
            pagination
            progressPending={loading}
          />
        }
      </Row>
    </CardBody>
  </Card>
</Container>
}
