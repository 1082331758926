/**
 * This is the table that shows all the invoices for the client
 * It shows the feasibility report number, invoice id and a button that redirects user to the invoice details page
 * It is rendered in the Billing.index component 
 */
import React from 'react';
import { useContext } from 'react';
import { OrgnaziationContext } from '../../../../Home';
import { Calendar, File, Info, CheckCircle } from 'react-feather'
import { toast } from 'react-toastify';
import axios from 'axios';

export default function InvoiceList({ data }) {

  // The name of the organization
  const { orgName } = useContext(OrgnaziationContext);

  // Data passed through props
  const listData = data;

  const requestToDownload = (invoiceId) => {
    axios.get(`/api/v1/organizations/${orgName}/invoices/${invoiceId}/request_to_download`, {}).then(res => {
      toast.success(res.data.message)
      setToggle(curr => !curr)
    }).catch(err => {
      toast.error(err.response.data.message)
    })
  }

  const requestToPay = (invoiceId) => {
    axios.get(`/api/v1/organizations/${orgName}/invoices/${invoiceId}/request_to_pay`, {}).then(res => {
      toast.success(res.data.message)
      setToggle(curr => !curr)
    }).catch(err => {
      toast.error(err.response.data.message)
    })
  }

  // If the invoices are available, display them in the list 
  if (listData?.length > 0) {
    return (
      <>
        {
          listData?.map(data => (
            <div className="my-3 border-radius-0"
              style={{ borderBottom: "1px solid #ccc", paddingBottom: 30 }}
              key={data?.id}>
              <h5 className="d-flex align-center" >
                <span className="me-2">
                  {data?.invoice_status === "paid" ? <CheckCircle fontSize={20} className="text-success" key={data?.id}/>
                      : <Info fontSize={20} className="text-dark" key={data?.id} />}
                    </span>
                    <a href={`/organizations/${orgName}/invoices/${data?.id}`} className="text-primary">
                      {data?.invoice_title}
                    </a>
                  </h5>
                  <p>
                    {data?.description}
                  </p>
                  <div className="d-flex justify-content-between align-center mt-2">
                    <div className="d-flex">
                      <h6 className="text-muted me-3">
                        <File size={16} key={data?.id} /> #{data?.invoice_number}
                      </h6>
                      <h6 className="text-muted">
                        <Calendar size={16} key={data?.id} /> {data?.created_at}
                      </h6>
                    </div>
                    <div>
                      {
                        data?.invoice_status == "paid" ?
                        <>
                          <button className="btn btn-success me-2">
                            {"Paid ✓"}
                          </button>
                          {
                            data?.service_type == "quickbooks" ? <button className="btn btn-primary me-2" onClick={() => requestToDownload(data.id)}>Request to download</button>
                              :
                              <a href={`/organizations/${orgName}/invoices/${data?.id}`} className="btn btn-success text-white">
                                View
                              </a>
                          } 
                        </> 
                        : 
                        <>
                          { 
                            data?.service_type == "quickbooks" ? <button className="btn btn-primary me-2" onClick={() => requestToPay(data.id)}>Request to pay</button>
                              : 
                              <a href={`/organizations/${orgName}/invoices/${data?.id}`} className="btn btn-primary text-white">
                                View & Proceed
                              </a>
                          } 
                        </>
                      }
                    </div>
                  </div>
                </div>
          ))
        }
      </>
    )
  }
  // otherwise display a message that says "No Invoice available"
  else {
    return (
      <>
        <p>No Invoices Available</p>
      </>
    )
  }
}
