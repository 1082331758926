import React, {useState, useEffect} from 'react'
import { Container, Row, Col } from 'reactstrap'
import axios from 'axios'
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';

export default function PopulatedDependentData() {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [dropdownOptions, setDropdownOptions] = useState({})
    const [selectedSite, setSelectedSite] = useState({})

    useEffect(() => {
        fetchSiteNames();
    }, [])
    
    function fetchSiteNames() {
        axios.get('/api/v1/internal/dropdown_list_helper')
        .then(res => {
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
            let group = {}, children = []
            group['label'] = <div className="fw-bold col text-black">{key}</div>
            site_names[key].map(item => {
                children.push({ label: item, value: item })
            })
            group['options'] = children;
            site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setDropdownOptions(site_names_options)
        })
        .catch(err => {
            console.error(err?.message || 'Oops something went wrong')
        })
    }
      
    const promiseOptions = (inputValue) => {
        if(inputValue.length < 3){
            return null
        } else {
            return new Promise((resolve) => {
            axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
                if (res.status == '200'){
                let site_names = res.data.site_names, site_names_options = []
                for (var key in site_names) {
                    let group = {}, children = []
                    group['label'] = <div className="fw-bold col text-black">{key}</div>
                    site_names[key].map(item => {
                    children.push({ label: item, value: item })
                    })
                    group['options'] = children;
                    site_names_options.push(group)
                }
                site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                setDropdownOptions(site_names_options)
                resolve(site_names_options)
                }
            }).catch(err => {
                    console.log(`err`, err)
                })
            })
        }
    }

    const handleSubmit = () => {
        setLoading(true)
        setErrors({})
        let tempObj = {}
        if (!(selectedSite?.value)){
            tempObj = {...tempObj, site:true}
        }
        setErrors(tempObj)

        if (Object.keys(tempObj)?.length == 0){
            let resultObj = {
                "site_name": selectedSite?.value, 
                "outer_command": "dependent_data_upload",
                "inner_command": "dependent_data_upload",
                "nested_inner_command": "dependent_data_upload",
            }
            axios.post('/api/v1/internal/command_dashboard/dependent_data_upload', {
                filter: resultObj
            }).then(res => {
                toast.success(res?.data?.message)
                setLoading(false)
                window.location = `/internal/command_dashboard`
            }).catch(err => {
                toast.error(err?.response?.data?.message)
                setLoading(false)
            })
        }
        else {
            setLoading(false)
        }
    }
    return (
        <Container fluid>
            <Row>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Outer Command (Non-Editable)</h6>
                    <input className='form-control' value='dependent_data_upload'
                        disabled />
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Inner Command (Non-Editable)</h6>
                    <input className='form-control' value='dependent_data_upload'
                        disabled />
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Nested Inner Command (Non-Editable)</h6>
                    <input className='form-control' value='dependent_data_upload'
                        disabled />
                </Col>
                <Col md={12} className='my-3'>
                    <h6 className='fw-bold'>Site Name*</h6>
                    <AsyncSelect cacheOptions defaultOptions={dropdownOptions} name='site_name' 
                        value={selectedSite} loadOptions={promiseOptions} onChange={res => setSelectedSite(res)}
                        placeholder="Select a site name" />
                    {errors?.site && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                    <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
                </Col>
                <Col md={6} className='my-3'>
                    <button className='btn btn-success' onClick={handleSubmit}
                        disabled={loading} style={{margin: '25px auto', width: '100%'}}> Submit </button>
                </Col>
            </Row>
        </Container>
    )
}
