import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";
import axios from "axios";

import { KIBANA_PLM_URL } from "../../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function JpInferredLocationErrorBySite() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[site_name]"),
    errorSubType: new URLSearchParams(search).get("filter[error_sub_type]"),
    fromTime: new URLSearchParams(search).get("filter[time]")
  }
  const [siteName, setSiteName] = useState({label: 'All', value: '*'});
  const [siteNameOptions, setSiteNameOptions] = useState([]);
  const [errorSubType, setErrorSubType] = useState({label: 'All', value: '*'});
  const [fromTime, setFromTime] = useState({ label: 'Today', value: 'now%2Fd' });

  const timeOptions = [
    { label: 'Today', value: 'now%2Fd' },
    { label: 'Last 30 minutes', value: 'now-30m' },
    { label: 'Last 1 hour', value: 'now-1h' },
    { label: 'Last 2 hours', value: 'now-2h' },
    { label: 'Last 4 hours', value: 'now-4h' },
    { label: "Last 10 hours", value: "now-10h" },
    { label: 'Last 12 hours', value: 'now-12h' },
    { label: 'Last 24 hours', value: 'now-24h' },
    { label: 'Last 2 days', value: 'now-2d' },
    { label: 'Last 3 days', value: 'now-3d' },
    { label: 'Last 4 days', value: 'now-4d' },
    { label: 'Last 7 days', value: 'now-7d' },
    { label: 'Last 15 days', value: 'now-15d' },
    { label: 'Last 30 days', value: 'now-30d' },
    { label: 'Last 60 days', value: 'now-60d' },
    { label: 'Last 1 year', value: 'now-1y' }
  ];

  const errorSubTypeOptions = [
    { label: 'All', value: '*' },
    { label: 'city_not_found', value: 'city_not_found' },
    { label: 'state_not_found', value: 'state_not_found' },
    { label: 'country_not_found', value: 'country_not_found' }
  ];

  const [UrlParams, setUrlParams] = useState({
    siteName: '*',
    errorSubType: '*',
    fromTime: 'now%2Fd'
  })

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v1/internal/jobspikr_sites')
      .then(res => {
        let site_names = []
        res.data.site_names.forEach(item => {
          site_names.push({ label: item, value: item })
        })
        site_names.push({ label: 'All', value: '*' })
        setSiteNameOptions(site_names);
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      });
  }, []);

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.siteName && searchParams.siteName != siteName.value) {
      setSiteName({ 
        label: searchParams.siteName == '*' ? 'All' : searchParams.siteName, 
        value: searchParams.siteName == "All"? "*" : searchParams.siteName
      });
    }
    if (searchParams.errorSubType && searchParams.errorSubType != errorSubType.value) {
      setErrorSubType({ 
        label: searchParams.errorSubType == '*' ? 'All' : searchParams.errorSubType, 
        value: searchParams.errorSubType == "All"? "*" : searchParams.errorSubType
      });
    }
    if (searchParams.fromTime && searchParams.fromTime != fromTime.value) {
      setFromTime(timeOptions.filter(item => item.value == searchParams.fromTime)[0]);
    }
    if (commit == 'Get Data') {
      setUrlParams(searchParams)
    }
  }

  function KibanaUrl(){
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
    var site_name_val = UrlParams.siteName == "All" ? "*" : UrlParams.siteName;
    var error_subType_val = UrlParams.errorSubType == "All" ? "*" : UrlParams.errorSubType;

    return `${KIBANA_PLM_URL}app/kibana#/visualize/edit/f974daf0-9670-11ea-8b4a-69464b0a274d?_g=(refreshInterval:(pause:!t,value:0),time:(from:'${UrlParams.fromTime}',to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'*jobspikr_inferred_location_error*+and+site_name.raw:${site_name_val}+and+error_sub_type.raw:${error_subType_val}'))`
  }

  const handleGetData = () => {
    window.location.href = `/jobspikr_v3_internal/jobspikr_graphs/jp_inferred_location_error_by_site?filter[site_name]=${siteName.value}&filter[time]=${fromTime.value}&filter[error_sub_type]=${errorSubType.value}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/jobspikr_v3_internal/jobspikr_graphs/jp_inferred_location_error_by_site">Jobspikr Inferred Location Error By Site Specific</a> : 'Jobspikr Inferred Location Error By Site Specific'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
          </BreadcrumbItem>}
        </Breadcrumb>
      </Col>
    </Row>
  </div>

  <Card>
    <CardBody>
      <Row>
        <Col sm="6">
          <label className="fw-bold">Site Name</label>
          <Select
            value={siteName}
            options={siteNameOptions}
            onChange={option => setSiteName(option)} />
            <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
        </Col>
        <Col sm="3">
          <label className="fw-bold">Time</label>
          <Select
            value={fromTime}
            options={timeOptions}
            onChange={option => setFromTime(option)} />
        </Col>
        <Col sm="3">
          <label className="fw-bold">Error Sub Type</label>
          <Select
            value={errorSubType}
            options={errorSubTypeOptions}
            onChange={option => setErrorSubType(option)} />
        </Col>
      </Row>
      <Row className="align-items-end mt-5">
        <center>
          <Col sm="4">
            <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
          </Col>
        </center>
      </Row>
    </CardBody>
  </Card>
  <Card>
    <CardBody>
      <center>
        <a target="_blank" className="link-primary mb-2" href={KibanaUrl()}>Click here to see below graph in kibana</a><br/>
      </center>
      <iframe src={KibanaUrl() + "&embed=true"} height="500" width="100%" className="border-0"></iframe>
    </CardBody>
  </Card>
</Container>
}

