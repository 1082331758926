export const fieldValueSampleResponseData = {
  "product_category": {
    "llm_value": "Apparel",
    "data_type_matched": true,
    "drl_data_type": "String",
    "llm_data_type": "String"
  },
  "product_brand": {
    "llm_value": "U.S. POLO ASSN.",
    "data_type_matched": true,
    "drl_data_type": "String",
    "llm_data_type": "String"
  },
  "product_name": {
    "llm_value": "U.S. POLO ASSN. Brand Taped Cotton Polo Dress",
    "data_type_matched": true,
    "drl_data_type": "String",
    "llm_data_type": "String"
  },
  "product_price": {
    "llm_value": "₹954.00",
    "data_type_matched": true,
    "drl_data_type": "Float",
    "llm_data_type": "Float"
  },
  "sku": {
    "llm_value": "UGDRS0208",
    "data_type_matched": true,
    "drl_data_type": "String",
    "llm_data_type": "String"
  },
  "product_description": {
    "llm_value": "Add a dash of colour to your little one's wardrobe with this cute and trendy pick from the Classic collection.",
    "data_type_matched": true,
    "drl_data_type": "String",
    "llm_data_type": "String"
  },
  "product_currency": {
    "llm_value": "₹",
    "data_type_matched": true,
    "drl_data_type": "String",
    "llm_data_type": "String"
  },
  "product_image_url": {
    "llm_value": "https://m.media-amazon.com/images/I/31eLJogxxRL._SX342_SY445_.jpg",
    "data_type_matched": true,
    "drl_data_type": "String",
    "llm_data_type": "String"
  },
  "product_model_number": {
    "llm_value": "UGDRS0208",
    "data_type_matched": true,
    "drl_data_type": "String",
    "llm_data_type": "String"
  },
  "product_contents": {
    "llm_value": "Care Instructions: Machine Wash\nFit Type: Regular\nPolo collar\nShort button placket\nShort sleeves\nRibbed collar and sleeve hems\nBrand taping at waist",
    "data_type_matched": true,
    "drl_data_type": "String",
    "llm_data_type": "String"
  },
  "bsr": {
    "llm_value": "23,576 in Girls' Dresses & Jumpsuits",
    "data_type_matched": true,
    "drl_data_type": "String",
    "llm_data_type": "String"
  }
}

export const xpathsWithTwoStepsSampleResponseData = {
  "product_category": {
    "llm_value": "Apparel",
    "xpath": "//div[@id='wayfinding-breadcrumbs_feature_div']//span[@class='a-list-item'][last()]//a[@class='a-link-normal a-color-tertiary']",
    "disk_value": "Fashion",
    "data_type_matched": true,
    "drl_data_type": "String",
    "llm_data_type": "String",
    "disk_llm_matching_score": 42.86
  },
  "product_brand": {
    "llm_value": "U.S. POLO ASSN.",
    "xpath": "//div[@id='titleBlock']//div[@id='bylineInfo_feature_div']//a[@id='bylineInfo']",
    "disk_value": "Visit the U.S. POLO ASSN. Store",
    "data_type_matched": true,
    "drl_data_type": "String",
    "llm_data_type": "String",
    "disk_llm_matching_score": 73.91
  },
  "product_name": {
    "llm_value": "U.S. POLO ASSN. Brand Taped Cotton Polo Dress",
    "xpath": "//div[@id='titleBlock']//div[@id='titleSection']//span[@id='productTitle']",
    "disk_value": "U.S. POLO ASSN. Brand Taped Cotton Polo Dress",
    "data_type_matched": true,
    "drl_data_type": "String",
    "llm_data_type": "String",
    "disk_llm_matching_score": 100
  },
  "product_price": {
    "llm_value": "₹954.00",
    "xpath": "//div[@id='corePriceDisplay_desktop_feature_div']//span[@class='a-price-whole']",
    "disk_value": "954",
    "data_type_matched": true,
    "drl_data_type": "Float",
    "llm_data_type": "Float",
    "disk_llm_matching_score": 77.78
  },
  "sku": {
    "llm_value": "UGDRS0208",
    "xpath": "//div[@id='detailBullets_feature_div']//li[contains(span[@class='a-text-bold'], 'ASIN')]//span[last()]",
    "disk_value": null,
    "data_type_matched": false,
    "drl_data_type": "String",
    "llm_data_type": "NilClass",
    "disk_llm_matching_score": 0
  },
  "product_description": {
    "llm_value": "Add a dash of colour to your little one's wardrobe with this cute and trendy pick from the Classic collection.",
    "xpath": "//div[@id='productDescription_feature_div']//div[@id='productDescription']//span",
    "disk_value": "Add a dash of colour to your little one's wardrobe with this cute and trendy pick from the Classic collection.",
    "data_type_matched": true,
    "drl_data_type": "String",
    "llm_data_type": "String",
    "disk_llm_matching_score": 100
  },
  "product_currency": {
    "llm_value": "₹",
    "xpath": "//div[@id='corePriceDisplay_desktop_feature_div']//span[@class='a-price-symbol']",
    "disk_value": "₹",
    "data_type_matched": true,
    "drl_data_type": "String",
    "llm_data_type": "String",
    "disk_llm_matching_score": 100
  },
  "product_image_url": {
    "llm_value": "https://m.media-amazon.com/images/I/31eLJogxxRL._SX342_SY445_.jpg",
    "xpath": "//div[@id='imageBlock_feature_div']//div[@id='imgTagWrapperId']//img[@id='landingImage']/@src",
    "disk_value": "https://m.media-amazon.com/images/I/31eLJogxxRL._SX342_SY445_.jpg",
    "data_type_matched": true,
    "drl_data_type": "String",
    "llm_data_type": "String",
    "disk_llm_matching_score": 100
  },
  "product_model_number": {
    "llm_value": "UGDRS0208",
    "xpath": "//div[@id='detailBullets_feature_div']//li[contains(span[@class='a-text-bold'], 'Item model number')]//span[last()]",
    "disk_value": null,
    "data_type_matched": false,
    "drl_data_type": "String",
    "llm_data_type": "NilClass",
    "disk_llm_matching_score": 0
  },
  "product_contents": {
    "llm_value": "Care Instructions: Machine Wash\nFit Type: Regular\nPolo collar\nShort button placket\nShort sleeves\nRibbed collar and sleeve hems\nBrand taping at waist",
    "xpath": "//div[@id='featurebullets_feature_div']//ul[@class='a-unordered-list a-nostyle a-vertical a-spacing-mini']//span[@class='a-list-item']",
    "disk_value": null,
    "data_type_matched": false,
    "drl_data_type": "String",
    "llm_data_type": "NilClass",
    "disk_llm_matching_score": 0
  },
  "bsr": {
    "llm_value": "23,576 in Girls' Dresses & Jumpsuits",
    "xpath": "//div[@id='detailBullets_feature_div']//li[contains(span[@class='a-text-bold'], 'Best Sellers Rank')]//span[@class='a-list-item'][last()]",
    "disk_value": null,
    "data_type_matched": false,
    "drl_data_type": "String",
    "llm_data_type": "NilClass",
    "disk_llm_matching_score": 0
  }
}

export const xpathsByValueSampleResponseData = {
  "sku": {
    "xpath": "//span[@id='productTitle']/text()",
    "disk_value": "U.S. POLO ASSN. Brand Taped Cotton Polo Dress",
    "input_value": "UGDRS0208",
    "data_type_matched": true,
    "drl_data_type": "String",
    "llm_data_type": "String",
    "disk_llm_matching_score": 42.22
  },
  "product_category": {
    "xpath": "//a[contains(text(),'Apparel')]/text()",
    "disk_value": "Apparel - Buy more save more",
    "input_value": "Apparel",
    "data_type_matched": true,
    "drl_data_type": "String",
    "llm_data_type": "String",
    "disk_llm_matching_score": 81.25
  }
}