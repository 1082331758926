import React from 'react';
import BreadCRumb from './BreadCrumb/BreadCrumb.component';
import { OVERALL_DEADUP_URL } from './KibanaEndpoints/KibanaEndpoints';
import OverallDeadupStatusTab from './Tabs/OverallDeadupStatusTab.component';
import { useEffect, useState } from "react";
import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useLocation } from "react-router-dom";
import { Row, Col } from 'reactstrap';
import moment from "moment";
import axios from "axios";
import JobStatusTable from './DataTables/JobStatusTable.component';
import { KIBANA_JP_URL } from '../../../../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints';


import { NewJpInternalBasePoint } from '../../../../../Common/BaseEsPoints/InternalEsBasePoint';

// PAGE URL
export const API_PREFIX = `${NewJpInternalBasePoint}/jobspikr_graphs/dedup_tracker`;
// Search Query
export const SEARCH_QUERY = `?filter[from_date]=$[fromDate]&filter[to_date]=$[toDate]&filter[tab_name]=overall_dedup_status&commit=Get Data`;

export default function DedupTracker() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const tabNameFromSearchUrl = new URLSearchParams(search).get("filter[tab_name]");
  const tabNameFromUrl = window.location.hash.substr(1);
  const searchParams = {
    uniqId: new URLSearchParams(search).get("filter[uniq_id]"),
    fromDate: new URLSearchParams(search).get("filter[from_date]"),
    toDate: new URLSearchParams(search).get("filter[to_date]"),
    tabName: tabNameFromSearchUrl ? tabNameFromSearchUrl : tabNameFromUrl
  }
  const now = new Date();
  const [uniqId, setUniqId] = useState("");
  const [rssQueueInfo, setRssQueueInfo] = useState([]);
  const [queueSizeInfo, setQueueSizeInfo] = useState([]);
  const [siteRssCount, setSiteRssCount] = useState([]);
  const [jobStatusData, setJobStatusData] = useState([]);
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [activeTab, setActiveTab] = useState(tabNameFromUrl ? tabNameFromUrl : 'overall_dedup_status');
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(7, 'days')));
  const [toDate, setToDate] = useState(now);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSearchParamsData()
  }, []);

  function fetchOverallDedupStatusData(from_date = fromDate, to_date = toDate) {
    setLoading(true)
    axios.get(`/api/v2/jobspikr_v3_internal/jobspikr_graphs/dedup_tracker?filter[from_date]=${moment(from_date).format('YYYY/MM/DD')}&filter[to_date]=${moment(to_date).format('YYYY/MM/DD')}`)
      .then(res => {
        setRssQueueInfo(res.data.rss_queue_info);
        setQueueSizeInfo(res.data.queue_size_info);
        setSiteRssCount(res.data.site_rss_count);
        setUniqId(res.data.uniq_id);
        setJobStatusData(res.data.job_status_data);
        setFromTime(res.data.from_time);
        setToTime(res.data.to_time);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  function fetchJobStatusByUniqIdData(uniq_id = uniqId) {
    setLoading(true)
    axios.get(`/api/v2/jobspikr_v3_internal/jobspikr_graphs/dedup_tracker?filter[uniq_id]=${uniq_id}`)
      .then(res => {
        setRssQueueInfo(res.data.rss_queue_info);
        setQueueSizeInfo(res.data.queue_size_info);
        setSiteRssCount(res.data.site_rss_count);
        setUniqId(res.data.uniq_id);
        setJobStatusData(res.data.job_status_data);
        setFromTime(res.data.from_time);
        setToTime(res.data.to_time);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.fromDate && searchParams.fromDate != fromDate) {
      setFromDate(new Date(searchParams.fromDate));
    }
    if (searchParams.toDate && searchParams.toDate != toDate) {
      setToDate(new Date(searchParams.toDate));
    }
    if (searchParams.tabName && searchParams.tabName != activeTab) {
      setActiveTab(searchParams.tabName);
    }
    if (commit == 'Get Data' && searchParams.fromDate && searchParams.toDate) {
      setActiveTab('overall_dedup_status');
      fetchOverallDedupStatusData(searchParams.fromDate, searchParams.toDate)
    }
    if (commit == 'Get Data' && searchParams.uniqId) {
      setActiveTab('job_status_by_uniq_id');
      fetchJobStatusByUniqIdData(searchParams.uniqId);
    }
    else {
      fetchOverallDedupStatusData(fromDate, toDate);
    }
  }

  function jobStatusByUniqIdKibanaUrl() {
    return `${KIBANA_JP_URL}app/kibana#/discover?_g=(refreshInterval:(pause:!t,value:0),time:(from:now-30d,to:now))&_a=(columns:!(_source),index:'1bfae8a0-47e6-11ea-8910-5d7412b1fc26',interval:auto,query:(language:kuery,query:'uniq_id:+%22${uniqId}%22'),sort:!(!('@timestamp',desc)))`
  }

  const handleInputChange = event => {
    setUniqId(event.target.value);
  }

  const handleJobStatusByUniqIdData = () => {
    window.location.href = `${NewJpInternalBasePoint}/jobspikr_graphs/dedup_tracker?filter[uniq_id]=${uniqId}&filter[tab_name]=job_status_by_uniq_id&commit=${'Get Data'}`
  }

  return (
    <>
      <BreadCRumb />
      <Card>
        <CardBody>
          <Nav tabs>
            <NavItem>
              <NavLink className={activeTab == 'overall_dedup_status' ? 'active' : ''} onClick={() => setActiveTab('overall_dedup_status')}>
                Overall Dedup Status & RSS counts
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={activeTab == 'job_status_by_uniq_id' ? 'active' : ''} onClick={() => setActiveTab('job_status_by_uniq_id')}>
                Job Status By Uniq Id
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId={'overall_dedup_status'}>
              <OverallDeadupStatusTab
                kibanaUrl={OVERALL_DEADUP_URL.replace('$[fromTime]', fromTime).replace('$[toTime]', toTime)}
                rssQueueInfo={rssQueueInfo}
                queueSizeInfo={queueSizeInfo}
                siteRssCount={siteRssCount}
                loading={loading} />
            </TabPane>
            <TabPane tabId={'job_status_by_uniq_id'}>
              <div className="ibox-content m-b-sm border-bottom">
                <Row className="align-items-end mb-3">
                  <Col sm="6">
                    <label className="fw-bold">Uniq Id</label>
                    <input type="text" className="form-control" value={uniqId} onChange={handleInputChange} />
                  </Col>
                  <Col sm="3">
                    <button className="btn btn-success w-100" onClick={handleJobStatusByUniqIdData}>Get Data</button>
                  </Col>
                </Row>
              </div>
              <div className="panel-body">
                {
                  jobStatusData.length > 0 ?
                    <>
                      <center><span className="fw-bold">Job Status</span></center><br />
                      <p>Index: <b>jobspikr_v3_data-*</b></p>
                      <center>
                        <a target="_blank" className="link-primary" href={jobStatusByUniqIdKibanaUrl()}>Click here to see below graph in kibana</a>
                      </center>
                    </>
                    : ""
                }
                <Row className="align-items-end ">
                  <JobStatusTable data={jobStatusData} loading={loading} />
                </Row>
              </div>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </>
  )
}
