import React, { useState, useEffect } from 'react'
import { Card, CardHeader, CardBody, Col, Row, Button } from 'reactstrap'
import Loader from 'react-loader-spinner';
import axios from 'axios'
import { toast } from 'react-toastify';
import MyAccordion from '../../../global/MyAccordion'
import { AlertTriangle, ArrowDown } from 'react-feather';
import DataTable from 'react-data-table-component'

export default function DataChecksReports({ site_name, apiEndpoint }) {

  const [data, setData] = useState();
  const [reportAvailableFields, setReportAvailableFields] = useState();
  const [reportMissingFields, setReportMissingFields] = useState();
  const [availableFieldsCount, setAvailableFieldsCount] = useState();
  const [MissingFieldsCount, setMissingFieldsCount] = useState();
  const [qaReportFilePresent, setQaReportFilePresent] = useState(false)
  const [qaReportFileName, setQaReportFileName] = useState("")
  const [loading, setLoading] = useState(true);
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [rssCrawlPushId, setRssCrawlPushId] = useState("NA");
  const [searchResults,setSearchResults]=useState([])
  const [searchText,setSearchText]=useState('')

  useEffect(() => {
    setLoading(true)
    axios.get(apiEndpoint).then(res => {
      setData(res?.data?.data_check_reports?.reports)
      setSearchResults(res?.data?.data_check_reports?.reports);
      setReportAvailableFields(res?.data?.data_check_reports?.report_available_fields)
      setReportMissingFields(res?.data?.data_check_reports?.report_missing_fields)
      setAvailableFieldsCount(res?.data?.data_check_reports?.no_of_fields_covered)
      setMissingFieldsCount(res?.data?.data_check_reports?.no_of_missing_fields)
      setRssCrawlPushId(res?.data?.data_check_reports?.rss_crawl_push_id)
      setQaReportFilePresent(res?.data?.qa_report_file_present)
      setQaReportFileName(res?.data?.qa_report_file_name)
      setIsDataAvailable(res?.data?.is_data_available)
      setLoading(false)
    }).catch(err => {
      toast.error(err.response?.data?.message)
      setLoading(false)
    })
  }, [site_name, apiEndpoint])

  const CSV_FILE_DOWNLOAD_API_ENDPOINT = `/api/v1/internal/new_qa_reports/${site_name}/rss_crawl_push_id/${rssCrawlPushId}/download_qa_reports`

  const downloadCsvGzFile = (apiEndpoint, fileName) => {
    if (qaReportFilePresent) {
      axios
        .get(apiEndpoint, {
          responseType: 'blob',
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/gzip' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          toast.error(err?.message)
        });
    }
  };

  const columnNames = [
    { 
      name: "Column Name", 
      selector: row => row?.column_name,
      sortable: true, 
      responsive: true,
      width: "30%",
    },
    { 
      name: "Report Name", 
      selector: row => row?.report_name,
      sortable: true, 
      responsive: true,
      width: "30%",
    },
    { 
      name: "Report Values", 
      selector: row => (
        <pre>{JSON.stringify(row?.values, null, 4)}</pre>
      ), 
      sortable: false, 
      responsive: true,
      wrap: true,
    }
  ]

  const handleInputChange = event => {
    if (event.target.value == '') {
      setSearchResults(data)
    }
    else {
      setSearchResults(data.filter(item => item.column_name.includes(event.target.value) || item.report_name.toString().includes(event.target.value)))
    }
    setSearchText(event.target.value);
  }


  return (
    <>
      {
        loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
          :
          <Card className='my-3'>
            <CardBody style={{padding: '0px'}} className='data-checks-reports'>
              { isDataAvailable ? 
              <>
                <div className="row" style={{padding: '20px'}}>
                  <div className="col">
                    Rss Crawl Push Id: <strong>{rssCrawlPushId ? rssCrawlPushId : "NA"}</strong>
                  </div>
                  <div className="col">
                    <Button
                      color='success'
                      disabled={!qaReportFilePresent}
                      onClick={()=> downloadCsvGzFile(CSV_FILE_DOWNLOAD_API_ENDPOINT, qaReportFileName)}
                      className='btn-xs fw-bold d-flex justify-content-center align-items-center pull-right'>
                      {
                        qaReportFilePresent ? <><ArrowDown size={14} className='mx-1' />Download QA Report</> : <><AlertTriangle size={14} className='mx-1' /> Report File Not Found </>
                      }
                    </Button>
                  </div>
                </div>
                <div style={{ position: "sticky", top: 0, backgroundColor: "white", zIndex:1, padding: "10px" }}>
                  <Row>
                    <hr />
                    <p><strong>Available columns in Data check reports (No.of fields: {availableFieldsCount}):</strong> {reportAvailableFields}</p>
                    <hr />
                  </Row>
                  <Row>
                    <hr />
                    <p><strong>Missing columns in Data check reports (No.of fields: {MissingFieldsCount}):</strong> {reportMissingFields}</p>
                    <hr />
                  </Row>
                </div>
                <Row className="my-3">
                  <Col>
                    <div className="float-end">
                      <Row className="align-items-center">
                        <Col sm="3">Search</Col>
                        <Col>
                          <input type="text" className="form-control" value={searchText} onChange={handleInputChange} />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <DataTable 
                    columns={columnNames} 
                    data={searchResults} 
                    responsive noHeader striped highlightOnHover pagination 
                  />
                </Row>
              </>
                  :
                  <p>No data available!</p>
              }
            </CardBody>
          </Card>
      }
    </>
  )
}
