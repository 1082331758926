import React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import StepZilla from "react-stepzilla";
import SiteDetails from '../Stepzilla/Steps/SiteDetails/SiteDetails.component';
import SchemaDetails from '../Stepzilla/Steps/SchemaDetails/SchemaDetails.component';
import AdditionalDetails from '../Stepzilla/Steps/AdditionalDetails/AdditionalDetails.component';

export default function Stepzilla({ environment }) {

  const {organization_name} = useParams();
  const [siteDetails, setSiteDetails] = useState([]);
  const [newRows, setNewRows] = useState(['row1', 'row2', 'row3', 'row4']);
  const [schemaDetails, setSchemaDetails] = useState({
    sgName: '', crawlType: '', dataDelivery: 'api',
    dataformat: '', freqSelection: '', freqMonthly: '',
    freqWeekly: '', freqOther: '', rssUrlsCrawlLimit: '', schemaFields: [],
  });

  const steps = [
    {
      name: "Schema Details",
      component: <SchemaDetails nextStep={true} environment={environment} schemaDetails={schemaDetails} setSchemaDetails={setSchemaDetails} />
    },
    {
      name: "Site Details",
      component: <SiteDetails
        nextStep={true}
        newRows={newRows}
        setNewRows={setNewRows}
        siteDetails={siteDetails}
        environment={environment}
        setSiteDetails={setSiteDetails}
        rssUrlsCrawlLimit={schemaDetails.rssUrlsCrawlLimit} />
    },
    {
      name: "Additional Details",
      component: <AdditionalDetails
        nextStep={true}
        siteDetails={siteDetails}
        schemaDetails={schemaDetails}
        organization_name={organization_name} />
    },
  ];

  return (
    <div>
      <StepZilla
        steps={steps}
        showSteps={true}
        dontValidate={false}
        showNavigation={true}
        stepsNavigation={true}
        prevBtnOnLastStep={true} />
    </div>
  )
}
