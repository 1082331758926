import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";
import moment from "moment";
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import axios from "axios";

import { KIBANA_PLM_URL } from "../../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function OverallPendingSegments() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const tabNameFromSearchUrl = new URLSearchParams(search).get("filter[tab_name]");
  const tabNameFromUrl = window.location.hash.substr(1);
  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[site_name]"),
    pipelineStage: new URLSearchParams(search).get("filter[pipeline_stage]"),
    hostName: new URLSearchParams(search).get("filter[host_name]"),
    fromTime: new URLSearchParams(search).get("filter[time]"),
    runDate: new URLSearchParams(search).get("filter[date]"),
    tabName: tabNameFromSearchUrl ? tabNameFromSearchUrl : tabNameFromUrl
  }
  const [siteName, setSiteName] = useState({label: 'All', value: '*'});
  const [siteNameOptions, setSiteNameOptions] = useState([]);
  const [hostName, setHostName] = useState({label: 'All', value: '*'});
  const [hostNameOptions, setHostNameOptions] = useState([]);
  const [pipelineStage, setPipelineStage] = useState({ label: 'All', value: '*' });
  const [fromTime, setFromTime] = useState({ label: '', value: 'nothing_selected' });
  const [activeTab, setActiveTab] = useState(tabNameFromUrl ? tabNameFromUrl : 'overall_pending_segments');
  const [runDate, setRunDate] = useState(null);

  const pipelineStageOptions = [
    { label: 'All', value: '*' },
    { label: 'Extraction Pending', value: 'extraction_pending' },
    { label: 'Dedup Pending', value: 'dedup_pending' },
    { label: 'Normalization Pending', value: 'normalization_pending' },
    { label: 'Upload Pending', value: 'upload_pending' },
  ]

  const timeOptions = [
    { label: "", value: 'nothing_selected'},
    { label: 'Today', value: 'now%2Fd' },
    { label: 'Last 30 minutes', value: 'now-30m' },
    { label: 'Last 1 hour', value: 'now-1h' },
    { label: 'Last 2 hours', value: 'now-2h' },
    { label: 'Last 4 hours', value: 'now-4h' },
    { label: "Last 10 hours", value: "now-10h" },
    { label: 'Last 12 hours', value: 'now-12h' },
    { label: 'Last 24 hours', value: 'now-24h' },
    { label: 'Last 2 days', value: 'now-2d' },
    { label: 'Last 3 days', value: 'now-3d' },
    { label: 'Last 4 days', value: 'now-4d' },
    { label: 'Last 7 days', value: 'now-7d' },
    { label: 'Last 15 days', value: 'now-15d' },
    { label: 'Last 30 days', value: 'now-30d' },
    { label: 'Last 60 days', value: 'now-60d' },
    { label: 'Last 1 year', value: 'now-1y' }
  ];

  const [UrlParams, setUrlParams] = useState({
    siteName: "*",
    pipelineStage: '*',
    hostName: '*',
    fromTime: 'nothing_selected',
    runDate: '*',
    tabName: "overall_pending_segments"
  })

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let site_names = res.data.site_names, site_names_options = []
        for (var key in site_names) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
            site_names[key].map(item => {
              children.push({ label: item, value: item })
            })
          group['options'] = children;
          site_names_options.push(group)
        }
        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: '*' }] })
        setSiteNameOptions(site_names_options);
        resolve(site_names_options)
       
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      });
  }, []);

  function titleize(value){
    return value.substr(0,1).toUpperCase() + value.substr(1).toLowerCase();
  }

  function pipelineStageLabel(){
    var labelValue;
    if (searchParams.pipelineStage == '*'){
      labelValue = "All";
    }
    else if (searchParams.pipelineStage == 'diskfetcher'){
      labelValue = "Feedcrawl";
    }
    else {
      labelValue = titleize(searchParams.pipelineStage);
    }
    return labelValue;
  }

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.siteName && searchParams.siteName != siteName.value) {
      setSiteName({ label: searchParams.siteName  == '*' ? 'All' :  searchParams.siteName, value: searchParams.siteName });
    }
    if (searchParams.hostName && searchParams.hostName != hostName.value) {
      setHostName({ label: searchParams.hostName == '*' ? 'All' :  searchParams.hostName, value: searchParams.hostName });
    }
    if (searchParams.pipelineStage && searchParams.pipelineStage != pipelineStage.value) {
      setPipelineStage({
        label: pipelineStageLabel(),
        value: searchParams.pipelineStage
      });
    }
    if (searchParams.fromTime && searchParams.fromTime != fromTime.value) {
      setFromTime(timeOptions.filter(item => item.value == searchParams.fromTime)[0]);
    }
    if (searchParams.tabName && searchParams.tabName != activeTab) {
      setActiveTab(searchParams.tabName);
    }
    if (searchParams.runDate && searchParams.runDate != runDate) {
      setRunDate(new Date(searchParams.runDate));
    }
    if (commit == 'Get Data') {
      setUrlParams(searchParams)
    }
  }

  function modifyRunDate(date){
    console.log("date", date);
    var converted_date = date == "*" ?  "*" : moment(date).format("YYYYMMDD"); 
    console.log("converted_date", converted_date);
    return converted_date;
  }

  function overallPendingSegmentsKibanaUrl(){
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
    var from_time_val = UrlParams.fromTime == "nothing_selected" ? "now%2Fd" : UrlParams.fromTime;

    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=line&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(pause:!t,value:0),time:(from:'${from_time_val}',to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'custom.raw+:+%22pending_segments_tracker%22+and+site_name.raw+:${UrlParams.siteName}+and+status.raw+:${UrlParams.pipelineStage}+and+host.name.raw+:${UrlParams.hostName}+and+date.raw+:${modifyRunDate(UrlParams.runDate)}+'),uiState:(),vis:(aggs:!((id:'1',params:(field:segment_count),schema:metric,type:sum),(id:'2',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:custom,min_doc_count:1),schema:segment,type:date_histogram),(id:'3',params:(customLabel:pipeline_status,field:status.raw,order:desc,orderBy:'1',size:20),schema:group,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:'Overall+Pending+Segments',type:line))`
  }

  function siteWisePendingSegmentsKibanaUrl(){
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
    var from_time_val = UrlParams.fromTime == "nothing_selected" ? "now-30m" : UrlParams.fromTime;

    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(pause:!t,value:0),time:(from:'${from_time_val}',to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'custom.raw+:+%22pending_segments_tracker%22+and+site_name.raw+:${UrlParams.siteName}+and+status.raw+:${UrlParams.pipelineStage}+and+host.name.raw+:${UrlParams.hostName}+and+date.raw+:${modifyRunDate(UrlParams.runDate)}+'),uiState:(),vis:(aggs:!((id:'1',params:(customLabel:segment_count,field:segment_count),schema:metric,type:sum),(id:'2',params:(customLabel:site_name,field:site_name.raw,order:desc,orderBy:'1',size:20),schema:segment,type:terms),(id:'3',params:(customLabel:date,field:date.raw,order:desc,orderBy:'1',size:10),schema:group,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Site+Wise+Pending+Segments',type:histogram))`
  }

  function siteWisePendingSegmentsByPipelineStageKibanaUrl(){
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
    var from_time_val = UrlParams.fromTime == "nothing_selected" ? "now-30m" : UrlParams.fromTime;

    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(pause:!t,value:0),time:(from:'${from_time_val}',to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'custom.raw+:+%22pending_segments_tracker%22+and+site_name.raw+:${UrlParams.siteName}+and+status.raw+:${UrlParams.pipelineStage}+and+host.name.raw+:${UrlParams.hostName}+and+date.raw+:${modifyRunDate(UrlParams.runDate)}+'),uiState:(),vis:(aggs:!((id:'1',params:(customLabel:'Segment+Count',field:segment_count),schema:metric,type:sum),(id:'2',params:(customLabel:'Site+Name',field:site_name.raw,order:desc,orderBy:'1',size:20),schema:segment,type:terms),(id:'3',params:(customLabel:'Pipeline+Stage',field:status.raw,order:desc,orderBy:'1',size:10),schema:group,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Site+Wise+Pending+Segments+By+Pipeline+Stage',type:histogram))`
  }

  function siteWisePendingSegmentsByHostKibanaUrl(){
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
    var from_time_val = UrlParams.fromTime == "nothing_selected" ? "now-30m" : UrlParams.fromTime;

    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(pause:!t,value:0),time:(from:'${from_time_val}',to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'custom.raw+:+%22pending_segments_tracker%22+and+site_name.raw+:${UrlParams.siteName}+and+status.raw+:${UrlParams.pipelineStage}+and+host.name.raw+:${UrlParams.hostName}+and+date.raw+:${modifyRunDate(UrlParams.runDate)}+'),uiState:(),vis:(aggs:!((id:'1',params:(customLabel:'Segment+Count',field:segment_count),schema:metric,type:sum),(id:'2',params:(customLabel:'Site+Name',field:site_name.raw,order:desc,orderBy:'1',size:20),schema:segment,type:terms),(id:'3',params:(customLabel:Host,field:host.name.raw,order:desc,orderBy:'1',size:10),schema:group,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Site+Wise+Pending+Segments+By+Host',type:histogram))`
  }

  function hostWisePendingSegmentsKibanaUrl(){
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
    var from_time_val = UrlParams.fromTime == "nothing_selected" ? "now-30m" : UrlParams.fromTime;

    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(pause:!t,value:0),time:(from:'${from_time_val}',to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'custom.raw+:+%22pending_segments_tracker%22+and+site_name.raw+:${UrlParams.siteName}+and+status.raw+:${UrlParams.pipelineStage}+and+host.name.raw+:${UrlParams.hostName}+and+date.raw+:${modifyRunDate(UrlParams.runDate)}+'),uiState:(),vis:(aggs:!((id:'1',params:(customLabel:segment_count,field:segment_count),schema:metric,type:sum),(id:'2',params:(customLabel:host_name,field:host.name.raw,order:desc,orderBy:'1',size:20),schema:segment,type:terms),(id:'3',params:(customLabel:date,field:date.raw,order:desc,orderBy:'1',size:10),schema:group,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Host+Wise+Pending+Segments',type:histogram))`
  }

  function hostWisePendingSegmentsByPipelineStageKibanaUrl(){
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
    var from_time_val = UrlParams.fromTime == "nothing_selected" ? "now-30m" : UrlParams.fromTime;

    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(pause:!t,value:0),time:(from:'${from_time_val}',to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'custom.raw+:+%22pending_segments_tracker%22+and+site_name.raw+:${UrlParams.siteName}+and+status.raw+:${UrlParams.pipelineStage}+and+host.name.raw+:${UrlParams.hostName}+and+date.raw+:${modifyRunDate(UrlParams.runDate)}+'),uiState:(),vis:(aggs:!((id:'1',params:(customLabel:'Segment+Count',field:segment_count),schema:metric,type:sum),(id:'2',params:(customLabel:'Host+Name',field:host.name.raw,order:desc,orderBy:'1',size:20),schema:segment,type:terms),(id:'3',params:(customLabel:'Pipeline+Stage',field:status.raw,order:desc,orderBy:'1',size:10),schema:group,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Host+Wise+Pending+Segments+By+Pipeline+Stage',type:histogram))`
  }

  function hostWisePendingSegmentsBySiteKibanaUrl(){
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
    var from_time_val = UrlParams.fromTime == "nothing_selected" ? "now-30m" : UrlParams.fromTime;

    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=8c44ed90-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(pause:!t,value:0),time:(from:'${from_time_val}',to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'custom.raw+:+%22pending_segments_tracker%22+and+site_name.raw+:${UrlParams.siteName}+and+status.raw+:${UrlParams.pipelineStage}+and+host.name.raw+:${UrlParams.hostName}+and+date.raw+:${modifyRunDate(UrlParams.runDate)}+'),uiState:(),vis:(aggs:!((id:'1',params:(customLabel:'Segment+Count',field:segment_count),schema:metric,type:sum),(id:'2',params:(customLabel:'Host+Name',field:host.name.raw,order:desc,orderBy:'1',size:20),schema:segment,type:terms),(id:'3',params:(customLabel:Site,field:site_name.raw,order:desc,orderBy:'1',size:10),schema:group,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Host+Wise+Pending+Segments+By+Site',type:histogram))`
  }

  const handleGetData = () => {
    window.location.href = `/internal/overall_system_reports/overall_pending_segments?filter[site_name]=${siteName.value}&filter[pipeline_stage]=${pipelineStage.value}&filter[host_name]=${hostName.value}&filter[time]=${fromTime.value}&filter[date]=${moment(runDate).format('YYYY/MM/DD')}&filter[tab_name]=${activeTab}&commit=${'Get Data'}`
  }

  const promiseOptions = (inputValue) => {
    if (inputValue.length < 3) {
      return null
    } else {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200') {
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
              site_names[key].map(item => {
                children.push({ label: item, value: item })
              })
              group['options'] = children;
              site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setSiteNameOptions(site_names_options)
            resolve(site_names_options)
          }
        }).catch(err => {
          console.log(`err`, err)
        })
      })
    }
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/overall_system_reports/overall_pending_segments">Overall Pending Segments</a> : 'Overall Pending Segments'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                Search
                </BreadcrumbItem>}
          </Breadcrumb>
        </Col>
      </Row>
    </div>

    <Card>
      <CardBody>
        <Row>
          <Col sm="8">
            <label className="fw-bold">Site Name</label>
            <AsyncSelect cacheOptions defaultOptions={siteNameOptions} name='site_name'
              value={siteName} loadOptions={promiseOptions} onChange={res => setSiteName(res)}
              placeholder="Select a site name" />
              <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
          </Col>
          <Col sm="4">
            <label className="fw-bold">Time</label>
            <Select
              value={fromTime}
              options={timeOptions}
              onChange={option => setFromTime(option)} />
          </Col>
        </Row>
        <Row className="align-items-end mt-2">
          <Col sm="3">
            <label className="fw-bold">Pipeline Stage</label>
            <Select
              value={pipelineStage}
              options={pipelineStageOptions}
              onChange={option => setPipelineStage(option)} />
          </Col>
          <Col sm="3">
            <label className="fw-bold">Host Name</label>
            <Select
              value={hostName}
              options={hostNameOptions}
              onChange={option => setHostName(option)} />
          </Col>

          <Col sm="3">
            <label className="fw-bold">Run Date</label>
            <DatePicker
              className="form-control"
              selected={runDate ? new Date(runDate) : runDate }
              dateFormat={"YYY/MM/dd"}
              maxDate={new Date()}
              onChange={date => setRunDate(date)} />
          </Col>
          <Col sm="3">
            <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
          </Col>
        </Row>
      </CardBody>
    </Card>
    <Card>
      <CardBody>
        <div className="mb-1">
          <b>NOTE:</b>
          If the graph is not shown after your form submission then you should do either maximize its size(the option is available on the right side of graph) or minimize the sidebar.
        </div>
        <Nav tabs>
          <NavItem>
            <NavLink className={activeTab == 'overall_pending_segments' ? 'active' : ''} onClick={() => setActiveTab('overall_pending_segments')}>
              Overall <br/> Pending <br/> Segments
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab == 'site_wise_pending_segments' ? 'active' : ''} onClick={() => setActiveTab('site_wise_pending_segments')}>
              Site Wise <br/> Pending <br/> Segments By Date
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab == 'site_wise_pending_segments_by_pipeline_stage' ? 'active' : ''} onClick={() => setActiveTab('site_wise_pending_segments_by_pipeline_stage')}>
              Site Wise <br/> Pending <br/> Segments By <br/> Pipeline Stage
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab == 'site_wise_pending_segments_by_host' ? 'active' : ''} onClick={() => setActiveTab('site_wise_pending_segments_by_host')}>
              Site Wise <br/> Pending <br/> Segments By Host
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab == 'host_wise_pending_segments' ? 'active' : ''} onClick={() => setActiveTab('host_wise_pending_segments')}>
              Host Wise <br/> Pending <br/> Segments By Date
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab == 'host_wise_pending_segments_by_pipeline_stage' ? 'active' : ''} onClick={() => setActiveTab('host_wise_pending_segments_by_pipeline_stage')}>
              Host Wise <br/> Pending <br/> Segments By <br/> Pipeline Stage
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab == 'host_wise_pending_segments_by_site' ? 'active' : ''} onClick={() => setActiveTab('host_wise_pending_segments_by_site')}>
              Host Wise <br/> Pending <br/> Segments By Site
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={'overall_pending_segments'}>
            <div className="panel-body">
              <center>
                <span className="fw-bold">Overall Pending Segments</span><br />
                <a target="_blank" className="link-primary" href={overallPendingSegmentsKibanaUrl()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={overallPendingSegmentsKibanaUrl() + "&embed=true"} height="500" width="100%" className="border-0"></iframe>
            </div>
          </TabPane>
          <TabPane tabId={'site_wise_pending_segments'}>
            <div className="panel-body">
              <center>
                <span className="fw-bold">Site Wise Pending Segments By Date</span><br />
                <a target="_blank" className="link-primary" href={siteWisePendingSegmentsKibanaUrl()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={siteWisePendingSegmentsKibanaUrl() + "&embed=true"} height="700" width="100%" className="border-0"></iframe>
            </div>
          </TabPane>
          <TabPane tabId={'site_wise_pending_segments_by_pipeline_stage'}>
            <div className="panel-body">
              <center>
                <span className="fw-bold">Site Wise Pending Segments By Pipeline Stage</span><br />
                <a target="_blank" className="link-primary" href={siteWisePendingSegmentsByPipelineStageKibanaUrl()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={siteWisePendingSegmentsByPipelineStageKibanaUrl() + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
            </div>
          </TabPane>
          <TabPane tabId={'site_wise_pending_segments_by_host'}>
            <div className="panel-body">
              <center>
                <span className="fw-bold">Site Wise Pending Segments By Host</span><br />
                <a target="_blank" className="link-primary" href={siteWisePendingSegmentsByHostKibanaUrl()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={siteWisePendingSegmentsByHostKibanaUrl() + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
            </div>
          </TabPane>
          <TabPane tabId={'host_wise_pending_segments'}>
            <div className="panel-body">
              <center>
                <span className="fw-bold">Host Wise Pending Segments By Date</span><br />
                <a target="_blank" className="link-primary" href={hostWisePendingSegmentsKibanaUrl()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={hostWisePendingSegmentsKibanaUrl() + "&embed=true"} height="500" width="100%" className="border-0"></iframe>
            </div>
          </TabPane>
          <TabPane tabId={'host_wise_pending_segments_by_pipeline_stage'}>
            <div className="panel-body">
              <center>
                <span className="fw-bold">Host Wise Pending Segments By Pipeline Stage</span><br />
                <a target="_blank" className="link-primary" href={hostWisePendingSegmentsByPipelineStageKibanaUrl()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={hostWisePendingSegmentsByPipelineStageKibanaUrl() + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
            </div>
          </TabPane>
          <TabPane tabId={'host_wise_pending_segments_by_site'}>
            <div className="panel-body">
              <center>
                <span className="fw-bold">Host Wise Pending Segments By Site</span><br />
                <a target="_blank" className="link-primary" href={hostWisePendingSegmentsBySiteKibanaUrl()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={hostWisePendingSegmentsBySiteKibanaUrl() + "&embed=true"} height="500" width="100%" className="border-0"></iframe>
            </div>
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  </Container>
}

