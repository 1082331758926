import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import AsyncSelect from 'react-select/async';
import axios from "axios";
import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function Index() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[site_name]")
  }
  const [siteName, setSiteName] = useState("");
  const [siteNameOptions, setSiteNameOptions] = useState([]);
  const [manuallyPushToQueueData, setmanuallyPushToQueueData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults]= useState([]);

  function KibanaUrl(documentId){
    return `${KIBANA_PLM_URL}app/kibana#/discover?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-7d,to:now))&_a=(columns:!(msg,line_number,resque_job_push_timestamp,document_id),filters:!(),index:'8c44ed90-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'manually_push_to_queue_job_tracker:+true+and+document_id:+%22${documentId}%22'),sort:!(!('@timestamp',desc)))`
  }

  const customStyles = {
    headCells: {
      style: { 'word-break': 'break-all' },
    }
  } 

  const tableColumns = [
    { 
      name: 'Document ID', 
      selector: 'document_id', 
      sortable: true,
      cell: row => (
        <a href={KibanaUrl(row.document_id)} target="_blank" rel="noopener noreferrer">	
          {row.document_id}
        </a>
        )
    },
    { 
      name: 'Job pushed at (resque_job_push_timestamp)', 
      selector: 'resque_job_push_timestamp', 
      sortable: true,
    },
    { name: 'Latest message (msg)', selector: 'msg', sortable: true},
    { name: 'Latest message at (log_ts)', selector: 'log_ts', sortable: true},
    { name: 'Job Status', selector: 'job_status', sortable: true}
  ]

  const [UrlParams, setUrlParams] = useState({
    siteName: "",
  })

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let site_names = res.data.site_names, site_names_options = []
        for (var key in site_names) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
          site_names[key].map(item => {
            children.push({ label: item, value: item })
          })
          group['options'] = children;
          site_names_options.push(group)
        }
        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: '*' }] })
        setSiteNameOptions(site_names_options);
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      });
  }, []);

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.siteName && searchParams.siteName != siteName.value) {
      setSiteName({ label: searchParams.siteName, value: searchParams.siteName });
    }
    if (commit == 'Get Data' && searchParams.siteName.length > 0) {
      setUrlParams(searchParams)
      fetchData(searchParams.siteName);
    }
  }

  function fetchData(site_name = siteName) {
    setLoading(true)
    axios.get(`/api/v1/internal/manually_push_to_queue_tracker?filter[site_name]=${site_name}`)
      .then(res => {
        setmanuallyPushToQueueData(res.data.manually_push_to_queue_data);
        setSearchResults(res.data?.manually_push_to_queue_data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  const promiseOptions = (inputValue) => {
    if(inputValue.length < 3){
      return null
    } else {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200'){
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
              site_names[key].map(item => {
                children.push({ label: item, value: item })
              })
              group['options'] = children;
              site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setSiteNameOptions(site_names_options)
            resolve(site_names_options)
          }
        }).catch(err => {
          console.log(`err`, err)
        })
      })
    }
  }

  const handleSearch = event =>{
    if(event.target.value == ''){
      setSearchResults(manuallyPushToQueueData);
    }
    else{
      setSearchResults(searchFromData(event.target.value));
    }
    setSearchText(event.target.value);
  }

  function searchFromData(value){
    var search_result = manuallyPushToQueueData.filter(item => item.document_id.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = manuallyPushToQueueData.filter(item => item.resque_job_push_timestamp.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = manuallyPushToQueueData.filter(item => item.msg.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = manuallyPushToQueueData.filter(item => item.log_ts.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = manuallyPushToQueueData.filter(item => item.job_status.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
  }

  const handleGetData = () => {
    window.location.href = `/internal/manually_push_to_queue_tracker?filter[site_name]=${siteName.value}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/manually_push_to_queue_tracker">Manually Push To Queue Tracker</a> : 'Manually Push To Queue Tracker'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
          </BreadcrumbItem>}
        </Breadcrumb>
      </Col>
    </Row>
  </div>

  <Card>
    <CardBody>
      <Row>
        <Col sm="9">
          <label className="fw-bold">Site Name</label>
          <AsyncSelect cacheOptions defaultOptions={siteNameOptions} name='site_name'
            value={siteName} loadOptions={promiseOptions} onChange={res => setSiteName(res)}
            placeholder="Select a site name" />
            <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
        </Col>
        <Col sm="3">
          <label className="fw-bold">&nbsp;</label>
          <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
        </Col>
      </Row>
    </CardBody>
  </Card>
  {
    commit == 'Get Data' ?
    <>
      <Card>
        <CardBody>
          <Row className='my-2'>
            <Col lg={12}>
              {
                loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> 
                :
                <Row className="align-items-end mt-2">
                  <center><h6 className="mb-2"><b>Site Name: </b>{UrlParams.siteName}</h6></center>
                  <Col>
                    <div className="float-end mb-3">
                      <Row className="align-items-center">
                        <Col sm="3">Search</Col>
                        <Col>
                          <input type="text" className="form-control" value={searchText} onChange={handleSearch} />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <DataTable
                    columns={tableColumns}
                    customStyles={customStyles}
                    data={searchResults}
                    noHeader
                    pagination
                    progressPending={loading}
                  />
                </Row>
              }
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
    : ""
  }
  </Container>
}

