import React from 'react';
import { useParams } from "react-router-dom";
import { Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { Row, Col, Table } from 'reactstrap';

export default function StatementOfWork({ data, handleClick, checked, setChecked }) {
	let { organization_name, feas_id } = useParams();

	return (
		<>
			<Card>
				<CardHeader>
					<Row>
						<Col lg={10}>
							<h6 className='text-center my-2'>Exhibit B</h6>
							<h5 className='text-center m-0'>
								STATEMENT OF WORK
							</h5>
						</Col>
						<Col lg={2}>
							<span className='text-muted label'>
								<i className='fa fa-clock-o me-1'></i>
								{data?.feasibility_report?.created_at}
							</span>
						</Col>
					</Row>
				</CardHeader>
				<CardBody>
					<div>
						<h6 className='text-center fw-bold my-2'>
							Data Crawl and Extraction Service
						</h6>
						<h6 className='lh-medium my-3'>
							This STATEMENT OF WORK (“SOW”) under the Contract Agreement by and between <b> PromptCloud Technologies Pvt. Ltd., </b> and having its registered office at <b> #722, 2nd floor, Chinmaya Mission Hospital Road, Indira Nagar 1st Stage, Hoysala Nagar, Bangalore, Karnataka – 560075, India </b> (herein referred to as <b> "PromptCloud” </b>) and the <b> “Client” or Company”. </b>
						</h6>
						<h6 className='lh-medium my-2'>
							The services set forth in this SOW delineates the entire scope of services to be provided to the Client by PromptCloud under this effort.
						</h6>
						<h6 className='lh-medium my-2'>
							PromptCloud can begin work on this effort immediately on acceptance of the Agreement and payment of initial invoice. This SOW may only be modified by a change order signed by both parties. The Client must submit all requests for changes in this SOW in writing to PromptCloud, and PromptCloud will respond with a written response to Client setting forth its best judgment as to the probable effect, if any, of the requested change in function, performance, reliability, schedule, payments and the effect on the price contained in the SOW. All agreed-to changes to the SOW will be authorized only by an amendment to the SOW to be signed by both parties.
						</h6>
					</div>

					<div className='my-3'>
						<h6 className='fw-bold'>General Scope of Effort</h6>
						<h6 className='lh-medium'>
							Client hereby appoints PromptCloud, and PromptCloud hereby agrees to provide Crawling and Extraction Service to the Client. As such, PromptCloud is authorized to purchase related outside services on Client's behalf subject to Client agreeing and authorizing PromptCloud to purchase such outside services in writing and in advance.
						</h6>
					</div>

					<div className='my-3'>
						<h6 className='fw-bold'>Client Responsibilities</h6>
						<Table className='my-3' responsive bordered>
							<thead className='bg-light text-dark fw-bold'>
								<tr>
									<th scope='col'>Item</th>
									<th scope='col'>Description</th>
									<th scope='col'>Timeframe</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										Approvals
									</td>
									<td>
										The client will give PromptCloud feedback on the quality of data within seven (7) working days of receiving the data.
										If there is no feedback, PromptCloud will assume the data is as per expectation and the Client has accepted it.
										Any rework request on the data after this period, will be charged as additional records.
									</td>
									<td>
										Duration of the contract
									</td>
								</tr>
								<tr>
									<td>
										Technical Contract
									</td>
									<td>
										The Client will provide a technical person who can get acquainted with the PromptCloud API and other delivery mechanism. PromptCloud will provide guidance and material for the same. PromptCloud will not be responsible for any issues with consuming of the data. Any changes by the client in the consuming pattern that may warrant change by PromptCloud, will be chargeable additionally. The Client will provide a technical person who can get acquainted with the PromptCloud API and other delivery mechanism. PromptCloud will provide guidance and material for the same. PromptCloud will not be responsible for any issues with consuming of the data. Any changes by the client in the consuming pattern that may warrant change by PromptCloud, will be chargeable additionally.
									</td>
									<td>
										Duration of the contract
									</td>
								</tr>
							</tbody>
						</Table>
					</div>

					<div className='my-3'>
						<h6 className='fw-bold'>
							Data Crawl and Extraction (“Services”)
						</h6>
						<h6 className='fw-bold my-2'>
							Scope
						</h6>
						<h6 className='lh-medium'>
							PromptCloud will crawl a list of sites for the Client and extract data in a predefined format as provided by the Client through PromptCloud’s CrawlBoard platform and agreed upon between the Client and PromptCloud.
							<br />
							Any support required with respect to data quality or structure changes on the sites will be taken care of as part of the project. Support requests will be primarily tracked using PromptCloud’s ticketing system.
							<br />
							Additional fields or change in schema may warrant setup fees that will be discussed at time of request. New sites pertaining to the same project can be added to the crawl list at any time subjected to the setup costs.
							<br />
							Any pause, termination or change in frequency request should be communicated in advance and will be effective at the beginning of the next calendar month.
						</h6>

						<h6 className='fw-bold my-2'>
							Crawling Terms
						</h6>
						<h6 className='lh-medium'>
							PromptCloud is only a data crawling service provider for the Client and is responsible for abiding by the target site's robots.txt. It is up to the Client to adhere to the terms and conditions of the source sites being crawled for usage of its data.
						</h6>

						<h6 className='fw-bold my-2'>
							Pricing – Table A as per definition in Master Service Agreement
						</h6>
						<h6 className='lh-medium'>
							Pricing takes into account the volume of data uploaded each month as well as additional delivery requirements as specified in PromptCloud’s CrawlBoard platform. All costs are in INR and exclusive of taxes. The cost is valid only for the current project and specifications provided by the client.
							<br />
							If new sites get added to the current list which are to be setup based on different set of requirements, PromptCloud will provide a quote at PromptCloud’s discretion.
						</h6>

						<h6 className='fw-bold my-2'>
							Data Arrival time from initiation
						</h6>
						<h6 className='lh-medium'>
							First data feed will start within 7 working days after the agreement is signed and request for data is received.
						</h6>

						<h6 className='fw-bold my-2'>
							Data Retention
						</h6>
						<h6 className='lh-medium'>
							All uploaded data will be archived for a month.
						</h6>

						<h6 className='fw-bold my-2'>
							Dropping a site
						</h6>
						<h6 className='fw-bold my-2'>
							Robots.txt
						</h6>
						<h6 className='lh-medium'>
							If robots.txt prohibits crawling of the site at any point in time, PromptCloud will not crawl that site. In cases when a site is not crawled due to robots.txt being blocked or any other reason, crawl costs will be pro-rated for that month accordingly.
						</h6>

						<h6 className='fw-bold my-2'>
							AJAX Components
						</h6>
						<h6 className='lh-medium'>
							Also at times, few sites implement AJAX components that might have issues w.r.t. crawling. PromptCloud will look at alternative methods in case crawling is still possible and the quote will be provided separately for such sites. If a solution is not viable, PromptCloud will inform Client and the site may be dropped from the crawling list. There will be no charges for such sites thereafter.
						</h6>

						<h6 className='fw-bold my-2'>
							Invoice and Payment
						</h6>
						<h6 className='lh-medium'>
							Invoice will be generated on a monthly basis at the beginning of the month and will be due for payment in full within the next 15 days. An invoice will be generated for the base costs before starting the project and will be due on receipt. This amount will be later adjusted with the first month’s bill.
						</h6>
					</div>
					<hr />
					{data?.feasibility_report?.feasibility_check_status == "not_feasible_and_closed" &&
						<div className='my-3 d-flex justify-content-center'>
							<a href={`/organizations/${organization_name}/sites/new`} className='btn btn-primary text-white'>Create new Sitegroup</a>
						</div>}

					{data?.feasibility_report?.feasibility_check_status == "paid_and_proceeding" &&
						<div className='my-3 d-flex justify-content-center'>
							<p className='text-muted'>Crawl works are in progress </p>
						</div>}

					{(data?.feasibility_report?.feasibility_check_status == "payment_pending" || data?.feasibility_report?.feasibility_check_status == "report_generated") && <div>
						<div className='my-3 d-flex justify-content-center'>
							<input type="checkbox" name="terms_and_conditions" checked={checked} onChange={() => setChecked(curr => !curr)} disabled={data?.feasibility_report?.feasibility_check_status == "payment_pending"} />
							&nbsp; I Agree to Terms and Conditions
						</div>
						<div className='my-3 d-flex justify-content-center'>
							{checked &&
								<button onClick={handleClick} className='btn btn-success text-white'>
									{data?.feasibility_report?.skip_invoice ? "Accept and Proceed" : 'Proceed to Pay'}
								</button>}
						</div>
					</div>}
				</CardBody>
			</Card>
		</>
	)
}
