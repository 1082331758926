import { Code, Globe, Box, DollarSign, Users, GitPullRequest, 
    CheckSquare, HelpCircle, Heart, FileText } from 'react-feather'
import axios from 'axios';

const MENUITEMS = [
    {
        menutitle:"Item1",
        menucontent:"Item1.1",
        Items:[
            {   
                title: 'Data Access', 
                icon: Code, type: 'sub', 
                active: false, 
                children: [
                    { path: `/`, title: 'Data Download', type: 'link' },
                    { path: `/`, title: 'API Details', type: 'link' },
                    { path: `/`, title: 'Merge and Upload', type: 'link' },
                    { path: `/`, title: 'Webhooks', type: 'link' },
                ]
            }
        ]
    },

    {
        menutitle:"Item2",
        menucontent:"Item2.1",
        Items:[
            { path: `/`, icon: Globe, title: 'Sites', type: 'link' },
            { path: `/`, icon: FileText , title: 'Feasibility Reports', type: 'link' },
            { path: `/`,icon: GitPullRequest, title: 'Issues', type: 'link' },
            { path: `/`,icon:DollarSign, type: 'link', title: 'Billing' },
            { path: `/app/analytcis`,icon:CheckSquare, type: 'link', title: 'Analytics Dashboards'},
        ]
    },

    {
        menutitle:"Components",
        menucontent:"UI Components & Elements",
        Items:[
            {
                title: 'Admin Section', icon: Box, type: 'sub',  active: false, children: [
                    { path: `/admins/feasibility_checks`, title: 'Feasibility Checks', type: 'link' },
                    { path: `/admins/feasibility_reports`, title: 'Feasibility Reports', type: 'link' },
                    { path: `/admins/coupons`, title: 'Discount coupons', type: 'link' },
                    { path: `/admins/searchpage`, title: 'Search Page', type: 'link' },
                    { path: `/admins/organization-mapping`, title: 'Organization mapping', type: 'link' },
                    { path: `/admins/datastock-files`, title: 'Datastock files', type: 'link' },
                    { path: `/admins/new-announcement`, title: 'New Announcement', type: 'link' },
                    { path: `/admins/all/organizations`, title: 'All Organizations', type: 'link' },
                    { path: `/admins/all/payments`, title: 'All payments', type: 'link' },
                    { path: `/admins/site-reports`, title: 'Site reports', type: 'link' }
                ]
            }
        ]
    },
    
    {
        menutitle:"Miscellaneous",
        menucontent:"Bouns Pages & Apps",
        Items:[
            { path: `/app/internal-dashboard`,icon: HelpCircle,  type: 'link',active:false, title: 'Internal Dashboard' },
            { path: `/app/datastock`,icon: Users,  type: 'link', active:false,title: 'Data Stocks' },
    ]   
    },
]

export {MENUITEMS}
