import React, { Fragment, useState, useEffect } from 'react'
import { Col, Row, Button, Card } from 'react-bootstrap'
import AsyncCreatableSelect from 'react-select/async-creatable';
import { toast } from 'react-toastify';
import axios from 'axios';
import DataTable from 'react-data-table-component'
import { stringify } from "json-to-pretty-yaml2";

import UploadHtmlFile from './UploadHtmlFile.component'
import ModifyXpathModal from './ModifyXpath.modal'
import SpinnerLoader from '../../../../Common/LoadingAnimation/SpinnerLoader.component'

export default function XpathsByValue() {
  const [data, setData] = useState([]) // Table data
  const [siteName, setSiteName] = useState({ label: 'Select Site Name', value: '' }) // sitename selected by user
  const [fieldValue, setFieldValue] = useState("") // fieldValue selected by user
  const [siteNamesDropdownOptions, setSiteNameDropdownOptions] = useState([]) // sitenames displayed in dropdown
  const [loading, setLoading] = useState(false) // controlled input for showing loading animation
  const [modalShow, setModalShow] = useState(false) // controlled input for displaying file upload modal
  const [inputFilePath, setInputFilePath] = useState("") 
  const [errors, setErrors] = useState({})
  const [selectedRowsData, setSelectedRowsData] = useState([])
  const [uploadedFileName, setUploadedFileName] = useState(""); 

  // Dummy axios call
  useEffect(() => {
    const constroller = new AbortController()
    setLoading(true)
    fetchDefaultOptionsForSiteName()
    setLoading(false)
    // cleanup
    return () => {
      constroller.abort()
    }
  }, []);

  // API endpoint
  const API_ENDPOINT = `/api/v1/internal/chat_gpt_xpath_result_page/xpaths_by_value`

  // Table Column Definitions
  const columns = [
    { name: "Field Name", selector: row => row?.field_name, sortable: true, left: true },
    { name: "XPATH", selector: row => row?.xpath, sortable: true, left: true },
    { name: "DisK Value", selector: row => row?.disk_value, sortable: true, left: true },
    { name: "Input Value", selector: row => row?.input_value, sortable: true, left: true },
    { name: "Data Type Matched", selector: row => (_.startCase(String(row?.data_type_matched))), sortable: true, left: true },
    { name: "DRL Data Type", selector: row => row?.drl_data_type, sortable: true, left: true },
    { name: "LLM Data Type", selector: row => row?.llm_data_type, sortable: true, left: true },
    { name: "Disc LLM Matching Score", selector: row => row?.disk_llm_matching_score, sortable: true, left: true },
    { 
      name: "Actions", selector: row => (
        <ModifyXpathButton row={row} data={data} setData={setData} inputFilePath={inputFilePath} />
      ), 
      left: true,
      width: "13%"
    },
  ]

  async function fetTableData() {
    const responseBody = {
      filters: {
        site_name: siteName.value,
        field_values: fieldValue,
        input_file_path: inputFilePath,
      }
    }

    try {
      setLoading(true)
      const response = await axios.post(API_ENDPOINT, responseBody)
      const data = response?.data?.data
      const transformedData = transformData(data[0])
      setData(transformedData)
      setLoading(false)
      toast.success(response?.data?.message)
    }
    catch (error) {
      setData([]);
      toast.error(error?.response?.data?.message, {autoClose: false})
      setLoading(false)
    }
  }

  async function onSubmitButtinCLickHandler() {
    let tempObj = {}
    if (siteName?.value.length == 0) {
      tempObj = { ...tempObj, is_site_name_empty: true }
    }
    if (fieldValue?.length == 0) {
      tempObj = { ...tempObj, is_field_value_empty: true }
    }
    if (inputFilePath?.length == 0) {
      tempObj = { ...tempObj, is_input_file_path_empty: true }
    }
    if (inputFilePath?.length !== 0) {
      if (siteName?.value !== uploadedFileName) {
        tempObj = { ...tempObj, upload_file_again: true }
      }
    }
    console.log("tempObj", tempObj);
    setErrors(tempObj)
    if (Object.keys(tempObj)?.length == 0) {
      await fetTableData()
    }
  }

  // Helper method to fetch default site name dropdown options
  function fetchDefaultOptionsForSiteName() {
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let siteNames = res?.data?.site_names, siteNamesOptions = []
        for (var key in siteNames) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
            siteNames[key].map(item => {
              children.push({ label: item, value: item })
            })
          group['options'] = children;
          siteNamesOptions.push(group)
        }
        setSiteNameDropdownOptions(siteNamesOptions)
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      })
  }

  // Helper mothod to fetch site name in site name dropdown async select
  async function onDropdownOptionSearchHandler(inputValue) {
    if (inputValue.length < 3) {
      return null
    } else {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200') {
            let siteNames = res.data.site_names, siteNamesOptions = []
            for (var key in siteNames) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
                siteNames[key].map(item => {
                  children.push({ label: item, value: item })
                })
              group['options'] = children;
              siteNamesOptions.push(group)
            }
            siteNamesOptions.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setSiteNameDropdownOptions(siteNamesOptions)
            resolve(siteNamesOptions)
          }
        }).catch(err => {
          console.log(`err`, err)
        })
      })
    }
  }

  const disableSubmitButton = () => {
    let status = false;
    if (siteName?.value?.length == 0) {
      status = true;
    }
    if (fieldValue?.length == 0) {
      status = true;
    }
    if (inputFilePath.length == 0) {
      status = true;
    }
    return status
  }

  const transformData = (inputData) => {
    const resultArray = Object.entries(inputData).map(([key, value]) => ({
      field_name: key,
      ...value
    }))

    return resultArray
  };

  const copyXpath = (data) => {
    let copy_data = {};
    copy_data[data.field_name] = {
      desc_of_xpath: data.xpath,
      standard_nodeset_range: "first",
      standard_nodeset_join_char: "|",
      standard_post_processing_functions_on_text: ["remove_newlines_and_whitespaces"]
    }
    const yml_data = stringify(copy_data);
    navigator.clipboard.writeText(yml_data)
    toast.success("Copied the data successfully!")
  }

  const copyAllXpaths = (data) => {
    let copy_data = []
    data.forEach((hash, index) => {
      let field_hash = {}
      field_hash[hash.field_name] = {
        desc_of_xpath: hash.xpath,
        standard_nodeset_range: "first",
        standard_nodeset_join_char: "|",
        standard_post_processing_functions_on_text: ["remove_newlines_and_whitespaces"]
      }
      copy_data.push(field_hash);
    });
    const yml_data = stringify(copy_data);
    navigator.clipboard.writeText(yml_data)
    toast.success("Copied the data successfully!")
  }

  const copyXpathAsTld = (data) => {
    let copy_data = {};
    copy_data["tld_"+ data.field_name] = {
      desc_of_xpath: data.xpath,
      standard_nodeset_range: "first",
      standard_nodeset_join_char: "|",
      standard_post_processing_functions_on_text: ["remove_newlines_and_whitespaces"]
    }
    const yml_data = stringify(copy_data);
    navigator.clipboard.writeText(yml_data)
    toast.success("Copied the data successfully!")
  }

  const copyAllXpathsAsTld = (data) => {
    let copy_data = []
    data.forEach((hash, index) => {
      let field_hash = {}
      field_hash["tld_"+ hash.field_name] = {
        desc_of_xpath: hash.xpath,
        standard_nodeset_range: "first",
        standard_nodeset_join_char: "|",
        standard_post_processing_functions_on_text: ["remove_newlines_and_whitespaces"]
      }
      copy_data.push(field_hash);
    });
    const yml_data = stringify(copy_data);
    navigator.clipboard.writeText(yml_data)
    toast.success("Copied the data successfully!")
  }

  function ModifyXpathButton(props) {
    const [modifyXmlModalShow, setModifyXmlModalShow] = useState(false) // controlled input for displaying modifymodal
    const [modalRespData, setModalRespData] = useState({})

    const handleHideModal = () => {
      if (modalRespData){
        const newData = props?.data?.map((item) => {
          if (item.field_name === modalRespData.field_name) {
            return {...item, xpath: modalRespData.xpath, disk_value: modalRespData.disk_value};
          }
          return item;
        });
        props.setData(newData);
      }
      setModifyXmlModalShow(false)
    }

    return (
      <Fragment>
        <Button
          style={{ marginBottom: "10px"}} 
          size="sm"
          variant='success' 
          onClick={() => setModifyXmlModalShow(true)}>
          Modify
        </Button>
        <br/>
        <Button 
          style={{ marginBottom: "10px"}} 
          size="sm"
          variant='primary' 
          onClick={() => copyXpath(props.row)}  
          data-tip='copy to clipboard'>
          Copy XPATH
        </Button>
        <Button 
          size="sm"
          variant='primary' 
          onClick={() => copyXpathAsTld(props.row)}  
          data-tip='copy to clipboard'>
          Copy XPATH as tld
        </Button>
        <ModifyXpathModal 
          show={modifyXmlModalShow} 
          inputFilePath={props?.inputFilePath} 
          fieldName={props?.row?.field_name}
          onHide={handleHideModal} 
          setModalRespData={setModalRespData}
        />
      </Fragment>
    )
  }

  const handleSelectRow = ({ selectedRows }) => {
    setSelectedRowsData(selectedRows)
  };

  const handleSiteNameChange = (option) => {
    let selected_site_name = option;
    if (selected_site_name){
      setSiteName(selected_site_name);
    }
    else {
      setSiteName({ label: 'Select Site Name', value: '' });
    }
    if (inputFilePath?.length !== 0) {
      if (siteName?.value !== uploadedFileName) {
        tempObj = { ...tempObj, upload_file_again: true }
        setErrors(tempObj)
      }
    }
  };

  return (
    <Fragment>
      <div className='mt-4'>
        <Row className='my-3 align-items-end'>
          <Col md='3'>
            <label className="fw-bold">Site Name*</label>
            <AsyncCreatableSelect
              value={siteName}
              cacheOptions
              defaultOptions={siteNamesDropdownOptions}
              loadOptions={onDropdownOptionSearchHandler}
              onChange={handleSiteNameChange} 
              isClearable={true}
              placeholder={'Select Site Name'}
              createOptionPosition={"first"}
            />
            {errors?.is_site_name_empty && <p className='fw-bold text-danger my-2'> This field is required! </p>}
          </Col>
          <Col md='3'>
            <label htmlFor="" className="fw-bold">Field Values*</label>
            <input
              type="text"
              value={fieldValue}
              placeholder='Ex: field1:value,field2:value2,etc'
              onChange={(event) => setFieldValue(event?.target?.value)} className='form-control' 
            />
            {errors?.is_field_value_empty && <p className='fw-bold text-danger my-2'> This field is required! </p>}
          </Col>
          <Col md='3'>
            <UploadHtmlFile
              setInputFilePath={setInputFilePath}
              siteName={siteName?.value} 
              setUploadedFileName={setUploadedFileName}
              tab_name={"xpaths_by_value"}
            />
            {errors?.is_input_file_path_empty && <p className='fw-bold text-danger my-2'> This field is required! </p>}
            {errors?.upload_file_again && <p className='fw-bold text-danger my-2'> You've changed the site name after file uploaded so please upload new one! </p>}
          </Col>
          <Col md='3'>
            <button
              className='btn btn-success w-100'
              onClick={() => onSubmitButtinCLickHandler()} >
              Submit
            </button>
          </Col>
          <div className="form-text">NOTE: All the fields are required and Please select a sitename before file upload!</div>
        </Row>
        <Row className='my-3'>
          <SpinnerLoader loading={loading}>
            {data && data.length > 0 &&
            <>
              <hr/>
              <Card>
                <Card.Header as="h6" style={{padding:"10px", fontWeight: "bold" }}>XPATHS by value data: </Card.Header>
                <Card.Body style={{padding:"0px" }}>
                  <Row className='my-3'>
                    <Col md='3'>
                      <button
                        className='btn btn-primary w-100'
                        disabled={selectedRowsData.length == 0}
                        onClick={() => copyAllXpaths(selectedRowsData)} >
                        Copy Selected XPATHS
                      </button>
                    </Col>
                    <Col md='3'>
                      <button
                        className='btn btn-primary w-100'
                        onClick={() => copyAllXpaths(data)} >
                        Copy All XPATHS
                      </button>
                    </Col>
                    <Col md='3'>
                      <button
                        className='btn btn-primary w-100'
                        disabled={selectedRowsData.length == 0}
                        onClick={() => copyAllXpathsAsTld(selectedRowsData)} >
                        Copy Selected XPATHS as tld
                      </button>
                    </Col>
                    <Col md='3'>
                      <button
                        className='btn btn-primary w-100'
                        onClick={() => copyAllXpathsAsTld(data)} >
                        Copy All XPATHS as tld
                      </button>
                    </Col>
                  </Row>
                  <DataTable 
                    columns={columns} 
                    data={data} 
                    selectableRows
                    onSelectedRowsChange={handleSelectRow}
                    responsive noHeader striped highlightOnHover pagination 
                  />
                </Card.Body>
              </Card>
            </>
            }
          </SpinnerLoader>
        </Row>
      </div>
    </Fragment>
  )
}
