import React, {useState, useEffect} from 'react'
import { Form, FormGroup, Label, Button } from 'reactstrap'
import { userLoggedin, userRegistered } from  '../localStorageMethods'
import {useForm} from 'react-hook-form'
import { toast } from 'react-toastify'
import { useHistory } from "react-router-dom";
import axios from 'axios'
import Loader from 'react-loader-spinner'

export default function ForgotPassword() {
    let history = useHistory();
    
    const [loading, setLoading] = useState(true);
    const [loadingBtns, setLoadingBtns] = useState(false)

    useEffect(() => {
        if (userLoggedin()){
            toast.warning('You are loggedin already')
            history.push('/')
        }
        setLoading(false)
    }, [])

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = data => {
        if (data !== '') {
            handlePasswordReset(data)
        } else {
            errors.showMessages();
        }
    };

    const handlePasswordReset = (data) => {
        setLoadingBtns(true)
        axios.post('/api/v1/users/password', {
            user: {email: data.email},
            controller_name: "passwords",
            page_type: "client"
        }).then(res => {
            const data1 = res.data
            if (data1.status == 'success'){
                toast.success(`${data1.message}`)
                history.push('/users/sign_in')
            }
            setLoadingBtns(false)
        })
        .catch(res => {
            toast.error(res.response.data.message)
            setLoadingBtns(false)
        })
    }

    return (
        <>
            {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
        : (<div className='login-card'>
            <div className='login-main login-tab'>
                <Form className="theme-form needs-validation" noValidate=""
                     onSubmit={handleSubmit(onSubmit)}>
                    <h4>Forgot Password</h4>
                    <p>Please enter your email</p>
                    <FormGroup>
                        <Label className="col-form-label">Email Address</Label>
                        <input className="form-control" name="email"
                            {...register("email", { required: true })}
                            type="email" placeholder="test@gmail.com"/>
                        <span className="error-msg">{ errors.email && 'Email is required'}</span>
                    </FormGroup>

                    <div className="form-group mb-0 mt-4">
                        <button type="submit" className="btn btn-primary btn-block w-100 m-t-5"
                            disabled={loadingBtns}>
                            Send Instructions
                        </button>
                    </div>
                    <p className="mt-4 mb-0"> Already have an account?
                        <a className="ml-2" href="/users/sign_in"> Login here </a>
                    </p>
                </Form>
            </div>
        </div>)}
        </>
    )
}
