/**
 * This is the component which is mounted when user click on billing in sidebar
 * This page displays a Table of all "Invoices" which are generated for the user
 */

import React, { useEffect, useState, useContext } from 'react'
import { useParams, useHistory } from "react-router-dom";


import { Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, Alert } from 'reactstrap'
import { Target, Info, CheckCircle } from 'react-feather';
import { toast } from 'react-toastify';
import axios from 'axios';

import { OrgnaziationContext } from '../../../Home';
import Breadcrumbs from '../../Common/BreadCrumb/BreadCrumbs.component';
import BannerNotifications from '../../Common/BannerNotification/BannerNotifications.component';
import SpinnerLoader from '../../Common/LoadingAnimation/SpinnerLoader.component';
import InfoCards from './InfoCards/InfoCards.component';
import InvoiceList from './InvoiceList/InvoiceList.component';

export default function Billing({ banner_notifications, current_user, current_organization }) {

  const [loading, setLoading] = useState(false);
  const [bannerNotifications, setBannerNotifications] = useState([]);
  const [paidData, setPaidData] = useState([]);
  const [pendingData, setPendingData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [cardsList, setCardsList] = useState([]);
  const [cardsData, setCardsData] = useState({});
  const [organizationName, setOrganizationName] = useState('');
  const [activeTab, setActiveTab] = useState("all");

  const history = useHistory();
  const { setOrgName } = useContext(OrgnaziationContext)
  const { organization_name } = useParams();

  useEffect(() => {
    if (current_organization?.org_identifier) {
      setOrganizationName(current_organization?.org_identifier)
    } else {
      setOrganizationName(organization_name)
    }
  }, [])

  useEffect(() => {
    if (!(current_organization?.org_identifier)) {
      setOrgName(organizationName)
    }
  }, [organizationName])

  // API ENDPOINT FOR GETTING PAID INVOICES
  const PAID_INVOICE_API = `/api/v1/organizations/${organizationName}/paid_invoices?page_type=client&controller_name=invoices`;

  // API ENDPOINT FOR GETTING PENDING INVPICES
  const PENDING_INVOICE_API = `/api/v1/organizations/${organizationName}/pending_invoices?page_type=client&controller_name=invoices`;

  // API ENDPOINT FOR GETTING USER ADDED CARDS FOR PAYMENT
  const PAYMENT_CARDS_API = `/api/v1/organizations/${organizationName}/cards?page_type=client&controller_name=cards`;

  useEffect(() => {
    if ((current_user?.id && !(current_user?.is_admin)) && !(current_user?.accessible_modules.includes("billing"))) {
      history.push(`/`)
      toast.error('You are not authorized to access this page!')
    }
  }, [current_user])

  useEffect(() => {
    setBannerNotifications(banner_notifications)
  }, [banner_notifications])



  useEffect(async () => {

    const controller = new AbortController();
    if (organizationName) {
      setLoading(true)
      // get the paid invoice data
      await getDataFromBackendDB(PAID_INVOICE_API, controller.signal, setPaidData);
      // get the unpaid invoice data
      await getDataFromBackendDB(PENDING_INVOICE_API, controller.signal, setPendingData);
      // get the cards added by the user
      await getDataFromBackendDB(PAYMENT_CARDS_API, controller.signal, setCardsList, 'cards');
      setLoading(false);
    }

    // cleanup
    return () => {
      controller.abort();
    }
  }, [organizationName])

  useEffect(() => {
    setTotalData([...paidData, ...pendingData])
  }, [paidData, pendingData]);

  // Helper function to get the data
  async function getDataFromBackendDB(baseURI, signal, setterMethod, dataFor = 'invoices') {
    try {
      const response = await axios.get(baseURI, { signal: signal });
      if (dataFor === 'cards') {
        const data = await response?.data?.cards;
        setterMethod(data);
      }
      else if (response?.data?.current_unbilled_amount && response?.data?.outstanding_bill_summary) {
        const data = await response?.data?.invoices;
        setCardsData({
          current_unbilled_amount: response?.data?.current_unbilled_amount,
          outstanding_bill_summary: response?.data?.outstanding_bill_summary
        })
        setterMethod(data);
      }
      else {
        const data = await response?.data?.invoices;
        setterMethod(data);
      }
    }
    catch (error) {
      setterMethod([]);
    }
  }


  return (
    <>
      <div className='container container-fluid'>
        <Breadcrumbs parent={"Invoices"} title="Invoices" />
        <BannerNotifications notificationsArray={bannerNotifications} />

        <Row>
          <Col md="12" className="project-list">
            <Card>
              <Row>
                <Col sm="6">
                  <Nav tabs className="nav-pills">
                    <NavItem><NavLink  className={activeTab === "all" ? "active btn-primary" : 'btn-light'} onClick={() => setActiveTab("all")}><Target />All</NavLink></NavItem>
                    <NavItem><NavLink  className={activeTab === "paid" ? "active btn-primary" : 'btn-light'} onClick={() => setActiveTab("paid")}><CheckCircle />Paid</NavLink></NavItem>
                    <NavItem><NavLink  className={activeTab === "pending" ? "active btn-primary" : 'btn-light'} onClick={() => setActiveTab("pending")}><Info />Pending</NavLink></NavItem>
                  </Nav>
                </Col>
                <Col sm="6">
                </Col>
              </Row>
            </Card>
          </Col>

          <SpinnerLoader loading={loading}>
            <Col md="9">
              <Card>
                <CardBody>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="all">
                      <Row>
                        <InvoiceList data={totalData} org={organizationName} key={"total"} />
                      </Row>
                    </TabPane>
                    <TabPane tabId="paid">
                      <Row>
                        <InvoiceList data={paidData} org={organizationName} key={"paid"} />
                      </Row>
                    </TabPane>
                    <TabPane tabId="pending">
                      <Row>
                        <InvoiceList data={pendingData} org={organizationName} key="pending" />
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <InfoCards
                title="Current Unbilled Amount"
                data={cardsData?.current_unbilled_amount}
                type={"unbilled"}
                key="unbilled-amount" />
              <InfoCards
                title="Outstanding Bill Summary"
                data={cardsData?.outstanding_bill_summary}
                type={"outstanding"}
                key="outstanding-amount" />
              <InfoCards
                title="Cards"
                data={cardsList}
                type={"payment-cards-info"}
                key={"cards-list"} />
            </Col>
          </SpinnerLoader>
        </Row>
      </div>
    </>
  )
}
