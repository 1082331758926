import React, {Fragment, forwardRef, useImperativeHandle,
  useState, useEffect} from 'react';
import { Card, CardBody, Row, Col, CardHeader} from 'reactstrap';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import Chart from "react-apexcharts";
import DatePicker from 'react-datepicker';
import AsyncSelect from 'react-select/async';

const SitesGraph = forwardRef(({orgName}, ref) => {
  const [siteData, setSiteData] = useState([]);
  const [sitegroupId, setSitegroupId] = useState('');
  const [graphData, setGraphData] = useState({
    days: [], uploads_count: []
  })
  const [sitename, setSitename] = useState('none');
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);

  const handleSiteName = (e) => {
    setSelectedSite(e)
    let siteNameVal = e['value'];
    setSitename(siteNameVal)
  }

  const today = new Date(Date.now())
  const minimumSelection = new Date(Date.now() - 180 * 24 * 60 * 60 * 1000)
  const [startDate, setStartDate] = useState(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(new Date(Date.now()));
  const [siteNamesDropdownOptions, setSiteNameDropdownOptions] = useState([]);
  const [selectedSite, setSelectedSite] = useState({value: '', label:'Select a Site'});

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  function getFormattedDate(date) {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  }

  function parseDate(str) {
    str = getFormattedDate(str)
    var mdy = str.split('/');
    return new Date(mdy[2], mdy[0]-1, mdy[1]);
  }

  function datediff(first, second) {
    return Math.round((second-first)/(1000*60*60*24));
  }

  const handleDataRequest = () => {
    let days = datediff(parseDate(startDate), parseDate(endDate))
    if (days > 30){
      toast.error("Please select dates range within 30 days")
    }
    else if (sitename === "none"){
      toast.error("Please select a site first")
    }
    else{
      setLoading(true)
      axios.get(`/api/v1/organizations/${orgName}/sites/uploads_count?from_date=${getFormattedDate(startDate)}&to_date=${getFormattedDate(endDate)}&site_id=${sitename}&sitegroup_id=${sitegroupId}&page_type=client&controller_name=sites`)
        .then(res => {
          setGraphData({
            days: res.data.sites_upload_count.days,
            uploads_count: res.data.sites_upload_count.uploads_count
          })
          setLoading(false)
        }).catch(err => {
          toast.error(err.response.data.message)
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    getSiteUploadCount()
    getSiteNames()
  }, [orgName])

  useImperativeHandle(ref, () => ({
    fetchSitesGraphData(sitegroupId) {
      setSitegroupId(sitegroupId);
      getSiteUploadCount(sitegroupId)
      getSiteNames(sitegroupId)
    }
  }));

  function getSiteNames(sitegroupId=""){
    setLoading(true)
    let url = `/api/v1/organizations/${orgName}/site_name_list?page_type=client&controller_name=sites`;
    if (sitegroupId != ""){
      url = `/api/v1/organizations/${orgName}/site_name_list?sitegroup_id=${sitegroupId}&page_type=client&controller_name=sites`;
    }
    axios.get(url)
      .then(res => {
        setSiteData(res.data.site_names)
        setSiteNameDropdownOptions(convertArray(res?.data?.site_names))
        setLoading(false)
      }).catch(err => {
        toast.error(err.response.data.message)
        setLoading(false)
      })
  }

  useEffect(() => {
    if ('days' in graphData && graphData?.days?.length > 0){
      setOptions(
        { chart: { id: "basic-bar" },
          xaxis: { categories: graphData.days }
        })
      setSeries([
        {
          name: "uploads",
          data: graphData.uploads_count
        }
      ])
    }
    else{
      setOptions({})
      setSeries([])
    }
  }, [graphData])

  function getSiteUploadCount(sitegroupId='', sitename='none'){
    let url;
    if (orgName){
      if (sitegroupId != '' && sitename != 'none'){
        url = `/api/v1/organizations/${orgName}/sites/uploads_count?from_date=${getFormattedDate(startDate)}&to_date=${getFormattedDate(endDate)}&site_id=${sitename}&sitegroup_id=${sitegroupId}&page_type=client&controller_name=sites`;
      }
      else if (sitename != 'none' && sitegroupId == ''){
        url = `/api/v1/organizations/${orgName}/sites/uploads_count?from_date=${getFormattedDate(startDate)}&to_date=${getFormattedDate(endDate)}&site_id=${sitename}&page_type=client&controller_name=sites`;
      }
      else if (sitegroupId != ''){
        url = `/api/v1/organizations/${orgName}/sites/uploads_count?sitegroup_id=${sitegroupId}&page_type=client&controller_name=sites`
      }
      else {
        url = `/api/v1/organizations/${orgName}/sites/uploads_count?page_type=client&controller_name=sites`;
      }
      setLoading(true)
      axios.get(url)
        .then(res => {
          setGraphData({
            days: res.data.sites_upload_count.days,
            uploads_count: res.data.sites_upload_count.uploads_count
          })
          setLoading(false);
        }).catch(err => {
          toast.error(err.response.data.message)
          setLoading(false);
        })
    }
  }

  // Helper method to convert the input array of objects into required format
  function convertArray(inputArray) {
    const convertedArray = inputArray.map(({ sitename, id}) => ({
      value: id,
      label: sitename
    }));
    return convertedArray;
  }

  // Helper function to filter the values and return the object
  // based on user input
  const filterValues = (inputValue) => {
    return siteNamesDropdownOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  // AsyncMethod to traverse the array and return resut that matches 
  // the input query entered by the user 
  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterValues(inputValue));
    }, 650);
  };

  return (
    <Card className='my-2 mb-5'>
      {
        loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
          : 
          <>
            <CardHeader className='m-0 p-4 d-flex align-center'>
              <Row className='w-100'>
                <Col lg={4}>
                  <AsyncSelect
                      name='site_name'
                      value={selectedSite}
                      placeholder="Select a Site"
                      cacheOptions
                      defaultOptions={siteNamesDropdownOptions}
                      onChange={res => handleSiteName(res)}
                      loadOptions={loadOptions}
                  />
                  {/* <select className='form-control show-arrow' defaultValue={sitename}
                    onChange={(e) => handleSiteName(e)}>
                    <option value='none'>Select a Site: </option>
                    {siteData?.map(each => (
                      <option value={each.id} key={each.id}>
                        {each.sitename}
                      </option>
                    ))}
                  </select> */}
                </Col>
                <Col lg={1}></Col>
                <Col lg={5}>
                  <div className='d-flex'>
                    <h6 className='me-2'> Select Date range: </h6>
                    <div>
                      <DatePicker selected={startDate} onChange={onDateChange}
                        maxDate={today} minDate={minimumSelection}
                        className='form-control' startDate={startDate} endDate={endDate} selectsRange />
                      <span className='text-muted' style={{fontSize: 12}}>
                        Any 30 days in last 6 months
                      </span>
                    </div>
                  </div>
                </Col>
                <Col lg={2}>
                  <button className='btn btn-success' 
                    onClick={handleDataRequest}>
                    Apply
                  </button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              { ('days' in graphData) ?
              <Row className='w-100 my-3'>
                {loading ? <p> Loading ... </p> :
                <Chart options={options} series={series} height="400"
                  type="line" style={{width: "100%"}} />}
                </Row> : <> 
                <h6> Select a sitename and click on apply </h6>
            </>}
          </CardBody>
        </>
      }
    </Card>
  )
});
export {SitesGraph as default}
