import React, {useEffect, useState} from 'react'
import { Mail } from 'react-feather';
import {Card,CardHeader,CardBody, Container} from "reactstrap";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify'
import axios from 'axios'
import { removeItem } from  '../localStorageMethods'

export default function User_email() {
  let history = useHistory();
  const [loading, setLoading] = useState(true);
  const [loadingBtns, setLoadingBtns] = useState(false)

  useEffect(() => {
    if (!localStorage.getItem('user_name') && !localStorage.getItem('authEmail')){
      toast.warning('please signup to get email confirmation')
      history.push('/users/sign_up')
    }
    setLoading(false)
  }, [])

  const sendEmail = () => {
    setLoadingBtns(true)
    axios.post('/api/v1/users/password', {
      user: {email: localStorage.getItem('authEmail')},
      controller_name: "passwords",
      page_type: "client"
    })
      .then((res) => res.data)
      .then(data => {
        setLoadingBtns(false)
        if(data.status == 'success'){
          removeItem('authEmail')
          removeItem('user_name')
          toast.success(`${data.message}`)
          history.push('/users/sign_in')
        }
        else{
          toast.danger(data.message)
        }
      })
  }

  let username = localStorage.getItem('user_name')
  let useremail = localStorage.getItem('authEmail')
  return (
    <>
      {loading ? <p>loading...</p>
          : (<Container fluid className='mt-5 pt-5'>
            <Card>
              <CardHeader>
                <h5> <Mail /> Confirm your Email Address</h5>
              </CardHeader>
              <CardBody>
                <div className="mb-0 my-card"> 
                  <p>Hi <b>{username}</b></p>
                  <p>Thanks for signing up!</p>
                  <p>Before you can access your account, you need to verify your registered email address.</p>
                  <p>To do that, please click on the verification link that we have already sent to <b>{useremail}</b></p>
                  <p>If you haven't received the verification link in your inbox, 
                    check your junk mail or spam folder, or 
                    <button onClick={sendEmail} className='font-blue' disabled={loadingBtns} > click here</button> and we'll send another 
                    verification link to your email.</p>
                  <p>Cheers,</p>
                  <p>Team PromptCloud</p>
                </div>
              </CardBody>
            </Card>
        </Container>)}
      </>
  )
}
