import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import moment from "moment";
import axios from "axios";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";
import AsyncSelect from 'react-select/async';
import Select, { components } from "react-select";

export default function New() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const site_name = new URLSearchParams(search).get("filter[site]");
  const rss_crawlstatus = new URLSearchParams(search).get("filter[rss_crawlstatus]");
  const searchParams = {
    siteName: site_name ? site_name : "all",
    rssCrawlStatus: rss_crawlstatus ? rss_crawlstatus : "all_other_than_done",
    fromDate: new URLSearchParams(search).get("filter[crawldate][from]"),
    toDate: new URLSearchParams(search).get("filter[crawldate][to]"),
  }
  const [siteName, setSiteName] = useState({label: 'All', value: 'all'});
  const [rssCrawlStatus, setRssCrawlStatus] = useState({label: 'All other than done', value: 'all_other_than_done'});
  const [siteNameOptions, setSiteNameOptions] = useState([]);
  const [rssProcessesData, setRssProcessesData] = useState([]);
  const now = new Date();
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(2, 'days')));
  const [toDate, setToDate] = useState(now);
  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults]= useState([]);

  const rssCrawlStatusOptions = [
    { label: 'All other than done', value: 'all_other_than_done' },
    { label: 'started', value: 'started' },
    { label: 'failed', value: 'failed' }
  ]

  const tableColumns = [
    { name: 'Host Name', selector: 'hostname', sortable: true },
    { name: 'Site Name', selector: 'site_name', sortable: true },
    { name: 'Rss Crawlstatus', selector: 'rss_crawlstatus', sortable: true },
    { name: 'Crawldate', selector: 'crawldate', sortable: true },
  ]

  const [UrlParams, setUrlParams] = useState({
    siteName: "all",
    rssCrawlStatus: 'all_other_than_done',
    fromDate: new Date(moment().subtract(2, 'days')),
    toDate: now
  })

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let site_names = res.data.site_names, site_names_options = []
        for (var key in site_names) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
          site_names[key].map(item => {
            children.push({ label: item, value: item })
          })
          group['options'] = children;
          site_names_options.push(group)
        }
        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
        setSiteNameOptions(site_names_options);
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      });
  }, []);

  const promiseOptions = (inputValue) => {
    if(inputValue.length < 3){
      return null
    } else {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200'){
            console.log(res.data)
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
              site_names[key].map(item => {
                children.push({ label: item, value: item })
              })
              group['options'] = children;
              site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setSiteNameOptions(site_names_options)
            resolve(site_names_options)
          }
        }).catch(err => {
          console.log(`err`, err)
        })
      })
    }
  }

  const handleSiteNameChange = (data) => {
    setSiteName(data)
  }

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.siteName && searchParams.siteName != siteName.value) {
      setSiteName({ label: searchParams.siteName  == 'all' ? 'All' :  searchParams.siteName, value: searchParams.siteName });
    }
    if (searchParams.rssCrawlStatus && searchParams.rssCrawlStatus != rssCrawlStatus.value) {
      setRssCrawlStatus({ label: searchParams.rssCrawlStatus  == 'all_other_than_done' ? 'All' :  searchParams.rssCrawlStatus, value: searchParams.rssCrawlStatus });
    }
    if (searchParams.fromDate && searchParams.fromDate != fromDate) {
      setFromDate(new Date(searchParams.fromDate));
    }
    if (searchParams.toDate && searchParams.toDate != toDate) {
      setToDate(new Date(searchParams.toDate));
    }
    if (commit == 'Get Data') {
      setUrlParams(searchParams)
      fetchDataWithParams(searchParams.siteName, searchParams.rssCrawlStatus, searchParams.fromDate, searchParams.toDate);
    }
    else {
      fetchData();
    }
  }

  function fetchDataWithParams(site_name = siteName, rss_crawlstatus = rssCrawlStatus, from_date = fromDate, to_date = toDate) {
    setLoading(true)
    axios.get(`/api/v1/internal/rss_processes?filter[site_name]=${site_name}&filter[rss_crawlstatus]=${rss_crawlstatus}&filter[from_date]=${moment(from_date).format('YYYY/MM/DD')}&filter[to_date]=${moment(to_date).format('YYYY/MM/DD')}`)
      .then(res => {
        setRssProcessesData(res.data.rss_processes_data);
        setSearchResults(res.data.rss_processes_data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  function fetchData() {
    setLoading(true)
    axios.get(`/api/v1/internal/rss_processes/new`)
      .then(res => {
        setRssProcessesData(res.data.rss_processes_data);
        setSearchResults(res.data.rss_processes_data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  const handleSearch = event =>{
    if(event.target.value == ''){
      setSearchResults(rssProcessesData);
    }
    else{
      setSearchResults(searchFromData(event.target.value));
    }
    setSearchText(event.target.value);
  }

  function searchFromData(value){
    var search_result = rssProcessesData.filter(item => item.site_name.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = rssProcessesData.filter(item => item.hostname.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = rssProcessesData.filter(item => item.rss_crawlstatus.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = rssProcessesData.filter(item => item.crawldate.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
  }

  const handleGetData = () => {
    window.location.href = `/internal/rss_process?filter[site]=${siteName.value}&filter[rss_crawlstatus]=${rssCrawlStatus.value}&filter[crawldate][from]=${moment(fromDate).format('YYYY/MM/DD')}&filter[crawldate][to]=${moment(toDate).format('YYYY/MM/DD')}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/rss_process/new">RSS Processes</a> : 'RSS Processes'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
          </BreadcrumbItem>}
        </Breadcrumb>
      </Col>
    </Row>
  </div>

  <Card>
    <CardBody>
      <Row>
        <div className="col-sm-8">
          <label className="fw-bold">Site Name</label>
          <AsyncSelect cacheOptions defaultOptions={siteNameOptions} name='site_name' value={siteName}
            loadOptions={promiseOptions} onChange={handleSiteNameChange}
            placeholder="Select a site name"
          />
          <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
        </div>
        <Col sm="4">
          <label className="fw-bold">Rss Crawl Status</label>
          <Select
            value={rssCrawlStatus}
            options={rssCrawlStatusOptions}
            onChange={option => setRssCrawlStatus(option)} />
        </Col>
      </Row>
      <Row className="align-items-end mt-2">
        <Col sm="4">
          <label className="fw-bold">From Date</label>
          <DatePicker
            className="form-control"
            selected={new Date(fromDate)}
            dateFormat={"YYY/MM/dd"}
            maxDate={new Date()}
            onChange={date => setFromDate(date)} />
        </Col>
        <Col sm="4">
          <label className="fw-bold">To Date</label>
          <DatePicker
            className="form-control"
            selected={new Date(toDate)}
            dateFormat={"YYY/MM/dd"}
            maxDate={new Date()}
            onChange={date => setToDate(date)} />
        </Col>
        <Col sm="4">
          <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
        </Col>
      </Row>
    </CardBody>
  </Card>
  <Card>
    <CardBody>
      <Row className="align-items-end mt-2 mb-3">
        <Col>
          <div className="float-end">
            <Row className="align-items-center">
              <Col sm="3">Search</Col>
              <Col>
                <input type="text" className="form-control" value={searchText} onChange={handleSearch} />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <DataTable
        columns={tableColumns}
        data={searchResults}
        noHeader
        pagination
        progressPending={loading}
      />
    </CardBody>
  </Card>
</Container>
}

