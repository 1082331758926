import React, { useState, useEffect } from 'react'
import { CardBody, Col, Card, Container, Row } from 'reactstrap';
import Breadcrumb from '../../global/Breadcrumb'
import axios from 'axios'
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component'
import Loader from 'react-loader-spinner';
import DatePicker from 'react-datepicker';
import moment from 'moment';


export default function AllPayments() {

    const [filtersData, setFiltersData] = useState({
        name: '', identifier: '', service_type: '', product_type: ''
    });
    const [payments, setPayments] = useState([]);
    const [page, setPage] = useState(1);
    const countPerPage = 20;
    const [pageTotal, setPageTotal] = useState(0);
    const [productTypes, setProductTypes] = useState([]);
    const [serviceTypes, setServiceTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortString, setSortString] = useState('');
    const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(null)


    useEffect(() => {
        setLoading(true)
        axios.get(`/api/v1/admins/payments?filterrific[filter_by_service_type]=${filtersData.service_type}&filterrific[filter_by_product_type]=${filtersData.product_type}&filterrific[search_by_name]=${filtersData.name}&filterrific[search_by_identifier]=${filtersData.identifier}&page=${page}`)
            .then(res => {
                setPayments(res.data?.payments)
                setPageTotal(res.data?.total_payments_count)
                setProductTypes(res.data?.product_types)
                setServiceTypes(res.data?.service_types)
                setLoading(false)
            }).catch(err => {
                toast.error(err?.response?.data?.message)
                setLoading(false)
            })
    }, []);


    useEffect(() => {
        if (sortString !== '' && sortString !== null) {
            setPage(1)
            setLoading(true)
            axios.get(`/api/v1/admins/payments?filterrific[filter_by_service_type]=${filtersData.service_type}&filterrific[filter_by_product_type]=${filtersData.product_type}&filterrific[search_by_name]=${filtersData.name}&filterrific[search_by_identifier]=${filtersData.identifier}&filterrific[sorted_by]=${sortString}&page=${page}`)
                .then(res => {

                    setPayments(res.data?.payments)
                    setPageTotal(res.data?.total_payments_count)
                    setProductTypes(res.data?.product_types)
                    setServiceTypes(res.data?.service_types)
                    setLoading(false)
                }).catch(err => {
                    toast.error(err?.response?.data?.message)
                    setLoading(false)
                })
        }
    }, [sortString]);

    const handleSearch = () => {
        setPage(1)
        setLoading(true)
        axios.get(`/api/v1/admins/payments?filterrific[filter_by_service_type]=${filtersData.service_type}&filterrific[filter_by_product_type]=${filtersData.product_type}&filterrific[search_by_name]=${filtersData.name}&filterrific[search_by_identifier]=${filtersData.identifier}&page=${page}${(fromDate && toDate) ? `&filterrific[filter_by_date_range]=${moment(fromDate).format("YYYY/MM/DD")}-${moment(toDate).format("YYYY/MM/DD")}`:''}`)
            .then(res => {
                setPayments(res.data?.payments)
                setPageTotal(res.data?.total_payments_count)
                setProductTypes(res.data?.product_types)
                setServiceTypes(res.data?.service_types)
                setLoading(false)
            }).catch(err => {
                toast.error(err?.response?.data?.message)
                setLoading(false)
            })
    }

    const handleFormReset = () => {
        setFiltersData({
            name: '', identifier: '', service_type: '', product_type: ''
        })
    }

    const customSort = (rows, selector, direction) => {
        if (selector != null) {
            setSortString(`${selector}_${direction}`)
        }
        return rows
    }

    const columns = [
        { name: 'Name', selector: 'name', sortable: true, center: true },
        {name: 'Identifier',
            cell: row =>(<a href={row.organization_link} target="_blank">{row.identifier}</a>),
            sortable: true,
            center:true
        },
        { name: 'Amount Paid', selector: 'amount_paid', sortable: true, center: true },
        { name: 'Invoice id', selector: 'invoice_id', center: true, sortable: true },
        { name: 'Service Type', selector: 'service_type', center: true },
        { name: 'Product Type', selector: 'product_type', center: true },
        { name: 'Created at', selector: 'created_at', sortable: true },
    ]

    const handlePageChange = page => {
        setPage(page)
        axios.get(`/api/v1/admins/payments?filterrific[filter_by_service_type]=${filtersData.service_type}&filterrific[filter_by_product_type]=${filtersData.product_type}&filterrific[search_by_name]=${filtersData.name}&filterrific[search_by_identifier]=${filtersData.identifier}&page=${page}${(fromDate && toDate) ? `&filterrific[filter_by_date_range]=${moment(fromDate).format("YYYY/MM/DD")}-${moment(toDate).format("YYYY/MM/DD")}`:''}`)
            .then(res => {
                setPayments(res.data?.payments)
                setPageTotal(res.data?.total_payments_count)
                setProductTypes(res.data?.product_types)
                setServiceTypes(res.data?.service_types)
            }).catch(err => {
                toast.error(err?.response?.data?.message)
            })
    }

    const onDateChange = (dates) => {
        const [start, end] = dates;
        setFromDate(start);
        setToDate(end);
    };
    
    const actionsMemo = <a href={`/admins/payments/download_csv?filterrific[filter_by_service_type]=${filtersData.service_type}&filterrific[filter_by_product_type]=${filtersData.product_type}&filterrific[search_by_name]=${filtersData.name}&filterrific[search_by_identifier]=${filtersData.identifier}${(fromDate && toDate) ? `&filterrific[filter_by_date_range]=${moment(fromDate).format("YYYY/MM/DD")}-${moment(toDate).format("YYYY/MM/DD")}`:''}`} className="btn btn-primary" download={'Payment.csv'}>Export</a>

    return (
        <Container fluid>
            <Breadcrumb parent={"Payments"} title={"Payments"} />

            <Card>
                <CardBody>
                    <Row className="align-items-end"> 
                        <Col md={3} className='my-2'>
                            <h6 className='fw-bold'>Name</h6>
                            <input type="text" className='form-control' value={filtersData.name}
                                onChange={(e) => setFiltersData({ ...filtersData, name: e.target.value })} placeholder="Enter Name of Organization" />
                        </Col>
                        <Col md={3} className='my-2'>
                            <h6 className='fw-bold'>Identifier</h6>
                            <input type="text" className='form-control' value={filtersData.identifier}
                                onChange={(e) => setFiltersData({ ...filtersData, identifier: e.target.value })} placeholder="Enter Organization Identifier" />
                        </Col>
                        <Col md={3} className='my-2'>
                            <h6 className='fw-bold'>Service Type</h6>
                            <select className='form-select' value={filtersData.service_type}
                                onChange={(e) => setFiltersData({ ...filtersData, service_type: e.target.value })} >
                                <option value="" hidden>Select Service Type</option>
                                {serviceTypes.map(each => (
                                    <option value={each[1]} key={each[0]}>
                                        {each[0]}
                                    </option>
                                ))}
                            </select>
                        </Col>
                        <Col md={3} className='my-2'>
                            <h6 className='fw-bold'>Product Type</h6>
                            <select className='form-select' value={filtersData.product_type}
                                onChange={(e) => setFiltersData({ ...filtersData, product_type: e.target.value })} >
                                <option value="" hidden>Select Product Type</option>
                                {productTypes.map(each => (
                                    <option value={each[1]} key={each[0]}>
                                        {each[0]}
                                    </option>
                                ))}
                            </select>
                        </Col>
                        <Col md={3} className='my-2'>
                            <h6 className='fw-bold'>Select Date</h6>
                            <DatePicker
                                selected={fromDate}
                                onChange={onDateChange}
                                dateFormat="yyyy/MM/dd"
                                maxDate={new Date()}
                                placeholderText='Select Date'
                                className='form-control'
                                startDate={fromDate}
                                endDate={toDate}
                                selectsRange />

                        </Col>
                        <Col md={3} className='my-2'>
                            
                            <button className='btn btn-primary w-100'
                                onClick={handleSearch}>Search</button>
                        </Col>
                        
                        <Col md={3} className='my-2'>
                        
                            <button className='btn btn-danger w-100'
                                onClick={handleFormReset}>Form Reset</button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Card>
                <CardBody>
                    {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
                        : <DataTable title={`Displaying New payment ${((page - 1) * countPerPage) + 1} - ${(page * countPerPage) > pageTotal ? pageTotal : page * countPerPage} of ${pageTotal} in total | Sort By ${sortString.split('_').join(' ')}`}
                            columns={columns} data={payments} sortFunction={customSort}
                            highlightOnHover pagination paginationServer progressPending={loading}
                            paginationTotalRows={pageTotal} paginationPerPage={countPerPage}
                            actions={actionsMemo}
                            paginationComponentOptions={{
                                noRowsPerPage: true
                            }} onChangePage={handlePageChange}
                        />}
                </CardBody>
            </Card>
        </Container>
    );
}
