import React, {useState, useEffect} from 'react'
import { CardBody, Col, Card, Container, Row } from 'reactstrap';
import Breadcrumb from '../../global/Breadcrumb'
import axios from 'axios'
import EditSiteDetailsForm from './EditSiteDetailsForm';
import ShowSitegroupDetails from './ShowSitegroupDetails';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component'
import Loader from 'react-loader-spinner';

export default function AllFeasibilityReports() {

  const [filtersData, setFiltersData] = useState({
    org_name: '', sitegroup_name: '', site_status: '', feasibility_status: ''
  });

  const [sites, setSites] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 20;
  const [pageTotal, setPageTotal] = useState(0);
  const [feasibilityStatuses, setFeasibilityStatuses] = useState([]);
  const [siteStatuses, setSiteStatuses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortString, setSortString] = useState('');
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setLoading(true)
    axios.get(`/api/v1/admins/feasibility_reports?filterrific[search_by_org_name]=${filtersData.org_name}&filterrific[search_by_site_group_name]=${filtersData.sitegroup_name}&filterrific[filter_by_site_status]=${filtersData.site_status}&filterrific[filter_by_feasibility_status]=${filtersData.feasibility_status}&page=${1}`)
      .then(res => {
        setSites(res.data?.sites)
        setPageTotal(res.data?.total_sites_count)
        setFeasibilityStatuses(res.data?.feasibility_statuses)
        setSiteStatuses(res.data?.site_statuses)
        setLoading(false)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
        setLoading(false)
      })
  }, [toggle]);

  useEffect(() => {
    if (sortString !== '' && sortString !== null){
      setPage(1)
      setLoading(true)
      axios.get(`/api/v1/admins/feasibility_reports?filterrific[search_by_org_name]=${filtersData.org_name}&filterrific[search_by_site_group_name]=${filtersData.sitegroup_name}&filterrific[filter_by_site_status]=${filtersData.site_status}&filterrific[filter_by_feasibility_status]=${filtersData.feasibility_status}&filterrific[sorted_by]=${sortString}&page=${1}`)
        .then(res => {
          setSites(res.data?.sites)
          setPageTotal(res.data?.total_sites_count)
          setFeasibilityStatuses(res.data?.feasibility_statuses)
          setSiteStatuses(res.data?.site_statuses)
          setLoading(false)
        }).catch(err => {
          toast.error(err?.response?.data?.message)
          setLoading(false)
        })
    }
  }, [sortString]);

  const handleSearch = () => {
    setPage(1)
    setLoading(true)
    axios.get(`/api/v1/admins/feasibility_reports?filterrific[search_by_org_name]=${filtersData.org_name}&filterrific[search_by_site_group_name]=${filtersData.sitegroup_name}&filterrific[filter_by_site_status]=${filtersData.site_status}&filterrific[filter_by_feasibility_status]=${filtersData.feasibility_status}&page=${1}`)
      .then(res => {
        setSites(res.data?.sites)
        setPageTotal(res.data?.total_sites_count)
        setFeasibilityStatuses(res.data?.feasibility_statuses)
        setSiteStatuses(res.data?.site_statuses)
        setLoading(false)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
        setLoading(false)
      })
  }

  const handleFormReset = () => {
    setFiltersData({
      org_name: '', sitegroup_name: '', site_status: '', feasibility_status: ''
    })
  }

  const customSort = (rows, selector, direction) => {
    if (selector != null){
      setSortString(`${selector}_${direction}`)
    }
    return rows
  }

  const columns = [
    {name: 'SiteName',
    selector: 'sitename',
    sortable: true,
    center: true,
    cell: row => (
			<a href={row.organization_link}  target="_blank" >
				{row.site_name}
			</a>)
    },
    {
      name: 'Sitegroup',
      selector: 'sitegroup',
      sortable: true,
      center:true,
      button: true,
      cell: row => (
        <ShowSitegroupDetails sitegroup_id={row.sitegroup_id} sitegroup_name={row.sitegroup_name} 
        key={row.site_id}/>
      )
    },
    {name: 'Organization',selector: 'organization_name',sortable: true,center:true},
    {name: 'Feasibility Status',selector: 'feasibility_status',center:true, sortable: true},
    {name: 'Site Status',selector: 'site_status',sortable: true,center:true},
    {name: 'Updated at',selector: 'updated_at',sortable: true, center:true},
    {
      name: 'Action',
      selector: '', 
      sortable: false,
      button: true,
      center: true,
      cell: row => (
        <EditSiteDetailsForm 
          data={row.pricing_detail_hash} 
          key={row.site_id} 
          setToggle={setToggle}
          siteStatuses={siteStatuses} 
          feasibilityStatuses={feasibilityStatuses}
        />
      )
    },
  ]

  const handlePageChange = pageNum => {
    setPage(pageNum)
    axios.get(`/api/v1/admins/feasibility_reports?filterrific[search_by_org_name]=${filtersData.org_name}&filterrific[search_by_site_group_name]=${filtersData.sitegroup_name}&filterrific[filter_by_site_status]=${filtersData.site_status}&filterrific[filter_by_feasibility_status]=${filtersData.feasibility_status}&page=${pageNum}`)
      .then(res => {
        setSites(res.data?.sites)
        setPageTotal(res.data?.total_sites_count)
        setFeasibilityStatuses(res.data?.feasibility_statuses)
        setSiteStatuses(res.data?.site_statuses)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })
  }

  return (
    <Container fluid>
      <Breadcrumb parent={"Sites Feasibility Reports"} title={"Sites Feasibility Reports"} />

      <Card>
        <CardBody>
          <Row>
            <Col md={3} className='my-2'>
              <h6 className='fw-bold'>Organization Name</h6>
              <input type="text" className='form-control' value={filtersData.org_name} 
                onChange={(e) => setFiltersData({...filtersData, org_name: e.target.value})} 
                placeholder="Enter Organization Name"/>
            </Col>
            <Col md={3} className='my-2'>
              <h6 className='fw-bold'>Sitegroup Name</h6>
              <input type="text" className='form-control' value={filtersData.sitegroup_name} 
                onChange={(e) => setFiltersData({...filtersData, sitegroup_name: e.target.value})}
                placeholder="Enter Sitegroup Name" />
            </Col>
            <Col md={3} className='my-2'>
              <h6 className='fw-bold'>Site Status</h6>
              <select className='form-select' value={filtersData.site_status}
                onChange={(e) => setFiltersData({...filtersData, site_status: e.target.value})} >
                <option value="" hidden>Select Site Status</option>
                {siteStatuses.map(each => (
                    <option value={each[1]} key={each[0]}>
                {each[0]}
              </option>
                ))}
            </select>
          </Col>
            <Col md={3} className='my-2'>
              <h6 className='fw-bold'>Feasibility Status</h6>
              <select className='form-select' value={filtersData.feasibility_status}
                onChange={(e) => setFiltersData({...filtersData, feasibility_status: e.target.value})} >
                <option value="" hidden>Select Feasibility Status</option>
                {feasibilityStatuses.map(each => (
                    <option value={each[1]} key={each[0]}>
                {each[0]}
              </option>
                ))}
            </select>
        </Col>
            <Col md={3} className='my-2'>
              <button className='btn btn-primary w-100'
                onClick={handleSearch}>Search</button>
            </Col>
            <Col md={3} className='my-2'>
              <button className='btn btn-danger w-100'
                onClick={handleFormReset}>Form Reset</button>
            </Col>
      </Row>
    </CardBody>
            </Card>

      <Card>
        <CardBody>
          {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> 
        :<DataTable title={`Displaying Website ${((page - 1) * countPerPage) + 1} - ${(page * countPerPage) > pageTotal ? pageTotal : page * countPerPage} of ${pageTotal} in total | Sort By ${sortString.split('_').join(' ')}`} 
          columns={columns} data={sites} sortFunction={customSort} 
          highlightOnHover pagination paginationServer progressPending={loading}
          paginationTotalRows={pageTotal} paginationPerPage={countPerPage}
          paginationComponentOptions={{
            noRowsPerPage: true
          }} onChangePage={handlePageChange}
        />}
        </CardBody>
      </Card>
        </Container>
  );
}
