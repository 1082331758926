import React from 'react';
import { useState, useEffect } from 'react';
import { Alert } from 'reactstrap';
export default function BannerNotifications({notifications}) {

  const [bannerNotifications, setBannerNotifications] = useState([]);

  useEffect(()=>{
    setBannerNotifications(notifications);
  },[notifications]);

  return (
    <>
      {
        bannerNotifications?.map(item => {
          if (item?.notify_maintenance) {
            return (
              <Alert color='primary' className='d-flex align-items-center justify-content-between my-2'>
                <p className='my-0'>{item?.notify_maintenance}</p>
              </Alert>
            )
          }
          if (item?.gst_update?.message) {
            return (
              <Alert color='primary' className='d-flex align-items-center justify-content-between my-2'>
                <p className='my-0'>{item?.gst_update?.message}</p>
                <a href={item?.gst_update?.url} target='_blank' style={{ background: 'white', whiteSpace: 'nowrap' }} className='btn btn-xs text-primary'>Update GSTIN</a>
              </Alert>
            )
          }
          if (item?.pending_feasibility_reports?.length > 0) {
            return (item?.pending_feasibility_reports?.map((each, index) => (
              <Alert color='primary' key={index} className='d-flex align-items-center justify-content-between my-2'>
                <p className='my-0'>{each?.message}</p>
                <a href={each?.url} target='_blank' style={{ background: 'white', whiteSpace: 'nowrap' }} className='btn btn-xs text-primary'>View and Proceed</a>
              </Alert>
            )))
          }
        }
        )
      }
    </>
  )
}
