import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";

import { KIBANA_JP_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function JobspikrRequestLogs() {

  function KibanaUrl(){
    return `${KIBANA_JP_URL}app/kibana#/dashboard/Jobspikr-Logstash?_g=()&_a=(description:'',filters:!(),fullScreenMode:!f,options:(darkTheme:!f),panels:!((embeddableConfig:(columns:!(method,status,route,duration,format,host,path),sort:!('@timestamp',desc)),gridData:(h:12,i:'1',w:48,x:0,y:12),id:Jobspikr-Request-Logs,panelIndex:'1',type:search,version:'7.5.1'),(embeddableConfig:(columns:!(method,route,error),sort:!('@timestamp',desc)),gridData:(h:12,i:'2',w:24,x:24,y:0),id:Jobspikr-Exception-Logs,panelIndex:'2',type:search,version:'7.5.1'),(embeddableConfig:(),gridData:(h:12,i:'3',w:24,x:0,y:0),id:Jobspikr-Request-Status-Graph,panelIndex:'3',type:visualization,version:'7.5.1')),query:(language:lucene,query:'*'),timeRestore:!f,title:'Jobspikr+Logstash',viewMode:view)`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>Jobspikr Request Logs</BreadcrumbItem>
        </Breadcrumb>
      </Col>
    </Row>
  </div>

  <Card>
    <CardBody>
      <center>
        <a target="_blank" className="link-primary" href={KibanaUrl()}>Click here to see below graph in kibana</a>
      </center>
      <iframe src={KibanaUrl() + "&embed=true"} height="500" width="100%" className="border-0"></iframe>
    </CardBody>
  </Card>
</Container>
}

