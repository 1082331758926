import React, { Fragment } from 'react'
import { AlertTriangle } from 'react-feather';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
export default function ResetQAStatusModal({ isOpen, toggle, onSubmitHandler, ...args }) {
  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div>
              <AlertTriangle size={32} strokeWidth={2.5} color='red' />
            </div>
            <div>
              <p className='fw-bold'>WARNING</p>
            </div>
            <div>
              <p className='fw-bold'>This will make all the QA status false and re-upload a QA file</p>
            </div>
            <div>
              <p className='fw-bold'>from the next run</p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onSubmitHandler}>
            Submit
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  )
}
