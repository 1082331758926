import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";

import { KIBANA_PLM_URL } from "../../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function JpIndexingErrors() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const searchParams = {
    fromTime: new URLSearchParams(search).get("filter[time]")
  }
  const [fromTime, setFromTime] = useState({ label: 'Last 7 days', value: 'now-7d' });

  const timeOptions = [
    { label: 'Today', value: 'now%2Fd' },
    { label: 'Last 30 minutes', value: 'now-30m' },
    { label: 'Last 1 hour', value: 'now-1h' },
    { label: 'Last 2 hours', value: 'now-2h' },
    { label: 'Last 4 hours', value: 'now-4h' },
    { label: "Last 10 hours", value: "now-10h" },
    { label: 'Last 12 hours', value: 'now-12h' },
    { label: 'Last 24 hours', value: 'now-24h' },
    { label: 'Last 2 days', value: 'now-2d' },
    { label: 'Last 3 days', value: 'now-3d' },
    { label: 'Last 4 days', value: 'now-4d' },
    { label: 'Last 7 days', value: 'now-7d' },
    { label: 'Last 15 days', value: 'now-15d' },
    { label: 'Last 30 days', value: 'now-30d' },
    { label: 'Last 60 days', value: 'now-60d' },
    { label: 'Last 1 year', value: 'now-1y' }
  ];

  const [UrlParams, setUrlParams] = useState({
    fromTime: 'now-7d'
  })

  useEffect(() => {
    setSearchParamsData();
  }, []);

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.fromTime && searchParams.fromTime != fromTime.value) {
      setFromTime(timeOptions.filter(item => item.value == searchParams.fromTime)[0]);
    }
    if (commit == 'Get Data' && searchParams.fromTime) {
      setUrlParams(searchParams)
    }
  }

  function kibanaToTimeVal() {
    return UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
  }

  function KibanaUrl(){
    return `https://kibanav8-plm.promptcloud.com/app/kibana#/dashboard/Filebeat-Logstash-Log-Dashboard-ecs?_g=(refreshInterval:(pause:!t,value:0),time:(from:'${UrlParams.fromTime}',to:'${kibanaToTimeVal()}'))&_a=(description:'Overview+of+Logstash+logs',filters:!(),fullScreenMode:!f,options:(darkTheme:!f),panels:!((embeddableConfig:(),gridData:(h:12,i:'2',w:24,x:24,y:0),id:'0b1dace0-cbdb-11e7-9852-73e0a9df1bb6-ecs',panelIndex:'2',type:visualization,version:'7.5.1'),(embeddableConfig:(),gridData:(h:12,i:'3',w:24,x:0,y:0),id:e90b7240-cbda-11e7-9852-73e0a9df1bb6-ecs,panelIndex:'3',type:visualization,version:'7.5.1'),(embeddableConfig:(columns:!(log.level,logstash.log.module,message,source),sort:!('@timestamp',desc)),gridData:(h:40,i:'4',w:48,x:0,y:12),id:cfaba090-cbda-11e7-9852-73e0a9df1bb6-ecs,panelIndex:'4',type:search,version:'7.5.1')),query:(language:kuery,query:'(message+:+jobspikr_v3_data+or+message:jobspikr_v3_staging_date)+and+(message:%22Could+not+index+event+to%22)'),timeRestore:!f,title:'%5BFilebeat+Logstash%5D+Logstash+Logs+ECS',viewMode:view)`
  }

  const handleGetData = () => {
    window.location.href = `/jobspikr_v3_internal/jobspikr_graphs/jp_indexing_errors?filter[time]=${fromTime.value}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/jobspikr_v3_internal/jobspikr_graphs/jp_indexing_errors">Jobspikr Indexing Errors</a> : 'Jobspikr Indexing Errors'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
          </BreadcrumbItem>}
        </Breadcrumb>
      </Col>
    </Row>
  </div>

  <Card>
    <CardBody>
      <Row>
        <Col sm="3">
          <label className="fw-bold">Time</label>
          <Select
            value={fromTime}
            options={timeOptions}
            onChange={option => setFromTime(option)} />
        </Col>
        <Col sm="3">
          <label className="fw-bold">&nbsp;</label>
          <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
        </Col>
      </Row>
    </CardBody>
  </Card>
  <Card>
    <CardBody>
      <center>
        <h5 className="mb-2">Jobspikr Indexing Errors</h5>
        <a target="_blank" className="link-primary" href={KibanaUrl()}>Click here to see below graph in kibana</a>
      </center>
      <iframe src={KibanaUrl() + "&embed=true"} height="600" width="100%" className="border-0"></iframe>
    </CardBody>
  </Card>
</Container>
}

