import React from 'react'
import {Row, Col } from 'reactstrap';
import {Link} from 'react-router-dom'
export default function SectionHeader({title, content, bcrumb, bcrumb1, bcrumb2}) {
    return (
        <Row className='mx-2 my-4 px-4 py-2'>
            <Col lg={6}>
                <h3 className='fw-light'>{title}</h3>
            </Col>
            {content && <Col lg={6}>
                <p> {content} </p>
            </Col>}
            {bcrumb && 
            <Col lg={6}>
                <Link to='/' className=''> Home</Link> / <span className=''>
                    {bcrumb}
                </span>
            </Col> }
            {bcrumb1 && <Col lg={6}>
                <Link to='/' className=''> Home</Link> / <Link to={bcrumb1['url']} className=''> {bcrumb1['key']} </Link> / <span className=''>
                    {bcrumb2}
                </span>
            </Col> }
        </Row>
    )
}
