/* THIS TABLE IS RENDERED IN DATA TABLE CARD COMPONENT */
import React from 'react';
import DataTable from 'react-data-table-component';

const columns = [
  {
    name: 'Host Name',
    selector: 'hostname',
    sortable: true,
  },
  {
    name: 'Site Name',
    selector: 'site_name',
    sortable: true,
  },
  {
    name: 'RSS Crawl Status',
    selector: 'rss_crawlstatus',
    sortable: true,
  },
  {
    name: 'RSS Crawl Start Status',
    selector: 'rss_crawl_start_status',
    sortable: true,
  },
  {
    name: 'RSS Crawl End Status',
    selector: 'rss_crawl_end_status',
    sortable: true,
  },
  {
    name: 'Crawl Date',
    selector: 'crawldate',
    sortable: true,
  },
];
export default function SitesDataTable(props) {
  return (
    <div>
      <DataTable
        columns={columns}
        data={props?.data}
        progressPending={props.progressPending}
        noHeader
        responsive
        pagination
      />
    </div>
  )
}
