import axios from 'axios'
import React, {useState, useEffect} from 'react'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalHeader, Row, Col, ModalFooter, Button } from 'reactstrap'
import Loader from 'react-loader-spinner'

export default function MembersRow({data, organization_name, setToggle, current_user}) {
    const [modal, setModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [memberModalData, setMemberModalData] = useState({
        first_name: '', last_name: '', email: '', designation: '', accessible_modules: [],
        notification_preferences: []
    })
    const [modal2, setModal2] = useState({
        visible: false, text: ''
    })
    const [loadingBtns, setLoadingBtns] = useState(false)
    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (modal){
            setLoading(true)
            axios.get(`/api/v1/organizations/${organization_name}/members/${data.id}/edit`).then(res => {
                setMemberModalData({
                    first_name: res.data?.member?.first_name, last_name: res.data?.member?.last_name, 
                    email: res.data?.member?.email, 
                    designation: res.data?.member?.designation, invited_by_id: res.data?.member.invited_by_id,
                    accessible_modules: res.data?.member?.accessible_modules,
                    notification_preferences: res.data?.member?.notification_preferences
                })
                setLoading(false)
            }).catch(err => {
                toast.error(err.response.data.message)
                setLoading(false)
            })
        }
    }, [modal])

    const toggleFunc = () => {
        setModal(curr => !curr)
    }

    const toggleFunc2 = () => {
        setModal2({
            ...modal2, visible: !modal2.visible
        })
    }

    const closeBtn = <button className="close" 
        onClick={() => setModal(false)}> &times; </button>;

    const closeBtn2 = <button className="close" 
        onClick={() => setModal2({...modal2, visible: false})}> &times; </button>;


    const removeUser = () => {
        axios.get(`/api/v1/organizations/${organization_name}/members/${data.id}/can_remove_member`).then(res => {
            if (res.data.can_remove_member){
                axios.get(`/api/v1/organizations/${organization_name}/members/${data.id}/can_remove_notification`).then(res2 => {
                    if (res2.data?.can_remove_notification_preference){
                        if(data.status == 'Invitation Sent'){
                            axios.delete(`/api/v1/organizations/${organization_name}/user_invites/${data.id}`).then(res => {
                                toast.success(res.data.message)
                                setToggle(curr => !curr)
                            }).catch(err => {
                                toast.error(err.response.data.message)
                            })
                        }
                        else{
                            axios.delete(`/api/v1/organizations/${organization_name}/members/${data.id}`).then(res => {
                                toast.success(res.data.message)
                                setToggle(curr => !curr)
                            }).catch(err => {
                                toast.error(err.response.data.message)
                            })
                        }
                    } else{
                        setModal2({
                            visible: true, text: res2.data?.popup_message
                        })
                    }
                })
            }
            else{
                setModal2({
                    visible: true, text: res.data?.popup_message
                })
            }
        })
        
    }
    
    const handleNotificationPreferences = (memberHash, accessible_module) => {
        let notificationPreferences = memberHash.notification_preferences
        if (accessible_module == "data_access"){
            notificationPreferences = notificationPreferences.filter(item => item !== "redmine")
        } 
        else if(accessible_module == "billing"){
            notificationPreferences = notificationPreferences.filter(item => item !== "invoice")
        }
        return notificationPreferences;
    }

    const handleCheck = (status, element) => {
        let temp = {...memberModalData}
        let notificationPreferences = handleNotificationPreferences(temp, element)
        if (status){
            temp = {...temp, accessible_modules: [...temp.accessible_modules, element], notification_preferences: notificationPreferences}
            setMemberModalData(temp)
        }
        else{
            setLoadingBtns(true)
            setLoading(true)
            axios.post(`/api/v1/organizations/${organization_name}/members/${data.id}/can_edit_accessible_modules`, {
                accessible_modules: [element]
            }).then(res => {
                if (res.data.can_edit_accessible_modules){
                    temp = {...temp, accessible_modules: temp.accessible_modules.filter(x => x != element), notification_preferences: notificationPreferences}
                    setMemberModalData(temp)
                } else {
                    setModal2({
                        visible: true, text: res.data?.popup_message
                    })
                }
                setLoadingBtns(false)
                setLoading(false)
            }).catch(err => {
                console.log("err", err);
                toast.error(err.response.data.message)
                setLoadingBtns(false)
                setLoading(false)    
            })
        }
    }

    const handleCheck2 = (status, element) => {
        let temp = {...memberModalData}
        if (status){
            temp = {...temp, notification_preferences: [...temp.notification_preferences, element]}
            setMemberModalData(temp)
        }
        else{
            setLoadingBtns(true)
            setLoading(true)
            axios.post(`/api/v1/organizations/${organization_name}/members/${data.id}/can_edit_notification_preferences`, {
                notification_preferences: [element]
            }).then(res => {
                if (res.data.can_edit_notification_preferences){
                    temp = {...temp, notification_preferences: temp.notification_preferences.filter(x => x != element)}
                    setMemberModalData(temp)
                } else {
                    setModal2({
                        visible: true, text: res.data?.popup_message
                    })
                }
                setLoadingBtns(false)
                setLoading(false)
            }).catch(err => {
                toast.error(err.response.data.message)
                setLoadingBtns(false)
                setLoading(false)    
            })
        }
    }

    const updateMember = () => {
        setErrors({})
        if (memberModalData?.first_name?.length === 0 || memberModalData?.last_name?.length === 0 || 
            memberModalData?.email?.length === 0 || memberModalData?.designation?.length === 0 || memberModalData?.accessible_modules?.length === 0){
            let tempObj = {}
            if (memberModalData?.first_name?.length === 0){
                tempObj.first_name = 'First Name is required'
            }
            if (memberModalData?.last_name?.length === 0){
                tempObj.last_name = 'Last Name is required'
            }
            if (memberModalData?.email?.length === 0){
                tempObj.email = 'Email is required'
            }
            if (memberModalData?.designation?.length === 0){
                tempObj.designation = 'Role is required'
            }
            if (memberModalData?.accessible_modules?.length === 0){
                tempObj.accessible_modules = 'Atleast one Accessible module is required'
            }
            setErrors(tempObj)
        }
        else{
            setLoadingBtns(true)
            console.log("memberModalData", memberModalData);
            axios.put(`/api/v1/organizations/${organization_name}/members/${data.id}`, {
                member: memberModalData
            }).then(res => {
                toast.success(res.data.message)
                setModal(false)
                setToggle(curr => !curr)
                setLoadingBtns(false)
            }).catch(err => {
                toast.error(err.response.data.message)
                setLoadingBtns(false)
            })
        }
    }

    const titelize = (arrayOfStrings) => {
        let result = []
        if (arrayOfStrings?.length > 0){
            arrayOfStrings.forEach(each => {
                result.push(each.replace("_", " "))
            })
        }
        console.log('result', result)
        return result.join(', ')
    }
    
    return (
        <>
        <Modal isOpen={modal} toggle={toggleFunc} backdrop='static' size='lg'>
            <ModalHeader toggle={toggleFunc} close={closeBtn}>
                Edit Member: {organization_name?.replaceAll("_", " ")}
            </ModalHeader>
            <ModalBody className='px-5'>
                {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
                : <>
                <Row className='my-2'>
                        <Col lg={4}>
                            <h6 className='my-0'>First Name </h6>
                        </Col>
                        <Col lg={8}>
                            <input type="text" className='form-control' value={memberModalData?.first_name} 
                                onChange={(e) => setMemberModalData({ ...memberModalData, first_name: e.target.value })} />
                            {errors.first_name && <p className='text-danger'> {errors.first_name} </p>}
                        </Col>
                    </Row>
                    <Row className='my-2'>
                        <Col lg={4}>
                            <h6 className='my-0'>Last Name </h6>
                        </Col>
                        <Col lg={8}>
                            <input type="text" className='form-control' value={memberModalData?.last_name}
                                onChange={(e) => setMemberModalData({ ...memberModalData, last_name: e.target.value })} />
                            {errors.last_name && <p className='text-danger'> {errors.last_name} </p>}
                        </Col>
                    </Row>
                    <Row className='my-2'>
                        <Col lg={4}>
                            <h6 className='my-0'>Enter Email </h6>
                        </Col>
                        <Col lg={8}>
                            <input type="email" className='form-control' value={memberModalData.email}
                                onChange={(e) => setMemberModalData({ ...memberModalData, email: e.target.value })} />
                            {errors.email && <p className='text-danger'> {errors.email} </p>}
                        </Col>
                    </Row>
                    <Row className='my-2'>
                        <Col lg={4}>
                            <h6 className='my-0'>Role </h6>
                        </Col>
                        <Col lg={8}>
                            <select className='form-control show-arrow' defaultValue={memberModalData.designation}
                                onChange={(e) => setMemberModalData({...memberModalData, designation: e.target.value})}>
                                <option value="">Select a role</option>
                                <option value="CXO">CXO</option>
                                <option value="CTO">CTO</option>
                                <option value="Product Head">Product Head</option>
                                <option value="Technical Head">Technical Head</option>
                                <option value="Business owner">Business owner</option>
                                <option value="Analyst">Analyst</option>
                                <option value="Other">Other</option>
                            </select>
                            {errors.designation && <p className='text-danger'> {errors.designation} </p>}
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <h6 className='my-0'>Accessible Modules </h6>
                        </Col>
                        <Col lg={8}>
                            <div className='my-1 d-flex align-items-center'>
                                <input type="checkbox" className='form-check' checked={memberModalData?.accessible_modules?.includes("data_access")} 
                                    onChange={(e) => handleCheck(e.target.checked, "data_access")} />
                                <h6 className='my-0 mx-2'>Data Access</h6>
                            </div>
                            <div className='my-1 d-flex align-items-center'>
                                <input type="checkbox" className='form-check' checked={memberModalData?.accessible_modules?.includes("billing")}
                                    onChange={(e) => handleCheck(e.target.checked, "billing")} />
                                <h6 className='my-0 mx-2'>Billing</h6>
                            </div>
                            <div className='my-1 d-flex align-items-center'>
                                <input type="checkbox" className='form-check' checked={memberModalData?.accessible_modules?.includes("user_management")}
                                    onChange={(e) => handleCheck(e.target.checked, "user_management")} />
                                <h6 className='my-0 mx-2'>User management</h6>
                            </div>
                            {errors.accessible_modules && <p className='text-danger'> {errors.accessible_modules} </p>}

                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <h6 className='my-0'>Notification Preferences </h6>
                        </Col>
                        <Col lg={8}>
                            <div className='my-1 d-flex align-items-center'>
                                <input type="checkbox" className='form-check' 
                                    disabled={!memberModalData?.accessible_modules?.includes('data_access')}
                                    checked={memberModalData?.accessible_modules?.includes('data_access') && memberModalData?.notification_preferences?.includes("redmine")} 
                                    onChange={(e) => handleCheck2(e?.target?.checked, "redmine")} />
                                <h6 className='my-0 mx-2'>Redmine</h6>
                            </div>
                            <div className='my-1 d-flex align-items-center'>
                                <input type="checkbox" className='form-check'
                                    disabled={!memberModalData?.accessible_modules?.includes('billing')} 
                                    checked={memberModalData?.accessible_modules?.includes('billing') && memberModalData?.notification_preferences?.includes("invoice")}
                                    onChange={(e) => handleCheck2(e?.target?.checked, "invoice")} />
                                <h6 className='my-0 mx-2'>Invoice</h6>
                            </div>
                            {errors.notification_preferences && <p className='text-danger'> {errors.notification_preferences} </p>}
                        </Col>
                    </Row>
                </>}
            </ModalBody>
            <ModalFooter>
                <Button color='light' onClick={() => setModal(false)}> Close </Button>
                <Button color="success" disabled={loadingBtns} onClick={updateMember}>update</Button>
            </ModalFooter>
        </Modal>
        <Modal isOpen={modal2.visible} toggle={toggleFunc2} backdrop='static' size='md'>
            <ModalHeader toggle={toggleFunc2} close={closeBtn2}>
                Error performing action
            </ModalHeader>
            <ModalBody className='px-5'>
                <h5 dangerouslySetInnerHTML={{__html: modal2.text}} 
                    className='text-danger' />
            </ModalBody>
            <ModalFooter>
                <Button color='danger' onClick={() => setModal2({
                    ...modal2, visible: false
                })}> Close </Button>
            </ModalFooter>
        </Modal>
        <tr>
            <td>
                {data.name}
            </td>
            <td>
                {data.email}
            </td>
            <td className='text-capitalize'>
                {titelize(data?.accessible_modules)}
            </td>
            <td className='text-capitalize'>
                {titelize(data?.notification_preferences)}
            </td>
            <td>
                {data?.invited_by ? data?.invited_by : ''}
            </td>
            <td>
                {data?.status}
            </td>
            <td>
                {
                (current_user?.is_admin || current_user?.accessible_modules.includes("user_management")) 
                && localStorage.getItem("authEmail") !== data?.email &&
                (<>
                    {data.status != 'Invitation Sent' && <button className='btn btn-xs btn-primary mx-1' onClick={toggleFunc}>Edit</button>}
                    <button className='btn btn-xs btn-danger mx-1'
                        onClick={removeUser}>Remove</button>
                </>)}
            </td>
        </tr>
        </>
    )
}
