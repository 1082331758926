import React from 'react';
import { OrgnaziationContext } from '../../../../Home';
import { Download } from 'react-feather';
import { useState, useEffect, useContext } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import DataTable from 'react-data-table-component';

export default function UploadedFilesCard() {

  const { orgName } = useContext(OrgnaziationContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Get last 10 uploaded data for current organization
  useEffect(() => {
    if (orgName) {
      setLoading(true)
      axios.get(`/api/v1/organizations/${orgName}/dashboard/fetch_last_ten_uploaded_files_from_es?page_type=client&controller_name=dashboard`).then(res => {
        setData(res?.data?.last_ten_uploaded_files)
        setLoading(false)
      }).catch(err => {
        setLoading(false)
      })
    }
  }, [orgName]);

  const columns = [
    {
      name: 'Site',
      selector: 'site_name',
      sortable: true,
      center: true,
      width: '400px'
    },
    {
      name: 'No. Of Records',
      selector: 'no_of_records',
      sortable: true,
      center: true,
    },
    {
      name: 'Upload Time',
      selector: 'upload_time',
      sortable: true,
      center: true
    },
    {
      name: 'Download',
      selector: 'download',
      center: true,
      cell: (row, index) => (
        <a href={row.download_url} target='_blank' className='btn btn-primary btn-sm'> <Download size={18} /> </a>
      )
    },
  ];

  return (
    <div>
      <Card>
        <CardBody>
          <h5 className='mb-4'> Last 10 uploaded files </h5>
          {
            loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> :
            <>
              <DataTable columns={columns} data={data} noHeader responsive />
              <br />
              <a href={`/organizations/${orgName}/api_details`} className='text-primary mt-4'>
                Click here for instructions to access rest of the data files
              </a>
            </>
          }
        </CardBody>
      </Card>
    </div>
  )
}
