import React, { useState, Fragment } from 'react'
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { Eye } from 'react-feather';

export default function UploadHtmlFile(props) {
  console.log("props", props);

  const UPLOAD_HTML_FILE_API_ENDPOINT = `/api/v1/internal/chat_gpt_xpath_result_page/upload_file`

  const [htmlFileContent, setHtmlFileContent] = useState('');
  const [showButton, setShowButton] = useState(false);

  // Helper function to upload html file and store it in state
  const validateFileSize = (file) => {
    try {
      // Check if a file is selected
      // Check file size (maxSize in bytes)
      const maxSize = 10 * 1024 * 1024; // 10 MB
      if (file.size > maxSize) {
        throw new Error('File size exceeds the maximum limit (10 MB). Please choose a smaller file.');
        return false;
      }
      return true;
    } catch (error) {
      toast.error(error);
    }
  }

  const handleFileUpload = async event => {
    if (props?.siteName?.length === 0) {
      setHtmlFileContent("")
      showButton(false);
    }
    else {
      const file = event.target.files[0];
      if (file) {
        let status = validateFileSize(file);
        if (status) {
          try {
            const fileContent = await readFileContent(file);
            const response = await uploadFile(file.name, fileContent);
            toast.success(response?.data?.message)
          } catch (error) {
            toast.error(error?.response?.message);
          }
        }
      }
    }
  };

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const content = event.target.result;
        setHtmlFileContent(content);
        setShowButton(true)
        resolve(event.target.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsText(file);
    });
  };

  const uploadFile = async (input_file_name, htmlContent) => {
    const responseBody = {
      filters: {
        site_name: props.siteName,
        tab_name: props.tab_name,
        input_file_name: input_file_name,
        input_file: htmlContent
      }
    }
    try {
      const response = await axios.post(UPLOAD_HTML_FILE_API_ENDPOINT, responseBody)
      props.setInputFilePath(response?.data?.data)
      toast.success(response?.data?.message)
      props.setUploadedFileName(props.siteName);
    }
    catch (error) {
      console.log(error)
      console.log(error?.response)
      toast.error(error?.response?.message)
    }
  };

  function displayHtmlData() {
    // Open a new window and display the HTML content
    const newWindow = window.open();
    newWindow.document.write(htmlFileContent);
  };
  return (
    <Fragment>
      <label className="fw-bold">Upload a HTML File*</label>
      <div>
        <input
          type="file"
          id="fileInput"
          data-tip
          data-for="html-file-upload-required"
          accept=".html"
          disabled={props?.siteName?.length == 0}
          onChange={handleFileUpload}
        />
        {(showButton && props?.siteName) && (<button className='btn btn-primary' onClick={displayHtmlData}>Preview HTML File</button>)}
        {props?.siteName?.length == 0 &&
          <ReactTooltip id={'html-file-upload-required'} effect='solid'>
            <span>Please select a sitename before file upload!</span>
          </ReactTooltip>
        }
      </div>
    </Fragment>
  )
}
