import axios from "axios";
import React from "react";
import { useState } from "react";
import Switch from "react-switch";
import { toast } from "react-toastify";

export default function ToggleButton({ fieldName, requestBody, notificationId, baseURI, active }) {

  let putRequestURI = baseURI.split("?")[0] + `/${notificationId}`;

  const [checked, setChecked] = useState(active);
  const [body, setBody] = useState(requestBody);

  // Helper function to make PUT request to backend
  async function makePutRequest(body) {
    try {
      let response = await axios.put(putRequestURI, { website_notification_preference: body, page_type: "client",  controller_name: "website_notification_preferences"});
      toast.success(response?.data?.message);
    }
    catch (error) {
      toast.error(error.message);
    }
  };

  // Helper function to set and update preferences for crawl notification
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
    if (fieldName === 'crawl_start_notification_preference') {
      body[fieldName] = nextChecked ? "notify_crawl_start" : "dont_notify_crawl_start";
      makePutRequest(body);
      return;
    }
    else if (fieldName === 'crawl_end_notification_preference') {
      body[fieldName] = nextChecked ? "notify_crawl_end" : "dont_notify_crawl_end"
    };
    makePutRequest(body);
    return;
  }
  return (
    <Switch
      className="react-switch"
      name="set_preferance"
      checked={checked}
      onChange={handleChange}
      height={20}
      width={42} />
  )
};

