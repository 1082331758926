import React from 'react'

export default function Footer() {
    return (
        <div className='footer ps-5'>
            <p className='my-0 ms-5'>
                Copyright © 2022 PromptCloud All Rights Reserved.
                <a href='/privacy_policy'> Privacy Policy</a>
            </p>
        </div>
    )
}
