import React,{ useState } from 'react';
import { Formik, Form, Field, ErrorMessage, useField } from 'formik';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, FormGroup, Label, Row, Col } from 'reactstrap';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import axios from 'axios';

const ChangePasswordModal = ({ isOpen, setModal, profile_id }) => {

  const toggleModal = () => setModal(!isOpen)
  const closeBtn = <button className="close" onClick={() => setModal(false)}> &times; </button>;

  const handleSubmit = (values, { setSubmitting, validateForm }) => {
    axios.put(`/api/v1/custom_password/${profile_id}`, {
      user: {
        current_password: values.oldPassword,
        password: values.newPassword,
        password_confirmation: values.confirmNewPassword
      },
      page_type: "client",
      controller_name: "custom_password"
    }).then(res => {
      toast.success(res.data.message);
      setSubmitting(false);
      setModal(false)
    }).catch(err => {
      toast.error(err.response.data.message);
      setSubmitting(false);
    });
  };

  const CustomPasswordField = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const [showPassword, setShowPassword] = useState(false);

    return(
      <FormGroup row className='my-4'>
        <Label htmlFor={props.name} tag="h6" className='fw-bold my-0 text-end h6' sm={6}>{label}:</Label>
        <Col sm={6} style={{position:"relative"}}>
          <input 
          {...field}
          {...props}
          type={showPassword ? 'text' : 'password'}
          className="form-control"
          /> 
          <Button color='link' onClick={()=> setShowPassword(!showPassword)} style={{textDecoration:"none", position:"absolute", right: '10px', top: '50%', transform: 'translateY(-50%)' }}>{showPassword ? 'Hide' : 'Show'}</Button>
        </Col>
        <Row>
          {
           meta.touched && meta.error ? 
           (
            <Col sm={{ size: 6, offset: 6 }}>
              <ErrorMessage name={props.name} component="p" className='text-danger m-0' style={{padding: '7px 12px'}} />
            </Col>
            ) : null
          }
        </Row>
      </FormGroup>
    )
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} backdrop='static' size='lg'>
     <ModalHeader toggle={toggleModal} close={closeBtn}>
      Change Password: {localStorage.getItem("authEmail")}
     </ModalHeader>
     <ModalBody>
        <Formik
        initialValues={{
          oldPassword: '',
          newPassword: '',
          confirmNewPassword: '',
        }}
        validationSchema={Yup.object().shape({
          oldPassword: Yup.string().required('This field is required!'),
          newPassword: Yup.string()
            .required('This field is required!')
            .min(8, 'Password is too short (minimum is 8 characters)')
            .test('not-same-as-old-password', 'New password cannot be the same as the old password', function(value) {
              return value !== this.parent.oldPassword;
            }),
          confirmNewPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'New password and Confirm new password fields are mismatched!')
            .required('This field is required!'),
        })}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <Row>
               <Col sm={11}>
                <CustomPasswordField name="oldPassword" label="Current Password"/>
                <CustomPasswordField name="newPassword" label="New Password"/>
                <CustomPasswordField name="confirmNewPassword" label="Confirm New Password:"/>
               </Col>
               <hr className="my-3" />
               <Col sm={12} className='d-flex justify-content-end'>
                <Button color='light' onClick={() => setModal(false)} className='mx-2'> Close </Button>
                <Button color="success" type='submit' disabled={isSubmitting} className='pr-1'>Update Password</Button>
               </Col>
            </Row>
          </Form>
        )}
        </Formik>
     </ModalBody>
    </Modal>
  );
};

export default ChangePasswordModal;
