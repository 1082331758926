import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Info, Home } from 'react-feather';
import Select from "react-select";
import moment from "moment";
import axios from "axios";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";
import ReactTooltip from 'react-tooltip';

export default function Index() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const searchParams = {
    siteCategory: new URLSearchParams(search).get("filter[site_category]"),
    date: new URLSearchParams(search).get("filter[date]"),
    seleniumCluster: new URLSearchParams(search).get("filter[selenium_cluster]"),
    geoCluster: new URLSearchParams(search).get("filter[geo_cluster]"),
    jobType: new URLSearchParams(search).get("filter[job_type]"),
    clusterType: new URLSearchParams(search).get("filter[cluster_type]"),
  }
  const [siteCategory, setSiteCategory] = useState(
    {
      label: 'Recrawl Scheduled on Selected Date', 
      value: 'all_sites_for_which_recrawl_should_run_today'
    }
  );
  const now = new Date();
  const [date, setDate] = useState(now);
  const [seleniumCluster, setSeleniumCluster] = useState({label: 'All', value: 'all'});
  const [geoCluster, setGeoCluster] = useState({label: 'All', value: 'All'});
  const [jobType, setJobType] = useState({label: 'All', value: 'All'});
  const [clusterType, setClusterType] = useState({label: 'All', value: 'All'});
  const [geoClusterOptions, setGeoClusterOptions] = useState([]);
  const [seleniumClusterOptions, setSeleniumClusterOptions] = useState([]);
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [machineCountData, setMachineCountData] = useState([]);
  const [sitesReportData, setSitesReportData] = useState([]);
  const [queueSizes, setQueueSizes] = useState([]);
  const [jobStatsData, setJobStatsData] = useState([]);
  const [loading, setLoading] = useState(false);

  const siteCategoriesOptions = [
    { label: 'Recrawl Scheduled on Selected Date', value: 'all_sites_for_which_recrawl_should_run_today' },
    { label: 'Recrawl Scheduled in Selected Month', value: 'all_sites_for_which_recrawl_should_run_this_month' },
    { label: 'Feedcrawl Running Now', value: 'all_sites_for_which_feedcrawl_job_was_pushed_in_last_cron' },
    { label: 'All Non-paused Sites', value: 'all_non_paused_sites' }
  ]

  const clusterTypeOptions = [
    { label: 'All', value: 'All' },
    { label: 'cluster1', value: 'cluster1' },
    { label: 'cluster2', value: 'cluster2' },
  ];

  const machineCountTableColumns = [
    { 
      name: 'Geo', 
      selector: 'geo', 
      sortable: true, 
      cell: (row, index) => (
        <>
          <a href={`/internal/aws_and_gce_machine_info/currently_running_machines?filter[cloud_type]=All&filter[job_type]=All&filter[region]=All&filter[tab_name]=running_machines_details`} target="_blank" data-tip data-for={'geo-tooltip-' + index}>{row.geo} <Info size={18} className='mx-2' /></a><br />
          <ReactTooltip id={'geo-tooltip-' + index} delayHide={1000} effect='solid'>
            <p>Go to Currently Running Machines View</p>
          </ReactTooltip>
        </>
        )
    },
    { 
      name: 'Feedcrawl', 
      selector: 'feedcrawl', 
      sortable: true,
      cell: (row, index) => (
        <>
          <a href={`/internal/aws_and_gce_machine_info/currently_running_machines?filter[cloud_type]=All&filter[job_type]=feedcrawl&filter[region]=All&filter[tab_name]=running_machines_details`} target="_blank" data-tip data-for={'feedcrawl-tooltip-' + index}>{row.feedcrawl} <Info size={18} className='mx-2' /></a><br />
          <ReactTooltip id={'feedcrawl-tooltip-' + index} delayHide={1000} effect='solid'>
            <p>Go to Currently Running Machines View</p>
          </ReactTooltip>
        </>
        )
    },
    { 
      name: 'Recrawl',
      selector: 'recrawl', 
      sortable: true,
      cell: (row, index) => (
        <>
          <a href={`/internal/aws_and_gce_machine_info/currently_running_machines?filter[cloud_type]=All&filter[job_type]=recrawl&filter[region]=All&filter[tab_name]=running_machines_details`} target="_blank" data-tip data-for={'recrawl-tooltip-' + index}>{row.recrawl} <Info size={18} className='mx-2' /></a><br />
          <ReactTooltip id={'recrawl-tooltip-' + index} delayHide={1000} effect='solid'>
            <p>Go to Currently Running Machines View</p>
          </ReactTooltip>
        </>
        )
    },
    { 
      name: 'Recrawl and Feedcrawl', 
      selector: 'recrawl_and_feedcrawl', 
      sortable: true,
      cell: (row, index) => (
        <>
          <a href={`/internal/aws_and_gce_machine_info/currently_running_machines?filter[cloud_type]=All&filter[job_type]=recrawl_and_feedcrawl&filter[region]=All&filter[tab_name]=running_machines_details`} target="_blank" data-tip data-for={'recrawl-and-feedcrawl-tooltip-' + index}>{row.recrawl_and_feedcrawl} <Info size={18} className='mx-2' /></a><br />
          <ReactTooltip id={'recrawl-and-feedcrawl-tooltip-' + index} delayHide={1000} effect='solid'>
            <p>Go to Currently Running Machines View</p>
          </ReactTooltip>
        </>
        )
    },
    { 
      name: 'Dependent Data Upload', 
      selector: 'dependent_data_upload', 
      sortable: true,
      cell: (row, index) => (
        <>
          <a href={`/internal/aws_and_gce_machine_info/currently_running_machines?filter[cloud_type]=All&filter[job_type]=dependent_data_upload&filter[region]=All&filter[tab_name]=running_machines_details`} target="_blank" data-tip data-for={'dependent-data-upload-tooltip-' + index}>{row.dependent_data_upload} <Info size={18} className='mx-2' /></a><br />
          <ReactTooltip id={'dependent-data-upload-tooltip-' + index} delayHide={1000} effect='solid'>
            <p>Go to Currently Running Machines View</p>
          </ReactTooltip>
        </>
        )
    },
  ]

  const SiteReportsTableColumns = [
    { 
      name: 'Site Name', 
      selector: 'site_name', 
      sortable: true, 
      cell: (row, index) => (
        <>
          <a href={`/internal/upload?filter[site]=${row.site_name}`} target="_blank" data-tip data-for={'site-tooltip-' + index}>{row.site_name} <Info size={18} className='mx-2' /></a><br />
          <ReactTooltip id={'site-tooltip-' + index} delayHide={1000} effect='solid'>
            <p>Go to Overall Stage Wise Stats</p>
          </ReactTooltip>
        </>
        )
    },
    { 
      name: 'Rss Q size', 
      selector: 'rss_queue_size', 
      sortable: true, 
      cell: (row, index) => (
        <>
          <a href={`/internal/queue_sizes/search?filter[queue_type]=rss_queue&filter[site]=${row.site_name}`} target="_blank" data-tip data-for={'rss-queue-size-tooltip-' + index}>{row.rss_queue_size} <Info size={18} className='mx-2' /></a><br />
          <ReactTooltip id={'rss-queue-size-tooltip-' + index} delayHide={1000} effect='solid'>
            <p>Go to Queue Size View</p>
          </ReactTooltip>
        </>
        )
    },
    { 
      name: 'Drss seed Q size', 
      selector: 'drss_seed_queue_size', 
      sortable: true, 
      cell: (row, index) => (
        <>
          <a href={`/internal/queue_sizes/search?filter[queue_type]=drss_seed_urls_queue&filter[site]=${row.site_name}`} target="_blank" data-tip data-for={'drss-queue-size-tooltip-' + index}>{row.drss_seed_queue_size} <Info size={18} className='mx-2' /></a><br />
          <ReactTooltip id={'drss-queue-size-tooltip-' + index} delayHide={1000} effect='solid'>
            <p>Go to Queue Size View</p>
          </ReactTooltip>
        </>
        )
    },
    { 
      name: 'Dep data upload Q', 
      selector: 'dep_data_upload_queue_size', 
      sortable: true, 
      cell: (row, index) => (
        <>
          <a href={`/internal/queue_sizes/${row.site_name}_dependent_data_upload_queue`} target="_blank" data-tip data-for={'dep-data-upload-queue-size-tooltip-' + index}>{row.dep_data_upload_queue_size} <Info size={18} className='mx-2' /></a><br />
          <ReactTooltip id={'dep-data-upload-queue-size-tooltip-' + index} delayHide={1000} effect='solid'>
            <p>Go to Queue Size View</p>
          </ReactTooltip>
        </>
        )
    },
    { 
      name: 'Dep data failed Q', 
      selector: 'dep_data_failed_queue_size', 
      sortable: true, 
      cell: (row, index) => (
        <>
          <a href={`/internal/queue_sizes/${row.site_name}_dependent_data_upload_failed_queue`} target="_blank" data-tip data-for={'dep-data-failed-queue-size-tooltip-' + index}>{row.dep_data_failed_queue_size} <Info size={18} className='mx-2' /></a><br />
          <ReactTooltip id={'dep-data-failed-queue-size-tooltip-' + index} delayHide={1000} effect='solid'>
            <p>Go to Queue Size View</p>
          </ReactTooltip>
        </>
        )
    },
    { 
      name: 'Test urls Q', 
      selector: 'test_queue_size', 
      sortable: true, 
      cell: (row, index) => (
        <>
          <a href={`/internal/queue_sizes/search?filter[queue_type]=test_run_queue&filter[site]=${row.site_name}`} target="_blank" data-tip data-for={'test-run-queue-tooltip-' + index}>{row.test_queue_size} <Info size={18} className='mx-2' /></a><br />
          <ReactTooltip id={'test-run-queue-tooltip-' + index} delayHide={1000} effect='solid'>
            <p>Go to Queue Size View</p>
          </ReactTooltip>
        </>
        )
    },
    { name: 'Job Type', selector: 'job_type', sortable: true },
    { name: 'Geo graphical Cluster', selector: 'cluster_name', sortable: true },
    { name: 'Cluster Type', selector: 'cluster_type', sortable: true },
    { name: 'Workers per host', selector: 'workers_per_host', sortable: true },
    { name: 'Max hosts', selector: 'max_hosts', sortable: true },
    { name: 'Tot Workers', selector: 'total_workers', sortable: true },
    { 
      name: 'Currently Running Workers', 
      selector: 'running_workers', 
      sortable: true, 
      cell: (row, index) => (
        <>
          <a href={`/internal/upload?filter[site]=${row.site_name}#worker-configurations`} target="_blank" data-tip data-for={'running-workers-tooltip-' + index}>{row.running_workers} <Info size={18} className='mx-2' /></a><br />
          <ReactTooltip id={'running-workers-tooltip-' + index} delayHide={1000} effect='solid'>
            <p>Go to Currently Running Workers View</p>
          </ReactTooltip>
        </>
        )
    },
    { 
      name: 'Currently Waiting Workers', 
      selector: 'waiting_workers', 
      sortable: true, 
      cell: (row, index) => (
        <>
          <a href={`/internal/upload?filter[site]=${row.site_name}#worker-configurations`} target="_blank" data-tip data-for={'waiting-workers-tooltip-' + index}>{row.waiting_workers} <Info size={18} className='mx-2' /></a><br />
          <ReactTooltip id={'waiting-workers-tooltip-' + index} delayHide={1000} effect='solid'>
            <p>Go to Currently Waiting Workers View</p>
          </ReactTooltip>
        </>
        )
    },
  ]

  const [UrlParams, setUrlParams] = useState({
    siteCategory: "all_sites_for_which_recrawl_should_run_today",
    date: now,
    seleniumCluster: "all",
    geoCluster: "All",
    jobType: "All",
    clusterType: "All"
  })

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v1/internal/clusterwise_worker_and_jobs_info/dropdown_options')
      .then(res => {
        let geo_clusters = []
        res.data.geo_clusters.forEach(item => {
          geo_clusters.push({ label: item, value: item })
        })
        geo_clusters.push({ label: 'All', value: 'All' })
        setGeoClusterOptions(geo_clusters);

        let job_types = []
        res.data.job_types.forEach(item => {
          job_types.push({ label: item, value: item })
        })
        job_types.push({ label: 'All', value: 'All' })
        setJobTypeOptions(job_types);

        let selenium_clusters = []
        res.data.selenium_clusters.forEach(item => {
          selenium_clusters.push({ label: item, value: item })
        })
        selenium_clusters.push({ label: 'All', value: 'all' })
        setSeleniumClusterOptions(job_types);
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      });
  }, []);

  function siteCategoryLabel(value) {
    if (value == "all_sites_for_which_recrawl_should_run_today"){
      return "Recrawl Scheduled on Selected Date"
    }
    else if(value == "all_sites_for_which_recrawl_should_run_this_month"){
      return "Recrawl Scheduled in Selected Month"
    }
    else if(value == "all_sites_for_which_feedcrawl_job_was_pushed_in_last_cron"){
      return "Feedcrawl Running Now"
    }
    else if(value == "all_non_paused_sites"){
      return "All Non-paused Sites"
    }
  }

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.siteCategory && searchParams.siteCategory != siteCategory.value) {
      setGeoCluster({ label: siteCategoryLabel(searchParams.siteCategory), value: searchParams.siteCategory });
    }
    if (searchParams.geoCluster && searchParams.geoCluster != geoCluster.value) {
      setGeoCluster({ label: searchParams.geoCluster, value: searchParams.geoCluster });
    }
    if (searchParams.seleniumCluster && searchParams.seleniumCluster != seleniumCluster.value) {
      setSeleniumCluster({ label: searchParams.seleniumCluster, value: searchParams.seleniumCluster });
    }
    if (searchParams.jobType && searchParams.jobType != jobType.value) {
      setJobType({ label: searchParams.jobType, value: searchParams.jobType });
    }
    if (searchParams.clusterType && searchParams.clusterType != clusterType.value) {
      setClusterType({ label: searchParams.clusterType, value: searchParams.clusterType });
    }
    if (searchParams.date && searchParams.date != date) {
      setDate(new Date(searchParams.date));
    }
    if (commit == 'Get Data') {
      setUrlParams(searchParams)
      fetchMachineCountDataWithParams();
      fetchSitesReportDataWithParams();
    }
    else {
      fetchMachineCountData();
      fetchSitesReportData();
    }
  }

  function fetchMachineCountDataWithParams() {
    setLoading(true)
    axios.get(`/api/v1/internal/clusterwise_worker_and_jobs_info/machines_count?filter[geo_cluster]=${searchParams.geoCluster}`)
      .then(res => {
        setMachineCountData(res.data.machines_count);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  function fetchMachineCountData() {
    setLoading(true)
    axios.get(`/api/v1/internal/clusterwise_worker_and_jobs_info/machines_count`)
      .then(res => {
        setMachineCountData(res.data.machines_count);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  function mergeArrayData(sites_report_data, queue_sizes, job_stats_data){
    console.log("sites_report_data", sites_report_data);
    console.log("queue_sizes", queue_sizes);
    console.log("job_stats_data", job_stats_data);
    let tempArr = []; 
    for(let i=0; i<sites_report_data.length; i++) {
      tempArr.push({
        ...sites_report_data[i],
        ...(queue_sizes.find((itmInner) => itmInner.site_name === sites_report_data[i].site_name)),
        ...(job_stats_data.find((itmInner) => itmInner.site_name === sites_report_data[i].site_name))}
      );
    }
    return tempArr;
  }


  function fetchSitesReportDataWithParams() {
    setLoading(true)
    axios.get(`/api/v1/internal/clusterwise_worker_and_jobs_info/integrated_reports?filter[site_category]=${searchParams.siteCategory}&filter[date]=${moment(searchParams.date).format('YYYY/MM/DD')}&filter[selenium_cluster]=${searchParams.seleniumCluster}&filter[geo_cluster]=${searchParams.geoCluster}&filter[job_type]=${searchParams.jobType}&filter[cluster_type]=${searchParams.clusterType}`)
      .then(res => {
        setSitesReportData(res.data.sites);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })

    axios.get(`/api/v1/internal/clusterwise_worker_and_jobs_info/site_wise_queue_sizes`)
      .then(res => {
        setQueueSizes(res.data.queue_sizes);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
      })

    axios.get(`/api/v1/internal/clusterwise_worker_and_jobs_info/site_wise_job_stats`)
      .then(res => {
        setJobStatsData(res.data.job_stats);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
      })
  }

  function fetchSitesReportData() {
    setLoading(true)
    axios.get(`/api/v1/internal/clusterwise_worker_and_jobs_info/integrated_reports`)
      .then(res => {
        setSitesReportData(res.data.sites);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })

    axios.get(`/api/v1/internal/clusterwise_worker_and_jobs_info/site_wise_queue_sizes`)
      .then(res => {
        setQueueSizes(res.data.queue_sizes);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
      })

    axios.get(`/api/v1/internal/clusterwise_worker_and_jobs_info/site_wise_job_stats`)
      .then(res => {
        setJobStatsData(res.data.job_stats);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
      })
  }


  const handleGetData = () => {
    window.location.href = `/internal/clusterwise_worker_and_jobs_info?filter[site_category]=${siteCategory.value}&filter[date]=${moment(date).format('YYYY/MM/DD')}&filter[selenium_cluster]=${seleniumCluster.value}&filter[geo_cluster]=${geoCluster.value}&filter[job_type]=${jobType.value}&filter[cluster_type]=${clusterType.value}&commit=${'Get Data'}`
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/clusterwise_worker_and_jobs_info">Clusterwise Worker and Jobs Info</a> : 'Clusterwise Worker and Jobs Info'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
          </BreadcrumbItem>}
        </Breadcrumb>
      </Col>
    </Row>
  </div>

  <Card>
    <CardBody>
      <Row>
        <Col sm="4">
          <label className="fw-bold">Sites to Show data for</label>
          <Select
            value={siteCategory}
            options={siteCategoriesOptions}
            onChange={option => setSiteCategory(option)} />
        </Col>
        <Col sm="4">
          <label className="fw-bold">Date to be used for report</label>
          <DatePicker
            className="form-control"
            selected={new Date(date)}
            dateFormat={"YYY/MM/dd"}
            onChange={date => setDate(date)} />
        </Col>
        <Col sm="4">
          <label className="fw-bold">Selenium Cluster</label>
          <Select
            value={seleniumCluster}
            options={seleniumClusterOptions}
            onChange={option => setSeleniumCluster(option)} />
        </Col>
      </Row>
      <Row className="align-items-end mt-2">
        <Col sm="4">
          <label className="fw-bold">Geo Cluster</label>
          <Select
            value={geoCluster}
            options={geoClusterOptions}
            onChange={option => setGeoCluster(option)} />
        </Col>
        <Col sm="4">
          <label className="fw-bold">Pipeline Stage to show Resque config for</label>
          <Select
            value={jobType}
            options={jobTypeOptions}
            onChange={option => setJobType(option)} />
        </Col>
        <Col sm="4">
          <label className="fw-bold">Cluster Type</label>
          <Select
            value={clusterType}
            options={clusterTypeOptions}
            onChange={option => setClusterType(option)} />
        </Col>
      </Row>
      <Row className="align-items-end mt-2">
        <Col sm="4">
          <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
        </Col>
      </Row>
    </CardBody>
  </Card>
  <Card>
    <CardBody>
      <Row className="align-items-end mt-2">
        <center>
          <h5 className="mb-2">Machines Count</h5>
          <p><strong>NOTE: The machines count is showing only for GCE related region</strong></p>
        </center>
        {
          loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
          :
          <DataTable
            columns={machineCountTableColumns}
            data={machineCountData}
            noHeader
            pagination
            progressPending={loading}
          />
        }
      </Row>
      <Row className="align-items-end mt-2">
        <center>
          <p><strong>NOTE: <Info size={14} className='ms-2' /> indicates the hover is there. It has some informations or links</strong></p>
        </center>
        {
          loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
          :
          <DataTable
            columns={SiteReportsTableColumns}
            data={mergeArrayData(sitesReportData, queueSizes, jobStatsData)}
            noHeader
            pagination
            progressPending={loading}
          />
        }
      </Row>
    </CardBody>
  </Card>
</Container>
}
