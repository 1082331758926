import React, { useEffect, useState } from "react";
import { Row, Col} from "reactstrap";
import DataTable from "react-data-table-component";
import { DownloadCloud } from "react-feather";

export default function ProxyMayNeedToChange({proxy_may_need_to_change_data, weekNumber, downloadCSVFile}) {

  const [tableData, setTableData] = useState(proxy_may_need_to_change_data);
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults]= useState(proxy_may_need_to_change_data);
  const [loading, setLoading] = useState(false);

  const tableColumns = [
    { name: 'Domain', selector: 'domain', sortable: true, width: "20%" },
    { name: 'Proxy', selector: 'proxy', sortable: true, width: "20%" },
    { name: 'CPM($)', selector: 'cpm', sortable: true, width: "20%"},
    { 
      name: 'Sites', 
      selector: 'sites', 
      sortable: true,
      cell: (row, index) => (
        <>
          <ul className="list-group">
            {row.sites.split("|").map((site, index) => (
              <li>{`${index + 1} ) ${site}`}</li>
            ))}
          </ul>
        </>
      )
    },
  ]

  const handleSearch = event =>{
    if(event.target.value == ''){
      setSearchResults(tableData);
    }
    else{
      setSearchResults(searchFromData(event.target.value));
    }
    setSearchText(event.target.value);
  }

  function searchFromData(value){
    var search_result = tableData.filter(item => item.domain.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.proxy.includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.cpm.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
    var search_result = tableData.filter(item => item.sites.toString().includes(value));
    if (search_result.length > 0){
      return search_result;
    }
  }

  return (
    <>
      <center>
        <h5 className="mb-2">Proxy may need to change</h5>
      </center>
      {
        proxy_may_need_to_change_data.length > 0 && (
          <div className="position-absolute top-0 end-0">
            <button className="btn btn-primary btn-sm mw-25 mh-25 float-right mx-3 my-3 d-inline"
            onClick={()=>downloadCSVFile(weekNumber, 'proxy_may_need_to_change')}>
            <span>Download CSV  <DownloadCloud style={{verticalAlign:"middle", width:"20px", height:"20px"}}/></span>
            </button>
          </div>
        )
      }
      <Row className="align-items-end mt-2">
        <Col>
          <div className="float-end mb-3">
            <Row className="align-items-center">
              <Col sm="3">Search</Col>
              <Col>
                <input type="text" className="form-control mx-1 my-3" value={searchText} onChange={handleSearch} />
              </Col>
            </Row>
          </div>
        </Col>
        <DataTable
          columns={tableColumns}
          data={searchResults}
          noHeader
          pagination
          progressPending={loading}
        />
      </Row>
    </>
  )
}
