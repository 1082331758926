// React imports
import React from 'react';
import { useEffect, useState, useContext, useMemo } from 'react';

// Third party imports
import { Row, Col, Table } from 'reactstrap';
import axios from 'axios';

// Components and scripts imports
import { AdminContext } from '../../../Context/Exports/ContextExports';
import SpinnerButton from '../../../Common/SpinnerButton/SpinnerButton.component';
import PaymentUnderProcessNotifier from './PaymentUnderProcessNotifier/PaymentUnderProcessNotifier.component';
import OrganizationAddress from './OrganizationAddress/OrganizationAddress.Component';
import InvoiceTable from './InvoiceTable/InvoiceTable.component';

export default function PaidInvoice({ invoiceData, organization_name, invoiceId }) {

  const [data, setData] = useState(data);
  const [buttonLoading, setButtonLoading] = useState(false); // used for loading animation for downloading PDF

  const { superUser } = useContext(AdminContext); // Check if user has admin privileges

  // PDF INVOICE DOWNLOAD API ENDPOINT
  const INVOICE_PDF_DOWNLOAD_ENDPOINT = `/api/v1/organizations/${organization_name}/invoices/${invoiceId}/download_as_pdf`;

  // Props propogation takes a while due to slow response from server
  // Hence, use a useEffect hook to avoid the 'data is undefined' issue
  useEffect(() => {
    setData(invoiceData)
  }, [data])

  // Helper function to download invoice in the form of pdf
  function handleDownload() {
    setButtonLoading(true);
    axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem("authToken")}`;
    axios.defaults.headers["Content-Type"] = `application/pdf`;
    axios.defaults.headers["Accept"] = `application/pdf`;
    axios({
      url: INVOICE_PDF_DOWNLOAD_ENDPOINT,
      method: 'GET',
      responseType: 'blob'
    })
      .then((res) => {
        const url_to_download = window.URL.createObjectURL(new Blob([res.data]));
        const a_tag = document.createElement('a');
        a_tag.href = url_to_download;
        a_tag.setAttribute('download', `invoice-${invoiceId}.pdf`);
        document.body.appendChild(a_tag);
        setButtonLoading(false);
        a_tag.click();
      });
    axios.defaults.headers["Content-Type"] = `application/json`;
    axios.defaults.headers["Accept"] = `application/json`;
  }

  return (
    <div>
      <div className='card'>
        <div className='card-body'>
          <div className='invoice'>
            <PaymentUnderProcessNotifier underProcess={data?.any_payments_being_processed} />
            <div>
              <div>
                <Row>
                  <Col sm={'7'}>
                    <OrganizationAddress serviceToUse={data?.service_to_use} />
                  </Col>
                  <Col sm={'5'}>
                    <div className='text-md-end text-xs-center'>

                      {/* The invoice number */}
                      <h4>Invoice #{data?.invoice_number && data?.invoice_number}</h4>

                      {/* Invoice date */}
                      <p className='m-0 my-1'>Issued: {data?.invoice_date && data?.invoice_date} </p>

                      {/* Payment status */}
                      {data?.paid_at && <p className='m-0 my-1'> Paid at: {data?.paid_at} </p>}

                      {/* Freshbook Id, if user is admin and service used is freshbooks */}
                      {(data?.service_type === "freshbooks" && superUser) &&
                        <a href={data?.fb_invoice_link} className='btn btn-outline-primary mt-3' target="_blank">
                          {`fresh_${data?.invoice_number}`}
                        </a>
                      }

                      {/* Quickbooks Id, if user is admin and the service used is quickbooks  */}
                      {(data?.service_type === "quickbooks" && superUser) &&
                        <a href={data?.qb_invoice_link} className='btn btn-outline-primary mt-3' target="_blank">
                          {`quick_${data?.invoice_number}`}
                        </a>
                      }

                      {/* Zohobooks Id, if user is admin and the service used is zohobooks  */}
                      {(data?.service_type === "zohobooks" && superUser) &&
                        <a href={data?.zb_invoice_link} className='btn btn-outline-primary mt-3' target="_blank">
                          {`zoho_${data?.invoice_number}`}
                        </a>
                      }

                    </div>
                  </Col>
                </Row>
              </div>
              <hr />
              <Row>
                <Col md={"7"}>
                  <div className="media">
                    <div className="media-body m-l-20">
                      <h5 className="media-heading">To: {data?.to_address?.length === 2 ? data?.to_address[0] : data?.to_address[0]} </h5>
                      {data?.to_address?.length !== 2 && data?.to_address.map((each, index) => {
                        return (
                          index !== 0 && <React.Fragment key={index}> <span> {each} </span> <br /> </React.Fragment>
                        )
                      })}
                      {data?.to_address?.length === 2 && data?.to_address[1].map((each, index) => {
                        return (
                          <React.Fragment key={index}> <span> {each} </span> <br /> </React.Fragment>
                        )
                      })}
                    </div>
                  </div>
                </Col>
                <Col md="5">
                  <div className="text-md-end" id="project">
                    <h5>Invoice summary: </h5>
                    <Table responsive className='float-right w-auto'>
                      <tbody>
                        <tr>
                          <td className="fw-bold mx-5">
                            Total:
                          </td>
                          <td className="mx-5">
                            {data?.total && data?.total}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-bold mx-5">
                            Amount received:
                          </td>
                          <td className="mx-5">
                            {data?.amount_received && data?.amount_received}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-bold mx-5">
                            Balance Due:
                          </td>
                          <td className="mx-5">
                            {data?.balance_due && data?.balance_due}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
              <InvoiceTable data={data} />
            </div>
            <div className="col-sm-12 text-center mt-5">
              <button className="btn btn-outline-success me-3">Paid</button>
              <SpinnerButton color='success' onClick={() => handleDownload()} loading={buttonLoading}>Download</SpinnerButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
