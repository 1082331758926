/*
    This Data Table is rendered by Index component
*/
import React from 'react';
import { useMemo } from 'react';
import DataTable from 'react-data-table-component';


export default function DRLConfigureWorkerTable({ data, progressPending }) {
    // Table Column Definitions
    const columns = useMemo(()=>[
        { name: 'Site Name', selector: 'site', sortable: true },
        { name: 'Rss Q size', selector: 'rss_queue_size', sortable: true },
        { name: 'Rss Count', selector: 'rss_count', sortable: true },
        { name: 'Feedcrawl Count', selector: 'feedcrawl_count', sortable: true },
        { name: 'Upload Count', selector: 'upload_count', sortable: true },
        { name: 'Job Type', selector: 'job_type', sortable: true },
        { name: 'Geographical Cluster', selector: 'cluster_name', sortable: true },
        { name: 'Workers Per Host', selector: 'workers_per_host', sortable: true },
        { name: 'Max Hosts', selector: 'max_hosts', sortable: true },
        { name: 'Tot workers', selector: 'tot_workers', sortable: true },
    ], []);
    return (
        <>
            <DataTable
                data={data}
                columns={columns}
                noHeader
                pagination
                progressPending={progressPending}
            />
        </>
    )
}
