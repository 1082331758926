import React, { useState, useEffect, useMemo, Fragment } from 'react'
import { useLocation, Link } from 'react-router-dom'

import { Card, CardBody, Col, Container, Row, Breadcrumb, BreadcrumbItem, Alert } from 'reactstrap'
import DataTable from 'react-data-table-component';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { Home } from 'react-feather';
import axios from 'axios'

import SpinnerLoader from '../../../Common/LoadingAnimation/SpinnerLoader.component'
import QualityAnalysisReportsBreadcrumbs from './QualityAnalysisReportsBreadcrumbs'
import QualityAnalysisReportsTable from './QualityAnalysisReportsTable'

import { NewInternalBasePoint } from '../../../../Common/BaseEsPoints/InternalEsBasePoint';

export default function QualityAnalysisReports() {
  const search = useLocation().search
  const pendingQaValue = new URLSearchParams(search).get('filter[pending_qa]')
  const siteValue = new URLSearchParams(search).get('filter[site]')
  const devSiteQaValue = new URLSearchParams(search).get('filter[dev_site_qa_status]')
  const bizSiteQaValue = new URLSearchParams(search).get('filter[biz_site_qa_status]')
  const commitValue = new URLSearchParams(search).get('commit')

  const [data, setData] = useState({})
  const [dropdownOptions, setDropdownOptions] = useState({})
  const [selectedSite, setSelectedSite] = useState({ value: '', label: 'All' })
  const [loading, setLoading] = useState(false)
  const [selectedDevQaStatus, setSelectedDevQaStatus] = useState('')
  const [selectedBizQaStatus, setSelectedBizQaStatus] = useState('')
  const [pendingQa, setPendingQa] = useState(null)
  const [tableData, setTableData] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [duplicateData, setDuplicateData] = useState([])


  const QA_REPORTS_SEARCH_API_ENDPOINT = useMemo(() => (
    `/api/v2/internal/qa_reports/search?filter[qa_pending_records]=${pendingQa ? 'yes' : 'no'}&filter[site_name]=${selectedSite.value ? selectedSite.value : (siteValue || '')}&filter[dev_site_qa_status]=${devSiteQaValue || (selectedDevQaStatus != '' ? selectedDevQaStatus : 0)}&filter[biz_site_qa_status]=${bizSiteQaValue || (selectedBizQaStatus != '' ? selectedBizQaStatus : 0)}`
  ), [pendingQa, selectedSite, siteValue, devSiteQaValue, selectedDevQaStatus, bizSiteQaValue, selectedBizQaStatus])

  const QA_REPORTS_PENDING_API_ENDPOINT = `/api/v2/internal/qa_reports/pending`

  const QA_REPORTS_API_ENDPOINT = '/api/v2/internal/qa_reports'


  // THis fetches site names from backend
  useEffect(() => {
    fetchSiteNames();
  }, [])


  // THis sets states whenever the URL changes
  useEffect(() => {
    if (siteValue) {
      setSelectedSite({ label: siteValue, value: siteValue })
    }
    if (pendingQaValue) {
      setPendingQa(pendingQaValue)
    }
    if (devSiteQaValue) {
      setSelectedDevQaStatus(devSiteQaValue)
    }
    if (bizSiteQaValue) {
      setSelectedBizQaStatus(bizSiteQaValue)
    }
    getUploadData()
  }, [pendingQaValue, devSiteQaValue, commitValue, siteValue])

  // Helper function to fetch and set data table data when URL includes pending, serch or qa_report
  const getUploadData = () => {
    if (window.location.pathname.includes('/qa_report/search')) {
      fetchAndSetDataForAPIUrl(QA_REPORTS_SEARCH_API_ENDPOINT)
    }
    else if (window.location.pathname.includes('/qa_report/pending')) {
      fetchAndSetDataForAPIUrl(QA_REPORTS_PENDING_API_ENDPOINT)
    }
    else if (window.location.pathname.includes('/qa_report')) {
      fetchAndSetDataForAPIUrl(QA_REPORTS_API_ENDPOINT)
    }

  }

  // THis useEffect fetches data based on the URL
  useEffect(() => {
    let tempTableData = []
    if (window.location.pathname.includes('/qa_report/search')) {
      tempTableData = data?.qa_report_list
    }
    else if (window.location.pathname.includes('/qa_report/pending')) {
      tempTableData = data?.pending_qa_report_list
    }
    else if (window.location.pathname.includes('/qa')) {
      tempTableData = data?.qa_report_list
    }
    setDuplicateData(tempTableData)
    setTableData(tempTableData)
  }, [data])


  // THis use effect is used for handeling the search 
  useEffect(() => {
    if (searchValue) {
      let res = []
      tableData.forEach(each => {
        if (
          each?.dev_site_qa_status_in_text?.includes(searchValue) ||
          each?.biz_site_qa_status_in_text?.includes(searchValue) ||
          each?.updated_at?.toString()?.includes(searchValue) ||
          each?.sample_data_record?.includes(searchValue) ||
          each?.dev_comment?.includes(searchValue) ||
          each?.assignee1?.includes(searchValue) ||
          each?.assignee2?.includes(searchValue) ||
          each?.site_name?.includes(searchValue) ||
          each?.comment?.includes(searchValue)
        ) {
          res.push(each)
        }
      })
      setDuplicateData(res)
    } else {
      setDuplicateData(tableData)
    }
  }, [searchValue])


  // Helper function to fetch data based on the URL and display it in data table
  async function fetchAndSetDataForAPIUrl(apiUrl) {
    try {
      setLoading(true)
      const res = await axios.get(apiUrl)
      setData(res?.data)
      setLoading(false)
    }
    catch (err) {
      toast.error(err?.response?.data?.message)
      setLoading(false)
    }
  }


  // Helper function to fetch data and set URL with
  // Appropriate params when get data button is clicked
  const onGetDataButtonClickHandler = () => {
    let tempPendingQA = 0
    if (pendingQaValue == 0 || pendingQaValue == 1) {
      tempPendingQA = pendingQaValue
    }
    else if (window.location.pathname.includes('/qa_report/pending')) {
      tempPendingQA = 1
      setPendingQa(1)
    }
    else {
      tempPendingQA = 0
      setPendingQa(0)
    }
    if (data?.current_user_can_access_biz_qa) {
      window.location.href = `${NewInternalBasePoint}/qa_report/search?utf8=✓&filter%5Bpending_qa%5D=${tempPendingQA}&filter%5Bsite%5D=${selectedSite.value || ''}&filter%5Bdev_site_qa_status%5D=${(selectedDevQaStatus != '' ? selectedDevQaStatus : 0)}&filter%5Bbiz_site_qa_status%5D=${(selectedBizQaStatus != '' ? selectedBizQaStatus : 0)}&commit=Get+Data`
    }
    else {
      window.location.href = `${NewInternalBasePoint}/qa_report/search?utf8=✓&filter%5Bpending_qa%5D=${tempPendingQA}&filter%5Bsite%5D=${selectedSite.value || ''}&filter%5Bdev_site_qa_status%5D=${(selectedDevQaStatus != '' ? selectedDevQaStatus : 0)}&commit=Get+Data`
    }
  }

    // Helper function to fetch the site names for dropdown options
    function fetchSiteNames() {
      axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let siteNames = res?.data?.site_names, siteNamesOptions = []
        for (var key in siteNames) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
          siteNames[key].map(item => {
            children.push({ label: item, value: item })
          })
          group['options'] = children;
          siteNamesOptions.push(group)
        }
        siteNamesOptions.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
        setDropdownOptions(siteNamesOptions)
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      })
    }
  
    // Helper function to fetch the site names for dropdown options based on user input
    const promiseOptions = (inputValue) => {
      if(inputValue.length < 3){
        return null
      } else {
        return new Promise((resolve) => {
          axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
            if (res.status == '200'){
              let siteNames = res?.data?.site_names, siteNamesOptions = []
              for (var key in siteNames) {
                let group = {}, children = []
                group['label'] = <div className="fw-bold col text-black">{key}</div>
                siteNames[key].map(item => {
                  children.push({ label: item, value: item })
                })
                group['options'] = children;
                siteNamesOptions.push(group)
              }
              siteNamesOptions.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
              setDropdownOptions(siteNamesOptions)
              resolve(siteNamesOptions)
            }
          }).catch(err => {
            console.log(`err`, err)
          })
        })
      }
    }



  return (
    <Container fluid>
      <QualityAnalysisReportsBreadcrumbs />
      <SpinnerLoader loading={loading}>
        <Card className='my-3'>
          <CardBody>
            <Row className='my-3'>
              <Col md={12}>
                <h6 className='fw-bold'>Site Name: </h6>
                <AsyncSelect
                  onChange={res => setSelectedSite(res)}
                  defaultOptions={dropdownOptions}
                  placeholder="Select a site name"
                  loadOptions={promiseOptions}
                  value={selectedSite}
                  name='site_name'
                  cacheOptions
                />
                <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
              </Col>
            </Row>
            <Row className='my-3'>
              <Col md={4}>
                <h6 className='fw-bold'>DevQA Status</h6>
                <select className='form-control' value={selectedDevQaStatus}
                  onChange={(e) => setSelectedDevQaStatus(e.target.value)}>
                  <option value=''></option>
                  <option value='0'>Does not look good!</option>
                  <option value='1'>All Fine</option>
                </select>
              </Col>
              <Col md={4}>
                <h6 className='fw-bold'>BizQA Status</h6>
                <select className='form-control' value={selectedBizQaStatus}
                  disabled={!(data?.current_user_can_access_biz_qa)}
                  onChange={(e) => setSelectedBizQaStatus(e.target.value)}>
                  <option value=''></option>
                  <option value='0'>Does not look good!</option>
                  <option value='1'>All Fine</option>
                </select>
              </Col>
              <Col md={4}>
                <button
                  className='btn btn-success'
                  style={{ width: '100%', marginTop: 27 }}
                  onClick={() => onGetDataButtonClickHandler()}> Get Data </button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {
          duplicateData?.length > 0 ?
            <Card>
              <CardBody>
                <Row className='mb-2'>
                  <Col md={7} className='my-3'></Col>
                  <Col md={5} className='my-3'>
                    <div className='d-flex justify-content-center align-items-center'>
                      <h6 className='my-0 me-2'>Search:</h6>
                      <input type='text' className='form-control' value={searchValue}
                        onChange={(e) => setSearchValue(e.target?.value)} />
                    </div>
                  </Col>
                </Row>
                <QualityAnalysisReportsTable data={duplicateData} />
              </CardBody>
            </Card>
            :
            <center><Alert color='dark'>Sorry! No records found!</Alert></center>
        }
      </SpinnerLoader >
    </Container >
  )
}
