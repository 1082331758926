import React, {useState, useEffect, useMemo} from 'react'
import { CardBody, Col, Card, Container, Row, CardHeader } from 'reactstrap';
import Breadcrumb from '../../global/Breadcrumb'
import axios from 'axios'
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import OrganizationCard from './OrganizationCard';

export default function OrganizationSearch() {
    const [data, setData] = useState([])
    const [inpData, setInpData] = useState({user: '', organization: '', project: '', website: ''})
    const [loading, setLoading] = useState(false)

    const formReset = () => {
        setInpData({user: '', organization: '', project: '', website: ''})
    }

    const handleSearch = () => {
        setLoading(true)
        axios.get(`/api/v1/admins/search?search[user]=${inpData.user}&search[organization]=${inpData.organization}&search[sitegroup]=${inpData.project}&search[site]=${inpData.website}`).then(res => {
            console.log('res.data', res.data)
            setData(res.data?.data)
            setLoading(false)
        }).catch(err => {
            toast.error(err.response.data.message)
            setLoading(false)
        })
    }

    return (
        <Container fluid>
            <Breadcrumb parent={"Search Page"} title={"Promptcloud"} />

            <Card>
                <CardBody>
                    <Row>
                        <Col md={3} className='my-2'>
                            <h6 className='fw-bold'>User:</h6>
                            <input type="text" className='form-control' placeholder='Email'
                                value={inpData.user} onChange={(e) => setInpData({...inpData, user: e.target.value})} />
                        </Col>
                        <Col md={3} className='my-2'>
                            <h6 className='fw-bold'>Organization:</h6>
                            <input type="text" className='form-control' placeholder='Organization Name'
                                value={inpData.organization} onChange={(e) => setInpData({...inpData, organization: e.target.value})} />
                        </Col>
                        <Col md={3} className='my-2'>
                            <h6 className='fw-bold'>Project/Sitegroup:</h6>
                            <input type="text" className='form-control' placeholder='Sitegroup Title' 
                               value={inpData.project} onChange={(e) => setInpData({...inpData, project: e.target.value})} />
                        </Col>
                        <Col md={3} className='my-2'>
                            <h6 className='fw-bold'>Website:</h6>
                            <input type="text" className='form-control' placeholder='Sitename' 
                                value={inpData.website} onChange={(e) => setInpData({...inpData, website: e.target.value})} />
                        </Col>
                        <Col md={3} className='my-2'>
                            <button className='btn btn-primary btn-block w-100' disabled={loading}
                                onClick={handleSearch}>Search</button>
                        </Col>
                        <Col md={3} className='my-2'>
                            <button className='btn btn-danger btn-block w-100'
                                onClick={formReset}>Form Reset</button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <div>
                {loading ? 
                <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> 
                : data.map((each, index) => (
                    <OrganizationCard key={index} each={each} />
                ))}
            </div>
        </Container>
    )
}