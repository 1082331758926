import React, {useState, useEffect} from 'react'
import { Col, Container, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button  } from 'reactstrap'
import Select from 'react-select';
import DatePicker from 'react-datepicker'
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function RunForceRunFailed() {
    const [selectedInnerCommand, setSelectedInnerCommand] = useState('')
    const [selectedServer, setSelectedServer] = useState('')
    const [selectedFromDate, setSelectedFromDate] = useState('')
    const [selectedToDate, setSelectedToDate] = useState('')
    const [dropdownOptions, setDropdownOptions] = useState({})
    const [selectedSite, setSelectedSite] = useState()
    const [extraction, setExtraction] = useState(true)
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const [serverOptions, setServerOptions] = useState([])
    const [modal, setModal] = useState(false)

    const toggle = () => {
        setModal(curr => !curr)
    }

    const today = new Date(Date.now())
    const innerCommandOptions = [
        {value: 'run_failed', label: 'Run Failed'},
        {value: 'run_force', label: 'Run Force'},
    ]    

    useEffect(() => {
        fetchSiteNames();
    }, [])

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('/');
    }
    
    function fetchSiteNames() {
        axios.get('/api/v1/internal/dropdown_list_helper')
        .then(res => {
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
            let group = {}, children = []
            group['label'] = <div className="fw-bold col text-black">{key}</div>
            site_names[key].map(item => {
                children.push({ label: item, value: item })
            })
            group['options'] = children;
            site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setDropdownOptions(site_names_options)
            let machine_names = []
            res.data.machine_names.forEach(item => {
            machine_names.push({ label: item, value: item })
            })
            machine_names.push({ label: 'All', value: '*' })
            console.log('machine_names', machine_names)
            setServerOptions(machine_names);
        })
        .catch(err => {
            console.error(err?.message || 'Oops something went wrong')
        })
    }
      
    const promiseOptions = (inputValue) => {
        if(inputValue.length < 3){
            return null
        } else {
            return new Promise((resolve) => {
            axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
                if (res.status == '200'){
                let site_names = res.data.site_names, site_names_options = []
                for (var key in site_names) {
                    let group = {}, children = []
                    group['label'] = <div className="fw-bold col text-black">{key}</div>
                    site_names[key].map(item => {
                    children.push({ label: item, value: item })
                    })
                    group['options'] = children;
                    site_names_options.push(group)
                }
                site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                setDropdownOptions(site_names_options)
                resolve(site_names_options)
                }
            }).catch(err => {
                    console.log(`err`, err)
                })
            })
        }
    }

    const handleSubmit = () => {
        setLoading(true)
        setErrors({})
        let tempObj = {}
        if (selectedInnerCommand?.length < 1){
            tempObj = {...tempObj, inner_command:true}
        }
        if (selectedServer?.length < 1){
            tempObj = {...tempObj, server:true}
        }
        if (selectedFromDate?.length < 1){
            tempObj = {...tempObj, from_date:true}
        }
        if (selectedToDate?.length < 1){
            tempObj = {...tempObj, to_date:true}
        }
        if (!(selectedSite?.value)){
            tempObj = {...tempObj, site:true}
        }
        setErrors(tempObj)

        if (Object.keys(tempObj)?.length == 0){
            if (selectedInnerCommand?.value == 'run_force'){
                setModal(true)
            }
            else {
                handleDataSubmission()
            }
        }
        else {
            setLoading(false)
        }
    }

    const closeBtn = <button className="close" 
        onClick={() => setModal(false)}> &times; </button>;

    const handleDataSubmission  = () => {
        axios.post('/api/v1/internal/command_dashboard/pipeline_utils', {
            filter: {
                outer_command: "pipeline_utils", inner_command: selectedInnerCommand?.value, server: selectedServer?.value,
                op: { e: extraction ? "on" : "off" }, sitename: selectedSite?.value,
                date: { from: formatDate(selectedFromDate), to: formatDate(selectedToDate)},
            }
        }).then(res => {
            toast.success(res?.data?.message)
            setLoading(false)
            window.location = `/internal/command_dashboard`
        }).catch(err => {
            toast.error(err?.response?.data?.message)
            setLoading(false)
        })
        setModal(false)
    }

    return (
        <Container>
            <Modal isOpen={modal} toggle={toggle} className="modal-body" backdrop='static' size='lg' >
                <ModalHeader toggle={toggle} close={closeBtn}>
                    <h6 className='fw-bold'>Are you sure that you want to submit the form?</h6>
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <h6>You have selected, </h6>
                        <h6>The Inner command is <b>{selectedInnerCommand?.value}</b></h6>
                        <h6>The Sitename is <b>{selectedSite?.value}</b></h6>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => {
                        setModal(false)
                        setLoading(false)
                    }}>No</Button>
                    <Button color="success" onClick={handleDataSubmission}>Yes</Button>
                </ModalFooter>
            </Modal>
            <Row>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Outer Command (Non-Editable)</h6>
                    <input placeholder='pipeline_utils' value='pipeline_utils'
                        className='form-control' disabled />
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Select Inner Command*</h6>
                    <Select value={selectedInnerCommand} onChange={(option) => setSelectedInnerCommand(option)} isSearchable
                        isClearable classNamePrefix options={innerCommandOptions}
                        placeholder='choose a value' />
                    {errors?.inner_command && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Server Name*</h6>
                    <Select value={selectedServer} onChange={(option) => setSelectedServer(option)} isSearchable
                        isClearable classNamePrefix options={serverOptions} placeholder='choose a value' />
                    {errors?.server && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                </Col>

                <Col md={3} className='my-3'>
                    <h6 className='fw-bold'>Select Operation</h6>
                    <div className='d-flex align-items-center'>
                        <input className="form-check-input me-2" type="checkbox" checked disabled />
                        <h6 className='fw-bold m-0'> FeedCrawl (Disabled) </h6> 
                    </div>
                </Col>

                <Col md={3} className='my-3 d-flex justify-content-center'>
                    <div className='d-flex align-items-center'>
                        <input className="form-check-input me-2" type="checkbox" checked={extraction} 
                           onChange={(e) => setExtraction(e.target.checked)} />
                        <h6 className='fw-bold m-0'> Extraction </h6> 
                    </div>
                </Col>

                <Col md={3} className='my-3'>
                    <h6 className='fw-bold'>From Date*</h6>
                    <DatePicker className="form-control my-2" dateFormat="yyyy/MM/dd" selected={selectedFromDate}
                        maxDate={today} onChange={(date) => setSelectedFromDate(date)} />
                    {errors?.from_date && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                </Col>

                <Col md={3} className='my-3'>
                    <h6 className='fw-bold'>To Date*</h6>
                    <DatePicker className="form-control my-2" dateFormat="yyyy/MM/dd" selected={selectedToDate}
                        maxDate={today} onChange={(date) => setSelectedToDate(date)} />
                    {errors?.to_date && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                </Col>

                {selectedInnerCommand?.value == 'run_force' && <Row>
                <Col md={6}></Col>    
                <Col md={6}>
                    <p className='m-0'><b>NOTE:</b> To reduce the DB load due to run_force, please run multiple run force jobs with a date range of maximum 10 days.</p>
                </Col>    
                </Row>}

                <Col md={8} className='my-3'>
                    <h6 className='fw-bold'>Site Name*</h6>
                    <AsyncSelect cacheOptions defaultOptions={dropdownOptions} name='site_name' 
                        value={selectedSite} loadOptions={promiseOptions} onChange={res => setSelectedSite(res)}
                        placeholder="Select a site name" />
                    {errors?.site && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                    <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
                </Col>

                <Col md={4} className='my-3'>
                    <button className='btn btn-success' onClick={handleSubmit}
                        disabled={loading}
                        style={{margin: '25px auto', width: '100%'}}> Submit </button>
                </Col>
            </Row>
        </Container>
    )
}
