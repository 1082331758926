import React, { useState } from 'react';
import { Modal, Button, ModalBody, ModalHeader, ModalFooter, FormGroup, Input, Label } from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

export default function SetBillingAlarms({ orgName, country }) {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(curr => !curr);
  };

  const closeBtn = <button className="close" onClick={() => setModal(false)}> &times; </button>;

  const initialValues = {
    alertType: 'records_count',
    records_count: 0,
    bill_amount: 0
  };

  const validationSchema = Yup.object({
    alertType: Yup.string().required('Alert type is required'),
    records_count: Yup.number()
        .typeError('Records count must be a number')
        .when('alertType', {
            is: 'records_count',
            then:()=> Yup.number().required('Records count is required').positive('Must be greater than zero'),
            otherwise:()=> Yup.mixed().strip()
        }),
    bill_amount: Yup.number()
        .typeError('Bill Amount must be a number')
        .when('alertType', {
            is: 'bill_amount',
            then:()=> Yup.number().required('Bill amount is required').positive('Must be greater than zero'),
            otherwise:()=> Yup.mixed().strip()
        })
  });


  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);

    const payload = {
      billing_alerts: {
        alert_by: values.alertType,
        [values.alertType]: values[values.alertType]
      },
      page_type: "client",
      controller_name: "organization_billing_alerts"
    };

    axios.post(`/api/v1/organizations/${orgName}/organization_billing_alerts`, payload)
      .then(res => {
        toast.success(res.data.message);
        setModal(false);
        setLoading(false);
        setSubmitting(false);
      })
      .catch(err => {
        toast.error(err.response.data.message);
        setLoading(false);
        setSubmitting(false);
      });
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop='static' size='lg'>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Set Billing Alarms
        </ModalHeader>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, errors }) => {
            return(
            <Form>
              <ModalBody className='p-5'>
                <div>
                  <h6 className='fw-bold mb-5'>
                    Notify me when:
                  </h6>
                  <FormGroup check className='d-flex my-3 align-items-start'>
                    <Field type="radio" name="alertType" value="records_count" className='me-3 mt-1'  />
                    {' '}
                    <Label check className='px-1'>
                      total records count for the month exceeds
                    </Label>
                    <div className='d-flex flex-column mx-2'>
                      <Field
                        name="records_count"
                        type="text"
                        placeholder='100'
                        className='form-control ms-2 m-0'
                        disabled={values.alertType !== 'records_count'}
                        style={{ width: 240 }}
                      />
                      <ErrorMessage name="records_count" component="p" className="m-0 error-msg mt-1 mb-2 ml-2" />
                    </div>
                  </FormGroup>
                  <FormGroup check className='d-flex my-3 align-items-start'>
                    <Field type="radio" name="alertType" value="bill_amount" className='me-3 mt-1' />
                    {' '}
                    <Label check className='px-1'>
                      total charges for the month exceeds
                    </Label>
                    <div className='d-flex align-items-center ms-2'>
                      <button className='btn text-primary px-1'>{getSymbolFromCurrency(country)}</button>
                      <div className='d-flex flex-column mx-2'>
                        <Field
                          name="bill_amount"
                          type="number"
                          className='form-control ms-2 m-0'
                          disabled={values.alertType !== 'bill_amount'}
                          style={{ width: 240 }}
                        />
                        <ErrorMessage name="bill_amount" component="p" className="m-0 error-msg mt-1 mb-2" />
                      </div>
                    </div>
                  </FormGroup>
                </div>
              </ModalBody>
              <ModalFooter>
                {loading ? (
                  <Button className='primary mx-2' disabled>
                    Loading ...
                  </Button>
                ) : (
                  <Button color="primary" className='mx-2' type="submit" disabled={isSubmitting}>
                    Set Alarm
                  </Button>
                )}
              </ModalFooter>
            </Form>
          )}}
        </Formik>
      </Modal>
      <Button className='mx-2' color="primary" onClick={toggle}> Set Billing Alarms </Button>
    </>
  );
}
