import React from 'react';
import { Row, Col } from 'reactstrap';
import { Card, CardHeader, CardBody } from 'reactstrap';
import ApexCharts from 'react-apexcharts';
import Loader from 'react-loader-spinner';
import SpinnerLoader from '../../../../V2/Common/LoadingAnimation/SpinnerLoader.component';

export default function UploadsCountChart({ data, chartOptions, chartSeries, loading }) {
  return (
    <div>
      <Row>
        <Col xl="12 xl-100" className="dashboard-sec box-col-12">
          <Card className="earning-card">
            <CardHeader className='p-4 fw-bold'>
              Records uploaded in last 30 days
            </CardHeader>
            <CardBody className="p-0">
              <Row className="m-0">
                <Col xl="3" className="earning-content p-0">
                  <Row className="m-0 chart-left">
                    <Col xl="12" className="p-0 left_side_earning">
                      <h5>Dashboard</h5>
                      <p className="font-rubik">Overview of last month</p>
                    </Col>
                    <Col xl="12" className="p-0 left_side_earning">
                      <h5>
                        {data?.active_sites}
                      </h5>
                      <p className="font-rubik">Active Sites</p>
                    </Col>
                    <Col xl="12" className="p-0 left_side_earning">
                      <h5>
                        {data?.new_records_today}
                      </h5>
                      <p className="font-rubik">New Records Today</p>
                    </Col>
                    <Col xl="12" className="p-0 left_side_earning">
                      <h5>
                        {data?.current_bill}
                      </h5>
                      <p className="font-rubik">Current Bill</p>
                    </Col>
                    <Col xl="12" className="p-0 left-btn"></Col>
                  </Row>
                </Col>
                <Col xl="9" className="p-0">
                  <div className="chart-right">
                    <Row className="m-0 p-tb">
                      <Col xl="8" md="8" sm="8" className="col-12 p-0">

                      </Col>
                      <Col xl="4" md="4" sm="4" className="col-12 p-0 justify-content-end">
                        <div className="inner-top-right">
                          <ul className="d-flex list-unstyled justify-content-end">

                            <li>Upload count</li>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <CardBody className="p-0">
                          <div className="current-sale-container">
                          <SpinnerLoader loading={loading}>
                            <ApexCharts id="chart-currently" options={chartOptions} series={chartSeries} type='area' height={240} />
                          </SpinnerLoader>
                          </div>
                        </CardBody>
                      </Col>
                    </Row>
                  </div>
                  <Row className="border-top m-0">
                    <Col xl="4" md="6" sm="6" className="pl-0">
                      <div className="media p-0">
                        <div className="media-left"><i className="icofont icofont-ui-previous"></i></div>
                        <div className="media-body">
                          <h6>Last Crawl Date</h6>
                          <p>{data?.last_crawl_date}</p>
                        </div>
                      </div>
                    </Col>
                    <Col xl="4" md="6" sm="6">
                      <div className="media p-0">
                        <div className="media-left bg-secondary"><i className="icofont icofont-upload"></i></div>
                        <div className="media-body">
                          <h6>Last upload Date</h6>
                          <p>
                            {data?.last_upload_date}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col xl="4" md="12" className="pr-0">
                      <div className="media p-0">
                        <div className="media-left"><i className="icofont icofont-ui-next"></i></div>
                        <div className="media-body">
                          <h6>Next Crawl Date</h6>
                          <p>
                            {data?.next_crawl_date}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
