import React from 'react';
import DataTable from 'react-data-table-component';
import ReactTooltip from 'react-tooltip';
import { Info } from 'react-feather';

export default function RssQInfoTable({ data, loading }) {
  const columns = [
    {
      name: 'Site',
      selector: 'site_name',
      sortable: true,
      cell: (each) => <a href={`/internal/upload?filter%5Bsite%5D=${each?.site_name}`}
        target='_blank'>{each?.site_name}</a>
    },
    {
      name: 'Rss Q Size',
      selector: 'queue_size',
      sortable: true,
      cell: (each) => <>
        <a href={`/internal/queue_sizes/search?filter%5Bqueue_type%5D=rss_queue&filter%5Bsite%5D=${each?.site_name}`}
          target='_blank' style={{ fontSize: 16 }}>{each?.queue_size}</a> <Info size={16} className='mx-2' data-tip data-for='goto_view' />
        <ReactTooltip id='goto_view' clickable effect='solid'>
          Go to Queue Size View
        </ReactTooltip>
      </>
    },
  ];

  return (
    <div>
      <DataTable
        data={data}
        columns={columns}
        progressPending={loading}
        noHeader
        responsive
        pagination />
    </div>
  )
}
