import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Home } from "react-feather";
import { useLocation, Link } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import { Card, CardBody, Container, Row, UncontrolledTooltip, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
export default function EndToEndCrawlTracker() {
    const search = useLocation().search;
    const commit = new URLSearchParams(search).get("commit");
    const searchParams = {
        siteName: new URLSearchParams(search).get("filter[site_name]"),
        crawlCounts: new URLSearchParams(search).get("filter[crawl_counts]")
    }

    const [siteName, setSiteName] = useState({ label: 'All', value: 'all' });
    const [siteNameOptions, setSiteNameOptions] = useState([]);
    const [crawlCounts, setCrawlCounts] = useState({ label: 7, value: 7 });
    const [data, setData] = useState([]);
    const [searchResults,setSearchResults]=useState([])
    const [searchText,setSearchText]=useState('')
    const [loading, setLoading] = useState(false);
    const columns = [
        { name: 'Site Name', selector: 'site_name', sortable: true, grow: 2, center: true },
        { name: 'Rss Crawl Push Id', selector: 'rss_crawl_push_id', sortable: true, center: true },
        { name: 'Crawl Start Time', selector: 'crawl_start_time', sortable: true, center: true },
        { name: 'Rss Crawl Status', selector: 'rss_crawlstatus', sortable: true, center: true, cell: row => <div><span id={'count' + row.rss_crawl_push_id.toString()} >{row.rss_crawlstatus[0].toUpperCase() + row.rss_crawlstatus.slice(1)} <i className="fa fa-info-circle"></i></span><UncontrolledTooltip style={{ backgroundColor: '#1976d2', color: '#ffffff' }} target={'count' + row.rss_crawl_push_id.toString()} placement="bottom" >Rss Count: {row.rss_count}</UncontrolledTooltip> </div> },
        {
            name: 'Feed Crawl Status', selector: 'feedcrawl_status', sortable: true, center: true, cell:
                row =>
                    <span>
                        <button id={'crawl' + row.rss_crawl_push_id.toString()} className={`btn p-2 h-50 ${row.feedcrawl_status ? 'btn-success' : 'btn-warning'} btn-sm`}>
                            {row.feedcrawl_status ? 'Finished' : 'Inprogress'} <i className="fa fa-info-circle"></i>
                        </button>
                        <UncontrolledTooltip style={{ backgroundColor: '#1976d2', color: '#ffffff' }} target={'crawl' + row.rss_crawl_push_id.toString()} placement="bottom" >Overall Feedcrawl Count: {row.overall_feedcrawl_count}</UncontrolledTooltip>
                    </span>
        },
        { name: 'Feed Crawl End Time', selector: 'feedcrawl_end_time', sortable: true, center: true, cell: row => <div>{row?.feedcrawl_end_time || 'No data is available'}</div> },
        { name: 'Total Feedcrawl Duration', selector: 'total_feedcrawl_duration', sortable: true, center: true },
        { name: 'EDNU Status', selector: 'ednu_status', sortable: true, cell: row => <button className={`btn p-2 h-50 ${row.ednu_status ? 'btn-success' : 'btn-warning'} btn-sm`}>{row.ednu_status ? 'Finished' : 'Inprogress'}</button>, center: true },
        { name: 'Overall Crawl End Time', selector: 'overall_crawl_endtime', sortable: true, center: true, cell: row => <div>{row?.overall_crawl_endtime || 'No data is available'}</div> },
        { name: 'Total Crawl Duration', selector: 'total_crawl_duration', sortable: true, center: true },
        { name: 'RSS jop type', selector: 'rss_job_type', sortable: true, center: true },
    ];
    const crawlCountsOptions = [
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
        { label: 6, value: 6 },
        { label: 7, value: 7 },
        { label: 8, value: 8 },
        { label: 9, value: 9 },
        { label: 10, value: 10 },
        { label: 11, value: 11 },
        { label: 12, value: 12 },
        { label: 13, value: 13 },
        { label: 14, value: 14 },
        { label: 15, value: 15 },

    ];

    useEffect(() => {
        setSearchParamsData();
        fetchSiteNames();
    }, []);

    function setSearchParamsData() {
        if (searchParams.siteName && searchParams.siteName != siteName.value) {
            setSiteName({ label: searchParams.siteName, value: searchParams.siteName })
        }
        if (searchParams.crawlCounts && searchParams.crawlCounts != crawlCounts.value) {
            setCrawlCounts({ label: searchParams.crawlCounts, value: searchParams.crawlCounts })
        }
        if (commit == 'Get Data' && searchParams.siteName && searchParams.crawlCounts) {
            fetchData(searchParams.siteName, searchParams.crawlCounts)
        }
        else {
            fetchData();
        }
    }

    function fetchSiteNames() {
        axios.get('/api/v1/internal/dropdown_list_helper')
            .then(res => {
                let site_names = res.data.site_names, site_names_options = []
                for (var key in site_names) {
                    let group = {}, children = []
                    group['label'] = <div className="fw-bold col text-black">{key}</div>
                    site_names[key].map(item => {
                        children.push({ label: item, value: item })
                    })
                    group['options'] = children;
                    site_names_options.push(group)
                }
                site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                setSiteNameOptions(site_names_options)
            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong')
            })
    }

    function fetchData(SiteName = siteName.value, CrawlCounts = crawlCounts.value) {
        setLoading(true)
        axios.get(`/api/v1/internal/end_to_end_crawl_tracker?filter[site_name]=${SiteName}&filter[crawl_counts]=${CrawlCounts}`)
            .then(res => {
                setData(res.data.rss_crawl);
                setSearchResults(res.data.rss_crawl);
                setLoading(false);

            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong');
                setLoading(false);

            });
    }

    const handleGetData = () => {
        window.location.href = `/internal/end_to_end_crawl_tracker?filter[site_name]=${siteName.value}&filter[crawl_counts]=${crawlCounts.value}&commit=${'Get Data'}`
    }

    const promiseOptions = (inputValue) => {
        if (inputValue.length < 3) {
            return null
        } else {
            return new Promise((resolve) => {
                axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
                    if (res.status == '200') {
                        let site_names = res.data.site_names, site_names_options = []
                        for (var key in site_names) {
                            let group = {}, children = []
                            group['label'] = <div className="fw-bold col text-black">{key}</div>
                            site_names[key].map(item => {
                                children.push({ label: item, value: item })
                            })
                            group['options'] = children;
                            site_names_options.push(group)
                        }
                        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                        setSiteNameOptions(site_names_options)
                        resolve(site_names_options)
                    }
                }).catch(err => {
                    console.log(`err`, err)
                })
            })
        }
    }
    const handleInputChange = event => {
        if (event.target.value == '') {
            setSearchResults(data)
        }
        else {
            setSearchResults(data.filter(item => item.site_name.includes(event.target.value) || item.rss_crawl_push_id.toString().includes(event.target.value) || item.rss_job_type.includes(event.target.value.toLowerCase()) || item.rss_crawlstatus.includes(event.target.value.toLowerCase()) || item.crawl_start_time.toLowerCase().includes(event.target.value.toLowerCase())))
        }
        setSearchText(event.target.value);
    }

    return <Container fluid>
        <Container fluid>
            <div className="page-title">
                <Row className=''>
                    <Col xs="6">
                        <h3>Internal Dashboard</h3>
                    </Col>
                    <Col xs="6">
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <Link to={`/internal`}><Home /></Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem className={commit == 'Get Data' ? '' : 'fw-bold'} >
                                {commit == 'Get Data' ? <a href={'/internal/end_to_end_crawl_tracker'}>End To End Crawl Tracker</a> : 'End To End Crawl Tracker'}
                            </BreadcrumbItem>
                            {commit == 'Get Data' && <BreadcrumbItem>
                                <b>Search</b>
                            </BreadcrumbItem>}
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
        </Container>
        <Card>
            <CardBody>
                <Row className="align-items-end">
                    <div className="col-sm-7">
                        <label className="fw-bold">Site Name</label>
                        <AsyncSelect cacheOptions defaultOptions={siteNameOptions} name='site_name'
                            value={siteName} loadOptions={promiseOptions} onChange={res => setSiteName(res)}
                            placeholder="Select a site name" />
                            {/* <p><i>Please type atleast 3 characters while searching the sitename!</i></p> */}
                    </div>
                    <div className="col-sm-3">
                        <label className="fw-bold">Crawl Counts</label>
                        <Select
                            onChange={option => setCrawlCounts(option)}
                            value={crawlCounts}
                            options={crawlCountsOptions} />
                    </div>
                    <div className="col-sm-2">
                        <button className="btn btn-success w-100 p-2" onClick={handleGetData}>
                            Get Data
                        </button>
                    </div>
                    <div className="form-text">Please type atleast 3 characters while searching the sitename</div>
                </Row>
            </CardBody>
        </Card>
        <Card>
            <CardBody>
                <div>
                    <h4>Note</h4>
                    <p>1)<b> Finished</b> signifies either that stage is done or failed in between.</p>
                    <p>2) DateTime is GMT and 24 hours format.</p>
                    <p>3) <i className="fa fa-info-circle"></i> indicates the hover is there. It has some informations or links.</p>
                </div>
                <Row className="my-3">
                    <Col>
                        <div className="float-end">
                            <Row className="align-items-center">
                                <Col sm="3">Search</Col>
                                <Col>
                                    <input type="text" className="form-control" value={searchText} onChange={handleInputChange} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                {(data?.length == 0 && !loading) ? <div className="fw-bold text-center">Probably crawl end functionality is not enabled for this site</div> :
                    <DataTable
                        columns={columns}
                        data={searchResults}
                        progressPending={loading}

                        paginationPerPage={15}
                        paginationServer
                        highlightOnHover
                        
                        noHeader
                        pagination
                    />
                }
            </CardBody>
        </Card>
    </Container >

}