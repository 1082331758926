/**
 * These are the cards are rendered on the right side of the table
 * These show information about the current amount, paid amount as well as
 * the added cards for making the payment
 * 
 * The component is rendered in Billig.index component
 */

import React from 'react';
import { useContext } from 'react';
import { OrgnaziationContext } from '../../../../Home';
import { Card, CardHeader, CardBody } from 'reactstrap';

export default function InfoCards({ type, data, title }) {

	const { orgName } = useContext(OrgnaziationContext);

	if (type === 'payment-cards-info') {
		return (
			<>
				<Card>
					<CardHeader className='m-0 p-4'>
					<h6>{title}</h6>
					</CardHeader>
					<CardBody className='m-0 p-4'>
						{data?.length > 0 ? data?.map(eachCard => (
							<h6 key={eachCard.id}>
								**** **** **** {eachCard.last4}
							</h6>
						))
							: <p> No cards added </p>}
						<a href={`/organizations/${orgName}/cards`} className='btn btn-bordered mt-2'>
							Manage my cards
						</a>
					</CardBody>
				</Card>
			</>
		)
	}
	else if (data?.length > 0) {
		return (
			<>
				<Card>
					<CardHeader className='p-4'>
						<h6>{title}</h6>
					</CardHeader>
					<CardBody className='p-4'>
						<p className='m-0 mb-1'>{type==="unbilled"?"Amount:":"Total:"}</p>
						<h3 className='m-0 p-1'>
							{data}
						</h3>
					</CardBody>
				</Card>
			</>
		)
	}
	else{
		return (
			<>

			</>
		)
	}
}
