import React from 'react';
import { Home } from 'react-feather';
import { Row, Col } from 'reactstrap';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useLocation, Link } from 'react-router-dom';

import { NewJpInternalBasePoint } from '../../../../../../Common/BaseEsPoints/InternalEsBasePoint';

export default function BreadCrumb() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  
  return (
    <div className='page-title'>
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href={`${NewJpInternalBasePoint}/jobspikr_graphs/dedup_tracker`}>Dedup Tracker</a> : 'Dedup Tracker'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
            </BreadcrumbItem>}
          </Breadcrumb>
        </Col>
      </Row>
    </div>
  )
}
