import React from 'react'
import { useState, useEffect } from "react";

import { Row, Col, Card, CardBody, Container } from "reactstrap";
import moment from "moment";
import axios from "axios";

import SiteSearchCard from './Cards/SiteSearchCard.component';
import BreadCrumbComponent from './Breadcrumb/BreadcrumbComponent';
import DiskfetcherProcessTable from './DataTable/DiskfetcherProcessTable.component';
import SpinnerLoader from '../../../V2/Common/LoadingAnimation/SpinnerLoader.component';

export default function Index() {

  const [loading, setLoading] = useState(false);
  const [diskfetcherProcessesData, setDiskfetcherProcessesData] = useState([]);
  const [searchString, setSearchString] = useState('') // value searched by user in the search input
  const [filteredData, setFilteredData] = useState('') // the result data of searching the value in the data
  const [displayTable, setDisplayTable] = useState(false)

  // Helper function to fetch data to be displayed in data table
  function fetchData(site_name, extract_status, crawl_status, from_date, to_date) {
    setLoading(true)
    setDisplayTable(true)
    axios.get(`/api/v2/internal/diskfetcher_processes?filter[site_name]=${site_name}&filter[extractstatus]=${extract_status}&filter[crawlstatus]=${crawl_status}&filter[from_date]=${moment(from_date).format('YYYY/MM/DD')}&filter[to_date]=${moment(to_date).format('YYYY/MM/DD')}`)
      .then(res => {
        setDiskfetcherProcessesData(res?.data?.diskfetcher_processes_data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  };

  // Helper method to search within the table
  function handleSearch(event) {
    let { value } = event.target;
    if (value == '') {
      setSearchResults(diskfetcherProcessesData);
    }
    else {
      setSearchResults(searchFromData(value));
    }
    setSearchText(value);
  };

    // Helper function to filter data based on search
    useEffect(() => {
      let filteredData = diskfetcherProcessesData.filter((data) => {
        return data.hostname.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
          data.segment_name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
          data.segment_name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
          data.crawlstatus.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
          data.feedcrawl_start_status.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
          data.feedcrawl_end_status.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
          data.extractstatus.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
      })
      setFilteredData(filteredData)
    }, [diskfetcherProcessesData, searchString])

  return (
    <div>
      <Container fluid>
        <BreadCrumbComponent />

        <SiteSearchCard
          fetchData={fetchData}
        />
        {displayTable?
        <SpinnerLoader loading={loading}>
        {
          (window.location.href.includes('[site]') && diskfetcherProcessesData.length > 0) ?
            <Card>
              <CardBody>
                <Row className="align-items-end mt-2 mb-3">
                  <Col>
                    <div className="float-end">
                      <Row className="align-items-center">
                        <Col sm="3">Search</Col>
                        <Col>
                          <input type="text" className="form-control" onChange={(event) => setSearchString(event.target.value)} />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <DiskfetcherProcessTable
                  data={filteredData}
                  progressPending={loading}
                />
              </CardBody>
            </Card>
            : 
            <Card>
              <CardBody>
                <center><h5 className='fw-bold'>There are No Records To Display</h5></center>
              </CardBody>
            </Card>
        }
        </SpinnerLoader>
        :""}
      </Container>
    </div>
  )
}
