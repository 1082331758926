import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";
import axios from "axios";
import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";

export default function Index() {
  const [ednuProcessesData, setEdnuProcessesData] = useState([]);
  const [loading, setLoading] = useState(false);

  const tableColumns = [
    { 
      name: 'sites', 
      selector: 'site_name', 
      cell: row => (
        <a href={"/internal/ednu_processes/" + row.site_name} target="_blank" rel="noopener noreferrer">
          {row.site_name}
        </a>
        )
    },
  ]

  useEffect(() => {
    setLoading(true)
    axios.get(`/api/v1/internal/ednu_processes`)
      .then(res => {
        setEdnuProcessesData(res.data.ednu_processes_data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }, []);

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>EDNU Processes</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
    </div>

    <Card>
      <CardBody>
        <div className="mb-1">
          <h6>NOTE:</h6>
          <p>1) <i class="fa fa-info-circle" aria-hidden="true"></i> indicates the hover is there. It has some informations or links.</p>
        </div>
      </CardBody>
    </Card>
    <Card>
      <CardBody>
        {ednuProcessesData.length > 0 ?
        <>
          <Row className="align-items-end mt-2">
            <DataTable
              columns={tableColumns}
              data={ednuProcessesData}
              noHeader
              pagination
              progressPending={loading}
            />
          </Row>
        </>
      : "No data available"}
    </CardBody>
  </Card>
</Container>
}

