import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";
import moment from "moment";
import AsyncSelect from 'react-select/async';
import axios from "axios";

import { KIBANA_PLM_URL } from "../../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function OverallSuccessfulSegments() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const tabNameFromSearchUrl = new URLSearchParams(search).get("filter[tab_name]");
  const tabNameFromUrl = window.location.hash.substr(1);
  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[site_name]"),
    pipelineStage: new URLSearchParams(search).get("filter[pipeline_stage]"),
    hostName: new URLSearchParams(search).get("filter[host_name]"),
    fromTime: new URLSearchParams(search).get("filter[time]"),
    tabName: tabNameFromSearchUrl ? tabNameFromSearchUrl : tabNameFromUrl
  }
  const [siteName, setSiteName] = useState({ label: 'All', value: '*' });
  const [siteNameOptions, setSiteNameOptions] = useState([]);
  const [hostName, setHostName] = useState({ label: 'All', value: '*' });
  const [hostNameOptions, setHostNameOptions] = useState([]);
  const [pipelineStage, setPipelineStage] = useState({ label: 'All', value: '*' });
  const [fromTime, setFromTime] = useState({ label: 'Last 4 hours', value: 'now-4h' });
  const [activeTab, setActiveTab] = useState(tabNameFromUrl ? tabNameFromUrl : 'overall_successful_segments');

  const pipelineStageOptions = [
    { label: 'All', value: '*' },
    { label: 'Feedcrawl', value: 'diskfetcher' },
    { label: 'Extraction', value: 'extraction' },
    { label: 'Dedup', value: 'dedup' },
    { label: 'Normalization', value: 'normalization' },
    { label: 'Upload', value: 'upload' },
  ]

  const timeOptions = [
    { label: 'Today', value: 'now%2Fd' },
    { label: 'Last 30 minutes', value: 'now-30m' },
    { label: 'Last 1 hour', value: 'now-1h' },
    { label: 'Last 2 hours', value: 'now-2h' },
    { label: 'Last 4 hours', value: 'now-4h' },
    { label: "Last 10 hours", value: "now-10h" },
    { label: 'Last 12 hours', value: 'now-12h' },
    { label: 'Last 24 hours', value: 'now-24h' },
    { label: 'Last 2 days', value: 'now-2d' },
    { label: 'Last 3 days', value: 'now-3d' },
    { label: 'Last 4 days', value: 'now-4d' },
    { label: 'Last 7 days', value: 'now-7d' },
    { label: 'Last 15 days', value: 'now-15d' },
    { label: 'Last 30 days', value: 'now-30d' },
    { label: 'Last 60 days', value: 'now-60d' },
    { label: 'Last 1 year', value: 'now-1y' }
  ];

  const [UrlParams, setUrlParams] = useState({
    siteName: "*",
    pipelineStage: '*',
    hostName: '*',
    fromTime: 'now-4h',
    tabName: "overall_successful_segments"
  })

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let site_names = res.data.site_names, site_names_options = []
        for (var key in site_names) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
          site_names[key].map(item => {
            children.push({ label: item, value: item })
          })
          group['options'] = children;
          site_names_options.push(group)
        }
        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: '*' }] })
        setSiteNameOptions(site_names_options);
        resolve(site_names_options)
        
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      });
  }, []);

  function titleize(value) {
    return value.substr(0, 1).toUpperCase() + value.substr(1).toLowerCase();
  }

  function pipelineStageLabel() {
    var labelValue;
    if (searchParams.pipelineStage == '*') {
      labelValue = "All";
    }
    else if (searchParams.pipelineStage == 'diskfetcher') {
      labelValue = "Feedcrawl";
    }
    else {
      labelValue = titleize(searchParams.pipelineStage);
    }
    return labelValue;
  }

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.siteName && searchParams.siteName != siteName.value) {
      if ((searchParams.siteName == "*") || (searchParams.siteName == "all")) {
        setSiteName({ label: 'All', value: "*" });
      }
      else {
        setSiteName({ label: searchParams.siteName, value: searchParams.siteName });
      }
    }
    if (searchParams.hostName && searchParams.hostName != hostName.value) {
      if ((searchParams.hostName == "*") || (searchParams.hostName == "all")) {
        setHostName({ label: 'All', value: "*" });
      }
      else {
        setHostName({ label: searchParams.hostName, value: searchParams.hostName });
      }
    }
    if (searchParams.pipelineStage && searchParams.pipelineStage != pipelineStage.value) {
      setPipelineStage({
        label: pipelineStageLabel(),
        value: searchParams.pipelineStage
      });
    }
    if (searchParams.fromTime && searchParams.fromTime != fromTime.value) {
      setFromTime(timeOptions.filter(item => item.value == searchParams.fromTime)[0]);
    }
    if (searchParams.tabName && searchParams.tabName != activeTab) {
      setActiveTab(searchParams.tabName);
    }
    if (commit == 'Get Data') {
      setUrlParams(searchParams)
    }
  }

  function overallSuccessfulSegmentsKibanaUrl() {
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
    if (UrlParams.pipelineStage == "*") {
      return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=line&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(pause:!t,value:0),time:(from:'${UrlParams.fromTime}',to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'((ednu_status_tracker:true+and+(file_name:upload_utils.rb+or+file_name:dedup.rb+or+file_name:extraction.rb+or+file_name:normalization.rb))+or+(diskfetcher_crawl_time_tracker:true+and+file_name:feedcrawl.rb)+and+current_status:%22done%22)+and+host.name.raw:${UrlParams.hostName == 'all' ? '*' : UrlParams.hostName}+and+site_name:${UrlParams.siteName == 'all' ? '*' : UrlParams.siteName}'),uiState:(vis:(colors:(dedup:%23F29191,extraction:%2399440A,upload:%23508642))),vis:(aggs:!((id:'1',params:(customLabel:'Segment+Count'),schema:metric,type:count),(id:'2',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:auto,min_doc_count:1),schema:segment,type:date_histogram),(id:'3',params:(filters:!((input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:feedcrawl.rb'))),label:feedcrawl),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:extraction.rb'))),label:extraction),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:dedup.rb'))),label:dedup),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:normalization.rb'))),label:normalization),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:upload_utils.rb'))),label:upload))),schema:group,type:filters)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:'Overall+Successful+Segments',type:line))`
    }
    else if (UrlParams.pipelineStage == "diskfetcher") {
      return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=line&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(pause:!t,value:0),time:(from:'${UrlParams.fromTime}',to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'((ednu_status_tracker:true+and+(file_name:upload_utils.rb+or+file_name:dedup.rb+or+file_name:extraction.rb+or+file_name:normalization.rb))+or+(diskfetcher_crawl_time_tracker:true+and+file_name:feedcrawl.rb)+and+current_status:%22done%22)+and+host.name.raw:${UrlParams.hostName == 'all' ? '*' : UrlParams.hostName}+and+site_name:${UrlParams.siteName  == 'all' ? '*' : UrlParams.siteName}+and+pipeline_stage.raw:%22diskfetcher%22'),uiState:(vis:(colors:(dedup:%23F29191,extraction:%2399440A,upload:%23508642))),vis:(aggs:!((id:'1',params:(customLabel:'Segment+Count'),schema:metric,type:count),(id:'2',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:auto,min_doc_count:1),schema:segment,type:date_histogram),(id:'3',params:(filters:!((input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:feedcrawl.rb'))),label:feedcrawl),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:extraction.rb'))),label:extraction),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:dedup.rb'))),label:dedup),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:normalization.rb'))),label:normalization),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:upload_utils.rb'))),label:upload))),schema:group,type:filters)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:'Overall+Successful+Segments',type:line))`
    }
    else {
      return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=line&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(pause:!t,value:0),time:(from:'${UrlParams.fromTime}',to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'((ednu_status_tracker:true+and+(file_name:upload_utils.rb+or+file_name:dedup.rb+or+file_name:extraction.rb+or+file_name:normalization.rb))+or+(diskfetcher_crawl_time_tracker:true+and+file_name:feedcrawl.rb)+and+current_status:%22done%22)+and+host.name.raw:${UrlParams.hostName == 'all' ? '*' : UrlParams.hostName}+and+site_name:${UrlParams.siteName  == 'all' ? '*' : UrlParams.siteName}+and+ednu_stage.raw:%22${UrlParams.pipelineStage}%22'),uiState:(vis:(colors:(dedup:%23F29191,extraction:%2399440A,upload:%23508642))),vis:(aggs:!((id:'1',params:(customLabel:'Segment+Count'),schema:metric,type:count),(id:'2',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:auto,min_doc_count:1),schema:segment,type:date_histogram),(id:'3',params:(filters:!((input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:feedcrawl.rb'))),label:feedcrawl),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:extraction.rb'))),label:extraction),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:dedup.rb'))),label:dedup),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:normalization.rb'))),label:normalization),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:upload_utils.rb'))),label:upload))),schema:group,type:filters)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:'Overall+Successful+Segments',type:line))`
    }
  }

  function siteWiseSuccessfulSegmentsKibanaUrl() {
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
    if (UrlParams.pipelineStage == "*") {
      return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${UrlParams.fromTime}',mode:quick,to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'((ednu_status_tracker:true+and+(file_name:upload_utils.rb+or+file_name:dedup.rb+or+file_name:extraction.rb+or+file_name:normalization.rb))+or+(diskfetcher_crawl_time_tracker:true+and+file_name:feedcrawl.rb)+and+current_status:%22done%22)+and+host.name.raw:${UrlParams.hostName  == 'all' ? '*' : UrlParams.hostName}+and+site_name:${UrlParams.siteName  == 'all' ? '*' : UrlParams.siteName}'),uiState:(),vis:(aggs:!((id:'1',params:(customLabel:'Segment+Count'),schema:metric,type:count),(id:'2',params:(field:site_name,order:desc,orderBy:'1',size:20),schema:segment,type:terms),(id:'3',params:(filters:!((input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:extraction.rb'))),label:extraction),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:dedup.rb'))),label:dedup),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:normalization.rb'))),label:normalization),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:feedcrawl.rb'))),label:feedcrawl),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:upload_utils.rb'))),label:upload))),schema:group,type:filters)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Site+Wise+Successful+Segments',type:histogram))`
    }
    else if (UrlParams.pipelineStage == "diskfetcher") {
      return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${UrlParams.fromTime}',mode:quick,to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'((ednu_status_tracker:true+and+(file_name:upload_utils.rb+or+file_name:dedup.rb+or+file_name:extraction.rb+or+file_name:normalization.rb))+or+(diskfetcher_crawl_time_tracker:true+and+file_name:feedcrawl.rb)+and+current_status:%22done%22)+and+host.name.raw:${UrlParams.hostName  == 'all' ? '*' : UrlParams.hostName}+and+site_name:${UrlParams.siteName  == 'all' ? '*' : UrlParams.siteName}+and+pipeline_stage.raw:%22diskfetcher%22'),uiState:(),vis:(aggs:!((id:'1',params:(customLabel:'Segment+Count'),schema:metric,type:count),(id:'2',params:(field:site_name,order:desc,orderBy:'1',size:20),schema:segment,type:terms),(id:'3',params:(filters:!((input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:extraction.rb'))),label:extraction),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:dedup.rb'))),label:dedup),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:normalization.rb'))),label:normalization),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:feedcrawl.rb'))),label:feedcrawl),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:upload_utils.rb'))),label:upload))),schema:group,type:filters)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Site+Wise+Successful+Segments',type:histogram))`
    }
    else {
      return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${UrlParams.fromTime}',mode:quick,to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'((ednu_status_tracker:true+and+(file_name:upload_utils.rb+or+file_name:dedup.rb+or+file_name:extraction.rb+or+file_name:normalization.rb))+or+(diskfetcher_crawl_time_tracker:true+and+file_name:feedcrawl.rb)+and+current_status:%22done%22)+and+host.name.raw:${UrlParams.hostName  == 'all' ? '*' : UrlParams.hostName}+and+site_name:${UrlParams.siteName  == 'all' ? '*' : UrlParams.siteName}+and+ednu_stage.raw:%22${UrlParams.pipelineStage}%22'),uiState:(),vis:(aggs:!((id:'1',params:(customLabel:'Segment+Count'),schema:metric,type:count),(id:'2',params:(field:site_name,order:desc,orderBy:'1',size:20),schema:segment,type:terms),(id:'3',params:(filters:!((input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:extraction.rb'))),label:extraction),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:dedup.rb'))),label:dedup),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:normalization.rb'))),label:normalization),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:feedcrawl.rb'))),label:feedcrawl),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:upload_utils.rb'))),label:upload))),schema:group,type:filters)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Site+Wise+Successful+Segments',type:histogram))`
    }
  }

  function hostWiseSuccessfulSegmentsKibanaUrl() {
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";
    if (UrlParams.pipelineStage == "*") {
      return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${UrlParams.fromTime}',mode:quick,to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'((ednu_status_tracker:true+and+(file_name:upload_utils.rb+or+file_name:dedup.rb+or+file_name:extraction.rb+or+file_name:normalization.rb))+or+(diskfetcher_crawl_time_tracker:true+and+file_name:feedcrawl.rb)+and+current_status:%22done%22)+and+host.name.raw:${UrlParams.hostName  == 'all' ? '*' : UrlParams.hostName}+and+site_name:${UrlParams.siteName  == 'all' ? '*' : UrlParams.siteName}'),uiState:(),vis:(aggs:!((id:'1',params:(customLabel:'Segment+Count'),schema:metric,type:count),(id:'2',params:(field:host.name.raw,order:desc,orderBy:'1',size:20),schema:segment,type:terms),(id:'3',params:(filters:!((input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:extraction.rb'))),label:extraction),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:dedup.rb'))),label:dedup),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:normalization.rb'))),label:normalization),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:feedcrawl.rb'))),label:feedcrawl),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:upload_utils.rb'))),label:upload))),schema:group,type:filters)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Host+Wise+Successful+Segments',type:histogram))`
    }
    else if (UrlParams.pipelineStage == "diskfetcher") {
      return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${UrlParams.fromTime}',mode:quick,to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'((ednu_status_tracker:true+and+(file_name:upload_utils.rb+or+file_name:dedup.rb+or+file_name:extraction.rb+or+file_name:normalization.rb))+or+(diskfetcher_crawl_time_tracker:true+and+file_name:feedcrawl.rb)+and+current_status:%22done%22)+and+host.name.raw:${UrlParams.hostName  == 'all' ? '*' : UrlParams.hostName}+and+site_name:${UrlParams.siteName  == 'all' ? '*' : UrlParams.siteName}+and+pipeline_stage.raw:%22diskfetcher%22'),uiState:(),vis:(aggs:!((id:'1',params:(customLabel:'Segment+Count'),schema:metric,type:count),(id:'2',params:(field:host.name.raw,order:desc,orderBy:'1',size:20),schema:segment,type:terms),(id:'3',params:(filters:!((input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:extraction.rb'))),label:extraction),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:dedup.rb'))),label:dedup),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:normalization.rb'))),label:normalization),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:feedcrawl.rb'))),label:feedcrawl),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:upload_utils.rb'))),label:upload))),schema:group,type:filters)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Host+Wise+Successful+Segments',type:histogram))`
    }
    else {
      return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${UrlParams.fromTime}',mode:quick,to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'((ednu_status_tracker:true+and+(file_name:upload_utils.rb+or+file_name:dedup.rb+or+file_name:extraction.rb+or+file_name:normalization.rb))+or+(diskfetcher_crawl_time_tracker:true+and+file_name:feedcrawl.rb)+and+current_status:%22done%22)+and+host.name.raw:${UrlParams.hostName  == 'all' ? '*' : UrlParams.hostName}+and+site_name:${UrlParams.siteName  == 'all' ? '*' : UrlParams.siteName}+and+ednu_stage.raw:%22${UrlParams.pipelineStage}%22'),uiState:(),vis:(aggs:!((id:'1',params:(customLabel:'Segment+Count'),schema:metric,type:count),(id:'2',params:(field:host.name.raw,order:desc,orderBy:'1',size:20),schema:segment,type:terms),(id:'3',params:(filters:!((input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:extraction.rb'))),label:extraction),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:dedup.rb'))),label:dedup),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:normalization.rb'))),label:normalization),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:feedcrawl.rb'))),label:feedcrawl),(input:(query:(query_string:(analyze_wildcard:!t,query:'file_name:upload_utils.rb'))),label:upload))),schema:group,type:filters)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),title:'Host+Wise+Successful+Segments',type:histogram))`
    }
  }

  const handleGetData = () => {
    window.location.href = `/internal/overall_system_reports/overall_successful_segments?filter[site_name]=${siteName.value}&filter[pipeline_stage]=${pipelineStage.value}&filter[host_name]=${hostName.value}&filter[time]=${fromTime.value}&filter[tab_name]=${activeTab}&commit=${'Get Data'}`
  }

  const promiseOptions = (inputValue) => {
    if (inputValue.length < 3) {
      return null
    } else {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200') {
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
              site_names[key].map(item => {
                children.push({ label: item, value: item })
              })
              group['options'] = children;
              site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setSiteNameOptions(site_names_options)
            resolve(site_names_options)
          }
        }).catch(err => {
          console.log(`err`, err)
        })
      })
    }
  }

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/overall_system_reports/overall_successful_segments">Overall Successful Segments</a> : 'Overall Successful Segments'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
            </BreadcrumbItem>}
          </Breadcrumb>
        </Col>
      </Row>
    </div>

    <Card>
      <CardBody>
        <Row>
          <Col sm="8">
            <label className="fw-bold">Site Name</label>
            <AsyncSelect cacheOptions defaultOptions={siteNameOptions} name='site_name'
              value={siteName} loadOptions={promiseOptions} onChange={res => setSiteName(res)}
              placeholder="Select a site name" />
              <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
          </Col>
          <Col sm="4">
            <label className="fw-bold">Time</label>
            <Select
              value={fromTime}
              options={timeOptions}
              onChange={option => setFromTime(option)} />
          </Col>
        </Row>
        <Row className="align-items-end mt-2">
          <Col sm="4">
            <label className="fw-bold">Pipeline Stage</label>
            <Select
              value={pipelineStage}
              options={pipelineStageOptions}
              onChange={option => setPipelineStage(option)} />
          </Col>
          <Col sm="4">
            <label className="fw-bold">Host Name</label>
            <Select
              value={hostName}
              options={hostNameOptions}
              onChange={option => setHostName(option)} />
          </Col>
          <Col sm="4">
            <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
          </Col>
        </Row>
      </CardBody>
    </Card>
    <Card>
      <CardBody>
        <div className="mb-1">
          <b>NOTE:</b>
          If the graph is not shown after your form submission then you should do either maximize its size(the option is available on the right side of graph) or minimize the sidebar.
        </div>
        <Nav tabs>
          <NavItem>
            <NavLink className={activeTab == 'overall_successful_segments' ? 'active' : ''} onClick={() => setActiveTab('overall_successful_segments')}>
              Overall Successful Segments
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab == 'site_wise_successful_segments' ? 'active' : ''} onClick={() => setActiveTab('site_wise_successful_segments')}>
              Site Wise Successful Segments
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab == 'host_wise_successful_segments' ? 'active' : ''} onClick={() => setActiveTab('host_wise_successful_segments')}>
              Host Wise Successful Segments
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={'overall_successful_segments'}>
            <div className="panel-body">
              <center>
                <span className="fw-bold">Overall Successful Segments</span><br />
                <a target="_blank" className="link-primary" href={overallSuccessfulSegmentsKibanaUrl()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={overallSuccessfulSegmentsKibanaUrl() + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
            </div>
          </TabPane>
          <TabPane tabId={'site_wise_successful_segments'}>
            <div className="panel-body">
              <center>
                <span className="fw-bold">Site Wise Successful Segments</span><br />
                <a target="_blank" className="link-primary" href={siteWiseSuccessfulSegmentsKibanaUrl()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={siteWiseSuccessfulSegmentsKibanaUrl() + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
            </div>
          </TabPane>
          <TabPane tabId={'host_wise_successful_segments'}>
            <div className="panel-body">
              <center>
                <span className="fw-bold">Host Wise Successful Segments</span><br />
                <a target="_blank" className="link-primary" href={hostWiseSuccessfulSegmentsKibanaUrl()}>Click here to see below graph in kibana</a>
              </center>
              <iframe src={hostWiseSuccessfulSegmentsKibanaUrl() + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
            </div>
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  </Container>
}

