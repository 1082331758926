import React from 'react'
import DataTable from 'react-data-table-component'
import { UncontrolledTooltip } from 'reactstrap'

const columns = [
  {
    name: 'Site Name',
    selector: 'site_name',
    sortable: true,
    grow: 2,
    center: true
  },
  {
    name: 'Rss Crawl Push Id',
    selector: 'rss_crawl_push_id',
    sortable: true,
    center: true
  },
  {
    name: 'Crawl Start Time',
    selector: 'crawl_start_time',
    sortable: true,
    center: true
  },
  {
    name: 'Rss Crawl Start Status',
    selector: 'rss_crawl_start_status',
    sortable: true, center: true,
    cell: row =>
      <div>
        <span id={'count' + row.rss_crawl_push_id.toString()}>
          {row.rss_crawl_start_status[0].toUpperCase() + row.rss_crawl_start_status.slice(1)}
          <i className="fa fa-info-circle"></i>
        </span>
        <UncontrolledTooltip
          style={{ backgroundColor: '#1976d2', color: '#ffffff' }}
          target={'count' + row.rss_crawl_push_id.toString()}
          placement="bottom" >
          Rss Count: {row.rss_count}
        </UncontrolledTooltip>
      </div>
  },
  {
    name: 'Rss Crawl End Status',
    selector: 'rss_crawl_end_status',
    sortable: true, center: true,
    cell: row =>
      <div>
        <span id={'count' + row.rss_crawl_push_id.toString()}>
          {row.rss_crawl_end_status[0].toUpperCase() + row.rss_crawl_end_status.slice(1)}
          <i className="fa fa-info-circle"></i>
        </span>
        <UncontrolledTooltip
          style={{ backgroundColor: '#1976d2', color: '#ffffff' }}
          target={'count' + row.rss_crawl_push_id.toString()}
          placement="bottom" >
          Rss Count: {row.rss_count}
        </UncontrolledTooltip>
      </div>
  },
  {
    name: 'Feed Crawl Status',
    selector: 'feedcrawl_status',
    sortable: true,
    center: true,
    cell: row =>
      <span>
        <button id={'crawl' + row.rss_crawl_push_id.toString()} className={`btn p-2 h-50 ${row.feedcrawl_status ? 'btn-success' : 'btn-warning'} btn-sm`}>
          {row.feedcrawl_status ? 'Finished' : 'Inprogress'} <i className="fa fa-info-circle"></i>
        </button>
        <UncontrolledTooltip
          style={{ backgroundColor: '#1976d2', color: '#ffffff' }}
          target={'crawl' + row.rss_crawl_push_id.toString()}
          placement="bottom" >
          Overall Feedcrawl Count: {row.overall_feedcrawl_count}
        </UncontrolledTooltip>
      </span>
  },
  {
    name: 'Feed Crawl End Time',
    selector: 'feedcrawl_end_time',
    sortable: true,
    center: true,
    cell: row =>
      <div>{row?.feedcrawl_end_time || 'No data is available'}</div>
  },
  {
    name: 'Total Feedcrawl Duration',
    selector: 'total_feedcrawl_duration',
    sortable: true,
    center: true
  },
  {
    name: 'EDNU Status',
    selector: 'ednu_status',
    sortable: true,
    cell: row =>
      <button className={`btn p-2 h-50 ${row.ednu_status ? 'btn-success' : 'btn-warning'} btn-sm`}>
        {row.ednu_status ? 'Finished' : 'Inprogress'}
      </button>,
    center: true
  },
  {
    name: 'Overall Crawl End Time',
    selector: 'overall_crawl_endtime',
    sortable: true,
    center: true,
    cell: row =>
      <div>{row?.overall_crawl_endtime || 'No data is available'}</div>
  },
  {
    name: 'Total Crawl Duration',
    selector: 'total_crawl_duration',
    sortable: true,
    center: true
  },
  {
    name: 'RSS Job Type',
    selector: 'rss_job_type',
    sortable: true,
    center: true
  },
]

export default function EndToEndCrawlTrackerDataTable(props) {
  const { data, tableData } = props
  if (data?.length > 0) {
    return (
      <React.Fragment>
        <DataTable
          data={tableData}
          columns={columns}
          pagination
          responsive
          noHeader />
      </React.Fragment>
    )
  }
  else {
    return (
      <div className="fw-bold text-center">Probably crawl end functionality is not enabled for this site</div>
    )
  }
}
