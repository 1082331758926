import React from 'react';
import { useState } from 'react';
import { Col } from 'reactstrap';
import { Card, CardBody } from 'reactstrap';

export default function GettingStartedCard() {

  const [checklist, setChecklist] = useState([{
    add_sites: false,
    invite_users: false,
    check_api: false,
    setup_api: false,
    faq: false,
    watch_video: false
  }]);

  // Helper function to set check list 
  function handleTick(event) {
    setChecklist({
      ...checklist, [event.target.name]: event.target.checked
    })
  };

  return (
    <div>

      <Card>
        <CardBody>
          <h5 className='mb-4'> Getting started </h5>
          <div className='d-flex align-center my-3'>
            <input type="checkbox" className='form-check-input mt-0 me-4'
              checked={checklist.add_sites}
              name='add_sites' onChange={(e) => handleTick(e)} />
            <h6 className={checklist.add_sites ? 'line-thrgh m-0' : 'm-0'}>Add sites to crawl</h6>
          </div>
          <div className='d-flex align-center my-3'>
            <input type="checkbox" className='form-check-input mt-0 me-4'
              checked={checklist.invite_users}
              name='invite_users' onChange={(e) => handleTick(e)} />
            <h6 className={checklist.invite_users ? 'line-thrgh m-0' : 'm-0'}>
              Invite users to your account
            </h6>
          </div>
          <div className='d-flex align-center my-3'>
            <input type="checkbox" className='form-check-input mt-0 me-4'
              checked={checklist.check_api}
              name='check_api' onChange={(e) => handleTick(e)} />
            <h6 className={checklist.check_api ? 'line-thrgh m-0' : 'm-0'}>
              Check the API doc
            </h6>
          </div>
          <div className='d-flex align-center my-3'>
            <input type="checkbox" className='form-check-input mt-0 me-4'
              checked={checklist.setup_api}
              name='setup_api' onChange={(e) => handleTick(e)} />
            <h6 className={checklist.setup_api ? 'line-thrgh m-0' : 'm-0'}>
              Setup API download
            </h6>
          </div>
          <div className='d-flex align-center my-3'>
            <input type="checkbox" className='form-check-input mt-0 me-4'
              checked={checklist.faq}
              name='faq' onChange={(e) => handleTick(e)} />
            <h6 className={checklist.faq ? 'line-thrgh m-0' : 'm-0'}>
              Go through <a href='https://www.promptcloud.com/web-data-scraping-faqs-promptcloud/' target='_blank' className='text-primary'>FAQ</a>
            </h6>
          </div>
          {/* <div className='d-flex align-center my-3'>
            <input type="checkbox" className='form-check-input mt-0 me-4'
              checked={checklist.watch_video}
              name='watch_video' onChange={(e) => handleTick(e)} />
            <h6 className={checklist.watch_video ? 'line-thrgh m-0' : 'm-0'}>
              Watch illustration <a href='#' target='_blank' className='text-primary'> video </a>
            </h6>
          </div> */}
        </CardBody>
      </Card>

    </div>
  )
}
