import React, { Fragment, useState, useEffect } from 'react'
import { Col, Row, Card } from 'react-bootstrap'
import Select from 'react-select';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component'
import Loader from 'react-loader-spinner';
import axios from 'axios'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function XpathsWithTwoStepsModal(props) {
  const [data, setData] = useState([]) // Table data
  const [loading, setLoading] = useState(false) // controlled input for showing loading animation
  const [show, setShow] = useState(false);
  const [fieldNameList, setFieldNameList] = useState([]); // field names from API
  // const [fieldName, setFieldName] = useState([]) // fieldname selected by user
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (props.siteName) {
      axios.get(`/api/v1/internal/schema_field_names_from_drl?site_name=${props.siteName}`)
        .then(res => {
          setFieldNameList(res?.data?.field_names)
        })
        .catch(error => {
          toast.error(error?.response?.data?.message, { autoClose: false })
        })
    }
  }, [props.siteName]);

  // API endpoint
  const API_ENDPOINT = `/api/v1/internal/new_qa_reports/xpaths_with_two_steps`

  const getChatGptDetail = () => {
    let tempObj = {}
    if (props?.fieldName?.length == 0) {
      tempObj = { ...tempObj, is_field_name_empty: true }
    }
    setErrors(tempObj)
    if ((Object.keys(tempObj)?.length == 0) && (props?.inputFilePath)) {
      setLoading(true)
      axios.post(API_ENDPOINT, {
        filters: {
          site_name: props.siteName,
          field_names: removeAllFromArray(props?.fieldName)?.map((field_name) => field_name).join(","),
          input_file_path: props.inputFilePath
        }
      }).then(res => {
        const data = res?.data?.data
        const transformedData = transformData(data[0])
        setData(transformedData)
        toast.success(res?.data?.message)
        setLoading(false)
      }).catch(err => {
        toast.error(err?.response?.data?.message, { autoClose: false })
        setLoading(false)
      })
    }
  }

  // Helper function to remove "All" value from field names
  function removeAllFromArray(inputArray) {
    // Use filter to create a new array without the "All" value
    const newArray = inputArray.filter(value => value !== "All");

    // Return the new array
    return newArray;
  }


  // Table Column Definitions
  const columns = [
    { name: "Field Name", selector: row => row?.field_name, sortable: true, left: true },
    { name: "LLM Value", selector: row => row?.llm_value, sortable: true, left: true },
    { name: "XPATH", selector: row => row?.xpath, sortable: true, left: true },
    { name: "Disk Value", selector: row => row?.disk_value, sortable: true, left: true },
    { name: "Data Type Matched", selector: row => (_.startCase(String(row?.data_type_matched))), sortable: true, left: true },
    { name: "DRL Data Type", selector: row => row?.drl_data_type, sortable: true, left: true },
    { name: "LLM Data Type", selector: row => row?.llm_data_type, sortable: true, left: true },
    { name: "Disk LLM Matching Score", selector: row => row?.disk_llm_matching_score, sortable: true, left: true },
  ]

  const transformData = (inputData) => {
    const resultArray = Object.entries(inputData).map(([key, value]) => ({
      field_name: key,
      ...value
    }))

    return resultArray
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFieldNameChange = (event) => {
    let target_value = event.target.value;
    if (target_value == "All") {
      if (fieldNameList.length == props?.fieldName.length) {
        props?.setFieldName([]);
      }
      else {
        props?.setFieldName(fieldNameList)
      }
    }
    else {
      if (props?.fieldName.includes(target_value)) {
        props?.setFieldName(props?.fieldName.filter((field_name) => field_name !== target_value))
      }
      else {
        props?.setFieldName([...props?.fieldName, target_value])
      }
    }
  };

  const disableSubmitButton = () => {
    let status = false;
    if (props?.fieldName?.length == 0) {
      status = true;
    }
    return status
  }

  return (
    <>
      {
        (props.inputFilePath && props.inputFilePath !== "NA") ?
          <>
            <Button variant="primary" onClick={handleShow} size="sm">
              ChatGPT Data
            </Button>
            <Modal
              size="xl"
              show={show}
              onHide={handleClose}
              scrollable={true}
              fullscreen='xl-down'
            >
              <Modal.Header closeButton>
                <Modal.Title>ChatGPT Data</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md='12'>
                    <label htmlFor="" className="fw-bold">Site Name</label>
                    <p>{props.siteName}</p>
                  </Col>
                  <Col md='12'>
                    <label htmlFor="" className="fw-bold">Input File Path</label>
                    <p>{props.inputFilePath}</p>
                  </Col>
                </Row>
                <Row className='mb-3 align-items-end'>
                  <label htmlFor="" className="fw-bold">Field Names*</label>
                  {fieldNameList.map((field_name) =>
                    <Col md='3'>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value={field_name} checked={props?.fieldName.includes(field_name)} onChange={handleFieldNameChange} id={field_name} />
                        <label class="form-check-label" for={field_name}>
                          {field_name}
                        </label>
                      </div>
                    </Col>
                  )}
                  {errors?.is_field_name_empty && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                </Row>
                <Row>
                  {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
                    :
                    <>
                      {(data.length !== 0) &&
                        <DataTable
                          columns={columns}
                          data={data}
                          responsive noHeader striped highlightOnHover pagination
                        />
                      }
                    </>
                  }
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="success" onClick={getChatGptDetail} disabled={disableSubmitButton()}>
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>
          </>
          : "NA"
      }
    </>
  )
}
