import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import axios from "axios";

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function AggregateUrlsCrawledByRequestType() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[site_name]"),
    requestType: new URLSearchParams(search).get("filter[request_type]"),
    fromTime: new URLSearchParams(search).get("filter[time]"),
  }

  const [siteName, setSiteName] = useState({ label: 'All', value: '*' });
  const [siteNameOptions, setSiteNameOptions] = useState([]);
  const [requestType, setRequestType] = useState({ label: 'All', value: '*' });
  const [fromTime, setFromTime] = useState({ label: 'Last 4 hours', value: 'now-4h' });

  const requestTypeOptions = [
    { label: 'All', value: '*' },
    { label: 'Get', value: 'get' },
    { label: 'Post', value: 'post' },
    { label: 'Selenium', value: 'selenium' },
    { label: 'Open-uri', value: 'open_uri' },
    { label: 'Scraper-api', value: 'scraper_api' },
    { label: 'Curl', value: 'curl' },
    { label: 'Proxy-crawl-api-get', value: 'proxy_crawl_api_get' },
  ]

  const timeOptions = [
    { label: 'Today', value: 'now%2Fd' },
    { label: 'Last 30 minutes', value: 'now-30m' },
    { label: 'Last 1 hour', value: 'now-1h' },
    { label: 'Last 2 hours', value: 'now-2h' },
    { label: 'Last 4 hours', value: 'now-4h' },
    { label: "Last 10 hours", value: "now-10h" },
    { label: 'Last 12 hours', value: 'now-12h' },
    { label: 'Last 24 hours', value: 'now-24h' },
    { label: 'Last 2 days', value: 'now-2d' },
    { label: 'Last 3 days', value: 'now-3d' },
    { label: 'Last 4 days', value: 'now-4d' },
    { label: 'Last 7 days', value: 'now-7d' },
    { label: 'Last 15 days', value: 'now-15d' },
    { label: 'Last 30 days', value: 'now-30d' },
    { label: 'Last 60 days', value: 'now-60d' },
    { label: 'Last 1 year', value: 'now-1y' }
  ];

  const [UrlParams, setUrlParams] = useState({
    siteName: '*',
    requestType: '*',
    fromTime: 'now-4h'
  })

  useEffect(() => {
    setSearchParamsData();
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let site_names = res.data.site_names, site_names_options = []
        for (var key in site_names) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
          site_names[key].map(item => {
            children.push({ label: item, value: item })
          })
          group['options'] = children;
          site_names_options.push(group)
        }
        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: '*' }] })
        setSiteNameOptions(site_names_options);
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      });
  }, []);


  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.siteName && searchParams.siteName != siteName.value) {
      setSiteName({ label: searchParams.siteName == '*' ? 'All' : searchParams.siteName, value: searchParams.siteName });
    }
    if (searchParams.requestType && searchParams.requestType != requestType.value) {
      setRequestType(requestTypeOptions.filter(option => option.value == searchParams.requestType)[0]);
    }
    if (searchParams.fromTime && searchParams.fromTime != fromTime.value) {
      setFromTime(timeOptions.filter(item => item.value == searchParams.fromTime)[0]);
    }
    if (commit == 'Get Data' && searchParams.fromTime && searchParams.siteName && searchParams.requestType) {
      setUrlParams(searchParams)
    }
  }

  function aggregateUrlsCrawledByRequestTypeKibanaUrl() {
    var to_time_val = UrlParams.fromTime == "now%2Fd" ? "now%2Fd" : "now";

    return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=histogram&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:'${UrlParams.fromTime}',mode:quick,to:'${to_time_val}'))&_a=(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:'diskfetcher_crawl_time_tracker:true%20and%20site_name:%20%22${UrlParams.siteName}%22%20and%20diskfetcher_type:%20%22${UrlParams.requestType}%22')),uiState:(spy:(mode:(fill:!f,name:!n)),vis:(colors:(Count:%237EB26D,get:%230A50A1,open_uri:%236D1F62,selenium:%23629E51))),vis:(aggs:!((id:'1',params:(customLabel:segment_count),schema:metric,type:count),(id:'2',params:(customInterval:'2h',extended_bounds:(),field:'@timestamp',interval:auto,min_doc_count:1),schema:segment,type:date_histogram),(id:'5',params:(customLabel:'Request%20Type',field:diskfetcher_type.raw,order:desc,orderBy:'1',size:5),schema:group,type:terms)),listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,defaultYExtents:!f,drawLinesBetweenPoints:!t,interpolate:linear,radiusRatio:9,scale:linear,setYExtents:!f,shareYAxis:!t,showCircles:!t,smoothLines:!f,times:!(),yAxis:()),title:'Request%20Type%20Graph%20-%20Internal%20Dashboard',type:line))`
  }

  const handleGetData = () => {
    window.location.href = `/internal/overall_system_reports/aggregate_urls_crawled_by_request_type?filter[site_name]=${siteName.value}&filter[request_type]=${requestType.value}&filter[time]=${fromTime.value}&commit=${'Get Data'}`
  }

  const promiseOptions = (inputValue) => {
    if (inputValue.length < 3) {
        return null
    } else {
        return new Promise((resolve) => {
            axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
                if (res.status == '200') {
                    let site_names = res.data.site_names, site_names_options = []
                    for (var key in site_names) {
                        let group = {}, children = []
                        group['label'] = <div className="fw-bold col text-black">{key}</div>
                        site_names[key].map(item => {
                            children.push({ label: item, value: item })
                        })
                        group['options'] = children;
                        site_names_options.push(group)
                    }
                    site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                    setSiteNameOptions(site_names_options)
                    resolve(site_names_options)
                }
            }).catch(err => {
                console.log(`err`, err)
            })
        })
    }
}

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {commit == 'Get Data' ? <a href="/internal/overall_system_reports/aggregate_urls_crawled_by_request_type">Aggregate URLs Crawled By Request Type</a> : 'Aggregate URLs Crawled By Request Type'}
            </BreadcrumbItem>
            {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
              Search
            </BreadcrumbItem>}
          </Breadcrumb>
        </Col>
      </Row>
    </div>

    <Card>
      <CardBody>
        <Row>
          <Col sm="8">
            <label className="fw-bold">Site Name</label>
            <AsyncSelect cacheOptions defaultOptions={siteNameOptions} name='site_name'
              value={siteName} loadOptions={promiseOptions} onChange={res => setSiteName(res)}
              placeholder="Select a site name" />
              <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
          </Col>
        </Row>
        <Row className="align-items-end mt-2">
          <Col sm="4">
            <label className="fw-bold">Request Type</label>
            <Select
              value={requestType}
              options={requestTypeOptions}
              onChange={option => setRequestType(option)} />
          </Col>
          <Col sm="4">
            <label className="fw-bold">Time</label>
            <Select
              value={fromTime}
              options={timeOptions}
              onChange={option => setFromTime(option)} />
          </Col>
          <Col sm="4">
            <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
          </Col>
        </Row>
      </CardBody>
    </Card>
    <Card>
      <CardBody>
        <center>
          <a target="_blank" className="link-primary" href={aggregateUrlsCrawledByRequestTypeKibanaUrl()}>Click here to see below graph in kibana</a>
        </center>
        <iframe src={aggregateUrlsCrawledByRequestTypeKibanaUrl() + "&embed=true"} height="500" width="100%" className="border-0"></iframe>
      </CardBody>
    </Card>
  </Container>
}

