import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from "moment";
import { Row, Col, Card, CardBody } from "reactstrap";
import DatePicker from "react-datepicker";
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import axios from 'axios';
import { toast } from 'react-toastify';

import { NewInternalBasePoint } from '../../../../Common/BaseEsPoints/InternalEsBasePoint';

export default function SiteSearchCard(params) {

  const DROPDOWN_LIST_OPTIONS_API_ENDPOINT = '/api/v1/internal/dropdown_list_helper';
  const SEARCH_SITE_NAME_API_ENDPOINT = '/api/v1/internal/search_site_names?site_name=';


  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");

  const rssJobTypeOptions = [
    { label: 'rss', value: 'rss' },
    { label: 'drss', value: 'drss' }
  ];

  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[site]"),
    recrawlGeoCluster: new URLSearchParams(search).get("filter[geo_cluster]") || 'all',
    rssJobType: new URLSearchParams(search).get("filter[rss_job_type]"),
    testRunStatus: new URLSearchParams(search).get("filter[test_run_status]") || 'false',
    fromDate: new URLSearchParams(search).get("filter[crawldate][from]"),
    toDate: new URLSearchParams(search).get("filter[crawldate][to]"),
  }

  const [UrlParams, setUrlParams] = useState({
    siteName: '',
    recrawlGeoCluster: 'all',
    rssJobType: 'rss',
    testRunStatus: 'false',
    fromDate: new Date(moment().subtract(2, 'days')),
    toDate: now
  })

  const now = new Date();
  const [siteName, setSiteName] = useState({ label: '', value: '' });
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(2, 'days')));
  const [toDate, setToDate] = useState(now);
  const [siteNameOptions, setSiteNameOptions] = useState([{ label: null, value: null }]);
  const [recrawlGeoCluster, setRecrawlGeoCluster] = useState({ label: 'All', value: 'all' });
  const [rssJobType, setRssJobType] = useState({ label: 'rss', value: 'rss' });
  const [testRunStatus, setTestRunStatus] = useState('false');
  const [recrawlGeoClusterOptions, setRecrawlGeoClusterOptions] = useState([]);

  // useEffect for when card is loaded
  useEffect(async () => {
    const controller = new AbortController();

    await setSearchParamsData();
    await fetchDropdownOptionsFromDB(controller.signal);

    return () => {
      controller.abort();
      console.log('Internal Segment Level RSS Stats Component un-mounted');
    }
  }, []);

  // Helper function to fetch dropdown options from backend
  async function fetchDropdownOptionsFromDB(signal) {
    try {
      const res = await axios.get(DROPDOWN_LIST_OPTIONS_API_ENDPOINT, { signal: signal });
      const siteNames = res?.data?.site_names;
      const geoData = res?.data?.geo_clusters;
      await optionsDataFormatter(siteNames, geoData);
    }
    catch (error) {
      toast.error(error?.message || 'Oops something went wrong');
    }
  };


  // Helper function to fetch name of site based on site name entered by user in async-select
  async function onAsyncSelectInputChangeHandler(inputValue) {
    if(inputValue.length < 3){
      return null
    } else {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200'){
            console.log(res.data)
            let siteNames = res.data.site_names, siteNamesOptions = []
            for (var key in siteNames) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
                siteNames[key].map(item => {
                  children.push({ label: item, value: item })
                })
              group['options'] = children;
              siteNamesOptions.push(group)
            }
            siteNamesOptions.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setSiteNameOptions(siteNamesOptions)
            resolve(siteNamesOptions)
          }
        }).catch(err => {
          console.log(`err`, err)
        })
      })
    }
  };

  // Helper function for get data button click
  function onGetDataButtonClickHandler() {
    let from_date = moment(fromDate).format('YYYY/MM/DD') == "Invalid date" ? new Date(moment().subtract(2, 'days')).format('YYYY/MM/DD') : moment(fromDate).format('YYYY/MM/DD')
    let to_date = moment(toDate).format('YYYY/MM/DD') == "Invalid date" ? moment().format('YYYY/MM/DD') : moment(toDate).format('YYYY/MM/DD')
    window.location.href = `${NewInternalBasePoint}/rss?filter[site]=${siteName.value}&filter[geo_cluster]=${recrawlGeoCluster.value}&filter[test_run_status]=${testRunStatus}&filter[rss_job_type]=${rssJobType.value}&filter[crawldate][from]=${from_date}&filter[crawldate][to]=${to_date}&commit=${'Get Data'}`;
  };

  // Helper function to format options data
  async function optionsDataFormatter(sitesData, geoData) {

    let siteNameOptions = [];

    for (let key in sitesData) {
      let group = {};
      let children = [];

      group['label'] = <div className='fw-bold col text-black'>{key}</div>

        sitesData[key].map((item) => {
          children.push({ label: item, value: item });
        });
      group['options'] = children;
      siteNameOptions.push(group);
    }
    siteNameOptions.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] });
    setSiteNameOptions(siteNameOptions);
    let geo_clusters = []
    geoData.forEach(item => {
      geo_clusters.push({ label: item, value: item })
    })
    geo_clusters.push({ label: 'All', value: 'all' })
    setRecrawlGeoClusterOptions(geo_clusters);

  };

  // Helper function to set search params
  async function setSearchParamsData() {
    if (searchParams.siteName && searchParams.siteName != siteName.value) {
      setSiteName({ label: searchParams.siteName, value: searchParams.siteName });
    }
    if (searchParams.recrawlGeoCluster && searchParams.recrawlGeoCluster != recrawlGeoCluster.value) {
      setRecrawlGeoCluster({ label: searchParams.recrawlGeoCluster == 'all' ? 'All' : searchParams.recrawlGeoCluster, value: searchParams.recrawlGeoCluster });
    }
    if (searchParams.rssJobType && searchParams.rssJobType != rssJobType.value) {
      setRssJobType({ label: searchParams.rssJobType, value: searchParams.rssJobType });
    }
    if (searchParams.testRunStatus && searchParams.testRunStatus != testRunStatus) {
      setTestRunStatus(searchParams.testRunStatus);
    }
    if (searchParams.fromDate && searchParams.fromDate != fromDate) {
      let from_date = moment(searchParams.fromDate).format('YYYY/MM/DD') == "Invalid date" ? moment(fromDate).format('YYYY/MM/DD') : moment(searchParams.fromDate).format('YYYY/MM/DD')
      setFromDate(new Date(from_date));
    }
    if (searchParams.toDate && searchParams.toDate != toDate) {
      let to_date = moment(searchParams.toDate).format('YYYY/MM/DD') == "Invalid date" ? moment(toDate).format('YYYY/MM/DD') : moment(searchParams.toDate).format('YYYY/MM/DD')
      setToDate(new Date(to_date));
    }
    if (commit == 'Get Data' || searchParams.siteName && searchParams.rssJobType && searchParams.fromDate && searchParams.toDate) {
      searchParams.fromDate = moment(searchParams.fromDate).format('YYYY/MM/DD') == "Invalid date" ? moment(fromDate).format('YYYY/MM/DD') : moment(searchParams.fromDate).format('YYYY/MM/DD')
      searchParams.toDate = moment(searchParams.toDate).format('YYYY/MM/DD') == "Invalid date" ? moment(toDate).format('YYYY/MM/DD') : moment(searchParams.toDate).format('YYYY/MM/DD')
      setUrlParams(searchParams)
      params.fetchData(searchParams);
    }
  };

  return (
    <div>
      <Card>
        <CardBody>
          <Row>
            <div className="col-sm-8">
              <label className="fw-bold">Site Name</label>
              <AsyncSelect
                cacheOptions
                defaultOptions={siteNameOptions}
                loadOptions={onAsyncSelectInputChangeHandler}
                name='site_name'
                value={siteName}
                onChange={(site) => setSiteName(site)}
                placeholder="Select a site name"
              />
              <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
            </div>
            <Col sm="4">
              <label className="fw-bold">Recrawl Geo Cluster</label>
              <Select
                value={recrawlGeoCluster}
                options={recrawlGeoClusterOptions}
                onChange={option => setRecrawlGeoCluster(option)} />
            </Col>
          </Row>
          <Row className="align-items-end mt-2">
            <Col sm="3">
              <label className="fw-bold">From Date</label>
              <DatePicker
                className="form-control"
                selected={new Date(fromDate)}
                dateFormat={"YYY/MM/dd"}
                maxDate={new Date()}
                onChange={date => setFromDate(date)} />
            </Col>
            <Col sm="3">
              <label className="fw-bold">To Date</label>
              <DatePicker
                className="form-control"
                selected={new Date(toDate)}
                dateFormat={"YYY/MM/dd"}
                maxDate={new Date()}
                onChange={date => setToDate(date)} />
            </Col>
            <Col sm="2">
              <input checked={testRunStatus == "true" ? true : false} onChange={() => setTestRunStatus(testRunStatus == "true" ? "false" : "true")} type={'checkbox'} name='test_run_status' /> Test Run Status
            </Col>
            <Col sm="2">
              <label className="fw-bold">Rss job type</label>
              <Select
                value={rssJobType}
                options={rssJobTypeOptions}
                onChange={option => setRssJobType(option)} />
            </Col>
            <Col sm="2">
              <button type='button' className="btn btn-success w-100" onClick={() => onGetDataButtonClickHandler()}>Get Data</button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  )
}
