import React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';


import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Row, Col, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone-uploader';
import axios from 'axios';

import SpinnerButton from '../../../Common/SpinnerButton/SpinnerButton.component';

export default function CreateNewIssue(props) {

  // states
  const [newIssueSubject, setNewIssueSubject] = useState('');
  const [newIssueDescription, setNewIssueDescription] = useState('');
  const [imageUpload, setImageUpload] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const [newIssueType, setNewIssueType] = useState(16);

  const {organization_name} = useParams();

  // Props 
  const { data, modal, createNewIssueModal } = props;
  const { setLoadToggle, setModal } = props;

  // Helper function to create a new issue in redmine
  const createIssue = () => {
    if (newIssueSubject.trim() === "") {
      toast.error('Please enter subject of the issue')
    }
    if (newIssueDescription.trim() === "") {
      toast.error('Please enter description of the issue')
    }
    else {
      setButtonLoading(true)
      axios.post(`/api/v1/organizations/${organization_name}/issues`, {
        issues: {
          subject: newIssueSubject, tracker_id: newIssueType,
          description: newIssueDescription, uploads: 'token' in imageUpload ? [imageUpload] : []
        },
        page_type: "client",
        controller_name: "issues"
      }).then(res => {
        toast.success(res.data.message)
        setLoadToggle(curr => !curr)
        setModal(false)
        setButtonLoading(false)
        clearSubjectAndDescription()
      }).catch(err => {
        toast.error(err.response.data.message)
        setButtonLoading(false)
      })
    }
  }

  // helper method to clear the description and subject
  function clearSubjectAndDescription() {
    setNewIssueSubject("");
    setNewIssueDescription("");
  }

  // Helper function to upload file
  const uploadFile = (meta, file) => {
    setButtonLoading(true)
    const body= new FormData()
    body.append('file', file)
    body.append('filename', meta.name)
    body.append('page_type', "client")
    body.append('controller_name', "issues")
    axios.post(`/api/v1/organizations/${organization_name}/issues/upload_file`, body, {
    }).then(res => {
      toast.success(res.data?.message)
      setButtonLoading(false)
      setImageUpload({ ...res.data?.upload, content_type: 'image/png', filename: meta.name })
    }).catch(err => {
      toast.error(err.response.data?.message)
      setButtonLoading(false)
    })
  }

  // Helper function to remove uploaded file
  const removeUploadedFile = (attachment_id) => {
    setButtonLoading(true)
    axios.delete(`/api/v1/organizations/${organization_name}/issues/${attachment_id}/remove_file?page_type=client&controller_name=issues`)
      .then(res => {
        toast.success(res.data?.message)
        setButtonLoading(false)
        setImageUpload({})
      }).catch(err => {
        toast.error(err.response.data?.message)
        setButtonLoading(false)
      })
  }

  // Dropzone status change handler
  const handleChangeStatus = ({ meta, file }, status) => {
    if (status == 'done') {
      uploadFile(meta, file)
    }
    if (status == 'removed') {
      removeUploadedFile(imageUpload?.id)
    }
  }

  // Helper function to handle issue type change in radio button
  const handleRadioButtons = (name) => {
    setNewIssueType(name)
  }



  return (
    <div>
      <Modal isOpen={modal} toggle={createNewIssueModal} backdrop='static' size='lg'>
        <ModalHeader toggle={createNewIssueModal} >
          Create New Issue
        </ModalHeader>
        <ModalBody className='px-5'>
          <Row className='my-4'>
            <Col lg={4} className='text-end px-2'>
              <h6 className='fw-bold m-0'>Type:</h6>
            </Col>
            <Col lg={8} className='d-flex align-center'>
              <div className='d-flex align-center mx-2'>
                <Input type="radio" name="radio1" className='me-2' checked={newIssueType == 14}
                  onChange={(e) => handleRadioButtons(14)} />
                <h6 className='m-0'>Data Issue</h6>
              </div>
              <div className='d-flex align-center mx-2'>
                <Input type="radio" name="radio1" className='me-2' checked={newIssueType == 15}
                  onChange={(e) => handleRadioButtons(15)} />
                <h6 className='m-0'>New Requirement</h6>
              </div>
              <div className='d-flex align-center mx-2'>
                <Input type="radio" name="radio1" className='me-2' checked={newIssueType == 16}
                  onChange={(e) => handleRadioButtons(16)} />
                <h6 className='m-0'>Other Issue</h6>
              </div>

            </Col>
          </Row>
          <Row className='my-4'>
            <Col lg={4} className='text-end px-2'>
              <h6 className='fw-bold m-0'>Subject:</h6>
            </Col>
            <Col lg={8} className='d-flex align-center'>
              <input type="text" className='form-control' value={newIssueSubject}
                onChange={(e) => setNewIssueSubject(e.target.value)} />
            </Col>
          </Row>
          <Row className='my-4'>
            <Col lg={4} className='text-end px-2'>
              <h6 className='fw-bold m-0'>Description:</h6>
            </Col>
            <Col lg={8} className='d-flex align-center'>
              <textarea className='form-control' rows="5" value={newIssueDescription}
                onChange={(e) => setNewIssueDescription(e.target.value)} />
            </Col>
          </Row>
          <Row className="my-3">
            <Col lg={4} className='text-end px-2'>
              <h6 className='fw-bold m-0'>Browse:</h6>
            </Col>
            <Col lg={8} className='d-flex align-center'>
              <Dropzone
                maxFiles={1}
                maxSizeBytes={14680064}
                onChangeStatus={handleChangeStatus}
                multiple={false}
                canCancel={true}
                accept="image/png"
                inputContent="Browse / Drop a File"
                styles={{
                  dropzone: { height: 50 },
                  dropzoneActive: { borderColor: 'green' },
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <SpinnerButton color="success" onClick={createIssue} loading={buttonLoading}>Create Issue</SpinnerButton>
        </ModalFooter>
      </Modal>
    </div>
  )
}
