import React from 'react';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Row, Col, Table } from 'reactstrap';
import SpinnerLoader from '../../../Common/LoadingAnimation/SpinnerLoader.component';
import SitegroupSchemaTable from '../DataTables/SitegroupSchemaTable.component';
import { SitesContext } from '../Context/SitesState';

export default function SitegroupDetailsCard() {
  const { loading, siteGroupNames, sitegroupId, sitegroupData, setgroupId } = useContext(SitesContext);
  const { organization_name } = useParams();

  // Function to handle the select changes for select option
  function onOptionSelectHandler(event) {
    event.preventDefault();
    const { value } = event.target; // This is sitegroupId  
    setgroupId(value); // Set the ID to state
  }

  // Misc
  function cleanText(text) {
    return text.replaceAll("_", " ");
  }

  return (
    <div>
      <SpinnerLoader loading={loading}>
        <div className='d-flex justify-content-between mb-3'>
          <a href={`/beta/v2/organizations/${organization_name}/sites/add`} className='btn btn-primary me-4'>
            + Add Site
          </a>
          <a href={`/organizations/${organization_name}/sites/new`} className='btn btn-primary'>
            + Add Site group
          </a>
        </div>
        <Card className='my-2'>
          <CardBody>
            <Row>
              <Col lg={3}>
                <select
                  className='form-control show-arrow'
                  defaultValue={sitegroupId}
                  onChange={(event) => onOptionSelectHandler(event)}
                >
                  <option value='' disabled hidden>
                    Select a Sitegroup
                  </option>
                  {siteGroupNames?.map(each => (
                    <option value={each[0]} key={each[0]}>
                      {each[1]}
                    </option>
                  ))}
                </select>
              </Col>
              {
                sitegroupData?.fields_to_extract &&
                (
                  <Col lg={3}>
                    <div>
                      <h6>Fields to extract</h6>
                      <h6 className='fw-bold'>
                        {sitegroupData?.fields_to_extract}
                      </h6>
                    </div>
                  </Col>
                )
              }
              {
                sitegroupData?.additional_details &&
                (
                  <Col lg={3}>
                    <Table bordered responsive>
                      <tbody>
                        <tr>
                          <td>Enable Merge</td>
                          <td className='fw-bold'>
                            {sitegroupData?.additional_details?.merge_files == 0 ? "Disabled" : "Enabled"}
                          </td>
                        </tr>
                        <tr>
                          <td>Enable Image Downloads</td>
                          <td className='fw-bold'>
                            {sitegroupData?.additional_details.image_downloads == 0 ? "Disabled" : "Enabled"}
                          </td>
                        </tr>
                        {
                          sitegroupData?.additional_details?.hosted_indexing &&
                          (
                            <>
                              <tr>
                                <td>Two days of delivery: </td>
                                <td className='fw-bold'>
                                  {sitegroupData?.additional_details?.two_days_of_delivery == 0 ? "Disabled" : "Enabled"}
                                </td>
                              </tr>
                              <tr>
                                <td>Hosted Indexing </td>
                                <td className='fw-bold'>
                                  {sitegroupData?.additional_details?.hosted_indexing == 0 ? "Disabled" : "Enabled"}
                                </td>
                              </tr>
                            </>
                          )
                        }
                      </tbody>
                    </Table>
                  </Col>
                )
              }
              <Col lg={3}>
                <Table bordered responsive>
                  <tbody>
                    <tr>
                      <td>Output Format</td>
                      <td className='fw-bold'>
                        {
                          sitegroupData?.delivery_format ? cleanText(sitegroupData?.delivery_format) : "NA"
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Frequency</td>
                      <td className='fw-bold'>
                        {
                          sitegroupData?.delivery_frequency_in_text ? cleanText(sitegroupData?.delivery_frequency_in_text) : "NA"
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Delivery method</td>
                      <td className='fw-bold'>
                        {
                          sitegroupData?.delivery_method ? cleanText(sitegroupData?.delivery_method) : "NA"
                        }
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className='my-4'>
              <div>
                <SitegroupSchemaTable data={sitegroupData?.schema_field_details} />
              </div>
            </Row>
          </CardBody>
        </Card>
      </SpinnerLoader>
    </div>
  )
}
