import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import axios from 'axios'
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify'
import Breadcrumbs from '../../../Common/BreadCrumb/BreadCrumbs.component';
import MasterServiceAgreement from './TermsAndConditionsText/MasterServiceAgreement/MasterServiceAgreement.component';
import StatementOfWork from './TermsAndConditionsText/StatementOfWork/StatementOfWork.component';

export default function ClientUserAgreement() {
	let { organization_name, feas_id } = useParams();
	const [checked, setChecked] = useState(false)
	const [data, setData] = useState({});
	let history = useHistory();

	useEffect(() => {
		axios.get(`/api/v1/organizations/${organization_name}/feasibility_reports/${feas_id}/user_agreements?page_type=client&controller_name=feasibility_reports`).then(res => {
			setData(res.data);
			if (res?.data?.feasibility_report?.feasibility_check_status == "payment_pending") {
				setChecked(true);
			}
			if (res.data.feasibility_report.skip_agreements) {
				toast.error("Agreements are skipped for this feasibility report.")
				history.push(`/organizations/${organization_name}/feasibility_reports/${feas_id}`)
			}
		}).catch(err => {
			toast.error(err?.message || 'Oops something went wrong')
			history.push(`/organizations/${organization_name}/feasibility_reports/${feas_id}`)
		})
	}, []);

	const handleClick = () => {
		if (data.feasibility_report?.address_contain_nessary_values) {
			axios.put(`/api/v1/organizations/${organization_name}/feasibility_reports/${feas_id}`, {
				organization: data?.address?.country == "IN"
					? { name: data?.address?.organization_name, gstin_id: data?.address?.gstin_id }
					: { name: data?.address?.organization_name },
				address: {
					line1: data?.address?.line1, line2: data?.address?.line2, city: data?.address?.city,
					state: data?.address?.state, zip: data?.address?.zip
				},
        page_type: "client", controller_name: "feasibility_reports"
			}).then(res => {
				toast.success(res.data.message)
				if (data?.feasibility_report?.skip_invoice) {
					history.push('/')
				}
				else {
					history.push(`/organizations/${organization_name}/invoices/${res.data.invoice_id}`)
				}
			}).catch(err => {
				toast.error(err.response.data.message)
			})
		}
		else {
			history.push(`/organizations/${organization_name}/feasibility_reports/${feas_id}/billing_details`)
		}
	}
	return (
		<>
			<Breadcrumbs title={"Feasibility Report"} />
			<div className='container container-fluid'>
			<MasterServiceAgreement data={data} />
			<StatementOfWork 
			data={data} 
			handleClick={handleClick} 
			checked={checked} 
			setChecked={setChecked} />
			</div>
		</>
	)
}
