import React, { Fragment, useState, useEffect } from 'react'
import { Col, Row, Button, Card, InputGroup, Form } from 'react-bootstrap'
import AsyncCreatableSelect from 'react-select/async-creatable';
import Select from 'react-select';

import UploadHtmlFile from './UploadHtmlFile.component'
import CustomTable from './CustomTable.component'
import SpinnerLoader from '../../../../Common/LoadingAnimation/SpinnerLoader.component'

import { toast } from 'react-toastify';
import _debounce from 'lodash/debounce'
import axios from 'axios';

// API endpoint
const API_ENDPOINT = `/api/v1/internal/chat_gpt_xpath_result_page/field_value`

// Table Column Definitions
const columns = [
  { name: "Field Name", selector: row => row?.field_name, sortable: true, left: true },
  { name: "LLM value", selector: row => row?.llm_value, sortable: true, left: true },
  { name: "Data Type Matched", selector: row => (_.startCase(String(row?.data_type_matched))), sortable: true, left: true },
  { name: "DRL Data Type", selector: row => row?.drl_data_type, sortable: true, left: true },
  { name: "LLM Data Type", selector: row => row?.llm_data_type, sortable: true, left: true },
]

export default function FieldValue() {

  const [data, setData] = useState([]) // Table data
  const [siteNamesDropdownOptions, setSiteNameDropdownOptions] = useState([]) // sitenames displayed in dropdown
  const [loading, setLoading] = useState(false) // controlled input for showing loading animation
  const [modalShow, setModalShow] = useState(false) // controlled input for displaying file upload modal
  const [inputFilePath, setInputFilePath] = useState("") 
  const [errors, setErrors] = useState({})
  const [fieldNameList, setFieldNameList] = useState([]);
  const [fieldName, setFieldName] = useState([]) // fieldname selected by user
  const [selectedSiteName, setSelectedSiteName] = useState("") // sitename selected by user
  const [uploadedFileName, setUploadedFileName] = useState(""); 
  const [isEnabledFileName, setIsEnabledFileName] = useState(true); 
  const [siteName, setSiteName] = useState("") // sitename selected by user

  // Dummy axios call
  useEffect(() => {
    const constroller = new AbortController()
    setLoading(true)
    fetchDefaultOptionsForSiteName()
    setLoading(false)
    // cleanup
    return () => {
      constroller.abort()
    }
  }, []);

  useEffect(() => {
    if (selectedSiteName?.value) {
      schema_field_names_from_drl(selectedSiteName?.value)
    }
  }, [selectedSiteName?.value]);

  function schema_field_names_from_drl(siteName){
    if (siteName) {
      axios.get(`/api/v1/internal/schema_field_names_from_drl?site_name=${siteName}`)
        .then(res => {
          setFieldNameList(res?.data?.field_names)
          setIsEnabledFileName(false);
        })
        .catch(error => {
          toast.error(error?.response?.data?.message, { autoClose: false })
          setFieldNameList([])
          setIsEnabledFileName(false);
        })
    }
  }

  async function fetTableData() {
    const responseBody = {
      filters: {
        site_name: siteName,
        field_names: removeAllFromArray(fieldName)?.map((field_name) => field_name).join(","),
        input_file_path: inputFilePath,
      }
    }
    try {
      setLoading(true)
      const response = await axios.post(API_ENDPOINT, responseBody)
      const data = response?.data?.data
      const transformedData = transformData(data[0])
      setData(transformedData)
      setLoading(false)
      toast.success(response?.data?.message)
    }
    catch (error) {
      setData([]);
      toast.error(error?.response?.data?.message, {autoClose: false})
      setLoading(false)
    }
  }

  
  // Helper function to remove "All" value from field names
  function removeAllFromArray(inputArray) {
    // Use filter to create a new array without the "All" value
    const newArray = inputArray.filter(value => value !== "All");

    // Return the new array
    return newArray;
  }

  async function onSubmitButtinCLickHandler() {
    let tempObj = {}
    if (siteName?.length == 0) {
      tempObj = { ...tempObj, is_site_name_empty: true }
    }
    if (siteName?.length != 0 && fieldName?.length == 0) {
      tempObj = { ...tempObj, is_field_name_empty: true }
    }
    if (inputFilePath?.length == 0) {
      tempObj = { ...tempObj, is_input_file_path_empty: true }
    }
    if (inputFilePath?.length !== 0) {
      if (siteName !== uploadedFileName) {
        tempObj = { ...tempObj, upload_file_again: true }
      }
    }
    setErrors(tempObj)
    if (Object.keys(tempObj)?.length == 0) {
      await fetTableData()
    }
  }

  // Helper method to fetch default site name dropdown options
  function fetchDefaultOptionsForSiteName() {
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let siteNames = res?.data?.site_names, siteNamesOptions = []
        for (var key in siteNames) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
            siteNames[key].map(item => {
              children.push({ label: item, value: item })
            })
          group['options'] = children;
          siteNamesOptions.push(group)
        }
        setSiteNameDropdownOptions(siteNamesOptions)
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      })
  }

  // Helper mothod to fetch site name in site name dropdown async select
  async function onDropdownOptionSearchHandler(inputValue) {
    if (inputValue.length < 3) {
      return null
    } else {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200') {
            let siteNames = res.data.site_names, siteNamesOptions = []
            for (var key in siteNames) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
                siteNames[key].map(item => {
                  children.push({ label: item, value: item })
                })
              group['options'] = children;
              siteNamesOptions.push(group)
            }
            siteNamesOptions.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setSiteNameDropdownOptions(siteNamesOptions)
            resolve(siteNamesOptions)
          }
        }).catch(err => {
          console.log(`err`, err)
        })
      })
    }
  }

  const handleFieldNameChange = (event) => {
    let target_value = event.target.value;
    if (target_value == "All") {
      if (fieldNameList.length == fieldName.length){
        setFieldName([]);
      }
      else{
        setFieldName(fieldNameList)
      }
    }
    else {
      if (fieldName.includes(target_value)){
        setFieldName(fieldName.filter((field_name) => field_name !== target_value))
      }
      else {
        setFieldName([...fieldName, target_value])
      }
    }
  };

  const handleSiteNameChange = (option) => {
    let selected_site_name = option;
    if (selected_site_name){
      setSelectedSiteName(selected_site_name);
      setSiteName(selected_site_name?.value);
      setFieldName([]);
    }
    else {
      setSelectedSiteName("");
      setSiteName("");
      setFieldNameList([]);
      setFieldName([]);
      setIsEnabledFileName(true);
    }
    if (inputFilePath.length != 0) {
      let tempObj = { ...errors, upload_file_again: true }
      setErrors(tempObj)
    }
  };

  const handleInputFieldNameChange = (event) => {
    let target_value = event?.target?.value;
    if (target_value.length !== 0){
      let filedNamesArray = target_value.split(",").map(item => item.trim());
      setFieldName(filedNamesArray.filter(element => element));
    }
    else {
      setFieldName([]);
    }
  }

  return (
    <Fragment>
      <div className='mt-4'>
        <Row className='my-3 align-items-end'>
          <Col md='6'>
            <label className="fw-bold">Select Site Name*</label>
            <AsyncCreatableSelect
              value={selectedSiteName}
              cacheOptions
              defaultOptions={siteNamesDropdownOptions}
              loadOptions={onDropdownOptionSearchHandler}
              onChange={handleSiteNameChange} 
              isClearable={true}
              placeholder={'Select Site Name'}
              createOptionPosition={"first"}
            />
            {errors?.is_site_name_empty && <p className='fw-bold text-danger my-2'> This field is required! </p>}
          </Col>
          <Col md='6'>
            <UploadHtmlFile
              setInputFilePath={setInputFilePath}
              siteName={siteName} 
              setUploadedFileName={setUploadedFileName}
              tab_name={"field_value"}
            />
            {errors?.is_input_file_path_empty && <p className='fw-bold text-danger my-2'> This field is required! </p>}
            {errors?.upload_file_again && <p className='fw-bold text-danger my-2'> You've changed the site name after file uploaded so please upload new one! </p>}
          </Col>
          <div className="form-text">NOTE: All the fields are required and Please select a sitename before file upload!</div>
        </Row>
        <Row className='my-3 align-items-end'>
          <label htmlFor="" className="fw-bold">Field Names</label>
          {
            fieldNameList.length ?
            fieldNameList.map((field_name) =>
            <Col md='3'>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value={field_name} checked={fieldName.includes(field_name)} onChange={handleFieldNameChange} id={field_name} />
                <label class="form-check-label" for={field_name}>
                  {field_name}
                </label>
              </div>
            </Col>
            ) :
            <Col md='6'>
              <input type="text" placeholder='Enter Field Names' className='form-control' onChange={handleInputFieldNameChange} disabled={isEnabledFileName} />
            </Col>
          }
          {errors?.is_field_name_empty && <p className='fw-bold text-danger my-2'> This field is required! </p>}
        </Row>
        <Row className='my-3 align-items-end'>
          <Col md={{ span: 3, offset: 4 }}>
            <button
              className='btn btn-success w-100'
              onClick={() => onSubmitButtinCLickHandler()} >
              Submit
            </button>
          </Col>
        </Row>
        <Row className='my-3'>
          <SpinnerLoader loading={loading}>
            {data && data.length > 0 &&
            <>
              <hr/>
              <Card>
                <Card.Header as="h6" style={{padding:"10px", fontWeight: "bold" }}>Field value data: </Card.Header>
                <Card.Body style={{padding:"0px" }}>
                  <CustomTable data={data} columns={columns} />
                </Card.Body>
              </Card>
            </>
            }
          </SpinnerLoader>
        </Row>
      </div>
    </Fragment>
  )
}

const transformData = (inputData) => {
  return Object.keys(inputData).map((fieldName) => {
    const fieldData = inputData[fieldName];
    return {
      field_name: fieldName,
      llm_value: fieldData.llm_value,
      data_type_matched: fieldData.data_type_matched,
      drl_data_type: fieldData.drl_data_type,
      llm_data_type: fieldData.llm_data_type,
    };
  });
};

