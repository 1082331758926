import React from "react";
import { Card, CardBody, CardHeader, Container, Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import { Home } from "react-feather";

export default function ElasticAlerts() {
    return <Container fluid>
        <div className="page-title">
            <Row>
                <Col xs="6">
                    <h3> Internal Dashboard</h3>
                </Col>
                <Col xs="6">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={'/internal'}>
                                <Home />
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem className="fw-bold">
                            Elastic Alerts
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
        <Card>
            <CardHeader className="p-3 fw-bold">
                Elastic Alerts Links
            </CardHeader>
            <CardBody>
                <a href="https://alertsplm.promptcloud.com/" target="_blank">https://alertsplm.promptcloud.com/</a><br />
                <a href="https://alertsbe.promptcloud.com/" target="_blank">https://alertsbe.promptcloud.com/</a><br />
                <a href="https://alertsjp.promptcloud.com/" target="_blank">https://alertsjp.promptcloud.com/</a><br />
            </CardBody>
        </Card >
    </Container >
}