import React, {useState, useEffect} from 'react'
import { Form, FormGroup, Input, Label, Button } from 'reactstrap'
import { setItem, userLoggedin } from  '../localStorageMethods'
import {useForm} from 'react-hook-form'
import { toast } from 'react-toastify'
import { useHistory } from "react-router-dom";
import axios from 'axios'
import queryString from 'query-string'
import { useCookies } from 'react-cookie';

export default function User_passwordreset(props) {

    let history = useHistory();
    const [initialPassword, setInitialPassword] = useState("")

    let queries = queryString.parse(props.location.search)

    useEffect(() => {
        if (userLoggedin()){
            toast.warning('You are loggedin already')
            history.push('/')
        }
    }, [])

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    
    const onSubmit = data => {
        if (data !== '') {
            if (data.passwd != data.passwd2){
                toast.error('Something error occurred please try again')
                data = {}
            }
            else{
                axios.put('/api/v1/users/password',{
                    user: {
                        password: data.passwd, 
                        password_confirmation: data.passwd2,
                        reset_password_token: queries.reset_password_token
                    },
                    controller_name: "passwords",
                    page_type: "client"
                }).then(res => {
                    const data1 = res.data
                    if (data1.status == 'success'){
                        toast.success(data1.message)
                        setItem('authToken', data1.authenticity_token)
                        setItem('authEmail', data1.email)
                        setItem('environment', data1.env)
                        if(data1.has_organization || data1.admin_user){
                            history.push('/')
                        }
                        else{
                            history.push('/organizations/new')
                        }
                    }
                    else{
                        console.log();
                    }
                }).catch(res => {
                    toast.error('Error occured please check your mail and try again')
                })
            }
        } else {
            errors.showMessages();
        }
    };

    const handlePassword = (passwd) => {
        setInitialPassword(passwd)
    }

    const checkPassword = (passwd2) => {
        return initialPassword == passwd2
    }
    return (
        <div className='login-card'>
            <div className='login-main login-tab'>
                <Form className="theme-form needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                    <h4>Change Password</h4>
                    <p>Set your password here</p>
                    <FormGroup>
                        <Label className="col-form-label">Password</Label>
                        <input className="form-control" name="passwd1"
                            {...register("passwd", { required: true,
                            validate: handlePassword, minLength: 8 })}
                            type="password" placeholder="*******" />
                            
                        <span className="error-msg">
                        { errors.passwd && (errors.passwd.type == 'minLength'
                                ? 'Password should consists min 8 characters'
                                : 'This field is required')}
                        </span>
                    </FormGroup>
                    <FormGroup>
                        <Label className="col-form-label">Confirm Password</Label>
                        <input className="form-control" placeholder="*******"
                            type="password" name="passwd"
                            {...register("passwd2", { required: true,
                            validate: checkPassword })} />
                        <span className="error-msg">
                            { errors.passwd2 && (errors.passwd2.type == 'validate'
                                ? 'Both passwords must be matched'
                                : 'This field is required')}
                        </span>
                    </FormGroup>
                    <div className="form-group mb-0">
                        <a className="link" href="/users/password/new">
                            Generate new link </a>
                        <br /> <br />
                        <Button color="primary" className="btn-block w-100 m-t-5">
                            Change Password
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}
