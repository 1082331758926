import React, {Fragment, useEffect, useState} from 'react'
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, CardBody, Card, Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'
import DataTable from 'react-data-table-component';
import ReactTooltip from 'react-tooltip';
import Loader from 'react-loader-spinner';
import moment from "moment";
import DatePicker from "react-datepicker";
import { useParams } from 'react-router-dom'
import { Home, Info, RefreshCcw } from 'react-feather';
import { useLocation } from 'react-router-dom'

import { KIBANA_PLM_URL } from '../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints';

export default function RabbitmqQueueSpecific() {
    let { queue_name } = useParams();

    const search = useLocation().search
    const serverName = new URLSearchParams(search).get('server_name')

    const [searchValue, setSearchValue] = useState('')
    const [data, setData] = useState({default_from: formatDate(new Date()), default_to: formatDate(new Date())})
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)
    const [fromDate, setFromDate] = useState(new Date(moment().subtract(7, 'days')))
    const [endDate, setEndDate] = useState(new Date())
    const [kibanaUrl, setKibanaUrl] = useState('')  

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('/');
    }

    useEffect(() => {
        setKibanaUrl(getUrl())
    }, [data])
    
    const getUrl = () => {
        return `${KIBANA_PLM_URL}app/kibana#/visualize/create?type=line&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(pause:!t,value:0),time:(from:'${data?.default_from}',to:'${data?.default_to}'))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'queue_size_tracker:true+and+name:%22+${queue_name}%22'),uiState:(),vis:(aggs:!((enabled:!t,id:'1',params:(),schema:metric,type:count),(enabled:!t,id:'2',params:(drop_partials:!f,extended_bounds:(),field:'@timestamp',interval:h,min_doc_count:1,scaleMetricValues:!f,timeRange:(from:now-30d,to:now),useNormalizedEsInterval:!t),schema:segment,type:date_histogram)),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,categoryAxes:!((id:CategoryAxis-1,labels:(filter:!t,show:!t,truncate:100),position:bottom,scale:(type:linear),show:!t,style:(),title:(),type:category)),dimensions:(x:(accessor:0,aggType:date_histogram,format:(id:date,params:(pattern:'YYYY-MM-DD+HH:mm')),params:(bounds:(max:'2022-02-17T06:51:09.516Z',min:'2022-01-18T06:51:09.516Z'),date:!t,format:'YYYY-MM-DD+HH:mm',interval:PT12H,intervalESUnit:h,intervalESValue:12)),y:!((accessor:1,aggType:count,format:(id:number),params:()))),grid:(categoryLines:!f),labels:(),legendPosition:right,seriesParams:!((data:(id:'1',label:Count),drawLinesBetweenPoints:!t,interpolate:linear,lineWidth:2,mode:normal,show:!t,showCircles:!t,type:line,valueAxis:ValueAxis-1)),thresholdLine:(color:%2334130C,show:!f,style:full,value:10,width:1),times:!(),type:line,valueAxes:!((id:ValueAxis-1,labels:(filter:!f,rotate:0,show:!t,truncate:100),name:LeftAxis-1,position:left,scale:(mode:normal,type:linear),show:!t,style:(),title:(text:Count),type:value))),title:'',type:line))&embed=true`
    }

    useEffect(() => {
        if (queue_name && serverName){
            setLoading(true)
            axios.get(`/api/v1/internal/queue_sizes/${queue_name}?server_name=${serverName}`).then(res => {
                console.log(res?.data)
                setData(res?.data)
                setLoading(false)
            }).catch(err => {
                setLoading(false)
            })
        } 
        else if (queue_name){
            setLoading(true)
            axios.get(`/api/v1/internal/queue_sizes/${queue_name}`).then(res => {
                console.log(res?.data)
                setData(res?.data)
                setLoading(false)
            }).catch(err => {
                setLoading(false)
            })
        }
    }, [queue_name, serverName])
    
    useEffect(() => {
        setTableData(data?.queue_sizes)
    }, [data])

    useEffect(() => {
        if (searchValue){
            let res = []
            data?.queue_sizes.forEach(each => {
              if (each?.queue_name?.includes(searchValue) || each?.server?.toString()?.includes(searchValue)
                || each?.queue_size?.toString()?.includes(searchValue)){
                res.push(each)
              }
            })
            setTableData(res)
          } else {
            setTableData(data?.queue_sizes)
          }
    }, [searchValue])

    const columns = [
        {name: 'Queue Name',selector: 'queue_name',center:true, sortable: true, grow: 3, cell: row => <>
            <a className='d-flex justify-content-center' data-tip data-for='info'
            href={`/internal/queue_sizes/${row.queue_name}`} target='_blank'>
                {row.queue_name} <Info size={18} className='mx-2' /> </a>
            <ReactTooltip id='info'>
                <p>Click this to see detailed stats </p>
            </ReactTooltip>
        </>},
        {name: 'Queue Size',selector: 'queue_size',center:true, sortable: true, grow: 2, cell: each => <div className='d-flex align-items-center justify-content-between'>
            <p className='my-0 me-2'>{each?.queue_size}</p>
            <a href={`/internal/queue_sizes/${each?.queue_name}?server_name=${each?.server}`} target='_blank'>
                <RefreshCcw size={14} /> </a>
        </div>},
        {name: 'Server Name',selector: 'server',center:true, sortable: true },
        {name: 'Actions',selector: 'action',center:true, cell: () => <button className='btn btn-xs btn-danger'>Delete Queue</button>},
    ]

    const getKibanaData = () => {
        window.location.href = `/internal/queue_sizes/search_by_date?utf8=%E2%9C%93&filter%5Bfrom%5D=${formatDate(fromDate)}&filter%5Bto%5D=${formatDate(endDate)}&filter%5Bqueue_name%5D=${queue_name}&commit=Get+Data`
    }

    return (
        <Container fluid>
            <Fragment>
                <Container fluid>
                    <div className="page-title">
                        <Row className=''>
                            <Col xs="6">
                                <h3>Internal Dashboard</h3>
                            </Col>
                            <Col xs="6">
                            <Breadcrumb>
                                <BreadcrumbItem>
                                    <Link to={`/internal`}><Home /></Link>
                                </BreadcrumbItem>
                                <BreadcrumbItem>
                                    <Link to={`/internal/queue_sizes`}>
                                        All Queues
                                    </Link>
                                </BreadcrumbItem>
                                <BreadcrumbItem active>
                                    <b style={{textTransform: 'lowercase'}}>{queue_name}</b>
                                </BreadcrumbItem>
                            </Breadcrumb>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </Fragment>

            {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
            : <Card>
                <CardBody>
                    <h5>NOTE:</h5>
                    <h6>1) <i className="fa fa-info-circle" aria-hidden="true"></i> indicates the hover is there. It has some informations or links.</h6>
                    <h6>2) Queue name and sizes from <b>all servers</b> </h6>
                    <Row className='mb-2'>
                        <Col md={7} className='my-3'></Col>
                        <Col md={5} className='my-3'>
                            <div className='d-flex justify-content-center align-items-center'>
                                <h6 className='my-0 me-2'>Search:</h6>
                                <input type='text' className='form-control' value={searchValue} 
                                onChange={(e) => setSearchValue(e.target?.value)} />
                            </div>
                        </Col>
                        <DataTable columns={columns} data={tableData} noHeader responsive className='my-2' pagination />
                        <div className='my-3'>
                            <h6 className='text-center'>NOTE: <b>Date filter for graph only</b> </h6>
                            <Row>
                                <Col md={4}>
                                    <h6 className='fw-bold'>From date</h6>
                                    <DatePicker selected={fromDate} onChange={(date) => setFromDate(date)}
                                        maxDate={new Date()} className='form-control' dateFormat="yyyy/MM/dd" />
                                </Col>
                                <Col md={4}>
                                    <h6 className='fw-bold'>To date</h6>
                                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)}
                                        maxDate={new Date()} className='form-control' dateFormat="yyyy/MM/dd" />
                                </Col>
                                <Col md={4} className='d-flex justify-content-center mt-3'>
                                    <button className='btn btn-success my-auto'
                                        onClick={() => getKibanaData()}>Get Data</button>
                                </Col>
                            </Row>
                            <div className="panel-body my-3">
                                <center className='my-3'>
                                    <h6 className='my-2 fw-bold'>Date Filter is applied between dates are {data?.default_from} and {data?.default_to} for Graph</h6>
                                    <a target="_blank" className="link-primary my-2" href={kibanaUrl}>Click here to see below graph in kibana</a>
                                </center>
                                <iframe src={kibanaUrl + "&embed=true"} height="800" width="100%" className="border-0"></iframe>
                            </div>
                        </div>
                    </Row>
                </CardBody>
            </Card>}
        </Container>
    )
}
