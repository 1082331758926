import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';

export default function CustomButtons(data) {
  const [loading, setLoading] = useState(false)
  
  const markAsPaid = () => {
    setLoading(true)
    axios.put(data.mark_as_paid_link, {
    }).then(res => {
      toast.success(res.data.message)
    }).catch(err => {
      toast.error(err.response.data.message)
    })
  }


  return (
    <>
      <a href={data.view_link} class="btn btn-xs btn-success mx-2 text-white" target="_blank">View</a>
      {!data.editable && data.can_mark_as_paid && <button className='btn btn-xs btn-primary mx-2' onClick={markAsPaid}>Mark as paid </button>}
      {data.editable && !data.can_mark_as_paid && <a href={data.edit_link} className='btn btn-xs btn-primary mx-2' target="_blank">Edit </a>}
    </>
  )
}
