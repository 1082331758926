import axios from 'axios'
import Select from "react-select";
import React, {useState, useEffect} from 'react'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalHeader, Row, Col, ModalFooter, Button } from 'reactstrap'
import Loader from 'react-loader-spinner'

export default function BulkUpdateForm({data, setToggle, primaryContacts, secondaryContacts, bizContacts, pmContacts, qaContacts}) {
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [orgModalData, setOrgModalData] = useState({
    primary_contact: '', secondary_contact: '', biz_contact: '', pm_contact: '',
    qa_contact: ''
  })
  const [loadingBtns, setLoadingBtns] = useState(false)

  const toggleFunc = () => {
    setModal(curr => !curr)
  }

  const closeBtn = <button className="close" 
    onClick={() => setModal(false)}> &times; </button>;

  const updateContactDetails = () => {
    setLoadingBtns(true)
    console.log("orgModalData", orgModalData);
    console.log("org_ids", data);
    axios.put(`/api/v1/admins/org_mapping/contacts_bulk_update`, {
      org_mapping: {
        primary_contact: orgModalData.primary_contact, 
        secondary_contact: orgModalData.secondary_contact,
        biz_contact: orgModalData.biz_contact,
        pm_contact: orgModalData.pm_contact,
        qa_contact: orgModalData.qa_contact,
        org_ids: data
      }
    }).then(res => {
      toast.success(res.data.message)
      setModal(false)
      setToggle(curr => !curr)
      setLoadingBtns(false)
      window.location.reload(true)
    }).catch(err => {
      toast.error(err.response.data.message)
      setLoadingBtns(false)
    })
  }

  return (
    <>
      <Modal isOpen={modal} toggle={toggleFunc} backdrop='static' size='lg'>
        <ModalHeader toggle={toggleFunc} close={closeBtn}>
          Bulk Update
        </ModalHeader>
        <ModalBody className='px-5'>
          {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
        : <>
          <Row className='my-2'>
            <Col lg={4}>
              <h6 className='my-0'>Primary Contact</h6>
            </Col>
            <Col md={8}>
              <Select options={primaryContacts} onChange={res => setOrgModalData({ ...orgModalData, primary_contact: res.value })} />
            </Col>
          </Row>
          <Row className='my-2'>
            <Col lg={4}>
              <h6 className='my-0'>Secondary Contact</h6>
            </Col>
            <Col lg={8}>
              <Select options={secondaryContacts} onChange={res => setOrgModalData({ ...orgModalData, secondary_contact: res.value })} />
            </Col>
          </Row>
          <Row className='my-2'>
            <Col lg={4}>
              <h6 className='my-0'>Biz Contact</h6>
            </Col>
            <Col lg={8}>
              <Select options={bizContacts} onChange={res => setOrgModalData({ ...orgModalData, biz_contact: res.value })} />
            </Col>
          </Row>
          <Row className='my-2'>
            <Col lg={4}>
              <h6 className='my-0'>PM Contact</h6>
            </Col>
            <Col lg={8}>
              <Select options={pmContacts} onChange={res => setOrgModalData({ ...orgModalData, pm_contact: res.value })} />
            </Col>
          </Row>
          <Row className='my-2'>
            <Col lg={4}>
              <h6 className='my-0'>QA Contact</h6>
            </Col>
            <Col lg={8}>
              <Select options={qaContacts} onChange={res => setOrgModalData({ ...orgModalData, qa_contact: res.value })} />
            </Col>
          </Row>
        </>}
        </ModalBody>
        <ModalFooter>
          <Button color='light' onClick={() => setModal(false)}> Close </Button>
          <Button color="success" disabled={loadingBtns} onClick={updateContactDetails}>update</Button>
        </ModalFooter>
      </Modal>
      <button className="btn btn-primary btn-sm" disabled={data.length == 0 ? true : false} onClick={toggleFunc}>Bulk Update</button>
    </>
  )
}
