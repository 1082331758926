import React from 'react'
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ForgotPassword from './ForgotPassword';
import Users_signin from './Users_signin';
import Users_signup from './Users_signup';
import User_email from './User_email'
import User_passwordreset from './User_passwordreset';

export default function Users() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/password/new`} 
                component={ForgotPassword} exact /> 
            <Route path={`${path}/sign_in`} 
                component={Users_signin} exact /> 
            <Route path={`${path}/sign_up`}
                component={Users_signup} exact /> 
            <Route path={`${path}/confirm_email`}
                component={User_email} exact /> 
            <Route path={`${path}/password/edit`}
                component={User_passwordreset} exact />
        </Switch>
    )
}
