import React from 'react';
import { Card, Container } from 'reactstrap';
import Stepzilla from './Stepzilla/Stepzilla.index';
import BreadCrumbs from '../../Common/BreadCrumb/BreadCrumbs.component';

export default function AddNewSitegroup({current_user, environment}) {
  return (
    <div>
      <Container fluid>
        <BreadCrumbs parent={"Add New Sitegroup"} title={"Add New Sitegroup"} />
        <Card className='px-5 py-4'>
          <Stepzilla environment={environment} />
        </Card>
      </Container>
    </div>
  )
}
