import React, {useState, useEffect, Fragment} from 'react';
import { Card, CardBody, Col, Container, Row, Breadcrumb, BreadcrumbItem, Nav, Table, Alert,
  NavItem, NavLink, TabContent, TabPane, CardHeader } from 'reactstrap';
import { useParams, useLocation, Link } from "react-router-dom";
import { Target, Info, CheckCircle, Home } from 'react-feather';
import SegmentLevelQa from './SegmentLevelQa';
import AggregateQa from './AggregateQa';

export default function EachQualityAnalysisPage() {
  let { sitename } = useParams();
  const search = useLocation().search
  const tabName = new URLSearchParams(search).get('tab_name')
  const [activeTab, setActiveTab] = useState(tabName? tabName : "segment_level_qa")

  function downloadDoc(content, fileName, contentType) {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }

  return (
    <Container fluid>
      <Fragment>
        <Container fluid>
          <div className="page-title">
            <Row className=''>
              <Col xs="6">
                <h3>Internal Dashboard</h3>
              </Col>
              <Col xs="6">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`/internal`}><Home /></Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link to={'/internal/qa'}>
                      All Sites
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link to={'/internal/qa/pending'}>
                      List of Sites Pending for QA
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <b>{sitename}</b>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </div>
        </Container>
      </Fragment>
      <Row>
        <Col md="12" className="project-list">
          <Card>
            <CardBody>
              <Nav tabs className="border-tab">
                <NavItem>
                  <NavLink className={activeTab === "segment_level_qa" ? "active" : ''} onClick={() => setActiveTab("segment_level_qa")}>
                    <Target /> Segment Level QA
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={activeTab === "aggregate_level_qa" ? "active" : ''} onClick={() => setActiveTab("aggregate_level_qa")}>
                    <CheckCircle /> Aggregate Level QA
                  </NavLink>
                </NavItem>
              </Nav>
            </CardBody>
          </Card>
        </Col>
        <Col md="12">
          <Card>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane tabId='segment_level_qa'>
                  <SegmentLevelQa downloadDoc={downloadDoc} activeTabName={activeTab}/>
                </TabPane>
                <TabPane tabId='aggregate_level_qa'>
                  <AggregateQa downloadDoc={downloadDoc} activeTabName={activeTab}/>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
