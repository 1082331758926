import axios from "axios";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import { Home } from "react-feather";
import Loader from "react-loader-spinner";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row } from "reactstrap";

export default function MultiTargetUploadSuccess() {
    const search = useLocation().search;
    const commit = new URLSearchParams(search).get("commit");
    const searchParams = {
        siteName: new URLSearchParams(search).get("filter[site_name]"),
        fromDate: new URLSearchParams(search).get("filter[from_date]"),
        toDate: new URLSearchParams(search).get("filter[to_date]"),
        protocol: new URLSearchParams(search).get("filter[protocol]")

    }
    const now = new Date()
    const [data, setData] = useState([]);
    const [siteName, setSiteName] = useState({ label: 'All', value: 'all' });
    const [siteNameOptions, setSiteNameOptions] = useState([]);
    const [fromDate, setFromDate] = useState(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7));
    const [toDate, setToDate] = useState(now);
    const [protocol, setProtocol] = useState({ label: "All", value: "all" });
    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [filesCount, setFilesCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const protocolOptions = [
        { label: "All", value: "all" },
        { label: "FTP", value: "ftp" },
        { label: "LFTP", value: "lftp" },
        { label: "S3", value: "s3" },
        { label: "DropBox", value: "dropbox" },
        { label: "Box", value: "box" },
        { label: "Google Drive", value: "google_drive" }
    ]
    const columns = [
        { name: 'Site', selector: 'site', center: true, sortable: true },
        { name: 'Protocol', selector: 'protocol', center: true, sortable: true, cell: row => <div>{row.protocol?.join(',')}</div> },
        { name: 'File Counts', selector: 'files_count', center: true, sortable: true },
    ]

    useEffect(() => {
        setSearchParamsData()
        axios.get('/api/v1/internal/dropdown_list_helper')
            .then(res => {
                let site_names = res.data.site_names, site_names_options = []
                for (var key in site_names) {
                    let group = {}, children = []
                    group['label'] = <div className="fw-bold col text-black">{key}</div>
                    site_names[key].map(item => {
                        children.push({ label: item, value: item })
                    })
                    group['options'] = children;
                    site_names_options.push(group)
                }
                site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                setSiteNameOptions(site_names_options);
            })
            .catch(err => {
                console.error(err?.message || 'Oops something went wrong');
            })
    }, []);

    function setSearchParamsData() {
        if (searchParams.siteName && searchParams.siteName != siteName.value) {
            setSiteName({
                label: searchParams.siteName == 'all' ? 'All' : searchParams.siteName,
                value: searchParams.siteName
            })
        }
        if (searchParams.fromDate && searchParams.fromDate != fromDate) {
            setFromDate(new Date(searchParams.fromDate))
        }
        if (searchParams.toDate && searchParams.toDate != toDate) {
            setToDate(new Date(searchParams.toDate))
        }
        if (searchParams.protocol && searchParams.protocol != protocol.value) {
            setProtocol(protocolOptions.filter(item => searchParams.protocol == item.value)[0])
        }
        if (commit == 'Get Data' && searchParams.siteName && searchParams.fromDate && searchParams.toDate && searchParams.protocol) {
            fetchData(searchParams.siteName, searchParams.fromDate, searchParams.toDate, searchParams.protocol)
        }
        else {
            fetchData()
        }
    }

    function fetchData(site_name = siteName.value, from_date = fromDate, to_date = toDate, Protocol = protocol.value) {
        setLoading(true)
        axios.get(`/api/v1/internal/extended_upload_success_stats/multi_target_upload_success?filter[site_name]=${site_name}&filter[from_date]=${moment(from_date).format('YYYY/MM/DD')}&filter[to_date]=${moment(to_date).format('YYYY/MM/DD')}&filter[protocol]=${Protocol}`)
            .then(res => {
                let count = 0
                res.data.data.forEach(item => count += item.files_count)
                setFilesCount(count)
                setData(res.data.data);
                setSearchResults(res.data.data);
                setLoading(false);
            })
            .catch(err => {
                console.log(err?.message || 'Oops something went wrong');
                setLoading(false);
            })
    }

    const handleInputChange = event => {
        if (event.target.value == '') {
            setSearchResults(data)
        }
        else {
            setSearchResults(data.filter(item => item.site.includes(event.target.value) || item.protocol.includes(event.target.value) || item.files_count.toString().includes(event.target.value)))
        }
        setSearchText(event.target.value);
    }

    const handleGetData = () => {
        window.location.href = `/internal/extended_upload_success_stats/multi_target_upload_success?filter[site_name]=${siteName.value}&filter[from_date]=${moment(fromDate).format('YYYY/MM/DD')}&filter[to_date]=${moment(toDate).format('YYYY/MM/DD')}&filter[protocol]=${protocol.value}&commit=${'Get Data'}`
    }

    

    const promiseOptions = (inputValue) => {
        if (inputValue.length < 3) {
            return null
        } else {
            return new Promise((resolve) => {
                axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
                    if (res.status == '200') {
                        let site_names = res.data.site_names, site_names_options = []
                        for (var key in site_names) {
                            let group = {}, children = []
                            group['label'] = <div className="fw-bold col text-black">{key}</div>
                            site_names[key].map(item => {
                                children.push({ label: item, value: item })
                            })
                            group['options'] = children;
                            site_names_options.push(group)
                        }
                        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                        setSiteNameOptions(site_names_options)
                        resolve(site_names_options)
                    }
                }).catch(err => {
                    console.log(`err`, err)
                })
            })
        }
    }


    return <Container fluid>
        <div className="page-title">
            <Row>
                <Col xs="6">
                    <h3>Extended Upload Success Stats</h3>
                </Col>
                <Col xs="6">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={'/internal'}>
                                <Home />
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            {commit == 'Get Data' ? <a className="link-primary" href={'/internal/extended_upload_success_stats/multi_target_upload_success'}>Multi Target Upload Success</a> : 'Multi Target Upload Success'}
                        </BreadcrumbItem>
                        {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                            Search Results
                        </BreadcrumbItem>}
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
        {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> : <Fragment>
            <Card>
                <CardBody>
                    <Row>
                        <Col sm="9">
                            <label className="fw-bold">Site Name</label>
                            <AsyncSelect cacheOptions defaultOptions={siteNameOptions} name='site_name'
                                value={siteName} loadOptions={promiseOptions} onChange={res => setSiteName(res)}
                                placeholder="Select a site name" />
                                <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
                        </Col>
                    </Row>
                    <Row className="mt-3 align-items-end">
                        <Col sm="3">
                            <label className="fw-bold">From Date</label>
                            <DatePicker
                                className="form-control"
                                minDate={new Date(now.getFullYear(), now.getMonth(), now.getDate() - 10)}
                                maxDate={new Date()}
                                selected={new Date(fromDate)}
                                dateFormat={"YYY/MM/dd"}
                                onChange={date => setFromDate(date)} />
                        </Col>
                        <Col sm="3">
                            <label className="fw-bold">To Date</label>
                            <DatePicker
                                className="form-control"
                                selected={new Date(toDate)}
                                minDate={new Date(now.getFullYear(), now.getMonth(), now.getDate() - 10)}
                                maxDate={new Date()}
                                dateFormat={"YYY/MM/dd"}
                                onChange={date => setToDate(date)} />
                        </Col>
                        <Col sm="3">
                            <label className="fw-bold">Protocol</label>
                            <Select
                                options={protocolOptions}
                                value={protocol}
                                onChange={option => setProtocol(option)} />
                        </Col>
                        <Col sm="3">
                            <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    
                    <Row className="my-3">
                        <Col>
                            <div className="float-end">
                                <Row className="align-items-center">
                                    <Col sm="3">Search</Col>
                                    <Col>
                                        <input type="text" className="form-control" value={searchText} onChange={handleInputChange} />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <DataTable
                        columns={columns}
                        data={searchResults}
                        noHeader
                        highlightOnHover
                        pagination
                        progressPending={loading}
                    />
                    {searchResults.length > 0 && <div className="fw-bold">
                        Overall file count:{filesCount}
                    </div>}
                </CardBody>
            </Card>
        </Fragment>}
    </Container>
}