import React from 'react';
import { useState } from 'react';
import { AlertTriangle } from 'react-feather';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
export default function ErrorMessageModal({ messageContent, errorModalOpenHandler }) {
    return (
        <>
            <Modal isOpen={true} centered={true} size={'md'} >
                <ModalHeader className='px-5'>
                    <div class="d-flex flex-row">
                        <div class="p-2"><AlertTriangle size={30} color='red' /></div>
                        <div class="p-2"><h3>Error</h3></div>
                    </div>
                </ModalHeader>
                <ModalBody className='px-5'>
                    <div>
                        <p className="h6">{messageContent.split('<br/><br/>')[0]}</p>
                        <p className="h6">{messageContent.split('<br/><br/>')[1]}</p>
                    </div>
                </ModalBody>
                <ModalFooter className='px-5'>
                    <Button color='danger' onClick={errorModalOpenHandler} >Close</Button>
                </ModalFooter>
            </Modal>
        </>
    )
};
