import React from 'react';
import { useContext, useState, useEffect, useMemo } from 'react';
import { SitesContext } from '../Context/SitesState';
import { useParams } from 'react-router-dom';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import SpinnerLoader from '../../../Common/LoadingAnimation/SpinnerLoader.component';
import SpinnerButton from '../../../Common/SpinnerButton/SpinnerButton.component';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import axios from 'axios';
import Chart from "react-apexcharts";


export default function SitesCrawlGraphCard() {
  const { sitegroupId } = useContext(SitesContext);
  const { organization_name } = useParams();

  const [loading, setLoading] = useState(false);
  const [siteNames, setSiteNames] = useState([]);
  const [graphData, setGraphData] = useState({});
  const [selectedSite, setSelectedSite] = useState("");
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});

  const minimumSelection = new Date(Date.now() - 180 * 24 * 60 * 60 * 1000)
  const [startDate, setStartDate] = useState(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(new Date(Date.now()));



  // This use effect is for getting site names and graph data for first time when component loads 
  useEffect(async () => {
    const controller = new AbortController();

    await getSiteNames(controller.signal, sitegroupId);
    await getUploadCounts(controller.signal);
    // cleanup
    return () => {
      controller.abort();
    }
  }, [sitegroupId]);

  // Function to get site names from DB
  // This changes when sitegroup Id changes
  async function getSiteNames(signal, groupId) {
    setLoading(true);
    try {
      const response = await axios.get(`/api/v1/organizations/${organization_name}/site_name_list?sitegroup_id=${groupId}`, { signal: signal });
      const data = await response?.data?.site_names;
      setSiteNames(data);
      setLoading(false);
    }
    catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  }

  // Function to get upload counts 
  async function getUploadCounts(signal) {
    setLoading(true);
    try {
      const response = await axios.get(`/api/v1/organizations/${organization_name}/sites/uploads_count?from_date=${getFormattedDate(startDate)}&to_date=${getFormattedDate(endDate)}&site_id=${selectedSite}&sitegroup_id=${sitegroupId}`, { signal: signal });
      const counts = {
        days: await response?.data?.sites_upload_count?.days,
        uploads_count: response?.data?.sites_upload_count?.uploads_count
      };
      setGraphData(counts);
      setLoading(false);
    }
    catch (error) {
      toast.error(error?.status?.data?.message);
      setLoading(false);
    }
  }

  // Function to set graph data
  useEffect(() => {
    if ('days' in graphData && graphData?.days?.length > 0) {
      setOptions(
        {
          chart: { id: "basic-bar" },
          xaxis: { categories: graphData?.days }
        })
      setSeries([
        {
          name: "uploads",
          data: graphData?.uploads_count
        }
      ])
    }
    else {
      setOptions({})
      setSeries([])
    }
  }, [graphData])

  function onOptionSelectHandler(event) {
    const { value } = event.target;
    setSelectedSite(value);
  }

  function onDateChange(dates) {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }

  async function onRequestSubmitHandler() {
    const difference = datediff(parseDate(startDate), parseDate(endDate));
    if (difference > 30) {
      toast.error("Date difference cannot be more than 30 days!");
      return;
    }
    else if (selectedSite === "") {
      toast.error("Site name is required and cannot be empty!");
      return;
    }
    else {
      await getUploadCounts();
    }
  }

  // Format date
  function getFormattedDate(date) {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  }

  // Date parser for calculating the date diff
  function parseDate(str) {
    str = getFormattedDate(str)
    var mdy = str.split('/');
    return new Date(mdy[2], mdy[0] - 1, mdy[1]);
  }

  // Date difference calculation
  function datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  return (
    <div>
      <Card className='my-2 mb-5'>
        <CardHeader className='m-0 p-4 d-flex align-center'>
          <Row className='w-100'>
            <Col lg={4}>
              <select
                className='form-control show-arrow'
                defaultValue={""}
                onChange={(event) => onOptionSelectHandler(event)} >
                <option value='' key={"none"} disabled hidden>
                  Select Site
                </option>
                {siteNames?.map(each => (
                  <option value={each.id} key={each.id}>
                    {each.sitename}
                  </option>
                ))}
              </select>
            </Col>
            <Col lg={1}></Col>
            <Col lg={5}>
              <div className='d-flex'>
                <h6 className='me-2'>Select Date Range:</h6>
                <div>
                  <DatePicker
                    className='form-control'
                    startDate={startDate}
                    maxDate={new Date(Date.now())}
                    minDate={minimumSelection}
                    endDate={endDate}
                    selected={startDate}
                    onChange={onDateChange}
                    selectsRange />
                  <span className='text-muted' style={{ fontSize: 12 }}>
                    Any 30 days in last 6 months
                  </span>
                </div>
              </div>
            </Col>
            <Col lg={2}>
              <SpinnerButton 
                color='success'
                onClick={onRequestSubmitHandler}
                loading={loading}
                disabled={loading}>
                Apply
              </SpinnerButton>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <SpinnerLoader loading={loading}>
            {('days' in graphData) ?
              <Row className='w-100 my-3'>
                <Chart options={options} series={series} height="400"
                  type="line" style={{ width: "100%" }} />
              </Row> : 
              <>
                <h6> Select a sitename and click on apply </h6>
              </>}
          </SpinnerLoader>
        </CardBody>
      </Card>
    </div>
  )
}
