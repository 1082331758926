import React, {Fragment} from 'react'
import { Container, Row, Col, BreadcrumbItem, Breadcrumb } from 'reactstrap'
import { Home } from 'react-feather';
import { Link } from 'react-router-dom'
import MyAccordion from '../../global/MyAccordion'
import RunForceRunFailed from './command-dashboard-steps/RunForceRunFailed';
import UpdateCodeOnHetzner from './command-dashboard-steps/UpdateCodeOnHetzner';
import DataApiAddMute from './command-dashboard-steps/DataApiAddMute';
import CollectFailedUrl from './command-dashboard-steps/CollectFailedUrl';
import PushFailedUrls from './command-dashboard-steps/PushFailedUrls';
import PopulatedDependentData from './command-dashboard-steps/PopulatedDependentData';
import PushRecrawl from './command-dashboard-steps/PushRecrawl';
import QAReportGeneration from './command-dashboard-steps/QAReportGeneration';
import DataStockGenerator from './command-dashboard-steps/DataStockGenerator';
import RunForceESDataDeletion from './command-dashboard-steps/RunForceESDataDeletion';
import MergeFilesAndUpload from './command-dashboard-steps/MergeFilesAndUpload';
import ManuallyPushToQueue from './command-dashboard-steps/ManuallyPushToQueue';

export default function CommandDashboard() {
    return (
        <Container fluid>
            <Fragment>
                <Container fluid>
                    <div className="page-title">
                        <Row className=''>
                            <Col xs="6">
                                <h3>Internal Dashboard</h3>
                            </Col>
                            <Col xs="6">
                                <Breadcrumb>
                                    <BreadcrumbItem>
                                        <Link to={`/internal`}><Home /></Link>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active>
                                        <b>Command Dashboard</b>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </Fragment>

            <div className='my-3 bg-white p-3'>
                <MyAccordion title='Run Force / Run Failed Extraction' className='my-2'>
                    <RunForceRunFailed />
                </MyAccordion>
                <br />
                <MyAccordion title='Update Code on Hetzner & Dedicated Servers' className='my-2'>
                    <UpdateCodeOnHetzner />
                </MyAccordion>
                <br />
                <MyAccordion title='Push Recrawl / Feedcrawl /Blocking solution finder Job To Resque' className='my-2'>
                    <PushRecrawl />
                </MyAccordion>
                <br />
                <MyAccordion title='Merge Files and Upload' className='my-2'>
                    <MergeFilesAndUpload />
                </MyAccordion>
                <br />
                <MyAccordion title='Data Api Add Mute Touch Unmute Files' className='my-2'>
                    <DataApiAddMute />
                </MyAccordion>
                <br />
                <MyAccordion title='Manually Push To Queue' className='my-2'>
                    <ManuallyPushToQueue />
                </MyAccordion>
                <br />
                <MyAccordion title='Collect Failed URLs' className='my-2'>
                    <CollectFailedUrl />
                </MyAccordion>
                <br />
                <MyAccordion title='Populated dependent data upload failed URLs queue' className='my-2'>
                    <PopulatedDependentData />
                </MyAccordion>
                <br />
                <MyAccordion title='Push Failed URLs for DRSS' className='my-2'>
                    <PushFailedUrls />
                </MyAccordion>
                <br />
                <MyAccordion title='QA Report Generation' className='my-2'>
                    <QAReportGeneration />
                </MyAccordion>
                <br />
                <MyAccordion title='DataStock Generator' className='my-2'>
                    <DataStockGenerator />
                </MyAccordion>
                <br />
                <MyAccordion title='Run Force ES Data Deletion' className='my-2'>
                    <RunForceESDataDeletion />
                </MyAccordion>
            </div>
        </Container>
    )
}
