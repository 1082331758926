import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment/moment';
import Loader from 'react-loader-spinner';

export default function Index() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const tabNameFromSearchUrl = new URLSearchParams(search).get("filter[tab_name]");
  const tabNameFromUrl = window.location.hash.substring(1);
  const searchParams = {
    freqUsedOrg: new URLSearchParams(search).get("filter[freq_used_org]") || "",
    orgIdentifier: new URLSearchParams(search).get("filter[org_identifier]") || "",
    projectTitle: new URLSearchParams(search).get("filter[project_title]") || "",
    customListName: new URLSearchParams(search).get("filter[custom_list_name]") || "",
    geoCluster: new URLSearchParams(search).get("filter[geo_cluster]") || "",
    topNsites: new URLSearchParams(search).get("filter[top_n_sites]") || "",
    fromDate: new URLSearchParams(search).get("filter[from_date]") || "",
    toDate: new URLSearchParams(search).get("filter[to_date]") || "",
    tabName: tabNameFromSearchUrl ? tabNameFromSearchUrl : tabNameFromUrl
  };

  useEffect(() => {
    if (commit == "Get Data"){
      history.push(`/internal/high_volume_clients_and_sites?filter[freq_used_org]=${searchParams.freqUsedOrg}&filter[org_identifier]=${searchParams.orgIdentifier}&filter[project_title]=${searchParams.projectTitle}&filter[custom_list_name]=${searchParams.customListName}&filter[top_n_sites]=${searchParams.topNsites}&filter[geo_cluster]=${searchParams.geoCluster}&filter[from_date]=${searchParams?.fromDate ? moment(searchParams.fromDate).format('YYYY/MM/DD') : searchParams.fromDate}&filter[to_date]=${searchParams?.toDate ? moment(searchParams.toDate).format('YYYY/MM/DD') : searchParams.toDate}&filter[tab_name]=${searchParams.tabName}&commit=${'Get Data'}`)
    }
    else{
      history.push(`/internal/high_volume_clients_and_sites`)
    }
  }, [])    


  return (
    <>
      {
        loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
          : "Error------"
      }
    </>
  )
}
