import React, { Fragment } from 'react'
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap'
import { tableStyle, tableHeaderStyle, tableRowStyle, pStyle, tableDataStyle, cardHeaderStyle } from './TableStyle'

export default function HighPriorityDataCheckReportsTable({ data }) {

  const dataTypeReport = data?.report_for_field_name.filter((data) => (data?.report_name === "data_type_report"))
  const frequencyReport = data?.report_for_field_name.filter((data) => (data?.report_name === "frequency_report"))
  const nullCount = data?.report_for_field_name.filter((data) => (data?.report_name === "null_counts"))
  const uniqCount = data?.report_for_field_name.filter((data) => (data?.report_name === "uniq_counts"))

  return (
    <Fragment>
      <div className='data-check-reports-table'>
        <div className='d-flex justify-content-center data-check-report-card-deck'>
          <Card className='d-flex flex-column'>
            <CardHeader style={{ backgroundColor: "#40A2E3" }} className='p-1'>
              <CardTitle className='p-2 m-0 text-white fw-bold'>{data?.name_of_field}</CardTitle>
            </CardHeader>
            <CardBody className='p-0 d-flex flex-column justify-content-between align-items-center' style={{ flex: "1", backgroundColor: "#BBE2EC" }}>
              <div>
                <DataTypeReportTable
                  tableName={"Data Type Report"}
                  tableHeaders={["Value", "Count"]}
                  tableData={dataTypeReport} />
              </div>
              <div>
                <FrequencyReportTable
                  tableName={"Frequency Report"}
                  tableHeaders={["Key", "Frequency"]}
                  tableData={frequencyReport} />
              </div>
            </CardBody>
            {
              (nullCount?.length > 0 && uniqCount?.length > 0) && (
                <CardFooter style={{ backgroundColor: "#FFF6E9" }}>
                  <div className='d-flex flex-row justify-content-between align-items-end'>
                    <div>
                      <p className='btn btn-light text-dark fw-bold'>{`Null Count ${nullCount.length > 0 ? nullCount[0]?.values[0]?.count : 0}`}</p>
                    </div>
                    <div>
                      <p className='btn btn-light text-dark fw-bold'>{`Uniq Count ${uniqCount.length > 0 ? uniqCount[0]?.values[0]?.count : 0}`}</p>
                    </div>
                  </div>
                </CardFooter>
              )
            }
            {
              (nullCount?.length > 0 && uniqCount?.length === 0) && (
                <CardFooter style={{ backgroundColor: "#FFF6E9" }} className='d-flex justify-content-center'>
                  <div className='d-flex flex-row justify-content-canter align-items-center'>
                    <div>
                      <p className='btn btn-light text-dark fw-bold'>{`Null Count ${nullCount.length > 0 ? nullCount[0]?.values[0]?.count : 0}`}</p>
                    </div>
                  </div>
                </CardFooter>
              )
            }
            {
              (uniqCount?.length > 0 && nullCount?.length === 0) && (
                <CardFooter style={{ backgroundColor: "#FFF6E9" }} className='d-flex justify-content-center'>
                  <div className='d-flex flex-row justify-content-canter align-items-center'>
                    <div>
                      <p className='btn btn-light text-dark fw-bold'>{`Uniq Count ${uniqCount.length > 0 ? uniqCount[0]?.values[0]?.count : 0}`}</p>
                    </div>
                  </div>
                </CardFooter>
              )
            }
          </Card>
        </div>
      </div>
    </Fragment>
  )
}

function DataTypeReportTable({ tableName, tableHeaders, tableData }) {
  // Extract values from the data
  const dataTypeReport = tableData?.flatMap((item) =>
    Object.entries(item?.values[0]).map(([key, value]) => ({ key, value: value.count }))
  );

  if (dataTypeReport.length > 0) {
    return (
      <Fragment>
        <table style={tableStyle}>
          <tr>
            <th style={tableHeaderStyle} rowSpan={dataTypeReport?.length + 1}>{tableName}</th>
            {tableHeaders.map((headerName, indx) => (
              <th style={tableHeaderStyle} key={indx}>{headerName}</th>
            ))}
          </tr>
          {
            dataTypeReport?.map((data, indx) => (
              <tr key={indx} style={tableRowStyle}>
                <td>{data?.key}</td>
                <td>{data?.value}</td>
              </tr>
            ))
          }
        </table>
      </Fragment>
    )
  }
  else {
    return (
      <Fragment>
      </Fragment>
    )
  }
}

function FrequencyReportTable({ tableName, tableHeaders, tableData }) {
  // Extract values from the data
  const frequencyReport = tableData?.flatMap((item) =>
    Object.entries(item?.values[0]).map(([key, value]) => ({
      key,
      frequency: value?.frequency_count,
    }))
  );

  if (frequencyReport.length > 0) {
    return (
      <Fragment>
        <table className='' style={tableStyle}>
          <tr>
            <th style={tableHeaderStyle} rowSpan={frequencyReport?.length + 1}>{tableName}</th>
            {tableHeaders.map((headerName, indx) => (
              <th style={tableHeaderStyle} key={indx}>{headerName}</th>
            ))}
          </tr>
          {
            frequencyReport?.map((data, indx) => (
              <tr key={indx} style={tableRowStyle}>
                <td style={tableDataStyle}><p style={pStyle}>{data?.key}</p></td>
                <td>{data?.frequency}</td>
              </tr>
            ))
          }
        </table>
      </Fragment>
    )

  }
  else {
    return (
      <Fragment>
      </Fragment>
    )
  }
}
