import React from "react";
import { Card, CardBody, Container } from "reactstrap";
import Breadcrumbs from "../../global/Breadcrumb";

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function HostWiseLoadStats() {
    return <Container fluid>
        <Breadcrumbs title="Internal / Devops" parent="Host Wise Load Stats" />
        <Card>
            <CardBody>
                <div className="text-center mb-3">
                    <b>Host Wise Load Stats</b><br />
                    <a href={`${KIBANA_PLM_URL}app/infra#/infrastructure/inventory?_g=()&waffleOptions=(autoBounds:!t,boundsOverride:(max:1,min:0),customOptions:!(),groupBy:!(),metric:(type:load),nodeType:host,view:table)`} className="link-primary" target={'_blank'}>Click here to see below graph in kibana</a>
                </div>
                <iframe className="border-0" src={`${KIBANA_PLM_URL}app/infra#/infrastructure/inventory?_g=()&waffleOptions=(autoBounds:!t,boundsOverride:(max:1,min:0),customOptions:!(),groupBy:!(),metric:(type:load),nodeType:host,view:table)&embed=true`} height={'1000'} width='100%'></iframe>
            </CardBody>
        </Card>
    </Container>
}