/**
 * This is the context provider that wraps the component to check if a particular 
 * user who is logging in or already logged in ha super user privilege or not
 * 
 * In the application, the Super User privilege is referred to as Admin Privileges which allows users to 
 * perform certain specific actions which are certain and specific to promptcloud users only
 * 
 * NOTE: MODIFY THE USE-EFFECT FOR GETTING THE "ror_user" PRIVILEGES IF NEEDED AND PASS IT IN THE VALUE
 * CAUTION: PLEASE CONTACT DEVS BEFORE MAKING CHANGES HERE
 */
import React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { AdminContext } from '../../Exports/ContextExports';
import { getItem } from '../../../../localStorageMethods';
import axios from 'axios';

export default React.memo(function SuperUserContext({ children }) {

	// API ENDPOINT FROM WHERE THE STATUS IS FETCHED
	const BASE_URI = useMemo(() => '/api/v1/user/details?page_type=client&controller_name=dashboard&email=', []);

	// The email which is read from the local storage
	const email = useMemo(() => {
		const mail = getItem("authEmail");
		return mail;
	}, []);

	const [superUser, setSuperUser] = useState(false); // This will store the super user privilege which can be used by components

	// Helper function to get the Super User/ Admin Privileges from user
	async function getPrivileges(signal) {
		try {
			const response = await axios.get(BASE_URI + email, { signal: signal });
			const user = await response?.data?.user?.is_admin;
			setSuperUser(user);
		}
		catch (error) {
			console.log(error?.message);
		}
	}

	// When the application loads, get the privileges and store it in the state
	useEffect(async () => {
		const controller = new AbortController();

		// Get the super user status from the backend
		await getPrivileges(controller.signal);

		// cleanup
		return () => {
			controller.abort();
		}

	}, [email]);

	return (
		<>
			<AdminContext.Provider value={{superUser:superUser}}>
				{children}
			</AdminContext.Provider>
		</>
	)
});
