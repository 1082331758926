import React, {Fragment, forwardRef, useImperativeHandle,
  useState, useEffect} from 'react';
import { Card, CardBody, Row, Col, CardHeader, Modal, Button,
  ModalBody, ModalHeader, ModalFooter, FormGroup, Input} from 'reactstrap';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';

const SetCrawlNotifications = forwardRef(({orgName, sitegroupNames}, ref) => {
  const [modal, setModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState('all')
  const [selectedRows, setSelectedRows] = useState([]);

  const [crawlNotifications, setCrawlNotifications] = useState({
    start: false, completion: false
  })

  const toggle = () => {
    setModal(curr => !curr)
  }

  useImperativeHandle(ref, () => ({
    handleRowChange(selectedRows){
      setSelectedRows(selectedRows)
      if (selectedRows.length === 0){
        setSelectedOptions('all')
      } else{
        setSelectedOptions('selected_sites')
      }
    }
  }));

  const postCrawlNotifications = () => {
    let selectedSiteIDs = []
    selectedRows.forEach(each => {
      selectedSiteIDs.push(each.id)
    })
    setLoading(true)
    if (selectedOptions === 'selected_sites'){
      axios.post(`/api/v1/organizations/${orgName}/website_notification_preferences`, {
        website_notification_preference: {
          selected_sites: selectedSiteIDs.toString(), request_from: 'site_list', site_options: 'selected_sites', 
          crawl_start_notification_preference: crawlNotifications.start ? 'notify_crawl_start' : 'dont_notify_crawl_start',
          crawl_end_notification_preference: crawlNotifications.completion ? 'notify_crawl_end' : 'dont_notify_crawl_end'
        },
        page_type: "client",
        controller_name: "website_notification_preferences"
      }).then(res => {
        toast.success(res.data.message)
        setModal(false)
        setLoading(false)
        window.location.reload()
      }).catch(err => {
        toast.error(err.response.data.message)
        setLoading(false)
      })
    }
    else if (selectedOptions === 'all'){
      axios.post(`/api/v1/organizations/${orgName}/website_notification_preferences`, {
        website_notification_preference: {
          request_from: 'site_list', site_options: 'all_sites', 
          crawl_start_notification_preference: crawlNotifications.start ? 'notify_crawl_start' : 'dont_notify_crawl_start',
          crawl_end_notification_preference: crawlNotifications.completion ? 'notify_crawl_end' : 'dont_notify_crawl_end'
        },
        page_type: "client",
        controller_name: "website_notification_preferences"
      }).then(res => {
        toast.success(res.data.message)
        setModal(false)
        setLoading(false)
        window.location.reload()
      }).catch(err => {
        toast.error(err.response.data.message)
        setLoading(false)
      })
    }
    else{
      axios.post(`/api/v1/organizations/${orgName}/website_notification_preferences`, {
        website_notification_preference: {
          request_from: 'site_list', site_options: selectedOptions, 
          crawl_start_notification_preference: crawlNotifications.start ? 'notify_crawl_start' : 'dont_notify_crawl_start',
          crawl_end_notification_preference: crawlNotifications.completion ? 'notify_crawl_end' : 'dont_notify_crawl_end'
        },
        page_type: "client",
        controller_name: "website_notification_preferences"
      }).then(res => {
        toast.success(res.data.message)
        setModal(false)
        setLoading(false)
        window.location.reload()
      }).catch(err => {
        setLoading(false)
        toast.error(err.response.data.message)
      })
    }
  }

  const closeBtn = <button className="close" 
    onClick={() => setModal(false)}> &times; </button>;

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop='static' size='lg'>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Set Crawl Notifications
        </ModalHeader>
        <ModalBody className='p-5'>
          <div>
            <div className='my-3 d-flex align-center'>
              <h6 className='me-2 fw-bold'>
                Sites:
              </h6>
              <select id="siteName" className='form-control show-arrow' value={selectedOptions}
                onChange={(e) => setSelectedOptions(e.target.value)}>
                <option value="selected_sites" disabled={selectedRows.length === 0}>
                  Selected sites
                </option>
                <option value='all'>All options</option>
                {sitegroupNames.map(each => (
                  <option key={each[0]} value={each[1]}>
                    Active sites of {each[1]}
                  </option>
                ))}
              </select>
            </div>
            <div className='my-5'>
              <FormGroup check className='my-3'>
                <Input type="checkbox" name='start'
                  checked={crawlNotifications.start}
                  onChange={(e) => setCrawlNotifications({...crawlNotifications, [e.target.name]: e.target.checked})} />
                {' '}
                <h6>Crawl Start Notification</h6>
              </FormGroup>
              <FormGroup check className='my-3'>
                <Input type="checkbox" name='completion'
                  checked={crawlNotifications.completion}
                  onChange={(e) => setCrawlNotifications({...crawlNotifications, [e.target.name]: e.target.checked})} />
                {' '}
                <h6>Crawl Completion Notification</h6>
              </FormGroup>
            </div>
            <a href={`/organizations/${orgName}/sites/crawl_notification_preferences`}
              className='text-primary mt-4 mb-2'>
              Edit Crawl Notification Preferences
            </a>
          </div>
        </ModalBody>
        <ModalFooter>
          {
            loading ?
            <>
              <Button className='primary mx-2' disabled>
                Loading ...
              </Button>
            </> :
            <>
              <Button color="primary mx-2" onClick={() => postCrawlNotifications()}>
                Save Preference
              </Button>
            </> 
          }
        </ModalFooter>
      </Modal>
      <Button className='mx-2' color="primary" onClick={toggle}> Set Crawl Notifications </Button>
    </>
  )

});

export {SetCrawlNotifications as default}
