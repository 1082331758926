import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Home } from 'react-feather';
import Loader from "react-loader-spinner";
import Select from "react-select";
import axios from "axios";

import ProxyWiseStats from './ProxyWiseStats';
import SiteWiseProxyStats from './SiteWiseProxyStats';
import ProxyMayNeedToChange from './ProxyMayNeedToChange';
import CpmMoreThanFifty from './CpmMoreThanFifty';
import FailingMoreThanEightyPercentage from './FailingMoreThanEightyPercentage';
import SuggestionsWarnings from './SuggestionsWarnings';

export default function Index() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const tabNameFromSearchUrl = new URLSearchParams(search).get("filter[tab_name]");
  const tabNameFromUrl = window.location.hash.substr(1);
  const searchParams = {
    weekNumber: new URLSearchParams(search).get("filter[week_number]"),
    tabName: tabNameFromSearchUrl ? tabNameFromSearchUrl : tabNameFromUrl
  }
  const [weekNumberOptions, setWeekNumberOptions] = useState([]);
  const [weekNumber, setWeekNumber] = useState({ label: searchParams.weekNumber, value: searchParams.weekNumber });
  const [activeTab, setActiveTab] = useState(tabNameFromUrl ? tabNameFromUrl : 'proxy_wise_stats');
  const [loading, setLoading] = useState(false);

  const [proxyWiseStatsData, setProxyWiseStatsData] = useState([]);
  const [siteWiseProxyStatsData, setSiteWiseProxyStatsData] = useState([]);
  const [proxyMayNeedToChangeData, setProxyMayNeedToChangeData] = useState([]);
  const [cpmMoreThanFiftyData, setCpmMoreThanFiftyData] = useState([]);
  const [failingMoreThanEightyPercentageData, setFailingMoreThanEightyPercentageData] = useState([]);
  const [suggestionsWarningsData, setSuggestionsWarningsData] = useState({});

  useEffect(() => {
    setSearchParamsData();
  }, []);

  function setSearchParamsData() {
    console.log("searchParams", searchParams);
    if (searchParams.tabName && searchParams.tabName != activeTab) {
      setActiveTab(searchParams.tabName);
    }
    if (commit == 'Get Data') {
      fetchDataWithParams();
    }
    else {
      fetchData();
    }
  }

  function fetchDataWithParams() {
    setLoading(true)
    axios.get(`/api/v1/internal/proxy_log_reports?filter[week_number]=${searchParams.weekNumber}`)
      .then(res => {
        setWeekNumberOptions(res.data.week_number_drop_down);
        setWeekNumber({ label: res.data.week_number, value: res.data.week_number });
        setProxyWiseStatsData(res.data.proxy_wise_stats_data);
        setSiteWiseProxyStatsData(res.data.site_wise_proxy_stats_data);
        setProxyMayNeedToChangeData(res.data.proxy_may_need_to_change_data);
        setCpmMoreThanFiftyData(res.data.cpm_more_than_fifty_data);
        setFailingMoreThanEightyPercentageData(res.data.failing_more_than_eighty_percentage_data);
        setSuggestionsWarningsData(res.data.suggestions_warnings_data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  function fetchData() {
    setLoading(true)
    axios.get(`/api/v1/internal/proxy_log_reports`)
      .then(res => {
        setWeekNumberOptions(res.data.week_number_drop_down);
        setWeekNumber({ label: res.data.week_number, value: res.data.week_number });
        setProxyWiseStatsData(res.data.proxy_wise_stats_data);
        setSiteWiseProxyStatsData(res.data.site_wise_proxy_stats_data);
        setProxyMayNeedToChangeData(res.data.proxy_may_need_to_change_data);
        setCpmMoreThanFiftyData(res.data.cpm_more_than_fifty_data);
        setFailingMoreThanEightyPercentageData(res.data.failing_more_than_eighty_percentage_data);
        setSuggestionsWarningsData(res.data.suggestions_warnings_data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }

  const handleGetData = () => {
    window.location.href = `/internal/proxy_log_reports?filter[week_number]=${weekNumber.value}&commit=${'Get Data'}`
  }

  // Helper function  to download log files
  function downloadFile(weekNumber, fileName, type = "csv") {
    axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem("authToken")}`;
    if (type === 'json') {
      axios.defaults.headers["Content-Type"] = `text/json`;
      axios.defaults.headers["Accept"] = `text/json`;
      axios({
        url: `/api/v1/internal/proxy_log_reports/download_data_file?weekNumber=${weekNumber}&fileName=${fileName}`,
        method: 'GET',
        responseType: 'blob'
      }).then((res) => {
        const url_to_download = window.URL.createObjectURL(new Blob([res.data]));
          const a_tag = document.createElement('a');
          a_tag.href = url_to_download;
          a_tag.setAttribute('download', `${fileName}.json`);
          document.body.appendChild(a_tag);
          a_tag.click();
      })
    }
    else {
      axios.defaults.headers["Content-Type"] = `text/csv`;
      axios.defaults.headers["Accept"] = `text/csv`;
      axios({
        url: `/api/v1/internal/proxy_log_reports/download_data_file?weekNumber=${weekNumber}&fileName=${fileName}`,
        method: 'GET',
        responseType: 'blob'
      })
        .then((res) => {
          const url_to_download = window.URL.createObjectURL(new Blob([res.data]));
          const a_tag = document.createElement('a');
          a_tag.href = url_to_download;
          a_tag.setAttribute('download', `${fileName}.csv`);
          document.body.appendChild(a_tag);
          a_tag.click();
        });
    }
  }

  // Helper function to download json file
  // THis method is invoked inside SuggestionsWarnings where the output format is json
  function downloadJSONFile(content, fileName) {
    const a = document.createElement("a");
    const file = new Blob([content], { type: 'text/json' });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }


  const failingMoreThanEightyPercentagetableColumns = [
    { name: 'Site Name', selector: 'site_name', sortable: true },
    { name: 'Proxy', selector: 'proxy', sortable: true },
    { name: 'Failed Percentage', selector: 'failed_percentage', sortable: true },
  ]

  return (
    <Container fluid>
      <div className="page-title">
        <Row className=''>
          <Col xs="6">
            <h3>Internal Dashboard</h3>
          </Col>
          <Col xs="6">
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={'/internal'}>
                  <Home />
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                {commit == 'Get Data' ? <a href="/internal/proxy_log_reports">Proxy log reports</a> : 'Proxy log reports'}
              </BreadcrumbItem>
              {commit == 'Get Data' && <BreadcrumbItem className="fw-bold">
                Search
              </BreadcrumbItem>
              }
            </Breadcrumb>
          </Col>
        </Row>
      </div>

      <Card>
        <CardBody>
          <Row>
            <Col sm="4">
              <label className="fw-bold">Week Number</label>
              <Select
                value={weekNumber}
                options={weekNumberOptions}
                onChange={option => setWeekNumber(option)} />
            </Col>
            <Col sm="4" className="mt-4">
              <label className="fw-bold mt-3"></label>
              <button className="btn btn-success w-100" onClick={handleGetData}>Get Data</button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Nav tabs>
            <NavItem>
              <NavLink className={activeTab == 'proxy_wise_stats' ? 'active' : ''} onClick={() => setActiveTab('proxy_wise_stats')}>
                Proxy wise stats
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={activeTab == 'site_wise_proxy_stats' ? 'active' : ''} onClick={() => setActiveTab('site_wise_proxy_stats')}>
                Site wise proxy stats
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={activeTab == 'proxy_may_need_to_change' ? 'active' : ''} onClick={() => setActiveTab('proxy_may_need_to_change')}>
                Proxy may need to change
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={activeTab == 'cpm_more_than_fifty' ? 'active' : ''} onClick={() => setActiveTab('cpm_more_than_fifty')}>
                CPM more than 50
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={activeTab == 'failing_more_than_eighty_percentage' ? 'active' : ''} onClick={() => setActiveTab('failing_more_than_eighty_percentage')}>
                Failing more than 80 percentage
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={activeTab == 'suggestions_warnings' ? 'active' : ''} onClick={() => setActiveTab('suggestions_warnings')}>
                Suggestions warnings
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={'proxy_wise_stats'}>
              <div className="panel-body">
                {
                  loading ?
                    <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
                    :
                    <ProxyWiseStats
                      proxy_wise_stats_data={proxyWiseStatsData}
                      weekNumber={weekNumber.value}
                      downloadCSVFile={downloadFile}
                    />
                }
              </div>
            </TabPane>
            <TabPane tabId={'site_wise_proxy_stats'}>
              <div className="panel-body">
                {
                  loading ?
                    <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
                    :
                    <SiteWiseProxyStats
                      site_wise_proxy_stats_data={siteWiseProxyStatsData}
                      weekNumber={weekNumber.value}
                      downloadCSVFile={downloadFile}

                    />
                }
              </div>
            </TabPane>
            <TabPane tabId={'proxy_may_need_to_change'}>
              <div className="panel-body">
                {
                  loading ?
                    <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
                    :
                    <ProxyMayNeedToChange
                      proxy_may_need_to_change_data={proxyMayNeedToChangeData}
                      weekNumber={weekNumber.value}
                      downloadCSVFile={downloadFile}
                    />
                }
              </div>
            </TabPane>
            <TabPane tabId={'cpm_more_than_fifty'}>
              <div className="panel-body">
                {
                  loading ?
                    <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
                    :
                    <CpmMoreThanFifty
                      cpm_more_than_fifty_data={cpmMoreThanFiftyData}
                      weekNumber={weekNumber.value}
                      downloadCSVFile={downloadFile}

                    />
                }
              </div>
            </TabPane>
            <TabPane tabId={'failing_more_than_eighty_percentage'}>
              <div className="panel-body">
                {
                  loading ?
                    <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
                    :
                    <FailingMoreThanEightyPercentage
                      failing_more_than_eighty_percentage_data={failingMoreThanEightyPercentageData}
                      weekNumber={weekNumber.value}
                      downloadCSVFile={downloadFile}
                    />
                }
              </div>
            </TabPane>
            <TabPane tabId={'suggestions_warnings'}>
              <div className="panel-body">
                {
                  loading ?
                    <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
                    :
                    <SuggestionsWarnings
                      data={suggestionsWarningsData}
                      weekNumber={weekNumber.value}
                      downloadJSONFile={downloadFile}
                    />
                }
              </div>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </Container>
  )
}
