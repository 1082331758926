import React, {useState, useEffect} from 'react'
import { Container, Row, Col } from 'reactstrap'
import axios from 'axios'
import AsyncSelect from 'react-select/async';
import DatePicker from 'react-datepicker'
import { toast } from 'react-toastify';

export default function DataApiAddMute() {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [selectedOption, setSelectedOption] = useState('')
    const [dropdownOptions, setDropdownOptions] = useState({})
    const [selectedSite, setSelectedSite] = useState({})
    const [selectedOperation, setSelectedOperation] = useState('')
    const [selectedCrawlFromDate, setSelectedCrawlFromDate] = useState('')
    const [selectedCrawlToDate, setSelectedCrawlToDate] = useState('')
    const [selectedFromDate, setSelectedFromDate] = useState('')
    const [selectedToDate, setSelectedToDate] = useState('')
    const [fileInput, setFileInput] = useState('')
    const [rssCrawlPushID, setRssCrawlPushID] = useState('')

    const today = new Date(Date.now())
    useEffect(() => {
        fetchSiteNames();
    }, [])

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('/');
    }
    
    function fetchSiteNames() {
        axios.get('/api/v1/internal/dropdown_list_helper')
        .then(res => {
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
            let group = {}, children = []
            group['label'] = <div className="fw-bold col text-black">{key}</div>
            site_names[key].map(item => {
                children.push({ label: item, value: item })
            })
            group['options'] = children;
            site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setDropdownOptions(site_names_options)
        })
        .catch(err => {
            console.error(err?.message || 'Oops something went wrong')
        })
    }
      
    const promiseOptions = (inputValue) => {
        if(inputValue.length < 3){
            return null
        } else {
            return new Promise((resolve) => {
            axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
                if (res.status == '200'){
                let site_names = res.data.site_names, site_names_options = []
                for (var key in site_names) {
                    let group = {}, children = []
                    group['label'] = <div className="fw-bold col text-black">{key}</div>
                    site_names[key].map(item => {
                    children.push({ label: item, value: item })
                    })
                    group['options'] = children;
                    site_names_options.push(group)
                }
                site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
                setDropdownOptions(site_names_options)
                resolve(site_names_options)
                }
            }).catch(err => {
                    console.log(`err`, err)
                })
            })
        }
    }

    const handleRadioBtn = (val) => {
        setSelectedOption(val)
    }

    const handleRadioBtn2 = (e, val) => {
        console.log('e, val', e.target, val)
        setSelectedOperation(val)
    }

    const handleSubmit = () => {
        setLoading(true)
        setErrors({})
        let tempObj = {}
        if (!(selectedSite?.value)){
            tempObj = {...tempObj, site:true}
        }
        if (selectedOperation?.length < 1){
            tempObj = {...tempObj, operation:true}
        }
        if (selectedOption?.length < 1){
            tempObj = {...tempObj, option:true}
        }
        if (selectedOption == 'Crawldate' && selectedCrawlFromDate?.toString()?.length < 1){
            tempObj = {...tempObj, crawl_from_date: true}
        }
        if (selectedOption == 'Crawldate' && selectedCrawlToDate?.toString()?.length < 1){
            tempObj = {...tempObj, crawl_to_date: true}
        }
        if (selectedOption == 'Date' && selectedFromDate?.toString()?.length < 1){
            tempObj = {...tempObj, from_date: true}
        }
        if (selectedOption == 'Date' && selectedToDate?.toString()?.length < 1){
            tempObj = {...tempObj, to_date: true}
        }
        if (selectedOption == 'Files' && fileInput?.length < 1){
            tempObj = {...tempObj, file_input: true}
        }
        if (selectedOption == 'RssCrawlPushId'){
            if (rssCrawlPushID?.length < 1){
                tempObj = {...tempObj, rss_crawl: 'This field is required'}
            }
            else if (isNaN(rssCrawlPushID)){
                tempObj = {...tempObj, rss_crawl: 'Please enter a valid number.'}
            }
            else if (rssCrawlPushID?.length < 14){
                tempObj = {...tempObj, rss_crawl: 'Rss crawl push id should be 14 characters'}
            }
        }
        setErrors(tempObj)

        if (Object.keys(tempObj)?.length == 0){
            let resultObj = {
                "outer_command": "data_api_add_mute_touch_unmute_files",
                "inner_command": "data_api_add_mute_touch_unmute_files",
                "nested_inner_command": "data_api_add_mute_touch_unmute_files",
                "sitename": selectedSite?.value, "input_type": selectedOption, "op": selectedOperation,
                "rss_crawl_push_id": selectedOption == 'RssCrawlPushId' ? rssCrawlPushID : 0,
                "file_name": selectedOption == 'Files' ? fileInput : '',
                "crawldate": {
                    from_crawldate: selectedOption == 'Crawldate' ? formatDate(selectedCrawlFromDate) : '',
                    to_crawldate: selectedOption == 'Crawldate' ? formatDate(selectedCrawlToDate) : ''
                },
                "date": {
                    from_date: selectedOption == 'Date'? formatDate(selectedFromDate) : '',
                    to_date: selectedOption == 'Date'? formatDate(selectedToDate) : '',
                }
            }
            axios.post('/api/v1/internal/command_dashboard/data_mute_unmute', {
                filter: resultObj
            }).then(res => {
                toast.success(res?.data?.message)
                setLoading(false)
                window.location = `/internal/command_dashboard`
            }).catch(err => {
                toast.error(err?.response?.data?.message)
                setLoading(false)
            })
        }
        else {
            setLoading(false)
        }
    }


    return (
        <Container>
            <Row>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Outer Command (Non-Editable)</h6>
                    <input className='form-control' value='data_api_add_mute_touch_unmute_files'
                        disabled />
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Inner Command (Non-Editable)</h6>
                    <input className='form-control' value='data_api_add_mute_touch_unmute_files'
                        disabled />
                </Col>
                <Col md={4} className='my-3'>
                    <h6 className='fw-bold'>Nested Inner Command (Non-Editable)</h6>
                    <input className='form-control' value='data_api_add_mute_touch_unmute_files'
                        disabled />
                </Col>

                <Col md={12} className='my-3'>
                    <h6 className='fw-bold'>Site Name*</h6>
                    <AsyncSelect cacheOptions defaultOptions={dropdownOptions} name='site_name' 
                        value={selectedSite} loadOptions={promiseOptions} onChange={res => setSelectedSite(res)}
                        placeholder="Select a site name" />
                    {errors?.site && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                    <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
                </Col>
                <Col md={12} className='my-3' >
                    <h6 className='fw-bold'>
                        Choose Dates or add File/File list or Enter Crawl Push Id
                    </h6>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="option"
                            checked={selectedOption == 'Crawldate'} 
                            onChange={() => handleRadioBtn('Crawldate')} id="option1" />
                        <label className="form-check-label" htmlFor="option1">
                            Operation based on Crawldate
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="option" 
                            checked={selectedOption == 'Date'}
                            onChange={() => handleRadioBtn('Date')} id="option2" />
                        <label className="form-check-label" htmlFor="option2">
                            Operation based on Date
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="option" 
                            checked={selectedOption == 'Files'}
                            onChange={() => handleRadioBtn('Files')} id="option3" />
                        <label className="form-check-label" htmlFor="option3">
                            Operation based on file/files
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="option" 
                            checked={selectedOption == 'RssCrawlPushId'}
                            onChange={() => handleRadioBtn('RssCrawlPushId')} id="option4" />
                        <label className="form-check-label" htmlFor="option4">
                            Operation based on Rss Crawl Push Id
                        </label>
                    </div>
                    {errors?.option && <h6 className='fw-bold text-danger my-2'> You must select an Input type </h6>}
                </Col>
                {selectedOption?.length > 0 && <Col md={12} className='my-3' >
                    {selectedOption == 'Crawldate' && <Row>
                        <Col md={6}>
                            <h6 className='fw-bold'>From Crawldate</h6>
                            <DatePicker className="form-control my-2" selected={selectedCrawlFromDate} dateFormat="yyyy/MM/dd"
                                maxDate={today} onChange={(date) => setSelectedCrawlFromDate(date)} />
                            {errors?.crawl_from_date && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                        </Col>    
                        <Col md={6}>
                            <h6 className='fw-bold'>To Crawldate</h6>
                            <DatePicker className="form-control my-2" selected={selectedCrawlToDate} dateFormat="yyyy/MM/dd"
                                maxDate={today} onChange={(date) => setSelectedCrawlToDate(date)} />
                            {errors?.crawl_to_date && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                        </Col>    
                    </Row>}

                    {selectedOption == 'Date' && <Row>
                        <Col md={6}>
                            <h6 className='fw-bold'>From date</h6>
                            <DatePicker className="form-control my-2" selected={selectedFromDate} dateFormat="yyyy/MM/dd"
                                maxDate={today} onChange={(date) => setSelectedFromDate(date)} />
                            {errors?.from_date && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                        </Col>    
                        <Col md={6}>
                            <h6 className='fw-bold'>To date</h6>
                            <DatePicker className="form-control my-2" selected={selectedToDate} dateFormat="yyyy/MM/dd"
                                maxDate={today} onChange={(date) => setSelectedToDate(date)} />
                            {errors?.to_date && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                        </Col> 
                    </Row>}

                    {selectedOption == 'Files' && <Row>
                        <Col md={12}>
                            <h6 className='fw-bold'>Add file/files ( comma(',') separated)</h6>
                            <input type='text' className='form-control' value={fileInput}
                                onChange={(e) => setFileInput(e.target.value)} />
                            {errors?.file_input && <h6 className='fw-bold text-danger my-2'> This field is required </h6>}
                        </Col>
                    </Row>}

                    {selectedOption == 'RssCrawlPushId' && <Row>
                        <Col md={12}>
                            <h6 className='fw-bold'>Rss Crawl Push Id</h6>
                            <input type='text' className='form-control' value={rssCrawlPushID}
                                onChange={(e) => setRssCrawlPushID(e.target.value)} />
                            {errors?.rss_crawl && <h6 className='fw-bold text-danger my-2'> {errors?.rss_crawl} </h6>}
                        </Col>
                    </Row>}
                </Col>}
                <Col md={12} className='my-3' >
                    <h6 className='fw-bold'>
                        Select Operation
                    </h6>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="operation"
                            checked={selectedOperation == 'mute'} 
                            onChange={(e) => handleRadioBtn2(e, 'mute')} id="operation1" />
                        <label className="form-check-label" htmlFor="operation1">
                            Mute
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="operation" 
                            checked={selectedOperation == 'unmute'}
                            onChange={(e) => handleRadioBtn2(e, 'unmute')} id="operation2" />
                        <label className="form-check-label" htmlFor="operation2">
                            Unmute
                        </label>
                    </div>
                    {errors?.operation && <h6 className='fw-bold text-danger my-2'> You must select an operation </h6>}
                </Col>
                <Col md={6} className='my-3'>
                    <button className='btn btn-success' onClick={handleSubmit}
                        disabled={loading} style={{margin: '25px auto', width: '100%'}}> Submit </button>
                </Col>
            </Row>
        </Container>
    )
}
