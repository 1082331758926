import React from 'react';
import { useMemo } from 'react';
import DataTable from 'react-data-table-component';

export default function SitegroupSchemaTable({ data }) {
  // Table column definitions
  const columns = useMemo(() => ([
    {
      name: 'Field Name',
      selector: 'field_name',
      sortable: true
    },
    {
      name: 'Data Type',
      selector: 'data_type',
      sortable: true,
    },
    {
      name: 'Is Mandatory',
      selector: 'is_mandatory',
      sortable: true
    },
    {
      name: 'Default Value',
      selector: 'default_value',
      sortable: true
    },
    {
      name: 'Sample Value',
      selector: 'sample_value',
      sortable: true
    },
    {
      name: 'Comments',
      selector: 'comments',
      sortable: true
    },
  ]), []);

  // Only display data when data is available 
  if (data?.length > 0) {
    return (

      <div>
        <DataTable
          data={data}
          columns={columns}
          pagination
          responsive
          noHeader />
      </div>

    )
  }
  else {
    return (null)
  }
}
