import React from 'react';
import { isEmpty } from 'lodash';
import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../../../global/Breadcrumb';
import { Container, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import LoadingAnimation from '../../../Common/LoadingAnimation/LoadingAnimation';
import InviteMembersButtonV2 from './Button/V2/InviteMembersButton.component';
import EditMembersDetailsButtonV2 from './Button/V2/EditMembersDetailsButton.component';
import RemoveMemberButton from './Button/RemoveMemberButton.component';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function ManageMembers({ current_user }) {
  // Fetching organization name from url
  const { organization_name } = useParams();
  // All the members that are active/currently allowed to access application
  const [activeMembers, setActiveMembers] = useState([]);
  // All the members that are removed from application
  const [removedMembers, setRemovedMembers] = useState([]);
  // Search value entered by user
  const [searchString, setSearchString] = useState('');
  // Search results when user searches for a member
  const [filteredData, setFilteredData] = useState([]);
  // Loading Animation
  const [loading, setLoading] = useState(true);
  // Column Definition for Active Members Table
  const activeMembersColumns = useMemo(() => [
    {
      name: "Name",
      selector: row => {
        if (row?.name?.trim() === '') {
          return 'N/A'
        }
        else if (row?.name?.trim() && row?.is_member_super_user) {
          return (<>
            <p>{row?.name}<br/><b>(Super User)</b></p>
          </>)
        }
        else {
          return <span>{row?.name}</span>
        }
      }
    },
    {
      name: "Email",
      selector: row => (row.email ? row.email : "N/A")
    },
    {
      name: "Accessible Modules",
      selector: row => (formatArray(row.accessible_modules)),
      center: true,
    },
    {
      name: "Notification Preference",
      selector: row => (formatArray(row.notification_preferences)),
    },
    {
      name: "Invited By",
      selector: row => (row.invited_by ? row.invited_by : "N/A")
    },
    {
      name: "Status",
      selector: row => (row.status ? (row.status) : "N/A")
    },

    {
      name: "Option",
      center: true,
      cell: row => (
        (current_user?.is_admin || current_user?.accessible_modules.includes("user_management"))
        && localStorage.getItem("authEmail") !== row?.email && (
          <div>
            {
              row.status !== 'Invitation Sent' && (
                <EditMembersDetailsButtonV2
                  key={'edit'}
                  color={"primary"}
                  size={"xs"}
                  children={"Edit"}
                  user={current_user}
                  organizationName={organization_name}
                  data={row} />
              )
            }
            <br />
            <RemoveMemberButton
              key={'remove'}
              color={"danger"}
              size={"xs"}
              children={"Remove"}
              organizationName={organization_name}
              data={row}
              user={current_user} />
          </div>
        )
      )
    },
  ], [current_user]);

  // Column definitions for removed members
  const removedMembersColumns = useMemo(() => [
    {
      name: "Name",
      selector: row => (row?.name?.trim() ? row.name : "N/A"),
    },
    {
      name: "Email",
      selector: row => (row?.email ? row.email : "N/A"),
    },
    {
      name: "Removed On",
      selector: row => (dateToYMD(new Date(row?.removed_at))),
    },
    {
      name: "Removed By",
      selector: row => (row?.removed_by_user ? row?.removed_by_user : "N/A"),
    }
  ], []);

  // use effect to set the static data displayed in the data-table component
  useEffect(async () => {
    const abortController = new AbortController();

    if (!_.isEmpty(current_user)) {
      setLoading(true);
      await fetchAllActiveMembersFromDB(`/api/v1/organizations/${organization_name}/user_invites?page_type=client&controller_name=user_invites`, abortController);
      await fetchAllRemovedMembersFromDB(`/api/v1/organizations/${organization_name}/members/removed_members?page_type=client&controller_name=members`, abortController);
      setLoading(false);
    }

    return () => {
      abortController.abort();
    }
  }, [current_user]);

  // use Effect for when user searches for individual in the data
  useEffect(() => {
    let filteredData = activeMembers.filter((member) => {
      return member?.name?.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||member?.email?.toLocaleLowerCase().includes(searchString.toLocaleLowerCase());
    });
    setFilteredData(filteredData);
  }, [searchString]);

  // Helper function to fetch data from the backend
  async function fetchAllActiveMembersFromDB(baseURI, abortController) {
    try {
      const response = await axios.get(baseURI, { signal: abortController.signal });
      const members = await response?.data?.members;
      const invited_members = await response?.data?.invited_members;
      setActiveMembers(members?.concat(invited_members));
    }
    catch (error) {
      toast.error(error.message);
    }
  };

  // Helper function to fetch data from the backend
  async function fetchAllRemovedMembersFromDB(baseURI, abortController) {
    try {
      const response = await axios.get(baseURI, { signal: abortController.signal });
      const removedMembers = await response?.data?.removed_members;
      setRemovedMembers(removedMembers);
    }
    catch (error) {
      toast.error(error.message);
    }
  };

  // Helper function to format the cells where data is in the form of array
  function formatArray(itemsArray) {
    if (itemsArray.length === 0) {
      return "N/A";
    }
    else {
      return itemsArray.map((item, index) => (
        index < itemsArray.length - 1 ?
          <span key={index} style={{ textTransform: "capitalize" }}>{item.split('_').join(' ') + ", "}</span>
          :
          <span key={index} style={{ textTransform: "capitalize" }}>{item.trim().split('_').join(' ')}</span>));
    }
  };

  // Helper function to format date
  function dateToYMD(date) {
    var strArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var d = date.getDate();
    var m = strArray[date.getMonth()];
    var y = date.getFullYear();
    return '' + (d <= 9 ? '0' + d : d) + '-' + m + '-' + y;
  };

  return (
    <>
      {
        _.isEmpty(current_user) || loading ? <LoadingAnimation /> :
          <Container fluid >
            <Breadcrumbs parent="Manage Members" title="Manage Members" />
            <div className='row'>
              <div className='col-md-7'>
              </div>
              <div className='col-md-5'>
                <div className='form-group my-3 d-flex align-items-center'>
                  <h6 className='m-0 pe-2 fw-bold'>Search: </h6> {"  "}
                  <input
                    type='text'
                    className='form-control'
                    name="search_member"
                    value={searchString}
                    placeholder='Search User'
                    onChange={(e) => setSearchString(e.target.value)} />
                </div>
              </div>
            </div>
            <Card>
              <CardHeader className='p-4 d-flex justify-content-between' >
                <Row>
                  <Col>
                    <h5 className='my-0 fw-light'>Active Members</h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {
                      (current_user?.is_admin || current_user?.accessible_modules.includes("user_management"))
                      &&
                      (<InviteMembersButtonV2 color="primary" size="sm" children="Invite Members" id={current_user?.id} organization={organization_name} />)
                    }
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <DataTable
                  noHeader
                  columns={activeMembersColumns}
                  data={filteredData.length ? filteredData : activeMembers}
                  pagination
                  paginationPerPage={10}
                />
              </CardBody>
            </Card>
            {
              removedMembers.length !== 0 && (
                <Card>
                  <CardHeader className='p-4 d-flex justify-content-between' >
                    <h5 className='my-0 fw-light'>Removed Members</h5>
                  </CardHeader>
                  <CardBody>
                    <DataTable
                      noHeader
                      columns={removedMembersColumns}
                      data={removedMembers}
                      pagination
                      paginationPerPage={10}
                    />
                  </CardBody>
                </Card>
              )
            }
          </Container>
      }
    </>
  )
}
