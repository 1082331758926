import React, {useState, useEffect} from 'react'
import { CardBody, Col, Card, Container, Row, Button } from 'reactstrap';
import Breadcrumb from '../../global/Breadcrumb'
import axios from 'axios'
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import DatePicker from 'react-datepicker'
import {useHistory} from 'react-router-dom'
import Select from 'react-select';

export default function NewCoupon() {
    const history = useHistory()
    const [errors, setErrors] = useState({});
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [loading, setLoading] = useState(true);
    const [organizationOptions, setOrganizationOptions] = useState([{
        value: 'All', label: 'All'
    }]);
    const [couponCode, setCouponCode] = useState('');
    const [AllowedOnOrganizations, setAllowedOnOrganizations] = useState([{
        value: 'All', label: 'All'
    }]);
    const [disableButton, setDisableButton] = useState(false)
    const [maxUsage, setMaxUsage] = useState('');
    const [couponType, setCouponType] = useState('fixed');
    const [discountValue, setDiscountValue] = useState('');
    const [selectedProduct, setSelectedProduct] = useState([{value: 'All', label: 'All'}]);
    const [selectedJpFrequency, setSelectedJpFrequency] = useState([{value: 'All', label: 'All'}])
    const [selectedJpPlans, setSelectedJpPlans] = useState([{value: 'All', label: 'All'}])
    const [newOrganization, setNewOrganization] = useState(false);
    const [selectedDataStock, setSelectedDataStock] = useState([{value: 'All', label: 'All'}]);
    const [selectedJobspikr, setSelectedJobspikr] = useState([{value: 'All', label: 'All'}]);
    const [selectedFrequency, setSelectedFrequency] = useState([{value: 'All', label: 'All'}]);
    const [selectedPermissions, setSelectedPermissions] = useState([{value: 'All', label: 'All'}]);
    const [productOptions, setProductOptions] = useState([])
    const [dataStockOptions, setDataStockOptions] = useState([])
    const [jobspikrOptions, setJobspikrOptions] = useState([])
    const [frequencyOptions, setFrequencyOptions] = useState([])
    const [allowedPlansOptions, setAllowedPlansOptions] = useState([])

    useEffect(() => {
        setLoading(true)
        axios.get(`/api/v2/admins/coupons/new`).then(res => {
            console.log('res.data', res.data)
            if (!(res.data?.coupon_authorized)){
                history.push(`/`)
            }

            setOrganizationOptions([{ value: 'All', label: 'All'}, ...res.data?.organizations])
            setProductOptions(res.data?.options?.all_products)
            setDataStockOptions(res.data?.options?.ds_product_categories?.map(each => ({value: each, label: each})))
            setJobspikrOptions(res.data?.options?.jp_product_categories?.map(each => ({value: each[1], label: each[0]})))
            setFrequencyOptions(res.data?.options?.jp_frequencies?.map(each => ({value: each[1], label: each[0]})))
            setAllowedPlansOptions(res.data?.options?.jp_current_plans?.map(each => ({value: each[1], label: each[0]})))
            setLoading(false)
        }).catch(err => {
            if (err.response.status == 401){
                history.push(`/`)
            }
            toast.error(err?.response?.data?.message)
            setLoading(false)
        })
    }, []);

    const today = new Date(Date.now())

    const handleSubmit = (e) => {
        e.preventDefault()
        setErrors({})
        let errorsObj = {}
        let selectedProductValues = []
        let selectedDataStockValues = []
        let selectedJobspikrValues = []
        let selectedFrequencyValues = []
        let selectedPermissionsValues = []
        let selectedJpFrequencyValues = []
        let selectedJpPlansValues = []

        selectedProduct.forEach(each => {
            selectedProductValues.push(each.value)
        })
        selectedDataStock.forEach(each => {
            selectedDataStockValues.push(each.value)
        })
        selectedJobspikr.forEach(each => {
            selectedJobspikrValues.push(each.value)
        })
        selectedFrequency.forEach(each => {
            selectedFrequencyValues.push(each.value)
        })
        selectedPermissions.forEach(each => {
            selectedPermissionsValues.push(each.value)
        })
        selectedJpFrequency.forEach(each => {
            selectedJpFrequencyValues.push(each.value)
        })
        selectedJpPlans.forEach(each => {
            selectedJpPlansValues.push(each.value)
        })

        if (selectedProductValues.includes('All') && selectedProductValues.length > 1){
            errorsObj = {...errorsObj, products: 'Please choose either All or other options'}
        }
        if (selectedProductValues.includes("data_stocks") && (selectedDataStockValues.includes('All') && selectedDataStockValues.length > 1)){
            errorsObj = {...errorsObj, data_stocks: 'Please choose either All or other options'}
        }
        if (selectedProductValues.includes("jobspikr") && 
            (selectedJobspikrValues.includes('All') && selectedJobspikrValues.length > 1)){
            errorsObj = {...errorsObj, jobspikr_product: 'Please choose either All or other options'}
        }
        if ((selectedProductValues.includes("jobspikr") && selectedJobspikrValues.includes("job_data")) && 
            (selectedFrequencyValues.includes('All') && selectedFrequencyValues.length > 1)){
            errorsObj = {...errorsObj, Allowed_frequencies: 'Please choose either All or other options'}
        }
        if ((selectedProductValues.includes("jobspikr") && selectedJobspikrValues.includes("job_data")) && 
            (selectedPermissionsValues.includes('All') && selectedPermissionsValues.length > 1)){
            errorsObj = {...errorsObj, Allowed_plans: 'Please choose either All or other options'}
        }
        if ((selectedProductValues.includes("jobspikr") && selectedJobspikrValues.includes("jobspikr_insights")) && 
            (selectedJpFrequencyValues.includes('All') && selectedJpFrequencyValues.length > 1)){
            errorsObj = {...errorsObj, jp_allowed_freq: 'Please choose either All or other options'}
        }
        if ((selectedProductValues.includes("jobspikr") && selectedJobspikrValues.includes("jobspikr_insights")) && 
            (selectedJpPlansValues.includes('All') && selectedJpPlansValues.length > 1)){
            errorsObj = {...errorsObj, jp_allowed_plans: 'Please choose either All or other options'}
        }
        if (couponCode?.trim()?.length === 0){
            errorsObj = {...errorsObj, couponCode: 'Please enter coupon code to continue'}
        }
        if (maxUsage?.trim()?.length === 0){
            errorsObj = {...errorsObj, maxUsage: 'Please enter 0 if there is no restriction'}
        }
        if (endDate?.toString().trim()?.length === 0){
            errorsObj = {...errorsObj, endDate: 'Please enter coupon expiry date'}
        }
        if (discountValue?.length === 0 || discountValue === 0.00){
            errorsObj = {...errorsObj, discountValue: 'Please enter valid discount value'}
        }
        else if (discountValue % 2 < 3){
            setDiscountValue(parseInt(discountValue).toFixed(2))
        }
        setErrors(errorsObj)
        
        if (Object.keys(errorsObj).length === 0){
            setDisableButton(true)
            let resObj1 = {}
            selectedDataStockValues = selectedDataStockValues.filter(each => each != 'All')
            if (selectedProductValues.includes("data_stocks") && selectedDataStockValues.filter(each => each != 'All').length > 0){
                resObj1 = {...resObj1, data_stocks: {
                    ...resObj1.data_stocks, product_categories: selectedDataStockValues
                }}
            }
            if (selectedProductValues.includes("jobspikr") && selectedJobspikrValues.filter(each => each != 'All').length > 0){
                resObj1 = {...resObj1, jobspikr: {
                    ...resObj1.jobspikr, product_categories: selectedJobspikrValues
                }}
            }
            if ((selectedProductValues.includes("jobspikr") && selectedJobspikrValues.includes("job_data")) 
                && selectedFrequencyValues.filter(each => each != 'All').length > 0){
                resObj1 = {...resObj1, jobspikr: {
                    ...resObj1.jobspikr, frequencies: selectedFrequencyValues
                }}
            }
            if ((selectedProductValues.includes("jobspikr") && selectedJobspikrValues.includes("job_data")) 
                && selectedPermissionsValues.filter(each => each != 'All').length > 0){
                resObj1 = {...resObj1, jobspikr: {
                    ...resObj1.jobspikr, plans: selectedPermissionsValues
                }}
            }
            if ((selectedProductValues.includes("jobspikr") && selectedJobspikrValues.includes("jobspikr_insights")) 
                && selectedJpFrequencyValues.filter(each => each != 'All').length > 0){
                resObj1 = {...resObj1, jobspikr: {
                    ...resObj1.jobspikr, jobspikr_insights_frequencies: selectedJpFrequencyValues
                }}
            }
            if ((selectedProductValues.includes("jobspikr") && selectedJobspikrValues.includes("jobspikr_insights")) 
                && selectedJpPlansValues.filter(each => each != 'All').length > 0){
                resObj1 = {...resObj1, jobspikr: {
                    ...resObj1.jobspikr, jobspikr_insights_plans: selectedJpPlansValues
                }}
            }

            let allowedOrganizationsValues = []
            AllowedOnOrganizations.forEach(each => {
                allowedOrganizationsValues.push(each.value)
            })
            allowedOrganizationsValues = allowedOrganizationsValues.filter(each => each != 'All')
            selectedProductValues = selectedProductValues.filter(each => each != 'All')
            
            
            axios.post(`/api/v2/admins/coupons`, { coupon: {
                coupon_code: couponCode, discount_type: couponType,
                discount_value: discountValue, max_usage_per_org: maxUsage, 
                start_at: startDate, expire_at: endDate, allowed_on_organizations_ids: allowedOrganizationsValues,
                allowed_on_products: selectedProductValues, for_new_org_only: newOrganization, allowed_product_categories: resObj1
            }}).then(res => {
                toast.success(res.data?.message)
                history.push('/admins/coupons')
                setDisableButton(false)
            }).catch(err => {
                toast.error(err.response.data.message)
                setDisableButton(false)
            })
        }
    }

    const checkIsSelected = (array, string) => {
        let result = false
        array.forEach(eachObj => {
            if (eachObj.value === string){
                result = true
            }
        })
        return result
    }

    return <Container fluid>
        <Breadcrumb title={"New Coupon"} parent={"Discount Coupon"}
            childrenObj={[{name: 'Coupons', url: '/admins/coupons'}]} />
        
            <Card>
                <CardBody>
                    {loading 
                    ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> 
                    : <form onSubmit={handleSubmit}>
                        <Row className='my-4'>
                            <Col sm={3}>
                                <h6 className='fw-bold my-2 float-end'>Coupon Code*</h6>
                            </Col>
                            <Col sm={9}>
                                <input type="text" className='form-control' name="coupon_code" placeholder='required' 
                                   value={couponCode} onChange={(e) => setCouponCode(e.target.value)}/>
                                {errors?.couponCode && <p className='text-danger'> {errors?.couponCode} </p>}
                            </Col>
                        </Row>
                        <Row className='my-4'>
                            <Col sm={3}>
                                <h6 className='fw-bold my-2 float-end'>Allowed on Organizations</h6>
                            </Col>
                            <Col sm={9}>
                                <Select value={AllowedOnOrganizations} classNamePrefix="select" 
                                  isMulti options={organizationOptions} className="basic-multi-select allowed_on_org"
                                  onChange={(res) => setAllowedOnOrganizations(res)} />
                                <p className='text-muted'>If nothing is selected it will be applied to everything</p>
                            </Col>
                        </Row>
                        <Row className='my-4'>
                            <Col sm={3}>
                                <h6 className='fw-bold my-2 float-end'>Max usage per Organization*</h6>
                            </Col>
                            <Col sm={9}>
                              <input type="text" className='form-control' name="max_usage_per_org" 
                                placeholder='if there is no restriction enter 0' 
                                   value={maxUsage} onChange={(e) => setMaxUsage(e.target.value)}/>
                                <p className='text-muted my-0'>if there is no restriction enter 0</p>
                                {errors?.maxUsage && <p className='text-danger'> {errors?.maxUsage} </p>}
                            </Col>
                        </Row>
                        <Row className='my-4'>
                            <Col sm={3}>
                                <h6 className='fw-bold my-2 float-end'>Products</h6>
                            </Col>
                            <Col sm={9}>
                                <Select value={selectedProduct} classNamePrefix="select" 
                                    isMulti options={productOptions} className="basic-multi-select products" 
                                    onChange={(res) => setSelectedProduct(res)} />
                                <p className='text-muted my-0'>If nothing is selected it will be applied to everything</p>
                                {errors.products && <p className='text-danger'>{errors.products}</p>}
                            </Col>
                        </Row>
                        {checkIsSelected(selectedProduct, 'data_stocks') && <Row className='my-4'>
                            <Col sm={3}>
                                <h6 className='fw-bold my-2 float-end'>DataStock Product Categories</h6>
                            </Col>
                            <Col sm={9}>
                                <Select value={selectedDataStock} classNamePrefix="select" 
                                    isMulti options={dataStockOptions} className="basic-multi-select datastock_product_categories" 
                                    onChange={(res) => setSelectedDataStock(res)} />
                                <p className='text-muted my-0'>If nothing is selected it will be applied to everything</p>
                                {errors.data_stocks && <p className='text-danger'>{errors.data_stocks}</p>}
                            </Col>
                        </Row>}
                        {checkIsSelected(selectedProduct, 'jobspikr') && <Row className='my-4'>
                            <Col sm={3}>
                                <h6 className='fw-bold my-2 float-end'>Jobspikr Product Categories</h6>
                            </Col>
                            <Col sm={9}>
                                <Select value={selectedJobspikr} classNamePrefix="select" 
                                    isMulti options={jobspikrOptions} className="basic-multi-select jobspikr_product_categories" 
                                    onChange={(res) => setSelectedJobspikr(res)} />
                                <p className='text-muted my-0'>If nothing is selected it will be applied to everything</p>
                                {errors?.jobspikr_product && <p className='text-danger'> {errors?.jobspikr_product} </p>}
                            </Col>
                        </Row>}
                        {( checkIsSelected(selectedProduct, 'jobspikr') 
                            && checkIsSelected(selectedJobspikr, 'job_data')) && <Row className='my-4'>
                            <Col sm={3}>
                                <h6 className='fw-bold my-2 float-end'>Allowed on Frequencies</h6>
                            </Col>
                            <Col sm={9}>
                                <Select value={selectedFrequency} classNamePrefix="select" 
                                    isMulti options={frequencyOptions} className="basic-multi-select allowed_on_frequencies" 
                                    onChange={(res) => setSelectedFrequency(res)} />
                                <p className='text-muted my-0'>If nothing is selected it will be applied to everything</p>
                                {errors?.Allowed_frequencies && <p className='text-danger'> {errors?.Allowed_frequencies} </p>}
                            </Col>
                        </Row>}
                        {(checkIsSelected(selectedProduct, 'jobspikr') && 
                            checkIsSelected(selectedJobspikr, 'job_data')) && <Row className='my-4'>
                            <Col sm={3}>
                                <h6 className='fw-bold my-2 float-end'>Allowed on Plans</h6>
                            </Col>
                            <Col sm={9}>
                                <Select value={selectedPermissions} classNamePrefix="select" 
                                    isMulti options={allowedPlansOptions} className="basic-multi-select allowed_on_plans" 
                                    onChange={(res) => setSelectedPermissions(res)} />
                                <p className='text-muted my-0'>If nothing is selected it will be applied to everything</p>
                                {errors?.Allowed_plans && <p className='text-danger'> {errors?.Allowed_plans} </p>}
                            </Col>
                        </Row>}
                        {(checkIsSelected(selectedProduct, 'jobspikr') && 
                            checkIsSelected(selectedJobspikr, 'jobspikr_insights')) && <Row className='my-4'>
                            <Col sm={3}>
                                <h6 className='fw-bold my-2 float-end'>Allowed on Jp Insight Frequencies</h6>
                            </Col>
                            <Col sm={9}>
                                <Select value={selectedJpFrequency} classNamePrefix="select" 
                                    isMulti options={frequencyOptions} className="basic-multi-select allowed_on_jp_insight_frequencies" 
                                    onChange={(res) => setSelectedJpFrequency(res)} />
                                <p className='text-muted my-0'>If nothing is selected it will be applied to everything</p>
                                {errors?.jp_allowed_freq && <p className='text-danger'> {errors?.jp_allowed_freq} </p>}
                            </Col>
                        </Row>}
                        {(checkIsSelected(selectedProduct, 'jobspikr') && 
                            checkIsSelected(selectedJobspikr, 'jobspikr_insights')) && <Row className='my-4'>
                            <Col sm={3}>
                                <h6 className='fw-bold my-2 float-end'>Allowed on JP Insights Plans</h6>
                            </Col>
                            <Col sm={9}>
                                <Select value={selectedJpPlans} classNamePrefix="select" 
                                    isMulti options={allowedPlansOptions} className="basic-multi-select allowed_on_jp_insights_plans" 
                                    onChange={(res) => setSelectedJpPlans(res)} />
                                <p className='text-muted my-0'>If nothing is selected it will be applied to everything</p>
                                {errors?.jp_allowed_plans && <p className='text-danger'> {errors?.jp_allowed_plans} </p>}
                            </Col>
                        </Row>}
                        
                        <Row className='my-4'>
                            <Col sm={3}>
                                <h6 className='fw-bold my-2 float-end'>Coupon Type</h6>
                            </Col>
                            <Col sm={9}>
                                <select className='form-control' name="coupon_type" value={couponType} onChange={(e) => setCouponType(e.target.value)}>
                                    <option value="fixed">Fixed</option>
                                    <option value="percent">Percent</option>
                                </select>
                            </Col>
                        </Row>
                        <Row className='my-4'>
                            <Col sm={3}>
                                <h6 className='fw-bold my-2 float-end'>Discount Value*</h6>
                            </Col>
                            <Col sm={9}>
                                <input className="form-control"type="text" name="discount_value"
                                    value={discountValue} onChange={(e) => setDiscountValue(e.target.value)} />
                                {errors?.discountValue && <p className='text-danger'> {errors?.discountValue} </p>}
                            </Col>
                        </Row>
                        <Row className='my-4'>
                            <Col sm={3}>
                                <h6 className='fw-bold my-2 float-end'>Allow only for new Org</h6>
                            </Col>
                            <Col sm={9}>
                                <input className="form-check form-checkbox-align-top" name="allow_only_for_new_org" type="checkbox" checked={newOrganization}
                                    onChange={(e) => setNewOrganization(e.target.checked)} />
                            </Col>
                        </Row>
                        <Row className='my-4'>
                            <Col sm={3}>
                                <h6 className='fw-bold my-2 float-end'>Start At*</h6>
                            </Col>
                            <Col sm={9}>
                                <DatePicker className="form-control" name="start_at" minDate={today} selected={startDate}
                                    onChange={(date) => setStartDate(date)} />
                            </Col>
                        </Row>
                        <Row className='my-4'>
                            <Col sm={3}>
                                <h6 className='fw-bold my-2 float-end'>Expire At*</h6>
                            </Col>
                            <Col sm={9}>
                                <DatePicker className="form-control" name="expire_at" minDate={startDate} selected={endDate}
                                    onChange={(date) => setEndDate(date)} />
                                {errors?.endDate && <p className='text-danger'> {errors?.endDate} </p>}
                            </Col>
                        </Row>
                        <Button color='success' className='float-end' type='submit'
                            disabled={disableButton}>
                            Submit
                        </Button>
                    </form>}
                </CardBody>
            </Card>
    </Container>;
}
