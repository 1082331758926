import React, {useState, useEffect, useContext} from 'react'
import { CardBody, Col, Card, Container, Row, CardHeader } from 'reactstrap';
import Breadcrumb from '../../global/Breadcrumb'
import axios from 'axios'
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import {useParams, useHistory} from 'react-router-dom'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { xcode } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { OrgnaziationContext } from '../../Home';

export default function OrgShow() {
    let { organization_name } = useParams();

    const {setOrgName} = useContext(OrgnaziationContext) 

    useEffect(() => {
        setOrgName(organization_name)
    }, [organization_name])

    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const [toggle, setToggle] = useState(false)

    useEffect(() => {
        setLoading(true)
        axios.get(`/api/v1/admins/organizations/${organization_name}/organization_show`).then(res => {
            setLoading(false)
            setData(res.data)
        }).catch(err => {
            toast.error(err.response?.data?.message)
            setLoading(false)
        })
    }, [toggle])

    const codeString1 = `
{
    "id": ${data?.api_data?.id},
    "client_name": ${data?.api_data?.client_name},
    "client_id": ${data?.api_data?.client_id},
    "client_auth_key": ${data?.api_data?.client_auth_key},
    "client_status": ${data?.api_data?.client_status},
    "created_at": ${data?.api_data?.created_at},
    "updated_at": ${data?.api_data?.updated_at},
    "client_api_url": ${data?.url}
}
`
    
    const toggleStatus = () => {
        axios.post(`/api/v1/admins/organizations/${organization_name}/organization_show/update_details`).then(res => {
            console.log('res.data', res.data)
            toast.success(res.data.message)
            setToggle(curr => !curr)
        }).catch(err => {
            toast.error(err.response.data.message)
        })
    }

    return (
        <Container fluid>
            <Breadcrumb parent={`${organization_name} API Details`} title={"Organization"} />
            { loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> :
            data.url && 
            <Card>
                <CardHeader>
                    {data?.api_data?.client_status == '1' && <div className='float-end d-flex'>
                        <p>Disable Client Status</p>
                        {/* <button className='btn btn-danger mx-2' onClick={toggleStatus}>Disable</button> */}
                    </div>}
                    {data?.api_data?.client_status == '0' && <div className='float-end d-flex'>
                        <p>Enable Client Status</p>
                        {/* <button className='btn btn-success mx-2' onClick={toggleStatus}>Enable</button> */}
                    </div>}
                </CardHeader>
                <CardBody>
                    <div className='bg-light text-dark'>
                        <SyntaxHighlighter language="javascript" style={xcode}>
                            {codeString1}
                        </SyntaxHighlighter>
                    </div>
                </CardBody>
            </Card>
            }
        </Container>
    )
}
