import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Home } from "react-feather";
import Loader from "react-loader-spinner";
import { Link, useLocation } from "react-router-dom";
import { Container, Col, Row, Breadcrumb, BreadcrumbItem, Card, CardBody } from "reactstrap";


export default function FreshnessCheckStats() {
    const search = useLocation().search;
    const commit = new URLSearchParams(search).get("commit");
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([])
    const [loading,setLoading]=useState(false);

    useEffect(() => {
        setLoading(true)
        axios.get(`/api/v1/jobspikr_v3_internal/jobspikr_graphs/freshness_check_stats`)
            .then(res => {
                setData(res.data.data);
                let hash = {},column=[]
                column.push({name: "Stats Type", selector: "stats_type", sortable: true, center: true, grow: 2})
                res.data.dates.forEach(item => {
                    hash = {}
                    hash["name"]=item
                    hash["sortable"]=true
                    hash["center"]=true
                    hash["cell"]=row =><div><span className="fw-bold">{row[item]}</span> <br />{row[`${item}_percentage`]}</div>
                    column.push(hash)
                })
                setColumns(column)
                setLoading(false)
            })
            .catch(err =>{
                console.error(err?.message || 'Oops something went wrong')
                setLoading(false);
            })
    }, [])

    return <Container fluid>
        <div className="page-title">
            <Row>
                <Col xs="6" className="flex-column">
                    <h4>Internal Dashboard</h4>

                </Col>
                <Col xs="6">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={'/internal'}>
                                <Home />
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            Internal QA
                        </BreadcrumbItem>
                        <BreadcrumbItem className="fw-bold">
                            Freshness check stats
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
       {loading ?  <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> :<Card>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={data} />
            </CardBody>
        </Card>}
    </Container>
}