import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Container, Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import DataTable from "react-data-table-component";
import { useLocation, Link } from "react-router-dom";
import { Home } from "react-feather";
import AsyncSelect from 'react-select/async';
import Select, { components } from "react-select";

import { KIBANA_PLM_URL } from "../../Common/KibanaAPIEndpoints/KibanaAPIEndpoints";

export default function RunForceSegmentStatus() {
  const search = useLocation().search;
  const commit = new URLSearchParams(search).get("commit");
  const searchParams = {
    siteName: new URLSearchParams(search).get("filter[site_name]"),
  }
  const [siteName, setSiteName] = useState({});
  const [siteNameOptions, setSiteNameOptions] = useState([]);
  const [runforceSegmentStatusData, setRunforceSegmentStatusData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSearchParamsData();
    fetchSiteNames();
  }, []);

  function setSearchParamsData() {
    if (searchParams.siteName) {
      setSiteName({ label: searchParams.siteName, value: searchParams.siteName })
    }
    if (commit == 'Get Data' && searchParams.siteName) {
      fetchData(searchParams.siteName)
    }
  }

  const promiseOptions = (inputValue) => {
    if(inputValue.length < 3){
      return null
    } else {
      return new Promise((resolve) => {
        axios.get(`/api/v1/internal/search_site_names?site_name=${inputValue}`).then(res => {
          if (res.status == '200'){
            console.log(res.data)
            let site_names = res.data.site_names, site_names_options = []
            for (var key in site_names) {
              let group = {}, children = []
              group['label'] = <div className="fw-bold col text-black">{key}</div>
              site_names[key].map(item => {
                children.push({ label: item, value: item })
              })
              group['options'] = children;
              site_names_options.push(group)
            }
            site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
            setSiteNameOptions(site_names_options)
            resolve(site_names_options)
          }
        }).catch(err => {
          console.log(`err`, err)
        })
      })
    }
  }

  const handleSiteNameChange = (data) => {
    setSiteName(data)
  }

  function fetchSiteNames() {
    axios.get('/api/v1/internal/dropdown_list_helper')
      .then(res => {
        let site_names = res.data.site_names, site_names_options = []
        for (var key in site_names) {
          let group = {}, children = []
          group['label'] = <div className="fw-bold col text-black">{key}</div>
          site_names[key].map(item => {
            children.push({ label: item, value: item })
          })
          group['options'] = children;
          site_names_options.push(group)
        }
        site_names_options.push({ label: <div className="fw-bold text-black">All</div>, options: [{ label: 'All', value: 'all' }] })
        setSiteNameOptions(site_names_options)
      })
      .catch(err => {
        console.error(err?.message || 'Oops something went wrong')
      })
  }

  function fetchData(SiteName = siteName.value) {
    setLoading(true)
    axios.get(`/api/v1/internal/runforce_segment_status?filter[site_name]=${SiteName}`)
      .then(res => {
        setRunforceSegmentStatusData(res.data?.runforce_segment_status_data)
        setLoading(false);
      })
      .catch(err => {
        console.error(err?.response?.data?.message)
        setLoading(false);
      });
  }

  const handleGetData = () => {
    window.location.href = `/internal/runforce_segment_status?filter[site_name]=${siteName.value}&commit=${'Get Data'}`
  }

  const runforceSegColumns = [
    {
      name: 'Batch ID',
      selector: 'batch_id',
      button: true,
      cell: row => (
        <a href={`${KIBANA_PLM_URL}app/kibana#/discover?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-30d,to:now))&_a=(columns:!(category,runforce_segment_status,batch_id,hostname,document_id),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'76972760-1ad0-11ec-9735-177ab3888d9e',key:msg,negate:!f,params:(query:runforce_segment_tracker),type:phrase),query:(match:(msg:(query:runforce_segment_tracker,type:phrase))))),index:'76972760-1ad0-11ec-9735-177ab3888d9e',interval:auto,query:(language:kuery,query:'batch_id+:+%22` + row.batch_id + "%22'),sort:!(!('@timestamp',desc)))"} target="_blank" rel="noopener noreferrer">
          {row.batch_id}
        </a>
        ),
    },
    { name: 'Runforce Timestamp', selector: 'runforce_timestamp' },
    { name: 'Uploaded Segments Count Before Runforce', selector: 'uploaded_segments_count_before_runforce' },
    { name: 'From Date', selector: 'from_date' },
    { name: 'To Date', selector: 'to_date' },
    { name: 'Runforce Percentage', selector: 'runforce_percentage' },
    { name: 'Pipeline Stage Status', selector: 'pipeline_stage_status' },
    {
      name: 'Current Status',
      button: true,
      cell: row => (
        <a href={`${KIBANA_PLM_URL}/app/kibana#/visualize/create?type=table&indexPattern=76972760-1ad0-11ec-9735-177ab3888d9e&_g=(refreshInterval:(pause:!t,value:0),time:(from:now-30d,to:now))&_a=(filters:!(),linked:!f,query:(language:kuery,query:'batch_id+:+%22` + row.batch_id + "%22'),uiState:(vis:(params:(sort:(columnIndex:!n,direction:!n)))),vis:(aggs:!((enabled:!t,id:'1',params:(),schema:metric,type:count),(enabled:!t,id:'2',params:(customLabel:status,field:runforce_segment_status.raw,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:555),schema:bucket,type:terms),(enabled:!t,id:'3',params:(customLabel:'+',field:category.raw,missingBucket:!t,missingBucketLabel:Missing,order:asc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,row:!f,size:50),schema:split,type:terms)),params:(dimensions:(buckets:!((accessor:0,aggType:terms,format:(id:terms,params:(id:string,missingBucketLabel:Missing,otherBucketLabel:Other)),params:()),(accessor:2,aggType:terms,format:(id:terms,params:(id:string,missingBucketLabel:Missing,otherBucketLabel:Other)),params:())),metrics:!((accessor:3,aggType:count,format:(id:number),params:())),splitColumn:!((accessor:1,aggType:terms,format:(id:terms,params:(id:string,missingBucketLabel:Missing,otherBucketLabel:Other)),params:()))),perPage:10,percentageCol:'',showMetricsAtAllLevels:!f,showPartialRows:!f,showTotal:!f,sort:(columnIndex:!n,direction:!n),totalFunc:sum),title:'',type:table))"} target="_blank" rel="noopener noreferrer">
          Open
        </a>
        ),
    },
  ];

  return (
    <Container fluid>
      <Container fluid>
        <div className="page-title">
          <Row className=''>
            <Col xs="6">
              <h3>Internal Dashboard</h3>
            </Col>
            <Col xs="6">
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to={`/internal`}><Home /></Link>
                </BreadcrumbItem>

                <BreadcrumbItem className={commit == 'Get Data' ? '' : 'fw-bold'} >
                  {commit == 'Get Data' ? <a href={'/internal/runforce_segment_status'}> Runforce Segment Status</a> : ' Runforce Segment Status'}
                </BreadcrumbItem>
                {commit == 'Get Data' && <BreadcrumbItem>
                  <b>Search</b>
              </BreadcrumbItem>}
            </Breadcrumb>
          </Col>
        </Row>
      </div>
    </Container>
    <Card>
      <CardBody>
        <Row className="align-items-end">
          <div className="col-sm-10">
            <label className="fw-bold">Site Name</label>
            <AsyncSelect cacheOptions defaultOptions={siteNameOptions} name='site_name' value={siteName}
              loadOptions={promiseOptions} onChange={handleSiteNameChange}
              placeholder="Select a site name"
            />
            <p><i>Please type atleast 3 characters while searching the sitename!</i></p>
          </div>
          <div className="col-sm-2">
            <button className="btn btn-success w-100 p-2" onClick={handleGetData}>Get Data</button>
            <p>&nbsp;</p>
          </div>
        </Row>
      </CardBody>
    </Card>
    {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> : <Fragment>
      {runforceSegmentStatusData.length != 0 ?
      <Card>
        <CardBody>
          <DataTable
            data={runforceSegmentStatusData}
            columns={runforceSegColumns} />
        </CardBody>
      </Card>
        : ""
      }
    </Fragment>}
  </Container>
  )
}
