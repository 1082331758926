import React, { useEffect, useState, createContext, useMemo } from 'react'
import { toast } from 'react-toastify'
import { userLoggedin } from './localStorageMethods'
import { useHistory } from "react-router-dom";
import Sidebar from './sidebar'
import Header from './header';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Admin from './admin-components/Admin.js'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CrawlNotificationPreferences from './home-components/sites/CrawlNotificationPreferences';
import MergeAndUpload from './home-components/dataaccess/MergeAndUpload';
import EditOrganization from './home-components/profile-pages/EditOrganization';
import ProfilePageV2 from './home-components/profile-pages/V2/Profile/index';
import Feedback from './users-components/Feedback';
import ApiDetails from './home-components/dataaccess/ApiDetails';
import Webhooks from './home-components/dataaccess/Webhooks';
import ManageMembers from './home-components/profile-pages/ManageMembers/ManageMembers.component';
import FAQ from './static-components/FAQ';
import Help from './static-components/Help';
import NotificationPage from './home-components/profile-pages/NotificationPage';
import Footer from './Footer';
import axios from 'axios';
import Loader from "react-loader-spinner";
import MyComponents from './my-components/MyComponents';
import OrgShow from './admin-components/organizations/OrgShow';
import WebhookDashboard from './home-components/dataaccess/WebhookDashboard';

/* import ES Pages */
import EsPageAllSites from './home-components/es_pages/sites/AllSites';
import EsPageHomepage from './home-components/es_pages/Home/Home.index';
import EsPageDataDownload from './home-components/es_pages/DataAccess/DataDownload/DataDownload.index';

/* Refactored V2 Pages */
import AddNewSitegroupBackup from './V2/ClientPages/AddNewSitegroup/AddNewSitegroupBackup.index';
import AddNewSitesToSiteGroup from './V2/ClientPages/AddNewSites/AddNewSitesToSiteGroup.component'
import AddNewSitegroup from './V2/ClientPages/AddNewSitegroup/AddNewSitegroup.index'
import FeasibilityReports from './V2/ClientPages/FeasibilityReports/FeasibilityReports.index';
import ClientFeasibilityReportSummary from './V2/ClientPages/FeasibilityReports/ClientFeasibilityReportSummary/ClientFeasibilityReportSummary.component';
import ClientUserAgreement from './V2/ClientPages/FeasibilityReports/ClientUserAgreement/ClientUserAgreement.component';
import ClientBillingDetailsPage from './V2/ClientPages/FeasibilityReports/ClientBillingDetails/ClientBillingDetailsPage.component';
import Billing from './V2/ClientPages/Billing/Billing.index';
import InvoiceDetails from './V2/ClientPages/Billing/InvoiceDetails/InvoiceDetails.component';
import ListAvailableCards from './V2/ClientPages/Cards/ListAvailableCards/ListAvailableCards.component';
import AddNewCard from './V2/ClientPages/Cards/AddNewCard/AddNewCard.component';
import MakePayment from './V2/ClientPages/MakePayment/MakePayment.index';
import OrganizationDetails from './V2/ClientPages/Organization/Organization.index';
import IssueList from './V2/ClientPages/Issues/Issues.index.jsx';
import IssueShow from './V2/ClientPages/Issues/TicketDetails/TicketDetails.component';

const OrgnaziationContext = createContext({
  orgName: '', setOrgName: () => { },
});

export default function Home() {
  let history = useHistory();
  const { path } = useRouteMatch();
  const [bannerNotifications, setBannerNotifications] = useState([])
  const [stripeToken, setStripeToken] = useState('')
  const [razorpayKey, setRazorpayKey] = useState('');
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState({})
  const [organization, setOrganization] = useState({})
  const [mainOrgName, setMainOrgName] = useState('')
  const [orgName, setOrgName] = useState('')
  const [environment, setEnvironment] = useState('')

  const value = useMemo(() => {
    if (orgName !== '') {
      axios.get(`/api/v1/my_organization?url_org_identifier=${orgName}&page_type=client&controller_name=dashboard`).then(res => {
        setMainOrgName(res.data?.organization?.org_identifier)
        setEnvironment(res.data?.organization?.current_environment)
        if (orgName !== res.data?.organization?.current_organization?.org_identifier) {
          history.push('/not-found')
          setOrgName(mainOrgName)
        }
      }).catch(error => {
        if (error.response.data.message === "Authorization token has expired") {
          handleLogout()
        }
        else if (error.response.status == 500) {
          history.push("/server-error")
          setOrgName(mainOrgName)
        }
        else {
          history.push('/not-found')
          setOrgName(mainOrgName)
        }
      })
    } else {
      setOrgName(mainOrgName)
    }
    return { orgName, setOrgName }
  }, [orgName]);

  useEffect(() => {
    if (orgName === '') {
      setOrgName(mainOrgName)
    }
  }, [mainOrgName])

  useEffect(() => {
    if (!userLoggedin()) {
      sessionStorage.setItem('redirectUrl', window.location.href)
      history.push('/users/sign_in')
    }
  }, [])

  const token = localStorage.getItem("authToken");
  const email = localStorage.getItem("authEmail");

  useEffect(() => {
    if (token && email) {
      axios.defaults.headers["X-Auth-Email"] = email;
      axios.defaults.headers["X-Auth-Token"] = token;
      axios.defaults.headers["Authorization"] = `Bearer ${token}`;
      axios.get(`/api/v1/user/details?email=${email}&page_type=client&controller_name=dashboard`).then(res => {
        setUser(res.data.user)
      }).catch(err => {
        if (err.response.data.message === "User is not present!") {
          handleLogout()
        }
      })
    }

  }, [token, email])

  useEffect(() => {
    axios.get(`/api/v1/app_status?page_type=client&controller_name=dashboard`).then(res => {
      if (res?.data?.app_status === "maintenance") {
        window.location.replace(res?.data?.maintain_url)
      }
      setLoading(false)
    }).catch(err => {
      setLoading(false)
    })
  }, [])

  const handleLogout = () => {
    axios.delete(`/api/v1/users/logout?page_type=client&controller_name=sessions`, {
      user: {
        email: localStorage.getItem("authEmail"),
        authenticity_token: localStorage.getItem("authToken")
      }
    }).then(res => {
      toast.success(res.data?.message)
    }).catch(err => {
      console.log('err', err);
    })
    localStorage.removeItem('authEmail');
    localStorage.removeItem('authToken');
    localStorage.removeItem('user_name');
    history.push("/users/sign_in")
  }

  useEffect(() => {
    setLoading(true)
    if (localStorage.getItem("authToken") && localStorage.getItem("authEmail")) {
      axios.get(`/api/v1/my_organization?page_type=client&controller_name=dashboard`).then(res => {
        setOrganization(res.data?.organization)
        setEnvironment(res.data?.organization?.current_environment)
        setMainOrgName(res.data?.organization?.org_identifier)
        setStripeToken(res.data?.organization?.stripe_publishable_key)
        setRazorpayKey(res.data?.organization?.razorpay_key)
        axios.get(`/api/v1/organizations/${res.data.organization.org_identifier}/banner_notifications?page_type=client&controller_name=notifications`).then(res => {
          setBannerNotifications(res.data.banner_notifications)
          setLoading(false)
        }).catch(err => {
          setLoading(false)
        })
      }).catch(err => {
        if (err.response.data.message === "Authorization token has expired") {
          handleLogout()
        }
        else if (err.response.status == 500) {
          history.push("/server-error")
        }
        else {
          history.push("/organizations/new")
        }
      })
    }
  }, [])

  return (
    <OrgnaziationContext.Provider value={value}>
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        {!loading && <Header orgName={orgName} />}
        {loading ? <Loader type="Puff" color="#00BFFF" height={100} width={100} className='pc-loader' />
          : <div className="page-body-wrapper">
            <Sidebar orgName={orgName} />
            <div className="page-body">
              <Switch>
                <Route path={`${path}admins/`}>
                  <Admin user={user} />
                </Route>

                <Route path={`${path}my/`}>
                  <MyComponents banner_notifications={bannerNotifications} stripeToken={stripeToken}
                    current_organization={organization} current_user={user} />
                </Route>

                <Route path={`${path}`} exact>
                  <EsPageHomepage banner_notifications={bannerNotifications} current_organization={organization} current_user={user} />
                </Route>

                <Route path={`${path}faq`} component={FAQ} exact />
                <Route path={`${path}help`} component={Help} exact />
                <Route path={`${path}notifications/all_notifications`} exact>
                  <NotificationPage myOrganization={organization} />
                </Route>

                {/* data access pages */}
                <Route path={`${path}organizations/:organization_name/merge_and_upload_new`} exact>
                  <MergeAndUpload current_user={user} />
                </Route>
                <Route path={`${path}organizations/:organization_name/api_details`} exact>
                  <ApiDetails current_user={user} />
                </Route>
                <Route path={`${path}organizations/:organization_name/webhooks`} exact>
                  <Webhooks current_user={user} />
                </Route>
                <Route path={`${path}organizations/:organization_name/webhook_dashboard`} exact>
                  <WebhookDashboard current_user={user} />
                </Route>

                {/* issues pages */}
                <Route path={`${path}organizations/:organization_name/issues`} exact>
                  <IssueList banner_notifications={bannerNotifications} current_user={user} />
                </Route>
                <Route path={`${path}organizations/:organization_name/issues/:issue_id`} exact>
                  <IssueShow banner_notifications={bannerNotifications} current_user={user} />
                </Route>

                {/* client feasibility reports */}
                <Route path={`${path}organizations/:organization_name/feasibility_reports`} exact>
                  <FeasibilityReports banner_notifications={bannerNotifications} environment={environment} />
                </Route>
                <Route path={`${path}organizations/:organization_name/feasibility_reports/:feas_id`} exact>
                  <ClientFeasibilityReportSummary environment={environment} />
                </Route>
                <Route path={`${path}organizations/:organization_name/feasibility_reports/:feas_id/user_agreements`}
                  component={ClientUserAgreement} exact />
                <Route path={`${path}organizations/:organization_name/feasibility_reports/:feas_id/billing_details`}
                  component={ClientBillingDetailsPage} exact />
                {/* sites */}
                <Route path={`${path}organizations/:organization_name/sites/new`} exact>
                  <AddNewSitegroup current_user={user} environment={environment} />
                </Route>
                <Route path={`${path}organizations/:organization_name/site/add`} exact>
                  <AddNewSitesToSiteGroup />
                </Route>
                <Route path={`${path}organizations/:organization_name/sites/crawl_notification_preferences`}
                  component={CrawlNotificationPreferences} exact />
                {/* invoice */}
                <Route path={`${path}organizations/:organization_name/invoices`} exact>
                  <Billing banner_notifications={bannerNotifications} current_user={user} current_organization={organization} />
                </Route>
                <Route path={`${path}organizations/:organization_name/invoices/:invoiceId`} exact>
                  <InvoiceDetails razorpayKey={razorpayKey} current_user={user} />
                </Route>

                {/* organization page */}
                <Route path={`${path}organizations/:organization_name`} exact>
                  <OrganizationDetails banner_notifications={bannerNotifications} />
                </Route>
                <Route path={`${path}organizations/:organization_name/manage_users`} exact>
                  <ManageMembers current_user={user} />
                </Route>
                <Route path={`${path}organizations/:organization_name/edit`} exact>
                  <EditOrganization myOrganization={organization} />
                </Route>

                <Route path={`${path}profile/:profile_id`}
                  component={ProfilePageV2} exact />

                <Route path={`${path}organizations/:organization_name/organization_show`} exact>
                  <OrgShow current_organization={organization} />
                </Route>

                {/* ES pages */}
                {<Route path={`${path}organizations/:organization_name/home`} exact>
                  <EsPageHomepage banner_notifications={bannerNotifications} current_organization={organization} current_user={user} />
                </Route>}
                <Route path={`${path}organizations/:organization_name/data_download`} exact>
                  <EsPageDataDownload banner_notifications={bannerNotifications} current_organization={organization} current_user={user} />
                </Route>
                <Route path={`${path}organizations/:organization_name/sites`} exact>
                  <EsPageAllSites banner_notifications={bannerNotifications} current_organization={organization} current_user={user} />
                </Route>

                {/* payment */}
                {stripeToken !== '' && <Elements stripe={loadStripe(stripeToken)}>
                  <Route path={`${path}organizations/:organization_name/cards`} exact>
                    <ListAvailableCards current_user={user} current_organization={organization} />
                  </Route>
                  <Route path={`${path}organizations/:organization_name/cards/new`} exact>
                    <AddNewCard current_user={user} current_organization={organization} />
                  </Route>
                  <Route path={`${path}organizations/:organization_name/invoices/:invoiceId/payments/new`} exact>
                    <MakePayment current_user={user} />
                  </Route>
                </Elements>}
              </Switch>
              <Feedback />
            </div>
          </div>}
        {!loading && <Footer />}
      </div>
    </OrgnaziationContext.Provider>
  )
}

export { OrgnaziationContext }
