import React from 'react';
import PropTypes from 'prop-types';
import 'boxicons/css/boxicons.min.css';
import { Button } from 'reactstrap';

export default function SpinnerButton(props) {
    const { children, loading, disabled, onClick, color } = props;
  return (
    <>
      <Button color={color} onClick = {onClick} disabled={disabled} {...props} >
        <span className='text' > {children} </span>
        {loading && (
          <span className='spinner' >
            <i className='bx bx-loader-alt bx-spin'></i>
          </span>
        )}
      </Button>
    </>
  )
}

SpinnerButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.node,
}