import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { Home } from "react-feather";
import Select from "react-select";
import axios from "axios";
import DataTable from "react-data-table-component";
import Loader from "react-loader-spinner";

export default function Show() {
  let { sitename } = useParams();
  const [ednuHash, setEdnuHash] = useState([]);
  const [segmentsCount, setSegmentsCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const tableColumns = [
    { name: 'Segment Name', selector: 'segment_name' },
    { name: 'Host Name', selector: 'host_name' },
    { name: 'EDNU Stage', selector: 'ednu_stage' },
    { name: 'Current Status', selector: 'current_status' },
    { name: 'Timestamp', selector: 'timestamp' },
  ]

  useEffect(() => {
    setLoading(true)
    axios.get(`/api/v1/internal/ednu_processes/${sitename}`)
      .then(res => {
        console.log("ednu_hash", res.data.ednu_hash);
        setEdnuHash(res.data.ednu_hash);
        console.log("ednuHash", ednuHash);
        setSegmentsCount(res.data.segments_count);
        setLoading(false);
      })
      .catch(err => {
        console.log(err?.message || 'Oops something went wrong');
        setLoading(false);
      })
  }, []);

  return <Container fluid>
    <div className="page-title">
      <Row className=''>
        <Col xs="6">
          <h3>Internal Dashboard</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={'/internal'}>
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <a href="/internal/ednu_processes">All Sites</a> 
            </BreadcrumbItem>
            <BreadcrumbItem className="fw-bold">
              Site Specific Process
            </BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
    </div>

    <Card>
      <CardBody>
        <div className="mb-1">
          <h6>Hits Total: {segmentsCount} <a href={"/internal/ednu_processes/" + sitename}><i className="fa fa-refresh"></i> <span>Refresh</span></a></h6>
        </div>
      </CardBody>
    </Card>
    <Card>
      <CardBody>
        {ednuHash.length > 0 ?
        <>
          <Row className="align-items-end mt-2">
            <DataTable
              columns={tableColumns}
              data={ednuHash}
              noHeader
              pagination
              progressPending={loading}
            />
          </Row>
        </>
      : "No data available"}
    </CardBody>
  </Card>
</Container>
}

