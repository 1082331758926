import React from 'react';
import { Info } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import DataTable from 'react-data-table-component';
import { useLocation } from 'react-router-dom';

import { NewInternalBasePoint } from '../../../../Common/BaseEsPoints/InternalEsBasePoint';

export default function TestSitesStatsTable({ data }) {

    const search = useLocation().search;
    const fromValue = new URLSearchParams(search).get('filter[crawldate][from]');
    const endValue = new URLSearchParams(search).get('filter[crawldate][to]');

    const fromDate = new Date(fromValue);
    const endDate = new Date(endValue);

    // Helper function to format date
    function getFormattedDate(date) {
        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return year + '/' + month + '/' + day;
    };

    // Table column definitions
    const columns = [
        {
            name: <div style={{ color: "#337ab7" }}>Site</div>,
            selector: 'site',
            width: '400px',
            sortable: true,
            center: true,
            grow: 4,
            cell: each => <>
                <a href={`${NewInternalBasePoint}/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${getFormattedDate(fromDate)?.replaceAll('/', '%2F')}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)?.replaceAll('/', '%2F')}&filter%5Bdedupstatus%5D=&filter%5Bhostname%5D=&filter%5Bnormalizationstatus%5D=&filter%5Bsite%5D=${each?.site}&filter%5Buploadstatus%5D=&test_run_status=true`} target='_blank'
                    data-tip data-for='segment_view' className='d-flex justify-content-center align-items-center'> {each?.site}  </a> {each?.site != '' && <Info size={24} className='ms-2' />}
                <ReactTooltip id='segment_view'>Go to Segment view</ReactTooltip>
            </>
        },
        {
            name: <div style={{ color: "#337ab7" }}>Queue Size</div>,
            selector: 'queue_size',
            sortable: true,
            width: '120px',
            center: true,
            cell: each => <>
                <a href={`/internal/queue_sizes/search?filter%5Bis_test_site%5D=true&filter%5Bsite%5D=${each?.site}`} target='_blank'>
                    {each?.queue_size}
                </a>
            </>
        },
        {
            name: <div style={{ color: "#337ab7" }}>Date</div>,
            selector: 'date',
            sortable: true,
            center: true,
            grow: 2,
            cell: each => <div>
                {each?.site == '' ? <b>{each.date}</b>
                    : <><p data-tip data-for='Kibana_Link' className='d-flex justify-content-center align-items-center m-0'>
                        {each?.date} <Info size={14} className='mx-2' /> </p>
                        <ReactTooltip clickable multiline delayHide={1000} effect='solid' id='Kibana_Link'>
                            <a href={`https://kibana.promptcloud.com/app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:%5Blogstash-%5DYYYY.MM.DD,interval:auto,query:(query_string:(analyze_wildcard:!t,query:'(site_name:%20${each?.site}%20OR%20sitename:%20${each?.site}%20OR%20site:%20${each?.site})%20AND%20yyyymmdd:${getFormattedDate(fromDate).split('/').join('')}')),sort:!('@timestamp',desc))`} target='_blank' className='text-white'>Kibana Link</a>
                        </ReactTooltip></>}
            </div>
        },
        {
            name: <div style={{ color: "#337ab7" }}>RSS</div>,
            selector: 'rss_count',
            sortable: true,
            center: true,
            cell: each => <>
                <a href={`${NewInternalBasePoint}/rss?filter%5Bcrawldate%5D%5Bfrom%5D=${getFormattedDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)}&filter%5Bsite%5D=${each?.site}&test_run_status=true`}
                    className='d-flex justify-content-center align-items-center' data-tip data-for='kibana_and_alert'>
                    {each?.rss_count} <Info size={18} className='mx-2' /> </a>
                <ReactTooltip clickable multiline delayHide={1000} effect='solid' id='kibana_and_alert'>
                    <a href={`https://kibana.promptcloud.com/app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:%5Blogstash-%5DYYYY.MM.DD,interval:auto,query:(query_string:(analyze_wildcard:!t,query:'(site_name:%20${each?.site}%20OR%20sitename:%20${each?.site}%20OR%20site:%20${each?.site})%20AND%20yyyymmdd:${getFormattedDate(fromDate).split('/').join('')}%20AND%20pipeline_stage:rss')),sort:!('@timestamp',desc))`} target='_blank' className='text-white'>
                        Kibana Link (To Do)
                    </a> <br />
                    <a href='http://alerta.promptcloud.com/#/alerts?status=open' target='_blank' className='text-white'>
                        Alerta link (To Do)
                    </a> <br />
                </ReactTooltip>
            </>
        },
        {
            name: <div style={{ color: "#337ab7" }}>Feedcrawl</div>,
            selector: 'feed_crawled_urls_count',
            sortable: true,
            center: true,
            cell: each => <>
                <a href={`${NewInternalBasePoint}/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${getFormattedDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)}&filter%5Bdedupstatus%5D=&filter%5Bhostname%5D=&filter%5Bnormalizationstatus%5D=&filter%5Bsite%5D=b${each?.site}&filter%5Buploadstatus%5D=&test_run_status=true`}
                    className='d-flex justify-content-center align-items-center' data-tip data-for='kibana_and_alert2'>
                    {each?.feed_crawled_urls_count} <Info size={18} className='mx-2' /> </a>
                <ReactTooltip clickable multiline delayHide={1000} effect='solid' id='kibana_and_alert2'>
                    <a href={`https://kibana.promptcloud.com/app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:%5Blogstash-%5DYYYY.MM.DD,interval:auto,query:(query_string:(analyze_wildcard:!t,query:'(site_name:%20${each?.site}%20OR%20sitename:%20${each?.site}%20OR%20site:%20${each?.site})%20%20AND%20yyyymmdd:${getFormattedDate(fromDate).split('/').join('')}%20AND%20(pipeline_stage:%20feedcrawl%20OR%20pipeline_stage:%20diskfetcher)')),sort:!('@timestamp',desc))`} target='_blank' className='text-white'>
                        Kibana Link
                    </a> <br />
                    <a href='http://alerta.promptcloud.com/#/alerts?status=open' target='_blank' className='text-white'>
                        Alerta link (To Do)
                    </a> <br />
                </ReactTooltip>
            </>
        },
        {
            name: <div style={{ color: "#337ab7" }}>Extraction</div>,
            selector: 'segment_extr_count',
            sortable: true, center:
                true,
            cell: each => <>
                <a href={`${NewInternalBasePoint}/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${getFormattedDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)}&filter%5Bdedupstatus%5D=&filter%5Bhostname%5D=&filter%5Bnormalizationstatus%5D=&filter%5Bsite%5D=${each?.site}&filter%5Buploadstatus%5D=&test_run_status=true`}
                    className='d-flex justify-content-center align-items-center' data-tip data-for='kibana_alert_dropped'>
                    {each?.segment_extr_count} <Info size={18} className='mx-2' /> </a>
                <ReactTooltip clickable multiline delayHide={1000} effect='solid' id='kibana_alert_dropped'>
                    <p className='m-0'>Dropped: {each?.dropped_ext}</p>
                    <a href={`https://kibana.promptcloud.com/app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:%5Blogstash-%5DYYYY.MM.DD,interval:auto,query:(query_string:(analyze_wildcard:!t,query:'(site_name:%20${each?.site}%20OR%20sitename:%20${each?.site}%20OR%20site:%20${each?.site})%20%20AND%20yyyymmdd:${getFormattedDate(fromDate).split('/').join('')}%20AND%20(pipeline_stage:%20feedcrawl%20OR%20pipeline_stage:%20diskfetcher)')),sort:!('@timestamp',desc))`} target='_blank' className='text-white'>
                        Kibana Link
                    </a> <br />
                    <a href='http://alerta.promptcloud.com/#/alerts?status=open' target='_blank' className='text-white'>
                        Alerta link (To Do)
                    </a> <br />
                </ReactTooltip>
            </>
        },
        {
            name: <div style={{ color: "#337ab7" }}>Dedup</div>,
            selector: 'segment_dedup_count',
            sortable: true,
            center: true,
            cell: each => <>
                <a href={`${NewInternalBasePoint}/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${getFormattedDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)}9&filter%5Bdedupstatus%5D=&filter%5Bhostname%5D=&filter%5Bnormalizationstatus%5D=&filter%5Bsite%5D=${each?.site}&filter%5Buploadstatus%5D=&test_run_status=true`}
                    className='d-flex justify-content-center align-items-center' data-tip data-for='kibana_alert_dropped2'>
                    {each?.segment_dedup_count} <Info size={18} className='mx-2' /> </a>
                <ReactTooltip clickable multiline delayHide={1000} effect='solid' id='kibana_alert_dropped2'>
                    <p className='m-0'>Dropped: {each?.dropped_ded}</p>
                    <a href={`https://kibana.promptcloud.com/app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:%5Blogstash-%5DYYYY.MM.DD,interval:auto,query:(query_string:(analyze_wildcard:!t,query:'(site_name:%20${each?.site}%20OR%20sitename:%20${each?.site}%20OR%20site:%20${each?.site})%20%20AND%20yyyymmdd:${getFormattedDate(fromDate).split('/').join('')}%20AND%20(pipeline_stage:%20feedcrawl%20OR%20pipeline_stage:%20diskfetcher)')),sort:!('@timestamp',desc))`} target='_blank' className='text-white'>
                        Kibana Link
                    </a> <br />
                    <a href='http://alerta.promptcloud.com/#/alerts?status=open' target='_blank' className='text-white'>
                        Alerta link (To Do)
                    </a> <br />
                </ReactTooltip>
            </>
        },
        {
            name: <div style={{ color: "#337ab7" }}>Normalize</div>,
            selector: 'segment_normalize_count',
            sortable: true,
            center: true,
            cell: each => <>
                <a href={`${NewInternalBasePoint}/segment?filter%5Bcrawldate%5D%5Bfrom%5D${getFormattedDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)}&filter%5Bdedupstatus%5D=&filter%5Bhostname%5D=&filter%5Bnormalizationstatus%5D=&filter%5Bsite%5D=${each?.site}&filter%5Buploadstatus%5D=&test_run_status=true`}
                    className='d-flex justify-content-center align-items-center' data-tip data-for='kibana_alert_dropped3'>
                    {each?.segment_normalize_count} <Info size={18} className='mx-2' /> </a>
                <ReactTooltip clickable multiline delayHide={1000} effect='solid' id='kibana_alert_dropped3'>
                    <p className='m-0'>Dropped: {each?.dropped_nor}</p>
                    <a href={`https://kibana.promptcloud.com/app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:%5Blogstash-%5DYYYY.MM.DD,interval:auto,query:(query_string:(analyze_wildcard:!t,query:'(site_name:%20${each?.site}%20OR%20sitename:%20${each?.site}%20OR%20site:%20${each?.site})%20%20AND%20yyyymmdd:${getFormattedDate(fromDate).split('/').join('')}%20AND%20(pipeline_stage:%20feedcrawl%20OR%20pipeline_stage:%20diskfetcher)')),sort:!('@timestamp',desc))`} target='_blank' className='text-white'>
                        Kibana Link
                    </a> <br />
                    <a href='http://alerta.promptcloud.com/#/alerts?status=open' target='_blank' className='text-white'>
                        Alerta link (To Do)
                    </a> <br />
                </ReactTooltip>
            </>
        },
        {
            name: <div style={{ color: "#337ab7" }}>Upload</div>,
            selector: 'segment_upload_count',
            sortable: true,
            center: true,
            cell: each => <>
                <a href={`${NewInternalBasePoint}/segment?filter%5Bcrawldate%5D%5Bfrom%5D=${getFormattedDate(fromDate)}&filter%5Bcrawldate%5D%5Bto%5D=${getFormattedDate(endDate)}&filter%5Bdedupstatus%5D=&filter%5Bhostname%5D=&filter%5Bnormalizationstatus%5D=&filter%5Bsite%5D=${each?.site}&filter%5Buploadstatus%5D=&test_run_status=true`}
                    className='d-flex justify-content-center align-items-center' data-tip data-for='kibana_alert_dropped3'>
                    {each?.segment_upload_count} <Info size={18} className='mx-2' /> </a>
                <ReactTooltip clickable multiline delayHide={1000} effect='solid' id='kibana_alert_dropped3'>
                    <p className='m-0'>Dropped: {each?.dropped_upload}</p>
                    <a href={`https://kibana.promptcloud.com/app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:%5Blogstash-%5DYYYY.MM.DD,interval:auto,query:(query_string:(analyze_wildcard:!t,query:'(site_name:%20${each?.site}%20OR%20sitename:%20${each?.site}%20OR%20site:%20${each?.site})%20%20AND%20yyyymmdd:${getFormattedDate(fromDate).split('/').join('')}%20AND%20(pipeline_stage:%20feedcrawl%20OR%20pipeline_stage:%20diskfetcher)')),sort:!('@timestamp',desc))`} target='_blank' className='text-white'>
                        Kibana Link
                    </a> <br />
                    <a href='http://alerta.promptcloud.com/#/alerts?status=open' target='_blank' className='text-white'>
                        Alerta link (To Do)
                    </a> <br />
                </ReactTooltip>
            </>
        },
        {
            name: <div style={{ color: "#337ab7" }}>Kibana</div>,
            selector: 'kibana',
            sortable: true,
            center: true,
            cell: each => <>
                <a href={`https://kibana.promptcloud.com/app/kibana#/discover?_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:now-7d,mode:quick,to:now))&_a=(columns:!(_source),index:%5Blogstash-%5DYYYY.MM.DD,interval:auto,query:(query_string:(analyze_wildcard:!t,query:'site_name:%20${each?.site}%20OR%20sitename:%20${each?.site}%20OR%20site:%20${each?.site}')),sort:!('@timestamp',desc))`} target='_blank'>Kibana Link</a>
            </>
        },
    ];


    return (
        <div>
            <DataTable
                data={data}
                columns={columns}
                noHeader
                responsive
                pagination />
        </div>
    )
}
